enum MetricValueTypeEnum {
  Percentage = 'PER',
  Absolute = 'ABS',
}

enum MetricValueTypeGraphqlEnum {
  'PER' = 'PER',
  'ABS' = 'ABS',
}

export { MetricValueTypeEnum, MetricValueTypeGraphqlEnum }
