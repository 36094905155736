import { Grid, Typography, styled } from '@mui/material'

export const Container = styled(Grid)`
    display: flex;
`
export const RightContainer = styled(Grid)`
    margin-left: 8px;
`
export const InfoContainer = styled(Grid)`
    display: flex;
    flex-direction: column;
`

export const StyledTypography = styled(Typography)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
