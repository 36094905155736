import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const FilterActiveIcon: React.FC<SvgProps> = ({
    color,
    width = 24,
    height = 24
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary
    const _fill = theme.palette.errorDangerMedium

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox={`0 0 24 24`}
            color={_color}
            fill='none'
        >
            <g id='ic-filter' clipPath='url(#clip0_9926_8447)'>
                <path
                    d='M6 12H18M3 6H21M9 18H15'
                    stroke={_color}
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <circle
                    id='Ellipse 82'
                    cx='18'
                    cy='4'
                    r='5'
                    fill={_fill}
                    stroke='white'
                    strokeWidth='2'
                />
            </g>
            <defs>
                <clipPath id='clip0_9926_8447'>
                    <rect width='24' height='24' fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
}

export default FilterActiveIcon
