import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_FAQS = gql`
  ${MetaFragment('FAQsResponseDto')}
  ${MessagesFragment('FAQsResponseDto')}
  query getFaqs {
    getFaqs {
      data {
        id
        question
        answer
      }
      ...MetaFragment
      ...MessagesFragment
    }
  }
`

export const GET_FAQ_BY_ID = gql`
  ${MetaFragment('FAQResponseDto')}
  ${MessagesFragment('FAQResponseDto')}
  query getFaq($id: String!) {
    getFaq(id: $id) {
      data {
        id
        question
        answer
      }
      ...MetaFragment
      ...MessagesFragment
    }
  }
`
