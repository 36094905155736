import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import React, { useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
    CircleIcon,
    DeleteIcon,
    DropdownIcon,
    PulseAnswerCheckedIcon,
    PulseAnswerIcon,
    SettingsCheckedIcon,
    SettingsIcon,
    SquareIcon,
    ViewIcon,
    ViewOffIcon
} from '@assets'
import {
    ConfirmationDialog,
    ControlledOptionGroup,
    ControlledTextInput,
    DropdownMenu,
    EmptyState,
    Panel,
    StyledErrorMessage
} from '@components'
import { Divider, Grid, Tooltip, Typography } from '@mui/material'
import {
    QUESTION_TYPES,
    QUESTION_TYPES_TITLE,
    surveyMultiAnswerTypes,
    surveyRecommendationTypes,
    TypographyVariants
} from '@constants'
import {
    QuestionTypeEnum,
    SurveyTypeEnum,
    UserPermissionEnum
} from '@dts/constants'
import {
    SurveyMultiResponsesSchema,
    SurveySummarySchema,
    generateUniqueId,
    mapDropdownOptions,
    mergeDropdownFields
} from '@dts/client-utils'
import { handleDragAndDrop, isMediumScreen, onKeyDown } from '@utils'
import { isEmpty, isUndefined } from 'lodash'
import { useCustomTheme, useGraphQLWrapper, useModalWithGraphQL } from '@hooks'

import { GET_QUESTION_TYPES } from '@dts/graphql/lib'
import { StyledButton } from '@components/OptionGroup/styles'
import { QUESTION_FEEDBACK_TYPES } from '@dts/constants/lib'
import { AccessibleAction } from '@hoc'
import { MultiChoiceAnswerDetails } from './multi-answer-details'
import { QuestionConfiguration } from './question-configurations'
import { StyledOutlinedButton } from './style'
import { SummaryDetail } from './summary-detail'
import { SurveyContentConfiguration } from './survey-content-configuration'

export const surveyRecommendationsInitialValues = {
    recommendations: surveyRecommendationTypes?.map((recommendationType) => ({
        recommendationTypeId: recommendationType?.id,
        skills: [],
        tags: []
    }))
}

export const answeDetailsInitialValues = {
    summaryEnabled: false,
    summaryTitle: null,
    summaryDescription: null,
    summaryResponseIconId: null
}

export const SurveyQuestions: React.FC = () => {
    const { t } = useTranslation()
    const {
        control,
        formState: { errors },
        watch,
        setValue,
        getValues,
        trigger
    } = useFormContext()

    const { theme } = useCustomTheme()
    const [searchParams] = useSearchParams()
    const surveyType = searchParams.get('surveyType')
    const {
        fields: questionsArray,
        update: updateQuestions,
        append: appendQuestion,
        remove: removeQuestion
    } = useFieldArray({
        control,
        name: 'questions'
    })
    const questions = watch('questions')

    const [hoveredItem, setHoveredItem] = useState()
    const mediumScreen = isMediumScreen()

    const { currentData: questionTypes } = useGraphQLWrapper({
        query: GET_QUESTION_TYPES,
        queryName: 'getQuestionTypes'
    })
    const { openModalWithForm: deleteConfirmation } = useModalWithGraphQL({
        modalConfig: {
            maxWidth: 'sm',
            Component: () => (
                <ConfirmationDialog
                    message={t('delete.confirmation.message')}
                />
            ),
            updationConfig: {
                primaryButtonText: t('general.confirm'),
                header: t('general.confirmation')
            },
            hasCancelButton: true
        }
    })

    const { openFeedbackDialog: deleteAlert } = useModalWithGraphQL({
        modalConfig: {
            maxWidth: 'sm',
            FeedbackComponent: () => (
                <Grid container justifyContent={'center'}>
                    <Typography
                        variant='body2'
                        py={2}
                        sx={{ whiteSpace: 'pre-line' }}
                    >
                        {t('survey.cannotDelete')}
                    </Typography>
                </Grid>
            )
        }
    })

    const deleteOption = (panel, index, optionIndex) => {
        const newOptionsArray = panel?.options?.filter(
            (option, idx) => idx !== optionIndex
        )

        const updatedPanel = {
            ...panel,
            options: newOptionsArray
        }
        updateQuestions(index, updatedPanel)
    }

    const renderOption = (panel, index, optionIndex) => {
        // Show batchActions when the accordion is hovered on a large screen or when it is a medium screen
        const displayBatchActions: boolean =
            (optionIndex === hoveredItem?.optionIndex &&
                index === hoveredItem?.panelIndex) ||
            mediumScreen
        return (
            <Grid container md={12} xs={12} alignItems={'center'}>
                <Grid item md={10} xs={8}>
                    <AccessibleAction
                        permissions={[UserPermissionEnum.SurveyCreateModify]}
                        component={
                            panel?.options?.[optionIndex]?.isEditing ? (
                                <ControlledTextInput
                                    name={`questions[${index}].options[${optionIndex}].title`}
                                    id={`questions[${index}].options[${optionIndex}].id`}
                                    height={'40px'}
                                    placeholder='Enter option'
                                    sx={{ marginTop: '0px' }}
                                    onBlur={() => {
                                        const updatedPanel = {
                                            ...panel,
                                            options: editOptions(
                                                panel?.options,
                                                optionIndex,
                                                false,
                                                true
                                            )
                                        }
                                        updateQuestions(index, updatedPanel)
                                    }}
                                    autoFocus
                                />
                            ) : (
                                <div>
                                    <Typography
                                        variant='body2'
                                        // Conditionally apply the `pt` prop
                                        pt={
                                            panel?.feedbackId ===
                                                QUESTION_FEEDBACK_TYPES.FeedbackAgainstAll ||
                                            panel?.feedbackId ===
                                                QUESTION_FEEDBACK_TYPES.FeedbackAgainstSelected
                                                ? 3
                                                : undefined
                                        }
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            const updatedPanel = {
                                                ...panel,
                                                options: editOptions(
                                                    panel?.options,
                                                    optionIndex,
                                                    true,
                                                    true
                                                )
                                            }
                                            updateQuestions(index, updatedPanel)
                                        }}
                                        style={{
                                            wordBreak: 'break-word', // Ensure words break if necessary
                                            overflowWrap: 'break-word', // Adds line breaks within long words
                                            whiteSpace: 'normal',
                                            color:
                                                !panel?.options?.[optionIndex]
                                                    ?.titleUpdated ||
                                                isUndefined(
                                                    panel?.options?.[
                                                        optionIndex
                                                    ]?.title
                                                ) ||
                                                isEmpty(
                                                    panel?.options?.[
                                                        optionIndex
                                                    ]?.title
                                                )
                                                    ? theme.palette
                                                          .placeholderGrey
                                                    : theme.palette
                                                          .contentPrimary
                                        }}
                                    >
                                        {panel?.options?.[optionIndex]?.title ||
                                            'Enter option title'}
                                    </Typography>

                                    {errors?.questions?.[index]?.options?.[
                                        optionIndex
                                    ]?.title?.message && (
                                        <Grid item>
                                            <StyledErrorMessage>
                                                {t(
                                                    errors?.questions?.[index]
                                                        ?.options?.[optionIndex]
                                                        ?.title?.message
                                                )}
                                            </StyledErrorMessage>
                                        </Grid>
                                    )}
                                </div>
                            )
                        }
                        elseComponent={
                            <Typography
                                variant='body2'
                                // Conditionally apply the `pt` prop
                                pt={
                                    panel?.feedbackId ===
                                        QUESTION_FEEDBACK_TYPES.FeedbackAgainstAll ||
                                    panel?.feedbackId ===
                                        QUESTION_FEEDBACK_TYPES.FeedbackAgainstSelected
                                        ? 3
                                        : undefined
                                }
                                onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    const updatedPanel = {
                                        ...panel,
                                        options: editOptions(
                                            panel?.options,
                                            optionIndex,
                                            true,
                                            true
                                        )
                                    }
                                    updateQuestions(index, updatedPanel)
                                }}
                                style={{
                                    wordBreak: 'break-word', // Ensure words break if necessary
                                    overflowWrap: 'break-word', // Adds line breaks within long words
                                    whiteSpace: 'normal',
                                    color:
                                        !panel?.options?.[optionIndex]
                                            ?.titleUpdated ||
                                        isUndefined(
                                            panel?.options?.[optionIndex]?.title
                                        ) ||
                                        isEmpty(
                                            panel?.options?.[optionIndex]?.title
                                        )
                                            ? theme.palette.placeholderGrey
                                            : theme.palette.contentPrimary
                                }}
                            >
                                {panel?.options?.[optionIndex]?.title ||
                                    'Enter option title'}
                            </Typography>
                        }
                    />
                    {(panel?.feedbackId ===
                        QUESTION_FEEDBACK_TYPES.FeedbackAgainstAll ||
                        panel?.feedbackId ===
                            QUESTION_FEEDBACK_TYPES.FeedbackAgainstSelected) && (
                        <ControlledTextInput
                            name={`questions[${index}].options[${optionIndex}].summaryDescription`}
                            id={`questions[${index}].options[${optionIndex}].id`}
                            height={'20px'}
                            sx={{ marginTop: '10px' }}
                            variant='standard'
                            placeholder={t('survey.optionsAnswereDetails')}
                            onBlur={() => {
                                const updatedPanel = {
                                    ...panel,
                                    options: editOptions(
                                        panel?.options,
                                        optionIndex,
                                        false,
                                        true
                                    )
                                }
                                updateQuestions(index, updatedPanel)
                            }}
                            autoFocus
                        />
                    )}
                </Grid>
                {displayBatchActions && (
                    <Grid
                        item
                        container
                        display={'flex'}
                        md={2}
                        xs={4}
                        pr={1}
                        justifyContent={'flex-end'}
                        alignItems={'center'}
                        onClick={(e) => e.preventDefault()}
                    >
                        <Tooltip
                            title={
                                panel?.skillId || panel?.topicId
                                    ? [
                                          t('general.settings'),
                                          t('general.checked')
                                      ].join(' ')
                                    : t('general.settings')
                            }
                            placement='top-end'
                        >
                            <Grid
                                item
                                mr={1}
                                tabIndex={0}
                                aria-label={
                                    panel?.skillId || panel?.topicId
                                        ? [
                                              t('general.settings'),
                                              t('general.checked')
                                          ].join(' ')
                                        : t('general.settings')
                                }
                                onKeyDown={(e) => {
                                    const onContentConfigOpen =
                                        getConfigurationSave(index, optionIndex)
                                    onKeyDown(e, onContentConfigOpen, false)
                                }}
                                onClick={() => {
                                    const onContentConfigOpen =
                                        getConfigurationSave(index, optionIndex)

                                    onContentConfigOpen()
                                }}
                            >
                                {/* to-do: need to pass fill in one - will be done later */}
                                {hasRecommendations(panel, optionIndex) ? (
                                    <SettingsCheckedIcon
                                        color={theme.palette.contentTertiary}
                                    />
                                ) : (
                                    <SettingsIcon
                                        height={'20'}
                                        width={'20'}
                                        color={theme.palette.contentTertiary}
                                    />
                                )}
                            </Grid>
                        </Tooltip>
                        <AccessibleAction
                            permissions={[
                                UserPermissionEnum.SurveyCreateModify
                            ]}
                            component={
                                <Tooltip
                                    title={t('general.delete')}
                                    placement='top-end'
                                >
                                    <Grid
                                        item
                                        tabIndex={0}
                                        aria-label={t('general.delete')}
                                        onKeyDown={(e) => {
                                            panel?.options?.length > 2
                                                ? onKeyDown(
                                                      e,
                                                      () =>
                                                          showOptionConfirmation(
                                                              panel,
                                                              index,
                                                              optionIndex
                                                          ),
                                                      false
                                                  )
                                                    ? onKeyDown(
                                                          e,
                                                          () =>
                                                              deleteConfirmation(
                                                                  {
                                                                      panel,
                                                                      index,
                                                                      optionIndex
                                                                  },
                                                                  (form) => {
                                                                      const {
                                                                          panel,
                                                                          index,
                                                                          optionIndex
                                                                      } = form

                                                                      deleteOption(
                                                                          panel,
                                                                          index,
                                                                          optionIndex
                                                                      )
                                                                  }
                                                              ),
                                                          false
                                                      )
                                                    : onKeyDown(
                                                          e,
                                                          () =>
                                                              deleteOption(
                                                                  panel,
                                                                  index,
                                                                  optionIndex
                                                              ),
                                                          false
                                                      )
                                                : onKeyDown(
                                                      e,
                                                      deleteAlert,
                                                      false
                                                  )
                                        }}
                                        onClick={() => {
                                            panel?.options?.length > 2
                                                ? showOptionConfirmation(
                                                      panel,
                                                      index,
                                                      optionIndex
                                                  )
                                                    ? deleteConfirmation(
                                                          {
                                                              panel,
                                                              index,
                                                              optionIndex
                                                          },
                                                          (form) => {
                                                              const {
                                                                  panel,
                                                                  index,
                                                                  optionIndex
                                                              } = form

                                                              deleteOption(
                                                                  panel,
                                                                  index,
                                                                  optionIndex
                                                              )
                                                          }
                                                      )
                                                    : deleteOption(
                                                          panel,
                                                          index,
                                                          optionIndex
                                                      )
                                                : deleteAlert()
                                        }}
                                    >
                                        <DeleteIcon
                                            height={'20'}
                                            width={'20'}
                                            color={
                                                theme.palette.contentTertiary
                                            }
                                        />
                                    </Grid>
                                </Tooltip>
                            }
                        />
                    </Grid>
                )}
            </Grid>
        )
    }

    const addOption = (id, title) => ({
        id,
        optionId: id,
        title,
        isEditing: false,
        titleUpdated: false,
        ...answeDetailsInitialValues,
        surveyRecommendations: surveyRecommendationsInitialValues
    })

    const addQuestion = (questionType) => {
        let options = []

        if (questionType === QUESTION_TYPES.YNQ) {
            options = [
                addOption(generateUniqueId(), t('general.yes')),
                addOption(generateUniqueId(), t('general.no'))
            ]
        } else {
            const optionIndices = [1, 2, 3]

            options = optionIndices?.map((optionIndex) =>
                addOption(
                    generateUniqueId(),
                    [t('general.option'), optionIndex].join('')
                )
            )
        }
        appendQuestion({
            questionId: questions?.length?.toString(),
            question: t('survey.newQuestion'),
            questionType,
            questionTypeTitle: QUESTION_TYPES_TITLE[questionType],
            surveyQuestionResponses: surveyMultiAnswerTypes?.map(
                (surveyMultiAnswerType) => ({
                    multiAnswerId: surveyMultiAnswerType?.id,
                    ...answeDetailsInitialValues
                })
            ),
            options,
            selectedOption: null,
            hideQuestion: false,
            isEditing: false,
            titleUpdated: false,
            topicId: null,
            skillId: null
        })
    }
    const hasRecommendations = (panel, optionIndex) =>
        panel?.options?.[
            optionIndex
        ]?.surveyRecommendations?.recommendations?.some(
            (config) => config?.skills?.length > 0 || config?.tags?.length > 0
        ) ||
        panel?.options?.[optionIndex]?.surveyRecommendations?.topics?.length > 0

    const onAddOption = (panel, index) => {
        const newOption = addOption(
            generateUniqueId(),
            [t('general.option'), panel?.options?.length + 1].join('')
        )

        const newOptionsArray = [...panel?.options, newOption]
        const updatedPanel = {
            ...panel,
            options: newOptionsArray
        }
        updateQuestions(index, updatedPanel)
    }
    const showOptionConfirmation = (panel, index, optionIndex) =>
        questions?.[index]?.selectedOption ===
            panel?.options?.[optionIndex]?.id ||
        questions?.[index]?.selectedOption?.includes?.(
            panel?.options?.[optionIndex]?.id
        ) ||
        panel?.options?.[optionIndex]?.summaryTitle ||
        panel?.options?.[optionIndex]?.title !==
            panel?.options?.[optionIndex]?.id ||
        hasRecommendations(panel, optionIndex)

    const editOptions = (options, optionIndex, isEditing, titleUpdated) =>
        options?.map((option, idx) => {
            if (idx === optionIndex) {
                return {
                    ...option,
                    isEditing,
                    titleUpdated
                }
            } else {
                return {
                    ...option,
                    isEditing: false
                }
            }
        })
    const { openModalWithForm: openContentConfigurationDialog } =
        useModalWithGraphQL({
            modalConfig: {
                Component: SurveyContentConfiguration,
                updationConfig: {
                    primaryButtonText: t('general.save'),
                    header: t('survey.contentConfiguration')
                },
                hasCancelButton: true
            },
            initialValues: {
                surveyRecommendations: surveyRecommendationsInitialValues
            },
            permissions: [UserPermissionEnum.SurveyCreateModify]
        })

    const { openModalWithForm: openQuestionConfiguration } =
        useModalWithGraphQL({
            modalConfig: {
                Component: QuestionConfiguration,
                updationConfig: {
                    primaryButtonText: t('general.save'),
                    header: t('survey.contentConfiguration')
                },
                hasCancelButton: true
            },
            initialValues: {
                skillId: null,
                topicId: null
            },
            permissions: [UserPermissionEnum.SurveyCreateModify]
        })

    const { openModalWithForm: openMultiAnswerDetailsDialog } =
        useModalWithGraphQL({
            modalConfig: {
                Component: MultiChoiceAnswerDetails,
                updationConfig: {
                    primaryButtonText: t('general.save'),
                    header: t('survey.answerDetails')
                },
                hasCancelButton: true
            },
            initialValues: {
                surveyQuestionResponses: surveyMultiAnswerTypes?.map(
                    (surveyMultiAnswerType) => ({
                        multiAnswerId: surveyMultiAnswerType?.id,
                        ...answeDetailsInitialValues
                    })
                )
            },
            yupResolverSchema: SurveyMultiResponsesSchema
        })

    const { openModalWithForm: openAnswerDetailDialog } = useModalWithGraphQL({
        modalConfig: {
            maxWidth: 'sm',
            Component: SummaryDetail,
            updationConfig: {
                primaryButtonText: t('general.save'),
                header: t('survey.answerDetails')
            },
            hasCancelButton: true
        },
        yupResolverSchema: SurveySummarySchema
    })

    const getConfigurationSave = (
        questionIndex: number,
        optionIndex: number
    ) => {
        const questionOption =
            questions?.[questionIndex]?.options?.[optionIndex]

        const formValues = {
            surveyRecommendations: questionOption?.surveyRecommendations
        }

        const onConfigSave = (values) => {
            const updatedVal = {
                ...questions[questionIndex],
                options: questions?.[questionIndex]?.options?.map(
                    (option: object, idx: number) => {
                        if (idx === optionIndex) {
                            return {
                                ...option,
                                ...values
                            }
                        }
                        return option
                    }
                )
            }

            updateQuestions(questionIndex, updatedVal)
        }

        return () => openContentConfigurationDialog(formValues, onConfigSave)
    }

    const getMultiAnswerDetailSave = (questionIndex: number) => {
        const formValues = {
            surveyQuestionResponses:
                questions?.[questionIndex]?.surveyQuestionResponses
        }

        const onConfigSave = (values) => {
            const updatedVal = {
                ...questions[questionIndex],
                surveyQuestionResponses: values?.surveyQuestionResponses
            }

            updateQuestions(questionIndex, updatedVal)
        }

        return () => openMultiAnswerDetailsDialog(formValues, onConfigSave)
    }

    const getAnswerDetailSave = (
        questionIndex: number,
        optionIndex: number
    ) => {
        const questionOption =
            questions?.[questionIndex]?.options?.[optionIndex]

        const formValues = {
            summaryEnabled: questionOption?.summaryEnabled,
            summaryTitle: questionOption?.summaryTitle,
            summaryDescription: questionOption?.summaryDescription,
            summaryResponseIconId: questionOption?.summaryResponseIconId
        }

        const onConfigSave = (values) => {
            const updatedVal = {
                ...questions[questionIndex],
                options: questions?.[questionIndex]?.options?.map(
                    (option: object, idx: number) => {
                        if (idx === optionIndex) {
                            return {
                                ...option,
                                ...values
                            }
                        }
                        return option
                    }
                )
            }

            updateQuestions(questionIndex, updatedVal)
        }

        return () => openAnswerDetailDialog(formValues, onConfigSave)
    }

    const getPanels = (panels: Array<any>) =>
        panels?.map((panel, index: number) => ({
            id: panel?.questionId,
            // To-do: Will be removed later on
            // header: panel?.title,
            headerIcon: true,
            subHeader: panel?.questionTypeTitle,
            header: panel?.question,
            hasError: !isEmpty(errors?.questions?.[index]),
            errorKey: 'title',
            errorMessage: errors?.questions?.[index]?.question?.message,
            // renderElementAtTitle: () => renderQuestion(panel, index),
            // To-do: Will be removed later on
            // batchActionsConfig: {
            //     actions: [
            //         {
            //             icon: ChartArrowDownIcon,
            //             sx: {
            //                 display: 'block'
            //             },
            //             iconId: 'collapsed-icon'
            //         }
            //     ],
            //     clickable: true
            // },
            // panelId: index + 1,
            editConfig: {
                panel,
                index,
                name: `questions.[${index}].question`,
                label: 'Question',
                defaultHeader: t('surveys.enterQuestionTitle'),
                permissions: [UserPermissionEnum.SurveyCreateModify],
                onBlur: (e) => {
                    const updatedPanel = {
                        ...panel,
                        question: e.target.value,
                        isEditing: false,
                        titleUpdated: true
                    }
                    updateQuestions(index, updatedPanel)

                    questions?.forEach((c, idx) =>
                        trigger(`questions.[${idx}].question`)
                    )
                },
                onHeaderClick: (e) => {
                    const updatedPanel = {
                        ...panel,
                        isEditing: true
                    }
                    updateQuestions(index, updatedPanel)
                }
            },
            body: panel?.options?.length ? (
                <>
                    {errors?.questions?.[index]?.options?.message && (
                        <Grid item>
                            <StyledErrorMessage>
                                {t(
                                    errors?.questions?.[index]?.options?.message
                                )}
                            </StyledErrorMessage>
                        </Grid>
                    )}
                    <Droppable droppableId={panel?.questionId}>
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <ControlledOptionGroup
                                    key={panel?.id}
                                    name={`questions[${index}].selectedOption`}
                                    hasColumnDirection
                                    options={mapDropdownOptions(panel?.options)}
                                    enableReorder
                                    permissions={[
                                        UserPermissionEnum.SurveyCreateModify
                                    ]}
                                    isMultiSelect={
                                        panel?.questionType ===
                                        QuestionTypeEnum.MultiChoice
                                    }
                                    onMouseEnter={(optionIndex) => {
                                        setHoveredItem({
                                            panelIndex: index,
                                            optionIndex
                                        })
                                    }}
                                    onMouseLeave={() => setHoveredItem(null)}
                                    rowComponent={(optionIndex) =>
                                        renderOption(panel, index, optionIndex)
                                    }
                                />
                                {provided?.placeholder}
                            </div>
                        )}
                    </Droppable>

                    <AccessibleAction
                        permissions={[UserPermissionEnum.SurveyCreateModify]}
                        component={
                            panel?.questionType !== QUESTION_TYPES.YNQ && (
                                <StyledButton
                                    startIcon={
                                        panel?.questionType ===
                                        QuestionTypeEnum.MultiChoice ? (
                                            <SquareIcon
                                                width={16}
                                                height={16}
                                            />
                                        ) : (
                                            <CircleIcon
                                                width={16}
                                                height={16}
                                            />
                                        )
                                    }
                                    aria-label={t('general.addOption')}
                                    tabIndex={0}
                                    onClick={() => onAddOption?.(panel, index)}
                                >
                                    <Typography
                                        variant='body2'
                                        color={theme.palette.contentLink}
                                    >
                                        {t('general.addOption')}
                                    </Typography>
                                </StyledButton>
                            )
                        }
                    />
                </>
            ) : (
                <Typography>{t('survey.noOptions')}</Typography>
            )
        }))

    return (
        <Grid container flexDirection={'column'}>
            {/* <Typography variant='h1'>{t('general.title')}</Typography>
            <ControlledTextInput
                name='title'
                id='title'
                placeholder={t('general.enterTitle')}
                multiline
                rows={1}
                fullWidth
            /> */}

            <Grid container item justifyContent={'space-between'} mb={1} mt={1}>
                <Grid item pt={2}>
                    <Typography variant={TypographyVariants.h2}>
                        {surveyType === SurveyTypeEnum.Pulse
                            ? [t('survey.pulse'), t('general.questions')].join(
                                  ' '
                              )
                            : surveyType === SurveyTypeEnum.PreTest
                            ? [
                                  t('survey.preTest'),
                                  t('general.questions')
                              ].join(' ')
                            : [
                                  t('survey.postTest'),
                                  t('general.questions')
                              ].join(' ')}
                    </Typography>
                </Grid>
                <AccessibleAction
                    permissions={[UserPermissionEnum.SurveyCreateModify]}
                    component={
                        <Grid
                            item
                            md={3}
                            xs={3}
                            display={'flex'}
                            justifyContent={'flex-end'}
                        >
                            <DropdownMenu
                                menuConfig={mergeDropdownFields(questionTypes, [
                                    {
                                        icon: CircleIcon,
                                        onClick: () =>
                                            addQuestion(QUESTION_TYPES.YNQ)
                                    },
                                    {
                                        icon: CircleIcon,
                                        onClick: () =>
                                            addQuestion(QUESTION_TYPES.SCQ)
                                    },
                                    {
                                        icon: SquareIcon,
                                        onClick: () =>
                                            addQuestion(QUESTION_TYPES.MCQ)
                                    }
                                ])}
                                MenuButton={() => (
                                    <StyledOutlinedButton
                                        variant='outlined'
                                        endIcon={
                                            <DropdownIcon
                                                color={
                                                    theme.palette.contentLink
                                                }
                                            />
                                        }
                                    >
                                        {t('survey.addQuestion')}
                                    </StyledOutlinedButton>
                                )}
                            />
                        </Grid>
                    }
                />
            </Grid>
            <Divider />
            <DragDropContext
                onDragEnd={(result) =>
                    handleDragAndDrop(
                        result,
                        questions,
                        'options',
                        (updatedObject) => setValue('questions', updatedObject),
                        'questionId'
                    )
                }
            >
                <Grid item mt={1}>
                    {errors?.questions?.message && (
                        <Grid item>
                            <StyledErrorMessage>
                                {t(errors?.questions?.message)}
                            </StyledErrorMessage>
                        </Grid>
                    )}

                    {questions?.length ? (
                        <Droppable droppableId='Root' type='group'>
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    <Panel
                                        panels={getPanels(questions)}
                                        // To-do: Will be removed later on
                                        // showSubtitleOnCollapsed={true}
                                        // showBatchActions={false}
                                        isHeaderEditable
                                        enableReorder
                                        backgroundColor={
                                            theme.palette.backgroundPrimary
                                        }
                                        showActionsOnHover={true}
                                        batchActions={[
                                            {
                                                icon: SettingsCheckedIcon,
                                                label: [
                                                    t('general.settings'),
                                                    t('general.checked')
                                                ].join(' '),
                                                hide: (data) =>
                                                    !data?.panel?.skillId &&
                                                    !data?.panel?.topicId,
                                                onClick: (data) => {
                                                    openQuestionConfiguration(
                                                        {
                                                            skillId:
                                                                data?.panel
                                                                    ?.skillId,
                                                            topicId:
                                                                data?.panel
                                                                    ?.topicId,
                                                            feedbackId:
                                                                data?.panel
                                                                    ?.feedbackId,
                                                            commonFeedback:
                                                                data?.panel
                                                                    ?.commonFeedback,
                                                            correctFeedback:
                                                                data?.panel
                                                                    ?.correctFeedback
                                                        },
                                                        (formValues) => {
                                                            const updatedPanel =
                                                                {
                                                                    ...data?.panel,
                                                                    skillId:
                                                                        formValues?.skillId,
                                                                    topicId:
                                                                        formValues?.topicId,
                                                                    feedbackId:
                                                                        formValues?.feedbackId,
                                                                    commonFeedback:
                                                                        formValues?.commonFeedback &&
                                                                        formValues?.feedbackId ===
                                                                            QUESTION_FEEDBACK_TYPES.CommonFeedback
                                                                            ? formValues?.commonFeedback
                                                                            : null,
                                                                    correctFeedback:
                                                                        formValues?.correctFeedback &&
                                                                        formValues?.feedbackId ===
                                                                            QUESTION_FEEDBACK_TYPES.CorrectIncorrectFeedback
                                                                            ? formValues?.correctFeedback
                                                                            : null
                                                                }
                                                            updateQuestions(
                                                                data?.index,
                                                                updatedPanel
                                                            )
                                                        }
                                                    )
                                                    // openQuestionConfiguration(
                                                    //     {
                                                    //         skillId:
                                                    //             data?.panel
                                                    //                 ?.skillId,
                                                    //         topicId:
                                                    //             data?.panel
                                                    //                 ?.topicId
                                                    //     },
                                                    //     () => {
                                                    //         const updatedPanel =
                                                    //             {
                                                    //                 ...data?.panel,
                                                    //                 skillId:
                                                    //                     formValues?.skillId,
                                                    //                 topicId:
                                                    //                     formValues?.topicId
                                                    //             }
                                                    //         updateQuestions(
                                                    //             data?.index,
                                                    //             updatedPanel
                                                    //         )
                                                    //     }
                                                    // )
                                                }
                                            },
                                            {
                                                icon: SettingsIcon,
                                                label: t('general.settings'),
                                                hide: (data) =>
                                                    data?.panel?.skillId ||
                                                    data?.panel?.topicId,
                                                onClick: (data) => {
                                                    openQuestionConfiguration(
                                                        {
                                                            skillId:
                                                                data?.panel
                                                                    ?.skillId,
                                                            topicId:
                                                                data?.panel
                                                                    ?.topicId,
                                                            feedbackId:
                                                                data?.panel
                                                                    ?.feedbackId,
                                                            commonFeedback:
                                                                data?.panel
                                                                    ?.commonFeedback,
                                                            correctFeedback:
                                                                data?.panel
                                                                    ?.correctFeedback
                                                        },
                                                        (formValues) => {
                                                            const updatedPanel =
                                                                {
                                                                    ...data?.panel,
                                                                    skillId:
                                                                        formValues?.skillId,
                                                                    topicId:
                                                                        formValues?.topicId,
                                                                    feedbackId:
                                                                        formValues?.feedbackId,
                                                                    commonFeedback:
                                                                        formValues?.commonFeedback &&
                                                                        formValues?.feedbackId ===
                                                                            QUESTION_FEEDBACK_TYPES.CommonFeedback
                                                                            ? formValues?.commonFeedback
                                                                            : null,
                                                                    correctFeedback:
                                                                        formValues?.correctFeedback &&
                                                                        formValues?.feedbackId ===
                                                                            QUESTION_FEEDBACK_TYPES.CorrectIncorrectFeedback
                                                                            ? formValues?.correctFeedback
                                                                            : null
                                                                }
                                                            updateQuestions(
                                                                data?.index,
                                                                updatedPanel
                                                            )
                                                        }
                                                    )
                                                    // openQuestionConfiguration(
                                                    //     {
                                                    //         skillId:
                                                    //             data?.panel
                                                    //                 ?.skillId,
                                                    //         topicId:
                                                    //             data?.panel
                                                    //                 ?.topicId
                                                    //     },
                                                    //     () => {
                                                    //         const updatedPanel =
                                                    //             {
                                                    //                 ...data?.panel,
                                                    //                 skillId:
                                                    //                     formValues?.skillId,
                                                    //                 topicId:
                                                    //                     formValues?.topicId
                                                    //             }
                                                    //         updateQuestions(
                                                    //             data?.index,
                                                    //             updatedPanel
                                                    //         )
                                                    //     }
                                                    // )
                                                }
                                            },
                                            {
                                                icon: ViewOffIcon,
                                                label: [
                                                    t('general.turnOff'),
                                                    t('general.view')
                                                ].join(' '),
                                                hide: (data) =>
                                                    !data?.panel?.hideQuestion,
                                                onClick: (data) => {
                                                    const updatedPanel = {
                                                        ...data?.panel,
                                                        hideQuestion:
                                                            !data?.panel
                                                                ?.hideQuestion
                                                    }
                                                    updateQuestions(
                                                        data?.index,
                                                        updatedPanel
                                                    )
                                                },
                                                permissions: [
                                                    UserPermissionEnum.SurveyCreateModify
                                                ]
                                            },
                                            {
                                                icon: ViewIcon,
                                                label: [
                                                    t('general.turnOn'),
                                                    t('general.view')
                                                ].join(' '),
                                                hide: (data) =>
                                                    data?.panel?.hideQuestion,
                                                onClick: (data) => {
                                                    const updatedPanel = {
                                                        ...data?.panel,
                                                        hideQuestion:
                                                            !data?.panel
                                                                ?.hideQuestion
                                                    }
                                                    updateQuestions(
                                                        data?.index,
                                                        updatedPanel
                                                    )
                                                },
                                                permissions: [
                                                    UserPermissionEnum.SurveyCreateModify
                                                ]
                                            },
                                            {
                                                icon: DeleteIcon,
                                                label: [
                                                    t('general.delete'),
                                                    t('general.question')
                                                ].join(' '),
                                                onClick: (data) => {
                                                    deleteConfirmation(
                                                        {
                                                            panel: data?.panel,
                                                            index: data?.index
                                                        },
                                                        (form) => {
                                                            const { index } =
                                                                form

                                                            removeQuestion(
                                                                index
                                                            )
                                                        }
                                                    )
                                                },
                                                permissions: [
                                                    UserPermissionEnum.SurveyCreateModify
                                                ]
                                            }
                                        ]}
                                        allowMultipleOpen={true}
                                        // isExpanded={questions?.[
                                        //     questions?.length - 1                                            //TODO: WIll be removed after testing
                                        // ]?.questionId?.toString()}
                                    />
                                    {provided?.placeholder}
                                </div>
                            )}
                        </Droppable>
                    ) : (
                        <>
                            <EmptyState
                                header={t('survey.noQuestions')}
                                subHeader={t('survey.noQuestionsCaption')}
                                preventPadding={true}
                            />
                        </>
                    )}
                </Grid>
            </DragDropContext>
        </Grid>
    )
}
