import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const ClockIcon: React.FC<SvgProps> = ({
    color,
    height = '20',
    width = '20',
    fill
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentPrimary
    const _fill = fill || theme.palette.backgroundPrimary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={height}
            height={width}
            viewBox='0 0 20 20'
            fill='none'
        >
            <g clipPath='url(#clip0_12239_9334)'>
                <path
                    d='M10.0003 5.00033V10.0003L13.3337 11.667M18.3337 10.0003C18.3337 14.6027 14.6027 18.3337 10.0003 18.3337C5.39795 18.3337 1.66699 14.6027 1.66699 10.0003C1.66699 5.39795 5.39795 1.66699 10.0003 1.66699C14.6027 1.66699 18.3337 5.39795 18.3337 10.0003Z'
                    stroke={_color}
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_12239_9334'>
                    <rect width={20} height={20} fill={_fill} />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ClockIcon
