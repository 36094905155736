import { isDOBAbove18, getPastCenturyDate } from '../../utils'
import * as yup from 'yup'

export const AdminProfileSchema = yup.object().shape({
  roleId: yup.string().required('This field is required'),
  firstName: yup
    .string()
    .trim()
    .required('errors.required')
    .max(60, 'errors.max'),
  lastName: yup
    .string()
    .trim()
    .required('errors.required')
    .max(60, 'errors.max'),
  email: yup
    .string()
    .required('errors.required')
    .email('Please provide a valid email')
    .max(255, 'Email should not exceed more than 255 characters'),
  mobileNumber: yup
    .string()
    .notRequired()
    .nullable()
    .test('phone', 'Enter at least 10 digits', (value) => {
      const val = value?.replace(/[^0-9]/g, '')
      return value?.length ? val?.length === 10 : true
    }),
  phoneNumber: yup
    .string()
    .required('errors.required')
    .nullable()
    .test('phone', 'Enter at least 10 digits', (value) => {
      const val = value?.replace(/[^0-9]/g, '')
      return val?.length === 10
    }),
  address: yup.string().max(255, 'errors.max').nullable(),
  dateOfBirth: yup
    .date()
    .max(new Date(), 'errors.birthday')
    .min(getPastCenturyDate(), 'errors.minBirthyear')
    .test('', 'errors.minBirthday', (value) =>
      isDOBAbove18(value.toDateString()),
    )
    .required('errors.required')
    .nullable()
    .transform((value, currentValue) =>
      currentValue && (!isNaN(currentValue) || typeof currentValue === 'string')
        ? value
        : undefined,
    ),
})
