export const ensureProtocol = (url) => {
    if (!/^[a-zA-Z][a-zA-Z\d+\-.]*:\/\//.test(url) && !/^mailto:/.test(url)) {
        return `https://${url}`
    }
    return url
}

export const isValidURL = (url) => {
    const videoUrlRegex =
        /^(https?:\/\/)?(www\.)?([\w-]+\.[\w.-]+)\/([a-zA-Z0-9_-]+\/?)+(\S+)?$/

    return videoUrlRegex.test(url.trim())
}
