/* eslint-disable arrow-body-style */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
    useFormFeedback,
    useGraphQLWrapper,
    useModalWithGraphQL,
    usePage
} from '@hooks'
import {
    DeleteIcon,
    DownloadIcon,
    EmptyReportStateImg,
    PencilIcon,
    SaveAsIcon,
    TextMessageIcon
} from '@assets'
import { Duplicate, ConfirmationDialog } from '@components'
import {
    ChartTypes,
    FlexDirection,
    REPORT_LIBRARY,
    REPORT_TIME_FRAME,
    ReportGranularity
} from '@constants'
import {
    ReportSaveAsNewSchema,
    ReportSchema,
    ReportTitleSchema
} from '@dts/client-utils'
import { DELETE_REPORT, PLOT_CHART, RENAME_REPORT } from '@dts/graphql/lib'
import {
    GET_REPORT_BY_ID,
    GET_REPORT_META_DATA,
    SAVE_REPORT
} from '@dts/graphql'
import { UserPermissionEnum } from '@dts/constants'
import { MetricsPanel } from './report-metrics'
import { ReportView } from './report-view'
import { getCustomRangeInitialValues } from './report-view/get-data'
import { SaveReportDialog } from './report-view/save-report'

export const CreateReport = () => {
    const { t } = useTranslation()
    const [reportTitle, setReportTitle] = useState(t('report.createNew'))
    const [reportId, setReportId] = useState()
    const [chartData, setChartData] = useState([])
    const navigate = useNavigate()

    const { chartId } = useParams()
    // This gets libraryId on + Icon click && libraryId+chartId on viewReport
    const [searchParams] = useSearchParams()
    const reportType = searchParams.get('reportType')
    const libraryId = searchParams.get('libraryId')
    const { currentData: plotChartResponse, retrieve } = useGraphQLWrapper({
        query: PLOT_CHART,
        queryName: 'plotChart',
        retrieveOnMount: false
    })

    const { queryLoading: reportQueryLoading, currentData: reportMetaData } =
        useGraphQLWrapper({
            query: GET_REPORT_META_DATA,
            queryName: 'getReportMetaData',
            retrieveOnMount: true
        })

    const { openPrepopulatedDialog } = useModalWithGraphQL({
        mutation: RENAME_REPORT,
        mutationName: 'renameReport',
        retrieveOnMount: false,
        isCustomMutationInput: true,
        mapFormToMutationInput: (formValues) => ({
            input: {
                id: formValues?.id,
                title: formValues?.title
            }
        }),
        onMutationSuccess: (res) => {
            navigate(
                `/reports/${res?.data?.id}?libraryId=${
                    libraryId ?? res?.data?.reportLibrary?.id
                }&reportType=${REPORT_LIBRARY.CUSTOM}`
            )
        },
        modalConfig: {
            maxWidth: 'sm',
            Component: Duplicate,
            updationConfig: {
                primaryButtonText: t('general.save'),
                header: [t('general.rename'), t('general.report')].join(' ')
            },
            hasCancelButton: true
        },
        yupResolverSchema: ReportTitleSchema
    })

    const { openModalWithForm } = useModalWithGraphQL({
        modalConfig: {
            maxWidth: 'sm',
            Component: Duplicate,
            updationConfig: {
                primaryButtonText: t('general.save'),
                header: [t('general.rename'), t('general.report')].join(' ')
            },
            hasCancelButton: true
        },
        yupResolverSchema: ReportTitleSchema
    })

    const { openPrepopulatedDialog: openModalWithFormForSaveAs } =
        useModalWithGraphQL({
            mutation: SAVE_REPORT,
            mutationName: 'saveReport',
            isCustomMutationInput: true,
            onMutationSuccess: (res) => {
                navigate(
                    `/reports/${res?.data?.id}?libraryId=${res?.data?.reportLibrary?.id}&reportType=${REPORT_LIBRARY.CUSTOM}`
                )
            },
            mapFormToMutationInput: ({
                metrics,
                filters,
                reportGranularity,
                reportTimeFrame,
                breakdown,
                fromDate,
                toDate,
                title,
                reportLibraryId,
                chartType,
                id,
                thumbnailSrc
            }) => {
                return mapFormToMutationInput(
                    metrics,
                    filters,
                    reportGranularity,
                    reportTimeFrame,
                    breakdown,
                    fromDate,
                    toDate,
                    title,
                    reportLibraryId,
                    chartType,
                    id,
                    thumbnailSrc
                )
            },

            initialValues: { title: null },
            modalConfig: {
                maxWidth: 'sm',
                Component: () => <SaveReportDialog />,
                updationConfig: {
                    primaryButtonText: t('general.save'),
                    header: 'Save as Custom Report'
                },

                hasCancelButton: true
            },

            yupResolverSchema: ReportSaveAsNewSchema
        })

    const { openConfirmationDialog: deleteReport } = useModalWithGraphQL({
        mutation: DELETE_REPORT,
        mutationName: 'deleteReport',
        onMutationSuccess: () => {
            navigate(`/reports/custom`)
        },
        isCustomMutationInput: true,

        modalConfig: {
            maxWidth: 'sm',
            Component: () => (
                <ConfirmationDialog
                    message={t('delete.confirmation.message')}
                />
            ),
            confirmationConfig: {
                primaryButtonText: t('general.confirm'),
                header: `${t('general.confirmation')}`
            },
            hasCancelButton: true
        }
    })
    const editReportName = () =>
        openPrepopulatedDialog(undefined, undefined, {
            title: reportTitle,
            id: reportId
        })

    const mapFormToMutationInput = (
        metrics,
        filters,
        reportGranularity,
        reportTimeFrame,
        breakdown,
        fromDate,
        toDate,
        title,
        reportLibraryId,
        chartType,
        id,
        thumbnailSrc
    ) => {
        return {
            input: {
                metricIds: metrics
                    ?.map((metric) => {
                        if (metric?.isAdded) {
                            return metric?.metricId
                        }
                    })
                    ?.filter(Boolean),
                filters: filters
                    ?.map((filter) => {
                        if (filter?.isAdded) {
                            return {
                                filterId: filter?.metricId,
                                value: filter?.selectedData
                            }
                        }
                    })
                    ?.filter(Boolean),
                reportTimeFrame: {
                    reportTimeFrameId: reportTimeFrame,
                    fromDate,
                    toDate
                },
                reportGranularityId: reportGranularity,
                breakdownIds: breakdown
                    ?.map((breakdownItem) => {
                        if (breakdownItem?.isAdded) {
                            return breakdownItem?.metricId
                        }
                    })
                    ?.filter(Boolean),
                chartTypeId: chartType,
                id,
                reportLibraryId: reportLibraryId ?? libraryId,
                thumbnail: thumbnailSrc ?? '/test',
                title: title ?? reportTitle
            }
        }
    }

    const saveAsNewReport = () => {
        const chartData = methods?.getValues()
        const {
            metrics,
            filters,
            reportGranularity,
            reportTimeFrame,
            breakdown,
            fromDate,
            toDate,
            title,
            reportLibraryId,
            chartType,
            thumbnailSrc
        } = chartData
        openModalWithFormForSaveAs(undefined, undefined, {
            metrics,
            filters,
            reportGranularity,
            reportTimeFrame,
            breakdown,
            fromDate,
            toDate,
            title: title ?? reportTitle,
            reportLibraryId: reportLibraryId ?? libraryId,
            chartType,
            thumbnailSrc
        })
    }
    const menuConfig = [
        {
            icon: SaveAsIcon,
            label: t('general.saveAs'),
            onClick: () => saveAsNewReport(),
            permissions: [UserPermissionEnum.ReportCreateModify]
        },
        {
            icon: PencilIcon,
            label: [t('general.rename'), t('general.report')].join(' '),
            onClick: () => editReportName(),
            permissions: [UserPermissionEnum.ReportCreateModify]
        },

        {
            icon: DownloadIcon,
            label: t('general.download'),
            onClick: () => {},
            permissions: [UserPermissionEnum.ReportView]
        },
        {
            icon: DeleteIcon,
            label: t('general.delete'),
            onClick: () => {
                deleteReport({ id: reportId })
            },
            permissions: [UserPermissionEnum.ReportDelete]
        }
    ]

    const {
        methods,
        retrieve: retrieveGetReport,
        save,
        queryLoading: getReportByIdLoading
    } = useFormFeedback({
        permissions: [UserPermissionEnum.ReportCreateModify],
        initialValues: {
            isApplied: false,
            metrics: [],
            filters: [],
            breakdown: [],
            customDateRange: getCustomRangeInitialValues(),
            reportTimeFrame: REPORT_TIME_FRAME.SEVEN_DAYS,
            chartType: ChartTypes.LINE_CHART,
            reportGranularity: ReportGranularity.DAILY,
            reportLibraryId: libraryId
        },
        query: GET_REPORT_BY_ID,
        queryName: 'getReport',
        queryVariables: {
            getReportId: chartId
        },
        retrieveOnMount: chartId ? true : false,
        mapQueryResultToForm: ({
            breakdowns,
            chartData,
            chartType,
            filters,
            metrics,
            reportGranularity,
            reportTimeFrame,
            title,
            id,
            thumbnailSrc,
            reportLibraryId
        }) => {
            setChartData(chartData)
            setReportTitle(title)
            setReportId(id)
            return {
                isApplied: true,
                reportGranularity: reportGranularity?.id,
                reportTimeFrame: reportTimeFrame?.id,
                editReportMetaData: {
                    breakdown: breakdowns,
                    filter: filters,
                    metric: metrics
                },
                chartType: chartType?.id,
                title,
                id,
                thumbnailSrc,
                reportLibraryId,
                customDateRange: [
                    reportTimeFrame?.fromDate,
                    reportTimeFrame?.toDate
                ]
            }
        },
        mapFormToMutationInput: ({
            metrics,
            filters,
            reportGranularity,
            reportTimeFrame,
            breakdown,
            fromDate,
            toDate,
            title,
            reportLibraryId,
            chartType,
            id,
            thumbnailSrc
        }) => {
            return mapFormToMutationInput(
                metrics,
                filters,
                reportGranularity,
                reportTimeFrame,
                breakdown,
                fromDate,
                toDate,
                title,
                reportLibraryId,
                chartType,
                id,
                thumbnailSrc
            )
        },
        mutation: SAVE_REPORT,
        mutationName: 'saveReport',
        isCustomMutationInput: true,
        onMutationSuccess: (res) => {
            navigate(
                `/reports/${res?.data?.id}?libraryId=${res?.data?.reportLibrary?.id}&reportType=${REPORT_LIBRARY.CUSTOM}`
            )
        },
        yupResolverSchema: ReportSchema
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ...(libraryId && {
                showBreadcrumbs: true,
                breadcrumbSearchParamAccessor: 'reportType'
            }),
            ComponentToRender: ReportView,
            componentProps: {
                image: EmptyReportStateImg,
                header: t('report.emptyState.header'),
                subHeader: t('report.emptyState.subHeader'),
                chartData: plotChartResponse ?? chartData,
                plotChart: retrieve,
                retrieveGetReport,
                libraryId,
                reportId: chartId,
                getReportByIdLoading
            },
            hasColumnDirection: true,
            hasHeader: true,

            preventMargin: true,
            preventPadding: true,
            pageHeaderConfig: {
                ...(reportType === REPORT_LIBRARY.CUSTOM &&
                    chartId && {
                        kebabMenuConfig: menuConfig.filter(Boolean)
                    }),
                headerText: reportTitle,
                ariaLabel: t('general.rename'),
                ...(reportType !== REPORT_LIBRARY.SYSTEM && {
                    headerEditAction: () => {
                        if (reportId) {
                            editReportName()
                        } else {
                            openModalWithForm(
                                { title: reportTitle },
                                (formValues) => {
                                    setReportTitle(formValues?.title)
                                }
                            )
                        }
                    }
                }),
                primaryButtonTitle:
                    reportType === REPORT_LIBRARY.CUSTOM
                        ? [t('general.save'), t('general.report')].join(' ')
                        : t('general.saveAs'),
                permissions: [UserPermissionEnum.ReportCreateModify],
                primaryButtonAction: () =>
                    reportType === REPORT_LIBRARY.CUSTOM
                        ? methods.handleSubmit(() => save())()
                        : saveAsNewReport()
            },
            overlayConfig: {
                OverlayComponent: MetricsPanel,
                overlayComponentProps: {
                    plotChart: retrieve,
                    reportMetaData: reportMetaData
                },
                hasFilterOverlay: true,
                overlayIcon: <TextMessageIcon />,
                direction: FlexDirection.RowReverse
            }
        }
    })

    return <>{page}</>
}
