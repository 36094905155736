import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const ArrowDownIcon: React.FC<SvgProps> = ({
    color,
    width = '10',
    height = '11'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 10 11`}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M2.5 4.25L5 6.75L7.5 4.25'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default ArrowDownIcon
