import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Steps, useGraphQLWrapper, useStepper } from '@hooks'
import {
    GET_ALL_VIDEOS,
    GET_COURSES,
    GET_VERTICAL,
    SAVE_VERTICAL
} from '@dts/graphql/lib'

import { TitleSchema } from '@components'
import {
    VerticalCourses,
    VerticalDetails,
    VerticalFilters,
    VerticalReview,
    VerticalVideos
} from './vertical-creation'
import { UserPermissionEnum } from '@dts/constants'
export const getAddedCourses = (coursesAdded, addedCourses) =>
    coursesAdded?.map((course) => {
        // if course already exists we pass id otherwise courseId
        const courseId = addedCourses?.some(
            (alreadyAddedCourse) => alreadyAddedCourse.id === course.id
        )
            ? { id: course?.id }
            : { courseId: course?.id }
        return {
            ...courseId,
            title: course?.title,
            courseTopics: course?.courseTopics?.map((courseTopic) => ({
                topicId: courseTopic?.topicId,
                duration: courseTopic?.duration ?? 0,
                isAssigned: courseTopic?.isAssigned
            }))
        }
    })
export const CreateVertical: React.FC = () => {
    const { t } = useTranslation()

    const { id } = useParams()

    const [courses, setCourses] = useState([])
    const [disableDraft, setDisableDraft] = useState(false)
    const [isVerticalTemplate, setVerticalTemplate] = useState(false)

    const navigate = useNavigate()

    const { queryLoading, data: coursesData } = useGraphQLWrapper({
        query: GET_COURSES,
        queryName: 'getCourses',
        retrieveOnMount: true
    })

    const { data: videosData } = useGraphQLWrapper({
        query: GET_ALL_VIDEOS,
        queryName: 'getVideos',
        retrieveOnMount: true
    })

    useEffect(() => {
        if (!queryLoading && coursesData) {
            setCourses(
                coursesData?.data?.filter(
                    (course) => !course.isDraft && !course.isArchived
                )
            )
        }
    }, [queryLoading, coursesData])

    const steps: Array<Steps> = [
        {
            component: VerticalDetails,
            label: t('general.description'),
            schema: TitleSchema
        },

        {
            component: VerticalCourses,
            label: t('general.courses'),
            preventDisable: true
        },

        {
            component: VerticalFilters,

            label: t('general.filters')
        },
        {
            component: VerticalVideos,
            componentProps: () => ({ allVideos: videosData?.data }),
            label: t('general.videos'),
            preventDisable: true
        },

        {
            component: VerticalReview,
            label: t('general.review'),
            preventDisable: true
        }
    ]

    const mapInputToForm = (result) => {
        const {
            id,
            title,
            description,
            tags,
            courses,
            isTemplate,
            skills,
            template,
            videos,
            isDraft
        } = result?.data || {}

        if (id && !isDraft) {
            setDisableDraft(true)
        } else {
            setDisableDraft(false)
        }

        setVerticalTemplate(isTemplate)
        return {
            id,
            name: title,
            description,
            tags: [...(tags ? tags : [])],
            addedCourses: courses?.map((course) => ({
                ...course,
                courseTopics: course?.courseTopics?.map((courseTopic) => ({
                    ...courseTopic,
                    title: courseTopic?.topic?.title
                }))
            })),
            isTemplate,
            skills: skills.reduce((acc, curr) => {
                acc[curr.id] = true
                return acc
            }, {}),
            addedVideos: videos,
            courseCount: courses?.length,
            videoCount: videos?.length,
            disableTemplate: isTemplate,
            previousTemplate: null
        }
    }

    const getMutationInput = (formValues) => {
        const {
            id,
            name,
            description,
            tags,
            courses,
            videos,
            skills,
            isTemplate,
            template,
            addedCourses,
            addedVideos
        } = formValues || {}

        const coursesAdded = courses
            ? courses?.filter((course) => course.isAdded)
            : addedCourses?.map((course) => ({ id: course.id }))
        const videosAdded = videos
            ? videos?.filter((video) => video.isAdded)
            : addedVideos?.map((video) => ({ id: video.id }))

        return {
            id: id ? id : undefined,
            title: name,
            description,
            isTemplate,
            skills: Object.keys(skills),
            tags,
            courses:
                getAddedCourses(coursesAdded, template ? [] : addedCourses) ??
                [],
            videos: videosAdded?.map((video) => video.id) ?? [],
            templateId: template
        }
    }
    const mapFormToInput = (formValues) => ({
        ...getMutationInput(formValues),
        isDraft: false
    })

    const updateCourseConfig = id && {
        query: GET_VERTICAL,
        queryName: 'getVertical',
        queryVariables: { id },
        mapInputToForm
    }

    const { stepper, jumpToLast } = useStepper({
        steps,
        headerText: id
            ? isVerticalTemplate
                ? t('vertical.editTemplate')
                : `${t('general.edit')} ${t('general.vertical')}`
            : `${t('general.new')} ${t('general.vertical')}`,
        editMode: !!id,
        showBreadcrumbs: true,
        permissions: [UserPermissionEnum.VerticalCreateModify],
        ...updateCourseConfig,
        initialValues: {
            isActive: true,
            isTemplate: false,
            tags: [],
            skills: {},
            topics: [],
            addedTopics: [],
            template: null,
            previousTemplate: null
        },
        mutation: SAVE_VERTICAL,
        mutationName: 'saveVertical',
        mapFormToInput,
        onMutationSuccess: (formValues) => {
            navigate(
                formValues?.data?.isTemplate
                    ? '/verticals/templates'
                    : '/verticals'
            )
        },
        tertiaryButtonConfig: {
            title: t('general.saveDraft'),
            disabled: disableDraft,
            schema: TitleSchema,
            tertiaryButtonMapper: (formValues) => ({
                ...getMutationInput(formValues),
                isDraft: true
            })
        }
    })

    useEffect(() => {
        if (id) jumpToLast()
    }, [id])

    return <>{stepper}</>
}
