import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom' // Import useParams to access URL parameters
import {
    Divider,
    Grid,
    IconButton,
    Typography,
    Card,
    CardMedia,
    CardContent,
    Tooltip
} from '@mui/material'
import { useCustomTheme, useGraphQLWrapper } from '@hooks'
import {
    ArrowRightIcon,
    ChevronLeftIcon,
    PictureFrameImg,
    ReportThumbnail
} from '@assets'
import { GET_REPORTS_BY_LIBRARY_ID } from '@dts/graphql'
import { REPORT_LIBRARY } from '@constants'
import { onKeyDown } from '@utils'
import { NestedDropDownMenu } from './nested-menu'
import {
    CardContainer,
    CardMediaContainer,
    LibraryCardContainer,
    LibraryPanelHeader
} from './styles'

// Constants for card dimensions and scrolling
const CARD_WIDTH = 267 // Width of each card
const CARD_SPACING = 16 // Spacing between cards
const CARDS_SCROLL_COUNT = 4 // Number of cards to scroll on arrow click

export const ReportsPanel = ({ retrieveGetReport, libraryId }) => {
    const { t } = useTranslation() // Hook for translation
    const { theme } = useCustomTheme() // Custom hook to get theme settings
    const containerRef = useRef(null) // Reference for the container element
    const { chartId: urlReportId } = useParams() // Get reportId from URL parameters

    const navigate = useNavigate()

    // This gets libraryId on + Icon click && libraryId+chartId on viewReport
    const [searchParams] = useSearchParams()
    const reportType = searchParams.get('reportType')
    const urlLibraryId = searchParams.get('libraryId')

    // State variables for managing selected library and report IDs
    const [selectedLibraryId, setSelectedLibraryId] = useState(libraryId) // Initially set to provided libraryId
    const [selectedReportId, setSelectedReportId] = useState(
        urlReportId || null
    ) // Initially set to URL reportId or null

    // Custom hook for executing the GraphQL query
    const {
        queryLoading: reportsInLibraryLoading,
        currentData: reportsByLibraryId,
        retrieve
    } = useGraphQLWrapper({
        query: GET_REPORTS_BY_LIBRARY_ID,
        queryName: 'getReportsByLibraryId',
        queryVariables: {
            args: {
                reportLibraryId: selectedLibraryId // Use the selected library ID for querying
            }
        },
        retrieveOnMount: false // Do not retrieve data on mount, only on request
    })

    // Function to fetch a report based on its ID
    const fetchReport = (reportId) => {
        retrieveGetReport({
            variables: {
                getReportId: reportId
            }
        })
    }

    // Handler to navigate to the previous set of cards
    const handlePrevious = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                left: -(CARD_WIDTH + CARD_SPACING) * CARDS_SCROLL_COUNT, // Scroll left by the width of CARDS_SCROLL_COUNT cards
                behavior: 'smooth' // Smooth scrolling
            })
        }
    }

    // Handler to navigate to the next set of cards
    const handleNext = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                left: (CARD_WIDTH + CARD_SPACING) * CARDS_SCROLL_COUNT, // Scroll right by the width of CARDS_SCROLL_COUNT cards
                behavior: 'smooth' // Smooth scrolling
            })
        }
    }

    // Update selected library ID and trigger a fetch of reports when the library changes
    const getSelectedLibrary = (key) => {
        setSelectedLibraryId(key)
    }

    // Fetch reports whenever the selected library ID changes
    useEffect(() => {
        if (selectedLibraryId) retrieve()
    }, [selectedLibraryId])

    // Handle changes in URL parameters to update the selected report ID and fetch report data
    useEffect(() => {
        if (urlReportId) {
            setSelectedReportId(urlReportId)
            fetchReport(urlReportId)
            retrieve()
        }
    }, [urlReportId])

    // Handle initial card selection based on the current list of reports
    useEffect(() => {
        if (reportsByLibraryId && reportsByLibraryId.length > 0) {
            const initialCardIndex = reportsByLibraryId.findIndex(
                (report) => report.id === selectedReportId
            )
            if (initialCardIndex >= 0) {
                setSelectedReportId(reportsByLibraryId[initialCardIndex].id)
                fetchReport(reportsByLibraryId[initialCardIndex].id)
            }
        }
    }, [reportsByLibraryId, selectedReportId])

    return (
        <>
            <LibraryPanelHeader
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
            >
                <Grid item>
                    <NestedDropDownMenu
                        getSelectedLibrary={getSelectedLibrary}
                        selectedLibraryId={selectedLibraryId}
                    />
                </Grid>
                <Grid item>
                    <IconButton
                        aria-label={t('general.previous')}
                        onClick={handlePrevious}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                    <IconButton
                        aria-label={t('general.next')}
                        onClick={handleNext}
                    >
                        <ArrowRightIcon />
                    </IconButton>
                </Grid>
            </LibraryPanelHeader>
            <Divider />
            <LibraryCardContainer
                container
                ref={containerRef} // Reference for the container element
            >
                {reportsByLibraryId?.map((report) => (
                    <CardContainer
                        key={report.id}
                        sx={{
                            border:
                                selectedReportId === report.id
                                    ? `2px solid ${theme.palette.BorderBrandPrimary}` // Highlight selected card
                                    : 'none'
                        }}
                        tabIndex={0}
                        onClick={() => {
                            navigate(
                                `/reports/${report.id}?libraryId=${selectedLibraryId}&reportType=${REPORT_LIBRARY.CUSTOM}`
                            )
                        }}
                        onKeyDown={(e) =>
                            onKeyDown(
                                e,
                                () =>
                                    navigate(
                                        `/reports/${report.id}?libraryId=${selectedLibraryId}&reportType=${REPORT_LIBRARY.CUSTOM}`
                                    ),
                                false
                            )
                        }
                    >
                        <CardMediaContainer
                            component='img'
                            height={'170px'}
                            image={report.thumbnailSrc || ReportThumbnail} // Use default image if none is provided
                            alt={t('general.brokenImage')}
                            aria-label={report.title}
                            onError={(e) => {
                                e.target.onerror = null // Prevent infinite loop if the default image is also broken
                                e.target.src = ReportThumbnail // Set the default image
                            }}
                        />

                        <CardContent>
                            <Tooltip
                                title={report.title}
                                sx={{
                                    cursor: 'pointer'
                                }}
                            >
                                <Typography variant='body2'>
                                    {report.title}
                                </Typography>
                            </Tooltip>
                        </CardContent>
                    </CardContainer>
                ))}
            </LibraryCardContainer>
        </>
    )
}
