import { gql } from '@apollo/client'
import {
  MessagesFragment,
  MetaFragment,
  LearnerListFields,
} from '../../fragments'

export const SAVE_LEARNER_LIST = gql`
  ${MessagesFragment('LearnerListResponse')}
  ${MetaFragment('LearnerListResponse')}
  ${LearnerListFields('LearnerList')}
  mutation createLearnerList($input: LearnerListInput!) {
    createLearnerList(input: $input) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...LearnerListFields
      }
    }
  }
`
