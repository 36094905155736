import React, { FC } from 'react'
import { useFieldArray, FormProvider, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { PlusSquareOutlineIcon, MinusSquareOutlineIcon } from '@assets'
import { OptionListActionItem } from '@components'
import { useCustomTheme } from '@hooks'
import { MultiFieldRowsProps } from './types'

export const MultiFieldRow: FC<MultiFieldRowsProps> = ({
    name,
    disableMultiRow = false,
    config,
    disabled,
    onDelete,
    addNewRowOptions,
    removeRow,
    error,
    canAddItem = true,
    fieldNameAccessor = '',
    maxScrollableHeight
}) => {
    const methods = useFormContext()
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const { append, remove } = useFieldArray({
        control: methods.control,
        name
    })

    const watchFieldArray = methods.watch(name)

    const getRow = (index: number, field: Record<string, unknown>) => (
        <Grid container flexDirection={'row'} gap={1} key={index}>
            {config?.map((conf, value) => (
                <Grid
                    item
                    xs={12}
                    md={conf?.md}
                    key={`${conf?.componentId}-${value}`}
                    id={`${conf?.componentId}-${value}`}
                >
                    {conf.component && (
                        <conf.component
                            name={`${name ?? ''}[${index}].${conf.name}`}
                            onChange={conf.componentProps.onChange}
                            disabled={methods.watch(
                                `${name ?? ''}[${index}].${
                                    conf?.componentProps?.disableName
                                }`
                            )}
                            {...conf.componentProps}
                            error={
                                error
                                    ? !!error?.[index]?.[conf.name]
                                    : !!methods.formState?.errors?.[name]?.[
                                          index
                                      ]?.[conf.name]
                            }
                            helperText={
                                error
                                    ? error?.[index]?.[conf.name]?.message
                                    : methods.formState?.errors?.[name]?.[
                                          index
                                      ]?.[conf.name]?.message
                            }
                        />
                    )}
                </Grid>
            ))}
            {!disableMultiRow && (
                <Grid item xs={2} md={2} display='flex' alignItems='end'>
                    {(methods.getValues(name)?.[index]?.id ||
                        watchFieldArray?.length > 1) &&
                        !field?.[fieldNameAccessor]?.length && (
                            <OptionListActionItem
                                id={'minus-circle'}
                                tooltipTitle={t('general.remove')}
                                actionIcon={MinusSquareOutlineIcon}
                                disabled={disabled}
                                ariaLabel={t('general.remove.ariaLabel', {
                                    ariaLabel: t('general.universityClosures')
                                })}
                                tabIndex={0}
                                disableRipple
                                onClick={
                                    !onDelete
                                        ? () => {
                                              remove(index)
                                              removeRow?.()
                                          }
                                        : () => {
                                              const variables = {
                                                  id: methods.getValues(name)[
                                                      index
                                                  ]?.id
                                              }
                                              if (variables.id) {
                                                  onDelete(variables)
                                              } else {
                                                  remove(index)
                                              }
                                          }
                                }
                            />
                        )}
                    {index === 0 && canAddItem && (
                        <OptionListActionItem
                            id='plus-circle'
                            tooltipTitle={t('general.add')}
                            actionIcon={PlusSquareOutlineIcon}
                            disabled={disabled}
                            ariaLabel={t('general.add.ariaLabel', {
                                ariaLabel: t('general.universityClosures')
                            })}
                            tabIndex={0}
                            disableRipple
                            onClick={() => {
                                append({
                                    ...addNewRowOptions,
                                    displayOrder: index + 2
                                })
                            }}
                            sx={{
                                '&:focus': {
                                    backgroundColor: theme.palette.naturalGrey
                                }
                            }}
                        />
                    )}
                </Grid>
            )}
        </Grid>
    )

    return (
        <FormProvider {...methods}>
            <Grid
                xs={12}
                md={12}
                style={{
                    overflow: maxScrollableHeight ? 'auto' : 'none',
                    maxHeight: maxScrollableHeight
                }}
            >
                <Grid
                    container
                    borderColor={theme.palette.borderGrey}
                    xs={12}
                    md={12}
                    flexDirection={'column'}
                >
                    {watchFieldArray?.length
                        ? watchFieldArray?.map(
                              (field: Record<string, unknown>, index: number) =>
                                  getRow(index, field)
                          )
                        : getRow(0)}
                </Grid>
            </Grid>
        </FormProvider>
    )
}
