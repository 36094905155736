import { gql } from '@apollo/client'

export const VideoFields = (on: string) => gql`
    fragment VideoFields on ${on} {
        id
        url
        urlSrc
        title
        status
        description
        tags
        coverImage
        coverImageSrc
        hyperlinks
        captions
        captionFile
        captionFileSrc
        version
        captionText
        stateSpecific
        duration
        topicIds
        creatorId
        viewCount
        creatorData{
            id
            firstName
            lastName
            role
            avatarSrc
            following
            muted
        }
        topicsData {
            id
            title
        }
        statesId
        statesData {
            id
            title
        }
        creatorId
        creatorData {
            id
            avatarSrc
            firstName
            lastName
            role
        }
        skillId
        skillData {
            id
            title
        }
        isDraft
        createdAt
        updatedAt
        publishedAt
        reviews {
            title
            details
            version
        }
        keyFrames
        keyFramesSrc
        videoInteraction {
            id
            videoId
            liked
            disliked
            saved
            watchTime
        }
        pendingStatus {
            firstLevel
            secondLevel
            thirdLevel
          }
          reporterData {
            id
            firstName
            lastName
            role
            avatarSrc
          }
          reviewerData {
            id
            firstName
            lastName
            role
            avatarSrc
          }
    }
`
