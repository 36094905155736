import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useFormContext, useWatch } from 'react-hook-form'
import { Grid, Typography } from '@mui/material'
import { useCustomTheme, useGraphQLWrapper } from '@hooks'
import {
    InsightKPIsKeys,
    InsightTimeline,
    formatMinutesToMinutesAndSeconds,
    formatNumberToKMB,
    secondsToCeiledMinutes
} from '@dts/client-utils'
import moment from 'moment'
import { GET_INSIGHT_METADATA } from '@dts/graphql'
import { BackButtonType } from '@constants'
import { isInteger } from 'lodash'
import { VideoCardGrid } from '@components'
import { useVideoInsight } from './get-video-insight'
import {
    AnalyticsGrid,
    EngagementDetailsGrid,
    InfoGrid,
    Container,
    VideoGrid,
    RowListGRid,
    CountLabel,
    CoverImage,
    StyledCard
} from './styles'
import { RowList } from './row-list'
import { INSIGHT_TIMEFRAME_ID, engagementDetails, videoKPIsCard } from './data'
import { VideoAnalyticCard } from './video-analytics-card'
import { InsightHeader } from './insight-header'

export const VideoInsight = () => {
    const [searchParams] = useSearchParams()
    const insightTimeFrameId = searchParams.get('insightTimeFrameId') || ''
    const { videoId = '' } = useParams<{ videoId: string }>()

    const { theme } = useCustomTheme()
    const { t } = useTranslation()
    const { control } = useFormContext()

    const navigate = useNavigate()
    const insightTimeFrame = useWatch({ control, name: 'insightTimeFrame' })

    const { insightsVideoKPIsData, retrieveVideoInsights } = useVideoInsight({
        insightTimeFrameId,
        videoId
    })

    const {
        currentData: insightMetaData,
        queryLoading: insightMetaDataLoading
    } = useGraphQLWrapper({
        query: GET_INSIGHT_METADATA,
        queryName: 'getInsightMetaData'
    })

    useEffect(() => {
        if (insightTimeFrame) {
            retrieveVideoInsights({
                variables: {
                    args: {
                        insightTimeFrameId: insightTimeFrame,
                        videoId
                    }
                }
            })
        }
    }, [insightTimeFrame])
    const VideoThumbnailCard = ({
        coverImageSrc
    }: {
        coverImageSrc: string
    }) => (
        <StyledCard>
            <CoverImage src={coverImageSrc} alt='Cover' />
        </StyledCard>
    )

    const videoDuration = insightsVideoKPIsData?.video?.duration
    const videoCreatedAt = insightsVideoKPIsData?.video?.createdAt

    const durationInMinutes = videoDuration
        ? `${secondsToCeiledMinutes(videoDuration)} ${t('general.minutes')}`
        : '--'

    const formattedCreatedAt = videoCreatedAt
        ? moment(videoCreatedAt).format('MMM DD, YYYY')
        : '--'

    return (
        <>
            <InsightHeader
                insightMetaData={insightMetaData}
                insightTimeFrame={insightTimeFrame}
                headerTitle={t('analytics.yourInsights.label')}
                backButtonConfig={{
                    showBackButton: true,
                    backButtonType: BackButtonType.History
                }}
            />
            <Container container>
                <VideoGrid item sm={12} md={2} width={'100%'}>
                    <VideoCardGrid
                        cardData={[insightsVideoKPIsData?.video]}
                        cardAction={(params) => false}
                        showViewMore={undefined}
                        marginRight={4}
                    />
                    {insightsVideoKPIsData?.video?.title && (
                        <Typography
                            variant='h2'
                            fontSize={20}
                            color={theme.palette.contentPrimary}
                            fontWeight={700}
                            mt={2}
                            textAlign={'center'}
                        >
                            {insightsVideoKPIsData?.video?.title}
                        </Typography>
                    )}
                    <Typography
                        mt={'5px'}
                        variant='body2'
                        fontSize={14}
                        color={theme.palette.contentSecondary}
                        fontWeight={400}
                    >
                        {insightsVideoKPIsData?.video?.topicsData?.[0]?.title}
                    </Typography>
                    <Typography
                        mt={'5px'}
                        variant='body2'
                        fontSize={12}
                        color={theme.palette.contentTertiary}
                        fontWeight={400}
                    >
                        {durationInMinutes} | {formattedCreatedAt}
                    </Typography>
                </VideoGrid>

                <InfoGrid item sm={12} md={10}>
                    <AnalyticsGrid container xs={12} spacing={2}>
                        {videoKPIsCard.map((data, index) => {
                            const kpiValue =
                                insightsVideoKPIsData?.KPIs?.[data?.id]

                            const count =
                                kpiValue || kpiValue === 0
                                    ? formatNumberToKMB(Number(kpiValue))
                                    : '--'

                            return (
                                <Grid item xs={4}>
                                    <VideoAnalyticCard
                                        key={data?.id + index}
                                        title={t(data?.title)}
                                        count={count}
                                    />
                                </Grid>
                            )
                        })}
                    </AnalyticsGrid>
                    <EngagementDetailsGrid>
                        <Typography
                            variant='h2'
                            fontSize={20}
                            color={theme.palette.contentPrimary}
                            fontWeight={700}
                        >
                            {t('insight.engagementsDetails')}
                        </Typography>

                        <Typography
                            variant='body2'
                            fontSize={12}
                            color={theme.palette.contentTertiary}
                            fontWeight={400}
                            mt={1}
                        >
                            {[
                                t('general.inLast'),
                                INSIGHT_TIMEFRAME_ID[
                                    insightTimeFrame ||
                                        InsightTimeline.LAST_SEVEN_DAYS
                                ]
                            ].join(' ')}
                        </Typography>
                        <RowListGRid>
                            {insightsVideoKPIsData &&
                                engagementDetails?.map((data, index) => {
                                    const matchedValue =
                                        insightsVideoKPIsData?.KPIs?.[data.id]
                                    let formattedValue

                                    // Apply different functions based on data.id
                                    switch (data.id) {
                                        case InsightKPIsKeys.AVERAGE_WATCH_TIME:
                                            formattedValue =
                                                matchedValue ||
                                                matchedValue === 0
                                                    ? formatMinutesToMinutesAndSeconds(
                                                          matchedValue
                                                      )
                                                    : '0'
                                            break
                                        case InsightKPIsKeys.WATCH_FULL_VIDEO:
                                            formattedValue =
                                                matchedValue ||
                                                matchedValue === 0
                                                    ? `${
                                                          isInteger(
                                                              matchedValue
                                                          )
                                                              ? matchedValue
                                                              : matchedValue?.toFixed(
                                                                    2
                                                                )
                                                      } %`
                                                    : '0'
                                            break
                                        // Add more cases as needed for different transformations
                                        default:
                                            formattedValue =
                                                matchedValue !== undefined
                                                    ? `${matchedValue}`
                                                    : '0'
                                    }

                                    return (
                                        <RowList
                                            key={data?.id + index}
                                            kpiTitle={t(data?.title)}
                                            kpiSubtitle={t(data?.description)}
                                            kpiValue={formattedValue}
                                            hideDivider={
                                                engagementDetails.length - 1 ===
                                                index
                                            }
                                        />
                                    )
                                })}
                        </RowListGRid>
                    </EngagementDetailsGrid>
                </InfoGrid>
            </Container>
        </>
    )
}
