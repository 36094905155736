import { format } from 'date-fns'
import { formatDateDaily, formatDateHourly, formatDateMonthly } from '../utils'
import { InsightTimeline } from '../constants'

export const useInsightLineChart = ({
  dataPoints,
  timeFrameId,
  timeframeData,
  metricName,
}: {
  timeframeData: Array<string>
  dataPoints: Array<{ count: number, date: Date }>
  timeFrameId: string
  metricName: string
}) => {
  const isHourly = timeFrameId === InsightTimeline.LAST_SEVEN_DAYS
  const isDaily =
    timeFrameId === InsightTimeline.LAST_TWENTY_EIGHT_DAYS ||
    timeFrameId === InsightTimeline.LAST_SIXTY_DAYS
  const isMonthly = timeFrameId === InsightTimeline.LAST_YEAR
  const dataMap = new Map(timeframeData.map((date) => [date, 0]))

  dataPoints?.forEach((point) => {
    const date: string | Date | number = new Date(point.date)
    const formattedDate = isHourly
      ? format(date, "yyyy-MM-dd'T'HH:00:00")
      : isDaily
      ? format(date, 'yyyy-MM-dd')
      : format(date, 'yyyy-MM-dd')

    if (dataMap.has(formattedDate)) {
      dataMap.set(formattedDate, point.count)
    }
  })

  const counts = Array?.from(dataMap.values())

  const chartData = {
    name: metricName,
    data: counts,
  }

  return {
    chartData,
    xAxisCategories: timeframeData?.map((date) => ({
      id: date,
      label: isHourly
        ? formatDateHourly(date)
        : isDaily
        ? formatDateDaily(date)
        : formatDateMonthly(date),
    })),
  }
}
