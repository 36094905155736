import { gql } from '@apollo/client'
import {
  MessagesFragment,
  MetaFragment,
  LearnerFields
} from '../../fragments'

export const CREATE_LEARNER = gql`
  ${MetaFragment('GetUserResponseDto')}
  ${MessagesFragment('GetUserResponseDto')}
  ${LearnerFields('UserDataDto')}
  mutation createLearner($input: CreateLearnerInputDto!) {
    createLearner(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...LearnerFields
      }
    }
  }
`
