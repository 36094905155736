import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const HalfMoonIcon: React.FC<SvgProps> = ({
    color,
    height = '24',
    width = '24'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 24 24`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M22 15.8442C20.6866 16.4382 19.2286 16.7688 17.6935 16.7688C11.9153 16.7688 7.23116 12.0847 7.23116 6.30654C7.23116 4.77135 7.5618 3.3134 8.15577 2C4.52576 3.64163 2 7.2947 2 11.5377C2 17.3159 6.68414 22 12.4623 22C16.7053 22 20.3584 19.4742 22 15.8442Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default HalfMoonIcon
