import { styled, Grid, Button, Card, CardMedia } from '@mui/material'
// Constants for card dimensions and scrolling
const CARD_WIDTH = 267 // Width of each card
const CARD_SPACING = 16 // Spacing between cards
export const LibraryPanelHeader = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.backgroundSecondary,
    padding: '8px',
    borderRadius: '8px'
}))

export const LibraryCardContainer = styled(Grid)(({ theme }) => ({
    display: 'block',
    padding: '16px',
    borderRadius: '8px',
    overflowX: 'auto', // Enable horizontal scrolling
    overflowY: 'hidden', // Hide vertical scrolling
    whiteSpace: 'nowrap', // Prevent line break
    height: 'calc(170px + 100px)' // Adjust height to fit cards and content
}))

export const CardContainer = styled(Card)(({ theme }) => ({
    display: 'inline-block',
    borderRadius: '8px',
    width: `${CARD_WIDTH}px`,
    marginRight: `${CARD_SPACING}px` // Spacing between cards
}))

export const CardMediaContainer = styled(CardMedia)(({ theme }) => ({
    minWidth: 'auto',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
    objectFit: 'cover'
}))
