import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, SurveyFields } from '../../fragments'

export const GET_SURVEY = gql`
  ${SurveyFields('SurveyDto')}
  ${MessagesFragment('SurveyResponseDto')}
  ${MetaFragment('SurveyResponseDto')}
  query getSurvey($id: String!) {
    getSurvey(id: $id) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...SurveyFields
      }
    }
  }
`
