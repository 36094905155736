import { Grid, styled } from '@mui/material'

export const StyledGrid = styled(Grid)(({ theme, hasIcon }) => ({
    '& .MuiSwitch-root': hasIcon && {
        width: '67px !important',
        height: '50px !important',
        '& .MuiSwitch-track': {
            backgroundColor: `${theme.palette.borderGrey} !important`,
            borderRadius: '12px'
        }
    }
}))
