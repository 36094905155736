import { useState } from 'react'

export const useSessionStorage = <T,>(key: string, initialValue?: T) => {
    const [storedValue, setStoredValue] = useState<T>(() => {
        if (typeof window === 'undefined') {
            return initialValue
        }
        try {
            const item = window.sessionStorage.getItem(key)
            return item ? JSON.parse(item) : initialValue
        } catch (error) {
            return initialValue
        }
    })

    const setValue = (value: T | ((val: T) => T), preventState = false) => {
        try {
            const valueToStore =
                value instanceof Function ? value(storedValue) : value
            !preventState && setStoredValue(valueToStore)
            if (typeof window !== 'undefined') {
                window.sessionStorage.setItem(key, JSON.stringify(valueToStore))
            }
        } catch (error) {
            //ignore
        }
    }

    const removeValue = () => {
        try {
            if (typeof window !== 'undefined') {
                window.sessionStorage.removeItem(key)
            }
        } catch (error) {
            //ignore
        }
    }
    return [storedValue, setValue, removeValue] as const
}
