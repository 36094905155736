import React from 'react'
import { UserProfileContainer } from '@containers'
import { CreatorVideosLayout } from '@pages'

export const CreatorProfilePage = () => (
    <>
        <UserProfileContainer />
        <CreatorVideosLayout />
    </>
)
