import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { usePageHeader, useRoutes } from '@hooks'
import { Grid } from '@mui/material'
import { HeaderOutlet } from '@components'

export const ReportsLayout = () => {
    const { t } = useTranslation()
    const { reportRoutes } = useRoutes()

    return (
        <HeaderOutlet
            headerText={t('general.reportLibraries')}
            menuConfig={reportRoutes}
            showBreadrumbs={true}
            breadcrumbSearchParamAccessor={'reportType'}
        />
    )
}
