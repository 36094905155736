import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { SearchableList, TextWithTooltip } from '@components'
import { Grid, Typography } from '@mui/material'
import { VideoThumbnailImg } from '@assets'
import { useCustomTheme } from '@hooks'

export const VideoRow: React.FC = ({ item, isAdded, typeError }) => {
    const { theme } = useCustomTheme()
    return (
        <Grid
            item
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
        >
            <Grid
                item
                md={12}
                xs={12}
                pr={1}
                display={'flex'}
                alignItems={'center'}
            >
                <Grid item pr={1}>
                    <Grid
                        sx={{
                            width: '60px',
                            height: '60px',
                            borderRadius: '8px',
                            padding: '8px'
                        }}
                    >
                        <img
                            src={
                                item.videoAvatar
                                    ? item.videoAvatar
                                    : VideoThumbnailImg
                            }
                            style={{
                                borderRadius: '10%',
                                objectFit: 'contain'
                            }}
                            height='100%'
                            width='100%'
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant='subtitle2'>
                        <TextWithTooltip
                            text={item?.title}
                            color={theme.palette.contentPrimary}
                        />
                    </Typography>
                    <Typography variant='smallText' fontWeight={'500px'}>
                        {item.creator}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const SearchableVideosList: React.FC = ({
    allVideos,
    permissions = []
}) => {
    const { t } = useTranslation()
    const { setValue, getValues, control } = useFormContext()
    const videosAdded = useWatch({ control, name: 'videos' })

    const addedVideos = useWatch({
        control,
        name: 'addedVideos'
    })

    useEffect(() => {
        if (!videosAdded?.length || !videosAdded) {
            const videosUpdated: Array<object> = []
            addedVideos?.forEach((video) => {
                videosUpdated.push({
                    id: video?.id,
                    title: video?.title,
                    isAdded: true,
                    videoAvatar: video?.coverImageSrc,
                    creator: `${video?.creatorData?.firstName} ${video?.creatorData?.lastName}`
                })
            })

            allVideos?.forEach((video) => {
                const titleExists = videosUpdated.some(
                    (existingVideo) => existingVideo.id === video?.id
                )

                if (!titleExists) {
                    videosUpdated.push({
                        id: video.id,
                        title: video?.title,
                        isAdded: false,
                        videoAvatar: video?.coverImageSrc,
                        creator: `${video?.creatorData?.firstName} ${video?.creatorData?.lastName}`
                    })
                }
            })

            setValue('videos', videosUpdated)
        }
    }, [allVideos, addedVideos])

    const { update: updateVideos, fields: videoFields } = useFieldArray({
        control,
        name: 'videos'
    })

    return (
        <SearchableList
            topicsData={videosAdded}
            updateData={updateVideos}
            listType={'videos'}
            addedIn={'Vertical'}
            rowComponent={VideoRow}
            permissions={permissions}
        />
    )
}
