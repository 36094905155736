import {
  format,
  parseISO,
  startOfMonth,
  endOfMonth,
  getMonth,
  getDate,
} from 'date-fns'
import { isNaN } from 'lodash'

export const formatDate = (date: string) => {
  if (isNaN(Date.parse(date))) {
    return
  }

  const convertedDate =
    typeof date === 'string' ? parseISO(date) : new Date(parseISO(date))

  return format(convertedDate, 'MM/dd/yyyy')
}

export const formatLongDate = (dateString: string) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const date = new Date(dateString)
  const month = months[date.getMonth()]
  const day = date.getDate()
  const year = date.getFullYear()

  return `${month} ${day}, ${year}`
}

export const formatDateTime = (date: string) =>
  format(new Date(date), 'MM/dd/yyyy HH:mm')

export const formatDateTimeToIso = (date: string) => {
  const dateObject = new Date(date)
  const isoString = dateObject.toISOString()
  return isoString
}

export const convertToDateTime = (timeStamp: string) => {
  const _timestamp = parseInt(timeStamp, 10) * 1000 // Convert to milliseconds
  return { ms: _timestamp, date: formatDate(_timestamp.toString()) }
}
export const isDOBAbove18 = (date: string) => {
  const today = new Date()
  const birthDate = new Date(date)
  const age = today.getFullYear() - birthDate.getFullYear()
  const calculatedAge: boolean = age >= 18
  return calculatedAge
}

export const getPastCenturyDate = () => {
  return new Date(new Date().setFullYear(new Date().getFullYear() - 100))
}

export const formatTime = (time) => format(time, 'H:m')

export const formatFullDate = (date: string, toLocalTimezone = true) => {
  if (isNaN(Date.parse(date))) {
    return
  }
  if (toLocalTimezone) {
    return format(new Date(date), 'MM/dd/yyyy')
  } else {
    const originalDate = new Date(date)
    const day = originalDate.getUTCDate().toString().padStart(2, '0')
    const month = (originalDate.getUTCMonth() + 1).toString().padStart(2, '0')
    const year = originalDate.getUTCFullYear()
    return `${month}/${day}/${year}`
  }
}

export const formatRemainingTime = (minutes: number) => {
  const totalMinutes = Math.floor(minutes)
  const hours = Math.floor(totalMinutes / 60)
  const remainingMinutes = Math.round(minutes % 60)
  return `${hours}h:${remainingMinutes}m left`
}

export const validateDateComparison = (
  startDate: string | Date,
  endDate: string | Date,
) => {
  if (!startDate || !endDate) {
    return true
  }

  const convertedStartDate =
    typeof startDate === 'string' ? new Date(startDate) : startDate
  const convertedEndDate =
    typeof endDate === 'string' ? new Date(endDate) : endDate

  return convertedEndDate > convertedStartDate
}

export const formatMonthAndDate = (dateString: string) => {
  if (!dateString || !parseISO(dateString)) {
    return 'errors.invalidTimeFormat'
  }

  return format(parseISO(dateString), 'MMMM dd')
}

export const formatDateHourly = (isoDateString: string): string => {
  const date = new Date(isoDateString)
  const formattedDate = format(date, 'MMMM dd') // Example: June 18, 12:00 pm
  return formattedDate
}
export const formatDateDaily = (isoDateString: string): string => {
  const date = new Date(isoDateString)
  const formattedDate = format(date, 'MMMM dd') // Example: June 18, 12:00 pm
  return formattedDate
}
export const formatDateMonthly = (isoDateString: string): string => {
  const date = new Date(isoDateString)
  const formattedDate = format(date, 'MMMM dd') // Example: June 18, 12:00 pm
  return formattedDate
}
export const getCurrentTimeDifference = (timeStamp: string) => {
  const _timestamp = parseInt(timeStamp)
  // Current time in seconds
  const currentTimestamp = Math.floor(Date.now() / 1000)

  // Check if provided timestamp is greater than the current timestamp
  if (_timestamp > currentTimestamp) {
    const differenceInSeconds = _timestamp - currentTimestamp
    const differenceInMilliseconds = (_timestamp - currentTimestamp) * 1000
    return { sec: differenceInSeconds, ms: differenceInMilliseconds }
  }

  const differenceInSeconds = currentTimestamp - _timestamp
  const differenceInMilliseconds = differenceInSeconds * 1000

  return { sec: differenceInSeconds, ms: differenceInMilliseconds }
}
