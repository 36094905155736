import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Grid, Avatar } from '@mui/material'
import { ControlledTextInput, TextWithTooltip } from '@components'
import {
    ActiveIcon,
    AddIcon,
    ArchiveIcon,
    EditUserIcon,
    PendingIcon,
    RestoreIcon,
    UserIcon
} from '@assets'
import {
    useModalWithGraphQL,
    useDirectory,
    useGraphQLWrapper,
    useCustomTheme
} from '@hooks'
import {
    ARCHIVE_CONTENT_CREATOR,
    GET_ALL_CREATORS,
    SEND_INVITE
} from '@dts/graphql'
import { SendInviteSchema, formatDate } from '@dts/client-utils'
import { ContentCreatorStatus, UserInviteStatus } from '@constants'
import { darkColors, lightColors } from '@colors'
import { UserPermissionEnum } from '@dts/constants'

export const InviteUser = () => {
    const { t } = useTranslation()
    return (
        <Grid p={2}>
            <ControlledTextInput
                name='email'
                label={t('general.email')}
                id='email'
            />
        </Grid>
    )
}

export const ManageContentCreators = ({ status }) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const navigate = useNavigate()

    const sendInviteConfig = {
        mutation: SEND_INVITE,
        mutationName: 'sendInvite',
        onMutationSuccess: () => {
            retrieve?.({
                variables: {
                    options: queryOptions
                }
            })
        },
        isCustomMutationInput: true
    }

    const archiveContentCreatorConfig = {
        mutation: ARCHIVE_CONTENT_CREATOR,
        mutationName: 'updateContentCreator',
        onMutationSuccess: () => {
            retrieve?.({
                variables: {
                    options: queryOptions
                }
            })
        },
        isCustomMutationInput: true
    }

    const { save: sendInvite } = useGraphQLWrapper({ ...sendInviteConfig })
    const { save: archiveContentCreator } = useGraphQLWrapper({
        ...archiveContentCreatorConfig
    })

    const { directory, retrieve, queryOptions } = useDirectory({
        directoryConfig: {
            id: 'creatorDirectory',
            query: GET_ALL_CREATORS,
            queryName: 'getAllContentCreators',
            queryVariables: {
                args: {
                    tab: status
                }
            },
            config: {
                customActions: [
                    {
                        icon: (
                            <EditUserIcon
                                color={theme.palette.contentSecondary}
                            />
                        ),
                        label: `${t('general.view')} ${t('general.user')}`,
                        ariaLabel: `${t('general.view')} ${t('general.user')}`,
                        showInMenu: true,
                        hide: (params) =>
                            status === ContentCreatorStatus.ARCHIVED ||
                            params?.row?.status?.id !== UserInviteStatus.ACTIVE,
                        action: (params) => {
                            navigate(`/content-creators/${params?.id}`)
                        }
                    },
                    {
                        icon:
                            status === ContentCreatorStatus.ACTIVE ? (
                                <ArchiveIcon
                                    color={theme.palette.contentSecondary}
                                />
                            ) : (
                                <RestoreIcon
                                    color={theme.palette.contentSecondary}
                                />
                            ),
                        label:
                            status === ContentCreatorStatus.ACTIVE
                                ? t('createArticle.archive')
                                : t('general.restore'),
                        ariaLabel:
                            status === ContentCreatorStatus.ACTIVE
                                ? t('createArticle.archive')
                                : t('general.restore'),
                        showInMenu: true,
                        action: (params) => {
                            archiveContentCreator({
                                input: {
                                    id: params?.row?.id,
                                    isArchived:
                                        status === ContentCreatorStatus.ACTIVE
                                }
                            })
                        },
                        permissions: [UserPermissionEnum.CreatorCreateModify]
                    },
                    {
                        icon: (
                            <UserIcon color={theme.palette.contentSecondary} />
                        ),
                        label: `${t('general.resend')} ${t('general.invite')}`,
                        ariaLabel: `${t('general.resend')} ${t(
                            'general.invite'
                        )}`,
                        showInMenu: true,
                        hide: (params) =>
                            params?.row?.status?.id !==
                            UserInviteStatus.PENDING,
                        action: (params) => {
                            sendInvite({
                                input: {
                                    email: params?.row?.email,
                                    isCreator: true
                                }
                            })
                        },
                        permissions: [UserPermissionEnum.CreatorCreateModify]
                    }
                ],
                columns: [
                    {
                        field: 'status',
                        headerName: '',
                        maxWidth: 30,
                        flex: 1,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={
                                    !params?.row?.active ? (
                                        <ActiveIcon
                                            color={darkColors.tomatoRed}
                                        />
                                    ) : params?.value?.id ===
                                      UserInviteStatus.ACTIVE ? (
                                        <ActiveIcon />
                                    ) : (
                                        <PendingIcon />
                                    )
                                }
                                toolTip={
                                    !params?.row?.active
                                        ? `${t('general.inactive')}`
                                        : params?.value?.id ===
                                          UserInviteStatus.ACTIVE
                                        ? t('general.active')
                                        : t('user.pendingInvitation')
                                }
                                tabIndex={params.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'avatarSrc',
                        headerName: '',
                        flex: 0,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={
                                    <Grid sx={{ p: 1 }}>
                                        <Avatar
                                            sx={{
                                                border: `1px solid ${theme.palette.borderGrey}`
                                            }}
                                            src={params?.value}
                                            alt={params.row.firstName}
                                        />
                                    </Grid>
                                }
                                isDirectoryLink={
                                    status === ContentCreatorStatus.ACTIVE
                                }
                                toolTip={params.row.firstName}
                                tabIndex={params.tabIndex}
                                onClick={() => {
                                    status === ContentCreatorStatus.ACTIVE &&
                                        navigate(
                                            `/content-creators/${params?.id}`
                                        )
                                }}
                            />
                        )
                    },
                    {
                        field: 'firstName',
                        headerName: t('general.name'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={`${params?.row?.firstName ?? ''} ${
                                    params?.row?.lastName ?? ''
                                }`}
                                isDirectoryLink={
                                    status === ContentCreatorStatus.ACTIVE
                                }
                                toolTip={params.value}
                                tabIndex={params.tabIndex}
                                onClick={() => {
                                    status === ContentCreatorStatus.ACTIVE &&
                                        navigate(
                                            `/content-creators/${params?.id}`
                                        )
                                }}
                            />
                        )
                    },
                    {
                        field: 'email',
                        headerName: t('general.email'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={params?.value}
                                tabIndex={params.tabIndex}
                            />
                        ),
                        flex: 1.5
                    },
                    {
                        field: 'createdAt',
                        headerName: t('general.dateJoined'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={
                                    params?.value
                                        ? formatDate(params?.value)
                                        : ''
                                }
                                tabIndex={params.tabIndex}
                            />
                        )
                    }
                ],
                pagination: true
            },
            enableSearch: status === ContentCreatorStatus.ACTIVE,
            toolbarConfig: {
                header:
                    status === ContentCreatorStatus.ACTIVE
                        ? t('contentCreators.active')
                        : t('contentCreators.archived'),

                ...(status === ContentCreatorStatus.ACTIVE && {
                    addNewButtonConfig: {
                        label: t('contentCreators.invite'),
                        action: () => openDialog(),
                        icon: AddIcon,
                        permissions: [UserPermissionEnum.CreatorCreateModify]
                    }
                })
            }
        }
    })

    const { openDialog } = useModalWithGraphQL({
        ...sendInviteConfig,
        mapFormToMutationInput: ({ email }) => ({ input: { email } }),
        initialValues: {
            email: null
        },
        modalConfig: {
            maxWidth: 'sm',
            Component: InviteUser,
            creationConfig: {
                primaryButtonText: t('general.confirm'),
                header: `${t('general.invite')} ${t('general.user')}`
            },
            hasCancelButton: true
        },
        yupResolverSchema: SendInviteSchema
    })

    return directory
}
