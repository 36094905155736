enum DailyReminderOptionEnum {
  Everyday = 'EVD',
  EveryWeekday = 'EWD',
}

enum DailyReminderOptionGraphqlEnum {
  EVD = 'EVD',
  EWD = 'EWD',
}

export { DailyReminderOptionEnum, DailyReminderOptionGraphqlEnum }
