import { Grid, styled } from '@mui/material'

export const StyledCheckboxGrid = styled(Grid, {
    shouldForwardProp: (prop) => prop !== 'checked'
})(({ theme, checked, width }) => ({
    padding: '0px 10px',
    height: '45px',
    width: width ?? '100%',
    backgroundColor: checked
        ? theme.palette.backgroundInfoSubtle
        : 'transparent',
    borderLeft: checked ? `2px solid ${theme.palette.contentLink}` : 'none',
    '&:hover': {
        cursor: 'pointer'
    }
    //TODO Remove Style
    // borderRadius: '5px',
    // border: `1px solid ${theme.palette.borderGrey}`,
}))
