import React, { useEffect, useState, memo } from 'react'
import { FixedSizeList, areEqual } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import InfiniteLoader from 'react-window-infinite-loader'
import { useCustomTheme, useGraphQLWrapper, useLoader, useVideo } from '@hooks'
import memoize from 'memoize-one'
import { useMediaQuery } from '@mui/material'
import { CustomLoader } from '@components'
import { UseVirtualizedScrollConfig } from './types'

const Item = memo((props) => {
    const { loading } = useLoader()
    const { index, style, data } = props
    const { itemData, loadComponent, isMediumScreen, queryLoading, itemCount } =
        data
    const video = itemData?.items?.[index]
    const itemStyle = {
        ...style,
        scrollSnapAlign: 'start',
        scrollSnapStop: 'always'
    }

    return (
        <div
            key={video?.id || index}
            id={video?.id}
            // style={isMediumScreen ? itemStyle : style}
            style={itemStyle}
            className='fixedSizeListItem'
        >
            {loadComponent(video, index)}
            {/* Todo verify with infinite scroll */}
            {loading && (
                <div style={{ padding: '10px', textAlign: 'center' }}>
                    <CustomLoader />
                </div>
            )}
        </div>
    )
}, areEqual)

const createItemData = memoize((items, queryLoading, itemCount) => ({
    items,
    queryLoading,
    itemCount
}))

export const useVirtualizedScroll = (config: UseVirtualizedScrollConfig) => {
    const {
        query,
        queryName,
        queryVariables: queryVars,
        loadComponent
    } = config
    const { theme } = useCustomTheme()
    const [queryVariables, setQueryVariables] = useState({
        options: {
            page: 1,
            pageSize: 10
        },
        ...queryVars
    })

    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )

    const { listRef, itemHeight } = useVideo()

    const [data, setData] = useState<Array<unknown>>([])

    const {
        currentData,
        retrieve,
        data: queryData,
        queryLoading
    } = useGraphQLWrapper({
        query,
        queryName,
        retrieveOnMount: false,
        queryVariables
    })

    useEffect(() => {
        retrieve?.({
            variables: queryVariables
        })
    }, [queryVariables])

    useEffect(() => {
        if (currentData) {
            if (queryVariables?.options?.page === 1) {
                setData(currentData)
            } else {
                setData((existingData) => [...existingData, ...currentData])
            }
        }
    }, [currentData])

    const fetchMore = () => {
        setQueryVariables?.((prevQueryVariables: object) => ({
            ...prevQueryVariables,
            options: {
                ...prevQueryVariables?.options,
                page: prevQueryVariables?.options?.page + 1
            }
        }))
    }

    function isRowLoaded(index) {
        return index < data.length && data[index] !== null
    }

    const itemCount = (queryData?.meta?.count || 0) ?? 0
    const itemData = createItemData(data, queryLoading, itemCount)

    // This condition is being used to cover an edge case for scroll-snap functionality on last video rendered
    useEffect(() => {
        const contentContainer = document.getElementById('content-container')
        if (contentContainer) contentContainer.style.overflowY = 'hidden'

        return () => {
            if (contentContainer) contentContainer.style.overflowY = 'auto'
        }
    }, [])

    const renderWrappedComponent = () => (
        <>
            <InfiniteLoader
                isItemLoaded={isRowLoaded}
                loadMoreItems={fetchMore}
                itemCount={itemCount}
            >
                {({ onItemsRendered, ref }) => (
                    <>
                        <AutoSizer>
                            {({ width, height }) => (
                                <div>
                                    <FixedSizeList
                                        className='fixedSizeList'
                                        width={width}
                                        height={height}
                                        itemData={{
                                            itemData,
                                            loadComponent,
                                            isMediumScreen,
                                            queryLoading,
                                            itemCount
                                        }}
                                        itemSize={itemHeight}
                                        onItemsRendered={onItemsRendered}
                                        ref={(list) => {
                                            listRef.current = list
                                            ref(list) // Pass ref to InfiniteLoader
                                        }}
                                        itemCount={itemCount}
                                        style={{
                                            paddingBottom: '175px',
                                            backgroundColor: isMediumScreen
                                                ? theme.palette
                                                      .backgroundPrimary
                                                : theme.palette
                                                      .backgroundPrimary,
                                            // ...(isMediumScreen
                                            //     ? {
                                            scrollSnapType: 'y mandatory'
                                            //   }
                                            // : {})
                                        }}
                                    >
                                        {Item}
                                    </FixedSizeList>
                                </div>
                            )}
                        </AutoSizer>
                    </>
                )}
            </InfiniteLoader>
        </>
    )

    return {
        data,
        setData,
        setQueryVariables,
        queryLoading,
        wrappedComponent: renderWrappedComponent()
    }
}
