import {
    InsightKPIsKeys,
    InsightPanelKey,
    VideoInsightCardKeys
} from '@dts/client-utils'

export const INSIGHT_PANEL_ID = {
    VIE: 'analytics.views.label',
    LIK: 'setting.liked',
    WAT: 'analytics.watchTime.label'
}

export enum INSIGHT_TIMEFRAME_ID {
    LSD = '7 days',
    LTD = '28 days',
    LSX = '60 days',
    LYR = 'year'
}

export const engagementDetails = [
    {
        id: InsightKPIsKeys.AVERAGE_WATCH_TIME,
        title: 'insight.engagementDetails.averageWatchTime',
        description: ''
    },
    {
        id: InsightKPIsKeys.WATCH_FULL_VIDEO,
        title: 'insight.engagementDetails.watchedFullVideo',
        description: 'insight.engagementDetails.watchedFullVideo.description'
    },
    {
        id: InsightKPIsKeys.LEARNER_REACHED,
        title: 'insight.engagementDetails.learnersReached',
        description: 'insight.engagementDetails.learnersReached.description'
    },
    {
        id: InsightKPIsKeys.REPLAYS,
        title: 'insight.engagementDetails.replays',
        description: 'insight.engagementDetails.replays.description'
    },
    {
        id: InsightKPIsKeys.SAVED,
        title: 'insight.engagementDetails.saved',
        description: 'insight.engagementDetails.replays.description'
    }
]

export const videoKPIsCard = [
    {
        id: VideoInsightCardKeys.VIEWS,
        title: 'general.views',
        description: ''
    },
    {
        id: VideoInsightCardKeys.LIKES,
        title: 'general.likes',
        description: 'insight.engagementDetails.learnersReached.description'
    },
    {
        id: VideoInsightCardKeys.WATCH_TIME_IN_MINUTES,
        title: 'insight.watchTimeInMins',
        description: 'insight.engagementDetails.replays.description'
    }
]

export const analyticsCardData = [
    {
        id: InsightPanelKey.VIEWED,
        title: 'general.views'
    },
    {
        id: InsightPanelKey.LIKED,
        title: 'insight.likes'
    },
    {
        id: InsightPanelKey.WATCH_TIME,
        title: 'insight.watchTimeInMins'
    }
]
