import { useCallback, useEffect } from 'react'
import { EditorContextType } from '@types'
import { UPLOAD_VIDEO_ICON } from '@constants'
import { MediaLoader } from '@assets'

export const useEditor = (params: EditorContextType) => {
    useEffect(() => {
        const { ref } = params

        const editor = ref?.current

        if (editor) {
            const toolbar = editor.getEditor().getModule('toolbar')
            const customVideoUploadElement = toolbar.container.querySelector(
                '.ql-toolbar > .ql-formats:last-child > .ql-custom'
            )

            // Create a new button element
            const button = document.createElement('button')
            // Set the inner HTML of the button to the SVG icon
            /**
             * TODO: This will be replaced with actual icon
             */
            button.innerHTML = UPLOAD_VIDEO_ICON

            button.style.marginTop = '4px'
            button.style.marginLeft = '-13px'

            // Add a click event listener to the button
            button.addEventListener('click', onVideoChange)

            // Replace the existing custom button with the new button
            customVideoUploadElement.parentNode.replaceChild(
                button,
                customVideoUploadElement
            )
        }
    }, [])

    const onMediaUploadCallBack = (
        url: string,
        id: string,
        index: number,
        mediaType: string
    ) => {
        const { ref } = params

        if (ref?.current) {
            const quill = ref?.current.getEditor()

            quill?.deleteText(index, 1)

            quill?.insertEmbed(index, mediaType, {
                src: url,
                id
            })
        }
    }

    const onVideoChange = useCallback(() => {
        const { ref, onVideoChange } = params

        const input = document.createElement('input')

        input.setAttribute('type', 'file')

        input.setAttribute('accept', 'video/*')

        input.click()

        input.onchange = () => {
            if (input) {
                const file = input.files?.[0]
                if (file) {
                    if (ref?.current) {
                        const quill = ref?.current?.getEditor()

                        const range = quill.getSelection(true)
                        /**
                         * TODO: This will be replaced with actual image
                         */
                        quill.insertEmbed(range.index, 'image', {
                            src: MediaLoader,
                            id: null,
                            imageWidth: '300px',
                            imageHeight: '200px'
                        })

                        onVideoChange(file, (url: string, id: string) =>
                            onMediaUploadCallBack(url, id, range.index, 'video')
                        )
                    }
                }
            }
        }
    }, [])

    const onImageChange = useCallback(() => {
        const { ref, onImageChange } = params

        const input = document.createElement('input')
        input.setAttribute('type', 'file')

        input.setAttribute('accept', 'img/*')

        input.click()

        input.onchange = () => {
            if (input) {
                const file = input.files?.[0]
                if (file) {
                    if (ref?.current) {
                        const quill = ref?.current?.getEditor()

                        const range = quill.getSelection(true)
                        /**
                         * TODO: This will be replaced with actual image
                         */
                        quill.insertEmbed(range.index, 'image', {
                            src: MediaLoader,
                            id: null,
                            imageWidth: '300px',
                            imageHeight: '200px'
                        })

                        onImageChange(file, (url: string, id: string) =>
                            onMediaUploadCallBack(url, id, range.index, 'image')
                        )
                    }
                }
            }
        }
    }, [])

    const onMediaRemove = (
        range: { index: number; length: number },
        context: object,
        mediaType = 'video'
    ) => {
        const { ref, onMediaRemove } = params

        if (ref?.current) {
            const quill = ref.current.getEditor()

            const [line] = quill.getLine(range.index - 1)

            if (line.domNode.classList.contains(`${mediaType}-container`)) {
                const imageId = line.domNode.getAttribute(
                    `data-${mediaType}-id`
                )
                if (imageId) onMediaRemove(imageId)
            }
        }

        return true
    }

    return {
        onVideoChange,
        onImageChange,
        onMediaRemove
    }
}
