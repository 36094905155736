import { AdminTopics, MessagesFragment, MetaFragment } from '../../fragments'

import { gql } from '@apollo/client'

export const GET_TOPICS = gql`
  ${MetaFragment('TopicsResponseDto')}
  ${MessagesFragment('TopicsResponseDto')}
  ${AdminTopics('TopicDto')}
  query getTopics ($forSurvey: Boolean){
    getTopics (forSurvey: $forSurvey){
      ...MetaFragment
      ...MessagesFragment
      data {
        ...AdminTopics
      }
    }
  }
`
