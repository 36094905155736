import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, CourseFields } from '../../fragments'

export const GET_COURSE = gql`
  ${MessagesFragment('CourseResponseDto')}
  ${MetaFragment('CourseResponseDto')}
  ${CourseFields('CourseDto')}
  query getCourse($id: String!) {
    getCourse(id: $id) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...CourseFields
      }
    }
  }
`
