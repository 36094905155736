import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import {
    ControlledCheckBox,
    ParentContainer,
    TypographyContainer,
    ControlledSelect,
    ErrorNotification,
    Loader
} from '@components'
import { Grid, Typography } from '@mui/material'

import { useQuery } from '@apollo/client'
import { mapDropdownOptions } from '@dts/client-utils'
import { GET_LEARNER_LIST_FIELD } from '@dts/graphql/lib'
import { transformErrorsForForm } from '@utils'
import { useLoader, useTable } from '@hooks'
import Fuse from 'fuse.js'
import { isEmpty } from 'lodash'

export const LearnerListMatch: React.FC = ({ allCourses }) => {
    const { t } = useTranslation()
    const { getValues, control, setValue, formState, setError } =
        useFormContext()
    const [dropdownOptions, setDropdownOptions] = useState([])
    const [transformedErrors, setTransformedErrors] = useState([])
    const fuseOptions = {
        keys: ['title'],
        includeScore: true,
        threshold: 0.4
    }
    const { loading } = useLoader()

    function tokenizeString(str) {
        // Tokenize the string by splitting on spaces, punctuation, and camelCase/PascalCase boundaries
        return str
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .toLowerCase()
            .split(/[\s,.;:!?]+/)
            .filter(Boolean)
    }

    function calculateSimilarity(searchTokens, itemTokens) {
        // calculates the Jaccard similarity coefficient between two sets of tokens
        const intersection = searchTokens.filter((token) =>
            itemTokens.includes(token)
        )
        const union = [...new Set([...searchTokens, ...itemTokens])]
        return intersection.length / union.length
    }

    function findClosestMatch(searchString, dataArray, threshold = 0.4) {
        let closestMatch = null
        let highestSimilarity = 0

        const searchTokens = tokenizeString(searchString)
        // The source string and dropDownOption elements are tokenized to calculate similarity between individual tokens
        dataArray.forEach((item) => {
            const itemTokens = tokenizeString(item.title)
            const similarity = calculateSimilarity(searchTokens, itemTokens)
            if (similarity > highestSimilarity && similarity >= threshold) {
                highestSimilarity = similarity
                closestMatch = item
            }
        })

        return closestMatch
    }

    const {
        data: dropDownsData,
        loading: dropdownsLoading,
        error: dropdownsError
    } = useQuery(GET_LEARNER_LIST_FIELD)

    useEffect(() => {
        if (!dropdownsLoading && !dropdownsError) {
            setDropdownOptions(dropDownsData?.getLearnerListField?.data)
        }
    }, [dropdownsLoading, dropDownsData])

    useEffect(() => {
        setValue('dropdownOptions', dropdownOptions)
    }, [dropdownOptions])

    const csvData = useWatch({
        control,
        name: 'csvData'
    })

    const pastedText = useWatch({
        control,
        name: 'learners'
    })

    useEffect(() => {
        setValue('previousPastedText', pastedText)
        setValue('undoPastedChanges', false)
    }, [pastedText])

    const fieldMappingArray = useWatch({
        control,
        name: 'fieldMappingArray'
    })

    useEffect(() => {
        if (formState?.errors?.['generic_error']) {
            setTransformedErrors(
                transformErrorsForForm([formState?.errors?.['generic_error']])
            )
        }
    }, [formState?.errors])

    useEffect(() => {
        if (
            !Object.keys(formState?.dirtyFields).includes(
                'fieldMappingArray'
            ) &&
            dropdownOptions?.length
        ) {
            const fuse = new Fuse(dropdownOptions, fuseOptions)
            const keys = csvData && csvData[0] ? Object.keys(csvData[0]) : []

            const keyMapping: Array<object> = []

            keys.map((key) => {
                const searchResult = fuse.search(key)
                const closestFuseMatch = searchResult[0]?.item
                const closestCustomMatch = findClosestMatch(
                    key,
                    dropdownOptions
                )
                keyMapping.push({
                    csvField: key,
                    hubbubField: closestFuseMatch
                        ? closestFuseMatch?.id
                        : closestCustomMatch
                        ? closestCustomMatch?.id
                        : '',
                    mapFieldValue: false
                })
            })

            setValue('fieldMappingArray', keyMapping)
        }
    }, [csvData, dropdownOptions, fuseOptions])

    const { tableComponent } = useTable({
        tableConfig: {
            tableData: fieldMappingArray,
            columns: [
                {
                    field: 'csvField',
                    label: t('learnerList.matchField.csv'),
                    headerStyle: { width: '30%' },
                    renderCell: (params, index) => (
                        <Grid flexDirection={'column'}>
                            <Typography variant='subtitle2'>
                                {params.csvField}
                            </Typography>
                            <Typography variant='caption'>
                                {[
                                    t('learnerList.mapFields.example'),
                                    csvData.find(
                                        (obj) =>
                                            obj?.[params?.csvField] &&
                                            !isEmpty(obj?.[params?.csvField])
                                    )?.[params?.csvField]
                                ].join(' ')}
                            </Typography>
                        </Grid>
                    ),
                    cellStyle: {
                        paddingTop: '10px',
                        paddingBottom: '10px'
                    }
                },
                {
                    field: 'hubbubField',
                    label: t('learnerList.matchField.hubbub'),
                    headerStyle: { width: '40%' },

                    renderCell: (params, index) => (
                        <ControlledSelect
                            name={`fieldMappingArray[${index}].hubbubField`}
                            placeholder={t('learnerList.matchField.doNotMap')}
                            width={'90%'}
                            ariaLabel={t('learnerList.matchField.hubbub')}
                            marginTop={0}
                            sx={{
                                '& .MuiInputBase-input': {
                                    paddingTop: '10px !important',
                                    paddingBottom: '10px !important'
                                }
                            }}
                            id={index}
                            options={
                                dropdownOptions
                                    ? mapDropdownOptions(dropdownOptions)
                                    : []
                            }
                        />
                    ),
                    cellStyle: {
                        paddingTop: '2px',
                        paddingBottom: '2px'
                    }
                },
                {
                    field: 'mapFieldValue',
                    label: t('learnerList.matchField.mapField'),
                    headerStyle: { width: '30%' },
                    renderCell: (params, index) => (
                        <ControlledCheckBox
                            id={index}
                            ariaLabel={t('learnerList.matchField.mapField')}
                            name={`fieldMappingArray[${index}].mapFieldValue`}
                        />
                    ),
                    cellStyle: {
                        paddingTop: '5px',
                        paddingBottom: '5px'
                    }
                }
            ]
        }
    })
    return (
        <ParentContainer container>
            <Typography variant='h1'>{getValues('name')}</Typography>
            <Grid container direction={'column'}>
                <TypographyContainer item md={12} xs={12} flexGrow={1}>
                    <Typography variant='h3'>
                        {t('learnerList.mapFields')}
                    </Typography>
                    <Typography variant='body2Medium'>
                        {t('learnerList.matchCaption')}
                    </Typography>
                </TypographyContainer>
                <Grid
                    container
                    md={9}
                    xs={12}
                    marginTop={2}
                    sx={{ position: 'relative' }}
                >
                    {loading && <Loader />}
                    <Grid mb={2} width={'100%'}>
                        {transformedErrors.length ? (
                            <ErrorNotification
                                genericError={transformedErrors}
                            ></ErrorNotification>
                        ) : (
                            <></>
                        )}
                    </Grid>
                    {tableComponent}
                </Grid>
            </Grid>
        </ParentContainer>
    )
}
