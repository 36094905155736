import React, { createContext, forwardRef, useState } from 'react'
import { Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import { ResponseStatusEnum } from '@dts/constants'

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})
export interface ToastContextData {
    showToast: (severity?: string, message?: string) => void
}
export interface NotificationProps {
    open: boolean
    severity?: string
    message?: string
}

const defaultValue: ToastContextData = {
    showToast: () => undefined
}
export const ToastContext = createContext(defaultValue)

export const ToastProvider = (props) => {
    const [notification, setNotification] = useState<NotificationProps>({
        open: false,
        severity: '',
        message: ''
    })

    const showToast = (severity: string, message: string) => {
        setNotification({ open: true, severity, message })
    }

    return (
        <ToastContext.Provider value={{ showToast }}>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={notification.open}
                autoHideDuration={
                    notification?.severity === ResponseStatusEnum.Success
                        ? 2000
                        : 6000
                }
                onClose={() =>
                    setNotification((prevNotification: NotificationProps) => ({
                        ...prevNotification,
                        open: false
                    }))
                }
            >
                <Alert severity={notification?.severity} sx={{ width: '100%' }}>
                    {notification?.message}
                </Alert>
            </Snackbar>
            {props.children}
        </ToastContext.Provider>
    )
}
