import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, TrainingFields } from '../../fragments'

export const GET_TRAINING = gql`
  ${TrainingFields('TrainingDto')}
  ${MessagesFragment('TrainingResponseDto')}
  ${MetaFragment('TrainingResponseDto')}
  query getTraining($id: String!) {
    getTraining(id: $id) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...TrainingFields
      }
    }
  }
`
