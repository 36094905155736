import { styled, Box } from '@mui/material'

export const MenuContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme }) => ({
    gap: '8px',
    '&& .Mui-selected': {
        borderLeft: `2px solid ${theme.palette.backgroundBrandPrimary}`,
        backgroundColor: `${theme.palette.BrandPrimaryContainer}`,
        color: `${theme.palette.backgroundPrimary}`
    }
}))
