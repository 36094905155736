export enum VideoStatus {
    Pending = 'PENDING',
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
    Reported = 'REPORTED',
    Draft = 'DRAFT',
    FILTERED_PENDING = 'FILTERED_PENDING',
    UnPublished = 'UNPUBLISHED'
}

export enum TopicsStatus {
    all = 'All',
    archive = 'ARCHIVED'
}

export enum CourseStatus {
    all = 'all',
    archive = 'archived',
    available = 'available'
}

export enum VideoConfigType {
    Upload = 'UPLOAD',
    Retrieve = 'RETRIEVE',
    Generic = 'GENERIC'
}

export enum BrowseType {
    All = 'ALL',
    ByTopic = 'BY_TOPIC',
    ByCreator = 'BY_CREATOR'
}

enum PendingStatusEnum {
    Pending = 'pending',
    Approved = 'approved',
    Escalated = 'escalated'
}

enum ConversationMessageTypeEnum {
    None = 'none',
    Escalate = 'escalate',
    Report = 'report',
    Reject = 'reject'
}

export enum SkillEnum {
    Beginner = 'BEG',
    Advanced = 'ADV',
    All = 'ALV'
}

export enum ReportVideoEnum {
    other = 'OTH'
}
export enum ReportFeedbackTypeEnum {
    REPORT_VIDEO = 'report_video',
    SHOW_LESS = 'show_less'
}
