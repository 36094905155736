import React, { useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { AdminAnalyticsCard } from '@components'
import { Grid } from '@mui/material'
import { InsightArrowDownIcon } from '@assets'
import { CardBodyCount } from './styles'

export const AdminCards = () => {
    const { control } = useFormContext()
    const [selectedCard, setSelectedCard] = useState(1)

    const categoryCounts = useWatch({ name: 'categoryCounts', control })

    const getCardBody = (count: number, index: number) => (
        <Grid
            container
            gap={'3%'}
            sx={{
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <CardBodyCount>{count}</CardBodyCount>
            <InsightArrowDownIcon />
        </Grid>
    )

    return (
        <Grid container>
            {categoryCounts ? (
                Object.values(categoryCounts)?.map((categoryCount, index) => (
                    <Grid item xs={6} md={3} key={index}>
                        <AdminAnalyticsCard
                            key={index}
                            isSelected={index === selectedCard}
                            onClick={() => setSelectedCard(index)}
                            header={categoryCount?.title}
                            content={getCardBody(categoryCount?.count, index)}
                        />
                    </Grid>
                ))
            ) : (
                <></>
            )}
        </Grid>
    )
}
