import React from 'react'
import { SvgProps } from '@types'

const UploadIcon: React.FC<SvgProps> = ({ color }) => (
    <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g id='icons/upload'>
            <path
                id='Icon'
                d='M25.6556 28H7.34448M23.1667 10.6667L16.5 4M16.5 4L9.83337 10.6667M16.5 4V20'
                stroke='#B05722'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
    </svg>
)

export default UploadIcon
