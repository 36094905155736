import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_VIDEO_KPI_BY_ID = gql`
  ${MetaFragment('InsightVideoResponseDto')}
  ${MessagesFragment('InsightVideoResponseDto')}
  query getVideoKPIsById($args: GetInsightVideoKPIsArgumentsDto!) {
    getVideoKPIsById(args: $args) {
      ...MetaFragment
      ...MessagesFragment
      data {
        KPIs {
          averageWatchTime
          learnersReached
          likes
          replays
          saved
          views
          watchTimeInMins
          watchedFullVideo
        }
        video {
          coverImage
          coverImageSrc
          createdAt
          description
          duration
          id
          title
          topicsData {
            id
            title
          }
          creatorData {
            id
            firstName
            lastName
            avatarSrc
            role
          }
          captionFileSrc
          urlSrc
        }
      }
    }
  }
`
