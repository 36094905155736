import React from 'react'
import { LikesIcon, ViewsIcon, WatchTimeIcon } from '@assets'
import {
    InsightPanelId,
    InsightPanelKey,
    InsightTimeline
} from '@dts/client-utils'
import { PLOT_INSIGHT_CHART } from '@dts/graphql'
import { useCustomTheme, useGraphQLWrapper } from '@hooks'

export const getChartData = (
    insightTimeFrameId: string = InsightTimeline.LAST_YEAR,
    insightPanelId: string = InsightPanelId.LIKED
) => {
    const {
        currentData: insightChartCurrent,
        queryLoading: insightChartLoading,
        retrieve: retrieveInsightChart,
        data: insightChartData
    } = useGraphQLWrapper({
        query: PLOT_INSIGHT_CHART,
        queryName: 'plotInsightChart',
        retrieveOnMount: false
    })

    const handleChartData = () => {
        retrieveInsightChart({
            variables: {
                args: {
                    insightTimeFrameId,
                    insightPanelId
                }
            }
        })
    }

    // Recursive function to remove __typename
    function removeTypename(obj) {
        if (Array.isArray(obj)) {
            return obj.map((item) => removeTypename(item))
        } else if (typeof obj === 'object' && obj !== null) {
            return Object.keys(obj).reduce((acc, key) => {
                if (key !== '__typename') {
                    acc[key] = removeTypename(obj[key])
                }
                return acc
            }, {})
        }
        return obj
    }

    return {
        chartData: removeTypename(insightChartCurrent),
        handleChartData,
        retrieveInsightChart,
        insightChartLoading,
        insightChartData
    }
}

export const renderInsightMetricIcon = (key: string, isSelected: boolean) => {
    const { theme } = useCustomTheme()
    return InsightPanelKey.LIKED === key ? (
        <LikesIcon
            color={
                isSelected
                    ? theme.palette.BorderBrandPrimary
                    : theme.palette.borderPrimary
            }
        />
    ) : InsightPanelKey.VIEWED === key ? (
        <ViewsIcon
            color={
                isSelected
                    ? theme.palette.BorderBrandPrimary
                    : theme.palette.borderPrimary
            }
        />
    ) : (
        <WatchTimeIcon
            color={
                isSelected
                    ? theme.palette.BorderBrandPrimary
                    : theme.palette.borderPrimary
            }
        />
    )
}
