import React from 'react'
import { DescriptionIcon, PlayIcon } from '@assets'
import { BrowseByTopics, VideosByTopic } from '@containers'
import { BrowseVideosPage, RouteLayout } from '@pages'
import { ObjectBaseFields } from '@types'
import { getAccessRoles } from '@utils'
import { useCustomTheme } from '@hooks'
import { UserPermissionEnum } from '@dts/constants'

export const getBrowseVideoRoutes = (roles: Array<ObjectBaseFields>) => {
    const accessedRoles = getAccessRoles(roles)
    const { isContentCreator } = accessedRoles
    const { theme } = useCustomTheme()

    return [
        {
            path: '',
            component: RouteLayout,
            canUserAccess: isContentCreator,
            subRoutes: [...getBrowseByTopicRoutes(isContentCreator)],
            permissions: [UserPermissionEnum.VideoBrowse]
        },
        {
            path: 'by-topics',
            component: BrowseByTopics,
            isLanding: 'browse-videos',
            label: 'browseVideos.byTopic',
            isHeaderLink: true,
            canUserAccess: isContentCreator,
            icon: () => <DescriptionIcon color={theme.palette.successMain} />
        },
        {
            path: 'watch',
            component: RouteLayout,
            label: 'general.watch',
            isHeaderLink: true,
            canUserAccess: isContentCreator,
            icon: () => (
                <PlayIcon
                    color={theme.palette.errorDangerMedium}
                    fill={theme.palette.errorDangerMedium}
                />
            ),
            subRoutes: [...getWatchVideoSubroutes(isContentCreator)]
        }
        // This will be used later on
        // {
        //     path: 'creators',
        //     component: () => <BrowseByCreators />,
        //     label: 'sideNavItems.creators',
        //     isHeaderLink: true,
        //     canUserAccess: isContentCreator,
        //     icon: () => (
        //         <UserIcon
        //             color={colors.primaryRust}
        //             fill={colors.primaryRust}
        //         />
        //     )
        // }
    ]
}

export const getWatchVideoSubroutes = (isContentCreator?: boolean) => [
    {
        path: '',
        component: BrowseVideosPage,
        canUserAccess: isContentCreator
    },
    {
        path: ':id',
        component: BrowseVideosPage,
        label: 'general.browse',
        isHeaderLink: false,
        isSideNav: false,
        canUserAccess: isContentCreator
    }
]

export const getBrowseByTopicRoutes = (isContentCreator?: boolean) => [
    {
        path: '',
        component: BrowseByTopics,
        canUserAccess: isContentCreator
    },
    {
        path: ':id',
        component: VideosByTopic,
        componentProps: { showBreadcrumbs: true },
        label: 'sideNavItems.videos',
        isHeaderLink: false,
        isSideNav: false,
        canUserAccess: isContentCreator
    }
]
