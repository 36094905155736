import {
    METRICS_BREAKDOWN,
    REPORT_TIME_FRAME,
    ReportGranularity
} from '@constants'
import {
    DataItem,
    GroupedData,
    MetricData,
    TimeInterval,
    InputData,
    DataPoint,
    GroupedEntry
} from './types'

/**
 *
 * @param data it is a chartData coming from the backend response of plotQuery
 * @returns
 * @param isEpoch it is to check the key of an object is an epoch or not.
 * @returns
 */

export const setBreakdown = (data = []) => {
    const breakdown = {
        age: false,
        interests: false,
        trainings: false,
        course: false,
        survey: false
    }

    data.forEach((item) => {
        if (item.metricId === METRICS_BREAKDOWN.AGE && item.isAdded) {
            breakdown.age = true
        }
        if (item.metricId === METRICS_BREAKDOWN.INTERESTS && item.isAdded) {
            breakdown.interests = true
        }
        if (item.metricId === METRICS_BREAKDOWN.TRAINING && item.isAdded) {
            breakdown.trainings = true
        }
        if (item.metricId === METRICS_BREAKDOWN.COURSE && item.isAdded) {
            breakdown.course = true
        }
        if (item.metricId === METRICS_BREAKDOWN.SURVEY && item.isAdded) {
            breakdown.survey = true
        }
    })

    return breakdown
}

export const isEpoch = (key) => {
    // Check if the key is a string of digits and represents a reasonable epoch time
    if (!/^\d{10,13}$/.test(key)) {
        return false
    }

    // Convert the key to a number
    const timestamp = Number(key)

    // Create a date object with the timestamp
    const date = new Date(timestamp)

    // Check if the date is valid
    return !isNaN(date.getTime())
}

function groupDataForTrainingToAgeToInterests(
    data: InputData,
    trainings: boolean = true,
    age: boolean = true,
    interests: boolean = true
): { [metric: string]: { [group: string]: Array<GroupedEntry> } } {
    const result: {
        [metric: string]: { [group: string]: Array<GroupedEntry> }
    } = {}

    Object.keys(data).forEach((metric) => {
        result[metric] = {}

        data[metric].forEach((dataPoint: DataPoint) => {
            const timestamp = Object.keys(dataPoint).find(
                (key) => !isNaN(Number(key))
            )!
            const value = dataPoint[timestamp] as number

            const trainingKey = trainings ? dataPoint.training : ''
            const ageKey = age ? dataPoint.age : ''
            const interestKey = interests ? dataPoint.interest : ''

            const keys = []

            if (trainingKey) keys.push(trainingKey)
            if (ageKey) keys.push(ageKey)
            if (interestKey) keys.push(interestKey)

            const groupKey = keys.join('/')

            if (!result[metric][groupKey]) {
                result[metric][groupKey] = []
            }

            const entry: GroupedEntry = {}
            entry[timestamp] = value
            result[metric][groupKey].push(entry)
        })
    })

    return result
}

function groupData(
    data: { [metricType: string]: Array<DataItem> },
    keyExtractor: (item: DataItem) => string
): GroupedData {
    const groupedData: GroupedData = {}

    Object.keys(data).forEach((metricType) => {
        groupedData[metricType] = {}

        data[metricType].forEach((item) => {
            const groupKey = keyExtractor(item)
            const epochTime = Object.keys(item).find((key) =>
                /^\d+$/.test(key)
            )!
            const value = item[epochTime] as number

            if (!groupedData[metricType][groupKey]) {
                groupedData[metricType][groupKey] = []
            }

            groupedData[metricType][groupKey].push({ [epochTime]: value })
        })
    })

    return groupedData
}

function checkMinTrue(obj: any, minTrue: number): boolean {
    const trueCount = Object.values(obj).filter(Boolean).length
    return trueCount >= minTrue
}

export const getGroupedData = (
    data = [],
    timeintervals = [],
    reportGranularity = ReportGranularity.DAILY,
    breakdown = {
        age: false,
        trainings: false,
        interests: false
    },
    reportTimeFrame = REPORT_TIME_FRAME.SEVEN_DAYS
) => {
    const { age, interests, trainings } = breakdown
    const groupedData = data.reduce((acc, item) => {
        const {
            reportMetricId,
            time,
            value,
            itemId,
            itemTitle,
            age,
            interest
        } = item
        if (!acc[reportMetricId]) {
            acc[reportMetricId] = []
        }
        acc[reportMetricId].push({
            [time]: Number(value?.toFixed(2)),
            training: itemTitle,
            age: age,
            interest: interest?.title,
            interestId: interest?.id,
            itemId
        })
        return acc
    }, {})
    if (age && !trainings && !interests) {
        const groupedDataByAge = groupData(
            groupedData,
            (item) => item.age as string
        )
        return (
            getSeriesDataWithAgeAndTrainingsBreakdown(
                groupedDataByAge,
                timeintervals,
                reportGranularity,
                data
            ) || []
        )
    } else if (trainings && !age && !interests) {
        const groupedDataByTrainings = groupData(
            groupedData,
            (item) =>
                Object.values(item).find(
                    (value) => typeof value === 'string'
                ) as string
        )

        return (
            getSeriesDataWithAgeAndTrainingsBreakdown(
                groupedDataByTrainings,
                timeintervals,
                reportGranularity,
                data
            ) || []
        )
    } else if (interests && !trainings && !age) {
        const groupedDataByInterests = groupData(
            groupedData,
            (item) => item.interest as string
        )

        return (
            getSeriesDataWithAgeAndTrainingsBreakdown(
                groupedDataByInterests,
                timeintervals,
                reportGranularity,
                data
            ) || []
        )
    } else if (checkMinTrue(breakdown, 2)) {
        const grouped = groupDataForTrainingToAgeToInterests(
            groupedData,
            trainings,
            age,
            interests
        )

        return (
            getSeriesDataWithAgeAndTrainingsBreakdown(
                grouped,
                timeintervals,
                reportGranularity,
                data
            ) || []
        )
    } else {
        return (
            getSeriesDataWithoutBreakdown(
                groupedData,
                timeintervals,
                reportGranularity,
                data,
                reportTimeFrame
            ) || []
        )
    }
}

function isEpochMatching(epoch1, epoch2) {
    // Convert milliseconds epoch to seconds and extract only the day
    const dayFromEpoch1 = new Date(epoch1).toISOString().split('T')[0] // Extracting only the date part
    const dayFromEpoch2 = new Date(epoch2 * 1000).toISOString().split('T')[0] // Convert seconds to milliseconds

    // Check if days are the same
    const isSameDay = dayFromEpoch1 === dayFromEpoch2

    return isSameDay
}

function isEpochInWeek(epoch1: number, weekEpoch: number): boolean {
    // Convert epoch to date and get the start and end of the week
    const startOfWeek = new Date(weekEpoch * 1000) // Start of the week (weekEpoch is in seconds)
    const endOfWeek = new Date(startOfWeek)
    endOfWeek.setUTCDate(startOfWeek.getUTCDate() + 6) // End of the week

    // Check if epoch1 is within this week
    const dateToCheck = new Date(epoch1)

    const isInWeek = dateToCheck >= startOfWeek && dateToCheck <= endOfWeek

    return isInWeek
}

function isEpochInMonth(epoch1, monthEpoch) {
    const dateToCheck = new Date(epoch1)
    const startOfMonth = new Date(monthEpoch * 1000)
    startOfMonth.setUTCDate(1) // Set to the first day of the month
    startOfMonth.setUTCHours(0, 0, 0, 0) // Reset time to midnight
    const endOfMonth = new Date(startOfMonth)
    endOfMonth.setUTCMonth(startOfMonth.getUTCMonth() + 1) // Move to the next month
    endOfMonth.setUTCDate(0) // Set to the last day of the current month
    endOfMonth.setUTCHours(23, 59, 59, 999) // Set time to the end of the day

    const isInMonth = dateToCheck >= startOfMonth && dateToCheck <= endOfMonth

    return isInMonth
}

function isEpochInHour(epoch1, hourEpoch) {
    // Convert both epochs to Date objects
    const dateToCheck = new Date(epoch1)
    const hourDate = new Date(hourEpoch)

    // Get the hour component of the epoch to check
    const epochHour = dateToCheck.getUTCHours()

    // Check if the epoch's hour matches the target hour
    const isInHour = epochHour === hourDate.getUTCHours()

    return isInHour
}

export const getSeriesDataWithoutBreakdown = (
    groupedData = {},
    timeintervals = [],
    reportGranularity = ReportGranularity.DAILY,
    data = [],
    reportTimeFrame = REPORT_TIME_FRAME.SEVEN_DAYS
) => {
    const transformedData = Object.keys(groupedData).map((metricId) => {
        const dataValues = timeintervals.map((timeInterval: any) => {
            const matchingObject = groupedData[metricId].find((item) => {
                const epochKey = Object.keys(item).find((key) =>
                    /^\d+$/.test(key)
                )
                if (epochKey) {
                    const epochKeyNumber = Number(epochKey)
                    if (reportGranularity === ReportGranularity.DAILY) {
                        return isEpochMatching(
                            epochKeyNumber,
                            timeInterval.epoch
                        )
                    } else if (reportGranularity === ReportGranularity.WEEKLY) {
                        return isEpochInWeek(epochKeyNumber, timeInterval.epoch)
                    } else if (
                        reportGranularity === ReportGranularity.MONTHLY
                    ) {
                        return isEpochInMonth(
                            epochKeyNumber,
                            timeInterval.epoch
                        )
                    } else if (reportGranularity === ReportGranularity.HOURLY) {
                        return isEpochInHour(epochKeyNumber, timeInterval.epoch)
                    }
                }
                return false
            })

            return matchingObject
                ? matchingObject[
                      Object.keys(matchingObject).find((key) =>
                          /^\d+$/.test(key)
                      )
                  ]
                : 0
        })

        return {
            name: getMetricNameById(data, metricId) || metricId,
            data:
                reportTimeFrame === REPORT_TIME_FRAME.TODAY &&
                reportGranularity === ReportGranularity.DAILY
                    ? [0, dataValues]
                    : dataValues
        }
    })

    return transformedData
}

function aggregateValuesInGroup(
    group: Array<{ [epochTime: string]: number }>,
    timeInterval: TimeInterval,
    reportGranularity: ReportGranularity
): number {
    let aggregatedValue = 0
    group.forEach((item) => {
        const epochKey = Object.keys(item).find((key) => /^\d+$/.test(key))
        if (epochKey) {
            const epochKeyNumber = Number(epochKey)
            if (
                (reportGranularity === ReportGranularity.DAILY &&
                    isEpochMatching(epochKeyNumber, timeInterval.epoch)) ||
                (reportGranularity === ReportGranularity.WEEKLY &&
                    isEpochInWeek(epochKeyNumber, timeInterval.epoch)) ||
                (reportGranularity === ReportGranularity.MONTHLY &&
                    isEpochInMonth(epochKeyNumber, timeInterval.epoch))
            ) {
                aggregatedValue += item[epochKey]
            } else if (
                reportGranularity === ReportGranularity.HOURLY &&
                isEpochInHour(epochKeyNumber, timeInterval.epoch)
            ) {
                aggregatedValue = item[epochKey]
            }
        }
    })
    return aggregatedValue
}

function generateSeriesForGroup(
    metricId: string,
    groupKey: string,
    group: Array<{ [epochTime: string]: number }>,
    timeIntervals: Array<TimeInterval>,
    reportGranularity: ReportGranularity,
    data: Array<MetricData>
): { name: string; data: Array<number> } {
    const dataValues = timeIntervals.map((timeInterval) =>
        aggregateValuesInGroup(group, timeInterval, reportGranularity)
    )
    return {
        name: `${getMetricNameById(data, metricId) || metricId} (${groupKey})`,
        data: dataValues
    }
}

export const getSeriesDataWithAgeAndTrainingsBreakdown = (
    groupedData: {
        [metricType: string]: {
            [groupKey: string]: Array<{ [epochTime: string]: number }>
        }
    } = {},
    timeIntervals: Array<TimeInterval> = [],
    reportGranularity: ReportGranularity = ReportGranularity.DAILY,
    data: Array<MetricData> = []
) => {
    const transformedData = Object.keys(groupedData).flatMap((metricId) => {
        const groups = groupedData[metricId]
        return Object.keys(groups).flatMap((groupKey) =>
            generateSeriesForGroup(
                metricId,
                groupKey,
                groups[groupKey],
                timeIntervals,
                reportGranularity,
                data
            )
        )
    })
    return transformedData
}

function getMetricNameById(data, reportMetricId: string) {
    const metric = data.find((item) => item.reportMetricId === reportMetricId)
    return metric ? metric?.reportMetricTitle : null
}
