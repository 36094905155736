import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDirectory, useModalWithGraphQL, useVideoConfig } from '@hooks'
import { StyledLink, TextWithTooltip } from '@components'
import { PlayIcon, VideoIcon } from '@assets'
import { GET_DASHBOARD_VIDEOS } from '@dts/graphql'
import { formatDate } from '@dts/client-utils'
import { VideoConfigType, VideoStatus } from '@constants'
import { getCoverImageRenderCell } from '@containers/content-creator'
import { StyledDirectoryLink } from '@containers/styles'

export const DashboardVideos = () => {
    const { t } = useTranslation()

    const { videoConfig } = useVideoConfig({
        videoConfigType: VideoConfigType.Retrieve
    })

    const { openPrepopulatedDialog: openDetailedVideo } =
        useModalWithGraphQL(videoConfig)

    const { directory } = useDirectory({
        directoryConfig: {
            id: 'dashboard',
            cardDirectoryConfig: {
                getCardAction: (params) => ({
                    icon: PlayIcon,
                    label: '122k'
                }),
                getCardConfig: (data) =>
                    data?.map((item) => ({
                        ...item,
                        onClick: () => {
                            openDetailedVideo({
                                id: item?.id
                            })
                        }
                    }))
            },
            config: {
                customActions: [
                    {
                        icon: <VideoIcon width='18' height='18' />,
                        label: t('general.viewDetails'),
                        action: (params) => {
                            // on click
                            openDetailedVideo({ id: params?.id })
                        },
                        showInMenu: true,
                        hide: (params) =>
                            params?.row?.status ===
                            VideoStatus.Rejected.toLowerCase()
                    }
                ],
                columns: [
                    {
                        field: 'coverImageSrc',
                        headerName: t('general.video'),
                        maxWidth: 120,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={getCoverImageRenderCell(params, () =>
                                    openDetailedVideo({
                                        id: params?.id
                                    })
                                )}
                                toolTip={params.row.title}
                                tabIndex={params.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'title',
                        headerName: t('general.title'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={params.value}
                                isDirectoryLink={true}
                                onClick={() =>
                                    openDetailedVideo({
                                        id: params?.id
                                    })
                                }
                                toolTip={params.value}
                                tabIndex={params.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'vertical',
                        headerName: t('general.vertical'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={'--'}
                                tabIndex={params.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'views',
                        headerName: t('general.views'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={'--'}
                                tabIndex={params.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'topicsData',
                        headerName: `${t('general.numberOf')} ${t(
                            'general.topics'
                        )}`,
                        renderCell: ({ value, tabIndex }) => (
                            <TextWithTooltip
                                renderer={value?.length}
                                tabIndex={tabIndex}
                            />
                        )
                    },
                    {
                        field: 'createdAt',
                        headerName: t('general.datePublished'),

                        renderCell: ({ row, tabIndex }) => (
                            <TextWithTooltip
                                renderer={formatDate(row?.publishedAt)}
                                tabIndex={tabIndex}
                            />
                        )
                    }
                ],
                hideFooter: false,
                pagination: true
            },
            enableSearch: true,
            toolbarConfig: {
                header: t('videos.directory.approved.header'),
                caption: t('videos.directory.approved.caption')
            },
            query: GET_DASHBOARD_VIDEOS,
            queryName: 'getDashboardVideos',
            shouldShowLoader: false
        }
    })

    return directory
}
