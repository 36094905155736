import React, {
    createContext,
    useEffect,
    useState,
    Dispatch,
    SetStateAction
} from 'react'
import { useLocation } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import { useMediaQuery } from '@mui/material'

type GlobalContextData = {
    navigationHistory: Array<string>
    isMediumScreen: boolean
    isInsideLayout: boolean
    setIsInsideLayout: Dispatch<SetStateAction<boolean>>
}

export const GlobalContext = createContext<GlobalContextData>({
    navigationHistory: [],
    isMediumScreen: false,
    isInsideLayout: false,
    setIsInsideLayout: () => undefined
})

export const GlobalProvider = ({ children }) => {
    const [navigationHistory, setNavigationHistory] = useState<Array<string>>(
        []
    )
    const [isInsideLayout, setIsInsideLayout] = useState<boolean>(false)
    const location = useLocation()

    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )

    useEffect(() => {
        const navigations = cloneDeep(navigationHistory)
        if (!navigations.includes(location?.pathname)) {
            navigations.push(location.pathname)
            setNavigationHistory(navigations)
        }
    }, [location])

    return (
        <GlobalContext.Provider
            value={{
                navigationHistory,
                isInsideLayout,
                setIsInsideLayout,
                isMediumScreen
            }}
        >
            {children}
        </GlobalContext.Provider>
    )
}
