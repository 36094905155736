import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { Grid, IconButton, InputAdornment } from '@mui/material'
import {
    useCustomTheme,
    useFormFeedback,
    useGraphQLWrapper,
    usePage
} from '@hooks'
import {
    ControlledCheckBox,
    ControlledSelect,
    ControlledTextInput,
    Input
} from '@components'

import {
    AVAILABLE_FILTERS,
    HelperTextPosition,
    REPORT_FILTER_TYPE
} from '@constants'
import { CloseIcon, SearchIcon } from '@assets'
import { isEmpty } from 'lodash'
import { GET_LEARNER_LISTS, GET_SKILLS } from '@dts/graphql'
import { mapDropdownOptions } from '@dts/client-utils'
import { GET_FILTER_DATA } from '@dts/graphql/lib'
import { useLazyQuery } from '@apollo/client'
import { StyledErrorMessage } from '@components/FieldErrors/styles'
import { SearchableMetricsListType } from './types'

export const SearchableMetricsList = ({
    name,
    listType,
    maxHeight = '150px',
    error
}: SearchableMetricsListType) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const [searchText, setSearchText] = useState<string>('')
    const [selectedFilter, setSelectedFilter] = useState<string>('')
    const [preOpenMenu, setPreOpenMenu] = useState<string>('')
    const [queryResult, setQueryResult] = useState([] | null)
    const { control, getValues, setValue } = useFormContext()

    const { fields, update } = useFieldArray({
        control,
        name: name
    })

    const metricData = useWatch({
        control,
        name: name
    })

    const filters = useWatch({
        control,
        name: 'filters'
    })

    const {
        currentData: dropDownData,
        queryLoading,
        retrieve
    } = useGraphQLWrapper({
        query: GET_FILTER_DATA,
        queryName: 'getFilterData',
        onQuerySuccess: async (result) => {
            setQueryResult(result?.data)
        },
        retrieveOnMount: false,
        shouldHideLoader: true
    })

    useEffect(() => {
        if (queryResult && !queryLoading) {
            const selectedItem = metricData?.find(
                (item) => item.metricId === selectedFilter
            )
            if (selectedItem) {
                selectedItem.dropdownData = dropDownData
                setValue(name, metricData)
            } else {
                console.error(
                    `No item found for selectedFilter: ${selectedFilter}`
                )
            }
        }
    }, [queryResult, queryLoading])

    useEffect(() => {
        if (!isEmpty(selectedFilter) && selectedFilter) {
            retrieve?.({
                variables: {
                    args: { filter: selectedFilter }
                }
            })
        }
    }, [selectedFilter])

    useEffect(() => {
        if (!isEmpty(filters) && filters) {
            filters?.map((item) => {
                item?.isAdded && setSelectedFilter(item?.metricId)
            })
        }
    }, [filters])

    return (
        <>
            <Grid item>
                <Input
                    placeholder={[t('general.search'), listType].join(' ')}
                    value={searchText}
                    onChange={(e) => {
                        setSearchText(e)
                    }}
                    height='40px'
                    ariaLabel={[t('general.search'), listType]?.join(' ')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon height={16} width={16} />
                            </InputAdornment>
                        ),
                        endAdornment: searchText && (
                            <InputAdornment position='end'>
                                <IconButton
                                    onClick={() => {
                                        setSearchText('')
                                    }}
                                    aria-label={t('general.clear')}
                                >
                                    <CloseIcon height={16} width={16} />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
            {error && (
                <Grid item>
                    <StyledErrorMessage>{error}</StyledErrorMessage>
                </Grid>
            )}
            <Grid item maxHeight={maxHeight} sx={{ overflow: 'auto' }}>
                {metricData?.map?.((dataItem, index) => {
                    const showRow =
                        searchText && !isEmpty(searchText)
                            ? dataItem?.title
                                  ?.toLowerCase()
                                  .includes(searchText?.toLowerCase())
                            : true
                    const filterMetricAdded =
                        dataItem?.filterTypeId ===
                            REPORT_FILTER_TYPE.CATEGORICAL && dataItem?.isAdded
                    return showRow ? (
                        <Grid
                            key={dataItem?.title + index}
                            container
                            direction={'column'}
                            xs={11.5}
                            mr={0.5}
                            sx={{
                                '&:hover': {
                                    backgroundColor:
                                        theme.palette.BrandPrimaryContainer
                                },
                                ...(filterMetricAdded && {
                                    border: `2px solid ${theme.palette.borderSecondary}`,
                                    borderLeft: `2px solid ${theme.palette.contentLink}`,
                                    marginBottom: '8px'
                                }),
                                borderRadius: '8px'
                            }}
                        >
                            <Grid item>
                                <Grid
                                    container
                                    display={'flex'}
                                    alignItems={'center'}
                                    px={1}
                                    sx={{
                                        '&:hover': (dataItem?.filterTypeId !==
                                            REPORT_FILTER_TYPE.CATEGORICAL ||
                                            !dataItem.isAdded) && {
                                            borderLeft: `2px solid ${theme.palette.contentLink}`
                                        },
                                        color: `${theme.palette.contentPrimary} !important`,
                                        flexWrap: 'nowrap'
                                    }}
                                >
                                    <Grid item>
                                        <ControlledCheckBox
                                            id={dataItem?.label}
                                            name={`${name}[${index}].isAdded`}
                                            ariaLabel={dataItem?.title}
                                            onClick={() => {
                                                if (!dataItem.isAdded) {
                                                    dataItem.preOpenMenu = true
                                                }
                                                dataItem.isAdded =
                                                    !dataItem?.isAdded
                                                update(index, dataItem)

                                                if (
                                                    dataItem?.filterTypeId ===
                                                        REPORT_FILTER_TYPE.CATEGORICAL &&
                                                    dataItem.metricId !==
                                                        AVAILABLE_FILTERS.Creator
                                                ) {
                                                    setSelectedFilter(
                                                        dataItem.metricId
                                                    )
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid item>{dataItem?.title}</Grid>
                                </Grid>
                            </Grid>

                            {filterMetricAdded && dataItem?.dropdownData && (
                                <Grid item px={2} pb={1} xs={11.5}>
                                    <ControlledSelect
                                        id={`${name}[${index}].metricId`}
                                        name={`${name}[${index}].selectedData`}
                                        isMulti
                                        hasChips={false}
                                        options={mapDropdownOptions(
                                            dataItem?.dropdownData
                                        )}
                                        // todo: fix preopenMenu logic
                                        // preOpenMenu={dataItem.preOpenMenu}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                paddingTop: '5px !important',
                                                paddingBottom: '5px !important'
                                            }
                                        }}
                                        marginTop={0}
                                        width={'100%'}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    ) : null
                })}
            </Grid>
        </>
    )
}
