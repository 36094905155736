import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGraphQLWrapper, useLoader, usePageHeader } from '@hooks'

import { GET_LEARNER_RESOURCES } from '@dts/graphql'
import { CustomLoader } from '@components'
import { Grid } from '@mui/material'
import { ResourceDisplay } from './resource-cards'

export const ResourcesAndServices = () => {
    const { t } = useTranslation()
    const [resources, setResources] = useState([])
    const { loading } = useLoader()

    const {
        data: data,
        currentData,
        queryLoading
    } = useGraphQLWrapper({
        query: GET_LEARNER_RESOURCES,
        queryName: 'getLearnerResources',
        retrieveOnMount: true
    })

    useEffect(() => {
        currentData && setResources(currentData)
    }, [data, queryLoading])

    const { pageHeader } = usePageHeader({
        headerText: t('resource.heading')
    })

    return (
        <>
            {loading ? (
                <Grid container xs={12} justifyContent={'center'} mt={4}>
                    <CustomLoader />
                </Grid>
            ) : (
                <>
                    {pageHeader}
                    <ResourceDisplay data={resources} />
                </>
            )}
        </>
    )
}
