import styled from '@emotion/styled'
import { Grid } from '@mui/material'

interface LoaderWrapperProps {
    height?: string
}

export const StyledLoader = styled.span`
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: black;
`
export const BackgroundGrid = styled(Grid)(({ theme }) => ({
    backdropFilter: 'blur(4px)',
    background: theme.palette.loaderBackDrop,
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 999
}))

export const LoaderGrid = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
    animation: slide-in 0.15s ease-in;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000;
`

export const LoaderWrapper = styled(Grid, {
    shouldForwardProp: (propName) => propName !== 'height'
})<LoaderWrapperProps>`
    position: absolute;
    top: 0;
    left: 0;
    height: ${({ height }) => height || '100%'};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`
