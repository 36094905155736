import { gql } from '@apollo/client'
import { MessagesFragment } from '../../fragments'

export const DUPLICATE_ARTICLE = gql`
  ${MessagesFragment('ArticleResponseDto')}
  mutation duplicateArticle($input: DuplicateArticleInputDto!) {
    duplicateArticle(input: $input) {
      ...MessagesFragment
      data {
        id
      }
    }
  }
`
