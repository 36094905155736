import { FormControlLabel, Grid, RadioGroup, styled } from '@mui/material'

export const StyledRadioGroup = styled(RadioGroup)(
    ({ isButton, hasColumnDirection, gap }) =>
        !isButton && {
            flexDirection: hasColumnDirection ? 'column' : 'row',
            gap: gap ?? gap
        }
)

export const StyledFormControlLabel = styled(FormControlLabel)(
    ({ isButton, isSelected, theme, hasStartLabelPlacement }) => ({
        ...(hasStartLabelPlacement && {
            display: 'flex',
            justifyContent: 'space-between'
        }),
        ...(isButton && {
            // Styles dependent on isButton
            background: isSelected
                ? theme.palette.backgroundInfoSubtle
                : 'none',
            borderLeft: isSelected
                ? `2px solid ${theme.palette.contentLink}`
                : 'none',
            padding: !hasStartLabelPlacement ? '5px 0px' : '5px 20px',
            margin: 0
        })
    })
)

export const RadioCardGrid = styled(Grid)(({ theme }) => ({
    boxShadow: '1px',
    borderRadius: '15px',
    padding: '15px 5px 40px 5px',
    margin: '10px',
    display: 'flex',
    minWidth: '250px',
    justifyContent: 'space-between'
}))
