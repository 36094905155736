import React from 'react'
import { SvgProps } from '@types'

const PendingIcon: React.FC<SvgProps> = ({
    color = '#C57629',
    width = 20,
    height = 21
}) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M7.85714 14.975H12.1429M6.14286 2.60004H13.8571C14.2572 2.60004 14.4572 2.60004 14.61 2.68178C14.7444 2.75369 14.8537 2.86842 14.9221 3.00954C15 3.16998 15 3.38 15 3.80004V5.35592C15 5.72281 15 5.90625 14.9605 6.07888C14.9255 6.23194 14.8678 6.37826 14.7895 6.51246C14.7011 6.66384 14.5776 6.79355 14.3305 7.05298L12.2367 9.25151C11.9538 9.54852 11.8124 9.69703 11.7594 9.86827C11.7128 10.0189 11.7128 10.1812 11.7594 10.3318C11.8124 10.503 11.9538 10.6516 12.2367 10.9486L14.3305 13.1471C14.5776 13.4065 14.7011 13.5362 14.7895 13.6876C14.8678 13.8218 14.9255 13.9681 14.9605 14.1212C15 14.2938 15 14.4773 15 14.8441V16.4C15 16.8201 15 17.0301 14.9221 17.1905C14.8537 17.3317 14.7444 17.4464 14.61 17.5183C14.4572 17.6 14.2572 17.6 13.8571 17.6H6.14286C5.74282 17.6 5.5428 17.6 5.39001 17.5183C5.25561 17.4464 5.14633 17.3317 5.07785 17.1905C5 17.0301 5 16.8201 5 16.4V14.8441C5 14.4773 5 14.2938 5.03947 14.1212C5.07447 13.9681 5.13219 13.8218 5.21051 13.6876C5.29886 13.5362 5.4224 13.4065 5.66947 13.1471L7.76331 10.9486C8.04618 10.6516 8.18761 10.503 8.2406 10.3318C8.28721 10.1812 8.28721 10.0189 8.2406 9.86827C8.18761 9.69703 8.04617 9.54852 7.76331 9.25151L5.66947 7.05298C5.4224 6.79355 5.29886 6.66384 5.21051 6.51246C5.13219 6.37826 5.07447 6.23194 5.03947 6.07888C5 5.90625 5 5.72281 5 5.35592V3.80004C5 3.38 5 3.16998 5.07785 3.00954C5.14633 2.86842 5.25561 2.75369 5.39001 2.68178C5.5428 2.60004 5.74282 2.60004 6.14286 2.60004Z'
            stroke={color}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
)

export default PendingIcon
