enum ReportVideoOptionEnum {
  HarmAbuse = 'HAV',
  Misinformation = 'MMD',
  MyProperty = 'MIP',
  PrivacyViolation = 'PRV',
  Other = 'OTH',
}

enum ReportVideoOptionGraphqlEnum {
  'HAV' = 'HAV',
  'MMD' = 'MMD',
  'MIP' = 'MIP',
  'PRV' = 'PRV',
  'OTH' = 'OTH',
}

export { ReportVideoOptionEnum, ReportVideoOptionGraphqlEnum }
