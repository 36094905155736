import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const DoubleArrowIcon: React.FC<SvgProps> = ({
    width = 24,
    height = 24,
    color
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.backgroundBrandPrimary
    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M6 18L12 12L6 6'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12 18L18 12L12 6'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default DoubleArrowIcon
