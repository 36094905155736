import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { VideoPlayer } from '@components'

import {
    Avatar,
    Button,
    Grid,
    Link,
    Typography,
    useMediaQuery
} from '@mui/material'
import { setUserData } from '@cacheql'
import { StyledDirectoryLink } from '@containers'
import { useCustomTheme } from '@hooks'
import { onKeyDown } from '@utils'
import {
    StyledDescriptionGrid,
    StyledHyperLinkTypography,
    VideoMetadataContainer
} from './styles'
import { VideoWebViewProps } from './types'

export const VideoWebView: React.FC<VideoWebViewProps> = ({
    video,
    config,
    index,
    accessRoles,
    isLearnerBrowse,
    surveyContextData
}) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const navigate = useNavigate()

    const { accountInteractions, menuConfig, onFollow, actionIcons } = config

    const isIntermediateScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('1460')
    )

    const isNotCurrentUser = video?.creatorData?.id !== setUserData()?.id

    const goToCreatorProfile = () =>
        navigate(`/creator-profile/${video?.creatorData?.id}/all-video`)

    return (
        <Grid
            item
            py={3}
            display={'flex'}
            flexDirection={'row'}
            key={video?.id}
            sx={{ height: '80vh' }}
            gap={1}
        >
            <Grid item width={'78%'} mb={1.5}>
                <VideoPlayer
                    value={video?.urlSrc}
                    captionFileSrc={video?.captionFileSrc}
                    menuConfig={menuConfig}
                    actionIcons={isNotCurrentUser && actionIcons}
                    hasMargin
                    videoId={video?.id}
                    showArrowIcons={true}
                    index={index}
                    handleWatchTime={accessRoles?.isLearner}
                    disableBar={accessRoles?.isLearner}
                    isLearnerBrowse={isLearnerBrowse}
                    surveyContextData={surveyContextData}
                />
            </Grid>

            <VideoMetadataContainer item py={3}>
                <Grid
                    container
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                    pl={2}
                >
                    <Grid
                        item
                        display={'flex'}
                        flexDirection={isIntermediateScreen ? 'column' : 'row'}
                        gap={1}
                        alignSelf={isIntermediateScreen && 'center'}
                    >
                        <Grid
                            item
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            <Avatar
                                sx={{
                                    border: `1px solid ${theme.palette.borderGrey}`,
                                    '&:hover': {
                                        cursor: 'pointer'
                                    }
                                }}
                                src={video?.creatorData?.avatarSrc}
                                alt=''
                                onClick={() => goToCreatorProfile()}
                            />
                        </Grid>

                        <Grid
                            item
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            <StyledDirectoryLink
                                sx={{
                                    fontWeight: '500',
                                    paddingLeft: '2%'
                                }}
                                variant='body2'
                                tabIndex={0}
                                onKeyDown={(e) =>
                                    onKeyDown(e, goToCreatorProfile, false)
                                }
                                onClick={() => goToCreatorProfile()}
                            >
                                {video?.creatorData?.firstName}
                            </StyledDirectoryLink>
                        </Grid>
                        <Grid
                            item
                            display={'flex'}
                            ml={isIntermediateScreen ? 0 : 1}
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            {isNotCurrentUser && (
                                <Button
                                    onClick={() => {
                                        onFollow()
                                    }}
                                    sx={{ padding: '0px', alignSelf: 'center' }}
                                >
                                    {accountInteractions?.following
                                        ? t('setting.following')
                                        : t('general.follow')}
                                </Button>
                            )}
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Typography variant='h3'>{video?.title}</Typography>
                    </Grid>
                    <StyledDescriptionGrid xs={12} md={12} item>
                        <Typography
                            variant='body2'
                            sx={{ color: theme.palette.contentPrimary }}
                        >
                            {video?.description}
                        </Typography>
                    </StyledDescriptionGrid>
                    {video?.hyperlinks?.map((hyperlink, index) => (
                        <Grid item key={index} xs={12} md={12}>
                            <Link
                                href={hyperlink}
                                underline='hover'
                                target='_blank'
                                sx={{
                                    display: 'flex',
                                    alignSelf: 'center'
                                }}
                            >
                                <StyledHyperLinkTypography variant='body2'>
                                    {`${hyperlink},`}
                                </StyledHyperLinkTypography>
                            </Link>
                        </Grid>
                    ))}
                    <Grid
                        item
                        sx={{
                            maxHeight: '200px'
                        }}
                    >
                        {video?.tags?.map((tag, index) => (
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    color: theme.palette.contentLink
                                }}
                                variant='body2'
                                key={index}
                            >
                                {`#${tag}, `}
                            </Typography>
                        ))}
                    </Grid>
                </Grid>
            </VideoMetadataContainer>
        </Grid>
    )
}
