import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const BoldCrossIcon: React.FC<SvgProps> = ({
    color,
    height = '18',
    width = '18'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 22 22'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g id='icons/x-cross'>
                <path
                    id='Icon'
                    d='M16.5 5.5L5.5 16.5M5.5 5.5L16.5 16.5'
                    stroke={_color}
                    strokeWidth='3'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
        </svg>
    )
}
export default BoldCrossIcon
