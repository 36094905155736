import ReactQuill from 'react-quill'
import { Box, styled } from '@mui/material'

export const StyledContainer = styled(Box)(({ height }) => ({
    '.ql-editor': {
        height: `${height ?? '70vh'}`
    }
}))

export const StyledReactQuill = styled(ReactQuill)`
    > .ql-container {
        min-height: 370px;
    }
    > .ql-container,
    .ql-snow {
        .ql-tooltip,
        .ql-editing {
            left: 0px !important;
        }
    }
`

const StyledBox = styled(Box)(({ theme }) => ({
    position: 'relative',
    background: theme.palette.backgroundPrimary,
    '& p': {
        color: `${theme.palette.contentPrimary} !important`
    },
    '& h1, & h2, & h3, & h4, & h5, & h6': {
        color: `${theme.palette.contentPrimary} !important`
    },
    '& .ql-picker-label': {
        color: `${theme.palette.contentLink} !important`
    },
    '& .ql-snow .ql-stroke': {
        stroke: `${theme.palette.contentLink} !important`
    },
    '& .ql-snow .ql-fill': {
        fill: `${theme.palette.contentLink} !important`
    },
    '& .ql-snow a': {
        color: `${theme.palette.contentLink} !important`
    },
    '& svg': {
        fill: `${theme.palette.contentLink} !important`,
        stroke: `${theme.palette.contentLink} !important`
    },
    '& .ql-editor.ql-blank::before': {
        color: `${theme.palette.contentPrimary} !important` // Ensure you have this color defined in your theme
    }
}))

export default StyledBox
