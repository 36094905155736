enum ResponseIconEnum {
    Checkmark = 'CHM',
    Tick = 'TIC',
    PartyPopper = 'PRP',
    CrossMark = 'CRM',
    ExclamationMark = 'EXM',
    ProhibitedSign = 'PRS',
    InformationSign = 'IFS',
    WarningSign = 'WRS',
    MagnifyingGlass = 'MFG',
    QuestionMark = 'QUM',
    Bullseye = 'BLE',
    Star = 'STA',
    Trophy = 'TRO',
    BrokenHeart = 'BRH',
    ThumbsUp = 'THU',
    ThumbsDown = 'THD',
    GreenCircle = 'GRC',
    RedCircle = 'REC',
    OrangeCircle = 'ORC',
}

enum ResponseIconGraphqlEnum {
    'CHM' = 'CHM',
    'TIC' = 'TIC',
    'PRP' = 'PRP',
    'CRM' = 'CRM',
    'EXM' = 'EXM',
    'PRS' = 'PRS',
    'IFS' = 'IFS',
    'WRS' = 'WRS',
    'MFG' = 'MFG',
    'QUM' = 'QUM',
    'BLE' = 'BLE',
    'STA' = 'STA',
    'TRO' = 'TRO',
    'BRH' = 'BRH',
    'THU' = 'THU',
    'THD' = 'THD',
    'GRC' = 'GRC',
    'REC' = 'REC',
    'ORC' = 'ORC',
}

export { ResponseIconEnum, ResponseIconGraphqlEnum }
