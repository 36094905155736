import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../../fragments'

export const GET_LEARNER_LISTS = gql`
  ${MetaFragment('LearnerListsResponse')}
  ${MessagesFragment('LearnerListsResponse')}
  query {
    getLearnerLists {
      ...MetaFragment
      ...MessagesFragment
      data {
        id
        title
        description
        learnersCount
        updatedAt
        isArchived
        learners {
          id
          tags
          firstName
          lastName
        }
        file
      }
    }
  }
`
