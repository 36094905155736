import { gql } from '@apollo/client'

export const ON_NEW_NOTIFICATION = gql`
  subscription onNewNotification {
    onNewNotification {
      userId
      id
      body {
        title
        message
        referenceId
      }
      notificationCategoryId
      isSeen
      isRead
      sentAt
    }
  }
`
