import {
    ArchiveIcon,
    CategoryIcon,
    CheckCircleIcon,
    TemplateIcon
} from '@assets'
import { VerticalsStatus } from '@constants'
import {
    BrowseVerticalsContainer,
    CreateVertical,
    EditCoursesContainer
} from '@containers'
import { UserPermissionEnum } from '@dts/constants'
import { RouteLayout } from '@pages'
import { ObjectBaseFields } from '@types'
import { getAccessRoles } from '@utils'

export const getVerticalRoutes = (roles: Array<ObjectBaseFields>) => {
    const accessedRoles = getAccessRoles(roles)
    const { isAdmin, isDefaultTenantUser } = accessedRoles
    return [
        {
            path: '',
            component: BrowseVerticalsContainer,
            componentProps: { status: VerticalsStatus.All },
            isHeaderLink: false,
            canUserAccess: isAdmin || isDefaultTenantUser,
            icon: CheckCircleIcon,
            permissions: [UserPermissionEnum.VerticalView]
        },
        {
            path: 'all',
            component: BrowseVerticalsContainer,
            componentProps: { status: VerticalsStatus.All },
            label: 'general.all',
            isLanding: 'verticals',
            isHeaderLink: true,
            canUserAccess: isAdmin || isDefaultTenantUser,
            icon: CheckCircleIcon,
            permissions: [UserPermissionEnum.VerticalView]
        },
        {
            path: 'templates',
            component: BrowseVerticalsContainer,
            componentProps: { status: VerticalsStatus.Templates },
            label: 'general.templates',
            isHeaderLink: true,
            canUserAccess: isAdmin || isDefaultTenantUser,
            icon: TemplateIcon,
            permissions: [UserPermissionEnum.VerticalView]
        },
        {
            path: 'archived',
            component: BrowseVerticalsContainer,
            componentProps: { status: VerticalsStatus.Archived },
            label: 'general.archived',
            isHeaderLink: true,
            canUserAccess: isAdmin || isDefaultTenantUser,
            icon: ArchiveIcon,
            permissions: [UserPermissionEnum.VerticalView]
        },
        {
            path: 'new',
            component: CreateVertical,
            isHeaderLink: false,
            isSideNav: false,
            canUserAccess: isAdmin || isDefaultTenantUser,
            hidePageHeader: true
        },
        {
            path: ':id',
            component: RouteLayout,
            isHeaderLink: false,
            isSideNav: false,
            hidePageHeader: true,
            canUserAccess: isAdmin || isDefaultTenantUser,
            subRoutes: [...getVerticalSubRoutes(isAdmin || isDefaultTenantUser)]
        }
    ]
}

export const getVerticalSubRoutes = (isAdmin?: boolean) => [
    {
        path: '',
        component: CreateVertical,
        canUserAccess: isAdmin
    },
    {
        path: 'edit-courses',
        component: EditCoursesContainer,
        isHeaderLink: false,
        isSideNav: false,
        canUserAccess: isAdmin
    }
]
