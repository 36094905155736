import React, { useEffect, useRef } from 'react'

import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCustomTheme } from '@hooks'

import {
    CardContent,
    CardHeader,
    Grid,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material'
import { LearnerResourceType, TypographyVariants } from '@constants'
import {
    ChatIcon,
    EmailIcon,
    GlobeIcon,
    Icon247,
    LockIcon,
    MapPinIcon,
    PhoneIcon
} from '@assets'
import { IconContainer, Link } from '@components'
import {
    ResourceCardProps,
    ResourceContainerProps,
    ResourceInnerContainerProps
} from './types'
import {
    AlwaysAvailableIconContainer,
    ConfidentialIconContainer,
    ContainerSubheading,
    ContainerTypography,
    FlexGrid,
    HeadingContainer,
    StyledCard,
    StyledTypography
} from './style'

export const ResourceContainer = ({
    title,
    resources,
    index,
    categoryId
}: ResourceContainerProps) => {
    const isSingleResource = resources?.length === 1
    const isLastIndexOdd = resources?.length % 2 !== 0
    const [searchParams] = useSearchParams()
    const routeId = searchParams.get('routeId')
    const { theme } = useCustomTheme()
    const linkColor = theme.palette.contentLink

    const resourceRefs = useRef<Array<HTMLDivElement | null>>([])
    const categoryTitleRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (routeId) {
            if (routeId === categoryId) {
                categoryTitleRef.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                })
            } else {
                const targetIndex = resources.findIndex(
                    (resource) => resource.id === routeId
                )
                if (targetIndex !== -1) {
                    resourceRefs.current[targetIndex]?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                }
            }
        }
    }, [resources, categoryId])

    const ResourceInnerContainer = ({
        heading,
        subHeading,
        icon,
        type,
        linkColor,
        linkText
    }: ResourceInnerContainerProps) => {
        if (!heading) return null

        const headingColor =
            type === LearnerResourceType.EMAIL ||
            type === LearnerResourceType.WEBSITE
                ? linkColor
                : theme.palette.textPrimary

        return (
            <FlexGrid container alignItems='center'>
                {icon && (
                    <Grid item mr={1} mb={1}>
                        {icon}
                    </Grid>
                )}
                <Grid item flex='1' ml={icon ? 1 : 0}>
                    <Grid item mb={2}>
                        {type === LearnerResourceType.WEBSITE ? (
                            <Link
                                route={{
                                    path: heading,
                                    label: linkText ? linkText : heading,
                                    isExternalLink: true
                                }}
                            >
                                <ContainerTypography
                                    color={headingColor}
                                    variant={TypographyVariants.othersNav}
                                >
                                    {linkText}
                                </ContainerTypography>
                            </Link>
                        ) : type === LearnerResourceType.EMAIL ? (
                            <Link
                                route={{
                                    path: `mailto:${heading}`,
                                    label: heading,
                                    isExternalLink: true
                                }}
                            >
                                <ContainerTypography
                                    color={headingColor}
                                    variant={TypographyVariants.othersNav}
                                >
                                    {heading}
                                </ContainerTypography>
                            </Link>
                        ) : (
                            <HeadingContainer
                                color={headingColor}
                                variant={TypographyVariants.othersNav}
                            >
                                {heading}
                            </HeadingContainer>
                        )}

                        {subHeading && (
                            <ContainerSubheading
                                variant={TypographyVariants.body2}
                            >
                                {subHeading}
                            </ContainerSubheading>
                        )}
                    </Grid>
                </Grid>
            </FlexGrid>
        )
    }

    const ResourceCard = ({ resource, isFullWidth }: ResourceCardProps) => {
        const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
        const { t } = useTranslation()
        return (
            <StyledCard
                isFullWidth={isFullWidth}
                id={`#${resource?.id}`}
                ref={(el) =>
                    (resourceRefs.current[resources.indexOf(resource)] = el)
                }
            >
                <CardHeader
                    tabIndex={0}
                    title={
                        <Typography variant={TypographyVariants.h2}>
                            {resource.title}
                        </Typography>
                    }
                    action={
                        <Grid
                            container
                            alignItems='center'
                            justifyContent='flex-end'
                            spacing={1}
                            pr={2}
                        >
                            {resource?.isAlwaysAvailable && (
                                <Grid item>
                                    <Tooltip title={t('resource.247badge')}>
                                        <AlwaysAvailableIconContainer container>
                                            <Icon247
                                                width={isMediumScreen ? 16 : 24}
                                                color={
                                                    theme.palette.contentNotice
                                                }
                                            />
                                        </AlwaysAvailableIconContainer>
                                    </Tooltip>
                                </Grid>
                            )}
                            {resource.isConfidential && (
                                <Grid item>
                                    <Tooltip
                                        title={t('resource.confidentialbadge')}
                                    >
                                        <ConfidentialIconContainer container>
                                            <LockIcon
                                                width={isMediumScreen ? 16 : 24}
                                                color={
                                                    theme.palette.contentNotice
                                                }
                                            />
                                        </ConfidentialIconContainer>
                                    </Tooltip>
                                </Grid>
                            )}
                        </Grid>
                    }
                />
                <CardContent>
                    <StyledTypography variant={TypographyVariants.body2}>
                        {resource?.description}
                    </StyledTypography>

                    {[
                        {
                            heading: resource?.address,
                            icon: (
                                <MapPinIcon
                                    width={24}
                                    height={24}
                                    color={theme.palette.BorderBrandPrimary}
                                />
                            )
                        },
                        {
                            heading: resource?.phoneNumberOne,
                            subHeading: resource?.phoneNumberOneDescription,
                            type: LearnerResourceType.PHONE,

                            icon: (
                                <PhoneIcon
                                    width={24}
                                    height={24}
                                    color={theme.palette.BorderBrandPrimary}
                                />
                            )
                        },
                        {
                            heading: resource?.phoneNumberTwo,
                            type: LearnerResourceType.PHONE,
                            subHeading: resource?.phoneNumberTwoDescription,

                            icon: (
                                <PhoneIcon
                                    width={24}
                                    height={24}
                                    color={theme.palette.BorderBrandPrimary}
                                />
                            )
                        },
                        {
                            heading: resource?.phoneNumberThree,
                            subHeading: resource?.phoneNumberThreeDescription,
                            type: LearnerResourceType.PHONE,
                            icon: (
                                <PhoneIcon
                                    width={24}
                                    height={24}
                                    color={theme.palette.BorderBrandPrimary}
                                />
                            )
                        },
                        {
                            heading: resource?.email,
                            type: LearnerResourceType.EMAIL,
                            linkColor: linkColor,
                            icon: (
                                <EmailIcon
                                    width={24}
                                    height={24}
                                    color={theme.palette.BorderBrandPrimary}
                                />
                            )
                        },
                        {
                            heading: resource?.websiteUrl,
                            linkText: resource?.websiteDisplayText,
                            type: LearnerResourceType.WEBSITE,
                            linkColor: linkColor,
                            icon: (
                                <GlobeIcon
                                    width={24}
                                    height={24}
                                    color={theme.palette.BorderBrandPrimary}
                                />
                            )
                        },
                        {
                            heading: resource?.onlineChatUrl,
                            linkText: resource?.onlineChatDisplayText,
                            type: LearnerResourceType.WEBSITE,
                            linkColor: linkColor,
                            icon: (
                                <ChatIcon
                                    width={24}
                                    height={24}
                                    color={theme.palette.BorderBrandPrimary}
                                />
                            )
                        }
                    ]?.map((props, index) => (
                        <ResourceInnerContainer
                            key={index}
                            heading={props?.heading}
                            subHeading={props?.subHeading}
                            type={props?.type}
                            linkColor={props?.linkColor}
                            icon={props?.icon}
                            linkText={props?.linkText}
                        />
                    ))}
                </CardContent>
            </StyledCard>
        )
    }

    return (
        <Grid
            container
            sx={{ marginBottom: 4 }}
            id={`${categoryId}`}
            ref={categoryTitleRef}
        >
            <Grid item xs={12}>
                <Typography
                    variant={TypographyVariants.h1}
                    component='h2'
                    sx={{ marginBottom: 3, marginLeft: 2 }}
                >
                    {title}
                </Typography>
            </Grid>
            <Grid
                item
                container
                spacing={2}
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap'
                }}
            >
                {resources?.map((resource, index) => {
                    const isFullWidth =
                        isSingleResource ||
                        (isLastIndexOdd && index === resources.length - 1)
                    return (
                        <Grid
                            item
                            xs={12}
                            lg={isFullWidth ? 11 : 5.5}
                            xl={isFullWidth ? 9 : 4.5}
                            key={index}
                        >
                            <ResourceCard
                                resource={resource}
                                isFullWidth={isFullWidth}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}
