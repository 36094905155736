import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { SearchableList, TextWithTooltip } from '@components'
import { Avatar, Grid, Typography } from '@mui/material'
import { useCustomTheme, useGraphQLWrapper } from '@hooks'
import { GET_LEARNERS } from '@dts/graphql'
import { AddLeanerProps } from './types'
import { StyledGrid } from './styles'

export const LearnerRow: React.FC = ({ item }) => {
    const { theme } = useCustomTheme()
    return (
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid
                item
                md={12}
                xs={12}
                pr={1}
                display={'flex'}
                alignItems={'center'}
            >
                <Grid item pr={1}>
                    <StyledGrid>
                        <Avatar
                            sx={{
                                border: `1px solid ${theme.palette.borderGrey}`
                            }}
                            src={item?.avatarSrc}
                            alt=''
                        />
                    </StyledGrid>
                </Grid>
                <Grid item>
                    <Typography variant='subtitle2'>
                        <TextWithTooltip
                            renderer={item?.title}
                            color={theme.palette.contentPrimary}
                        />
                    </Typography>
                    <Typography variant='caption' fontWeight={'500px'}>
                        {item?.userId ?? '--'}
                    </Typography>
                    <Typography variant='caption' fontWeight={'500px'}>
                        {item?.email}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}
export const SearchableLearnersList: React.FC<{
    addedIn: string
    learnerListId: string
}> = ({ addedIn = '', learnerListId }) => {
    const { t } = useTranslation()
    const { setValue, control } = useFormContext()
    const learnersAdded = useWatch({ control, name: 'learners' })
    const [learners, setLearners] = useState<Array<AddLeanerProps>>([])
    const addedCourses = useWatch({
        control,
        name: 'addedCourses'
    })
    const { queryLoading, data: learnersData } = useGraphQLWrapper({
        query: GET_LEARNERS,
        queryName: 'getLearners',
        retrieveOnMount: true,
        queryVariables: {
            learnerListId
        }
    })

    useEffect(() => {
        if (!queryLoading && learnersData) {
            setLearners(learnersData?.data)
        }
    }, [queryLoading, learnersData])

    useEffect(() => {
        if (learners?.length) {
            const learnersUpdated: Array<AddLeanerProps> = []

            learners?.forEach((learner) => {
                learnersUpdated.push({
                    id: learner.id,
                    title: learner?.firstName,
                    isAdded: false,
                    email: learner?.email,
                    courseTopics: learner?.courseTopics
                })
            })

            setValue('learners', learnersUpdated)
        }
    }, [learners, addedCourses])

    const { update: updateCourses } = useFieldArray({
        control,
        name: 'learners'
    })

    return (
        <SearchableList
            topicsData={learnersAdded}
            updateData={updateCourses}
            listType={'learners'}
            addedIn={addedIn || t('general.vertical')}
            rowComponent={LearnerRow}
            showNotAdded={true}
        />
    )
}
