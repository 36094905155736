import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { Grid, useMediaQuery } from '@mui/material'
import { EmailVerificationImg } from '@assets'
import {
    ErrorContainer,
    StyledGrid,
    StyledTypographyHeader,
    StyledTypographySubHeader,
    StyledAnchorTag
} from '../styles'

export const VerifyEmail: React.FC = () => {
    const { t } = useTranslation()
    const { logout } = useAuth0()

    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))

    return (
        <ErrorContainer>
            <StyledGrid container p={2} m={2} xs={10} md={6}>
                <Grid item>
                    <img
                        src={EmailVerificationImg}
                        alt='Email Verification'
                        style={{
                            borderRadius: '10%',
                            height: isSmallScreen ? '110px' : '150px',
                            width: isSmallScreen ? '180px' : '250px',
                            objectFit: 'contain'
                        }}
                    />
                </Grid>
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '10px'
                    }}
                >
                    <StyledTypographyHeader>
                        {t('verifyEmail.header')}
                    </StyledTypographyHeader>
                    <StyledTypographySubHeader>
                        {t('verifyEmail.subHeader')}
                    </StyledTypographySubHeader>
                </Grid>

                <Grid item sx={{ marginTop: '20px' }}>
                    <StyledAnchorTag onClick={logout}>
                        {t('verifyEmail.redirect')}
                    </StyledAnchorTag>
                </Grid>
            </StyledGrid>
        </ErrorContainer>
    )
}
