import {
  ARTICLE,
  CLIENT,
  COURSE,
  REPORT,
  REPORT_LIBRARY,
  RESOURCES,
  RESOURCES_CATEGORY,
  TOPIC,
  USER,
  VERTICAL,
  VIDEO,
} from '../single-value'

export const ELASTICSEARCH_GENERIC_INDICES = [
  ARTICLE,
  VIDEO,
  USER,
  RESOURCES,
  RESOURCES_CATEGORY,
]

export const ELASTICSEARCH_REPORTER_INDICES = [REPORT, REPORT_LIBRARY]

export const ELASTICSEARCH_DEFAULT_CLIENT_SPECIFIC_INDICES = [
  CLIENT,
  TOPIC,
  VERTICAL,
  COURSE,
]

export const ELASTICSEARCH_FIELDS = [
  'title^3',
  'description^2',
  'firstName',
  'lastName',
  'tags',
  'email',
  'address',
  'creatorData.firstName',
  'creatorData.lastName',
  'topicsData.title',
  'clientId',
  'campusId',
  'organization',
  'userId',
]
