import { gql } from '@apollo/client'
import { MessagesFragment, ClientFields } from '../../fragments'

export const GET_CLIENT_TITLE = gql`
  ${MessagesFragment('ClientResponseDto')}

  query getClient($clientId: String!) {
    getClient(id: $clientId) {
      ...MessagesFragment
      data {
        title
      }
    }
  }
`
