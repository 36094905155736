import { gql } from '@apollo/client'

export const GET_DISPLAY_MODE = gql`
  query getDisplayModes {
    getDisplayModes {
      id
      title
    }
  }
`
