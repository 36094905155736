import {
    ApolloClient,
    createHttpLink,
    split,
    from,
    ApolloLink
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'

import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { createClient } from 'graphql-ws'
import config from '@config'
import {
    cache,
    queryResponseInterceptor,
    setGraphQLError,
    setNetworkError
} from '@cacheql'
import { getAuthHeaders } from './auth-headers'

const httpLink = createHttpLink({
    uri: config.API_SERVER
})

const authLink = setContext((_, { headers }) =>
    // return the headers to the context so httpLink can read them
    ({
        headers: {
            ...headers,
            ...getAuthHeaders()
        }
    })
)

const wsLink = new GraphQLWsLink(
    createClient({
        url: config.WS_SERVER,
        shouldRetry: () => true, // retry on connection problem
        lazy: false, // connect as soon as the client is created
        retryAttempts: 6, // if 0 then fail immediately else keep trying to connect

        connectionParams: () => ({
            ...getAuthHeaders()
        })
    })
)

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        setGraphQLError(graphQLErrors)
    }

    if (networkError) {
        setNetworkError(networkError)
    }
})

const queryInterceptorLink = new ApolloLink((operation, forward) =>
    forward(operation).map((operationresponse) =>
        queryResponseInterceptor(operation.operationName, operationresponse)
    )
)

const appLink = from([queryInterceptorLink, errorLink, httpLink])

const splitLink = split(
    // split link added to use multiple url (for normal server url and for socket url to connect with backend)
    ({ query }) => {
        const definition = getMainDefinition(query)
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        )
    },
    wsLink, // websocket url
    authLink.concat(appLink) // server url
)

export const client = new ApolloClient({
    link: splitLink,
    cache,
    connectToDevTools: true
})
