import { MessagesFragment, MetaFragment, UserDetails } from '../../fragments'

import { gql } from '@apollo/client'

export const ADD_INFO_TO_USER = gql`
  ${MetaFragment('GetUserResponseDto')}
  ${MessagesFragment('GetUserResponseDto')}
  ${UserDetails('UserDataDto')}
  mutation addInformationToUser($input: AddInformationToUserInputDto!) {
    addInformationToUser(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...UserDetails
      }
    }
  }
`
export const SAVE_USER = gql`
  ${MetaFragment('GetUserResponseDto')}
  ${MessagesFragment('GetUserResponseDto')}
  ${UserDetails('UserDataDto')}
  mutation saveUser($input: SaveUserInputDto!) {
    saveUser(input: $input) {
      data {
        ...UserDetails
      }
      ...MetaFragment
      ...MessagesFragment
    }
  }
`
