import React, { useState, useEffect } from 'react'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import { FieldErrors } from '@components'
import { Autocomplete, FormControl, TextField } from '@mui/material'

export const ControlledAutoCompleteSelect = (props) => {
    const [selectedValue, setSelectedValue] = useState('')

    // This state handles the open state for the autocomplete component to cater an edge case related to auto-fill
    const [open, setOpen] = useState(false)
    const { name, id, label, options, maxLength, textColor } = props

    const {
        formState: { errors },
        setValue,
        control
    } = useFormContext()

    const {
        field: { value },
        fieldState: { error }
    } = useController({
        name
    })

    useEffect(() => {
        setSelectedValue(value)
        setOpen(false) // This code handles an edge case related to auto-fill
    }, [value])

    return (
        <FormControl sx={{ width: '100%' }} error={!!errors?.[name]}>
            <Autocomplete
                id={id}
                autoSelect
                freeSolo
                aria-label={label}
                value={selectedValue}
                options={options ?? []}
                sx={{ width: '100%', marginRight: 2, marginTop: 2 }}
                disabled={props.disabled}
                // This code handles an edge case related to auto-fill
                onChangeCapture={(e) => {
                    setValue(name, e?.target?.value)
                }}
                open={open}
                onFocus={() => setOpen(true)}
                ListboxProps={{
                    style: {
                        color: textColor
                    }
                }}
                onChange={(_, values) => {
                    setValue(name, values?.label)
                    setOpen(false)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        {...props}
                        onBlur={() => setOpen(false)} // This code handles an edge case related to auto-fill
                        aria-label={label}
                        error={!!errors?.[name]}
                        inputProps={{
                            ...params.inputProps,
                            maxLength // Set maximum length to 5 characters
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                        InputProps={{
                            ...params.InputProps,
                            sx: { padding: '7px !important' }
                        }}
                    />
                )}
            />
            <FieldErrors error={error} name={name} />
        </FormControl>
    )
}
