import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import {
    useDirectory,
    useModalWithGraphQL,
    useVideoConfig,
    useRoutes,
    useGraphQLWrapper,
    useAuth
} from '@hooks'
import { FilterLayout, StyledLink, TextWithTooltip } from '@components'
import {
    DeleteIcon,
    LaunchIcon,
    PencilIcon,
    RocketDown,
    UserCheckIcon,
    UserSingleIcon,
    UserUnsureIcon,
    VideoIcon,
    VideoThumbnailImg
} from '@assets'
import { Grid } from '@mui/material'
import {
    GET_TOPICS,
    GET_VIDEOS_DIRECTORY,
    PUBLISH_VIDEO,
    UNPUBLISH_VIDEO
} from '@dts/graphql'
import { VideoConfigType, VideoStatus } from '@constants'
import { formatDate, RejectVideoSchema } from '@dts/client-utils'
import { PendingStatusEnum } from '@dts/constants'
import { isEmpty } from 'lodash'
import { getAccessRoles, renderButtonForRole } from '@utils'
import { mapGenericFormToMutationInput } from './review-video-container'
import { RejectVideoForm } from '@types'
import { UnpublishVideo } from './unpublish-video'

export const unpublishVideoConfig = (t, navigate) => ({
    modalConfig: {
        Component: UnpublishVideo,
        creationConfig: {
            primaryButtonText: t('general.submit'),
            header: [t('video.unpublish'), t('general.video')].join(' ')
        }
    },
    mutation: UNPUBLISH_VIDEO,
    mutationName: 'unpublishVideo',

    onMutationSuccess: () => {
        navigate('/videos/unpublished')
    },
    yupResolverSchema: RejectVideoSchema
})
export const AdminVideosPage = ({ status }: { status: string }) => {
    const [topics, setTopics] = useState([])
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [dataTableHeader, setDataTableHeader] = useState('')
    const [selectedFilterTopic, setSelectedFilterTopic] = useState('')

    const updateHeaderText = (newHeader) => {
        setDataTableHeader(newHeader)
    }

    const {
        userInfo: { roles }
    } = useAuth()
    const accessedRoles = getAccessRoles(roles)
    const isManager = accessedRoles?.isManager

    const { queryLoading, data: topicsData } = useGraphQLWrapper({
        query: GET_TOPICS,
        queryName: 'getTopics',
        retrieveOnMount: true
    })
    const { save: publishVideo } = useGraphQLWrapper({
        mutation: PUBLISH_VIDEO,
        mutationName: 'publishVideo',

        onMutationSuccess: () => {
            navigate('/videos/approved')
        }
    })
    const renderDirectoryImage = (params) => (
        <Grid
            sx={{
                width: '54px',
                height: '64px',
                borderRadius: '8px',
                padding: '8px'
            }}
        >
            <img
                src={params.value?.length ? params?.value : VideoThumbnailImg}
                style={{ borderRadius: '10%', objectFit: 'cover' }}
                height='100%'
                width='100%'
                alt={params.row.title}
            />
        </Grid>
    )

    const { openDialog: openUnpublishDialog } = useModalWithGraphQL({
        ...unpublishVideoConfig(t, navigate),
        mapFormToMutationInput: (form: RejectVideoForm) => {
            const { title, details } = form
            return {
                message: {
                    title,
                    details
                }
            }
        },
        hasPrimaryExtraInput: true
    })

    const getToolbarConfig = () => {
        switch (status) {
            case VideoStatus.Pending:
                return {
                    header: t('general.pending'),
                    caption: t('videos.directory.pending.caption')
                }
            case VideoStatus.Approved:
                return {
                    header: t('general.approved'),
                    caption: t('videos.directory.approved.caption')
                }
            case VideoStatus.Rejected:
                return {
                    header: t('general.rejected'),
                    caption: t('videos.directory.rejected.caption')
                }
            case VideoStatus.Reported:
                return {
                    header: t('general.reported'),
                    caption: t('reviewVideos.directory.reported.capion')
                }
            case VideoStatus.Draft:
                return {
                    header: t('general.drafts'),
                    caption: t('reviewVideos.directory.draft.capion')
                }
            case VideoStatus.UnPublished:
                return {
                    header: t('videos.directory.unpublshed.header'),
                    caption: t('videos.directory.unpublshed.caption')
                }
            default:
                return {}
        }
    }

    const onVideoClick = (params) => ({
        to: `/videos/${params.id}?tab=${status}`
    })

    const { videoConfig: videoDetailsConfig, getDeleteConfig } = useVideoConfig(
        {
            videoConfigType: VideoConfigType.Retrieve
        }
    )

    const { openPrepopulatedDialog: openDetailedVideo } =
        useModalWithGraphQL(videoDetailsConfig)

    const { openConfirmationDialog: deleteVideo } = useModalWithGraphQL(
        getDeleteConfig(() => {
            retrieve({
                variables: {
                    options: queryOptions
                }
            })
        })
    )

    const levelApprovalIcon = (
        level,
        previousLevelIcons,
        index,
        previousLevel
    ) => {
        switch (level) {
            case PendingStatusEnum.Pending:
                if (
                    index === 0 ||
                    previousLevel === PendingStatusEnum.Approved ||
                    previousLevel === PendingStatusEnum.Escalated
                ) {
                    return (
                        <>
                            {previousLevelIcons}
                            <UserSingleIcon />
                        </>
                    )
                }
                return previousLevelIcons
            case PendingStatusEnum.Approved:
                return (
                    <>
                        {previousLevelIcons}
                        <UserCheckIcon />
                    </>
                )
            case PendingStatusEnum.Escalated:
                return (
                    <>
                        {previousLevelIcons}
                        <UserUnsureIcon />
                    </>
                )
            default:
                return <></>
        }
    }

    const renderApprovalIcons = (levels, tabIndex) => {
        let icons = null
        levels.forEach((level, index) => {
            const previousLevelIcons = index ? (
                <Grid
                    item
                    key={index}
                    sx={{ paddingRight: '5px', display: 'flex' }}
                    tabIndex={tabIndex}
                >
                    {icons}
                </Grid>
            ) : null
            icons = levelApprovalIcon(
                level,
                previousLevelIcons,
                index,
                levels[index - 1]
            )
        })

        return <Grid container>{icons}</Grid>
    }

    const { adminVideoRoutes } = useRoutes()

    const getTabSpecificData = () => {
        switch (status) {
            case VideoStatus.Pending:
                return [
                    {
                        field: 'pendingStatus',
                        headerName: t('general.status'),
                        renderCell: (params) =>
                            renderApprovalIcons(
                                [
                                    params?.value?.firstLevel,
                                    params?.value?.secondLevel,
                                    params?.value?.thirdLevel
                                ],
                                params.tabIndex
                            )
                    }
                ]
            case VideoStatus.Approved:
                return [
                    {
                        field: 'reviewerData',
                        headerName: t('general.approvedBy'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={`${params?.value?.firstName ?? ''} ${
                                    params?.value?.lastName ?? ''
                                }`}
                                tabIndex={params.tabIndex}
                            />
                        )
                    }
                ]
            case VideoStatus.Rejected:
                return [
                    {
                        field: 'reviewerData',
                        headerName: t('general.rejectedBy'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={`${params?.value?.firstName ?? ''} ${
                                    params?.value?.lastName ?? ''
                                }`}
                                tabIndex={params.tabIndex}
                            />
                        )
                    },
                    {
                        field: `reviews`,
                        headerName: t('general.reason'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={
                                    params?.value?.find(
                                        (review) =>
                                            review?.version ===
                                            params?.row?.version
                                    )?.title
                                }
                                tabIndex={params.tabIndex}
                            />
                        )
                    }
                ]

            case VideoStatus.Reported:
                return [
                    {
                        field: 'reporterData',
                        headerName: t('general.reportedBy'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={`${params?.value?.firstName ?? ''} ${
                                    params?.value?.lastName ?? ''
                                }`}
                                tabIndex={params.tabIndex}
                            />
                        )
                    }
                ]
            default:
                return []
        }
    }
    const [filterEnabled, setFilterEnabled] = useState(true)
    const { directory, queryOptions, retrieve, data, directoryLoading } =
        useDirectory({
            directoryConfig: {
                id: 'adminVideo',
                query: GET_VIDEOS_DIRECTORY,
                queryName: 'getVideoDirectory',
                accessor: 'videos',
                queryVariables: {
                    args: {
                        tab:
                            filterEnabled && status === VideoStatus.Pending
                                ? VideoStatus.FILTERED_PENDING
                                : status,
                        topicId: selectedFilterTopic
                    }
                },
                // TODO: will be used once cardDirectory integrated
                // cardDirectoryConfig: {
                //     getCardAction: (params) => ({
                //         icon: PlayIcon,
                //         label: '122k'
                //     }),
                //     getCardConfig: (data) =>
                //         data?.videos?.map((item) => ({
                //             ...item,
                //             onClick: () => {
                //                 if (status === VideoStatus.Pending) {
                //                     navigate(`/videos/${item.id}/review`)
                //                 } else {
                //                     openDetailedVideo({
                //                         id: item?.id
                //                     })
                //                 }
                //             }
                //         }))
                // },
                config: {
                    customActions:
                        status === VideoStatus.Pending
                            ? [
                                  {
                                      icon: <PencilIcon />,
                                      ariaLabel: t('general.edit'),
                                      disabled: (params) =>
                                          !renderButtonForRole(
                                              status,
                                              params?.row,
                                              accessedRoles
                                          ),
                                      action: (params) => {
                                          navigate(
                                              `/videos/${params.id}/review?tab=${status}`
                                          )
                                      }
                                  }
                              ]
                            : [
                                  {
                                      icon: (
                                          <VideoIcon width='18' height='18' />
                                      ),
                                      label: t('general.viewDetails'),
                                      action: (params) => {
                                          navigate(
                                              `/videos/${params.id}?tab=${status}`
                                          )
                                      },
                                      showInMenu: true
                                  },

                                  isManager && {
                                      icon: (
                                          <DeleteIcon width='18' height='18' />
                                      ),
                                      label: t('general.delete'),
                                      action: (params) => {
                                          // on click
                                          deleteVideo({ id: params?.id })
                                      },
                                      showInMenu: true,
                                      hide: () =>
                                          status !== VideoStatus.Approved
                                  },
                                  status === VideoStatus.Approved
                                      ? {
                                            icon: (
                                                <RocketDown
                                                    width='18'
                                                    height='18'
                                                />
                                            ),
                                            label: [
                                                t('video.unpublish'),
                                                t('general.video')
                                            ].join(' '),
                                            action: (params) => {
                                                openUnpublishDialog({
                                                    id: params?.row?.id
                                                })
                                            },
                                            showInMenu: true
                                        }
                                      : null,
                                  status === VideoStatus.UnPublished
                                      ? {
                                            icon: (
                                                <LaunchIcon
                                                    width='18'
                                                    height='18'
                                                />
                                            ),
                                            label: [
                                                t('video.publish'),
                                                t('general.video')
                                            ].join(' '),
                                            action: (params) => {
                                                publishVideo({
                                                    ...mapGenericFormToMutationInput(
                                                        params?.row,
                                                        params?.row?.id
                                                    )
                                                })
                                            },
                                            showInMenu: true
                                        }
                                      : null
                              ].filter(Boolean),
                    columns: [
                        {
                            field: 'coverImageSrc',
                            headerName: t('general.video'),
                            minWidth: 80,
                            maxWidth: 100,
                            renderCell: (params) => (
                                <TextWithTooltip
                                    renderer={
                                        <StyledLink {...onVideoClick(params)}>
                                            {renderDirectoryImage(params)}
                                        </StyledLink>
                                    }
                                    toolTip={params.row.title}
                                    tabIndex={params.tabIndex}
                                />
                            )
                        },
                        {
                            field: 'title',
                            headerName: t('general.title'),
                            width: 120,
                            renderCell: (params) => (
                                <TextWithTooltip
                                    renderer={params.value}
                                    onClick={() =>
                                        navigate(
                                            `/videos/${params.id}?tab=${status}`
                                        )
                                    }
                                    toolTip={params.value}
                                    isDirectoryLink
                                    tabIndex={params.tabIndex}
                                />
                            )
                        },
                        ...getTabSpecificData(),

                        {
                            field: 'creatorData',
                            headerName: t('general.contentCreator'),
                            renderCell: (params) => (
                                <TextWithTooltip
                                    renderer={`${
                                        params?.value?.firstName ?? ''
                                    } ${params?.value?.lastName ?? ''}`}
                                    tabIndex={params.tabIndex}
                                />
                            )
                        },
                        {
                            field:
                                status === VideoStatus.UnPublished
                                    ? 'updatedAt'
                                    : 'createdAt',
                            headerName:
                                status === VideoStatus.UnPublished
                                    ? t('general.dateUnpublished')
                                    : t('general.dateCreated'),
                            renderCell: (params) => (
                                <TextWithTooltip
                                    renderer={
                                        params?.value
                                            ? formatDate(params?.value)
                                            : ''
                                    }
                                    tabIndex={params.tabIndex}
                                />
                            )
                        }
                    ].filter(Boolean),
                    pagination: true
                },
                enableSearch: true,
                filterConfig: {
                    filterVariables:
                        status ===
                        (VideoStatus.Pending || VideoStatus.FILTERED_PENDING)
                            ? {
                                  args: {
                                      tab: VideoStatus.FILTERED_PENDING,
                                      topicId: selectedFilterTopic
                                  }
                              }
                            : undefined,
                    filterEnabled,
                    setFilterEnabled
                },
                toolbarConfig: getToolbarConfig(),
                dataTableHeader,
                useFilterHeader: true
            }
        })

    useEffect(() => {
        if (!queryLoading && data) {
            const allTopics: Array<object> = [
                {
                    id: '',
                    title: [t('general.all'), t('general.videos')].join(' ')
                },
                ...(topicsData?.data || [])
            ]
            setTopics(
                allTopics?.map((topic) => ({
                    ...topic,
                    label: topic.title,
                    count: isEmpty(topic?.id)
                        ? data?.allTopicsCount
                        : data?.topics?.filter(
                              (topicObj) => topicObj.id === topic?.id
                          )?.[0]?.count ?? 0,
                    onClick: () => {
                        setSelectedFilterTopic(topic?.id)
                        retrieve({
                            variables: {
                                args: {
                                    tab:
                                        filterEnabled &&
                                        status === VideoStatus.Pending
                                            ? VideoStatus.FILTERED_PENDING
                                            : status,
                                    topicId: topic?.id
                                },
                                options: queryOptions
                            }
                        })
                    }
                }))
            )
        }
    }, [queryLoading, data, topicsData, filterEnabled])

    useEffect(() => {
        if (status === VideoStatus.Pending && queryOptions?.page) {
            retrieve({
                variables: {
                    args: {
                        tab: filterEnabled
                            ? VideoStatus.FILTERED_PENDING
                            : VideoStatus.Pending,
                        topicId: selectedFilterTopic
                    },
                    options: queryOptions
                }
            })
        }
    }, [filterEnabled])

    useEffect(() => {
        retrieve({
            variables: {
                args: {
                    tab:
                        filterEnabled && status === VideoStatus.Pending
                            ? VideoStatus.FILTERED_PENDING
                            : status,
                    topicId: selectedFilterTopic
                },
                options: queryOptions
            }
        })
    }, [status])

    // return directory
    return (
        <>
            <FilterLayout
                pageHeaderConfig={{
                    headerText: t('general.videos'),
                    menuConfig: () => adminVideoRoutes
                }}
                filterConfig={{
                    categoriesArray: topics,
                    categoriesHeader: t('general.topics'),
                    setDataTableHeader: updateHeaderText
                }}
                headerConfig={getToolbarConfig()}
                directoryComponent={directory}
                directoryLoading={directoryLoading}
            />
        </>
    )
}
