import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const LikesIcon: React.FC<SvgProps> = ({
    color,
    width = '28',
    height = '28'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.borderPrimary
    const _fill = color || theme.palette.backgroundPrimary

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 29 28'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M8.83529 25.6663V12.833M3.00195 15.1663V23.333C3.00195 24.6217 4.04662 25.6663 5.33529 25.6663H20.9992C22.7267 25.6663 24.1959 24.406 24.4585 22.6985L25.7149 14.5319C26.0411 12.4117 24.4007 10.4997 22.2556 10.4997H18.1686C17.5243 10.4997 17.002 9.97734 17.002 9.33301V5.20982C17.002 3.621 15.714 2.33301 14.1251 2.33301C13.7462 2.33301 13.4028 2.55619 13.2489 2.90248L9.14321 12.1402C8.95596 12.5615 8.53815 12.833 8.0771 12.833H5.33529C4.04662 12.833 3.00195 13.8777 3.00195 15.1663Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default LikesIcon
