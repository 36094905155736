import { InMemoryCache, makeVar, FetchResult } from '@apollo/client'
import { BackendErrorOptions } from '@constants'
import { ResponseStatusEnum } from '@dts/constants'
import { Client, ObjectBaseFields } from '@types'
import { cloneDeep } from 'lodash'

export const setAccessToken = makeVar<string | null>(null)
export const setUserData = makeVar<object | null>(null)
export const setGraphQLError = makeVar<object | null>(null)
export const setNetworkError = makeVar<object | null>(null)
export const setUnauthorizedError = makeVar<object | null>(null)
export const URLSegmentInfo = makeVar<Array<ObjectBaseFields> | null>([])
export const surveyStepsInfo = makeVar<Array<object> | null>([])
export const cachedSurveyInfo = makeVar<{
    answeredQuestionIds: Array<string>
    visitedIds: Array<string>
    cachedSurveys: Array<object>
} | null>({
    answeredQuestionIds: [],
    cachedSurveys: [],
    visitedIds: []
})

export const setDirectoryQueryOptions = makeVar<
    Array<{
        id: string
        options: object
    }>
>([])

export const cache = new InMemoryCache({
    dataIdFromObject: (o) => (o._id ? `${o.__typename}:${o._id}` : undefined),
    typePolicies: {
        Query: {
            fields: {
                accessToken: {
                    read() {
                        return setAccessToken()
                    }
                },
                userData: {
                    read() {
                        return setUserData()
                    }
                },
                graphQLError: {
                    read() {
                        return setGraphQLError()
                    }
                },
                directoryQueryOptions: {
                    read() {
                        return setDirectoryQueryOptions()
                    }
                },
                networkError: {
                    read() {
                        return setNetworkError()
                    }
                },
                unauthorizedError: {
                    read() {
                        return setUnauthorizedError()
                    }
                },
                surveyStepsInfo: {
                    read() {
                        return surveyStepsInfo()
                    }
                },
                cachedSurveyInfo: {
                    read() {
                        return cachedSurveyInfo()
                    }
                }
            }
        }
    }
})

export const addEntryToCache = (id: string, title: string) => {
    const objectToStore = {
        id,
        title
    }

    const updatedArray = cloneDeep(URLSegmentInfo())
    updatedArray?.push(objectToStore)

    URLSegmentInfo(updatedArray)
}

// This function will be further optimized on the basis of operationName if cache size becomes too large in the future
export const queryResponseInterceptor = (
    operationName: string,
    operationResponse: FetchResult
) => {
    const response = operationResponse?.data?.[operationName]
    if (
        (response?.message?.type === ResponseStatusEnum.Error &&
            response?.message?.messages?.includes(
                BackendErrorOptions.Unauthorized
            )) ||
        (response?.message?.type === ResponseStatusEnum.Error &&
            response?.message?.messages?.includes(
                BackendErrorOptions.Forbidden
            )) ||
        response?.errors?.some(
            (error) =>
                error?.message === BackendErrorOptions.Unauthorized ||
                error?.message === BackendErrorOptions.Forbidden
        ) ||
        operationResponse?.errors?.some(
            (error) =>
                error?.message === BackendErrorOptions.Unauthorized ||
                error?.message === BackendErrorOptions.Forbidden
        )
    ) {
        setUnauthorizedError({ error: BackendErrorOptions.Unauthorized })
    }
    const data = response?.data ?? response

    if (data?.id) {
        addEntryToCache(
            data?.id,
            data?.firstName || data?.lastName
                ? [data?.firstName, data?.lastName].filter(Boolean).join(' ')
                : data?.title ?? data?.name
        )
    }
    return operationResponse
}
