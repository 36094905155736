import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Popover as MuiPopover, Badge } from '@mui/material'
import { useCustomTheme } from '@hooks'
import { PopoverProps } from './types'

export const Popover = ({
    popoverIcon,
    Content,
    contentProps,
    showIconBadge,
    onIconClick,
    customIconClick,
    ariaLabel
}: PopoverProps) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    )

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onIconClick?.()
        if (customIconClick) {
            customIconClick()
        } else {
            setAnchorEl(event.currentTarget)
        }
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    return (
        <>
            <IconButton
                aria-label={ariaLabel ?? 'popover-button'}
                onClick={handleClick}
                edge='end'
            >
                <Badge
                    variant='dot'
                    invisible={!showIconBadge} // You can control the visibility of the badge based on your logic
                    sx={{
                        '& .MuiBadge-dot': {
                            backgroundColor: theme.palette.contentNegative
                        }
                    }}
                >
                    {popoverIcon}
                </Badge>
            </IconButton>

            <MuiPopover
                id={open ? 'popover' : undefined}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <div
                    style={{
                        minWidth: '400px',
                        maxWidth: '700px',
                        maxHeight: '20%'
                    }}
                >
                    {
                        <Content
                            isPopoverOpen={open}
                            closePopover={handleClose}
                            {...contentProps}
                        />
                    }
                </div>
            </MuiPopover>
        </>
    )
}
