import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Skeleton, Typography } from '@mui/material'
import { CardDirectoryConfig, QueryOptions, useCustomTheme } from '@hooks'
import { isMediumScreen, onKeyDown } from '@utils'
import { ArrowRightIcon } from '@assets'
import {
    StyledCardAction,
    StyledCardGrid,
    StyledCardVideo,
    StyledVideoCard
} from './styles'

type CardGridProps = {
    data: Array<object>
    config: CardDirectoryConfig
    setQueryOptions?: React.Dispatch<React.SetStateAction<QueryOptions>>
    totalLength?: number
    fetchMoreRecords?: () => void
}

export const CardGridWithDirectory: React.FC<CardGridProps> = ({
    data,
    config,
    setQueryOptions,
    totalLength,
    fetchMoreRecords
}) => {
    const [cardData, setCardData] = useState([])
    useEffect(() => {
        data && setCardData(config?.getCardConfig?.(data))
    }, [data])

    return (
        <InfiniteScroll
            dataLength={cardData?.length}
            hasMore={cardData?.length !== totalLength}
            next={() => fetchMoreRecords()}
            scrollThreshold={0.5}
            style={{ minHeight: '100%' }}
            scrollableTarget='content-container'
        >
            <VideoCardGrid
                cardData={cardData}
                cardAction={config?.getCardAction}
            />
        </InfiniteScroll>
    )
}

export const VideoCardGrid = ({
    cardData,
    cardAction,
    showViewMore,
    onClickViewMore,
    marginRight = 0
}) => (
    <>
        <StyledCardGrid container sx={{ marginRight }}>
            {cardData?.map((item, index) => (
                <VideoCardWithAction
                    key={item?.id}
                    item={item}
                    cardAction={cardAction}
                    showViewMore={cardData.length - 1 === index && showViewMore}
                    onClickViewMore={onClickViewMore}
                />
            ))}
        </StyledCardGrid>
    </>
)
export const VideoCardWithAction = ({
    item,
    cardAction,
    showViewMore = false,
    onClickViewMore = () => false
}) => {
    const action = cardAction?.(item)
    const { theme } = useCustomTheme()
    const { t } = useTranslation()
    return (
        <>
            <StyledVideoCard
                key={item?.id}
                xs={!isMediumScreen?.() ? 1 : 4}
                onKeyDown={(e) => {
                    onKeyDown(e, item?.onClick, false)
                }}
                tabIndex={item?.tabIndex ?? 0}
                role='button'
                aria-label={
                    action?.ariaLabel
                        ? item?.title
                        : [
                              item?.title,
                              item?.viewCount,
                              t('general.views')
                          ].join(' ')
                }
                onClick={() => item?.onClick?.()}
                width={196}
            >
                <CustomVideoCard
                    src={item?.urlSrc}
                    //TODO: check the corner cases
                    poster={item?.coverImageSrc || item?.coverImage}
                />

                <StyledCardAction>
                    {action?.icon ? (
                        <div
                            {...(action?.ariaLabel && {
                                tabIndex: 0
                            })}
                            onKeyDown={(e) =>
                                onKeyDown(e, action?.onActionClick, true)
                            }
                            aria-label={action?.ariaLabel}
                            role='button'
                            onClick={(e) => {
                                e.stopPropagation()
                                action?.onActionClick?.()
                            }}
                        >
                            <action.icon />
                        </div>
                    ) : null}
                    {action?.label}
                </StyledCardAction>
            </StyledVideoCard>

            {showViewMore && (
                <StyledVideoCard
                    key={item?.id}
                    tabIndex={0}
                    onClick={onClickViewMore}
                    onKeyDown={(e) => onKeyDown(e, onClickViewMore, false)}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: theme.palette.BrandPrimaryContainer
                    }}
                    width={196}
                >
                    <Typography
                        variant='h2'
                        fontSize={'14px'}
                        color={theme.palette.BorderBrandPrimary}
                        fontWeight={600}
                    >
                        {t('general.viewMore')}
                    </Typography>
                    <ArrowRightIcon
                        width={16}
                        height={16}
                        color={theme.palette.BorderBrandPrimary}
                    />
                </StyledVideoCard>
            )}
        </>
    )
}

export const CustomVideoCard = ({
    src,
    poster
}: {
    src?: string
    poster?: string
}) => {
    const videoRef = useRef<HTMLVideoElement>(null)
    const [isHovered, setIsHovered] = useState(false)
    const [loading, setLoading] = useState(true)

    const handleMouseOver = () => {
        setIsHovered(true)
        const video = videoRef.current
        if (video) {
            video.currentTime = 0
            if (video.paused) {
                video.play().catch((error) => {
                    // Handle play error
                })
            }
        }
    }

    const handleMouseOut = () => {
        setIsHovered(false)
        const video = videoRef?.current
        if (video) {
            video.pause()?.catch((error) => {
                // Handle pause error
            })
        }
    }

    const handleTimeUpdate = () => {
        if (isHovered) {
            const video = videoRef.current
            if (video && video?.currentTime >= 5) {
                video.currentTime = 0
                video.play()?.catch((error) => {
                    // Handle play error
                })
            }
        }
    }

    const handlePosterLoad = () => {
        setLoading(false)
    }

    return (
        <>
            <StyledCardVideo
                preload='metadata'
                poster={poster}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                onFocus={handleMouseOver}
                onBlur={handleMouseOut}
                onTimeUpdate={handleTimeUpdate}
                ref={videoRef}
                src={src}
                muted
                onLoadedMetadata={() => {
                    setLoading(false)
                }}
                style={{ display: loading ? 'none' : 'block' }}
            />
            {loading && (
                <Skeleton
                    variant='rectangular'
                    width='100%'
                    height='100%'
                    sx={{
                        borderRadius: '6px',
                        objectFit: 'contain'
                    }}
                />
            )}
            <img
                src={poster}
                alt='poster'
                style={{ display: 'none', objectFit: 'contain' }}
                onLoad={handlePosterLoad}
            />
        </>
    )
}
