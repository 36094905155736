import {
  MessagesFragment,
  MetaFragment,
  ReportLibraryFields,
} from '../../fragments'

import { gql } from '@apollo/client'

export const DELETE_REPORT = gql`
  ${MessagesFragment('ReportResponseDto')}
  ${MetaFragment('ReportResponseDto')}
  mutation deleteReport($id: String!) {
    deleteReport(id: $id) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
        title
      }
    }
  }
`
