import { gql } from '@apollo/client'
import { MessagesFragment, ClientFields } from '../../fragments'

export const SAVE_CLIENT = gql`
  ${MessagesFragment('ClientResponseDto')}
  ${ClientFields('ClientDto')}
  mutation saveClient($input: ClientInputDto!) {
    saveClient(input: $input) {
      ...MessagesFragment
      data {
        ...ClientFields
      }
    }
  }
`
