export const REGEX = {
  clientTypes: /^[A-Za-z0-9 _.-]*$/,
  stepSubStep: /^[A-Za-z0-9 _,.-]*$/,
  alphaNumeric: /^[A-Za-z0-9]*$/,
  email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  templates: /^[A-Za-z0-9 _.-{}#]*$/,
  splitString: /([A-Z])/g,
  userName_email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*$/,
  formatPhoneNo: /^(\d{3})(\d{3})(\d{4})$/,
  trackerId: /^(\d{4})(\d{2})(\d{2})([A-Z]{2})(\w{5})(\w{7})$/,
  email_table:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phone: /^\d{10}$/,
  datePattern: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/,
  noSpecialChars: /^[A-Za-z0-9 _.-]*$/,
}
