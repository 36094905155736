import {
    Button,
    FormControlLabel,
    Grid,
    RadioGroup,
    styled
} from '@mui/material'

export const StyledOptionGroup = styled(RadioGroup)(
    ({ hasColumnDirection }) => ({
        flexDirection: hasColumnDirection ? 'column' : 'row'
    })
)

export const StyledFormControlLabel = styled(FormControlLabel)({
    width: '100%',
    '& .MuiFormControlLabel-label': {
        width: '100%',
        lineHeight: '0'
    }
})

export const StyledButton = styled(Button)(({ theme }) => ({
    minHeight: '40px',
    padding: '4px',
    marginLeft: '12px',
    display: 'flex',
    color: theme.palette.neutralsTextGrey,
    alignItems: 'center',
    justifyContent: 'left',
    '& .MuiButton-startIcon': {
        marginRight: 0
    }
}))
