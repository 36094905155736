import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import {
    IconContainer,
    LearnCard,
    LearnCardProps,
    SearchField
} from '@components'
import { CinemaIcon, HelpIcon, LightIcon, PolicyIcon } from '@assets/icons'
import { TextFieldVariant } from '@constants'
import { LEARN_ICON_CONTAINER_SIZE } from '@dts/constants'
import { useCustomTheme } from '@hooks'

export const CreatorLearn = () => {
    const navigate = useNavigate()
    const { theme } = useCustomTheme()
    const { t } = useTranslation()

    const cards: Array<LearnCardProps> = [
        {
            id: 1,
            icon: (
                <IconContainer
                    color={theme.palette.cream}
                    size={LEARN_ICON_CONTAINER_SIZE}
                    displayFlex
                >
                    <LightIcon />
                </IconContainer>
            ),
            title: t('learn.learningCenter.title'),
            description: t('learn.learningCenter.description'),
            onClick: () => {
                navigate('learning-center')
            }
        },
        {
            id: 2,
            icon: (
                <IconContainer
                    color={theme.palette.lightPink}
                    size={LEARN_ICON_CONTAINER_SIZE}
                    displayFlex
                >
                    <CinemaIcon />
                </IconContainer>
            ),
            title: t('learn.creatorTips.title'),
            description: t('learn.creatorTips.description')
        },
        {
            id: 3,
            icon: (
                <IconContainer
                    color={theme.palette.lightLavender}
                    size={LEARN_ICON_CONTAINER_SIZE}
                    displayFlex
                >
                    <PolicyIcon />
                </IconContainer>
            ),
            title: t('learn.policies.title'),
            description: t('learn.policies.description')
        },
        {
            id: 4,
            icon: (
                <IconContainer
                    color={theme.palette.skyBlue}
                    size={LEARN_ICON_CONTAINER_SIZE}
                    displayFlex
                >
                    <HelpIcon />
                </IconContainer>
            ),
            title: t('learn.help.title'),
            description: t('learn.help.description')
        }
    ]

    return (
        <>
            <Grid
                container
                direction={'column'}
                sx={{
                    p: '2%'
                }}
                md={8}
                spacing={2}
            >
                <Grid item>
                    <Typography
                        variant='h3'
                        color={theme.palette.contentSecondary}
                    >
                        {t('learn.header')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant='h1'>
                        {t('learn.creator.header')}
                    </Typography>
                </Grid>
                <Grid item sx={{ width: '80%' }}>
                    <SearchField
                        setQueryOptions={() => {}}
                        value={''}
                        id={'search'}
                        ariaLabel='search client'
                        variant={TextFieldVariant.Outlined}
                        placeholder={t('learn.searchField.placeholder')}
                    />
                </Grid>
                <Grid container item spacing={2} direction={'column'}>
                    {cards.map((card) => (
                        <Grid item key={card?.id}>
                            <LearnCard {...card} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </>
    )
}
