import * as yup from 'yup'

export const VideoDetailValidations = {
  title: yup
    .string()
    .max(200, 'errors.max')
    .when('buttonType', ([buttonType], schema) =>
      buttonType !== 'secondary'
        ? schema.required('errors.required').min(3, 'errors.min')
        : yup.string().nullable(),
    ),
  description: yup
    .string()
    .max(1000, 'errors.max')
    .when('buttonType', ([buttonType], schema) =>
      buttonType !== 'secondary'
        ? schema.required('errors.required').nullable()
        : yup.string().notRequired().nullable(),
    ),
  topicIds: yup
    .array()
    .when('buttonType', ([buttonType], schema) =>
      buttonType !== 'secondary'
        ? schema.min(1, 'errors.required')
        : yup.array(),
    ),
}

export const UploadVideoSchema = yup.object().shape({
  url: yup.string().required('errors.video.upload').nullable(),
  tags: yup.array().of(yup.string()).max(7, 'tags.limit'),
  ...VideoDetailValidations,
})
