import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const PlayIcon: React.FC<SvgProps> = ({
    width = 24,
    height = 24,
    color,
    fill
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.backgroundPrimary

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 24 24`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M6 5.85779C6 5.00689 6 4.58144 6.17115 4.34692C6.32026 4.14261 6.54815 4.01618 6.79486 4.00091C7.07804 3.98338 7.41954 4.21938 8.10254 4.69137L16.9907 10.8336C17.555 11.2236 17.8372 11.4186 17.9355 11.6644C18.0215 11.8793 18.0215 12.1207 17.9355 12.3356C17.8372 12.5814 17.555 12.7764 16.9907 13.1664L8.10254 19.3086C7.41954 19.7806 7.07804 20.0166 6.79486 19.9991C6.54815 19.9838 6.32026 19.8574 6.17115 19.6531C6 19.4186 6 18.9931 6 18.1422V5.85779Z'
                stroke={_color}
                fill={'none'}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default PlayIcon
