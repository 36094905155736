import { Dispatch, SetStateAction } from 'react'

export type AdminProfileProps = {
    isCurrentUser?: boolean
    isClientAdmin?: boolean
    isManageAdmin?: boolean
    retrieve?: Function
    setIsUserActive?: Dispatch<SetStateAction<boolean>>
}

export type AdminProfileFormProps = {
    isCurrentUser?: boolean
    clientId?: string
    isClientAdmin?: boolean
}

export enum ClientPortalStatus {
    'Active' = 'ACT',
    'Pending' = 'PEN',
    'Rejected' = 'REJ'
}
