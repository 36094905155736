import { gql } from '@apollo/client'
import { LearnerListFields, MessagesFragment } from '../../fragments'

export const UPDATE_LEARNER_LIST = gql`
  ${MessagesFragment('LearnerListResponse')}
  ${LearnerListFields('LearnerList')}
  mutation updateLearnerList($input: UpdateLearnerListInput!) {
    updateLearnerList(input: $input) {
      ...MessagesFragment
      data {
        ...LearnerListFields
      }
    }
  }
`
