import * as React from 'react'
import { Campuses, EditCampusContainer } from '@containers'
import { ObjectBaseFields } from '@types'
import { RouteLayout } from '@pages'
import { getAccessRoles } from '@utils'

export const ROLES = {
    SUPER_ADMIN: 'Super Admin',
    CLIENT_USER: 'Client User',
    INDIVIDUAL_USER: 'Individual',
    ADMIN: 'Admin',
    ADVISOR: 'Advisor'
}

export const getCampusRoutes = (roles: Array<ObjectBaseFields>) => {
    const accessedRoles = getAccessRoles(roles)
    const isAdmin = accessedRoles?.isAdmin
    const isClientAdmin = accessedRoles?.isClientAdmin

    return [
        {
            path: '',
            component: Campuses,
            label: 'general.campuses',
            canUserAccess: isAdmin || isClientAdmin
        },
        {
            path: ':campusId',
            component: EditCampusContainer,
            isHeaderLink: true,
            canUserAccess: isAdmin || isClientAdmin
            // subRoutes: [
            //     {
            //         path: '',
            //         component: Campuses,
            //         label: 'campus.campusDetails.label',
            //         canUserAccess: isAdmin
            //     },

            //     {
            //         path: 'edit',
            //         component: EditCampusContainer,
            //         label: 'campus.editCampus.label',
            //         isHeaderLink: true,
            //         canUserAccess: isAdmin
            //     }
            // ]
        },
        {
            path: 'new',
            component: EditCampusContainer,
            label: 'campus.addCampus.label',
            isHeaderLink: true,
            canUserAccess: isAdmin || isClientAdmin
        }
    ]
}
