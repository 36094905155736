import React from 'react'
import { SvgProps } from '@types'

const Error403Icon: React.FC<SvgProps> = ({ color }) => (
    <svg
        width='137'
        height='54'
        viewBox='0 0 137 54'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g id='403'>
            <path
                d='M0.834473 40.774V34.114L26.3645 0.739999H36.6505L11.4905 34.114L6.68047 32.634H47.7505V40.774H0.834473ZM29.2505 52.54V40.774L29.5465 32.634V22.2H38.5745V52.54H29.2505Z'
                fill='#959595'
            />
            <path
                d='M72.6671 53.28C68.5724 53.28 64.8724 52.244 61.5671 50.172C58.3111 48.1 55.7457 45.0907 53.8711 41.144C51.9964 37.148 51.0591 32.3133 51.0591 26.64C51.0591 20.9667 51.9964 16.1567 53.8711 12.21C55.7457 8.214 58.3111 5.18 61.5671 3.108C64.8724 1.036 68.5724 0 72.6671 0C76.8111 0 80.5111 1.036 83.7671 3.108C87.0231 5.18 89.5884 8.214 91.4631 12.21C93.3871 16.1567 94.3491 20.9667 94.3491 26.64C94.3491 32.3133 93.3871 37.148 91.4631 41.144C89.5884 45.0907 87.0231 48.1 83.7671 50.172C80.5111 52.244 76.8111 53.28 72.6671 53.28ZM72.6671 44.918C75.0844 44.918 77.1811 44.2767 78.9571 42.994C80.7331 41.662 82.1144 39.6393 83.1011 36.926C84.1371 34.2127 84.6551 30.784 84.6551 26.64C84.6551 22.4467 84.1371 19.018 83.1011 16.354C82.1144 13.6407 80.7331 11.6427 78.9571 10.36C77.1811 9.028 75.0844 8.362 72.6671 8.362C70.3484 8.362 68.2764 9.028 66.4511 10.36C64.6751 11.6427 63.2691 13.6407 62.2331 16.354C61.2464 19.018 60.7531 22.4467 60.7531 26.64C60.7531 30.784 61.2464 34.2127 62.2331 36.926C63.2691 39.6393 64.6751 41.662 66.4511 42.994C68.2764 44.2767 70.3484 44.918 72.6671 44.918Z'
                fill='#959595'
            />
            <path
                d='M115.667 53.28C112.017 53.28 108.44 52.762 104.937 51.726C101.484 50.6407 98.5734 49.1607 96.2054 47.286L100.349 39.812C102.224 41.3413 104.493 42.5747 107.157 43.512C109.821 44.4493 112.609 44.918 115.519 44.918C118.973 44.918 121.661 44.2273 123.585 42.846C125.509 41.4153 126.471 39.4913 126.471 37.074C126.471 34.706 125.583 32.8313 123.807 31.45C122.031 30.0687 119.17 29.378 115.223 29.378H110.487V22.792L125.287 5.18L126.545 8.806H98.7214V0.739999H134.019V7.178L119.219 24.79L114.187 21.83H117.073C123.437 21.83 128.198 23.2607 131.355 26.122C134.562 28.934 136.165 32.56 136.165 37C136.165 39.9107 135.425 42.5993 133.945 45.066C132.465 47.5327 130.196 49.5307 127.137 51.06C124.128 52.54 120.305 53.28 115.667 53.28Z'
                fill='#959595'
            />
        </g>
    </svg>
)

export default Error403Icon
