import { VideoStatus } from '@constants'
import { PendingStatusEnum } from '@dts/constants'

export const renderButtonForRole = (tab, currentData, accessedRoles) => {
    switch (tab) {
        case VideoStatus.Pending:
            if (
                (accessedRoles?.isCoordinator &&
                    currentData?.pendingStatus?.firstLevel ===
                        PendingStatusEnum.Pending) ||
                (accessedRoles?.isModerator &&
                    currentData?.pendingStatus?.secondLevel ===
                        PendingStatusEnum.Pending &&
                    currentData?.pendingStatus?.firstLevel !==
                        PendingStatusEnum.Pending) ||
                (accessedRoles?.isManager &&
                    currentData?.pendingStatus?.thirdLevel ===
                        PendingStatusEnum.Pending)
            )
                return true
            return false
        case VideoStatus.Approved:
            return false
        case VideoStatus.Rejected:
            return false
        case VideoStatus.Reported:
            return accessedRoles?.isManager ? true : false
        default:
            return false
    }
}
