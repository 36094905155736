import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const InsightArrowUpIcon: React.FC<SvgProps> = ({
    color,
    width = 21,
    height = 21
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.brightGreen
    const _fill = color || theme.palette.backgroundPrimary
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 21 21`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g clipPath='url(#clip0_2719_236)'>
                <path
                    d='M18.8333 6.33325L12.2761 12.8904C11.9461 13.2205 11.7811 13.3855 11.5908 13.4473C11.4235 13.5017 11.2432 13.5017 11.0758 13.4473C10.8855 13.3855 10.7205 13.2205 10.3905 12.8904L8.10946 10.6094C7.77945 10.2794 7.61444 10.1144 7.42417 10.0525C7.2568 9.99817 7.07651 9.99817 6.90914 10.0525C6.71887 10.1144 6.55386 10.2794 6.22385 10.6094L2.16666 14.6666M18.8333 6.33325H13M18.8333 6.33325V12.1666'
                    stroke={_color}
                    strokeWidth='2.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_2719_236'>
                    <rect
                        width='20'
                        height='20'
                        fill={_fill}
                        transform='translate(0.5 0.5)'
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
export default InsightArrowUpIcon
