import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Grid, Typography } from '@mui/material'
import { ControlledNativeSelect } from '@components'
import {
    InsightTimeline,
    getInsightTimelineDate,
    mapDropdownOptions
} from '@dts/client-utils'
import { useCustomTheme, useNavigations } from '@hooks'
import { TypographyVariants } from '@constants'
import { InsightHeaderProps } from './types'
import { StyledHeader } from './styles'

export const InsightHeader: FC<InsightHeaderProps> = (props) => {
    const { insightTimeFrame, insightMetaData, headerTitle, backButtonConfig } =
        props || {}
    const { t } = useTranslation()
    const { theme } = useCustomTheme()

    const { backRedirectionComponent } = backButtonConfig
        ? useNavigations({
              backButtonConfig
          })
        : { backRedirectionComponent: <></> }

    return (
        <>
            <StyledHeader container mb={2}>
                <Grid item alignItems={'center'} display={'flex'} mx={2}>
                    {backRedirectionComponent}
                    <Typography
                        variant={TypographyVariants.h2}
                        color={theme.palette.contentPrimary}
                        fontWeight={700}
                    >
                        {headerTitle || t('analytics.yourInsights.label')}
                    </Typography>
                </Grid>
                <Grid mx={2}>
                    <ControlledNativeSelect
                        id='insightTimeFrame'
                        title={getInsightTimelineDate(
                            insightTimeFrame || InsightTimeline.LAST_SEVEN_DAYS
                        )}
                        ariaLabel={t('general.insightTimeFrame')}
                        name='insightTimeFrame'
                        options={mapDropdownOptions(
                            insightMetaData?.insightTimeFrame
                        )}
                    />
                </Grid>
            </StyledHeader>
            <Divider color={theme.palette.contentPrimary} />
        </>
    )
}
