import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SearchField } from '@components/SearchField'
import { setUserData } from '@cacheql'
import { TextFieldVariant, UserRoles, VideoConfigType } from '@constants'
import {
    useLocalStorage,
    useAuth,
    useModalWithGraphQL,
    useVideoConfig,
    useCustomTheme
} from '@hooks'

import {
    Typography,
    Box,
    Divider,
    Grid,
    MenuItem,
    IconButton,
    useMediaQuery,
    Badge,
    Avatar,
    Menu,
    Button
} from '@mui/material'
import {
    ArrowDownIcon,
    LockIcon,
    LogoutIcon,
    NotificationsIcon,
    UserIcon,
    AddIcon,
    PortalIntoIcon,
    InfoIcon
} from '@assets'
import { ConfirmationDialog } from '@components'
import { CHANGE_PASSWORD } from '@dts/graphql'
import {
    ClientPortalContainer,
    NotificationsList,
    NotificationsPopover
} from '@containers'
import { deleteCookie, getAccessRoles, getCookie } from '@utils'
import { AccessibleAction } from '@hoc'
import { IconGrid, StyledButton, StyledTypography } from './styles'
import { UserPermissionEnum } from '@dts/constants'

export const SettingsToolbar = () => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const {
        logout,
        userInfo: { isPortalUser, adminPortalOnlyAccess, roles }
    } = useAuth()

    const navigate = useNavigate()
    const [storedUser, storeLoggedInUser] = useLocalStorage('user-data')

    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )
    const { videoConfig } = useVideoConfig({
        videoConfigType: VideoConfigType.Upload
    })
    const { openDialog } = useModalWithGraphQL(videoConfig)
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
        null
    )

    const { openConfirmationDialog: onChangePassword } = useModalWithGraphQL({
        mutation: CHANGE_PASSWORD,
        mutationName: 'changePassword',
        modalConfig: {
            maxWidth: 'sm',
            confirmationConfig: {
                primaryButtonText: t('general.confirm'),
                header: t('userMenu.changePassword')
            },
            Component: () => (
                <ConfirmationDialog
                    message={t('userMenu.changePassword.confirmation')}
                />
            )
            // hasCancelButton: true
        }
    })

    const { openModalWithForm: portalIntoClient } = useModalWithGraphQL({
        modalConfig: {
            maxWidth: 'sm',
            updationConfig: {
                header: t('userMenu.portalIntoClient'),
                hideFooter: true
            },
            hasCancelButton: false,
            Component: () => <ClientPortalContainer />
        }
    })

    const exitPortal = () => {
        const adminCookie = getCookie('portalInfo')
        storeLoggedInUser({
            roles: adminCookie?.roles,
            clientId: adminCookie?.clientId,
            firstLogin: adminCookie?.firstLogin ?? undefined,
            portal: false
        })
        deleteCookie('portalInfo')
        navigate('/dashboard')
        window.location.reload()
    }

    useEffect(() => {
        adminPortalOnlyAccess && navigate('/portal-client')
    }, [adminPortalOnlyAccess])

    const commonUserProfileOptions = () => {
        // This function and the condition is temporary
        // It will be updated when RBAC is implemented
        const accessedRoles = getAccessRoles(roles)
        const isAdmin = accessedRoles?.isAdmin

        if (!isPortalUser) {
            return [
                ...(isAdmin
                    ? [
                          {
                              label: t('userMenu.portalIntoClient'),
                              icon: () => (
                                  <PortalIntoIcon
                                      color={theme.palette.contentSecondary}
                                  />
                              ),
                              onClick: () => {
                                  portalIntoClient()
                              }
                          }
                      ]
                    : []),
                {
                    label: t('userMenu.changePassword'),
                    icon: LockIcon,
                    onClick: () => {
                        onChangePassword()
                    }
                }
            ]
        } else {
            return []
        }
    }

    const userProfileOptions = [
        {
            label: t('userMenu.userProfile'),
            icon: () => <UserIcon color={theme.palette.contentSecondary} />,
            onClick: () => {
                navigate('/edit-profile')
            }
        },
        ...commonUserProfileOptions(),
        {
            label: t('userMenu.logout'),
            icon: LogoutIcon,
            onClick: () => {
                logout()
            }
        }
    ]

    const handleClose = () => {
        setAnchorElUser(null)
    }

    const handleUpload = () => {
        openDialog({ isDraft: true })
    }

    const setQueryOptions = () => {
        // Global search functionality
    }

    return (
        <Grid container>
            {isPortalUser && (
                <Grid
                    item
                    xs={12}
                    mb={1}
                    p={1}
                    sx={{ backgroundColor: theme.palette.errorDangerMain }}
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    gap={1}
                    maxHeight={'40px'}
                >
                    <InfoIcon color={theme.palette.backgroundPrimary} />
                    <Typography variant='h4'>
                        {t('portal.client.exit.message')}
                    </Typography>
                    <Button
                        variant='outlined'
                        color='secondary'
                        sx={{ height: '10px !Important' }}
                        onClick={() => exitPortal()}
                    >
                        {t('general.exit')}
                    </Button>
                </Grid>
            )}
            <Grid
                container
                item
                display={'flex'}
                justifyContent='space-between'
            >
                <Grid
                    item
                    md={5}
                    xs={7}
                    sx={{
                        bgcolor: theme.palette.backgroundSecondary,
                        borderRadius: '40px',
                        padding: '0px 8px'
                    }}
                >
                    <SearchField
                        value={''}
                        id={'search'}
                        ariaLabel={t('general.globalSearch')}
                        variant={TextFieldVariant.Standard}
                        placeholder={t('searchField.placeholder')}
                    />
                </Grid>

                <Grid item gap={2} display={'flex'} alignItems={'center'}>
                    {!isMediumScreen && (
                        <AccessibleAction
                            permissions={[UserPermissionEnum.VideoUpload]}
                            component={
                                <StyledButton
                                    aria-label={t('general.add.ariaLabel', {
                                        ariaLabel: t('general.video')
                                    })}
                                    variant='outlined'
                                    startIcon={
                                        <AddIcon
                                            color={
                                                theme.palette
                                                    .backgroundBrandPrimary
                                            }
                                        />
                                    }
                                    size='medium'
                                    onClick={() => handleUpload()}
                                    sx={{
                                        borderColor:
                                            theme.palette.backgroundInfo
                                    }}
                                >
                                    <Typography
                                        variant='h4'
                                        sx={{
                                            marginLeft: '-10px',
                                            color: theme.palette
                                                .backgroundBrandPrimary
                                        }}
                                    >
                                        {t('general.video')}
                                    </Typography>
                                </StyledButton>
                            }
                        />
                    )}

                    <NotificationsPopover />
                    <Box sx={{ flexGrow: 1 }}>
                        <IconButton
                            onClick={(event: React.MouseEvent<HTMLElement>) =>
                                setAnchorElUser(event.currentTarget)
                            }
                            sx={{ p: 0 }}
                            aria-label={t('sideNavItems.userProfile')}
                        >
                            <Badge
                                overlap='circular'
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                                color='primary'
                                badgeContent={
                                    <ArrowDownIcon
                                        color={
                                            theme.palette.contentPrimaryInverse
                                        }
                                    />
                                }
                            >
                                <Avatar
                                    sx={{
                                        border: `1px solid ${theme.palette.borderGrey}`
                                    }}
                                    src={setUserData()?.avatarSrc}
                                    alt=''
                                />
                            </Badge>
                        </IconButton>
                        <Menu
                            sx={{
                                mt: '35px'
                            }}
                            id='menu-appbar'
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={() => handleClose()}
                        >
                            {userProfileOptions?.map((option, index) => (
                                <MenuItem
                                    key={option?.label}
                                    onClick={() => {
                                        handleClose()
                                        option.onClick?.()
                                    }}
                                    tabIndex={0}
                                    aria-label={option?.label}
                                    sx={{
                                        padding: '16px'
                                    }}
                                >
                                    <option.icon />
                                    <StyledTypography>
                                        {option?.label}
                                    </StyledTypography>
                                    {index ===
                                        userProfileOptions.length - 2 && (
                                        <Divider
                                            sx={{ margin: '0 !important' }}
                                        />
                                    )}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}
