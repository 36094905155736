import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const PortalIntoIcon: React.FC<SvgProps> = ({
    color,
    width = '24',
    height = '24'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 24 24'
            fill='none'
        >
            <path
                d='M16.6658 3H17.1658C18.5636 3 19.2625 3 19.8138 3.22836C20.5489 3.53284 21.1329 4.11687 21.4374 4.85195C21.6658 5.40326 21.6658 6.10217 21.6658 7.5V16.5C21.6658 17.8978 21.6658 18.5967 21.4374 19.1481C21.1329 19.8831 20.5489 20.4672 19.8138 20.7716C19.2625 21 18.5636 21 17.1658 21H16.6658M10.4993 17.1048L15.604 12M15.604 12L10.4993 6.8952M15.604 12L3.35254 12'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default PortalIntoIcon
