import React from 'react'
import { useLocation } from 'react-router-dom'
import { CreateReport } from '@containers/report'

// Custom hook to force re-render on navigation
const useForceUpdateOnNavigation = () => {
    const location = useLocation() // Get current location
    const [key, setKey] = React.useState(location.key) // Initialize state with location key

    React.useEffect(() => {
        // Update state with new location key on location change
        setKey(location.key)
    }, [location])

    return key // Return the current location key
}

export const WrapperComponent = ({
    children
}: {
    children: React.ReactNode
}) => {
    const locationKey = useForceUpdateOnNavigation() // Get the current location key from the custom hook
    return <div key={locationKey}>{children}</div> // Use the location key as the key for the div to force re-render
}

export const ReportingPage: React.FC = () => (
    <WrapperComponent>
        <CreateReport />
    </WrapperComponent>
)
