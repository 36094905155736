import React from 'react'
import { SvgProps } from '@types'

const VideoIcon: React.FC<SvgProps> = ({
    color = '#35383E',
    width = '24',
    height = '24'
}) => (
    <svg
        width={width}
        height={height}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M7.8 21C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8M12.5 9.5L10.7326 8.29239C10.3311 8.03429 10.1304 7.90525 9.96438 7.9171C9.81971 7.92744 9.68666 8.00007 9.59974 8.11618C9.5 8.24941 9.5 8.48805 9.5 8.96533V15.0347C9.5 15.512 9.5 15.7506 9.59974 15.8838C9.68666 15.9999 9.81971 16.0726 9.96438 16.0829C10.1304 16.0948 10.3311 15.9657 10.7326 15.7076L12 15'
            stroke={color}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M17 17.3333C15.1034 17.3333 13.4168 18.2687 12.343 19.7203C12.1119 20.0327 11.9963 20.1889 12.0001 20.4001C12.003 20.5632 12.1033 20.7689 12.2289 20.8696C12.3916 21 12.6169 21 13.0676 21H20.9324C21.3831 21 21.6084 21 21.7711 20.8696C21.8967 20.7689 21.997 20.5632 21.9999 20.4001C22.0037 20.1889 21.8881 20.0327 21.657 19.7203C20.5832 18.2687 18.8966 17.3333 17 17.3333Z'
            stroke={color}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M17 15.5C18.4869 15.5 19.6923 14.2688 19.6923 12.75C19.6923 11.2312 18.4869 10 17 10C15.5131 10 14.3077 11.2312 14.3077 12.75C14.3077 14.2688 15.5131 15.5 17 15.5Z'
            stroke={color}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
)

export default VideoIcon
