import React, { FC } from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const PlusSquareOutlineIcon: FC<SvgProps> = ({
    color,
    width = '24',
    height = '25'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.backgroundBrandPrimary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 24 25'
            fill='none'
        >
            <path
                d='M19 3.5H5C3.89543 3.5 3 4.39543 3 5.5V19.5C3 20.6046 3.89543 21.5 5 21.5H19C20.1046 21.5 21 20.6046 21 19.5V5.5C21 4.39543 20.1046 3.5 19 3.5Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12 8.5V16.5'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M8 12.5H16'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default PlusSquareOutlineIcon
