import React from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { FieldErrors } from '@components'
import { Grid } from '@mui/material'
import { Input } from './text-input'

export const TextInput = (props) => {
    const { register, trigger } = useFormContext()

    const {
        fieldState: { error }
    } = useController({
        name: props?.name
    })

    return (
        <Grid container flexDirection='column'>
            <Grid item width={'100%'}>
                <Input
                    {...props}
                    errors={error}
                    trigger={trigger}
                    register={register(props?.name)}
                    sx={{ marginBottom: '0px' }}
                />
            </Grid>

            {error && (
                <Grid item alignSelf={'flex-start'}>
                    <FieldErrors
                        error={error}
                        errorPosition={props?.errorPosition}
                        name={props?.name}
                    />
                </Grid>
            )}
        </Grid>
    )
}
