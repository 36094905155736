import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const READ_NOTIFICATION = gql`
  ${MessagesFragment('NotificationResponseDto')}
  ${MetaFragment('NotificationResponseDto')}
  mutation readNotification($input: ReadNotificationInputDto!) {
    readNotification(input: $input) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
        body {
          title
          message
          referenceId
        }
        notificationCategoryId
        isSeen
        isRead
        sentAt
      }
    }
  }
`
