import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const TickIcon: React.FC<SvgProps> = ({
    color,
    height = '20',
    width = '21'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 21 20'
            fill='none'
        >
            <path
                d='M17.1663 5L7.99967 14.1667L3.83301 10'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default TickIcon
