import { gql } from '@apollo/client'

export const LearnerListFields = (on: string) => gql`
    fragment LearnerListFields on ${on} {
        id
        title
        description
        learnersCount
        updatedAt
        isArchived
        trainings{
            id
            title
        }
    }
`
