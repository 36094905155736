import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, ReportFragment } from '../../fragments'

export const GET_REPORT_BY_ID = gql`
  ${MessagesFragment('ReportResponseDto')}
  ${MetaFragment('ReportResponseDto')}
  ${ReportFragment('ReportDto')}
  query getReport($getReportId: String!) {
    getReport(id: $getReportId) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...ReportFragment
      }
    }
  }
`
