import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, CourseFields } from '../../fragments'

export const SAVE_CLIENT_COURSES = gql`
  ${MessagesFragment('ClientCoursesResponseDto')}
  ${MetaFragment('ClientCoursesResponseDto')}
  ${CourseFields('CourseDto')}
  mutation saveClientCourses($input: SaveClientCoursesDto!) {
    saveClientCourses(input: $input) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...CourseFields
      }
    }
  }
`
