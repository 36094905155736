import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Controller,
    useFieldArray,
    useFormContext,
    useWatch
} from 'react-hook-form'
import {
    ControlledCheckBox,
    ControlledMultiSelectAutoComplete,
    ListInput,
    SearchableList,
    BaseContainer,
    ComponentContainer,
    ParentContainer,
    TypographyContainer,
    SearchableTags
} from '@components'
import { Button, Checkbox, Grid, Typography } from '@mui/material'
import { ListType } from '@constants'
import { useCustomTheme, useGraphQLWrapper, useModalWithGraphQL } from '@hooks'

import { ControlledMultipleCheckboxes } from '@components/CheckboxGroup'
import { GET_SKILLS, GET_TAGS } from '@dts/graphql/lib'
import { mapDropdownOptions } from '@dts/client-utils'

export const VerticalFilters: React.FC = () => {
    const { theme } = useCustomTheme()
    const { t } = useTranslation()
    const { getValues } = useFormContext()

    const { data: skillsData } = useGraphQLWrapper({
        query: GET_SKILLS,
        queryName: 'getSkills',
        retrieveOnMount: true
    })

    return (
        <ParentContainer container>
            <Typography variant='h1'>{getValues('name')}</Typography>
            <BaseContainer item>
                <TypographyContainer item md={2.5}>
                    <Typography sx={{ color: theme.palette.contentPrimary }}>
                        {t('videoDetails.skillLevel.label')}
                    </Typography>
                    <Typography variant='body2Medium'>
                        {t('vertical.skillCaption')}
                    </Typography>
                </TypographyContainer>
                <ComponentContainer item md={9.5}>
                    <ControlledMultipleCheckboxes
                        options={mapDropdownOptions(skillsData)}
                        name={'skills'}
                    />
                </ComponentContainer>
            </BaseContainer>
            <BaseContainer item>
                <TypographyContainer item md={2.5}>
                    <Typography variant='h3'>{t('general.tags')}</Typography>
                    <Typography variant='body2Medium'>
                        {t('vertical.tagsCaption')}
                    </Typography>
                </TypographyContainer>

                <ComponentContainer item md={9.5}>
                    <Grid container md={8} xs={12}>
                        <SearchableTags name='tags' customDataName='tags' />
                    </Grid>
                </ComponentContainer>
            </BaseContainer>
        </ParentContainer>
    )
}
