import { Quill } from 'react-quill'
import { isObject } from 'lodash'
import { VideoAttributes } from '../../types'

const VideoBlot = (function (node) {
    const BlockEmbed = Quill.import('blots/block/embed')
    class Video extends BlockEmbed {
        static create(params: string | object) {
            const node = super.create() as HTMLElement
            if (isObject(params)) {
                const { id, src } = params as VideoAttributes
                node.setAttribute('data-video-id', id)
                node.style.maxWidth = '800px'

                node.innerHTML = `<video controls src="${src}" class="video-container"></video>`
            } else {
                node.innerHTML = `<iframe controls src="${params}" class="video-container"></iframe>`
            }

            node.classList.add('video-container')
            return node
        }

        static value(node: HTMLElement) {
            const video = node.querySelector('video')
            if (video) {
                video.style.maxWidth = '800px'
            }

            if (node.getAttribute('data-video-id')) {
                return {
                    id: node.getAttribute('data-video-id'),
                    src: node.querySelector('video')?.getAttribute('src')
                }
            } else {
                return node.querySelector('iframe')?.getAttribute('src')
            }
        }
    }
    Video.blotName = 'video'

    Video.tagName = 'div'

    Video.className = 'video-container'

    return Video
})(Quill)

Quill.register('formats/video', VideoBlot)
