enum NotificationTypeEnum {
  Current = 'CUR',
  Scheduled = 'SCH',
  Repeated = 'REP',
}

enum NotificationTypeGraphqlEnum {
  CUR = 'CUR',
  SCH = 'SCH',
  REP = 'REP',
}

export { NotificationTypeEnum, NotificationTypeGraphqlEnum }
