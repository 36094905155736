import * as yup from 'yup'

export const TopicListSchema = yup.object().shape({
  name: yup
    .string()
    .when('shouldValidateName', ([shouldValidateName]) =>
      shouldValidateName
        ? yup.string().trim().required('errors.required').max(200, 'errors.max')
        : yup.string(),
    ),
  topics: yup.array().of(
    yup.object().shape({
      isAdded: yup.boolean(),
      isAssigned: yup.boolean(),
      duration: yup
        .string()
        .when(['isAdded', 'isAssigned'], ([isAdded, isAssigned], schema) =>
          isAdded && isAssigned
            ? yup
                .string()
                .required('courses.durationRequired')
                .min(1, 'courses.durationRequired')
                .test({
                  test: (value) => value !== '0',
                  message: 'errors.durationGreaterThanZero',
                })
            : yup.string().nullable(),
        ),
    }),
  ),
})
