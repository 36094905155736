import styled from '@emotion/styled'
import { Typography } from '@mui/material'

export const StyledTypography = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '120%',
    color: theme.palette.contentPrimary
}))

export const StyledTypographyHelper = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '140%',
    color: theme.palette.contentPrimary,
    whiteSpace: 'pre-line'
}))
