import { MessagesFragment, MetaFragment } from '../../fragments'

import { gql } from '@apollo/client'

export const DUPLICATE_LEARNER_LIST = gql`
  ${MessagesFragment('LearnerListResponse')}
  ${MetaFragment('LearnerListResponse')}
  mutation duplicateLearnerList($input: UpdateLearnerListInput!) {
    duplicateLearnerList(input: $input) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
      }
    }
  }
`
