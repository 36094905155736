import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { NotFoundPage, ProtectedLayoutPage } from '@pages'
import { useRoutes } from '@hooks'
import { withAcessControl } from '@hoc'
import { ProtectedRoute } from './protected-route'

export const Routing: React.FC = () => {
    const { centralRoutes, publicRoutes } = useRoutes()
    const getRoutes = (route) => (
        <Route
            key={route.path}
            path={route.path}
            element={
                <ProtectedRoute>
                    {withAcessControl(
                        route?.canUserAccess ?? true,
                        route?.permissions ? route.permissions : true
                    )(<route.component {...route.componentProps} />)}
                </ProtectedRoute>
            }
        >
            {route?.subRoutes?.map((subRoute) => getRoutes(subRoute))}
        </Route>
    )

    return (
        <Routes>
            {publicRoutes &&
                publicRoutes?.map(({ path, Component, props = {} }, index) => (
                    <Route
                        key={index}
                        path={path}
                        element={Component ? <Component {...props} /> : <></>}
                    />
                ))}

            <Route
                path=''
                element={
                    <ProtectedRoute>
                        <ProtectedLayoutPage />
                    </ProtectedRoute>
                }
            >
                {centralRoutes()?.map((route) => getRoutes(route))}
                <Route path='/*' element={<NotFoundPage />}></Route>
            </Route>
            <Route path='/*' element={<NotFoundPage />}></Route>
        </Routes>
    )
}

export * from './routes'
