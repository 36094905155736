import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const PulseAnswerCheckedIcon: React.FC<SvgProps> = ({
    color,
    height = '20',
    width = '20'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 20 20'
            fill='none'
        >
            <path
                d='M6.5 17.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V7.33333C2.5 5.9332 2.5 5.23313 2.77248 4.69836C3.01217 4.22795 3.39462 3.8455 3.86502 3.60582C4.3998 3.33333 5.09987 3.33333 6.5 3.33333H12.6667C14.0668 3.33333 14.7669 3.33333 15.3016 3.60582C15.772 3.8455 16.1545 4.22795 16.3942 4.69836C16.6667 5.23313 16.6667 5.9332 16.6667 7.33333V9.16667M13.3333 7.5H5.83333M10 10.8333H5.83333'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M17.4998 12.5L11.7707 18.3333L9.1665 15.6818'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default PulseAnswerCheckedIcon
