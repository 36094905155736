import React, { useEffect, useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
    Autocomplete as MuiAutoComplete,
    TextField,
    AutocompleteGetTagProps,
    Paper
} from '@mui/material'
import { OptionType, SmartSelectProps } from '@types'
import { controlled } from '@hoc'
import { KeyboardShortcut } from '@constants'
import { isEmpty } from 'lodash'
import { StyledChip } from '@components/Input'
import { useCustomTheme } from '@hooks'

export const MultiSelectAutoComplete: React.FC<SmartSelectProps> = ({
    options,
    name,
    value,
    onChange: onFormChange,
    placeholder,
    label,
    errors,
    width,
    allowCustomAddition = false,
    customDataName,
    sx,
    loading,
    disabled,
    ...props
}) => {
    // maxElements code in this file may be used later
    const { setValue, control } = useFormContext()
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const inputRef = useRef(null)
    const [selectedValue, setSelectedValue] = useState<Array<string>>(
        value as Array<string>
    )
    const { ...formContext } = useFormContext()
    const [customOptions, setCustomOptions] = useState(options)
    const handleDelete = (id?: string) => {
        setSelectedValue(
            (value as Array<string>)?.filter((option: string) => option !== id)
        )
        onFormChange(
            (value as Array<string>)?.filter((option: string) => option !== id)
        )
        if (allowCustomAddition && customData?.length) {
            setValue(customDataName as string, [
                ...customData.filter((option: string) => option !== id)
            ])
        }
    }

    const customData: Array<string> =
        useWatch({ control, name: customDataName as string }) ?? []
    useEffect(() => {
        setSelectedValue(value)
    }, [value])

    useEffect(() => {
        let newOptions: Array<OptionType> = options ?? []
        if (allowCustomAddition) {
            const optionsToAdd = customData?.filter(
                (customValue: string) =>
                    !options.find((option) => option.label === customValue)
            )

            newOptions = [
                ...options,
                ...(optionsToAdd
                    ? [
                          ...optionsToAdd.map((customValue: string) => ({
                              label: customValue,
                              value: customValue,
                              type: 'custom'
                          }))
                      ]
                    : [])
            ]
        }
        setCustomOptions([...newOptions])
    }, [options])
    const getLabel = (val) =>
        customOptions.find((opt) => opt.value === val)?.label

    const customFilterOptions = (options, { inputValue }) => {
        // Custom filter logic: Filter options that include the input value in their label
        const filteredOptions = options.filter((option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        )
        const selectedOptions = filteredOptions.filter(
            (opt) => !selectedValue?.includes(opt.value)
        )

        return selectedOptions
    }

    return (
        <>
            <MuiAutoComplete
                sx={{
                    marginTop: 2.5,
                    width: width ?? '100%',
                    background: theme.palette.backgroundPrimary,
                    '& .css-iyeqkd-MuiSvgIcon-root': {
                        color: theme.palette.contentSecondary
                    },
                    ...sx
                }}
                disabled={formContext?.formEditingState?.isReadOnly || disabled}
                multiple
                id={`auto-complete-${name}`}
                options={customOptions ?? []}
                value={selectedValue}
                renderTags={(
                    option: Array<string>,
                    getTagProps: AutocompleteGetTagProps
                ) =>
                    option.map((opt, index) => {
                        const { onDelete } = getTagProps({ index })
                        return (
                            <StyledChip
                                key={opt}
                                label={getLabel(opt) ?? ''}
                                onDelete={(e) => {
                                    onDelete(e)
                                    e.preventDefault()
                                    handleDelete(opt)
                                }}
                                sx={{ mr: 1 }}
                            />
                        )
                    })
                }
                onChange={(e, newValue: Array<string>, reason) => {
                    // if (
                    //     !maxElements ||
                    //     (maxElements && selectedValue?.length < maxElements)
                    // ) {
                    let newArray: Array<string>
                    if (!newValue?.length) {
                        newArray = newValue
                    } else {
                        const newSelectedValue =
                            newValue[newValue?.length - 1]?.value
                        if (allowCustomAddition) {
                            if (
                                customOptions?.find(
                                    (option) =>
                                        newSelectedValue === option.label
                                )?.type === 'custom'
                            ) {
                                setValue(customDataName as string, [
                                    ...customData,
                                    newSelectedValue
                                ])
                            }
                        }

                        newArray = [...selectedValue, newSelectedValue]
                    }
                    setSelectedValue(newArray)
                    onFormChange(newArray)
                    // }
                }}
                filterOptions={customFilterOptions}
                // below PaperComponent is used to change the background color and text color of option list
                PaperComponent={({ children }) => (
                    <Paper
                        sx={{
                            background: theme.palette.backgroundSecondary,
                            color: theme.palette.contentPrimary
                        }}
                    >
                        {children}
                    </Paper>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputRef={inputRef}
                        variant='outlined'
                        label={label}
                        id={`auto-complete-input-${name}`}
                        // disabled={
                        //     maxElements && selectedValue?.length >= maxElements
                        // }
                        onKeyDown={(e) => {
                            const {
                                key,
                                target: { value: newValue }
                            } = e
                            if (
                                key === KeyboardShortcut.Enter &&
                                !isEmpty(newValue?.trim())
                            ) {
                                // if (
                                //     (maxElements &&
                                //         selectedValue?.length < maxElements) ||
                                //     !maxElements
                                // ) {
                                const filteredValue = customOptions.find(
                                    (opt) =>
                                        opt.label?.toLowerCase() ===
                                        newValue?.toLowerCase()
                                )?.value

                                if (!selectedValue?.includes(filteredValue)) {
                                    if (filteredValue) {
                                        onFormChange([
                                            ...(value as Array<string>),
                                            filteredValue as string
                                        ])
                                        setSelectedValue([
                                            ...(value as Array<string>),
                                            filteredValue as string
                                        ])
                                    } else if (
                                        newValue?.length &&
                                        allowCustomAddition
                                    ) {
                                        setValue(customDataName as string, [
                                            ...customData,
                                            newValue
                                        ])
                                        onFormChange([
                                            ...(value as Array<string>),
                                            newValue as string
                                        ])
                                        setCustomOptions([
                                            ...customOptions,
                                            {
                                                label: newValue,
                                                value: newValue,
                                                type: 'custom'
                                            }
                                        ])
                                    }
                                } else {
                                    inputRef.current.value = ''
                                }
                                // }
                            }
                            if (key === KeyboardShortcut.Backspace) {
                                e.stopPropagation()
                            }
                        }}
                        error={!!errors}
                        inputProps={{
                            ...params.inputProps,
                            'aria-label': label || name,
                            'aria-labelledby': `label-${name}`
                        }}
                        InputLabelProps={{
                            shrink: true,
                            id: `label-${name}`
                        }}
                    />
                )}
            />
            {/* {maxElements && (
                <Typography
                    fontWeight={'400'}
                    fontSize={'0.75rem'}
                    color={theme.palette.neutralsTextLight}
                    pt={1}
                    pl={1}
                >
                    {t('tags.limit')}
                </Typography>
            )} */}
        </>
    )
}

export const ControlledMultiSelectAutoComplete = controlled(
    MultiSelectAutoComplete
)
