import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const ActiveIcon: React.FC<SvgProps> = ({ color, width = 20, height = 20 }) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.activeGreen

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 20 20`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <circle cx='10' cy='10.5' r='5' fill={_color} />
        </svg>
    )
}
export default ActiveIcon
