import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import {
    useCustomTheme,
    useFormFeedback,
    useGraphQLWrapper,
    usePage
} from '@hooks'
import { isMediumScreen } from '@utils'
import {
    GET_DISPLAY_MODE,
    GET_PREFERENCE_SETTING,
    SAVE_PREFERENCE_SETTING
} from '@dts/graphql'
import { ControlledRadioGroup } from '@components'
import { HalfMoonIcon, SettingsIcon, SunIcon } from '@assets'
import { Typography } from '@mui/material'
import { BackButtonType } from '@constants'

export const ChangeDisplayMode = ({ onSave }) => {
    const { t } = useTranslation()
    const { control, formState } = useFormContext()
    const displayMode = useWatch({ control, name: 'displayMode' })
    const { data: displayModes } = useGraphQLWrapper({
        query: GET_DISPLAY_MODE,
        queryName: 'getDisplayModes',
        retrieveOnMount: true
    })

    const displayIcons = [HalfMoonIcon, SunIcon, SettingsIcon]

    useEffect(() => {
        formState.isDirty && onSave?.()
    }, [displayMode])
    return (
        <>
            <ControlledRadioGroup
                name='displayMode'
                isButton
                hasStartLabelPlacement
                options={displayModes?.map((displayMode, index) => ({
                    value: displayMode?.id,
                    label: displayMode?.title,
                    radioIcon: displayIcons[index]
                }))}
            />
            <Typography variant='body2' pt={2}>
                {t('settings.display.description')}
            </Typography>
        </>
    )
}

export const DisplaySetting = () => {
    const { t } = useTranslation()
    const { toggleTheme } = useCustomTheme()
    const { methods, save, mutationLoading, queryLoading } = useFormFeedback({
        mutation: SAVE_PREFERENCE_SETTING,
        mutationName: 'savePreferenceSetting',

        mapFormToMutationInput: (form) => {
            toggleTheme(form?.displayMode)
            return { displayModeId: form?.displayMode }
        },
        query: GET_PREFERENCE_SETTING,
        queryName: 'getPreferenceSetting',
        mapQueryResultToForm: (response) => ({
            displayMode: response?.displayMode?.id
        }),
        retrieveOnMount: true,
        suppressToast: true
    })

    useEffect(
        () => () => {
            // Cleanup function (will be called on unmount)
            save()
        },
        []
    )

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: ChangeDisplayMode,
            componentProps: { onSave: save },
            hasHeader: true,
            preventMargin: true,
            backButtonConfig: {
                showBackButton: isMediumScreen?.(),
                backButtonType: BackButtonType.Hierarchical
            },
            pageHeaderConfig: {
                headerText: t('setting.display')
            }
        }
    })

    return <>{page}</>
}
