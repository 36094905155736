import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useController } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import { Grid, Button, Typography } from '@mui/material'
import { DragAndDropIcon, UploadFileIcon } from '@assets'
import { useFileUpload } from '@hooks'
import { FieldErrors } from '@components/FieldErrors'
import { Loader } from '@components'
import { DragAndDropProps } from './types'
import {
    BrowseGridContainer,
    DragAndDropContainer,
    DragAndDropInnerGrid
} from './styles'

export const DragAndDrop: React.FC = ({
    name = 'fileUploader',
    acceptedFileTypes,
    acceptedFileExtensions
}: DragAndDropProps) => {
    const { t } = useTranslation()
    const { getValues, setError } = useFormContext()
    const { thumbnail } = getValues()
    const handleUploadThumbnail = () => {
        document?.getElementById('thumbnail-upload')?.click()
    }
    const {
        fieldState: { error }
    } = useController({ name })

    const { fileUploadComponent, removeFile, fileSelect, isUploadingStart } =
        useFileUpload({
            name: name,
            displayFile: thumbnail ? true : false,
            chipView: true,
            typeAccepted: '.csv,.tsv',
            inputLabel: 'thumbnail-upload',
            uploadButton: (
                <label
                    htmlFor='thumbnail-upload'
                    style={{
                        cursor: 'pointer'
                    }}
                >
                    <Grid
                        container
                        direction={'column'}
                        justifyContent={'center'}
                    >
                        <Grid item display={'flex'} justifyContent={'center'}>
                            <Button
                                variant='outlined'
                                color='primary'
                                startIcon={
                                    <UploadFileIcon width='20' height='21' />
                                }
                                onClick={handleUploadThumbnail}
                            >
                                {t('learnerList.browseFile')}
                            </Button>
                        </Grid>
                        <Grid
                            item
                            py={2}
                            display={'flex'}
                            justifyContent={'center'}
                        >
                            <Typography variant='caption'>
                                {t('learnerList.uploadMessage')}
                            </Typography>
                        </Grid>
                    </Grid>
                </label>
            )
        })

    const onDrop = (acceptedFiles) => {
        removeFile()

        let filteredFiles = acceptedFiles

        if (acceptedFileTypes?.length || acceptedFileExtensions?.length) {
            filteredFiles = acceptedFiles.filter(
                (file) =>
                    acceptedFileTypes?.includes(file.type) ||
                    acceptedFileExtensions?.some((extension) =>
                        file.name.endsWith(extension)
                    )
            )
        }

        if (filteredFiles.length > 0) {
            const file: File = filteredFiles[0]
            const fileUrl = URL.createObjectURL(filteredFiles[0])
            fileSelect(file, fileUrl)
        } else {
            setError(name, {
                type: 'manual',
                message: t('learnerList.fileFormat.error')
            })
        }
    }
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        noClick: true
    })

    return (
        <Grid container height={'210px'}>
            <BrowseGridContainer
                md={4}
                xs={6}
                mt={2}
                item
                mr={2}
                sx={{ position: 'relative' }}
            >
                {isUploadingStart && <Loader />}
                <Grid flexDirection='column' container md={12} px={2} py={2}>
                    <Grid item display={'flex'} justifyContent={'center'}>
                        {fileUploadComponent}
                    </Grid>

                    <Grid item display={'flex'} justifyContent={'center'}>
                        {error && <FieldErrors error={error} name={name} />}
                    </Grid>
                </Grid>
            </BrowseGridContainer>
            <DragAndDropContainer
                item
                mt={2}
                md={4}
                xs={6}
                sx={{ position: 'relative' }}
            >
                {isUploadingStart && <Loader />}
                <DragAndDropInnerGrid
                    container
                    md={12}
                    px={2}
                    py={2}
                    {...getRootProps()}
                >
                    <input
                        {...getInputProps()}
                        style={{ display: 'none' }}
                        type='file'
                        accept={acceptedFileTypes}
                        id={'thumbnail-upload'}
                    />

                    <Grid item>
                        <DragAndDropIcon />
                    </Grid>
                    <Grid item>
                        <Typography variant='h2'>
                            {t('general.dragAndDrop')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='caption' mt={1}>
                            {t('learnerList.dragAndDrop.caption')}
                        </Typography>
                    </Grid>
                </DragAndDropInnerGrid>
            </DragAndDropContainer>
        </Grid>
    )
}
