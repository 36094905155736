import React from 'react'
import styled from '@emotion/styled'

export const StyledImage = styled.img`
    border-radius: 6px;
    object-fit: contain;
    opacity: ${(props) => props.customOpacity};
    height: 100%;
    width: 100%;
`
