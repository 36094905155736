import { gql } from '@apollo/client'
import { disableFragmentWarnings } from 'graphql-tag'

disableFragmentWarnings()

export const MetaFragment = (on: string) => gql`
    fragment MetaFragment on ${on} {
       meta {
        count
        verified
     }
    }
`
