import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
    useCustomTheme,
    useGraphQLWrapper,
    useLoader,
    useModalWithGraphQL
} from '@hooks'
import { t } from 'i18next'
import { Button, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import {
    DeleteIcon,
    InsightsIcon,
    PencilIcon,
    PlusIcon,
    UserIcon
} from '@assets'
import {
    DELETE_REPORT_LIBRARY,
    GET_REPORTS_BY_LIBRARY_ID,
    GET_REPORT_LIBRARIES,
    SAVE_REPORT_LIBRARY
} from '@dts/graphql/lib'
import {
    ConfirmationDialog,
    Duplicate,
    EmptyState,
    KebabMenu,
    Loader,
    Panel
} from '@components'
import { REPORT_LIBRARY, TooltipPlacement } from '@constants'
import { ReportTitleSchema } from '@dts/client-utils'
import { onKeyDown } from '@utils'
import { AccessibleAction } from '@hoc'
import { UserPermissionEnum } from '@dts/constants'
import { LibraryReports } from './library-reports'

export const ManageReports = ({ status }: { status: string }) => {
    const navigate = useNavigate()
    const { theme } = useCustomTheme()
    const { loading } = useLoader()
    const [searchParams] = useSearchParams()
    const reportType = searchParams.get('reportType')

    useEffect(() => {
        if (reportType) {
            navigate(`/reports/${reportType}`)
        }
    }, [reportType])
    const {
        queryLoading,
        currentData: reportLibraries,
        retrieve
    } = useGraphQLWrapper({
        query: GET_REPORT_LIBRARIES,
        queryName: 'getAllReportLibraries',
        retrieveOnMount: false,
        queryVariables: {
            args: {
                tab: status
            }
        }
    })

    useEffect(() => {
        retrieve?.({
            variables: {
                args: {
                    tab: status
                }
            }
        })
    }, [status])

    const { openConfirmationDialog: deleteLibrary } = useModalWithGraphQL({
        mutation: DELETE_REPORT_LIBRARY,
        mutationName: 'deleteReportLibrary',
        onMutationSuccess: () => {
            retrieve?.({
                variables: {
                    args: {
                        tab: status
                    }
                }
            })
        },
        isCustomMutationInput: true,

        modalConfig: {
            maxWidth: 'sm',
            Component: () => (
                <ConfirmationDialog
                    message={t('delete.confirmation.message')}
                />
            ),
            confirmationConfig: {
                primaryButtonText: t('general.confirm'),
                header: `${t('general.confirmation')}`
            },
            hasCancelButton: true
        }
    })

    const { openPrepopulatedDialog: saveReportLibrary } = useModalWithGraphQL({
        mutation: SAVE_REPORT_LIBRARY,
        mutationName: 'saveReportLibrary',
        retrieveOnMount: false,
        isCustomMutationInput: true,
        mapFormToMutationInput: (formValues) => ({
            input: {
                id: formValues?.id,
                title: formValues?.title
            }
        }),
        onMutationSuccess: (response) => {
            retrieve?.({
                variables: {
                    args: {
                        tab: status
                    }
                }
            })
        },
        initialValues: { title: null },
        modalConfig: {
            maxWidth: 'sm',
            Component: Duplicate,
            updationConfig: {
                primaryButtonText: t('general.save'),
                header: t('report.updateLibraries')
            },
            hasCancelButton: true
        },
        yupResolverSchema: ReportTitleSchema
    })

    const customAccordionActions = (id, title) => [
        {
            icon: PencilIcon,
            label: t('general.rename'),
            onClick: () => {
                saveReportLibrary(undefined, undefined, { id, title })
            },
            permissions: [UserPermissionEnum.ReportCreateModify]
        },
        {
            icon: DeleteIcon,
            label: t('general.delete'),
            onClick: () => {
                deleteLibrary({ id })
            },
            permissions: [UserPermissionEnum.ReportDelete]
        }
    ]

    const getPanels = (panels: Array<any>) =>
        panels?.map((panel, index: number) => ({
            id: panel?.id,
            ...(status === REPORT_LIBRARY.SYSTEM && { header: panel?.title }),
            // To-do: This will be removed later on
            // headerIcon: true,
            ...(status === REPORT_LIBRARY.CUSTOM && {
                renderElementAtTitle: () => (
                    <Grid container alignItems={'center'}>
                        <Grid item md={10} xs={8}>
                            <Typography variant='body2'>
                                {panel?.title}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            display={'flex'}
                            md={2}
                            xs={4}
                            pr={1}
                            justifyContent={'flex-end'}
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                        >
                            <AccessibleAction
                                permissions={[
                                    UserPermissionEnum.ReportCreateModify
                                ]}
                                component={
                                    <Grid
                                        item
                                        mr={1}
                                        alignItems={'center'}
                                        display={'flex'}
                                    >
                                        <Tooltip
                                            title={[
                                                t('general.edit'),
                                                t('general.report')
                                            ].join(' ')}
                                            placement={TooltipPlacement.topEnd}
                                        >
                                            <IconButton
                                                aria-label={[
                                                    t('general.edit'),
                                                    t('general.report')
                                                ].join(' ')}
                                                onClick={(e) => {
                                                    navigate(
                                                        `/reports/new/?libraryId=${panel?.id}&reportType=${status}`
                                                    )
                                                }}
                                                tabIndex={0}
                                                onKeyDown={(e) =>
                                                    onKeyDown(
                                                        e,
                                                        () =>
                                                            navigate(
                                                                `/reports/new/?libraryId=${panel?.id}&reportType=${status}`
                                                            ),
                                                        false
                                                    )
                                                }
                                            >
                                                <PlusIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                }
                            />

                            <Grid item alignItems={'center'} display={'flex'}>
                                <KebabMenu
                                    dropDownConfig={customAccordionActions?.(
                                        panel?.id,
                                        panel?.title
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }),
            // To-do: This will be removed later on
            // batchActionsConfig: {
            //     actions: [
            //         {
            //             icon: ChartArrowDownIcon,
            //             sx: {
            //                 display: 'block'
            //             },
            //             iconId: 'collapsed-icon',
            //             permissions: ['Client.Cases.View']
            //         }
            //     ],
            //     clickable: true
            // },
            // panelId: index + 1,
            accordionSummaryProps: {
                sx: { display: 'flex', flexDirection: 'row-reverse' }
            },
            body: (
                <LibraryReports
                    query={GET_REPORTS_BY_LIBRARY_ID}
                    queryName={'getReportsByLibraryId'}
                    libraryId={panel?.id}
                    reportType={status}
                />
            )
        }))

    return (
        <Grid container flexDirection={'column'} md={12} xs={12}>
            <Grid item container p={2}>
                <Grid item container md={8} xs={12}>
                    <Grid item md={0.5} xs={1}>
                        {status === REPORT_LIBRARY.SYSTEM ? (
                            <InsightsIcon />
                        ) : (
                            <UserIcon />
                        )}
                    </Grid>
                    <Grid item container direction='column' md={11.5} xs={11}>
                        <Grid item>
                            <Typography variant='h3'>
                                {status === REPORT_LIBRARY.SYSTEM
                                    ? t('report.systemLibraries')
                                    : t('report.customLibraries')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='body2'>
                                {status === REPORT_LIBRARY.SYSTEM
                                    ? t('report.systemLibrariesCaption')
                                    : t('report.customLibrariesCaption')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {status === REPORT_LIBRARY.CUSTOM && (
                    <AccessibleAction
                        permissions={[UserPermissionEnum.ReportCreateModify]}
                        component={
                            <Grid
                                item
                                container
                                md={4}
                                xs={12}
                                justifyContent={'flex-end'}
                            >
                                <Button
                                    variant='contained'
                                    startIcon={
                                        <PlusIcon
                                            color={
                                                theme.palette
                                                    .contentPrimaryInverse
                                            }
                                            width='20'
                                            height='20'
                                        />
                                    }
                                    sx={{
                                        color: theme.palette
                                            .contentPrimaryInverse
                                    }}
                                    onClick={() => {
                                        saveReportLibrary()
                                    }}
                                >
                                    {t('report.newLibrary')}
                                </Button>
                            </Grid>
                        }
                    />
                )}
            </Grid>
            {loading && queryLoading ? (
                <Loader />
            ) : reportLibraries?.length ? (
                <Panel
                    panels={getPanels(reportLibraries)}
                    //To-do: This will be used later on
                    // showSubtitleOnCollapsed={true}
                    // showBatchActions={false}
                    backgroundColor={theme.palette.backgroundPrimary}
                    isExpanded={reportLibraries?.[0].id}
                />
            ) : (
                <>
                    <EmptyState preventPadding={true} />
                </>
            )}
        </Grid>
    )
}
