import { useEffect, useState } from 'react'
import { setUserData } from '@cacheql'
import { FileTypeEnum, useGraphQL, useS3 } from '@dts/client-utils'
import { isEmpty, isNull } from 'lodash'
import { REMOVE_FILE } from '@dts/graphql'

export const useMediaUpload = () => {
    const [progress, setProgress] = useState(0)
    const [loading, setLoading] = useState(false)
    const [mediaCallBack, setMediaCallBack] = useState<
        (url: string, id: string) => void
    >(() => null)
    const { initializeVideoUpload, key, location, uploadFile } = useS3({
        setProgress,
        isAttachment: true,
        forPublicBucket: true
    })

    const { data, retrieve: removeFileFromS3 } = useGraphQL({
        query: REMOVE_FILE,
        queryName: 'removeFile',
        retrieveOnMount: false
    })

    const handleVideoUpload = (
        file: File,
        callBack: (url: string, id: string) => void
    ) => {
        setLoading(true)

        initializeVideoUpload(file, '')
        setMediaCallBack(
            (url: string, id: string) => (url: string, id: string) =>
                callBack(url, id)
        )
    }
    const handleMediaRemove = (key: string) => {
        removeFileFromS3?.({
            variables: {
                key
            }
        })
    }
    const handleImageUpload = async (
        file: File,
        callBack: (url: string, id: string) => void
    ) => {
        setLoading(true)
        const res = await uploadFile(file, {
            userId: setUserData()?.id,
            type: FileTypeEnum.ARTICLE
        })
        const index = res?.url.indexOf('?')
        const imageUrl: string =
            index !== -1
                ? (res?.url.substring(0, index) as string)
                : (res?.url as string)
        setLoading(false)
        callBack(imageUrl, res?.key)
    }

    useEffect(() => {
        if (!isEmpty(location) && mediaCallBack) {
            mediaCallBack?.(location, key)
            setLoading(false)
        } else if (isNull(location)) {
            setLoading(true)
        }
    }, [location, mediaCallBack])

    return {
        handleImageUpload,
        handleVideoUpload,
        handleMediaRemove,
        loading
    }
}
