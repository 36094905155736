import { styled, Grid, Button } from '@mui/material'
import { isMediumScreen } from '@utils'

export const ParentContainer = styled(Grid)(({ theme }) => ({
    width: '100%',
    justifyContent: 'space-between',
    gap: '30px',
    flexDirection: 'column'
}))

export const BaseContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: isMediumScreen() ? 'column' : 'row',
    justifyContent: 'space-between'
}))

export const TypographyContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: isMediumScreen() ? '20px' : '0px'
}))

export const ComponentContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: !isMediumScreen() ? '40px' : '0px',
    gap: '10px',
    alignItems: 'baseline'
}))

export const SiblingComponentContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: '10px'
}))

export const ReviewButton = styled(Button)(({ theme }) => ({
    height: '50px',
    width: '170px',
    border: `1px solid ${theme.palette.backgroundInfo}`,
    variant: 'outlined'
}))
