import * as yup from 'yup'

export const CompleteProfileSchema = yup.object().shape({
  email: yup
    .string()
    .required('This field is required')
    .nullable(),
  birthday: yup.date().required('This field is required')
    .nullable(),
  ethnicity: yup
    .string()
    .required('This field is required')
    .nullable(),
    gender: yup
    .string()
    .required('This field is required')
    .nullable(),
    interests:yup.array().max(1)
})
