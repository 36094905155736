import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import Switch from '@mui/material/Switch'
import { GridIcon, ListIcon } from '@assets'
import { FormControlLabel } from '@mui/material'
import { IconContainer } from '@components/IconContainer'
import { controlled } from '@hoc'
import { useCustomTheme } from '@hooks'
import { StyledGrid } from './styles'
import { IconSwitchProps } from './types'

export const IconSwitch = ({
    value,
    label,
    onChange,
    hasIcon = false,
    ariaLabel = '',
    disabled = false
}: IconSwitchProps) => {
    const { theme } = useCustomTheme()
    const { formContext } = useFormContext?.() || {}
    const renderIcons = hasIcon && {
        icon: (
            <IconContainer
                size='30px'
                color={theme.palette.primary.main}
                displayFlex
            >
                <ListIcon />
            </IconContainer>
        ),
        checkedIcon: (
            <IconContainer
                size='30px'
                color={theme.palette.primary.main}
                displayFlex
            >
                <GridIcon />
            </IconContainer>
        )
    }

    return (
        <StyledGrid hasIcon={hasIcon}>
            <FormControlLabel
                label={label}
                aria-label={ariaLabel}
                control={
                    <Switch
                        name={name}
                        checked={value}
                        label={label}
                        aria-label={ariaLabel}
                        onChange={onChange}
                        disabled={
                            formContext?.formEditingState?.isReadOnly ||
                            disabled
                        }
                        {...renderIcons}
                    />
                }
                // TODO Remove style
                // sx={{
                //     '& .MuiFormControlLabel-label': {
                //         color: theme.palette.contentPrimary // Replace with your desired color
                //     }
                // }}
            />
        </StyledGrid>
    )
}

export const ControlledSwitch = controlled(IconSwitch)
