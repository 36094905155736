import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { Button } from '@mui/material'
import { EditIcon } from '@assets'
import { useCustomTheme, useFileUpload } from '@hooks'

export const useImageUploader = () => {
    const { getValues } = useFormContext()
    const { thumbnail } = getValues()
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const handleUploadThumbnail = () => {
        document?.getElementById('thumbnail-upload')?.click()
    }
    const { fileUploadComponent, isUploadingStart } = useFileUpload({
        name: 'thumbnail',
        displayFile: thumbnail ? true : false,
        inputLabel: 'thumbnail-upload',
        typeAccepted: '.png,.jpg,.jpeg',
        uploadButton: (
            <label
                htmlFor='thumbnail-upload'
                style={{
                    cursor: 'pointer'
                }}
            >
                <Button
                    fullWidth
                    variant='outlined'
                    color='primary'
                    startIcon={
                        <EditIcon
                            width='21'
                            height='20'
                            color={theme.palette.backgroundBrandPrimary}
                        />
                    }
                    onClick={handleUploadThumbnail}
                    sx={{ borderRadius: '12px' }}
                >
                    {t('createArticle.uploadImage')}
                </Button>
            </label>
        )
    })

    return { fileUploadComponent, isUploadingStart }
}
