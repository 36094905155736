import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const RestoreIcon: React.FC<SvgProps> = ({
    color,
    width = '24',
    height = '24',
    fill = 'none'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 24 24'
            fill={fill}
        >
            <path
                d='M20 17.2V12H16H8H4V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2Z'
                fill='#E2E9EC'
            />
            <path
                d='M4 11.9966C3.83599 11.9924 3.7169 11.9829 3.60982 11.9616C2.81644 11.8038 2.19624 11.1836 2.03843 10.3902C2 10.197 2 9.96466 2 9.5C2 9.03534 2 8.80302 2.03843 8.60982C2.19624 7.81644 2.81644 7.19624 3.60982 7.03843C3.80302 7 4.03534 7 4.5 7H8.25M20 11.9966C20.164 11.9924 20.2831 11.9829 20.3902 11.9616C21.1836 11.8038 21.8038 11.1836 21.9616 10.3902C22 10.197 22 9.96466 22 9.5C22 9.03534 22 8.80302 21.9616 8.60982C21.8038 7.81644 21.1836 7.19624 20.3902 7.03843C20.197 7 19.9647 7 19.5 7H15.75M10 16H14M4 12V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V12H16H8C6.4379 12 4 12 4 12Z'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12 7V1M12 1L8.5 4.5M12 1L15.5 4.5'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default RestoreIcon
