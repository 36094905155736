import { MessagesFragment, MetaFragment } from '../../fragments'

import { gql } from '@apollo/client'

export const GET_NOTIFICATION_PREFERENCES = gql`
  ${MessagesFragment('NotificationPreferencesResponseDto')}
  ${MetaFragment('NotificationPreferencesResponseDto')}
  query getNotificationPreferences {
    getNotificationPreferences {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
        title
        notificationPreferences {
          id
          title
        }
      }
    }
  }
`
