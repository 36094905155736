import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'
export const INITIALIZE_VIDEO_UPLOAD = gql`
  ${MetaFragment('MultipartUploadResponseDto')}
  ${MessagesFragment('MultipartUploadResponseDto')}
  query initializeMultipartUpload($input: InitializeMultipartUploadInputDto!) {
    initializeMultipartUpload(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        fileKey
        fileId
      }
    }
  }
`
export const GET_MULTIPART_PRE_SIGNED_URLS = gql`
  ${MetaFragment('PreSignedUrlResponseDto')}
  ${MessagesFragment('PreSignedUrlResponseDto')}
  query getMultipartPreSignedUrls($input: MultipartUploadInputDto!) {
    getMultipartPreSignedUrls(input: $input) {
      data {
        PartNumber
        signedUrl
      }
      ...MetaFragment
      ...MessagesFragment
    }
  }
`
export const FINALIZE_MULTIPART_UPLOAD = gql`
  ${MetaFragment('S3FileResponseDto')}
  ${MessagesFragment('S3FileResponseDto')}
  query finalizeMultipartUpload($input: FinalizeMultipartUploadInputDto!) {
    finalizeMultipartUpload(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        location
        key
        videoId
      }
    }
  }
`

export * from './details'
