import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const SAVE_FOLLOW_STATUS = gql`
  ${MetaFragment('SaveFollowStatusResponseDto')}
  ${MessagesFragment('SaveFollowStatusResponseDto')}
  mutation saveFollowStatus($input: SaveFollowStatusInputDto!) {
    saveFollowStatus(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        followingId
        following
      }
    }
  }
`
