import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useCustomTheme, useGlobal } from '@hooks'

import { BoldCrossIcon, BoldTickIcon } from '@assets'
import { QuestionFeedbackTypeEnum, SurveyTypeEnum } from '@dts/constants'
import { TypographyVariants } from '@constants'
import { getUrlSegmentTitleById } from '@utils'
import { cachedSurveyInfo } from '@cacheql'
import {
    getIconBySummaryResponseIconId,
    QuestionFeedback
} from './question-feedback'

export const SurveyQuestionDetails = (props) => {
    const { theme } = useCustomTheme()
    const { isMediumScreen } = useGlobal()
    const [answerMappings, setAnswerMappings] = useState()
    const [hasGeneralOptions, setHasGeneralOptions] = useState()

    const {
        currentTotalQuestions,
        BackClickComponent,
        surveyQuestion,
        getFirstAndLastQuestion,
        surveyType,
        courseId,
        questionFeedbackType,
        summaryTitle,
        summaryDescription,
        summaryResponseIcon
    } = props || {}

    useEffect(() => {
        const mappedAnswers = props?.surveyOptions?.map((surveyOption) => ({
            ...surveyOption,
            isAnswered: props?.answeredOptions?.includes(surveyOption?.id)
        }))

        const allGeneralOptions = props?.surveyOptions?.every(
            (surveyOption) => surveyOption?.isAnswer === false
        )
        setHasGeneralOptions(allGeneralOptions)
        setAnswerMappings(mappedAnswers)
    }, [props])

    const getStylesByOptionStatus = (surveyOption) => {
        const isCorrect = surveyOption?.isAnswered && surveyOption?.isAnswer
        const isWrong = surveyOption?.isAnswered && !surveyOption?.isAnswer

        return isCorrect
            ? {
                  backgroundColor: theme?.palette?.backgroundPositiveSubtle,
                  iconColor: theme?.palette?.backgroundPositive,
                  textColor: theme?.palette?.contentPrimary,
                  borderColor: theme?.palette?.backgroundPositive
              }
            : isWrong
            ? {
                  backgroundColor: hasGeneralOptions
                      ? theme?.palette?.backgroundInfoSubtle
                      : theme?.palette?.backgroundNegativeSubtle,
                  iconColor: hasGeneralOptions
                      ? theme?.palette?.backgroundBrandPrimary
                      : theme?.palette?.backgroundNegative,
                  textColor: theme?.palette?.contentPrimary,
                  borderColor: hasGeneralOptions
                      ? theme?.palette?.backgroundBrandPrimary
                      : theme?.palette?.backgroundNegative
              }
            : {
                  backgroundColor: 'none',
                  iconColor: theme?.palette?.placeholderGrey,
                  textColor: theme?.palette?.placeholderGrey,
                  borderColor: 'none'
              }
    }

    const CommonFeedbackIcon = getIconBySummaryResponseIconId(
        summaryResponseIcon?.id,
        { height: 20, width: 20 }
    )

    return (
        <>
            {questionFeedbackType?.id ===
                QuestionFeedbackTypeEnum.CommonFeedback &&
            !cachedSurveyInfo()?.visitedIds?.includes(props?.id) ? (
                <QuestionFeedback
                    {...props}
                    isMediumScreen={isMediumScreen}
                    showBackButton={
                        surveyQuestion?.id !==
                        getFirstAndLastQuestion()?.firstQuestionId
                    }
                />
            ) : (
                <Grid
                    container
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                    height={isMediumScreen ? 'calc(100vh - 8.438rem)' : '90%'} // To-do: Height needs to be reconsidered
                >
                    <Grid
                        item
                        container
                        flexDirection='column'
                        mb={!isMediumScreen ? 2 : 0}
                    >
                        <Grid
                            item
                            container
                            flexDirection={'row'}
                            alignItems={'center'}
                        >
                            {surveyQuestion?.id !==
                            getFirstAndLastQuestion()?.firstQuestionId ? (
                                <BackClickComponent />
                            ) : (
                                <></>
                            )}
                            {courseId ? (
                                <Typography
                                    variant={TypographyVariants.body3}
                                    fontWeight={500}
                                >
                                    {getUrlSegmentTitleById(courseId)}
                                </Typography>
                            ) : (
                                <></>
                            )}
                        </Grid>
                        <Grid
                            item
                            container
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                        >
                            <Typography
                                variant={TypographyVariants.h1}
                                color={theme.palette.backgroundBrandPrimary}
                                sx={{ fontSize: '32px' }}
                            >
                                {surveyType?.id !== SurveyTypeEnum.Pulse
                                    ? surveyType?.title
                                    : 'Quiz'}
                            </Typography>
                            {surveyType?.id !== SurveyTypeEnum.Pulse &&
                            surveyQuestion?.questionNumber &&
                            currentTotalQuestions ? (
                                <Typography
                                    variant={TypographyVariants.smallText}
                                >
                                    {[
                                        surveyQuestion?.questionNumber,
                                        currentTotalQuestions
                                    ]?.join('/')}
                                </Typography>
                            ) : (
                                <></>
                            )}
                        </Grid>
                        <Typography variant={TypographyVariants.h2} py={2}>
                            {surveyQuestion?.title}
                        </Typography>

                        <Grid item>
                            {answerMappings?.map((surveyOption, index) => (
                                <Grid
                                    container
                                    flexDirection={'column'}
                                    key={index}
                                    xs={12}
                                    md={12}
                                    p={2}
                                    mb={1}
                                    sx={{
                                        backgroundColor:
                                            getStylesByOptionStatus(
                                                surveyOption
                                            )?.backgroundColor,
                                        borderLeft: `3px solid ${
                                            getStylesByOptionStatus(
                                                surveyOption
                                            )?.borderColor
                                        }`
                                    }}
                                >
                                    <Grid
                                        item
                                        container
                                        alignItems={'center'}
                                        gap={1}
                                    >
                                        {surveyOption?.isAnswer ? (
                                            <BoldTickIcon
                                                color={
                                                    getStylesByOptionStatus(
                                                        surveyOption
                                                    )?.iconColor
                                                }
                                            />
                                        ) : hasGeneralOptions ? (
                                            <></>
                                        ) : (
                                            <BoldCrossIcon
                                                color={
                                                    getStylesByOptionStatus(
                                                        surveyOption
                                                    )?.iconColor
                                                }
                                            />
                                        )}

                                        <Typography
                                            variant={TypographyVariants.body1}
                                            color={
                                                getStylesByOptionStatus(
                                                    surveyOption
                                                )?.textColor
                                            }
                                        >
                                            {surveyOption?.title}
                                        </Typography>
                                    </Grid>
                                    {surveyOption?.summaryDescription && (
                                        <Grid item>
                                            <Typography
                                                variant={
                                                    TypographyVariants.body2
                                                }
                                                color={
                                                    surveyOption?.isAnswered
                                                        ? theme?.palette
                                                              ?.contentPrimary
                                                        : theme?.palette
                                                              ?.placeholderGrey
                                                }
                                            >
                                                {
                                                    surveyOption?.summaryDescription
                                                }
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    {questionFeedbackType?.id ===
                        QuestionFeedbackTypeEnum.CorrectIncorrectFeedback &&
                    !hasGeneralOptions &&
                    (summaryTitle || summaryDescription) ? (
                        <Grid
                            item
                            container
                            justifySelf={'flex-end'}
                            flexDirection={'row'}
                            p={2}
                            gap={1}
                            sx={{
                                borderRadius: '4px',
                                backgroundColor:
                                    theme?.palette?.backgroundSecondary,
                                border: `1px solid ${theme?.palette?.borderSecondary}`
                            }}
                        >
                            <Grid item>{CommonFeedbackIcon}</Grid>
                            <Grid
                                item
                                container
                                flexDirection={'column'}
                                xs
                                gap={0.5}
                            >
                                <Typography
                                    variant={TypographyVariants.body2Medium}
                                    color={theme?.palette?.contentPrimary}
                                >
                                    {summaryTitle}
                                </Typography>
                                <Typography variant={TypographyVariants.body3}>
                                    {summaryDescription}
                                </Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Grid>
            )}
        </>
    )
}
