import React from 'react'
import { NotificationsList } from '@containers'
import { useNotifications } from '@hooks'

export const NotificationsPage = () => {
    const config = useNotifications()
    return (
        <>
            <NotificationsList {...config} />
        </>
    )
}
