import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const SpeakerIcon: React.FC<SvgProps> = ({
    color,
    height = '32',
    width = '32',
    muted
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.backgroundPrimary

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 32 32`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            {!muted ? (
                <path
                    d='M25.2975 7.36146C26.9983 9.79288 28 12.7761 28 16C28 19.2239 26.9983 22.2071 25.2975 24.6385M20.4944 11.0637C21.4434 12.4629 22 14.1646 22 16C22 17.8354 21.4434 19.5371 20.4944 20.9363M13.1612 7.81274L9.36236 11.7194C9.15481 11.9329 9.05104 12.0396 8.92994 12.1159C8.82258 12.1836 8.70552 12.2334 8.58308 12.2637C8.44497 12.2978 8.29822 12.2978 8.00471 12.2978H5.92C5.24794 12.2978 4.91191 12.2978 4.65521 12.4323C4.42942 12.5506 4.24584 12.7394 4.13079 12.9716C4 13.2356 4 13.5811 4 14.2723V17.7277C4 18.4189 4 18.7644 4.13079 19.0284C4.24584 19.2606 4.42942 19.4494 4.65521 19.5677C4.91191 19.7022 5.24794 19.7022 5.92 19.7022H8.00471C8.29822 19.7022 8.44497 19.7022 8.58308 19.7363C8.70552 19.7666 8.82258 19.8164 8.92994 19.8841C9.05104 19.9604 9.15481 20.0671 9.36235 20.2806L13.1612 24.1873C13.6752 24.7159 13.9323 24.9802 14.1529 24.9981C14.3444 25.0136 14.5315 24.9339 14.6562 24.7837C14.8 24.6106 14.8 24.2368 14.8 23.4892V8.51084C14.8 7.76322 14.8 7.3894 14.6562 7.21631C14.5315 7.06611 14.3444 6.98641 14.1529 7.00191C13.9323 7.01977 13.6752 7.28409 13.1612 7.81274Z'
                    stroke={_color}
                    fill={_color}
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            ) : (
                <path
                    d='M25.6666 11.1823L18.6666 18.1823M18.6666 11.1823L25.6666 18.1823M11.24 6.94229L7.54671 10.6356C7.34494 10.8374 7.24405 10.9383 7.12631 11.0104C7.02193 11.0744 6.90813 11.1215 6.78908 11.1501C6.65481 11.1823 6.51214 11.1823 6.22678 11.1823H4.19998C3.54659 11.1823 3.21989 11.1823 2.97032 11.3095C2.7508 11.4213 2.57232 11.5998 2.46047 11.8193C2.33331 12.0689 2.33331 12.3956 2.33331 13.049V16.3157C2.33331 16.9691 2.33331 17.2958 2.46047 17.5453C2.57232 17.7648 2.7508 17.9433 2.97032 18.0552C3.21989 18.1823 3.54658 18.1823 4.19998 18.1823H6.22678C6.51214 18.1823 6.65481 18.1823 6.78908 18.2146C6.90813 18.2431 7.02193 18.2903 7.12631 18.3542C7.24405 18.4264 7.34494 18.5273 7.54671 18.7291L11.24 22.4224C11.7398 22.9221 11.9897 23.172 12.2042 23.1889C12.3904 23.2036 12.5723 23.1282 12.6935 22.9862C12.8333 22.8226 12.8333 22.4692 12.8333 21.7624V7.60226C12.8333 6.89548 12.8333 6.54208 12.6935 6.37844C12.5723 6.23645 12.3904 6.1611 12.2042 6.17575C11.9897 6.19264 11.7398 6.44252 11.24 6.94229Z'
                    stroke={_color}
                    fill={_color}
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            )}
        </svg>
    )
}

export default SpeakerIcon
