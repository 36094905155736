import UserAvatarImg from './user-avatar.jpg'
import VideoThumbnailImg from './video-thumbnail.png'
import EmailVerificationImg from './email-verification.png'
import PictureFrameImg from './picture-frame.png'
import CampusImg from './campus-logo.png'
import CampusLogo from './client-logo.png'
import MediaLoader from './media-loader.gif'
import AppLogo from './app-logo.png'
import LogoBackground from './logo-background.png'
import EmptyReportStateImg from './empty-report-state.png'
import EmptyNotificationsImg from './empty-notifications.png'
import EmptyStateImg from './generic-empty-state.png'
import ReportThumbnail from './report-thumbnail.png'
import NotificationImg from './notifications.png'

export {
    UserAvatarImg,
    VideoThumbnailImg,
    EmailVerificationImg,
    PictureFrameImg,
    CampusImg,
    CampusLogo,
    MediaLoader,
    AppLogo,
    LogoBackground,
    EmptyReportStateImg,
    EmptyNotificationsImg,
    EmptyStateImg,
    ReportThumbnail,
    NotificationImg
}
