export const getConfigs = () => {
    const getFormats = () => [
        'header',
        'bold',
        'italic',
        'underline',
        'list',
        'bullet',
        'link',
        'image',
        'video'
    ]

    return { getFormats }
}
