import React, { FC, useEffect, useState } from 'react'
import { Checkbox } from '@components'
import { controlled } from '@hoc'
import { StyledAccordion } from '@components/Panel/styles'
import { AccordionDetails, AccordionSummary, Grid } from '@mui/material'
import { useCustomTheme } from '@hooks'
import { ParentMultiCheckboxesProps } from './types'

export const ParentMultiCheckboxes: FC<ParentMultiCheckboxesProps> = ({
    options = [],
    onChange,
    headerName,
    headerCheckBoxLabel,
    value,
    width,
    disabled,
    sx,
    direction = 'row',
    hasBackground = false,
    stripedBackground = false
}) => {
    const { theme } = useCustomTheme()
    const initialState: Record<string, boolean> = options?.reduce(
        (acc, current) => ({
            ...acc,
            [current.value]: false
        }),
        {}
    )

    const [selected, setSelected] =
        useState<Record<string, boolean>>(initialState)

    useEffect(() => {
        setSelected((prevState) => {
            const newState = { ...prevState }
            value?.forEach((val) => {
                newState[val] = true
            })
            return newState
        })
    }, [value])

    const onCheckboxChange = (checked: boolean, name: string) => {
        const newState = { ...selected, [name]: checked }
        setSelected(newState)
        onChange(Object.keys(newState).filter((key) => newState[key]))
    }

    const handleParentCheckboxChange = (checked: boolean) => {
        const newState = options.reduce(
            (acc, { value }) => ({
                ...acc,
                [value]: checked
            }),
            {}
        )
        setSelected(newState)
        onChange(Object.keys(newState).filter((key) => newState[key]))
    }

    return (
        <StyledAccordion>
            <AccordionSummary>
                <Checkbox
                    label={headerCheckBoxLabel}
                    name={headerName}
                    checked={Object.values(selected).every((value) => value)}
                    onChange={handleParentCheckboxChange}
                />
            </AccordionSummary>
            <AccordionDetails>
                <Grid
                    container
                    sx={{ width }}
                    display={'flex'}
                    flexDirection={direction}
                >
                    {options.map(({ label, value }, index) => (
                        <Grid
                            item
                            key={value}
                            xs={12}
                            ml={2}
                            sx={{
                                ...sx,
                                backgroundColor: `${
                                    stripedBackground
                                        ? index % 2
                                            ? theme.palette.backgroundPrimary
                                            : theme.palette.dimGrey
                                        : theme.palette.backgroundPrimary
                                }`
                            }}
                        >
                            <Checkbox
                                key={value}
                                label={label}
                                name={value}
                                disabled={disabled}
                                checked={selected[value]}
                                onChange={onCheckboxChange}
                                hasBackground={hasBackground}
                                isGrouped
                                fullWidth
                            />
                        </Grid>
                    ))}
                </Grid>
            </AccordionDetails>
        </StyledAccordion>
    )
}

export const ControlledParentMultipleCheckboxes = controlled(
    ParentMultiCheckboxes
)
