import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, UserDetails } from '../../fragments'

export const GET_ALL_USERS = gql`
  ${UserDetails('UserDataDto')}
  query getAllUsers {
    getAllUsers {
      data {
        data {
          ...UserDetails
        }
      }
    }
  }
`

export const GET_USERS = gql`
  ${MessagesFragment('GetMultipleUserResponseDto')}
  ${MetaFragment('GetMultipleUserResponseDto')}
  ${UserDetails('UserDataDto')}
  query getUsers($options: PaginationDto!, $clientId: String) {
    getUsers(options: $options, clientId: $clientId) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...UserDetails
      }
    }
  }
`
