import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, VideoFields } from '../../fragments'

export const GET_DASHBOARD_VIDEOS = gql`
  ${MetaFragment('DashboardVideosResponseDto')}
  ${MessagesFragment('DashboardVideosResponseDto')}
  ${VideoFields('VideoDataDto')}
  query getDashboardVideos(
    $options: PaginationDto!
    $args: DashboardVideosArgumentsDto
  ) {
    getDashboardVideos(options: $options, args: $args) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...VideoFields
      }
      meta {
        count
        verified
      }
    }
  }
`
