import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { ControlledMultipleCheckboxes, ControlledRadioGroup } from '@components'
import { Grid, Typography } from '@mui/material'
import { useCustomTheme } from '@hooks'
import { QuestionTypeEnum } from '@dts/constants/lib'
import { mapDropdownOptions } from '@dts/client-utils'
import { SurveyTypeEnum } from '@dts/constants'
import { getUrlSegmentTitleById } from '@utils'
import { TypographyVariants } from '@constants'

export const SurveyForm = (props) => {
    const { theme } = useCustomTheme()
    const { setValue } = useFormContext()

    const {
        surveyQuestion,
        surveyType,
        currentTotalQuestions,
        courseId,
        BackClickComponent,
        getFirstAndLastQuestion
    } = props || {}

    useEffect(() => {
        setValue('surveyQuestion', surveyQuestion)
    }, [surveyQuestion])

    return (
        <>
            <Grid container flexDirection='column'>
                <Grid
                    item
                    container
                    flexDirection={'row'}
                    alignItems={'center'}
                >
                    {surveyQuestion?.id !==
                    getFirstAndLastQuestion()?.firstQuestionId ? (
                        <BackClickComponent />
                    ) : (
                        <></>
                    )}
                    {courseId ? (
                        <Typography
                            variant={TypographyVariants.body3Medium}
                            fontWeight={500}
                        >
                            {getUrlSegmentTitleById(courseId)}
                        </Typography>
                    ) : (
                        <></>
                    )}
                </Grid>
                <Grid
                    item
                    container
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                >
                    <Typography
                        variant='h1'
                        color={theme.palette.backgroundBrandPrimary}
                        sx={{ fontSize: '32px' }}
                    >
                        {surveyType?.id !== SurveyTypeEnum.Pulse
                            ? surveyType?.title
                            : 'Quiz'}
                    </Typography>
                    {surveyType?.id !== SurveyTypeEnum.Pulse &&
                    surveyQuestion?.questionNumber &&
                    currentTotalQuestions ? (
                        <Typography variant='smallText'>
                            {[
                                surveyQuestion?.questionNumber,
                                currentTotalQuestions
                            ]?.join('/')}
                        </Typography>
                    ) : (
                        <></>
                    )}
                </Grid>
                <Typography variant='h2' py={2}>
                    {surveyQuestion?.title}
                </Typography>

                {surveyQuestion?.questionType?.id ===
                QuestionTypeEnum.MultiChoice ? (
                    <ControlledMultipleCheckboxes
                        name='multiChoiceAnswers'
                        options={mapDropdownOptions(
                            surveyQuestion?.surveyOptions
                        )}
                        styles={{ md: 12, xs: 12 }}
                        hasBackground={true}
                    />
                ) : (
                    <ControlledRadioGroup
                        name='singleChoiceAnswer'
                        isButton={true}
                        options={mapDropdownOptions(
                            surveyQuestion?.surveyOptions
                        )}
                    />
                )}
            </Grid>
        </>
    )
}
