import * as React from 'react'
import { Card, CardContent, CardHeader, SxProps } from '@mui/material'
import { onKeyDown } from '@utils'

type CardProps = {
    header?: string
    title?: string
    content: JSX.Element
    onClick?: VoidFunction
    sx?: SxProps
    contentSx?: SxProps
}

export const MuiCard: React.FC<CardProps> = ({
    header,
    content,
    title,
    onClick,
    sx,
    contentSx
}) => (
    <Card
        sx={{
            height: '100%',
            width: '100%',
            cursor: onClick ? 'pointer' : 'auto',
            ...sx
        }}
        tabIndex={0}
        aria-label={title}
        role='button'
        onKeyDown={(e) => {
            onKeyDown(e, onClick, false)
        }}
        onClick={onClick}
    >
        {header && <CardHeader>{header}</CardHeader>}
        <CardContent sx={contentSx}>{content}</CardContent>
    </Card>
)
