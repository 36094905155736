import { useState } from 'react'
import { useForm as _useForm, UseFormProps } from 'react-hook-form'

type WithDisabled<P> = { disabled: boolean } & P

/**
 * A wrapper of useForm. It adds the possibility to disable a form.
 */
export const useForm = (props: WithDisabled<UseFormProps>) => {
    const [disabled, setDisabled] = useState(false)

    const methods = _useForm(props)

    /**
     * Sets the disability of the form. If no arugment is provided, disabled is set to true.
     *
     * @param flag Optional param to set the value explicitly
     * @returns void
     */
    const disable = (flag = true) => setDisabled(flag)

    /**
     * Enables the form
     *
     * @returns void
     */
    const enable = () => disable(false)

    /**
     * Toggles the form state enable/disable
     */
    const toggleReadOnly = (flag: boolean | undefined) => {
        if (flag === false) {
            enable()
        } else if (flag === true) {
            disable()
        } else {
            disabled ? enable() : disable()
        }
    }

    return {
        ...methods,
        formEditingState: {
            isReadOnly: disabled,
            toggleReadOnly
        }
    }
}
