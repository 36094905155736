import { gql } from '@apollo/client'
import { MessagesFragment } from '../../fragments'

export const GET_CLIENT_TYPES = gql`
  ${MessagesFragment('ClientTypeResponseDto')}
  query getClientTypes {
    getClientTypes {
      ...MessagesFragment
      data {
        id
        title
        subType
      }
    }
  }
`
