import React, { FC } from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const KeyIcon: FC<SvgProps> = ({ color, width = '20', height = '20' }) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.backgroundBrandPrimary

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M21 2L19 4M11.3891 11.6109C12.3844 12.6062 13 13.9812 13 15.5C13 18.5376 10.5376 21 7.5 21C4.46244 21 2 18.5376 2 15.5C2 12.4624 4.46244 10 7.5 10C9.01878 10 10.3938 10.6156 11.3891 11.6109ZM11.3891 11.6109L15.5 7.5M15.5 7.5L18.5 10.5L22 6.99999L19 4M15.5 7.5L19 4'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default KeyIcon
