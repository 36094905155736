import React from 'react'
import { useTranslation } from 'react-i18next'

import { Typography, Grid } from '@mui/material'
import { useCustomTheme } from '@hooks'
import { EmptyState } from '@components'
import { TypographyVariants } from '@constants'
import { ResourceDisplayProps } from './types'
import { ResourceContainer } from './resource-card-container'
import { HeaderGrid } from './style'

export const ResourceDisplay = ({ data }: ResourceDisplayProps) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()

    return (
        <HeaderGrid>
            {data?.length ? (
                <>
                    <Grid container spacing={2} xs={12}>
                        {(Array.isArray(data) ? data : [data])?.map(
                            ({ resources, title, id }, index) => (
                                <Grid item xs={12} key={index}>
                                    <ResourceContainer
                                        title={title}
                                        index={index}
                                        resources={resources}
                                        categoryId={id}
                                    />
                                </Grid>
                            )
                        )}
                    </Grid>

                    <Grid item xs={12} ml={2} mb={2}>
                        <Typography
                            variant={TypographyVariants.h1}
                            color={theme.palette.textPrimary}
                        >
                            {t('resource.additionalResources')}
                        </Typography>
                        <Typography
                            variant={TypographyVariants.body2}
                            color={theme.palette.textPrimary}
                            sx={{ mt: 2 }}
                        >
                            {t('resource.additionalResourceDescription')}
                        </Typography>
                    </Grid>
                </>
            ) : (
                <EmptyState
                    header={t('resource.emptyState.header')}
                    subHeader={t('resource.emptyState.subHeader')}
                />
            )}
        </HeaderGrid>
    )
}
