import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Divider, Grid, Typography } from '@mui/material'
import { useCustomTheme, useGraphQLWrapper, usePageHeader } from '@hooks'
import { GET_CAMPUSES, GET_CLIENT_TITLE } from '@dts/graphql/lib'
import { Panel } from '@components'
import { PlusIcon } from '@assets'
import { BaseFields } from '@types'
import { CampusContainer } from './campus-container'
import { PanelType } from './types'
import { UserPermissionEnum } from '@dts/constants'

export const Campuses = () => {
    const { theme } = useCustomTheme()
    const { t } = useTranslation()
    const { state } = useLocation()

    const { id: clientId } = useParams()
    const navigate = useNavigate()
    const [campusId, setCampusId] = useState('')
    const { currentData: campuses } = useGraphQLWrapper({
        query: GET_CAMPUSES,
        queryName: 'getCampuses',
        queryVariables: {
            clientId
        }
    })

    const { currentData: clientData } = useGraphQLWrapper({
        query: GET_CLIENT_TITLE,
        queryName: 'getClient',
        queryVariables: {
            clientId
        }
    })

    const getPanels = (panels: Array<PanelType>) =>
        panels?.map((panel, index: number) => ({
            id: panel?.id,
            header: panel?.title,
            // To-do: Will be removed later on
            // batchActionsConfig: {
            //     actions: [
            //         {
            //             icon: ChartArrowDownIcon,
            //             sx: {
            //                 display: 'block'
            //             },
            //             iconId: 'collapsed-icon',
            //             permissions: ['Client.Cases.View']
            //         }
            //     ],
            //     // TODO this style remove after verify
            //     // sx: {
            //     //     //right: '0px',
            //     //     // bottom: '9px'
            //     // },
            //     clickable: true
            // },
            // To-do: Will be removed later on
            // panelId: index + 1,
            body: <CampusContainer campusId={campusId} />
            // TODO this style remove after verify
            // bodySx: {
            //     // marginLeft: '31px'
            // }
        }))

    const { pageHeader } = usePageHeader({
        headerText: clientData?.title || '',
        primaryButtonTitle: t('clients.newCampus'),
        primaryButtonIcon: <PlusIcon color={theme.palette.contentSecondary} />,
        primaryButtonAction: () => navigate('new'),
        saveForm: false,
        permissions: [UserPermissionEnum.ClientCreateModify]
    })

    return (
        <>
            {pageHeader}
            <Grid m={2}>
                {/* Will be removed after testing
                 <Grid
                container
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                <Typography variant='h1'>{clientData?.title || ''}</Typography>

                <Button
                    variant={'outlined'}
                    onClick={() => navigate('new')}
                    sx={{ height: '40px' }}
                    startIcon={<PlusIcon />}
                >
                    {t('clients.newCampus')}
                </Button>
            </Grid> */}

                <Divider
                    sx={{
                        marginTop: '16px !important',
                        marginBottom: '16px !important'
                    }}
                />

                {campuses?.length ? (
                    <Panel
                        panels={getPanels(
                            campuses?.map((item: BaseFields) => ({
                                id: item?.id,
                                title: item?.title
                            }))
                        )}
                        // To-do: Will be removed later on
                        // showSubtitleOnCollapsed={true}
                        // showBatchActions={false}
                        onChange={(openedAccordionId: string) =>
                            setCampusId(openedAccordionId)
                        }
                        isExpanded={state?.campusId || campuses?.[0]?.id}
                    />
                ) : (
                    <Typography variant='body2'>
                        {t('campuses.noCampusesFound')}
                    </Typography>
                )}
            </Grid>
        </>
    )
}
