import {
    Grid,
    styled,
    Divider as MuiDivider,
    Typography as MuiTypography,
    Box
} from '@mui/material'
export const StyledFormFrame = styled(Box)`
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    padding: 8px;

    #hubspot-form-container {
        height: 61vh;
        padding: 16px;

        max-height: 73vh;
    }

    .formsstyles {
        hs-form-field input[type='text'] {
            border-radius: 8px;
            background: red;
        }
        label {
            margin-left: 4px;
            line-height: 4px;
        }
    }
`

export const CenteredGridContainer = styled(Grid)(({ theme }) => ({
    minHeight: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}))

export const ContainerGrid = styled(Grid)(({ theme }) => ({
    minHeight: 'calc(100vh - 120px)',
    padding: theme.spacing(3)
}))

export const StyledBox = styled('div')(({ theme }) => ({
    border: `1px solid ${theme.palette.BorderGray}`,
    borderRadius: '25px',
    height: '100%',
    padding: theme.spacing(3)
}))

export const IconBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '2.75rem',
    height: '2.75rem',
    '& svg': {
        borderRadius: '0.5rem',
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(1.25),
        height: '2.50rem',
        width: '2.5rem',
        background: theme.palette.backgroundBrandPrimary,
        '& path': {
            stroke: theme.palette.borderPrimaryInverse
        }
    }
}))

export const StyledDivider = styled(MuiDivider)(({ theme }) => ({
    color: theme.palette.borderSecondary
}))

export const StyledTypography = styled(MuiTypography)(({ theme }) => ({
    color: theme.palette.contentSecondary,
    marginBottom: theme.spacing(2)
}))

export const StyledTypographyHeading = styled(MuiTypography)(({ theme }) => ({
    marginBottom: theme.spacing(2)
}))
