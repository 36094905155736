import { CustomRoute } from '@routes'
import { isEmpty } from 'lodash'

export const groupRoutes = (routes: Array<CustomRoute>) =>
    routes.reduce((groups, item) => {
        const { group } = item

        if (item?.group) {
            if (!groups[group]) {
                groups[group] = []
            }

            groups[group].push(item)
        }
        return groups
    }, {})

export const checkCurrentRoute = (
    routes: Array<CustomRoute>,
    paramValues?: Array<string>
) =>
    routes.find((route) =>
        !isEmpty(route?.path)
            ? location.pathname.includes(route?.path) ||
              paramValues?.some((val) => route?.path === `:${val}`)
            : undefined
    )
