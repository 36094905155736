import './classes'
import React, { useEffect, useMemo, useRef } from 'react'
import ReactQuill from 'react-quill'
import { useTranslation } from 'react-i18next'
import { useCustomTheme, useEditor } from '@hooks'
import { KeyboardShortcut } from '@constants'
import { onKeyDown } from '@utils'
import { getConfigs } from './config'
import 'react-quill/dist/quill.snow.css'
import { can_access, controlled } from '@hoc'
import { RichEditorProps } from './types'
import StyledBox, { StyledReactQuill } from './styles'

export const RichEditor = ({
    onVideoChange,
    onImageChange,
    placeholder,
    isLoading,
    onMediaRemove,
    permissions = [],
    ...props
}: RichEditorProps) => {
    const { t } = useTranslation()
    const quillRef = useRef<ReactQuill | null>(null)
    const { getFormats } = getConfigs()
    const editor = useEditor({
        ref: quillRef,
        onVideoChange,
        onImageChange,
        isLoading,
        onMediaRemove
    })
    const readOnly = permissions?.length > 0 && !can_access(permissions)
    const { theme } = useCustomTheme()
    const formats = getFormats()

    useEffect(() => {
        const addAriaLabels = () => {
            const quillEditor = quillRef.current?.getEditor()
            if (quillEditor) {
                const toolbar = quillEditor.getModule('toolbar')
                if (toolbar) {
                    const toolbarContainer = toolbar.container
                    const buttons = toolbarContainer.querySelectorAll('button')
                    buttons.forEach((button) => {
                        if (!button.getAttribute('aria-label')) {
                            let ariaLabel =
                                button.classList[0] ||
                                button.dataset.originalTitle
                            if (!ariaLabel && button.querySelector('svg')) {
                                const svgTitle =
                                    button.querySelector('svg title')
                                ariaLabel = svgTitle
                                    ? svgTitle.textContent
                                    : null
                            }
                            if (button.classList.contains('ql-bold'))
                                ariaLabel = t('general.bold')
                            if (button.classList.contains('ql-italic'))
                                ariaLabel = t('general.italic')
                            if (button.classList.contains('ql-underline'))
                                ariaLabel = t('general.underline')
                            if (
                                button.classList.contains('ql-list') &&
                                button.classList.contains('ql-ordered')
                            )
                                ariaLabel = t('general.orderedList')
                            if (
                                button.classList.contains('ql-list') &&
                                button.classList.contains('ql-bullet')
                            )
                                ariaLabel = t('general.bulletList')
                            if (button.classList.contains('ql-link'))
                                ariaLabel = t('general.link')
                            if (button.classList.contains('ql-image'))
                                ariaLabel = t('general.image')
                            if (button.classList.contains('ql-video'))
                                ariaLabel = t('general.video')
                            // Handle the custom video button
                            if (
                                button.querySelector('svg') &&
                                button.querySelector('svg path[d^="M9.948"]')
                            ) {
                                ariaLabel = t('general.addVideo')
                            }
                            if (ariaLabel) {
                                button.setAttribute('aria-label', ariaLabel)
                            }
                        }
                    })

                    const anchorTag = document.querySelector('.ql-preview')
                    if (anchorTag) {
                        anchorTag.setAttribute(
                            'aria-label',
                            t('video.upload.preview.label')
                        )
                        // Add aria-label to input sibling of the anchor tag
                        const inputTag = anchorTag.nextElementSibling
                        if (
                            inputTag &&
                            inputTag.tagName.toLowerCase() === 'input'
                        ) {
                            inputTag.setAttribute(
                                'aria-label',
                                t('general.input')
                            )
                        }
                    }

                    // Add labels to select elements
                    const selects = toolbarContainer.querySelectorAll('select')
                    selects.forEach((select) => {
                        if (!select.getAttribute('aria-label')) {
                            if (select.classList.contains('ql-header')) {
                                select.setAttribute(
                                    'aria-label',
                                    t('general.fontSelect')
                                )
                            }
                        }
                    })
                }
            }
        }
        addAriaLabels()
        // Disable the toolbar buttons if the editor is disabled
        const quillEditor = quillRef.current?.getEditor()
        const toolbar = quillEditor?.getModule('toolbar')

        if (toolbar && readOnly) {
            const toolbarButtons =
                toolbar.container.querySelectorAll('button, select')
            toolbarButtons.forEach((button) => {
                button.setAttribute('disabled', 'true')
            })
        }
    }, [readOnly])

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [1, 2, false] }],
                    ['bold', 'italic', 'underline'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    // [{ color: [] }, { background: [] }], // this control add in future
                    ['link', 'image', 'video'],
                    ['custom']
                ],
                handlers: {
                    custom: editor?.onVideoChange,
                    image: editor?.onImageChange
                }
            },

            keyboard: {
                bindings: {
                    custom: {
                        key: KeyboardShortcut.Backspace,
                        handler: (range: any, context: any) =>
                            editor?.onMediaRemove(range, context, 'video')
                    },
                    image: {
                        key: KeyboardShortcut.Backspace,
                        handler: (range: any, context: any) =>
                            editor?.onMediaRemove(range, context, 'img')
                    }
                }
            }
        }),
        []
    )

    return (
        /**
         * Apply theme color when we can select colors from editor
         */
        <StyledBox>
            <StyledReactQuill
                theme='snow'
                ref={quillRef}
                placeholder={placeholder}
                modules={modules}
                formats={formats}
                readOnly={readOnly}
                {...props}
                onKeyDown={(e) =>
                    onKeyDown(
                        e,
                        () => {
                            quillRef?.current.blur()
                            const nextElement =
                                document.querySelector('#previewButton')
                            nextElement?.focus()
                        },
                        true,
                        KeyboardShortcut.ESC
                    )
                }
            />
        </StyledBox>
    )
}

export default RichEditor

export const ControlledRichEditor = controlled(RichEditor)
