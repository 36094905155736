import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_STATES = gql`
  ${MessagesFragment('CommonRefsResponseDto')}
  ${MetaFragment('CommonRefsResponseDto')}
  query getStatesDropDown {
    getStatesDropDown {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
        title
        available
        displayOrder
      }
    }
  }
`

export const GET_CITIES = gql`
  ${MessagesFragment('CitiesResponseDto')}
  ${MetaFragment('CitiesResponseDto')}
  query getCitiesDropDown($stateId: String!) {
    getCitiesDropDown(stateId: $stateId) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
        title
      }
    }
  }
`

export const GET_ZIP_CODES = gql`
  ${MessagesFragment('ZipCodesResponseDto')}
  ${MetaFragment('ZipCodesResponseDto')}
  query getZipCodes($cityId: Float!) {
    getZipCodes(cityId: $cityId) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
        zipCode
        longitude
        latitude
      }
    }
  }
`
