import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const StyledGrid = styled(Grid, {
    shouldForwardProp: (prop) =>
        prop !== 'background' && prop !== 'borderBottom'
})<{
    background?: string
    borderBottom?: string
    selectedTraining?: boolean
}>(({ background, theme, selectedTraining }) => ({
    background: background,
    padding: '1rem 0.75rem ',
    borderRadius: '0px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    width: '100%',
    boxShadow: selectedTraining
        ? `2px 0px 0px 0px ${theme?.palette?.backgroundBrandPrimary} inset`
        : 'none'
}))
