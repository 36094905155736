import { KeyboardShortcut } from '@constants'
import { TimePeriod } from '@dts/client-utils'

export const onKeyDown = (
    e,
    onClick: () => void,
    stopPropagation: boolean,
    requiredKey: string = KeyboardShortcut.Enter
) => {
    if (e.key === requiredKey) {
        stopPropagation && e.stopPropagation()
        onClick?.()
    }
}

export const GetTimelineAriaLabel = (
    period: string,
    t: (label: string) => void
) => {
    if (period === TimePeriod.SevenDays) {
        return t('general.SevenDays')
    } else if (period === TimePeriod.ThirtyDays) {
        return t('general.ThirtyDays')
    } else if (period === TimePeriod.ThreeMonths) {
        return t('general.ThreeMonths')
    } else if (period === TimePeriod.SixMonths) {
        return t('general.SixMonths')
    } else if (period === TimePeriod.TwelveMonths) {
        return t('general.TwelveMonths')
    } else {
        return period
    }
}
