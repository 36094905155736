import { gql } from '@apollo/client'
import { MessagesFragment, CourseFields } from '../../fragments'

export const UPDATE_COURSE = gql`
  ${MessagesFragment('CourseResponseDto')}
  ${CourseFields('CourseDto')}
  mutation UpdateCourse($input: UpdateCourseInputDto!) {
    updateCourse(input: $input) {
      ...MessagesFragment
      data {
        ...CourseFields
      }
    }
  }
`
