import styled from '@emotion/styled'
import { Grid } from '@mui/material'

export const StyledContainer = styled(Grid)`
    display: flex;
    margin-bottom: 10px;
    margin-top: 10px;
`
export const StyledChippedTab = styled(Grid)(
    ({ index, activeIndex, theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '8px',
        minWidth: 'fit-content',
        maxWidth: 'fit-content',
        whiteSpace: 'nowrap',
        borderRadius: '15px',
        padding: '8px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.lightBlue
        },
        background: theme.palette.backgroundPrimary,
        border: `0.1px solid ${theme.palette.borderGrey}`,
        background1: activeIndex === index ? theme.palette.lightBlue : 'none'
    })
)

export const StyledChipsContainer = styled(Grid)(({ isScrolling, theme }) => ({
    overflowX: 'auto',
    maxWidth: '100%',
    scrollbarWidth: isScrolling ? 'thin' : 'none',
    '&::-webkit-scrollbar': {
        width: '1px',
        height: '1px',
        backgroundColor: theme.palette.backgroundSecondary,
        borderRadius: '2px',
        display: isScrolling ? 'block' : 'none'
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.lightBlue,
        borderRadius: '2px'
    },
    background: theme.palette.dimGrey
}))
