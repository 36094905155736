import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import {
    ControlledSelect,
    ControlledSwitch,
    ControlledTextInput
} from '@components'
import { Grid, Typography, Divider } from '@mui/material'
import { GET_RESPONSE_ICONS } from '@dts/graphql/lib'
import { useGraphQLWrapper } from '@hooks'
import { mapDropdownOptions, mergeDropdownFields } from '@dts/client-utils'
import {
    CheckmarkIcon,
    CrossIcon,
    InfoIcon,
    InformationIcon,
    TickIcon,
    WarningIcon
} from '@assets'
import { can_access } from '@hoc'
import { SummaryDetail } from './summary-detail'

export const SurveySummary: React.FC = ({ permissions }) => {
    const { t } = useTranslation()
    const {
        formState: { errors },
        control
    } = useFormContext()

    const questions = useWatch({
        control,
        name: 'questions'
    })

    return (
        <Grid container flexDirection={'column'}>
            <Grid item>
                <ControlledSwitch
                    name={'summaryEnabled'}
                    label={t('survey.enableSummary')}
                />
            </Grid>

            <Typography variant='body2' mt={3}>
                {t('survey.summaryCaption', { value: questions?.length ?? 0 })}
            </Typography>
            <Grid item md={12} xs={12}>
                <SummaryDetail permissions={permissions} />
            </Grid>
        </Grid>
    )
}
