import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import { isMediumScreen } from '@utils'

export const StyledFlexContainer = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px'
}))
