import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const ChatIcon: React.FC<SvgProps> = ({
    color,
    height = '20',
    width = '20'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g clipPath='url(#clip0_26576_39311)'>
                <path
                    d='M8.33317 12.4998L5.77046 15.0946C5.41299 15.4565 5.23426 15.6375 5.08063 15.6502C4.94735 15.6613 4.81685 15.6077 4.7298 15.5062C4.62947 15.3892 4.62947 15.1348 4.62947 14.6261V13.3262C4.62947 12.8698 4.25573 12.5396 3.80417 12.4734V12.4734C2.71129 12.3134 1.85298 11.4551 1.6929 10.3622C1.6665 10.182 1.6665 9.96693 1.6665 9.53687V5.6665C1.6665 4.26637 1.6665 3.56631 1.93899 3.03153C2.17867 2.56112 2.56112 2.17867 3.03153 1.93899C3.56631 1.6665 4.26637 1.6665 5.6665 1.6665H11.8332C13.2333 1.6665 13.9334 1.6665 14.4681 1.93899C14.9386 2.17867 15.321 2.56112 15.5607 3.03153C15.8332 3.56631 15.8332 4.26637 15.8332 5.6665V9.1665M15.8332 18.3332L14.0195 17.0722C13.7645 16.895 13.6371 16.8064 13.4983 16.7435C13.3752 16.6878 13.2457 16.6472 13.1128 16.6227C12.963 16.5951 12.8078 16.5951 12.4973 16.5951H10.9998C10.0664 16.5951 9.59971 16.5951 9.24319 16.4134C8.92958 16.2536 8.67462 15.9987 8.51483 15.6851C8.33317 15.3285 8.33317 14.8618 8.33317 13.9284V11.8332C8.33317 10.8998 8.33317 10.433 8.51483 10.0765C8.67462 9.76292 8.92958 9.50795 9.24319 9.34816C9.59971 9.1665 10.0664 9.1665 10.9998 9.1665H15.6665C16.5999 9.1665 17.0666 9.1665 17.4232 9.34816C17.7368 9.50795 17.9917 9.76292 18.1515 10.0765C18.3332 10.433 18.3332 10.8997 18.3332 11.8332V14.0951C18.3332 14.8716 18.3332 15.2599 18.2063 15.5662C18.0371 15.9746 17.7127 16.2991 17.3043 16.4682C16.998 16.5951 16.6097 16.5951 15.8332 16.5951V18.3332Z'
                    stroke={_color}
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_26576_39311'>
                    <rect width='20' height='20' fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
}
export default ChatIcon
