import { gql } from '@apollo/client'
import { ArticleFields } from '../../fragments'

export const GET_ARTICLE = gql`
  ${ArticleFields('ArticleDataDto')}
  query getArticle($id: String!) {
    getArticle(id: $id) {
      data {
        ...ArticleFields
      }
    }
  }
`
