import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const FillCheckCircleIcon: React.FC<SvgProps> = ({
    color,
    width = 72,
    height = 72
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.successMain

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 72 72'
            fill='none'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M69 36C69 54.2254 54.2254 69 36 69C17.7746 69 3 54.2254 3 36C3 17.7746 17.7746 3 36 3C54.2254 3 69 17.7746 69 36ZM53.3246 28.5748C54.6914 27.208 54.6914 24.9919 53.3246 23.625C51.9577 22.2582 49.7417 22.2582 48.3748 23.625L38.4748 33.5251L31.0497 40.9502L23.6246 33.5251C22.2577 32.1582 20.0417 32.1582 18.6748 33.5251C17.308 34.8919 17.308 37.108 18.6748 38.4748L28.5748 48.3748C29.9417 49.7416 32.1577 49.7416 33.5246 48.3748L43.4246 38.4748L53.3246 28.5748Z'
                fill={_color}
            />
        </svg>
    )
}
export default FillCheckCircleIcon
