import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const ReportIcon: React.FC<SvgProps> = ({ color, width = 24, height = 24 }) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox={`0 0 24 24`}
            fill='none'
        >
            <path
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M11 14.6667V11M11 7.33333H11.0092M7.15 19.25H14.85C16.3901 19.25 17.1602 19.25 17.7485 18.9503C18.2659 18.6866 18.6866 18.2659 18.9503 17.7485C19.25 17.1602 19.25 16.3901 19.25 14.85V7.15C19.25 5.60986 19.25 4.83978 18.9503 4.25153C18.6866 3.73408 18.2659 3.31338 17.7485 3.04973C17.1602 2.75 16.3901 2.75 14.85 2.75H7.15C5.60986 2.75 4.83978 2.75 4.25153 3.04973C3.73408 3.31338 3.31338 3.73408 3.04973 4.25153C2.75 4.83978 2.75 5.60986 2.75 7.15V14.85C2.75 16.3901 2.75 17.1602 3.04973 17.7485C3.31338 18.2659 3.73408 18.6866 4.25153 18.9503C4.83978 19.25 5.60986 19.25 7.15 19.25Z'
            />
        </svg>
    )
}
export default ReportIcon
