import React from 'react'
import { isNull } from 'lodash'
import { ListItem as MUIListItem, ListItemText, Grid } from '@mui/material'
import { useCustomTheme } from '@hooks'
import { ListItemProps } from './types'

export const ListItem = ({
    label,
    value,
    id,
    labelSx,
    valueSx,
    borderBottom = false,
    icon
}: ListItemProps) => {
    const { theme } = useCustomTheme()
    return (
        <MUIListItem
            sx={{
                ...(borderBottom && {
                    borderBottom: `1px solid ${theme.palette.borderSecondary}`
                })
            }}
        >
            <Grid container>
                {label && (
                    <Grid item xs={6}>
                        <ListItemText
                            disableTypography
                            sx={
                                labelSx ?? {
                                    fontSize: '1rem',
                                    fontWeight: '600',
                                    color: theme.palette.contentPrimary
                                }
                            }
                            primary={label}
                            id={id}
                        />
                    </Grid>
                )}
                {!isNull(value) && (
                    <Grid
                        xs={label ? 6 : 12}
                        display={'flex'}
                        flexDirection={'row'}
                        container
                    >
                        {icon && (
                            <Grid item sx={{ minWidth: '40px' }}>
                                {<icon.iconComponent />}
                            </Grid>
                        )}
                        <Grid item xs={9}>
                            <ListItemText
                                disableTypography
                                primary={value}
                                id={id}
                                sx={{
                                    ...valueSx,
                                    wordBreak: 'break-all',
                                    color: icon
                                        ? theme.palette.contentLink
                                        : theme.palette.contentPrimary
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </MUIListItem>
    )
}
