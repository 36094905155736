import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { formatDate } from '@dts/client-utils'
import { DELETE_CLIENT_ROLE, GET_CLIENT_ROLES } from '@dts/graphql'
import { useCustomTheme, useDirectory, useGraphQLWrapper } from '@hooks'
import { AddIcon, DeleteIcon, EditIcon } from '@assets'
import { StyledDirectoryLink } from '@containers'
import { TextWithTooltip } from '@components'
import { UserPermissionEnum } from '@dts/constants'

export const ManageClientRoles: React.FC = () => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const { id: clientId } = useParams()
    const navigate = useNavigate()

    const deleteRoleConfig = {
        mutation: DELETE_CLIENT_ROLE,
        mutationName: 'deleteRole',
        onMutationSuccess: () => {
            retrieve?.({
                variables: {
                    options: queryOptions
                }
            })
        },
        isCustomMutationInput: true
    }

    const { save: deleteRole } = useGraphQLWrapper({
        ...deleteRoleConfig
    })

    const { directory, retrieve, queryOptions } = useDirectory({
        directoryConfig: {
            id: 'clientRoles',
            query: GET_CLIENT_ROLES,
            queryName: 'getRoles',
            queryVariables: {
                clientId: clientId
            },
            config: {
                customActions: [
                    {
                        icon: <EditIcon />,
                        label: `${t('general.edit')}`,
                        showInMenu: true,
                        action: (params) => navigate(`${params?.row?.id}`),
                        permissions: [
                            UserPermissionEnum.RolesCreateModify,
                            UserPermissionEnum.ClientCreateModify
                        ]
                    },
                    {
                        icon: (
                            <DeleteIcon
                                color={theme.palette.contentSecondary}
                            />
                        ),
                        label: `${t('general.delete')}`,
                        showInMenu: true,
                        action: (params) => {
                            deleteRole({
                                id: params?.row?.id,
                                clientId: clientId
                            })
                        },
                        permissions: [
                            UserPermissionEnum.RolesDelete,
                            UserPermissionEnum.ClientDelete
                        ]
                    }
                ],
                columns: [
                    {
                        field: 'title',
                        headerName: t('general.title'),
                        minWidth: 500,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={params?.value}
                                tabIndex={params.tabIndex}
                                onClick={() => navigate(`${params?.row?.id}`)}
                                isDirectoryLink
                            />
                        )
                    },
                    {
                        field: 'users',
                        headerName: t('general.users'),
                        minWidth: 80,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={params?.row?.usersCount}
                                tabIndex={params.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'dateCreated',
                        headerName: t('general.dateCreated'),
                        minWidth: 100,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={formatDate(params?.row?.createdAt)}
                                tabIndex={params.tabIndex}
                            />
                        )
                    }
                ]
            },
            toolbarConfig: {
                header: t('general.roles'),
                addNewButtonConfig: {
                    label: t('clients.newRole'),
                    action: () => navigate(`new`),
                    icon: AddIcon,
                    permissions: [
                        UserPermissionEnum.RolesCreateModify,
                        UserPermissionEnum.ClientCreateModify
                    ]
                }
            },
            enableSearch: true,
            rowHeight: 50
        }
    })

    return directory
}
