import React, { useState } from 'react'
import { Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { IconContainer } from '@components'
import { DotMenuIcon } from '@assets'
import { useCustomTheme } from '@hooks'
import { AccessibleAction } from '@hoc'
import { DropdownMenuProps } from './types'
import { getAllPermissions } from '@utils'

export const DropdownMenu = ({
    menuConfig,
    MenuButton,
    callbackData
}: DropdownMenuProps) => {
    const { theme } = useCustomTheme()
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget)
    }
    const handleMenuClose = () => {
        setMenuAnchor(null)
    }

    return (
        <AccessibleAction
            permissions={getAllPermissions(menuConfig)}
            component={
                <>
                    <Grid onClick={handleMenuOpen}>
                        {MenuButton ? (
                            <MenuButton />
                        ) : (
                            <IconButton>
                                <DotMenuIcon
                                    color={theme.palette.contentSecondary}
                                />
                            </IconButton>
                        )}
                    </Grid>
                    <Menu
                        anchorEl={menuAnchor}
                        open={Boolean(menuAnchor)}
                        onClose={handleMenuClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                        sx={{
                            borderRadius: '8px'
                        }}
                    >
                        {menuConfig?.map((menu, index) => (
                            <AccessibleAction
                                permissions={menu?.permissions}
                                component={
                                    <MenuItem
                                        key={index}
                                        tabIndex={0}
                                        onClick={() => {
                                            menu.onClick?.(callbackData)
                                            handleMenuClose()
                                        }}
                                        sx={{ padding: '10px' }}
                                    >
                                        {menu?.icon && (
                                            <IconContainer>
                                                <menu.icon />
                                            </IconContainer>
                                        )}
                                        <Typography
                                            sx={{
                                                fontWeight: 500,
                                                paddingLeft: '7px',
                                                paddingRight: '7px'
                                            }}
                                            variant='body2'
                                        >
                                            {menu?.label}
                                        </Typography>
                                    </MenuItem>
                                }
                            />
                        ))}
                    </Menu>
                </>
            }
        />
    )
}
