enum QuestionFeedbackTypeEnum {
  CommonFeedback = 'CMN',
  CorrectIncorrectFeedback = 'CIF',
  FeedbackAgainstAll = 'FAO',
  FeedbackAgainstSelected = 'FSO',
  NoFeedback = 'NFD',
}

enum QuestionFeedbackTypeGraphqlEnum {
  'CMN' = 'CMN',
  'CIF' = 'CIF',
  'FAO' = 'FAO',
  'FSO' = 'FSO',
  'NFD' = 'NFD',
}

export { QuestionFeedbackTypeEnum, QuestionFeedbackTypeGraphqlEnum }
