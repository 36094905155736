import React, { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isNull } from 'lodash'
import { Sector } from '@containers/grs-admin/clients-management/constants'
import { mapDropdownOptions } from '@dts/client-utils'
import {
    FormLabel,
    FormControlLabel,
    Typography,
    FormControl,
    RadioGroup,
    Radio,
    Grid
} from '@mui/material'
import {
    MaskInput,
    ControlledDatePicker,
    ControlledTextInput,
    ControlledSelect,
    ControlledAutoCompleteSelect,
    ControlledSwitch
} from '@components'
import { controlled } from '@hoc'
import { useGraphQLWrapper } from '@hooks'
import {
    GET_CITIES,
    GET_STATES,
    GET_ZIP_CODES,
    GET_CLIENT_TYPES,
    GET_VERTICALS
} from '@dts/graphql'
import { phoneNumberMask, TrainingsStatus } from '@constants'

export const CreateEditClientForm: React.FC = () => {
    const { control, setValue } = useFormContext()
    const { t } = useTranslation()

    const contractStart = useWatch({
        control,
        name: 'startDate'
    })
    const contractExpiry = useWatch({
        control,
        name: 'expiryDate'
    })
    const stateId = useWatch({
        control,
        name: 'state'
    })
    const cityId = useWatch({
        control,
        name: 'city'
    })
    const status = useWatch({
        control,
        name: 'status'
    })

    useEffect(() => {
        contractExpiry &&
            contractExpiry < contractStart &&
            setValue('expiryDate', contractStart)
    }, [contractStart])

    const { currentData: verticalsData } = useGraphQLWrapper({
        query: GET_VERTICALS,
        queryName: 'getVerticals',
        queryVariables: {
            args: {
                tab: TrainingsStatus.AVAILABLE
            }
        }
    })

    const { currentData: typesData } = useGraphQLWrapper({
        query: GET_CLIENT_TYPES,
        queryName: 'getClientTypes'
    })

    const { currentData: stateData } = useGraphQLWrapper({
        query: GET_STATES,
        queryName: 'getStatesDropDown'
    })

    const { currentData: cityData, retrieve: retrieveCities } =
        useGraphQLWrapper({
            query: GET_CITIES,
            queryName: 'getCitiesDropDown',
            retrieveOnMount: false
        })

    const { currentData: zipCodeData, retrieve: retrieveZipCodes } =
        useGraphQLWrapper({
            query: GET_ZIP_CODES,
            queryName: 'getZipCodes',
            retrieveOnMount: false
        })

    useEffect(() => {
        stateId && retrieveCities({ variables: { stateId } })
    }, [stateId])

    useEffect(() => {
        cityId && retrieveZipCodes({ variables: { cityId } })
    }, [cityId])

    // This will be updated with a reusable component
    const ControlledRadioGroup = controlled(RadioGroup)

    return (
        <Grid container>
            <Grid container columnSpacing={2} alignItems={'center'}>
                <Grid item xs={12} md={8}>
                    <ControlledSelect
                        name='clientType'
                        id='clientType'
                        label={t('createClient.type')}
                        placeholder={t('createClient.type')}
                        options={mapDropdownOptions(typesData)}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <ControlledSwitch
                        name='status'
                        label={
                            status ? t('general.active') : t('general.inactive')
                        }
                    />
                </Grid>
            </Grid>

            <Grid container columnSpacing={2}>
                <Grid item xs={12} md={8}>
                    <ControlledSelect
                        id='verticals'
                        name='verticals'
                        label={t('general.vertical')}
                        placeholder={t('general.vertical')}
                        options={mapDropdownOptions(verticalsData)}
                        isMulti
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} md={9}>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} md={4}>
                        <ControlledTextInput
                            id='clientId'
                            name='clientId'
                            label={t('createClient.clientId')}
                            placeholder={t('createClient.clientId')}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <ControlledTextInput
                            id='clientName'
                            name='clientName'
                            label={t('createClient.name')}
                            placeholder={t('createClient.name')}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={9}>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} md={6}>
                        <ControlledTextInput
                            id='email'
                            name='email'
                            label={t('general.email')}
                            placeholder={t('general.email')}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MaskInput
                            id='phone'
                            name='phone'
                            label={t('general.phone')}
                            mask={phoneNumberMask}
                            placeholder={t('general.phone')}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={9}>
                <ControlledTextInput
                    id='address'
                    name='address'
                    label={t('general.address')}
                    placeholder={t('general.address')}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={9}>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} md={4}>
                        <ControlledSelect
                            id='state'
                            name='state'
                            label={t('general.state')}
                            placeholder={t('general.state')}
                            options={mapDropdownOptions(stateData)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <ControlledSelect
                            id='city'
                            name='city'
                            label={t('general.city')}
                            placeholder={t('general.city')}
                            options={mapDropdownOptions(cityData)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} mt={0.6}>
                        <ControlledAutoCompleteSelect
                            name='zipCode'
                            id='zipCode'
                            label={t('general.zipCode')}
                            placeholder={t('general.zipCode')}
                            options={zipCodeData?.map((zipCode) => ({
                                value: zipCode?.id,
                                label: zipCode?.zipCode
                            }))}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={9}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <ControlledDatePicker
                            id='startDate'
                            label={t('createClient.contractStarted')}
                            name='startDate'
                            width='100%'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <ControlledDatePicker
                            id='expiryDate'
                            label={t('createClient.contractExpired')}
                            name='expiryDate'
                            width='100%'
                            isDisableFuture={false}
                            fullWidth
                            minDate={contractStart}
                            disabled={isNull(contractStart)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} mt={2}>
                {/* This will be updated with a reusable Radio Button component */}
                <FormControl id='sector'>
                    <FormLabel>
                        <Typography variant='h3'>
                            {t('createClient.sector')}
                        </Typography>
                    </FormLabel>
                    <ControlledRadioGroup row name='sector'>
                        <FormControlLabel
                            value={Sector.Public}
                            control={<Radio />}
                            label={t('general.public')}
                        />
                        <FormControlLabel
                            value={Sector.Private}
                            control={<Radio />}
                            label={t('general.private')}
                        />
                    </ControlledRadioGroup>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <Typography variant='h2' sx={{ mb: 1, mt: 3 }}>
                    {t('createClient.contactPerson.title')}
                </Typography>
                <Typography variant='body2' sx={{ mb: 2, mt: 1 }}>
                    {t('createClient.contactPerson.subtitle')}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4.5}>
                <ControlledTextInput
                    id='contactRole'
                    name='contactRole'
                    label={t('createClient.contactPerson.role')}
                    placeholder={t('createClient.contactPerson.role')}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={9}>
                <ControlledTextInput
                    id='contactName'
                    name='contactName'
                    label={t('createClient.contactPerson.name')}
                    placeholder={t('createClient.contactPerson.name')}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={9}>
                <ControlledTextInput
                    id='contactEmail'
                    name='contactEmail'
                    label={t('createClient.contactPerson.email')}
                    placeholder={t('createClient.contactPerson.email')}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={9}>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} md={6}>
                        <MaskInput
                            id='contactPhone'
                            name='contactPhone'
                            label={t('editAdmin.officePhone')}
                            placeholder={t('editAdmin.officePhone')}
                            mask={phoneNumberMask}
                            width='100%'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MaskInput
                            id='contactMobile'
                            name='contactMobile'
                            label={t('editAdmin.mobilePhone')}
                            placeholder={t('editAdmin.mobilePhone')}
                            mask={phoneNumberMask}
                            width='100%'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
