import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import { useNavigations } from '@hooks'
import { TextPageHeaderModalProps } from './types'

export const TextPageHeader: React.FC<TextPageHeaderModalProps> = ({
    title,
    showBreadcrumbs = false,
    backButtonConfig
}) => {
    const { t } = useTranslation()

    const { breadcrumbComponent, backRedirectionComponent } = useNavigations({
        showBreadcrumbs,
        backButtonConfig
    })

    return (
        <>
            <Grid container py={'8px'} flexDirection={'column'}>
                <Grid item display={'flex'} flexDirection={'row'}>
                    {backRedirectionComponent}
                    <Typography
                        fontSize={'26px'}
                        fontWeight={'600'}
                        variant='h1'
                    >
                        {title}
                    </Typography>
                </Grid>

                {breadcrumbComponent}
            </Grid>
        </>
    )
}
