import { useEffect, useState } from 'react'
import { isEmpty, isArray, isNull } from 'lodash'
import {
  useMutation,
  useLazyQuery,
  QueryLazyOptions,
  OperationVariables,
  useApolloClient,
  FetchMoreQueryOptions,
} from '@apollo/client'
import {
  ApolloResponse,
  MutationVariableInput,
  UseGraphQLParams,
} from '../types'
import { removeTypename } from '../utils'
import { ResponseStatusEnum } from '../../../constants/lib'

export const useGraphQL = <
  QueryResult,
  MutationInput,
  QueryVariables = object,
  SubscriptionVariables = object,
>({
  id,
  query,
  mutation,
  queryName,
  mutationName,
  queryVariables,
  onError,
  onMutationSuccess,
  onQuerySuccess,
  onResponseMessages,
  subscription,
  subscriptionVariables,
  updateQuery,
  retrieveOnMount = true,
  isCustomMutationInput = false,
  token,
  preventReload = false,
  clearCacheOnUnmount = false,
  fetchPolicy = 'network-only',
  clientId,
}: UseGraphQLParams<
  QueryResult,
  MutationInput,
  QueryVariables,
  SubscriptionVariables
>) => {
  const client = useApolloClient()
  const [data, setData] = useState<ApolloResponse<QueryResult>>()
  const [currentData, setCurrentData] = useState<QueryResult>()
  const [error, setError] = useState<ApolloResponse<QueryResult>>()

  let retrieve = (_options?: QueryLazyOptions<OperationVariables>): void =>
    undefined
  let mutate
  let refetch
  const [queryLoading, setQueryLoading] = useState(undefined)
  let mutationLoading = false
  let getMore: (
    options?: FetchMoreQueryOptions<
      OperationVariables,
      ApolloResponse<QueryResult>
    >,
  ) => Promise<void> = async (_options) => {}

  if (mutation && mutationName) {
    const [mutationCall, { loading }] = useMutation<
      ApolloResponse<QueryResult>,
      MutationVariableInput<MutationInput>
    >(mutation)

    mutate = mutationCall
    mutationLoading = loading
  }

  if ((query && queryName) || subscription) {
    const [
      fetch,
      {
        data: queryData,
        loading,
        fetchMore,
        subscribeToMore,
        refetch: _refetch,
      },
    ] = useLazyQuery<ApolloResponse<QueryResult>>(query, {
      ...(token && {
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
            'client-Id': clientId,
          },
        },
      }),
      variables: { ...queryVariables },
      fetchPolicy,
      notifyOnNetworkStatusChange: !preventReload,
    })

    retrieve = fetch
    refetch = _refetch
    // @ts-ignore
    getMore = async (options) => fetchMore(options)

    useEffect(() => {
      // setQueryLoading(loading)
      if (!loading && queryData) {
        setData(queryData)
        const result = queryData?.[queryName]
        if (
          result &&
          (!isEmpty(result.message?.messages) || !isNull(result.meta))
        ) {
          onResponseMessages?.(result.message, result?.meta)
        }
        setCurrentData(result?.data)
        onQuerySuccess?.(result)
      }
    }, [queryData, loading])

    useEffect(() => {
      setQueryLoading(loading)
    }, [loading])
    // this below useEffect is used for subscription
    useEffect(() => {
      subscription &&
        subscriptionVariables &&
        updateQuery &&
        subscribeToMore({
          document: subscription,
          variables: subscriptionVariables,
          onError: (err) => console.log('Subscription Error: ', err.message),
          updateQuery: (prev, { subscriptionData }) =>
            updateQuery(prev, subscriptionData),
        })
    }, [])
  }

  // Retrieves data by calling query on first load
  useEffect(() => {
    if (retrieveOnMount) {
      retrieve?.()
    }

    return () => {
      if (query && queryName && clearCacheOnUnmount) {
        client.cache.evict({ id: 'ROOT_QUERY', fieldName: queryName })
        client.cache.gc()
      }
    }
  }, [])

  const save = async (
    mutationInput: MutationInput & { id?: string },
    extraData?: QueryVariables & { name?: string },
  ) => {
    const input = isArray(mutationInput)
      ? mutationInput
      : { ...mutationInput, ...(extraData || {}) }

    const variables: MutationVariableInput<MutationInput & { id?: string }> =
      isCustomMutationInput
        ? (input as MutationVariableInput<MutationInput & { id?: string }>)
        : {
            input,
          }

    try {
      const { data } = await mutate({
        variables,

        ...(token && {
          context: {
            headers: {
              authorization: `Bearer ${token}`,
              'client-Id': clientId,
            },
          },
        }),
      })

      const response = data?.[mutationName]
      if (
        response &&
        (!isEmpty(response.message?.messages) || !isEmpty(response.meta))
      ) {
        onResponseMessages?.(response.message, response.meta)
      }

      if (
        response?.message?.type === ResponseStatusEnum.Success ||
        !isEmpty(response?.data)
      ) {
        setCurrentData(removeTypename(response?.data))
        onMutationSuccess?.(response)
      }

      return response
    } catch (e) {
      // const errors: Array<string> = parseGraphqlErrors(error as ApolloError);
      onError?.(e?.graphQLErrors)
      setError(e)
      throw e
    } finally {
      // finally statement
    }
  }

  return {
    data: data && !isEmpty(data) ? data?.[queryName ?? ''] : data,
    currentData,
    save,
    retrieve,
    queryLoading,
    mutationLoading,
    error,
    getMore,
    refetch,
  }
}
