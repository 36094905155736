import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { EmailIcon, GlobeIcon, LinkIcon, ArrowRightIcon } from '@assets'
import {
    Button,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Link as MuiLink
} from '@mui/material'
import { useCustomTheme } from '@hooks'
import { TooltipPlacement } from '@constants'
import { ensureProtocol, onKeyDown } from '@utils'
import { StyledMuiLink } from '@containers/resources/style'
import { StyledHyperLink, StyledLink } from './styles'

export * from './styles'

type LinkProps = {
    route: object
    tooltipPlacement: string
    selected: boolean
    onClick: VoidFunction
    menu: boolean
    indent: boolean
    list: boolean
    selectedIconColor: string
    iconColor: string
    fillColor: string
    children: React.FC
    sx: object
    isBottomNav?: boolean
    hasTooltip: boolean
    iconContainerSx: object
    hasArrowIcon: boolean
}

export const Link: React.FC<LinkProps> = ({
    route,
    tooltipPlacement = TooltipPlacement.topEnd,
    onClick,
    menu = false,
    indent = true,
    list = false,
    selectedIconColor,
    iconColor,
    fillColor,
    children,
    sx,
    isBottomNav,
    hasTooltip,
    hasArrowIcon = false,
    iconContainerSx,
    ...props
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { theme } = useCustomTheme()
    const { pathname } = useLocation()

    const checkPathSelection = (routePath: string) => {
        const extractedPaths = pathname.split('/')
        return (
            pathname === routePath ||
            extractedPaths.includes(routePath) ||
            route?.isLanding === pathname
        )
    }

    const selected = checkPathSelection(route?.path)

    const renderStyledLink = () => {
        if (route?.isExternalLink) {
            const url = ensureProtocol(route?.path)
            return (
                <StyledMuiLink
                    href={url}
                    color={theme.palette.contentLink}
                    target='_blank'
                    underline='none'
                    rel='noopener noreferrer'
                >
                    {route?.label}
                </StyledMuiLink>
            )
        } else {
            return (
                <StyledLink
                    border
                    menu={menu}
                    indent={indent}
                    to={route.path}
                    state={props.state}
                    style={sx}
                    onKeyDown={(e) =>
                        onKeyDown(e, () => navigate(route?.path), false)
                    }
                    {...(isBottomNav && {
                        'aria-label': route?.ariaLabel
                    })}
                >
                    {list ? (
                        <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
                            <ListItem
                                selected={selected}
                                onClick={onClick}
                                key={route.id}
                                role='listitem'
                                aria-hidden={'true'}
                                aria-labelledby={route.label}
                            >
                                {route.icon ? (
                                    <ListItemIcon
                                        id={route.label}
                                        style={iconContainerSx}
                                    >
                                        <route.icon
                                            color={
                                                selected
                                                    ? selectedIconColor
                                                    : iconColor
                                            }
                                            fill={fillColor}
                                            fillOpacity={selected ? '1' : '0.1'}
                                        />
                                    </ListItemIcon>
                                ) : null}
                                <ListItemText
                                    id={route.label}
                                    primaryTypographyProps={{
                                        fontWeight: 500
                                    }}
                                    sx={{
                                        color: selected
                                            ? selectedIconColor
                                            : `${theme.palette.contentPrimary}`,
                                        lineHeight: '16.8px'
                                    }}
                                    primary={t(route.label)}
                                />
                                {hasArrowIcon ? (
                                    <Grid width={'10%'}>
                                        <ArrowRightIcon
                                            color={
                                                selected
                                                    ? selectedIconColor
                                                    : iconColor
                                            }
                                        />
                                    </Grid>
                                ) : null}
                            </ListItem>
                        </List>
                    ) : children ? (
                        children
                    ) : (
                        route.label
                    )}
                </StyledLink>
            )
        }
    }

    return (
        <>
            {hasTooltip ? (
                <Tooltip
                    title={t(route.label)}
                    PopperProps={{
                        sx: {
                            '& .MuiTooltip-tooltip': {
                                position: 'absolute',
                                bottom: '0px'
                            }
                        }
                    }}
                    placement={tooltipPlacement}
                >
                    {renderStyledLink()}
                </Tooltip>
            ) : (
                renderStyledLink()
            )}
            {route?.hasDivider && (
                <Divider
                    sx={{
                        marginTop: '0.5rem',
                        borderColor: `${theme.palette.backgroundTertiary}`
                    }}
                    variant='middle'
                />
            )}
        </>
    )
}

export const LinkButton = ({ href = '', state = {}, label, ...props }) => {
    const button = (
        <StyledLink to={href} state={state}>
            <Button {...props}>{label}</Button>
        </StyledLink>
    )
    return button
}

export const HyperLink = ({ link, hasDefaultStyling = false, sx }) => {
    const { theme } = useCustomTheme()
    const icon = link?.value?.toLowerCase()?.includes('https://') ? (
        <GlobeIcon />
    ) : link?.value?.includes('@') ? (
        <EmailIcon />
    ) : (
        <LinkIcon />
    )

    return (
        <>
            {hasDefaultStyling ? (
                <MuiLink
                    href={link}
                    underline='hover'
                    target='_blank'
                    sx={{
                        display: 'flex',
                        alignSelf: 'center',
                        ...sx
                    }}
                >
                    {link}
                </MuiLink>
            ) : (
                <>
                    <ListItem key={link?.id}>
                        <StyledHyperLink
                            to={`${link.value}`}
                            color={theme?.palette?.contentLink}
                            target='_blank'
                        >
                            <Grid container columnGap={1}>
                                <Grid>{icon}</Grid>
                                <Grid>{link?.value}</Grid>
                            </Grid>
                        </StyledHyperLink>
                    </ListItem>
                    <Divider />
                </>
            )}
        </>
    )
}
