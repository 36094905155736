import React from 'react'
import { useCustomTheme } from '@hooks'
import { SvgProps } from '@types'

const ArrowDownCircleIcon: React.FC<SvgProps> = ({
    color,
    width = '20',
    height = '20'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentPrimaryInverse

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M13.3333 9.99967L9.99996 13.333M9.99996 13.333L6.66663 9.99967M9.99996 13.333V6.66634M18.3333 9.99967C18.3333 5.3973 14.6023 1.66634 9.99996 1.66634C5.39759 1.66634 1.66663 5.3973 1.66663 9.99967C1.66663 14.602 5.39759 18.333 9.99996 18.333C14.6023 18.333 18.3333 14.602 18.3333 9.99967Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default ArrowDownCircleIcon
