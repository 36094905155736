import { UserPermissionEnum } from '@dts/constants'
import {
    LearnPage,
    LearningCenterPage,
    RouteLayout,
    ViewArticlePage
} from '@pages'

export const getLearningCenterRoutes = (canUserAccess?: boolean) => [
    {
        path: '',
        component: LearningCenterPage,
        canUserAccess
    },
    {
        path: ':id',
        component: ViewArticlePage,
        componentProps: { showBreadcrumbs: true },
        isHeaderLink: false,
        isSideNav: false,
        canUserAccess
    }
]

export const getLearnRoutes = (canUserAccess?: boolean) => [
    {
        path: '',
        component: LearnPage,
        canUserAccess,
        permissions: [UserPermissionEnum.LearnView]
    },
    {
        path: 'learning-center',
        component: RouteLayout,
        isHeaderLink: false,
        isSideNav: false,
        canUserAccess,
        subRoutes: [...getLearningCenterRoutes(canUserAccess)]
    }
]
