import React from 'react'
import { Grid, Typography } from '@mui/material'
import { TypographyVariants } from '@constants'
import { MetricCardProps } from './types'
import { StyledCardContainer } from './styles'

export const MetricCard: React.FC<MetricCardProps> = ({
    value,
    title,
    Icon
}) => (
    <StyledCardContainer container>
        <Grid container flexDirection={'row'} justifyContent={'space-between'}>
            <Grid item>
                <Typography variant={TypographyVariants.h2}>
                    {value ?? 0}
                </Typography>
            </Grid>
            <Grid item>{Icon}</Grid>
        </Grid>
        <Grid item>
            <Typography variant={TypographyVariants.caption} fontSize={'15px'}>
                {title}
            </Typography>
        </Grid>
    </StyledCardContainer>
)
