import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, UserDetails } from '../../fragments'

export const GET_USER = gql`
  ${UserDetails('UserDataDto')}
  query getUser {
    getUser {
      data {
        ...UserDetails
      }
    }
  }
`

export const GET_USER_BY_ID = gql`
  ${MessagesFragment('GetUserResponseDto')}
  ${MetaFragment('GetUserResponseDto')}
  ${UserDetails('UserDataDto')}
  query getUserById($id: String!, $clientId: String) {
    getUserById(id: $id, clientId: $clientId) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...UserDetails
      }
    }
  }
`
