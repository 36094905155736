import React from 'react'
import { SvgProps } from '@types'

const CloseIcon: React.FC<SvgProps> = ({
    color = '#8C8C8C',
    height = '32',
    width = '32'
}) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 32 32`}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M24 8L8 24M8 8L24 24'
            stroke={color}
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
)

export default CloseIcon
