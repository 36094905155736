import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'
import { ListItemText } from '@mui/material'

export const StyledLink = styled(NavLink, {
    shouldForwardProp: (prop) =>
        prop !== 'menu' &&
        prop !== 'border' &&
        prop !== 'indent' &&
        prop !== 'color'
})(({ theme, menu, indent = true, border = false, color, style }) => ({
    borderBottom: menu ? `2px solid  ${theme.palette.secondary.dark}` : 'unset',
    textDecoration: 'none',
    overflow: 'hidden',
    display: style?.display,
    alignItems: style?.alignItems,
    color: menu ? `${theme.palette.secondary.dark}` : 'inherit',
    '& span': {
        letterSpacing: '-0.2px',
        fontSize: '14px',
        fontWeight: '500'
    },
    '& li': {
        padding: !indent ? 0 : 'auto',
        /** The code below might be useful in the future. It'll be removed after testing */
        // borderLeft:
        //     border && menu
        //         ? `2px solid ${theme.palette.backgroundSecondary}`
        //         : `2px solid ${theme.palette.backgroundPrimary}`,
        '&:hover': {
            backgroundColor:
                indent && menu && theme.palette.BrandPrimaryContainer
            //  borderRadius: '12px'
            /** Used in future if border-left is required */
            // borderLeft:
            //     border &&
            //     indent &&
            //     menu &&
            //     `2px solid ${theme.palette.secondary.dark}`
        }
    }
}))

interface StyledMenuItemProps {
    selected?: boolean
}

export const StyledMenuItem = styled(ListItemText, {
    shouldForwardProp: (prop) => true
})<StyledMenuItemProps>(({ selected, theme }) => ({
    '& span': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '14px',
        fontWeight: 500,
        color: selected
            ? `${theme.palette.contentLink}`
            : theme.palette.contentPrimary
    }
}))

export const StyledHyperLink = styled(NavLink)(({ theme }) => ({
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: `${theme.palette.primary.main}`,
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    letterSpacing: '-0.16px'
}))
