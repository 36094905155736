import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_INSIGHT_METADATA = gql`
  ${MetaFragment('InsightMetaDataResponseDto')}
  ${MessagesFragment('InsightMetaDataResponseDto')}
  query getInsightMetaData {
    getInsightMetaData {
      ...MetaFragment
      ...MessagesFragment
      data {
        insightVideoOption {
          id
          title
          available
          displayOrder
        }
        insightTimeFrame {
          id
          title
          available
          displayOrder
        }
      }
    }
  }
`
