import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_CHART_META_DATA = gql`
  ${MessagesFragment('ChartMetaDataResponseDto')}
  ${MetaFragment('ChartMetaDataResponseDto')}
  query getChartMetaData {
    getChartMetaData {
      ...MessagesFragment
      ...MetaFragment
      data {
        chartType {
          id
          title
          available
          displayOrder
        }
        reportTimeFrame {
          id
          title
          available
          displayOrder
        }
        reportGranularity {
          id
          title
          available
          displayOrder
        }
      }
    }
  }
`
