export enum InsightPanelId {
  VIEWED = 'VIE',
  LIKED = 'LIK',
  WATCH_TIME = 'WAT',
}

export enum InsightPanelKey {
  VIEWED = 'views',
  LIKED = 'liked',
  WATCH_TIME = 'watchTime',
}
export enum VideoInsightCardKeys {
  VIEWS = 'views',
  LIKES = 'likes',
  WATCH_TIME_IN_MINUTES = 'watchTimeInMins',
}
