import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    DataGridPro,
    DataGridProProps,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    GridPaginationModel,
    GridSortModel,
    GridSlots,
    GridColumnHeaderParams
} from '@mui/x-data-grid-pro'
import {
    QueryOptions,
    ColumnDef,
    useCustomActions,
    useCustomTheme
} from '@hooks'
import { Typography, Grid, Tooltip, LinearProgress } from '@mui/material'
import { controlled } from '@hoc'
import { CustomActions } from '@types'
import { CustomLoader } from '@components'
import { getHeaderAriaLabel } from '@utils'
import { StyledContainer, StyledDataGrid, StyledHeader } from './styles'

export type DataGridProps = DataGridProProps & {
    columns: ColumnDef
    value: Array<object>
    setQueryOptions?: (queryOptions: QueryOptions) => void
    queryOptions?: QueryOptions
    maxWidth?: number
    minWidth?: number
    rowCount?: number
    paginationModel?: GridPaginationModel
    setPaginationModel?: (model: GridPaginationModel) => void
    pagination?: boolean
    onChange?: (data) => void
    headerLabel?: string
    headerColor?: string
    id: string
    maxHeight?: number
    headerHeight?: number
    customActions?: Array<CustomActions>
    handleSortModelChange?: (sortModel: GridSortModel) => void
    rowHeight?: number
    directoryLoading?: boolean
    shouldShowLoader?: boolean
}

export const DataTable: React.FC<DataGridProps> = ({
    setQueryOptions,
    maxWidth,
    minWidth,
    columns,
    rowCount,
    paginationModel,
    setPaginationModel,
    pagination = false,
    queryOptions,
    value,
    onChange,
    headerLabel,
    hideFooter = false,
    headerColor,
    id,
    customActions,
    headerHeight = 48,
    handleSortModelChange,
    maxHeight,
    rowHeight,
    genericActions,
    CustomDetailPanelToggle,
    directoryLoading = true,
    shouldShowLoader,
    ...props
}) => {
    const [rows, setRows] = useState([])
    const { theme } = useCustomTheme()
    const { t } = useTranslation()

    useEffect(() => {
        value && setRows(value)
    }, [value])

    useEffect(() => {
        const hiddenTextarea = document.querySelector(
            '.MuiSelect-nativeInput[aria-hidden="true"]'
        )
        if (hiddenTextarea) {
            hiddenTextarea.setAttribute('aria-label', t('general.rowsPerPage'))
        }
    }, [columns])

    const { getCustomActions, showActionsColumn } =
        customActions || genericActions
            ? useCustomActions({
                  customActions: customActions,
                  genericActions
              })
            : { getCustomActions: undefined }

    const { gridColumns } = useMemo(() => {
        const tempColumns: Array<ColumnDef> = []

        columns?.forEach(
            (
                { flex = 1, renderCell, field, minWidth = 120, ...props },
                index
            ) => {
                index === 1 &&
                    props.detailCompConfig &&
                    tempColumns.push({
                        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                        maxWidth: 32,
                        renderCell: (params) => (
                            <CustomDetailPanelToggle
                                id={params.id}
                                value={params.value}
                            />
                        )
                    })

                tempColumns.push({
                    ...props,
                    field,
                    flex,
                    minWidth,
                    sortable: false,
                    renderHeader: (params: GridColumnHeaderParams) => (
                        <StyledHeader
                            aria-label={getHeaderAriaLabel(props?.headerName)}
                        >
                            {props?.headerName}
                        </StyledHeader>
                    ),
                    renderCell: !renderCell
                        ? (params) => (
                              <Grid sx={{ wordBreak: 'break-all' }}>
                                  <Tooltip title={params.value}>
                                      {params.value}
                                  </Tooltip>
                              </Grid>
                          )
                        : (params) => (
                              <Grid sx={{ wordBreak: 'break-all' }}>
                                  {renderCell(params)}
                              </Grid>
                          )
                })
            }
        )

        if (
            (customActions || genericActions) &&
            getCustomActions &&
            showActionsColumn
        ) {
            tempColumns.push({
                field: 'actions',
                type: 'actions',
                minWidth: 80,
                getActions: (params) => getCustomActions(params) ?? []
            })
        }

        return { gridColumns: tempColumns }
    }, [columns])

    return (
        <StyledContainer
            id={id}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            headerColor={headerColor}
        >
            {headerLabel && (
                <Typography variant='h4' gutterBottom>
                    {headerLabel}
                </Typography>
            )}
            <StyledDataGrid
                {...props}
                rows={rows}
                getRowHeight={() => (rowHeight ? undefined : 'auto')} // If row height not passed, will take auto
                autoHeight={true}
                columns={gridColumns}
                initialState={{
                    pagination: {
                        paginationModel
                    },
                    pinnedColumns: { right: ['actions'] }
                }}
                disableRowSelectionOnClick
                disableColumnMenu
                sortingMode='server'
                paginationMode='server'
                onSortModelChange={handleSortModelChange}
                pagination={pagination}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[10, 25, 50, 100]}
                rowCount={rowCount}
                paginationModel={paginationModel}
                hideFooter={hideFooter}
                columnHeaderHeight={headerHeight}
                rowHeight={rowHeight ?? 45}
                // Add custom loader in DataGridPro
                slots={{
                    loadingOverlay: CustomLoader as GridSlots['loadingOverlay']
                }}
                loading={shouldShowLoader && directoryLoading}
            />
        </StyledContainer>
    )
}

export const ControlledDataTable = controlled(DataTable)
