import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isMediumScreen } from '@utils'
import { formatDate } from '@dts/client-utils'
import { Avatar, Box, Grid, Typography } from '@mui/material'
import { ContactGrid, DetailTable, Map } from '@components'
import { ActiveIcon, CampusLogo } from '@assets'
import { useCustomTheme } from '@hooks'

export const ClientDetailsForm = () => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const { getValues } = useFormContext()
    const [details, setDetails] = useState([{}])
    const {
        id,
        clientId,
        title,
        active,
        email,
        phone,
        address,
        city,
        state,
        zipCode,
        contractExpiryDate,
        contractStartDate,
        clientContact,
        logoSrc
    } = getValues()

    useEffect(() => {
        id &&
            setDetails(
                [
                    clientId
                        ? {
                              label: `${t('general.id')}: `,
                              value: clientId
                          }
                        : false,
                    {
                        label: `${t('clients.details.activeTraining')}: `,
                        value: 0
                    },
                    {
                        label: `${t('clients.details.totalCourses')}: `,
                        value: 0
                    },

                    contractStartDate
                        ? {
                              label: `${t('general.dateJoined')}: `,
                              value: formatDate(contractStartDate) || '-'
                          }
                        : false,

                    contractExpiryDate
                        ? {
                              label: `${t('createClient.contractExpired')}: `,
                              value: formatDate(contractExpiryDate) || '-'
                          }
                        : false
                ].filter(Boolean)
            )
    }, [contractExpiryDate, contractStartDate, id])

    return (
        <Grid container p={2}>
            <Grid item sm={12} md={6}>
                <Grid container columnSpacing={2}>
                    <Grid item sm={12} md={2} mb={2}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: isMediumScreen()
                                    ? 'left'
                                    : 'center'
                            }}
                        >
                            <Avatar
                                id='avatar'
                                sx={{ height: '80px', width: '80px' }}
                                src={logoSrc || CampusLogo}
                                alt={t('general.brokenImage')}
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={12} md={8}>
                        <Grid container direction={'row'} gap={2}>
                            {title && (
                                <Typography variant='h2' id={'title'}>
                                    {title}
                                </Typography>
                            )}
                            <Grid item display={'flex'} id='status' pt={0.2}>
                                <ActiveIcon
                                    color={
                                        active
                                            ? theme.palette.activeGreen
                                            : theme.palette.errorDangerMedium
                                    }
                                />
                                <Typography variant='h4' id={'name'} mt={0.2}>
                                    {active
                                        ? `${t('general.active')}`
                                        : `${t('general.inactive')}`}
                                </Typography>
                            </Grid>
                        </Grid>

                        <ContactGrid
                            email={email}
                            phone={phone}
                            address={address}
                            state={state}
                            city={city}
                            zipCode={zipCode}
                            valueSx={{
                                fontSize: '0.9rem',
                                fontWeight: '400',
                                wordBreak: 'break-all',
                                minWidth: '150px'
                            }}
                            iconBackground={
                                theme.palette.backgroundBrandPrimary
                            }
                            iconColor={theme.palette.contentPrimaryInverse}
                        />

                        <Grid my={2}>
                            {details?.length && (
                                <DetailTable
                                    title={t('clients.otherDetails')}
                                    details={details}
                                    labelSx={{
                                        fontSize: '12px',
                                        fontWeight: '400',
                                        color: theme.palette.contentPrimary
                                    }}
                                    valueSx={{
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        float: 'right',
                                        wordBreak: 'break-all'
                                    }}
                                />
                            )}
                        </Grid>

                        {clientContact && (
                            <Typography
                                mt={1.5}
                                mb={1}
                                variant='h4'
                                color={theme.palette.errorDangerMedium}
                            >
                                {t('createClient.contactPerson.title')}
                            </Typography>
                        )}
                        {clientContact?.title && (
                            <Typography variant='h2' id={'clientTitle'}>
                                {clientContact?.title}
                            </Typography>
                        )}
                        {clientContact?.designation && (
                            <Typography variant='h4' id={'clientDesignation'}>
                                {clientContact?.designation}
                            </Typography>
                        )}

                        <ContactGrid
                            clientContact={clientContact}
                            valueSx={{
                                fontSize: '0.9rem',
                                fontWeight: '400',
                                wordBreak: 'break-all',
                                minWidth: '150px'
                            }}
                            iconBackground={
                                theme.palette.backgroundBrandPrimary
                            }
                            iconColor={theme.palette.contentPrimaryInverse}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sm={12} md={6}>
                <Box borderRadius={'10px'} marginTop={2} height={'100%'}>
                    <Map />
                </Box>
            </Grid>
        </Grid>
    )
}
