import React, { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
    ClickAwayListener,
    Grid,
    Link,
    Tooltip,
    Typography
} from '@mui/material'
import { ControlledSelect, ControlledTextInput } from '@components'
import { GET_COURSES, GET_TOPICS } from '@dts/graphql'
import { SurveySummarySchema, mapDropdownOptions } from '@dts/client-utils'
import { useCustomTheme, useGraphQLWrapper, useModalWithGraphQL } from '@hooks'

import { FeedbackIcon } from '@assets'
import { SurveyTypeEnum, UserPermissionEnum } from '@dts/constants'
import { startCase } from 'lodash'
import { onKeyDown } from '@utils'
import { SurveyQuestions } from './survey-questions'
import { SurveySummary } from './survey-summary'
import { StyledList, StyledListItem } from './style'

export const SurveyCreationDetails = () => {
    const [open, setOpen] = useState(false)
    const { theme } = useCustomTheme()
    const { t } = useTranslation()
    const { setValue, getValues, control } = useFormContext()
    const [searchParams] = useSearchParams()
    const [surveys, setSurveys] = useState([])

    const handleTooltipClose = () => {
        setOpen(false)
    }

    const handleTooltipOpen = () => {
        setOpen(true)
    }

    const surveyId = useWatch({
        control,
        name: 'id'
    })

    const topicId = useWatch({
        control,
        name: 'topicId'
    })

    const surveyTypeId: SurveyTypeEnum = searchParams.get('surveyType') // Type of survey being created or opened

    const { queryLoading, currentData: coursesData } = useGraphQLWrapper({
        query: GET_COURSES,
        queryName: 'getCourses',
        retrieveOnMount: true,
        queryVariables: {
            surveyTypeId
        }
    })

    const { currentData: topicsData } = useGraphQLWrapper({
        query: GET_TOPICS,
        queryName: 'getTopics',
        retrieveOnMount: true,
        queryVariables: {
            forSurvey: true
        }
    })

    const { openModalWithForm } = useModalWithGraphQL({
        modalConfig: {
            maxWidth: 'sm',
            Component: SurveySummary,
            updationConfig: {
                primaryButtonText: t('general.update'),
                header: 'Feedback Configuration'
            },
            hasCancelButton: true
        },
        permissions: [UserPermissionEnum.SurveyCreateModify],
        yupResolverSchema: SurveySummarySchema
    })

    const getDropdownOptions = (data) =>
        data?.map((item) => ({
            value: item?.id,
            label: item?.title,
            surveyId: item?.surveys?.[0]?.id,
            surveyTitle: item?.surveys?.[0]?.title,
            isGreyedOut:
                item?.surveys?.[0]?.id &&
                item?.surveys?.[0]?.id !== getValues('id')
        })) ?? []

    useEffect(() => {
        setValue(
            'surveyTypeTitle',
            startCase(
                Object.keys(SurveyTypeEnum).find(
                    (key) =>
                        SurveyTypeEnum[key as keyof typeof SurveyTypeEnum] ===
                        surveyTypeId
                )
            )
        )
    })

    useEffect(() => {
        if (topicId && topicsData) {
            // Find course
            const course = topicsData?.find((topic) => topic.id === topicId)

            setSurveys(
                course?.surveys?.filter((survey) => survey.id !== surveyId) ||
                    []
            )
        }
    }, [topicId])

    const tooltipValue = (
        <StyledList>
            {surveys.map((survey, index) => (
                <StyledListItem key={index}>{survey.title}</StyledListItem>
            ))}
        </StyledList>
    )

    return (
        <Grid container flexDirection={'column'}>
            <Grid item md={12} xs={12}>
                <Grid container md={12} xs={12}>
                    <Grid item md={7} xs={12} pr={1}>
                        <ControlledTextInput
                            name='title'
                            label={'Survey Title'}
                            id='title'
                            placeholder={t('general.enterTitle')}
                            multiline
                            rows={1}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4.5} xs={10}>
                        {surveyTypeId === SurveyTypeEnum.Pulse ? (
                            <>
                                <ControlledSelect
                                    name='topicId'
                                    id='topicId'
                                    label={t('general.topic')}
                                    marginTop={'1rem'}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            paddingTop: '8.5px !important',
                                            paddingBottom: '8.5px !important'
                                        }
                                    }}
                                    options={mapDropdownOptions(topicsData)}
                                />

                                {surveys?.length ? (
                                    <>
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                color: theme.palette
                                                    .contentTertiary
                                            }}
                                        >
                                            {t('survey.pulse.alreadyExists')}
                                            <ClickAwayListener
                                                onClickAway={handleTooltipClose}
                                            >
                                                <Tooltip
                                                    PopperProps={{
                                                        disablePortal: true
                                                    }}
                                                    onClose={handleTooltipClose}
                                                    open={open}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={tooltipValue}
                                                    arrow
                                                >
                                                    <Link
                                                        component='button'
                                                        variant='body2'
                                                        sx={{
                                                            fontSize: 12,
                                                            color: theme.palette
                                                                .contentPrimary
                                                        }}
                                                        onClick={
                                                            handleTooltipOpen
                                                        }
                                                    >
                                                        {t(
                                                            'general.viewDetails'
                                                        )}
                                                    </Link>
                                                </Tooltip>
                                            </ClickAwayListener>
                                        </Typography>
                                    </>
                                ) : null}
                            </>
                        ) : (
                            <>
                                <ControlledSelect
                                    name='course'
                                    id='course'
                                    label={t('general.course')}
                                    marginTop={'1rem'}
                                    isObject
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            paddingTop: '8.5px !important',
                                            paddingBottom: '8.5px !important'
                                        }
                                    }}
                                    options={getDropdownOptions(coursesData)}
                                />
                            </>
                        )}
                    </Grid>
                    {surveyTypeId !== SurveyTypeEnum.Pulse && (
                        <Grid
                            item
                            paddingTop={'1.3rem'}
                            md={0.5}
                            xs={2}
                            display={'flex'}
                            justifyContent={'center'}
                            tabIndex={0}
                            onKeyDown={(e) =>
                                onKeyDown(
                                    e,
                                    () =>
                                        openModalWithForm(
                                            { ...getValues() },
                                            (formValues) => {
                                                setValue(
                                                    'summaryResponseIconId',
                                                    formValues?.summaryResponseIconId
                                                )
                                                setValue(
                                                    'summaryTitle',
                                                    formValues?.summaryTitle
                                                )
                                                setValue(
                                                    'summaryDescription',
                                                    formValues?.summaryDescription
                                                )
                                                setValue(
                                                    'summaryEnabled',
                                                    formValues?.summaryEnabled
                                                )
                                            }
                                        ),
                                    false
                                )
                            }
                            alignItems={'flex-start'}
                            onClick={() =>
                                openModalWithForm(
                                    { ...getValues() },
                                    (formValues) => {
                                        setValue(
                                            'summaryResponseIconId',
                                            formValues?.summaryResponseIconId
                                        )
                                        setValue(
                                            'summaryTitle',
                                            formValues?.summaryTitle
                                        )
                                        setValue(
                                            'summaryDescription',
                                            formValues?.summaryDescription
                                        )
                                        setValue(
                                            'summaryEnabled',
                                            formValues?.summaryEnabled
                                        )
                                    }
                                )
                            }
                        >
                            <FeedbackIcon />
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <SurveyQuestions />
        </Grid>
    )
}
