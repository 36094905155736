import { CustomActionConfigTypes } from '@constants'
import { useFormFeedback, useGraphQLWrapper, useModalWithGraphQL } from '@hooks'

export const useActionInterceptor = ({ actionConfig }) => {
    let onClickFn
    if (actionConfig) {
        if (actionConfig?.type === CustomActionConfigTypes.openDialog) {
            const { openDialog } = useModalWithGraphQL(actionConfig)
            onClickFn = openDialog
        } else {
            const {
                mutation,
                mutationName,
                query,
                queryName,
                mapQueryResultToForm,
                mapFormToMutationInput
            } = actionConfig

            if (actionConfig?.type === CustomActionConfigTypes.callMutation) {
                const { save } = useGraphQLWrapper({
                    mutation,
                    mutationName
                })
                const mutationInput = actionConfig?.mapValuesToMutationInput()
                onClickFn = () => save(mutationInput)
            } else if (
                actionConfig?.type === CustomActionConfigTypes.callWithForm
            ) {
                const { mutation, mutationName } = actionConfig
                const { save } = useFormFeedback({
                    mutation,
                    mutationName,
                    query,
                    queryName,
                    mapFormToMutationInput,
                    mapQueryResultToForm
                })
                onClickFn = save
            }
        }
    }
    return { save: onClickFn }
}
