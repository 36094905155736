import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const UNPUBLISH_VIDEO = gql`
  ${MetaFragment('VideoResponseDto')}
  ${MessagesFragment('VideoResponseDto')}
  mutation unpublishVideo($input: UnpublishVideoInputDto!) {
    unpublishVideo(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        id
      }
    }
  }
`
