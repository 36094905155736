import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import dayjs, { Dayjs } from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { controlled } from '@hoc'
import { formatTime } from '@dts/client-utils'
import { useCustomTheme } from '@hooks'

type TimePickerProps = React.InputHTMLAttributes<HTMLInputElement> & {
    label?: string
    width?: string
    fullWidth?: boolean
    name: string
}

export const MuiTimePicker = ({
    name,
    width,
    height,
    label,
    onChange,
    error,
    helperText,
    id,
    ...props
}: TimePickerProps) => {
    const { theme } = useCustomTheme()
    const [date, setDate] = React.useState<Dayjs | null>(null)
    const { setValue, getValues, trigger } = useFormContext()

    useEffect(() => {
        const parsedTime = getValues(name) && dayjs(getValues(name), 'H:m')
        setDate(parsedTime)
    }, [getValues(name)])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
                {...props}
                label={label}
                name={name}
                value={date}
                format='HH:mm'
                onChange={(newValue) => {
                    if (newValue) {
                        const newTime = new Date(newValue)
                        const formattedTime = formatTime(newTime)
                        setDate(formattedTime)
                        setValue(name, formattedTime)
                        onChange?.(formattedTime)
                    } else {
                        setDate(newValue)
                        setValue(name, undefined)
                    }
                }}
                sx={{
                    width,

                    '& .MuiInputBase-root.MuiOutlinedInput-root': {
                        height
                    }
                    // TODO Remove Style
                    // '& .MuiSvgIcon-root': {
                    //     color: theme.palette.contentSecondary // Change to your desired icon color
                    // }
                }}
                slotProps={{
                    textField: {
                        error: error ?? props.errors,
                        id,
                        InputLabelProps: {
                            shrink: true
                        }
                    }
                }}
            />
        </LocalizationProvider>
    )
}

export const ControlledTimePicker = controlled(MuiTimePicker)
