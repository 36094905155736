import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, ClientFields } from '../../fragments'

export const GET_CLIENTS = gql`
  ${MessagesFragment('ClientsResponseDto')}
  ${MetaFragment('ClientsResponseDto')}
  ${ClientFields('ClientDto')}
  query getClients {
    getClients {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...ClientFields
      }
    }
  }
`
