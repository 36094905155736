import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const AddCircleIcon: React.FC<SvgProps> = ({
    color,
    width = 48,
    height = 48
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox={`0 0 48 48`}
            fill='none'
        >
            <path
                d='M46.25 23.5C46.25 35.5122 36.5122 45.25 24.5 45.25C12.4878 45.25 2.75 35.5122 2.75 23.5C2.75 11.4878 12.4878 1.75 24.5 1.75C36.5122 1.75 46.25 11.4878 46.25 23.5Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                stroke={_color}
                clipRule='evenodd'
                d='M24.5 13C23.9201 13 23.45 13.4701 23.45 14.05V22.45H15.05C14.4701 22.45 14 22.9201 14 23.5C14 24.0799 14.4701 24.55 15.05 24.55H23.45V32.95C23.45 33.5299 23.9201 34 24.5 34C25.0799 34 25.55 33.5299 25.55 32.95V24.55H33.95C34.5299 24.55 35 24.0799 35 23.5C35 22.9201 34.5299 22.45 33.95 22.45H25.55V14.05C25.55 13.4701 25.0799 13 24.5 13Z'
                fill={_color}
            />
        </svg>
    )
}

export default AddCircleIcon
