import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const DescriptionIcon: React.FC<SvgProps> = ({
    width = 24,
    height = 24,
    color,
    fill
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary
    const _fill = fill
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 24 24`}
            fill={_fill}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M2.75 11H19.25M2.75 5.5H19.25M2.75 16.5H13.75'
                stroke={_color}
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default DescriptionIcon
