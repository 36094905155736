import { MessagesFragment, MetaFragment } from '../../fragments'

import { gql } from '@apollo/client'

export const DUPLICATE_VERTICAL = gql`
  ${MessagesFragment('VerticalResponseDto')}
  ${MetaFragment('VerticalResponseDto')}
  mutation duplicateVertical($input: DuplicateVerticalArgumentsDto!) {
    duplicateVertical(input: $input) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
      }
    }
  }
`
