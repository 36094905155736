import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import {
    BaseContainer,
    ComponentContainer,
    ParentContainer,
    TypographyContainer,
    DragAndDrop
} from '@components'
import { Typography } from '@mui/material'

export const ListUploadContent: React.FC = ({
    prevFileUploaderRef
}: ListUploadContentProps) => {
    const { t } = useTranslation()
    const { getValues, reset, control } = useFormContext()
    const handleUploadThumbnail = () => {
        document?.getElementById('thumbnail-upload')?.click()
    }
    const { thumbnail } = getValues()

    const fileUploader = useWatch({
        control,
        name: 'fileUploader'
    })

    const csvData = useWatch({
        control,
        name: 'csvData'
    })
    const name = 'fileUploader'

    const {
        fieldState: { error }
    } = useController({ name })
    useEffect(() => {
        if (fileUploader !== prevFileUploaderRef.current && csvData?.length) {
            const { fieldMappingArray, valueMappingArray, ...rest } =
                getValues()
            reset({
                fieldMappingArray: [],
                valueMappingArray: [],
                ...rest
            })
        }
        prevFileUploaderRef.current = fileUploader
    }, [fileUploader])

    return (
        <ParentContainer container>
            <Typography variant='h1'>{getValues('name')}</Typography>
            <BaseContainer item>
                <TypographyContainer item md={2.5}>
                    <Typography variant='h3'>{`${t('general.upload')} ${t(
                        'general.learnerList'
                    )}`}</Typography>
                    <Typography variant='body2Medium'>
                        {t('learnerList.contentCaption')}
                    </Typography>
                </TypographyContainer>
                <ComponentContainer item md={9.5}>
                    <DragAndDrop
                        acceptedFileTypes={[
                            'text/csv',
                            'text/tab-separated-values'
                        ]}
                        acceptedFileExtensions={['.csv', '.tsv']}
                    />
                </ComponentContainer>
            </BaseContainer>
        </ParentContainer>
    )
}
