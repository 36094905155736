import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
    GET_USER,
    GET_USER_BY_ID,
    RESPOND_PORTAL_CLIENT_REQUEST
} from '@dts/graphql'
import {
    useCustomTheme,
    useFormFeedback,
    useGraphQLWrapper,
    usePage
} from '@hooks'
import { capitalize } from 'lodash'
import { Grid } from '@mui/material'
import { ActiveIcon } from '@assets'
import { UserInviteStatus, UserInviteStatusResponse } from '@constants'
import { setUserData } from '@cacheql'
import { AdminView } from './admin-view'
import { UserPermissionEnum } from '@dts/constants'

export const ViewAdminContainer: React.FC = () => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const id = setUserData()?.id
    const navigate = useNavigate()
    const location = useLocation()
    const { id: adminId } = useParams()
    const isRejected = location?.state?.isRejected

    const respondPortalRequestConfig = {
        mutation: RESPOND_PORTAL_CLIENT_REQUEST,
        mutationName: 'respondToPortalRequest',
        onMutationSuccess: () => {
            navigate('/users')
        },
        isCustomMutationInput: true
    }

    const { save: respondPortalRequest, mutationLoading } = useGraphQLWrapper({
        ...respondPortalRequestConfig
    })

    const { methods, queryLoading } = useFormFeedback({
        initialValues: {
            userRoleDetails: [],
            userNameDetails: [],
            userContactDetails: [],
            userMailDetails: [],
            userAddressDetails: []
        },
        query: adminId ? GET_USER_BY_ID : GET_USER,
        queryName: adminId ? 'getUserById' : 'getUser',
        queryVariables: { id: adminId },
        retrieveOnMount: true,
        mapQueryResultToForm: ({
            email,
            firstName,
            lastName,
            phoneNumber,
            mobileNumber,
            address,
            zipCode,
            avatarSrc,
            status,
            roles,
            state,
            city
        }) => ({
            avatarSrc,
            adminDetails: [
                [
                    {
                        label: t('general.status'),
                        value: (
                            <Grid container alignItems={'center'} gap={0.5}>
                                {status?.id === UserInviteStatus.ACTIVE ? (
                                    <ActiveIcon />
                                ) : status?.id === UserInviteStatus.REJECTED ? (
                                    <ActiveIcon
                                        color={theme.palette.errorDangerMedium}
                                    />
                                ) : (
                                    <ActiveIcon color={theme.palette.yellow} />
                                )}
                                {capitalize(status?.title)}
                            </Grid>
                        )
                    },
                    { label: t('general.role'), value: roles?.[0]?.title }
                ],
                [
                    { label: t('general.firstName'), value: firstName },
                    { label: t('general.lastName'), value: lastName }
                ],
                [
                    {
                        label: t('editAdmin.officePhone'),
                        value: phoneNumber
                    },
                    {
                        label: t('editAdmin.mobilePhone'),
                        value: mobileNumber
                    }
                ],
                [
                    {
                        label: t('general.email'),
                        value: email
                    }
                ],
                [
                    {
                        label: t('general.address'),
                        value: address
                    },
                    {
                        label: t('general.state'),
                        value: state?.title
                    },
                    {
                        label: t('general.city'),
                        value: city?.title
                    },
                    {
                        label: t('general.zipCode'),
                        value: zipCode
                    }
                ]
            ]
        })
    })

    const tertiaryButtonConfig = adminId &&
        !isRejected && {
            tertiaryButtonTitle: t('general.reject'),
            tertiaryButtonAction: () => {
                respondPortalRequest({
                    input: {
                        userId: adminId,
                        statusId: UserInviteStatusResponse.REJECT
                    }
                })
            }
        }

    const secondaryButtonConfig = adminId && {
        secondaryButtonTitle: t('general.cancel'),
        secondaryButtonAction: () => navigate('/users')
    }

    const primaryButtonConfig = !isRejected && {
        primaryButtonTitle: adminId ? t('general.accept') : t('general.edit'),
        primaryButtonAction: () => {
            adminId
                ? respondPortalRequest({
                      input: {
                          userId: adminId,
                          statusId: UserInviteStatusResponse.ACCEPT
                      }
                  })
                : navigate(`/users`)
        }
    }

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: AdminView,
            hasHeader: true,
            preventMargin: true,
            showBreadcrumbs: true,
            pageHeaderConfig: {
                headerText: t('pageHeader.admin.adminProfile'),
                ...primaryButtonConfig,
                ...secondaryButtonConfig,
                ...tertiaryButtonConfig,
                permissions: [UserPermissionEnum.UserCreateModify]
            }
        }
    })

    return <>{page}</>
}
