import React, { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import { FileTypeEnum, useS3 } from '@dts/client-utils'
import { UploadThumbnailIcon, VideoThumbnailImg } from '@assets'
import { setUserData } from '@cacheql'
import { CustomLoader, StyledChip } from '@components'
import Papa from 'papaparse'
import { isString, isUndefined } from 'lodash'
import { Thumbnail } from './styles'
import { UploadFileType } from './types'

export const useFileUpload = ({
    name,
    uploadButton,
    displayFile = true,
    inputLabel = 'file-upload',
    typeAccepted,
    chipView = false
}: UploadFileType) => {
    const { t } = useTranslation()
    const { setValue, control, trigger, getValues } = useFormContext()
    const url = useWatch({ control, name })
    const [selected, setSelected] = useState(false)
    const [isUploadingStart, setIsUploadingStart] = useState(false)

    const [source, setSource] = useState('')
    const { uploadFile, key, s3Object, emptyS3Object } = useS3({})
    const [data, setData] = useState([])
    const parseCSVData = (csvData) => {
        Papa.parse(csvData, {
            header: true,
            complete: function (results) {
                setData(results.data)
            },
            transformHeader: (header) => {
                const hasQuotes = header.includes('"')
                if (hasQuotes) {
                    return header.replace(/"/g, '')
                }
                return header
            },
            transform: (value, header) => {
                if (
                    isString(value) &&
                    value.startsWith('"') &&
                    value.endsWith('"')
                ) {
                    return value.slice(1, -1)
                }
                return value
            }
        })
    }

    const removeFile = () => {
        emptyS3Object()
        setSource('')
        setValue('csvData', [])
        setValue(name, null)
    }

    const fileSelect = async (file, fileUrl) => {
        if (fileUrl !== source) {
            setSource('')
            setIsUploadingStart(true)
            const res = await uploadFile(file, {
                userId: setUserData()?.id,
                type:
                    ['text/csv', 'text/tab-separated-values']?.includes(
                        file.type
                    ) ||
                    ['.csv', '.tsv']?.some((extension) =>
                        file.name.endsWith(extension)
                    )
                        ? FileTypeEnum.CLIENT
                        : FileTypeEnum.THUMBNAIL
            })
            if (res?.status === 200) {
                setValue('fileUrl', fileUrl)
                setSource(fileUrl)
                setIsUploadingStart(false)
            }
        }

        // csv/tsv file reading
        const acceptedFileTypes = ['text/csv', 'text/tab-separated-values']
        if (
            acceptedFileTypes.includes(file.type) ||
            file.name.endsWith('.csv') ||
            file.name.endsWith('.tsv')
        ) {
            const reader = new FileReader()

            reader.onload = () => {
                const csvData = reader.result

                parseCSVData(csvData)
            }

            reader.readAsText(file)
        }
    }

    const fileUpload = async (e) => {
        e.preventDefault()
        const file: File = e.target.files[0]
        const fileUrl = URL.createObjectURL(file)
        fileSelect(file, fileUrl)
    }

    // setting csvData to form
    useEffect(() => {
        if (!isUndefined(s3Object)) {
            setValue('csvData', data)
            trigger()
        }
    }, [data])

    useEffect(() => {
        if (key) setValue(name, key)
    }, [key])

    useEffect(() => {
        if (url && !key) {
            setSource(url)
        }
    }, [url])

    useEffect(() => {
        if (s3Object) {
            setValue('fileUploadKey', s3Object?.key)
        }
    }, [s3Object])

    const fileUploadComponent = (
        <>
            <Grid
                container
                gap={2}
                flexDirection={
                    uploadButton
                        ? chipView
                            ? 'column'
                            : 'column-reverse'
                        : 'row'
                }
            >
                <Grid item>
                    {!uploadButton ? (
                        <label htmlFor='file-upload'>
                            <Thumbnail container sx={{ width: '110px' }}>
                                {isUploadingStart ? (
                                    <CustomLoader />
                                ) : (
                                    <>
                                        <Grid item xs={12}>
                                            <UploadThumbnailIcon />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='body2'>
                                                {t('coverImage.upload.label')}
                                            </Typography>
                                        </Grid>
                                    </>
                                )}
                            </Thumbnail>
                        </label>
                    ) : (
                        uploadButton
                    )}

                    <input
                        style={{ display: 'none' }}
                        type='file'
                        accept={typeAccepted}
                        id={inputLabel}
                        onChange={(e) => {
                            fileUpload(e)
                        }}
                    />
                </Grid>
                {url && chipView ? (
                    <Grid item display={'flex'} justifyContent={'center'}>
                        {source && (
                            // displaying csv/tsv chip
                            <StyledChip
                                label={getValues(name)}
                                key={getValues(name)}
                                sx={{ mb: 1 }}
                                onDelete={(e) => {
                                    removeFile()
                                    const uploadInput =
                                        document.getElementById(inputLabel)
                                    if (uploadInput) {
                                        uploadInput.value = ''
                                    }
                                }}
                            />
                        )}
                    </Grid>
                ) : (
                    url && (
                        <Grid>
                            <Thumbnail
                                width={uploadButton ? '100%' : '110px'}
                                selected={!!source}
                                onClick={() => setSelected(!selected)}
                            >
                                <img
                                    src={
                                        source?.length
                                            ? source
                                            : VideoThumbnailImg
                                    }
                                    alt='thumbnail'
                                    height='100%'
                                    width='100%'
                                    style={{
                                        borderRadius: '10%',
                                        objectFit: source?.length
                                            ? 'contain'
                                            : 'fill'
                                    }}
                                />
                            </Thumbnail>
                        </Grid>
                    )
                )}
            </Grid>
        </>
    )
    return { fileUploadComponent, removeFile, fileSelect, isUploadingStart }
}
