import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { DirectionMode } from '@dts/constants'
import { TDirection } from './types'

export const StyledContainer = styled(Box)<TDirection>`
    display: flex;
    justify-content: ${({ direction }) =>
        direction === DirectionMode.LEFT_TO_RIGHT ? 'unset' : 'flex-end'};
    margin-bottom: 10px;
    margin-top: 10px;
`
