import React from 'react'
import { ContentCreatorsPage, EditContentCreatorsPage } from '@pages'
import { ObjectBaseFields } from '@types'
import { getAccessRoles } from '@utils'
import { ArchiveIcon, CheckCircleIcon } from '@assets'
import { ContentCreatorStatus } from '@constants'
import { UserPermissionEnum } from '@dts/constants'

export const getContentCreatorRoutes = (roles: Array<ObjectBaseFields>) => {
    const accessedRoles = getAccessRoles(roles)
    const { isManager, isClientAdmin } = accessedRoles

    return [
        {
            path: '',
            component: ContentCreatorsPage,
            componentProps: { status: ContentCreatorStatus.ACTIVE },
            isHeaderLink: false,
            canUserAccess: isManager || isClientAdmin,
            permissions: [UserPermissionEnum.CreatorView]
        },
        {
            path: 'active',
            component: ContentCreatorsPage,
            componentProps: { status: ContentCreatorStatus.ACTIVE },
            isLanding: 'content-creators',
            isHeaderLink: true,
            canUserAccess: isManager || isClientAdmin,
            icon: CheckCircleIcon,
            label: 'general.active',
            permissions: [UserPermissionEnum.CreatorView]
        },
        {
            path: 'archived',
            component: ContentCreatorsPage,
            componentProps: { status: ContentCreatorStatus.ARCHIVED },
            isHeaderLink: true,
            canUserAccess: isManager || isClientAdmin,
            icon: ArchiveIcon,
            label: 'general.archived',
            permissions: [UserPermissionEnum.CreatorView]
        },
        {
            path: ':id',
            component: EditContentCreatorsPage,
            canUserAccess: isManager || isClientAdmin,
            hidePageHeader: true,
            isHeaderLink: false,
            isSideNav: false
        }
    ]
}
