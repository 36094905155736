import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useLocalStorage } from '@hooks'

export const ProtectedRoute = ({ children }) => {
    const location = useLocation()
    const { isAuthenticated } = useAuth0()
    const [storedUser] = useLocalStorage('user-data')

    // Will be removed after testing
    // return firstLogin === true ? (
    //     <Navigate to='/complete-profile' state={{ from: location }} />
    // ) :

    return isAuthenticated || storedUser ? (
        children
    ) : (
        <Navigate to='/unauthorized' state={{ from: location }} />
    )
}
