import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { ControlledCheckBox, ControlledTextInput } from '@components'
import { Grid, Typography } from '@mui/material'
import {
    BaseContainer,
    ComponentContainer,
    ParentContainer,
    TypographyContainer
} from './styles'

export const CourseDetails: React.FC = () => {
    const { t } = useTranslation()
    const { control } = useFormContext()
    const { fields: topics } = useFieldArray({
        control,
        name: 'topics'
    })

    return (
        <ParentContainer container>
            <Typography variant='h1'>{'Student List Name'}</Typography>
            <BaseContainer item>
                <TypographyContainer item md={2}>
                    <Typography variant='h3'>{t('general.topics')}</Typography>
                    <Typography variant='body2Medium'>
                        {t('trainings.chooseTopicsAndTrainingTime')}
                    </Typography>
                </TypographyContainer>
                <Grid container flexDirection={'column'} md={8} sm={12}>
                    <ComponentContainer item md={6} flexDirection={'column'}>
                        {topics?.map((topic, index) => (
                            <Grid
                                item
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                                key={index}
                            >
                                <Grid item md={1}>
                                    <ControlledCheckBox
                                        name={`topics[${index}].selected`}
                                        id={`topics[${index}].selected`}
                                        bgWidth={'330px'}
                                    />
                                </Grid>
                                <Grid item md={2} ml={2}>
                                    <Typography>{topic?.label}</Typography>
                                </Grid>
                                <Grid item md={9}>
                                    <ControlledTextInput
                                        name={`topics[${index}].timeRequired`}
                                        label={'Time Required'}
                                        id={`topics[${index}].timeRequired`}
                                    />
                                </Grid>
                            </Grid>
                        ))}
                    </ComponentContainer>
                </Grid>
            </BaseContainer>
        </ParentContainer>
    )
}
