import React from 'react'
import { DescriptionIcon, PlayIcon } from '@assets'
import { BrowseByTopics, VideosByTopic } from '@containers'
import { ObjectBaseFields } from '@types'
import { useCustomTheme } from '@hooks'

export const getCreatorProfileRoutes = (roles: Array<ObjectBaseFields>) => {
    const { theme } = useCustomTheme()

    return [
        {
            path: 'all-video',
            component: VideosByTopic,
            componentProps: {
                isCreatorVideos: true
            },
            isLanding: 'creator-profile',
            label: 'general.allVideos',
            isHeaderLink: true,
            canUserAccess: true,
            icon: () => (
                <PlayIcon
                    color={theme.palette.errorDangerMedium}
                    fill={theme.palette.errorDangerMedium}
                />
            )
        },
        {
            path: 'by-topics',
            component: BrowseByTopics,
            componentProps: {
                isCreatorVideos: true
            },
            label: 'browseVideos.byTopic',
            isHeaderLink: true,
            canUserAccess: true,
            icon: () => <DescriptionIcon />
        },
        {
            path: 'browse-videos-by-topic/:id?',
            component: VideosByTopic,
            label: 'sideNavItems.videos',
            isHeaderLink: false,
            isSideNav: false,
            canUserAccess: true
        }
    ]
}
