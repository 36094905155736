import React from 'react'
import { useNavigate, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { GET_VIDEO_BY_ID, REJECT_VIDEO, SAVE_VIDEO } from '@dts/graphql'
import {
    useAuth,
    useFormFeedback,
    useGraphQLWrapper,
    useModalWithGraphQL,
    usePage
} from '@hooks'
import { isEmpty } from 'lodash'
import { RejectVideoSchema, ReviewVideoSchema } from '@dts/client-utils'
import {
    APPROVE_VIDEO,
    ESCALATE_VIDEO,
    PUBLISH_VIDEO,
    REPORT_VIDEO
} from '@dts/graphql/lib'
import { DraftIcon, ReportIcon, TextMessageIcon } from '@assets'
import { getAccessRoles } from '@utils'
import { ReviewVideo } from './review-video'
import { RejectVideo } from './reject-video'
import { CommunicationTab } from './communication-tab'
import { RejectVideoForm } from '@types'
export const mapGenericFormToMutationInput = (form, id) => {
    const {
        title,
        description,
        tags,
        skillId,
        topicIds,
        states,
        urlSrc,
        hyperlinks,
        captionText
    } = form
    return {
        id,
        title,
        description,
        tags,
        hyperlinks,
        topicIds,
        skillId,
        url: urlSrc,
        stateIds: states,
        isDraft: false,
        captionText
    }
}
export const ReviewVideoContainer = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const navigate = useNavigate()
    const {
        userInfo: { roles }
    } = useAuth()

    const accessedRoles = getAccessRoles(roles)
    const isManager = accessedRoles?.isManager

    const { save: publishVideo } = useGraphQLWrapper({
        mutation: isManager ? PUBLISH_VIDEO : APPROVE_VIDEO,
        mutationName: isManager ? 'publishVideo' : 'approveVideo',

        onMutationSuccess: () => {
            navigate(isManager ? '/videos/approved' : '/videos/pending')
        }
    })

    const { save, methods, mutationLoading, queryLoading } = useFormFeedback({
        initialValues: {
            title: null,
            description: null,
            tags: [],
            hyperlinks: [],
            topicIds: [],
            skillId: null,
            states: [],
            captionText: null,
            url: null
        },
        query: GET_VIDEO_BY_ID,
        queryName: 'getVideo',
        retrieveOnMount: true,
        queryVariables: { id },
        mapQueryResultToForm: (response) => {
            const {
                title,
                urlSrc,
                description,
                tags,
                skillId,
                topicIds,
                statesData,
                hyperlinks,
                url,
                captionText,
                captionFileSrc,
                previousVersions
            } = response || {}
            return {
                title,
                description,
                tags: [...(tags ? tags : [])],
                skillId,
                topicIds,
                states: statesData?.map((s) => s?.id),
                url: urlSrc,
                urlSrc: url,
                hyperlinks: !isEmpty(hyperlinks) ? hyperlinks : [],
                captionText,
                captionFileSrc,
                previousVersions
            }
        },
        mutation: SAVE_VIDEO,
        mutationName: 'saveVideo',
        mapFormToMutationInput: (form) => {
            const {
                title,
                description,
                tags,
                skillId,
                topicIds,
                states,
                urlSrc,
                hyperlinks,
                captionText
            } = form

            return {
                id,
                title,
                description,
                tags,
                hyperlinks,
                topicIds,
                skillId,
                url: urlSrc,
                stateIds: states,
                isDraft: false,
                captionText
            }
        },
        onMutationSuccess: () => {
            navigate('/videos/pending')
        },
        yupResolverSchema: ReviewVideoSchema
    })

    const { openDialog: openRejectDialog } = useModalWithGraphQL({
        modalConfig: {
            Component: RejectVideo,
            creationConfig: {
                primaryButtonText: t('general.submit'),
                header: `${t('general.reject')} ${t('general.video')}`
            }
        },
        mutation: REJECT_VIDEO,
        mutationName: 'rejectVideo',
        mapFormToMutationInput: (form: RejectVideoForm) => {
            const { title, details } = form
            return {
                ...mapGenericFormToMutationInput(methods.getValues(), id),
                message: {
                    title,
                    details
                }
            }
        },
        onMutationSuccess: () => {
            navigate('/videos/rejected')
        },
        yupResolverSchema: RejectVideoSchema
    })

    const { openDialog: openReportDialog } = useModalWithGraphQL({
        modalConfig: {
            Component: RejectVideo,
            creationConfig: {
                primaryButtonText: t('general.submit'),
                header: `${t('general.report')} ${t('general.video')}`
            }
        },
        mutation: REPORT_VIDEO,
        mutationName: 'reportVideo',
        mapFormToMutationInput: (form: RejectVideoForm) => {
            const { title, details } = form
            return {
                ...mapGenericFormToMutationInput(methods.getValues(), id),
                message: {
                    title,
                    details
                }
            }
        },
        onMutationSuccess: () => {
            navigate('/videos/reported')
        },
        yupResolverSchema: RejectVideoSchema
    })

    const { openDialog: openEscalateDialog } = useModalWithGraphQL({
        modalConfig: {
            Component: RejectVideo,
            creationConfig: {
                primaryButtonText: t('general.submit'),
                header: t('general.attachNotes')
            }
        },
        mutation: ESCALATE_VIDEO,
        mutationName: 'escalateVideo',
        mapFormToMutationInput: (form: RejectVideoForm) => {
            const { title, details } = form
            return {
                ...mapGenericFormToMutationInput(methods.getValues(), id),
                message: {
                    title,
                    details
                }
            }
        },
        onMutationSuccess: () => {
            navigate('/videos/pending')
        },
        yupResolverSchema: RejectVideoSchema
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: ReviewVideo,
            hasColumnDirection: true,
            hasHeader: true,
            preventMargin: true,
            preventPadding: true,
            showBreadcrumbs: true,
            breadcrumbSearchParamAccessor: 'tab',
            pageHeaderConfig: {
                headerText: `${t('general.review')} ${t('general.video')}`,
                primaryButtonTitle: isManager
                    ? t('general.approve')
                    : t('general.submit'),
                primaryButtonAction: methods.handleSubmit(() =>
                    publishVideo(
                        mapGenericFormToMutationInput(methods.getValues(), id)
                    )
                ),
                secondaryButtonTitle: t('general.reject'),
                secondaryButtonAction: openRejectDialog,
                kebabMenuConfig: [
                    {
                        label: t('general.saveDraft'),
                        icon: DraftIcon,
                        onClick: save
                    },
                    !isManager && {
                        label: `${t('general.report')} ${t('general.video')}`,
                        icon: ReportIcon,
                        onClick: openReportDialog
                    }
                ].filter(Boolean)
            },
            overlayConfig: {
                OverlayComponent: CommunicationTab,
                hasFilterOverlay: true,
                overlayIcon: <TextMessageIcon />
            }
        }
    })

    return <>{page}</>
}
