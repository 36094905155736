import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import { CircularProgressWithLabel, Panel, StartIconButton } from '@components'
import { DiceIcon, ReadingIcon } from '@assets'
import { useCustomTheme } from '@hooks'
import { PanelBasicFields } from '@components/Panel/types'
import { StyledSubheaderGrid } from '@components/FilterLayout/styles'
import { ButtonVariants, ShuffleForMe } from '@constants'
import { floor } from 'lodash'
import { addEntryToCache } from '@cacheql'
import { TopicCard } from './topic-card'
import { CoursesContainerProps } from './types'

export const renderTrainingAccordionSummary = (
    panel: PanelBasicFields,
    theme: object,
    t
) => (
    <Grid container gap={1} alignItems={'center'}>
        <Grid item display={'flex'}>
            <CircularProgressWithLabel value={panel?.progress} />
        </Grid>
        <Grid item>
            <Typography variant='h4' position={'relative'} fontSize={'16px'}>
                {panel?.title}
            </Typography>
        </Grid>
    </Grid>
)

export const CoursesContainer: FC<CoursesContainerProps> = (props) => {
    const { selectedTraining } = props || {}
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const navigate = useNavigate()

    const handleCompletedTopics = (
        courseTopics: Array<{
            progress: number
        }>
    ) => {
        const numCompletedTopics = courseTopics.reduce(
            (count: number, topic) => {
                if (topic.progress === 100) {
                    return count + 1
                } else {
                    return count
                }
            },
            0
        )
        return numCompletedTopics
    }

    const getPanels = (panels: Array<any>) =>
        panels?.map((panel, index: number) => ({
            id: panel?.id,

            icon: (
                <ReadingIcon
                    width={20}
                    height={20}
                    color={theme.palette.contentSecondary}
                />
            ),
            renderElementAtTitle: () =>
                renderTrainingAccordionSummary(panel, theme, t),
            panelId: index + 1,
            body: (
                <Grid container>
                    <Grid
                        item
                        container
                        justifyContent={'space-between'}
                        mx={2}
                        mt={1}
                        alignItems={'center'}
                    >
                        <Typography
                            variant='body2'
                            color={theme.palette.contentSecondary}
                        >
                            {[
                                `${handleCompletedTopics(
                                    panel?.courseTopics
                                )}/${panel?.courseTopics?.length}`,
                                t('general.topics').toLowerCase(),
                                t('general.completed').toLowerCase()
                            ].join(' ')}
                        </Typography>
                        <StartIconButton
                            label={t('shuffleForMe.title')}
                            Icon={DiceIcon}
                            variant={ButtonVariants.Outlined}
                            onClick={() => {
                                addEntryToCache(
                                    ShuffleForMe,
                                    t('shuffleForMe.title')
                                )
                                navigate(
                                    `${ShuffleForMe}?trainingId=${
                                        selectedTraining?.id
                                    }&courseId=${panel?.id}&progress=${floor(
                                        panel?.progress
                                    )}`
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TopicCard
                            trainingId={selectedTraining?.id}
                            {...panel}
                        />
                    </Grid>
                </Grid>
            )
        }))

    return (
        <Grid>
            <StyledSubheaderGrid container flexDirection={'column'}>
                <Typography variant='h1'>{selectedTraining?.title}</Typography>
                {selectedTraining?.description ? (
                    <Typography mt={1} variant='body2'>
                        {selectedTraining?.description}
                    </Typography>
                ) : (
                    <></>
                )}
            </StyledSubheaderGrid>
            {selectedTraining?.courses?.length ? (
                <Panel
                    panels={getPanels(selectedTraining?.courses)}
                    // To-do: Will be removed later on
                    // showSubtitleOnCollapsed={true}
                    // showBatchActions={false}
                    backgroundColor={theme.palette.backgroundPrimary}
                    onChange={(openedAccordionId: string) => false}
                    isExpanded={selectedTraining?.courses?.[0]?.id}
                    preventBorderRadius
                />
            ) : (
                <Grid container justifyContent={'center'} mt={1}>
                    <Typography variant='h3'>
                        {t('general.notFound', {
                            label: t('general.courses').toLowerCase()
                        })}
                    </Typography>
                </Grid>
            )}
        </Grid>
    )
}
