import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const LightIcon: React.FC<SvgProps> = ({
    height = '36',
    width = '32',
    color
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.fireOrange

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 32 36`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M21.6142 22.417C20.1591 24.0707 19.3207 26.1409 19.2304 28.2846H12.7721C12.6945 26.145 11.8608 24.0805 10.405 22.4385C9.17954 21.0568 8.51184 19.277 8.52457 17.4272C8.53846 15.4102 9.33519 13.528 10.7684 12.1278C12.2022 10.7259 14.1052 9.97193 16.1233 10.0043C20.1776 10.0697 23.4762 13.4233 23.4762 17.4804C23.4762 19.2995 22.8148 21.0527 21.6142 22.417ZM16.1401 8.95128C13.839 8.91483 11.6693 9.77463 10.0324 11.3745C8.3967 12.9731 7.48772 15.1197 7.47152 17.4203C7.45705 19.5304 8.21907 21.5607 9.61697 23.1374C10.9761 24.6701 11.7248 26.6148 11.7248 28.6133V28.6723C11.7248 29.0391 12.0239 29.3383 12.3902 29.3383H19.61C19.9762 29.3383 20.2754 29.0391 20.2754 28.6723C20.2754 26.6483 21.0316 24.6736 22.4052 23.1125C23.7747 21.5561 24.5292 19.5559 24.5292 17.4804C24.5292 15.2262 23.6579 13.1004 22.0765 11.4948C20.4975 9.89093 18.3885 8.98774 16.1401 8.95128Z'
                fill={_color}
            />
            <path
                d='M19.5521 31.1302H12.448C12.1576 31.1302 11.9215 31.3657 11.9215 31.6562C11.9215 31.9472 12.1576 32.1827 12.448 32.1827H19.5521C19.8425 32.1827 20.0786 31.9472 20.0786 31.6562C20.0786 31.3657 19.8425 31.1302 19.5521 31.1302Z'
                fill={_color}
            />
            <path
                d='M17.7096 33.9752H14.2905C14.1115 33.9752 13.9667 34.2113 13.9667 34.5018C13.9667 34.7928 14.1115 35.0289 14.2905 35.0289H17.7096C17.8886 35.0289 18.0335 34.7928 18.0335 34.5018C18.0335 34.2113 17.8886 33.9752 17.7096 33.9752Z'
                fill={_color}
            />
            <path
                d='M16.042 4.93186C16.0472 4.93186 16.0524 4.93128 16.0576 4.93128C16.3486 4.9226 16.5772 4.68074 16.5685 4.38971L16.4846 1.4834C16.4759 1.19236 16.2335 0.963239 15.943 0.971918C15.652 0.980597 15.4235 1.22303 15.4321 1.51349L15.516 4.42038C15.5247 4.70563 15.7585 4.93186 16.042 4.93186Z'
                fill={_color}
            />
            <path
                d='M8.57562 6.78786C8.67514 6.94929 8.84756 7.03839 9.02403 7.03839C9.11892 7.03839 9.21381 7.01294 9.30002 6.95971C9.54766 6.80695 9.62462 6.48294 9.47187 6.2353L7.9461 3.76005C7.79335 3.51241 7.46876 3.43603 7.22169 3.5882C6.97405 3.74095 6.8971 4.06555 7.04985 4.31261L8.57562 6.78786Z'
                fill={_color}
            />
            <path
                d='M5.27593 11.3171L2.71736 9.93661C2.46104 9.7989 2.14165 9.89437 2.00395 10.1501C1.86566 10.4064 1.96113 10.7258 2.21745 10.8635L4.77602 12.2441C4.85587 12.2869 4.94092 12.3071 5.02539 12.3071C5.21286 12.3071 5.39396 12.207 5.48943 12.0306C5.62714 11.7742 5.53167 11.4549 5.27593 11.3171Z'
                fill={_color}
            />
            <path
                d='M3.44165 16.7702L0.535923 16.8541C0.244887 16.8628 0.0163405 17.1052 0.0250195 17.3957C0.0331198 17.6815 0.266874 17.9072 0.550967 17.9072C0.556174 17.9072 0.561382 17.9072 0.56601 17.9072L3.47232 17.8227C3.76335 17.814 3.9919 17.5716 3.98322 17.2811C3.97512 16.9906 3.73211 16.7604 3.44165 16.7702Z'
                fill={_color}
            />
            <path
                d='M22.7001 6.95996C22.7863 7.01319 22.8812 7.03865 22.9761 7.03865C23.1526 7.03865 23.325 6.94955 23.4245 6.78812L24.9503 4.31287C25.103 4.06581 25.0261 3.74121 24.7784 3.58846C24.5314 3.43571 24.2068 3.51266 24.054 3.7603L22.5283 6.23555C22.3755 6.4832 22.4525 6.80721 22.7001 6.95996Z'
                fill={_color}
            />
            <path
                d='M29.9962 10.1501C29.8585 9.89437 29.5391 9.7989 29.2834 9.93661L26.7242 11.3171C26.4685 11.4549 26.373 11.7742 26.5107 12.0306C26.6062 12.207 26.7873 12.3071 26.9748 12.3071C27.0592 12.3071 27.1449 12.2869 27.2241 12.2441L29.7827 10.8635C30.039 10.7258 30.1345 10.4064 29.9962 10.1501Z'
                fill={_color}
            />
            <path
                d='M31.4641 16.8552L28.5578 16.7713C28.2667 16.7621 28.0249 16.9918 28.0168 17.2822C28.0081 17.5727 28.2372 17.8151 28.5277 17.8238L31.434 17.9083C31.4392 17.9083 31.4438 17.9083 31.4496 17.9083C31.7331 17.9083 31.9669 17.6826 31.975 17.3968C31.9837 17.1063 31.7551 16.8639 31.4641 16.8552Z'
                fill={_color}
            />
            <path
                d='M16.0852 11.0813C14.3343 11.0535 12.6842 11.7073 11.439 12.9241C10.1944 14.1404 9.50244 15.7737 9.49087 17.5234C9.48855 17.8139 9.72289 18.0517 10.0133 18.0534H10.0174C10.3061 18.0534 10.5416 17.8203 10.5433 17.5304C10.5538 16.0642 11.1329 14.6958 12.1744 13.6775C13.2176 12.6586 14.6005 12.1106 16.0684 12.1338C16.0713 12.1338 16.0736 12.1338 16.0771 12.1338C16.3635 12.1338 16.5984 11.9041 16.603 11.6159C16.6077 11.3255 16.3756 11.0853 16.0852 11.0813Z'
                fill={_color}
            />
        </svg>
    )
}

export default LightIcon
