import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { formatDate } from '@dts/client-utils'
import { Grid } from '@mui/material'
import { StyledDirectoryLink } from '@containers/styles'
import { useCustomTheme, useDirectory, useGraphQLWrapper } from '@hooks'
import {
    GET_ARTICLES_DIRECTORY,
    DELETE_ARTICLE,
    DUPLICATE_ARTICLE
} from '@dts/graphql'
import {
    ActiveIcon,
    AddIcon,
    ArchiveIcon,
    DeleteIcon,
    DuplicateIcon,
    EditIcon
} from '@assets'
import parse from 'html-react-parser'
import { TextWithTooltip } from '@components'
import { UserPermissionEnum } from '@dts/constants'

export const BrowseArticles = () => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const navigate = useNavigate()

    const deleteArticleConfig = {
        mutation: DELETE_ARTICLE,
        mutationName: 'deleteArticle',
        onMutationSuccess: () => {
            retrieve?.({
                variables: {
                    options: queryOptions
                }
            })
        },
        isCustomMutationInput: true
    }

    const duplicateArticleConfig = {
        mutation: DUPLICATE_ARTICLE,
        mutationName: 'duplicateArticle',
        onMutationSuccess: () => {
            retrieve?.({
                variables: {
                    options: queryOptions
                }
            })
        },
        isCustomMutationInput: true
    }

    const { save: duplicateArticle } = useGraphQLWrapper({
        ...duplicateArticleConfig
    })

    const { save: deleteArticle } = useGraphQLWrapper({
        ...deleteArticleConfig
    })

    const { directory, retrieve, queryOptions } = useDirectory({
        directoryConfig: {
            id: 'articlesDirectory',
            query: GET_ARTICLES_DIRECTORY,
            queryName: 'getAdminArticles',
            queryVariables: {
                args: {}
            },
            config: {
                customActions: [
                    {
                        icon: (
                            <EditIcon color={theme.palette.contentSecondary} />
                        ),
                        label: `${t('general.edit')}`,
                        ariaLabel: `${t('general.edit')}`,
                        showInMenu: true,
                        action: (params) =>
                            navigate(`/knowledge-center/${params?.row?.id}`),
                        permissions: [UserPermissionEnum.ArticleCreateModify]
                    },
                    {
                        icon: (
                            <DuplicateIcon
                                color={theme.palette.contentSecondary}
                            />
                        ),
                        label: `${t('createArticle.duplicate')}`,
                        ariaLabel: `${t('createArticle.duplicate')}`,
                        showInMenu: true,
                        action: (params) => {
                            duplicateArticle({
                                input: {
                                    id: params?.row?.id,
                                    title: params?.row?.title
                                }
                            })
                        },
                        permissions: [UserPermissionEnum.ArticleCreateModify]
                    },
                    // TO-DO: Functionality will be added later on
                    // {
                    //     icon: (
                    //         <ArchiveIcon
                    //             color={theme.palette.contentSecondary}
                    //         />
                    //     ),
                    //     label: `${t('createArticle.archive')}`,
                    //     ariaLabel: `${t('createArticle.archive')}`,
                    //     showInMenu: true,
                    //     action: () => {}
                    // },
                    {
                        icon: (
                            <DeleteIcon
                                color={theme.palette.contentSecondary}
                            />
                        ),
                        label: `${t('general.delete')}`,
                        ariaLabel: `${t('general.delete')}`,
                        showInMenu: true,
                        action: (params) => {
                            deleteArticle({
                                id: params?.row?.id
                            })
                        },
                        permissions: [UserPermissionEnum.ArticleDelete]
                    }
                ],
                columns: [
                    {
                        field: 'title',
                        headerName: t('general.title'),
                        minWidth: 150,
                        renderCell: (params) => (
                            <Grid
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                {params.row?.isDraft ? (
                                    <>
                                        <ActiveIcon
                                            color={theme.palette.inactiveBrown}
                                        />
                                        <span
                                            style={{
                                                color: `${theme.palette.inactiveBrown}`
                                            }}
                                        >{` [${t(
                                            'browseArticle.draft'
                                        )}] - `}</span>
                                        <TextWithTooltip
                                            renderer={parse(params.row?.title)}
                                            toolTip={params.row.title}
                                            tabIndex={params.tabIndex}
                                            onClick={() =>
                                                navigate(
                                                    `/knowledge-center/${params?.row?.id}`
                                                )
                                            }
                                            isDirectoryLink
                                        />
                                    </>
                                ) : (
                                    <>
                                        <ActiveIcon />
                                        <TextWithTooltip
                                            renderer={parse(params.row?.title)}
                                            tabIndex={params.tabIndex}
                                            onClick={() =>
                                                navigate(
                                                    `/knowledge-center/${params?.row?.id}`
                                                )
                                            }
                                            isDirectoryLink
                                        />
                                    </>
                                )}
                            </Grid>
                        )
                    },
                    {
                        field: 'topic',
                        headerName: t('general.topic'),
                        minWidth: 80,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={params?.row?.topicData?.title}
                                tabIndex={params.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'views',
                        headerName: t('general.views'),
                        minWidth: 80,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={params?.row?.views}
                                tabIndex={params.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'datePublished',
                        headerName: t('general.datePublished'),
                        minWidth: 100,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={formatDate(params.row?.createdAt)}
                                tabIndex={params.tabIndex}
                            />
                        )
                    }
                ]
            },
            toolbarConfig: {
                header: t('general.articles'),
                addNewButtonConfig: {
                    label: t('general.createNew'),
                    ariaLabel: t('general.article'),
                    action: () => navigate(`/knowledge-center/new`),
                    icon: AddIcon,
                    permissions: [UserPermissionEnum.ArticleCreateModify]
                }
            },
            enableSearch: true,
            rowHeight: 60
        }
    })

    return directory
}
