export const daysData = [
    {
        id: 'MON',
        title: 'Monday',
        available: true,
        displayOrder: 1
    },
    {
        id: 'TUE',
        title: 'Tuesday',
        available: true,
        displayOrder: 2
    },
    {
        id: 'WED',
        title: 'Wednesday',
        available: true,
        displayOrder: 3
    },
    {
        id: 'THR',
        title: 'Thursday',
        available: true,
        displayOrder: 4
    },
    {
        id: 'FRI',
        title: 'Friday',
        available: true,
        displayOrder: 5
    },
    {
        id: 'SAT',
        title: 'Saturday',
        available: true,
        displayOrder: 6
    },
    {
        id: 'SUN',
        title: 'Sunday',
        available: true,
        displayOrder: 7
    }
]

export const monthWeeksData = [
    {
        id: 'FIR',
        title: 'First',
        available: true,
        displayOrder: 1
    },
    {
        id: 'SEC',
        title: 'Second',
        available: true,
        displayOrder: 2
    },
    {
        id: 'THI',
        title: 'Third',
        available: true,
        displayOrder: 3
    },
    {
        id: 'FOR',
        title: 'Forth',
        available: true,
        displayOrder: 4
    },
    {
        id: 'LAS',
        title: 'Last',
        available: true,
        displayOrder: 5
    }
]

export const dailyReminderOptionsData = [
    {
        id: 'EVD',
        title: 'Everyday',
        available: true,
        displayOrder: 1
    },
    {
        id: 'EWD',
        title: 'Every Weekday',
        available: true,
        displayOrder: 2
    }
]

export const monthlyReminderOptionsData = [
    {
        id: 'DTS',
        title: 'Date Specific',
        available: true,
        displayOrder: 1
    },
    {
        id: 'DYS',
        title: 'Day Specific',
        available: true,
        displayOrder: 2
    }
]

export const notificationFrequenciesData = [
    {
        id: 'NON',
        title: 'None',
        available: true,
        displayOrder: 1
    },
    {
        id: 'DAI',
        title: 'Daily',
        available: true,
        displayOrder: 2
    },
    {
        id: 'WEE',
        title: 'Weekly',
        available: true,
        displayOrder: 3
    },
    {
        id: 'MON',
        title: 'Monthly',
        available: true,
        displayOrder: 4
    }
]
