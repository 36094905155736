import { gql } from '@apollo/client'
import { MessagesFragment, CourseFields } from '../../fragments'

export const SAVE_COURSE = gql`
  ${MessagesFragment('CourseResponseDto')}
  ${CourseFields('CourseDto')}
  mutation saveCourse($input: CourseInputDto!) {
    saveCourse(input: $input) {
      ...MessagesFragment
      data {
        ...CourseFields
      }
    }
  }
`
