import { MessagesFragment, MetaFragment } from '../../fragments'

import { gql } from '@apollo/client'

export const DUPLICATE_TRAINING = gql`
  ${MessagesFragment('TrainingResponseDto')}
  ${MetaFragment('TrainingResponseDto')}
  mutation duplicateTraining($input: DuplicateTrainingArgumentsDto!) {
    duplicateTraining(input: $input) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
      }
    }
  }
`
