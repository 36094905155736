import { gql } from '@apollo/client'

export const CREATED_REPORTED_ISSUE = gql`
  mutation createReportedIssue($input: ReportedIssueInputDto!) {
    createReportedIssue(input: $input) {
      data {
        id
        description
        screenshots
        screenshotsSrc
        creatorId
        creator {
          id
          firstName
          avatar
          avatarSrc
          status
          lastName
        }
      }
    }
  }
`
