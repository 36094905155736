enum StaffTypeEnum {
  Internal = 'INT',
  External = 'EXT',
}

enum StaffTypeGraphqlEnum {
  'INT' = 'INT',
  'EXT' = 'EXT',
}

export { StaffTypeEnum, StaffTypeGraphqlEnum }
