import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
    useCustomTheme,
    useGraphQLWrapper,
    useInfiniteScroll,
    usePageHeader
} from '@hooks'
import { isMediumScreen } from '@utils'
import { GET_SAVED_VIDEOS, SAVE_VIDEO_INTERACTION } from '@dts/graphql'
import { VideoCardGrid } from '@components'
import { SaveIcon } from '@assets'
import { Grid } from '@mui/material'
import { BackButtonType } from '@constants'

export const SavedVideos = () => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const navigate = useNavigate()
    const [savedStatus, setSavedStatus] = useState<{
        [k: number]: boolean
    }>({})

    const { pageHeader } = usePageHeader({
        headerText: t('video.savedVideos'),
        backButtonConfig: {
            showBackButton: isMediumScreen?.(),
            backButtonType: BackButtonType.Hierarchical
        }
    })

    const { save: saveVideoInteraction } = useGraphQLWrapper({
        mutation: SAVE_VIDEO_INTERACTION,
        mutationName: 'saveVideoInteraction',
        onMutationSuccess: (response) =>
            setSavedStatus((prev) => ({
                ...prev,
                [response?.data?.videoId]: response?.data?.saved
            }))
    })

    const loadComponent = (mergedData: Array<object>) => (
        <Grid p={2}>
            <VideoCardGrid
                cardData={mergedData?.map((data) => ({
                    ...data,
                    onClick: () => navigate(`/browse-videos/watch/${data?.id}`)
                }))}
                cardAction={(params) => {
                    const isSaved = savedStatus?.[params?.id]
                    return {
                        icon: () => (
                            <SaveIcon
                                color={theme.palette.contentSecondary}
                                fill={
                                    isSaved
                                        ? theme.palette.electricBlue
                                        : 'none'
                                }
                            />
                        ),
                        ariaLabel: isSaved
                            ? [t('general.unSave'), t('general.video')].join(
                                  ' '
                              )
                            : [t('general.save'), t('general.video')].join(' '),
                        onActionClick: () => {
                            if (params?.id) {
                                saveVideoInteraction({
                                    videoId: params?.id,
                                    saved: !isSaved
                                })
                            }
                        }
                    }
                }}
            />
        </Grid>
    )

    const { wrappedComponent, data } = useInfiniteScroll({
        query: GET_SAVED_VIDEOS,
        queryName: 'getSavedVideos',
        loadComponent
    })

    useEffect(() => {
        if (data) {
            const savedVideos = {}

            data?.forEach((data) => {
                savedVideos[data.id] = true
            })

            setSavedStatus(savedVideos)
        }
    }, [data])

    return (
        <>
            {pageHeader}
            {wrappedComponent}
        </>
    )
}
