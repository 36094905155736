import { styled, Typography, Box } from '@mui/material'
import { ErrorProps } from './types'

export const StyledErrorDiv = styled(Box)<ErrorProps>`
    position: ${({ errorPosition }) => errorPosition && 'absolute'};
    bottom: ${({ errorPosition }) => errorPosition && '-5px'};
`

export const StyledErrorMessage = styled(Typography)(
    ({ theme, errorPosition, color }) => ({
        fontSize: errorPosition ? '10px !important' : '12px !important',
        color: `${color ?? theme.palette.errorDangerMedium} !important`
    })
)
