import { FC } from 'react'
import { DocumentNode } from '@apollo/client'

export type NextStep = {
    hasConfirmationDialog: boolean
    onConfirm: VoidFunction
    onError?: VoidFunction
    onClose?: VoidFunction
}

export type Step = {
    schema?: object
    label?: string
    initialValues?: object
    component?: FC
    nextStepConfig?: NextStep
    preventDisable?: boolean
}

export type StepperProps = {
    steps: Array<Step>
    headerText?: string
    stepNumber?: number
    query?: DocumentNode
    queryName?: string
    initialValues: object
    queryVariables?: object
    mutation?: DocumentNode
    mutationName?: string
    retrieveOnMount?: boolean
    showNextSteps?: boolean
    showBreadcrumbs?: boolean
    editMode?: boolean
    mapFormToInput?: (formValues?: object) => object
    mapInputToForm?: (response?: object) => object
    mapPreservedFormInputs?: (response?: object) => object
    onMutationSuccess?: (response?: object) => void
    tertiaryButtonConfig?: {
        title?: string
        disabled?: boolean
        onClick?: VoidFunction
        tertiaryButtonMapper?: (formValues?: object) => object
        schema?: object
    }
}
