import React from 'react'
import { useTranslation } from 'react-i18next'
import { mapDropdownOptions } from '@dts/client-utils'
import { Grid } from '@mui/material'
import {
    ControlledTextInput,
    ControlledSelect,
    MaskInput,
    ControlledDatePicker,
    ControlledCheckBox
} from '@components'
import { GET_ROLES, GET_STATES } from '@dts/graphql'
import { useGraphQLWrapper } from '@hooks'
import { phoneNumberMask } from '@constants'
import { StyledFlexContainer } from './styles'
import { AdminProfileFormProps } from './types'

export const AdminDetailsForm: React.FC<AdminProfileFormProps> = ({
    isCurrentUser,
    clientId,
    isClientAdmin
}) => {
    const { t } = useTranslation()

    const { currentData: rolesData } = useGraphQLWrapper({
        query: GET_ROLES,
        queryName: 'getRoles',
        queryVariables: { clientId }
    })

    const { currentData: stateData } = useGraphQLWrapper({
        query: GET_STATES,
        queryName: 'getStatesDropDown'
    })

    return (
        <>
            {!isCurrentUser && (
                <Grid item display={'flex'} alignItems={'center'} gap={3}>
                    <Grid item xs={12} md={5.9}>
                        <ControlledSelect
                            name='roleId'
                            id='role'
                            label={t('general.role')}
                            ariaLabel={t('general.role')}
                            options={mapDropdownOptions(rolesData)}
                        />
                    </Grid>
                </Grid>
            )}
            <StyledFlexContainer>
                <Grid item xs={12} md={5.9}>
                    <ControlledTextInput
                        id='firstName'
                        name='firstName'
                        label={t('general.firstName')}
                        disabled={isCurrentUser}
                    />
                </Grid>
                <Grid item xs={12} md={5.9}>
                    <ControlledTextInput
                        id='lastName'
                        name='lastName'
                        label={t('general.lastName')}
                        disabled={isCurrentUser}
                    />
                </Grid>
            </StyledFlexContainer>
            <StyledFlexContainer>
                <Grid item xs={12} md={5.9}>
                    <MaskInput
                        name='phoneNumber'
                        id='office-phone'
                        label={t('editAdmin.officePhone')}
                        mask={phoneNumberMask}
                        placeholder={'(000) 000-0000'}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={5.9}>
                    <MaskInput
                        name='mobileNumber'
                        id='mobile-phone'
                        label={t('editAdmin.mobilePhone')}
                        mask={phoneNumberMask}
                        placeholder={'(000) 000-0000'}
                        fullWidth
                    />
                </Grid>
            </StyledFlexContainer>
            <StyledFlexContainer>
                <Grid item xs={12} md={5.9}>
                    <ControlledTextInput
                        id='email'
                        type='email'
                        name='email'
                        label={t('general.email')}
                        disabled={isCurrentUser}
                    />
                </Grid>
                <Grid item xs={12} md={5.9}>
                    <ControlledDatePicker
                        id='dateOfBirth'
                        label={t('completeProfile.birthday')}
                        name='dateOfBirth'
                        width={'100%'}
                    />
                </Grid>
            </StyledFlexContainer>
            <StyledFlexContainer>
                <Grid item xs={12} md={5.9}>
                    <ControlledSelect
                        name='stateId'
                        id='state'
                        label={t('general.state')}
                        ariaLabel={t('general.state')}
                        options={mapDropdownOptions(stateData)}
                    />
                </Grid>

                <Grid item xs={12} md={5.9}>
                    <MaskInput
                        name='zipCode'
                        id='zip-code'
                        label={t('general.zipCode')}
                        mask='99999'
                        placeholder={'-----'}
                        fullWidth
                    />
                </Grid>
            </StyledFlexContainer>
            {!isClientAdmin && !isCurrentUser && (
                <StyledFlexContainer>
                    <Grid item xs={12} sm={5.5}>
                        <ControlledCheckBox
                            id='adminPortalOnlyAccess'
                            name='adminPortalOnlyAccess'
                            label={t('editAdmin.restrictClientPortal')}
                        />
                    </Grid>
                </StyledFlexContainer>
            )}
        </>
    )
}
