import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const StatusGrid = styled(Grid, {
    shouldForwardProp: (prop) => prop !== 'bgColor'
})<{ bgColor?: string; theme }>(({ bgColor, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    background: bgColor || theme.palette.completedGreen,
    padding: '4px 8px'
}))

export const StyledGrid = styled(Grid)`
    flex-wrap: wrap;
`

export const StyledCardGrid = styled(Grid, {
    shouldForwardProp: (prop) =>
        prop !== 'background' && prop !== 'borderBottom'
})(({ theme, background, borderColor, isDisabled }) => ({
    background,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '16px',
    borderRadius: '16px',
    cursor: 'pointer',
    minHeight: '90px',
    border: borderColor ? `1px solid ${borderColor}` : undefined,
    ...(isDisabled && {
        cursor: 'auto',
        backgroundColor: theme?.palette?.backgroundDisabled,
        border: 'none'
    })
}))
