import React from 'react'
import { useFormContext, useFormState, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, Grid, FormGroup, Typography } from '@mui/material'

import {
    MultiFieldRow,
    ControlledTimePicker,
    ControlledDatePicker,
    ControlledCheckBox,
    ControlledSelect,
    ControlledRadioGroup,
    TextInput,
    ParentContainer,
    BaseContainer,
    TypographyContainer,
    ComponentContainer
} from '@components'
import { GET_DISPLAY_MODE, GET_LANGUAGES } from '@dts/graphql'
import { useGraphQLWrapper } from '@hooks'
import { mapDropdownOptions, weekdays } from '@dts/client-utils'
import { capitalize } from 'lodash'
import { DateFormat } from '@constants'

export const CampusPreferenceForm: React.FC = () => {
    const { control, setValue, trigger, getValues } = useFormContext()

    const { data: languages, queryLoading } = useGraphQLWrapper({
        query: GET_LANGUAGES,
        queryName: 'getLanguages'
    })

    const { data: displayModes } = useGraphQLWrapper({
        query: GET_DISPLAY_MODE,
        queryName: 'getDisplayModes'
    })

    const { errors } = useFormState()
    const { t } = useTranslation()

    const businessDays = useWatch({
        control,
        name: 'businessDays'
    })

    const multiFieldRowConfig = {
        name: 'universityClosure',
        config: [
            {
                name: 'title',
                component: TextInput,
                componentProps: {
                    label: t('campus.universityClosures.closureTitle'),
                    placeholder: t('campus.universityClosures.closureTitle'),
                    width: '100%'
                },
                componentId: 'title',
                md: 3
            },
            {
                name: 'date',
                component: ControlledDatePicker,
                componentProps: {
                    isDisableFuture: false,
                    label: t('general.date'),
                    placeholder: t('campus.universityClosures.enterDate'),
                    width: '100%'
                },
                componentId: 'date',
                md: 3
            }
        ],
        addNewRowOptions: { date: '', title: '' }
    }
    const firstFourDays = weekdays.slice(0, 4)
    const remainingDays = weekdays.slice(4)

    const DayColumn = ({ days }) => (
        <Grid item xs={6} sm={4} md={3}>
            {days.map((weekDay, index: number) => (
                <Grid direction={'column'} ml={1.5} md={6} key={index}>
                    <FormGroup>
                        <FormControlLabel
                            id={weekDay}
                            key={weekDay}
                            control={
                                <ControlledCheckBox
                                    id={`${weekdays}-${index}`}
                                    checked={
                                        businessDays?.[weekDay.toLowerCase()]
                                    }
                                    checkBoxHandler={(e) => {
                                        setValue(
                                            `businessDays[${weekDay.toLowerCase()}]`,
                                            e.target.checked
                                        )
                                        trigger('businessDays')
                                    }}
                                    name={`businessDays[${weekDay.toLowerCase()}]`}
                                />
                            }
                            label={weekDay}
                        />
                    </FormGroup>
                </Grid>
            ))}
        </Grid>
    )

    return (
        <>
            <ParentContainer container>
                <BaseContainer item>
                    <TypographyContainer item md={2}>
                        <Typography variant='h3'>
                            {t('general.videos')}
                        </Typography>
                        <Typography variant='body2Medium'>
                            {t('campus.videos.description')}
                        </Typography>
                    </TypographyContainer>
                    <ComponentContainer item md={10}>
                        <ControlledCheckBox
                            id='autoPlay'
                            name='autoPlay'
                            label={t('general.autoPlayVideos')}
                        />
                    </ComponentContainer>
                </BaseContainer>
                <BaseContainer item>
                    <TypographyContainer item md={2}>
                        <Typography variant='h3'>
                            {capitalize(t('general.caption'))}
                        </Typography>
                        <Typography variant='body2Medium'>
                            {t('campus.caption.description')}
                        </Typography>
                    </TypographyContainer>
                    <ComponentContainer item md={10}>
                        <Grid md={6}>
                            <ControlledSelect
                                name='captionLanguage'
                                id='captionLanguageId'
                                label={t('setting.languages')}
                                options={mapDropdownOptions(languages)}
                            />
                        </Grid>
                    </ComponentContainer>
                </BaseContainer>

                <BaseContainer item>
                    <TypographyContainer item md={2}>
                        <Typography variant='h3'>
                            {t('setting.display')}
                        </Typography>
                        <Typography variant='body2Medium'>
                            {t('campus.display.description')}
                        </Typography>
                    </TypographyContainer>
                    <ComponentContainer item md={10}>
                        <Grid md={6}>
                            <ControlledRadioGroup
                                name='displayModeId'
                                options={mapDropdownOptions(displayModes)}
                            />
                        </Grid>
                    </ComponentContainer>
                </BaseContainer>

                <BaseContainer item>
                    <TypographyContainer item md={2}>
                        <Typography variant='h3'>
                            {t('general.businessDays')}
                        </Typography>
                        <Typography variant='body2Medium'>
                            {t('campus.businessDays.description')}
                        </Typography>
                    </TypographyContainer>
                    <ComponentContainer item md={10}>
                        <Grid container spacing={2}>
                            {/* First column for the first four working days */}
                            <DayColumn days={firstFourDays} />

                            {/* Second column for the remaining two working days */}
                            <DayColumn days={remainingDays} />
                        </Grid>
                    </ComponentContainer>
                </BaseContainer>
                {errors?.businessDays && errors?.businessDays?.message && (
                    <Grid container sm={12} md={8}>
                        <Grid item md={8}>
                            <Typography fontSize='12px' color='red'>
                                {errors?.businessDays?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                )}

                <BaseContainer item>
                    <TypographyContainer item md={2}>
                        <Typography variant='h3'>
                            {t('general.academicCalendar')}
                        </Typography>
                        <Typography variant='body2Medium'>
                            {t('campus.academicCalendar.description')}
                        </Typography>
                    </TypographyContainer>
                    <ComponentContainer item md={10}>
                        <Grid md={3}>
                            <ControlledDatePicker
                                id='calendar-year-from'
                                name='calendarYearFrom'
                                format={DateFormat.MONTH}
                                openTo='month'
                                label={t('general.from')}
                                width='95%'
                                isDisableFuture={false}
                                views={['year', 'month']}
                            />
                        </Grid>

                        <Grid md={3}>
                            <ControlledDatePicker
                                id='calendar-year-to'
                                name='calendarYearTo'
                                format={DateFormat.MONTH}
                                openTo='month'
                                label={t('general.to')}
                                width='95%'
                                isDisableFuture={false}
                                views={['year', 'month']}
                            />
                        </Grid>
                    </ComponentContainer>
                </BaseContainer>

                <BaseContainer item>
                    <TypographyContainer item md={2}>
                        <Typography variant='h3'>
                            {t('general.officeHours')}
                        </Typography>
                        <Typography variant='body2Medium'>
                            {t('campus.officeHours.description')}
                        </Typography>
                    </TypographyContainer>
                    <ComponentContainer item md={10}>
                        <Grid item md={3}>
                            <ControlledTimePicker
                                id='office-from'
                                label={t('general.from')}
                                name={'officeFrom'}
                                width='95%'
                                ariaLabel='time from'
                            />
                        </Grid>

                        <Grid item md={3}>
                            <ControlledTimePicker
                                id='office-to'
                                name='officeTo'
                                width='95%'
                                ariaLabel='time to'
                                label={t('general.to')}
                            />
                        </Grid>
                    </ComponentContainer>
                </BaseContainer>

                <BaseContainer item>
                    <TypographyContainer item md={2}>
                        <Typography variant='h3'>
                            {t('general.universityClosures')}
                        </Typography>
                        <Typography variant='body2Medium'>
                            {t('campus.universityClosures.description')}
                        </Typography>
                    </TypographyContainer>
                    <ComponentContainer item md={10}>
                        <MultiFieldRow {...multiFieldRowConfig} />
                    </ComponentContainer>
                </BaseContainer>
            </ParentContainer>
        </>
    )
}
