import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, AdminTopics } from '../../fragments'

export const GET_TOPIC_BY_ID = gql`
  ${MetaFragment('TopicResponseDto')}
  ${MessagesFragment('TopicResponseDto')}
  ${AdminTopics('TopicDto')}
  query GetTopic($getTopicId: String!) {
    getTopic(id: $getTopicId) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...AdminTopics
        coursesCount
        videosCount
      }
    }
  }
`
