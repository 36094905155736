import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const DiceIcon: React.FC<SvgProps> = ({ width = 20, height = 20, color }) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.backgroundInfo

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M18.1022 9.88044L10 10M10 10L5.61811 3.18402M10 10L6.25794 16.9765M15.0106 16.5257L18.208 10.5648C18.3429 10.3132 18.4104 10.1874 18.4335 10.0553C18.454 9.93841 18.4492 9.81853 18.4196 9.70365C18.3862 9.57379 18.309 9.4537 18.1546 9.21351L14.3397 3.27957C14.1935 3.05216 14.1204 2.93845 14.0223 2.85661C13.9354 2.78418 13.8348 2.73019 13.7264 2.69791C13.604 2.66143 13.4688 2.66343 13.1985 2.66742L6.14482 2.77154C5.85931 2.77575 5.71656 2.77786 5.58986 2.8218C5.47778 2.86068 5.37528 2.92304 5.28924 3.00472C5.19198 3.09704 5.12449 3.22285 4.98952 3.47447L1.79214 9.43534C1.65717 9.68696 1.58968 9.81277 1.56657 9.94487C1.54612 10.0617 1.55086 10.1816 1.58047 10.2965C1.61394 10.4263 1.69114 10.5464 1.84556 10.7866L5.66041 16.7206C5.80661 16.948 5.87972 17.0617 5.97783 17.1435C6.06467 17.2159 6.16533 17.2699 6.27371 17.3022C6.39615 17.3387 6.53131 17.3367 6.80164 17.3327L13.8553 17.2286C14.1408 17.2244 14.2835 17.2223 14.4102 17.1783C14.5223 17.1395 14.6248 17.0771 14.7109 16.9954C14.8081 16.9031 14.8756 16.7773 15.0106 16.5257Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M11.6957 5.94755L11.8924 5.9532C12.4462 5.96909 12.7897 6.56229 12.5278 7.05057C12.3944 7.29935 12.1318 7.45139 11.8496 7.44329L11.653 7.43765C11.0991 7.42176 10.7556 6.82856 11.0175 6.34028C11.151 6.09149 11.4135 5.93945 11.6957 5.94755Z'
                fill={_color}
            />
            <path
                d='M5.66872 8.38855L5.55525 8.22785C5.23565 7.77522 4.55146 7.81717 4.28955 8.30545C4.1561 8.55424 4.17467 8.85707 4.33751 9.08769L4.45098 9.24839C4.77058 9.70102 5.45477 9.65907 5.71668 9.17079C5.85013 8.922 5.83156 8.61917 5.66872 8.38855Z'
                fill={_color}
            />
            <path
                d='M11.1415 12.2698L11.3382 12.2755C11.892 12.2914 12.2355 12.8846 11.9736 13.3728C11.8402 13.6216 11.5776 13.7737 11.2954 13.7656L11.0988 13.7599C10.5449 13.744 10.2014 13.1508 10.4633 12.6625C10.5968 12.4138 10.8593 12.2617 11.1415 12.2698Z'
                fill={_color}
            />
            <path
                d='M9.95966 14.4729L10.1563 14.4785C10.7102 14.4944 11.0537 15.0876 10.7917 15.5759C10.6583 15.8247 10.3957 15.9767 10.1135 15.9686L9.91689 15.963C9.36304 15.9471 9.01954 15.3539 9.28145 14.8656C9.4149 14.6168 9.67745 14.4648 9.95966 14.4729Z'
                fill={_color}
            />
            <path
                d='M14.1323 11.9828L14.3289 11.9884C14.8828 12.0043 15.2263 12.5975 14.9644 13.0858C14.8309 13.3346 14.5683 13.4866 14.2861 13.4785L14.0895 13.4729C13.5356 13.457 13.1921 12.8638 13.4541 12.3755C13.5875 12.1267 13.8501 11.9747 14.1323 11.9828Z'
                fill={_color}
            />
            <path
                d='M12.9506 14.1859L13.1473 14.1915C13.7011 14.2074 14.0446 14.8006 13.7827 15.2889C13.6493 15.5377 13.3867 15.6897 13.1045 15.6816L12.9079 15.676C12.354 15.6601 12.0105 15.0669 12.2724 14.5786C12.4059 14.3298 12.6684 14.1778 12.9506 14.1859Z'
                fill={_color}
            />
            <path
                d='M7.084 11.039L6.97053 10.8783C6.65094 10.4257 5.96674 10.4676 5.70483 10.9559C5.57139 11.2047 5.58995 11.5075 5.7528 11.7381L5.86627 11.8988C6.18586 12.3515 6.87006 12.3095 7.13197 11.8212C7.26541 11.5725 7.24685 11.2696 7.084 11.039Z'
                fill={_color}
            />
        </svg>
    )
}

export default DiceIcon
