import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const SAVE_MUTE_STATUS = gql`
  ${MetaFragment('SaveMuteStatusResponseDto')}
  ${MessagesFragment('SaveMuteStatusResponseDto')}
  mutation saveMuteStatus($input: SaveMuteStatusInputDto!) {
    saveMuteStatus(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        mutedId
        muted
      }
    }
  }
`
