import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, ReportFragment } from '../../fragments'

export const SAVE_REPORT_THUMBNAIL = gql`
  ${MetaFragment('ReportResponseDto')}
  ${MessagesFragment('ReportResponseDto')}
  mutation Mutation($input: ReportThumbnailInputDto!) {
    saveReportThumbnail(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        id
        thumbnailSrc
      }
    }
  }
`
