import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { GET_TRAINING, UPDATE_TRAINING } from '@dts/graphql'
import { useAuth, useFormFeedback, usePage } from '@hooks'
import { EditTrainingCourses, getAddedCourses } from '@containers'
import { isMediumScreen } from '@utils'
import { BackButtonType } from '@constants'

export const EditTrainingCoursesContainer: React.FC = () => {
    const { t } = useTranslation()

    const { id } = useParams()
    const navigate = useNavigate()

    const updateTrainingConfig = {
        mutation: UPDATE_TRAINING,
        mutationName: 'updateTraining',
        onMutationSuccess: () => {
            navigate(`/trainings`)
        },
        isCustomMutationInput: true
    }

    const { methods, save } = useFormFeedback({
        initialValues: {
            title: null,
            topics: [],
            addedTopics: []
        },
        query: GET_TRAINING,
        queryName: 'getTraining',
        queryVariables: { id },
        retrieveOnMount: true,
        mapQueryResultToForm: ({ id, title, courses }) => ({
            id,
            title,
            addedCourses: courses
        }),
        ...updateTrainingConfig,
        mapFormToMutationInput: (form) => {
            const { id, courses, addedCourses } = form
            const coursesAdded = courses
                ? courses?.filter((course) => course.isAdded)
                : addedCourses?.map((course) => ({ id: course.id }))

            return {
                input: {
                    id,
                    courses: getAddedCourses(coursesAdded, addedCourses)
                }
            }
        }
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: EditTrainingCourses,
            hasHeader: true,
            preventMargin: true,
            showBreadcrumbs: true,
            backButtonConfig: {
                showBackButton: isMediumScreen?.(),
                backButtonType: BackButtonType.Custom,
                customUrl: '/trainings'
            },
            pageHeaderConfig: {
                headerText: t('edit.training.header'),
                primaryButtonTitle: t('general.save'),
                primaryButtonAction: () => save(),
                secondaryButtonTitle: t('general.cancel'),
                secondaryButtonAction: () => navigate(`/trainings`)
            }
        }
    })

    return <>{page}</>
}
