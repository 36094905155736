import { gql } from '@apollo/client'

export const GET_REPORT_VIDEO_OPTIONS = gql`
  query GetReportVideoOptions {
    getReportVideoOptions {
      available
      displayOrder
      id
      title
    }
  }
`
