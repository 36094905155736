import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Steps, useModalWithGraphQL, useStepper } from '@hooks'
import { GET_VERTICAL, SAVE_LEARNER_LIST } from '@dts/graphql/lib'

import { InitialDetails, TitleSchema } from '@components'
import { setUserData } from '@cacheql'
import {
    CopyPasteSchema,
    FileTypeEnum,
    FormLevelMessages,
    ListContentSchema,
    ListMatchSchema,
    useS3
} from '@dts/client-utils'
import Papa from 'papaparse'
import { DelimiterType, FileType, LearnerAdditionType } from '@constants'
import { isString } from 'lodash'
import {
    ListUploadContent,
    ListLearners,
    LearnerListMap,
    LearnerListMatch,
    ListReview,
    ListCopyAndPaste,
    ListConfigurations
} from './learner-list-creation'

export const CreateLearnerList: React.FC = () => {
    const { t } = useTranslation()
    const prevFileUploaderRef = useRef(null)
    const prevPastedValueRef = useRef(null)
    const [listCreationType, setListCreationType] = useState(
        LearnerAdditionType.Upload
    )
    const navigate = useNavigate()

    const userId = setUserData()?.id
    const { uploadFile, key, s3Object, emptyS3Object } = useS3({})

    const uploadFileAndHandleResponse = async (csvFile, methods) => {
        const res = await uploadFile(csvFile, {
            userId: setUserData()?.id,
            type: FileTypeEnum.CLIENT
        })
        if (res?.status === 200) {
            methods.setValue('pastedTextUpdated', false)
            methods.setValue('fileUploaded', true)
        } else {
            throw new Error(t('learnerList.dataProcessingIssue'))
        }
    }

    const UploadFile = async (csvFile, methods) => {
        await uploadFileAndHandleResponse(csvFile, methods)
    }

    const OnCopyPasteNext = async (csvText, delimiterType, methods) => {
        if (csvText) {
            const csvFile = new File(
                [csvText],
                delimiterType === FileType.CSV ? 'data.csv' : 'data.tsv',
                {
                    type: delimiterType
                }
            )
            await UploadFile(csvFile, methods)
            Papa.parse(csvText, {
                header: true,
                complete: function (results) {
                    const { data, meta } = results
                    const headers = meta.fields
                    const processedData =
                        headers?.length && !data.length
                            ? [
                                  headers.reduce((acc, key) => {
                                      acc[key] = ''
                                      return acc
                                  }, {})
                              ]
                            : data
                    methods.setValue('csvData', processedData)
                },
                transformHeader: (header) => {
                    const hasQuotes = header.includes('"')
                    if (hasQuotes) {
                        return header.replace(/"/g, '')
                    }
                    return header
                },
                transform: (value, header) => {
                    if (
                        isString(value) &&
                        value.startsWith('"') &&
                        value.endsWith('"')
                    ) {
                        return value.slice(1, -1)
                    }
                    return value
                }
            })
        }
        const {
            fieldMappingArray,
            valueMappingArray,
            undoPastedChanges,
            ...rest
        } = methods?.getValues() || {}
        if (!undoPastedChanges) {
            methods?.reset({
                fieldMappingArray: [],
                valueMappingArray: [],
                undoPastedChanges,
                ...rest
            })
        }

        return true
    }

    const getAdditionalSteps = () => {
        if (listCreationType !== LearnerAdditionType.EmptyList) {
            return [
                listCreationType === LearnerAdditionType.CopyPaste
                    ? {
                          component: ListCopyAndPaste,
                          componentProps: () => ({
                              prevPastedValueRef: prevPastedValueRef
                          }),
                          label: t('general.content'),
                          schema: CopyPasteSchema,
                          nextStepConfig: {
                              hasConfirmationDialog: (methods) => {
                                  const {
                                      pastedTextUpdated,
                                      fileUploaded,
                                      undoPastedChanges
                                  } = methods?.getValues() || {}

                                  if (
                                      !fileUploaded ||
                                      !pastedTextUpdated ||
                                      undoPastedChanges
                                  ) {
                                      return false
                                  }
                                  return true
                              },
                              onConfirm: (methods) => {
                                  const {
                                      learners: csvText,
                                      delimiterType,
                                      pastedTextUpdated
                                  } = methods?.getValues() || {}

                                  if (pastedTextUpdated) {
                                      return OnCopyPasteNext(
                                          csvText,
                                          delimiterType,
                                          methods
                                      )
                                  }
                              },
                              onError: async (error, methods) => {
                                  methods.setError('generic_error', {
                                      message: t(
                                          'learnerList.dataProcessingIssue'
                                      ),
                                      type: FormLevelMessages.ERRORS
                                  })
                                  alert(t('learnerList.dataProcessingIssue'))
                                  return false
                              },
                              onClose: (methods) => {
                                  const { previousPastedText } =
                                      methods.getValues()
                                  methods.setValue(
                                      'learners',
                                      previousPastedText
                                  )
                                  methods.setValue('undoPastedChanges', true)
                              }
                          }
                      }
                    : {
                          component: ListUploadContent,
                          componentProps: () => ({
                              prevFileUploaderRef: prevFileUploaderRef
                          }),
                          label: t('general.content'),
                          schema: ListContentSchema
                      },
                {
                    component: ListConfigurations,
                    label: t('learnerList.configurations')
                },
                {
                    component: LearnerListMatch,
                    label: t('learnerList.mapFields'),
                    schema: ListMatchSchema
                },

                {
                    component: LearnerListMap,
                    label: t('learnerList.mapValues')
                },
                {
                    component: ListReview,
                    label: t('general.review')
                }
            ]
        }
        return []
    }

    const steps: Array<Steps> = [
        {
            component: InitialDetails,
            componentProps: () => ({
                header: t('general.about')
            }),
            label: t('general.description'),
            schema: TitleSchema
        },

        {
            component: ListLearners,
            componentProps: () => ({
                setListCreationType: setListCreationType
            }),

            label: t('general.learners')
        },
        ...getAdditionalSteps()
    ].filter(Boolean)

    const getMutationInput = (formValues) => {
        const {
            name,
            description,
            addLearnerType,
            delimiterType,
            fileUploader,
            fieldMappingArray,
            valueMappingArray,
            updateExisting,
            tags
        } = formValues

        return {
            title: name,
            description,
            file:
                addLearnerType === LearnerAdditionType.Upload
                    ? `${userId}/client/${fileUploader}`
                    : addLearnerType === LearnerAdditionType.CopyPaste
                    ? delimiterType === FileType.CSV
                        ? `${userId}/client/data.csv`
                        : `${userId}/client/data.tsv`
                    : null,
            fieldMapping: fieldMappingArray.map((field) => ({
                ...field,
                mapFieldValue: field?.mapFieldValue?.toString()
            })),
            valueMapping: valueMappingArray
                .filter((value) => !value.hideRow)
                .map((value) => ({
                    csvField: value?.csvField,
                    csvValue: value?.csvValue,
                    hubbubValue: value?.hubbubValue
                })),
            updateExisting,
            tags
        }
    }
    const mapFormToInput = (formValues) => ({
        ...getMutationInput(formValues),
        isArchived: false
    })

    const { stepper } = useStepper({
        steps,
        showNextSteps: false,
        showBreadcrumbs: true,
        headerText: [t('general.new'), t('general.learnerList')].join(' '),
        initialValues: {
            tags: [],
            addLearnerType: LearnerAdditionType.Upload,
            fileUploader: null,
            fieldMappingArray: [],
            valueMappingArray: [],
            delimiterType: FileType.CSV,
            fileUploaded: false,
            pastedTextUpdated: false,
            previousPastedText: null,
            undoPastedChanges: false
        },
        mutation: SAVE_LEARNER_LIST,
        mutationName: 'createLearnerList',
        mapFormToInput,
        onMutationSuccess: () => {
            navigate('/learner-list')
        }
    })

    return <>{stepper}</>
}
