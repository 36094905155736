import { gql } from '@apollo/client'
import { VerticalFields, MessagesFragment, MetaFragment } from '../../fragments'

export const GET_VERTICALS = gql`
  ${MetaFragment('VerticalsResponseDto')}
  ${MessagesFragment('VerticalsResponseDto')}
  ${VerticalFields('VerticalDto')}
  query getVerticals(
    $args: GetVerticalsArgumentsDto!
    $options: VerticalPaginationDto
  ) {
    getVerticals(args: $args, options: $options) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...VerticalFields
      }
    }
  }
`
