import React, { ElementType, FC, ReactElement, useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { Grid } from '@mui/material'
import { SvgProps } from '@types'
import { isMediumScreen } from '@utils'
import {
    FilterLayoutComponentContainer,
    FilterLayoutOverlayContainer,
    Loader,
    ParentFilterLayoutContainer
} from '@components'
import { FlexDirection } from '@constants'
import { useLoader } from '@hooks'
import {
    BackButtonConfig,
    FetchEntityVariables,
    usePageHeader
} from './use-page-header'
import { useOverlay } from './use-overlay'

export type DropdownMenuConfig = {
    label: string
    icon?: JSX.Element | FC<SvgProps>
    onClick?: (callbackData?: object) => void
    hide?: boolean | ((callbackData?: object) => boolean)
    permissions?: Array<string>
}
type UsePageProps = {
    pageConfig: {
        hasHeader?: boolean
        preventPadding?: boolean
        preventMargin?: boolean
        showBreadcrumbs?: boolean
        breadcrumbSearchParamAccessor?: string
        fetchEntityVariables?: FetchEntityVariables
        methods: object
        ComponentToRender: ElementType
        hasColumnDirection?: boolean
        componentProps?: object | void
        backButtonConfig?: BackButtonConfig
        pageHeaderConfig: {
            headerText: string
            headerEditAction?: VoidFunction
            defaultGoBack?: boolean
            primaryButtonTitle?: string
            primaryButtonAction?: VoidFunction
            primaryButtonIcon?: FC<SvgProps>
            disablePrimaryButton?: () => boolean
            secondaryButtonTitle?: string
            secondaryButtonAction?: VoidFunction
            tertiaryButtonTitle?: string
            tertiaryButtonAction?: VoidFunction
            kebabMenuConfig?: Array<DropdownMenuConfig>
            primaryButtonMenuConfig?: Array<DropdownMenuConfig>
            backButton?: boolean
            menuConfig?: () => Array<{
                label: string
                path: string
                isHeaderLink?: boolean
                isLanding?: string | boolean
                isSideNav?: boolean
                icon?: ReactElement<SvgProps>
                canUserAccess?: boolean
            }>
            permissions?: Array<string>
        }
        overlayConfig?: {
            OverlayComponent: ElementType
            overlayComponentProps?: object | void
            hasFilterOverlay?: boolean
            overlayIcon: JSX.Element
            direction?: string
        }
        shouldShowLoader?: boolean
    }
}

export const usePage: React.FC<UsePageProps> = ({
    pageConfig: {
        hasHeader = true,
        preventPadding = false,
        preventMargin = false,
        showBreadcrumbs = false,
        breadcrumbSearchParamAccessor,
        fetchEntityVariables,
        backButtonConfig,
        methods,
        ComponentToRender,
        hasColumnDirection = false,
        componentProps,
        pageHeaderConfig: {
            headerText,
            ariaLabel,
            headerEditAction,
            primaryButtonTitle,
            primaryButtonAction,
            primaryButtonIcon,
            disablePrimaryButton,
            secondaryButtonTitle,
            secondaryButtonAction,
            tertiaryButtonTitle,
            tertiaryButtonAction,
            kebabMenuConfig,
            primaryButtonMenuConfig,
            backButton,
            menuConfig,
            defaultGoBack,
            permissions = []
        },
        overlayConfig,
        shouldShowLoader = true
    }
}) => {
    const { loading } = useLoader()
    const OverlayComponent = overlayConfig?.OverlayComponent
    const { showDrawer, overlayComponent } = overlayConfig
        ? useOverlay({
              DrawerComponent: OverlayComponent,
              drawerComponentProps: overlayConfig?.overlayComponentProps
          })
        : { showDrawer: undefined, overlayComponent: <></> }

    useEffect(() => {
        const contentContainer = document.getElementById('content-container')

        if (contentContainer && overlayConfig) {
            contentContainer.style.display = 'flex'
            contentContainer.style.flexDirection = 'column'
        }
    }, [])

    const { pageHeader } = usePageHeader({
        methods,
        headerText,
        ariaLabel,
        headerEditAction,
        primaryButtonTitle,
        secondaryButtonTitle,
        primaryButtonAction,
        secondaryButtonAction,
        primaryButtonIcon,
        disablePrimaryButton,
        tertiaryButtonTitle,
        tertiaryButtonAction,
        saveForm: true,
        kebabMenuConfig,
        primaryButtonMenuConfig,
        showBreadcrumbs,
        breadcrumbSearchParamAccessor,
        fetchEntityVariables,
        backButton,
        menuConfig,
        permissions,
        defaultGoBack,
        hasColumnDirection,
        backButtonConfig,
        overlayConfig: {
            showFilterOverlay: showDrawer,
            hasFilterOverlay: overlayConfig?.hasFilterOverlay ?? false,
            overlayIcon: overlayConfig?.overlayIcon
        }
    })
    return {
        page: (
            <>
                {loading && <Loader />}
                {hasHeader && pageHeader}

                {methods ? (
                    <FormProvider {...methods}>
                        {overlayConfig ? (
                            <ParentFilterLayoutContainer
                                container
                                padding={!preventPadding ? '2%' : '0%'}
                                margin={!preventMargin ? '2%' : '0%'}
                                flexDirection={
                                    overlayConfig?.direction ||
                                    FlexDirection.Row
                                }
                                // To-do: Will be removed after testing
                                // sx={{
                                //     overflow: 'hidden',
                                //     height: '100%',
                                //     maxHeight: '100%',
                                //     flexDirection:
                                //         overlayConfig?.direction ||
                                //         FlexDirection.Row
                                // }}
                            >
                                <FilterLayoutComponentContainer
                                    item
                                    md={overlayConfig ? 9 : 12}
                                    xs
                                    // To-do: Will be removed after testing
                                    // sx={{
                                    //     overflowY: 'auto',
                                    //     height: '100%',
                                    //     overflowX: 'hidden'
                                    // }}
                                >
                                    <ComponentToRender {...componentProps} />
                                </FilterLayoutComponentContainer>

                                <FilterLayoutOverlayContainer
                                    item
                                    md={3}
                                    xs={!isMediumScreen?.() ? 12 : 0}
                                    // To-do: Will be removed after testing
                                    // pt={0}
                                    // justifyContent={'center'}
                                    // position={'relative'}
                                    // p={0.2}
                                >
                                    {overlayComponent}
                                </FilterLayoutOverlayContainer>
                            </ParentFilterLayoutContainer>
                        ) : (
                            <Grid
                                sx={{
                                    p: !preventPadding && '2%',
                                    m: !preventMargin && '2%'
                                }}
                            >
                                <ComponentToRender {...componentProps} />
                            </Grid>
                        )}
                    </FormProvider>
                ) : (
                    <ComponentToRender {...componentProps} />
                )}
            </>
        )
    }
}
