import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const CheckmarkIcon: React.FC<SvgProps> = ({
    color,
    width = 20,
    height = 20,
    fill
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.backgroundPositive
    const _fill = fill || 'none'

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g clipPath='url(#clip0_21152_43182)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M19.1666 9.99967C19.1666 15.0623 15.0625 19.1663 9.99992 19.1663C4.93731 19.1663 0.833252 15.0623 0.833252 9.99967C0.833252 4.93706 4.93731 0.833008 9.99992 0.833008C15.0625 0.833008 19.1666 4.93706 19.1666 9.99967ZM14.8319 7.95676C15.2225 7.56624 15.2225 6.93307 14.8319 6.54255C14.4414 6.15202 13.8083 6.15202 13.4177 6.54255L10.6677 9.29255L8.62483 11.3354L6.58194 9.29255C6.19142 8.90202 5.55825 8.90202 5.16773 9.29255C4.7772 9.68307 4.7772 10.3162 5.16773 10.7068L7.91773 13.4568C8.30825 13.8473 8.94142 13.8473 9.33194 13.4568L12.0819 10.7068L14.8319 7.95676Z'
                    fill={_color}
                />
            </g>
            <defs>
                <clipPath id='clip0_21152_43182'>
                    <rect
                        width='20'
                        height='20'
                        fill={theme.palette.contentPrimary}
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
export default CheckmarkIcon
