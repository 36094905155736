import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, VideoFields } from '../../fragments'

export const GET_VIDEOS_GROUPED_BY_CREATORS = gql`
  ${MetaFragment('VideosGroupByCreatorsResponseDto')}
  ${MessagesFragment('VideosGroupByCreatorsResponseDto')}
  ${VideoFields('VideoDataDto')}
  query GetVideosGroupedByCreators($args: VideosGroupByCreatorsArgumentsDto) {
    getVideosGroupedByCreators(args: $args) {
      ...MetaFragment
      ...MessagesFragment
      data {
        count
        firstName
        id
        lastName
        videos {
          ...VideoFields
        }
      }
    }
  }
`
