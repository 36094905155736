enum NotificationPreferenceEnum {
    AllowAll = 'ALL',
    NewTraining = 'NTR',
    CompleteTrainingReminders = 'RCT',
    ReminderEmails = 'RME',
    TrainingCompleted = 'TCD',
    WeeklyRecapCompletionRate = 'WRC',
    VideoSubmissionEmailUpdates = 'VSE',
    SupportEmails = 'SEM',
    HubbubUpdates = 'HUP',
    VideoSubmissionPushUpdates = 'VSP',
    NewVideoSubmittedPush = 'NVP',
    VideoFlaggedPush = 'VFP',
    NewVideoSubmittedEmail = 'NVE',
    WeeklyStatusVideoSubmission = 'WVS',
    VideoFlaggedEmail = 'VFE',
    NewTrainingEmail = 'NTE'
}

enum NotificationPreferenceGraphqlEnum {
    ALL = 'ALL',
    NTR = 'NTR',
    RCT = 'RCT',
    RME = 'RME',
    TCD = 'TCD',
    WRC = 'WRC',
    VSE = 'VSE',
    SEM = 'SEM',
    HUP = 'HUP',
    VSP = 'VSP',
    NVP = 'NVP',
    VFP = 'VFP',
    NVE = 'NVE',
    WVS = 'WVS',
    VFE = 'VFE',
    NTE = 'NTE'
}

export {NotificationPreferenceEnum, NotificationPreferenceGraphqlEnum}
