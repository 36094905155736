import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Grid, IconButton } from '@mui/material'
import { ButtonVariants } from '@constants'
import { useCustomTheme, useGlobal, useGraphQLWrapper } from '@hooks'
import { SAVE_SURVEY_RESPONSE } from '@dts/graphql'
import { QuestionTypeEnum, SurveyTypeEnum } from '@dts/constants'
import { valueExists } from '@utils'
import { ArrowLeftCircleIcon, DoubleArrowIcon } from '@assets'
import { cachedSurveyInfo, surveyStepsInfo } from '@cacheql'
import { cloneDeep, isEmpty } from 'lodash'
import { CachedSurveyStepInfo, CurrentSurveyStep } from '@providers'
import { QuestionFeedback } from './question-feedback'
import { SurveyQuestionDetails } from './question-details'

export const SurveyDialog = ({
    stepNumber = 0,
    steps,
    setSteps,
    hideDialog,
    currentSurveyType
}: {
    stepNumber: number
    hideDialog: VoidFunction
    currentSurveyType: string
    steps: Array<CurrentSurveyStep>
    setSteps: Dispatch<SetStateAction<Array<CurrentSurveyStep>>>
}) => {
    const { t } = useTranslation()
    const [activeStep, setActiveStep] = useState(stepNumber ?? 0)
    const [queryLoad, setQueryLoad] = useState(false)
    const { theme } = useCustomTheme()
    const { isMediumScreen } = useGlobal()
    const [searchParams] = useSearchParams()
    const courseId = searchParams.get('courseId') || null

    const methods = useForm({
        shouldUnregister: false,
        defaultValues: steps?.[activeStep]?.initialValues,
        resolver:
            steps?.[activeStep]?.schema &&
            yupResolver(steps?.[activeStep]?.schema),
        mode: 'onChange'
    })

    const updateStepinCache = (
        questionId?: string,
        accessorsHideStep?: Array<{ id: string; hideStep: boolean }>,
        lastMainStepQuestionId?: string
    ) => {
        const cacheData = cloneDeep(surveyStepsInfo())
        const updatedCacheData = cacheData?.map(
            (step: CachedSurveyStepInfo) => {
                if (step?.questionId === questionId && accessorsHideStep) {
                    let updatedStep = step
                    accessorsHideStep?.forEach((accessor) => {
                        if (step?.[accessor?.id] === true) {
                            updatedStep = {
                                ...step,
                                hideStep: accessor?.hideStep
                            }
                        }
                    })

                    return updatedStep
                } else if (lastMainStepQuestionId === step?.questionId) {
                    if (step?.isMainStep) {
                        return {
                            ...step,
                            hideStep: true
                        }
                    } else if (step?.isDetailsStep) {
                        return {
                            ...step,
                            hideStep: false
                        }
                    }
                    // To-do: Will be removed after testing
                    // else if (step?.isFeedbackStep) {
                    //     return {
                    //         ...step,
                    //         hideStep: true
                    //     }
                    // }
                }
                return step
            }
        )

        surveyStepsInfo(updatedCacheData)
    }

    const getLastMainStepQuestionId = (activeStep: CurrentSurveyStep) => {
        const currentStep = steps?.[activeStep]
        let lastMainStepIndex = null
        if (currentStep?.isMainStep && activeStep !== 0) {
            for (let i = 0; i < activeStep; i++) {
                if (steps[i]?.isMainStep === true) {
                    lastMainStepIndex = i
                }
            }

            if (lastMainStepIndex && lastMainStepIndex >= 0) {
                return steps?.[lastMainStepIndex]?.questionId
            } else {
                return null
            }
        }
    }

    const changeStep = (stepsToJump: number) => {
        if (activeStep + stepsToJump >= steps.length) {
            hideDialog()
        } else {
            setActiveStep(activeStep + stepsToJump)
            methods.clearErrors()
            methods.reset({
                ...methods.getValues(),
                ...steps?.[activeStep]?.initialValues
            })
        }
    }

    const maintainAnsweredQuestions = (questionId: string, accessor) => {
        const prevCachedSurveyInfo = cachedSurveyInfo()
        const updatedQuestionIds: Array<string> = cloneDeep(
            prevCachedSurveyInfo?.[accessor]
        )
        updatedQuestionIds?.push(questionId)
        cachedSurveyInfo({
            ...prevCachedSurveyInfo,
            [accessor]: updatedQuestionIds
        })
    }

    const { save: graphQLSave } = useGraphQLWrapper({
        mutation: SAVE_SURVEY_RESPONSE,
        mutationName: 'saveSurveyResponse',
        onMutationSuccess: (response) => {
            maintainAnsweredQuestions(response?.data?.id, 'answeredQuestionIds')
            setSteps((prevSteps) =>
                prevSteps?.map((step) => {
                    if (
                        step?.questionId === response?.data?.id &&
                        step?.isDetailsStep
                    ) {
                        return {
                            ...step,
                            component: (compProps) => (
                                <SurveyQuestionDetails {...compProps} />
                            ),
                            componentProps: () => ({
                                ...step?.componentProps?.(),
                                ...response?.data
                            })
                        }
                    }
                    // To-do: Will be removed after testing
                    // else if (
                    //     step?.questionId === response?.data?.id &&
                    //     step?.isFeedbackStep
                    // ) {
                    //     return {
                    //         ...step,
                    //         component: (compProps) => (
                    //             <QuestionFeedback {...compProps} />
                    //         ),
                    //         componentProps: () => ({
                    //             ...step?.componentProps?.(),
                    //             ...response?.data
                    //         })
                    //     }
                    // }
                    else {
                        return step
                    }
                })
            )
        },
        suppressToast: true
    })

    useEffect(() => {
        if (queryLoad) {
            setQueryLoad(false)
        }
    }, [activeStep])

    const mapFormToInput = (formValues, isSkipped: boolean) => {
        const { surveyQuestion } = formValues || {}
        const surveyQuestionType = surveyQuestion?.questionType?.id

        return {
            courseId,
            surveyQuestionId: surveyQuestion?.id,
            questionNumber: surveyQuestion?.questionNumber,
            surveyOptionIds: isSkipped
                ? []
                : surveyQuestionType === QuestionTypeEnum.SingleChoice ||
                  surveyQuestionType === QuestionTypeEnum.YesNo
                ? [formValues?.singleChoiceAnswer]
                : surveyQuestionType === QuestionTypeEnum.MultiChoice
                ? Object.keys(formValues?.multiChoiceAnswers)
                : undefined
        }
    }

    const save = async (isSkipped: boolean) => {
        const formValues = methods.getValues()
        const mutationVariables = mapFormToInput?.(formValues, isSkipped)
        return graphQLSave(mutationVariables)
    }

    const getFirstAndLastQuestion = (clonedCacheData) => ({
        firstQuestionId: steps[0]?.questionId,
        lastQuestionId: steps[steps?.length - 2]?.questionId,
        hideSummaryStep:
            clonedCacheData?.length && !isEmpty(clonedCacheData)
                ? clonedCacheData[clonedCacheData?.length - 1]?.hideStep
                : true
    })

    const updateStepVisibilityOnBack = (currentStep) => {
        if (
            // To-do: Will be removed after testing
            // currentStep?.isFeedbackStep ||
            currentStep?.isDetailsStep
        ) {
            updateStepinCache(
                undefined,
                undefined,
                steps?.[activeStep]?.questionId
            )
        } else if (currentStep?.isMainStep) {
            const lastMainStepQuestionId = getLastMainStepQuestionId(activeStep)
            updateStepinCache(undefined, undefined, lastMainStepQuestionId)
        }
    }

    const shouldHideSummaryStep = (
        clonedCacheData: Array<object>,
        skipAnswerDetailsStep: boolean
        // To-do: Will be removed after testing
        // skipFeedbackStep: boolean
    ) => {
        const firstAndLastStepInfo = getFirstAndLastQuestion(clonedCacheData)
        const currentStep = steps?.[activeStep]
        if (
            firstAndLastStepInfo?.lastQuestionId ===
                steps?.[activeStep]?.questionId &&
            firstAndLastStepInfo?.hideSummaryStep
        ) {
            // To-do: Will be removed after testing
            // if (currentStep?.isFeedbackStep) {
            //     return 1
            // }
            if (currentStep?.isDetailsStep) {
                return firstAndLastStepInfo?.hideSummaryStep ? 1 : 0
            } else if (currentStep?.isMainStep) {
                return skipAnswerDetailsStep ? 1 : 0
            } else {
                return 0
            }
        } else {
            return 0
        }
        return 0
    }

    const handleNext = async (isSkipped?: boolean) => {
        const onNextStepChange = async () => {
            let response
            let stepsToJump = 0
            const clonedCacheData = cloneDeep(surveyStepsInfo())

            if (steps?.[activeStep]?.isMainStep) {
                response = await save(isSkipped)
            }

            if (response) {
                const responseData = response?.data
                const lastMainStepQuestionId =
                    getLastMainStepQuestionId(activeStep)

                responseData?.hideFeedback &&
                    maintainAnsweredQuestions(responseData?.id, 'visitedIds')

                updateStepinCache(
                    responseData?.id,
                    [
                        {
                            id: 'isMainStep',
                            hideStep: true
                        },
                        {
                            id: 'isDetailsStep',
                            hideStep: responseData?.hideFeedback
                        }
                        // To-do: Will be removed after testing
                        // {
                        //     id: 'isFeedbackStep',
                        //     hideStep: !valueExists(response?.data?.summaryTitle)
                        // }
                    ],
                    lastMainStepQuestionId
                )

                // To-do: Will be removed after testing
                // const skipAnswerDetailsStep =
                //     responseData?.surveyOptions?.every(
                //         (surveyOption) => surveyOption?.isAnswer === false
                //     )
                //         ? 1
                //         : 0

                const skipAnswerDetailsStep = responseData?.hideFeedback
                // To-do: Will be removed after testing
                // const skipFeedbackStep = !valueExists(
                //     responseData?.summaryTitle
                // )
                //     ? 1
                //     : 0

                // const responseCalculatedSteps =
                //     skipAnswerDetailsStep === skipFeedbackStep
                //         ? skipAnswerDetailsStep + skipFeedbackStep
                //         : skipAnswerDetailsStep === 1
                //         ? skipAnswerDetailsStep
                //         : 0

                const hideSummaryStep = shouldHideSummaryStep(
                    clonedCacheData,
                    skipAnswerDetailsStep
                    // To-do: Will be removed after testing
                    // skipFeedbackStep
                )

                const checkSkippedJump = isSkipped
                    ? 2
                    : 1 + responseData?.hideFeedback

                stepsToJump = hideSummaryStep + checkSkippedJump
            } else {
                maintainAnsweredQuestions(
                    steps?.[activeStep]?.questionId,
                    'visitedIds'
                )
                let entryFound = 0
                for (let i = activeStep + 1; i < steps?.length; i++) {
                    if (clonedCacheData?.[i]?.hideStep !== true) {
                        entryFound = i
                        break
                    }
                }
                stepsToJump = entryFound - activeStep
            }

            if (stepsToJump >= 0) {
                changeStep(stepsToJump)
            } else {
                hideDialog()
            }
        }

        if (isSkipped) {
            onNextStepChange()
        } else {
            await methods.trigger().then(async (resp) => {
                if (resp) {
                    onNextStepChange()
                }
            })
        }
    }

    const isLastStep = activeStep === steps?.length - 1

    const StepperFooter = () => {
        const nextButtonProps = isLastStep
            ? { variant: ButtonVariants.Contained, sx: { width: '100%' } }
            : {
                  variant: ButtonVariants.Text,
                  sx: { fontSize: '1rem', fontWeight: 400, gap: '0px' },
                  endIcon: <DoubleArrowIcon />
              }
        return (
            <Grid
                container
                width={'100%'}
                justifyContent={'space-between'}
                sx={{
                    position: isMediumScreen ? 'fixed' : 'sticky',
                    bottom: 0,
                    padding: '24px',
                    backgroundColor: theme.palette.backgroundPrimary
                }}
            >
                <Grid item xs={5.5} md={5.5}>
                    {currentSurveyType !== SurveyTypeEnum.Pulse &&
                        !isLastStep &&
                        steps?.[activeStep]?.isMainStep && (
                            <Button
                                onClick={() => handleNext(true)}
                                // sx={{ width: '100%' }}
                                variant={ButtonVariants.Text}
                                sx={{
                                    fontSize: '1rem',
                                    fontWeight: 400,
                                    color: theme?.palette?.contentSecondary
                                }}
                            >
                                {t('general.skip')}
                            </Button>
                        )}
                </Grid>

                <Grid
                    item
                    xs={isLastStep ? 11 : 5.5}
                    md={isLastStep ? 12 : 5.5}
                    textAlign={isLastStep ? 'center' : 'end'}
                >
                    <Button
                        onClick={() => handleNext()}
                        disabled={activeStep === steps?.length}
                        {...nextButtonProps}
                    >
                        {isLastStep ? t('general.continue') : t('general.next')}
                    </Button>
                </Grid>
            </Grid>
        )
    }

    const onBackClick = () => {
        let indexToJump
        for (let i = 0; i < activeStep; i++) {
            if (steps[i]?.isDetailsStep === true) {
                indexToJump = i
            }
        }

        if (indexToJump) {
            updateStepVisibilityOnBack(steps?.[activeStep])
            setActiveStep(indexToJump)
        }
    }

    const BackClickComponent = () => (
        <IconButton
            color='primary'
            sx={{ padding: 0, marginRight: '8px' }}
            onClick={() => {
                onBackClick?.()
            }}
        >
            <ArrowLeftCircleIcon color={theme.palette.contentPrimary} />
        </IconButton>
    )

    const renderStepBody = () => {
        const currentStep = steps?.[activeStep]
        const currentProps = {
            ...currentStep?.componentProps?.(),
            BackClickComponent,
            getFirstAndLastQuestion: () => getFirstAndLastQuestion()
        }

        return (
            <>
                <FormProvider {...methods}>
                    {currentStep?.component &&
                        currentStep.component(currentProps)}
                </FormProvider>
                {/* To-do: Height needs to be reconsidered */}
                {isMediumScreen ? <Box minHeight={'8.438rem'} /> : <></>}
            </>
        )
    }

    return (
        <>
            <Grid id={'survey-dialog'} sx={{ height: '100%', padding: '25px' }}>
                {renderStepBody()}
            </Grid>
            <Grid sx={{ position: 'relative' }}>
                <StepperFooter />
            </Grid>
        </>
    )
}
