import { styled } from '@mui/material/styles'
import { Grid, LinearProgress, linearProgressClasses } from '@mui/material'
import { LinearProgressProps } from './types'

export const StyledLinearProgress = styled(LinearProgress)<LinearProgressProps>(
    ({
        theme,
        barBackgroundColor,
        progressBackgroundColor,
        height,
        border,
        hasBorder
    }) => ({
        height,
        borderRadius: 5,
        border: hasBorder
            ? border || `1px solid ${theme?.palette?.borderSecondary}`
            : undefined,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: barBackgroundColor
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: progressBackgroundColor
        }
    })
)

export const StyledLabelGrid = styled(Grid)(({ theme }) => ({
    padding: '4px',
    borderRadius: theme.spacing(4) || '4px', // Access theme spacing or fallback to default value
    background: theme.palette.contentPrimary
}))
