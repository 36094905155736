import { NotificationCategoryEnum } from '@dts/constants'

const getFallbackNotificationRoute = (route, referenceId) =>
    referenceId ? route : '/notifications'

export const redirectNotificationToItem = (item, referenceId) => {
    // There will be further conditions in this
    if (item?.notificationCategoryId === NotificationCategoryEnum.Training) {
        return getFallbackNotificationRoute(
            `/my-trainings?routeId=${referenceId}`,
            referenceId
        )
    } else if (
        item?.notificationCategoryId === NotificationCategoryEnum.Video ||
        item?.notificationCategoryId === NotificationCategoryEnum.VideoFlaged
    ) {
        return getFallbackNotificationRoute(
            `/videos/${referenceId}`,
            referenceId
        )
    } else if (
        item?.notificationCategoryId === NotificationCategoryEnum.VideoPublished
    ) {
        return getFallbackNotificationRoute(
            `/my-account/${referenceId}?isAccountVideos=${true}`,
            referenceId
        )
    } else if (
        item?.notificationCategoryId === NotificationCategoryEnum.VideoRejected
    ) {
        return `/my-account/rejected`
    }
}
