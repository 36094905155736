import React from 'react'
import { useFormFeedback, usePage } from '@hooks'
import { InsightTimeline, InsightVideoOption } from '@dts/client-utils'
import { ContentCreatorCards } from './content-creator-cards'

export const ContentCreatorDashboard = () => {
    const { methods } = useFormFeedback({
        initialValues: {
            insightTimeFrame: InsightTimeline.LAST_SEVEN_DAYS,
            insightVideoOption: InsightVideoOption.LATEST_VIDEOS
        }
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: ContentCreatorCards,
            hasHeader: false,
            preventPadding: true,
            preventMargin: true,
            pageHeaderConfig: {
                headerText: ''
            }
        }
    })

    return <>{page}</>
}
