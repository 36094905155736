import { UploadedFileType } from '../../constants'
import { FormLevelMessages } from '../../types'
import * as yup from 'yup'

export const CopyPasteSchema = yup.object().shape({
  learners: yup
    .string()
    .required('learnerList.learnerRequired')
    .test(
      'delimiterCheck',
      'Invalid format for learners data',
      function (value) {
        const delimiterType = this.parent.delimiterType
        if (delimiterType === UploadedFileType.CSV) {
          return value && value.includes(',')
        } else if (delimiterType === UploadedFileType.TSV) {
          return !value || !value.includes(',')
        }
        return true
      },
    ),
  delimiterType: yup
    .string()
    .oneOf([UploadedFileType.CSV, UploadedFileType.TSV])
    .required('Delimiter type is required'),
})
