import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const DELETE_VERTICAL = gql`
  ${MessagesFragment('VerticalResponseDto')}
  ${MetaFragment('VerticalResponseDto')}
  mutation deleteVertical($id: String!) {
    deleteVertical(id: $id) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
      }
    }
  }
`
