import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isMediumScreen } from '@utils'
import { mapDropdownOptions } from '@dts/client-utils'
import { ViewArticleContainer } from '@containers'
import { Button, Divider, Grid } from '@mui/material'
import { GET_VIDEOS_DROPDOWN_DATA } from '@dts/graphql'
import { DraftIcon, ReviewIcon } from '@assets'
import {
    useFormFeedback,
    useMediaUpload,
    useModalWithGraphQL,
    useImageUploader,
    useGraphQLWrapper
} from '@hooks'
import {
    ConfirmationDialog,
    ControlledCheckBox,
    ControlledRichEditor,
    ControlledSelect,
    ControlledTextInput,
    SearchableTags
} from '@components'
import { AccessibleAction } from '@hoc'
import { UserPermissionEnum } from '@dts/constants'

const PreviewDraftFeatured = ({ props, isUploadingStart, permissions }) => {
    const { t } = useTranslation()
    const { getValues } = useFormContext()
    const { isDraft, id } = getValues()

    const { openModalWithForm } = useModalWithGraphQL({
        modalConfig: {
            Component: ViewArticleContainer,
            fullScreen: true
        }
    })

    const { openModalWithForm: publishedToDraftModal } = useModalWithGraphQL({
        modalConfig: {
            updationConfig: {
                primaryButtonText: t('general.confirm'),
                header: t('general.saveDraft')
            },
            Component: () => (
                <ConfirmationDialog
                    message={t(
                        'createArticle.publisedToDraft.confirmationMessage'
                    )}
                />
            )
        }
    })

    /**
     * fileUrl is being maintained inside the Thumbnail component
     */
    const previewButtonHanlder = () => {
        const { description, articleHeader, tags, time, fileUrl, thumbnail } =
            getValues()
        openModalWithForm({
            description,
            articleHeader,
            tags,
            time,
            fileUrl: fileUrl || thumbnail
        })
    }

    const draftHandler = () => props?.onDraftHandler?.(true)

    return (
        <>
            <Button
                fullWidth
                variant='outlined'
                color='primary'
                sx={{ marginBottom: '10px', borderRadius: '12px' }}
                startIcon={<ReviewIcon />}
                onClick={() => previewButtonHanlder()}
                id='previewButton'
            >
                {t('video.upload.preview.label')}
            </Button>

            <AccessibleAction
                permissions={permissions}
                component={
                    <Button
                        fullWidth
                        variant='outlined'
                        color='primary'
                        sx={{ marginBottom: '10px', borderRadius: '12px' }}
                        startIcon={<DraftIcon width='21' height='20' />}
                        disabled={isUploadingStart}
                        onClick={() =>
                            id && !isDraft
                                ? publishedToDraftModal(_, draftHandler)
                                : draftHandler()
                        }
                    >
                        {t('general.saveDraft')}
                    </Button>
                }
            />
            <ControlledCheckBox
                id='isFeatured'
                name='isFeatured'
                label={t('createArticle.featured')}
            />
        </>
    )
}

export const CreateEditArticleForm: React.FC = (props) => {
    const { t } = useTranslation()
    const isMdScreen = isMediumScreen()
    const { fileUploadComponent, isUploadingStart } = useImageUploader()

    const { currentData: articleData } = useGraphQLWrapper({
        query: GET_VIDEOS_DROPDOWN_DATA,
        queryName: 'getVideoDropDownsData'
    })

    const { handleImageUpload, handleMediaRemove, handleVideoUpload, loading } =
        useMediaUpload()

    useEffect(() => {
        props?.setIsImageUploading?.(isUploadingStart)
    }, [isUploadingStart])

    return (
        <>
            <Grid
                container
                justifyContent={isMdScreen ? 'space-around' : 'space-between'}
            >
                <Grid item md={9.5} mb={1}>
                    <ControlledTextInput
                        id='articleHeader'
                        label={t('createArticle.header.placeholder')}
                        name='articleHeader'
                        fullWidth
                        sx={{ marginTop: '0rem !important' }}
                    />
                    <ControlledRichEditor
                        name={'description'}
                        placeholder={t('createArticle.body.placeholder')}
                        onImageChange={handleImageUpload}
                        onVideoChange={handleVideoUpload}
                        onMediaRemove={handleMediaRemove}
                        isLoading={loading}
                        permissions={[UserPermissionEnum.ArticleCreateModify]}
                    />
                </Grid>
                <Grid item md={2.4}>
                    {!isMdScreen && (
                        <>
                            <PreviewDraftFeatured
                                props={props}
                                isUploadingStart={isUploadingStart}
                                permissions={[
                                    UserPermissionEnum.ArticleCreateModify
                                ]}
                            />
                            <Divider sx={{ marginBottom: 2 }} />
                        </>
                    )}
                    <AccessibleAction
                        permissions={[UserPermissionEnum.ArticleCreateModify]}
                        component={fileUploadComponent}
                    />

                    <Divider sx={{ marginTop: 2 }} />

                    <ControlledSelect
                        name='topic'
                        label={t('general.topic')}
                        ariaLabel={t('general.topic')}
                        placeholder={t(
                            'createVideos.modal.component.topic.placeholder'
                        )}
                        id='topic'
                        options={mapDropdownOptions(articleData?.topics)}
                    />
                    <SearchableTags name='tags' customDataName='tags' />
                    <ControlledTextInput
                        id='time'
                        label={t('createArticle.time')}
                        name='time'
                        fullWidth
                        sx={{ marginTop: '15px' }}
                    />

                    {isMdScreen && (
                        <>
                            <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
                            <PreviewDraftFeatured
                                props={props}
                                isUploadingStart={isUploadingStart}
                                permissions={[
                                    UserPermissionEnum.ArticleCreateModify
                                ]}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    )
}
