import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const VersionsIcon: React.FC<SvgProps> = ({
    color,
    height = '24',
    width = '24',
    fill
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.backgroundBrandPrimary
    const _fill = fill || theme.palette.darkestBlue

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox={`0 0 24 24`}
            fill='none'
        >
            <rect
                id='Rectangle 182'
                x='3'
                y='10.5'
                width='18'
                height='11'
                rx='3'
                fill={_fill}
                fillOpacity='0.1'
                stroke={_color}
                strokeWidth='1.5'
            />
            <path
                id='Line 36'
                d='M5 8H19'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
            />
            <path
                id='Line 37'
                d='M7 5.5L17 5.5'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
            />
            <path
                id='Line 38'
                d='M9 3L15 3'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
            />
        </svg>
    )
}
export default VersionsIcon
