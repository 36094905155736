import * as React from 'react'

import { useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import {
    Box,
    Button,
    Checkbox,
    Grid,
    IconButton,
    Radio,
    Typography
} from '@mui/material'
import { CircleIcon, ReOrderIcon, SquareIcon } from '@assets'
import { AccessibleAction, can_access, controlled } from '@hoc'
import { useCustomTheme } from '@hooks'
import {
    StyledButton,
    StyledFormControlLabel,
    StyledOptionGroup
} from './styles'

import { RadioGroupProps } from './types'

export const OptionGroup = ({
    name,
    value,
    onChange,
    options,
    hasColumnDirection,
    gap,
    rowComponent,
    onMouseEnter,
    onMouseLeave,
    onAddOption,
    isMultiSelect,
    enableReorder = false,
    permissions = []
}: RadioGroupProps) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const [selectedValues, setSelectedValues] = useState(
        isMultiSelect ? value || [] : value
    )
    const { formContext } = useFormContext()

    useEffect(() => {
        const validValues = isMultiSelect
            ? (value || []).filter((val) =>
                  options.some((option) => option.value === val)
              )
            : options.some((option) => option.value === value)
            ? value
            : null

        if (
            isMultiSelect
                ? validValues.length !== (value || []).length
                : validValues !== value
        ) {
            onChange(validValues)
        }

        setSelectedValues(validValues)
    }, [value, isMultiSelect, options])

    const handleSelectionChange = (val) => {
        if (isMultiSelect) {
            const newSelectedValues = selectedValues.includes(val)
                ? selectedValues.filter((v) => v !== val)
                : [...selectedValues, val]
            setSelectedValues(newSelectedValues)
            onChange(newSelectedValues)
        } else {
            const newValue = selectedValues === val ? null : val
            setSelectedValues(newValue)
            onChange(newValue)
        }
    }

    const isSelected = (val) =>
        isMultiSelect ? selectedValues.includes(val) : selectedValues === val

    const getRadio = (option, index) => (
        <StyledFormControlLabel
            key={option?.value}
            value={option?.value}
            disabled={permissions?.length > 0 && !can_access(permissions)}
            control={
                isMultiSelect ? (
                    <Checkbox
                        checked={isSelected(option?.value)}
                        size='small'
                        onClick={(e) => {
                            e.stopPropagation()
                            handleSelectionChange(option?.value)
                        }}
                    />
                ) : (
                    <Radio
                        checked={isSelected(option?.value)}
                        size='small'
                        onClick={(e) => {
                            e.stopPropagation()
                            handleSelectionChange(option?.value)
                        }}
                    />
                )
            }
            onClick={(e) => e.preventDefault()}
            label={
                <Grid container gap={2} alignItems={'center'}>
                    {option?.radioIcon && (
                        <option.radioIcon
                            color={theme.palette.contentPrimary}
                            height={22}
                            width={22}
                        />
                    )}
                    {rowComponent ? (
                        rowComponent?.(index)
                    ) : (
                        <Typography variant='body3-medium'>
                            {option?.label}
                        </Typography>
                    )}
                </Grid>
            }
            isSelected={isSelected(option?.value)}
        />
    )

    return (
        <>
            <StyledOptionGroup
                name={name}
                value={selectedValues}
                hasColumnDirection={hasColumnDirection}
                onChange={onChange}
                onClick={(e) => {
                    e.stopPropagation()
                }}
                aria-setsize={'sm'}
                sx={{ display: 'contents' }}
                gap={gap}
            >
                {options?.map((option, index) => (
                    <Grid
                        key={option?.value}
                        item
                        sx={{
                            minHeight: '60px' // Set a consistent minimum height
                        }}
                        onMouseEnter={() => {
                            onMouseEnter?.(index)
                        }}
                        onMouseLeave={() => onMouseLeave?.()}
                    >
                        {enableReorder ? (
                            <Draggable
                                draggableId={option?.value}
                                key={option?.value}
                                index={index}
                            >
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                    >
                                        <Grid
                                            container
                                            {...provided.dragHandleProps}
                                            alignItems={'center'}
                                        >
                                            <Grid item p={1}>
                                                <AccessibleAction
                                                    permissions={permissions}
                                                    component={
                                                        <IconButton
                                                            {...provided.dragHandleProps}
                                                            sx={{
                                                                minWidth: 'auto'
                                                            }}
                                                        >
                                                            <ReOrderIcon />
                                                        </IconButton>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                {getRadio?.(option, index)}
                                            </Grid>
                                        </Grid>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Draggable>
                        ) : (
                            getRadio?.(option, index)
                        )}
                    </Grid>
                ))}
            </StyledOptionGroup>
        </>
    )
}

export const ControlledOptionGroup = controlled(OptionGroup)
