export enum TypographyVariants {
    body1 = 'body1',
    body2 = 'body2',
    body1Medium = 'body1Medium',
    body2Medium = 'body2Medium',
    body3Medium = 'body3Medium',
    body3 = 'body3',
    button = 'button',
    caption = 'caption',
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
    inherit = 'inherit',
    overline = 'overline',
    subtitle1 = 'subtitle1',
    subtitle2 = 'subtitle2',
    othersNav = 'othersNav',
    smallText = 'smallText'
}
