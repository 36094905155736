import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@mui/material'
import { AddIcon, EmptyStateImg } from '@assets'
import { StartIconButton } from '@components'
import { AccessibleAction } from '@hoc'
import { EmptyStateFields } from './types'

export const EmptyState: React.FC<EmptyStateFields> = ({
    header,
    subHeader,
    image,
    preventPadding = false,
    buttonConfig
}) => {
    const { t } = useTranslation()

    return (
        <Grid
            container
            direction={'column'}
            py={preventPadding ? 0 : '10%'}
            gap={3}
        >
            <Grid item alignSelf={'center'} xs={4}>
                <Box
                    component='img'
                    src={image ? image : EmptyStateImg}
                    alt={header ? header : t('general.noData')}
                    sx={{ maxWidth: '100%', height: 200 }}
                />
            </Grid>
            <Grid item container flexDirection={'column'} gap={1}>
                <Grid item>
                    <Typography variant='h2' textAlign={'center'}>
                        {header ? header : t('general.noData')}
                    </Typography>
                </Grid>
                <Grid item textAlign={'center'}>
                    <AccessibleAction
                        permissions={buttonConfig?.permissions}
                        component={
                            <Typography variant='body2'>
                                {subHeader
                                    ? subHeader
                                    : t('general.subheader.noData')}
                            </Typography>
                        }
                        elseComponent={
                            <Typography>
                                {t('general.subheader.noData')}
                            </Typography>
                        }
                    />
                </Grid>
            </Grid>
            {buttonConfig ? (
                <Grid item alignSelf={'center'}>
                    <StartIconButton
                        label={buttonConfig?.label}
                        onClick={buttonConfig?.onClick}
                        Icon={AddIcon}
                        permissions={buttonConfig?.permissions}
                    />
                </Grid>
            ) : (
                <></>
            )}
        </Grid>
    )
}
