import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { Divider, Grid, Typography } from '@mui/material'
import {
    ControlledMultiSelectAutoComplete as ControlledAutoComplete,
    ControlledVideoPlayer
} from '@components'
import { isMediumScreen, mapLabelsWithAutoIncrements } from '@utils'
import { StyledChip } from '@components/Input/styles'
import { ChippedLinks } from '@components/General'
import { useVideoCaptions } from '@hooks'

export const ViewVideo = ({ accountVideos }) => {
    const { t } = useTranslation()

    const { getValues, control, setValue } = useFormContext()

    const {
        title,
        description,
        hyperlinks,
        captionText,
        tags,
        topicsData,
        skillData,
        captionFileSrc
    } = getValues()
    const { fetchVTTFile } = useVideoCaptions()
    const seekTime = useWatch({
        control,
        name: 'timeStamp'
    })

    useEffect(() => {
        captionFileSrc && fetchVTTFile?.(captionFileSrc)
    }, [captionFileSrc])

    return (
        <Grid container mt={0} p={1} xs={12} md={12}>
            <Grid
                item
                xs={12}
                md={4}
                pr={2}
                pt={2}
                pb={2}
                mb={4}
                justifyContent={'center'}
            >
                <ControlledVideoPlayer
                    name='url'
                    controls
                    width='100%'
                    height={isMediumScreen?.() ? '100%' : '60vh'}
                    sx={{ borderRadius: '16px' }}
                    captionFileSrc={captionFileSrc}
                    seekConfig={{
                        skipToTime: seekTime,
                        onSeekSuccess: () => {
                            setValue('timeStamp', 0)
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12} md={8} pt={2} pr={4}>
                <Grid container flexDirection={'column'} md={12} xs={12}>
                    <Grid item xs={12} md={12} mb={4}>
                        <Typography variant='h1'>{title}</Typography>
                    </Grid>
                    {description && (
                        <Grid item xs={12} md={12} mb={4}>
                            <Typography variant='body2'>
                                {description}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12} md={12} mb={2}>
                        <Typography variant='h3'>
                            {t('createVideos.modal.component.links.label')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} mb={2}>
                        {hyperlinks?.length ? (
                            <ChippedLinks
                                links={mapLabelsWithAutoIncrements(hyperlinks)}
                            />
                        ) : (
                            <Typography variant='body2'>
                                {t('viewVideos.noLinksFound')}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} md={12} mt={2} mb={2}>
                        <Typography variant='h3'>
                            {t('general.captions')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} mb={2}>
                        <Typography variant='body2'>
                            {captionText ?? t('viewVideos.noCaptionsFound')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} mb={2} mt={2}>
                        <Typography variant='h3'>
                            {t('general.tags')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        {tags?.length ? (
                            mapLabelsWithAutoIncrements(tags)?.map((tag) => (
                                <StyledChip
                                    label={tag.value}
                                    key={tag.value}
                                    sx={{ mb: 1 }}
                                />
                            ))
                        ) : (
                            <Typography variant='body2'>
                                {t('viewVideos.noTagsFound')}
                            </Typography>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        mt={accountVideos ? 2 : 4}
                        mb={accountVideos ? 0 : 2}
                    >
                        <Typography variant='h3'>
                            {t('createVideos.modal.component.others.label')}
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        display={'flex'}
                        xs={12}
                        md={12}
                        alignItems='center'
                    >
                        <Grid item md={5} xs={6}>
                            {t('general.categories')}
                        </Grid>
                        <Grid item md={7} xs={6}>
                            {topicsData?.map((category) => (
                                <StyledChip
                                    label={category.title}
                                    key={category.title}
                                />
                            ))}
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid
                        item
                        display={'flex'}
                        xs={12}
                        md={12}
                        alignItems='center'
                    >
                        <Grid item md={5} xs={6}>
                            {t('createVideos.modal.component.skillLevel.label')}
                        </Grid>
                        <Grid item md={7} ml={1} xs={6}>
                            <Typography variant='body2'>
                                {skillData?.title}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Grid>
            </Grid>
        </Grid>
    )
}
