import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomActions } from '@types'
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid-pro'
import { Paper, Tooltip, Typography } from '@mui/material'
import { ConfirmationDialog, Duplicate, TextWithTooltip } from '@components'
import { DocumentNode } from '@apollo/client'
import { ArchiveIcon, DeleteIcon, DuplicateIcon, RestoreIcon } from '@assets'
import { useCustomTheme } from '@hooks'
import { can_access } from '@hoc'
import { useActionInterceptor } from './use-action-interceptor'
import { useModalWithGraphQL } from './use-modal-with-graphql'

type GenericAction = {
    name: string
    mutation: DocumentNode
    mutationName: string
    query?: DocumentNode
    queryName?: string
    onMutationSuccess?: (response?: unknown) => void
    hide?: (params: unknown) => void
    permissions?: Array<string>
}

export type GenericActions = {
    duplicateConfig?: GenericAction
    archiveConfig?: GenericAction
    restoreConfig?: GenericAction
    deletionConfig?: GenericAction
}

export const getConfirmationConfig = (t, confirmationMessage?: string) => ({
    maxWidth: 'sm',
    Component: () => (
        <ConfirmationDialog
            message={confirmationMessage ?? t('confirmation.proceedMessage')}
        />
    ),
    confirmationConfig: {
        primaryButtonText: t('general.confirm'),
        header: t('general.confirmation')
    },
    hasCancelButton: true
})

export const useCustomActions = ({
    customActions = [],
    genericActions = {}
}: {
    customActions: Array<CustomActions>
    genericActions?: GenericActions
}) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()

    const [allActions, setAllActions] = useState([])

    const { duplicateConfig, archiveConfig, restoreConfig, deletionConfig } =
        genericActions

    useEffect(() => {
        const duplicateAction = duplicateConfig && {
            icon: <DuplicateIcon color={theme.palette.contentSecondary} />,
            label: t('createArticle.duplicate'),
            showInMenu: true,
            action: (params) => {
                openDuplicateDialog({
                    id: params?.row?.id
                })
            },
            permissions: duplicateConfig?.permissions
        }

        const archiveAction = archiveConfig && {
            icon: <ArchiveIcon color={theme.palette.contentSecondary} />,
            label: t('createArticle.archive'),
            showInMenu: true,
            action: (params) => {
                archiveConfirmationDialog(archiveFn(params, true))
            },
            hide: archiveConfig?.hide,
            permissions: archiveConfig?.permissions
        }

        const restoreAction = restoreConfig && {
            icon: <RestoreIcon />,
            label: t('general.restore'),
            showInMenu: true,
            action: (params) => {
                restoreConfirmationDialog(archiveFn(params, false))
            },
            hide: restoreConfig?.hide,
            permissions: restoreConfig?.permissions
        }

        const deleteAction = deletionConfig && {
            icon: <DeleteIcon color={theme.palette.contentSecondary} />,
            label: t('general.delete'),
            showInMenu: deletionConfig?.showInMenu ?? true,
            action: (params) => {
                deleteConfirmationDialog({ id: params?.id })
            },
            hide: deletionConfig?.hide,
            permissions: deletionConfig?.permissions
        }

        const actions = [
            duplicateAction,
            archiveAction,
            restoreAction,
            deleteAction
        ].filter(Boolean)

        setAllActions(actions)
    }, [])

    const archiveFn = (params, archive) => ({
        input: {
            id: params?.row?.id,
            isArchived: archive
        }
    })

    const { openPrepopulatedDialog: openDuplicateDialog } = duplicateConfig
        ? useModalWithGraphQL({
              mutation: duplicateConfig?.mutation,
              mutationName: duplicateConfig?.mutationName,
              onMutationSuccess: duplicateConfig?.onMutationSuccess,
              isCustomMutationInput: true,
              mapFormToMutationInput: ({ id, title }) => ({
                  input: { id, title }
              }),
              query: duplicateConfig?.query,
              queryName: duplicateConfig?.queryName,
              mapQueryResultToForm: ({ id, title }) => ({
                  id,
                  title
              }),
              initialValues: {
                  title: null
              },
              modalConfig: {
                  maxWidth: 'sm',
                  Component: duplicateConfig?.Component ?? Duplicate,
                  updationConfig: {
                      primaryButtonText: t('general.confirm'),
                      header: [
                          t('createArticle.duplicate'),
                          duplicateConfig?.name
                      ].join(' ')
                  },
                  hasCancelButton: true
              }
          })
        : { openPrepopulatedDialog: () => undefined }

    const { openConfirmationDialog: archiveConfirmationDialog } = archiveConfig
        ? useModalWithGraphQL({
              mutation: archiveConfig?.mutation,
              mutationName: archiveConfig?.mutationName,
              onMutationSuccess: archiveConfig?.onMutationSuccess,
              isCustomMutationInput: true,

              modalConfig: getConfirmationConfig(t)
          })
        : { openConfirmationDialog: () => undefined }

    const { openConfirmationDialog: restoreConfirmationDialog } = restoreConfig
        ? useModalWithGraphQL({
              mutation: restoreConfig?.mutation,
              mutationName: restoreConfig?.mutationName,
              onMutationSuccess: restoreConfig?.onMutationSuccess,
              isCustomMutationInput: true,

              modalConfig: getConfirmationConfig(t)
          })
        : { openConfirmationDialog: () => undefined }

    const { openConfirmationDialog: deleteConfirmationDialog } = deletionConfig
        ? useModalWithGraphQL({
              mutation: deletionConfig?.mutation,
              mutationName: deletionConfig?.mutationName,
              onMutationSuccess: deletionConfig?.onMutationSuccess,
              isCustomMutationInput: true,

              modalConfig: getConfirmationConfig(t)
          })
        : { openConfirmationDialog: () => undefined }

    const allPermissions = [...allActions, ...customActions]
        ?.flatMap((action) => action?.permissions)
        .filter(Boolean)

    const showActionsColumn = can_access(allPermissions)

    const getCustomActions = (params?: GridRowParams) =>
        [...allActions, ...customActions]?.map(
            ({
                icon: Icon,
                label,
                action,
                actionConfig,
                showInMenu,
                disabled,
                hide,
                getLabel,
                getIcon,
                ariaLabel = '',
                role,
                permissions
            }) => {
                let onClickFn
                if (action) {
                    onClickFn = () => action(params)
                } else if (actionConfig) {
                    const { save } = useActionInterceptor(actionConfig)
                    onClickFn = save
                }

                if (hide?.(params)) {
                    return <></>
                } else {
                    /**
                     * This customLabel && cutsomIcon are used to get the all PARAMS at the label.
                     *  e.g in-order to get the status & toggle the label/icon on the basis of that status
                     */
                    const customLabel = getLabel?.(params)
                    const customIcon = getIcon?.(params)

                    return can_access(permissions) ? (
                        !showInMenu ? (
                            <TextWithTooltip
                                toolTip={ariaLabel}
                                onClick={() => onClickFn(params)}
                                renderer={
                                    <GridActionsCellItem
                                        key={params?.id}
                                        disabled={disabled?.(params)}
                                        aria-label={ariaLabel ?? label}
                                        icon={Icon ?? customIcon}
                                        tabIndex={-1}
                                        {...(role && {
                                            role
                                        })}
                                        label={
                                            <Typography
                                                sx={{
                                                    color: `${theme.palette.contentPrimary}`,
                                                    fontSize: '14px',
                                                    fontWeight: '600'
                                                }}
                                                className='MuiTypography-root MuiTypography-body2'
                                            >
                                                {label ?? customLabel}
                                            </Typography>
                                        }
                                        onClick={() => onClickFn(params)}
                                        showInMenu={showInMenu}
                                        sx={{
                                            py: 1.5,
                                            px: 2,
                                            background: showInMenu
                                                ? theme.palette
                                                      .backgroundSecondary
                                                : 'transparent',
                                            '& svg path': {
                                                stroke: showInMenu
                                                    ? theme.palette
                                                          .contentSecondary
                                                    : theme.palette.contentLink // Icon stroke color on default
                                            },
                                            '&:hover': {
                                                background: showInMenu
                                                    ? theme.palette.contentLink // Background color on hover if showInMenu is true
                                                    : 'transparent', // No hover effect if showInMenu is false
                                                '& svg path': {
                                                    fill: 'none',
                                                    stroke: showInMenu
                                                        ? theme.palette
                                                              .contentPrimaryInverse // Icon stroke color on hover if showInMenu is true
                                                        : theme.palette
                                                              .contentLink // Icon stroke color on default if showInMenu is false
                                                },
                                                '& .MuiTypography-root.MuiTypography-body2':
                                                    {
                                                        color: showInMenu
                                                            ? theme.palette
                                                                  .contentPrimaryInverse // Text color (unchanged) on hover if showInMenu is true
                                                            : theme.palette
                                                                  .contentLink // Text color (unchanged) on default if showInMenu is false
                                                    }
                                            }
                                        }}
                                    />
                                }
                            />
                        ) : (
                            <GridActionsCellItem
                                key={params?.id}
                                disabled={disabled?.(params)}
                                aria-label={ariaLabel ?? label}
                                icon={Icon ?? customIcon}
                                tabIndex={params?.tabIndex}
                                {...(role && {
                                    role
                                })}
                                label={
                                    <Typography
                                        sx={{
                                            color: `${theme.palette.contentPrimary}`,
                                            fontSize: '14px',
                                            fontWeight: '600'
                                        }}
                                        className='MuiTypography-root MuiTypography-body2'
                                    >
                                        {label ?? customLabel}
                                    </Typography>
                                }
                                onClick={() => onClickFn(params)}
                                showInMenu={showInMenu}
                                sx={{
                                    py: 1.5,
                                    px: 2,
                                    background: showInMenu
                                        ? theme.palette.backgroundSecondary
                                        : 'transparent',
                                    '& svg path': {
                                        stroke: showInMenu
                                            ? theme.palette.contentSecondary
                                            : theme.palette.contentLink // Icon stroke color on default
                                    },
                                    '&:hover': {
                                        background: showInMenu
                                            ? theme.palette.contentLink // Background color on hover if showInMenu is true
                                            : 'transparent', // No hover effect if showInMenu is false
                                        '& svg path': {
                                            fill: 'none',
                                            stroke: showInMenu
                                                ? theme.palette
                                                      .contentPrimaryInverse // Icon stroke color on hover if showInMenu is true
                                                : theme.palette.contentLink // Icon stroke color on default if showInMenu is false
                                        },
                                        '& .MuiTypography-root.MuiTypography-body2':
                                            {
                                                color: showInMenu
                                                    ? theme.palette
                                                          .contentPrimaryInverse // Text color (unchanged) on hover if showInMenu is true
                                                    : theme.palette.contentLink // Text color (unchanged) on default if showInMenu is false
                                            }
                                    }
                                }}
                            />
                        )
                    ) : (
                        <></>
                    )
                }
            }
        )
    return {
        getCustomActions,
        showActionsColumn: showActionsColumn
    }
}
