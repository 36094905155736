enum NotificationPreferenceCategoryEnum {
    NewTraining = 'NTA',
    ReminderNotifications = 'REN',
    TrainingCompleted = 'TCD',
    WeeklyRecapCompletionRate = 'WRC',
    VideoSubmissionUpdates = 'UVS',
    SupportEmails = 'SEM',
    HubbubUpdates = 'HBU',
    NewVideoSubmitted = 'NVS',
    VideoFlagged = 'VFL',
    WeeklyStatusVideoSubmission = 'WVS',
}


export {NotificationPreferenceCategoryEnum }
