import {
    styled,
    Button,
    IconButton,
    Box,
    Drawer as MuiDrawer,
    AppBar as MuiAppBar,
    BottomNavigation as MUIBottomNavigation,
    Typography
} from '@mui/material'

export const drawerWidth = 250
const drawerWidthCollapsed = 50

const openedMixin = (theme) => ({
    backgroundColor: `${theme.palette.backgroundSecondary}`,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
})

const closedMixin = (theme) => ({
    backgroundColor: `${theme.palette.backgroundSecondary}`,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`
    }
})

export const StyledDrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
    minHeight: '132px'
}))

export const StyledAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
    width: `calc(100% - ${drawerWidthCollapsed}px)`,
    backgroundColor: `${theme.palette.backgroundSecondary}`,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.down('md')]: {
        width: `100%`
    },
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`
    })
}))

export const StyledBottomNavigation = styled(MUIBottomNavigation)(
    ({ theme }) => ({
        height: '55px',
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: '10px',
        paddingRight: '10px',
        position: 'absolute',
        bottom: '0',
        width: '100%',
        zIndex: '10',
        backgroundColor: theme?.palette?.backgroundPrimary,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    })
)

export const StyledDrawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
    backgroundColor: `${theme.palette.backgroundSecondary}`,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
        '& span': {
            marginLeft: '-0.8rem !important'
        }
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
    })
}))

export const IconGrid = styled(IconButton)(({ theme }) => ({
    backgroundColor: `${theme.palette.backgroundPrimary}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    gap: '8px'
}))

export const SideNavContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
    padding: open ? '16px' : '16px 3px',
    gap: '8px',
    '&& .Mui-selected': {
        borderRadius: '12px',
        backgroundColor: `${theme.palette.backgroundBrandPrimary}`,
        color: `${theme.palette.backgroundPrimary}`
    }
}))

export const StyledButton = styled(Button)(({ theme }) => ({
    color: `${theme.palette.contentPrimary}`,
    height: '40px'
}))

export const StyledTypography = styled(Typography)(({ theme }) => ({
    color: `${theme.palette.contentPrimary}`,
    fontWeight: '600',
    fontSize: '14px',
    marginLeft: '16px',
    marginRight: '16px'
}))
