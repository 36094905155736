import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'

export const OuterDiv = styled('div')`
    height: calc(100vh - 180px);
    display: flex;
    align-items: center;
    justify-content: center;
`

export const VideosContainer = styled('div')`
    position: relative;
    height: 100%;
    overflow: scroll;
    width: 100%;
    max-width: 800px;
    scroll-snap-type: y mandatory;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
`

export const VideoGrid = styled('div')(({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.palette.contentPrimary,
    height: '100%',
    width: '100%',
    display: 'grid',
    placeItems: 'center'
}))

export const StyledMetaDataTypography = styled(Typography)(({ theme }) => ({
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    maxHeight: '50px',
    height: '30px',
    maxWidth: '80%',
    color: theme.palette.contentPrimaryInverse
}))

export const StyledDescriptionTypography = styled(Typography)(({ theme }) => ({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '90%',
    maxHeight: '50px',
    height: '30px',
    color: theme.palette.contentPrimaryInverse
}))

export const StyledHyperLinkTypography = styled(Typography)<{
    color?: string
    isMediumScreen?: boolean
    theme
}>(({ color, isMediumScreen, theme }) => ({
    color: color || theme.palette.contentLink,
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%'
}))

export const StyledDescriptionGrid = styled(Grid)<{ numberOfLines?: number }>(
    ({ numberOfLines }) => ({
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: numberOfLines ?? 3,
        maxHeight: '90px'
    })
)

export const StyledDetailedOverlay = styled(Grid)(({ theme }) => ({
    color: theme.palette.contentPrimaryInverse,
    padding: '20px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7))',
    zIndex: 999,
    height: '60vh',
    maxHeight: '60vh',
    overflowY: 'auto',
    top: 'auto',
    bottom: 0,
    width: '100%'
}))

export const VideoMetadataContainer = styled(Grid)(() => ({
    maxHeight: '80vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '20%'
}))
