import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const FilterIcon: React.FC<SvgProps> = ({ color, width = 24, height = 24 }) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.backgroundBrandPrimary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox={`0 0 24 24`}
            color={_color}
            fill='none'
        >
            <g id='ic-filter'>
                <path
                    id='Icon'
                    d='M6 12H18M3 6H21M9 18H15'
                    stroke={_color}
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
        </svg>
    )
}
export default FilterIcon
