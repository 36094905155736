import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { Grid, Typography } from '@mui/material'
import { SearchableTopicsList } from '@containers/courses/course-creation'
import { ControlledTextInput } from '@components'
import { HelperTextPosition } from '@constants'

export const UpdateCourse: React.FC = ({
    allTopics,
    preventNestedListUpdate,
    nestedAssignmentsOnly
}) => {
    const { t } = useTranslation()
    const { ...formContext } = useFormContext()
    return (
        <Grid container flexDirection={'column'}>
            {!nestedAssignmentsOnly && (
                <Grid item md={10} xs={10}>
                    <ControlledTextInput
                        name='name'
                        id='name'
                        label={t('general.title')}
                        multiline
                        disabled={formContext?.formEditingState?.isReadOnly}
                        rows={2}
                        helperTextConfig={{
                            wordLength: 200,
                            helperTextPosition: HelperTextPosition.Right
                        }}
                    />
                </Grid>
            )}

            <Grid item md={10} xs={10} mt={1}>
                <Typography variant='h3'>
                    {nestedAssignmentsOnly
                        ? t('courses.reAssignTopics')
                        : [t('general.manage'), t('general.topics')].join(' ')}
                </Typography>
            </Grid>

            <Grid item>
                <SearchableTopicsList
                    allTopics={allTopics}
                    editComponentConfig={
                        preventNestedListUpdate
                            ? {
                                  hideSearchBar: true,
                                  hideNotAdded: true,
                                  hideListUpdateButtons: true
                              }
                            : nestedAssignmentsOnly
                            ? { nestedAssignmentsOnly: true }
                            : undefined
                    }
                />
            </Grid>
        </Grid>
    )
}
