import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, RoleFields } from '../../fragments'

export const GET_ROLE = gql`
  ${MessagesFragment('RoleResponseDto')}
  ${MetaFragment('RoleResponseDto')}
  ${RoleFields('RoleDto')}
  query getRole($id: String!, $clientId: String) {
    getRole(id: $id, clientId: $clientId) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...RoleFields
      }
    }
  }
`
