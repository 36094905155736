import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const PhoneIcon: React.FC<SvgProps> = ({
    color,
    width = '16',
    height = '17'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 16 17'
            fill='none'
        >
            <path
                d='M14.6667 11.7807V13.7807C14.6675 13.9664 14.6294 14.1502 14.555 14.3203C14.4807 14.4904 14.3716 14.6431 14.2348 14.7686C14.0979 14.8941 13.9364 14.9897 13.7605 15.0492C13.5847 15.1087 13.3983 15.1308 13.2134 15.114C11.1619 14.8911 9.19137 14.1901 7.46004 13.0674C5.84926 12.0438 4.48359 10.6782 3.46004 9.06738C2.33336 7.32819 1.6322 5.34805 1.41337 3.28738C1.39671 3.10303 1.41862 2.91722 1.4777 2.7418C1.53679 2.56637 1.63175 2.40518 1.75655 2.26846C1.88134 2.13175 2.03324 2.02252 2.20256 1.94773C2.37189 1.87294 2.55493 1.83422 2.74004 1.83405H4.74004C5.06357 1.83086 5.37723 1.94543 5.62254 2.1564C5.86786 2.36737 6.02809 2.66035 6.07337 2.98072C6.15779 3.62076 6.31434 4.2492 6.54004 4.85405C6.62973 5.09266 6.64915 5.35199 6.59597 5.6013C6.5428 5.85061 6.41928 6.07946 6.24004 6.26072L5.39337 7.10738C6.34241 8.77641 7.72434 10.1583 9.39337 11.1074L10.24 10.2607C10.4213 10.0815 10.6501 9.95795 10.8994 9.90478C11.1488 9.85161 11.4081 9.87102 11.6467 9.96072C12.2516 10.1864 12.88 10.343 13.52 10.4274C13.8439 10.4731 14.1396 10.6362 14.3511 10.8857C14.5625 11.1352 14.6748 11.4538 14.6667 11.7807Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default PhoneIcon
