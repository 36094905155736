import { gql } from '@apollo/client'

export const TrainingFields = (on: string) => gql`
    fragment TrainingFields on ${on} {
        id
        title
        description
        tags
        startDate
        dueDate
        startTime
        dueTime
        isDraft
        isArchived
        enableNotifications
        createdAt
        updatedAt
        remindersStartDate
        remindersEndDate
        progress
        reminders{
          notificationFrequency
          daily
          weekly
          monthly{
            reminderOption
            date
            week
            day
          }
        }
        learnerLists{
          id
          title
        }
        courses{
          id
          title
          description
          courseId
          tenantCourseId
          surveys{
          id
          title
          duration
          completed
          surveyType{
          id 
          title
          available
          }
          }
          courseTopics{
              topic{
                  id
                  title
                  description
                  isArchived
                  createdAt
                  updatedAt
                  videosCount
                  coursesCount
              }
              topicId
              duration
              progress
              isAssigned
              remainingDuration
          }
          topicsCount
          progress
      }
        assignedTags
        excludedTag
        learnersCount
    }
`
