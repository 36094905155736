export const darkColors = {
    // Background color's
    backgroundSecondary: '#252728',

    backgroundBrandPrimary: '#007B94',

    brandPurplePrimary: '#7F3879',

    backgroundPrimary: '#181B1B',

    backgroundInfo: '#15A4C1',

    backgroundInfoSubtle: '#007B941A',

    backgroundTertiary: '#3D4142',

    neutralsBackgroundVariant: '#E6E6E6',

    BrandPrimaryContainer: '#007B940F',

    backgroundNoticeSubtle: '#F47B0B1A',

    backgroundNegativeSubtle: '#DF0C1D1A',

    borderNegative: '#D73439',

    // Content/text color's

    contentSecondary: '#C2C6C7',

    contentPrimary: '#FDFCFC',

    contentLink: '#15A4C1',

    contentPrimaryInverse: '#FDFCFC',

    contentTertiary: '#888F90',

    neutralsTextLight: '#959595',

    neutralsTextGrey: '#727272',

    contentNegative: '#DF0C1D ',

    // Border color's

    borderPrimary: '#888F90',

    borderSecondary: '#343738',

    borderGrey: '#E0E0E0',

    BorderBrandPrimary: '#5CB1C4',

    borderPrimaryInverse: '#181B1B',

    // BoxShadow color's

    drawerBoxShadow: 'rgba(0, 0, 0, 0.08)',

    // loaderBackDrop color's

    loaderBackDrop: 'rgba(0, 0, 0, 0.7)',

    // Other color's used in app

    secondaryRust: '#F2B69D',

    successMain: '#738442',

    errorDangerMedium: '#C51111',

    cream: '#FBF5F3',

    lightPink: '#F8E6E6',

    lightLavender: '#F3EDF9',

    skyBlue: '#E2F6FE',

    fireOrange: '#F54E09',

    tomatoRed: '#F04343',

    royalBlue: '#585DD7',

    navyBlue: '#0467A3',

    dullGreen: '#859B53',

    defaultCopper: '#C27933',

    lightBlue: '#E6EFF2',

    darkestBlue: '#005D7A',

    electricBlue: '#1C76FE',

    azureBlue: '#DFE9EB',

    errorDangerMain: '#BF4F2A',

    brandRedLight: '#C74D61',

    activeGreen: '#00B955',

    inactiveBrown: '#C27933',

    yellow: '#FDD133',

    alertRed: '#F9E7E7',

    dimGrey: '#F8F8F8',

    primaryGrey: '#f9f9f9',

    secondaryGrey: '#e7e7e7',

    tertiaryGrey: '#d4d4d4',

    naturalGrey: '#d9d9d9',

    primaryBlue: '#E2E9EC',

    secondaryBlue: '#b3d9f4',

    chipsBlue: '#D9E7EB',

    completedGreen: '#EDF3CF',

    secondaryOrange: '#EA711A',

    secondaryPink: '#DA4595',

    secondaryYellow: '#D3CB05',

    pale: '#F6E9D1',

    paleBorder: '#FAC868',

    progressBarGreen: '#5F9C11',

    progressBarBlue: '#0C61B0',

    progressBarCyan: '#005d7a',

    brightGreen: '#3DB007',

    skeletonBackground: '#9e9e9e',

    backgroundNegative: '#A41D21',

    backgroundPositive: '#698928',

    backgroundPositiveSubtle: '#6989281A',

    contentNotice: '#B95B20',

    constantsWhite: '#ffffff',

    constantsWhiteSubtle: '#ffffff1A',

    placeholderGrey: '#c4c4c4',

    backgroundBrandPrimarySubtle: 'rgba(0, 123, 148, 0.10)',

    backgroundDisabled: '#3D4142',

    surfaceL1: '#1D2021', // TODO: Naming Convention to be updated by UI team

    tempContentSecondary: '#C2C6C7'
}

export const lightColors = {
    // Background color's
    backgroundSecondary: '#F5F5F5',

    backgroundBrandPrimary: '#007B94',

    brandPurplePrimary: '#7F3879',

    backgroundPrimary: '#FDFCFC',

    backgroundInfo: '#15A4C1',

    backgroundInfoSubtle: '#EBF5FA',

    backgroundTertiary: '#E0E1E1',

    neutralsBackgroundVariant: '#E6E6E6',

    BrandPrimaryContainer: '#007B940F',

    backgroundNoticeSubtle: '#F47B0B1A',

    backgroundNegativeSubtle: '#DF0C1D1A',

    borderNegative: '#D73439',

    // Content/text color's

    contentSecondary: '#3D4142',

    contentPrimary: '#181B1B',

    contentLink: '#016588',

    contentPrimaryInverse: '#FDFCFC',

    contentTertiary: '#888F90',

    neutralsTextLight: '#959595',

    neutralsTextGrey: '#727272',

    contentNegative: '#DF0C1D ',

    // Border color's

    borderPrimary: '#888F90',

    borderSecondary: '#C2C6C7',

    borderGrey: '#E0E0E0',

    BorderBrandPrimary: '#5CB1C4',

    borderPrimaryInverse: '#FDFCFC',

    // BoxShadow color's

    drawerBoxShadow: 'rgba(0, 0, 0, 0.08)',

    // loaderBackDrop color's

    loaderBackDrop: 'rgba(255, 255, 255, 0.7)',

    // Other color's used in app

    secondaryRust: '#F2B69D',

    successMain: '#738442',

    errorDangerMedium: '#C51111',

    brandRedLight: '#C74D61',

    cream: '#FBF5F3',

    lightPink: '#F8E6E6',

    lightLavender: '#F3EDF9',

    skyBlue: '#E2F6FE',

    fireOrange: '#F54E09',

    tomatoRed: '#F04343',

    royalBlue: '#585DD7',

    navyBlue: '#0467A3',

    dullGreen: '#859B53',

    defaultCopper: '#C27933',

    lightBlue: '#E6EFF2',

    darkestBlue: '#005D7A',

    electricBlue: '#1C76FE',

    azureBlue: '#DFE9EB',

    errorDangerMain: '#BF4F2A',

    activeGreen: '#00B955',

    inactiveBrown: '#C27933',

    yellow: '#FDD133',

    alertRed: '#F9E7E7',

    dimGrey: '#F8F8F8',

    primaryGrey: '#f9f9f9',

    secondaryGrey: '#e7e7e7',

    tertiaryGrey: '#d4d4d4',

    naturalGrey: '#d9d9d9',

    primaryBlue: '#E2E9EC',

    secondaryBlue: '#b3d9f4',

    chipsBlue: '#D9E7EB',

    completedGreen: '#EDF3CF',

    secondaryOrange: '#EA711A',

    secondaryPink: '#DA4595',

    secondaryYellow: '#D3CB05',

    pale: '#F6E9D1',

    paleBorder: '#FAC868',

    progressBarGreen: '#5F9C11',

    progressBarBlue: '#0C61B0',

    progressBarCyan: '#005d7a',

    brightGreen: '#3DB007',

    skeletonBackground: '#9e9e9e',

    backgroundNegative: '#A41D21',

    backgroundPositive: '#698928',

    backgroundPositiveSubtle: '#6989281A',

    contentNotice: '#B95B20',

    constantsWhite: '#ffffff',

    constantsWhiteSubtle: '#ffffff1A',

    placeholderGrey: '#c4c4c4',

    backgroundBrandPrimarySubtle: 'rgba(0, 123, 148, 0.10)',

    backgroundDisabled: '#E4E5E5',

    surfaceL1: '#F5F5F5', // TODO: Naming Convention to be updated by UI team

    tempContentSecondary: '#565C5D'
}
