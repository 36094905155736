import styled from '@emotion/styled'
import { Grid } from '@mui/material'

export const StyledCardContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    padding: '8px',
    width: '180px',
    borderRadius: '12px',
    border: `1px solid ${theme.palette.borderSecondary}`,
    background: theme.palette.backgroundSecondary
}))
