import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const UserUnsureIcon: React.FC<SvgProps> = ({
    color,
    width = 24,
    height = 25
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.errorDangerMain

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox={`0 0 24 25`}
            color={_color}
            fill='none'
        >
            <path
                d='M16.4999 8C16.4999 5.51472 14.4851 3.5 11.9999 3.5C9.51457 3.5 7.49985 5.51472 7.49985 8C7.49985 9.56027 8.29393 10.9351 9.5 11.7423M9 21.5H5.42708C4.67372 21.5 4.29704 21.5 4.02522 21.2867C3.81521 21.1219 3.64761 20.7852 3.64273 20.5183C3.63641 20.1728 3.82956 19.9172 4.21585 19.406C5.40282 17.8351 7.03781 16.6336 8.92604 16'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M16.3798 18.8333C16.3798 18.4549 16.4419 18.1225 16.5659 17.8361C16.6899 17.5497 16.8501 17.294 17.0465 17.069C17.2429 16.844 17.4496 16.6344 17.6667 16.44C17.8941 16.2457 18.1059 16.0565 18.3023 15.8724C18.4987 15.6883 18.6589 15.494 18.7829 15.2894C18.907 15.0848 18.969 14.8547 18.969 14.599C18.969 14.1695 18.7933 13.8217 18.4419 13.5558C18.0904 13.2899 17.6202 13.1569 17.031 13.1569C16.4625 13.1569 15.9664 13.2592 15.5426 13.4637C15.1292 13.6683 14.7829 13.9598 14.5039 14.3382L13 13.3717C13.4341 12.7887 14.0026 12.3336 14.7054 12.0063C15.4083 11.6688 16.2403 11.5 17.2016 11.5C17.9561 11.5 18.6176 11.6125 19.186 11.8375C19.7545 12.0523 20.199 12.3694 20.5194 12.7887C20.8398 13.1978 21 13.699 21 14.2922C21 14.7013 20.9328 15.0593 20.7984 15.3661C20.6744 15.6729 20.509 15.944 20.3023 16.1792C20.1059 16.4042 19.8941 16.619 19.6667 16.8236C19.4393 17.0179 19.2222 17.2122 19.0155 17.4066C18.8191 17.6009 18.6589 17.8106 18.5349 18.0356C18.4109 18.2606 18.3488 18.5265 18.3488 18.8333H16.3798ZM17.3721 22.5C17.0103 22.5 16.7106 22.3824 16.4729 22.1471C16.2455 21.9119 16.1318 21.6306 16.1318 21.3033C16.1318 20.9761 16.2455 20.6999 16.4729 20.4749C16.7106 20.2397 17.0103 20.122 17.3721 20.122C17.7339 20.122 18.0284 20.2397 18.2558 20.4749C18.4832 20.6999 18.5969 20.9761 18.5969 21.3033C18.5969 21.6306 18.4832 21.9119 18.2558 22.1471C18.0284 22.3824 17.7339 22.5 17.3721 22.5Z'
                fill={_color}
            />
        </svg>
    )
}
export default UserUnsureIcon
