import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const CrossIcon: React.FC<SvgProps> = ({
    color,
    width = '20',
    height = '20',
    fill
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.backgroundNegative
    const _fill = fill || 'none'

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g clipPath='url(#clip0_21152_43180)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M19.1666 9.99967C19.1666 15.0623 15.0625 19.1663 9.99992 19.1663C4.93731 19.1663 0.833252 15.0623 0.833252 9.99967C0.833252 4.93706 4.93731 0.833008 9.99992 0.833008C15.0625 0.833008 19.1666 4.93706 19.1666 9.99967ZM6.70711 5.29289C6.31658 4.90237 5.68342 4.90237 5.29289 5.29289C4.90237 5.68342 4.90237 6.31658 5.29289 6.70711L8.58579 10L5.29289 13.2929C4.90237 13.6834 4.90237 14.3166 5.29289 14.7071C5.68342 15.0976 6.31658 15.0976 6.70711 14.7071L10 11.4142L13.2929 14.7071C13.6834 15.0976 14.3166 15.0976 14.7071 14.7071C15.0976 14.3166 15.0976 13.6834 14.7071 13.2929L11.4142 10L14.7071 6.70711C15.0976 6.31658 15.0976 5.68342 14.7071 5.29289C14.3166 4.90237 13.6834 4.90237 13.2929 5.29289L10 8.58579L6.70711 5.29289Z'
                    fill={_color}
                />
            </g>
            <defs>
                <clipPath id='clip0_21152_43180'>
                    <rect
                        width='20'
                        height='20'
                        fill={theme.palette.contentPrimary}
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
export default CrossIcon
