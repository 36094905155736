import * as yup from 'yup'

export const BasicFieldsSchema = yup.object().shape({
    name: yup
        .string()
        .trim()
        .required('errors.required')
        .max(200, 'errors.max'),
    description: yup.string().nullable().max(1000, 'errors.max')
})

export const TitleSchema = yup.object().shape({
    name: yup.string().trim().required('errors.required').max(200, 'errors.max')
})
