import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const ReOrderIcon: React.FC<SvgProps> = ({
    color,
    width = '24',
    height = '25',
    fill = 'none'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentTertiary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 24 25'
            fill={fill}
        >
            <line
                x1='16.75'
                y1='4.25'
                x2='16.75'
                y2='21.75'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='square'
                strokeLinejoin='round'
                strokeDasharray='1 4'
            />
            <line
                x1='11.75'
                y1='4.25'
                x2='11.75'
                y2='21.75'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='square'
                strokeLinejoin='round'
                strokeDasharray='1 4'
            />
            <line
                x1='6.75'
                y1='4.25'
                x2='6.75'
                y2='21.75'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='square'
                strokeLinejoin='round'
                strokeDasharray='1 4'
            />
        </svg>
    )
}
export default ReOrderIcon
