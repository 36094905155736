import { CreateArticleContainer } from '@containers'
import { UserPermissionEnum } from '@dts/constants'
import { LearnPageAdmin } from '@pages'

export const getKnowledgeCenterRoutes = (isAdmin: boolean) => [
    {
        path: '',
        component: LearnPageAdmin,
        canUserAccess: isAdmin,
        permissions: [UserPermissionEnum.ArticleView]
    },
    {
        path: ':id',
        component: CreateArticleContainer,
        isHeaderLink: false,
        isSideNav: false,
        canUserAccess: isAdmin
    },
    {
        path: 'new',
        component: CreateArticleContainer,
        isHeaderLink: false,
        isSideNav: false,
        canUserAccess: isAdmin
    }
]
