import { ButtonVariants } from '@constants'
import { Button, styled } from '@mui/material'

export const StyledButton = styled(Button)(({ theme, variant }) => ({
    borderRadius: '8px',
    height: '44px',
    border: 'none',
    gap: 0,
    color:
        variant === ButtonVariants.Contained
            ? theme?.palette?.contentPrimaryInverse
            : theme.palette.backgroundBrandPrimary
}))
