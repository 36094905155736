import React from 'react'
import Highcharts from 'highcharts'
import { Grid } from '@mui/material'
import { Chart } from '@components'
import {
    getInsightLineChartOptions,
    getLineChartOptions
} from '@dts/charting/lib'
import exporting from 'highcharts/modules/exporting'
import offlineExporting from 'highcharts/modules/offline-exporting'
import { CHART_PANEL_TYPE } from '@constants'

// Initialize the exporting and offline-exporting modules
exporting(Highcharts)
offlineExporting(Highcharts)

export const LineChart = (props) => {
    const {
        chartData,
        timelineIntervals,
        chartStyles,
        chartConfig,
        chartRef,
        chartPanel
    } = props || {}

    const _chartData = {
        xAxis: timelineIntervals,
        seriesData: chartData
    }

    // TODO this code might be used later
    // const chartExportingOptions = {
    //     enabled: false,
    //     chartOptions: {
    //         // Specific options for the exported image
    //         xAxis: {
    //             labels: {
    //                 enabled: false // Hide xAxis labels during export
    //             },
    //             title: {
    //                 text: null // Hide xAxis title during export
    //             }
    //         },
    //         yAxis: {
    //             labels: {
    //                 enabled: false // Hide yAxis labels during export
    //             },
    //             title: {
    //                 text: null // Hide yAxis title during export
    //             }
    //         },
    //         legend: {
    //             enabled: true // Hide legend during export
    //         },
    //         title: {
    //             text: null // Optionally, hide the title during export
    //         },
    //         plotOptions: {
    //             series: {
    //                 dataLabels: {
    //                     enabled: false // Optionally, enable data labels during export
    //                 }
    //             }
    //         }
    //     }
    // }
    const chartExportingOptions = {}
    const chartOptions = getLineChartOptions(
        _chartData,
        chartStyles,
        chartExportingOptions,
        chartConfig
    )
    const insightsChartOptions = getInsightLineChartOptions(
        _chartData,
        chartStyles,
        chartExportingOptions,
        chartConfig
    )

    return (
        <Grid>
            <Chart
                highcharts={Highcharts}
                options={
                    chartPanel === CHART_PANEL_TYPE.REPORT
                        ? chartOptions
                        : insightsChartOptions
                }
                ref={chartRef}
            />
        </Grid>
    )
}
