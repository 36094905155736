import React from 'react'
import { Grid, Typography } from '@mui/material'
import { StyledDirectoryLink } from '@containers'
import { FillCheckCircle } from '@assets'

export const ReportSuccess: React.FC<{
    header?: string
    message?: string
    link?: string
}> = ({ header, message, link }) => (
    <Grid container>
        <Grid
            item
            display={'flex'}
            xs={12}
            md={12}
            direction='column'
            alignItems='center'
            gap={1}
        >
            <FillCheckCircle />
            <Typography
                variant='h1'
                sx={{
                    fontWeight: 700
                }}
            >
                {header}
            </Typography>

            <Typography
                variant='h3'
                sx={{
                    fontWeight: 400,
                    textAlign: 'center'
                }}
            >
                {message}
            </Typography>
            {link && (
                <StyledDirectoryLink
                    variant='Body/Body1-medium'
                    sx={{
                        fontWeight: 600
                    }}
                >
                    {link}
                </StyledDirectoryLink>
            )}
        </Grid>
    </Grid>
)
