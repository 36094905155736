import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PictureFrameImg } from '@assets'
import { Grid } from '@mui/material'
import { htmlToText } from 'html-to-text'
import {
    SearchItemContainer,
    StyledSearchTypography,
    StyledTypographyContainer
} from './styles'

export const SearchResultItem: React.FC = ({ dataItem, hasImage }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <SearchItemContainer
            xs={12}
            md={12}
            mt={1}
            item
            onClick={() => {
                if (dataItem && dataItem.routeState) {
                    navigate(
                        `${dataItem.route}?routeId=${dataItem.routeState}`,
                        {
                            state: {
                                // Will be removed later on
                                // routeId: dataItem.routeState,
                                isSearchItem: true
                            }
                        }
                    )
                } else {
                    navigate(dataItem.route, {
                        state: {
                            isSearchItem: true
                        }
                    })
                }
            }}
        >
            <Grid
                item
                md={12}
                xs={12}
                pr={1}
                display={'flex'}
                alignItems={'center'}
            >
                {hasImage && (
                    <Grid item pr={1}>
                        <Grid
                            sx={{
                                width: '100px',
                                height: '80px',
                                padding: '8px'
                            }}
                        >
                            <img
                                src={
                                    dataItem.imageSrc
                                        ? dataItem.imageSrc
                                        : PictureFrameImg
                                }
                                style={{
                                    objectFit: dataItem.imageSrc
                                        ? 'contain'
                                        : 'none'
                                }}
                                height='100%'
                                width='100%'
                            />
                        </Grid>
                    </Grid>
                )}
                <StyledTypographyContainer
                    item
                    container
                    p={1}
                    hasImage={hasImage}
                >
                    <Grid item>
                        <StyledSearchTypography variant='h4' displayedLines={1}>
                            {dataItem?.title}
                        </StyledSearchTypography>
                    </Grid>
                    <Grid item>
                        <StyledSearchTypography
                            variant='body2'
                            fontWeight={'500px'}
                            displayedLines={2}
                        >
                            {htmlToText(dataItem?.description)}
                        </StyledSearchTypography>
                    </Grid>
                </StyledTypographyContainer>
            </Grid>
        </SearchItemContainer>
    )
}
