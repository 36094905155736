export const leanerProfileData = [
    {
        id: '1',
        label: 'general.email',
        value: 'email'
    },
    {
        id: '2',
        label: 'general.firstName',
        value: 'firstName'
    },
    {
        id: '3',
        label: 'general.lastName',
        value: 'lastName'
    },
    {
        id: '4',
        label: 'general.learnerId',
        value: 'learnerId'
    }
]
