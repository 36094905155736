export const DATE_TIME_SETTING = {
  UNITS: {
    DAY: 'day',
    WEEK: 'week',
    HOUR: 'hour',
    MONTH: 'month',
    YEAR: 'year',
    TIME: 'time',
  },
  DATE_FORMATS: {
    DAY: 'MMM D', // e.g., "Aug 14"
    WEEK: 'MMM D', // e.g., "Aug 14"
    HOUR: 'MMM D, ha', // e.g., "Aug 14, 2pm"
    MONTH: 'MMM YYYY', // e.g., "Aug 2024"
    DATE_OF_BIRTH: 'YYYY-MM-DD',
  },
}
