import { gql } from '@apollo/client'

export const UserDetails = (on: string) => gql`
    fragment UserDetails on ${on} {
      id
      firstName
      avatar
      avatarSrc
      status{
        id
        title
      }
      active
      lastName
      email
      userId
      staffType {
        id
        title
        available
        displayOrder
      }
      campuses {
        id
        title
      }
      active
      dateOfBirth
      phoneNumber
      mobileNumber
      address
      cityId
      city {
        id
        title
      }
      stateId
      state {
        id
        title
      }
      zipCode
      roleId
      clientId
      races {
        id
        title
        available
        displayOrder
      }
      genders {
        id
        title
        available
        displayOrder
      }
      interests {
        id
        title
        available
        displayOrder
      }
      customInterests
      roles {
        id
        title
        roleId
        description
        createdAt
        updatedAt
      }
      inviteSent
      userSetting {
        addedToNewVertical
        appLanguage {
          id
          title
          available
          displayOrder
        }
        captionEnabled
        captionLanguage {
          id
          title
          available
          displayOrder
        }
        displayMode {
          id
          title
          available
          displayOrder
        }
        newTopicAssigned
        newlyUploadedVideos
        newsAndEvents
        pauseAll
        reminderEmails
        topicCompleted
        uploadVideoToCloud
      }
      createdAt
      updatedAt
      title
      organization
      notes
      tags
      trainings {
        id
        title
        description
        courses {
          id
          title
          description
          courseTopics {
            topic {
              id
              title
            }
            duration
            remainingDuration
            isAssigned
            progress
          }
          progress
        }
        progress
      }
      portalClients {
        id
        title
        status {
          id
          title
        }
      }
      adminPortalOnlyAccess
    }
`
