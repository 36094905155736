/* eslint-disable complexity */
import React, { useState, useEffect } from 'react'
import {
    ThemeProvider as MuiThemeProvider,
    createTheme
} from '@mui/material/styles'
import { useAuth, useCustomTheme, useLocalStorage } from '@hooks'
import { grey } from '@mui/material/colors'
import { enUS } from '@mui/material/locale'
import { darkColors, lightColors } from '@colors'
import { ThemeContext } from '@context'
import { DisplayModeEnum, ThemeMode } from '@dts/constants'
import { isUndefined } from 'lodash'

const _ThemeProvider: React.FC = ({ children }) => {
    const {
        userInfo: { displayMode },
        appTheme
    } = useAuth()

    const [appColors, setAppColors] = useState(
        appTheme === DisplayModeEnum.DarkMode
            ? darkColors
            : appTheme === DisplayModeEnum.LightMode
            ? lightColors
            : appTheme === DisplayModeEnum.SystemDefault && darkColors
    )
    const [isDarkTheme, setIsDarkTheme] = useState()

    const getSystemTheme = () => {
        if (
            window.matchMedia &&
            window.matchMedia('(prefers-color-scheme: dark)').matches
        ) {
            return DisplayModeEnum.DarkMode
        }
        return DisplayModeEnum.LightMode
    }

    useEffect(() => {
        toggleTheme(appTheme)
    }, [appTheme])

    const toggleTheme = (theme) => {
        if (!isUndefined(theme)) {
            if (theme === DisplayModeEnum.LightMode) {
                setAppColors(lightColors)
                setIsDarkTheme(false)
            } else if (theme === DisplayModeEnum.DarkMode) {
                setAppColors(darkColors)
                setIsDarkTheme(true)
            } else if (theme === DisplayModeEnum.SystemDefault) {
                const sysTheme = getSystemTheme()
                setAppColors(
                    sysTheme === DisplayModeEnum.LightMode
                        ? lightColors
                        : darkColors
                )
                setIsDarkTheme(
                    sysTheme === DisplayModeEnum.LightMode ? false : true
                )
            }
        }
    }

    useEffect(() => {
        if (appTheme === DisplayModeEnum.SystemDefault) {
            const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
            const handleChange = (e) => {
                toggleTheme(DisplayModeEnum.SystemDefault)
            }
            mediaQuery.addEventListener('change', handleChange)

            return () => {
                mediaQuery.removeEventListener('change', handleChange)
            }
        }
    }, [])

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: isDarkTheme ? ThemeMode.Dark : ThemeMode.Light,
                    primary: {
                        main: isDarkTheme
                            ? darkColors.backgroundBrandPrimary
                            : lightColors.backgroundBrandPrimary,
                        contrastText: isDarkTheme
                            ? darkColors.backgroundPrimary
                            : lightColors.backgroundPrimary
                    },
                    secondary: {
                        main: isDarkTheme
                            ? darkColors.backgroundTertiary
                            : lightColors.backgroundTertiary,
                        contrastText: isDarkTheme
                            ? darkColors.contentPrimary
                            : lightColors.contentPrimary
                    },
                    ...appColors
                },
                typography: {
                    fontFamily: 'Roboto ',
                    fontSize: 14,

                    h1: {
                        fontSize: 20,
                        fontWeight: 600,
                        color: isDarkTheme
                            ? darkColors.contentPrimary
                            : lightColors.contentPrimary
                    },
                    h2: {
                        fontSize: 18,
                        fontWeight: 600,
                        color: isDarkTheme
                            ? darkColors.contentPrimary
                            : lightColors.contentPrimary
                    },
                    h3: {
                        fontSize: 16,
                        fontWeight: 600,
                        color: isDarkTheme
                            ? darkColors.contentPrimary
                            : lightColors.contentPrimary
                    },
                    h4: {
                        fontSize: 14,
                        fontWeight: 600,
                        color: isDarkTheme
                            ? darkColors.contentPrimary
                            : lightColors.contentPrimary
                    },
                    h5: {
                        fontSize: 14,
                        color: isDarkTheme
                            ? darkColors.contentPrimary
                            : lightColors.contentPrimary
                    },
                    h6: {
                        fontSize: 10
                    },
                    overline: {
                        color: grey[700],
                        textTransform: 'capitalize'
                    },
                    body1Medium: {
                        lineHeight: '140%',
                        letterSpacing: '0.16px',
                        color: isDarkTheme
                            ? darkColors.contentPrimary
                            : lightColors.contentPrimary,
                        fontSize: '1rem',
                        fontWeight: 500
                    },
                    body2: {
                        lineHeight: 1.3,
                        // color: isDarkTheme
                        //     ? darkColors.contentSecondary
                        //     : lightColors.contentSecondary
                        color: isDarkTheme
                            ? darkColors.contentSecondary
                            : lightColors.contentSecondary
                    },
                    subtitle1: {
                        fontSize: 14,
                        fontWeight: 550,
                        color: isDarkTheme
                            ? darkColors.contentPrimary
                            : lightColors.contentPrimary
                    },
                    subtitle2: {
                        fontSize: 14,
                        fontWeight: 550,
                        //  color: grey[800]
                        color: isDarkTheme
                            ? darkColors.contentPrimary
                            : lightColors.contentPrimary
                    },
                    body3: {
                        color: isDarkTheme
                            ? darkColors.contentSecondary
                            : lightColors.contentSecondary,
                        fontSize: '0.75rem',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '140%'
                    },
                    body3Medium: {
                        color: isDarkTheme
                            ? darkColors.contentSecondary
                            : lightColors.contentSecondary,
                        fontSize: '0.75rem',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '140%'
                    },
                    caption: {
                        color: `${
                            isDarkTheme
                                ? darkColors.contentSecondary
                                : lightColors.contentSecondary
                        } !important`
                    },
                    Label: {
                        color: isDarkTheme
                            ? darkColors.contentPrimary
                            : lightColors.contentPrimary
                    },
                    body2Medium: {
                        fontSize: '0.875rem',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '140%',
                        color: isDarkTheme
                            ? darkColors.neutralsTextGrey
                            : lightColors.neutralsTextGrey
                    },
                    smallText: {
                        fontSize: '0.75rem',
                        fontWeight: 400,
                        lineHeight: 1.66,
                        color: isDarkTheme
                            ? darkColors.neutralsTextGrey
                            : lightColors.neutralsTextGrey
                    },
                    section2: {
                        color: isDarkTheme
                            ? darkColors.contentPrimary
                            : lightColors.contentPrimary
                    },
                    othersNav: {
                        fontSize: 16,
                        fontWeight: 400,
                        color: isDarkTheme
                            ? darkColors.contentPrimary
                            : lightColors.contentPrimary,
                        lineHeight: '120%'
                    }
                },
                components: {
                    MuiCssBaseline: {
                        styleOverrides: {
                            body: {
                                backgroundColor: isDarkTheme
                                    ? darkColors.backgroundPrimary
                                    : lightColors.backgroundPrimary,
                                color: isDarkTheme
                                    ? darkColors.contentPrimary
                                    : lightColors.contentPrimary
                            }
                        }
                    },
                    MuiTypography: {
                        styleOverrides: {
                            root: {
                                // color: '#222'
                                // '&:after': {
                                //     content: '""'
                                // }
                                '&.MuiTypography-body3-medium': {
                                    color: isDarkTheme
                                        ? darkColors.contentSecondary
                                        : lightColors.contentSecondary // Replace with your desired color
                                }
                            },
                            gutterBottom: {
                                // marginBottom: 12,
                            }
                        }
                    },
                    MuiTooltip: {
                        styleOverrides: {
                            popper: {
                                margin: '-2px !important',
                                marginRight: '-1px !important'
                            }
                        }
                    },
                    MuiDataGrid: {
                        styleOverrides: {
                            root: {
                                maxWidth: '100% !important'
                                // '&:after': {
                                //     content: '""'
                                // }
                            },
                            columnHeaderTitle: {
                                fontWeight: 'bold'
                            },
                            overlay: {
                                backgroundColor: isDarkTheme
                                    ? darkColors.backgroundPrimary
                                    : lightColors.backgroundPrimary // Set your desired background color
                            }
                        }
                    },
                    MuiInputBase: {
                        styleOverrides: {
                            root: {
                                // fontSize: '0.94rem',
                                // '&:after': {
                                //     content: '""'
                                // }
                            },
                            sizeSmall: {
                                padding: '6px'
                            },
                            input: {
                                fontSize: '1rem',
                                color: isDarkTheme
                                    ? darkColors.contentPrimary
                                    : lightColors.contentPrimary
                            }
                        }
                    },
                    MuiSelect: {
                        styleOverrides: {
                            select: {
                                color: isDarkTheme
                                    ? darkColors.contentPrimary
                                    : lightColors.contentPrimary
                            },
                            icon: {
                                '&.MuiSelect-icon': {
                                    color: isDarkTheme
                                        ? darkColors.contentSecondary
                                        : lightColors.contentSecondary
                                }
                            }
                        }
                    },
                    MuiMenuItem: {
                        styleOverrides: {
                            root: {
                                color: `${
                                    isDarkTheme
                                        ? darkColors.contentSecondary
                                        : lightColors.contentSecondary
                                } !important`
                            }
                        }
                    },
                    MuiOutlinedInput: {
                        styleOverrides: {
                            root: {
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: isDarkTheme
                                        ? darkColors.borderSecondary
                                        : lightColors.borderSecondary,
                                    borderRadius: '12px'
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: isDarkTheme
                                        ? darkColors.borderSecondary
                                        : lightColors.borderSecondary
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                    {
                                        borderColor: isDarkTheme
                                            ? darkColors.borderSecondary
                                            : lightColors.borderSecondary
                                    },
                                '&.Mui-disabled': {
                                    opacity: 0.5,
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: isDarkTheme
                                            ? darkColors.borderSecondary
                                            : lightColors.borderSecondary // Set your desired border color for disabled input
                                    },
                                    // If you want to change the text color
                                    '& .MuiInputBase-input': {
                                        '-webkit-text-fill-color': isDarkTheme
                                            ? darkColors.contentPrimary
                                            : lightColors.contentPrimary // Change the text color for disabled input
                                    }
                                },
                                '& .MuiSvgIcon-root': {
                                    color: isDarkTheme
                                        ? darkColors.contentSecondary
                                        : lightColors.contentSecondary // Change to your desired icon color
                                }
                            }
                        }
                    },
                    MuiInputLabel: {
                        styleOverrides: {
                            root: {
                                color: isDarkTheme
                                    ? darkColors.contentPrimary
                                    : lightColors.contentPrimary
                            }
                        }
                    },
                    MuiButton: {
                        styleOverrides: {
                            root: {
                                textAlign: 'center',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                letterSpacing: '-0.3px',
                                padding: '15px 24px',
                                textTransform: 'capitalize',
                                gap: '10px',
                                boxShadow: 'none'
                            },
                            sizeSmall: {
                                padding: '1rem 1rem !important',
                                fontSize: '12px !important',
                                textWrap: 'nowrap'
                            },
                            outlined: {
                                borderColor: isDarkTheme
                                    ? darkColors.contentLink
                                    : lightColors.contentLink,
                                backgroundColor: isDarkTheme
                                    ? darkColors.backgroundInfoSubtle
                                    : lightColors.backgroundInfoSubtle,
                                color: isDarkTheme
                                    ? darkColors.contentLink
                                    : lightColors.contentLink,

                                '&:hover': {
                                    backgroundColor: isDarkTheme
                                        ? darkColors.BrandPrimaryContainer
                                        : lightColors.BrandPrimaryContainer
                                }
                            }
                        }
                    },
                    MuiButtonBase: {
                        styleOverrides: {
                            root: {
                                '&.Mui-disabled': {
                                    color: `${
                                        isDarkTheme
                                            ? darkColors.contentPrimaryInverse
                                            : lightColors.contentPrimaryInverse
                                    } !important`,
                                    opacity: '0.26'
                                }
                            }
                        }
                    },
                    MuiModal: {
                        styleOverrides: {
                            root: {
                                '& .MuiPaper-root': {
                                    backgroundColor: isDarkTheme
                                        ? darkColors.backgroundPrimary
                                        : lightColors.backgroundPrimary
                                }
                            }
                        }
                    },
                    MuiDivider: {
                        styleOverrides: {
                            root: {
                                borderColor: isDarkTheme
                                    ? darkColors.borderSecondary
                                    : lightColors.borderSecondary
                            }
                        }
                    },
                    MuiSvgIcon: {
                        styleOverrides: {
                            root: {
                                '&.css-1l6vthu-MuiSvgIcon-root-MuiSelect-icon':
                                    {
                                        color: isDarkTheme
                                            ? darkColors.errorDangerMedium
                                            : lightColors.errorDangerMedium // Replace with your desired color
                                    }
                            }
                        }
                    },
                    MuiFormLabel: {
                        styleOverrides: {
                            root: {
                                color: `${
                                    isDarkTheme
                                        ? darkColors.contentPrimary
                                        : lightColors.contentPrimary
                                } !important`, // Replace with your desired color
                                '&.Mui-disabled': {
                                    color: `${
                                        isDarkTheme
                                            ? darkColors.neutralsTextGrey
                                            : lightColors.neutralsTextGrey
                                    } !important` // Change to your desired disabled text color
                                }
                            }
                        }
                    },
                    MuiFormControlLabel: {
                        styleOverrides: {
                            label: {
                                color: `${
                                    isDarkTheme
                                        ? darkColors.contentPrimary
                                        : lightColors.contentPrimary
                                } !important` // Replace with your desired label color
                            }
                        }
                    },
                    MuiDatePicker: {
                        styleOverrides: {
                            root: {
                                '& .MuiSvgIcon-root': {
                                    color: '#YourContentSecondaryColor' // Change to your desired icon color
                                }
                            }
                        }
                    },
                    MuiRadio: {
                        styleOverrides: {
                            root: {
                                color: isDarkTheme
                                    ? darkColors.borderSecondary
                                    : lightColors.borderSecondary, // Replace with your desired color for both selected and unselected states
                                '&.Mui-checked': {
                                    color: isDarkTheme
                                        ? darkColors.backgroundBrandPrimary
                                        : lightColors.backgroundBrandPrimary // Ensure the same color is applied when selected
                                },
                                '&.Mui-disabled': {
                                    color: isDarkTheme
                                        ? `${darkColors.neutralsTextGrey} !important`
                                        : `${lightColors.neutralsTextGrey} !important`
                                }
                            }
                        }
                    },
                    MuiCheckbox: {
                        styleOverrides: {
                            root: {
                                color: isDarkTheme
                                    ? `${darkColors.borderSecondary}`
                                    : `${lightColors.borderSecondary}`, // Replace with your desired color for both selected and unselected states
                                '&.Mui-checked': {
                                    color: isDarkTheme
                                        ? `${darkColors.backgroundBrandPrimary} !important`
                                        : `${lightColors.backgroundBrandPrimary} !important` // Ensure the same color is applied when selected
                                },

                                '&.Mui-disabled': {
                                    color: isDarkTheme
                                        ? `${darkColors.neutralsTextGrey} !important`
                                        : `${lightColors.neutralsTextGrey} !important`
                                }
                            }
                        }
                    },
                    MuiDrawer: {
                        styleOverrides: {
                            root: {
                                '&.MuiDrawer-root': {
                                    '&.MuiDrawer-docked': {
                                        '& .MuiDrawer-paper': {
                                            height: '100%',
                                            borderRight: `1px solid ${
                                                isDarkTheme
                                                    ? darkColors.borderSecondary
                                                    : lightColors.borderSecondary
                                            }`,
                                            background: `${
                                                isDarkTheme
                                                    ? darkColors.backgroundSecondary
                                                    : lightColors.backgroundSecondary
                                            }`,
                                            boxShadow: `-4px 0px 6px 0px ${
                                                isDarkTheme
                                                    ? darkColors.drawerBoxShadow
                                                    : lightColors.drawerBoxShadow
                                            } inset`
                                        }
                                    }
                                }
                            }
                        }
                    },

                    MuiPaper: {
                        styleOverrides: {
                            root: {
                                background: `${
                                    isDarkTheme
                                        ? darkColors.backgroundSecondary
                                        : lightColors.backgroundSecondary
                                }`
                            }
                        }
                    },
                    MuiBreadcrumbs: {
                        styleOverrides: {
                            li: {
                                color: `${
                                    isDarkTheme
                                        ? darkColors.contentPrimary
                                        : lightColors.contentPrimary
                                } !important`, // Optional text color
                                a: {
                                    color: `${
                                        isDarkTheme
                                            ? darkColors.contentLink
                                            : lightColors.contentLink
                                    } !important` // Optional text color
                                }
                            },
                            separator: {
                                color: `${
                                    isDarkTheme
                                        ? darkColors.contentPrimary
                                        : lightColors.contentPrimary
                                } !important`
                            }
                        }
                    },
                    MuiDataGridPro: {
                        styleOverrides: {
                            root: {
                                // Change color of the dropdown icon in footer
                                '& .MuiSelect-icon': {
                                    color: isDarkTheme
                                        ? darkColors.contentSecondary
                                        : lightColors.contentSecondary
                                },
                                // Change color of the footer icons (e.g., pagination icons)
                                '& .MuiDataGrid-footerContainer .MuiButtonBase-root .MuiSvgIcon-root':
                                    {
                                        color: isDarkTheme
                                            ? darkColors.contentSecondary
                                            : lightColors.contentSecondary
                                    },
                                // Change background color of rows on hover
                                '& .MuiDataGrid-row:hover': {
                                    backgroundColor: isDarkTheme
                                        ? darkColors.BrandPrimaryContainer
                                        : lightColors.BrandPrimaryContainer
                                },
                                // Change background color of column headers
                                '.MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor':
                                    {
                                        backgroundColor: isDarkTheme
                                            ? darkColors.backgroundPrimary
                                            : lightColors.backgroundPrimary
                                    },
                                // Change background color of pinned column headers on the right
                                '.MuiDataGrid-pinnedColumnHeaders.MuiDataGrid-pinnedColumnHeaders--right.MuiDataGrid-withBorderColor':
                                    {
                                        backgroundColor: isDarkTheme
                                            ? darkColors.backgroundPrimary
                                            : lightColors.backgroundPrimary
                                    },
                                // Change background color of batch action cell
                                '.MuiDataGrid-cell.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor':
                                    {
                                        backgroundColor: isDarkTheme
                                            ? darkColors.backgroundPrimary
                                            : lightColors.backgroundPrimary
                                    },
                                // Change fill color of a specific SVG icon (three dot icon)
                                '.css-93sdsc-MuiSvgIcon-root': {
                                    fill: isDarkTheme
                                        ? darkColors.contentSecondary
                                        : lightColors.contentSecondary
                                },
                                // Change fill color of another specific SVG icon (plus icon)
                                '.css-1boubtc-MuiSvgIcon-root': {
                                    fill: isDarkTheme
                                        ? darkColors.backgroundBrandPrimary
                                        : lightColors.backgroundBrandPrimary
                                },
                                // Change background color of nested grid containers
                                '.css-vj1n65-MuiGrid-root': {
                                    backgroundColor: isDarkTheme
                                        ? darkColors.backgroundPrimary
                                        : lightColors.backgroundPrimary
                                },
                                // Change background color of IconButton roots
                                '.css-17zbuz4-MuiButtonBase-root-MuiIconButton-root':
                                    {
                                        backgroundColor: isDarkTheme
                                            ? darkColors.backgroundPrimary
                                            : lightColors.backgroundPrimary
                                    },
                                // Change background color of rows on hover, ensuring !important takes precedence
                                '.MuiDataGrid-row.MuiDataGrid-row--Mui-hovered':
                                    {
                                        backgroundColor: isDarkTheme
                                            ? darkColors.backgroundPrimary
                                            : lightColors.backgroundPrimary
                                    },
                                // Change border color of elements with the `MuiDataGrid-withBorderColor` class
                                '& .MuiDataGrid-withBorderColor': {
                                    borderColor: isDarkTheme
                                        ? darkColors.borderSecondary
                                        : lightColors.borderSecondary
                                },
                                // Set text color for the grid
                                color: isDarkTheme
                                    ? darkColors.contentPrimary
                                    : lightColors.contentPrimary,
                                // Set border color for the grid
                                borderColor: isDarkTheme
                                    ? darkColors.borderSecondary
                                    : lightColors.borderSecondary
                            }
                        }
                    },
                    MuiAutocomplete: {
                        styleOverrides: {
                            noOptions: {
                                color: isDarkTheme
                                    ? darkColors.contentPrimary
                                    : lightColors.contentPrimary // Replace select NoOption text color
                            }
                        }
                    },
                    MuiSkeleton: {
                        styleOverrides: {
                            root: {
                                // this color code add in theme later when
                                backgroundColor: isDarkTheme
                                    ? darkColors.skeletonBackground
                                    : lightColors.skeletonBackground //  Use the same color as in the palette
                            }
                        }
                    },
                    MuiPickersDay: {
                        styleOverrides: {
                            root: {
                                '&.MuiDateRangePickerDay-day': {
                                    color: `${
                                        isDarkTheme
                                            ? darkColors.contentPrimary
                                            : lightColors.contentPrimary
                                    } !important`
                                },

                                button: {
                                    color: `${
                                        isDarkTheme
                                            ? darkColors.contentPrimary
                                            : lightColors.contentPrimary
                                    } !important`
                                },
                                color: `${
                                    isDarkTheme
                                        ? darkColors.contentPrimary
                                        : lightColors.contentPrimary
                                } !important`
                            }
                        }
                    },
                    MuiDateRangeCalendar: {
                        styleOverrides: {
                            root: {
                                '& .css-1boubtc-MuiSvgIcon-root': {
                                    color: `${
                                        isDarkTheme
                                            ? darkColors.contentPrimary
                                            : lightColors.contentPrimary
                                    } !important`
                                }
                            }
                        }
                    },
                    MuiPickersCalendarHeader: {
                        styleOverrides: {
                            label: {
                                color: `${
                                    isDarkTheme
                                        ? darkColors.contentPrimary
                                        : lightColors.contentPrimary
                                } !important` // Change the color of the label
                            },
                            '&.css-kl4qja-MuiSvgIcon-root': {
                                color: `${
                                    isDarkTheme
                                        ? darkColors.contentPrimary
                                        : lightColors.contentPrimary
                                } !important` // Change to your date picker icon left and right  color
                            }
                        }
                    },
                    MuiIconButton: {
                        styleOverrides: {
                            root: {
                                '&.MuiPickersCalendarHeader-switchViewButton': {
                                    color: `${
                                        isDarkTheme
                                            ? darkColors.contentPrimary
                                            : lightColors.contentPrimary
                                    } !important` // Ensure the class is targeted correctly
                                },
                                '&.MuiPickersArrowSwitcher-button': {
                                    color: `${
                                        isDarkTheme
                                            ? darkColors.contentPrimary
                                            : lightColors.contentPrimary
                                    } !important`
                                }
                            }
                        }
                    },
                    MuiStepLabel: {
                        styleOverrides: {
                            label: {
                                '&.Mui-active': {
                                    color: `${
                                        isDarkTheme
                                            ? darkColors.contentPrimary
                                            : lightColors.contentPrimary
                                    } !important`
                                }
                            }
                        }
                    },
                    MuiBottomNavigation: {
                        styleOverrides: {
                            root: {
                                '& hr': {
                                    display: 'none !important' // used to hide extra space for app bar
                                }
                            }
                        }
                    }
                },
                enUS
            }),
        [isDarkTheme, appColors]
    )

    return (
        <ThemeContext.Provider value={{ toggleTheme, theme, isDarkTheme }}>
            {children}
        </ThemeContext.Provider>
    )
}

function ThemeProviderWrapper({ children }) {
    const { theme } = useCustomTheme()

    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}

export const ThemeProvider = ({ children }) => (
    <_ThemeProvider>
        <ThemeProviderWrapper>{children}</ThemeProviderWrapper>
    </_ThemeProvider>
)
