import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFieldArray, useFormContext } from 'react-hook-form'
import {
    SearchableTags,
    BaseContainer,
    ComponentContainer,
    ParentContainer,
    TypographyContainer
} from '@components'
import { Typography } from '@mui/material'
import { SearchableCoursesList } from '@containers'
import { useAuth } from '@hooks'
import { GET_CLIENT_COURSES } from '@dts/graphql'
import { UserPermissionEnum } from '@dts/constants'

export const TrainingCourses: React.FC = () => {
    const { t } = useTranslation()
    const { getValues } = useFormContext()
    const {
        userInfo: { clientId }
    } = useAuth()

    return (
        <ParentContainer container>
            <Typography variant='h1'>{getValues('name')}</Typography>
            <BaseContainer item>
                <TypographyContainer item md={2.5}>
                    <Typography variant='h3'>{t('general.courses')}</Typography>
                    <Typography variant='body2Medium'>
                        {t('trainings.chooseCoursesAndTopics')}
                    </Typography>
                </TypographyContainer>
                <ComponentContainer item md={9.5}>
                    <SearchableCoursesList
                        name='courses'
                        query={GET_CLIENT_COURSES}
                        queryName={'getClientCourses'}
                        queryVariables={{ clientId }}
                        courseIdAccessor={'tenantCourseId'}
                        preventNestedListUpdate={true}
                        permissions={[UserPermissionEnum.TrainingsCreateModify]}
                    />
                </ComponentContainer>
            </BaseContainer>
            <BaseContainer item>
                <TypographyContainer item md={2.5}>
                    <Typography variant='h3'>{`${t('general.tags')} (${t(
                        'general.optional'
                    )})`}</Typography>
                    <Typography variant='body2Medium'>
                        {t('trainings.chooseTags')}
                    </Typography>
                </TypographyContainer>

                <ComponentContainer item md={9.5}>
                    <SearchableTags
                        name='videoTags'
                        customDataName='tags'
                        width={'500px'}
                    />
                </ComponentContainer>
            </BaseContainer>
        </ParentContainer>
    )
}
