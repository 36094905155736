import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import { useAuth, useGlobal } from '@hooks'
import { StyledAnchorTag, StyledGridAccessDenied } from '../styles'

export const AccessDenied: React.FC = () => {
    const { t } = useTranslation()
    const { logout } = useAuth()
    const { isInsideLayout, navigationHistory } = useGlobal()
    const navigate = useNavigate()

    return (
        <StyledGridAccessDenied container p={1} gap={2}>
            <Grid item>
                <Typography variant='h1'>
                    {t('general.accessDenied')}
                </Typography>
            </Grid>

            <Grid item>
                <Typography variant='body2' sx={{ whiteSpace: 'pre-line' }}>
                    {t('accessDenied.permissionMessage')}
                </Typography>
            </Grid>

            <Grid item>
                <StyledAnchorTag
                    onClick={() => {
                        if (isInsideLayout) {
                            if (navigationHistory?.length <= 1) {
                                navigate('/')
                            }
                            navigate(-1)
                        } else {
                            logout()
                        }
                    }}
                >
                    {isInsideLayout
                        ? t('accessDenied.redirectPrevious')
                        : t('userMenu.logout')}
                </StyledAnchorTag>
            </Grid>
        </StyledGridAccessDenied>
    )
}
