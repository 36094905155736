import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_INSIGHT_VIDEOS = gql`
  ${MetaFragment('InsightVideosResponseDto')}
  ${MessagesFragment('InsightVideosResponseDto')}
  query getInsightVideos($args: GetInsightVideoArgumentsDto!) {
    getInsightVideos(args: $args) {
      ...MetaFragment
      ...MessagesFragment
      data {
        topViewed {
          id
          title
          description
          coverImage
          coverImageSrc
          topicsData {
            id
            title
            available
            displayOrder
          }
          duration
          createdAt
          viewCount
          urlSrc
          captionFileSrc
        }
        latestVideos {
          id
          title
          description
          coverImage
          coverImageSrc
          duration
          createdAt
          viewCount
          urlSrc
          captionFileSrc
        }
      }
    }
  }
`
