import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, CampusFields } from '../../fragments'

export const GET_CAMPUSES = gql`
  ${MessagesFragment('CampusesResponseDto')}
  ${MetaFragment('CampusesResponseDto')}
  ${CampusFields('CampusDto')}
  query getCampuses($clientId: String!) {
    getCampuses(clientId: $clientId) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...CampusFields
      }
    }
  }
`
