import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ADD_INFO_TO_USER, GET_USER, GET_USER_BY_ID } from '@dts/graphql'
import { useFormFeedback, usePage } from '@hooks'
import { SAVE_USER } from '@dts/graphql/lib'
import { AdminProfileSchema } from '@dts/client-utils'
import { setUserData } from '@cacheql'
import { ClientAdminProfileForm } from './client-admin-profile'
import { UserPermissionEnum } from '@dts/constants'

export const ModifyClientAdminContainer: React.FC = ({ userId }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { id: clientId, adminId: paramsId } = useParams()

    const adminId = userId ?? paramsId
    const isCurrentUser = setUserData()?.id === adminId

    const [isUserActive, setIsUserActive] = useState(true)

    const { methods, save } = useFormFeedback({
        permissions: [UserPermissionEnum.ClientCreateModify],
        initialValues: {
            email: null,
            firstName: null,
            lastName: null,
            officeNumber: null,
            mobileNumber: null,
            stateId: null,
            zipCode: null,
            roleId: null,
            active: true,
            dateOfBirth: null,
            title: null,
            notes: null,
            campuses: []
        },
        mapFormToMutationInput: (
            {
                firstName,
                lastName,
                email,
                phoneNumber,
                mobileNumber,
                stateId,
                zipCode,
                roleId,
                dateOfBirth,
                notes,
                title,
                active,
                campuses
            },
            extraData
        ) => ({
            input: {
                id: isCurrentUser ? undefined : adminId ? adminId : undefined,
                firstName,
                lastName,
                mobileNumber,
                phoneNumber,
                stateId,
                zipCode,
                dateOfBirth,
                ...(clientId &&
                    !isCurrentUser && {
                        campuses,
                        sendInvite: extraData?.sendInvite,
                        clientId,
                        email,
                        roleId,
                        notes,
                        title,
                        active
                    })
            }
        }),
        mutation: isCurrentUser ? ADD_INFO_TO_USER : SAVE_USER,
        mutationName: isCurrentUser ? 'addInformationToUser' : 'saveUser',
        isCustomMutationInput: true,
        onMutationSuccess: (response) => {
            const user = response?.data
            setUserData({
                ...setUserData(),
                avatarSrc: user?.avatarSrc
            })

            if (isCurrentUser && location?.pathname?.includes('my-account')) {
                navigate('/my-account')
            } else if (clientId) {
                navigate(`/clients/${clientId}/users`)
            }
        },
        ...(adminId
            ? {
                  query: isCurrentUser ? GET_USER : GET_USER_BY_ID,
                  queryName: isCurrentUser ? 'getUser' : 'getUserById',
                  retrieveOnMount: true,
                  queryVariables: { id: adminId, clientId },
                  mapQueryResultToForm: ({
                      id,
                      email,
                      firstName,
                      lastName,
                      phoneNumber,
                      mobileNumber,
                      stateId,
                      zipCode,
                      dateOfBirth,
                      roles,
                      title,
                      notes,
                      campuses,
                      active
                  }) => ({
                      id: id ? id : undefined,
                      firstName,
                      lastName,
                      email,
                      phoneNumber,
                      mobileNumber,
                      stateId,
                      zipCode,
                      roleId: roles?.[0]?.id,
                      title,
                      notes,
                      dateOfBirth,
                      active,
                      campuses: campuses?.map((campus) => campus?.id)
                  })
              }
            : undefined),
        yupResolverSchema: AdminProfileSchema
    })

    const tertiaryButtonConfig = !isCurrentUser && {
        tertiaryButtonTitle: t('general.save'),
        tertiaryButtonAction: methods.handleSubmit(() =>
            save({ sendInvite: false })
        )
    }

    const secondaryButtonConfig = !isCurrentUser && {
        secondaryButtonTitle: t('general.cancel'),
        secondaryButtonAction: () => navigate(`/clients/${clientId}/users`)
    }

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: ClientAdminProfileForm,
            componentProps: { isCurrentUser, clientId, setIsUserActive },
            hasHeader: true,
            preventMargin: true,
            showBreadcrumbs: true,
            fetchEntityVariables: clientId
                ? { id: clientId, type: 'client' }
                : undefined,
            pageHeaderConfig: {
                disablePrimaryButton: () => !isUserActive,
                headerText: adminId ? t('user.edit') : t('user.create'),
                primaryButtonTitle: isCurrentUser
                    ? t('general.save')
                    : t('editAdmin.saveAndSendInvite'),
                primaryButtonAction: () =>
                    methods.handleSubmit(() =>
                        save({ sendInvite: isCurrentUser ? undefined : true })
                    )(),
                ...secondaryButtonConfig,
                ...tertiaryButtonConfig,
                permissions: [UserPermissionEnum.ClientCreateModify]
            }
        }
    })

    return <>{page}</>
}
