import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const ARCHIVE_CONTENT_CREATOR = gql`
  ${MetaFragment('GetUserResponseDto')}
  ${MessagesFragment('GetUserResponseDto')}
  mutation updateContentCreator($input: UpdateUserInputDto!) {
    updateContentCreator(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        id
        firstName
        email
      }
    }
  }
`
