import { gql } from '@apollo/client'

export const AdminTopics = (on: string) => gql`
    fragment AdminTopics on ${on} {
        createdAt
        description
        id
        isArchived
        title
        updatedAt
        coursesCount
        videosCount
        surveys {
            id
            title
        }
    }
`
