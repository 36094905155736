import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Grid, Typography } from '@mui/material'
import { ClockIcon } from '@assets'
import { useCustomTheme } from '@hooks'
import { ActivityFeedProps } from './types'
import { Container, InfoContainer, RightContainer, StyledBox } from './styles'

export const ActivityFeed: FC<ActivityFeedProps> = (props) => {
    const { activityFeedData } = props || {}
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    return (
        <StyledBox component='section'>
            <Typography variant='h4' fontSize={17} marginBottom={2}>
                {t('general.activityFeed')}
            </Typography>
            {activityFeedData?.map((item, index) => (
                <>
                    <Container py={2}>
                        <Grid>
                            <ClockIcon />
                        </Grid>
                        <RightContainer>
                            <InfoContainer>
                                <Typography
                                    variant='body2'
                                    color={theme.palette.contentPrimary}
                                    fontWeight={500}
                                >
                                    {item?.title}
                                </Typography>
                                <Typography
                                    variant='body2'
                                    fontSize={12}
                                    color={theme.palette.contentSecondary}
                                    mt={1}
                                >
                                    {item?.date}
                                </Typography>
                            </InfoContainer>
                        </RightContainer>
                    </Container>
                    {activityFeedData?.length - 1 !== index && <Divider />}
                </>
            ))}
        </StyledBox>
    )
}
