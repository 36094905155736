import { gql } from '@apollo/client'

export const ResourcesFields = (on: string) => gql`
  fragment ResourcesFields on ${on} {
    displayOrder
    id
    title
    isDefault
   resources {
      id
      title
      displayOrder
      description
      isConfidential
      isNationalResource
      isAlwaysAvailable
      address
      email
      phoneNumberOne
      phoneNumberTwo
      phoneNumberThree
      phoneNumberOneDescription
      phoneNumberTwoDescription
      phoneNumberThreeDescription
      websiteUrl
      websiteDisplayText
      onlineChatUrl
      onlineChatDisplayText
      stateId
      cityId
      zipCode
      campus{
      id 
      title 
      }
    }
  }
`
