import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { Divider, Typography, Grid } from '@mui/material'
import {
    BaseContainer,
    ParentContainer,
    TypographyContainer,
    StyledChip,
    ReviewButton
} from '@components'
import { isMediumScreen } from '@utils'

import { StyledBox } from '@components/Panel/styles'
import { MapValuesIcon, MatchFieldsIcon, PencilIcon } from '@assets'
import { LearnerAdditionType } from '@constants'
import { isEmpty } from 'lodash'
import { useCustomTheme } from '@hooks'

interface CourseReviewProps {
    handleStepJump?: (step: number) => void
}

export const ListReview: React.FC<CourseReviewProps> = ({ handleStepJump }) => {
    const { theme } = useCustomTheme()
    const { t } = useTranslation()
    const { getValues, control } = useFormContext()
    const courseCount = useWatch({
        control,
        name: 'courseCount'
    })
    const videoCount = useWatch({
        control,
        name: 'videoCount'
    })
    const addedCourses = useWatch({
        control,
        name: 'addedCourses'
    })
    const addedVideos = useWatch({
        control,
        name: 'addedVideos'
    })

    const courses = useWatch({
        control,
        name: 'courses'
    })
    const videos = useWatch({
        control,
        name: 'videos'
    })

    const mappedFieldsCount = getValues('fieldMappingArray')?.filter(
        (mappedField) => !isEmpty(mappedField?.hubbubField)
    ).length

    const mappedValuesCount = getValues('fieldMappingArray')?.filter(
        (mappedField) => mappedField?.mapFieldValue
    ).length

    const reviewSteps = [
        {
            header: t('general.description'),
            details: () => (
                <Typography variant='body2Medium'>
                    {getValues('description')}
                </Typography>
            ),
            editButtonText: `${t('general.description')}`,
            stepNumber: 0
        },

        {
            header: t('general.learners'),
            details: () => (
                <Typography variant='body2Medium'>
                    {t('learnerList.learnerReview')}
                </Typography>
            ),
            editButtonText: `${t('general.learners')}`,
            stepNumber: 1
        },
        {
            header: t('general.content'),
            details: () =>
                getValues('addLearnerType') === LearnerAdditionType.Upload ? (
                    <>
                        <Typography variant='body2Medium'>
                            {t('learnerList.addedByUpload')}
                        </Typography>
                        <StyledBox>
                            <StyledChip
                                label={getValues('fileUploader')}
                                key={getValues('fileUploader')}
                                sx={{ mb: 1 }}
                            />
                        </StyledBox>
                    </>
                ) : (
                    <>
                        <Typography variant='body2Medium'>
                            {t('learnerList.addedByCopyPaste')}
                        </Typography>
                    </>
                ),
            editButtonText: `${t('general.content')}`,
            stepNumber: 2
        },
        {
            header: t('learnerList.configurations'),
            details: () => (
                <>
                    {getValues('tags')?.length ? (
                        <Typography variant='body2Medium'>
                            {t('courses.tags.caption')}
                        </Typography>
                    ) : (
                        <></>
                    )}
                    <StyledBox>
                        {getValues('tags')?.map((tag) => (
                            <StyledChip label={tag} key={tag} sx={{ mb: 1 }} />
                        ))}
                    </StyledBox>
                </>
            ),
            editButtonText: `${t('learnerList.configurations')}`,
            stepNumber: 3
        },
        {
            header: t('learnerList.mapFields'),
            details: () => (
                <>
                    <Typography variant='body2Medium'>
                        {t('learnerList.mapFieldsReview')}
                    </Typography>
                    <Grid display={'flex'} alignItems={'center'}>
                        <MatchFieldsIcon />
                        <Typography variant='body2Medium' ml={1}>
                            {[
                                mappedFieldsCount,
                                mappedFieldsCount === 1
                                    ? t('learnerList.fieldMapped')
                                    : t('learnerList.fieldsMapped')
                            ].join(' ')}
                        </Typography>
                    </Grid>
                </>
            ),
            editButtonText: [t('learnerList.mapFields')].join(' '),
            stepNumber: 4
        },
        {
            header: t('learnerList.mapValues'),
            details: () => (
                <>
                    <Typography variant='body2Medium'>
                        {t('learnerList.mapValuesReview')}
                    </Typography>
                    <Grid display={'flex'} alignItems={'center'}>
                        <MapValuesIcon />
                        <Typography variant='body2Medium' ml={1}>
                            {[
                                mappedValuesCount,
                                mappedValuesCount === 1
                                    ? t('learnerList.fieldValueMapped')
                                    : t('learnerList.fieldValuesMapped')
                            ].join(' ')}
                        </Typography>
                    </Grid>
                </>
            ),
            editButtonText: [t('learnerList.mapValues')].join(' '),
            stepNumber: 5
        }
    ]
    return (
        <ParentContainer container>
            <Typography variant='h1'>{`${t('general.review')} ${t(
                'general.learnerList'
            )}`}</Typography>
            {reviewSteps?.map((step) => (
                <>
                    <BaseContainer item>
                        <TypographyContainer item md={4}>
                            <Typography variant='h3'>{step?.header}</Typography>
                            {<step.details />}
                        </TypographyContainer>
                        <Grid
                            item
                            md={4}
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                marginLeft: !isMediumScreen() ? '40px' : '0px',
                                gap: '10px'
                            }}
                        >
                            <ReviewButton
                                onClick={handleStepJump(step?.stepNumber)}
                                startIcon={
                                    <PencilIcon
                                        color={theme.palette.primary.main}
                                    />
                                }
                            >
                                {step?.editButtonText}
                            </ReviewButton>
                        </Grid>
                    </BaseContainer>
                    <Divider />
                </>
            ))}
        </ParentContainer>
    )
}
