import { REGEX } from '../../utils'
import * as yup from 'yup'

export const CreateCampusSchema = yup.object().shape({
  title: yup
    .string()
    .required('errors.required')
    .nullable()
    .matches(REGEX.clientTypes, 'Please provide a valid name')
    .matches(/[^\s*]/g, 'Please provide a valid name')
    .max(60, 'campus.error.name'),
  campusId: yup
    .string()
    .required('errors.required')
    .nullable()
    .min(5, 'campus.error.min.id')
    .matches(REGEX.alphaNumeric, 'campus.error.matches.id'),
  email: yup
    .string()
    .required('errors.required')
    .nullable()
    .email('Please provide a valid email')
    .max(255, 'campus.error.max.email'),
  phone: yup
    .string()
    .required('errors.required')
    .nullable()
    .test('phone', 'Add at least 10 digits', (value) => {
      const val = value?.replace(/[^0-9]/g, '')
      return val?.length === 10
    }),
  address: yup
    .string()
    .required('errors.required')
    .nullable()
    .max(255, 'campus.error.max.address'),
  state: yup.string().required('errors.required').nullable(),
  city: yup.string().required('errors.required').nullable(),
  zipCode: yup
    .string()
    .max(5, 'campus.error.max.zipCode')
    .required('errors.required')
    .nullable(),
})
