import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, VerticalFields } from '../../fragments'

export const GET_VERTICAL = gql`
  ${MessagesFragment('VerticalResponseDto')}
  ${MetaFragment('VerticalResponseDto')}
  ${VerticalFields('VerticalDto')}
  query getVertical($id: String!) {
    getVertical(id: $id) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...VerticalFields
      }
    }
  }
`
