import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { CreateCampusSchema, formatPhoneNumber } from '@dts/client-utils'
import { GET_CAMPUS, SAVE_CAMPUS } from '@dts/graphql'
import { useFormFeedback, usePage } from '@hooks'
import { isEmpty } from 'lodash'
import { Campus } from './types'
import { EditCampusForm } from './edit-campus-form'

export const EditCampusContainer: React.FC = () => {
    const { t } = useTranslation()
    const { id: clientId, campusId } = useParams()
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const [editMode] = useState(!isEmpty(campusId))

    const mapFormToMutationInput = (form: Campus) => ({
        id: editMode ? campusId : undefined,
        clientId,
        title: form.title,
        campusId: form.campusId,
        active: form.active,
        email: form.email,
        phone: formatPhoneNumber(form.phone),
        address: form.address,
        stateId: form.state,
        cityId: form.city,
        zipCode: form.zipCode,
        logo: form?.fileUploadKey
    })

    const mapQueryResultToForm = (campusData: Campus) => ({
        id: editMode ? campusId : undefined,
        title: campusData?.title,
        campusId: campusData?.campusId,
        active: campusData?.active,
        email: campusData?.email,
        phone: formatPhoneNumber(campusData?.phone, true),
        address: campusData?.address,
        state: campusData?.state?.id,
        city: campusData?.city?.id,
        zipCode: campusData?.zipCode,
        logo: campusData?.logoSrc
    })

    const { methods, save, mutationLoading, queryLoading } = useFormFeedback({
        initialValues: {
            title: '',
            campusId: '',
            active: true,
            email: '',
            phone: '',
            address: '',
            state: '',
            city: '',
            zipCode: ''
        },
        mapFormToMutationInput,
        mutation: SAVE_CAMPUS,
        mutationName: 'saveCampus',
        onMutationSuccess: () => {
            navigate(`/clients/${clientId}/campuses`)
        },
        ...(campusId
            ? {
                  query: GET_CAMPUS,
                  queryName: 'getCampus',
                  retrieveOnMount: true,
                  queryVariables: {
                      campusId,
                      clientId
                  },
                  mapQueryResultToForm
              }
            : undefined),
        yupResolverSchema: CreateCampusSchema
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: EditCampusForm,
            hasHeader: true,
            preventMargin: true,
            showBreadcrumbs: true,
            fetchEntityVariables: clientId
                ? { id: clientId, type: 'client' }
                : undefined,
            pageHeaderConfig: {
                headerText: t('clients.newCampus'),
                primaryButtonTitle: t('general.save'),
                primaryButtonAction: () => {
                    methods.handleSubmit(() => save())()
                },
                secondaryButtonTitle: t('general.cancel'),
                secondaryButtonAction: () =>
                    navigate(`/clients/${clientId}/campuses`)
            }
        }
    })

    return <>{page}</>
}
