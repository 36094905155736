import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    AppBar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Toolbar
} from '@mui/material'
import { debounce } from 'lodash'
import { CloseIcon } from '@assets'
import { ButtonVariant } from '@types'
import { ButtonVariants, KeyboardShortcut } from '@constants'
import { useCustomTheme, useLoader } from '@hooks'
import { Loader } from '@components'
import { onKeyDown } from '@utils'
import { StyledDialogActions, StyledDialogTitle } from './styles'
import { CustomDialogActions } from './dialog-actions'

export interface ModalProps {
    title?: React.FC | string
    Component?: React.Element
    message?: string
    maxWidth?: string
    onClose: VoidFunction
    open: boolean
    submitting?: boolean
    primaryButtonConfig?: {
        text: string
        variant?: ButtonVariant
        onConfirm: VoidFunction
    }
    secondaryButtonConfig?: {
        text: string
        variant?: ButtonVariant
        onConfirm: VoidFunction
    }
    hasCancelButton?: boolean
    fullScreen?: boolean
    hasFeedbackDialog?: boolean
    showLoader?: boolean
    hideCloseIcon?: boolean
    hideDialogActions?: boolean
    dialogContentPadding?: string
}

export const Modal: React.FC<ModalProps> = ({
    title,
    Component,
    message,
    onClose,
    primaryButtonConfig,
    secondaryButtonConfig,
    open,
    maxWidth,
    submitting,
    hasCancelButton,
    fullScreen,
    hasFeedbackDialog,
    hideCloseIcon = false,
    hideDialogActions = false,
    dialogContentPadding,
    showLoader
}) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const { loading } = useLoader()

    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        open && setDisabled(false)
    }, [disabled, open])

    const dialogActionsProps = {
        hasFeedbackDialog,
        hasCancelButton,
        onClose,
        secondaryButtonConfig,
        primaryButtonConfig,
        submitting,
        disabled,
        setDisabled
    }
    React.useEffect(() => {
        // advancement towards hook yet to be done after custom key combination for saving form
        // this useEffect is for submitting form on CTRL + ENTER Key for ADA Compliance.
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === KeyboardShortcut.Enter) {
                primaryButtonConfig?.onConfirm?.()
            }
        }

        // Add event listener when the component mounts
        !(submitting || disabled) &&
            document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [primaryButtonConfig])

    return (
        <Dialog
            maxWidth={maxWidth || 'md'}
            fullWidth
            fullScreen={fullScreen}
            open={open}
            aria-labelledby='dialog-title'
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    onClose()
                }
            }}
            BackdropProps={{ onClick: (event) => event.stopPropagation() }}
        >
            {
                <>
                    {!hasFeedbackDialog ? (
                        <DialogTitle
                            id='dialog-title'
                            sx={{ padding: '16px 24px' }}
                            minHeight={'60px'}
                        >
                            <Box
                                sx={{
                                    maxWidth: '28rem'
                                }}
                            >
                                <StyledDialogTitle variant='h2'>
                                    {title}
                                </StyledDialogTitle>
                            </Box>
                            {!hideCloseIcon && onClose ? (
                                <IconButton
                                    aria-label='close modal'
                                    onClick={onClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            ) : (
                                <></>
                            )}
                        </DialogTitle>
                    ) : (
                        <></>
                    )}
                </>
            }
            <Divider />

            <Grid sx={{ position: 'relative' }}>
                {loading && <Loader />}
                <DialogContent
                    sx={{
                        height: fullScreen ? '100vh' : 'auto',
                        padding: dialogContentPadding
                    }}
                >
                    {Component ?? message}
                </DialogContent>

                {!hideDialogActions && (
                    <CustomDialogActions {...dialogActionsProps} />
                )}
            </Grid>
        </Dialog>
    )
}
