import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const PLOT_CHART = gql`
  ${MessagesFragment('PlotChartResponseDto')}
  ${MetaFragment('PlotChartResponseDto')}
  query plotChart($input: PlotChartInputDto!) {
    plotChart(input: $input) {
      ...MessagesFragment
      ...MetaFragment
      data {
        reportMetricId
        reportMetricTitle
        time
        value
        age
        interest {
          id
          title
        }
        itemId
        itemTitle
        itemCount
        metricValueTypeId
        reportMetricTypeId
        aggregationTypeId
      }
    }
  }
`
