import { Grid } from '@mui/material'
import styled from '@emotion/styled'

type Props = {
    dragOver?: boolean
    preview?: boolean
    hasError?: boolean
}

export const UploadBox = styled(Grid)<Props>(
    ({ theme, dragOver, hasError, preview }) => ({
        background: theme.palette.backgroundInfoSubtle,
        borderRadius: '8px',
        border: `${
            dragOver
                ? `2px dashed ${theme.palette.borderSecondary}`
                : `1px dashed ${
                      hasError
                          ? theme.palette.contentNegative
                          : theme.palette.borderSecondary
                  }`
        }`,
        '& > div': {
            height: preview ? '16.4rem' : '150px',
            '@media (max-width: 1081px)': {
                height: preview ? '20rem !important' : '210px',
                '& > button': {
                    fontSize: '10px',
                    padding: '0'
                }
            }
        }
    })
)
