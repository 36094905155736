import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const DELETE_PORTAL_CLIENT = gql`
  ${MessagesFragment('PortalUserResponseDto')}
  ${MetaFragment('PortalUserResponseDto')}
  mutation deletePortalClient($clientId: String!, $userId: String) {
    deletePortalClient(clientId: $clientId, userId: $userId) {
      ...MessagesFragment
      ...MetaFragment
      data
    }
  }
`
