export const activityFeedData = [
    {
        id: 1,
        title: 'Started Physical harassment course',
        date: '10/10/23 at 4:00pm'
    },
    {
        id: 2,
        title: 'Completed topic “Consent”',
        date: '10/10/23 at 4:00pm'
    },
    {
        id: 3,
        title: 'Quiz “Title of the quiz” has been answered',
        date: '010/10/23 at 4:00pm'
    }
]
