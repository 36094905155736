import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useCustomTheme, useDirectory } from '@hooks'
import {
    DELETE_TRAINING,
    DUPLICATE_TRAINING,
    GET_TRAINING,
    GET_TRAININGS,
    UPDATE_TRAINING
} from '@dts/graphql'
import { t } from 'i18next'
import { formatDate } from '@dts/client-utils'
import { Grid } from '@mui/material'
import { DetailedDirectoryLink, StyledChip, TextWithTooltip } from '@components'
import { EditRecordIcon, PlusIcon, UsersIcon } from '@assets'
import { startCase } from 'lodash'
import { UserPermissionEnum } from '@dts/constants'

export const ManageTrainings = ({ status }: { status: string }) => {
    const navigate = useNavigate()
    const { theme } = useCustomTheme()

    const refetchDirectory = () => {
        retrieve?.({
            variables: {
                options: queryOptions
            }
        })
    }

    const { directory, retrieve, queryOptions } = useDirectory({
        directoryConfig: {
            id: 'manageTraining',
            config: {
                customActions: [
                    {
                        icon: <PlusIcon />,
                        showInMenu: false,
                        ariaLabel: [
                            t('general.edit'),
                            t('general.course')
                        ].join(' '),
                        action: (params) => {
                            navigate(`/trainings/${params?.id}/edit-courses`)
                        },
                        permissions: [UserPermissionEnum.TrainingsCreateModify]
                    },
                    {
                        icon: (
                            <EditRecordIcon
                                color={theme.palette.contentSecondary}
                            />
                        ),
                        label: t('general.edit'),
                        showInMenu: true,
                        action: (params) =>
                            navigate(`/trainings/${params?.id}`),

                        permissions: [UserPermissionEnum.TrainingsCreateModify]
                    }
                ],
                genericActions: {
                    duplicateConfig: {
                        name: t('general.training'),
                        mutation: DUPLICATE_TRAINING,
                        mutationName: 'duplicateTraining',
                        onMutationSuccess: () => {
                            refetchDirectory?.()
                        },
                        query: GET_TRAINING,
                        queryName: 'getTraining',
                        permissions: [UserPermissionEnum.TrainingsCreateModify]
                    },
                    archiveConfig: {
                        name: t('general.training'),
                        mutation: UPDATE_TRAINING,
                        mutationName: 'updateTraining',
                        onMutationSuccess: () => {
                            refetchDirectory?.()
                            navigate('/trainings/archived')
                        },
                        hide: (params) =>
                            params?.row?.isArchived || params?.row?.isDraft,
                        permissions: [UserPermissionEnum.TrainingsCreateModify]
                    },
                    restoreConfig: {
                        name: t('general.training'),
                        mutation: UPDATE_TRAINING,
                        mutationName: 'updateTraining',
                        onMutationSuccess: () => {
                            refetchDirectory?.()
                            navigate('/trainings/all')
                        },
                        hide: (params) => !params?.row?.isArchived,
                        permissions: [UserPermissionEnum.TrainingsCreateModify]
                    },
                    deletionConfig: {
                        name: t('general.training'),
                        mutation: DELETE_TRAINING,
                        mutationName: 'deleteTraining',
                        onMutationSuccess: () => {
                            refetchDirectory?.()
                        },
                        hide: (params) => !params?.row?.isArchived,
                        permissions: [UserPermissionEnum.TrainingsDelete]
                    }
                },
                columns: [
                    {
                        field: 'title',
                        headerName: t('sideNavItems.trainings'),
                        minWidth: 150,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={
                                    <DetailedDirectoryLink
                                        title={params?.value}
                                        tabIndex={params?.tabIndex}
                                        description={params?.row?.description}
                                        isDraft={params?.row?.isDraft}
                                        onClick={() => {
                                            navigate(`/trainings/${params?.id}`)
                                        }}
                                        additionalInfo={[
                                            {
                                                icon: UsersIcon,
                                                text: `${
                                                    params?.row?.learnersCount
                                                } ${t('general.learners')}`
                                            }
                                        ]}
                                    />
                                }
                                toolTip={params?.value}
                                tabIndex={params?.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'courses',
                        headerName: t('general.courses'),
                        minWidth: 150,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={
                                    <Grid item py={1}>
                                        {params?.value?.map(({ id, title }) => (
                                            <StyledChip
                                                label={title}
                                                key={id}
                                                sx={{ mb: 1 }}
                                            />
                                        ))}
                                    </Grid>
                                }
                                toolTip={params?.value
                                    ?.map(({ title }) => title)
                                    ?.join(', ')}
                                tabIndex={params?.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'dueDate',
                        headerName: t('general.dueDate'),
                        minWidth: 150,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={
                                    params?.value
                                        ? formatDate(params?.value)
                                        : '--'
                                }
                                tabIndex={params?.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'updatedAt',
                        headerName: t('general.dateModified'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={formatDate(params?.value)}
                                tabIndex={params?.tabIndex}
                            />
                        )
                    }
                ],
                hideFooter: false,
                pagination: true
            },
            enableSearch: true,
            toolbarConfig: {
                header: startCase(status),
                addNewButtonConfig: {
                    label: `${t('general.create')} ${t(
                        'sideNavItems.trainings'
                    )}`,
                    action: () => navigate('/trainings/new'),
                    permissions: [UserPermissionEnum.TrainingsCreateModify]
                }
            },
            query: GET_TRAININGS,
            queryName: 'getTrainings',
            queryVariables: {
                args: {
                    tab: status
                }
            }
        }
    })

    return directory
}
