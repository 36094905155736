import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { GET_COURSES, GET_VERTICAL, UPDATE_VERTICAL } from '@dts/graphql'
import { useFormFeedback, useGraphQLWrapper, usePage } from '@hooks'
import { isMediumScreen } from '@utils'
import { BackButtonType } from '@constants'
import { VerticalCourses } from './vertical-creation'
import { getAddedCourses } from './create-vertical'

export const EditCoursesContainer: React.FC = () => {
    const { t } = useTranslation()

    const { id } = useParams()
    const navigate = useNavigate()
    const [courses, setCourses] = useState([])

    const { queryLoading, data: coursesData } = useGraphQLWrapper({
        query: GET_COURSES,
        queryName: 'getCourses',
        retrieveOnMount: true
    })
    const updateVerticalConfig = {
        mutation: UPDATE_VERTICAL,
        mutationName: 'updateVertical',
        onMutationSuccess: () => {
            navigate(`/verticals`)
        },
        isCustomMutationInput: true
    }
    useEffect(() => {
        if (!queryLoading && coursesData) {
            setCourses(
                coursesData?.data?.filter(
                    (course) => !course.isDraft && !course.isArchived
                )
            )
        }
    }, [queryLoading, coursesData])
    const {
        methods,
        save,
        queryLoading: getVerticalLoading,
        mutationLoading
    } = useFormFeedback({
        initialValues: {
            title: null,
            topics: [],
            addedTopics: []
        },
        query: GET_VERTICAL,
        queryName: 'getVertical',
        queryVariables: { id },
        retrieveOnMount: true,
        mapQueryResultToForm: ({ id, title, courses }) => ({
            id,
            title,
            addedCourses: courses
        }),
        ...updateVerticalConfig,
        mapFormToMutationInput: (form) => {
            const { id, courses, addedCourses } = form
            const coursesAdded = courses
                ? courses?.filter((course) => course.isAdded)
                : addedCourses?.map((course) => ({ id: course.id }))

            return {
                input: {
                    id,
                    courses: getAddedCourses(coursesAdded, addedCourses)
                }
            }
        }
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: VerticalCourses,
            componentProps: { allCourses: courses },
            hasHeader: true,
            preventMargin: true,
            showBreadcrumbs: true,
            backButtonConfig: {
                showBackButton: isMediumScreen?.(),
                backButtonType: BackButtonType.Custom,
                customUrl: '/verticals'
            },
            pageHeaderConfig: {
                headerText: t(t('vertical.editCourses')),
                primaryButtonTitle: t('general.save'),
                primaryButtonAction: () => save(),
                secondaryButtonTitle: t('general.cancel'),
                secondaryButtonAction: () => navigate(`/verticals`)
            }
        }
    })

    return <>{page}</>
}
