import React, { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GET_CLIENT } from '@dts/graphql'
import { useFormFeedback, usePage } from '@hooks'
import { ClientDetailsForm } from './client-details-form'
import { ClientDetailsContainerProps } from './types'
import { UserPermissionEnum } from '@dts/constants'

export const ClientDetailsContainer: FC<ClientDetailsContainerProps> = (
    props
) => {
    const { tertiaryButtonAction } = props || {}
    const { t } = useTranslation()
    const { id } = useParams()
    const navigate = useNavigate()
    const { methods, mutationLoading, queryLoading } = useFormFeedback({
        initialValues: {
            id: '',
            title: '',
            email: '',
            active: true,
            phone: '',
            address: '',
            state: '',
            city: '',
            zipCode: '',
            sector: 'Public',
            contractExpiryDate: null,
            contractStartDate: null,
            clientContact: {}
        },
        query: GET_CLIENT,
        queryName: 'getClient',
        retrieveOnMount: true,
        queryVariables: { id },
        mapQueryResultToForm: ({
            id,
            title,
            email,
            active,
            phone,
            address,
            state,
            city,
            zipCode,
            sector,
            contractExpiryDate,
            contractStartDate,
            clientType,
            clientContact
        }) => ({
            id,
            title,
            email,
            active,
            phone,
            address,
            state: state.title,
            city: city.title,
            zipCode,
            sector: sector.title,
            contractExpiryDate,
            contractStartDate,
            clientType: clientType.title,
            clientContact
        })
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: ClientDetailsForm,
            hasHeader: true,
            preventMargin: true,
            pageHeaderConfig: {
                headerText: t('pageHeader.clientDetails'),
                tertiaryButtonTitle: t('general.edit'),
                tertiaryButtonAction: () => {
                    navigate(`/clients/${id}/edit`)
                },
                permissions: [UserPermissionEnum.ClientCreateModify]
            }
        }
    })

    return <>{page}</>
}
