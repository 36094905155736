import React, { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCustomTheme, useInfiniteScroll, usePageHeader } from '@hooks'
import { GET_DASHBOARD_VIDEOS } from '@dts/graphql'
import { Grid } from '@mui/material'
import { VideoCardGrid } from '@components'
import { PlayIcon } from '@assets'
import { getUrlSegmentTitleById } from '@utils'
import { BrowseVideosByTopicsProps } from './types'
import { viewCountCardAction } from './browse-by-topics'

export const VideosByTopic: FC<BrowseVideosByTopicsProps> = ({
    query = GET_DASHBOARD_VIDEOS,
    queryName = 'getDashboardVideos',
    isCreatorVideos = false,
    showBreadcrumbs = false
}) => {
    const { id, creatorId } = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { theme } = useCustomTheme()

    const loadComponent = (mergedData: Array<object>) => (
        <VideoCardGrid
            cardData={mergedData?.map((data) => ({
                ...data,
                onClick: () => navigate(`/browse-videos/watch/${data?.id}`)
            }))}
            cardAction={(params) => ({
                icon: () => viewCountCardAction(theme, params?.viewCount)
            })}
        />
    )

    const { wrappedComponent } = useInfiniteScroll({
        query,
        queryName,
        queryVariables: { args: { topicId: id, creatorId } },
        loadComponent
    })

    const { pageHeader } = usePageHeader({
        headerText: isCreatorVideos
            ? t('general.allVideos')
            : getUrlSegmentTitleById(id),
        showBreadcrumbs,
        fetchEntityVariables: id ? { id, type: 'topic' } : undefined
    })

    return (
        <>
            {pageHeader}
            <Grid p={'2%'}> {wrappedComponent}</Grid>
        </>
    )
}
