import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import {
    IconContainer,
    LearnCard,
    LearnCardProps,
    SearchField
} from '@components'
import { CinemaIcon, DocumentIcon, LaunchIcon, VideoPlayerIcon } from '@assets'
import { TextFieldVariant } from '@constants'
import { LEARN_ICON_CONTAINER_SIZE } from '@dts/constants'
import { isMediumScreen } from '@utils'
import { useCustomTheme } from '@hooks'

export const HelpCenter = () => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()

    const cards: Array<LearnCardProps> = [
        {
            id: 1,
            icon: (
                <IconContainer
                    color={theme.palette.cream}
                    size={LEARN_ICON_CONTAINER_SIZE}
                    displayFlex
                >
                    <LaunchIcon
                        color={theme.palette.fireOrange}
                        width={32}
                        height={32}
                    />
                </IconContainer>
            ),
            title: t('settings.help.gettingStarted'),
            description: t('settings.help.gettingStarted.description'),
            onClick: () => {
                // On click
            }
        },
        {
            id: 2,
            icon: (
                <IconContainer
                    color={theme.palette.lightPink}
                    size={LEARN_ICON_CONTAINER_SIZE}
                    displayFlex
                >
                    <VideoPlayerIcon
                        color={theme.palette.tomatoRed}
                        height={32}
                        width={32}
                    />
                </IconContainer>
            ),
            title: t('settings.help.contentCreation'),
            description: t('settings.help.contentCreation.description'),
            onClick: () => {
                // On click
            }
        },
        {
            id: 3,
            icon: (
                <IconContainer
                    color={theme.palette.lightLavender}
                    size={LEARN_ICON_CONTAINER_SIZE}
                    displayFlex
                >
                    <DocumentIcon
                        color={theme.palette.royalBlue}
                        height={32}
                        width={32}
                    />
                </IconContainer>
            ),
            title: t('settings.help.accountAndPrivacy'),
            description: t('settings.help.accountAndPrivacy.description'),
            onClick: () => {
                // On click
            }
        },
        {
            id: 4,
            icon: (
                <IconContainer
                    color={theme.palette.skyBlue}
                    size={LEARN_ICON_CONTAINER_SIZE}
                    displayFlex
                >
                    <CinemaIcon color={theme.palette.navyBlue} />
                </IconContainer>
            ),
            title: t('settings.help.deletingAccount'),
            description: t('settings.help.deletingAccount.description'),
            onClick: () => {
                // On click
            }
        }
    ]

    return (
        <>
            <Grid
                container
                direction={'column'}
                sx={{
                    p: '2%'
                }}
                spacing={2}
            >
                <Grid item>
                    <Typography
                        variant='h3'
                        color={theme.palette.contentSecondary}
                    >
                        {t('setting.helpCenter')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant='h1'>
                        {t('settings.help.description')}
                    </Typography>
                </Grid>
                <Grid item sx={{ width: isMediumScreen?.() ? '80%' : '60%' }}>
                    <SearchField
                        setQueryOptions={() => {}}
                        value={''}
                        variant={TextFieldVariant.Outlined}
                        placeholder={t('learn.searchField.placeholder')}
                    />
                </Grid>
                <Grid container item spacing={2} direction={'row'}>
                    {cards.map((card) => (
                        <Grid item key={card?.id} xs={12} sm={6} md={5}>
                            <LearnCard {...card} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </>
    )
}
