import React from 'react'
import { useFormFeedback, usePage } from '@hooks'
import { InsightTimeline } from '@dts/client-utils'
import { VideoInsight } from './video-insight'

export const ControlledVideoInsight = () => {
    const { methods } = useFormFeedback({
        initialValues: {
            insightTimeFrame: InsightTimeline.LAST_SEVEN_DAYS
        }
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: VideoInsight,
            hasHeader: false,
            preventPadding: true,
            pageHeaderConfig: {
                headerText: ''
            }
        }
    })

    return <>{page}</>
}
