import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { useTranslation } from 'react-i18next'
import { TextFieldProps } from '@mui/material'
import { getErrorHelperText } from '@utils'
import { StyledTextField } from './styles'
type InputProps = TextFieldProps & {
    id: string
    label: string
    width?: string
    fullWidth?: boolean
    mask?: string
}
export const MaskInput = React.forwardRef(
    (
        {
            id,
            name = '',
            width,
            mask = '',
            error,
            helperText,
            ...props
        }: InputProps,
        ref
    ) => {
        const {
            register,
            control,
            formState: { errors },
            watch,
            ...formContext
        } = useFormContext()

        const { t } = useTranslation()
        return (
            <Controller
                name={name}
                control={control}
                render={() => (
                    <InputMask
                        {...props}
                        value={watch(name) ?? ''}
                        {...register(name)}
                        mask={mask}
                        disabled={formContext?.formEditingState?.isReadOnly}
                    >
                        {(inputProps) => (
                            <StyledTextField
                                {...inputProps}
                                InputLabelProps={{ shrink: true }}
                                color='primary'
                                aria-label={props.label}
                                size='small'
                                disabled={
                                    formContext?.formEditingState?.isReadOnly ||
                                    props?.disabled
                                }
                                id={id ? id : 'phone-text'}
                                variant='outlined'
                                InputProps={{
                                    sx: !props.fullWidth
                                        ? width
                                            ? { width }
                                            : { width: '17.5rem' }
                                        : props?.height
                                        ? { height: props?.height }
                                        : {}
                                }}
                                error={error || !!errors?.[name]?.message}
                                helperText={getErrorHelperText(errors, name, t)}
                                FormHelperTextProps={{
                                    style: {
                                        marginTop: '0px',
                                        marginLeft: '0px'
                                    }
                                }}
                            />
                        )}
                    </InputMask>
                )}
            />
        )
    }
)
