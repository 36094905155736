import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Avatar, Grid, Typography } from '@mui/material'
import { setCookie } from '@utils'
import { useAuth, useCustomTheme, useLocalStorage } from '@hooks'
import { ArrowRightIcon, CampusLogo } from '@assets'

export const onPortalClient = (client, fetchUserData, prevUser, onSuccess) => {
    setCookie('portalInfo', JSON.stringify(prevUser))
    fetchUserData?.(
        () => {
            onSuccess?.()
            window.location?.reload()
        },
        { clientId: client }
    )
}

export const ClientPortalForm = () => {
    const [storedLoggedInUser] = useLocalStorage('user-data')
    const portalClients = useWatch({ control, name: 'portalClients' })
    const { control } = useFormContext()
    const { fetchUserData } = useAuth()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { theme } = useCustomTheme()

    return (
        <Grid container md={12} display={'flex'} flexDirection={'column'}>
            {portalClients?.length === 0 && (
                <Typography variant='body2'>
                    {t('general.noRecordsFound')}
                </Typography>
            )}
            {portalClients?.map((client) => (
                <Grid
                    item
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    p={3}
                    sx={{
                        '&:hover': {
                            backgroundColor: theme.palette.backgroundInfoSubtle,
                            cursor: 'pointer'
                        }
                    }}
                    onClick={() =>
                        onPortalClient(
                            client?.value,
                            fetchUserData,
                            storedLoggedInUser,
                            () => navigate('/dashboard')
                        )
                    }
                >
                    <Grid
                        item
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={2}
                    >
                        <Avatar
                            id='avatar'
                            sx={{ height: '40px', width: '40px' }}
                            src={CampusLogo}
                        />
                        <Typography variant='h3'>{client?.label}</Typography>
                    </Grid>
                    <Grid item>
                        <ArrowRightIcon />
                    </Grid>
                </Grid>
            ))}
        </Grid>
    )
}
