import React from 'react'
import { useTranslation } from 'react-i18next'
import { ControlledTextInput } from '@components'
import { Grid, Typography } from '@mui/material'
import { HelperTextPosition } from '@constants'

export const InitialDetails: React.FC = ({ header }) => {
    const { t } = useTranslation()
    return (
        <Grid container md={12} xs={12}>
            {header && (
                <Grid item md={11} xs={11}>
                    <Typography variant='h1'>{header}</Typography>
                </Grid>
            )}
            <Grid item xs={12} md={12} mt={2}>
                <ControlledTextInput
                    name='name'
                    id='name'
                    label={t('general.name')}
                    multiline
                    rows={2}
                    helperTextConfig={{
                        wordLength: 200,
                        helperTextPosition: HelperTextPosition.Right
                    }}
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} md={12}>
                <ControlledTextInput
                    name='description'
                    id='description'
                    label={t('general.description')}
                    multiline
                    rows={5}
                    helperTextConfig={{
                        wordLength: 1000,
                        helperTextPosition: HelperTextPosition.Right
                    }}
                    fullWidth
                />
            </Grid>
        </Grid>
    )
}
