import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const SearchIcon: React.FC<SvgProps> = ({
    color,
    height = '24',
    width = '24',
    fill = 'none',
    fillOpacity = '0.1'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 24 24`}
            fill={fill}
            fillOpacity={fillOpacity}
            xmlns='http://www.w3.org/2000/svg'
        >
            <g id='icons/search-lg'>
                <path
                    id='Icon'
                    d='M21 21L17.5001 17.5M20 11.5C20 16.1944 16.1944 20 11.5 20C6.80558 20 3 16.1944 3 11.5C3 6.80558 6.80558 3 11.5 3C16.1944 3 20 6.80558 20 11.5Z'
                    stroke={_color}
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
        </svg>
    )
}
export default SearchIcon
