import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useCustomTheme } from '@hooks'
import { Grid } from '@mui/material'
import { CampusPreferencesContainer } from './campus-preferences-container'
import { CampusDetailsContainer } from './campus-details'
import { StyledButton } from './styles'
import { CampusCoursesContainer } from './campus-courses'

export const CampusContainer = ({ campusId }: { campusId: string }) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const navigate = useNavigate()

    const [activeIndex, setActiveIndex] = useState<number>(0)

    const components = [
        <CampusDetailsContainer
            key={`${campusId}+details`}
            campusId={campusId}
            tertiaryButtonAction={() => navigate(`${campusId}`)}
        />,
        <CampusCoursesContainer
            key={`${campusId}+courses`}
            campusId={campusId}
        />,
        <CampusPreferencesContainer
            key={`${campusId}+preferences`}
            campusId={campusId}
        />
    ]

    return (
        <Grid px={'8px'}>
            {[
                'general.details',
                'sideNavItems.courses',
                'setting.preferences'
            ].map((item, index: number) => (
                <StyledButton
                    key={item + index}
                    variant={'outlined'}
                    onClick={() => setActiveIndex(index)}
                    sx={[
                        index !== 0 && { marginLeft: '8px' },
                        {
                            border: 'none',
                            background: theme.palette.backgroundSecondary,
                            color: theme.palette.contentPrimary,
                            borderRadius: '12px',
                            '&:hover': {
                                border: 'none' // Override hover effect
                            }
                        }
                        // TODO below code deleted later might select button updated
                        // activeIndex === index
                        //     ? {
                        //           border: 'none',
                        //           background: theme.palette.backgroundSecondary,
                        //           color: theme.palette.contentPrimary,
                        //           boxShadow: 'none', // Remove box shadow effect for selected button
                        //           '&:hover': {
                        //               background:
                        //                   theme.palette.backgroundBrandPrimary // Override hover effect
                        //           }
                        //       }
                        //     : {
                        //           borderColor: theme.palette.backgroundInfo,
                        //           '&:hover': {
                        //               background: 'transparent' // Override hover effect
                        //           }
                        //       },
                        // { borderRadius: '12px' }
                    ]}
                >
                    {t(item)}
                </StyledButton>
            ))}

            {components[activeIndex]}
        </Grid>
    )
}
