import React, { createContext } from 'react'
import { ObjectBaseFields } from '@types'
import { DisplayModeEnum } from '@dts/constants'

// Commented code in this file will be removed after testing of login, logout and signup functionality

interface ContextData {
    // isLogin: boolean
    firstLogin?: boolean | null
    userExists?: boolean | null
    login: (data: unknown) => void
    tokenExpired?: boolean
    onSessionTimeoutClose?: VoidFunction
    userInfo: {
        roles: Array<ObjectBaseFields>
        firstLogin?: boolean | null
        clientId?: string
        adminPortalOnlyAccess?: boolean
        displayMode?: string
    }
    fetchUserData: (onSuccess?: VoidFunction) => void
    getToken?: VoidFunction
    userData?: unknown
    loginWithRedirect: (
        connectionData?: { connectionName: string },
        connectionQueryInput?: { email: string }
    ) => void
}

const defaultValue: ContextData = {
    // isLogin: false,
    firstLogin: undefined,
    userExists: undefined,
    login: () => undefined,
    fetchUserData: () => undefined,
    userInfo: {
        roles: [],
        firstLogin: undefined,
        clientId: undefined,
        adminPortalOnlyAccess: false,
        displayMode: DisplayModeEnum.DarkMode
    },
    getToken: () => undefined,
    userData: undefined,
    loginWithRedirect: () => undefined
}

export const AuthContext = createContext<ContextData>(defaultValue)
