import React from 'react'
import { useFormContext, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid, Button, InputAdornment } from '@mui/material'
import { TextInput } from '@components'
import { AppLogo, EmailIcon } from '@assets'
import { useCustomTheme } from '@hooks'
import { onKeyDown } from '@utils'
import { StyledBox } from './styles'

export const LoginAsForm: React.FC = ({ onSubmit }) => {
    const methods = useFormContext()
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const formState = useFormState({ control: methods.control })
    return (
        <StyledBox width='inherit'>
            <Grid
                container
                display={'grid'}
                sx={{
                    backgroundColor: theme.palette.backgroundPrimary,
                    padding: '1.5rem',
                    borderRadius: `24px 24px 0px 0px`
                }}
            >
                <Grid item height={'6rem'}>
                    <img src={AppLogo} alt='Logo' />
                </Grid>
            </Grid>
            <Grid
                container
                display={'grid'}
                sx={{
                    padding: '1rem 2rem 1rem 2rem',
                    backgroundColor: theme.palette.backgroundPrimary,
                    paddingBottom: '30px'
                }}
            >
                <Grid item display='grid'>
                    <TextInput
                        name='email'
                        onKeyDown={(e) => {
                            onKeyDown(e, onSubmit, false)
                        }}
                        width='inherit'
                        placeholder='Enter Email'
                        keyboardType='email-address'
                        sx={{
                            mt: '24px',
                            mb: '1rem'
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <EmailIcon
                                        color={theme.palette.neutralsTextLight}
                                    />
                                </InputAdornment>
                            ),
                            sx: {
                                display: 'flex',
                                gap: '0.2rem',
                                borderRadius: '12px',
                                '& input': {
                                    fontSize: '14px'
                                }
                            }
                        }}
                        error={formState?.errors?.loginAsSchema?.email}
                        helperText={
                            formState?.errors?.loginAsSchema?.email?.message
                        }
                        startAdornment={
                            <EmailIcon color={theme.palette.contentSecondary} />
                        }
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Button
                    variant='contained'
                    type='submit'
                    onKeyDown={(e) => {
                        onKeyDown(e, onSubmit, false)
                    }}
                    onClick={onSubmit}
                    primary
                    sx={{
                        fontSize: '14px',
                        padding: `20px`,
                        width: `100%`,
                        height: '70px',
                        letterSpacing: `1px`,
                        transition: `0.2s ease-in-out`,
                        borderRadius: `0 0 24px 24px`,
                        color: theme.palette.contentPrimaryInverse
                    }}
                >
                    {t('general.continue')}
                </Button>
            </Grid>
        </StyledBox>
    )
}
