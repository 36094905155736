import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material'

import { useCustomTheme } from '@hooks'
import { TableConfig } from './types'

export const useTable = ({
    tableConfig: { tableData, columns, groupBy }
}: {
    tableConfig: TableConfig
}) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const calculateRowspan = (tableData, groupBy) => {
        if (groupBy && tableData?.length) {
            const groupedData = {}
            tableData.forEach((item) => {
                const key = item[groupBy]
                groupedData[key] = groupedData[key] || []
                groupedData[key].push(item)
            })

            const rowspanMap = {}
            Object.keys(groupedData).forEach((key) => {
                rowspanMap[key] = groupedData[key].length
            })

            return rowspanMap
        }
        return null
    }

    const rowspanMap = calculateRowspan(tableData, groupBy)
    const groupingColumnExists = columns.some((col) => col.field === groupBy)

    const tableComponent = (
        <TableContainer
            component={Paper}
            sx={{
                border: `0.5px solid ${theme.palette.borderSecondary}`,
                background: theme.palette.backgroundPrimary
            }}
            mt={2}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        {columns?.map((column, index) => (
                            <TableCell
                                key={index}
                                sx={{
                                    ...column?.headerStyle,
                                    color: `${theme.palette.contentPrimary} !important`
                                }}
                            >
                                {column?.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData?.map(
                        (dataRow, rowIndex) =>
                            !dataRow?.hideRow && (
                                <TableRow key={rowIndex}>
                                    {columns?.map((column, columnIndex) =>
                                        !groupingColumnExists ||
                                        column?.field !== groupBy ||
                                        rowIndex === 0 ||
                                        dataRow?.[groupBy] !==
                                            tableData[rowIndex - 1]?.[
                                                groupBy
                                            ] ? (
                                            <TableCell
                                                key={rowIndex}
                                                sx={{
                                                    ...column?.cellStyle,
                                                    color: theme.palette
                                                        .contentPrimary
                                                }}
                                                rowSpan={
                                                    groupingColumnExists &&
                                                    column?.field === groupBy
                                                        ? rowspanMap?.[
                                                              dataRow?.[groupBy]
                                                          ]
                                                        : 1
                                                }
                                            >
                                                {!column?.renderCell
                                                    ? dataRow[column?.field]
                                                    : column?.renderCell(
                                                          dataRow,
                                                          rowIndex
                                                      )}
                                            </TableCell>
                                        ) : (
                                            <></>
                                        )
                                    )}
                                </TableRow>
                            )
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )

    return {
        tableComponent
    }
}
