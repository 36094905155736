import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import {
    BaseContainer,
    ComponentContainer,
    ParentContainer,
    TypographyContainer,
    ControlledRadioGroup
} from '@components'
import { Typography } from '@mui/material'
import { LearnerAdditionType } from '@constants'
import { CopyIcon, LaterIcon, UploadFileIcon } from '@assets'

export const ListLearners: FC = ({ setListCreationType }) => {
    const { t } = useTranslation()
    const { getValues, control, reset } = useFormContext()
    const learnerAdditionOptions = [
        {
            value: LearnerAdditionType.Upload,
            label: t('learnerList.uploadFile'),
            description: t('learnerList.uploadFile.description'),
            icon: UploadFileIcon
        },
        {
            value: LearnerAdditionType.CopyPaste,
            label: t('learnerList.copyAndPaste'),
            description: t('learnerList.copyPaste.description'),
            icon: CopyIcon
        },
        {
            value: LearnerAdditionType.EmptyList,
            label: t('learnerList.addLater'),
            description: t('learnerList.emptyList.description'),
            icon: LaterIcon
        }
        // To-do will be implemented later
        // {
        //     value: LearnerAdditionType.Import,
        //     label: t('learnerList.import'),
        //     description:
        //         'Import contacts automatically from tools you already use.',
        // }
    ]

    const addLearnerType = useWatch({
        control,
        name: 'addLearnerType'
    })

    useEffect(() => {
        setListCreationType(addLearnerType)
    }, [addLearnerType])

    return (
        <ParentContainer container>
            <Typography variant='h1'>{getValues('name')}</Typography>
            <BaseContainer item>
                <TypographyContainer item md={2.5}>
                    <Typography variant='h3'>
                        {[t('general.add'), t('general.learners')].join(' ')}
                    </Typography>
                    <Typography variant='body2Medium'>
                        {t('learnerList.learnersCaption')}
                    </Typography>
                </TypographyContainer>
                <ComponentContainer item md={9.5}>
                    <ControlledRadioGroup
                        name='addLearnerType'
                        hasCardView={true}
                        options={learnerAdditionOptions}
                    />
                </ComponentContainer>
            </BaseContainer>
        </ParentContainer>
    )
}
