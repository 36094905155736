import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_REPORT_META_DATA = gql`
  ${MessagesFragment('ReportMetaDataResponseDto')}
  ${MetaFragment('ReportMetaDataResponseDto')}
  query getReportMetaData {
    getReportMetaData {
      ...MessagesFragment
      ...MetaFragment
      data {
        metric {
          id
          title
          available
          displayOrder
        }
        filter {
          id
          title
          available
          displayOrder
          reportFilterType {
            id
            title
            available
            displayOrder
          }
        }
        breakdown {
          id
          title
          available
          displayOrder
        }
      }
    }
  }
`
