import React, { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { HyperLink, VideoPlayer } from '@components'
import { Chip, Divider, Grid, List, ListItem, Typography } from '@mui/material'
import { isMediumScreen, mapLabelsWithAutoIncrements } from '@utils'
import { useCustomTheme } from '@hooks'

export const ViewVideoDetails = () => {
    const { watch, control } = useFormContext()
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const formData = useWatch({
        control
    })
    const [data, setData] = useState(watch())

    useEffect(() => {
        formData && setData(formData)
    }, [formData])

    return (
        <Grid container m={0}>
            <Grid
                item
                xs={12}
                md={4}
                mb={{
                    md: 0,
                    xs: 4
                }}
                mr={4}
                sx={{ height: isMediumScreen?.() && '55vh' }}
            >
                <VideoPlayer
                    name='url'
                    value={data?.url}
                    controls={true}
                    height='100%'
                    width='100%'
                    thumbnail={data?.thumbnail}
                />
            </Grid>
            <Grid item xs={12} md={7} mt={4}>
                <Grid item mb={2}>
                    <Typography variant='h1'>{data.title}</Typography>
                </Grid>
                <Grid item mb={3}>
                    <Typography variant='body2'>{data.description}</Typography>
                </Grid>
                {data?.tags?.length ? (
                    <Grid item>
                        <Grid item>
                            <Typography variant='h1' mb={2}>
                                {t('createVideos.modal.component.tags.label')}
                            </Typography>
                        </Grid>
                        <Grid item mb={2}>
                            {data?.tags?.map(
                                (tag: { id: number; value: string }) => (
                                    <Chip
                                        label={tag?.value}
                                        key={tag?.id}
                                        sx={{
                                            mr: 1,
                                            my: 1,
                                            borderRadius: '10%',
                                            // Border style remove after verify
                                            // border: `1.5px solid ${theme.palette.borderPrimary}`,
                                            backgroundColor:
                                                theme.palette
                                                    .backgroundSecondary,
                                            color: theme.palette.contentPrimary
                                        }}
                                    />
                                )
                            )}
                        </Grid>
                    </Grid>
                ) : null}
                {data?.hyperlinks?.length ? (
                    <Grid item>
                        <Grid item>
                            <Typography variant='h1' mb={2}>
                                {t('createVideos.modal.component.links.label')}
                            </Typography>
                        </Grid>
                        <Grid item mb={2}>
                            {mapLabelsWithAutoIncrements(data?.hyperlinks)?.map(
                                (link: { id: number; value: string }) => (
                                    <HyperLink link={link} key={link?.id} />
                                )
                            )}
                        </Grid>
                    </Grid>
                ) : null}
                <Grid item>
                    <Grid item>
                        <Typography variant='h1'>
                            {t('createVideos.modal.component.others.label')}
                        </Typography>
                    </Grid>
                    <List component='ul' sx={{ flexDirection: 'column' }}>
                        <ListItem sx={{ p: 2 }}>
                            <Grid container flexDirection={'column'}>
                                <Grid item xs={6} md={6}>
                                    <Typography
                                        component='p'
                                        sx={{
                                            color: theme.palette.contentPrimary
                                        }}
                                    >
                                        {t('general.categories')}
                                    </Typography>
                                </Grid>
                                <Grid item mb={2}>
                                    {data?.topicsData?.map(
                                        (topic: {
                                            id: number
                                            title: string
                                        }) => (
                                            <Chip
                                                label={topic?.title}
                                                key={topic?.id}
                                                sx={{
                                                    mr: 1,
                                                    my: 1,
                                                    borderRadius: '8px',
                                                    // Border style remove after verify
                                                    // border: `1.5px solid ${theme.palette.borderPrimary}`,
                                                    backgroundColor:
                                                        theme.palette
                                                            .backgroundSecondary,
                                                    color: theme.palette
                                                        .contentPrimary
                                                }}
                                            />
                                        )
                                    )}
                                </Grid>
                            </Grid>
                        </ListItem>

                        <Divider />
                        <ListItem sx={{ p: 2 }}>
                            <Grid container>
                                <Grid
                                    item
                                    xs={6}
                                    md={6}
                                    justifyContent='flex-start'
                                >
                                    <Typography
                                        component='p'
                                        sx={{
                                            color: theme.palette.contentPrimary
                                        }}
                                    >
                                        {t(
                                            'createVideos.modal.component.skillLevel.label'
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    md={6}
                                    justifyContent='flex-start'
                                    sx={{
                                        color: theme.palette.contentPrimary
                                    }}
                                >
                                    <Typography
                                        component='p'
                                        fontWeight='500'
                                        sx={{
                                            color: theme.palette.contentPrimary
                                        }}
                                    >
                                        {data?.skillData?.title}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Divider />
                        {/* For future addition */}
                        {/* <ListItem sx={{ p: 2 }}>
                            <Grid container>
                                <Grid item xs={6} md={6} alignSelf='center'>
                                    <Typography component='p'>
                                        {t(
                                            'createVideos.modal.component.states.label'
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    md={6}
                                    justifyContent='flex-start'
                                >
                                    {data?.statesData?.map((state) => (
                                        <BulletList
                                            key={state?.id}
                                            sx={{
                                                listStyleType: 'disc',
                                                listStylePosition: 'inside',
                                                p: 0
                                            }}
                                        >
                                            <StyledListItem>
                                                {state?.title}
                                            </StyledListItem>
                                        </BulletList>
                                    ))}
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ p: 2 }}>
                            <Grid container>
                                <Grid item xs={6} md={6} alignSelf='center'>
                                    <Typography component='p'>
                                        {t(
                                            'createVideos.modal.component.schools.label'
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    md={6}
                                    justifyContent='flex-start'
                                >
                                    {data?.schoolsData?.map((school) => (
                                        <BulletList
                                            key={school?.id}
                                            sx={{
                                                listStyleType: 'disc',
                                                listStylePosition: 'inside',
                                                p: 0
                                            }}
                                        >
                                            <StyledListItem></StyledListItem>
                                        </BulletList>
                                    ))}
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Divider /> */}
                    </List>
                </Grid>
            </Grid>
        </Grid>
    )
}
