import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ActiveIcon, DashboardIcon, EmptyNotificationsImg } from '@assets'
import { EmptyState, IconContainer } from '@components'
import { NotificationCategoryEnum } from '@dts/constants/lib'
import {
    GET_USER_NOTIFICATIONS,
    ON_NEW_NOTIFICATION,
    READ_ALL_NOTIFICATIONS,
    READ_NOTIFICATION
} from '@dts/graphql'
import {
    useCustomTheme,
    useGraphQLWrapper,
    useInfiniteScroll,
    useNotifications,
    usePageHeader
} from '@hooks'
import { Grid, Typography } from '@mui/material'
import { formatDistanceToNow } from 'date-fns'
import { isEmpty } from 'lodash'
import { StyledNotificationRow } from './styles'

export const NotificationsList = (config: {
    isPopoverOpen?: boolean
    closePopover?: VoidFunction
}) => {
    const { t } = useTranslation()

    const {
        wrappedComponent,
        data,
        closePopoverRef,
        refetchNotifications,
        queryLoading
    } = config || {}

    const navigate = useNavigate()

    const { save: markAllAsRead } = useGraphQLWrapper({
        mutation: READ_ALL_NOTIFICATIONS,
        mutationName: 'readAllNotifications'
    })

    const tertiaryButtonConfig = config?.isPopoverOpen
        ? {
              tertiaryButtonTitle: t('general.viewAll'),
              tertiaryButtonAction: () => {
                  config?.closePopover?.()
                  navigate('/notifications')
              }
          }
        : {
              tertiaryButtonTitle: t('notifications.markAllAsRead'),
              tertiaryButtonAction: async () => {
                  await markAllAsRead()
                  refetchNotifications?.()
              }
          }

    useEffect(() => {
        closePopoverRef.current = config?.closePopover
    }, [config?.closePopover])

    const { pageHeader } = usePageHeader({
        headerText: t('sideNavItems.notifications'),
        ...tertiaryButtonConfig
    })
    return isEmpty(data) && !queryLoading ? (
        <EmptyState
            header={t('emptyState.notifications.title')}
            subHeader={t('emptyState.notifications.subTitle')}
            image={EmptyNotificationsImg}
        />
    ) : (
        <>
            <div
                style={{
                    marginBottom: !config?.isPopoverOpen ? '15px' : 0
                }}
            >
                {pageHeader}
            </div>
            {wrappedComponent}
        </>
    )
}
