import { Box, Card, Grid, Link, styled, Typography } from '@mui/material'

export const StyledCard = styled(Card)(({ theme, isFullWidth }: any) => ({
    margin: theme.spacing(0, 2),
    padding: 10,
    flexWrap: 'wrap',
    borderRadius: theme.spacing(1.5),
    border: `1px solid ${theme.palette.borderSecondary}`,
    backgroundColor: theme.palette.surfaceL1,
    height: '100%',
    whiteSpace: 'normal', // Changed from 'nowrap' to 'normal'

    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0),
        flexBasis: '100%'
    },

    [theme.breakpoints.down('lg')]: {
        padding: 2
    }
}))

export const HeaderGrid = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
}))

export const BadgeContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap', // Changed from 'nowrap' to 'wrap'
    gap: theme.spacing(1)
}))

export const FlexEndGrid = styled(Grid)(({ theme }) => ({
    justifyContent: 'flex-end',
    alignItems: 'center',
    direction: 'row',
    flexWrap: 'wrap',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2)
}))

export const StyledGridContainer = styled(Grid)(({ theme }) => ({
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap', // Changed from 'nowrap' to 'wrap'
    spacing: theme.spacing(2),
    marginY: theme.spacing(2)
}))

export const StyledTypography = styled(Typography)(({ theme }) => ({
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    marginBottom: theme.spacing(2),
    color: theme.palette.tempContentSecondary
}))

export const ContainerTypography = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    color: (props) => props.color,
    wordBreak: 'break-word', // Added for better text wrapping
    overflowWrap: 'break-word' // Added for better text wrapping
}))

export const ContainerSubheading = styled(Typography)(({ theme }) => ({
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    hyphens: 'auto',
    marginTop: theme.spacing(1),
    whiteSpace: 'pre-wrap',
    color: theme.palette.tempContentSecondary
}))

export const HeadingContainer = styled(Typography)(({ theme }) => ({
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    marginBottom: theme.spacing(1),
    color: (props) => props.color,
    variant: 'othersNav'
}))

export const StyledIconContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 24,
    minHeight: 24,
    padding: 0.5,
    borderRadius: '50%'
}))

export const AlwaysAvailableIconContainer = styled(StyledIconContainer)(
    ({ theme }) => ({
        backgroundColor: theme.palette.backgroundNegativeSubtle
    })
)

export const ConfidentialIconContainer = styled(StyledIconContainer)(
    ({ theme }) => ({
        backgroundColor: theme.palette.backgroundNoticeSubtle,
        marginLeft: theme.spacing(1)
    })
)

// Updated Box component
export const FlexGrid = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginX: theme.spacing(1),
    flexWrap: 'wrap' // Added to ensure content wraps
}))

// Updated Link component
export const StyledMuiLink = styled(Link)({
    textDecoration: 'none',
    wordBreak: 'break-word', // Added for better text wrapping
    overflowWrap: 'break-word' // Added for better text wrapping
})
