import React, { createContext } from 'react'
import { DisplayModeEnum } from '@dts/constants'

interface ThemeContextData {
    theme?: string
    toggleTheme: () => void
    isDarkTheme?: boolean
}

const defaultValue: ThemeContextData = {
    theme: undefined,
    toggleTheme: () => undefined,
    isDarkTheme: undefined
}

export const ThemeContext = createContext({ defaultValue })
