import { UserRoles } from '@constants'
import { UserRolesEnum } from '@dts/constants/lib'
import { ObjectBaseFields } from '@types'

export const getAccessRoles = (
    roles: Array<object>,
    adminPortalOnlyAccess?: boolean
) => {
    const isAdmin = roles?.some(
        (role) =>
            !adminPortalOnlyAccess &&
            (role?.roleId === UserRolesEnum.Manager ||
                role?.roleId === UserRolesEnum.SuperAdmin ||
                role?.roleId === UserRolesEnum.Coordinator ||
                role?.roleId === UserRolesEnum.Moderator)
    )
    const isManager = roles?.some(
        (role) =>
            !adminPortalOnlyAccess &&
            (role?.roleId === UserRolesEnum.Manager ||
                role?.roleId === UserRolesEnum.SuperAdmin)
    )
    const isModerator = roles?.some(
        (role) =>
            !adminPortalOnlyAccess && role?.roleId === UserRolesEnum.Moderator
    )
    const isCoordinator = roles?.some(
        (role) =>
            !adminPortalOnlyAccess && role?.roleId === UserRolesEnum.Coordinator
    )

    const isContentCreator = roles?.some(
        (role) =>
            !adminPortalOnlyAccess &&
            role?.roleId === UserRolesEnum.ContentCreator
    )
    const isContainContentCreator = roles?.some(
        (role) =>
            role?.roleId === UserRolesEnum.ContentCreator &&
            (isLearner || isClientAdmin)
    )

    const isOnlyContentCreator = roles?.every(
        (role) =>
            !adminPortalOnlyAccess &&
            role?.roleId === UserRolesEnum.ContentCreator
    )

    const isLearner =
        !adminPortalOnlyAccess &&
        roles?.some((role) => role?.roleId === UserRolesEnum.Learner)

    const isClientAdmin = roles?.some(
        (role) =>
            (!adminPortalOnlyAccess &&
                role?.roleId === UserRolesEnum.ClientUser) ||
            role?.roleId === UserRolesEnum.SupportUser
    )

    const isClientUser = roles?.some(
        (role) =>
            !adminPortalOnlyAccess && role?.roleId === UserRolesEnum.ClientUser
    )

    const isDefaultTenantUser = roles?.some(
        (role) =>
            !adminPortalOnlyAccess &&
            role?.roleId === UserRolesEnum.DefaultTenantUser
    )

    return {
        isAdmin,
        isContentCreator,
        isLearner,
        isCoordinator,
        isModerator,
        isManager,
        isClientAdmin,
        isOnlyContentCreator,
        isContainContentCreator,
        isClientUser,
        isDefaultTenantUser
    }
}
