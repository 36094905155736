import { gql } from '@apollo/client'

import {
  MessagesFragment,
  MetaFragment,
  ReportLibraryFields,
} from '../../../fragments'

export const GET_REPORT_LIBRARIES = gql`
  ${MetaFragment('ReportLibrariesResponseDto')}
  ${MessagesFragment('ReportLibrariesResponseDto')}
  ${ReportLibraryFields('ReportLibraryDto')}
  query getAllReportLibraries(
    $args: GetReportLibrariesArgumentsDto
    $options: PaginationDto
  ) {
    getAllReportLibraries(args: $args, options: $options) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...ReportLibraryFields
      }
    }
  }
`
