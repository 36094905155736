import React from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import { usePageHeader } from '@hooks'
import { checkCurrentRoute } from '@utils'
import { HeaderOutletProps } from './types'

export const HeaderOutlet: React.FC = ({
    headerText,
    menuConfig,
    showBreadcrumbs = false,
    breadcrumbSearchParamAccessor
}: HeaderOutletProps) => {
    const paramValues = Object.keys(useParams())

    const currentRoute = checkCurrentRoute(menuConfig, paramValues)

    const { pageHeader } = usePageHeader({
        headerText,
        menuConfig: () => menuConfig,
        showBreadcrumbs,
        breadcrumbSearchParamAccessor
    })

    return (
        <>
            {!currentRoute?.hidePageHeader && pageHeader}
            {currentRoute?.hidePageHeader ? (
                <Outlet />
            ) : (
                <Grid py={1} px={2}>
                    <Outlet />
                </Grid>
            )}
        </>
    )
}
