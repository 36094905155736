export enum mappingInputEnum {
  'FTN' = 'firstName',
  'LTN' = 'lastName',
  'EML' = 'email',
  'LID' = 'userId',
  'TAG' = 'tags',
}

export enum fileExtensionSeparatorEnum {
  'tsv' = '\t',
  'csv' = ',',
}

export const LEARNER_LIST_MAPPING_TAG = 'TAG'
