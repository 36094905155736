import React, { useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    Box,
    Button,
    Divider,
    Grid,
    Step,
    StepButton,
    Stepper
} from '@mui/material'
import { cloneDeep } from 'lodash'
import { ButtonType, ButtonVariants } from '@constants'
import { usePageHeader } from '@hooks/use-page-header'
import { ConfirmationDialog, Loader } from '@components'
import { useModalWithGraphQL } from '@hooks/use-modal-with-graphql'
import { ResponseStatusEnum } from '@dts/constants'
import { useCustomTheme, useLoader } from '@hooks'
import { AccessibleAction, can_access } from '@hoc'
import { useForm } from '@dts/client-utils'
import { useGraphQLWrapper } from '../use-graphql-wrapper'
import { StepperConnector, StyledStepperFooter } from './styles'
import { StepperProps } from './types'

export const useStepper = ({
    steps,
    headerText,
    stepNumber = 0,
    editMode = false,
    showNextSteps = true,
    query,
    queryName,
    queryVariables,
    initialValues,
    retrieveOnMount = true,
    showBreadcrumbs = false,
    mutation,
    mutationName,
    mapFormToInput,
    mapInputToForm,
    onMutationSuccess,
    mapPreservedFormInputs,
    permissions = [],
    tertiaryButtonConfig = {
        disabled: false
    }
}: StepperProps) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const navigate = useNavigate()
    const { loading } = useLoader()
    const [activeStep, setActiveStep] = useState(stepNumber ?? 0)
    const [queryLoad, setQueryLoad] = useState(false)
    const [totalSteps, setTotalSteps] = useState(0)
    const [completed, setCompleted] = useState<{
        [k: number]: boolean
    }>({})
    const readOnly = permissions?.length > 0 && !can_access(permissions)
    const [selectedButton, setSelectedButton] = useState(ButtonType.None)

    const getResolverSchema = () => {
        if (
            selectedButton === ButtonType.Tertiary &&
            tertiaryButtonConfig?.schema
        ) {
            return tertiaryButtonConfig?.schema
        } else if (
            steps?.[activeStep]?.schema &&
            selectedButton !== ButtonType.Tertiary
        ) {
            return steps?.[activeStep]?.schema
        }
    }

    const resolverSchema = getResolverSchema()

    const methods = useForm({
        shouldUnregister: false,
        defaultValues: initialValues,
        resolver: resolverSchema ? yupResolver(resolverSchema) : undefined,
        mode: 'onChange',
        disabled: false
    })
    const { openModalWithForm } = useModalWithGraphQL({
        modalConfig: {
            maxWidth: 'sm',
            Component: () => (
                <ConfirmationDialog
                    message={[
                        t('learnerList.overwriteData'),
                        t('general.proceedMessage')
                    ].join('\n\n')}
                />
            ),
            updationConfig: {
                primaryButtonText: t('general.confirm'),
                header: `${t('general.confirmation')}`
            },
            hasCancelButton: true
        }
    })

    const onNextStep = async () => {
        if (activeStep === totalSteps - 1) {
            const response = await save()
            if (response?.message?.type === ResponseStatusEnum.Success) {
                handleStepChange(activeStep + 1, false)
                setActiveStep(activeStep + 1)
            }
        } else {
            handleStepChange(activeStep + 1, false)
            setActiveStep(activeStep + 1)
        }
    }
    // to-do: remove after testing
    // useEffect(() => {
    // if (selectedButton !== ButtonType.None) methods.trigger()
    // if (selectedButton === ButtonType.Tertiary) {
    // if (tertiaryButtonConfig?.onClick) {
    // tertiaryButtonConfig?.onClick()
    // } else {
    // methods.handleSubmit(async () => {
    // const mutationVariables =
    // tertiaryButtonConfig?.tertiaryButtonMapper?.(
    // methods.getValues()
    // )
    //                 await graphQLSave(mutationVariables)
    // })()
    // }
    //     } else if (selectedButton === ButtonType.Primary) {
    // if (
    // steps?.[activeStep]?.nextStepConfig?.hasConfirmationDialog?.(
    // methods
    //             )
    //         ) {
    //             openModalWithForm(
    //                 undefined,
    //                 () => {
    //                     methods.handleSubmit(async () => {
    // try {
    // const confirmed = await steps?.[
    // activeStep
    // ]?.nextStepConfig?.onConfirm?.(methods)

    // if (confirmed) {
    //                                 onNextStep()
    //                             }
    //                         } catch (error) {
    //                             await steps?.[
    //                                 activeStep
    //                             ]?.nextStepConfig?.onError?.(error, methods)
    // }
    //                     })()
    // },
    // () => {
    // steps?.[activeStep]?.nextStepConfig?.onClose?.(methods)
    // }
    //             )
    //         } else {
    //             methods.handleSubmit(async () => {
    // try {
    // if (steps?.[activeStep]?.nextStepConfig?.onConfirm) {
    // await steps?.[
    // activeStep
    // ]?.nextStepConfig?.onConfirm?.(methods)
    // }

    // onNextStep()
    // } catch (error) {
    // steps?.[activeStep]?.nextStepConfig?.onError?.(
    // error,
    //                         methods
    //                     )
    //                 }
    //             })()
    //         }
    //     }
    //     setSelectedButton(ButtonType.None)
    // }, [selectedButton])

    const {
        save: graphQLSave,
        mutationLoading,
        queryLoading
    } = useGraphQLWrapper({
        query,
        queryName,
        queryVariables,
        onQuerySuccess: (result) => {
            setActiveStep(activeStep)
            setQueryLoad(true)
            const formValues = mapInputToForm?.(result)
            const preservedInput = mapPreservedFormInputs?.(result)
            methods.reset({
                ...initialValues,
                ...formValues,
                ...preservedInput
            })
            methods.setValue('isActive', true)
            methods?.formEditingState?.toggleReadOnly(readOnly)
        },
        retrieveOnMount,
        mutation,
        mutationName,
        onMutationSuccess
    })

    useEffect(() => {
        steps && setTotalSteps(steps.length)
    }, [steps])

    useEffect(() => {
        if (queryLoad) {
            handleStepChange(activeStep, false)
            setQueryLoad(false)
        }
    }, [activeStep])

    const save = async () => {
        const formValues = methods.getValues()
        const mutationVariables = mapFormToInput?.(formValues)
        return graphQLSave(mutationVariables)
    }

    const handleStepChange = (step: number, checkPrevious: boolean) => {
        setCompleted((prevCompleted) => {
            const newCompleted = { ...prevCompleted }

            for (let i = step - 1; i >= 0; i--) {
                newCompleted[i] = true

                if (checkPrevious) {
                    newCompleted[step] = false
                }
            }

            return newCompleted
        })
    }

    const handleStepJump = (step: number) => () => {
        if (editMode || step <= activeStep) {
            const newCompleted = cloneDeep(completed)
            for (let i = step; i <= activeStep; i++) {
                newCompleted[i] = false
            }
            if (editMode) {
                for (let i = step - 1; i >= 0; i--) {
                    newCompleted[i] = true
                }
            }
            setCompleted(newCompleted)
            setActiveStep(step)
        }
    }

    const jumpToLast = () => {
        const newCompleted = cloneDeep(completed)
        for (let i = 0; i <= steps?.length - 2; i++) {
            newCompleted[i] = true
        }
        setCompleted(newCompleted)
        setActiveStep(steps?.length - 1)
    }

    const handleNext = async () => {
        setSelectedButton(ButtonType.Primary)
        // trigger called to update schema between primary and tertiary
        await methods.trigger()
        if (
            steps?.[activeStep]?.nextStepConfig?.hasConfirmationDialog?.(
                methods
            )
        ) {
            openModalWithForm(
                undefined,
                () => {
                    methods.handleSubmit(async () => {
                        try {
                            const confirmed = await steps?.[
                                activeStep
                            ]?.nextStepConfig?.onConfirm?.(methods)

                            if (confirmed) {
                                onNextStep()
                            }
                        } catch (error) {
                            await steps?.[
                                activeStep
                            ]?.nextStepConfig?.onError?.(error, methods)
                        }
                    })()
                },
                () => {
                    steps?.[activeStep]?.nextStepConfig?.onClose?.(methods)
                }
            )
        } else {
            methods.handleSubmit(async () => {
                try {
                    if (steps?.[activeStep]?.nextStepConfig?.onConfirm) {
                        await steps?.[activeStep]?.nextStepConfig?.onConfirm?.(
                            methods
                        )
                    }

                    onNextStep()
                } catch (error) {
                    steps?.[activeStep]?.nextStepConfig?.onError?.(
                        error,
                        methods
                    )
                }
            })()
        }
    }

    const handlePrevious = () => {
        handleStepChange(activeStep - 1, true)
        setActiveStep(activeStep - 1)
    }

    const handleCancel = () => {
        navigate(-1)
    }

    const HorizontalStepper = () => (
        <Grid item sx={{ width: '99%', mt: 2 }}>
            <Stepper
                nonLinear
                activeStep={activeStep}
                alternativeLabel
                connector={<StepperConnector />}
            >
                {steps?.map(({ label }, index) => {
                    if (editMode || showNextSteps || index <= activeStep) {
                        return (
                            <Step key={label} completed={completed[index]}>
                                <StepButton
                                    onClick={handleStepJump(index)}
                                    sx={
                                        !showNextSteps && activeStep === 0
                                            ? {
                                                  display: 'flex',
                                                  justifyContent: 'flex-start',
                                                  marginLeft: '20px'
                                              }
                                            : {
                                                  '& .MuiStepIcon-root': {
                                                      color: theme.palette
                                                          .backgroundTertiary // Default color for non-completed steps
                                                  },
                                                  '& .MuiStepIcon-root.Mui-active':
                                                      {
                                                          color: theme.palette
                                                              .primary.main // Color for active step icon
                                                      },
                                                  '& .MuiStepIcon-root.Mui-completed':
                                                      {
                                                          color: theme.palette
                                                              .primary.main // Color for completed step icon
                                                      },
                                                  '& .MuiStepLabel-label.Mui-active':
                                                      {
                                                          color: theme.palette
                                                              .contentPrimary // Color for active step label
                                                      },
                                                  '& .MuiStepLabel-label.Mui-completed':
                                                      {
                                                          color: theme.palette
                                                              .contentPrimary // Color for completed step label
                                                      },
                                                  '& .MuiStepLabel-label': {
                                                      color: theme.palette
                                                          .contentPrimary // Color for all step labels
                                                  }
                                              }
                                    }
                                >
                                    {label}
                                </StepButton>
                            </Step>
                        )
                    }
                    return null
                })}
            </Stepper>
            <Divider sx={{ mt: 2, width: '100%' }} />
        </Grid>
    )
    const renderStepBody = () => {
        const currentStep = steps?.[activeStep]
        const currentProps = currentStep?.componentProps?.()
        return (
            <FormProvider {...methods}>
                <fieldset
                    style={{ border: 'none' }}
                    disabled={
                        !currentStep?.preventDisable &&
                        methods?.formEditingState?.isReadOnly
                    }
                >
                    {steps?.[activeStep]?.component && (
                        <currentStep.component
                            {...currentProps}
                            handleStepJump={handleStepJump}
                        />
                    )}
                </fieldset>
            </FormProvider>
        )
    }

    const onTertiaryButtonClick = async () => {
        setSelectedButton(ButtonType.Tertiary)
        // trigger called to update schema between primary and tertiary
        await methods.trigger()
        if (tertiaryButtonConfig?.onClick) {
            tertiaryButtonConfig?.onClick()
        } else {
            methods.handleSubmit(async () => {
                const mutationVariables =
                    tertiaryButtonConfig?.tertiaryButtonMapper?.(
                        methods.getValues()
                    )
                await graphQLSave(mutationVariables)
            })()
        }
    }

    const StepperFooter = () => (
        <StyledStepperFooter container>
            <Grid item>
                <Button
                    onClick={activeStep === 0 ? handleCancel : handlePrevious}
                    color='secondary'
                    variant={ButtonVariants.Contained}
                    sx={{
                        width: 134,
                        backgroundColor: theme.palette.backgroundInfoSubtle,
                        color: theme.palette.contentLink,
                        '&:hover': {
                            backgroundColor: theme.palette.BrandPrimaryContainer
                        }
                    }}
                >
                    {activeStep === 0
                        ? t('general.cancel')
                        : t('general.previous')}
                </Button>
            </Grid>

            <Grid item display={'flex'} gap={1}>
                {tertiaryButtonConfig?.title && (
                    <AccessibleAction
                        permissions={permissions}
                        component={
                            <Button
                                onClick={onTertiaryButtonClick}
                                variant='contained'
                                disabled={tertiaryButtonConfig?.disabled}
                                sx={{
                                    width: 134,
                                    backgroundColor:
                                        theme.palette.neutralsBackgroundVariant,
                                    color: theme.palette.contentLink,
                                    '&:hover': {
                                        backgroundColor:
                                            theme.palette.borderSecondary
                                    }
                                }}
                            >
                                {tertiaryButtonConfig?.title}
                            </Button>
                        }
                    />
                )}
                {readOnly && activeStep === totalSteps - 1 ? null : (
                    <Button
                        onClick={handleNext}
                        disabled={activeStep === totalSteps}
                        variant='contained'
                        sx={{
                            width: 134,
                            color: theme.palette.contentPrimaryInverse
                        }}
                    >
                        {activeStep === totalSteps - 1
                            ? t('general.save')
                            : t('general.next')}
                    </Button>
                )}
            </Grid>
        </StyledStepperFooter>
    )
    const { pageHeader } = usePageHeader({
        headerText,
        showBreadcrumbs
    })

    return {
        stepper: (
            <>
                {loading && <Loader />}
                {pageHeader}
                <HorizontalStepper />
                <Box sx={{ margin: 2.5, marginBottom: '120px' }}>
                    {renderStepBody()}
                </Box>
                <StepperFooter />
            </>
        ),
        handleStepJump,
        jumpToLast
    }
}
