import React, { useEffect, useState } from 'react'
import { Drawer, IconButton, useMediaQuery } from '@mui/material'
import { CloseIcon } from '@assets'

export const useOverlay = ({ DrawerComponent, drawerComponentProps }: any) => {
    const [open, setOpen] = useState(true)
    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )

    const shouldCloseDrawer = useMediaQuery((theme) =>
        theme.breakpoints.down('1100')
    )

    useEffect(() => {
        if (shouldCloseDrawer) {
            setOpen(false)
        } else {
            setOpen(true)
        }
    }, [shouldCloseDrawer])

    const showDrawer = () => {
        setOpen((prevState) => !prevState)
    }

    const renderOverlayComponent = (
        <>
            {!isMediumScreen ? (
                <DrawerComponent
                    {...drawerComponentProps}
                    showDrawer={showDrawer}
                />
            ) : (
                <Drawer
                    open={open}
                    onClose={() => setOpen(false)}
                    anchor='right'
                    sx={{
                        width: 250,
                        height: '100%',
                        flexShrink: 0,
                        whiteSpace: 'nowrap',
                        boxSizing: 'border-box',
                        '.MuiPaper-root': {
                            width: '100%'
                        }
                    }}
                >
                    <IconButton
                        color='primary'
                        aria-label='collapse drawer'
                        onClick={showDrawer}
                        sx={{ alignSelf: 'right', width: '50px' }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DrawerComponent
                        {...drawerComponentProps}
                        showDrawer={showDrawer}
                    />
                </Drawer>
            )}
        </>
    )

    return { showDrawer, overlayComponent: renderOverlayComponent }
}
