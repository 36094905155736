import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const ChartArrowDownIcon: React.FC<SvgProps> = ({
    color,
    width = 20,
    height = 21
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.errorDangerMedium

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M18.3334 14.6666L11.7762 8.10939C11.4461 7.77938 11.2811 7.61437 11.0909 7.55255C10.9235 7.49817 10.7432 7.49817 10.5758 7.55255C10.3856 7.61437 10.2206 7.77938 9.89055 8.10939L7.6095 10.3904C7.27948 10.7205 7.11448 10.8855 6.9242 10.9473C6.75683 11.0017 6.57654 11.0017 6.40917 10.9473C6.2189 10.8855 6.05389 10.7205 5.72388 10.3904L1.66669 6.33325M18.3334 14.6666H12.5M18.3334 14.6666V8.83325'
                stroke={_color}
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default ChartArrowDownIcon
