import { Typography, styled } from '@mui/material'

export const StyledDirectoryText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'isSelected'
})(({ color }) => ({
    fontSize: '14px',
    variant: 'section',
    fontWeight: '500',
    color: color ?? 'initial',
    cursor: 'pointer',
    '&:hover': {
        textDecoration: 'underline'
    }
}))

export const StyledDirectoryDescription = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'isSelected'
})(({ color }) => ({
    fontSize: '14px',
    variant: 'tcell',
    fontWeight: '400',
    color: color ?? 'initial'
}))
