enum DayEnum {
  Monday = 'MON',
  Tuesday = 'TUE',
  Wednesday = 'WED',
  Thursday = 'THR',
  Friday = 'FRI',
  Saturday = 'SAT',
  Sunday = 'SUN',
}

enum DayGraphqlEnum {
  'MON' = 'MON',
  'TUE' = 'TUE',
  'WED' = 'WED',
  'THR' = 'THR',
  'FRI' = 'FRI',
  'SAT' = 'SAT',
  'SUN' = 'SUN',
}

export { DayEnum, DayGraphqlEnum }
