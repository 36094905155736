import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, SurveyFields } from '../../fragments'

export const SAVE_SURVEY = gql`
  ${MessagesFragment('SurveyResponseDto')}
  ${MetaFragment('SurveyResponseDto')}
  ${SurveyFields('SurveyDto')}
  mutation saveSurvey($input: SurveyInputDto!) {
    saveSurvey(input: $input) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...SurveyFields
      }
    }
  }
`
