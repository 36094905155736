import React, { FC } from 'react'
import Box from '@mui/material/Box'
import { Grid, Tooltip } from '@mui/material'
import { ProgressBarVariants, TooltipPlacement } from '@constants'
import { StyledLinearProgress } from './styles'
import { MultiLinearProgressProps, LinearProgressProps } from './types'

export const MultiLinearProgress: FC<MultiLinearProgressProps> = ({
    multiProgressBar
}) => {
    const getLinearProgress = ({
        barBackgroundColor,
        progressBackgroundColor,
        height = '7px',
        border
    }: LinearProgressProps) => (
        <StyledLinearProgress
            variant={ProgressBarVariants.determinate}
            value={100}
            barBackgroundColor={barBackgroundColor}
            progressBackgroundColor={progressBackgroundColor}
            height={height}
            border={border}
        />
    )

    return (
        <Grid display={'flex'}>
            {multiProgressBar?.map(
                ({
                    barBackgroundColor,
                    progressBackgroundColor,
                    height,
                    border,
                    hasTooltip,
                    value
                }) => (
                    <Grid item width={`${value}%`}>
                        <Box>
                            {hasTooltip ? (
                                <Tooltip
                                    title={`${value}%`}
                                    placement={TooltipPlacement.top}
                                >
                                    {getLinearProgress({
                                        barBackgroundColor,
                                        progressBackgroundColor,
                                        height,
                                        border
                                    })}
                                </Tooltip>
                            ) : (
                                getLinearProgress({
                                    barBackgroundColor,
                                    progressBackgroundColor,
                                    height,
                                    border
                                })
                            )}
                        </Box>
                    </Grid>
                )
            )}
        </Grid>
    )
}
