import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { cachedSurveyInfo } from '@cacheql'
import { SurveyTypeEnum, SurveyTypeGraphqlEnum } from '@dts/constants'
import { useGraphQLWrapper, useSurveys } from '@hooks'
import { SurveyProvider } from '@providers'
import { GET_ENTITY } from '@dts/graphql'
import { LearnerSurveyMode } from '@constants'

export const TrainingSurveyPage = () => (
    <SurveyProvider>
        <TrainingSurvey />
    </SurveyProvider>
)

export const TrainingSurvey = () => {
    const navigate = useNavigate()
    const surveyContextData = useSurveys({
        onDialogClose: () => navigate('/my-trainings')
    })
    const [searchParams] = useSearchParams()
    const [trainingId, courseId, progress, surveyType] = [
        'trainingId',
        'courseId',
        'progress',
        'surveyType'
    ].map((param) => searchParams.get(param) || '')

    const { retrieve } = useGraphQLWrapper({
        query: GET_ENTITY,
        queryName: 'getEntity',
        retrieveOnMount: false
    })

    useEffect(() => {
        if (courseId) {
            retrieve({ variables: { input: { id: courseId, type: 'course' } } })
        }
    }, [courseId])

    useEffect(() => {
        if (surveyType === SurveyTypeGraphqlEnum.POS) {
            surveyContextData?.openSurvey(
                trainingId,
                courseId,
                SurveyTypeEnum.PostTest,
                LearnerSurveyMode.NoCache
            )
        } else if (surveyType === SurveyTypeGraphqlEnum.RRS) {
            surveyContextData?.openSurvey(
                trainingId,
                courseId,
                SurveyTypeEnum.PreTest
            )
        }

        return () => surveyContextData?.surveyDialogUnmount()
    }, [])

    return <></>
}
