import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const CameraIcon: React.FC<SvgProps> = ({
    color,
    height = '16',
    width = '16'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.backgroundPrimary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 16 16'
            fill='none'
        >
            <path
                d='M1.33337 5.58547C1.33337 5.35192 1.33337 5.23515 1.34312 5.13679C1.43711 4.18816 2.18755 3.43772 3.13618 3.34373C3.23454 3.33398 3.35761 3.33398 3.60376 3.33398C3.69861 3.33398 3.74603 3.33398 3.7863 3.33155C4.30045 3.30041 4.75068 2.9759 4.9428 2.49798C4.95785 2.46056 4.97191 2.41837 5.00004 2.33398C5.02817 2.2496 5.04223 2.20741 5.05728 2.16999C5.24941 1.69207 5.69963 1.36756 6.21378 1.33642C6.25405 1.33398 6.29852 1.33398 6.38747 1.33398H9.61262C9.70156 1.33398 9.74603 1.33398 9.7863 1.33642C10.3004 1.36756 10.7507 1.69207 10.9428 2.16999C10.9579 2.20741 10.9719 2.2496 11 2.33398C11.0282 2.41837 11.0422 2.46056 11.0573 2.49798C11.2494 2.9759 11.6996 3.30041 12.2138 3.33155C12.254 3.33398 12.3015 3.33398 12.3963 3.33398C12.6425 3.33398 12.7655 3.33398 12.8639 3.34373C13.8125 3.43772 14.563 4.18816 14.657 5.13679C14.6667 5.23515 14.6667 5.35192 14.6667 5.58547V10.8007C14.6667 11.9208 14.6667 12.4808 14.4487 12.9086C14.257 13.285 13.951 13.5909 13.5747 13.7827C13.1469 14.0007 12.5868 14.0007 11.4667 14.0007H4.53337C3.41327 14.0007 2.85322 14.0007 2.42539 13.7827C2.04907 13.5909 1.74311 13.285 1.55136 12.9086C1.33337 12.4808 1.33337 11.9208 1.33337 10.8007V5.58547Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M8.00004 11.0007C9.4728 11.0007 10.6667 9.80674 10.6667 8.33398C10.6667 6.86123 9.4728 5.66732 8.00004 5.66732C6.52728 5.66732 5.33337 6.86123 5.33337 8.33398C5.33337 9.80674 6.52728 11.0007 8.00004 11.0007Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default CameraIcon
