import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const VideoApprovalIcon: React.FC<SvgProps> = ({
    color,
    width = '25',
    height = '25'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 24 24'
            fill='none'
        >
            <path
                d='M7.8 19C6.11984 19 5.27976 19 4.63803 18.673C4.07354 18.3854 3.6146 17.9265 3.32698 17.362C3 16.7202 3 15.8802 3 14.2V7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H13.2C14.8802 3 15.7202 3 16.362 3.32698C16.9265 3.6146 17.3854 4.07354 17.673 4.63803C18 5.27976 18 6.11984 18 7.8M22.0026 21.3256L20.1893 19.5123M17.0807 20.8075C19.5129 20.8075 21.4845 18.8359 21.4845 16.4037C21.4845 13.9716 19.5129 12 17.0807 12C14.6486 12 12.677 13.9716 12.677 16.4037C12.677 18.8359 14.6486 20.8075 17.0807 20.8075ZM8.70857 13.1562L12.2148 10.9022C12.4735 10.7358 12.6029 10.6527 12.6476 10.5469C12.6866 10.4545 12.6866 10.3502 12.6476 10.2578C12.6029 10.152 12.4735 10.0689 12.2148 9.90253L8.70857 7.64854C8.41037 7.45684 8.26127 7.36099 8.13797 7.3698C8.03052 7.37748 7.9317 7.43143 7.86714 7.51766C7.79305 7.61662 7.79305 7.79387 7.79305 8.14837V12.6563C7.79305 13.0108 7.79305 13.1881 7.86714 13.287C7.9317 13.3733 8.03052 13.4272 8.13797 13.4349C8.26127 13.4437 8.41037 13.3479 8.70857 13.1562Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default VideoApprovalIcon
