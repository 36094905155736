import { MessagesFragment, MetaFragment } from '../../fragments'

import { gql } from '@apollo/client'

export const SAVE_USER_NOTIFICATION_PREFERENCES = gql`
  ${MessagesFragment('UserNotificationPreferencesResponseDto')}
  ${MetaFragment('UserNotificationPreferencesResponseDto')}
  mutation saveUserNotificationPreferences(
    $input: SaveUserNotificationPreferencesDto!
  ) {
    saveUserNotificationPreferences(input: $input) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
        title
      }
    }
  }
`
