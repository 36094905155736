import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const PLOT_INSIGHT_CHART = gql`
  ${MetaFragment('InsightChartResponseDto')}
  ${MessagesFragment('InsightChartResponseDto')}
  query plotInsightChart($args: GetInsightChartArgumentsDto!) {
    plotInsightChart(args: $args) {
      ...MetaFragment
      ...MessagesFragment
      data {
        KPIs {
          liked {
            percentage
            value
          }
          views {
            percentage
            value
          }
          watchTime {
            percentage
            value
          }
        }
        dataPoints {
          count
          date
        }
      }
    }
  }
`
