import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormFeedback, usePage } from '@hooks'
import { isMediumScreen } from '@utils'
import { GET_PREFERENCE_SETTING, SAVE_PREFERENCE_SETTING } from '@dts/graphql'
import { BackButtonType } from '@constants'
import { ChangeLanguage } from './language'

export const CaptionsSetting = () => {
    const { t } = useTranslation()

    const { methods, save, mutationLoading, queryLoading } = useFormFeedback({
        mutation: SAVE_PREFERENCE_SETTING,
        mutationName: 'savePreferenceSetting',
        mapFormToMutationInput: (form) => ({
            captionLanguageId: form?.language,
            captionEnabled: form?.captionEnabled
        }),
        query: GET_PREFERENCE_SETTING,
        queryName: 'getPreferenceSetting',
        mapQueryResultToForm: (response) => ({
            language: response?.captionLanguage?.id,
            captionEnabled: response?.captionEnabled
        }),
        initialValues: { captionEnabled: false },
        retrieveOnMount: true,
        suppressToast: true
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: ChangeLanguage,
            componentProps: { isCaptionsLanguage: true, onSave: save },
            hasHeader: true,
            preventMargin: true,
            backButtonConfig: {
                showBackButton: isMediumScreen?.(),
                backButtonType: BackButtonType.Hierarchical
            },
            pageHeaderConfig: {
                headerText: t('general.captions')
            }
        }
    })

    return <>{page}</>
}
