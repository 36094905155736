import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const DELETE_LEARNER_LIST = gql`
  ${MessagesFragment('LearnerListResponse')}
  ${MetaFragment('LearnerListResponse')}
  mutation deleteLearnerList($id: String!) {
    deleteLearnerList(id: $id) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
      }
    }
  }
`
