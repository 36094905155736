import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_PORTAL_CLIENTS = gql`
  ${MessagesFragment('PortalClientsResponseDto')}
  ${MetaFragment('PortalClientsResponseDto')}
  query getPortalClients {
    getPortalClients {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
        title
        status {
          id
          title
        }
        loggedInAt
      }
    }
  }
`
