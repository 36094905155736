import { REGEX as Regex } from './regex'

export const formatPhoneNumber = (phoneNumber: string, applyMask?: boolean) => {
  const format = (`${  phoneNumber}`).replace(/\D/g, '')
  const match = format.match(Regex.formatPhoneNo)
  if (match) {
    return applyMask
      ? `(${match[1]}) ${match[2]}-${match[3]}`
      : match[1] + match[2] + match[3]
  }
  return null
}
