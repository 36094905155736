import { useTranslation } from 'react-i18next'

export const getAddress = (
  address: string,
  city: { name: string },
  state: { name: string },
  zipCode: string,
) => {
  const { t } = useTranslation()
  return `${address ? `${address  },` : ''} ${city?.name ?? (city ? city : '')} ${
    state?.name ?? (state ? `${state  },` : '')
  } ${zipCode ? `${zipCode  },` : ''} ${t('general.USA')}`
}
