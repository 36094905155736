import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
    useCustomTheme,
    useDirectory,
    useModalWithGraphQL,
    useVideoConfig
} from '@hooks'

import {
    CloseIcon,
    DeleteIcon,
    EditIcon,
    HourGlassIcon,
    InfoIcon,
    InsightsIcon,
    PlayIcon,
    VideoIcon,
    VideoThumbnailImg
} from '@assets'
import { Grid, Tooltip, Typography } from '@mui/material'
import { GET_VIDEOS } from '@dts/graphql'
import { InsightTimeline, formatDate } from '@dts/client-utils'
import { TooltipPlacement, VideoConfigType, VideoStatus } from '@constants'
import { t } from 'i18next'
import { capitalize } from 'lodash'
import { StyledDirectoryLink } from '@containers/styles'
import { TextWithTooltip } from '@components'
import { viewCountCardAction } from '@containers/browse-videos'

export const getCoverImageRenderCell = (params, onClick?: VoidFunction) => {
    const thumbnail = (
        <Grid
            sx={{
                width: '54px',
                height: '64px',
                borderRadius: '8px',
                padding: '8px'
            }}
        >
            <img
                src={params.value?.length ? params?.value : VideoThumbnailImg}
                name='image upload'
                alt='Avatar'
                aria-label={'ariaLabel'}
                height='100%'
                width='100%'
                style={{ borderRadius: '10%', objectFit: 'cover' }}
            />
        </Grid>
    )
    return (
        <StyledDirectoryLink onClick={() => onClick?.()}>
            {thumbnail}
        </StyledDirectoryLink>
    )
}

export const CreatorVideos = ({ status }: { status: string }) => {
    const { theme } = useCustomTheme()
    const navigate = useNavigate()
    const { videoConfig: videoDetailsConfig } = useVideoConfig({
        videoConfigType: VideoConfigType.Retrieve
    })

    const { openPrepopulatedDialog: openDetailedVideo } =
        useModalWithGraphQL(videoDetailsConfig)

    const { videoConfig: prepoulatedDialogConfig, getDeleteConfig } =
        useVideoConfig({
            videoConfigType: VideoConfigType.Generic
        })

    const { openPrepopulatedDialog } = useModalWithGraphQL(
        prepoulatedDialogConfig
    )

    const { openConfirmationDialog: deleteVideo } = useModalWithGraphQL(
        getDeleteConfig(() => {
            retrieve({
                variables: {
                    options: queryOptions
                }
            })
        })
    )

    const onVideoClick = (params) =>
        status === VideoStatus.Draft ||
        params?.status === VideoStatus.Rejected.toLowerCase() ||
        params?.status === VideoStatus.UnPublished.toLowerCase()
            ? openPrepopulatedDialog({ id: params?.id }, { isDraft: true })
            : navigate(`/my-account/${params?.id}?isAccountVideos=${true}`)

    const getToolbarConfig = () => {
        switch (status) {
            case VideoStatus.Pending:
                return {
                    header: t('videos.directory.pending.header'),
                    caption: t('videos.directory.pending.caption')
                }
            case VideoStatus.Rejected:
                return {
                    header: t('videos.directory.rejected.header'),
                    caption: t('videos.directory.rejected.caption')
                }
            case VideoStatus.Approved:
                return {
                    header: t('videos.directory.approved.header'),
                    caption: t('videos.directory.approved.caption')
                }
            case VideoStatus.Draft:
                return {
                    header: t('creatorVideos.directory.draft.header'),
                    caption: t('creatorVideos.directory.draft.caption')
                }
            default:
                return {}
        }
    }

    const isShowEditAndDelete = (params) =>
        params?.row?.status === VideoStatus.Rejected.toLowerCase() ||
        params?.row?.status === VideoStatus.UnPublished.toLowerCase() ||
        (params?.row?.status === VideoStatus?.Pending.toLowerCase() &&
            params?.row?.pendingStatus?.firstLevel ===
                VideoStatus?.Pending.toLowerCase() &&
            params?.row?.pendingStatus?.secondLevel ===
                VideoStatus?.Pending.toLowerCase() &&
            params?.row?.pendingStatus?.thirdLevel ===
                VideoStatus?.Pending.toLowerCase())

    const { directory, retrieve, queryOptions } = useDirectory({
        directoryConfig: {
            id: 'videoDirectory',
            cardDirectoryConfig: {
                getCardAction: (params: { viewCount: string }) => ({
                    icon: () => viewCountCardAction(theme, params?.viewCount)
                }),
                getCardConfig: (data) =>
                    data?.map((item) => ({
                        ...item,
                        onClick: () => {
                            onVideoClick(item)
                        }
                    }))
            },
            config: {
                customActions: [
                    ...(status === VideoStatus.Approved ||
                    status === VideoStatus.Pending
                        ? [
                              {
                                  icon: <VideoIcon width='18' height='18' />,
                                  label: t('general.viewDetails'),
                                  ariaLabel: t('general.viewDetails'),
                                  action: (params) => {
                                      navigate(
                                          `/my-account/${
                                              params?.id
                                          }?isAccountVideos=${true}`
                                      )
                                  },
                                  showInMenu: true,
                                  hide: (params) =>
                                      params?.row?.status ===
                                      VideoStatus.Rejected.toLowerCase()
                              }
                          ]
                        : []),
                    ...(status === VideoStatus.Approved
                        ? [
                              {
                                  icon: <InsightsIcon width='18' height='18' />,
                                  label: t('insight.viewInsights'),
                                  ariaLabel: t('insight.viewInsights'),
                                  action: (params) => {
                                      // on click
                                      navigate(
                                          `/video-insights/${params?.id}?insightTimeFrameId=${InsightTimeline.LAST_SEVEN_DAYS}`
                                      )
                                  },
                                  showInMenu: true
                              }
                          ]
                        : []),
                    ...(status !== VideoStatus.Approved
                        ? [
                              {
                                  icon: <EditIcon width='18' height='18' />,
                                  label: t('general.edit'),
                                  ariaLabel: t('general.edit'),
                                  action: (params) => {
                                      // on click
                                      openPrepopulatedDialog(
                                          { id: params?.id },
                                          { isDraft: true }
                                      )
                                  },
                                  showInMenu: true,
                                  hide: (params) => !isShowEditAndDelete(params)
                              },
                              {
                                  icon: <DeleteIcon width='18' height='18' />,
                                  label: t('general.delete'),
                                  ariaLabel: t('general.delete'),
                                  action: (params) => {
                                      // on click
                                      deleteVideo({ id: params?.id })
                                  },
                                  showInMenu: true,
                                  hide: (params) => !isShowEditAndDelete(params)
                              }
                          ]
                        : [])
                ],
                columns: [
                    {
                        field: 'coverImageSrc',
                        headerName: t('general.video'),
                        maxWidth: 120,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={getCoverImageRenderCell(params, () =>
                                    onVideoClick(params?.row)
                                )}
                                toolTip={
                                    params.row?.title ||
                                    t('creatorVideos.directory.title.empty')
                                }
                                tabIndex={params.tabIndex}
                            />
                        )
                    },
                    ...(status === VideoStatus.Rejected
                        ? [
                              {
                                  field: 'reviews',
                                  headerName: t('general.rejectedReason'),
                                  renderCell: (params) => (
                                      <TextWithTooltip
                                          renderer={
                                              params?.value?.find(
                                                  (review) =>
                                                      review?.version ===
                                                      params?.row?.version
                                              )?.title
                                          }
                                          tabIndex={params.tabIndex}
                                      />
                                  )
                              }
                          ]
                        : []),
                    {
                        field: 'title',
                        headerName: t('general.title'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={
                                    params.value ||
                                    t('creatorVideos.directory.title.empty')
                                }
                                onClick={() => onVideoClick(params?.row)}
                                isDirectoryLink
                                tabIndex={params.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'vertical',
                        headerName: t('general.vertical'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={'--'}
                                tabIndex={params.tabIndex}
                            />
                        )
                    },
                    ...(status === VideoStatus.Approved
                        ? [
                              {
                                  field: 'views',
                                  headerName: t('general.views'),
                                  renderCell: (params) => (
                                      <TextWithTooltip
                                          renderer={'--'}
                                          tabIndex={params.tabIndex}
                                      />
                                  )
                              }
                          ]
                        : []),
                    {
                        field: 'topicsData',
                        headerName: `${t('general.numberOf')} ${t(
                            'general.topics'
                        )}`,
                        renderCell: ({ value, tabIndex }) => (
                            <TextWithTooltip
                                renderer={value?.length}
                                tabIndex={tabIndex}
                            />
                        )
                    },
                    ...(status === VideoStatus.Pending
                        ? [
                              {
                                  field: 'status',
                                  headerName: t('general.status'),
                                  minWidth: 200,
                                  renderCell: (params) => {
                                      let color = ''
                                      let icon
                                      let value = capitalize(params.value)
                                      if (
                                          params.value ===
                                          VideoStatus.Rejected.toLowerCase()
                                      ) {
                                          color =
                                              theme.palette.errorDangerMedium
                                          icon = (
                                              <CloseIcon
                                                  width='18'
                                                  height='18'
                                                  color={color}
                                              />
                                          )
                                      } else if (
                                          params.value ===
                                          VideoStatus.Pending.toLowerCase()
                                      ) {
                                          value = `${value} ${t(
                                              'general.approval'
                                          )}`
                                          icon = (
                                              <HourGlassIcon
                                                  width='20'
                                                  height='21'
                                              />
                                          )
                                      }
                                      const tooltipValue = (
                                          <Grid
                                              sx={{
                                                  p: 1
                                              }}
                                          >
                                              <Grid
                                                  item
                                                  sx={{
                                                      overflow: 'auto'
                                                  }}
                                              >
                                                  <Typography
                                                      variant='h2'
                                                      mb={1}
                                                  >
                                                      {
                                                          params?.row
                                                              ?.reviews?.[0]
                                                              ?.title
                                                      }
                                                  </Typography>
                                              </Grid>
                                              <Grid
                                                  item
                                                  sx={{
                                                      maxHeight: '200px',
                                                      overflowY: 'auto',
                                                      '::-webkit-scrollbar': {
                                                          width: '4px'
                                                      },
                                                      '::-webkit-scrollbar-track':
                                                          {
                                                              backgroundColor:
                                                                  '#fff'
                                                          },
                                                      '::-webkit-scrollbar-thumb':
                                                          {
                                                              backgroundColor:
                                                                  '#727272',
                                                              borderRadius: 2
                                                          }
                                                  }}
                                              >
                                                  {
                                                      params?.row?.reviews?.[0]
                                                          ?.details
                                                  }
                                              </Grid>
                                          </Grid>
                                      )
                                      const renderValue: JSX.Element = (
                                          <Grid container gap={1} wrap='nowrap'>
                                              <Grid
                                                  item
                                                  alignSelf='center'
                                                  display='flex'
                                              >
                                                  {icon}
                                              </Grid>
                                              <Grid
                                                  item
                                                  alignSelf='center'
                                                  sx={{ color }}
                                              >
                                                  {value}
                                              </Grid>
                                              {params.value ===
                                              VideoStatus.Rejected.toLowerCase() ? (
                                                  <Tooltip
                                                      title={tooltipValue}
                                                      placement={
                                                          TooltipPlacement.bottom
                                                      }
                                                      componentsProps={{
                                                          tooltip: {
                                                              sx: {
                                                                  maxWidth:
                                                                      '265px',
                                                                  background:
                                                                      '#000'
                                                              }
                                                          }
                                                      }}
                                                  >
                                                      <Grid
                                                          item
                                                          alignSelf='center'
                                                          sx={{
                                                              cursor: 'pointer'
                                                          }}
                                                      >
                                                          <InfoIcon
                                                              width='18'
                                                              height='18'
                                                          />
                                                      </Grid>
                                                  </Tooltip>
                                              ) : null}
                                          </Grid>
                                      )
                                      return (
                                          <TextWithTooltip
                                              renderer={renderValue}
                                              toolTip={value}
                                              tabIndex={params.tabIndex}
                                          />
                                      )
                                  }
                              }
                          ]
                        : []),
                    {
                        field: 'createdAt',
                        headerName:
                            status === VideoStatus.Approved
                                ? t('general.dateCreated')
                                : status === VideoStatus.Pending
                                ? t('general.datePublished')
                                : status === VideoStatus.Rejected
                                ? t('general.dateRejected')
                                : t('general.dateModified'),
                        renderCell: ({ row, tabIndex }) => {
                            const date =
                                status === VideoStatus.Approved
                                    ? formatDate(row?.publishedAt)
                                    : status === VideoStatus.Pending
                                    ? formatDate(row?.createdAt)
                                    : formatDate(row?.updatedAt)
                            return (
                                <TextWithTooltip
                                    renderer={date}
                                    tabIndex={tabIndex}
                                />
                            )
                        }
                    }
                ],
                hideFooter: false,
                pagination: true
            },
            enableSearch: true,
            toolbarConfig: getToolbarConfig(),
            query: GET_VIDEOS,
            queryName: 'getMyVideos',
            queryVariables: {
                args: {
                    tab: status
                }
            },
            shouldShowLoader: false
        }
    })

    return directory
}
