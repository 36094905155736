import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const DELETE_CLIENT_ROLE = gql`
  ${MessagesFragment('RoleResponseDto')}
  ${MetaFragment('RoleResponseDto')}
  mutation deleteRole($id: String!, $clientId: String) {
    deleteRole(id: $id, clientId: $clientId) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
      }
    }
  }
`
