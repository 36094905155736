import React, { FC } from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const InformationIcon: FC<SvgProps> = ({
    color,
    width = '20',
    height = '20'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.backgroundBrandPrimary

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2.29065 3.45603C2 4.02646 2 4.77319 2 6.26667V13.7333C2 15.2268 2 15.9735 2.29065 16.544C2.54631 17.0457 2.95426 17.4537 3.45603 17.7094C4.02646 18 4.77319 18 6.26667 18H13.7333C15.2268 18 15.9735 18 16.544 17.7094C17.0457 17.4537 17.4537 17.0457 17.7094 16.544C18 15.9735 18 15.2268 18 13.7333V6.26667C18 4.77319 18 4.02646 17.7094 3.45603C17.4537 2.95426 17.0457 2.54631 16.544 2.29065C15.9735 2 15.2268 2 13.7333 2H6.26667C4.77319 2 4.02646 2 3.45603 2.29065C2.95426 2.54631 2.54631 2.95426 2.29065 3.45603ZM10 5.69479C9.58579 5.69479 9.25 6.03058 9.25 6.44479C9.25 6.85901 9.58579 7.19479 10 7.19479H10.0089C10.4231 7.19479 10.7589 6.85901 10.7589 6.44479C10.7589 6.03058 10.4231 5.69479 10.0089 5.69479H10ZM10.75 10.0003C10.75 9.58613 10.4142 9.25035 10 9.25035C9.58579 9.25035 9.25 9.58613 9.25 10.0003V13.5559C9.25 13.9701 9.58579 14.3059 10 14.3059C10.4142 14.3059 10.75 13.9701 10.75 13.5559V10.0003Z'
                fill={_color}
            />
        </svg>
    )
}
export default InformationIcon
