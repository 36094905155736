import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const TagIcon: React.FC<SvgProps> = ({
    color,
    width = '20',
    height = '20'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M6.66602 6.6665H6.67435M1.66602 4.33317L1.66602 8.06193C1.66602 8.46958 1.66602 8.67341 1.71207 8.86522C1.75289 9.03528 1.82023 9.19786 1.91162 9.34698C2.01468 9.51517 2.15881 9.6593 2.44706 9.94755L8.83759 16.3381C9.82763 17.3281 10.3226 17.8231 10.8935 18.0086C11.3956 18.1718 11.9364 18.1718 12.4386 18.0086C13.0094 17.8231 13.5044 17.3281 14.4944 16.3381L16.3376 14.4949C17.3276 13.5049 17.8226 13.0099 18.0081 12.439C18.1713 11.9369 18.1713 11.3961 18.0081 10.894C17.8226 10.3231 17.3276 9.82812 16.3376 8.83808L9.94706 2.44755C9.65881 2.1593 9.51468 2.01517 9.34649 1.9121C9.19737 1.82072 9.03479 1.75338 8.86473 1.71255C8.67292 1.6665 8.4691 1.6665 8.06144 1.6665L4.33268 1.6665C3.39926 1.6665 2.93255 1.6665 2.57603 1.84816C2.26243 2.00795 2.00746 2.26292 1.84767 2.57652C1.66602 2.93304 1.66602 3.39975 1.66602 4.33317ZM7.08268 6.6665C7.08268 6.89662 6.89613 7.08317 6.66602 7.08317C6.4359 7.08317 6.24935 6.89662 6.24935 6.6665C6.24935 6.43639 6.4359 6.24984 6.66602 6.24984C6.89613 6.24984 7.08268 6.43639 7.08268 6.6665Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default TagIcon
