import * as React from 'react'
import { CardContent, Typography } from '@mui/material'
import { useCustomTheme } from '@hooks'
import { StyledCard } from './styles'

type AdminAnalyticsCardProps = {
    header: string
    content: JSX.Element
    isSelected?: boolean
    onClick?: VoidFunction
}

export const AdminAnalyticsCard: React.FC<AdminAnalyticsCardProps> = ({
    header,
    content,
    isSelected,
    onClick
}) => {
    const { theme } = useCustomTheme()
    return (
        <StyledCard onClick={() => onClick?.()} isSelected={isSelected}>
            <Typography
                sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '120%',
                    letterSpacing: '-0.14px',
                    color: theme.palette.contentPrimary
                }}
            >
                {header}
            </Typography>
            <CardContent>{content}</CardContent>
        </StyledCard>
    )
}
