import { gql } from '@apollo/client'
import {
  MessagesFragment,
  MetaFragment,
  ResourcesFields,
} from '../../fragments'

export const GET_RESOURCES = gql`
  ${MessagesFragment('ResourcesResponseDto')}
  ${MetaFragment('ResourcesResponseDto')}
  ${ResourcesFields('ResourceCategoryResponse')}
  query getResources {
    getResources {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...ResourcesFields
      }
    }
  }
`
