import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
    ArrowLeftIcon,
    ArrowNarrowLeftIcon,
    DotMenuIcon,
    EditIcon
} from '@assets'
import {
    Box,
    Divider,
    Grid,
    IconButton,
    MenuItem as MItem,
    Menu,
    Typography,
    useMediaQuery
} from '@mui/material'
import { ButtonVariants, KeyboardShortcut } from '@constants'
/* eslint-disable complexity */
import { useCustomTheme, useNavigations } from '@hooks'

import { MenuItem, TextWithTooltip } from '@components'
import { toUpper } from 'lodash'
import { AccessibleAction } from '@hoc'
import { PageHeaderProps } from './types'
import {
    StyledPageHeader,
    StyledPageTitle,
    StyledPrimaryButton,
    StyledSecondaryButton,
    StyledTertiaryButton
} from './styles'
import { getAllPermissions } from '@utils'

export const usePageHeader = ({
    headerText,
    ariaLabel,
    headerEditAction,
    headerIcon,
    goBackAction,
    saveForm = false,
    primaryButtonTitle,
    primaryButtonAction,
    primaryButtonIcon,
    disablePrimaryButton = () => false,
    secondaryButtonTitle,
    secondaryButtonAction,
    secondaryButtonIcon,
    tertiaryButtonTitle,
    tertiaryButtonAction,
    tertiaryButtonIcon,
    kebabMenuConfig,
    primaryButtonMenuConfig,
    menuConfig,
    permissions = [],
    hasColumnDirection: _hasColumnDirection = false,
    isInsideLayout = true,
    overlayConfig,
    showMenu = true,
    defaultGoBack = false,
    showBreadcrumbs = false,
    breadcrumbSearchParamAccessor,
    fetchEntityVariables,
    backButtonConfig
}: PageHeaderProps) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const navigate = useNavigate()

    const { breadcrumbComponent, backRedirectionComponent } =
        showBreadcrumbs || backButtonConfig
            ? useNavigations({
                  showBreadcrumbs,
                  backButtonConfig,
                  fetchEntityVariables,
                  breadcrumbSearchParamAccessor
              })
            : { breadcrumbComponent: <></>, backRedirectionComponent: <></> }

    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )

    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))

    const hasAllButtons =
        headerText &&
        tertiaryButtonTitle &&
        primaryButtonTitle &&
        secondaryButtonTitle &&
        isSmallScreen

    const hasColumnDirection = _hasColumnDirection || hasAllButtons

    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)

    React.useEffect(() => {
        // this useEffect is for submitting form on CTRL + ENTER Key for ADA Compliance.
        const handleKeyDown = (event) => {
            if (saveForm) {
                if (event.ctrlKey && event.key === KeyboardShortcut.Enter) {
                    primaryButtonAction?.()
                }
            }
        }

        // Add event listener when the component mounts
        !disablePrimaryButton() &&
            document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [primaryButtonAction])

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget)
    }
    const handleMenuClose = () => {
        setMenuAnchor(null)
    }

    const [primaryButtonMenuAnchor, sePrimaryButtontMenuAnchor] =
        useState<null | HTMLElement>(null)
    const handlePrimaryButtonMenuOpen = (
        event: React.MouseEvent<HTMLElement>
    ) => {
        sePrimaryButtontMenuAnchor(event.currentTarget)
    }
    const handlePrimaryButtonMenuClose = () => {
        sePrimaryButtontMenuAnchor(null)
    }

    const menuCollapse = (
        <Grid container justifyContent={'flex-end'}>
            <IconButton
                color='primary'
                aria-label={t('general.drawer')}
                onClick={() => {
                    overlayConfig?.showFilterOverlay?.()
                }}
                edge='end'
            >
                {overlayConfig?.overlayIcon}
            </IconButton>
        </Grid>
    )

    const renderDropdownMenu = (
        renderDotsIcon,
        dropdownConfig,
        anchor,
        menuClose
    ) => {
        return (
            <AccessibleAction
                permissions={getAllPermissions(dropdownConfig)}
                component={
                    <div
                        style={{
                            paddingLeft: '10px',
                            paddingBottom: '0px'
                        }}
                    >
                        {renderDotsIcon && (
                            <IconButton
                                aria-label={t('general.actionDropdown')}
                                onClick={handleMenuOpen}
                            >
                                <DotMenuIcon
                                    color={theme.palette.contentPrimary}
                                />
                            </IconButton>
                        )}
                        <Menu
                            anchorEl={anchor}
                            open={Boolean(anchor)}
                            onClose={menuClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                            sx={{
                                marginTop: '40px',
                                marginRight: '50px',
                                borderRadius: '8px'
                            }}
                        >
                            {dropdownConfig?.map((menu, index) => (
                                <AccessibleAction
                                    permissions={menu?.permissions}
                                    component={
                                        <MItem
                                            key={index}
                                            onClick={() => {
                                                menu.onClick?.()
                                                menuClose?.()
                                            }}
                                            sx={{
                                                padding: '2px 30px 2px 2px'
                                            }}
                                        >
                                            {menu?.icon && (
                                                <IconButton>
                                                    <menu.icon />
                                                </IconButton>
                                            )}
                                            <Typography
                                                sx={{
                                                    fontWeight: 400,
                                                    paddingLeft: '7px'
                                                }}
                                                variant='body2'
                                            >
                                                {menu?.label}
                                            </Typography>
                                            <Divider />
                                        </MItem>
                                    }
                                />
                            ))}
                        </Menu>
                    </div>
                }
            />
        )
    }

    const showOverLayIcon =
        isMediumScreen &&
        overlayConfig?.hasFilterOverlay &&
        !primaryButtonTitle &&
        !secondaryButtonTitle &&
        !tertiaryButtonTitle

    const PageHeaderButtons = (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: {
                    md: 'flex-end',
                    sm: hasColumnDirection ? 'flex-start' : 'flex-end'
                },
                flexDirection: {
                    xs: 'row',
                    sm: 'row'
                }
            }}
        >
            {secondaryButtonTitle && (
                <AccessibleAction
                    permissions={permissions}
                    component={
                        <StyledSecondaryButton
                            size={isMediumScreen ? 'small' : 'medium'}
                            onClick={() => secondaryButtonAction?.()}
                            color='secondary'
                            variant={ButtonVariants.Contained}
                        >
                            {secondaryButtonIcon ?? null}
                            {secondaryButtonTitle}
                        </StyledSecondaryButton>
                    }
                />
            )}
            {tertiaryButtonTitle && (
                <AccessibleAction
                    permissions={permissions}
                    component={
                        <StyledTertiaryButton
                            size={isMediumScreen ? 'small' : 'medium'}
                            onClick={() => tertiaryButtonAction?.()}
                            variant='outlined'
                        >
                            {tertiaryButtonIcon ?? null}
                            {tertiaryButtonTitle}
                        </StyledTertiaryButton>
                    }
                />
            )}
            {primaryButtonTitle && (
                <AccessibleAction
                    permissions={permissions}
                    component={
                        <>
                            <StyledPrimaryButton
                                size={isMediumScreen ? 'small' : 'medium'}
                                disabled={disablePrimaryButton?.()}
                                onClick={(event) =>
                                    primaryButtonMenuConfig?.length
                                        ? handlePrimaryButtonMenuOpen?.(event)
                                        : primaryButtonAction?.()
                                }
                            >
                                {primaryButtonIcon ?? null}
                                {primaryButtonTitle}
                            </StyledPrimaryButton>
                            {primaryButtonMenuConfig?.length &&
                                renderDropdownMenu(
                                    false,
                                    primaryButtonMenuConfig,
                                    primaryButtonMenuAnchor,
                                    handlePrimaryButtonMenuClose
                                )}
                        </>
                    }
                />
            )}
            {isMediumScreen &&
                overlayConfig?.hasFilterOverlay &&
                !showOverLayIcon &&
                menuCollapse}
            {kebabMenuConfig?.length &&
                renderDropdownMenu(
                    true,
                    kebabMenuConfig,
                    menuAnchor,
                    handleMenuClose
                )}
        </Box>
    )

    const PageHeaderTabs = (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: {
                    md: hasColumnDirection ? 'flex-start' : 'flex-end',
                    sm: hasColumnDirection ? 'flex-start' : 'flex-end'
                },
                flexDirection: {
                    xs: 'row',
                    sm: 'row'
                }
            }}
        >
            {menuConfig?.()?.map((item, index) =>
                item?.isHeaderLink ? (
                    <MenuItem
                        id={item?.path}
                        label={toUpper(t(item?.label))}
                        path={item?.path}
                        key={item?.path}
                        isLanding={item?.isLanding}
                        canUserAccess={item?.canUserAccess}
                        icon={item?.icon}
                    />
                ) : null
            )}
        </Box>
    )

    const GoBackAction = () =>
        (goBackAction || defaultGoBack) && (
            <Grid item sx={{ width: '34px' }}>
                <IconButton
                    aria-label={t('general.goBack')}
                    tabIndex={0}
                    color='primary'
                    onClick={() => {
                        if (defaultGoBack) {
                            navigate(-1)
                        } else {
                            goBackAction?.()
                        }
                    }}
                >
                    <ArrowNarrowLeftIcon />
                </IconButton>
            </Grid>
        )

    return {
        pageHeader: (
            <>
                <Grid
                    container
                    direction={'row'}
                    display={'flex'}
                    justifyContent={'space-between'}
                >
                    <StyledPageHeader
                        id='page-header-parent'
                        isInsideLayout={isInsideLayout}
                        hasMenuConfig={!!menuConfig}
                        hasColumnDirection={hasColumnDirection}
                    >
                        <Grid
                            item
                            sm={hasColumnDirection ? 12 : 6}
                            xs={hasColumnDirection ? 12 : 6}
                        >
                            <Grid
                                container
                                justifyContent={
                                    isMediumScreen && 'space-between'
                                }
                                sx={{
                                    alignItems: 'center',
                                    paddingBottom: !isMediumScreen
                                        ? '12px'
                                        : '0px'
                                }}
                                sm={12}
                            >
                                <Grid
                                    item
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '8px',
                                        alignItems: 'center'
                                    }}
                                >
                                    <GoBackAction />
                                    {headerIcon ?? null}
                                    <Grid item>
                                        {headerText && (
                                            <StyledPageTitle variant='h1'>
                                                <Grid
                                                    container
                                                    display={'flex'}
                                                    flexDirection={'column'}
                                                    alignItems={'center'}
                                                >
                                                    <Grid
                                                        item
                                                        container
                                                        flexDirection={'row'}
                                                        alignItems={'center'}
                                                    >
                                                        {
                                                            backRedirectionComponent
                                                        }
                                                        {headerText}
                                                        {headerEditAction && (
                                                            <AccessibleAction
                                                                permissions={
                                                                    permissions
                                                                }
                                                                component={
                                                                    <TextWithTooltip
                                                                        tabIndex={
                                                                            -1
                                                                        }
                                                                        toolTip={
                                                                            ariaLabel
                                                                                ? [
                                                                                      ariaLabel,
                                                                                      headerText
                                                                                  ].join(
                                                                                      ' '
                                                                                  )
                                                                                : [
                                                                                      t(
                                                                                          'general.edit'
                                                                                      ),
                                                                                      headerText
                                                                                  ].join(
                                                                                      ' '
                                                                                  )
                                                                        }
                                                                        renderer={
                                                                            <Grid
                                                                                item
                                                                                ml={
                                                                                    1
                                                                                }
                                                                            >
                                                                                <IconButton
                                                                                    tabIndex={
                                                                                        0
                                                                                    }
                                                                                    onClick={() =>
                                                                                        headerEditAction()
                                                                                    }
                                                                                    aria-label={
                                                                                        ariaLabel
                                                                                            ? [
                                                                                                  ariaLabel,
                                                                                                  headerText
                                                                                              ].join(
                                                                                                  ' '
                                                                                              )
                                                                                            : [
                                                                                                  t(
                                                                                                      'general.edit'
                                                                                                  ),
                                                                                                  headerText
                                                                                              ].join(
                                                                                                  ' '
                                                                                              )
                                                                                    }
                                                                                >
                                                                                    <EditIcon
                                                                                        height={
                                                                                            20
                                                                                        }
                                                                                        width={
                                                                                            20
                                                                                        }
                                                                                    />
                                                                                </IconButton>
                                                                            </Grid>
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        )}
                                                    </Grid>

                                                    {showBreadcrumbs &&
                                                        breadcrumbComponent}
                                                </Grid>
                                            </StyledPageTitle>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    {showOverLayIcon && menuCollapse}
                                </Grid>
                            </Grid>
                        </Grid>
                        {showMenu && (
                            <Grid item sm={hasColumnDirection ? 12 : 6} lg={8}>
                                {!menuConfig ? (
                                    <>{PageHeaderButtons}</>
                                ) : (
                                    <>{PageHeaderTabs}</>
                                )}
                            </Grid>
                        )}
                    </StyledPageHeader>
                    <Divider />
                </Grid>
            </>
        )
    }
}
