import React from 'react'
import { ManageResourcesContainer, ResourcesAndServices } from '@containers'
import { useAuth } from '@hooks'
import { getAccessRoles } from '@utils'

export const ResourcesPage = () => {
    const {
        userInfo: { roles }
    } = useAuth()
    const accessedRoles = getAccessRoles(roles)
    const { isLearner, isClientAdmin } = accessedRoles

    return isLearner ? (
        <ResourcesAndServices />
    ) : (
        <ManageResourcesContainer isClientAdmin={isClientAdmin} />
    )
}
