import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { GET_SURVEY, UPDATE_SURVEY } from '@dts/graphql'
import { useFormFeedback, usePage } from '@hooks'
import {
    VerticalCourses,
    getAddedSurveyCourses,
    mapSurveyCourses
} from '@containers'
import { isMediumScreen } from '@utils'
import { BackButtonType } from '@constants'
import { SurveyCoursesSchema } from '@dts/client-utils'
import { SurveyTabEnum } from '@dts/constants'

export const EditSurveyCourses: React.FC = () => {
    const { t } = useTranslation()

    const { id } = useParams()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const status = searchParams.get('status') // Type of survey being created

    const updateSurveyConfig = {
        mutation: UPDATE_SURVEY,
        mutationName: 'updateSurvey',
        onMutationSuccess: () => {
            navigate(
                `/surveys/${
                    status === SurveyTabEnum.SURVEY ? 'all' : 'archived'
                }`
            )
        },
        isCustomMutationInput: true
    }

    const { methods, save } = useFormFeedback({
        initialValues: {
            title: null,
            topics: [],
            addedTopics: [],
            isArchived: false
        },
        query: GET_SURVEY,
        queryName: 'getSurvey',
        queryVariables: { id },
        retrieveOnMount: true,
        mapQueryResultToForm: (response) => {
            const { id, title, surveyCourses, isArchived } = response || {}

            return {
                id,
                title,
                addedCourses: mapSurveyCourses(surveyCourses),
                isArchived
            }
        },
        ...updateSurveyConfig,
        mapFormToMutationInput: (form) => {
            const { id, courses, addedCourses, isArchived } = form || {}

            const coursesAdded = courses
                ? courses?.filter((course) => course.isAdded)
                : addedCourses?.map((course) => ({ id: course.id }))

            const surveyCourses = getAddedSurveyCourses(
                coursesAdded,
                addedCourses
            )
            return {
                input: {
                    id,
                    surveyCourses,
                    isArchived
                }
            }
        },
        yupResolverSchema: SurveyCoursesSchema
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: VerticalCourses,
            componentProps: {
                addedIn: t('general.survey'),
                nestedAssignmentsOnly: true,
                subHeader: t('survey.coursesCaption')
            },
            hasHeader: true,
            preventMargin: true,
            showBreadcrumbs: true,
            backButtonConfig: {
                showBackButton: isMediumScreen?.(),
                backButtonType: BackButtonType.Custom,
                customUrl: '/surveys'
            },
            pageHeaderConfig: {
                headerText: t('edit.training.header'),
                primaryButtonTitle: t('general.save'),
                primaryButtonAction: () => methods.handleSubmit(() => save())(),
                secondaryButtonTitle: t('general.cancel'),
                secondaryButtonAction: () =>
                    navigate(
                        `/surveys/${
                            status === SurveyTabEnum.SURVEY ? 'all' : 'archived'
                        }`
                    )
            }
        }
    })

    return <>{page}</>
}
