import React from 'react'
import { useDirectory } from '@hooks'
import { GET_VIDEOS } from '@dts/graphql'
import { t } from 'i18next'
import { TextWithTooltip } from '@components'

export const ManageTraineeList = ({ status }: { status: string }) => {
    const { directory } = useDirectory({
        id: 'traineeList',
        directoryConfig: {
            config: {
                columns: [
                    {
                        field: 'vertical',
                        headerName: t('general.vertical'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={'--'}
                                tabIndex={params.tabIndex}
                            />
                        )
                    }
                ],
                hideFooter: false,
                pagination: true
            },
            enableSearch: true,
            toolbarConfig: {
                addNewButtonConfig: {
                    label: `${t('general.create')} ${t(
                        'sideNavItems.traineesList'
                    )}`
                }
            },
            query: GET_VIDEOS,
            queryName: 'getMyVideos',
            queryVariables: {
                args: {
                    tab: status
                }
            }
        }
    })

    return directory
}
