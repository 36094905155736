import React from 'react'
import { Outlet } from 'react-router-dom'
import { Layout, Signup } from '@components'
import { useAuth } from '@hooks'

export const ProtectedLayout = () => {
    const { userInfo } = useAuth()

    return userInfo?.firstLogin ? (
        <Signup />
    ) : (
        <Layout>
            <Outlet />
        </Layout>
    )
}
