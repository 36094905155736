import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const ListIcon: React.FC<SvgProps> = ({ color, width = 16, height = 16 }) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.backgroundPrimary

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 16 16`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M11.8667 6.66667C12.6134 6.66667 12.9868 6.66667 13.272 6.52134C13.5229 6.39351 13.7268 6.18954 13.8547 5.93865C14 5.65344 14 5.28007 14 4.53333V4.13333C14 3.3866 14 3.01323 13.8547 2.72801C13.7268 2.47713 13.5229 2.27316 13.272 2.14533C12.9868 2 12.6134 2 11.8667 2L4.13333 2C3.3866 2 3.01323 2 2.72801 2.14532C2.47713 2.27316 2.27316 2.47713 2.14532 2.72801C2 3.01323 2 3.3866 2 4.13333L2 4.53333C2 5.28007 2 5.65344 2.14532 5.93865C2.27316 6.18954 2.47713 6.39351 2.72801 6.52134C3.01323 6.66667 3.3866 6.66667 4.13333 6.66667L11.8667 6.66667Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M11.8667 14C12.6134 14 12.9868 14 13.272 13.8547C13.5229 13.7268 13.7268 13.5229 13.8547 13.272C14 12.9868 14 12.6134 14 11.8667V11.4667C14 10.7199 14 10.3466 13.8547 10.0613C13.7268 9.81046 13.5229 9.60649 13.272 9.47866C12.9868 9.33333 12.6134 9.33333 11.8667 9.33333L4.13333 9.33333C3.3866 9.33333 3.01323 9.33333 2.72801 9.47866C2.47713 9.60649 2.27316 9.81046 2.14532 10.0613C2 10.3466 2 10.7199 2 11.4667L2 11.8667C2 12.6134 2 12.9868 2.14532 13.272C2.27316 13.5229 2.47713 13.7268 2.72801 13.8547C3.01323 14 3.3866 14 4.13333 14H11.8667Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default ListIcon
