import { CourseFields, MessagesFragment } from '../../fragments'

import { gql } from '@apollo/client'

export const DUPLICATE_COURSE = gql`
  ${MessagesFragment('CourseResponseDto')}
  ${CourseFields('CourseDto')}
  mutation DuplicateCourse($input: DuplicateCourseArgumentsDto!) {
    duplicateCourse(input: $input) {
      ...MessagesFragment
      data {
        ...CourseFields
      }
    }
  }
`
