import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { NotificationsIcon } from '@assets'
import { Popover } from '@components'
import { useCustomTheme, useNotifications } from '@hooks'
import { isMediumScreen } from '@utils'

export const NotificationsPopover = () => {
    const { theme } = useCustomTheme()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const {
        hasUnseenNotifications,
        viewNotifications,
        NotificationsList,
        wrappedComponent,
        data,
        closePopoverRef,
        refetchNotifications,
        queryLoading
    } = useNotifications()

    return (
        <Popover
            Content={NotificationsList}
            contentProps={{
                wrappedComponent,
                data,
                closePopoverRef,
                refetchNotifications,
                queryLoading
            }}
            customIconClick={
                isMediumScreen?.()
                    ? () => {
                          navigate('/notifications')
                      }
                    : undefined
            }
            onIconClick={() => {
                if (hasUnseenNotifications) {
                    viewNotifications()
                }
            }}
            showIconBadge={hasUnseenNotifications}
            ariaLabel={t('sideNavItems.notifications')}
            popoverIcon={
                <NotificationsIcon
                    color={theme.palette.backgroundBrandPrimary}
                    fill={theme.palette.backgroundBrandPrimary}
                    height='24px'
                    width='24px'
                />
            }
        />
    )
}
