import * as yup from 'yup'

import { QuestionTypeEnum, SurveyTypeEnum } from '../../../../constants/lib'
import { isEmpty } from 'lodash'
import { hasDuplicates } from '../../utils'

export const SurveyQuestionsSchema = yup.object().shape({
  title: yup.string().trim().required('errors.required').max(200, 'errors.max'),
  course: yup
    .object()
    .shape({ surveyTitle: yup.string(), surveyId: yup.string() })
    .when(['surveyType'], function ([surveyType], schema) {
      return surveyType !== SurveyTypeEnum.Pulse
        ? schema.required('errors.required')
        : schema.nullable()
    })
    .test('course', 'survey.invalidCourse', function (value) {
      if (value?.surveyTitle && value?.surveyId !== this?.parent?.id) {
        return this.createError({
          message: `A ${this?.parent?.surveyTypeTitle} survey '${value?.surveyTitle}' already exists for this course `,
        })
      }
      return true
    }),
  topicId: yup.string().when(['surveyType'], function ([surveyType], schema) {
    return surveyType === SurveyTypeEnum.Pulse
      ? schema.required('errors.required')
      : schema.nullable()
  }),
  questions: yup
    .array()
    .of(
      yup.object().shape({
        question: yup
          .string()
          .trim()
          .required('survey.questionRequired')
          .max(1000, 'survey.questionMaxLimit')
          .test('unique-title', 'survey.duplicateQuestions', function (value) {
            const questions = this?.from?.[1]?.value?.questions
            if (questions && value) {
              const trimmedValue = value?.trim()?.toLowerCase()
              const trimmedQuestions = questions?.map((c) =>
                c?.question?.trim()?.toLowerCase(),
              )

              return hasDuplicates(trimmedQuestions, trimmedValue?.toString())
                ? false
                : true
            }

            return true
          }),
        options: yup
          .array()
          .of(
            yup.object().shape({
              title: yup
                .string()
                .trim()
                .required('survey.optionRequired')
                .max(1000, 'survey.optionMaxLimit'),
            }),
          )
          .test(
            'no-duplicate-option-titles',
            'survey.duplicateOptions',
            function (options) {
              if (!options) {
                return true
              }

              const trimmedOptionTitles = options.map((opt) => opt.title.trim())
              const uniqueOptionTitles = new Set(trimmedOptionTitles)
              return uniqueOptionTitles.size === trimmedOptionTitles.length
            },
          ),
      }),
    )
    .min(1, 'errors.minQuestions'),
    // to-do: remove after testing GT-1619
  // .test(
  //   'no-duplicate-questions',
  //   'survey.duplicateQuestions',
  //   function (value) {
  //     if (!value) {
  //       return true
  //     }

  //     const trimmedQuestions = value.map((q) => q?.question?.trim())
  //     const uniqueQuestions = new Set(trimmedQuestions)
  //     return uniqueQuestions.size === trimmedQuestions.length
  //   },
  // ),
})

export const SurveyCoursesSchema = yup.object().shape({
  courses: yup
    .array()
    .test('at-least-one-added', 'errors.minCourses', (courses) => {
      const addedCourses = courses?.some((course) => course?.isAdded)
      return addedCourses
    }),
})

export const SurveyTitleSchema = yup.object().shape({
  title: yup.string().trim().required('errors.required').max(200, 'errors.max'),
})

export const SurveySummarySchema = yup.object().shape({
  summaryEnabled: yup.boolean().nullable(),
  summaryTitle: yup
    .string()
    .nullable()
    .trim()
    .max(200, 'errors.max')
    .when(
      ['summaryDescription', 'summaryResponseIconId'],
      ([summaryDescription, summaryResponseIconId], schema) =>
        summaryDescription || summaryResponseIconId
          ? schema.required('survey.titleRequired')
          : schema,
    ),
  summaryDescription: yup.string().nullable().trim().max(1000, 'errors.max'),
})

export const SurveyFormSchema = yup.object().shape({
  singleChoiceAnswer: yup
    .string()
    .test(function (value) {
      const formValues = this.parent

      if (
        formValues?.isMainStep &&
        value === null &&
        (formValues?.surveyQuestion?.questionType?.id ===
          QuestionTypeEnum.YesNo ||
          formValues?.surveyQuestion?.questionType?.id ===
            QuestionTypeEnum.SingleChoice)
      ) {
        return this.createError({
          message: 'errors.selectAtleastOneOption',
        })
      }
      return true
    })
    .nullable(),
  multiChoiceAnswers: yup
    .object()
    .test(function (value) {
      const formValues = this.parent
      if (
        formValues?.isMainStep &&
        isEmpty(value) &&
        formValues?.surveyQuestion?.questionType?.id ===
          QuestionTypeEnum.MultiChoice
      ) {
        return this.createError({
          message: 'errors.selectAtleastOneOption',
        })
      }
      return true
    })
    .nullable(),
})

export const SurveyMultiResponsesSchema = yup.object().shape({
  surveyQuestionResponses: yup.array().of(SurveySummarySchema),
})
