import React, { useEffect } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
    useGraphQLWrapper,
    useLoader,
    usePageHeader,
    useSurveys,
    useVirtualizedScroll
} from '@hooks'
import { GET_DASHBOARD_VIDEOS, GET_ENTITY } from '@dts/graphql'
import { ShuffleForMe } from '@constants'
import { CustomLoader, EmptyState } from '@components'
import { isEmpty } from 'lodash'
import { getUrlSegmentTitleById, valueExists } from '@utils'
import { SurveyTypeEnum } from '@dts/constants'
import { addEntryToCache, cachedSurveyInfo } from '@cacheql'
import { Grid } from '@mui/material'
import { AccountInteractionsCallbackProps } from './types'
import { BrowseVideos } from './browse'

export const BrowseVideosScroll = () => {
    const { id, topicId } = useParams()
    const { t } = useTranslation()
    const { state } = useLocation()
    const [searchParams] = useSearchParams()
    const trainingProgress = searchParams.get('progress') || null
    const trainingId = searchParams.get('trainingId') || null
    const courseId = searchParams.get('courseId') || null

    const { retrieve } = useGraphQLWrapper({
        query: GET_ENTITY,
        queryName: 'getEntity',
        retrieveOnMount: false
    })

    const surveyContextData = useSurveys()

    useEffect(() => {
        if (trainingProgress === '100') {
            surveyContextData?.openSurvey(
                trainingId,
                courseId,
                SurveyTypeEnum.PostTest
            )
        } else if (valueExists(trainingProgress)) {
            surveyContextData?.openSurvey(
                trainingId,
                courseId,
                SurveyTypeEnum.PreTest
            )
        }
    }, [trainingProgress])

    useEffect(
        () => () => {
            surveyContextData?.surveyDialogUnmount()
        },
        []
    )

    useEffect(() => {
        if (courseId) {
            retrieve({ variables: { input: { id: courseId, type: 'course' } } })
        }
    }, [courseId])

    const { pageHeader } = topicId
        ? usePageHeader({
              headerText:
                  topicId === ShuffleForMe
                      ? t('shuffleForMe.title')
                      : getUrlSegmentTitleById(topicId),
              showBreadcrumbs: true,
              fetchEntityVariables: topicId
                  ? { id: topicId, type: 'topic' }
                  : undefined
          })
        : { pageHeader: <></> }

    const loadComponent = (video: Array<object>, index: number) => (
        <>
            <BrowseVideos
                video={video}
                key={video?.id}
                index={index}
                accountInteractionsCallback={accountInteractionsCallback}
                isLearnerBrowse={valueExists(trainingProgress)}
                surveyContextData={surveyContextData}
            />
        </>
    )
    const { loading } = useLoader()
    const { wrappedComponent, data, setData, queryLoading } =
        useVirtualizedScroll({
            query: GET_DASHBOARD_VIDEOS,
            queryName: 'getDashboardVideos',
            queryVariables: {
                args: {
                    ...(courseId && topicId === ShuffleForMe && { courseId }),
                    ...(topicId && topicId !== ShuffleForMe && { topicId }),
                    ...(id && { videoId: id })
                }
            },
            loadComponent
        })

    const accountInteractionsCallback = ({
        accessor,
        status,
        accountId
    }: AccountInteractionsCallbackProps) => {
        setData((prevData) => {
            const updatedData = prevData?.map((video) => {
                if (video?.creatorData?.id === accountId) {
                    const updatedVideo = {
                        ...video,
                        creatorData: {
                            ...video?.creatorData,
                            [accessor]: status
                        }
                    }
                    return updatedVideo
                } else {
                    return video
                }
            })
            return updatedData
        })
    }

    return (
        <>
            {topicId && pageHeader}
            {isEmpty(data) && !queryLoading ? (
                <EmptyState
                    title={t('browseVideos.noVideos')}
                    description={t('browseVideos.noVideosDescription')} //TODO: Waiting for figma design for empty state
                />
            ) : (
                wrappedComponent
            )}
        </>
    )
}
