import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { useFormFeedback, useGraphQLWrapper, usePage } from '@hooks'
import { isMediumScreen } from '@utils'
import {
    GET_LANGUAGES,
    GET_PREFERENCE_SETTING,
    SAVE_PREFERENCE_SETTING
} from '@dts/graphql'
import { ControlledRadioGroup, ControlledSwitch } from '@components'
import { mapDropdownOptions } from '@dts/client-utils'
import { Grid, Typography } from '@mui/material'
import { BackButtonType } from '@constants'

export const ChangeLanguage = ({
    isCaptionsLanguage,
    onSave
}: {
    isCaptionsLanguage?: boolean
    onSave: (
        extraData?:
            | object & {
                  name?: string
              }
    ) => Promise<void>
}) => {
    const { t } = useTranslation()
    const { control, formState } = useFormContext()
    const language = useWatch({ control, name: 'language' })
    const captionEnabled = useWatch({ control, name: 'captionEnabled' })

    const { data: languages } = useGraphQLWrapper({
        query: GET_LANGUAGES,
        queryName: 'getLanguages',
        retrieveOnMount: true
    })

    useEffect(() => {
        formState.isDirty && onSave?.()
    }, [language, captionEnabled])

    return (
        <>
            {isCaptionsLanguage && (
                <Grid container justifyContent={'space-between'} mt={1} mb={3}>
                    <Typography variant='body2'>
                        {t('settings.captions.description')}
                    </Typography>
                    <ControlledSwitch
                        name='captionEnabled'
                        ariaLabel={t('general.toggle.OnOff')}
                    />
                </Grid>
            )}
            <ControlledRadioGroup
                name='language'
                hasStartLabelPlacement
                isButton
                options={mapDropdownOptions(languages)}
            />
        </>
    )
}

export const LanguageSetting = () => {
    const { t } = useTranslation()

    const { methods, save, mutationLoading, queryLoading } = useFormFeedback({
        mutation: SAVE_PREFERENCE_SETTING,
        mutationName: 'savePreferenceSetting',
        mapFormToMutationInput: (form) => ({ appLanguageId: form?.language }),
        query: GET_PREFERENCE_SETTING,
        queryName: 'getPreferenceSetting',
        mapQueryResultToForm: (response) => ({
            language: response?.appLanguage?.id
        }),
        retrieveOnMount: true,
        suppressToast: true
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: ChangeLanguage,
            componentProps: { onSave: save },
            hasHeader: true,
            preventMargin: true,
            backButtonConfig: {
                showBackButton: isMediumScreen?.(),
                backButtonType: BackButtonType.Hierarchical
            },
            pageHeaderConfig: {
                headerText: t('settings.appLanguage'),
                defaultGoBack: isMediumScreen()
            }
        }
    })

    return <>{page}</>
}
