import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { setUserData } from '@cacheql'
import { Avatar, Button, Grid, useMediaQuery } from '@mui/material'
import { FollowIcon } from '@assets'
import { getFullName, useGraphQL } from '@dts/client-utils'
import {
    GET_ACCOUNT_INTERACTIONS,
    GET_CONTENT_CREATOR_BY_ID,
    SAVE_FOLLOW_STATUS
} from '@dts/graphql'
import {
    useCustomTheme,
    useGraphQLWrapper,
    useImageLoader,
    useLoader
} from '@hooks'
import { CustomLoader, Loader } from '@components'
import {
    StyledAvatarContainer,
    StyledLoaderContainer,
    StyledTypography,
    StyledTypographyBio
} from './styles'

export const UserProfile = () => {
    const userData = setUserData()
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const navigate = useNavigate()
    const { creatorId } = useParams()
    const { loading: globalLoading } = useLoader()

    const [data, setData] = useState(userData)
    const [avatarSrc, setAvatarSrc] = useState('')
    const isLoadingImage = useImageLoader(avatarSrc)

    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )

    const { data: creatorData, retrieve: retrieveCreator } = useGraphQL({
        query: GET_CONTENT_CREATOR_BY_ID,
        queryName: 'getContentCreator',
        retrieveOnMount: false,
        queryVariables: { getContentCreatorId: creatorId }
    })

    const { save: saveFollowStatus } = useGraphQLWrapper({
        mutation: SAVE_FOLLOW_STATUS,
        mutationName: 'saveFollowStatus',
        retrieveOnMount: false,
        isCustomMutationInput: true,
        onMutationSuccess: (response) => {
            retrieveAccountInteractions()
        }
    })

    const {
        retrieve: retrieveAccountInteractions,
        currentData: accountInteractions
    } = useGraphQLWrapper({
        query: GET_ACCOUNT_INTERACTIONS,
        queryName: 'getAccountInteractions',
        retrieveOnMount: false,
        queryVariables: {
            accountId: creatorId
        }
    })

    useEffect(() => {
        if (creatorId) {
            retrieveCreator()
            retrieveAccountInteractions()
        }
    }, [creatorId])

    useEffect(() => {
        if (creatorData && creatorId) {
            setData(creatorData?.data)
            setAvatarSrc(creatorData?.data?.avatarSrc)
        }
    }, [creatorData, creatorId])

    useEffect(() => {
        if (userData && !creatorId) {
            setData(userData)
            setAvatarSrc(userData?.avatarSrc)
        }
    }, [userData, creatorId])

    return (
        <Grid
            container
            p={3}
            display={'flex'}
            justifyContent={isMediumScreen ? 'center' : 'none'}
            sx={{
                background: theme.palette.backgroundSecondary
            }}
        >
            {globalLoading && <Loader />}{' '}
            {/* Show global loader if loading state is true */}
            <StyledAvatarContainer item md={2} lg={1} mx={2}>
                <Avatar
                    sx={{
                        width: 100,
                        height: 100,
                        border: `1px solid ${theme.palette.borderGrey}`
                    }}
                    src={avatarSrc} // Avatar source
                    alt={t('general.brokenImage')}
                />
                {isLoadingImage && avatarSrc && (
                    <StyledLoaderContainer>
                        <CustomLoader />
                    </StyledLoaderContainer>
                )}
            </StyledAvatarContainer>
            <Grid
                container
                md={9}
                display={'flex'}
                flexDirection={'column'}
                alignItems={isMediumScreen ? 'center' : 'none'}
                gap={1}
            >
                <Grid item>
                    <StyledTypography>
                        {getFullName(
                            data?.firstName ?? '',
                            data?.lastName ?? ''
                        )}
                    </StyledTypography>
                </Grid>
                <Grid item>
                    <StyledTypographyBio
                        sx={{ color: theme.palette.contentPrimary }}
                    >
                        {(creatorData && data?.roles?.[0]?.title) || data?.role}
                        {data?.organization ? ' | ' + data?.organization : ''}
                    </StyledTypographyBio>
                </Grid>
                {creatorData ? (
                    <Grid item>
                        <Button
                            size='large'
                            sx={{
                                height: '40px'
                            }}
                            variant={
                                accountInteractions?.following
                                    ? 'contained'
                                    : 'outlined'
                            }
                            color='primary'
                            aria-label={
                                accountInteractions?.following
                                    ? t('general.unfollow')
                                    : t('general.follow')
                            }
                            onClick={() => {
                                saveFollowStatus({
                                    input: {
                                        followingId:
                                            accountInteractions?.accountId,
                                        following:
                                            !accountInteractions?.following
                                    }
                                })
                            }}
                            startIcon={
                                <FollowIcon
                                    color={
                                        accountInteractions?.following
                                            ? theme.palette.backgroundPrimary
                                            : theme.palette
                                                  .backgroundBrandPrimary
                                    }
                                />
                            }
                        >
                            {accountInteractions?.following
                                ? t('setting.following')
                                : t('general.follow')}
                        </Button>
                    </Grid>
                ) : (
                    <Grid item>
                        <Button
                            variant='outlined'
                            size='large'
                            sx={{ height: '20px' }}
                            color='primary'
                            onClick={() => {
                                navigate('edit')
                            }}
                        >
                            <StyledTypographyBio>
                                {t('userProfile.editProfile')}
                            </StyledTypographyBio>
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}
