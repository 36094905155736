import { gql } from '@apollo/client'
import {
  MessagesFragment,
  MetaFragment,
  ResourcesFields,
} from '../../fragments'

export const GET_TENANT_RESOURCES = gql`
  ${MessagesFragment('ResourcesResponseDto')}
  ${MetaFragment('ResourcesResponseDto')}
  ${ResourcesFields('ResourceCategoryResponse')}
  query getTenantResources {
    getTenantResources {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...ResourcesFields
      }
    }
  }
`
