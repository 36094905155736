import {
  DailyReminderOptionEnum,
  DayEnum,
  MonthWeekEnum,
  MonthlyReminderOptionEnum,
  NotificationFrequencyEnum,
} from '../'

export interface MonthlyReminders {
  reminderOption: MonthlyReminderOptionEnum
  date?: number
  week?: MonthWeekEnum
  day?: DayEnum
}

export class Reminders {
  notificationFrequency: NotificationFrequencyEnum
  daily?: DailyReminderOptionEnum
  weekly?: DayEnum[]
  monthly?: MonthlyReminders
}
