import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, RoleFields } from '../../fragments'

export const SAVE_ROLE = gql`
  ${MessagesFragment('RoleResponseDto')}
  ${MetaFragment('RoleResponseDto')}
  ${RoleFields('RoleDto')}
  mutation saveRole($input: SaveRoleInputDto!) {
    saveRole(input: $input) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...RoleFields
      }
    }
  }
`
