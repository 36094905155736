import { gql } from '@apollo/client'
import { CampusFields, MessagesFragment, MetaFragment } from '../../fragments'

export const GET_CAMPUS = gql`
  ${MessagesFragment('CampusResponseDto')}
  ${MetaFragment('CampusResponseDto')}
  ${CampusFields('CampusDto')}
  query getCampus($campusId: String!, $clientId: String!) {
    getCampus(id: $campusId, clientId: $clientId) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...CampusFields
      }
    }
  }
`
