import React from 'react'
import { useCustomTheme } from '@hooks'
import { Button, Grid } from '@mui/material'
import { isMediumScreen } from '@utils'
import { can_access } from '@hoc'
import { ToolbarButtonConfig } from './types'

export const ToolbarButton: React.FC<ToolbarButtonConfig> = ({ config }) => {
    const { theme } = useCustomTheme()

    return (
        <Grid item alignItems='center' display='flex'>
            <Button
                variant={config?.variant ?? 'contained'}
                size={isMediumScreen?.() ? 'small' : 'medium'}
                startIcon={config?.icon && <config.icon />}
                aria-label={
                    config?.ariaLabel
                        ? `${config?.label} ${config?.ariaLabel}`
                        : config?.label
                }
                sx={{
                    color: config?.variant
                        ? theme.palette.contentLink
                        : theme.palette.contentPrimaryInverse,
                    borderRadius: '12px'
                }}
                onClick={config?.action}
            >
                {config?.label}
            </Button>
        </Grid>
    )
}
