import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChevronLeftIcon, ArrowRightIcon, CategoryIcon } from '@assets'
import { useCustomTheme, usePageHeader } from '@hooks'

import {
    Box,
    Drawer,
    Grid,
    IconButton,
    MenuItem,
    Typography,
    useMediaQuery
} from '@mui/material'
import { SvgProps } from '@types'
import { isEmpty, isNull, isUndefined } from 'lodash'
import { ChippedTabs } from '@components/ChippedTabs'
import { Loader } from '@components'
import { onKeyDown } from '@utils'
import {
    FilterLayoutComponentContainer,
    FilterLayoutOverlayContainer,
    ParentFilterLayoutContainer,
    StyledAvatar,
    StyledFilterLayoutMenuItem,
    StyledSubheaderGrid
} from './styles'

export type CategoriesConfig = {
    label: string
    id: string
    count?: string
    Icon?: JSX.Element
    onClick: VoidFunction
}
export type StaticCategoryConfig = {
    id: string
    name: string
    icon: JSX.Element
    onClick: VoidFunction
}

export type FilterConfig = {
    categoriesArray: Array<CategoriesConfig>
    categoriesHeader?: string
    selectedCategory?: string
    categoriesHeaderColor?: string
    staticCategoriesArray?: Array<StaticCategoryConfig>
    hasStaticCategories?: boolean
    setDataTableHeader?: VoidFunction
}

export type HeaderConfig = { header?: string; caption?: string }
export type PageHeaderConfig = {
    headerText: string
    menuConfig?: () => Array<{
        label: string
        icon: React.FC<SvgProps>
    }>
    showMenu?: boolean
    goBackAction?: VoidFunction
    showBreadcrumbs?: boolean
}
export type FilterLayoutProps = {
    pageHeaderConfig: PageHeaderConfig
    filterConfig: FilterConfig
    headerConfig?: HeaderConfig
    directoryComponent: JSX.Element
    renderComponent?: () => JSX.Element
    isTabMode?: boolean
    fallbackMessage?: string
    directoryLoading?: boolean
    OverlayIcon?: FC<SvgProps>
}

export const FilterLayout: React.FC<FilterLayoutProps> = ({
    pageHeaderConfig,
    filterConfig = {
        hasStaticCategories: false,
        staticCategoriesArray: []
    },
    headerConfig,
    directoryComponent,
    renderComponent,
    isTabMode = false,
    OverlayIcon = undefined,
    fallbackMessage = 'general.records',
    directoryLoading = false
}) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()

    const [open, setOpen] = useState(true)
    const [selectedId, setSelectedId] = useState<string>(
        filterConfig?.selectedCategory || ''
    )

    useEffect(() => {
        if (!isEmpty(filterConfig?.selectedCategory))
            setSelectedId(filterConfig?.selectedCategory)
    }, [filterConfig?.selectedCategory])
    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )

    const shouldCloseDrawer = useMediaQuery((theme) =>
        theme.breakpoints.down('1100')
    )

    useEffect(() => {
        if (shouldCloseDrawer) {
            setOpen(false)
        } else {
            setOpen(true)
        }
    }, [shouldCloseDrawer])

    const showDrawer = () => {
        setOpen((prevState) => !prevState)
    }

    const overlayObject = {
        overlayConfig: {
            showFilterOverlay: showDrawer,
            hasFilterOverlay: true,
            overlayIcon: OverlayIcon ? (
                <OverlayIcon color={theme.palette.backgroundBrandPrimary} />
            ) : (
                <CategoryIcon color={theme.palette.backgroundBrandPrimary} />
            )
        },
        hasColumnDirection: true
    }

    const { pageHeader } = usePageHeader({
        headerText: pageHeaderConfig.headerText,
        menuConfig: pageHeaderConfig.menuConfig,
        showBreadcrumbs: pageHeaderConfig?.showBreadcrumbs,
        ...(!isTabMode && overlayObject),
        showMenu: pageHeaderConfig.showMenu,
        goBackAction: pageHeaderConfig.goBackAction
    })

    useEffect(() => {
        const contentContainer = document.getElementById('content-container')

        if (contentContainer) {
            contentContainer.style.display = 'flex'
            contentContainer.style.flexDirection = 'column'
        }
        return () => {
            if (contentContainer) {
                contentContainer.style.display = 'block'
            }
        }
    }, [])

    const menuCollapse = (
        <Grid container justifyContent={'space-between'}>
            <IconButton
                color='primary'
                aria-label='drawer'
                onClick={showDrawer}
                edge='end'
            >
                <ChevronLeftIcon />
            </IconButton>
        </Grid>
    )

    const drawerContent = (
        <Grid
            item
            container
            sx={{
                borderRight: `0.5px solid ${theme.palette.borderSecondary}`
            }}
        >
            <Grid item flexDirection={'column'} md={12} width={'100%'}>
                {filterConfig?.hasStaticCategories &&
                    filterConfig?.staticCategoriesArray?.map(
                        (staticCategory: StaticCategoryConfig) => {
                            const itemColor =
                                staticCategory?.id === selectedId
                                    ? theme.palette.contentLink
                                    : theme.palette.contentPrimary
                            return (
                                <Grid item key={staticCategory.id}>
                                    <StyledFilterLayoutMenuItem
                                        onClick={() => {
                                            setSelectedId(staticCategory.id)
                                            staticCategory.onClick()
                                            setOpen(false)
                                        }}
                                        tabIndex={0}
                                        onKeyDown={(e) =>
                                            onKeyDown(
                                                e,
                                                () => {
                                                    setSelectedId(
                                                        staticCategory.id
                                                    )
                                                    staticCategory.onClick()
                                                    setOpen(false)
                                                },
                                                false
                                            )
                                        }
                                        // sx={{
                                        //     width: '100%',
                                        //     '&:hover': {
                                        //         backgroundColor:
                                        //             theme.palette
                                        //                 .BrandPrimaryContainer
                                        //     },
                                        //     borderRadius: '4px',
                                        //     padding: renderComponent
                                        //         ? '0px'
                                        //         : '24px 8px 24px 16px'
                                        // }}
                                        renderComponent={renderComponent}
                                        selected={
                                            staticCategory.id === selectedId
                                        }
                                    >
                                        <Grid
                                            container
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Grid
                                                item
                                                xs={10}
                                                sx={{
                                                    display: 'flex',
                                                    gap: '16px'
                                                }}
                                            >
                                                {staticCategory?.icon ? (
                                                    <staticCategory.icon
                                                        color={itemColor}
                                                    />
                                                ) : (
                                                    <></>
                                                )}
                                                <Typography
                                                    sx={{
                                                        color: itemColor,
                                                        fontWeight: '400',
                                                        fontSize: '15px'
                                                    }}
                                                >
                                                    {staticCategory?.name}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={2}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end'
                                                }}
                                            >
                                                <ArrowRightIcon
                                                    color={itemColor}
                                                />
                                            </Grid>
                                        </Grid>
                                    </StyledFilterLayoutMenuItem>
                                </Grid>
                            )
                        }
                    )}

                {filterConfig?.categoriesHeader && (
                    <StyledSubheaderGrid
                        item
                        color={filterConfig?.categoriesHeaderColor}
                        hasStaticCategories={filterConfig?.hasStaticCategories}
                    >
                        {filterConfig?.categoriesHeader}
                    </StyledSubheaderGrid>
                )}
                {filterConfig?.categoriesArray?.map((category, index) => {
                    const Icon = category?.Icon
                    const itemColor =
                        category?.id === selectedId
                            ? theme.palette.contentLink
                            : theme.palette.contentPrimary
                    return (
                        <Grid item key={category.id}>
                            <StyledFilterLayoutMenuItem
                                onClick={() => {
                                    setSelectedId(category.id)
                                    category.onClick()
                                    filterConfig?.setDataTableHeader &&
                                        filterConfig?.setDataTableHeader(
                                            category?.label
                                        )
                                    setOpen(false)
                                }}
                                tabIndex={0}
                                selected={category.id === selectedId}
                                renderComponent={renderComponent}
                                // sx={{
                                //     background:
                                //         category.id === selectedId
                                //             ? `${theme.palette.backgroundInfoSubtle} !important`
                                //             : theme.palette.backgroundPrimary,
                                //     padding: renderComponent
                                //         ? '0px'
                                //         : '16px 8px 16px 16px',
                                //     '&:hover': {
                                //         backgroundColor:
                                //             theme.palette.BrandPrimaryContainer
                                //     },
                                //     borderRadius: '8px',
                                //     marginBottom: '4px'
                                // }}
                            >
                                <>
                                    {renderComponent ? (
                                        renderComponent?.(category, index)
                                    ) : (
                                        <Grid
                                            container
                                            sx={{
                                                justifyContent: 'space-between',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            {Icon && (
                                                <Grid item xs={2} mt={1}>
                                                    <Icon
                                                        width={'22'}
                                                        height={'21'}
                                                        color={itemColor}
                                                    />
                                                </Grid>
                                            )}

                                            <Grid
                                                item
                                                xs={Icon ? 7 : 9}
                                                sx={{
                                                    whiteSpace: 'break-spaces',
                                                    display: 'flex'
                                                }}
                                                ml={0.5}
                                            >
                                                <Typography
                                                    variant='body2'
                                                    sx={{
                                                        color: `${itemColor} !important`
                                                    }}
                                                >
                                                    {category?.label}
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={2}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end'
                                                }}
                                            >
                                                {!isUndefined(
                                                    category?.count
                                                ) &&
                                                !isNull(category?.count) ? (
                                                    <StyledAvatar
                                                        sx={
                                                            category.id ===
                                                            selectedId
                                                                ? {
                                                                      backgroundColor:
                                                                          theme
                                                                              .palette
                                                                              .backgroundBrandPrimary,
                                                                      color: theme
                                                                          .palette
                                                                          .contentPrimaryInverse
                                                                  }
                                                                : {
                                                                      backgroundColor:
                                                                          theme
                                                                              .palette
                                                                              .backgroundSecondary,
                                                                      color: theme
                                                                          .palette
                                                                          .contentLink
                                                                  }
                                                        }
                                                    >
                                                        {category?.count}
                                                    </StyledAvatar>
                                                ) : (
                                                    <ArrowRightIcon
                                                        color={itemColor}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}
                                </>
                            </StyledFilterLayoutMenuItem>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )

    return (
        <>
            {pageHeader}
            {isTabMode && isMediumScreen && (
                <ChippedTabs
                    tabs={filterConfig?.categoriesArray?.map((category) => ({
                        ...category,
                        headerLabel: category?.label,
                        body: <>{category?.label}</>
                    }))}
                />
            )}
            {headerConfig && (
                <Grid
                    xs={12}
                    md={12}
                    my={1}
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    py={1}
                    px={2}
                    borderBottom={`0.5px solid ${theme.palette.borderSecondary}`}
                >
                    {!filterConfig?.hasStaticCategories && (
                        <Grid container display='flex' flexDirection='column'>
                            <Typography variant='h2'>
                                {headerConfig?.header}
                            </Typography>

                            <Typography variant='caption' component={'p'}>
                                {headerConfig?.caption}
                            </Typography>
                        </Grid>
                    )}

                    <Grid item>{isMediumScreen && menuCollapse}</Grid>
                </Grid>
            )}

            {filterConfig?.categoriesArray?.length ||
            filterConfig?.staticCategoriesArray ? (
                <ParentFilterLayoutContainer container xs={12} md={12}>
                    <FilterLayoutOverlayContainer
                        item
                        md={2}
                        xs={!isMediumScreen ? 12 : 0}
                    >
                        <Grid
                            container
                            flex={1}
                            style={{
                                overflow: 'hidden',
                                overflowY: 'auto',
                                position: !isMediumScreen
                                    ? 'absolute'
                                    : 'static',
                                height: !isMediumScreen ? '100%' : 'auto'
                            }}
                        >
                            {!isMediumScreen ? (
                                <>{drawerContent}</>
                            ) : (
                                <Drawer
                                    open={open}
                                    onClose={() => setOpen(false)}
                                    anchor='right'
                                    sx={{
                                        width: 250,
                                        flexShrink: 0,
                                        whiteSpace: 'nowrap',
                                        boxSizing: 'border-box',
                                        '.MuiPaper-root': {
                                            width: 250
                                        }
                                    }}
                                >
                                    {drawerContent}
                                </Drawer>
                            )}
                        </Grid>
                    </FilterLayoutOverlayContainer>
                    <FilterLayoutComponentContainer item md={10} xs>
                        {directoryComponent}
                    </FilterLayoutComponentContainer>
                </ParentFilterLayoutContainer>
            ) : (
                <Grid container xs={12} justifyContent={'center'}>
                    {<Loader />}
                    {/* TODO commented code remove after approve */}
                    {/* <Typography variant='body2'>
                            {t('general.notFound', {
                                label: t(fallbackMessage).toLowerCase()
                            })}
                        </Typography> */}
                </Grid>
            )}
        </>
    )
}
