import React from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Typography } from '@mui/material'
import { AppLogo, CheckCircleIcon, KeyIcon, XCircleIcon } from '@assets'
import { useCustomTheme } from '@hooks'
import { StyledAlertIcon } from './styles'

export const CustomConfirmation: React.FC = () => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const location = useLocation()
    const [params, setParams] = React.useState({})

    const [loading, setLoading] = React.useState()
    React.useEffect(() => {
        setLoading(true)
        const searchParams = new URLSearchParams(location.search)
        const paramsObj = {}

        for (const [key, value] of searchParams.entries()) {
            paramsObj[key] = value
        }

        setParams(paramsObj)
        setLoading(false)
    }, [location.search])

    return (
        <Grid
            container
            justifyContent={'space-around'}
            sx={{ backgroundColor: theme.palette.backgroundSecondary }}
            padding={4}
            height={'100vh'}
        >
            <Grid
                item
                xs={10}
                md={4}
                sx={{
                    textAlign: 'center',
                    backgroundColor: theme.palette.backgroundPrimary,
                    padding: 4,
                    borderRadius: '25px',
                    border: `1px solid ${theme.palette.borderGrey}`,
                    width: '200px',
                    height: 'fit-content'
                }}
                marginTop={2}
            >
                <Grid item xs={12} textAlign='center'>
                    <img src={AppLogo} />
                </Grid>
                {loading === false && (
                    <Grid item xs={12} marginTop={2}>
                        <Grid
                            container
                            justifyContent={'center'}
                            flexDirection={'column'}
                            marginTop={3}
                        >
                            <Grid
                                item
                                xs
                                display={'flex'}
                                justifyContent={'center'}
                            >
                                <StyledAlertIcon
                                    color={
                                        params.success === 'true'
                                            ? params?.message?.includes('login')
                                                ? theme.palette.dullGreen
                                                : '!unset'
                                            : theme.palette.errorDangerMedium
                                    }
                                >
                                    {params?.message?.includes('login') ? (
                                        <KeyIcon />
                                    ) : params.success &&
                                      params.success === 'true' ? (
                                        <CheckCircleIcon />
                                    ) : (
                                        <XCircleIcon />
                                    )}
                                </StyledAlertIcon>
                                <Typography
                                    variant='overline'
                                    sx={{
                                        color: theme.palette.contentPrimary,
                                        fontSize: '16px',
                                        fontWeight: 600
                                    }}
                                >
                                    {params.code}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography
                                    variant='subtitle2'
                                    sx={{ fontSize: '14px' }}
                                >
                                    {params?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                {params.success &&
                                    params.success === 'true' &&
                                    ['login', 'verified'].some((word) =>
                                        params?.message?.includes(word)
                                    ) && (
                                        <a href={`${window.location.origin}`}>
                                            <Button
                                                aria-label={'login-button'}
                                                id={'login'}
                                                variant={'contained'}
                                                size={'medium'}
                                                sx={{
                                                    mt: 4,
                                                    height: '3rem !important',
                                                    marginTop: '39px',
                                                    fontWeight: 600,
                                                    fontSize: '13px'
                                                }}
                                            >
                                                {t('general.continue')}
                                            </Button>
                                        </a>
                                    )}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}
