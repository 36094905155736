import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { controlled } from '@hoc'
import { isEmpty } from 'lodash'
import { useCustomTheme } from '@hooks'
import { InputAdornment } from '@mui/material'
import { injectAriaLabelToInnerInput } from '@utils'
import { StyledTextField } from './styles'
import { InputProps } from './types'

export const Input: React.FC<InputProps> = ({
    id,
    name = '',
    width,
    height,
    value,
    error,
    variant,
    display,
    type,
    shrink = true,
    placeholder,
    sx,
    disabled = false,
    errors,
    onKeyDown,
    helperTextConfig,
    onChange,
    ariaLabel,
    onBlur,
    StartIcon,
    ...props
}) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const [inputValue, setInputValue] = useState()
    const { formContext } = useFormContext()

    useEffect(() => {
        if (props.multiline) {
            injectAriaLabelToInnerInput(
                '.MuiInputBase-input[aria-hidden="true"]',
                ariaLabel ?? props.label
            )
        }
    }, [name])

    const helperText = (
        <>
            {errors
                ? t(errors?.[name]?.message as string)
                : helperTextConfig
                ? helperTextConfig?.text ??
                  ` ${value?.length ?? 0}/${helperTextConfig?.wordLength}`
                : null}
        </>
    )

    const handleChange = (event) => {
        const newValue = event.target.value
        // Check if the new and old value contains only spaces
        if (newValue && inputValue) {
            if (isEmpty(newValue?.trim()) && isEmpty(inputValue?.trim())) {
                return // Don't update the state if it's only spaces
            }
        }
        onChange?.(newValue)
        setInputValue(newValue)
    }

    useEffect(() => {
        setInputValue(value)
    }, [value])

    return (
        <StyledTextField
            {...props}
            label={props.label}
            aria-label={ariaLabel ?? props.label}
            type={type}
            value={inputValue}
            onChange={handleChange}
            onBlur={onBlur}
            color='primary'
            size='small'
            id={id || 'text-input'}
            variant={variant ?? 'outlined'}
            sx={{ ...sx }}
            disabled={formContext?.formEditingState?.isReadOnly || disabled}
            fullWidth={props.fullWidth}
            width={width}
            InputProps={{
                ...props.InputProps,
                id: id || 'text-input',
                'aria-labelledby': id || 'text-input',
                sx: {
                    height: height,
                    background: theme.palette.backgroundPrimary,
                    borderRadius: '12px'
                },
                inputProps: {
                    'aria-label': ariaLabel ?? props.label,
                    placeholder:
                        placeholder ?? `${t('general.enter')} ${props?.label}`,
                    onKeyDown,
                    style: { color: theme.palette.contentPrimary } // Adding this line to style placeholder
                },
                startAdornment: StartIcon ? (
                    <InputAdornment position='start'>
                        <StartIcon
                            color={theme.palette.neutralsTextLight}
                            width={18}
                            height={18}
                        />
                    </InputAdornment>
                ) : undefined
            }}
            InputLabelProps={{
                shrink,
                style: { color: theme.palette.contentPrimary }
            }} // Styling the label color
            helperText={helperText}
            // This code will be removed later on after testing
            // FormHelperTextProps={{
            //     sx: !errors
            //         ? {
            //               alignSelf:
            //                   helperTextConfig?.helperTextPosition ===
            //                   HelperTextPosition.Left
            //                       ? 'auto'
            //                       : 'flex-end'
            //           }
            //         : {}
            // }}
            error={!!errors || !!errors?.[name]?.message}
            {...props.register}
        />
    )
}

export const ControlledTextInput = controlled(Input)
