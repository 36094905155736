import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { SearchableList, TextWithTooltip } from '@components'
import { Grid, Typography } from '@mui/material'
import { SearchableListButton } from '@components/SearchableList/styles'
import { useCustomTheme, useGraphQLWrapper, useModalWithGraphQL } from '@hooks'
import { GET_COURSES, GET_TOPICS } from '@dts/graphql'
import { TopicListSchema } from '@dts/client-utils'
import { can_access } from '@hoc'
import { UserPermissionEnum } from '@dts/constants'
import { SearchableCoursesListProps } from '../types'
import { UpdateCourse } from './update-course'

export const CourseRow: React.FC = ({
    item,
    isAdded,
    updateData,
    index,
    preventNestedListUpdate,
    nestedAssignmentsOnly,
    permissions
}) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const { data: topicsData } = useGraphQLWrapper({
        query: GET_TOPICS,
        queryName: 'getTopics',
        retrieveOnMount: true
    })

    const { ...formContext } = useFormContext()
    const { openModalWithForm: openUpdateTopicsDialog } = useModalWithGraphQL({
        modalConfig: {
            Component: UpdateCourse,
            componentProps: {
                allTopics: topicsData?.data,
                preventNestedListUpdate,
                nestedAssignmentsOnly
            },
            updationConfig: {
                primaryButtonText: t('general.save'),
                header: `${
                    formContext?.formEditingState?.isReadOnly
                        ? t('general.view')
                        : t('general.edit')
                } ${t('general.course')}`
            },
            hasCancelButton: true,
            maxWidth: nestedAssignmentsOnly && 'sm'
        },
        permissions: permissions,
        initialValues: {
            id: null,
            name: null,
            addedTopics: [],
            topics: []
        },

        yupResolverSchema: !nestedAssignmentsOnly && TopicListSchema
    })
    const topicCount = item.topicsCount
    return (
        <Grid
            item
            md={12}
            xs={12}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
        >
            <Grid item md={10} xs={10} pr={1} alignItems={'center'}>
                <Typography variant='subtitle2'>
                    <TextWithTooltip
                        renderer={item?.title}
                        color={theme.palette.contentPrimary}
                    />
                </Typography>
                <Typography variant='caption' fontWeight={'500px'} tabIndex={0}>
                    {t('vertical.courseTopicCount', { topicCount })}
                </Typography>
            </Grid>
            {isAdded && (
                <Grid
                    item
                    md={2}
                    xs={2}
                    sx={{ display: 'flex', justifyContent: 'end' }}
                >
                    <SearchableListButton
                        onClick={() => {
                            openUpdateTopicsDialog(
                                {
                                    id: item?.id,
                                    name: item?.title,
                                    addedTopics: item?.courseTopics,
                                    topics: [],
                                    shouldValidateName: true
                                },
                                (form) => {
                                    const { id, topics, addedTopics } = form

                                    const topicsAdded = topics.filter(
                                        (topic) => topic.isAdded
                                    )

                                    const selectedTopics = topicsAdded?.filter(
                                        (topic) => topic?.isAssigned
                                    )

                                    item.title = form?.name
                                    item.topicsCount = selectedTopics?.length
                                    item.courseTopics = topicsAdded?.map(
                                        (topic) => ({
                                            topic: {
                                                title: topic?.title
                                            },
                                            topicId: topic?.id,
                                            duration: topic?.duration ?? 0,
                                            isAssigned: topic?.isAssigned
                                        })
                                    )

                                    updateData(index, item)
                                }
                            )
                        }}
                    >
                        {formContext?.formEditingState?.isReadOnly
                            ? t('general.view')
                            : t('general.edit')}
                    </SearchableListButton>
                </Grid>
            )}
        </Grid>
    )
}

export const SearchableCoursesList: React.FC<SearchableCoursesListProps> = ({
    addedIn = '',
    name = 'courses',
    query = GET_COURSES,
    queryName = 'getCourses',
    queryVariables = undefined,
    courseIdAccessor = 'courseId',
    preventNestedListUpdate = false,
    nestedAssignmentsOnly = false,
    permissions = []
}) => {
    const { t } = useTranslation()
    const { setValue, getValues, control } = useFormContext()
    const coursesAdded = useWatch({ control, name })
    const [courses, setCourses] = useState([])
    const addedCourses = useWatch({
        control,
        name: 'addedCourses'
    })

    const {
        queryLoading,
        retrieve,
        data: coursesData
    } = useGraphQLWrapper({
        query,
        queryName,
        queryVariables,
        retrieveOnMount: false
    })
    const isAccessible = can_access([
        UserPermissionEnum.ClientView,
        UserPermissionEnum.TrainingsCreateModify
    ])
    useEffect(() => {
        if (isAccessible) {
            retrieve()
        } else {
            const coursesUpdated: Array<object> = []
            addedCourses?.forEach((course) => {
                coursesUpdated.push({
                    id: course?.id,
                    courseId: course?.[courseIdAccessor],
                    title: course?.title,
                    isAdded: true,
                    topicsCount: course?.topicsCount ?? 0,
                    courseTopics: course?.courseTopics
                })
            })
            setValue(name, coursesUpdated)
        }
    }, [])

    useEffect(() => {
        if (!queryLoading && coursesData) {
            setCourses(
                coursesData?.data?.filter(
                    (course) => !course.isDraft && !course.isArchived
                )
            )
        }
    }, [queryLoading, coursesData])

    useEffect(() => {
        if (courses?.length && (!coursesAdded?.length || !coursesAdded)) {
            const coursesUpdated: Array<object> = []
            addedCourses?.forEach((course) => {
                coursesUpdated.push({
                    id: course?.id,
                    courseId: course?.[courseIdAccessor],
                    title: course?.title,
                    isAdded: true,
                    topicsCount: course?.topicsCount ?? 0,
                    courseTopics: course?.courseTopics
                })
            })

            courses?.forEach((course) => {
                const titleExists = coursesUpdated.some(
                    (existingCourse) => existingCourse?.courseId === course?.id
                )

                if (!titleExists) {
                    coursesUpdated.push({
                        id: course.id,
                        title: course?.title,
                        isAdded: false,
                        topicsCount: course?.topicsCount ?? 0,
                        courseTopics: course?.courseTopics
                    })
                }
            })

            setValue(name, coursesUpdated)
        }
    }, [courses, addedCourses])

    const { update: updateCourses, fields: courseFields } = useFieldArray({
        control,
        name
    })

    return (
        <SearchableList
            name={name}
            topicsData={coursesAdded}
            updateData={updateCourses}
            listType={'courses'}
            addedIn={addedIn || t('general.vertical')}
            rowComponent={CourseRow}
            showNotAdded={true}
            preventNestedListUpdate={preventNestedListUpdate}
            nestedAssignmentsOnly={nestedAssignmentsOnly}
            permissions={permissions}
        />
    )
}
