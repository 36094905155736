import { StepConnector, stepConnectorClasses, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { isMediumScreen } from '@utils'

export const StepperConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)'
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.backgroundTertiary,
        borderTopWidth: 3,
        borderRadius: 1
    }
}))

export const StyledStepperFooter = styled(Grid)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.borderSecondary}`,
    borderBottom: `1px solid ${theme.palette.borderSecondary}`,
    bottom: isMediumScreen?.() ? '55px' : 0,
    position: 'absolute',
    width: 'fill-available',
    zIndex: '10',
    backgroundColor: theme.palette.backgroundPrimary,
    justifyContent: 'space-between',
    padding: '10px 40px'
}))
