import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const WatchTimeIcon: React.FC<SvgProps> = ({
    color,
    width = '28',
    height = '28'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.borderPrimary
    const _fill = color || theme.palette.backgroundPrimary

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 29 28'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M14.0007 6.99967V13.9997L18.6673 16.333M25.6673 13.9997C25.6673 20.443 20.444 25.6663 14.0007 25.6663C7.55733 25.6663 2.33398 20.443 2.33398 13.9997C2.33398 7.55635 7.55733 2.33301 14.0007 2.33301C20.444 2.33301 25.6673 7.55635 25.6673 13.9997Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default WatchTimeIcon
