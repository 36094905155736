import { useEffect, useState } from 'react'
import { GET_VIDEO_KPI_BY_ID } from '@dts/graphql'
import { useGraphQLWrapper } from '@hooks'
import { VideoInsightProps } from './types'

export const useVideoInsight = ({
    insightTimeFrameId = '',
    videoId = ''
}: VideoInsightProps) => {
    const [insightsVideoKPIsData, setInsightsVideoKPIsData] = useState({})

    const {
        data: videoInsightsData,
        queryLoading: videoInsightsLoading,
        retrieve: retrieveVideoInsights
    } = useGraphQLWrapper({
        query: GET_VIDEO_KPI_BY_ID,
        queryName: 'getVideoKPIsById',
        retrieveOnMount: true,
        queryVariables: {
            args: {
                insightTimeFrameId,
                videoId
            }
        }
    })

    useEffect(() => {
        if (videoInsightsData && !videoInsightsLoading) {
            setInsightsVideoKPIsData(videoInsightsData?.data)
        }
    }, [videoInsightsData, videoInsightsLoading])

    return {
        insightsVideoKPIsData,
        videoInsightsLoading,
        retrieveVideoInsights
    }
}
