import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { UseGraphQLParams, useGraphQL, useOnlineState } from '@dts/client-utils'
import { isEmpty } from 'lodash'
import { fetchDataFromIndexedDB } from '@storage'
import { ResponseStatusEnum } from '@dts/constants'
import { BackendErrorOptions } from '@constants'
import { setUnauthorizedError } from '@cacheql'
import { useToasts } from './use-toast'
import { useLoader } from './use-loader'

export const useGraphQLWrapper = <
    QueryResult,
    MutationInput,
    QueryVariables = object
>(
    params: UseGraphQLParams<QueryResult, MutationInput, QueryVariables>
) => {
    const { t } = useTranslation()
    const { showToast } = useToasts()
    const { isOnline } = useOnlineState()
    const { setLoading } = useLoader()
    const [queryData, setQueryData] = useState()

    const {
        data: graphQLData,
        currentData,
        retrieve,
        save: graphQLSave,
        queryLoading,
        mutationLoading
    } = useGraphQL({
        ...params,
        onError: (errors) => {
            if (
                errors?.some(
                    (error) =>
                        error?.message === BackendErrorOptions.Unauthorized
                ) ||
                errors?.some(
                    (error) => error?.message === BackendErrorOptions.Forbidden
                )
            ) {
                setUnauthorizedError({
                    error: BackendErrorOptions.Unauthorized
                })
            }
        }
    })

    // TO-DO: Offline access for the PWA
    // const fetchAndSetIndexedDBData = async () => {
    //     const indexedDBResult = await fetchDataFromIndexedDB(
    //         params?.queryVariables,
    //         params?.queryName
    //     )
    //     setQueryData(indexedDBResult)
    //     if (!isEmpty(indexedDBResult?.data)) {
    //         params?.onQuerySuccess?.(indexedDBResult)
    //     }
    // }

    useEffect(() => {
        // TO-DO: Offline access for the PWA
        // if (isOnline) {
        //     setQueryData(graphQLData)
        // } else {
        //     fetchAndSetIndexedDBData()
        // }
        setQueryData(graphQLData)
    }, [graphQLData, queryLoading])

    const save = async (
        mutationInput: MutationInput & { id?: string },
        extraData?: QueryVariables & { name?: string }
    ) => {
        const response = await graphQLSave(mutationInput, extraData)
        const customMessage = response?.message?.toast
        if (
            response?.message?.type === ResponseStatusEnum.Success &&
            !params?.suppressToast
        ) {
            if (!params?.shouldHideToast)
                showToast(
                    'success',
                    customMessage ?? t('toast.success.generic')
                )
        } else if (response?.message?.type === ResponseStatusEnum.Error) {
            if (!params?.shouldHideToast)
                showToast('error', customMessage ?? t('toast.error.generic'))
        }
        return response
    }

    useEffect(() => {
        if (!params?.shouldHideLoader) {
            setLoading?.(mutationLoading || queryLoading)
        }
    }, [mutationLoading, queryLoading])

    return {
        data: queryData,
        currentData,
        save,
        retrieve,
        queryLoading,
        mutationLoading
    }
}
