import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import { LinearProgress } from '@components'
import {
    CheckCircleIcon,
    ClockIcon,
    FilledCheckedIcon,
    LockIcon
} from '@assets'
import { floor, isInteger, upperCase } from 'lodash'
import { useCustomTheme } from '@hooks'
import { TypographyVariants } from '@constants'
import { onKeyDown } from '@utils'
import { SurveyTypeGraphqlEnum } from '@dts/constants'
import { StyledGrid, StyledCardGrid } from './styles'
import { TopicCardProps } from './types'

export const TopicCard: FC<TopicCardProps> = (props) => {
    const {
        courseTopics,
        id: courseId,
        title: courseTitle,
        trainingId,
        surveys,
        title
    } = props || {}
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const navigate = useNavigate()
    const progressBarColors = [
        theme.palette.backgroundBrandPrimary,
        theme.palette.backgroundPositive,
        theme.palette.contentNotice,
        theme.palette.brandPurplePrimary
    ]

    const responsiveCardStyles = { xs: 11, sm: 5.8, md: 5.8, lg: 3.5 }

    const renderSurveyCard = (surveyType) => {
        const survey = surveys?.find(
            (survey) => survey?.surveyType?.id === surveyType
        )

        const isCompleted = survey?.completed

        const isPostSurveyDisabled =
            survey?.surveyType?.id === SurveyTypeGraphqlEnum.POS &&
            props?.progress !== 100

        return survey ? (
            <StyledCardGrid
                item
                container
                isDisabled={isCompleted || isPostSurveyDisabled}
                gap={0.5}
                borderColor={theme?.palette?.brandPurplePrimary}
                {...responsiveCardStyles}
                onClick={() => {
                    if (!isCompleted && !isPostSurveyDisabled) {
                        navigate(
                            `/my-trainings/surveys/?trainingId=${trainingId}&courseId=${courseId}&progress=${floor(
                                props?.progress
                            )}&surveyType=${survey?.surveyType?.id}`
                        )
                    }
                }}
            >
                <Grid item container flexDirection={'row'}>
                    <Grid item container flexDirection={'column'} xs>
                        <Typography
                            variant={TypographyVariants.caption}
                            color={theme?.palette?.contentPrimary}
                        >
                            {[
                                survey?.surveyType?.id ===
                                SurveyTypeGraphqlEnum.RRS
                                    ? upperCase(t('survey.preTest'))
                                    : upperCase(t('survey.postTest')),
                                upperCase(t('general.survey'))
                            ].join(' ')}
                        </Typography>

                        <Typography
                            variant={TypographyVariants.body2}
                            fontWeight={500}
                            color={theme?.palette?.contentPrimary}
                        >
                            {survey?.title}
                        </Typography>
                    </Grid>
                    <Grid item>
                        {isPostSurveyDisabled ? (
                            <LockIcon />
                        ) : isCompleted ? (
                            <CheckCircleIcon />
                        ) : (
                            <></>
                        )}
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    flexDirection={'row'}
                    gap={0.5}
                    alignItems={'baseline'}
                >
                    {survey?.duration ? (
                        <>
                            <ClockIcon
                                height={14}
                                width={14}
                                color={theme?.palette?.contentSecondary}
                            />
                            <Typography variant={TypographyVariants.caption}>
                                {[
                                    (survey?.duration / 60)?.toString(),
                                    survey?.duration / 60 === 1
                                        ? t('general.minApprox')
                                        : t('general.minsApprox')
                                ].join(' ')}
                            </Typography>
                        </>
                    ) : (
                        <></>
                    )}
                </Grid>
            </StyledCardGrid>
        ) : (
            <></>
        )
    }

    return (
        <StyledGrid container xs={12} md={12} p={2} gap={2}>
            {renderSurveyCard(SurveyTypeGraphqlEnum.RRS)}
            {courseTopics?.map(({ progress, topic: { title, id } }, index) => (
                <StyledCardGrid
                    item
                    gap={1}
                    key={index + id}
                    background={progressBarColors[index % 4]}
                    tabIndex={0}
                    {...responsiveCardStyles}
                    onKeyDown={(e) => {
                        onKeyDown(
                            e,
                            () => {
                                navigate(
                                    `${id}?trainingId=${trainingId}&courseId=${courseId}&progress=${floor(
                                        props?.progress
                                    )}`
                                )
                            },
                            false
                        )
                    }}
                    onClick={() => {
                        navigate(
                            `${id}?trainingId=${trainingId}&courseId=${courseId}&progress=${floor(
                                props?.progress
                            )}`
                        )
                    }}
                >
                    <Grid
                        item
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                    >
                        <Typography
                            variant={TypographyVariants.h4}
                            color={theme?.palette?.contentPrimaryInverse}
                        >
                            {title || '--'}
                        </Typography>
                        {floor(progress) === 100 && (
                            <FilledCheckedIcon
                                color={theme?.palette?.constantsWhite}
                            />
                        )}
                    </Grid>

                    <Grid item sx={{ marginTop: 1 }}>
                        <LinearProgress
                            height='6px'
                            value={floor(progress)}
                            barBackgroundColor={
                                theme?.palette?.constantsWhiteSubtle
                            }
                            progressBackgroundColor={
                                theme?.palette?.constantsWhite
                            }
                            showProgress={false}
                            hasBorder={false}
                            hasTooltip
                        />
                    </Grid>
                    <Grid item>
                        <Typography
                            variant={TypographyVariants.caption}
                            color={`${theme?.palette?.contentPrimaryInverse} !important`}
                            mt={1}
                        >
                            {floor(progress) === 0
                                ? t('general.startCourse')
                                : [
                                      `${
                                          isInteger(progress)
                                              ? progress
                                              : progress?.toFixed(2)
                                      }%`,
                                      t('general.completed')
                                  ].join(' ')}
                        </Typography>
                    </Grid>
                </StyledCardGrid>
            ))}
            {renderSurveyCard(SurveyTypeGraphqlEnum.POS)}
        </StyledGrid>
    )
}
