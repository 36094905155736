import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const VIEW_NOTIFICATIONS = gql`
  ${MessagesFragment('NotificationsResponseDto')}
  ${MetaFragment('NotificationsResponseDto')}
  mutation viewNotifications {
    viewNotifications {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
        body {
          title
          message
          referenceId
        }
        notificationCategoryId
        isSeen
        isRead
        sentAt
      }
    }
  }
`
