import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const RENAME_REPORT = gql`
  ${MetaFragment('ReportResponseDto')}
  ${MessagesFragment('ReportResponseDto')}
  mutation renameReport($input: RenameChartInputDto!) {
    renameReport(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        id
        title
      }
    }
  }
`
