import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const TrainingAndCoursesGrid = styled(Grid)(({ theme }) => ({
    borderRadius: '8px', // Access theme spacing or fallback to default value
    border: `1px solid ${theme.palette.borderSecondary}`,
    backgroundColor: theme.palette.backgroundSecondary
}))

export const StatusGrid = styled(Grid, {
    shouldForwardProp: (prop) => prop !== 'bgColor'
})<{ bgColor?: string; theme }>(({ bgColor, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    background: bgColor || theme.palette.completedGreen,
    padding: '4px 8px'
}))

export const StyledTrainingGrid = styled(Grid)`
    max-height: 475px;
    overflow-y: auto;
`
export const CoursesGrid = styled(Grid)(({ theme }) => ({
    borderLeft: `1px solid ${theme.palette.borderSecondary}`,
    maxHeight: '475px',
    overflowY: 'auto',
    backgroundColor: theme.palette.backgroundSecondary
}))
