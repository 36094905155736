import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    CardMedia,
    Chip,
    Grid,
    IconButton,
    Typography,
    useMediaQuery
} from '@mui/material'
import { PictureFrameImg, SaveIcon } from '@assets'
import { isNil } from 'lodash'
import { TruncatedText } from '@components'
import { t } from 'i18next'
import parse from 'html-react-parser'
import { useCustomTheme } from '@hooks'
import { onKeyDown } from '@utils'

export type ArticleInteraction = {
    saved?: boolean
    isHelpful?: boolean
}

export type Article = {
    id: string
    title: string
    description: string
    isFeatured?: boolean
    tags?: Array<string>
    coverImageSrc?: string
    articleInteraction?: ArticleInteraction
    duration?: number
}

export type LearningCenterCardProps = {
    article: Article
    onClick?: VoidFunction
}

export const LearningCenterCardContent: React.FC<LearningCenterCardProps> = ({
    article,
    onClick
}) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const isSmallestScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('sm')
    )

    return (
        <Grid
            container
            direction={article.isFeatured ? 'row' : 'column'}
            gap={2}
        >
            <Grid
                item
                xs={12}
                md={article?.isFeatured ? 5 : 12}
                sm={article?.isFeatured ? 5 : 12}
            >
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'relative',
                        height: '100%'
                    }}
                >
                    <CardMedia
                        component='img'
                        height={article.isFeatured ? '100%' : '170'}
                        image={article.coverImageSrc || PictureFrameImg}
                        sx={{
                            minWidth: article.isFeatured ? '412' : 'auto',
                            borderRadius: '4px'
                        }}
                        alt={article?.title}
                    />
                    {article?.isFeatured && (
                        <Chip
                            size='small'
                            variant='filled'
                            label={t('learn.featured').toUpperCase()}
                            sx={{
                                position: 'absolute',
                                top: '8px',
                                left: '8px',
                                borderRadius: '4px',
                                backgroundColor: theme.palette.yellow,
                                fontSize: '12px',
                                fontWeight: 600
                            }}
                        />
                    )}
                    <IconButton
                        color='primary'
                        aria-label={t('general.save')}
                        sx={{
                            position: 'absolute',
                            bottom: '8px',
                            right: '8px',
                            borderRadius: '9px',
                            background: article?.articleInteraction?.saved
                                ? theme.palette.backgroundBrandPrimary
                                : theme.palette.contentSecondary,
                            width: '32px',
                            height: '32px'
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                            onClick?.()
                        }}
                        onKeyDown={(e) => {
                            onKeyDown(e, onClick, true)
                        }}
                    >
                        <SaveIcon
                            color={theme.palette.neutralsBackgroundVariant}
                            fill={
                                article?.articleInteraction?.saved
                                    ? theme.palette.backgroundPrimary
                                    : 'none'
                            }
                        />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid
                item
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    justifyContent: 'center',
                    padding: article?.isFeatured ? '24px' : '0px',
                    maxWidth: '100% !important'
                }}
                xs={12}
                md={article?.isFeatured ? 6 : 12}
                sm={article?.isFeatured ? 6 : 12}
            >
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px'
                    }}
                >
                    {!article?.isFeatured && (
                        <Grid
                            item
                            xs={12}
                            md={12}
                            gap={1}
                            sx={
                                isSmallestScreen
                                    ? {
                                          display: 'flex',
                                          flexWrap: 'wrap'
                                      }
                                    : {
                                          display: '-webkit-box',
                                          WebkitBoxOrient: 'vertical',
                                          WebkitLineClamp: '1',
                                          overflow: 'hidden'
                                      }
                            }
                        >
                            {article.tags?.map((tag, index) => (
                                <Chip
                                    key={index}
                                    size='small'
                                    variant='filled'
                                    label={tag}
                                    sx={{
                                        borderRadius: '4px',
                                        backgroundColor:
                                            theme.palette.backgroundSecondary,
                                        color: theme.palette.contentPrimary,
                                        marginRight: isSmallestScreen
                                            ? '0px'
                                            : '8px'
                                    }}
                                />
                            ))}
                        </Grid>
                    )}

                    <Grid item>
                        <TruncatedText
                            text={article.title}
                            lines={article?.isFeatured ? 5 : 2}
                            fontSize={18}
                            fontWeight={600}
                        />
                        {!isNil(article.duration) && (
                            <Grid item xs={12} md={12}>
                                <Typography
                                    fontSize={12}
                                    fontWeight={500}
                                    sx={{ color: theme.palette.contentPrimary }}
                                >
                                    {`–  ${article.duration} ${t(
                                        'general.minRead'
                                    )}`}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <TruncatedText
                    text={parse(article.description || '') as string}
                    lines={article?.isFeatured ? 4 : 3}
                />
            </Grid>
        </Grid>
    )
}
