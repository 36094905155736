import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const ADD_VIDEO_FEEDBACK = gql`
  ${MetaFragment('VideoFeedbackResponseDto')}
  ${MessagesFragment('VideoFeedbackResponseDto')}
  mutation AddVideoFeedback($input: VideoFeedbackInputDto!) {
    addVideoFeedback(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        id
        reportVideoOptionId
      }
    }
  }
`
