import { useEffect, useState } from 'react'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from 'firebase/messaging'
import {
    DELETE_PUSH_NOTIFICATION_TOKEN,
    UPDATE_PUSH_NOTIFICATION_TOKEN
} from '@dts/graphql'
import config from '@config'
import { permissionGranted } from '@constants'
import { useGraphQLWrapper } from './use-graphql-wrapper'

export const firebaseConfig = {
    apiKey: config.FIREBASE_API_KEY,
    authDomain: config.FIREBASE_AUTH_DOMAIN,
    projectId: config.FIREBASE_PROJECT_ID,
    storageBucket: config.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID,
    appId: config.FIREBASE_APP_ID,
    measurementId: config.FIREBASE_MEASUREMENT_ID
}

export const usePushNotifications = () => {
    const [fcmToken, setFcmToken] = useState('')

    const app = initializeApp(firebaseConfig)

    const { save: updatePushNotificationToken } = useGraphQLWrapper({
        mutation: UPDATE_PUSH_NOTIFICATION_TOKEN,
        mutationName: 'updatePushNotificationToken',
        suppressToast: true
    })

    const { save: deletePushNotificationToken } = useGraphQLWrapper({
        mutation: DELETE_PUSH_NOTIFICATION_TOKEN,
        mutationName: 'deletePushNotificationToken',
        suppressToast: true
    })

    const deleteFcmToken = () => {
        if (fcmToken) {
            deletePushNotificationToken({ token: fcmToken })
        }
    }

    const messaging = getMessaging(app)

    const requestNotificationPermission = async () => {
        const permission = await Notification.requestPermission()
        if (permission === permissionGranted) {
            await getFcmToken()
        } else {
            // Permission denied
        }
    }

    const getFcmToken = async () => {
        try {
            const currentToken = await getToken(messaging, {
                vapidKey: config.FIREBASE_VAPID_KEY
            })

            if (currentToken) {
                setFcmToken(currentToken)
                updatePushNotificationToken({ token: currentToken })
            } else {
                // No token available
            }
        } catch (error) {
            // Notification error
        }
    }

    return {
        fcmToken,
        requestNotificationPermission,
        deleteFcmToken
    }
}
