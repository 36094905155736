import React, { FC } from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const Icon247: FC<SvgProps> = ({ color, width = 24, height = 24 }) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentNegative

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 20 20'
            fill='none'
        >
            <g clipPath='url(#clip0_24545_10916)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M2.41675 9.99935C2.41675 5.81119 5.81192 2.41602 10.0001 2.41602C12.5398 2.41602 14.7891 3.66463 16.1657 5.58317L13.9072 5.09932C13.5022 5.01255 13.1035 5.27055 13.0167 5.67558C12.93 6.0806 13.188 6.47928 13.593 6.56604L17.4829 7.39938C17.8267 7.47302 18.1755 7.29798 18.3219 6.97833L19.8486 3.645C20.0211 3.26841 19.8557 2.82329 19.4791 2.6508C19.1025 2.47832 18.6574 2.64378 18.4849 3.02037L17.5809 4.994C15.9558 2.53756 13.1679 0.916016 10.0001 0.916016C4.98349 0.916016 0.916748 4.98276 0.916748 9.99935C0.916748 15.0159 4.98349 19.0827 10.0001 19.0827C13.726 19.0827 16.9263 16.8395 18.3273 13.633C18.4931 13.2534 18.3199 12.8113 17.9403 12.6454C17.5607 12.4796 17.1186 12.6528 16.9527 13.0324C15.7819 15.7123 13.1086 17.5827 10.0001 17.5827C5.81192 17.5827 2.41675 14.1875 2.41675 9.99935ZM4.51383 11.3435C4.93295 10.9942 5.26694 10.7039 5.51579 10.4725C5.76465 10.2367 5.97203 9.99225 6.13793 9.73903C6.30383 9.48581 6.38678 9.23696 6.38678 8.99247C6.38678 8.76981 6.33439 8.59518 6.22961 8.46857C6.12483 8.34196 5.96329 8.27865 5.745 8.27865C5.52671 8.27865 5.35862 8.35287 5.24075 8.50131C5.12287 8.64538 5.06175 8.84403 5.05738 9.09725H4.16675C4.18421 8.57335 4.3392 8.17606 4.63171 7.90537C4.92859 7.63469 5.30405 7.49935 5.7581 7.49935C6.25581 7.49935 6.63782 7.63251 6.90413 7.89882C7.17045 8.16078 7.30361 8.50786 7.30361 8.94008C7.30361 9.28061 7.21193 9.60587 7.02856 9.91584C6.84519 10.2258 6.63563 10.4965 6.39988 10.7279C6.16412 10.9549 5.85633 11.23 5.4765 11.553H7.40839V12.3127H4.1733V11.6316L4.51383 11.3435ZM7.81587 11.4482V10.7279L9.95732 7.66306H11.0248V10.6493H11.6011V11.4482H11.0248V12.3782H10.1079V11.4482H7.81587ZM10.1669 8.64538L8.82438 10.6493H10.1669V8.64538ZM15.6867 11.935L16.9326 9.01695V8.52983H14.675V9.0919H16.2769L15.0216 11.935H15.6867ZM14.4064 7.49935L13.1418 12.7827H12.5001L13.7647 7.49935H14.4064Z'
                    fill={_color}
                />
            </g>
            <defs>
                <clipPath id='clip0_24545_10916'>
                    <rect
                        width={width}
                        height={height}
                        fill={theme.palette.loaderBackDrop}
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
export default Icon247
