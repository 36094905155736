import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { ControlledTextInput } from '@components'
import {
    Button,
    Divider,
    Grid,
    IconButton,
    Typography,
    MenuItem,
    useMediaQuery
} from '@mui/material'
import {
    ReportIcon,
    TextMessageIcon,
    UserUnsureIcon,
    VersionsIcon,
    XCircleIcon
} from '@assets'
import {
    ADD_VIDEO_CONVERSATION,
    GET_VIDEO_CONVERSATION
} from '@dts/graphql/lib'
import { useCustomTheme, useGraphQLWrapper } from '@hooks'
import { formatDate } from '@dts/client-utils'
import { ConversationMessageTypeEnum } from '@dts/constants'
import dayjs from 'dayjs'

export const CommunicationTab = () => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const [showVersions, setShowVersions] = useState(false)
    const [selectedId, setSelectedId] = useState<string>('')
    const { getValues, control, setValue } = useFormContext()
    const [conversationData, setConversationData] = useState([])
    const { id } = useParams()

    const newMessage = useWatch({
        control,
        name: 'message'
    })
    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )

    const { currentData, queryLoading } = useGraphQLWrapper({
        query: GET_VIDEO_CONVERSATION,
        queryName: 'getVideoConversation',
        retrieveOnMount: true,
        queryVariables: {
            videoId: id
        }
    })

    const { save } = useGraphQLWrapper({
        mutation: ADD_VIDEO_CONVERSATION,
        mutationName: 'addVideoConversationMessage'
    })

    useEffect(() => {
        if (!queryLoading && currentData?.length) {
            setConversationData(currentData)
        }
    }, [queryLoading, currentData])

    const addNewMessage = (message) => {
        if (message) {
            const newMessage = {
                message: message,
                createdAt: dayjs(new Date()).format('YYYY-MM-DD'),
                userData: { firstName: t('conversation.self'), lastName: '' }
            }
            const newConversationData = [...conversationData, newMessage]
            setConversationData(newConversationData)
            setValue('message', '')
            save({ message: message, videoId: id })
        }
    }

    const renderRoleIcon = (type) => {
        switch (type?.toLowerCase()) {
            case ConversationMessageTypeEnum.Escalate:
                return <UserUnsureIcon />
            case ConversationMessageTypeEnum.Report:
                return <ReportIcon />
            case ConversationMessageTypeEnum.Reject:
                return <XCircleIcon />
            default:
                return <></>
        }
    }

    const processMessage = (message) => {
        if (!message) return null

        const regex = /(\d+:\d+)|([a-zA-Z]+)|(\s+)/g

        const processedParts = []

        message.split(regex).forEach((segment, index) => {
            if (segment?.match(/\d+:\d+/)) {
                processedParts.push(
                    <a
                        key={index}
                        href='#'
                        onClick={(event) => {
                            event.preventDefault()
                            const [minutes, seconds] = segment
                                .split(':')
                                .map(Number)
                            const totalTimeInSeconds = minutes * 60 + seconds
                            const decimalTime = totalTimeInSeconds / 60
                            setValue('timeStamp', decimalTime)
                        }}
                    >
                        {segment}
                    </a>
                )
            } else if (segment?.match(/[a-zA-Z]+/)) {
                processedParts.push(
                    <Typography key={index} component='span' variant='body2'>
                        {segment}
                    </Typography>
                )
            } else {
                processedParts.push(segment)
            }
        })

        return <>{processedParts}</>
    }

    const displayMessage = (message) => (
        <Grid item flexDirection={'column'} py={1.5}>
            <Grid item>
                <Typography variant='subtitle2' fontSize={'12px'}>
                    {message?.userData?.role}
                </Typography>
            </Grid>
            <Grid
                item
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Grid item display={'flex'}>
                    <Grid item pr={1}>
                        <Typography variant='h4'>
                            {`${message?.userData?.firstName}`}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography variant='h5'>
                            {formatDate(message?.createdAt)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>{renderRoleIcon(message?.type)}</Grid>
            </Grid>

            {message?.title && (
                <Grid item>
                    <Typography variant='h3'>{message?.title}</Typography>
                </Grid>
            )}
            <Grid item>{processMessage(message?.message)}</Grid>
            <Divider />
        </Grid>
    )

    const showTextMessages = () => {
        setShowVersions(false)
    }
    const handleShowVersions = () => {
        setShowVersions(true)
    }
    return (
        <Grid
            container
            borderLeft={`1px solid ${theme.palette.backgroundSecondary}`}
            direction={'column'}
            md={12}
            xs={12}
            padding={1}
            position={!isMediumScreen ? 'absolute' : 'static'}
            height={!isMediumScreen ? '100%' : 'auto'}
        >
            <Grid item>
                <Grid container xs={12}>
                    <Grid item md={9} xs={10} mt={2}>
                        {!showVersions && (
                            <Typography variant='h3'>
                                {t('general.communication')}
                            </Typography>
                        )}
                        {showVersions && (
                            <Typography variant='h3'>
                                {t('general.versions')}
                            </Typography>
                        )}
                    </Grid>
                    <Grid
                        item
                        md={1.5}
                        xs={1}
                        alignContent={'center'}
                        sx={{
                            borderBottom:
                                !showVersions &&
                                `1px solid ${theme.palette.backgroundBrandPrimary}`
                        }}
                    >
                        <IconButton
                            color='primary'
                            aria-label={t('general.messages')}
                            onClick={showTextMessages}
                            edge='end'
                        >
                            <TextMessageIcon />
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        md={1.5}
                        xs={1}
                        alignContent={'center'}
                        sx={{
                            borderBottom:
                                showVersions &&
                                `1px solid ${theme.palette.backgroundBrandPrimary}`
                        }}
                    >
                        <IconButton
                            color='primary'
                            aria-label={t('general.versions')}
                            onClick={handleShowVersions}
                            edge='end'
                        >
                            <VersionsIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <Divider />
            </Grid>
            <Grid
                item
                pr={1}
                flex={1}
                sx={{
                    overflow: 'hidden',
                    overflowY: 'auto'
                }}
            >
                {!showVersions && (
                    <Grid
                        container
                        flexDirection={'column'}
                        sx={{ paddingTop: '5px' }}
                        pb={2}
                    >
                        {conversationData?.map((message, index) =>
                            displayMessage(message)
                        )}
                    </Grid>
                )}

                {showVersions && (
                    <Grid container sx={{ paddingTop: '5px' }} xs={12}>
                        {getValues('previousVersions')?.map((item, index) => (
                            <Grid
                                item
                                xs={12}
                                sx={{ padding: '2px 0px' }}
                                key={index}
                            >
                                <MenuItem
                                    onClick={() => {
                                        setSelectedId(item.id)
                                    }}
                                    selected={item.id === selectedId}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        '&:hover': {
                                            backgroundColor:
                                                theme.palette.lightBlue
                                        },
                                        padding: '10px 0px'
                                    }}
                                >
                                    <Grid
                                        container
                                        sx={{
                                            justifyContent: 'space-between',
                                            display: 'flex'
                                        }}
                                        xs={12}
                                        md={12}
                                    >
                                        <Grid
                                            item
                                            xs={7.3}
                                            sx={{
                                                whiteSpace: 'break-spaces',
                                                display: 'flex'
                                            }}
                                        >
                                            <Typography variant='h4'>
                                                {index
                                                    ? item?.title
                                                    : `${item?.title} (current)`}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={4.7} pl={3}>
                                            <Typography variant='body2'>
                                                {formatDate(item?.createdAt)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            </Grid>
                        )) ?? (
                            <Typography align={'center'} variant='body2'>
                                {t('versions.notFound')}
                            </Typography>
                        )}
                    </Grid>
                )}
            </Grid>

            {!showVersions && (
                <Grid item>
                    <Grid container flexDirection={'column'} pr={1}>
                        <Grid item xs>
                            <ControlledTextInput
                                id='message'
                                type='text'
                                name='message'
                                label={t('general.message')}
                                sx={{
                                    background: theme.palette.backgroundPrimary
                                }}
                                rows={2}
                                multiline
                            />
                        </Grid>
                        <Grid item xs>
                            <Button
                                sx={{
                                    width: '100%',
                                    color: theme.palette.contentPrimaryInverse
                                }}
                                variant='contained'
                                onClick={() => {
                                    addNewMessage(newMessage)
                                }}
                            >
                                {t('general.send')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}
