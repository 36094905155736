import React from 'react'
import { useTranslation } from 'react-i18next'
import { ParentContainer } from '@components'
import { Typography } from '@mui/material'
import { useAuth, useCustomTheme } from '@hooks'
import { GET_CLIENT_COURSES } from '@dts/graphql'
import { SearchableCoursesList } from '@containers'
import { UserPermissionEnum } from '@dts/constants'

export const EditTrainingCourses: React.FC = () => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const { userInfo } = useAuth()

    return (
        <ParentContainer container>
            <Typography
                variant='body2Medium'
                fontWeight={500}
                color={theme.palette.contentPrimary}
                pt={2}
            >
                {t('edit.training.searchText')}
            </Typography>

            <SearchableCoursesList
                addedIn={t('general.training')}
                courseIdAccessor={'tenantCourseId'}
                name='courses'
                query={GET_CLIENT_COURSES}
                queryName={'getClientCourses'}
                queryVariables={{ clientId: userInfo?.clientId }}
                preventNestedListUpdate={true}
                permissions={[UserPermissionEnum.TrainingsCreateModify]}
            />
        </ParentContainer>
    )
}
