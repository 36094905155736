import React from 'react'
import { usePageHeader } from '@hooks'
import { BackButtonType } from '@constants'
import { isMediumScreen } from '@utils'

export const ReportIssuePage = () => {
    const { pageHeader } = usePageHeader({
        headerText: 'Report Issue',
        backButtonConfig: {
            showBackButton: isMediumScreen?.(),
            backButtonType: BackButtonType.Hierarchical
        }
    })

    return pageHeader
}
