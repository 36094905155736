import { gql } from '@apollo/client'

export const BasicFields = (on: string) => gql`
    fragment BasicFields on ${on} {
        id
        title
        available
        displayOrder
    }
`
