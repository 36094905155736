import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, VideoFields } from '../../fragments'

export const GET_VIDEO_BY_ID = gql`
  ${MetaFragment('VideoDetailsResponseDto')}
  ${MessagesFragment('VideoDetailsResponseDto')}
  ${VideoFields('VideoDetailsDto')}
  query getVideo($id: String!) {
    getVideo(id: $id) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...VideoFields
        previousVersions {
          id
          title
          createdAt
        }
      }
    }
  }
`
