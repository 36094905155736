import { ArchiveIcon, CheckCircleIcon, UserIcon } from '@assets'
import { LearnerListStatus } from '@constants'
import {
    LearnerListContainer,
    LearnersContainer,
    LearnerProfileContainer,
    CreateLearnerList
} from '@containers'
import { LearnerProfilePage, RouteLayout, UserProfileEditPage } from '@pages'
import { ObjectBaseFields } from '@types'
import { getAccessRoles } from '@utils'

export const getLearnerListRoutes = (canUserAccess?: boolean) => [
    {
        path: '',
        component: LearnersContainer,
        isHeaderLink: false,
        hidePageHeader: true,
        canUserAccess
    },
    {
        path: ':learnerId',
        component: LearnerProfileContainer,
        isLanding: 'learner-list',
        hidePageHeader: true,
        isHeaderLink: false,
        canUserAccess
    },
    {
        path: 'learner-profile/:learnerId',
        component: LearnerProfileContainer,
        isLanding: 'learner-list',
        hidePageHeader: true,
        isHeaderLink: false,
        canUserAccess
    }
]
export const getLearnerRoutes = (canUserAccess?: boolean) => [
    {
        path: '',
        component: LearnerListContainer,
        componentProps: { status: LearnerListStatus.Active },
        isHeaderLink: false,
        canUserAccess,
        icon: CheckCircleIcon
    },
    {
        path: 'active',
        component: LearnerListContainer,
        componentProps: { status: LearnerListStatus.Active },
        label: 'general.active',
        isLanding: 'learner-list',
        isHeaderLink: true,
        canUserAccess,
        icon: CheckCircleIcon
    },
    {
        path: 'archived',
        component: LearnerListContainer,
        componentProps: { status: LearnerListStatus.Archived },
        label: 'general.archived',
        isHeaderLink: true,
        canUserAccess,
        icon: ArchiveIcon
    },
    {
        path: 'new',
        component: CreateLearnerList,
        isHeaderLink: false,
        isSideNav: false,
        canUserAccess,
        hidePageHeader: true
    },

    {
        path: ':learnerListId',
        component: RouteLayout,
        hidePageHeader: true,
        canUserAccess,
        isHeaderLink: false,
        isSideNav: false,
        subRoutes: [...getLearnerListRoutes(canUserAccess)]
    }
]

export const getLearnerProfileRoutes = (roles: Array<ObjectBaseFields>) => {
    const accessedRoles = getAccessRoles(roles)
    const { isLearner } = accessedRoles
    return [
        {
            path: '',
            component: LearnerProfilePage,
            isHeaderLink: false,
            canUserAccess: isLearner,
            icon: UserIcon
        },
        {
            path: 'edit',
            component: UserProfileEditPage,
            isHeaderLink: false,
            canUserAccess: isLearner
        }
    ]
}
