import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { Grid, Typography } from '@mui/material'
import {
    ControlledMultiSelectAutoComplete as ControlledAutoComplete,
    ControlledSelect,
    ControlledTextInput,
    ControlledVideoPlayer,
    ListInput,
    SearchableTags
} from '@components'
import { HelperTextPosition, ListType } from '@constants'
import { useQuery } from '@apollo/client'
import { GET_VIDEOS_DROPDOWN_DATA } from '@dts/graphql'
import { mapDropdownOptions } from '@dts/client-utils'
import { isMediumScreen } from '@utils'
import { useVideoCaptions } from '@hooks'

export const ReviewVideo = () => {
    const { t } = useTranslation()
    const [dropdownOptions, setDropdownOptions] = useState([])
    const { control, setValue } = useFormContext()

    const {
        data: dropDownsData,
        loading: dropdownsLoading,
        error: dropdownsError
    } = useQuery(GET_VIDEOS_DROPDOWN_DATA)

    const seekTime = useWatch({
        control,
        name: 'timeStamp'
    })
    const captionFileSrc = useWatch({ control, name: 'captionFileSrc' })
    const { fetchVTTFile, getVideoCaptionLoading } = useVideoCaptions()
    useEffect(() => {
        if (!dropdownsLoading && !dropdownsError) {
            setDropdownOptions(dropDownsData?.getVideoDropDownsData?.data)
        }
    }, [dropdownsLoading])

    useEffect(() => {
        captionFileSrc && fetchVTTFile?.(captionFileSrc)
    }, [captionFileSrc])
    return (
        <Grid container mt={0}>
            <Grid
                item
                display={'flex'}
                xs={12}
                md={4}
                pr={2}
                justifyContent={'center'}
                sx={{
                    height: isMediumScreen?.() && '100%',
                    paddingTop: '2%',
                    paddingLeft: '2%'
                }}
            >
                <ControlledVideoPlayer
                    name='url'
                    controls
                    width='100%'
                    height={isMediumScreen?.() ? '100%' : '60vh'}
                    sx={{ borderRadius: '16px' }}
                    captionFileSrc={captionFileSrc}
                    seekConfig={{
                        skipToTime: seekTime,
                        onSeekSuccess: () => {
                            setValue('timeStamp', 0)
                        }
                    }}
                />
            </Grid>
            <Grid
                item
                display={'flex'}
                xs={12}
                md={8}
                p={2}
                direction='column'
                sx={{ paddingTop: '2%' }}
            >
                <Grid item xs={12} md={6}>
                    <Typography variant='h3'>{t('general.about')}</Typography>
                </Grid>
                <Grid item xs={12} md={6} mb={2}>
                    <ControlledTextInput
                        id='title'
                        label='Title'
                        name='title'
                        multiline
                        rows={3}
                        helperTextConfig={{
                            wordLength: 100,
                            helperTextPosition: HelperTextPosition.Right
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={2}>
                    <ControlledTextInput
                        id='description'
                        label='Description'
                        name='description'
                        multiline
                        rows={3}
                        helperTextConfig={{
                            wordLength: 5000,
                            helperTextPosition: HelperTextPosition.Right
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={2}>
                    <ControlledTextInput
                        id='captionText'
                        label={t('general.captions')}
                        name='captionText'
                        placeholder={t('video.captions.placeholder')}
                        multiline
                        rows={6}
                        fullWidth
                        disabled={getVideoCaptionLoading}
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={3}>
                    <ControlledAutoComplete
                        name='topicIds'
                        label={t('createVideos.modal.component.topic.label')}
                        placeholder={t(
                            'createVideos.modal.component.topic.placeholder'
                        )}
                        id='category'
                        options={mapDropdownOptions(dropdownOptions?.topics)}
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={3}>
                    <ControlledSelect
                        name='skillId'
                        label={t('general.skill')}
                        ariaLabel={t('general.skill')}
                        id='skill'
                        options={
                            dropdownOptions
                                ? mapDropdownOptions(dropdownOptions.skills)
                                : []
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant='h3'>
                        {t('links.resourceLinks.header')}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} mb={2}>
                    <ListInput
                        name='hyperlinks'
                        label={t('general.link')}
                        placeholder={t('reviewVideo.links')}
                        id='list'
                        listType={ListType.List}
                        isLink={true}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant='h3'>{t('general.tags')}</Typography>
                </Grid>
                <Grid item xs={12} md={6} mb={2}>
                    <SearchableTags name='tags' customDataName='tags' />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant='h3'>
                        {t('reviewVideo.verticals.heading')}
                        <Typography variant='caption'>
                            {t('reviewVideo.verticals.caption')}
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} mb={2}>
                    <ListInput
                        name={t('reviewVideo.vertical')}
                        label='Vertical 1, Vertical 2, Vertical 3'
                        disabled={true}
                        hasHelperText={false}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
