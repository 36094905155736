import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { UserProfileForm } from '@components/Forms'
import { Grid } from '@mui/material'
import { ADD_INFO_TO_USER, GET_USER } from '@dts/graphql'
import { useFormFeedback, usePageHeader } from '@hooks'
import { SignUpSchema } from '@dts/client-utils'
import { setUserData } from '@cacheql'
import { isMediumScreen } from '@utils'
import { DEFAULT_MIN_DATE } from '@constants'

export const UserProfileEdit: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { methods, save } = useFormFeedback({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            birthday: null,
            avatarUpload: null,
            races: [],
            genders: [],
            interests: [],
            customInterests: [],
            fileUploadKey: null,
            title: null,
            school: null
        },
        mapFormToMutationInput: ({
            firstName,
            lastName,
            races,
            genders,
            birthday,
            interests,
            customInterests,
            fileUploadKey,
            title,
            school
        }) => ({
            input: {
                avatar: fileUploadKey,
                firstName,
                lastName,
                races,
                genders,
                dateOfBirth: birthday ?? DEFAULT_MIN_DATE,
                interests: interests?.filter(
                    (interest) => !customInterests?.includes(interest)
                ),
                customInterests,
                title,
                organization: school
            }
        }),
        mutation: ADD_INFO_TO_USER,
        mutationName: 'addInformationToUser',
        isCustomMutationInput: true,
        onMutationSuccess: (response) => {
            const user = response?.data
            setUserData({
                ...setUserData(),
                id: user?.id,
                firstName: user?.firstName,
                lastName: user?.lastName,
                avatarSrc: user?.avatarSrc,
                email: user?.email,
                organization: user?.organization
            })

            if (location?.pathname?.includes('my-account')) {
                navigate('/my-account')
            }
        },
        query: GET_USER,
        queryName: 'getUser',
        retrieveOnMount: true,
        mapQueryResultToForm: ({
            firstName,
            lastName,
            email,
            races,
            genders,
            dateOfBirth,
            avatarSrc,
            interests,
            customInterests,
            title,
            organization
        }) => ({
            firstName,
            lastName,
            email,
            avatarUpload: avatarSrc,
            birthday: dateOfBirth ?? null,
            races: races?.map((i) => i?.id),
            genders: genders?.map((i) => i?.id),
            interests: [
                ...(interests ? [...interests.map((i) => i?.id)] : []),
                ...(customInterests ?? [])
            ],
            customInterests,
            title,
            school: organization
        }),
        yupResolverSchema: SignUpSchema
    })

    const getHeaderConfig = {
        headerText: t('pageHeader.titleEdit'),
        primaryButtonTitle: t('general.save'),
        primaryButtonAction: methods.handleSubmit(save),
        saveForm: true
    }

    const { pageHeader } = usePageHeader(getHeaderConfig)

    return (
        <>
            {pageHeader}
            <FormProvider {...methods}>
                <Grid container justifyContent='space-evenly'>
                    <UserProfileForm />
                </Grid>
            </FormProvider>
        </>
    )
}
