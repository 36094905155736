import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { useFormFeedback, useGraphQLWrapper, usePage } from '@hooks'
import { isMediumScreen } from '@utils'
import {
    GET_NOTIFICATION_PREFERENCES,
    GET_USER_NOTIFICATION_PREFERENCES,
    SAVE_USER_NOTIFICATION_PREFERENCES
} from '@dts/graphql'
import { BackButtonType } from '@constants'
import { Grid, Typography, Divider } from '@mui/material'
import { ControlledSwitch } from '@components'
import { SettingSubRouteProps } from './types'

export const ConfigureNotifications = ({ onSave }: SettingSubRouteProps) => {
    const { formState } = useFormContext()
    const formValues = useWatch()
    const { t } = useTranslation()

    const { currentData: notificationsData } = useGraphQLWrapper({
        query: GET_NOTIFICATION_PREFERENCES,
        queryName: 'getNotificationPreferences'
    })

    useEffect(() => {
        formState.isDirty && onSave?.()
    }, [formValues])

    return (
        <>
            <Grid container flexDirection='column'>
                {notificationsData?.map((notificationType) => (
                    <Grid item mb={4} key={notificationType?.id}>
                        <Typography variant='h3' sx={{ marginBottom: '15px' }}>
                            {notificationType?.title}
                        </Typography>
                        {notificationType?.notificationPreferences?.map(
                            (notification) => (
                                <>
                                    <Grid
                                        container
                                        justifyContent={'space-between'}
                                        alignItems={'center'}
                                        key={notification?.id}
                                    >
                                        <Grid item display={'flex'} gap={2}>
                                            <Typography variant='body2'>
                                                {notification?.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <ControlledSwitch
                                                name={notification?.id}
                                                ariaLabel={[
                                                    notification?.title,
                                                    t('general.toggle.OnOff')
                                                ].join(' ')}
                                                // This type will be imported from constants as soon as backend moves the enum there
                                                disabled={
                                                    formValues['ALL'] ===
                                                        true &&
                                                    notificationType?.id !==
                                                        'ALL'
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider
                                        sx={{
                                            marginBottom: '5px',
                                            marginTop: '5px'
                                        }}
                                    />
                                </>
                            )
                        )}
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export const NotificationsSetting = () => {
    const { t } = useTranslation()

    // These will be imported from constants as soon as backend moves the enum there
    const initialValues = {
        NTR: false,
        NTA: false,
        RCT: false,
        RME: false,
        TCD: false,
        WRC: false,
        VSE: false,
        SEM: false,
        HUP: false,
        VSP: false,
        NVP: false,
        VFP: false,
        NVE: false,
        WVS: false,
        SRC: false,
        VFE: false,
        ALL: false
    }

    const { methods, save } = useFormFeedback({
        mutation: SAVE_USER_NOTIFICATION_PREFERENCES,
        mutationName: 'saveUserNotificationPreferences',
        mapFormToMutationInput: (form) => ({
            notificationPreferenceIds: Object.keys(form).filter(
                (key) => form?.[key] === true
            )
        }),
        query: GET_USER_NOTIFICATION_PREFERENCES,
        queryName: 'getUserNotificationPreferences',
        mapQueryResultToForm: (response) => {
            const mappedValues = {}
            response?.forEach((preference) => {
                mappedValues[preference?.id] = true
            })

            Object?.keys(initialValues)?.map((val) => {
                if (mappedValues?.[val] === undefined) {
                    mappedValues[val] = false
                }
            })

            return mappedValues
        },
        initialValues,
        retrieveOnMount: true,
        suppressToast: true
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: ConfigureNotifications,
            componentProps: { onSave: save },
            hasHeader: true,
            preventMargin: true,
            backButtonConfig: {
                showBackButton: isMediumScreen?.(),
                backButtonType: BackButtonType.Hierarchical
            },
            pageHeaderConfig: {
                headerText: t('setting.manageNotifications')
            }
        }
    })

    return <>{page}</>
}
