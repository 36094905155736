import React from 'react'
import { SvgProps } from '@types'

const InfoIcon: React.FC<SvgProps> = ({
    color = '#35383E',
    height = '20',
    width = '21'
}) => (
    <svg
        width={width}
        height={height}
        viewBox='0 0 21 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g id='icons/info'>
            <path
                id='Icon'
                d='M10.3334 13.3337V10.0003M10.3334 6.66699H10.3417M18.6667 10.0003C18.6667 14.6027 14.9358 18.3337 10.3334 18.3337C5.73102 18.3337 2.00006 14.6027 2.00006 10.0003C2.00006 5.39795 5.73102 1.66699 10.3334 1.66699C14.9358 1.66699 18.6667 5.39795 18.6667 10.0003Z'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
    </svg>
)

export default InfoIcon
