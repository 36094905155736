import React, { useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { controlled } from '@hoc'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import { IconButton, Tooltip, useMediaQuery } from '@mui/material'
import {
    CaptionsIcon,
    CollapseIcon,
    DotMenuIcon,
    ExpandedIcon,
    ReplayIcon,
    ReportIcon,
    ShowLess,
    SpeakerIcon
} from '@assets'
import { IconContainer } from '@components/IconContainer'
import { DropdownMenu } from '@components/DropdownMenu'
import {
    CaptionMode,
    ReportFeedbackTypeEnum,
    ReportVideoEnum
} from '@constants'
import {
    useAuth,
    useCustomTheme,
    useGraphQLWrapper,
    useModalWithGraphQL,
    useVideo
} from '@hooks'
import { ADD_VIDEO_FEEDBACK } from '@dts/graphql/lib'
import { getAccessRoles } from '@utils'
import { ReportVideoSchema } from '@dts/client-utils'
import { useSurveys } from '@hooks/use-surveys'
import { cachedSurveys, surveyStepsInfo } from '@cacheql'
import { isEmpty, pullAt } from 'lodash'
import { SurveyTypeEnum } from '@dts/constants/lib'
import {
    ActionIconGrid,
    DotMenuGrid,
    StyledMetadataGrid,
    StyledReactPlayer,
    StyledReplayButton,
    StyledVideoPlayer
} from './styles'
import { VideoPlayerProps } from './types'
import { Report } from './report-dialog'
import { ReportSuccess } from './report-success-dialog'

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
    name,
    width,
    height,
    value,
    seekConfig,
    disableBar = false,
    isSwipeable = false,
    isFirstEntry = false,
    hasMuteAction = false,
    menuConfig,
    thumbnail,
    MetaDataComponent,
    actionIcons,
    captionFileSrc,
    hasMargin = false,
    videoId,
    index,
    showArrowIcons = false,
    handleWatchTime = false,
    isLearnerBrowse = false,
    surveyContextData,
    sx = {}
}) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()

    const { surveyForLearnerLoading, isSurveyOpened, onVideoEnded } =
        surveyContextData || {}

    const {
        scrollToNextItem,
        scrollToPreviousItem,
        videoVolume,
        setVideoVolume,
        saveVideoInteraction,
        listRef,
        setIsMuted,
        isMuted,
        itemHeight
    } = useVideo()
    const playerRef = useRef(null)
    const videoRef = useRef(null)
    const replayButtonRef = useRef(null)
    const [showGrid, setShowGrid] = useState(false)
    const [isReady, setIsReady] = useState(false)

    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )
    const {
        userInfo: { roles }
    } = useAuth()
    const accessedRoles = getAccessRoles(roles)
    const isLearner = accessedRoles?.isLearner
    const [searchParams] = useSearchParams()
    const trainingId = searchParams.get('trainingId') || ''
    const courseId = searchParams.get('courseId') || ''
    const { topicId } = useParams()

    const resetAndPauseVideo = (videoElement, preventReset) => {
        if (videoElement) {
            if (!preventReset) {
                videoElement.currentTime = 0
            } // Reset video to the beginning
            videoElement?.pause() // Pause the video
        }
    }

    useEffect(() => {
        if (seekConfig?.skipToTime) {
            if (videoRef?.current) {
                const timeInSeconds = Math.round(seekConfig?.skipToTime * 60)
                videoRef.current.currentTime = timeInSeconds
                videoRef?.current?.pause()
                seekConfig?.onSeekSuccess?.()
            }
        }
    }, [seekConfig?.skipToTime])

    useEffect(() => {
        if (playerRef?.current && videoVolume !== undefined && isReady) {
            playerRef?.current?.volume(videoVolume)
        }
    }, [videoVolume, isReady])

    useEffect(() => {
        if (isReady && playerRef?.current) {
            playerRef?.current?.muted(isMuted)
        }
    }, [isMuted, isReady])

    useEffect(() => {
        if (isSurveyOpened) {
            resetAndPauseVideo(videoRef?.current, true)
        }
    }, [isSurveyOpened])

    const saveWatchTime = () => {
        if (videoId) {
            saveVideoInteraction({
                videoId,
                watchTime: videoRef?.current?.currentTime,
                ...(courseId &&
                    trainingId && {
                        watchedLocation: {
                            courseId,
                            trainingId
                        }
                    })
            })
        }
    }

    const handleIntersection = (entries) => {
        entries.forEach((entry) => {
            if (
                !entry.isIntersecting &&
                !playerRef?.current?.paused() &&
                handleWatchTime
            ) {
                saveWatchTime()
            }
            if (!entry.isIntersecting) {
                resetAndPauseVideo(
                    videoRef?.current,
                    isLearnerBrowse ? true : false
                )
            } else {
                if (isLearnerBrowse) {
                    if (!isSurveyOpened && !surveyForLearnerLoading) {
                        playerRef?.current?.play()?.catch((error) => {
                            // error
                        })
                    }
                } else {
                    isSwipeable &&
                        playerRef?.current?.play()?.catch((error) => {
                            // error
                        })
                }
            }
        })
    }

    useEffect(() => {
        if (value) {
            // Initialize the Video.js player when the component mounts
            playerRef.current = videojs(videoRef?.current, {
                name,
                controls: true,
                preload: 'auto',
                experimentalSvgIcons: true,
                textTrackSettings: false,
                bigPlayButton: isSwipeable || isLearnerBrowse ? false : true,
                controlBar: {
                    skipButtons: { backward: 5, forward: 5 },
                    fullscreenToggle: false,
                    pictureInPictureToggle: false
                },

                tracks: [
                    {
                        kind: 'captions',
                        label: 'English',
                        src: captionFileSrc, // Replace with the path to your .vtt file
                        srclang: 'en',
                        default: true // Set this track as default
                    }
                ]
            })

            playerRef.current.ready(() => {
                const controlBar = playerRef.current.controlBar

                // Disable auto-hide of the control bar and progress bar
                controlBar.options.autoHide = false
                controlBar.progressControl.options.autoHide = false

                setIsReady(true)

                // Show the progress control if it's hidden by default
                controlBar.progressControl.show()
            })

            // Set the video source
            playerRef.current.src({ type: 'video/mp4', src: value })

            // Disable the progress bar
            disableBar && playerRef.current.controlBar.progressControl.disable()
        }

        playerRef?.current?.on('volumechange', function (e) {
            const muted = playerRef?.current.muted()
            setIsMuted(muted)
            setVideoVolume(playerRef?.current?.volume())
        })

        playerRef?.current?.on('ended', () => {
            handleWatchTime && saveWatchTime()
            resetAndPauseVideo(videoRef?.current)
            onVideoEnded?.()
            // Show the replay button
            if (isMediumScreen && replayButtonRef?.current) {
                replayButtonRef.current.style.display = 'block'
            }
        })

        if (isSwipeable) {
            // Enable play/pause on touch on mobile devices
            playerRef?.current?.on('touchend', () => {
                setShowGrid(true)
                if (playerRef?.current?.paused()) {
                    playerRef?.current?.play()?.catch((error) => {
                        // error
                    })
                } else {
                    playerRef?.current?.pause()?.catch((error) => {
                        // error
                    })
                }

                setTimeout(() => {
                    setShowGrid(false)
                }, 5000)
            })

            playerRef?.current?.on('loadedData', function () {
                if (
                    isFirstEntry &&
                    isLearnerBrowse &&
                    !isSurveyOpened &&
                    !surveyForLearnerLoading
                ) {
                    if (playerRef?.current?.paused()) {
                        playerRef?.current?.play()?.catch((error) => {
                            // error
                        })
                    }
                }
            })

            // Hide the replay button when the video starts playing
            playerRef?.current?.on('play', () => {
                replayButtonRef.current.style.display = 'none'
            })

            // Hide the replay button when clicked
            replayButtonRef.current.onclick = () => {
                playerRef?.current?.play() // Start playing the video
            }
        }

        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.2
        })

        observer?.observe(videoRef?.current)

        return () => {
            observer.disconnect()
        }
    }, [value])

    const toggleCaptions = () => {
        if (playerRef?.current) {
            const player = playerRef?.current
            const tracks = player?.textTracks()

            for (let i = 0; i < tracks.length; i++) {
                const track = tracks[i]
                if (track.kind === CaptionMode.CAPTIONS) {
                    if (track.mode === CaptionMode.SHOWING) {
                        track.mode = CaptionMode.DISABLED
                    } else {
                        track.mode = CaptionMode.SHOWING
                    }
                }
            }
        }
    }

    /**
     * The below code is used to open model for report a video
     */
    const addVideoFeedbackConfig = {
        mutation: ADD_VIDEO_FEEDBACK,
        mutationName: 'addVideoFeedback',
        onMutationSuccess: (res) => false,
        isCustomMutationInput: true
    }

    const initialValues = {
        selectedOption: '',
        description: undefined
    }

    const getMutationInput = (
        { selectedOption, description },
        hasReportVideo
    ) => ({
        input: {
            videoId,
            ...(hasReportVideo
                ? { reportVideoOptionId: selectedOption }
                : {
                      showLessVideoOptionId: selectedOption
                  }),
            ...(selectedOption === ReportVideoEnum.other && {
                description
            }),
            feedbackType: hasReportVideo
                ? ReportFeedbackTypeEnum.REPORT_VIDEO
                : ReportFeedbackTypeEnum.SHOW_LESS
        }
    })

    const { save } = useGraphQLWrapper({ ...addVideoFeedbackConfig })

    const { openDialog: openReportVideoDialog } = useModalWithGraphQL({
        ...addVideoFeedbackConfig,

        mapFormToMutationInput: (input) => getMutationInput(input, true),

        initialValues,
        modalConfig: {
            maxWidth: 'sm',
            Component: () => <Report isReportVideo={true} />,
            creationConfig: {
                primaryButtonText: t('general.confirm'),
                header: t('general.reportVideo')
            },
            FeedbackComponent: () => (
                <ReportSuccess
                    header={t('showLessSuccessDialog.header')}
                    message={t('reportVideoSuccessDialog.message')}
                    link={''}
                />
            )
        },
        yupResolverSchema: ReportVideoSchema
    })

    /**
     * The below code is used to open model for show less a video
     */

    const { openDialog: openShowLessDialog } = useModalWithGraphQL({
        ...addVideoFeedbackConfig,

        mapFormToMutationInput: (input) => getMutationInput(input, false),

        initialValues,
        modalConfig: {
            maxWidth: 'sm',
            Component: () => <Report isReportVideo={false} />,
            creationConfig: {
                primaryButtonText: t('general.confirm'),
                header: t('general.showLess')
            },
            FeedbackComponent: () => (
                <ReportSuccess
                    header={t('showLessSuccessDialog.header')}
                    message={t('showLessSuccessDialog.message')}
                    link={t('showLessSuccessDialog.link')}
                />
            )
        },
        yupResolverSchema: ReportVideoSchema
    })

    const playerMenuConfig = {
        label: t('general.captions.onOff'),
        icon: CaptionsIcon,
        onClick: () => toggleCaptions()
    }

    const learnerMenuConfig = isLearner
        ? [
              {
                  label: t('general.reportVideo'),
                  icon: ReportIcon,
                  onClick: () => openReportVideoDialog()
              },
              {
                  label: t('general.showLess'),
                  icon: ShowLess,
                  onClick: () => openShowLessDialog()
              }
          ]
        : []

    const renderDotMenu = () => (
        <DotMenuGrid>
            <DropdownMenu
                menuConfig={[
                    ...menuConfig,
                    ...learnerMenuConfig,
                    playerMenuConfig
                ]}
                MenuButton={() => (
                    <IconButton
                        color='inherit'
                        aria-label={t('general.actionDropdown')}
                        sx={{
                            backgroundColor:
                                ' rgba(27, 27, 27, 0.5); !important',
                            '&:hover': {
                                backgroundColor: 'rgba(51, 51, 51, 0.5);'
                            }
                        }}
                    >
                        <DotMenuIcon
                            color={theme.palette.contentPrimaryInverse}
                        />
                    </IconButton>
                )}
            />
        </DotMenuGrid>
    )

    const renderAction = (action, index) => (
        <ActionIconGrid bottom={index * 55}>
            <IconContainer
                displayFlex={true}
                color={'rgba(27,27,27, 0.5);'}
                sx={{
                    padding: '5px',
                    '&:hover': {
                        backgroundColor: 'rgba(51, 51, 51, 0.5);'
                    }
                }}
                onClick={action?.onClick}
            >
                <Tooltip title={action?.label} placement='top-end'>
                    <IconButton aria-label={action?.label} tabIndex={0}>
                        <action.icon color={theme.palette.backgroundPrimary} />
                    </IconButton>
                </Tooltip>
            </IconContainer>
        </ActionIconGrid>
    )

    const renderActionIcons = () => (
        <>
            {hasMuteAction &&
                renderAction(
                    {
                        icon: () => <SpeakerIcon muted={isMuted} />,
                        onClick: () => {
                            // This code will be removed after testing
                            // const muted = playerRef?.current?.muted()
                            // playerRef?.current?.muted(!muted)
                            setIsMuted((prev) => !prev)
                        }
                    },
                    1
                )}

            {showArrowIcons && (
                <>
                    {index !== 0 &&
                        renderAction(
                            {
                                icon: () => (
                                    <ExpandedIcon
                                        width={32}
                                        height={32}
                                        color={
                                            theme.palette
                                                .neutralsBackgroundVariant
                                        }
                                    />
                                ),
                                label: [
                                    t('general.previous'),
                                    t('general.video')
                                ].join(' '),
                                onClick: () => {
                                    scrollToPreviousItem()
                                }
                            },
                            5
                        )}

                    {index !== listRef?.current?.props?.itemCount - 1 &&
                        renderAction(
                            {
                                icon: () => (
                                    <CollapseIcon
                                        width={32}
                                        height={32}
                                        color={
                                            theme.palette
                                                .neutralsBackgroundVariant
                                        }
                                    />
                                ),
                                label: [
                                    t('general.next'),
                                    t('general.video')
                                ].join(' '),
                                onClick: () => {
                                    scrollToNextItem()
                                }
                            },
                            4.1
                        )}
                </>
            )}

            {actionIcons &&
                actionIcons?.map((actionIcon, index) => (
                    <>
                        {renderAction(
                            actionIcon,
                            hasMuteAction ? index + 2 : index + 1
                        )}
                    </>
                ))}
        </>
    )

    const renderMetadataComponent = () =>
        showGrid && <StyledMetadataGrid>{MetaDataComponent}</StyledMetadataGrid>

    return (
        <StyledReactPlayer
            id={videoId}
            isSwipeable={isSwipeable}
            sx={sx}
            hasMargin={hasMargin}
            height={height ?? '100%'}
            width={width ?? '100%'}
            itemHeight={itemHeight}
        >
            {menuConfig && renderDotMenu()}
            <div data-vjs-player>
                <StyledVideoPlayer
                    ref={videoRef}
                    sx={sx}
                    className='video-js vjs-theme-city video-js'
                    isSwipeable={isSwipeable}
                    {...(thumbnail
                        ? {
                              poster: thumbnail
                          }
                        : {})}
                />
            </div>
            {isSwipeable && (
                <StyledReplayButton
                    ref={replayButtonRef}
                    className='replay-button'
                >
                    <ReplayIcon />
                </StyledReplayButton>
            )}

            {renderActionIcons()}

            {isSwipeable && renderMetadataComponent()}

            {}
        </StyledReactPlayer>
    )
}

export const ControlledVideoPlayer = controlled(VideoPlayer)
