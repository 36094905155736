import { Grid, Typography } from '@mui/material'
import styled from '@emotion/styled'

type Props = {
    dragOver?: boolean
    preview?: boolean
    hasError?: boolean
}
export const ErrorContainer = styled(Grid)(({ theme }) => ({
    background: `${theme.palette.backgroundSecondary}`,
    height: '100%',
    display: 'flex',
    justifyContent: 'center'
}))

export const StyledGridAccessDenied = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    height: '90%'
}))

export const StyledGrid = styled(Grid)(({ theme }) => ({
    height: '55%',
    marginTop: '100px',
    background: `${theme.palette.backgroundPrimary}`,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '32px',
    boxShadow: '0px 16px 40px -16px rgba(0, 0, 0, 0.70)',
    borderRadius: '10px'
}))

export const StyledTypographyHeader = styled(Typography)(({ theme }) => ({
    color: theme.palette.contentPrimary,
    textAlign: 'center',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '130%',
    letterSpacing: '-0.48px'
}))

export const StyledTypographySubHeader = styled(Typography)(({ theme }) => ({
    color: theme.palette.contentPrimary,
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '170%',
    letterSpacing: '0.16px'
}))

export const StyledAnchorTag = styled('a')(({ theme }) => ({
    color: theme.palette.contentLink,
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0.16px',
    cursor: 'pointer'
}))

export const AccessDeniedMessage = styled(Typography)(({ theme }) => ({
    color: theme.palette.contentPrimary,
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '140%'
}))
