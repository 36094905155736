import React, { useEffect, useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { EmptyState, LineChart } from '@components'
import { isBoolean, isEmpty } from 'lodash'
import { EmptyReportStateImg } from '@assets'
import {
    CHART_PANEL_TYPE,
    REPORT_BREAK_DOWN_FIELDS,
    REPORT_DATA_TABLE_COLUMNS,
    REPORT_TIME_FRAME
} from '@constants'
import HighchartsReact from 'highcharts-react-official'
import { SAVE_REPORT_THUMBNAIL } from '@dts/graphql'
import { useGraphQLWrapper } from '@hooks'
import { FileTypeEnum, useS3 } from '@dts/client-utils'
import { setUserData } from '@cacheql'
import { getChartThumbnail } from '@utils'
import { ReportsPanel } from '../report-library-panel'
import { setBreakdown, getGroupedData } from './data-view/get-series-data'
import { TimelinePanel } from './timeline-panel'
import { generateIntervals } from './data-view/get-intervals'
import { generateCustomIntervals } from './data-view/get-custom-intervals'
import { DataView } from './data-view'
import {
    breakdownData,
    createDataSet,
    getSeriesData,
    getXAxis,
    transformData,
    transformDataForChart
} from './data-view/report-utils'

export const ReportView = (props) => {
    const {
        chartData,
        retrieveGetReport,
        libraryId,
        reportId,
        getReportByIdLoading
    } = props || {}
    const chartRef = useRef<HighchartsReact.RefObject>(null)
    const [seriesData, setSeriesData] = useState([])
    const [breakdowns, setBreakdowns] = useState({
        age: true,
        interests: true,
        trainings: true,
        course: true,
        survey: true
    })
    const [timelineIntervals, setTimelineIntervals] = useState([])
    const { t } = useTranslation()
    const { getValues, control, setValue } = useFormContext()
    const { uploadFile, key, s3Object } = useS3({})
    const isApplied = useWatch({ control, name: 'isApplied' })
    const id = useWatch({ control, name: 'id' })
    const thumbnailSrc = useWatch({ control, name: 'thumbnailSrc' })
    const [isThumbnailUpload, setIsThumbnailUpload] = useState(true)
    const user = setUserData()

    useEffect(() => {
        const {
            reportGranularity,
            reportTimeFrame,
            breakdown,
            customDateRange
        } = getValues()
        if (chartData && isBoolean(isApplied)) {
            let _timelineIntervals = []
            if (reportTimeFrame === REPORT_TIME_FRAME.CUSTOM) {
                _timelineIntervals = generateCustomIntervals(
                    customDateRange?.[0],
                    customDateRange?.[1],
                    reportGranularity
                )
            } else {
                _timelineIntervals = generateIntervals(
                    reportTimeFrame,
                    reportGranularity
                )
            }
            setTimelineIntervals(_timelineIntervals)
            setValue('isApplied', false)
            const correctedSeriesData = getGroupedData(
                chartData,
                _timelineIntervals,
                reportGranularity,
                setBreakdown(breakdown),
                reportTimeFrame
            )

            setBreakdowns({
                age: setBreakdown(breakdown).age,
                trainings: setBreakdown(breakdown).trainings,
                interests: setBreakdown(breakdown).interests,
                course: setBreakdown(breakdown).course,
                survey: setBreakdown(breakdown).survey
            })
            setSeriesData(correctedSeriesData)
        }
    }, [chartData, isApplied])

    const { save: saveReportThumbnail } = useGraphQLWrapper({
        mutation: SAVE_REPORT_THUMBNAIL,
        mutationName: 'saveReportThumbnail',
        retrieveOnMount: false,
        isCustomMutationInput: true,
        onMutationSuccess: (response) => {
            if (response) {
                setValue('thumbnailSrc', response?.data?.thumbnailSrc)
            }
        },
        shouldHideLoader: true,
        shouldHideToast: true
    })

    const uploadThumbnail = async () => {
        try {
            // Get the chart thumbnail file
            const file = await getChartThumbnail(chartRef, reportId)
            // Upload the file
            const res = await uploadFile(file, {
                userId: user?.id,
                type: FileTypeEnum.THUMBNAIL
            })
            // Handle the response
            if (res?.status === 200) {
                // handle success logic here
            } else {
                console.error('Failed to upload thumbnail:', res)
            }
        } catch (error) {
            console.error('Error uploading thumbnail:', error)
        }
    }

    useEffect(() => {
        if (s3Object && id) {
            saveReportThumbnail({
                input: {
                    id,
                    thumbnail: s3Object?.key
                }
            })
        } else {
            setValue('thumbnailSrc', s3Object?.key)
        }
    }, [s3Object])
    // TODO this code used later
    // useEffect(() => {
    //     if (!isEmpty(seriesData)) setValue('chartRef', chartRef)
    // }, [seriesData])

    useEffect(() => {
        if (
            !isEmpty(seriesData) &&
            !getReportByIdLoading &&
            isThumbnailUpload &&
            libraryId &&
            user?.id
        ) {
            setIsThumbnailUpload(false)
            uploadThumbnail()
        }
    }, [seriesData])
    // Determine the fields to include based on the breakdown options
    const breakdownFields = [
        REPORT_BREAK_DOWN_FIELDS.METRIC,
        ...(breakdowns?.trainings ? [REPORT_BREAK_DOWN_FIELDS.TRAINING] : []),
        ...(breakdowns?.course ? [REPORT_BREAK_DOWN_FIELDS.COURSE] : []),
        ...(breakdowns?.survey ? [REPORT_BREAK_DOWN_FIELDS.SURVEY] : []),
        ...(breakdowns?.age ? [REPORT_BREAK_DOWN_FIELDS.AGE] : []),
        ...(breakdowns?.interests ? [REPORT_BREAK_DOWN_FIELDS.INTEREST] : [])
    ]
    const dataSet = createDataSet(chartData, breakdowns)
    // Process the data to get breakdown results

    const breakdown = breakdownData(dataSet, breakdownFields)

    // Transform the breakdown data into the format suitable for table display
    const tableData = transformData(breakdown)

    const _chartData = transformDataForChart(breakdown)

    const columns = [
        REPORT_DATA_TABLE_COLUMNS.METRICS,
        ...(breakdowns?.trainings ? [REPORT_DATA_TABLE_COLUMNS.TRAINING] : []),
        ...(breakdowns?.course ? [REPORT_DATA_TABLE_COLUMNS.COURSE] : []),
        ...(breakdowns?.survey ? [REPORT_DATA_TABLE_COLUMNS.SURVEY] : []), // Fixed syntax error here
        ...(breakdowns?.age ? [REPORT_DATA_TABLE_COLUMNS.AGE] : []),
        ...(breakdowns?.interests ? [REPORT_DATA_TABLE_COLUMNS.INTEREST] : [])
    ]
    const XAxis = getXAxis(chartData)

    const series = getSeriesData(_chartData, XAxis)
    return (
        <>
            <TimelinePanel plotChart={props?.plotChart} />

            {!isEmpty(series) ? (
                <>
                    <LineChart
                        chartData={series}
                        timelineIntervals={XAxis}
                        chartRef={chartRef}
                        chartPanel={CHART_PANEL_TYPE.REPORT}
                    />
                    <DataView columns={columns} tableData={tableData} />
                </>
            ) : (
                <EmptyState
                    header={t('report.emptyState.header')}
                    image={EmptyReportStateImg}
                />
            )}

            <ReportsPanel
                retrieveGetReport={retrieveGetReport}
                libraryId={libraryId}
                reportId={reportId}
            />
        </>
    )
}
