import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Grid, Typography } from '@mui/material'
import { useCustomTheme, useGraphQLWrapper } from '@hooks'
import { CheckmarkIcon, CrossIcon, InformationIcon, WarningIcon } from '@assets'
import { GET_SURVEY_RESULT } from '@dts/graphql'
import { isEmpty } from 'lodash'
import { TypographyVariants } from '@constants'

export const SurveyResult = (props) => {
    const { theme } = useCustomTheme()
    const {
        BackClickComponent,
        id,
        surveyType,
        summaryResponseIcon,
        summaryTitle,
        summaryDescription,
        summaryEnabled,
        courseId
    } = props || {}

    const { currentData, retrieve } = useGraphQLWrapper({
        query: GET_SURVEY_RESULT,
        queryName: 'getSurveyResult',
        retrieveOnMount: false
    })

    useEffect(() => {
        if (id && courseId) {
            retrieve({ variables: { args: { surveyId: id, courseId } } })
        }
    }, [id, courseId])

    const iconMapper = [
        { id: 'CHM', iconToRender: CheckmarkIcon },
        { id: 'CRM', iconToRender: CrossIcon },
        { id: 'IFS', iconToRender: InformationIcon },
        { id: 'WRS', iconToRender: WarningIcon }
    ]

    const getIconToBeRendered = (idToMatch, size) => {
        const IconToBeRendered = iconMapper.find(
            (iconElement) => iconElement?.id === idToMatch
        )?.iconToRender

        return IconToBeRendered ? (
            <IconToBeRendered height={size} width={size} />
        ) : (
            <></>
        )
    }

    return (
        <>
            <Grid container flexDirection='column' gap={1}>
                <Grid container gap={2} flexDirection={'column'}>
                    <Grid item container flexDirection={'row'}>
                        <Grid item>
                            <BackClickComponent />
                        </Grid>
                        {/* <Grid item>
                            <Typography
                                variant={TypographyVariants.h1}
                                color={theme.palette.backgroundBrandPrimary}
                            >
                                {`${surveyType?.title} Quiz Results`}
                            </Typography>
                        </Grid> */}
                    </Grid>

                    {summaryResponseIcon?.id && (
                        <Grid item alignSelf={'center'}>
                            {getIconToBeRendered(summaryResponseIcon?.id, 64)}
                        </Grid>
                    )}

                    {summaryTitle && (
                        <Grid item alignSelf={'center'}>
                            <Typography
                                variant={TypographyVariants.h1}
                                fontSize={'24px'}
                            >
                                {summaryTitle}
                            </Typography>
                        </Grid>
                    )}
                    {summaryDescription && (
                        <Grid item alignSelf={'center'}>
                            <Typography variant={TypographyVariants.body2}>
                                {summaryDescription}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                {summaryEnabled ? (
                    <Grid container flexDirection={'column'} pt={2} gap={1}>
                        <Typography variant={TypographyVariants.body2}>
                            {'Following are details about your answers'}
                        </Typography>
                        {currentData?.surveyQuestions?.map(
                            (question, index) => (
                                <Grid
                                    container
                                    flexDirection={'column'}
                                    xs={12}
                                    md={12}
                                    key={question?.id}
                                    p={2}
                                    sx={{
                                        borderRadius: '4px',
                                        backgroundColor:
                                            theme?.palette?.backgroundSecondary,
                                        border: `1px solid ${theme?.palette?.borderSecondary}`
                                    }}
                                >
                                    <Typography
                                        variant={TypographyVariants.body1Medium}
                                        sx={{ paddingBottom: '0.313rem' }}
                                    >
                                        {question?.title}
                                    </Typography>
                                    {isEmpty(question?.surveyOptions) ? (
                                        <Typography
                                            variant={TypographyVariants.body3}
                                        >
                                            {'Skipped'}
                                        </Typography>
                                    ) : (
                                        <>
                                            {question?.surveyOptions?.map(
                                                (option) => (
                                                    <Grid
                                                        container
                                                        flexDirection={'row'}
                                                        xs={12}
                                                        md={12}
                                                        alignItems={'center'}
                                                        gap={1}
                                                        my={1}
                                                    >
                                                        <Grid
                                                            item
                                                            display={'flex'}
                                                            alignSelf={
                                                                'flex-start'
                                                            }
                                                        >
                                                            {question?.isCorrect ? (
                                                                <CheckmarkIcon
                                                                    height={18}
                                                                    width={18}
                                                                />
                                                            ) : question?.isCorrect ===
                                                              false ? (
                                                                <CrossIcon
                                                                    height={18}
                                                                    width={18}
                                                                />
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            container
                                                            flexDirection={
                                                                'column'
                                                            }
                                                            xs
                                                        >
                                                            <Typography
                                                                variant={
                                                                    TypographyVariants.body2Medium
                                                                }
                                                                color={
                                                                    theme
                                                                        ?.palette
                                                                        ?.contentPrimary
                                                                }
                                                            >
                                                                {option?.title ??
                                                                    '--'}
                                                            </Typography>
                                                            <Typography
                                                                variant={
                                                                    TypographyVariants.body3
                                                                }
                                                            >
                                                                {
                                                                    option?.summaryDescription
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            )}
                                        </>
                                    )}
                                </Grid>
                            )
                        )}
                    </Grid>
                ) : (
                    <></>
                )}
            </Grid>
        </>
    )
}
