import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import {
    ParentContainer,
    TypographyContainer,
    ControlledTextInput
} from '@components'
import { Grid, Typography } from '@mui/material'

import { useTable } from '@hooks'

const groupDataByKeys = (data) => {
    const groupedData = {}

    data?.forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
            if (!(key in groupedData)) {
                groupedData[key] = new Set()
            }
            if (value !== '' && value !== null && value !== undefined) {
                groupedData[key].add(value)
            }
        })
    })

    Object.keys(groupedData).forEach((key) => {
        groupedData[key] = Array.from(groupedData[key])
    })

    return groupedData
}
export const LearnerListMap: React.FC = ({ allCourses }) => {
    const { t } = useTranslation()
    const { getValues, control, setValue, formState } = useFormContext()

    const csvData = useWatch({
        control,
        name: 'csvData'
    })
    const valueMappingArray = useWatch({
        control,
        name: 'valueMappingArray'
    })
    const fieldMappingArray = useWatch({
        control,
        name: 'fieldMappingArray'
    })
    const dropdownOptions = useWatch({
        control,
        name: 'dropdownOptions'
    })

    useEffect(() => {
        const selectedCSVFields = fieldMappingArray
            .filter((item) => item.mapFieldValue === true)
            .map((item) => item.csvField)

        const keys = csvData && Object.keys(csvData?.[0])
        const groupedData = groupDataByKeys(csvData)
        const valueMapping: Array<object> = []
        let valueMappingIndex = 0

        keys?.map((key) =>
            groupedData[key].map((fieldValue, valueIndex) => {
                valueMapping.push({
                    csvField: key,
                    csvValue: fieldValue,
                    hubbubValue:
                        formState?.dirtyFields?.valueMappingArray?.[
                            valueMappingIndex
                        ]?.hubbubValue &&
                        fieldMappingArray.find((item) => item.csvField === key)
                            ?.mapFieldValue
                            ? valueMappingArray?.[valueMappingIndex]
                                  ?.hubbubValue
                            : fieldValue,
                    valueIndex: valueIndex,
                    rowSpan: groupedData[key]?.length,
                    hideRow: !selectedCSVFields.includes(key),
                    importedAs: dropdownOptions.find(
                        (option) =>
                            option.id ===
                            fieldMappingArray.find(
                                (item) => item.csvField === key
                            )?.hubbubField
                    )?.title
                })
                valueMappingIndex += 1
            })
        )

        setValue('valueMappingArray', valueMapping)
    }, [csvData, dropdownOptions])

    const { tableComponent } = useTable({
        tableConfig: {
            tableData: valueMappingArray,
            columns: [
                {
                    field: 'csvField',
                    label: t('learnerList.matchField.csv'),
                    headerStyle: { width: '30%' },
                    renderCell: (params, index) => (
                        <Grid flexDirection={'column'}>
                            <Typography variant='subtitle2'>
                                {params.csvField}
                            </Typography>
                            <Typography variant='caption'>
                                {[
                                    t('learnerList.importedAs'),
                                    params.importedAs
                                ].join(' ')}
                            </Typography>
                        </Grid>
                    ),
                    cellStyle: {
                        paddingTop: '10px',
                        paddingBottom: '10px'
                    }
                },
                {
                    field: 'csvValue',
                    label: t('learnerList.mapField.importerValue'),
                    headerStyle: { width: '40%' },
                    cellStyle: {
                        paddingTop: '2px',
                        paddingBottom: '2px'
                    }
                },
                {
                    field: 'hubbubValue',
                    label: t('learnerList.mapField.hubbubValue'),
                    headerStyle: { width: '30%' },
                    renderCell: (params, index) => (
                        <ControlledTextInput
                            sx={{
                                '&&.MuiFormControl-root.MuiTextField-root': {
                                    marginTop: '0.25rem'
                                },
                                '& .MuiInputBase-input': {
                                    padding: '0 !important'
                                }
                            }}
                            label={t('learnerList.targetValue')}
                            name={`valueMappingArray[${index}].hubbubValue`}
                            id={index}
                            fullWidth
                        />
                    ),
                    cellStyle: {
                        paddingTop: '3px',
                        paddingBottom: '3px'
                    }
                }
            ],
            groupBy: 'csvField'
        }
    })

    return (
        <ParentContainer container>
            <Typography variant='h1'>{getValues('name')}</Typography>
            <Grid container direction={'column'}>
                <TypographyContainer item md={12}>
                    <Typography variant='h3'>
                        {t('learnerList.mapValues')}
                    </Typography>
                    <Typography variant='body2Medium'>
                        {t('learnerList.matchCaption')}
                    </Typography>
                </TypographyContainer>
                <Grid container md={8} xs={12} marginTop={2}>
                    {valueMappingArray.some((item) => !item?.hideRow) ? (
                        tableComponent
                    ) : (
                        <Typography variant='h3'>
                            {valueMappingArray?.length
                                ? t('learnerList.noRecordsToDisplay')
                                : t('learnerList.noRecords')}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </ParentContainer>
    )
}
