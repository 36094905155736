import React from 'react'
import { SAVE_CAMPUS_COURSES, GET_CAMPUS_COURSES } from '@dts/graphql'
import { CampusCoursesProps } from './types'
import { useCourses } from './hook'

export const CampusCoursesContainer: React.FC<CampusCoursesProps> = (props) => {
    const { campusId = '' } = props || {}

    const page = useCourses({
        campusId,
        addedIn: 'general.campus',
        mutation: SAVE_CAMPUS_COURSES,
        mutationName: 'saveCampusCourses',
        query: GET_CAMPUS_COURSES,
        queryName: 'getCampusCourses'
    })

    return <>{page}</>
}
