import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { DocumentNode } from 'graphql'
import { GridPaginationModel, GridRowModel } from '@mui/x-data-grid-pro'
import { isEmpty, isEqual } from 'lodash'
import { CardGridWithDirectory, Toolbar, ToolbarConfig } from '@components'
import { SvgProps } from '@types'
import { useMediaQuery } from '@mui/material'
import { useCachedDirectoryOptions } from '@hooks'
import { DataTableConfig, QueryOptions, useDataTable } from './use-datatable'
import { useGraphQLWrapper } from './use-graphql-wrapper'
export type CardDirectoryConfig = {
    getCardAction?: (params?: object) => {
        icon?: React.FC<SvgProps>
        label?: string
    }
    getCardConfig?: (data?: Array<object>) => Array<{ onClick?: VoidFunction }>
    setGridView?: Dispatch<SetStateAction<boolean>>
}
export type DirectoryConfig = {
    config: DataTableConfig
    query?: DocumentNode
    queryName?: string
    accessor?: string
    queryVariables?: object
    toolbarConfig?: ToolbarConfig
    id: string
    retrieveOnMount?: boolean
    enableSearch?: boolean
    filterConfig?: {
        filterVariables: object
        filterEnabled: boolean
        setFilterEnabled: VoidFunction
    }
    cardDirectoryConfig?: CardDirectoryConfig
    rowHeight?: number
    dataTableHeader?: string
    useFilterHeader: boolean
    shouldShowLoader?: boolean
}
export const useDirectory = ({
    directoryConfig: {
        config: {
            columns,
            pagination = true,
            hideFooter = false,
            customActions,
            detailCompConfig,
            genericActions
        },
        cardDirectoryConfig,
        toolbarConfig,
        query,
        queryName,
        accessor,
        queryVariables,
        id,
        retrieveOnMount = false,
        enableSearch = false,
        rowHeight,
        dataTableHeader = '',
        filterConfig,
        useFilterHeader = false,
        shouldShowLoader = true
    }
}: {
    directoryConfig: DirectoryConfig
}) => {
    const [data, setData] = useState<Array<GridRowModel>>([])
    const [rowCount, setRowCount] = useState<number>(10)
    const [gridView, setGridView] = useState<boolean>(false)
    const [firstLoad, setFirstLoad] = useState<boolean>(true)
    const [isRetrieveData, setIsRetrieveData] = useState<boolean>(false)
    const [getCachedDirectoryOptions, setCachedDirectoryOptions] =
        useCachedDirectoryOptions()
    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
        {
            page: 0,
            pageSize: 10
        }
    )

    const [queryOptions, setQueryOptions] = useState<QueryOptions>({
        page: 1,
        pageSize: 10
    })
    const {
        retrieve,
        data: response,
        queryLoading: directoryLoading
    } = useGraphQLWrapper({
        query,
        queryName,
        queryVariables,
        retrieveOnMount
    })

    /**
     * This useEffect is triggered when there are cached directory options available and when data retrieval
     * is not in progress. It initiates a data retrieval operation using the cached options.
     */
    useEffect(() => {
        // Proceed only if there are cached directory options and data retrieval is not in progress
        if (
            !isEmpty(getCachedDirectoryOptions(id)?.options)
            //  && !isRetrieveData (TBD)
        ) {
            // Initiate data retrieval with the cached options
            retrieve?.({
                variables: {
                    options: getCachedDirectoryOptions(id)?.options
                }
            })
            setIsRetrieveData(false) // Mark data retrieval as in progress
        }
    }, [getCachedDirectoryOptions(id)?.options, isRetrieveData])

    /**
     * This useEffect is triggered when the pagination model changes in the directory.
     * It updates cached directory options and initiates data retrieval if necessary.
     */
    useEffect(() => {
        // Proceed only if it's not the initial load, pagination exists, and data retrieval is not ongoing
        if (!firstLoad && pagination && !isRetrieveData) {
            // Update cached directory options with the updated pagination model
            setCachedDirectoryOptions(id, {
                ...getCachedDirectoryOptions(id)?.options,
                ...paginationModel,
                page: paginationModel.page + 1 // Increment page for next data fetch
            })
            setIsRetrieveData(true) // Mark data retrieval as in progress
        }

        // Update firstLoad flag after the initial load
        if (firstLoad) {
            setFirstLoad(false)
        }
    }, [paginationModel])

    /**
     * This useEffect manages cached directory options and pagination model initialization.
     * It sets cached directory options if absent and adjusts pagination model if cached options exist.
     */
    useEffect(() => {
        // Set cached directory options if they don't exist
        if (!getCachedDirectoryOptions(id)?.options) {
            setCachedDirectoryOptions(id, {
                ...paginationModel,
                page: paginationModel?.page + 1 // Increment page for next data fetch
            })
        } else if (
            // Adjust pagination model if cached options indicate a non-initial page or pageSize
            (getCachedDirectoryOptions(id)?.options?.page > 1 ||
                getCachedDirectoryOptions(id)?.options?.pageSize > 10) &&
            !isRetrieveData
        ) {
            // Decrement page to load the correct page
            setPaginationModel((prevPaginationModel) => ({
                ...prevPaginationModel,
                page: prevPaginationModel.page - 1
            }))
            setIsRetrieveData(true) // Mark data retrieval as in progress
        }
    }, [])

    useEffect(() => {
        if (!gridView && !firstLoad) {
            setCachedDirectoryOptions(id, { page: 1, pageSize: 10 })
        }
    }, [gridView])

    const fetchMoreRecords = () => {
        if (
            getCachedDirectoryOptions(id)?.options?.page *
                getCachedDirectoryOptions(id)?.options?.pageSize <=
            rowCount
        ) {
            setCachedDirectoryOptions(id, {
                ...getCachedDirectoryOptions(id)?.options,
                page: getCachedDirectoryOptions(id)?.options?.page + 1 // Increment page for next data fetch
            })
            setIsRetrieveData(true) // Mark data retrieval as in progress}
        }
    }

    useEffect(() => {
        if (response) {
            setData((existingData) => {
                const incomingData = accessor
                    ? response?.data?.[accessor]
                    : response?.data

                // Checks if search text field has a value entered in it or not
                const isSearchTermEmpty =
                    queryOptions?.search === undefined ||
                    isEmpty(queryOptions?.search)

                // Checks if the card view toggle is enabled
                const isCardViewEnabled =
                    (gridView || isMediumScreen) && cardDirectoryConfig

                // This is used to merge the data in case of infinite scroll
                if (isCardViewEnabled && isSearchTermEmpty) {
                    const existingIds = existingData?.map((row) => row?.id)

                    const filteredIncomingData = incomingData?.filter?.(
                        (row) => !existingIds?.includes(row?.id)
                    )
                    return [...existingData, ...filteredIncomingData]
                }

                // This is used to return the data as it is from the response (In case of tabular view and when searh term exists in card view)
                return accessor ? response?.data?.[accessor] : response?.data
            })
            setRowCount(response?.meta?.count)
        }
    }, [response])
    const { dataTable } = useDataTable({
        config: {
            columns,
            customActions,
            genericActions,
            pagination,
            hideFooter,
            queryOptions,
            setQueryOptions: setCachedDirectoryOptions,
            paginationModel,
            setPaginationModel,
            detailCompConfig
        },
        data,
        id,
        rowCount,
        refetch: retrieve,
        headerHeight: 40,
        rowHeight,
        directoryLoading,
        shouldShowLoader
    })

    const directoryToolbarConfig = {
        id,
        header: dataTableHeader,
        showBreadcrumbs: toolbarConfig?.showBreadcrumbs,
        fetchEntityVariables: toolbarConfig?.fetchEntityVariables,
        ...(filterConfig?.filterVariables
            ? {
                  filterConfig: {
                      onFilter: () => {
                          retrieve({
                              variables: {
                                  ...filterConfig?.filterVariables,
                                  options: {
                                      page: 1,
                                      pageSize: 10
                                  }
                              }
                          })
                      },
                      onUnfilter: () => {
                          retrieve({
                              variables: {
                                  options: {
                                      page: 1,
                                      pageSize: 10
                                  }
                              }
                          })
                      },
                      filterEnabled: filterConfig?.filterEnabled,
                      setFilterEnabled: filterConfig?.setFilterEnabled
                  }
              }
            : {}),
        ...(!useFilterHeader && toolbarConfig),
        ...(cardDirectoryConfig
            ? {
                  cardDirectoryConfig: {
                      ...cardDirectoryConfig,
                      setGridView,
                      gridView
                  }
              }
            : {}),
        ...(enableSearch
            ? {
                  searchConfig: {
                      onSearch: (value: string, isValueEntered?: boolean) => {
                          setCachedDirectoryOptions(id, {
                              page: 1,
                              pageSize: 10,
                              search: isValueEntered ? value : undefined
                          })
                          setIsRetrieveData(true)

                          if (gridView) {
                              setData([])
                          }
                      }
                  }
              }
            : {})
    }

    const directory = (
        <>
            <Toolbar toolbarConfig={directoryToolbarConfig} />

            {(gridView || isMediumScreen) && cardDirectoryConfig ? (
                <CardGridWithDirectory
                    data={data}
                    config={cardDirectoryConfig}
                    setQueryOptions={setQueryOptions}
                    totalLength={rowCount}
                    fetchMoreRecords={fetchMoreRecords}
                />
            ) : (
                dataTable
            )}
        </>
    )

    return {
        directory,
        retrieve,
        queryOptions,
        rowCount,
        data: response?.data,
        directoryLoading
    }
}
