import React, { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
    useCustomTheme,
    useGraphQLWrapper,
    useModalWithGraphQL,
    useVideoConfig
} from '@hooks'
import {
    InsightPanelId,
    InsightPanelKey,
    InsightTimeline,
    InsightVideoOption,
    formatNumberToKMB,
    formatPercentageChange,
    getInsightTimeLineDays,
    getTimeFrameData,
    mapDropdownOptions,
    useInsightLineChart
} from '@dts/client-utils'
import { GET_INSIGHT_METADATA, GET_INSIGHT_VIDEOS } from '@dts/graphql'
import {
    InsightArrowUpIcon,
    InsightArrowDownIcon,
    PlayFilledIcon
} from '@assets'
import { Box, Grid, Typography } from '@mui/material'
import {
    AnalyticsCard,
    LineChart,
    ControlledNativeSelect,
    VideoCardGrid,
    EmptyState
} from '@components'

import { VideoConfigType } from '@constants'
import { getChartData, renderInsightMetricIcon } from './get-chart-data'
import {
    INSIGHT_PANEL_ID,
    INSIGHT_TIMEFRAME_ID,
    analyticsCardData
} from './data'
import { InsightHeader } from './insight-header'
import { EmptyStateForInsights } from './empty-state-for-insights'

export const ContentCreatorCards = () => {
    const { control } = useFormContext()
    const [selectedCard, setSelectedCard] = useState(0)
    const [insightPanelId, setInsightPanelId] = useState(InsightPanelId.LIKED)
    const [lineChartData, setLineChartData] = useState([])
    const [xAxisCategories, setXAxisCategories] = useState([])
    const { theme } = useCustomTheme()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const insightTimeFrame = useWatch({ control, name: 'insightTimeFrame' })
    const insightVideoOption = useWatch({ control, name: 'insightVideoOption' })

    const {
        chartData,
        retrieveInsightChart,
        insightChartLoading,
        insightChartData
    } = getChartData(insightTimeFrame, insightPanelId)
    const {
        currentData: insightMetaData,
        queryLoading: insightMetaDataLoading
    } = useGraphQLWrapper({
        query: GET_INSIGHT_METADATA,
        queryName: 'getInsightMetaData'
    })
    const {
        currentData: insightVideosData,
        queryLoading: insightVideosLoading,
        retrieve: retrieveInsightVideos,
        data: insightVideosDataCount
    } = useGraphQLWrapper({
        query: GET_INSIGHT_VIDEOS,
        queryName: 'getInsightVideos',
        retrieveOnMount: false,
        queryVariables: {
            args: {
                insightTimeFrameId:
                    insightTimeFrame || InsightTimeline.LAST_SEVEN_DAYS,
                limit: 5
            }
        }
    })

    const handleLineChart = ({
        plotChartData = {},
        metricName = INSIGHT_PANEL_ID[insightPanelId]
    }) => {
        const timeframeData = getTimeFrameData(insightTimeFrame)
        const { chartData, xAxisCategories } = useInsightLineChart({
            dataPoints: plotChartData?.dataPoints,
            timeFrameId: insightTimeFrame,
            timeframeData,
            metricName: t(metricName)
        })
        setLineChartData(chartData)
        setXAxisCategories(xAxisCategories)
    }

    useEffect(() => {
        /**
         * For the First load setting chart-data-points
         */
        if (chartData) {
            handleLineChart({
                plotChartData: chartData,
                metricName: INSIGHT_PANEL_ID[insightPanelId]
            })
        }
    }, [insightChartLoading])

    useEffect(() => {
        if (chartData && insightPanelId) {
            retrieveInsightChart({
                variables: {
                    args: {
                        insightTimeFrameId:
                            insightTimeFrame || InsightTimeline.LAST_SEVEN_DAYS,
                        insightPanelId
                    }
                }
            })
                .then((response) => {
                    handleLineChart({
                        metricName: INSIGHT_PANEL_ID[insightPanelId],
                        plotChartData:
                            response?.data?.plotInsightChart?.data?.dataPoints
                    })
                })
                .catch((err) => {
                    //error handling
                })
        }
    }, [insightPanelId])

    useEffect(() => {
        if (insightTimeFrame) {
            retrieveInsightChart({
                variables: {
                    args: {
                        insightTimeFrameId: insightTimeFrame,
                        insightPanelId
                    }
                }
            })
                .then((response) => {
                    handleLineChart({
                        metricName: INSIGHT_PANEL_ID[insightPanelId],
                        plotChartData:
                            response?.data?.plotInsightChart?.data?.dataPoints
                    })
                })
                .catch((err) => {
                    //error handling
                })
            retrieveInsightVideos({
                variables: {
                    args: {
                        insightTimeFrameId: insightTimeFrame,
                        limit: 5
                    }
                }
            })
        }
    }, [insightTimeFrame])

    const { videoConfig } = useVideoConfig({
        videoConfigType: VideoConfigType.Upload
    })
    const { openDialog } = useModalWithGraphQL(videoConfig)

    return (
        <Grid my={2}>
            {insightVideosData?.latestVideos ? (
                <>
                    <InsightHeader
                        insightMetaData={insightMetaData}
                        insightTimeFrame={insightTimeFrame}
                        headerTitle={t('analytics.yourInsights.label')}
                    />
                    <Grid
                        container
                        sx={{
                            borderRadius: '8px 8px 0px 0px',
                            border: `1px solid ${theme.palette.backgroundTertiary}`,
                            borderTop: '0px'
                        }}
                    >
                        {analyticsCardData?.map(({ id, title }, index) => (
                            <Grid
                                item
                                xs={4}
                                key={index}
                                sx={{
                                    paddingInlineEnd:
                                        analyticsCardData.length - 1 !== index
                                            ? '8px'
                                            : '0px'
                                }}
                            >
                                <AnalyticsCard
                                    key={index}
                                    isSelected={index === selectedCard}
                                    onClick={() => {
                                        setInsightPanelId(
                                            id === InsightPanelKey.LIKED
                                                ? InsightPanelId.LIKED
                                                : id === InsightPanelKey.VIEWED
                                                ? InsightPanelId.VIEWED
                                                : InsightPanelId.WATCH_TIME
                                        )
                                        setSelectedCard(index)
                                    }}
                                    header={t(title)}
                                    renderInsightIcon={() =>
                                        chartData?.KPIs[id]?.percentage > 0 ? (
                                            <InsightArrowUpIcon />
                                        ) : (
                                            <InsightArrowDownIcon />
                                        )
                                    }
                                    renderHeaderIcon={() =>
                                        renderInsightMetricIcon(
                                            id,
                                            index === selectedCard
                                        )
                                    }
                                    count={formatNumberToKMB(
                                        chartData?.KPIs[id]?.value || 0
                                    )}
                                    description={[
                                        `${formatPercentageChange(
                                            chartData?.KPIs[id]?.percentage
                                        )}, ${getInsightTimeLineDays(
                                            insightTimeFrame
                                        )}`
                                    ].join(' ')}
                                />
                            </Grid>
                        ))}

                        <Grid item xs={12} width={'100%'} mt={2}>
                            <LineChart
                                chartData={lineChartData}
                                timelineIntervals={xAxisCategories}
                                chartStyles={{
                                    height: 300,
                                    backgroundColor:
                                        theme.palette.backgroundPrimary
                                }}
                                chartConfig={{
                                    legendEnabled: false,
                                    markerEnabled: false,
                                    chartTitle: null,
                                    xAxisLabelColor:
                                        theme.palette.contentTertiary,
                                    yAxisLabelColor:
                                        theme.palette.contentTertiary,
                                    showTooltipDataInPercentage: false
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid sx={{ width: '200px', padding: '16px' }}>
                        <ControlledNativeSelect
                            title={
                                InsightVideoOption.LATEST_VIDEOS !==
                                insightVideoOption
                                    ? [
                                          t('general.inLast'),
                                          INSIGHT_TIMEFRAME_ID[
                                              insightTimeFrame ||
                                                  InsightTimeline.LAST_SEVEN_DAYS
                                          ]
                                      ].join(' ')
                                    : ''
                            }
                            ariaLabel={t('general.insightVideoOption')}
                            isSubheading={true}
                            name='insightVideoOption'
                            options={mapDropdownOptions(
                                insightMetaData?.insightVideoOption
                            )}
                        />
                    </Grid>

                    <Box
                        sx={{
                            display: 'flex',
                            overflowX: 'auto',
                            '&::-webkit-scrollbar': {
                                display: 'none'
                            },
                            scrollbarWidth: 'none', // For Firefox
                            msOverflowStyle: 'none' // For Internet Explorer and Edge
                        }}
                    >
                        <VideoCardGrid
                            cardData={insightVideosData?.[
                                InsightVideoOption.LATEST_VIDEOS ===
                                insightVideoOption
                                    ? 'latestVideos'
                                    : 'topViewed'
                            ]?.map((data) => ({
                                ...data,
                                tabIndex: 0,
                                onClick: () =>
                                    navigate(
                                        `/video-insights/${data?.id}?insightTimeFrameId=${insightTimeFrame}`
                                    )
                            }))}
                            cardAction={(params) => ({
                                icon: () => (
                                    <Grid
                                        display={'flex'}
                                        alignItems={'center'}
                                    >
                                        <PlayFilledIcon
                                            color={theme.palette.constantsWhite}
                                        />
                                        <Typography
                                            variant='body1'
                                            fontSize={'12px'}
                                            color={theme.palette.constantsWhite}
                                            fontWeight={600}
                                            ml={0.5}
                                        >
                                            {params?.viewCount}
                                        </Typography>
                                    </Grid>
                                )
                            })}
                            showViewMore={
                                insightVideosDataCount?.meta?.count > 5
                            }
                            onClickViewMore={() =>
                                navigate(`/my-account/published`)
                            }
                        />
                    </Box>
                </>
            ) : (
                <EmptyStateForInsights onButtonClick={openDialog} />
            )}
        </Grid>
    )
}
