import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import {
    DeleteIcon,
    DownloadIcon,
    PencilIcon,
    SaveAsIcon,
    ViewIcon
} from '@assets'

import { ConfirmationDialog, Duplicate, EmptyState, Loader } from '@components'
import { t } from 'i18next'
import { useGraphQLWrapper, useLoader, useModalWithGraphQL } from '@hooks'
import { REPORT_LIBRARY } from '@constants'
import { DELETE_REPORT, RENAME_REPORT } from '@dts/graphql/lib'
import { ReportTitleSchema } from '@dts/client-utils'
import { DUPLICATE_REPORT, SAVE_REPORT_LIBRARY } from '@dts/graphql'
import { UserPermissionEnum } from '@dts/constants'
import { ReportCard } from './report-card'
import { SaveReportDialog } from './create-report/report-view/save-report'

export const LibraryReports: React.FC = ({
    query,
    queryName,
    libraryId,
    reportType
}) => {
    const { loading } = useLoader()
    const navigate = useNavigate()
    const {
        queryLoading: reportsInLibraryLoading,
        currentData: reportsByLibraryId,
        retrieve
    } = useGraphQLWrapper({
        query,
        queryName,
        queryVariables: {
            args: {
                reportLibraryId: libraryId
            }
        }
    })
    const { openPrepopulatedDialog: renameReport } = useModalWithGraphQL({
        mutation: RENAME_REPORT,
        mutationName: 'renameReport',
        retrieveOnMount: false,
        isCustomMutationInput: true,
        mapFormToMutationInput: (formValues) => ({
            input: {
                id: formValues?.id,
                title: formValues?.title
            }
        }),
        onMutationSuccess: (response) => {
            retrieve?.({
                variables: {
                    args: {
                        reportLibraryId: libraryId
                    }
                }
            })
        },
        modalConfig: {
            maxWidth: 'sm',
            Component: Duplicate,
            updationConfig: {
                primaryButtonText: t('general.save'),
                header: [t('general.rename'), t('general.report')].join(' ')
            },
            hasCancelButton: true
        },
        yupResolverSchema: ReportTitleSchema
    })

    const { openPrepopulatedDialog: saveAsNewModal } = useModalWithGraphQL({
        mutation: DUPLICATE_REPORT,
        mutationName: 'duplicateReport',
        mapFormToMutationInput: (formValues) => ({
            input: {
                id: formValues?.id,
                title: formValues?.title,
                reportLibraryId: formValues?.reportLibraryId
            }
        }),
        retrieveOnMount: false,
        isCustomMutationInput: true,
        onMutationSuccess: (response) => {
            retrieve?.({
                variables: {
                    args: {
                        reportLibraryId: libraryId
                    }
                }
            })
        },
        initialValues: {
            title: null,
            reportLibraryId: null
        },
        modalConfig: {
            maxWidth: 'sm',
            Component: () => <SaveReportDialog />,
            updationConfig: {
                primaryButtonText: t('general.save'),
                header: t('report.saveCustomReport')
            },
            hasCancelButton: true
        },
        yupResolverSchema: ReportTitleSchema
    })

    const { openConfirmationDialog: deleteReport } = useModalWithGraphQL({
        mutation: DELETE_REPORT,
        mutationName: 'deleteReport',
        onMutationSuccess: () => {
            retrieve?.({
                variables: {
                    args: {
                        reportLibraryId: libraryId
                    }
                }
            })
        },
        isCustomMutationInput: true,

        modalConfig: {
            maxWidth: 'sm',
            Component: () => (
                <ConfirmationDialog
                    message={t('delete.confirmation.message')}
                />
            ),
            confirmationConfig: {
                primaryButtonText: t('general.confirm'),
                header: `${t('general.confirmation')}`
            },
            hasCancelButton: true
        }
    })

    return loading && reportsInLibraryLoading ? (
        <Loader />
    ) : reportsByLibraryId?.length ? (
        <>
            <Grid
                p={1}
                container
                xs={12}
                md={12}
                sx={{ maxHeight: '350px', overflowY: 'auto' }}
            >
                {reportsByLibraryId?.map((report, index) => (
                    <ReportCard
                        key={index}
                        title={report?.title}
                        thumbnail={report?.thumbnailSrc}
                        onClickAction={() => {
                            navigate(
                                `/reports/${report?.id}?libraryId=${libraryId}&reportType=${reportType}`
                            )
                        }}
                        cardActions={[
                            ...(reportType === REPORT_LIBRARY.CUSTOM
                                ? [
                                      {
                                          icon: SaveAsIcon,
                                          label: t('general.saveAs'),
                                          onClick: () => {
                                              saveAsNewModal(
                                                  undefined,
                                                  undefined,
                                                  {
                                                      id: report?.id,
                                                      title: report?.title,
                                                      reportLibraryId: libraryId
                                                  }
                                              )
                                          },
                                          permissions: [
                                              UserPermissionEnum.ReportCreateModify
                                          ]
                                      },
                                      {
                                          icon: PencilIcon,
                                          label: [
                                              t('general.rename'),
                                              t('general.report')
                                          ].join(' '),
                                          onClick: () => {
                                              renameReport(
                                                  undefined,
                                                  undefined,
                                                  {
                                                      id: report?.id,
                                                      title: report?.title
                                                  }
                                              )
                                          },
                                          permissions: [
                                              UserPermissionEnum.ReportCreateModify
                                          ]
                                      }
                                  ]
                                : []),
                            {
                                icon: DownloadIcon,
                                label: t('general.download'),
                                onClick: () => {},
                                permissions: [UserPermissionEnum.ReportView]
                            },
                            reportType === REPORT_LIBRARY.CUSTOM && {
                                icon: DeleteIcon,
                                label: t('general.delete'),
                                onClick: () => {
                                    deleteReport({ id: report?.id })
                                },
                                permissions: [UserPermissionEnum.ReportDelete]
                            }
                        ].filter(Boolean)}
                    />
                ))}
            </Grid>
        </>
    ) : (
        <EmptyState preventPadding={true} />
    )
}
