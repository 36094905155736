import { styled, Grid, Button } from '@mui/material'
import { isMediumScreen } from '@utils'

export const StyledMetricsContainer = styled(Grid)(({ theme }) => ({
    minWidth: '170px',
    flexDirection: 'column',
    position: !isMediumScreen?.() ? 'absolute' : 'static',
    height: !isMediumScreen?.() ? '100%' : 'auto',
    borderRight: `0.5px solid ${theme.palette.borderSecondary}`
}))

export const StyledPanelButton = styled(Button)(({ theme }) => ({
    marginBottom: '10px',
    background: theme.palette.backgroundBrandPrimary,
    color: theme.palette.contentPrimaryInverse,
    '&:hover': {
        background: theme.palette.backgroundInfo,
        color: theme.palette.contentPrimaryInverse
    }
}))
