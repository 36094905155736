import * as yup from 'yup'

export const AddLearnerSchema = yup.object().shape({
  userType: yup.string().required('errors.required').nullable(),

  firstName: yup
    .string()
    .when('userType', ([userType], schema) =>
      userType === 'new'
        ? schema
            .required('errors.required')
            .min(2, 'errors.min')
            .max(60, 'errors.max')
        : yup.string(),
    ),
  lastName: yup
    .string()
    .when('userType', ([userType], schema) =>
      userType === 'new'
        ? schema
            .required('errors.required')
            .min(2, 'errors.min')
            .max(60, 'errors.max')
        : yup.string(),
    ),
  email: yup
    .string()
    .when('userType', ([userType], schema) =>
      userType === 'new'
        ? schema
            .required('errors.required')
            .email('Please provide a valid email')
            .max(255, 'Email should not exceed more than 255 characters')
        : yup.string(),
    ),
  userId: yup
    .string()
    .when('userType', ([userType], schema) =>
      userType === 'new'
        ? schema
            .required('errors.required')
            .min(5, 'errors.min')
            .max(60, 'errors.max')
        : yup.string(),
    ),

  learners: yup.array().when('userType', ([userType], schema) =>
    userType !== 'new'
      ? schema.test('at-least-one-added', 'errors.required', (courses) => {
          const addedCourses = courses?.some((course) => course?.isAdded)
          return addedCourses
        })
      : yup.array(),
  ),
})
