import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCustomTheme, useDirectory, useGraphQLWrapper } from '@hooks'
import { GET_USERS, SEND_INVITE } from '@dts/graphql'
import { Grid, Avatar, Typography } from '@mui/material'
import { TextWithTooltip } from '@components'
import { ActiveIcon, AddIcon, PaperPlaneIcon, UserIcon } from '@assets'
import { formatDate } from '@dts/client-utils'
import { UserInviteStatus } from '@constants'
import { StyledDirectoryLink } from '@containers/styles'
import { UserPermissionEnum } from '@dts/constants'
import { darkColors } from '@colors'

export const ManageAdmins = ({ isClientAdmin = false }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { theme } = useCustomTheme()

    const sendInviteConfig = {
        mutation: SEND_INVITE,
        mutationName: 'sendInvite',
        onMutationSuccess: () => {
            retrieve?.({
                variables: {
                    options: queryOptions
                }
            })
        },
        isCustomMutationInput: true
    }

    const { save: sendInvite } = useGraphQLWrapper({ ...sendInviteConfig })

    const navigateToAdmin = (params) => {
        navigate(`${params.id}`)
    }
    const navigateToAdminViewOnly = (params) => {
        const isRejected = params?.row?.status?.id === UserInviteStatus.REJECTED
        navigate(`view-admin/${params.id}`, {
            state: { isRejected }
        })
    }

    const { directory, retrieve, queryOptions } = useDirectory({
        directoryConfig: {
            id: 'adminDirectory',
            query: GET_USERS,
            queryName: 'getUsers',
            config: {
                customActions: [
                    {
                        icon: (
                            <UserIcon
                                color={theme.palette.backgroundBrandPrimary}
                                fill={theme.palette.darkestBlue}
                            />
                        ),
                        label: `${t('user.edit')}`,
                        ariaLabel: `${t('user.edit')}`,
                        showInMenu: true,
                        action: (params) =>
                            params?.row?.status?.id !==
                                UserInviteStatus.ACTIVE && isClientAdmin
                                ? navigateToAdminViewOnly(params)
                                : navigateToAdmin(params),
                        permissions: [
                            UserPermissionEnum.UserCreateModify,
                            UserPermissionEnum.AdminCreateModify
                        ]
                    },
                    {
                        icon: (
                            <PaperPlaneIcon fill={theme.palette.darkestBlue} />
                        ),
                        label: `${t('user.resendInvite')}`,
                        ariaLabel: `${t('user.resendInvite')}`,
                        showInMenu: true,
                        hide: (params) =>
                            params?.row?.status?.id !==
                                UserInviteStatus.PENDING ||
                            params?.row?.active !== true,
                        action: (params) => {
                            sendInvite({
                                input: {
                                    email: params?.row?.email,
                                    isCreator: false
                                }
                            })
                        },
                        permissions: [
                            UserPermissionEnum.UserCreateModify,
                            UserPermissionEnum.AdminCreateModify
                        ]
                    }
                ],
                columns: [
                    {
                        field: 'status',
                        headerName: '',
                        maxWidth: 30,
                        flex: 1,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={
                                    !params?.row?.active ? (
                                        <ActiveIcon
                                            color={darkColors.tomatoRed}
                                        />
                                    ) : params?.value?.id ===
                                      UserInviteStatus.ACTIVE ? (
                                        <ActiveIcon />
                                    ) : (
                                        <ActiveIcon
                                            color={theme.palette.yellow}
                                        />
                                    )
                                }
                                toolTip={
                                    !params.row?.active
                                        ? `${t('general.inactive')}`
                                        : params?.value?.id ===
                                          UserInviteStatus.ACTIVE
                                        ? `${t('general.active')}`
                                        : `${t('editAdmin.status.pending')}`
                                }
                                tabIndex={params.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'avatarSrc',
                        headerName: '',
                        flex: 0,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={
                                    <Grid sx={{ p: 1 }}>
                                        <Avatar
                                            sx={{
                                                border: `1px solid ${theme.palette.borderGrey}`
                                            }}
                                            src={params?.value}
                                            alt={t('general.brokenImage')}
                                        />
                                    </Grid>
                                }
                                onClick={() => {
                                    navigateToAdmin(params)
                                }}
                                toolTip={`${params?.row?.firstName ?? ''} ${
                                    params?.row?.lastName ?? ''
                                }`}
                                tabIndex={params.tabIndex}
                            />
                        )
                    },

                    {
                        field: 'name',
                        headerName: t('general.name'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={`${params?.row?.firstName ?? ''} ${
                                    params?.row?.lastName ?? ''
                                }`}
                                isDirectoryLink
                                onClick={() => {
                                    params?.row?.status?.id !==
                                        UserInviteStatus.ACTIVE && isClientAdmin
                                        ? navigateToAdminViewOnly(params)
                                        : navigateToAdmin(params)
                                }}
                                tabIndex={params.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'email',
                        headerName: t('general.email'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={params?.value}
                                tabIndex={params.tabIndex}
                            />
                        ),
                        flex: 2
                    },
                    {
                        field: 'roles',
                        headerName: t('general.role'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={params?.value?.[0]?.title}
                                tabIndex={params.tabIndex}
                            />
                        ),
                        flex: 1
                    },
                    {
                        field: 'createdAt',
                        headerName: t('general.date'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={
                                    params?.row?.status?.id ===
                                    UserInviteStatus.ACTIVE ? (
                                        params?.value ? (
                                            formatDate(params?.value)
                                        ) : null
                                    ) : params?.row?.status?.id ===
                                      UserInviteStatus.PENDING ? (
                                        <Typography
                                            variant='tCell'
                                            sx={{
                                                color: theme.palette
                                                    .defaultCopper
                                            }}
                                        >
                                            {t('editAdmin.status.pending')}
                                        </Typography>
                                    ) : params?.row?.status?.id ===
                                      UserInviteStatus.REJECTED ? (
                                        <Typography
                                            variant='tCell'
                                            sx={{
                                                color: theme.palette
                                                    .errorDangerMedium
                                            }}
                                        >
                                            {t('general.rejected')}
                                        </Typography>
                                    ) : (
                                        <Typography
                                            variant='tCell'
                                            sx={{
                                                color: theme.palette
                                                    .defaultCopper
                                            }}
                                        >
                                            {t('user.pendingInvitation')}
                                        </Typography>
                                    )
                                }
                                toolTip={
                                    params?.row?.status?.id ===
                                    UserInviteStatus.ACTIVE
                                        ? params?.value
                                            ? formatDate(params?.value)
                                            : ''
                                        : params?.row?.status?.id ===
                                          UserInviteStatus.PENDING
                                        ? t('editAdmin.status.pending')
                                        : params?.row?.status?.id ===
                                          UserInviteStatus.REJECTED
                                        ? t('general.rejected')
                                        : t('user.pendingInvitation')
                                }
                                tabIndex={params.tabIndex}
                            />
                        )
                    }
                ],
                hideFooter: false,
                pagination: true
            },
            enableSearch: true,
            toolbarConfig: {
                header: t('sideNavItems.admins'),
                addNewButtonConfig: {
                    label: t('general.createNew'),
                    ariaLabel: t('pageHeader.admin.titleView'),
                    action: () => {
                        navigate(`new`)
                    },
                    icon: AddIcon,
                    permissions: [
                        UserPermissionEnum.UserCreateModify,
                        UserPermissionEnum.AdminCreateModify
                    ]
                }
            }
        }
    })

    return directory
}
