import React from 'react'
import { Resolver } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { useGraphQL } from '../hooks'
import { SaveMode } from '../constants'
import { useForm } from './use-form'
import { Message, Response, UseFormWithGraphQLParams } from '../types'

export const useFormWithGraphQL = <
  FormType,
  QueryResult,
  MutationInput,
  QueryVariables = object,
>({
  id,
  query,
  mutation,
  mapQueryResultToForm,
  mapFormToMutationInput,
  mapPreservedFormInputs,
  queryName,
  mutationName,
  queryVariables,
  initialValues,
  onError,
  onMutationSuccess,
  onQuerySuccess,
  formErrorMap,
  yupResolverSchema,
  isCustomMutationInput = false,
  retrieveOnMount = true,
}: UseFormWithGraphQLParams<
  FormType,
  QueryResult,
  MutationInput,
  QueryVariables
>) => {
  const resolver: Resolver | undefined =
    yupResolverSchema && yupResolver(yupResolverSchema)

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'all',
    disabled: false,
  })

  const handleErrors = (message?: Message, meta?: object) => {
    // addErrorsToForm(methods, t, message, formErrorMap);
    // Future Implementations
  }

  const handleResponse =
    (
      callback?: (
        result?: Response<QueryResult>,
        mode?: SaveMode,
        formValue?: FormType,
      ) => void,
    ) =>
    (result?: Response<QueryResult>, message?: Message, mode?: SaveMode) => {
      const formInput = mapQueryResultToForm?.(result?.data)
      const preservedFormInputs = mapPreservedFormInputs?.(
        methods.getValues() as FormType,
      )
      methods.reset({
        ...formInput,
        ...preservedFormInputs,
      })
      callback?.(result, mode)
    }

  const {
    data: graphQLData,
    currentData,
    retrieve,
    save: graphQLSave,
  } = useGraphQL<QueryResult, MutationInput, QueryVariables>({
    id,
    query,
    mutation,
    queryName,
    mutationName,
    queryVariables,
    isCustomMutationInput,
    onError,
    onMutationSuccess: handleResponse((result) => onMutationSuccess?.(result)),
    onQuerySuccess: handleResponse((result) => onQuerySuccess?.(result)),
    onResponseMessages: handleErrors,
    retrieveOnMount,
  })

  const save = async (extraData?: QueryVariables & { name?: string }) => {
    const formValues = methods.getValues() as FormType
    const mutationVariables = mapFormToMutationInput?.(formValues)
    return graphQLSave(mutationVariables, extraData)
  }

  return {
    data: graphQLData,
    currentData,
    save,
    methods,
    retrieve,
  }
}
