import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { Avatar, Grid, useMediaQuery } from '@mui/material'
import { DetailGrid } from '@components'
import { useCustomTheme } from '@hooks'

export const UserProfileView: React.FC = () => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const { control } = useFormContext()
    const formData = useWatch({
        control
    })

    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )

    return (
        <Grid
            container
            xs={12}
            md={10}
            p={5}
            width='auto'
            flexDirection={'column'}
            gap={1}
        >
            <Grid
                item
                display='flex'
                justifyContent={!isMediumScreen ? 'flex-start' : 'space-around'}
            >
                <Avatar
                    sx={{
                        width: 80,
                        height: 80,
                        border: `1px solid ${theme.palette.borderGrey}`
                    }}
                    src={formData?.avatarSrc as string}
                />
            </Grid>
            <DetailGrid tableData={formData?.userDetails}></DetailGrid>

            <DetailGrid
                tableHeadings={t('userProfile.otherInformation')}
                tableData={formData?.additionalUserDetails}
            ></DetailGrid>
        </Grid>
    )
}
