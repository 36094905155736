import { Grid, IconButton, styled } from '@mui/material'

type PlayerProps = {
    isSwipeable: boolean
    height: string | number
    width: string | number
    hasMargin: boolean
    itemHeight: number
    sx?: object
}

export const StyledReactPlayer = styled(Grid, {
    shouldForwardProp: (prop) =>
        prop !== 'width' && prop !== 'height' && prop !== 'itemHeight'
})<PlayerProps>(
    ({ isSwipeable, height, width, hasMargin, itemHeight, sx }) => ({
        backgroundColor: 'black',
        aspectRatio: '3/4',
        height: isSwipeable ? itemHeight : height,
        width: width ?? '100%',
        maxWidth: isSwipeable && '100%',
        minWidth: isSwipeable && '100%',
        margin: hasMargin && '10px',
        position: 'relative',
        ...sx
    })
)

// This code will be removed after complete testing of the video player
// export const StyledReactPlayer = styled(Grid, {
//     shouldForwardProp: (prop) => prop !== 'width' && prop !== 'height'
// })(({ theme, height, width }) => ({
//     height,
//     width,
//     '& video': { backgroundColor: theme.palette.contentPrimary }
// }))

export const StyledMetadataGrid = styled(Grid)(() => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: '100',
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0),black)',
    height: 'fit-content'
}))

export const DotMenuGrid = styled('div')(() => ({
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 100
}))

export const ActionIconGrid = styled('div')(({ bottom }) => ({
    position: 'absolute',
    right: '10px',
    zIndex: 1000,
    bottom: bottom ?? 30,
    '&:hover': { cursor: 'pointer' }
}))

export const StyledReplayButton = styled(IconButton)(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9,
    display: 'none',
    background: 'rgba(0, 0, 0, 0.5)',
    width: '60px',
    height: '60px',
    padding: 0,
    border: 'none'
}))

export const StyledVideoPlayer = styled('video')(
    ({ isSwipeable, theme, sx }) => ({
        width: '100%',
        height: '100%',
        '.vjs-tech': {
            ...sx
        },

        '&.vjs-theme-city': {
            '--vjs-theme-city--primary': theme.palette.backgroundBrandPrimary,
            '--vjs-theme-city--secondary': theme.palette.backgroundPrimary
        },

        '.vjs-control-bar': {
            zIndex: 200,
            paddingTop: '20px',
            background: 'none',
            backgroundImage: 'linear-gradient(to top, #000, rgba(0, 0, 0, 0))',
            height: isSwipeable ? '30px' : '70px'
        },

        '.vjs-button > .vjs-icon-placeholder::before': {
            lineHeight: '50px',
            display: isSwipeable ? 'none' : 'auto'
        },

        '.vjs-play-progress::before': {
            display: 'none'
        },

        '.vjs-progress-control': {
            position: 'absolute',
            top: '0',
            right: '0',
            left: '0',
            width: '100%',
            height: '20px'
        },

        '.vjs-skip-backward-5': { display: isSwipeable ? 'none' : 'auto' },
        '.vjs-skip-forward-5': { display: 'none' },
        '.vjs-progress-control .vjs-progress-holder': {
            position: 'absolute',
            top: '20px',
            right: '0',
            left: '0',
            width: '100%',
            margin: '0',
            height: isSwipeable && '10px'
        },

        '.vjs-play-progress': {
            backgroundColor: 'var(--vjs-theme-city--primary)',
            display: isSwipeable ? 'none' : 'auto'
        },

        '.vjs-remaining-time': {
            order: '1',
            lineHeight: '50px',
            flex: '3',
            textAlign: 'left',
            display: isSwipeable ? 'none' : 'auto'
        },

        '.vjs-play-control': {
            order: '2',
            flex: '50',
            fontSize: '1.75em',
            display: isSwipeable ? 'none' : 'auto'
        },

        '.vjs-subs-caps-button': {
            order: '4',
            display: isSwipeable ? 'none' : 'auto'
        },

        '.vjs-volume-panel': {
            order: '4',
            display: isSwipeable ? 'none' : 'auto'
        },

        '.vjs-volume-panel .vjs-volume-control.vjs-volume-horizontal': {
            alignSelf: 'center'
        },

        '.vjs-text-track-display': {
            position: 'absolute',
            top: 0 /* Adjust as needed */,
            transform: isSwipeable ? 'translateY(-80%)' : ''
        },

        '.vjs-text-track-cue': {
            font: 'small-caption !important',
            '> div': {
                display: 'flex !important',
                borderRadius: '8px',
                margin: '0px 10px !important',
                padding: '5px !important',
                justifyContent: 'center'
            }
        }
    })
)
