import React, { FC } from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const WarningIcon: FC<SvgProps> = ({ color, width = '20', height = '20' }) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentNotice

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8.6142 3.98105L8.61419 3.98106L2.38532 14.7698C1.9306 15.5574 1.70324 15.9512 1.73721 16.2743C1.76683 16.5562 1.91466 16.8122 2.14394 16.9788C2.40681 17.1698 2.86153 17.1698 3.77096 17.1698H16.2287C17.1381 17.1698 17.5929 17.1698 17.8557 16.9788C18.085 16.8122 18.2328 16.5562 18.2625 16.2743C18.2964 15.9512 18.0691 15.5574 17.6143 14.7698L11.3855 3.98105C10.9308 3.19346 10.7034 2.79967 10.4066 2.66751C10.1477 2.55224 9.852 2.55224 9.5931 2.66751C9.29627 2.79967 9.06891 3.19346 8.6142 3.98105ZM10 6.68901C9.58579 6.68901 9.25001 7.02479 9.25001 7.43901C9.25001 7.85322 9.58579 8.18901 10 8.18901H10.0095C10.4237 8.18901 10.7595 7.85322 10.7595 7.43901C10.7595 7.02479 10.4237 6.68901 10.0095 6.68901H10ZM10.75 11.2181C10.75 10.8039 10.4142 10.4681 10 10.4681C9.58579 10.4681 9.25001 10.8039 9.25001 11.2181V14.9972C9.25001 15.4114 9.58579 15.7472 10 15.7472C10.4142 15.7472 10.75 15.4114 10.75 14.9972V11.2181Z'
                fill={_color}
            />
        </svg>
    )
}
export default WarningIcon
