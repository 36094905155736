import React, { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import dayjs, { Dayjs } from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { controlled } from '@hoc'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { ClockIcon } from '@assets'
import { isEmpty } from 'lodash'

export const DurationPicker = ({
    name,
    updateOnChange,
    item,
    index,
    placeholder,
    value,
    typeError,
    disabled
}) => {
    const [inputValue, setInputValue] = useState('')
    const [displayValue, setDisplayValue] = useState('')
    const [isFocused, setIsFocused] = useState(false)
    const { formContext } = useFormContext()
    const { t } = useTranslation()

    const {
        formState: { errors },
        getValues,
        trigger
    } = useFormContext()

    const calculateTotalMinutes = (value) => {
        if (value) {
            const [hours, mins] = value?.includes?.(':')
                ? value.split(':')
                : ['', value]
            const totalMinutes =
                (hours ? parseInt(hours) * 60 : 0) + (mins ? parseInt(mins) : 0)
            return totalMinutes
        }
        return null
    }

    const DisplayHoursAndMinutes = (value) => {
        const [hours, mins] = value?.includes?.(':')
            ? value.split(':')
            : ['', value]

        const hoursInt = parseInt(hours)
        const minsInt = parseInt(mins)

        const totalHours =
            (hoursInt ? hoursInt : 0) + (minsInt ? Math.floor(minsInt / 60) : 0)
        const remainingMins = minsInt ? minsInt % 60 : 0

        setDisplayValue(
            `${
                totalHours.toString() && totalHours.toString() !== '0'
                    ? totalHours.toString() + ' hrs'
                    : ''
            } ${
                remainingMins.toString() && remainingMins.toString() !== '0'
                    ? remainingMins.toString() + ' mins'
                    : ''
            }`
        )
        return [totalHours, remainingMins]
    }

    useEffect(() => {
        if (value) {
            const [totalHours, remainingMins] = DisplayHoursAndMinutes(value)
            let formattedValue = totalHours !== 0 ? `${totalHours}:` : ''

            formattedValue += remainingMins ? remainingMins.toString() : '0'
            setInputValue(formattedValue)
            item.duration = calculateTotalMinutes(value)
            updateOnChange(index, item)
        }
    }, [value])

    const handleFocus = () => {
        setIsFocused(true)
    }

    const handleChange = (event, index, item) => {
        const value = event.target.value

        if (/^\d*(?::(?:[0-5]?[0-9])?)?$/.test(value)) {
            setInputValue(value)

            item.duration = calculateTotalMinutes(value)
            updateOnChange(index, item)
        }
    }

    const handleBlur = () => {
        setIsFocused(false)
        if (!inputValue) {
            setInputValue(null)
            setDisplayValue(null)
        } else {
            const [totalHours, remainingMins] =
                DisplayHoursAndMinutes(inputValue)
            let formattedValue = totalHours !== 0 ? `${totalHours}:` : ''

            formattedValue += remainingMins ? remainingMins.toString() : ''

            setInputValue(formattedValue)
        }

        trigger(name)
    }

    return (
        <TextField
            sx={{
                '& .MuiInputBase-input': {
                    py: '8px',
                    fontSize: '0.875rem'
                }
            }}
            placeholder={placeholder}
            disabled={formContext?.formEditingState?.isReadOnly || disabled}
            name={name}
            value={isFocused ? inputValue : displayValue}
            error={!!typeError}
            onChange={(e) => {
                handleChange(e, index, item)
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            aria-label={t('general.setDuration')}
            label={t('general.setDuration')}
            InputLabelProps={{
                shrink: false,
                sx: {
                    clipPath: 'inset(50%)'
                }
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <ClockIcon />
                    </InputAdornment>
                )
            }}
        />
    )
}

export const ControlledDurationPicker = controlled(DurationPicker)
