import { gql } from '@apollo/client'
import {
  MessagesFragment,
  MetaFragment,
  PreferenceSettingFragment,
} from '../../fragments'

export const SAVE_PREFERENCE_SETTING = gql`
  ${MetaFragment('PreferenceSettingDto')}
  ${MessagesFragment('PreferenceSettingDto')}
  ${PreferenceSettingFragment('PreferenceSetting')}
  mutation savePreferenceSetting($input: SavePreferenceInput!) {
    savePreferenceSetting(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...PreferenceSettingFragment
      }
    }
  }
`
