import React, { FC, ReactElement } from 'react'
import { DropdownMenuConfig } from '@hooks/use-page'
import { SvgProps } from '@types'
import { BackButtonType } from '@constants'

export type PageHeaderMenuConfig = () => Array<{
    label: string
    path: string
    isHeaderLink?: boolean
    isLanding?: string | boolean
    isSideNav?: boolean
    icon?: ReactElement<SvgProps>
    canUserAccess?: boolean
}>

export type BackButtonConfig = {
    backButtonType?: BackButtonType
    showBackButton?: boolean
    customUrl?: string
}

export type FetchEntityVariables = {
    id: string
    type: string
}

export type PageHeaderProps = {
    headerText: string
    headerIcon?: JSX.Element
    ariaLabel?: string
    headerEditAction?: VoidFunction
    goBackAction?: VoidFunction
    showBreadcrumbs?: boolean
    breadcrumbSearchParamAccessor?: string
    fetchEntityVariables?: FetchEntityVariables
    defaultGoBack?: boolean
    primaryButtonTitle?: string
    primaryButtonAction?: VoidFunction
    disablePrimaryButton?: boolean
    secondaryButtonTitle?: string
    primaryButtonIcon?: FC<SvgProps>
    secondaryButtonAction?: VoidFunction
    tertiaryButtonTitle?: string
    secondaryButtonIcon?: JSX.Element
    tertiaryButtonAction?: VoidFunction
    tertiaryButtonIcon?: JSX.Element
    saveForm?: boolean
    kebabMenuConfig?: Array<DropdownMenuConfig>
    primaryButtonMenuConfig?: Array<DropdownMenuConfig>
    menuConfig?: PageHeaderMenuConfig
    permissions?: Array<string>
    hasColumnDirection?: boolean
    isInsideLayout?: boolean
    backButtonConfig?: BackButtonConfig
    showMenu?: boolean
    overlayConfig?: {
        showFilterOverlay?: VoidFunction
        hasFilterOverlay?: boolean
        overlayIcon: JSX.Element
    }
}
