import * as React from 'react'
import { Box } from '@mui/material'
import { REPORT_DATA_TABLE_COLUMNS } from '@constants'
import { CustomTable } from './dataTable'

export const DataView = ({ columns = [], tableData = [] }) => (
    <Box sx={{ height: 'auto', width: '100%' }}>
        <CustomTable columns={columns} tableData={tableData} />
    </Box>
)
