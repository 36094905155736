enum DisplayModeEnum {
  LightMode = 'LIM',
  DarkMode = 'DAM',
  SystemDefault = 'SYD',
}
enum ThemeMode {
  Light = 'light',
  Dark = 'dark',
}

enum DisplayModeGraphqlEnum {
  'LIM' = 'LIM',
  'DAM' = 'DAM',
  'SYD' = 'SYD',
}

export { DisplayModeEnum, DisplayModeGraphqlEnum, ThemeMode }
