import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import languageDetector from 'i18next-browser-languagedetector'

import resources from '@dts/translation'

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(languageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en',
        keySeparator: false,
        fallbackLng: 'en',
        debug: true,
        interpolation: { escapeValue: false }
    })

export default i18n
