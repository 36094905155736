import React from 'react'
import {
    Controller,
    FieldValues,
    FormProvider as RHFFormProvider,
    FormProviderProps as RHFFormProviderProps
} from 'react-hook-form'
import { ErrorNotification } from '@components'

export const FormProvider = <TFieldValues extends FieldValues>({
    children,
    ...methods
}: RHFFormProviderProps) => (
    <RHFFormProvider {...methods}>
        <fieldset
            style={{ border: 'none' }}
            disabled={methods?.formEditingState?.isReadOnly}
        >
            <Controller
                name='generic_error'
                control={methods.control}
                render={({ fieldState: { error } }) =>
                    (error && <ErrorNotification genericError={error} />) ?? (
                        <></>
                    )
                }
            />
            {children}
        </fieldset>
    </RHFFormProvider>
)
