import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, omit } from 'lodash'
// import { NotificationKind } from 'carbon-components-react'
import { FormErrors, FormLevelMessages } from '@dts/client-utils'
import { Alert, ListItem } from '@mui/material'
import { StyledList } from './styles'

export type ErrorNotificationProps = {
    genericError?: Array<FormErrors>
}

export type FormMessage = {
    id: string
    kind: unknown
    title: string
    fiteredMessages: Array<FormErrors>
}

export const ErrorNotification: React.FC<ErrorNotificationProps> = ({
    genericError
}) => {
    const { t } = useTranslation()

    const filteredGenericError = omit(genericError, 'ref')

    const formLevelMessages: Array<FormErrors> = Object.values(
        filteredGenericError
    ) as Array<FormErrors>

    const [errorMessage, setErrorMessage] = useState('')

    const formMessages: Array<FormMessage> = [
        {
            id: 'inline-notification-error',
            kind: 'error',
            title: t('errorNotification.general.message'),
            fiteredMessages: formLevelMessages.filter(
                (message) => message.type === FormLevelMessages.ERRORS
            )
        },
        {
            id: 'inline-notification-warning',
            kind: 'warning',
            title: t('general.warning'),
            fiteredMessages: formLevelMessages.filter(
                (message) => message.type === FormLevelMessages.WARNINGS
            )
        },
        {
            id: 'inline-notification-info',
            kind: 'info',
            title: t('general.information'),
            fiteredMessages: formLevelMessages.filter(
                (message) => message.type === FormLevelMessages.INFO
            )
        }
    ]

    useEffect(() => {
        setErrorMessage(formLevelMessages[0]?.key)
        if (
            formLevelMessages.length &&
            formLevelMessages[0]?.key !== errorMessage
        ) {
            document
                .getElementById('inline-notification-error')
                ?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest'
                })
        }
    }, [formLevelMessages])

    return (
        <>
            {formMessages.map(({ id, kind, title, fiteredMessages }) =>
                fiteredMessages && !isEmpty(fiteredMessages) ? (
                    <Alert key={id} severity='error'>
                        <StyledList>
                            {fiteredMessages.map(
                                (filteredMessage) => filteredMessage.key
                            )}
                        </StyledList>
                    </Alert>
                ) : null
            )}
        </>
    )
}
