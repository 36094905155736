import React from 'react'
import { useTranslation } from 'react-i18next'
import { ControlledTextInput } from '@components'
import { Grid } from '@mui/material'
import { HelperTextPosition } from '@constants'

export const Duplicate = () => {
    const { t } = useTranslation()
    return (
        <Grid container>
            <Grid item xs={12} md={12} gap={4}>
                <ControlledTextInput
                    id='title'
                    label={t('general.title')}
                    name='title'
                    multiline
                    rows={1}
                    helperTextConfig={{
                        wordLength: 200,
                        helperTextPosition: HelperTextPosition.Right
                    }}
                    fullWidth
                />
            </Grid>
        </Grid>
    )
}
