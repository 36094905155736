import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Avatar, Grid, useMediaQuery } from '@mui/material'
import { DetailGrid } from '@components'
import { useCustomTheme } from '@hooks'

export const AdminView: React.FC = () => {
    const { control } = useFormContext()
    const { theme } = useCustomTheme()
    const formData = useWatch({
        control
    })

    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )

    return (
        <Grid
            container
            xs={12}
            md={10}
            px={3}
            width='auto'
            flexDirection={'column'}
            gap={1}
        >
            <Grid
                item
                display='flex'
                justifyContent={!isMediumScreen ? 'flex-start' : 'space-around'}
            >
                <Avatar
                    sx={{
                        width: 80,
                        height: 80,
                        border: `1px solid ${theme.palette.borderGrey}`
                    }}
                    src={formData?.avatarSrc as string}
                />
            </Grid>

            {formData?.adminDetails?.map((data, index) => (
                <DetailGrid key={index} tableData={data}></DetailGrid>
            ))}
        </Grid>
    )
}
