import React from 'react'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { usePageHeader, useRoutes } from '@hooks'

export const AdminTopicsLayout: React.FC = () => {
    const { adminTopicsRoutes } = useRoutes()
    const { t } = useTranslation()

    const { pageHeader } = usePageHeader({
        headerText: t('sideNavItems.topics'),
        menuConfig: () => adminTopicsRoutes
    })

    return (
        <>
            {pageHeader}
            <Grid py={1} px={2}>
                <Outlet />
            </Grid>
        </>
    )
}
