import { useEffect, useState } from 'react'

export const useOnlineState = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine)

    useEffect(() => {
        // Add event listeners for online and offline events
        window.addEventListener('online', handleOnline)
        window.addEventListener('offline', handleOffline)

        // Clean up the event listeners when the component unmounts
        return () => {
            window.removeEventListener('online', handleOnline)
            window.removeEventListener('offline', handleOffline)
        }
    }, [])

    const handleOnline = () => {
        setIsOnline(true)
    }

    const handleOffline = () => {
        setIsOnline(false)
    }

    return { isOnline }
}
