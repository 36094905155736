import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { Link } from '@components'
import { useCustomTheme } from '@hooks'

export const LoginFooter: React.FC = () => {
    const { theme } = useCustomTheme()
    const [menuItems] = useState([
        {
            label: 'Contact',
            path: '#',
            mailTo: 'info@grandriversolutions.com'
        },
        {
            label: 'Privacy Policy',
            path: 'privacy-policy' // TBD (mockup dependency)
        }
    ])
    return menuItems.map((item, index) => (
        <Grid item key={index}>
            {/* Will be sorted out later on
           <Typography
                variant='body2'
                sx={{ fontWeight: 'bold' }}
                color={'primary'}
            >
                &nbsp;|&nbsp;
            </Typography> */}
            <Typography variant='body2'>
                <Link
                    route={item}
                    key={index}
                    sx={{ color: theme.palette.contentLink, ml: 1 }}
                />
            </Typography>
        </Grid>
    ))
}
