import React from 'react'
import { Box } from '@mui/system'
import { CustomLoader } from '@components'
import { useLoader } from './use-loader'
/**
 * This hook allows management of the document head of this app.
 * The document head contains meta information such as title, meta tags, styles, scripts, etc.
 * it is returning a iframe form
 */
export const useHelmet = ({
    embedForm = false,
    src = '#',
    asyncFetch = undefined,
    scriptBodyCallback,
    scriptBodyCallbackConfig,
    formContainerId = '',
    reload
}) => {
    const { loading, setLoading } = useLoader()

    const { error, setError } = React.useState('')
    const [formContainer] = React.useState(
        embedForm && formContainerId
            ? document.getElementById(formContainerId)
            : null
    )
    React.useEffect(() => {
        setLoading(true)
        if (embedForm) {
            const script = document.createElement('script')
            script.charset = 'utf-8'
            script.type = 'text/javascript'
            script.async = asyncFetch
            script.src = src

            if (formContainer) {
                formContainer.innerHTML = '' // Clear container
            }

            script.onload = async () => {
                try {
                    const v = await scriptBodyCallback?.({
                        ...scriptBodyCallbackConfig,
                        target: `#${formContainerId}`
                    })
                    if (v) {
                        setLoading(false)
                    }
                } catch (e) {
                    setLoading(false)

                    setError(e)
                }
            }

            document.head.appendChild(script)

            return () => {
                document.head.removeChild(script)
            }
        }
    }, [reload])

    const formiFrame = () => (
        <div id={formContainerId}>
            {loading ? (
                <Box
                    sx={{
                        paddingTop: '20px',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <CustomLoader />
                </Box>
            ) : error ? (
                <Box
                    sx={{
                        paddingTop: '20px',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    {error}
                </Box>
            ) : (
                <>{/* contains the form iFrmae */}</>
            )}
        </div>
    )

    return {
        formiFrame: formiFrame()
    }
}
