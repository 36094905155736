import React from 'react'
import { ListItem } from '@components'
import { Grid, List, Typography, useMediaQuery, Divider } from '@mui/material'

type TableConfig = {
    tableData: Array<{
        label: string
        value: string
    }>
    tableHeadings?: string
    hasDividers?: boolean
}
export const DetailGrid: React.FC<TableConfig> = ({
    tableData,
    tableHeadings,
    hasDividers = false
}) => {
    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )

    return (
        <Grid
            container
            direction='column'
            justifyContent={!isMediumScreen ? 'flex-start' : 'center'}
            flexWrap={'nowrap'}
        >
            {tableHeadings && (
                <Grid item mb={2} mt={1}>
                    <Typography
                        variant='h1'
                        sx={{
                            wordBreak: 'break-all'
                        }}
                    >
                        {tableHeadings}
                    </Typography>
                </Grid>
            )}

            <Grid item mb={1}>
                <List>
                    {tableData.map(({ label, value }, index) => (
                        <ListItem
                            key={label}
                            label={label}
                            value={value ?? '-'}
                            id={label}
                            borderBottom={
                                hasDividers && tableData.length - 1 !== index
                            }
                        />
                    ))}
                </List>
            </Grid>

            <Grid item xs={6} md={6}>
                <Divider />
            </Grid>
        </Grid>
    )
}
