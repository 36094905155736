import { ARTICLE, CAMPUS, CLIENT, COURSE, LEARNER_LIST, TOPIC, TRAINING, USER, VERTICAL, VIDEO } from '../'

export const SORTED_ELASTICSEARCH_INDICES = {
  [VIDEO]: 0,
  [ARTICLE]: 1,
  [USER]: 2,
  [CLIENT]: 3,
  [CAMPUS]: 4,
  [TOPIC]: 5,
  [VERTICAL]: 6,
  [COURSE]: 7,
  [TRAINING]: 8,
  [LEARNER_LIST]: 9,
}
