import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, CourseFields } from '../../fragments'

export const GET_CAMPUS_COURSES = gql`
  ${MessagesFragment('ClientCoursesResponseDto')}
  ${MetaFragment('ClientCoursesResponseDto')}
  ${CourseFields('CourseDto')}
  query getCampusCourses($clientId: String!, $campusId: String!) {
    getCampusCourses(clientId: $clientId, campusId: $campusId) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...CourseFields
      }
    }
  }
`
