import React, {
    Dispatch,
    MutableRefObject,
    SetStateAction,
    createContext,
    useEffect,
    useRef,
    useState
} from 'react'
import { useGraphQLWrapper } from '@hooks'
import { SAVE_VIDEO_INTERACTION } from '@dts/graphql'
import { useMediaQuery } from '@mui/material'

type VideoData = {
    videoVolume: number
    setVideoVolume: Dispatch<SetStateAction<number>>
    isMuted: boolean
    setIsMuted: Dispatch<SetStateAction<boolean>>
    listRef: MutableRefObject<null | { current: object }>
    saveVideoInteraction: (
        mutationInput: {
            id?: string
        },
        extraData?: object & {
            name?: string
        }
    ) => Promise<unknown> | undefined
    scrollToNextItem: VoidFunction
    scrollToPreviousItem: VoidFunction
    itemHeight?: number
}

export const VideoContext = createContext<VideoData>({
    videoVolume: 1,
    isMuted: false,
    scrollToNextItem: () => undefined,
    scrollToPreviousItem: () => undefined,
    setVideoVolume: () => undefined,
    setIsMuted: () => undefined,
    saveVideoInteraction: () => undefined,
    listRef: { current: null }
})

export const VideoProvider = ({ children }) => {
    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )
    const [videoVolume, setVideoVolume] = useState<number>(1)
    const [isMuted, setIsMuted] = useState<boolean>(false)
    const [itemHeight, setItemHeight] = useState<number>(
        isMediumScreen ? window.innerHeight - 175 : 0.75 * window.innerHeight
    )
    const listRef = useRef({ current: null })
    const pageHeaderElement = document.getElementById('page-header-parent')

    useEffect(() => {
        const pageHeaderHeight = pageHeaderElement?.clientHeight ?? 0
        const calculatedHeight = isMediumScreen
            ? window.innerHeight - (111 + pageHeaderHeight)
            : 0.75 * window?.innerHeight

        setItemHeight(calculatedHeight)
    }, [pageHeaderElement?.clientHeight, window.innerHeight])

    const { save: saveVideoInteraction } = useGraphQLWrapper({
        mutation: SAVE_VIDEO_INTERACTION,
        mutationName: 'saveVideoInteraction',
        suppressToast: true
    })

    const scrollToNextItem = () => {
        if (listRef?.current) {
            const currentScroll = listRef.current.state.scrollOffset
            const nextScroll = currentScroll + itemHeight
            listRef.current.scrollTo(nextScroll)
        }
    }

    const scrollToPreviousItem = () => {
        if (listRef?.current) {
            const currentScroll = listRef.current.state.scrollOffset
            const previousScroll = currentScroll - itemHeight
            listRef.current.scrollTo(previousScroll)
        }
    }

    return (
        <VideoContext.Provider
            value={{
                saveVideoInteraction,
                videoVolume,
                setVideoVolume,
                listRef,
                scrollToNextItem,
                scrollToPreviousItem,
                isMuted,
                setIsMuted,
                itemHeight
            }}
        >
            {children}
        </VideoContext.Provider>
    )
}
