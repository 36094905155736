enum MonthWeekEnum {
  First = 'FIR',
  Second = 'SEC',
  Third = 'THI',
  Forth = 'FOR',
  Last = 'LAS',
}

enum MonthWeekGraphqlEnum {
  FIR = 'FIR',
  SEC = 'SEC',
  THI = 'THI',
  FOR = 'FOR',
  LAS = 'LAS',
}

export { MonthWeekEnum, MonthWeekGraphqlEnum }
