import React, { FC } from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const DragAndDropIcon: FC<SvgProps> = ({
    color,
    width = '24',
    height = '25'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 24 25'
            fill='none'
        >
            <rect
                x='9'
                y='9.29199'
                width='14'
                height='13'
                rx='2'
                stroke={_color}
                strokeWidth='1.5'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M1 2.29199H3V4.29199H1V2.29199ZM13 2.29199H15V4.29199H13V2.29199ZM6 2.29199H4V4.29199H6V2.29199ZM7 2.29199H9V4.29199H7V2.29199ZM12 2.29199H10V4.29199H12V2.29199ZM1 5.29199H3V7.29199H1V5.29199ZM15 5.29199H13V7.29199H15V5.29199ZM1 11.292H3V13.292H1V11.292ZM3 8.29199H1V10.292H3V8.29199ZM1 14.292H3V16.292H1V14.292ZM6 14.292H4V16.292H6V14.292Z'
                fill={_color}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M20 13.292H12V11.792H20V13.292ZM20 16.292H12V14.792H20V16.292ZM12 19.292H16V17.792H12V19.292Z'
                fill={_color}
            />
        </svg>
    )
}

export default DragAndDropIcon
