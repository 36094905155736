import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_SURVEY_RESULT_DETAIL = gql`
  ${MessagesFragment('SurveyResultResponseDto')}
  ${MetaFragment('SurveyResultResponseDto')}
  query getSurveyResults($id: String!) {
    getSurveyResults(id: $id) {
      ...MetaFragment
      ...MessagesFragment
      data {
        id
        title
        surveyQuestions {
          id
          title
          displayOrder
          responsesCount
          responsesPercentage
          correctResponsesPercentage
          incorrectResponsesPercentage
          isKnowledgeRelatedQuestion
          surveyOptions {
            id
            title
            displayOrder
            isAnswer
            responsesCount
            responsesPercentage
          }
        }
        surveyTypeId
        questionsCount
        responsesCount
        assigneesCount
        correctAnswersCount
        incorrectAnswersCount
      }
    }
  }
`
