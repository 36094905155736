import { REGEX } from '../../../../constants/lib'
import { hasDuplicates } from '../../utils'
import * as yup from 'yup'

export const ResourcesSchema = yup.object().shape({
  categories: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .trim()
        .required('errors.required')
        .max(200, 'errors.max')
        .test(
          'unique-title',
          'errors.categories.uniqueTitle',
          function (value) {
            const categories = this?.from?.[1]?.value?.categories
            if (categories && value) {
              const trimmedValue = value?.trim()?.toLowerCase()
              const trimmedCategories = categories?.map((c) =>
                c?.title?.trim()?.toLowerCase(),
              )
              return hasDuplicates(trimmedCategories, trimmedValue)
                ? false
                : true
            }

            return true
          },
        ),
      resources: yup.array().of(
        yup.object().shape({
          title: yup
            .string()
            .trim()
            .required('errors.required')
            .max(200, 'errors.max')
            .test(
              'unique-title',
              'errors.resources.uniqueTitle',
              function (value) {
                const resources = this?.from?.[1]?.value?.resources
                if (resources && value) {
                  const trimmedValue = value?.trim()?.toLowerCase()
                  const trimmedResources = resources?.map((c) =>
                    c?.title?.trim()?.toLowerCase(),
                  )
                  return hasDuplicates(trimmedResources, trimmedValue)
                    ? false
                    : true
                }

                return true
              },
            ),
          description: yup.string().nullable().max(1000, 'errors.max'),
          email: yup
            .string()
            .trim()
            .nullable()
            .test('valid-email', 'errors.emailValidity', function (value) {
              if (value && !REGEX?.email?.test(value)) {
                return false
              }
              return true
            }),
          phoneNumberOneDescription: yup
            .string()
            .nullable()
            .max(200, 'errors.max'),
          phoneNumberTwoDescription: yup
            .string()
            .nullable()
            .max(200, 'errors.max'),
          phoneNumberThreeDescription: yup
            .string()
            .nullable()
            .max(200, 'errors.max'),
          websiteDisplayText: yup.string().nullable().max(200, 'errors.max'),
          websiteUrl: yup.string().trim().nullable().url('errors.linkValidity'),
          onlineChatDisplayText: yup.string().nullable().max(200, 'errors.max'),
          onlineChatUrl: yup
            .string()
            .trim()
            .nullable()
            .url('errors.linkValidity'),
        }),
      ),
    }),
  ),
})
