import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Grid, Tooltip, Typography } from '@mui/material'
import {
    useCustomTheme,
    useDirectory,
    useGraphQLWrapper,
    useModalWithGraphQL
} from '@hooks'
import {
    AddIcon,
    ArchiveIcon,
    CategoryIcon,
    DeleteIcon,
    DuplicateIcon,
    EditRecordIcon,
    PlusIcon,
    RestoreIcon
} from '@assets'
import {
    DuplicateCourseSchema,
    TopicListSchema,
    formatDate
} from '@dts/client-utils'
import {
    DELETE_COURSE,
    DUPLICATE_COURSE,
    GET_COURSE,
    UPDATE_COURSE
} from '@dts/graphql/lib'
import {
    ConfirmationDialog,
    DetailedDirectoryLink,
    Duplicate,
    StyledChip,
    TextWithTooltip
} from '@components'
import { CourseStatus } from '@constants'
import { GET_COURSE_DIRECTORY, GET_TOPICS } from '@dts/graphql'
import { startCase } from 'lodash'
import { SearchableTopicsList } from './course-creation'
import { UserPermissionEnum } from '@dts/constants'

export const Courses = ({ status }: { status: string }) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const navigate = useNavigate()

    const updateCourseConfig = {
        mutation: UPDATE_COURSE,
        mutationName: 'updateCourse',
        onMutationSuccess: () => {
            retrieve?.({
                variables: {
                    options: queryOptions
                }
            })
        },
        isCustomMutationInput: true
    }

    const { data: topicsData } = useGraphQLWrapper({
        query: GET_TOPICS,
        queryName: 'getTopics',
        retrieveOnMount: true
    })

    const { openPrepopulatedDialog: openUpdateTopicsDialog } =
        useModalWithGraphQL({
            modalConfig: {
                Component: () => (
                    <SearchableTopicsList allTopics={topicsData?.data} />
                ),

                updationConfig: {
                    primaryButtonText: t('general.save'),
                    header: `${t('general.update')} ${t('general.topics')}`
                },
                hasCancelButton: true
            },
            query: GET_COURSE,
            queryName: 'getCourse',
            mapQueryResultToForm: ({ id, title, courseTopics }) => ({
                id,
                title,
                addedTopics: courseTopics?.map((courseTopic) => ({
                    ...courseTopic,
                    duration: courseTopic.duration.toString()
                })),
                shouldValidateName: false
            }),
            initialValues: {
                title: null,
                topics: [],
                addedTopics: []
            },
            ...updateCourseConfig,
            mapFormToMutationInput: (form) => {
                const { id, topics } = form
                const topicsAdded = topics.filter((topic) => topic.isAdded)

                return {
                    input: {
                        id,
                        courseTopics: topicsAdded?.map((topic) => ({
                            topicId: topic?.id,
                            duration: topic?.duration ?? 0,
                            isAssigned: topic?.isAssigned
                        }))
                    }
                }
            },
            yupResolverSchema: TopicListSchema
        })
    const duplicateCourseConfig = {
        mutation: DUPLICATE_COURSE,
        mutationName: 'duplicateCourse',
        onMutationSuccess: () => {
            retrieve?.({
                variables: {
                    options: queryOptions
                }
            })
        },
        isCustomMutationInput: true
    }

    const deleteCourseConfig = {
        mutation: DELETE_COURSE,
        mutationName: 'deleteCourse',

        onMutationSuccess: () => {
            retrieve?.({
                variables: {
                    options: queryOptions
                }
            })
        },
        isCustomMutationInput: true
    }

    const { save: deleteCourse } = useGraphQLWrapper({
        ...deleteCourseConfig
    })
    const { save: updateCourse } = useGraphQLWrapper({ ...updateCourseConfig })

    const { save: duplicateCourse } = useGraphQLWrapper({
        ...duplicateCourseConfig
    })

    const updateArchive = (params, archive) => ({
        input: {
            id: params?.row?.id,
            courseTopics: params?.row?.courseTopics?.map((topic) => ({
                topicId: topic?.topicId,
                duration: topic?.duration,
                isAssigned: topic?.isAssigned
            })),
            isArchived: archive
        }
    })

    const { directory, retrieve, queryOptions } = useDirectory({
        directoryConfig: {
            query: GET_COURSE_DIRECTORY,
            queryName: 'getCourseDirectory',

            queryVariables: {
                args: {
                    tab:
                        status === CourseStatus.all
                            ? CourseStatus.available
                            : status
                }
            },
            config: {
                customActions: [
                    {
                        icon: <RestoreIcon />,
                        label: `${t('general.restore')}`,
                        ariaLabel: t('general.restore'),
                        showInMenu: true,
                        action: (params) => {
                            openConfirmationDialog(updateArchive(params, false))
                        },
                        hide: (params) => params?.row?.isArchived !== true,
                        permissions: [UserPermissionEnum.CourseCreateModify]
                    },
                    {
                        icon: <EditRecordIcon />,
                        ariaLabel: t('general.edit'),
                        label: `${t('general.edit')}`,
                        showInMenu: true,
                        action: (params) =>
                            navigate(`/courses/${params?.row?.id}`),
                        permissions: [UserPermissionEnum.CourseCreateModify]
                    },
                    {
                        icon: (
                            <DeleteIcon
                                color={theme.palette.contentSecondary}
                                fill={theme.palette.contentSecondary}
                            />
                        ),
                        ariaLabel: t('general.delete'),
                        label: `${t('general.delete')}`,
                        showInMenu: true,
                        action: (params) => {
                            deleteCourse({
                                deleteCourseId: params?.row?.id
                            })
                        },
                        permissions: [UserPermissionEnum.CourseDelete]
                    },
                    {
                        icon: <DuplicateIcon />,
                        label: `${t('createArticle.duplicate')}`,
                        ariaLabel: `${t('createArticle.duplicate')}`,
                        showInMenu: true,
                        action: (params) =>
                            openPrepopulatedDialog({
                                id: params?.id
                            }),
                        permissions: [UserPermissionEnum.CourseCreateModify]
                    },
                    {
                        icon: <ArchiveIcon />,
                        label: `${t('createArticle.archive')}`,
                        ariaLabel: `${t('createArticle.archive')}`,
                        showInMenu: true,
                        action: (params) => {
                            archiveConfirmationDialog(
                                updateArchive(params, true)
                            )
                        },
                        hide: (params) =>
                            params?.row?.isArchived || params?.row?.isDraft,
                        permissions: [UserPermissionEnum.CourseCreateModify]
                    },
                    {
                        icon: <PlusIcon />,
                        ariaLabel: `${t('general.edit')} ${t('general.topic')}`,
                        role: 'button',
                        showInMenu: false,
                        action: (params) => {
                            openUpdateTopicsDialog({
                                id: params?.id
                            })
                        },
                        permissions: [UserPermissionEnum.CourseCreateModify]
                    }
                ],
                columns: [
                    {
                        field: 'title',
                        headerName: t('general.title'),
                        minWidth: 250,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={
                                    <DetailedDirectoryLink
                                        title={params?.row?.title}
                                        tabIndex={params?.tabIndex}
                                        description={params?.row?.description}
                                        isDraft={params?.row?.isDraft}
                                        onClick={() => {
                                            navigate(
                                                `/courses/${params?.row?.id}`
                                            )
                                        }}
                                        additionalInfo={[
                                            {
                                                icon: CategoryIcon,
                                                text: `${
                                                    params?.row
                                                        ?.verticalsCount ?? 0
                                                } ${t(
                                                    'sideNavItems.verticals'
                                                )}`
                                            }
                                        ]}
                                    />
                                }
                                toolTip={params?.row?.title}
                                tabIndex={params?.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'courseTopics',
                        headerName: t('general.topic'),
                        minWidth: 300,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={
                                    <Grid item py={1}>
                                        {params?.row?.courseTopics?.map(
                                            (topic: {
                                                id: number
                                                title: string
                                            }) => (
                                                <StyledChip
                                                    label={topic?.topic?.title}
                                                    key={topic?.id}
                                                    sx={{ mb: 1 }}
                                                />
                                            )
                                        )}
                                    </Grid>
                                }
                                toolTip={params?.row?.courseTopics
                                    ?.map((topic) => topic?.topic?.title)
                                    ?.join(', ')}
                                tabIndex={params?.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'createdAt',
                        minWidth: 100,
                        headerName: t('general.dateCreated'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={
                                    params?.value
                                        ? formatDate(params?.value)
                                        : ''
                                }
                                tabIndex={params?.tabIndex}
                            />
                        )
                    }
                ],
                hideFooter: false,
                pagination: true
            },
            enableSearch: true,
            toolbarConfig: {
                header: `${startCase(status)} ${t('general.courses')}`,
                addNewButtonConfig: {
                    label: t('general.createNew'),
                    ariaLabel: t('general.course'),
                    action: () => navigate(`/courses/new`),
                    icon: AddIcon,
                    permissions: [UserPermissionEnum.CourseCreateModify]
                }
            },
            id: 'courses'
        }
    })

    /**
     * openConfirmationDialog is used to restore archive course
     */
    const { openConfirmationDialog } = useModalWithGraphQL({
        ...updateCourseConfig,

        modalConfig: {
            maxWidth: 'sm',
            Component: () => (
                <ConfirmationDialog
                    message={t('course.restore.confirmation.message')}
                />
            ),
            confirmationConfig: {
                primaryButtonText: t('general.confirm'),
                header: `${t('general.confirmation')}`
            },
            hasCancelButton: true
        }
    })

    /**
     * openConfirmationDialog is used to  archive course
     */
    const { openConfirmationDialog: archiveConfirmationDialog } =
        useModalWithGraphQL({
            ...updateCourseConfig,

            modalConfig: {
                maxWidth: 'sm',
                Component: () => (
                    <ConfirmationDialog
                        message={t('archive.confirmation.message')}
                    />
                ),
                confirmationConfig: {
                    primaryButtonText: t('general.confirm'),
                    header: `${t('general.confirmation')}`
                },
                hasCancelButton: true
            }
        })

    /**
     * openPrepopulatedDialog is used to duplicate course
     */
    const { openPrepopulatedDialog } = useModalWithGraphQL({
        ...duplicateCourseConfig,
        mapFormToMutationInput: ({ id, title }) => ({
            input: { id, title }
        }),
        query: GET_COURSE,
        queryName: 'getCourse',
        mapQueryResultToForm: ({ id, title }) => ({
            id,
            title
        }),
        initialValues: {
            title: null
        },
        modalConfig: {
            maxWidth: 'sm',
            Component: Duplicate,
            updationConfig: {
                primaryButtonText: t('general.confirm'),
                header: `${t('createArticle.duplicate')} ${t('general.course')}`
            },

            hasCancelButton: true
        },
        yupResolverSchema: DuplicateCourseSchema
    })

    return directory
}
