import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_KEYWORD_SEARCH_DATA = gql`
  ${MetaFragment('KeywordSearchResponseDto')}
  ${MessagesFragment('KeywordSearchResponseDto')}
  query getKeywordSearchData($args: KeywordSearchArgsDto!) {
    getKeywordSearchData(args: $args) {
      ...MetaFragment
      ...MessagesFragment
      data {
        suggestion
        data {
          category
          result {
            id
            title
            description
            imageSrc
            roles {
              id
              roleId
              title
              description
            }
          }
        }
      }
    }
  }
`
