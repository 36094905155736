import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const EmailIcon: React.FC<SvgProps> = ({
    color,
    height = '20',
    width = '20'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 24 24`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g id='icons/email'>
                <path
                    id='Icon'
                    d='M1.6665 5.83301L8.4706 10.5959C9.02158 10.9816 9.29707 11.1744 9.59672 11.2491C9.86142 11.3151 10.1383 11.3151 10.403 11.2491C10.7026 11.1744 10.9781 10.9816 11.5291 10.5959L18.3332 5.83301M5.6665 16.6663H14.3332C15.7333 16.6663 16.4334 16.6663 16.9681 16.3939C17.4386 16.1542 17.821 15.7717 18.0607 15.3013C18.3332 14.7665 18.3332 14.0665 18.3332 12.6663V7.33301C18.3332 5.93288 18.3332 5.23281 18.0607 4.69803C17.821 4.22763 17.4386 3.84517 16.9681 3.60549C16.4334 3.33301 15.7333 3.33301 14.3332 3.33301H5.6665C4.26637 3.33301 3.56631 3.33301 3.03153 3.60549C2.56112 3.84517 2.17867 4.22763 1.93899 4.69803C1.6665 5.23281 1.6665 5.93288 1.6665 7.33301V12.6663C1.6665 14.0665 1.6665 14.7665 1.93899 15.3013C2.17867 15.7717 2.56112 16.1542 3.03153 16.3939C3.56631 16.6663 4.26637 16.6663 5.6665 16.6663Z'
                    stroke={_color}
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
        </svg>
    )
}

export default EmailIcon
