enum SurveyTypeEnum {
    Pulse = 'PLS',
    PreTest = 'RRS',
    PostTest = 'POS',
}

enum SurveyTypeGraphqlEnum {
    'PLS' = 'PLS',
    'RRS' = 'RRS',
    'POS' = 'POS',
}

export { SurveyTypeEnum, SurveyTypeGraphqlEnum }
