import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IconButton, Tooltip } from '@mui/material'
import { TooltipPlacement } from '@constants'
import { OptionListActionItemProps } from './types'

export const OptionListActionItem: FC<OptionListActionItemProps> = ({
    ariaLabel,
    onClick,
    disabled,
    id,
    tabIndex,
    disableRipple,
    viewBox,
    sx,
    tooltipTitle,
    actionIcon: ActionIcon
}) => {
    const { ...formContext } = useFormContext()
    const { t } = useTranslation()

    return (
        <>
            {formContext?.formEditingState?.isReadOnly ? (
                <></>
            ) : (
                <Tooltip
                    title={tooltipTitle || t('general.add')}
                    placement={TooltipPlacement.topEnd}
                >
                    <IconButton
                        disableRipple={disableRipple}
                        id={id}
                        sx={sx}
                        tabIndex={tabIndex}
                        aria-label={ariaLabel}
                        onClick={() => onClick?.()}
                        disabled={
                            formContext?.formEditingState?.isReadOnly ||
                            disabled
                        }
                    >
                        <ActionIcon />
                    </IconButton>
                </Tooltip>
            )}
        </>
    )
}
