import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_ALL_CREATORS = gql`
  ${MessagesFragment('GetMultipleUserResponseDto')}
  ${MetaFragment('GetMultipleUserResponseDto')}
  query getAllContentCreators(
    $options: PaginationDto!
    $args: GetContentCreatorsArgumentsDto
  ) {
    getAllContentCreators(options: $options, args: $args) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
        firstName
        avatar
        avatarSrc
        status {
          id
          title
        }
        active
        lastName
        email
        dateOfBirth
        createdAt
      }
    }
  }
`
