import { gql } from '@apollo/client'
import {
  MessagesFragment,
  MetaFragment,
  LearnerFields
} from '../../fragments'

export const DELETE_USER = gql`
  ${MetaFragment('GetUserResponseDto')}
  ${MessagesFragment('GetUserResponseDto')}
  ${LearnerFields('UserDataDto')}
  mutation deleteUser($id: String!, $clientId: String) {
    deleteUser(id: $id, clientId: $clientId) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...LearnerFields
      }
    }
  }
`
