import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { CalendarIcon, DatePickerProps } from '@mui/x-date-pickers'
import { controlled } from '@hoc'
import dayjs, { Dayjs } from 'dayjs'
import { isArray } from 'lodash'
import { injectAriaLabelToInnerInput } from '@utils'
import { SingleInputDateRangePickerProps } from './types'

export const SingleInputDateRangePicker = ({
    id,
    name,
    width,
    views,
    disabled,
    openTo,
    helperText,
    error,
    onChange,
    format,
    value,
    minDate,
    ariaLabel = '',
    isDisableFuture = true,
    ...props
}: SingleInputDateRangePickerProps) => {
    const {
        register,
        setValue,
        getValues,
        trigger,
        watch,
        formState: { errors },
        ...formContext
    } = useFormContext()

    const formattedValue = (v: Array<string>): Array<string> | Array<Dayjs> => {
        if (v && v.length) {
            return isArray(getValues(name)) ? v?.map((i) => dayjs(i)) : v
        }
        return []
    }

    React.useEffect(() => {
        injectAriaLabelToInnerInput(
            '.MuiInputBase-input.MuiOutlinedInput-input',
            ariaLabel
        )
    }, [])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            {/* <DemoContainer components={['SingleInputDateRangeField']}> */}
            <DateRangePicker
                {...props}
                slots={{ field: SingleInputDateRangeField }}
                name={name}
                role={id}
                disabled={disabled}
                openTo={openTo ?? 'day'}
                value={formattedValue(value) as [string | null, string | null]}
                disableFuture={isDisableFuture}
                onChange={onChange}
                sx={{
                    '& > div': {
                        height: '32px'
                    }
                }}
                slotProps={{
                    textField: {
                        InputProps: { endAdornment: <CalendarIcon /> }
                    }
                }}
                /**
                 * TODO: Needs testing, this will be used including demo-container above
                 */
                // views={views ?? ['year', 'day']}
                //format={format ?? 'MM/DD/YYYY'}
                // minDate={minDate}
                // disableOpenPicker={
                //     formContext?.formEditingState?.isReadOnly || false
                // }
                // onClose={() => trigger(name)}
                // isRequired
                // slotProps={{
                //     textField: {
                //         onBlur: () => trigger(name),
                //         error: error ?? !!errors?.[name],
                //         id
                //     }
                // }}
            />
            {/* </DemoContainer> */}
        </LocalizationProvider>
    )
}

export const ControlledSingleInputDateRangePicker = controlled(
    SingleInputDateRangePicker
)
