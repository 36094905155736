import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { isMediumScreen, uniqueBy } from '@utils'
import { Grid, Typography } from '@mui/material'
import { ActiveIcon, AddIcon, DeleteIcon } from '@assets'
import {
    ConfirmationDialog,
    ControlledDataTable,
    ControlledSwitch,
    UploadAvatar
} from '@components'
import { useCustomTheme, useModalWithGraphQL, usePageHeader } from '@hooks'
import { DELETE_PORTAL_CLIENT, GET_CLIENTS, DELETE_USER } from '@dts/graphql'
import { AdminClientSchema } from '@dts/client-utils'
import { AccessibleAction, can_access } from '@hoc'
import { StyledButton } from '../clients-management/styles'
import { AdminDetailsForm } from './admin-details'
import { ClientsListForm } from './clients-list-form'
import { AdminProfileProps, ClientPortalStatus } from './types'
import { UserPermissionEnum } from '@dts/constants'

export const AdminProfileForm: React.FC<AdminProfileProps> = ({
    isCurrentUser,
    isClientAdmin = false,
    isManageAdmin = false,
    retrieve,
    setIsUserActive
}) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const { watch, setValue, getValues, control } = useFormContext()
    const userId = getValues('id')
    const portalClients = getValues('portalClients')
    const activeStatus = useWatch({ control, name: 'active' })

    const navigate = useNavigate()

    const { openModalWithForm: openNewClientDialog } = useModalWithGraphQL({
        query: GET_CLIENTS,
        queryName: 'getClients',
        initialValues: {
            client: null,
            clientsData: []
        },
        mapQueryResultToForm: (clients) => ({
            clientsData: clients,
            client: null
        }),
        yupResolverSchema: AdminClientSchema,
        modalConfig: {
            updationConfig: {
                primaryButtonText: t('general.save'),
                header: t('clients.newClient')
            },
            Component: () => <ClientsListForm existingClient={portalClients} />
        }
    })

    const { openPrepopulatedDialog: deleteAdmin } = useModalWithGraphQL({
        mutation: DELETE_USER,
        mutationName: 'deleteUser',
        isCustomMutationInput: true,
        initialValues: {
            clientId: ''
        },
        mapFormToMutationInput: () => ({ id: userId }),
        onMutationSuccess: () => {
            navigate(-1)
        },
        modalConfig: {
            updationConfig: {
                primaryButtonText: t('general.confirm'),
                header: t('admin.delete')
            },
            Component: () => (
                <ConfirmationDialog message={t('general.proceedMessage')} />
            )
        }
    })

    const { openConfirmationDialog: deleteClientPortal } = useModalWithGraphQL({
        mutation: DELETE_PORTAL_CLIENT,
        mutationName: 'deletePortalClient',
        isCustomMutationInput: true,
        initialValues: {
            clientId: ''
        },
        onMutationSuccess: () => {
            retrieve?.()
        },
        modalConfig: {
            confirmationConfig: {
                primaryButtonText: t('general.confirm'),
                header: t('admin.delete')
            },
            Component: () => (
                <ConfirmationDialog message={t('general.proceedMessage')} />
            )
        }
    })

    const addClientIntoPortal = ({ clientsData, client }) => {
        if (client) {
            const selectedClient = clientsData?.filter(
                (clients) => clients.id === client
            )

            const clientData = {
                id: selectedClient?.[0]?.id,
                title: selectedClient?.[0]?.title,
                unsaved: true,
                status: {
                    id: ClientPortalStatus.Pending,
                    title: t('general.pending')
                }
            }
            const clientPortalData = [...watch('portalClients'), clientData]
            setValue('portalClients', uniqueBy(clientPortalData, 'title'))
        }
    }

    /**
     * If an unsaved client is deleted it is removed from the table without
     * calling any external mutation.
     * However, if an already saved client is removed it is deleted by
     * calling a mutation to the backend.
     * @param clientId
     */
    const removeClientIntoPortal = (clientId) => {
        setValue(
            'portalClients',
            [...watch('portalClients')].filter(
                (client) => client.id !== clientId
            )
        )
    }

    const { pageHeader } = usePageHeader({
        headerText: t('editAdmin.portalIntoClients'),
        primaryButtonTitle: t('clients.newClient'),
        primaryButtonIcon: <AddIcon color={theme.palette.constantsWhite} />,
        primaryButtonAction: () => openNewClientDialog(_, addClientIntoPortal),
        permissions: [
            UserPermissionEnum.UserCreateModify,
            UserPermissionEnum.AdminCreateModify
        ]
    })

    const adminPortalColumns = [
        {
            headerName: t('general.status'),
            field: 'status',
            flex: 1,
            renderCell: (params) => (
                <Grid display={'flex'} alignItems={'center'}>
                    {params.row.status.id === ClientPortalStatus.Active ? (
                        <ActiveIcon />
                    ) : params.row.status.id === ClientPortalStatus.Rejected ? (
                        <ActiveIcon color={theme.palette.errorDangerMedium} />
                    ) : (
                        <ActiveIcon color={theme.palette.inactiveBrown} />
                    )}
                    <Typography variant='body2'>
                        {params?.row?.status?.title}
                    </Typography>
                </Grid>
            )
        },
        {
            headerName: t('general.title'),
            field: 'title',
            flex: 3,
            renderCell: (params) => (
                <Typography variant='body2'> {params.row?.title} </Typography>
            )
        }
    ]

    useEffect(() => {
        setIsUserActive?.(activeStatus)
    }, [activeStatus])

    return (
        <Grid
            container
            direction={'column'}
            xs={12}
            md={9}
            px={3}
            pb={3}
            gap={1}
            justifyContent={'space-between'}
        >
            <Grid
                item
                xs={12}
                md={12}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    ...(isMediumScreen?.()
                        ? { flexDirection: 'column', justifyContent: 'center' }
                        : {})
                }}
            >
                <Grid item xs={10} md={10}>
                    <UploadAvatar name='avatarUpload' />
                </Grid>
                <Grid item xs={2} md={2}>
                    <ControlledSwitch
                        name='active'
                        label={t('general.active')}
                    />
                </Grid>
            </Grid>

            <AdminDetailsForm
                isCurrentUser={isCurrentUser}
                isClientAdmin={isClientAdmin}
            />

            {!isClientAdmin &&
                isManageAdmin &&
                (can_access([UserPermissionEnum.AdminCreateModify]) ||
                    portalClients?.length > 0) && (
                    <Grid item xs={12} mt={3}>
                        {pageHeader}
                        <ControlledDataTable
                            columns={adminPortalColumns}
                            id={'portalClients'}
                            name={'portalClients'}
                            directoryLoading={false}
                            rowHeight={45}
                            customActions={[
                                {
                                    icon: (
                                        <DeleteIcon
                                            color={
                                                theme.palette.contentSecondary
                                            }
                                        />
                                    ),
                                    label: t('general.delete'),
                                    showInMenu: false,
                                    action: (params) => {
                                        removeClientIntoPortal(params?.id)
                                    },
                                    hide: (params) => !params?.row?.unsaved,
                                    permissions: [
                                        UserPermissionEnum.AdminCreateModify
                                    ]
                                },
                                {
                                    icon: (
                                        <DeleteIcon
                                            color={
                                                theme.palette.contentSecondary
                                            }
                                        />
                                    ),
                                    label: t('general.delete'),
                                    showInMenu: false,
                                    action: (params) => {
                                        deleteClientPortal({
                                            clientId: params?.id,
                                            userId
                                        })
                                    },
                                    hide: (params) => params?.row?.unsaved,
                                    permissions: [
                                        UserPermissionEnum.AdminCreateModify
                                    ]
                                }
                            ]}
                        />
                    </Grid>
                )}

            {!isCurrentUser && userId && (
                <AccessibleAction
                    permissions={[
                        UserPermissionEnum.AdminDelete,
                        UserPermissionEnum.UserDelete
                    ]}
                    component={
                        <Grid item xs={4} mt={isClientAdmin ? 6 : 3}>
                            <StyledButton
                                sx={{
                                    color: `${theme.palette.errorDangerMedium}`,
                                    border: `1px solid ${theme.palette.errorDangerMedium}`
                                }}
                                onClick={() => deleteAdmin()}
                            >
                                <DeleteIcon
                                    color={theme.palette.errorDangerMedium}
                                />
                                {t('admin.delete')}
                            </StyledButton>
                            <Typography
                                variant='body2'
                                sx={{
                                    color: theme.palette.contentSecondary
                                }}
                            >
                                {t('editAdmin.deleteUserMessage')}
                            </Typography>
                        </Grid>
                    }
                />
            )}
        </Grid>
    )
}
