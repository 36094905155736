import { Grid, Typography } from '@mui/material'
import styled from '@emotion/styled'

export const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '130%',
    letterSpacing: '-0.48px',
    color: theme.palette.contentPrimary
}))

export const StyledTypographyBio = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '140%'
}))
export const StyledAvatarContainer = styled(Grid)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}))
export const StyledLoaderContainer = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}))
