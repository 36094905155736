import { gql } from '@apollo/client'

export const GET_SHOW_LESS_VIDEO_OPTIONS = gql`
  query GetShowLessVideoOptions {
    getShowLessVideoOptions {
      available
      displayOrder
      id
      title
    }
  }
`
