import { ModifyAdminContainer } from '@containers'
import { UserPermissionEnum } from '@dts/constants'
import { AdminsPage } from '@pages'
import { getAccessRoles } from '@utils'

export const getGrsAdminsRoutes = (roles) => {
    const accessedRoles = getAccessRoles(roles)
    const { isDefaultTenantUser, isAdmin } = accessedRoles
    return [
        {
            path: '',
            component: AdminsPage,
            label: 'sideNavItems.admins',
            isHeaderLink: true,
            canUserAccess: isAdmin || isDefaultTenantUser,
            isSideNav: isAdmin || isDefaultTenantUser,
            permissions: [UserPermissionEnum.AdminView]
        },
        {
            path: 'new',
            component: ModifyAdminContainer,
            label: 'sideNavItems.videos',
            isHeaderLink: false,
            canUserAccess: isAdmin || isDefaultTenantUser
        },
        {
            path: ':id',
            component: ModifyAdminContainer,
            label: 'sideNavItems.videos',
            isHeaderLink: false,
            canUserAccess: isAdmin || isDefaultTenantUser
        }
    ]
}
