import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import { Panel, ProgressCard } from '@components'
import { ReadingIcon } from '@assets'
import { useCustomTheme } from '@hooks'
import { renderTrainingAccordionSummary } from '@containers/browse-videos/learner-browsing/learner-trainings/course-container'
import { CoursesContainerProps } from './types'

export const CoursesContainer: FC<CoursesContainerProps> = (props) => {
    const { selectedTraining } = props || {}
    const { t } = useTranslation()
    const { theme } = useCustomTheme()

    const getPanels = (panels: Array<any>) =>
        panels?.map((panel, index: number) => ({
            id: panel?.id,
            header: panel?.title,
            icon: (
                <ReadingIcon
                    width={20}
                    height={20}
                    color={theme.palette.contentSecondary}
                />
            ),
            renderElementAtTitle: () =>
                renderTrainingAccordionSummary(panel, theme, t),
            // To-do: Will be removed later on
            // batchActionsConfig: {
            //     actions: [
            //         {
            //             icon: ChartArrowDownIcon,
            //             sx: {
            //                 display: 'block'
            //             },
            //             iconId: 'collapsed-icon',
            //             permissions: ['Client.Cases.View']
            //         }
            //     ],
            //     clickable: true
            // },
            panelId: index + 1,
            body: <ProgressCard {...panel} />,
            bodySx: {
                padding: '16px',
                background: theme.palette.lightBlue
            }
        }))

    return (
        <Grid>
            {selectedTraining?.courses?.length ? (
                <Panel
                    panels={getPanels(selectedTraining?.courses)}
                    // To-do: Will be removed later on
                    // showSubtitleOnCollapsed={true}
                    // showBatchActions={false}
                    backgroundColor={theme.palette.backgroundPrimary}
                    onChange={(openedAccordionId: string) => false}
                    isExpanded={selectedTraining?.courses?.[0]?.id}
                />
            ) : (
                <Grid container justifyContent={'center'} mt={1}>
                    <Typography variant='h3'>
                        {t('general.notFound', {
                            label: t('general.courses').toLowerCase()
                        })}
                    </Typography>
                </Grid>
            )}
        </Grid>
    )
}
