import React from 'react'
import { Providers } from '@providers'
import { Routing } from '@routes'
import './App.css'

const App: React.FC = () => (
    <Providers>
        <Routing />
    </Providers>
)

export default App
