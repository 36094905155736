import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { HyperLink, LoginAsForm, SystemDown, VerifyEmail } from '@components'
import { useAuth, useCustomTheme, useGraphQLWrapper, useToasts } from '@hooks'
import { FormProvider } from '@providers'
import { GET_CONNECTION } from '@dts/graphql'
import { Box, Grid, Link, Typography } from '@mui/material'
import { LogoBackground } from '@assets'
import { loginAsSchema } from '@dts/client-utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { ResponseStatusEnum } from '@dts/constants'
import { getUserRoutes } from '@routes'
import { LoginFooter } from './login-footer'

export const LoginAs: FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const { showToast } = useToasts()

    const {
        userData,
        getToken,
        loginWithRedirect,
        userInfo: { roles, adminPortalOnlyAccess, firstLogin }
    } = useAuth()

    const methods = useForm({
        defaultValues: {
            email: ''
        },
        resolver: yupResolver(loginAsSchema)
    })
    const { error, isLoading, isAuthenticated, user } = useAuth0()

    const [connectionQueryInput, setConnectionQueryInput] = useState<object>()
    const { retrieve: connectionDataRetrieve } = useGraphQLWrapper({
        query: GET_CONNECTION,
        queryName: 'getConnection',
        onQuerySuccess: async (connectionData) => {
            if (
                !isAuthenticated &&
                !isLoading &&
                connectionData?.data?.connectionName
            ) {
                loginWithRedirect(connectionData?.data, connectionQueryInput)
            }
        },
        retrieveOnMount: false
    })

    // Find first matching route in roleactions

    const matchedRoute = getUserRoutes(roles, adminPortalOnlyAccess)?.find(
        (item) =>
            roles?.some((role) =>
                role?.roleActions?.some((action) =>
                    item?.permissions?.some(
                        (permission) =>
                            action?.id === permission &&
                            !item?.path?.includes('/')
                    )
                )
            )
    )

    const onSubmitLogin = async () => {
        const connectionQueryInput = {
            email: methods?.getValues?.('email')?.trim()
        }

        // Clearing session and local storage to avoid passing token to 'getConnection'
        sessionStorage.clear()
        localStorage.clear()

        setConnectionQueryInput(connectionQueryInput)
        const res = await connectionDataRetrieve({
            variables: { args: connectionQueryInput }
        })

        // Show error toast if user already exists
        const response = res?.data?.getConnection

        if (response?.message?.type === ResponseStatusEnum.Error) {
            showToast(
                'error',
                response?.message?.toast ?? t('toast.error.generic')
            )
        }
    }

    // Todo: Will be removed later on after testing
    // useEffect(() => {
    //     // if (!isAuthenticated && !isLoading && !error) {
    //     //     loginWithRedirect()
    //     // }
    //     getToken()
    // }, [])

    useEffect(() => {
        if (userData) {
            if (firstLogin) {
                navigate('/complete-profile')
            } else if (!adminPortalOnlyAccess) {
                if (matchedRoute?.path) {
                    navigate(`/${matchedRoute.path}`)
                } else navigate('/settings')
            } else if (adminPortalOnlyAccess) {
                navigate('/portal-client')
            }
        }
    }, [userData])

    if (error) {
        return error?.message.includes(
            'Please verify your email before logging in'
        ) ? (
            <VerifyEmail />
        ) : (
            <SystemDown errorMessage={error?.message} />
        )
    }

    if (!isLoading && !user && !isAuthenticated) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundImage: `url(${LogoBackground})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '100vh',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <FormProvider {...methods}>
                    <LoginAsForm
                        onSubmit={methods.handleSubmit(onSubmitLogin)}
                    />
                </FormProvider>

                <Grid
                    container
                    position={'absolute'}
                    bottom='2%'
                    fontWeight={750}
                    textAlign='center'
                    flexDirection={'column'}
                    alignItems={'center'}
                >
                    <Grid item>
                        <Typography variant='body2' marginTop={2}>
                            {t('login.membershipRequired')}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        gap={0.5}
                    >
                        <Grid item>
                            <Typography variant='body2'>
                                {t('login.forMoreInfo')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='body2'>
                                <HyperLink
                                    link={
                                        'https://grandriversolutions.com/hubbub/'
                                    }
                                    hasDefaultStyling
                                    sx={{
                                        fontWeight: 400
                                    }}
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item display='flex' flexDirection={'row'} gap={1}>
                        <Typography
                            variant='body2'
                            color={theme.palette.contentPrimary}
                        >
                            {t('login.copyright')}
                        </Typography>
                        <LoginFooter />
                    </Grid>
                </Grid>
            </Box>
        )
    }
}
