import React from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from 'react-hook-form'
import { Grid } from '@mui/material'
import { useCustomTheme } from '@hooks'
import { AccessibleAction } from '@hoc'
import { SearchableListButton } from './styles'

export type SearchableListRowProps = {
    sx?: object
    buttonConfig?: {
        title: string
        onClick?: VoidFunction
    }
    rowComponent?: JSX.Element
    permissions?: Array<string>
}

export const SearchableListRow: React.FC<SearchableListRowProps> = ({
    sx,
    buttonConfig,
    rowComponent,
    permissions
}) => {
    const { theme } = useCustomTheme()
    const { t } = useTranslation()
    const { ...formContext } = useFormContext()
    return (
        <Grid
            className='table'
            container
            justifyContent='space-between'
            alignItems='center'
            px={1}
            py={0.5}
            sx={sx}
            border={`1px solid ${theme.palette.borderGrey}`}
            md={12}
            xs={12}
        >
            <Grid
                item
                md={formContext?.formEditingState?.isReadOnly ? 12 : 10}
                xs={formContext?.formEditingState?.isReadOnly ? 12 : 10}
            >
                {rowComponent}
            </Grid>

            {buttonConfig && (
                <AccessibleAction
                    permissions={permissions}
                    component={
                        <Grid
                            item
                            md={2}
                            xs={2}
                            sx={{ display: 'flex', justifyContent: 'center' }}
                            pr={1}
                        >
                            <SearchableListButton
                                onClick={() => {
                                    buttonConfig?.onClick?.()
                                }}
                            >
                                {buttonConfig?.title}
                            </SearchableListButton>
                        </Grid>
                    }
                />
            )}
        </Grid>
    )
}
