import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import {
    Duplicate,
    ConfirmationDialog,
    StyledChip,
    TextWithTooltip
} from '@components'
import { formatDate } from '@dts/client-utils'
import { LearnerListStatus } from '@constants'
import {
    useCustomTheme,
    useDirectory,
    useGraphQLWrapper,
    useModalWithGraphQL
} from '@hooks'
import {
    AddIcon,
    ArchiveIcon,
    DeleteIcon,
    DuplicateIcon,
    PlusIcon,
    RestoreIcon
} from '@assets'
import { DUPLICATE_LEARNER_LIST } from '@dts/graphql'
import {
    DELETE_LEARNER_LIST,
    GET_LEARNER_LIST,
    GET_LEARNER_LIST_DIRECTORY,
    UPDATE_LEARNER_LIST
} from '@dts/graphql/lib'
import { startCase } from 'lodash'
import { useAddLearner } from '@containers'
import { LearnerListProps } from './types'
import { UserPermissionEnum } from '@dts/constants'

export const LearnerListContainer: FC<LearnerListProps> = ({ status }) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const navigate = useNavigate()
    const { onAddLearner } = useAddLearner()
    const refetchDirectory = () => {
        retrieve?.({
            variables: {
                options: { ...queryOptions }
            }
        })
    }

    const updateLearnerListConfig = {
        mutation: UPDATE_LEARNER_LIST,
        mutationName: 'updateLearnerList',
        onMutationSuccess: () => {
            refetchDirectory?.()
        },
        isCustomMutationInput: true
    }

    const deleteLearnerListConfig = {
        mutation: DELETE_LEARNER_LIST,
        mutationName: 'deleteLearnerList',
        onMutationSuccess: () => {
            refetchDirectory?.()
        },
        isCustomMutationInput: true
    }

    const duplicateLearnerListConfig = {
        mutation: DUPLICATE_LEARNER_LIST,
        mutationName: 'duplicateLearnerList',
        onMutationSuccess: () => {
            refetchDirectory?.()
        },
        isCustomMutationInput: true
    }

    const { openPrepopulatedDialog } = useModalWithGraphQL({
        ...duplicateLearnerListConfig,
        mapFormToMutationInput: ({ id, title }) => ({
            input: { id, title }
        }),
        query: GET_LEARNER_LIST,
        queryName: 'getLearnerList',
        mapQueryResultToForm: ({ id, title }) => ({
            id,
            title
        }),
        initialValues: {
            title: null
        },
        modalConfig: {
            maxWidth: 'sm',
            Component: Duplicate,
            updationConfig: {
                primaryButtonText: t('general.confirm'),
                header: [
                    t('createArticle.duplicate'),
                    t('general.learnerList')
                ].join(' ')
            },
            hasCancelButton: true
        }
    })

    const { save: deleteLearnerList } = useGraphQLWrapper({
        ...deleteLearnerListConfig
    })

    const updateArchive = (params, archive) => ({
        input: {
            id: params?.row?.id,
            isArchived: archive
        }
    })
    const { directory, retrieve, queryOptions } = useDirectory({
        directoryConfig: {
            id: 'manageLearnerList',
            query: GET_LEARNER_LIST_DIRECTORY,
            queryName: 'getLearnerListDirectory',
            queryVariables: {
                args: { tab: status }
            },
            config: {
                customActions: [
                    {
                        icon: <PlusIcon />,
                        showInMenu: false,
                        ariaLabel: t('learner.addLearner.label'),
                        action: (params) => {
                            onAddLearner({ learnerListId: params?.id })
                        },
                        permissions: [UserPermissionEnum.LearnersCreateModify]
                    },
                    {
                        icon: <RestoreIcon />,
                        label: t('general.restore'),
                        showInMenu: true,
                        action: (params) => {
                            openConfirmationDialog(updateArchive(params, false))
                        },
                        hide: (params) => params?.row?.isArchived !== true,
                        permissions: [UserPermissionEnum.LearnersCreateModify]
                    },

                    {
                        icon: (
                            <DuplicateIcon
                                color={theme.palette.contentSecondary}
                            />
                        ),
                        label: t('createArticle.duplicate'),
                        showInMenu: true,
                        action: (params) => {
                            openPrepopulatedDialog({
                                args: { id: params?.row?.id }
                            })
                        },
                        permissions: [UserPermissionEnum.LearnersCreateModify]
                    },
                    {
                        icon: (
                            <DeleteIcon
                                color={theme.palette.contentSecondary}
                            />
                        ),
                        label: t('general.delete'),
                        showInMenu: true,
                        action: (params) => {
                            deleteLearnerList({
                                id: params?.row?.id
                            })
                        },
                        hide: (params) => params?.row?.isArchived !== true,
                        permissions: [UserPermissionEnum.LearnersDelete]
                    },

                    {
                        icon: (
                            <ArchiveIcon
                                color={theme.palette.contentSecondary}
                            />
                        ),
                        label: t('createArticle.archive'),
                        showInMenu: true,
                        action: (params) => {
                            archiveConfirmationDialog(
                                updateArchive(params, true)
                            )
                        },
                        hide: (params) =>
                            params?.row?.isArchived || params?.row?.isDraft,
                        permissions: [UserPermissionEnum.LearnersCreateModify]
                    }
                ],
                columns: [
                    {
                        field: 'title',
                        headerName: t('general.list'),
                        minWidth: 100,
                        renderCell: (params) => (
                            <Grid p={2.5}>
                                <TextWithTooltip
                                    renderer={params?.value}
                                    isDirectoryLink
                                    onClick={() =>
                                        navigate(
                                            `/learner-list/${params?.row?.id}`,
                                            {
                                                state: {
                                                    learnerName:
                                                        params?.row?.title
                                                }
                                            }
                                        )
                                    }
                                />
                            </Grid>
                        )
                    },
                    {
                        field: 'learnersCount',
                        headerName: t('general.learners'),
                        minWidth: 80,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={params?.value}
                                tabIndex={params.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'trainings',
                        headerName: t('sideNavItems.trainings'),
                        minWidth: 300,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={
                                    <Grid item py={1}>
                                        {params?.value?.map((training) => (
                                            <StyledChip
                                                label={training.title}
                                                key={training?.id}
                                                sx={{ mb: 1, content: '""' }}
                                            />
                                        ))}
                                    </Grid>
                                }
                                toolTip={params?.value
                                    ?.map((training) => training.title)
                                    ?.join(', ')}
                                tabIndex={params.tabIndex}
                            />
                        )
                    },

                    {
                        field: 'updatedAt',
                        headerName: t('general.dateModified'),
                        minWidth: 100,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={formatDate(params?.value)}
                                tabIndex={params.tabIndex}
                            />
                        )
                    }
                ]
            },
            enableSearch: true,
            toolbarConfig: {
                header:
                    status === LearnerListStatus.Active
                        ? t('general.active')
                        : startCase(status),
                addNewButtonConfig: {
                    label: t('general.createNew'),
                    ariaLabel: t('general.learnerList'),
                    action: () => navigate('/learner-list/new'),
                    icon: AddIcon,
                    permissions: [UserPermissionEnum.LearnersCreateModify]
                }
            }
        }
    })
    /**
     * openConfirmationDialog is used to restore archive learner list
     */
    const { openConfirmationDialog } = useModalWithGraphQL({
        ...updateLearnerListConfig,
        mapFormToMutationInput: ({ id, isArchived }) => ({
            input: { id, isArchived }
        }),

        modalConfig: {
            maxWidth: 'sm',
            Component: () => (
                <ConfirmationDialog
                    message={t('course.restore.confirmation.message')}
                />
            ),
            confirmationConfig: {
                primaryButtonText: t('general.confirm'),
                header: t('general.confirmation')
            },
            hasCancelButton: true
        }
    })

    /**
     * archiveConfirmationDialog is used to  archive learner list
     */
    const { openConfirmationDialog: archiveConfirmationDialog } =
        useModalWithGraphQL({
            ...updateLearnerListConfig,
            mapFormToMutationInput: ({ id, isArchived }) => ({
                input: { id, isArchived }
            }),

            modalConfig: {
                maxWidth: 'sm',
                Component: () => (
                    <ConfirmationDialog
                        message={t('archive.confirmation.message')}
                    />
                ),
                confirmationConfig: {
                    primaryButtonText: t('general.confirm'),
                    header: t('general.confirmation')
                },
                hasCancelButton: true
            }
        })

    return directory
}
