import { FlexDirection } from '@constants'
import styled from '@emotion/styled'
import { Avatar, Grid, MenuItem } from '@mui/material'

export const StyledSubheaderGrid = styled(Grid)(
    ({ color, hasStaticCategories, theme }) => ({
        color: color || theme.palette.contentPrimary,
        fontSize: '16px',
        fontWeight: '600',
        // To-do: Will be removed after testing
        // marginTop: '8px',
        // marginBottom: '8px',
        lineHeight: !hasStaticCategories ? '24px' : '120%',
        padding: '16px 16px',
        borderBottom: !hasStaticCategories
            ? `0.5px solid ${theme.palette.borderSecondary}`
            : '0px'
    })
)

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: 26,
    height: 20,
    fontSize: 10,
    fontWeight: 'bold'
}))

export const ParentFilterLayoutContainer = styled(Grid)(
    ({
        flexDirection,
        padding,
        margin
    }: {
        flexDirection?: FlexDirection
        padding?: string
        margin?: string
    }) => ({
        overflow: 'hidden',
        height: '100%',
        maxHeight: '100%',
        flexDirection: flexDirection ? flexDirection : FlexDirection.Row,
        p: padding,
        m: margin
    })
)

export const FilterLayoutComponentContainer = styled(Grid)(() => ({
    overflowY: 'auto',
    height: '100%',
    overflowX: 'hidden'
}))

export const FilterLayoutOverlayContainer = styled(Grid)(() => ({
    pt: 0,
    justifyContent: 'center',
    position: 'relative',
    p: 0.2
}))

export const StyledFilterLayoutMenuItem = styled(MenuItem)(
    ({ theme, selected, renderComponent }) => ({
        width: '100%',
        background: selected
            ? `${theme.palette.backgroundInfoSubtle} !important`
            : theme.palette.backgroundPrimary,
        '&:hover': {
            backgroundColor: theme.palette.BrandPrimaryContainer
        },
        '&:focus-visible': {
            backgroundColor: `${theme.palette.BrandPrimaryContainer} !important`
        },
        padding: renderComponent ? '0px' : '16px 8px 16px 16px',
        boxShadow: selected
            ? `2px 0px 0px 0px ${theme?.palette?.backgroundBrandPrimary} inset`
            : 'none'
    })
)
