import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const ESCALATE_VIDEO = gql`
  ${MetaFragment('VideoResponseDto')}
  ${MessagesFragment('VideoResponseDto')}
  mutation escalateVideo($input: RejectVideoInputDto!) {
    escalateVideo(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        id
      }
    }
  }
`
