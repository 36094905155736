import React from 'react'
import {
    ClientDetailsContainer,
    ManageClientsContainer,
    ModifyClientContainer,
    ClientRolesContainer,
    ClientCoursesContainer,
    ManageClientRoles
} from '@containers'
import { ObjectBaseFields } from '@types'
import { getAccessRoles } from '@utils'
import { ClientsPage, RouteLayout } from '@pages'
import {
    BuildingsIcon,
    DocumentFoldIcon,
    ReadingIcon,
    TwoTogglesIcon,
    UserCheckIcon,
    UsersIcon
} from '@assets'
import { useCustomTheme } from '@hooks'
import { UserPermissionEnum } from '@dts/constants'
import { getCampusRoutes } from './campuses'
import { getClientAdminRoutes } from './client-admins'

export const getManageClientsRoutes = (roles: Array<ObjectBaseFields>) => {
    const accessedRoles = getAccessRoles(roles)
    const { isClientAdmin, isDefaultTenantUser, isAdmin } = accessedRoles
    const { theme } = useCustomTheme()

    return [
        {
            path: '',
            component: ClientDetailsContainer,
            canUserAccess: isAdmin || isDefaultTenantUser,
            icon: DocumentFoldIcon
        },
        {
            path: 'details',
            component: ClientDetailsContainer,
            label: 'general.details',
            canUserAccess: isAdmin || isDefaultTenantUser,
            isHeaderLink: true,
            icon: DocumentFoldIcon
        },
        {
            path: 'edit',
            component: ModifyClientContainer,
            isHeaderLink: false,
            isSideNav: false,
            canUserAccess: isAdmin || isDefaultTenantUser
        },
        {
            path: 'client-courses',
            component: ClientCoursesContainer,
            canUserAccess: isAdmin || isDefaultTenantUser,
            isHeaderLink: true,
            label: 'general.courses',
            icon: ReadingIcon
        },
        {
            path: 'campuses',
            component: RouteLayout,
            label: 'general.campuses',
            isHeaderLink: true,
            canUserAccess: isAdmin || isDefaultTenantUser,
            subRoutes: [...getCampusRoutes(roles)],
            icon: BuildingsIcon
        },
        {
            path: 'roles',
            component: RouteLayout,
            canUserAccess: isAdmin || isDefaultTenantUser,
            isHeaderLink: true,
            label: 'general.roles',
            subRoutes: [...getClientRolesRoutes(roles)],
            icon: UserCheckIcon
        },
        {
            path: 'users',
            component: RouteLayout,
            canUserAccess: isAdmin || isDefaultTenantUser,
            isHeaderLink: true,
            label: 'general.users',
            subRoutes: getClientAdminRoutes(roles),
            icon: UsersIcon
        },
        {
            path: 'preferences',
            component: RouteLayout,
            canUserAccess: isAdmin || isDefaultTenantUser,
            isHeaderLink: true,
            label: 'setting.preferences',
            icon: TwoTogglesIcon
        }
    ]
}

export const getClientRolesRoutes = (roles: Array<ObjectBaseFields>) => {
    const accessedRoles = getAccessRoles(roles)
    const { isAdmin, isClientAdmin, isDefaultTenantUser } = accessedRoles
    return [
        {
            path: '',
            component: ManageClientRoles,
            isHeaderLink: false,
            canUserAccess: isAdmin || isDefaultTenantUser || isClientAdmin
        },
        {
            path: ':roleId',
            component: ClientRolesContainer,
            isHeaderLink: false,
            canUserAccess: isAdmin || isDefaultTenantUser || isClientAdmin
        },
        {
            path: 'new',
            component: ClientRolesContainer,
            isHeaderLink: false,
            canUserAccess: isAdmin || isDefaultTenantUser || isClientAdmin
        }
    ]
}

export const getClientsRoutes = (roles: Array<ObjectBaseFields>) => {
    const accessedRoles = getAccessRoles(roles)

    const { isAdmin, isDefaultTenantUser } = accessedRoles

    return [
        {
            path: '',
            component: ClientsPage,
            isHeaderLink: false,
            canUserAccess: isAdmin || isDefaultTenantUser,
            permissions: [UserPermissionEnum.ClientView]
        },
        {
            path: ':id',
            component: ManageClientsContainer,
            isHeaderLink: false,
            canUserAccess: isAdmin || isDefaultTenantUser,
            subRoutes: [...getManageClientsRoutes(roles)]
        },
        // {
        //     path: 'modify/:id?',
        //     component: ModifyClientContainer,
        //     isHeaderLink: false,
        //     isSideNav: false,
        //     canUserAccess: isAdmin
        // },
        {
            path: 'new',
            component: ModifyClientContainer,
            isHeaderLink: false,
            isSideNav: false,
            canUserAccess: isAdmin || isDefaultTenantUser
        }
    ]
}
