import React from 'react'
import {
    LoginAs,
    ProtectedLayout,
    AccessDeniedContainer,
    SystemDownContainer
} from '@containers'

export const LoginAsPage: React.FC = () => <LoginAs />
export const ProtectedLayoutPage: React.FC = () => <ProtectedLayout />
export const AccessDeniedPage: React.FC = () => <AccessDeniedContainer />
export const SystemDownPage: React.FC = () => <SystemDownContainer />
