enum RecommendationTypeEnum {
    SuggestContent = 'SGC',
    SuppressContent = 'SPC',
}

enum RecommendationTypeGraphqlEnum {
    'SGC' = 'SGC',
    'SPC' = 'SPC',
}

export { RecommendationTypeEnum, RecommendationTypeGraphqlEnum }
