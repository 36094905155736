import React, { FC, useEffect, useState } from 'react'
import { Checkbox } from '@components'
import { groupBy } from 'lodash'
import { controlled } from '@hoc'
import { Grid } from '@mui/material'

export type LabelValueGroup = { label: string; value: string; group?: string }

export type MultiCheckboxesProps = {
    options: Array<LabelValueGroup>
    value: Array<string>
    onChange: (selectedOptions: Array<string>) => void
    disabled?: boolean
    width?: string
    hasBackground?: boolean
    styles?: {
        md?: number
    }
}

export const MultiCheckboxes: FC<MultiCheckboxesProps> = ({
    options = [],
    onChange,
    value,
    width,
    disabled,
    hasBackground = false,
    styles
}) => {
    const initialState: Record<string, boolean> = options?.reduce(
        (acc, current) => ({
            ...acc,
            [current.value]: false
        }),
        {}
    )

    const getCheckboxes = () => (
        <Grid container sx={{ width }}>
            {options.map(({ label, value }) => (
                <Grid
                    item
                    key={value}
                    gap={1}
                    pb={1}
                    md={styles?.md}
                    xs={styles?.xs}
                >
                    <Checkbox
                        key={value}
                        label={label}
                        name={value}
                        disabled={disabled}
                        value={selected?.[value]}
                        onChange={onCheckboxChange}
                        hasBackground={hasBackground}
                        isGrouped
                    />
                </Grid>
            ))}
        </Grid>
    )

    const [selected, setsSelected] =
        useState<Record<string, boolean>>(initialState)

    useEffect(() => {
        setsSelected(value)
    }, [value])

    const onCheckboxChange = (checked: boolean, name: string) => {
        const newState = { ...selected, [name]: checked }

        onChange(newState)
        setsSelected(newState)
    }

    return <>{getCheckboxes()}</>
}

export const ControlledMultipleCheckboxes = controlled(MultiCheckboxes)
