import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const ADD_VIDEO_CONVERSATION = gql`
  ${MetaFragment('VideoConversationResponseDto')}
  ${MessagesFragment('VideoConversationResponseDto')}
  mutation addVideoConversationMessage($input: VideoConversationInputDto!) {
    addVideoConversationMessage(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        message
        type
        userId
        userData {
          id
          firstName
          lastName
          role
        }
        createdAt
      }
    }
  }
`
