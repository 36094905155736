import { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { error } from 'console'
import {
    CAPTIONS_READY_SUBSCRIPTION,
    GET_VIDEOS_DROPDOWN_DATA,
    GET_VIDEO_CAPTION
} from '@dts/graphql'
import { useGraphQL } from '@dts/client-utils'
import { useLazyQuery } from '@apollo/client'
import { isEqual } from 'lodash'

export const useVideoCaptions = () => {
    const { setValue, control } = useFormContext()
    const videoId = useWatch({ control, name: 'videoId' })
    const captionText = useWatch({ control, name: 'captionText' })
    const captionFileSrc = useWatch({ control, name: 'captionFileSrc' })

    const [captionVideoId, setCaptionVideoId] = useState('')
    const [captionVideoUrl, setCaptionVideoUrl] = useState('')

    const [
        getVideoCaption,
        { data: getVideoCaptionData, loading: getVideoCaptionLoading }
    ] = useLazyQuery(GET_VIDEO_CAPTION, {
        variables: {
            fetchPolicy: 'network-only'
        }
    })

    const { data: getVideoDropDownsData } = useGraphQL({
        query: GET_VIDEOS_DROPDOWN_DATA,
        queryName: 'getVideoDropDownsData',
        subscription: CAPTIONS_READY_SUBSCRIPTION,
        subscriptionVariables: {},
        updateQuery: (prev, subscriptionData) => {
            setCaptionVideoId(subscriptionData?.data.captionsReady?.videoId)
            setCaptionVideoUrl(subscriptionData?.data.captionsReady?.fileName)
            return subscriptionData
        }
    })

    useEffect(() => {
        /**
         * This code is used getVideoCaption
         */
        if (isEqual(captionVideoId, videoId) && captionVideoUrl) {
            getVideoCaption({
                variables: {
                    videoId: captionVideoId,
                    url: captionVideoUrl
                }
            })
        }
    }, [captionVideoId, captionVideoUrl])

    const fetchVTTFile = async (captionFileSrc) => {
        try {
            const response = await fetch(captionFileSrc)
            if (!response.ok) {
                throw new Error('Failed to fetch VTT file')
            }
            const vttData = await response.text()

            setValue('captionText', vttData)
            setValue('actualCaptionText', vttData)
        } catch (error) {
            // error fetching captions
        }
    }

    useEffect(() => {
        /**
         * This code is used getVideoCaptions
         */

        if (
            getVideoCaptionData?.getVideoCaption?.data?.captionFileSrc &&
            !getVideoCaptionLoading
        ) {
            setValue(
                'captionFileSrc',
                getVideoCaptionData?.getVideoCaption?.data?.captionFileSrc
            )
        }
    }, [getVideoCaptionData, getVideoCaptionLoading])

    useEffect(() => {
        if (captionFileSrc) {
            fetchVTTFile(captionFileSrc)
        }
    }, [captionFileSrc])

    return {
        getVideoDropDownsData,
        fetchVTTFile,
        getVideoCaptionLoading
    }
}
