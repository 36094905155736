import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, TrainingFields } from '../../fragments'

export const GET_MY_TRAININGS = gql`
  ${TrainingFields('TrainingDto')}
  ${MessagesFragment('TrainingsResponseDto')}
  ${MetaFragment('TrainingsResponseDto')}
  query getMyTrainings {
    getMyTrainings {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...TrainingFields
      }
    }
  }
`
