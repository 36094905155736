import { gql } from '@apollo/client'

export const CourseFields = (on: string) => gql`
    fragment CourseFields on ${on} {
        id
        title
        description
        tags
        isDraft
        isArchived
        verticalsCount
        createdAt
        updatedAt
        courseId
        tenantCourseId
        creator{
            id
            firstName
            lastName
            role
            avatar
            avatarSrc
        }
        modifier{
            id
            firstName
            lastName
            role
            avatar
            avatarSrc
        }
        creatorId
        modifierId
        courseTopics{
            topic{
            id
            title
            description
            isArchived
            createdAt
            updatedAt
            videosCount
            coursesCount
            }
            topicId
            duration
            isAssigned
        }  
        topicsCount
        vertical{
            id
            title
        }
        progress
        surveys{
            id
            title
        }
    }
`
