import styled from '@emotion/styled'
import { Button, Grid } from '@mui/material'

export const StyledFlexContainer = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '10px'
}))

export const StyledAvatarContainer = styled(Grid)(() => ({
    padding: '3, 3',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
}))

export const StyledButton = styled(Button)(({ theme }) => ({
    height: '32px',
    marginBottom: '10px',
    marginTop: '16px'
}))
