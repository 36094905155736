import React from 'react'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Link } from '@components'
import { useCustomTheme, usePageHeader, useRoutes } from '@hooks'
import { Grid, ListSubheader } from '@mui/material'
import { CustomRoute } from '@routes'
import { groupRoutes, isMediumScreen } from '@utils'
import { MenuContainer } from './styles'

export const SettingsMenu = () => {
    const { settingsRoutes } = useRoutes()
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const groupedRoutes = groupRoutes(settingsRoutes as Array<CustomRoute>)

    return (
        <>
            <MenuContainer
                sx={{
                    borderRight: `2px solid ${theme.palette.backgroundSecondary}`
                }}
            >
                {Object.keys(groupedRoutes)?.map((group, index) => (
                    <>
                        <ListSubheader
                            key={index}
                            sx={{
                                color: theme.palette.contentPrimary,
                                fontSize: '16px',
                                fontWeight: '600',
                                background: 'transparent'
                            }}
                        >
                            {t(group)}
                        </ListSubheader>
                        {groupedRoutes?.[group]?.map(
                            (route: CustomRoute, index: number) =>
                                route?.isHeaderLink ? (
                                    <Link
                                        list
                                        menu
                                        tooltipPlacement='right-end'
                                        route={route}
                                        key={index}
                                        hasArrowIcon
                                        iconContainerSx={{
                                            minWidth: '40px'
                                        }}
                                        iconColor={theme.palette.contentPrimary}
                                        selectedIconColor={
                                            theme.palette.backgroundBrandPrimary
                                        }
                                    />
                                ) : null
                        )}
                    </>
                ))}
            </MenuContainer>
        </>
    )
}

export const SettingsPage = () => {
    const { t } = useTranslation()
    const { pageHeader } = usePageHeader({
        headerText: t('general.settings')
    })

    return (
        <>
            {!isMediumScreen?.() && pageHeader}
            <Grid container>
                {!isMediumScreen?.() ? (
                    <>
                        <Grid item xs={12} md={2.5}>
                            <SettingsMenu />
                        </Grid>
                    </>
                ) : null}
                <Grid item xs={12} md={9}>
                    <Outlet />
                </Grid>
            </Grid>
        </>
    )
}
