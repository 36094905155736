import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Grid } from '@mui/material'
import { formatDate } from '@dts/client-utils'
import { useCustomTheme, useDirectory } from '@hooks'
import { GET_CLIENTS, GET_CAMPUSES } from '@dts/graphql'
import { AddIcon, DocumentIcon, BuildingsIcon, ActiveIcon } from '@assets'
import { StyledDirectoryLink } from '@containers'
import { TextWithTooltip } from '@components'
import { UserPermissionEnum } from '@dts/constants'

export const ManageClients = () => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const navigate = useNavigate()

    const campusColumns = [
        {
            headerName: t('general.title'),
            field: 'titleDetailed',
            minWidth: 400,
            searchable: true,
            renderCell: (params) => (
                <TextWithTooltip
                    renderer={params?.row?.title}
                    tabIndex={params.tabIndex}
                    isDirectoryLink
                    onClick={() =>
                        navigate(`${params?.row?.clientPrimaryId}/campuses`, {
                            state: {
                                campusId: params?.id
                            }
                        })
                    }
                />
            )
        },
        {
            headerName: t('general.courses'),
            field: 'coursesDetailed',
            minWidth: 200,
            renderCell: ({ tabIndex }) => (
                <TextWithTooltip renderer={'0'} tabIndex={tabIndex} />
            )
        },
        {
            headerName: t('general.type'),
            field: 'typeDetailed',
            minWidth: 200,
            searchable: true,
            renderCell: ({ tabIndex }) => (
                <TextWithTooltip renderer={'--'} tabIndex={tabIndex} />
            )
        },
        {
            headerName: t('general.phone'),
            field: 'phoneDetailed',
            minWidth: 200,
            searchable: true,
            renderCell: (params) => (
                <TextWithTooltip
                    renderer={params?.row?.phone}
                    tabIndex={params.tabIndex}
                />
            )
        },
        {
            headerName: t('clients.contractExpiry'),
            field: 'contractExpiryDetailed',
            minWidth: 200,
            renderCell: ({ tabIndex }) => (
                <TextWithTooltip renderer={'--'} tabIndex={tabIndex} />
            )
        }
    ]

    const { directory } = useDirectory({
        directoryConfig: {
            id: 'clientDirectory',
            query: GET_CLIENTS,
            queryName: 'getClients',
            config: {
                customActions: [
                    {
                        icon: <DocumentIcon />,
                        label: `${t('general.viewDetails')}`,
                        ariaLabel: `${t('general.viewDetails')}`,
                        showInMenu: true,
                        action: (params) =>
                            navigate(`${params?.row?.id}/details`)
                    }
                ],
                columns: [
                    {
                        field: 'title',
                        headerName: t('general.title'),
                        minWidth: 350,
                        renderCell: (params) => (
                            <Grid
                                sx={{
                                    display: 'flex',
                                    gap: 1,
                                    alignItems: 'center'
                                }}
                                pt={0.2}
                                tabIndex={params.tabIndex}
                            >
                                <ActiveIcon
                                    color={
                                        params?.row?.active
                                            ? theme.palette.activeGreen
                                            : theme.palette.errorDangerMedium
                                    }
                                />
                                <TextWithTooltip
                                    renderer={params?.value}
                                    toolTip={params?.value}
                                    onClick={() =>
                                        navigate(`${params?.row?.id}/details`)
                                    }
                                    isDirectoryLink
                                    ellipsisLength={25}
                                />
                            </Grid>
                        )
                    },
                    {
                        field: 'coursesCount',
                        headerName: t('general.courses'),
                        minWidth: 80,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={params?.value} // This filed is set to zero till client onboarding
                                tabIndex={params.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'type',
                        headerName: t('general.type'),
                        minWidth: 80,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={params?.row?.clientType?.title}
                                tabIndex={params.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'phone',
                        headerName: t('general.phone'),
                        minWidth: 80,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={params?.row?.phone}
                                tabIndex={params.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'contractExpiry',
                        headerName: t('clients.contractExpiry'),
                        minWidth: 100,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={formatDate(
                                    params.row?.contractExpiryDate
                                )}
                                tabIndex={params.tabIndex}
                            />
                        )
                    }
                ],
                detailCompConfig: {
                    id: 'campuses',
                    columns: campusColumns,
                    width: '100%',
                    name: 'campuses',
                    query: GET_CAMPUSES,
                    queryName: 'getCampuses',
                    toolbarConfig: {
                        headerIcon: (
                            <BuildingsIcon
                                color={theme.palette.darkestBlue}
                                fill={theme.palette.lightBlue}
                            />
                        ),
                        buttonTitle: t('clients.newCampus'),
                        buttonIcon: () => (
                            <AddIcon color={theme.palette.contentLink} />
                        ),
                        buttonAction: (row) => {
                            navigate(`${row?.id}/campuses/new`)
                        },
                        permissions: [UserPermissionEnum.ClientCreateModify]
                    }
                }
            },
            toolbarConfig: {
                header: t('general.clients'),
                addNewButtonConfig: {
                    label: t('clients.newClient'),
                    action: () => navigate(`new`),
                    icon: () => (
                        <AddIcon color={theme.palette.contentPrimaryInverse} />
                    ),
                    permissions: [UserPermissionEnum.ClientCreateModify]
                }
            },
            enableSearch: true,
            rowHeight: 50
        }
    })

    return directory
}
