import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useController } from 'react-hook-form'
import { Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import { Input } from '@components/Input'
import { capitalize } from 'lodash'
import { formatDuration } from '@utils'
import { CloseIcon, SearchIcon, XCircleIcon } from '@assets'
import { FieldErrors } from '@components'
import { useCustomTheme } from '@hooks'
import { SearchableListRow } from './list-row'
import { SectionContainer } from './styles'
import { SearchableListSectionType, SearchableListType } from './types'

const SearchableListSection: React.FC<SearchableListSectionType> = ({
    sectionHeader,
    isAdded,
    topicsData,
    updateData,
    searchText,
    RowComponent,
    showHeader,
    listType,
    showNotAdded,
    editComponentConfig,
    preventNestedListUpdate,
    nestedAssignmentsOnly,
    permissions
}) => {
    const { theme } = useCustomTheme()
    const { t } = useTranslation()

    const {
        getValues,
        formState: { errors },
        setValue
    } = useFormContext()

    let totalDuration = 0
    let totalAssigned = 0

    const hasRecords = !(
        !topicsData?.some((topic) => topic.isAdded === true) && isAdded
    )

    return (
        <>
            <Grid item xs={12} md={12}>
                {(isAdded ||
                    (!isAdded && searchText) ||
                    (showNotAdded &&
                        topicsData?.filter((item) => !item.isAdded).length >
                            0)) && (
                    <Typography
                        variant='subtitle2'
                        color={
                            isAdded
                                ? theme.palette.successMain
                                : theme.palette.errorDangerMedium
                        }
                    >
                        {sectionHeader}
                    </Typography>
                )}
            </Grid>
            {isAdded && showHeader && (
                <SearchableListRow
                    sx={{
                        borderTopLeftRadius: '5px',
                        borderLeft: `2px solid ${theme.palette.successMain}`,
                        borderTopRightRadius: '5px'
                    }}
                    rowComponent={
                        <Grid
                            item
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Grid
                                item
                                md={8.2}
                                pl={3}
                                display={'flex'}
                                alignItems={'center'}
                            >
                                <Typography variant='subtitle2'>
                                    {t('general.topics')}
                                </Typography>
                            </Grid>

                            {!editComponentConfig?.nestedAssignmentsOnly && (
                                <Grid item md={3.8} xs={3.8} pl={2}>
                                    <Typography variant='subtitle2'>
                                        {t('general.completionTime')}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    }
                    permissions={permissions}
                />
            )}
            <SectionContainer isAdded={isAdded}>
                {hasRecords ? (
                    topicsData?.map((item, index) => {
                        if (item?.isAdded === isAdded) {
                            const showRow = searchText
                                ? item?.title
                                      ?.toLowerCase()
                                      .includes(searchText?.toLowerCase())
                                : showNotAdded
                                ? showNotAdded
                                : item?.isAdded
                            const typeError =
                                errors?.[`topics[${index}]`?.split('[')?.[0]]?.[
                                    index
                                ]?.duration
                            return showRow ? (
                                <SearchableListRow
                                    permissions={permissions}
                                    buttonConfig={
                                        !editComponentConfig?.hideListUpdateButtons &&
                                        !editComponentConfig?.nestedAssignmentsOnly
                                            ? {
                                                  title: isAdded
                                                      ? capitalize(
                                                            t('general.remove')
                                                        )
                                                      : capitalize(
                                                            t('general.add')
                                                        ),
                                                  onClick: () => {
                                                      item.isAdded = !isAdded

                                                      item.isAssigned = isAdded
                                                          ? item.isAssigned
                                                          : true
                                                      item.duration = null
                                                      updateData(index, item)
                                                  }
                                              }
                                            : undefined
                                    }
                                    rowComponent={
                                        RowComponent ? (
                                            <RowComponent
                                                item={item}
                                                index={index}
                                                updateData={updateData}
                                                isAdded={isAdded}
                                                typeError={typeError}
                                                editComponentConfig={
                                                    editComponentConfig
                                                }
                                                preventNestedListUpdate={
                                                    preventNestedListUpdate
                                                }
                                                nestedAssignmentsOnly={
                                                    nestedAssignmentsOnly
                                                }
                                                permissions={permissions}
                                            />
                                        ) : (
                                            <></>
                                        )
                                    }
                                />
                            ) : null
                        }
                    })
                ) : (
                    <Grid pl={3} sx={{ height: '30px' }} alignItems={'center'}>
                        <Typography variant='body2' sx={{ marginTop: '10px' }}>
                            {t('searchableList.noRecordAdded', { listType })}
                        </Typography>
                    </Grid>
                )}

                {getValues('topics')?.map((topic) => {
                    if (topic.isAssigned && topic.isAdded) {
                        totalDuration += topic.duration
                        totalAssigned += 1
                    }
                })}
                {totalDuration
                    ? setValue('totalDuration', formatDuration(totalDuration))
                    : setValue('totalDuration', null)}
            </SectionContainer>
            {isAdded && !searchText && showHeader && (
                <SearchableListRow
                    sx={{
                        background: theme.palette.backgroundSecondary,
                        borderBottomLeftRadius: '5px',
                        borderLeft: `2px solid ${theme.palette.successMain}`,
                        borderBottomRightRadius: '5px'
                    }}
                    rowComponent={
                        <Grid
                            item
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Grid
                                item
                                md={8.2}
                                xs={8.2}
                                pl={3}
                                display={'flex'}
                                alignItems={'center'}
                            >
                                <Typography variant='subtitle2'>
                                    {`${totalAssigned} Assigned`}
                                </Typography>
                            </Grid>

                            {!editComponentConfig?.nestedAssignmentsOnly &&
                                totalDuration !== 0 && (
                                    <Grid item md={3.8} xs={3.8} pl={2}>
                                        <Typography variant='subtitle2'>
                                            {formatDuration(totalDuration)}
                                        </Typography>
                                    </Grid>
                                )}
                        </Grid>
                    }
                    permissions={permissions}
                />
            )}
        </>
    )
}

export const SearchableList: React.FC<SearchableListType> = ({
    name,
    topicsData,
    updateData,
    listType,
    addedIn,
    rowComponent,
    showHeader = false,
    showNotAdded = false,
    editComponentConfig,
    preventNestedListUpdate,
    nestedAssignmentsOnly,
    permissions = []
}) => {
    const { t } = useTranslation()
    const {
        fieldState: { error }
    } = name
        ? useController({
              name
          })
        : { fieldState: { error: undefined } }

    const [searchText, setSearchText] = useState<string>('')

    return (
        <Grid container md={10}>
            <Grid item xs={12} md={12}>
                {!editComponentConfig?.hideSearchBar &&
                    !editComponentConfig?.nestedAssignmentsOnly && (
                        <Input
                            placeholder={[t('general.search'), listType].join(
                                ' '
                            )}
                            value={searchText}
                            ariaLabel={`${t('general.search')} ${listType}`}
                            onChange={(e) => {
                                setSearchText(e)
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: searchText && (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label={t('general.cancel')}
                                            onClick={() => {
                                                setSearchText('')
                                            }}
                                        >
                                            <CloseIcon
                                                width={'22'}
                                                height={'22'}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                {error && <FieldErrors error={error} name={name} />}
            </Grid>
            <Grid item xs={12} md={12} mt={2}>
                <SearchableListSection
                    sectionHeader={`${t('courses.inSectionHeader')} ${addedIn}`}
                    isAdded={true}
                    showNotAdded={showNotAdded}
                    topicsData={topicsData}
                    updateData={updateData}
                    searchText={searchText}
                    RowComponent={rowComponent}
                    showHeader={showHeader}
                    listType={listType}
                    editComponentConfig={editComponentConfig}
                    preventNestedListUpdate={preventNestedListUpdate}
                    nestedAssignmentsOnly={nestedAssignmentsOnly}
                    permissions={permissions}
                />
            </Grid>

            {!editComponentConfig?.hideNotAdded && (
                <Grid item xs={12} md={12} mt={2}>
                    <SearchableListSection
                        sectionHeader={`${t(
                            'courses.notInSectionHeader'
                        )} ${addedIn}`}
                        isAdded={false}
                        showNotAdded={showNotAdded}
                        topicsData={topicsData}
                        updateData={updateData}
                        searchText={searchText}
                        RowComponent={rowComponent}
                        showHeader={showHeader}
                        listType={listType}
                        permissions={permissions}
                    />
                </Grid>
            )}
        </Grid>
    )
}
