import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { GET_FEATURES } from '@dts/graphql/lib'
import { useGraphQLWrapper } from '@hooks'
import { mapDropdownOptions } from '@dts/client-utils'
import { Divider, Grid, Typography } from '@mui/material'
import {
    ControlledCheckBox,
    ControlledParentMultipleCheckboxes,
    ControlledTextInput,
    Panel
} from '@components'
import { HelperTextPosition, TypographyVariants } from '@constants'

export const ClientRolesForm = () => {
    const { t } = useTranslation()
    const { id: clientId } = useParams()
    const { control, setValue } = useFormContext()
    const permissions = useWatch({ name: 'permissions', control })
    const { queryLoading, currentData: featuresData } = useGraphQLWrapper({
        query: GET_FEATURES,
        queryName: 'getFeatures',
        queryVariables: {
            clientId
        },
        retrieveOnMount: true
    })
    const mergedPermissionsData = useWatch({
        name: 'mergedPermissions',
        control
    })

    useEffect(() => {
        if (
            !queryLoading &&
            featuresData &&
            (!mergedPermissionsData || mergedPermissionsData?.length)
        ) {
            const mergedPermissions: Array<object> = []

            featuresData?.forEach((feature) => {
                // Add all features to mergedPermissions with actions set to not selected
                mergedPermissions.push({
                    ...feature,
                    isSelected: false,
                    actions: feature.actions.map((action) => {
                        const sortedDependsOn = [
                            ...(action?.dependsOn || [])
                        ].sort((a, b) => {
                            // Find the index of 'a' and 'b' in the feature.actions array based on their 'id'
                            const indexA = feature?.actions?.findIndex(
                                (act) => act.id === a.id
                            )
                            const indexB = feature.actions.findIndex(
                                (act) => act.id === b.id
                            )

                            // Compare the index positions for sorting
                            return indexA - indexB
                        })

                        return {
                            ...action,
                            dependsOn: sortedDependsOn, // Update dependsOn with the sorted array
                            isSelected: false // Initialize all actions as not selected
                        }
                    })
                })
            })

            // Now loop over permissions to update the isSelected status in mergedPermissions
            permissions?.forEach((permission) => {
                const featureInMerged = mergedPermissions.find(
                    (feature) => feature.id === permission.id
                )

                if (featureInMerged) {
                    // Update the feature's actions with selected status from permissions
                    featureInMerged.actions = featureInMerged?.actions?.map(
                        (action) => {
                            const matchingAction = permission?.actions?.find(
                                (permAction) => permAction.id === action.id
                            )
                            return {
                                ...action,
                                isSelected: matchingAction
                                    ? matchingAction.isSelected
                                    : false
                            }
                        }
                    )

                    // Check if all actions are selected to mark the feature as selected
                    featureInMerged.isSelected =
                        featureInMerged?.actions?.length > 0 &&
                        featureInMerged?.actions?.every(
                            (action) => action.isSelected
                        )
                }
            })

            setValue('mergedPermissions', mergedPermissions)
        }
    }, [featuresData, permissions, queryLoading])

    const handleParentChange = (index, isSelected) => {
        // Update parent selection
        setValue(`mergedPermissions[${index}].isSelected`, isSelected)

        // Update child actions selection based on parent state
        mergedPermissionsData[index]?.actions?.forEach((_, actionIndex) => {
            setValue(
                `mergedPermissions[${index}].actions[${actionIndex}].isSelected`,
                isSelected
            )

            if (isSelected) {
                mergedPermissionsData[index].actions[
                    actionIndex
                ]?.dependsOn?.map((dependsOnItem) => {
                    if (dependsOnItem?.relationshipTypeId === 'DEP') {
                        const dependsOnIndex = mergedPermissionsData[
                            index
                        ].actions?.findIndex(
                            (action) => action?.id === dependsOnItem?.id
                        )
                        if (dependsOnIndex !== -1) {
                            setValue(
                                `mergedPermissions[${index}].actions[${dependsOnIndex}].isSelected`,
                                true
                            )
                            const exclusiveDependencies =
                                mergedPermissionsData?.[index]?.actions?.[
                                    dependsOnIndex
                                ]?.dependsOn?.filter(
                                    (dependsOnItem) =>
                                        dependsOnItem?.relationshipTypeId ===
                                        'EXC'
                                )

                            // Check if there are any exclusive dependencies
                            if (exclusiveDependencies?.length > 0) {
                                exclusiveDependencies?.map(
                                    (selectedExclusive) => {
                                        // Find the index of the selected exclusive action
                                        const selectedExclusiveIndex =
                                            mergedPermissionsData?.[
                                                index
                                            ]?.actions?.findIndex(
                                                (action) =>
                                                    action?.id ===
                                                    selectedExclusive?.id
                                            )

                                        // Set only the selected exclusive action as selected
                                        if (selectedExclusiveIndex !== -1) {
                                            setValue(
                                                `mergedPermissions[${index}].actions[${selectedExclusiveIndex}].isSelected`,
                                                false
                                            )
                                        }
                                    }
                                )
                            }
                        }
                    }
                    if (dependsOnItem?.relationshipTypeId === 'EXC') {
                        const dependsOnIndex = mergedPermissionsData[
                            index
                        ].actions?.findIndex(
                            (action) => action?.id === dependsOnItem?.id
                        )
                        if (dependsOnIndex !== -1) {
                            setValue(
                                `mergedPermissions[${index}].actions[${dependsOnIndex}].isSelected`,
                                false
                            )
                        }
                    }
                })
            }
        })
    }

    const handleActionChange = (index, actionIndex, isSelected) => {
        setValue(
            `mergedPermissions[${index}].actions[${actionIndex}].isSelected`,
            isSelected
        )

        if (isSelected) {
            mergedPermissionsData[index].actions[actionIndex]?.dependsOn?.map(
                (dependsOnItem) => {
                    if (dependsOnItem?.relationshipTypeId === 'DEP') {
                        const dependsOnIndex = mergedPermissionsData[
                            index
                        ].actions?.findIndex(
                            (action) => action?.id === dependsOnItem?.id
                        )
                        if (dependsOnIndex !== -1) {
                            setValue(
                                `mergedPermissions[${index}].actions[${dependsOnIndex}].isSelected`,
                                true
                            )
                            const exclusiveDependencies =
                                mergedPermissionsData?.[index]?.actions?.[
                                    dependsOnIndex
                                ]?.dependsOn?.filter(
                                    (dependsOnItem) =>
                                        dependsOnItem?.relationshipTypeId ===
                                        'EXC'
                                )

                            // Check if there are any exclusive dependencies
                            if (exclusiveDependencies?.length > 0) {
                                exclusiveDependencies?.map(
                                    (selectedExclusive) => {
                                        // Find the index of the selected exclusive action
                                        const selectedExclusiveIndex =
                                            mergedPermissionsData?.[
                                                index
                                            ]?.actions?.findIndex(
                                                (action) =>
                                                    action?.id ===
                                                    selectedExclusive?.id
                                            )

                                        // Set only the selected exclusive action as selected
                                        if (selectedExclusiveIndex !== -1) {
                                            setValue(
                                                `mergedPermissions[${index}].actions[${selectedExclusiveIndex}].isSelected`,
                                                false
                                            )
                                        }
                                    }
                                )
                            }
                        }
                    }
                    if (dependsOnItem?.relationshipTypeId === 'EXC') {
                        const dependsOnIndex = mergedPermissionsData[
                            index
                        ].actions?.findIndex(
                            (action) => action?.id === dependsOnItem?.id
                        )
                        if (dependsOnIndex !== -1) {
                            setValue(
                                `mergedPermissions[${index}].actions[${dependsOnIndex}].isSelected`,
                                false
                            )
                        }
                    }
                }
            )
        } else {
            mergedPermissionsData[index].actions?.forEach(
                (action, actionIdx) => {
                    action?.dependsOn?.forEach((dependsOnItem) => {
                        if (
                            dependsOnItem?.id ===
                            mergedPermissionsData[index].actions[actionIndex]
                                ?.id
                        ) {
                            setValue(
                                `mergedPermissions[${index}].actions[${actionIdx}].isSelected`,
                                false
                            )
                        }
                    })
                }
            )
        }

        // value set through setValue cannot be accessed in immediately next line so setting value for local use as well
        mergedPermissionsData[index].actions[actionIndex].isSelected =
            isSelected
        if (
            mergedPermissionsData[index]?.actions?.every(
                (action) => action.isSelected
            ) &&
            !mergedPermissionsData[index]?.isSelected
        ) {
            setValue(`mergedPermissions[${index}].isSelected`, true)
        }
        if (
            mergedPermissionsData[index]?.actions?.every(
                (action) => !action.isSelected
            ) &&
            mergedPermissionsData[index]?.isSelected
        ) {
            setValue(`mergedPermissions[${index}].isSelected`, false)
        }
    }

    const getPanels = (panels: Array<object>) =>
        panels?.map((panel, index: number) => ({
            id: panel?.id,
            renderElementAtTitle: () => (
                <Grid container alignItems={'center'}>
                    <Grid
                        item
                        md={0.5}
                        xs={1.5}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <ControlledCheckBox
                            id={`mergedPermissions[${index}].isSelected`}
                            name={`mergedPermissions[${index}].isSelected`}
                            indeterminate={
                                !mergedPermissionsData?.[index]?.actions?.every(
                                    (action) => action.isSelected
                                ) &&
                                mergedPermissionsData?.[index]?.actions?.some(
                                    (action) => action.isSelected
                                )
                            }
                            onClick={(e) => handleParentChange(index, e)}
                        />
                    </Grid>
                    <Grid item md={11} xs={10}>
                        <Typography variant={TypographyVariants.h4}>
                            {panel?.title}
                        </Typography>
                    </Grid>
                </Grid>
            ),
            bodySx: { paddingLeft: 5, paddingRight: 2 },
            body: (
                <Grid container flexDirection={'column'} gap={1}>
                    {panel?.actions?.map((action, actionIndex) => (
                        <Grid container alignItems={'center'}>
                            <Grid item md={0.5} xs={1.5}>
                                <ControlledCheckBox
                                    id={`mergedPermissions[${index}].actions[${actionIndex}]`}
                                    name={`mergedPermissions[${index}].actions[${actionIndex}].isSelected`}
                                    onClick={(e) =>
                                        handleActionChange(
                                            index,
                                            actionIndex,
                                            e
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item md={11} xs={10}>
                                <Typography variant={TypographyVariants.body2}>
                                    {action?.title}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            )
        }))

    return (
        <Grid container display={'flex'} flexDirection={'column'}>
            <Grid item xs={12} md={7} mb={7}>
                <ControlledTextInput
                    id='title'
                    name='title'
                    label={t('general.role')}
                    placeholder={t('general.role')}
                    fullWidth
                />
                <ControlledTextInput
                    id='description'
                    name='description'
                    label={t('general.description')}
                    placeholder={t('general.description')}
                    fullWidth
                    multiline
                    rows={3}
                    helperTextConfig={{
                        wordLength: 200,
                        helperTextPosition: HelperTextPosition.Right
                    }}
                />
            </Grid>
            <Grid item>
                <Typography variant='h2' mb={3}>
                    {t('roles.managePermissions')}
                </Typography>
            </Grid>
            {/* to-do: will be removed after testing */}
            {/* {featuresData?.data.map((featureHeaders, index) => (
                <ControlledParentMultipleCheckboxes
                    options={mapDropdownOptions(featureHeaders?.actions)}
                    name={`permissions[${featureHeaders?.id}]`}
                    headerName={featureHeaders?.id}
                    headerCheckBoxLabel={featureHeaders?.title}
                    stripedBackground
                    key={featureHeaders?.id + index}
                />
            ))} */}
            {mergedPermissionsData && (
                <Panel panels={getPanels(mergedPermissionsData)} />
            )}
        </Grid>
    )
}
