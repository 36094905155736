import React from 'react'
import {
    ControlledAutoCompleteSelect,
    MultiSelectAutoComplete,
    Select
} from '@components'
import { useRefValues } from '@hooks'
import { DocumentNode } from 'graphql'
import { SmartSelectProps } from '@types'
import { controlled } from './controlled-component'

export enum SelectTypeEnum {
    Single = 'Single',
    MultiAutoComplete = 'MultiAutoComplete',
    AutoCompleteSelect = 'AutoCompleteSelect'
}

type SelectProps = SmartSelectProps & {
    queryVariables?: object
    accessor?: string
    hasDataAccessor?: boolean
    selectType: SelectTypeEnum
    optionAccessor?: string
}

export const withSmartSelect =
    (query: DocumentNode) => (props: SelectProps) => {
        const { dropdownOptions, queryLoading } = useRefValues(
            query,
            props?.queryVariables,
            props?.accessor,
            props?.optionAccessor
        )

        return props?.selectType === SelectTypeEnum.Single ? (
            <Select
                {...props}
                options={dropdownOptions}
                loading={queryLoading}
            />
        ) : props?.selectType === SelectTypeEnum.MultiAutoComplete ? (
            <MultiSelectAutoComplete
                {...props}
                options={dropdownOptions}
                loading={queryLoading}
            />
        ) : props?.selectType === SelectTypeEnum.AutoCompleteSelect ? (
            <ControlledAutoCompleteSelect
                {...props}
                options={dropdownOptions}
                loading={queryLoading}
            />
        ) : (
            <></>
        )
    }

export const controlledWithSmartSelect = (query: DocumentNode) =>
    controlled(withSmartSelect(query))
