import { gql } from '@apollo/client'

export const CampusFields = (on: string) => gql`
    fragment CampusFields on ${on} {
        id
        title
        email
        phone
        zipCode
        active
        logo
        logoSrc
        campusId
        address
        city {
          id
          title
        }
        creator {
          id
          firstName
          lastName
          email
        }
        modifier {
          id
          firstName
          lastName
          email
        }
        state {
          id
          title
          available
          displayOrder
        }
    }
`
