import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, ClientFields } from '../../fragments'

export const GET_CLIENT = gql`
  ${MessagesFragment('ClientResponseDto')}
  ${MetaFragment('ClientResponseDto')}
  ${ClientFields('ClientDto')}
  query getClient($id: String!) {
    getClient(id: $id) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...ClientFields
      }
    }
  }
`
