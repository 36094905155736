export enum ButtonVariants {
    Text = 'text',
    Outlined = 'outlined',
    Contained = 'contained'
}

export enum HelperTextPosition {
    Left = 'left',
    Right = 'right'
}

export enum TextFieldVariant {
    Outlined = 'outlined',
    Standard = 'standard',
    Filled = 'filled'
}

export const LOGO_URL =
    'https://hubbub-public-assets.s3.amazonaws.com/HB_Vertical_B.png'
