import { Box, styled, Grid } from '@mui/material'

export const StyledContactDetailsBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'contrast'
})(({ theme, contrast }) => ({
    [theme.breakpoints.up('md')]: {
        minWidth: '40%',
        display: 'inline-block'
    },
    padding: contrast ? '1rem 0' : '0',
    '& .material-icons': {
        borderRadius: contrast ? 'unset' : '0.45rem',
        height: '32px',
        width: '32px',
        lineHeight: '2rem',
        marginRight: '1rem'
    },
    '& li': {
        '& div': {
            margin: 2,
            position: 'relative',
            right: '2px'
        },
        '& span': {
            marginTop: 8
        },
        padding: '1px 0'
    },
    '& > *': {
        whiteSpace: 'break-spaces'
    }
}))

export const StyledIconContainer = styled(Grid)(({ backgroundColor }) => ({
    backgroundColor: `${backgroundColor}`,
    width: '27px',
    height: '25px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
}))
