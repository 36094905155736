import React, { useState } from 'react'
import { useCustomTheme, useInfiniteScroll } from '@hooks'
import { EmptyState, MuiCard } from '@components'
import { Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import { DocumentNode } from '@apollo/client'
import { cloneDeep } from 'lodash'

export type CardConfig = {
    onCardClick?: (data: object) => void
    loadCardContent: (data: object) => JSX.Element
}

export type UseCardDirectoryConfig = {
    header?: string
    excludedFeaturedHeaders: Array<string>
    query: DocumentNode
    queryName: string
    queryVariables: object
    cardConfig: CardConfig
}

export const useCardDirectory = (config: UseCardDirectoryConfig) => {
    const {
        header,
        query,
        queryName,
        excludedFeaturedHeaders,
        queryVariables,
        cardConfig: { onCardClick, loadCardContent }
    } = config
    const { theme } = useCustomTheme()
    const [headerTitle, setHeader] = useState(header)

    const isSmallestScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('sm')
    )

    const loadComponent = (data: Array<object>) => {
        const showFeatured = headerTitle
            ? !excludedFeaturedHeaders.includes(headerTitle)
            : false

        const cards = data?.length ? (
            data?.map((item) => {
                const copyItem = cloneDeep(item)
                copyItem.isFeatured = showFeatured && copyItem?.isFeatured

                return (
                    <Grid
                        item
                        md={copyItem?.isFeatured ? 12 : 4}
                        sm={copyItem?.isFeatured ? 12 : 6}
                        xs={12}
                        key={copyItem?.id}
                        sx={{
                            paddingBottom: copyItem?.isFeatured ? '24px' : '0px'
                        }}
                    >
                        <MuiCard
                            sx={{
                                boxShadow: 'none',
                                background: theme.palette.backgroundPrimary
                            }}
                            onClick={() => onCardClick?.(copyItem)}
                            content={loadCardContent(copyItem)}
                            contentSx={{
                                padding: isSmallestScreen
                                    ? '0px'
                                    : '0px 16px 0px 0px'
                            }}
                        />
                        {copyItem?.isFeatured && <Divider />}
                    </Grid>
                )
            })
        ) : (
            <EmptyState />
        )

        return (
            <Grid container direction={'column'}>
                {headerTitle && (
                    <Grid mx={3}>
                        <Typography
                            sx={{
                                padding: '16px',
                                fontSize: '20px',
                                fontWeight: '600'
                            }}
                        >
                            {headerTitle}
                        </Typography>
                        <Divider />
                    </Grid>
                )}
                <Grid
                    container
                    sx={{
                        padding: headerTitle ? '24px' : '16px'
                    }}
                    md={12}
                >
                    {cards}
                </Grid>
            </Grid>
        )
    }

    const { data, setData, setQueryVariables, wrappedComponent } =
        useInfiniteScroll({
            query,
            queryName,
            queryVariables,
            loadComponent
        })

    return {
        data,
        setData,
        setHeader,
        setQueryVariables,
        component: wrappedComponent
    }
}
