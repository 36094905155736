import { MessagesFragment } from '../../fragments'

import { gql } from '@apollo/client'

export const GET_CONNECTION = gql`
  ${MessagesFragment('GetConnectionResponseDto')}
  query getConnection($args: GetUserInputDto!) {
    getConnection(args: $args) {
      ...MessagesFragment
      data {
        connectionName
      }
      meta {
        count
        verified
        userExists
      }
    }
  }
`
