import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRoutes } from '@hooks'
import { HeaderOutlet } from '@components'

export const VerticalsPage = () => {
    const { t } = useTranslation()
    const { verticalRoutes } = useRoutes()

    return (
        <HeaderOutlet
            headerText={t('sideNavItems.verticals')}
            menuConfig={verticalRoutes}
        />
    )
}
