import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormFeedback, usePage } from '@hooks'
import { GET_DASHBOARD_ANALYTICS } from '@dts/graphql'
import { AdminCards } from './admin-cards'
import { DashboardVideos } from './manage-dashboard-videos'

export const AdminDashboard = () => {
    const { t } = useTranslation()

    const { methods, mutationLoading, queryLoading } = useFormFeedback({
        query: GET_DASHBOARD_ANALYTICS,
        queryName: 'getDashboardAnalytics',
        mapQueryResultToForm: (response) => {
            const categoryCounts = {
                clients: {
                    title: t('general.clients'),
                    count: 0
                },
                videos: {
                    title: t('general.videos'),
                    count: response?.videosCount
                },
                trainees: {
                    title: t('general.trainees'),
                    count: 0
                },
                creators: {
                    title: t('general.contentCreators'),
                    count: response?.contentCreatorsCount
                }
            }

            return { categoryCounts }
        }
    })

    const getComponentToRender = useCallback(
        () => (
            <>
                <AdminCards />
                <DashboardVideos />
            </>
        ),
        []
    )
    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: getComponentToRender,
            hasHeader: true,
            preventPadding: true,
            pageHeaderConfig: {
                headerText: t('sideNavItems.dashboard')
            }
        }
    })

    return <>{page}</>
}
