import React from 'react'
import { RouteLayout, TrainingsPage } from '@pages'
import { ObjectBaseFields } from '@types'
import { getAccessRoles } from '@utils'
import { TrainingsStatus } from '@constants'
import { ArchiveIcon, CheckCircleIcon } from '@assets'
import { CreateTraining, EditTrainingCoursesContainer } from '@containers'
import { UserPermissionEnum } from '@dts/constants'

export const getTrainingsRoutes = (roles: Array<ObjectBaseFields>) => {
    const accessedRoles = getAccessRoles(roles)
    const isClientAdmin = accessedRoles?.isClientAdmin
    return [
        {
            path: '',
            component: TrainingsPage,
            componentProps: { status: TrainingsStatus.AVAILABLE },
            canUserAccess: isClientAdmin,
            permissions: [UserPermissionEnum.TrainingsView]
        },
        {
            path: 'all',
            component: TrainingsPage,
            componentProps: { status: TrainingsStatus.AVAILABLE },
            label: 'general.active',
            isLanding: 'trainings',
            isHeaderLink: true,
            canUserAccess: isClientAdmin,
            icon: CheckCircleIcon
        },
        {
            path: 'archived',
            component: TrainingsPage,
            componentProps: { status: TrainingsStatus.Archived },
            label: 'general.archived',
            isHeaderLink: true,
            canUserAccess: isClientAdmin,
            icon: ArchiveIcon
        },
        {
            path: 'new',
            component: CreateTraining,
            label: 'sideNavItems.videos',
            isHeaderLink: false,
            isSideNav: false,
            canUserAccess: isClientAdmin,
            hidePageHeader: true
        },

        {
            path: ':id',
            component: RouteLayout,
            label: 'sideNavItems.videos',
            hidePageHeader: true,
            canUserAccess: isClientAdmin,
            isHeaderLink: false,
            isSideNav: false,
            subRoutes: [...getTrainingsSubRoutes(roles)]
        }
    ]
}

export const getTrainingsSubRoutes = (roles: Array<ObjectBaseFields>) => {
    const accessedRoles = getAccessRoles(roles)
    const isClientAdmin = accessedRoles?.isClientAdmin
    return [
        {
            path: '',
            component: CreateTraining,
            label: 'sideNavItems.videos',
            isHeaderLink: false,
            isSideNav: false,
            canUserAccess: isClientAdmin,
            hidePageHeader: true
        },
        {
            path: 'edit-courses',
            component: EditTrainingCoursesContainer,
            label: 'sideNavItems.videos',
            isHeaderLink: false,
            isSideNav: false,
            canUserAccess: isClientAdmin,
            hidePageHeader: true
        }
    ]
}
