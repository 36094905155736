import { isEmpty } from 'lodash'
import * as yup from 'yup'

export const ListContentSchema = yup.object().shape({
  fileUploader: yup
    .string()
    .required('learnerList.fileUpload.required')
    .test('csvDataCheck', function (value) {
      const csvData = this.resolve(yup.ref('csvData'))
      if (!Array.isArray(csvData) || csvData.every((item) => item === '')) {
        return this.createError({ message: 'learnerList.file.data' })
      }
      return true
    }),
})
