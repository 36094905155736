import ActiveIcon from './active'
import AddCircleIcon from './add-circle'
import AddIcon from './add'
import AllIcon from './all'
import ArchiveIcon from './archive'
import ArrowDownCircleIcon from './arrow-down-circle'
import ArrowDownIcon from './arrow-down'
import ArrowLeftCircleIcon from './arrow-left-circle'
import ArrowLeftIcon from './arrow-left'
import ArrowNarrowLeftIcon from './arrow-narrow-left'
import ArrowRightIcon from './arrow-right'
import ArrowUpCircleIcon from './arrow-up-circle'
import BarChartIcon from './bar-chart'
import BoldCrossIcon from './bold-cross'
import BoldTickIcon from './bold-tick'
import BreakdownIcon from './breakdown'
import BuildingsIcon from './building'
import CameraIcon from './camera'
import CaptionsIcon from './captions'
import CategoryIcon from './category'
import ChartArrowDownIcon from './chart-arrow-down'
import CheckCircleIcon from './check-circle'
import CheckmarkIcon from './checkmark'
import ChevronDownIcon from './chevron-down'
import ChevronLeftIcon from './chevron-left'
import ChevronUpIcon from './chevron-up'
import CinemaIcon from './ciname'
import CircleIcon from './circle'
import ClockIcon from './clock'
import CloseIcon from './close'
import CollapseIcon from './collapse'
import CopyIcon from './copy'
import CourseIcon from './course'
import CrossIcon from './cross'
import DashboardIcon from './dashboard'
import DeleteIcon from './delete-icon'
import DescriptionIcon from './description'
import DiceIcon from './dice'
import DocumentFoldIcon from './document-fold'
import DocumentIcon from './document'
import DocumentQuestionMarkIcon from './document-question-mark'
import DotMenuIcon from './dot-menu'
import DownloadIcon from './download'
import DraftIcon from './draft'
import DragAndDropIcon from './drag-and-drop'
import DropdownIcon from './dropdown'
import DuplicateIcon from './duplicate'
import EditIcon from './edit'
import EditProfilePictureIcon from './edit-profile-picture'
import EditRecordIcon from './edit-record'
import EditUserIcon from './edit-user'
import EmailIcon from './email'
import Error403Icon from './Error403'
import ExpandedIcon from './expanded'
import FeedbackIcon from './feedback'
import FillCheckCircle from './fill-check-circle'
import FilledCheckedIcon from './filled-checked'
import FilterActiveIcon from './filter-active'
import FilterIcon from './filter'
import FolderIcon from './folder'
import FollowIcon from './follow'
import GlobeIcon from './globe'
import GridIcon from './grid'
import HalfMoonIcon from './half-moon'
import HelpIcon from './help'
import HelpTextIcon from './help-text'
import HideIcon from './hide'
import HourGlassIcon from './hourglass'
import InfoIcon from './info'
import Icon247 from './247Icon'
import InformationIcon from './information'
import InsightArrowDownIcon from './insight-arrow-down'
import InsightArrowUpIcon from './insight-arrow-up'
import InsightsIcon from './insights'
import KeyIcon from './key'
import LaterIcon from './later'
import LaunchIcon from './launch'
import LightIcon from './light'
import LikeIcon from './like'
import LikesIcon from './likes'
import LinkIcon from './link'
import ListIcon from './list'
import LockIcon from './lock'

import LogoutIcon from './logout'
import MapPinIcon from './map-pin'
import MapValuesIcon from './map-fields'
import MatchFieldsIcon from './match-fields'
import MenuIcon from './menu'
import MetricIcon from './metric'
import MinusSquareOutlineIcon from './minus-square-outline'
import MobileIcon from './mobile'
import NotificationsIcon from './notifications'
import PaperPlaneIcon from './paper-plane'
import PencilIcon from './pencil'
import PendingIcon from './pending'
import PhoneIcon from './phone'
import PlayFilledIcon from './play-filled'
import PlayIcon from './play'
import PlusBoxIcon from './plus-box'
import PlusIcon from './plus'
import PlusSquareOutlineIcon from './plus-square-outline'
import PolicyIcon from './policy'
import PortalIntoIcon from './portal-into'
import PostTestIcon from './post-test'
import PreAndPostSurveyIcon from './pre-and-post-survey'
import PreTestIcon from './pre-test'
import PulseAnswerCheckedIcon from './pulse-answer-checked'
import PulseAnswerIcon from './pulse-answer'
import PulseIcon from './pulse'
import QuestionMarkIcon from './question-mark'
import ReOrderIcon from './re-order'
import ReadingIcon from './reading'
import RecordIcon from './record'
import ReplayIcon from './replay'
import ReportIcon from './report'
import ReportIssueIcon from './report-issue'
import ResourcesIcon from './resources'
import RestoreIcon from './restore'
import ReviewIcon from './review'
import ReviewVideoIcon from './review-video'
import SaveAsIcon from './save-as'
import SaveIcon from './save'
import SearchIcon from './search'
import SettingsCheckedIcon from './settings-checked'
import SettingsIcon from './settings'
import ShowLess from './show-less'
import SpeakerIcon from './speaker'
import SquareIcon from './square'
import SunIcon from './sun'
import SupportIcon from './support'
import TagIcon from './tag'
import TemplateIcon from './templates'
import TextMessageIcon from './text-message'
import TickIcon from './tick'
import TimeoutIcon from './timeout'
import ToolsIcon from './tools'
import TopicsIcon from './topics'
import TwoTogglesIcon from './two-toggles'
import UnHideIcon from './unhide'
import UploadFileIcon from './upload-file'
import UploadIcon from './upload'
import UploadThumbnailIcon from './upload-thumbnail'
import UserCheckIcon from './user-check'
import UserIcon from './user'
import UserSingleIcon from './user-single'
import UserUnsureIcon from './user-unsure'
import UsersIcon from './users'
import VersionsIcon from './versions'
import VideoApprovalIcon from './video-approval'
import VideoIcon from './video'
import VideoPlayerIcon from './video-player'
import ViewIcon from './view'
import ViewOffIcon from './view-off'
import ViewsIcon from './views'
import WarningIcon from './warning'
import WatchTimeIcon from './watch-time'
import XCircleIcon from './x-circle'
import MultipleUsersIcon from './multiple-users'
import SurveyQuestionsTextIcon from './survey-question-text'
import ChatIcon from './chat'
import ReloadIcon from './reload'
import DoubleArrowIcon from './double-arrow'
import RocketDown from './rocket-down'

export {
    ArrowDownIcon,
    DashboardIcon,
    CategoryIcon,
    VideoIcon,
    UsersIcon,
    Icon247,
    NotificationsIcon,
    EditUserIcon,
    SettingsIcon,
    BuildingsIcon,
    VersionsIcon,
    TextMessageIcon,
    CloseIcon,
    MenuIcon,
    LogoutIcon,
    LockIcon,
    UserIcon,
    DeleteIcon,
    EditIcon,
    UploadThumbnailIcon,
    UploadIcon,
    RecordIcon,
    EmailIcon,
    LinkIcon,
    GlobeIcon,
    EditProfilePictureIcon,
    PencilIcon,
    ChartArrowDownIcon,
    InsightArrowUpIcon,
    PendingIcon,
    ActiveIcon,
    AddIcon,
    DocumentIcon,
    InfoIcon,
    ReviewVideoIcon,
    Error403Icon,
    SaveIcon,
    LikeIcon,
    FollowIcon,
    HideIcon,
    CaptionsIcon,
    HalfMoonIcon,
    QuestionMarkIcon,
    ReportIssueIcon,
    ArrowRightIcon,
    ChevronLeftIcon,
    HourGlassIcon,
    LaunchIcon,
    DraftIcon,
    XCircleIcon,
    CheckCircleIcon,
    UserSingleIcon,
    UserCheckIcon,
    PlayIcon,
    ListIcon,
    GridIcon,
    ToolsIcon,
    AddCircleIcon,
    VideoPlayerIcon,
    ReportIcon,
    BarChartIcon,
    TopicsIcon,
    ReadingIcon,
    ResourcesIcon,
    SupportIcon,
    LightIcon,
    CinemaIcon,
    PolicyIcon,
    HelpIcon,
    SearchIcon,
    ArrowNarrowLeftIcon,
    DotMenuIcon,
    DescriptionIcon,
    ReviewIcon,
    PlusBoxIcon,
    TickIcon,
    DuplicateIcon,
    ArchiveIcon,
    SpeakerIcon,
    PaperPlaneIcon,
    UserUnsureIcon,
    FilterActiveIcon,
    FilterIcon,
    CollapseIcon,
    ExpandedIcon,
    PlusSquareOutlineIcon,
    MinusSquareOutlineIcon,
    RestoreIcon,
    EditRecordIcon,
    CourseIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    PlusIcon,
    SunIcon,
    PhoneIcon,
    MapPinIcon,
    MobileIcon,
    ClockIcon,
    DocumentFoldIcon,
    TwoTogglesIcon,
    UnHideIcon,
    UploadFileIcon,
    MapValuesIcon,
    MatchFieldsIcon,
    TagIcon,
    FilledCheckedIcon,
    CopyIcon,
    LaterIcon,
    ReplayIcon,
    DiceIcon,
    DragAndDropIcon,
    ShowLess,
    FillCheckCircle,
    PortalIntoIcon,
    AllIcon,
    VideoApprovalIcon,
    KeyIcon,
    TemplateIcon,
    CameraIcon,
    ArrowLeftIcon,
    ArrowLeftCircleIcon,
    MetricIcon,
    BreakdownIcon,
    HelpTextIcon,
    CircleIcon,
    ViewIcon,
    SquareIcon,
    PulseAnswerIcon,
    CrossIcon,
    InformationIcon,
    WarningIcon,
    CheckmarkIcon,
    ViewsIcon,
    LikesIcon,
    WatchTimeIcon,
    InsightArrowDownIcon,
    DropdownIcon,
    DocumentQuestionMarkIcon,
    PreTestIcon,
    PostTestIcon,
    PulseIcon,
    ArrowUpCircleIcon,
    ArrowDownCircleIcon,
    PlayFilledIcon,
    InsightsIcon,
    ViewOffIcon,
    PulseAnswerCheckedIcon,
    SettingsCheckedIcon,
    TimeoutIcon,
    BoldCrossIcon,
    BoldTickIcon,
    FolderIcon,
    SaveAsIcon,
    DownloadIcon,
    ReOrderIcon,
    PreAndPostSurveyIcon,
    FeedbackIcon,
    MultipleUsersIcon,
    SurveyQuestionsTextIcon,
    ChatIcon,
    ReloadIcon,
    DoubleArrowIcon,
    RocketDown
}
