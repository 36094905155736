import React from 'react'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { usePageHeader, useRoutes } from '@hooks'

export const VideosLayout: React.FC = () => {
    const { t } = useTranslation()
    return <Outlet />
}
export const CreatorVideosLayout: React.FC = () => {
    const { userAccountRoutes } = useRoutes()
    const { t } = useTranslation()

    const { pageHeader } = usePageHeader({
        headerText: t('sideNavItems.myVideos'),
        menuConfig: () => userAccountRoutes
    })

    return (
        <>
            {pageHeader}
            <Grid py={1} px={2}>
                <Outlet />
            </Grid>
        </>
    )
}
export const CreatorVideosSubLayout: React.FC = () => (
    <Grid py={1} px={2}>
        <Outlet />
    </Grid>
)
