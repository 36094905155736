import { Dispatch } from 'react'

export type LearnerListProps = {
    status: string
}
export type AddLearnerProps = {
    setUserType: Dispatch<React.SetStateAction<string>>
    learnerListId: string
}

export enum UserType {
    NEW = 'new',
    EXISTING = 'existing'
}

export type AddLeanerProps = {
    courseTopics?: object
    id: string
    email: string
    firstName?: string
    title?: string
    isAdded?: boolean
}
