import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, VideoFields } from '../../fragments'

export const GET_SEARCH_SUGGESTIONS = gql`
  ${MetaFragment('SearchSuggestionResponseDto')}
  ${MessagesFragment('SearchSuggestionResponseDto')}
  query getSearchSuggestions($args: SearchSuggestionArgsDto!) {
    getSearchSuggestions(args: $args) {
      ...MetaFragment
      ...MessagesFragment
      data {
        suggestions
      }
    }
  }
`
