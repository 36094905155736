import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import {
    DatePicker as MuiDatePicker,
    DatePickerProps,
    LocalizationProvider
} from '@mui/x-date-pickers-pro'
import dayjs, { Dayjs } from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { isNaN, isNull } from 'lodash'
import { controlled } from '@hoc'
import { useCustomTheme } from '@hooks'
import { DEFAULT_MIN_DATE } from '@constants'

export const DatePicker = React.forwardRef(
    (
        {
            id,
            name,
            width,
            views,
            disabled,
            openTo,
            helperText,
            error,
            onChange,
            format,
            value,
            minDate,
            isDisableFuture = true,
            ...props
        }: InputProps & DatePickerProps,
        ref
    ) => {
        const {
            register,
            setValue,
            getValues,
            trigger,
            watch,
            formState: { errors },
            ...formContext
        } = useFormContext()
        const { theme } = useCustomTheme()

        const formattedValue = (v) => {
            if (v) {
                return typeof getValues(name) === 'string'
                    ? dayjs(getValues(name))
                    : v
            }
            return null
        }

        return (
            <LocalizationProvider
                width={width}
                dateAdapter={AdapterDayjs}
                adapterLocale={'en'}
            >
                <MuiDatePicker
                    {...props}
                    name={name}
                    role={id}
                    disabled={disabled}
                    disableOpenPicker={
                        formContext?.formEditingState?.isReadOnly || false
                    }
                    views={views ?? ['year', 'day']}
                    openTo={openTo ?? 'day'}
                    value={formattedValue(value)}
                    isRequired
                    onClose={() => trigger(name)}
                    format={format ?? 'MM/DD/YYYY'}
                    disableFuture={isDisableFuture}
                    minDate={dayjs(minDate || DEFAULT_MIN_DATE)}
                    onChange={(newValue) => {
                        const value = !isNull(newValue) ? newValue : undefined

                        if (!isNull(newValue) && !isNaN(Date.parse(newValue))) {
                            onChange?.(value?.format('YYYY-MM-DD'))
                            setValue(name, value?.format('YYYY-MM-DD'))
                        } else {
                            onChange?.(newValue?.format('YYYY-MM-DD'))
                        }
                        trigger(name)
                        props?.triggerChange && trigger(props?.triggerChange)
                    }}
                    sx={{
                        width,
                        marginTop: '1rem'
                        // TODO Remove Style
                        // '& .MuiSvgIcon-root': {
                        //     color: theme.palette.contentSecondary // Change to your desired icon color
                        // },
                        // '& .css-jzhy1d-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled':
                        //     {
                        //         color: theme.palette.neutralsTextGrey // Change to your desired disabled text color
                        //     }
                    }}
                    slotProps={{
                        textField: {
                            onBlur: () => trigger(name),
                            error: error ?? !!errors?.[name],
                            id
                        }
                    }}
                />
            </LocalizationProvider>
        )
    }
)
export const ControlledDatePicker = controlled(DatePicker)
