import styled from '@emotion/styled'
import { Grid } from '@mui/material'

export const StyledNotificationRow = styled(Grid)(({ isRead, theme }) => ({
    '&:hover': {
        backgroundColor: theme.palette.BrandPrimaryContainer,
        cursor: 'pointer'
    },
    width: '100%',
    borderRadius: '8px',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: isRead ? theme.palette.backgroundPrimary : `#6989281A` // This color will be replaced later on after the final theming changes
}))
