import { BasicFields } from '../../fragments'
import { gql } from '@apollo/client'

export const GET_DAYS = gql`
  ${BasicFields('BaseRefDto')}
  query getDays {
    getDays {
      ...BasicFields
    }
  }
`

export const GET_MONTH_WEEKS = gql`
  ${BasicFields('BaseRefDto')}
  query getMonthWeeks {
    getMonthWeeks {
      ...BasicFields
    }
  }
`

export const GET_DAILY_REMINDER_OPTIONS = gql`
  ${BasicFields('BaseRefDto')}
  query getDailyReminderOptions {
    getDailyReminderOptions {
      ...BasicFields
    }
  }
`

export const GET_MONTHLY_REMINDER_OPTIONS = gql`
  ${BasicFields('BaseRefDto')}
  query getMonthlyReminderOptions {
    getMonthlyReminderOptions {
      ...BasicFields
    }
  }
`

export const GET_NOTIFICATION_FREQUENCIES = gql`
  ${BasicFields('BaseRefDto')}
  query getNotificationFrequencies {
    getNotificationFrequencies {
      ...BasicFields
    }
  }
`
