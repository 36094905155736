import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { CreateClientSchema } from '@dts/client-utils'
import { SAVE_CLIENT, GET_CLIENT } from '@dts/graphql'
import { useFormFeedback, usePage } from '@hooks'
import { CreateEditClientForm } from './modify-client-form'
import { Sector } from './constants'

export const ModifyClientContainer: React.FC = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const navigate = useNavigate()

    const { methods, save, queryLoading, mutationLoading } = useFormFeedback({
        initialValues: {
            clientType: '',
            clientId: '',
            clientName: '',
            status: true,
            email: '',
            phone: '',
            address: '',
            state: '',
            city: '',
            zipCode: '',
            verticals: [],
            sector: Sector.Public,
            expiryDate: null,
            startDate: null,
            contactName: '',
            contactRole: '',
            contactEmail: '',
            contactPhone: '',
            contactMobile: ''
        },
        mapFormToMutationInput: ({
            clientType,
            clientId,
            clientName,
            status,
            email,
            phone,
            address,
            state,
            city,
            zipCode,
            startDate,
            expiryDate,
            sector,
            contactRole,
            contactName,
            contactEmail,
            contactPhone,
            contactMobile,
            verticals
        }) => ({
            input: {
                id: id ? id : undefined,
                title: clientName,
                email,
                phone,
                zipCode,
                cityId: city,
                active: status,
                sectorId: sector,
                verticals,
                stateId: state,
                contractExpiryDate: expiryDate,
                contractStartDate: startDate,
                address,
                clientId,
                clientTypeId: clientType,
                clientContact: {
                    title: contactName,
                    email: contactEmail,
                    phone: contactPhone,
                    mobile: contactMobile,
                    designation: contactRole
                }
            }
        }),
        mutation: SAVE_CLIENT,
        mutationName: 'saveClient',
        isCustomMutationInput: true,
        onMutationSuccess: () => {
            navigate('/clients')
        },
        ...(id
            ? {
                  query: GET_CLIENT,
                  queryName: 'getClient',
                  retrieveOnMount: true,
                  queryVariables: { id },
                  mapQueryResultToForm: ({
                      title,
                      email,
                      active,
                      phone,
                      address,
                      state,
                      city,
                      zipCode,
                      sector,
                      contractExpiryDate,
                      contractStartDate,
                      clientType,
                      clientId,
                      clientContact,
                      verticals
                  }) => ({
                      clientType: clientType?.id,
                      clientId,
                      clientName: title,
                      email,
                      status: active,
                      phone,
                      address,
                      state: state?.id,
                      city: city?.id,
                      zipCode,
                      sector: sector?.id,
                      startDate: contractStartDate,
                      expirayDate: contractExpiryDate,
                      expiryDate: contractExpiryDate,
                      contactRole: clientContact?.designation,
                      contactName: clientContact?.title,
                      contactEmail: clientContact?.email,
                      contactPhone: clientContact?.phone,
                      contactMobile: clientContact?.mobile,
                      verticals: verticals?.map((vertical) => vertical?.id)
                  })
              }
            : undefined),
        yupResolverSchema: CreateClientSchema
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: CreateEditClientForm,
            hasHeader: true,
            preventMargin: true,
            showBreadcrumbs: true,
            pageHeaderConfig: {
                headerText: t('clients.newClient'),
                primaryButtonTitle: t('general.save'),
                primaryButtonAction: () => {
                    methods.handleSubmit(() => save())()
                },
                secondaryButtonTitle: t('general.cancel'),
                secondaryButtonAction: () => navigate('/clients')
            }
        }
    })

    return <>{page}</>
}
