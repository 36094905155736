import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { useCustomTheme } from '@hooks'
import { CustomLoader } from '@components'
import { LoaderProps } from '@types'
import { LOADER_TIMEOUT } from '@constants'
import {
    StyledLoader,
    LoaderWrapper,
    BackgroundGrid,
    LoaderGrid
} from './styles'

export const Loader: React.FC<LoaderProps> = ({ size, label, height }) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false)
        }, LOADER_TIMEOUT)
        return () => clearTimeout(timer)
    }, [])

    return (
        <>
            {loading && (
                <LoaderWrapper height={height}>
                    <BackgroundGrid />
                    <LoaderGrid>
                        <StyledLoader>
                            <CustomLoader />
                        </StyledLoader>
                    </LoaderGrid>
                </LoaderWrapper>
            )}
        </>
    )
}
