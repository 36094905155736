import React from 'react'
import { ArchiveIcon, PreAndPostSurveyIcon, PulseIcon } from '@assets'
import { ObjectBaseFields } from '@types'
import { RouteLayout } from '@pages'
import {
    CreateSurvey,
    EditSurveyCourses,
    ManageSurveys,
    SurveyResults
} from '@containers'
import { SurveyTabEnum, UserPermissionEnum } from '@dts/constants'
import { getAccessRoles } from '@utils'

export const getSurveyRoutes = (roles: Array<ObjectBaseFields>) => {
    const accessedRoles = getAccessRoles(roles)
    const { isManager, isClientAdmin } = accessedRoles
    return [
        {
            path: '',
            component: ManageSurveys,
            componentProps: { insightPanelId: SurveyTabEnum.SURVEY },
            canUserAccess: isManager || isClientAdmin,
            permissions: [UserPermissionEnum.SurveyView]
        },
        {
            path: 'pre-and-post',
            component: ManageSurveys,
            componentProps: { insightPanelId: SurveyTabEnum.SURVEY },
            label: 'survey.preAndPostSurveys',
            isLanding: 'surveys',
            isHeaderLink: true,
            canUserAccess: isManager || isClientAdmin,
            icon: PreAndPostSurveyIcon,
            permissions: [UserPermissionEnum.SurveyView]
        },
        {
            path: 'pulse',
            component: ManageSurveys,
            componentProps: { insightPanelId: SurveyTabEnum.PULSE },
            label: 'survey.pulseQuestions',
            isHeaderLink: true,
            canUserAccess: isManager || isClientAdmin,
            icon: PulseIcon,
            permissions: [UserPermissionEnum.SurveyView]
        },
        {
            path: 'archived',
            component: ManageSurveys,
            componentProps: { insightPanelId: SurveyTabEnum.ARCHIVED },
            label: 'general.archived',
            isHeaderLink: true,
            canUserAccess: isManager || isClientAdmin,
            icon: ArchiveIcon,
            permissions: [UserPermissionEnum.SurveyView]
        },
        {
            path: 'new',
            component: CreateSurvey,
            label: 'sideNavItems.videos',
            isHeaderLink: false,
            isSideNav: false,
            canUserAccess: isManager || isClientAdmin,
            hidePageHeader: true
        },

        {
            path: ':id',
            component: RouteLayout,
            label: 'sideNavItems.videos',
            hidePageHeader: true,
            canUserAccess: isManager || isClientAdmin,
            isHeaderLink: false,
            isSideNav: false,
            subRoutes: [...getSurveySubRoutes(isManager || isClientAdmin)]
        }
    ]
}

export const getSurveySubRoutes = (canUserAccess: boolean) => [
    {
        path: '',
        component: CreateSurvey,
        label: 'sideNavItems.videos',
        isHeaderLink: false,
        isSideNav: false,
        canUserAccess,
        hidePageHeader: true
    },
    {
        path: 'edit-courses',
        component: EditSurveyCourses,
        label: 'sideNavItems.surveys',
        isHeaderLink: false,
        isSideNav: false,
        canUserAccess,
        hidePageHeader: true
    },
    {
        path: 'results',
        component: SurveyResults,
        isHeaderLink: false,
        isSideNav: false,
        canUserAccess,
        hidePageHeader: true
    }
]
