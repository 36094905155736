import { gql } from '@apollo/client'

export const GET_SKILLS = gql`
  query getSkills {
    getSkills {
      id
      title
      available
      displayOrder
    }
  }
`
