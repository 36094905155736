import styled from '@emotion/styled'
import { Typography, Grid } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Box } from '@mui/system'
import { ThemeMode } from '@dts/constants'
type CellProps = {
    backgroundColor?: string
}
type GridProps = {
    minHeight?: number
    maxWidth?: number
    headerColor?: string
    isColumnHeaderCheckbox?: boolean
    rowsLength?: number
}
export const StyledContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'headerColor'
})<GridProps>`
    height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : '100%')};
    max-width: unset;
    flex-grow: 1;
    > .MuiDataGrid-root {
        width: auto;
        border-radius: 0;
        .MuiDataGrid-pinnedColumnHeaders {
            padding: 0px !important;
        }
    }
`
export const StyledHeader = styled(Typography)`
    font-size: 14px;
    font-weight: bold;
`

/**    
 * 
 * datagrid styles might need them in future
 *  test
 * 
 * > .MuiDataGrid-root {
        max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 950)}px;
        width: auto;
        flex: 1;
        border-radius: 2px;
        .MuiDataGrid-main {
            display: ${({ isApplicable }) => (isApplicable ? 'none' : 'flex')};
        }
        .MuiDataGrid-columnHeaders {
            border-radius: 0;
            background-color: ${({ headerColor }) => headerColor ?? '#fff'};
            button {
                svg {
                    path {
                        stroke: white;
                    }
                }
            }
        }
        .MuiDataGrid-columnSeparator--sideRight {
            display: none !important;
            background: black;
        }
        .MuiDataGrid-row {
            // border-top: 1px solid #e0e0e0;
            >div: nth-last-of-type(2) {
                border-right: none;
            }
        }
        .MuiDataGrid-detailPanel {
            .MuiDataGrid-row {
                border: 0;
                > div:first-of-type {
                    border-left: 2px solid secondary.main;
                }
                > div:nth-last-of-type(2) {
                    border-right: none;
                }
            }
        }
        .MuiDataGrid-row.Mui-selected {
            background-color: #eff9ff;
        }
        .MuiDataGrid-cell {
            &:focus-within {
                outline: 0;
            }
        }
        .selected-row {
            background: #eff9ff;
        }
        .remove-border {
            border-right: 0px;
        }
        .MuiDataGrid-columnHeaders {
            border: 0;
        }
        .MuiDataGrid-columnHeaderCheckbox
            .MuiDataGrid-columnHeaderTitleContainer {
            display: ${({ isColumnHeaderCheckbox }) =>
                !isColumnHeaderCheckbox ? 'none' : 'flex'};
        }
    }
 */
export const CustomCell = styled.div<CellProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: ${({ backgroundColor }) => backgroundColor};
    .text {
        margin: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .icon {
        margin-right: 8px;
    }
`
export const StyledDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text {
        > p {
            margin: 8px;
            width: 140px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .icon {
        margin-right: 8px;
    }
`

export const TextContainer = styled(Typography)`
    font-size: 0.875rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80% & p {
        margin-left: 7px;
        display: inline;
        font-size: 0.875rem;
    }
    & svg {
        position: relative;
        top: 4px;
        right: 1px;
    }
`

export const StyledComponentGrid = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`
export const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
    '& .MuiSelect-icon': {
        color: theme.palette.contentSecondary // Change to color of footer page DropDown icon
    },
    '& .MuiDataGrid-footerContainer .MuiButtonBase-root .MuiSvgIcon-root': {
        color: theme.palette.contentSecondary // Change to color of footer icons page pageSize
    },
    '& .MuiDataGrid-row:hover': {
        backgroundColor: theme.palette.BrandPrimaryContainer // Row hover effect
    },
    '.MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor': {
        backgroundColor: theme.palette.backgroundPrimary // This class is used to change the column header color
        // borderColor: 'red' // Might be used in future to change border color
    },
    '.MuiDataGrid-pinnedColumnHeaders.MuiDataGrid-pinnedColumnHeaders--right.MuiDataGrid-withBorderColor':
        {
            backgroundColor: theme.palette.backgroundPrimary // Used to change color of right top cell
        },
    '.MuiDataGrid-cell.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor':
        {
            backgroundColor: theme.palette.backgroundPrimary // Used to change batch action cell
        },
    '& .MuiSvgIcon-root': {
        fill: theme.palette.contentSecondary // Change fill color for all icons
    },
    '& .MuiSvgIcon-root[data-testid="AddIcon"]': {
        fill: theme.palette.backgroundBrandPrimary // Specifically target the plus icon via data attribute or other stable selectors
    },
    '& .MuiGrid-root': {
        backgroundColor:
            theme.palette.mode === ThemeMode.Dark
                ? theme.palette.backgroundPrimary
                : 'inherit' // Change background color for nested tables
    },
    '& .MuiIconButton-root': {
        backgroundColor: `${theme.palette.backgroundPrimary} !important` // Override the background color of the IconButton
    },
    '.MuiDataGrid-row.MuiDataGrid-row--Mui-hovered': {
        backgroundColor: `${theme.palette.backgroundPrimary} !important` // Ensure hover background color is applied
    },
    '& .MuiDataGrid-withBorderColor': {
        borderColor: theme.palette.borderSecondary // Replace with your custom border color
    },
    color: theme.palette.contentPrimary,
    borderColor: theme.palette.borderSecondary
}))
