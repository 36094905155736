import React from 'react'
import { useCustomTheme } from '@hooks'
import { SvgProps } from '@types'

const ArrowUpCircleIcon: React.FC<SvgProps> = ({
    color,
    width = '20',
    height = '20'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentPrimaryInverse

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M13.3333 10.0003L9.99996 6.66699M9.99996 6.66699L6.66663 10.0003M9.99996 6.66699V13.3337M18.3333 10.0003C18.3333 14.6027 14.6023 18.3337 9.99996 18.3337C5.39759 18.3337 1.66663 14.6027 1.66663 10.0003C1.66663 5.39795 5.39759 1.66699 9.99996 1.66699C14.6023 1.66699 18.3333 5.39795 18.3333 10.0003Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default ArrowUpCircleIcon
