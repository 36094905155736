import React from 'react'
import { useTranslation } from 'react-i18next'
import { AddLearnerSchema } from '@dts/client-utils'
import { useModalWithGraphQL } from '@hooks'
import { ADD_LEARNER } from '@dts/graphql'
import { UserType } from './types'
import { AddLearner } from './add-learners-form'

export const useAddLearner = (
    onMutationSuccess?: ((response?: unknown) => void) | undefined
) => {
    const { t } = useTranslation()

    const { openDialog: onAddLearner } = useModalWithGraphQL({
        mutationName: 'addLearner',
        mutation: ADD_LEARNER,
        onMutationSuccess,
        mapFormToMutationInput: (form) => {
            const {
                firstName,
                lastName,
                tags,
                userId,
                email,
                userType,
                learners
            } = form
            return userType === UserType.NEW
                ? {
                      email,
                      firstName,
                      lastName,
                      tags,
                      userId,
                      type: userType
                  }
                : {
                      type: userType,
                      learners: learners
                          .filter((item) => item?.isAdded)
                          .map((i) => i?.id)
                  }
        },
        initialValues: {
            id: '',
            learners: [],
            learnerListId: '',
            email: '',
            firstName: '',
            lastName: '',
            tags: [],
            userId: '',
            userType: UserType.NEW
        },
        yupResolverSchema: AddLearnerSchema,
        hasPrimaryExtraInput: true,
        modalConfig: {
            maxWidth: 'md',
            creationConfig: {
                primaryButtonText: t('general.add'),
                header: t('learner.addLearner.label')
            },
            componentProps: (props) => ({
                ...props
            }),
            Component: AddLearner
        }
    })

    return { onAddLearner }
}
