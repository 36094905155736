enum ReportMetricTypeEnum {
  Training = 'TRA',
  Course = 'COR',
  Survey = 'SUR',
  VideoInsights = 'VIN',
  CreatorInsights = 'CIN',
}

enum ReportMetricTypeGraphqlEnum {
  'TRA' = 'TRA',
  'COR' = 'COR',
  'SUR' = 'SUR',
  'VIN' = 'VIN',
  'CIN' = 'CIN',
}

export { ReportMetricTypeEnum, ReportMetricTypeGraphqlEnum }
