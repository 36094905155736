import { gql } from '@apollo/client'
import {
  MessagesFragment,
  MetaFragment,
  CampusPreferenceFragment,
} from '../../fragments'

export const GET_CAMPUS_PREFERENCE = gql`
  ${MessagesFragment('CampusPreferencesResponseDto')}
  ${MetaFragment('CampusPreferencesResponseDto')}
  ${CampusPreferenceFragment('CampusPreferencesDto')}

  query getCampusPreferences($campusId: String!, $clientId: String!) {
    getCampusPreferences(campusId: $campusId, clientId: $clientId) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...CampusPreferenceFragment
      }
    }
  }
`
