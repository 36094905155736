import { gql } from '@apollo/client'
import {
  MessagesFragment,
  MetaFragment,
  PreferenceSettingFragment,
} from '../../fragments'

export const GET_PREFERENCE_SETTING = gql`
  ${MetaFragment('PreferenceSettingDto')}
  ${MessagesFragment('PreferenceSettingDto')}
  ${PreferenceSettingFragment('PreferenceSetting')}
  query getPreferenceSetting {
    getPreferenceSetting {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...PreferenceSettingFragment
      }
    }
  }
`
