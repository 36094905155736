import React, { useState, useRef, useCallback } from 'react'
import {
    Menu,
    MenuItem,
    ListItemIcon,
    PopoverOrigin,
    Tooltip,
    Typography
} from '@mui/material'
import { ArrowDownIcon } from '@assets'
import { NestedMenuConfiguration, NestedMenuProps } from '@/types'

export const NestedMenu: React.FC<NestedMenuProps> = ({
    configuration,
    onClose,
    onSelect,
    selectedKey,
    ...others
}) => {
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(
        null
    )
    const [subMenuConfig, setSubMenuConfig] = useState<
        Array<NestedMenuConfiguration>
    >([])
    const [subMenuOrigin, setSubMenuOrigin] = useState<{
        anchorOrigin: PopoverOrigin
        transformOrigin: PopoverOrigin
    }>({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transformOrigin: { vertical: 'top', horizontal: 'left' }
    })
    const [openMenuItemKey, setOpenMenuItemKey] = useState<string | null>(null)

    const menuContainerRef = useRef<HTMLDivElement>(null)
    const subMenuContainerRef = useRef<HTMLDivElement>(null)
    const subMenuCloseTimer = useRef<number | null>(null)
    const [isSubMenuHovered, setIsSubMenuHovered] = useState(false)

    // Opens the submenu
    const handleSubMenuOpen = (
        event: React.MouseEvent<HTMLElement>,
        subMenu: Array<NestedMenuConfiguration>,
        key: string
    ) => {
        if (openMenuItemKey === key) {
            return // Submenu is already open, do not reopen
        }
        setOpenMenuItemKey(key)

        const buttonRect = event.currentTarget.getBoundingClientRect()
        const windowWidth = window.innerWidth

        // Adjust submenu origin based on screen width
        if (buttonRect.right + 200 > windowWidth) {
            setSubMenuOrigin({
                anchorOrigin: { vertical: 'top', horizontal: 'left' },
                transformOrigin: { vertical: 'top', horizontal: 'right' }
            })
        } else {
            setSubMenuOrigin({
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                transformOrigin: { vertical: 'top', horizontal: 'left' }
            })
        }

        setSubMenuAnchorEl(event.currentTarget)
        setSubMenuConfig(subMenu)
    }

    // Closes the submenu
    const handleSubMenuClose = useCallback(() => {
        setSubMenuAnchorEl(null)
        setSubMenuConfig([])
        setOpenMenuItemKey(null)
    }, [])

    // Handles item click, triggers onSelect and closes submenu
    const handleItemClick = (
        key: string,
        onClick?: (event: React.MouseEvent<HTMLElement>) => void
    ) => {
        onSelect(key)
        if (onClick) onClick(new MouseEvent('click'))
        handleSubMenuClose()
    }

    // Opens submenu on mouse enter
    const handleMouseEnter = (
        event: React.MouseEvent<HTMLElement>,
        subMenu: Array<NestedMenuConfiguration>,
        key: string
    ) => {
        if (subMenuCloseTimer.current) {
            clearTimeout(subMenuCloseTimer.current)
            subMenuCloseTimer.current = null
        }
        handleSubMenuOpen(event, subMenu, key)
    }

    // Sets a timer to close submenu on mouse leave
    const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
        subMenuCloseTimer.current = window.setTimeout(() => {
            if (!isSubMenuHovered) {
                handleSubMenuClose()
            }
        }, 600)
    }

    // Cancels close timer if submenu is hovered
    const handleSubMenuMouseEnter = () => {
        if (subMenuCloseTimer.current) {
            clearTimeout(subMenuCloseTimer.current)
            subMenuCloseTimer.current = null
        }
        setIsSubMenuHovered(true)
    }

    // Sets a timer to close submenu if not hovered
    const handleSubMenuMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
        setIsSubMenuHovered(false)
        if (!menuContainerRef.current?.contains(event.relatedTarget as Node)) {
            subMenuCloseTimer.current = window.setTimeout(() => {
                handleSubMenuClose()
            }, 300)
        }
    }

    // Renders a menu item with a tooltip
    const renderOption = (label: string) => (
        <Tooltip
            title={label}
            sx={{
                cursor: 'pointer'
            }}
        >
            <Typography
                variant='body2'
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}
            >
                {label}
            </Typography>
        </Tooltip>
    )

    // Maps over configuration to render menu items
    const menuItems = configuration?.map((config) => {
        if (!config.subMenu) {
            return (
                <MenuItem
                    key={config.key}
                    selected={config.key === selectedKey}
                    onClick={() => handleItemClick(config.key, config.onClick)}
                >
                    {renderOption(config.caption)}
                </MenuItem>
            )
        } else {
            return (
                <MenuItem
                    key={config.key}
                    onMouseEnter={(e) =>
                        handleMouseEnter(e, config.subMenu!, config.key)
                    }
                    onMouseLeave={handleMouseLeave}
                >
                    <span>{config.caption}</span>
                    <ListItemIcon sx={{ paddingLeft: 8 }}>
                        <ArrowDownIcon />
                    </ListItemIcon>
                </MenuItem>
            )
        }
    })

    return (
        <>
            <Menu
                {...others}
                onClose={() => {
                    handleSubMenuClose()
                    onClose()
                }}
                ref={menuContainerRef}
            >
                {menuItems}
            </Menu>

            {subMenuConfig.length > 0 && (
                <Menu
                    open={Boolean(subMenuAnchorEl)}
                    anchorEl={subMenuAnchorEl}
                    anchorOrigin={subMenuOrigin.anchorOrigin}
                    transformOrigin={subMenuOrigin.transformOrigin}
                    MenuListProps={{
                        onMouseEnter: handleSubMenuMouseEnter,
                        onMouseLeave: handleSubMenuMouseLeave,
                        ref: subMenuContainerRef
                    }}
                >
                    {subMenuConfig.map((config) => (
                        <MenuItem
                            key={config.key}
                            selected={config.key === selectedKey}
                            onClick={() =>
                                handleItemClick(config.key, config.onClick)
                            }
                        >
                            {renderOption(config.caption)}
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </>
    )
}

export default NestedMenu
