import React, { useState, useEffect, useMemo } from 'react'
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Tooltip, Typography } from '@mui/material'
import { NestedMenu } from '@components'
import { useGraphQLWrapper } from '@hooks'
import { GET_REPORT_LIBRARIES } from '@dts/graphql'
import { DropdownIcon } from '@assets'
import { NestedMenuConfiguration } from '@types'

// The component takes two props: a callback to get the selected library and the currently selected library ID.
export const NestedDropDownMenu: React.FC<{
    getSelectedLibrary: (key: string) => void
    selectedLibraryId: string
}> = ({ getSelectedLibrary, selectedLibraryId }) => {
    const { t } = useTranslation()

    const location = useLocation()
    const [searchParams] = useSearchParams()

    const libraryId = searchParams.get('libraryId')
    // Local state for selected item, selected path, and anchor element for the dropdown.
    const [selectedItem, setSelectedItem] = useState<string | null>(null)
    const [selectedPath, setSelectedPath] = useState<string>('')
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    // Custom hook for GraphQL query to fetch report libraries.
    const { queryLoading, currentData: reportLibraries } = useGraphQLWrapper({
        query: GET_REPORT_LIBRARIES,
        queryName: 'getAllReportLibraries',
        retrieveOnMount: true,
        queryVariables: { args: null }
    })

    // Memoized function to generate menu configuration based on fetched libraries.
    const menuConfiguration = useMemo(() => {
        const initialConfiguration: Array<NestedMenuConfiguration> = [
            {
                key: 'customLibrary',
                caption: t('general.customLibrary'),
                subMenu: []
            },
            {
                key: 'systemLibrary',
                caption: t('general.systemLibrary'),
                subMenu: []
            }
        ]

        reportLibraries?.forEach((library) => {
            const libraryMenu = {
                key: library.id,
                caption: library.title
            }

            if (library.isCustomLibrary === 'true') {
                initialConfiguration[0].subMenu.push(libraryMenu)
            } else {
                initialConfiguration[1].subMenu.push(libraryMenu)
            }
        })

        return initialConfiguration
    }, [reportLibraries, t])

    // Effect to handle the initial selection of the menu item based on URL parameter or default values.
    useEffect(() => {
        const initialSelectedId = libraryId

        if (initialSelectedId) {
            let found = false

            menuConfiguration.forEach((menu) => {
                menu.subMenu.forEach((subMenu) => {
                    if (subMenu.key === initialSelectedId) {
                        setSelectedItem(subMenu.key)
                        setSelectedPath(`${menu.caption} / ${subMenu.caption}`)
                        getSelectedLibrary(subMenu.key)
                        found = true
                    }
                })
            })

            if (!found) {
                const defaultItem = menuConfiguration[1].subMenu.length
                    ? menuConfiguration[1].subMenu[0]
                    : menuConfiguration[0].subMenu[0]
                if (defaultItem) {
                    setSelectedItem(defaultItem.key)
                    setSelectedPath(
                        `${t(
                            defaultItem.isCustomLibrary === 'true'
                                ? 'general.customLibrary'
                                : 'general.systemLibrary'
                        )} / ${defaultItem.caption}`
                    )
                    getSelectedLibrary(defaultItem.key)
                }
            }
        } else {
            const defaultItem = menuConfiguration[1].subMenu.length
                ? menuConfiguration[1].subMenu[0]
                : menuConfiguration[0].subMenu[0]
            if (defaultItem) {
                setSelectedItem(defaultItem.key)
                setSelectedPath(
                    `${t(
                        defaultItem.isCustomLibrary === 'true'
                            ? 'general.customLibrary'
                            : 'general.systemLibrary'
                    )} / ${defaultItem.caption}`
                )
                getSelectedLibrary(defaultItem.key)
            }
        }
    }, [libraryId, menuConfiguration])

    // Function to handle selection of a menu item.
    const handleSelect = (key: string) => {
        setSelectedItem(key)
        const path = findSelectedPath(menuConfiguration, key)
        setSelectedPath(path)
        getSelectedLibrary(key)
        setAnchorEl(null)
    }

    // Recursive function to find the full path of the selected item.
    const findSelectedPath = (
        configs: Array<NestedMenuConfiguration>,
        key: string
    ): string => {
        for (const config of configs) {
            if (config.key === key) {
                return config.caption
            }
            if (config.subMenu) {
                const nestedPath = findSelectedPath(config.subMenu, key)
                if (nestedPath !== '') {
                    return `${config.caption} / ${nestedPath}`
                }
            }
        }
        return ''
    }

    // Function to handle the button click to open the dropdown menu.
    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    // Function to handle closing of the menu.
    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    return (
        <div>
            <Button
                aria-controls='nested-menu'
                aria-haspopup='true'
                onClick={handleButtonClick}
                endIcon={<DropdownIcon />}
            >
                <Tooltip
                    title={selectedPath}
                    sx={{
                        cursor: 'pointer'
                    }}
                >
                    <Typography
                        variant='body2'
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {selectedPath
                            ? `${selectedPath?.slice(0, 35)}...`
                            : t('general.selectLibrary')}
                    </Typography>
                </Tooltip>
            </Button>
            <NestedMenu
                configuration={menuConfiguration}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                onSelect={handleSelect}
                selectedKey={selectedItem}
            />
        </div>
    )
}

export default NestedDropDownMenu
