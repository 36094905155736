import { MessagesFragment, MetaFragment } from '../../fragments'

import { gql } from '@apollo/client'

export const DELETE_TRAINING = gql`
  ${MessagesFragment('TrainingResponseDto')}
  ${MetaFragment('TrainingResponseDto')}
  mutation deleteTraining($id: String!) {
    deleteTraining(id: $id) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
      }
    }
  }
`
