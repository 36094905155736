import { gql } from '@apollo/client'
import { MessagesFragment, CampusFields, MetaFragment } from '../../fragments'

export const SAVE_CAMPUS = gql`
  ${MessagesFragment('CampusResponseDto')}
  ${MetaFragment('CampusResponseDto')}
  ${CampusFields('CampusDto')}
  mutation saveCampus($input: CampusInputDto!) {
    saveCampus(input: $input) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...CampusFields
      }
    }
  }
`
