import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import {
    ControlledCheckBox,
    ControlledDurationPicker,
    SearchableList,
    TextWithTooltip
} from '@components'
import { Grid, Typography } from '@mui/material'
import { useCustomTheme } from '@hooks'
import { can_access } from '@hoc'

export const TopicsRow: React.FC = ({
    item,
    index,
    updateData,
    isAdded,
    typeError,
    editComponentConfig,
    permissions
}) => {
    //  nestedAssignmentsOnly means course topics can only be assigned or unassigned within a course,
    //  no searching, addition,removal, completion time or title changes can be made
    const { theme } = useCustomTheme()
    const { t } = useTranslation()
    return (
        <Grid
            xs={12}
            md={12}
            item
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
        >
            <Grid
                item
                md={8.5}
                xs={8.5}
                pr={1}
                display={'flex'}
                alignItems={'center'}
            >
                {isAdded && (
                    <Grid item>
                        <ControlledCheckBox
                            key={item?.title}
                            name={`topics[${index}].isAssigned`}
                            onClick={() => {
                                item.isAssigned = !item?.isAssigned
                                updateData(index, item)
                            }}
                            ariaLabel={t('general.topics')}
                        />
                    </Grid>
                )}
                <Grid item>
                    <Typography variant='subtitle2'>
                        <TextWithTooltip
                            renderer={item?.title}
                            color={theme.palette.contentPrimary}
                        />
                    </Typography>
                </Grid>
            </Grid>
            {isAdded && !editComponentConfig?.nestedAssignmentsOnly && (
                <Grid item md={3.5} xs={3.5}>
                    <ControlledDurationPicker
                        name={`topics[${index}].duration`}
                        updateOnChange={updateData}
                        placeholder={'hrs:mins'}
                        item={item}
                        index={index}
                        typeError={typeError}
                        disabled={
                            permissions?.length > 0 && !can_access(permissions)
                        }
                    />
                </Grid>
            )}
        </Grid>
    )
}

export const SearchableTopicsList: React.FC = ({
    allTopics,
    editComponentConfig,
    permissions = []
}) => {
    const { t } = useTranslation()
    const { setValue, getValues, control } = useFormContext()
    const topicsAdded = useWatch({ control, name: 'topics' })

    const addedTopics = useWatch({
        control,
        name: 'addedTopics'
    })

    useEffect(() => {
        if (!topicsAdded?.length || !topicsAdded) {
            const topicsUpdated: Array<object> = []

            addedTopics?.forEach((topic) => {
                topicsUpdated.push({
                    id: topic?.topicId,
                    title: topic?.topic?.title,
                    isAdded: true,
                    isAssigned: topic?.isAssigned,
                    duration: topic?.duration
                })
            })
            allTopics?.forEach((topic) => {
                const titleExists = topicsUpdated.some(
                    (existingTopic) => existingTopic.id === topic?.id
                )

                if (!titleExists) {
                    topicsUpdated.push({
                        id: topic.id,
                        title: topic?.title,
                        isAdded: false,
                        isAssigned: false,
                        duration: null
                    })
                }
            })

            setValue('topics', topicsUpdated)
        }
    }, [allTopics, addedTopics])

    const { update: updateTopics, fields: topicFields } = useFieldArray({
        control,
        name: 'topics'
    })

    return (
        <SearchableList
            topicsData={topicsAdded}
            updateData={updateTopics}
            listType={'topics'}
            addedIn={'Course'}
            rowComponent={TopicsRow}
            showHeader={true}
            editComponentConfig={editComponentConfig}
            permissions={permissions}
        />
    )
}
