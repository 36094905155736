import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_CLIENT_ROLES = gql`
  ${MessagesFragment('RolesResponseDto')}
  ${MetaFragment('RolesResponseDto')}
  query getRoles($clientId: String) {
    getRoles(clientId: $clientId) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
        title
        roleId
        description
        createdAt
        updatedAt
        usersCount
      }
    }
  }
`
