import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, LearnerFields } from '../../fragments'

export const GET_LEARNERS = gql`
  ${MessagesFragment('GetMultipleUserResponseDto')}
  ${MetaFragment('GetMultipleUserResponseDto')}
  ${LearnerFields('UserDataDto')}
  query getLearners($learnerListId: String!) {
    getLearners(learnerListId: $learnerListId) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...LearnerFields
      }
    }
  }
`
