import { Button, ButtonGroup, Grid, styled } from '@mui/material'

export const StyledButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'isSelected'
})<{ isSelected: boolean }>(({ theme, isSelected }) => ({
    minWidth: '49px',
    height: '28px',
    color: isSelected
        ? theme.palette.contentPrimaryInverse
        : theme.palette.contentLink,
    background: isSelected
        ? theme.palette.backgroundBrandPrimary
        : theme.palette.BrandPrimaryContainer,
    '&:hover': {
        background: isSelected
            ? theme.palette.backgroundBrandPrimary
            : theme.palette.BrandPrimaryContainer
    }
}))
