import React from 'react'
import { Loader, UserProfileEdit } from '@components'
import { useAuth } from '@hooks'
import { getAccessRoles } from '@utils'
import { setUserData } from '@cacheql'
import {
    LearnerEditProfileContainer,
    ModifyAdminContainer,
    ModifyClientAdminContainer
} from '@containers'

export const UserProfileEditPage: React.FC = () => {
    const {
        userInfo: { roles }
    } = useAuth()

    const userId = setUserData()?.id

    const accessRoles = getAccessRoles(roles)

    return userId ? (
        accessRoles?.isAdmin ? (
            <ModifyAdminContainer userId={userId} />
        ) : accessRoles?.isContentCreator ? (
            <UserProfileEdit />
        ) : accessRoles?.isClientAdmin ? (
            <ModifyClientAdminContainer userId={userId} />
        ) : accessRoles?.isLearner ? (
            <LearnerEditProfileContainer userId={userId} />
        ) : (
            <Loader />
        )
    ) : (
        <Loader />
    )
}
