import React, { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid, Skeleton, Typography } from '@mui/material'
import { FileTypeEnum, useGraphQL, useS3 } from '@dts/client-utils'
import { UploadThumbnailIcon } from '@assets'
import { setUserData } from '@cacheql'

import {
    GET_VIDEOS_DROPDOWN_DATA,
    GET_VIDEO_KEY_FRAMES,
    KEY_FRAMES_READY_SUBSCRIPTION
} from '@dts/graphql'
import { useLazyQuery } from '@apollo/client'

import { Thumbnail, useCustomTheme } from '@hooks'
import { CustomLoader } from '@components'
import { StyledImage } from './styles'

export const KeyFrames = ({ name }: { name: string }) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const { setValue, watch, control } = useFormContext()
    const url = useWatch({ control, name })
    const keyFramesData = watch('keyFrames')
    const keyFramesSrcData = watch('keyFramesSrc')
    const coverImage = useWatch({ control, name: 'coverImage' })
    const videoUrl = useWatch({ control, name: 'url' })
    const [isUploadedImage, setIsUploadedImage] = useState(false)
    const [isUploadingStart, setIsUploadingStart] = useState(false)
    const [source, setSource] = useState('')
    const [videoId, setVideoId] = useState('')

    const [selectedKeyFrame, setSelectedKeyFrame] = useState(
        keyFramesSrcData?.[0]
    )

    const { uploadFile, key, s3Object } = useS3({})

    const [
        getVideoKeyFrames,
        {
            data: getVideoKeyFramesData,
            loading: getVideoKeyFramesLoading,
            error: getVideoKeyFramesError
        }
    ] = useLazyQuery(GET_VIDEO_KEY_FRAMES, {
        variables: {
            fetchPolicy: 'network-only'
        }
    })

    const { data } = useGraphQL({
        /**
         * Listening KEY_FRAMES_READY_SUBSCRIPTION backend event
         */
        query: GET_VIDEOS_DROPDOWN_DATA,
        queryName: 'getVideoDropDownsData',
        subscription: KEY_FRAMES_READY_SUBSCRIPTION,
        subscriptionVariables: {},
        updateQuery: (prev, subscriptionData) => {
            setVideoId(subscriptionData?.data.keyFramesReady?.videoId)
            return subscriptionData
        }
    })

    const fileSelect = async (e) => {
        e.preventDefault()
        const file: File = e.target.files[0]
        const fileUrl = URL.createObjectURL(file)
        const user = setUserData()
        if (fileUrl !== source) {
            setSource('')
            setIsUploadedImage(true)
            setIsUploadingStart(true)
            const res = await uploadFile(file, {
                userId: user?.id,
                type: FileTypeEnum.THUMBNAIL
            })
            if (res?.status === 200) {
                setValue('fileUrl', fileUrl)
                setSource(fileUrl)
                setIsUploadingStart(false)
            }
        }
    }
    useEffect(() => {
        if (key) setValue(name, key)
    }, [key])

    useEffect(() => {
        if (url && !key) {
            setSource(url)
        }
    }, [url])

    useEffect(() => {
        setValue('fileUploadKey', s3Object?.key)
    }, [s3Object])

    useEffect(() => {
        if (url && source) {
            setSelectedKeyFrame(source)
        }
        if (!url) {
            setSelectedKeyFrame(keyFramesSrcData?.[0])
        }
    }, [url, source, coverImage])

    useEffect(() => {
        /**
         * Adding uploaded img source in keyFrames and keyFramesSrc array
         */
        if (source?.length > 1) {
            const urlExist = keyFramesSrcData?.find(
                (item: string) => item === source
            )
            if (!urlExist && keyFramesSrcData) {
                setValue('keyFrames', [key, ...keyFramesData])
                setValue('keyFramesSrc', [source, ...keyFramesSrcData])
            }
            if (source?.length > 1 && !keyFramesSrcData) {
                setValue('keyFrames', [key])
                setValue('keyFramesSrc', [source])
            }
        }
    }, [source, coverImage, isUploadedImage, key])

    useEffect(() => {
        /**
         * This code is used getVideoKeyFrames
         */
        if (videoId && videoUrl) {
            getVideoKeyFrames({
                variables: {
                    videoId,
                    url: videoUrl
                }
            })
        }
    }, [videoId])

    useEffect(() => {
        /**
         * This code is used to map video keyFrames
         */
        if (getVideoKeyFramesData && !getVideoKeyFramesLoading) {
            const data = getVideoKeyFramesData?.getVideoKeyFrames?.data

            setValue('keyFrames', data?.keyFrames)
            setValue('keyFramesSrc', data?.keyFramesSrc)
            setSelectedKeyFrame(
                data?.keyFramesSrc?.length > 1
                    ? data?.keyFramesSrc
                    : data?.keyFramesSrc?.[0]
            )
        }
    }, [getVideoKeyFramesData, getVideoKeyFramesLoading])

    return (
        <Grid container gap={2} flexDirection={'row'}>
            <Grid item>
                <label htmlFor='file-upload'>
                    <Thumbnail container sx={{ width: '110px' }}>
                        {isUploadingStart ? (
                            <CustomLoader />
                        ) : (
                            <>
                                <Grid item xs={12}>
                                    <UploadThumbnailIcon
                                        color={theme.palette.contentSecondary}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant='body2'
                                        sx={{
                                            color: theme.palette.contentLink
                                        }}
                                    >
                                        {t('coverImage.upload.label')}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                    </Thumbnail>
                </label>

                <input
                    style={{ display: 'none' }}
                    type='file'
                    accept='.png,.jpg,.jpeg'
                    id={'file-upload'}
                    onChange={(e) => fileSelect(e)}
                />
            </Grid>
            {keyFramesSrcData?.map((item: string, index: number) => (
                <Thumbnail
                    width={'110px'}
                    selected={selectedKeyFrame === item}
                    onClick={() => {
                        setIsUploadedImage(false)
                        setSource(item)
                        setValue('coverImage', keyFramesData?.[index])
                        setValue(name, item)
                        setSelectedKeyFrame(item)
                    }}
                    key={`${item}${index}`}
                >
                    <StyledImage
                        src={item}
                        alt='thumbnail'
                        customOpacity={selectedKeyFrame === item ? 1 : 0.5}
                    />
                </Thumbnail>
            ))}
            {!source && !keyFramesSrcData && videoUrl ? (
                <>
                    {[1, 2, 3].map((item) => (
                        <Grid key={item} container sx={{ width: '110px' }}>
                            <Skeleton
                                variant='rectangular'
                                width={'100%'}
                                height={'100%'}
                                sx={{
                                    borderRadius: '6px',
                                    objectFit: 'contain'
                                }}
                            />
                        </Grid>
                    ))}
                </>
            ) : (
                <></>
            )}
        </Grid>
    )
}
