import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
    useRoutes,
    useModalWithGraphQL,
    useVideoConfig,
    useAuth,
    useCustomTheme,
    useGlobal
} from '@hooks'
import { VideoConfigType } from '@constants'
import { Link } from '@components'

import {
    AddCircleIcon,
    ChevronLeftIcon,
    ArrowRightIcon,
    AppLogo
} from '@assets'
import {
    Box,
    Divider,
    useMediaQuery,
    Grid,
    CssBaseline,
    Toolbar,
    IconButton,
    Drawer
} from '@mui/material'
import { can_access } from '@hoc'
import { SettingsToolbar } from './settings-toolbar'

import {
    StyledDrawerHeader,
    StyledDrawer,
    SideNavContainer,
    StyledAppBar,
    StyledBottomNavigation
} from './styles'

export const Layout = ({ children }) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const [open, setOpen] = React.useState(true)
    const { userData } = useAuth()
    const { centralRoutes, commonNavRoutes } = useRoutes()
    const { setIsInsideLayout } = useGlobal()
    const {
        userInfo: { isPortalUser }
    } = useAuth()

    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )

    const shouldCloseDrawer = useMediaQuery((theme) =>
        theme.breakpoints.down('1100')
    )

    const { videoConfig } = useVideoConfig({
        videoConfigType: VideoConfigType.Upload
    })
    const { openDialog } = useModalWithGraphQL(videoConfig)

    useEffect(() => {
        if (shouldCloseDrawer) {
            setOpen(false)
        } else {
            setOpen(true)
        }
    }, [shouldCloseDrawer])

    const showDrawer = () => {
        setOpen((prevState) => !prevState)
    }

    const uploadVideo = () => {
        openDialog({ isDraft: true })
    }

    useEffect(() => {
        setIsInsideLayout(true)

        return () => setIsInsideLayout(false)
    }, [])

    const menuCollapse = (
        <IconButton
            color='primary'
            aria-label='collapse drawer'
            onClick={showDrawer}
            edge='end'
        >
            <ArrowRightIcon />
        </IconButton>
    )

    const drawerContent = (
        <>
            <StyledDrawerHeader>
                <Grid container justifyContent={'space-between'}>
                    <Grid>
                        <img
                            src={AppLogo}
                            alt='Logo'
                            style={{
                                objectFit: 'contain',
                                width: open ? 'auto' : '40px'
                            }}
                        />
                    </Grid>
                    {open ? (
                        <IconButton
                            disableRipple
                            color='primary'
                            aria-label={t('general.collapse', {
                                name: t('general.drawer')
                            })}
                            onClick={showDrawer}
                            edge='end'
                            sx={{
                                marginRight: !open ? 5 : 1,
                                '&:hover': {
                                    backgroundColor: 'transparent'
                                },
                                '&:focus-visible': {
                                    background: theme.palette.backgroundPrimary
                                }
                            }}
                        >
                            <ChevronLeftIcon />
                        </IconButton>
                    ) : (
                        <IconButton
                            disableRipple
                            color='primary'
                            aria-label={t('general.expand', {
                                name: t('general.drawer')
                            })}
                            onClick={showDrawer}
                            edge='end'
                            sx={{
                                marginRight: !open ? 5 : 1,
                                '&:hover': {
                                    backgroundColor: 'transparent'
                                }
                            }}
                        >
                            <ArrowRightIcon />
                        </IconButton>
                    )}
                </Grid>
            </StyledDrawerHeader>
            <Divider />
            <SideNavContainer open={open}>
                {centralRoutes()?.map((route, index) =>
                    route?.isSideNav && can_access(route.permissions)
                        ? route?.canUserAccess && (
                              <Link
                                  list
                                  menu
                                  hasTooltip
                                  tooltipPlacement='right-end'
                                  route={route}
                                  onClick={() => isMediumScreen && showDrawer()}
                                  key={route?.path}
                                  iconColor={theme.palette.contentSecondary}
                                  selectedIconColor={
                                      theme.palette.contentPrimaryInverse
                                  }
                                  sx={{
                                      color: `${theme.palette.contentPrimary}`
                                  }}
                              />
                          )
                        : null
                )}
            </SideNavContainer>
        </>
    )

    return (
        <>
            {userData && (
                <Box display='flex' height='100%'>
                    <CssBaseline />

                    <StyledAppBar
                        position='fixed'
                        open={open}
                        sx={{ boxShadow: 0 }}
                    >
                        <Toolbar
                            sx={{
                                display: 'flex',
                                flexDirection: `${
                                    !isMediumScreen ? 'row-reverse' : 'row'
                                }`,
                                minHeight: isPortalUser
                                    ? '100px !Important'
                                    : '56px !Important',
                                backgroundColor: `${theme.palette.backgroundPrimary}`
                            }}
                        >
                            {isMediumScreen && menuCollapse}
                            {<SettingsToolbar />}
                        </Toolbar>

                        <Divider />
                    </StyledAppBar>

                    {!isMediumScreen ? (
                        <StyledDrawer variant={'permanent'} open={open}>
                            {drawerContent}
                        </StyledDrawer>
                    ) : (
                        <Drawer
                            open={open}
                            onClose={() => setOpen(false)}
                            sx={{
                                width: 250,
                                flexShrink: 0,
                                whiteSpace: 'nowrap',
                                boxSizing: 'border-box',
                                '.MuiPaper-root': {
                                    width: 250
                                }
                            }}
                        >
                            {drawerContent}
                        </Drawer>
                    )}
                    <Box
                        component='main'
                        id='content-container'
                        sx={{
                            width: '100%',
                            backgroundColor: `${theme.palette.backgroundPrimary}`,
                            height: '100%',
                            overflow: 'hidden',
                            overflowY: 'auto'
                        }}
                    >
                        <Box
                            sx={{
                                minHeight: isPortalUser
                                    ? '100px !Important'
                                    : '56px !Important'
                            }}
                        />
                        {children}
                        {isMediumScreen && <Box sx={{ minHeight: '60px' }} />}
                    </Box>
                </Box>
            )}

            {userData && isMediumScreen && (
                <StyledBottomNavigation>
                    {commonNavRoutes?.map((route, index) => (
                        <>
                            {index === commonNavRoutes.length / 2 && (
                                <IconButton
                                    onClick={uploadVideo}
                                    aria-label={t('general.add.ariaLabel', {
                                        ariaLabel: t('general.video')
                                    })}
                                >
                                    <AddCircleIcon
                                        color={
                                            theme.palette.backgroundBrandPrimary
                                        }
                                        fill={theme.palette.darkestBlue}
                                    />
                                </IconButton>
                            )}
                            {route?.isBottomNav && (
                                <Link
                                    list
                                    route={{
                                        ...route,
                                        label: '',
                                        ariaLabel: t(route.label)
                                    }}
                                    key={route?.path}
                                    iconColor={
                                        theme.palette.backgroundBrandPrimary
                                    }
                                    fillColor={theme.palette.darkestBlue}
                                    selectedIconColor={theme.palette.lightBlue}
                                    iconContainerSx={{ minWidth: 0 }}
                                    isBottomNav
                                    sx={{
                                        color: `${theme.palette.contentPrimary}`,
                                        display: 'flex !important',
                                        alignItems: 'center !important'
                                    }}
                                />
                            )}
                        </>
                    ))}
                </StyledBottomNavigation>
            )}
        </>
    )
}
