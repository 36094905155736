import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
    useCustomTheme,
    useGraphQLWrapper,
    useInfiniteScroll,
    usePageHeader
} from '@hooks'
import { isMediumScreen } from '@utils'
import { GET_FOLLOWING_ACCOUNTS, SAVE_FOLLOW_STATUS } from '@dts/graphql'
import { Avatar, Button, Divider, Grid } from '@mui/material'
import { StyledDirectoryLink } from '@containers'
import { getFullName } from '@dts/client-utils'
import { BackButtonType } from '@constants'
import { TextWithTooltip } from '@components'

export const FollowingAccounts = () => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const navigate = useNavigate()
    const { pageHeader } = usePageHeader({
        headerText: t('setting.following'),
        backButtonConfig: {
            showBackButton: isMediumScreen?.(),
            backButtonType: BackButtonType.Hierarchical
        }
    })

    const [followingAccounts, setFollowingAccounts] = useState<{
        [k: number]: boolean
    }>({})

    const { save: saveFollowStatus } = useGraphQLWrapper({
        mutation: SAVE_FOLLOW_STATUS,
        mutationName: 'saveFollowStatus',
        retrieveOnMount: false,
        isCustomMutationInput: true,
        onMutationSuccess: (response) => {
            setFollowingAccounts((prev) => ({
                ...prev,
                [response?.data?.followingId]: response?.data?.following
            }))
        }
    })

    const goToCreatorProfile = (id: string) =>
        navigate(`/creator-profile/${id}/all-video`)

    const loadComponent = (mergedData: Array<object>) =>
        mergedData?.map((data) => (
            <Grid
                container
                flexDirection={'column'}
                gap={2}
                p={'15px 20px 5px 20px'}
                key={data?.id}
            >
                <Grid
                    item
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                >
                    <Grid item display={'flex'} gap={3} alignItems={'center'}>
                        <Avatar
                            sx={{
                                border: `1px solid ${theme.palette.borderGrey}`,
                                '&:hover': {
                                    cursor: 'pointer'
                                }
                            }}
                            src={data?.urlSrc}
                            onClick={() => goToCreatorProfile(data?.id)}
                        />
                        <TextWithTooltip
                            renderer={getFullName(
                                data?.firstName,
                                data?.lastName
                            )}
                            variant='section'
                            onClick={() => goToCreatorProfile(data?.id)}
                            toolTip={getFullName(
                                data?.firstName,
                                data?.lastName
                            )}
                            isDirectoryLink
                        />
                    </Grid>

                    <Grid item>
                        <Button
                            variant={
                                followingAccounts?.[data?.id]
                                    ? 'contained'
                                    : 'outlined'
                            }
                            sx={{
                                borderRadius: '12px',
                                height: '32px'
                            }}
                            onClick={() => {
                                saveFollowStatus({
                                    input: {
                                        followingId: data?.id,
                                        following:
                                            !followingAccounts?.[data?.id]
                                    }
                                })
                            }}
                        >
                            {followingAccounts?.[data?.id]
                                ? t('setting.following')
                                : t('general.follow')}
                        </Button>
                    </Grid>
                </Grid>
                <Divider />
            </Grid>
        ))

    const { wrappedComponent, data } = useInfiniteScroll({
        query: GET_FOLLOWING_ACCOUNTS,
        queryName: 'getFollowingAccounts',
        loadComponent
    })

    useEffect(() => {
        if (data) {
            const followStatuses = {}

            data?.forEach((data) => {
                followStatuses[data?.id] = true
            })

            setFollowingAccounts(followStatuses)
        }
    }, [data])

    return (
        <>
            {pageHeader}
            {wrappedComponent}
        </>
    )
}
