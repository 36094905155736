import { gql } from '@apollo/client'

import {
  LearnerListDetailsFields,
  MessagesFragment,
  MetaFragment,
} from '../../fragments'

export const GET_LEARNER_LIST = gql`
  ${MessagesFragment('LearnerListResponse')}
  ${MetaFragment('LearnerListResponse')}
  ${LearnerListDetailsFields('LearnerList')}
  query getLearnerList($args: GetLearnerListArgs!
    $options: LearnerPaginationDto) {
    getLearnerList(args: $args, options: $options) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...LearnerListDetailsFields
      }
    }
  }
`
