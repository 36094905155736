import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const ViewsIcon: React.FC<SvgProps> = ({
    color,
    width = '28',
    height = '28'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.borderPrimary
    const _fill = color || theme.palette.backgroundPrimary

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 29 28'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M3.15746 14.8317C2.99858 14.5801 2.91913 14.4543 2.87466 14.2603C2.84126 14.1146 2.84126 13.8848 2.87466 13.739C2.91913 13.545 2.99858 13.4192 3.15746 13.1676C4.47044 11.0887 8.37862 5.83301 14.3345 5.83301C20.2903 5.83301 24.1985 11.0887 25.5115 13.1676C25.6703 13.4192 25.7498 13.545 25.7943 13.739C25.8277 13.8848 25.8277 14.1146 25.7943 14.2603C25.7498 14.4543 25.6703 14.5801 25.5115 14.8317C24.1985 16.9107 20.2903 22.1663 14.3345 22.1663C8.37862 22.1663 4.47044 16.9107 3.15746 14.8317Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.3345 17.4997C16.2675 17.4997 17.8345 15.9327 17.8345 13.9997C17.8345 12.0667 16.2675 10.4997 14.3345 10.4997C12.4015 10.4997 10.8345 12.0667 10.8345 13.9997C10.8345 15.9327 12.4015 17.4997 14.3345 17.4997Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default ViewsIcon
