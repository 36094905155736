import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, ReportFragment } from '../../fragments'

export const SAVE_REPORT = gql`
  ${MetaFragment('ReportResponseDto')}
  ${MessagesFragment('ReportResponseDto')}
  ${ReportFragment('ReportDto')}
  mutation SaveReport($input: ReportInputDto!) {
    saveReport(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...ReportFragment
      }
    }
  }
`
