import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'
import { Step, useStepper } from '@hooks'
import {
    TrainingCoursesSchema,
    TrainingDatesSchema,
    TrainingLearnerSchema
} from '@dts/client-utils'
import { BasicFieldsSchema, InitialDetails } from '@components'
import { GET_TRAINING, SAVE_TRAINING } from '@dts/graphql'
import { getAddedCourses } from '@containers'
import {
    DailyReminderOptionEnum,
    MonthlyReminderOptionEnum,
    NotificationFrequencyEnum
} from '@dts/constants/lib'

import { isEmpty } from 'lodash'
import {
    TrainingCourses,
    TrainingDates,
    TrainingReview,
    TrainingLearners
} from './training-creation'
import { UserPermissionEnum } from '@dts/constants'

export const CreateTraining: React.FC = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const navigate = useNavigate()

    const steps: Array<Step> = [
        {
            component: InitialDetails,
            label: t('general.name'),
            componentProps: () => ({
                header: t('general.about')
            }),
            schema: BasicFieldsSchema
        },
        {
            component: TrainingLearners,
            label: t('general.learners'),
            schema: TrainingLearnerSchema
        },
        {
            component: TrainingCourses,
            label: t('general.courses'),
            schema: TrainingCoursesSchema,
            preventDisable: true
        },
        {
            component: TrainingDates,
            label: t('general.dates'),
            schema: TrainingDatesSchema
        },
        {
            component: TrainingReview,
            label: t('general.review'),
            preventDisable: true
        }
    ]

    const mapInputToForm = (result) => {
        const {
            id,
            title,
            description,
            tags,
            isArchived,
            isDraft,
            startDate,
            startTime,
            dueDate,
            dueTime,
            assignedTags,
            excludedTag,
            enableNotifications,
            courses,
            learnerLists,
            remindersStartDate,
            remindersEndDate,
            reminders
        } = result?.data || {}

        return {
            id,
            name: title,
            description,
            tags,
            isArchived,
            isDraft,
            videoTags: tags,
            startDate,
            startTime,
            dueDate,
            dueTime,
            includedTag: assignedTags[0],
            excludedTag,
            learnerLists: learnerLists?.map((list) => list?.id),
            notificationFrequency: reminders?.notificationFrequency,
            dailyReminderOption: reminders?.daily,
            weekdays: reminders?.weekly?.reduce((acc, day) => {
                acc[day] = true
                return acc
            }, {}),
            monthlyReminderOption: reminders?.monthly?.reminderOption,
            monthDate: reminders?.monthly?.date,
            monthWeek: reminders?.monthly?.week,
            monthDay: reminders?.monthly?.day,
            enableReminderNotifications: enableNotifications,
            remindersStartDate,
            remindersEndDate,
            addedCourses: courses?.map((course) => ({
                ...course,
                courseTopics: course?.courseTopics?.map((courseTopic) => ({
                    ...courseTopic,
                    title: courseTopic?.topic?.title
                }))
            }))
        }
    }

    const updateTrainingConfig = id && {
        query: GET_TRAINING,
        queryName: 'getTraining',
        queryVariables: { id },
        mapInputToForm
    }

    const getReminderSettings = (formValues) => {
        const {
            notificationFrequency,
            dailyReminderOption,
            weekdays,
            monthlyReminderOption,
            monthDate,
            monthWeek,
            monthDay
        } = formValues || {}
        if (notificationFrequency === NotificationFrequencyEnum.Daily) {
            return { daily: dailyReminderOption }
        } else if (notificationFrequency === NotificationFrequencyEnum.Weekly) {
            return { weekly: Object.keys(weekdays) }
        } else if (
            notificationFrequency === NotificationFrequencyEnum.Monthly
        ) {
            if (
                monthlyReminderOption === MonthlyReminderOptionEnum.DateSpecific
            ) {
                return {
                    monthly: {
                        date: Number(monthDate),
                        reminderOption: monthlyReminderOption
                    }
                }
            } else {
                return {
                    monthly: {
                        week: monthWeek,
                        day: monthDay,
                        reminderOption: monthlyReminderOption
                    }
                }
            }
        }
    }

    const getMutationInput = (formValues) => {
        const {
            id,
            name,
            description,
            learnerLists,
            startTime,
            startDate,
            dueDate,
            dueTime,
            remindersStartDate,
            remindersEndDate,
            excludedTag,
            includedTag,
            notificationFrequency,
            videoTags,
            courses,
            isArchived,
            enableReminderNotifications,
            addedCourses
        } = formValues || {}

        const coursesAdded =
            courses && !isEmpty(courses)
                ? courses?.filter((course) => course.isAdded)
                : addedCourses?.map((course) => ({ id: course.id }))

        return {
            id: id ? id : undefined,
            title: name,
            description,
            startDate,
            startTime,
            dueDate,
            dueTime,
            isArchived: isArchived ?? false,
            assignedTags: includedTag ? [includedTag] : [],
            excludedTag,
            enableNotifications: enableReminderNotifications,
            courses: getAddedCourses(coursesAdded, addedCourses) ?? [],
            learnerLists,
            tags: videoTags,
            remindersEndDate,
            remindersStartDate,
            reminders: {
                notificationFrequency,
                ...getReminderSettings(formValues)
            }
        }
    }

    const mapFormToInput = (formValues) => ({
        ...getMutationInput(formValues),
        isDraft: false
    })

    const mapPreservedFormInputs = (response) => {
        const reminders = response?.data?.reminders

        return {
            ...(reminders?.notificationFrequency !==
                NotificationFrequencyEnum.Daily && {
                dailyReminderOption: DailyReminderOptionEnum.Everyday
            }),
            ...(reminders?.notificationFrequency !==
                NotificationFrequencyEnum.Monthly && {
                monthlyReminderOption: MonthlyReminderOptionEnum.DateSpecific
            }),
            ...(reminders?.notificationFrequency !==
                NotificationFrequencyEnum.Weekly && {
                weekdays: {}
            })
        }
    }
    const { stepper, jumpToLast } = useStepper({
        headerText: id
            ? [t('general.edit'), t('general.training')].join(' ')
            : [t('general.new'), t('general.training')].join(' '),
        steps,
        editMode: !!id,
        showBreadcrumbs: true,
        ...updateTrainingConfig,
        mutation: SAVE_TRAINING,
        mutationName: 'saveTraining',
        permissions: [UserPermissionEnum.TrainingsCreateModify],
        mapFormToInput,
        mapInputToForm,
        mapPreservedFormInputs,
        onMutationSuccess: () => {
            navigate('/trainings')
        },
        tertiaryButtonConfig: {
            title: t('general.saveDraft'),
            tertiaryButtonMapper: (formValues) => ({
                ...getMutationInput(formValues),
                isDraft: true
            }),
            schema: BasicFieldsSchema
        },
        initialValues: {
            name: null,
            description: null,
            learnerLists: [],
            startTime: '00:00',
            startDate: null,
            dueDate: null,
            dueTime: '00:00',
            reminderStartDate: null,
            remindersEndDate: null,
            excludedTag: null,
            includedTag: null,
            weekdays: {},
            notificationFrequency: NotificationFrequencyEnum.None,
            dailyReminderOption: DailyReminderOptionEnum.Everyday,
            monthlyReminderOption: MonthlyReminderOptionEnum.DateSpecific,
            monthDate: null,
            monthWeek: null,
            monthDay: null,
            enableReminderNotifications: false,
            videoTags: [],
            courses: []
        }
    })

    useEffect(() => {
        if (id) jumpToLast()
    }, [id])

    return <>{stepper}</>
}
