import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const PolicyIcon: React.FC<SvgProps> = ({
    height = '32',
    width = '28',
    color
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.royalBlue

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 28 32`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M27.5083 16.1189C27.4681 16.1746 27.4216 16.2282 27.3726 16.2768C27.265 16.3835 24.581 18.3546 24.581 18.3546C24.3922 18.589 24.7288 19.2781 24.8561 19.6428C24.8686 19.6809 24.8795 19.7148 24.887 19.7441C24.9532 19.9953 24.9628 20.2578 24.9172 20.5119C24.8556 20.8485 24.6957 21.1701 24.4387 21.4305C24.4353 21.4347 24.4328 21.4372 24.4286 21.4405L24.1297 21.7415L24.1247 21.7357L19.6513 17.2694L19.9523 16.9684C19.9557 16.965 19.959 16.9617 19.9628 16.9592C20.2257 16.7013 20.5493 16.5418 20.8863 16.4832C21.1379 16.4371 21.3975 16.4472 21.6454 16.5116C21.8434 16.5635 22.2386 16.7574 22.5731 16.8499C22.7808 16.9073 22.9658 16.9248 23.0646 16.8453C23.0646 16.8453 25.0725 14.0805 25.1228 14.0298C25.1722 13.9787 25.2257 13.9335 25.2814 13.8917C25.42 13.7857 25.572 13.7045 25.7311 13.6505C26.2871 13.46 26.9289 13.5864 27.3726 14.0298C27.9429 14.6004 27.9881 15.4955 27.5083 16.1189Z'
                fill={_color}
            />
            <path
                d='M23.6194 21.8118L22.9977 22.4323L18.9605 18.3989L19.5814 17.7781L23.6194 21.8118Z'
                fill={_color}
            />
            <path
                d='M25.7312 4.72993V12.7732C25.3892 12.8465 25.0589 12.9943 24.7667 13.2191C24.6716 13.2911 24.5896 13.3623 24.5167 13.4376C24.4774 13.477 24.433 13.5256 24.1248 13.9426V4.72993C24.1248 3.40989 23.0489 2.33561 21.7284 2.33561H4.75678C3.43466 2.33561 2.35953 3.40989 2.35953 4.72993V27.2693C2.35953 28.5898 3.43466 29.6637 4.75678 29.6637H21.7284C23.0489 29.6637 24.1248 28.5898 24.1248 27.2693V22.9379L24.132 22.9458L25.03 22.0424C25.3821 21.6899 25.6232 21.2519 25.7312 20.7692V27.2693C25.7312 29.4744 23.9352 31.2692 21.7284 31.2692H4.75678C2.54877 31.2692 0.752716 29.4744 0.752716 27.2693V4.72993C0.752716 2.52443 2.54877 0.730469 4.75678 0.730469H21.7284C23.9352 0.730469 25.7312 2.52443 25.7312 4.72993Z'
                fill={_color}
            />
            <path
                d='M5.48101 20.4939H13.7939V19.0906H5.48101V20.4939Z'
                fill={_color}
            />
            <path
                d='M13.7939 15.7298H5.48101V17.1339H13.7939V15.7298Z'
                fill={_color}
            />
            <path
                d='M5.48101 5.68466V7.08885H21.0033V5.68466H5.48101Z'
                fill={_color}
            />
            <path
                d='M5.48101 10.4314H21.0033V9.02806H5.48101V10.4314Z'
                fill={_color}
            />
            <path
                d='M5.48101 13.7752H21.0033V12.3714H5.48101V13.7752Z'
                fill={_color}
            />
            <path
                d='M20.2329 22.1561L17.5259 25.5418C17.4727 25.6079 17.3722 25.6067 17.3211 25.5393L15.7767 23.4874C15.6967 23.3815 15.811 23.2379 15.9329 23.2923L17.4308 23.9588L20.0541 21.9727C20.1751 21.881 20.328 22.0376 20.2329 22.1561ZM18.0061 20.4207C16.1585 20.4207 14.6551 21.9229 14.6559 23.7683C14.6559 25.6138 16.1589 27.1155 18.0069 27.1155C19.854 27.1147 21.3579 25.6138 21.3579 23.7675C21.357 21.922 19.8536 20.4207 18.0061 20.4207Z'
                fill={_color}
            />
        </svg>
    )
}
export default PolicyIcon
