export enum KeyboardShortcut {
    Enter = 'Enter',
    Backspace = 'Backspace',
    ESC = 'Escape'
}

export enum ListType {
    List = 'list',
    Tag = 'tag'
}
