import React from 'react'
import { AdminDashboard, ContentCreatorDashboard } from '@containers'
import { Loader } from '@components'
import { useAuth } from '@hooks'
import { getAccessRoles } from '@utils'
import { setUserData } from '@cacheql'

export const DashboardPage: React.FC = () => {
    const {
        userInfo: { roles }
    } = useAuth()

    const userId = setUserData()?.id
    const accessRoles = getAccessRoles(roles)

    return userId ? (
        accessRoles?.isContentCreator ? (
            <ContentCreatorDashboard />
        ) : (
            <AdminDashboard />
        )
    ) : (
        <Loader />
    )
}
