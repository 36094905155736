import React, { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { LinearProgress } from '@components'
import { formatRemainingTime } from '@dts/client-utils'
import { useCustomTheme } from '@hooks'
import { isInteger } from 'lodash'
import { ProgressCardProps } from './types'
import { StyledGrid } from './styles'

export const ProgressCard: FC<ProgressCardProps> = (props) => {
    const { theme } = useCustomTheme()
    const { courseTopics } = props || {}

    const progressBarColors = [
        theme.palette.secondaryPink,
        theme.palette.secondaryYellow,
        theme.palette.secondaryOrange,
        theme.palette.completedGreen
    ]
    return (
        <>
            {courseTopics?.map(
                (
                    { progress, remainingDuration, topic: { id, title } },
                    index
                ) => (
                    <StyledGrid key={id + index}>
                        <Grid
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                        >
                            <Typography
                                variant='body2'
                                fontSize={14}
                                fontWeight={500}
                                color={theme.palette.contentPrimary}
                                mb={1}
                            >
                                {title}
                            </Typography>

                            <Typography
                                variant='body2'
                                fontSize={13}
                                fontWeight={500}
                                color={theme.palette.contentLink}
                            >
                                {formatRemainingTime(
                                    remainingDuration as number
                                )}
                            </Typography>
                        </Grid>

                        <LinearProgress
                            height='6px'
                            value={
                                isInteger(progress)
                                    ? progress
                                    : progress?.toFixed(2)
                            }
                            barBackgroundColor={theme.palette.backgroundPrimary}
                            progressBackgroundColor={progressBarColors[index]}
                            hasTooltip
                        />
                    </StyledGrid>
                )
            )}
        </>
    )
}
