import { Quill } from 'react-quill'
import { VideoAttributes } from '../../types'

const BlockEmbed = Quill.import('blots/block/embed')

class Image extends BlockEmbed {
    static create({ id, src, imageWidth = 'auto', imageHeight = 'auto' }) {
        const node = super.create()

        node.setAttribute('data-img-id', id)
        node.style.position = 'relative'
        node.style.width = imageWidth
        node.style.height = imageHeight

        const img = document.createElement('img')
        img.setAttribute('src', src)
        img.style.width = '100%'
        img.style.height = '100%'
        node.appendChild(img)

        const resizeHandle = document.createElement('div')
        resizeHandle.style.position = 'absolute'
        resizeHandle.style.bottom = '0'
        resizeHandle.style.right = '0'
        resizeHandle.style.width = '20px'
        resizeHandle.style.height = '20px'
        resizeHandle.style.backgroundImage =
            "url(\"data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-maximize-2'><polyline points='15 3 21 3 21 9'></polyline><polyline points='9 21 3 21 3 15'></polyline><line x1='21' y1='3' x2='14' y2='10'></line><line x1='3' y1='21' x2='10' y2='14'></line></svg>\")"
        resizeHandle.style.backgroundSize = '100% 100%'
        resizeHandle.style.cursor = 'nwse-resize'
        node.appendChild(resizeHandle)

        this.addResizeListeners(node)

        return node
    }

    static value(node) {
        const img = node.querySelector('img')
        return {
            id: node.getAttribute('data-img-id'),
            src: img ? img.getAttribute('src') : null,
            width: node.style.width,
            height: node.style.height
        }
    }

    static addResizeListeners(node) {
        const resizeHandle = node.querySelector('div')
        resizeHandle.addEventListener('mousedown', function (e) {
            e.preventDefault()

            const startX = e.clientX
            const startY = e.clientY
            const startWidth = parseInt(
                document.defaultView.getComputedStyle(node).width,
                10
            )
            const startHeight = parseInt(
                document.defaultView.getComputedStyle(node).height,
                10
            )

            function doResize(e) {
                node.style.width = `${startWidth + e.clientX - startX}px`
                node.style.height = `${startHeight + e.clientY - startY}px`
            }

            function stopResize() {
                window.removeEventListener('mousemove', doResize)
                window.removeEventListener('mouseup', stopResize)
            }

            window.addEventListener('mousemove', doResize)
            window.addEventListener('mouseup', stopResize)
        })
    }
}

Image.blotName = 'image'
Image.tagName = 'div'
Image.className = 'img-container'

Quill.register('formats/image', Image)
