import { useFormContext, useWatch } from 'react-hook-form'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ArrowDownCircleIcon, ArrowUpCircleIcon } from '@assets'
import {
    ControlledMultiSelectAutoComplete,
    ControlledSelect,
    SearchableTags
} from '@components'
import {
    GET_QUESTION_FEEDBACK_TYPES,
    GET_SKILLS,
    GET_TOPICS
} from '@dts/graphql'
import { Grid, Typography } from '@mui/material'
import {
    QuestionTypeEnum,
    SurveyTypeEnum,
    UserPermissionEnum
} from '@dts/constants'
import { useCustomTheme, useGraphQLWrapper } from '@hooks'

import { mapDropdownOptions } from '@dts/client-utils'
import { startCase } from 'lodash'
import { surveyRecommendationTypes, TypographyVariants } from '@constants'
import { QUESTION_FEEDBACK_TYPES } from '@dts/constants/lib'
import { can_access } from '@hoc'
import { SurveyContentConfiguration } from './survey-content-configuration'
import { SummaryDetail } from './summary-detail'
import { MultiChoiceAnswerDetails } from './multi-answer-details'

export const QuestionConfiguration = ({ permissions }) => {
    const { theme } = useCustomTheme()
    const { t } = useTranslation()
    const { control } = useFormContext()
    const [searchParams] = useSearchParams()

    const surveyTypeId: SurveyTypeEnum = searchParams.get('surveyType') // Type of survey being created or opened

    const { data: skillsData } = useGraphQLWrapper({
        query: GET_SKILLS,
        queryName: 'getSkills',
        retrieveOnMount: true
    })

    const { currentData: topicsData } = useGraphQLWrapper({
        query: GET_TOPICS,
        queryName: 'getTopics',
        retrieveOnMount: true
    })

    const { currentData: feedbackData } = useGraphQLWrapper({
        query: GET_QUESTION_FEEDBACK_TYPES,
        queryName: 'getQuestionFeedbackTypes',
        retrieveOnMount: true
    })

    const selectedFeedback = useWatch({
        control,
        name: 'feedbackId'
    })

    const renderFeedbackComponent = () => {
        switch (selectedFeedback) {
            case QUESTION_FEEDBACK_TYPES.NoFeedback:
                return null
            case QUESTION_FEEDBACK_TYPES.CommonFeedback:
                return (
                    <SummaryDetail
                        selectName='commonFeedback.summaryResponseIconId'
                        titleName='commonFeedback.summaryTitle'
                        descriptionName='commonFeedback.summaryDescription'
                        permissions={permissions}
                    />
                )
            case QUESTION_FEEDBACK_TYPES.CorrectIncorrectFeedback:
                return (
                    <Grid pt={2}>
                        <MultiChoiceAnswerDetails
                            showIcon={false} //TODO: Name needed to be reconsidered
                            formName='correctFeedback'
                            permissions={permissions}
                        />
                    </Grid>
                )
            case QUESTION_FEEDBACK_TYPES.FeedbackAgainstAll:
                return (
                    <Typography
                        pl={1}
                        pt={2}
                        variant={TypographyVariants.h5}
                        color={theme.palette.contentSecondary}
                    >
                        {t('survey.allOptionsFeedback')}
                    </Typography>
                )
            case QUESTION_FEEDBACK_TYPES.FeedbackAgainstSelected:
                return (
                    <Typography
                        pl={1}
                        pt={2}
                        variant={TypographyVariants.h5}
                        color={theme.palette.contentSecondary}
                    >
                        {t('survey.selectedOptionsFeedback')}
                    </Typography>
                )
            default:
                return null
        }
    }

    return (
        <>
            <Grid container>
                {surveyTypeId !== SurveyTypeEnum.Pulse && (
                    <ControlledSelect
                        name={'topicId'}
                        id={'topicId'}
                        label={t('general.topic')}
                        marginTop={'1rem'}
                        disabled={
                            !can_access([UserPermissionEnum.SurveyCreateModify])
                        }
                        clearable
                        options={mapDropdownOptions(topicsData)}
                    />
                )}
            </Grid>
            <Grid container>
                <ControlledSelect
                    id={'skillId'}
                    name={'skillId'}
                    label={t('general.skill')}
                    clearable
                    disabled={
                        !can_access([UserPermissionEnum.SurveyCreateModify])
                    }
                    options={mapDropdownOptions(skillsData)}
                />
            </Grid>
            <Grid container>
                <ControlledSelect
                    id={'feedbackId'}
                    name={'feedbackId'}
                    label={t('general.feedback')}
                    clearable
                    disabled={
                        !can_access([UserPermissionEnum.SurveyCreateModify])
                    }
                    options={mapDropdownOptions(feedbackData)}
                />
            </Grid>

            <Grid pb={2}>{renderFeedbackComponent()}</Grid>
        </>
    )
}
