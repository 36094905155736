import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import MenuItem from '@mui/material/MenuItem'
import {
    FormControl,
    InputLabel,
    OutlinedInput,
    Select as MUISelect,
    Box,
    Grid,
    Typography,
    IconButton,
    Tooltip
} from '@mui/material'
import { SmartSelectProps } from '@types'
import { controlled } from '@hoc'
import { StyledChip } from '@components/Input'
import { useCustomTheme } from '@hooks'
import { CloseIcon } from '@assets'
import { CustomLoader } from '@components/CustomLoader'
import { injectAriaLabelToInnerInput } from '@utils'

export const Select: React.FC<SmartSelectProps> = ({
    onChange: onFormChange,
    name,
    label,
    options = [],
    value,
    id,
    ariaLabel = '',
    isMulti = false,
    hasChips = true,
    errors,
    preOpenMenu = false,
    clearable = false,
    sx,
    placeholder,
    height = 52,
    paddingTop = 9,
    width,
    disabled = false,
    marginTop = 2.5,
    isObject = false,
    loading,
    onClear,
    ...props
}) => {
    const { theme } = useCustomTheme()
    const [selectedValue, setSelectedValue] = useState(isMulti ? [] : null)
    const { t } = useTranslation()
    const getLabel = (val) => options.find((opt) => opt.value === val)?.label
    const [menuOpen, setMenuOpen] = useState(preOpenMenu) // State to control menu open/close
    const { formContext } = useFormContext()

    const handleMenuOpen = () => {
        setMenuOpen(true)
    }

    const handleMenuClose = () => {
        setMenuOpen(false)
    }

    useEffect(() => {
        injectAriaLabelToInnerInput(
            '.MuiSelect-nativeInput[aria-hidden="true"]',
            ariaLabel
        )
    }, [])

    useEffect(() => {
        setSelectedValue(value)
    }, [value])

    const renderOption = (label: string, isGreyedOut?: string) => (
        <Tooltip
            title={label}
            sx={{
                cursor: 'pointer'
            }}
        >
            <Typography
                variant='body2'
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: isGreyedOut && theme.palette.neutralsTextLight
                }}
            >
                {label}
            </Typography>
        </Tooltip>
    )

    return (
        <FormControl fullWidth sx={{ marginTop: marginTop, width }}>
            <InputLabel
                id={`${id}-label`}
                shrink={true}
                sx={{ color: errors ? theme.palette.errorDangerMedium : null }}
                aria-label={
                    label
                        ? t('general.select.fieldName', {
                              fieldName: label
                          })
                        : t('general.select.fieldName', {
                              fieldName: ariaLabel
                          })
                }
            >
                {label}
            </InputLabel>
            <MUISelect
                id={`${id}-select`}
                name={name}
                value={
                    value
                        ? isObject
                            ? value?.value
                            : value
                        : isMulti
                        ? []
                        : null
                }
                open={menuOpen}
                aria-label={t('general.select.fieldName', {
                    fieldName: ariaLabel
                })}
                onOpen={handleMenuOpen}
                onClose={handleMenuClose}
                multiple={isMulti}
                disabled={formContext?.formEditingState?.isReadOnly || disabled}
                placeholder={`${t('general.select')} ${label}`}
                input={<OutlinedInput notched label={label} />}
                IconComponent={
                    selectedValue && clearable
                        ? () => (
                              <Grid mr={1}>
                                  <IconButton
                                      aria-label={t('general.clear')}
                                      onClick={() => {
                                          onClear?.()
                                          onFormChange(null)
                                      }}
                                  >
                                      <CloseIcon height='18' width='18' />
                                  </IconButton>
                              </Grid>
                          )
                        : undefined
                }
                onChange={(e) => {
                    isObject
                        ? onFormChange(
                              options.find(
                                  (option) => option.value === e.target.value
                              )
                          )
                        : onFormChange(e.target.value as string)
                    handleMenuClose()
                }}
                sx={{
                    // TODO Remove Style
                    // '& .MuiSelect-icon': {
                    //     color: theme.palette.contentSecondary // Replace 'yourIconColor' with the desired color value
                    // },

                    background: theme.palette.backgroundPrimary,
                    borderRadius: '12px',
                    '& .MuiSelect-select .notranslate::after':
                        label || placeholder
                            ? {
                                  content: label
                                      ? `"${t('general.select')} ${label}"`
                                      : `"${placeholder}"`,
                                  opacity: 0.42
                              }
                            : {},
                    ...sx
                }}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: height * 4.5 + paddingTop,
                            maxWidth: 120
                        }
                    }
                }}
                {...(isMulti
                    ? {
                          renderValue: (selected: Array<OptionType>) => (
                              <Box
                                  sx={{
                                      size: 'small',
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      textWrap: 'wrap',
                                      gap: 0.5
                                  }}
                              >
                                  {selected?.map((selectedValue, index) =>
                                      hasChips ? (
                                          <StyledChip
                                              key={selectedValue}
                                              label={getLabel(selectedValue)}
                                          />
                                      ) : (
                                          getLabel(selectedValue) +
                                          (index !== selected.length - 1
                                              ? ', '
                                              : '')
                                      )
                                  )}
                              </Box>
                          )
                      }
                    : {})}
                error={!!errors || !!errors?.[name]?.message}
            >
                {options?.length ? (
                    options?.map((option) => {
                        const Icon = option?.icon
                        return (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                aria-label={option.label}
                                // TODO Remove Style
                                // sx={{ color: theme.palette.contentSecondary }}
                            >
                                {Icon ? (
                                    <Grid container alignItems={'center'}>
                                        <Grid
                                            item
                                            mr={1}
                                            display={'flex'}
                                            alignContent={'center'}
                                        >
                                            <Icon width={22} height={22} />
                                        </Grid>
                                        <Grid item>
                                            {renderOption(
                                                option?.label,
                                                option?.isGreyedOut
                                            )}
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <>
                                        {renderOption(
                                            option?.label,
                                            option?.isGreyedOut
                                        )}
                                    </>
                                )}
                            </MenuItem>
                        )
                    })
                ) : (
                    <Typography
                        aria-label={t('general.noRecordsFound')}
                        variant='body2'
                    >
                        {t('general.noRecordsFound')}
                    </Typography>
                )}
            </MUISelect>
        </FormControl>
    )
}
export const ControlledSelect = controlled(Select)
