import React, { FC } from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const CourseIcon: FC<SvgProps> = ({
    color,
    height = '24',
    width = '24',
    fill,
    fillOpacity
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary
    const _fill = fill || theme.palette.primaryBlue

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 24 24'
            fill='none'
        >
            <path
                d='M12.3935 2.13204C12.2492 2.05979 12.1771 2.02366 12.1014 2.00944C12.0344 1.99685 11.9656 1.99685 11.8986 2.00944C11.8229 2.02366 11.7508 2.05979 11.6065 2.13204L1 7.44263L11.6065 12.7532C11.7508 12.8255 11.8229 12.8616 11.8986 12.8758C11.9656 12.8884 12.0344 12.8884 12.1014 12.8758C12.1771 12.8616 12.2492 12.8255 12.3935 12.7532L23 7.44263L12.3935 2.13204Z'
                fill={_fill}
                fillOpacity={fillOpacity}
            />
            <path
                d='M16.4 14.0518V10.7411C16.4 10.5434 16.4 10.4445 16.3699 10.3572C16.3434 10.2801 16.3 10.2098 16.2429 10.1515C16.1784 10.0855 16.0901 10.0413 15.9135 9.95288L10.9 7.44263M4.85 9.3703V16.7664C4.85 17.1761 4.85 17.3809 4.91382 17.5603C4.97024 17.7188 5.06219 17.8623 5.18262 17.9798C5.31884 18.1126 5.5048 18.1979 5.87669 18.3686L11.2667 20.7736C11.5364 20.8974 11.6713 20.9593 11.8118 20.9838C11.9363 21.0054 12.0637 21.0054 12.1882 20.9838C12.3287 20.9593 12.4636 20.8974 12.7333 20.7736L19.7733 17.5425C20.1452 17.3718 19.7812 17.5618 19.9174 17.429C20.0378 17.3116 20.1298 17.1681 20.1862 17.0095C20.25 16.8302 20.25 16.6253 20.25 16.2157V8.81953M1 7.44263L11.6065 2.13204C11.7508 2.05979 11.8229 2.02366 11.8986 2.00944C11.9656 1.99685 12.0344 1.99685 12.1014 2.00944C12.1771 2.02366 12.2492 2.05979 12.3935 2.13204L23 7.44263L12.3935 12.7532C12.2492 12.8255 12.1771 12.8616 12.1014 12.8758C12.0344 12.8884 11.9656 12.8884 11.8986 12.8758C11.8229 12.8616 11.7508 12.8255 11.6065 12.7532L1 7.44263Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default CourseIcon
