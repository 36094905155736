import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const DraftIcon: React.FC<SvgProps> = ({
    color,
    width = 24,
    height = 24,
    fill
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.backgroundBrandPrimary
    const _fill = fill || 'none'
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox={`0 0 24 24`}
            fill='none'
        >
            <path
                d='M7.33396 13.1317C7.33396 12.6833 7.33396 12.4591 7.38462 12.2481C7.42953 12.0611 7.5036 11.8822 7.60412 11.7182C7.7175 11.5332 7.87604 11.3746 8.19312 11.0576L16.959 2.29171C17.7184 1.53232 18.9496 1.53232 19.709 2.29171C20.4684 3.0511 20.4684 4.28232 19.709 5.04171L10.9431 13.8076C10.6261 14.1246 10.4675 14.2832 10.2825 14.3966C10.1185 14.4971 9.93964 14.5711 9.75258 14.6161C9.54158 14.6667 9.31737 14.6667 8.86896 14.6667H7.33396V13.1317Z'
                fill={_fill}
                fillOpacity='0.1'
            />
            <path
                d='M10.084 3.66671H6.23398C4.69384 3.66671 3.92377 3.66671 3.33551 3.96644C2.81806 4.2301 2.39737 4.65079 2.13372 5.16824C1.83398 5.7565 1.83398 6.52657 1.83398 8.06671V15.7667C1.83398 17.3069 1.83398 18.0769 2.13372 18.6652C2.39737 19.1826 2.81806 19.6033 3.33551 19.867C3.92377 20.1667 4.69384 20.1667 6.23398 20.1667H13.934C15.4741 20.1667 16.2442 20.1667 16.8325 19.867C17.3499 19.6033 17.7706 19.1826 18.0343 18.6652C18.334 18.0769 18.334 17.3069 18.334 15.7667V11.9167M7.33396 14.6667H8.86896C9.31737 14.6667 9.54158 14.6667 9.75258 14.6161C9.93964 14.5711 10.1185 14.4971 10.2825 14.3966C10.4675 14.2832 10.6261 14.1246 10.9431 13.8076L19.709 5.04171C20.4684 4.28232 20.4684 3.0511 19.709 2.29171C18.9496 1.53232 17.7184 1.53232 16.959 2.29171L8.19312 11.0576C7.87604 11.3746 7.7175 11.5332 7.60412 11.7182C7.5036 11.8822 7.42953 12.0611 7.38462 12.2481C7.33396 12.4591 7.33396 12.6833 7.33396 13.1317V14.6667Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default DraftIcon
