import { setAccessToken, setUserData } from '@cacheql'

export const getAuthHeaders = () => {
    const accessToken =
        JSON.parse(window.localStorage.getItem('accessToken') as string) ??
        setAccessToken()
    const user =
        JSON.parse(window.localStorage.getItem('user-data') as string) ??
        setUserData()

    return {
        authorization: accessToken ? `Bearer ${accessToken}` : '',
        'client-id': user?.clientId
    }
}
