import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { formatDate, getFullName } from '@dts/client-utils'
import { UserInviteStatus } from '@constants'
import { StyledDirectoryLink } from '@containers/styles'
import { useCustomTheme, useDirectory, useGraphQLWrapper } from '@hooks'
import {
    ActiveIcon,
    AddIcon,
    CloseIcon,
    EditRecordIcon,
    HideIcon,
    UnHideIcon
} from '@assets'
import { Box, Grid, Typography } from '@mui/material'
import { StyledChip, TextWithTooltip } from '@components'
import {
    GET_LEARNER_LIST,
    DELETE_LEARNER_LIST_USER,
    UPDATE_USER
} from '@dts/graphql'
import { OperationVariables, QueryLazyOptions } from '@apollo/client'
import { getUrlSegmentTitleById } from '@utils'
import { useEditLearnerProfile } from '../learner-profile/edit-profile-modal'
import { useAddLearner } from './add-learner-modal'
import { LearnerListProps } from './types'
import { UserPermissionEnum } from '@dts/constants'

const handleRemoveLearner = (
    retrieve?: (
        _options?: QueryLazyOptions<OperationVariables> | undefined
    ) => void
) => {
    const deleteLearnerListUserConfig = {
        mutation: DELETE_LEARNER_LIST_USER,
        mutationName: 'deleteLearnerListUser',
        onMutationSuccess: (res) => {
            retrieve?.({
                variables: {
                    args: {
                        id: res?.data?.learnerListId
                    },
                    options: {
                        page: 1,
                        pageSize: 10
                    }
                }
            })
        }
    }

    const { save: deleteLearnerListUser } = useGraphQLWrapper({
        ...deleteLearnerListUserConfig
    })
    return { deleteLearnerListUser }
}

const handleUpdateLearner = (
    onMutationSuccess?: ((response?: unknown) => void) | undefined
) => {
    const updateLearnerConfig = {
        mutation: UPDATE_USER,
        mutationName: 'updateUser',
        onMutationSuccess
    }

    const { save: updateLearner } = useGraphQLWrapper({
        ...updateLearnerConfig
    })
    return { updateLearner }
}

export const LearnersContainer: FC<LearnerListProps> = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const { learnerListId } = useParams()
    const { state } = useLocation()

    const { directory, retrieve } = useDirectory({
        directoryConfig: {
            id: 'leanerList',
            query: GET_LEARNER_LIST,
            queryName: 'getLearnerList',
            accessor: 'learners',
            queryVariables: {
                args: {
                    id: learnerListId
                }
            },
            config: {
                customActions: [
                    {
                        icon: (
                            <EditRecordIcon
                                width={24}
                                height={24}
                                color={theme.palette.contentSecondary}
                            />
                        ),
                        label: t('general.edit'),
                        showInMenu: true,
                        action: (params) => editUserProfile({ id: params.id }),
                        permissions: [UserPermissionEnum.LearnersCreateModify]
                    },
                    {
                        getIcon: (params) =>
                            params?.row?.active ? (
                                <HideIcon
                                    width={24}
                                    height={24}
                                    color={theme.palette.contentSecondary}
                                />
                            ) : (
                                <UnHideIcon
                                    width={24}
                                    height={24}
                                    color={theme.palette.contentSecondary}
                                />
                            ),
                        getLabel: (params) =>
                            !params?.row?.active
                                ? t('general.makeActive')
                                : t('general.makeInactive'),
                        showInMenu: true,
                        action: (params) => {
                            updateLearner({
                                active: !params?.row?.active,
                                id: params?.id
                            })
                        },
                        permissions: [UserPermissionEnum.LearnersCreateModify]
                    },
                    {
                        icon: (
                            <CloseIcon
                                width={24}
                                height={24}
                                color={theme.palette.contentSecondary}
                            />
                        ),
                        label: t('general.removeFromList'),
                        showInMenu: true,
                        action: (params) => {
                            deleteLearnerListUser({
                                learnerListId,
                                userId: params?.id
                            })
                        },
                        permissions: [UserPermissionEnum.LearnersCreateModify]
                    }
                ],
                columns: [
                    {
                        field: 'status',
                        headerName: '',
                        maxWidth: 30,
                        flex: 1,
                        renderCell: (params) =>
                            !params?.row?.active ? (
                                <ActiveIcon
                                    color={theme.palette.errorDangerMedium}
                                />
                            ) : (!params?.row?.status ||
                                  params?.row?.status?.id ===
                                      UserInviteStatus.PENDING) &&
                              params?.row?.active ? (
                                <ActiveIcon color={theme.palette.yellow} />
                            ) : (
                                <ActiveIcon />
                            )
                    },
                    {
                        field: 'email',
                        headerName: t('general.email'),
                        minWidth: 100,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={
                                    <Box
                                        onClick={() =>
                                            navigate(
                                                `/learner-list/${learnerListId}/${params?.id}`
                                            )
                                        }
                                    >
                                        {params?.value ? params?.value : '--'}
                                    </Box>
                                }
                                isDirectoryLink
                                tabIndex={params.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'name',
                        headerName: t('general.name'),
                        renderCell: (params) => (
                            <Grid py={2.5}>
                                <TextWithTooltip
                                    renderer={getFullName(
                                        params?.row?.firstName,
                                        params?.row?.lastName
                                    )}
                                    tabIndex={params.tabIndex}
                                />
                            </Grid>
                        )
                    },
                    {
                        field: 'tags',
                        headerName: t('general.tags'),
                        minWidth: 300,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={
                                    <Grid item py={1}>
                                        {params?.value?.length
                                            ? params?.value?.map(
                                                  (learner: string) => (
                                                      <StyledChip
                                                          label={learner}
                                                          key={learner}
                                                          sx={{ mb: 1 }}
                                                      />
                                                  )
                                              )
                                            : '--'}
                                    </Grid>
                                }
                                tabIndex={params.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'updatedAt',
                        headerName: t('general.lastModified'),
                        minWidth: 100,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={formatDate(params?.value) ?? '--'}
                                tabIndex={params.tabIndex}
                            />
                        )
                    }
                ]
            },
            enableSearch: true,
            toolbarConfig: {
                header: getUrlSegmentTitleById(learnerListId) ?? '',
                showBreadcrumbs: true,
                fetchEntityVariables: learnerListId
                    ? { id: learnerListId, type: 'learnerList' }
                    : undefined,
                addNewButtonConfig: {
                    label: t('learner.addLearner.label'),
                    action: () => onAddLearner({ learnerListId }),
                    icon: AddIcon,
                    permissions: [UserPermissionEnum.LearnersCreateModify]
                }
            }
        }
    })

    const onMutationSuccess = () => {
        retrieve?.({
            variables: {
                args: {
                    id: learnerListId
                },
                options: {
                    page: 1,
                    pageSize: 10
                }
            }
        })
    }

    const { onAddLearner } = useAddLearner(onMutationSuccess)
    const { editUserProfile } = useEditLearnerProfile(onMutationSuccess)
    const { deleteLearnerListUser } = handleRemoveLearner(retrieve)
    const { updateLearner } = handleUpdateLearner(onMutationSuccess)

    return directory
}
export * from './add-learner-modal'
