import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { DocumentIcon, DownloadIcon } from '@assets'
import { useCustomTheme, useGlobal } from '@hooks'
import { Grid, Typography } from '@mui/material'
import { MetricCard } from '@components'
import { ButtonVariants, TypographyVariants } from '@constants'
import { SurveyTypeEnum } from '@dts/constants'
import {
    StyledButton,
    StyledButtonTypography,
    StyledIconButton,
    StyledMetricsCardsContainer,
    StyledMetricsContainer,
    StyledMetricsHeaderContainer
} from './styles'
import { ResultMetricsProps } from './types'

export const ResultMetrics: FC<ResultMetricsProps> = ({ title, metrics }) => {
    const { theme } = useCustomTheme()
    const { isMediumScreen } = useGlobal()
    const { t } = useTranslation()
    const downloadReport = () => {}

    const [searchParams] = useSearchParams()
    const surveyType = searchParams.get('surveyType')

    return (
        <StyledMetricsContainer container>
            <StyledMetricsHeaderContainer container item>
                <Grid item display={'flex'} gap={2}>
                    {!isMediumScreen && <DocumentIcon />}
                    <Typography variant={TypographyVariants.h1}>
                        {title}
                    </Typography>
                </Grid>

                <Grid item>
                    {isMediumScreen ? (
                        <StyledIconButton
                            onClick={downloadReport}
                            aria-label={t('general.add.ariaLabel', {
                                ariaLabel: t('general.download')
                            })}
                        >
                            <DownloadIcon
                                color={theme.palette.contentPrimaryInverse}
                            />
                        </StyledIconButton>
                    ) : (
                        <StyledButton
                            variant={ButtonVariants.Contained}
                            size='small'
                            onClick={() => downloadReport()}
                            aria-label={t('general.add.ariaLabel', {
                                ariaLabel: t('general.download')
                            })}
                            startIcon={
                                <DownloadIcon
                                    color={theme.palette.contentPrimaryInverse}
                                />
                            }
                        >
                            <StyledButtonTypography
                                variant={TypographyVariants.h5}
                            >
                                {t('general.download')}
                            </StyledButtonTypography>
                        </StyledButton>
                    )}
                </Grid>
            </StyledMetricsHeaderContainer>

            <StyledMetricsCardsContainer item container>
                {metrics?.map((metricCard) => {
                    if (
                        surveyType === SurveyTypeEnum.Pulse &&
                        (metricCard.title === t('surveyResults.assignee') ||
                            metricCard.title ===
                                t('surveyResults.totalResponses'))
                    ) {
                        return null
                    }

                    return (
                        <MetricCard
                            key={`${metricCard?.title}-${metricCard?.value}`}
                            value={metricCard?.value}
                            title={metricCard?.title}
                            Icon={metricCard?.Icon}
                        />
                    )
                })}
            </StyledMetricsCardsContainer>
        </StyledMetricsContainer>
    )
}
