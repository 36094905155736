import { gql } from '@apollo/client'

export const DELETE_VIDEO = gql`
  mutation deleteVideo($id: String!) {
    deleteVideo(id: $id) {
      message {
        type
        messages
      }
      data {
        id
      }
    }
  }
`
