import React from 'react'
import { Tooltip, Typography } from '@mui/material'
import { TextWithTooltipProps } from '@types'
import { useCustomTheme } from '@hooks'
import { displayTextWithEllipsis, onKeyDown } from '@utils'
import { TooltipPlacement } from '@constants'

export const TextWithTooltip: React.FC<TextWithTooltipProps> = ({
    isDirectoryLink = false,
    mt = 0,
    fontSize = '14px',
    fontWeight = isDirectoryLink ? '500' : '400',
    variant = isDirectoryLink ? 'section' : 'tCell',
    renderer = '',
    onClick = () => undefined,
    ellipsisLength = 50,
    toolTip = undefined,
    tabIndex = 0
}) => {
    const { theme } = useCustomTheme()
    const _color = isDirectoryLink
        ? theme.palette.contentLink
        : theme.palette.contentSecondary
    return (
        <Tooltip
            title={toolTip ?? renderer}
            placement={TooltipPlacement.topEnd}
        >
            <Typography
                tabIndex={tabIndex}
                mt={mt}
                fontSize={fontSize}
                fontWeight={fontWeight}
                color={_color}
                variant={variant}
                style={isDirectoryLink ? { cursor: 'pointer' } : {}}
                onClick={onClick}
                onKeyDown={(e) => {
                    onKeyDown(e, onClick, false)
                }}
                onkey
                sx={
                    isDirectoryLink
                        ? { '&:hover': { textDecoration: 'underline' } }
                        : {}
                }
            >
                {displayTextWithEllipsis(renderer as string, ellipsisLength)}
            </Typography>
        </Tooltip>
    )
}
