import React from 'react'

export const handleDragAndDrop = (
    results,
    initialList,
    subListAccessor,
    onReorder,
    droppableIdAccessor = 'id'
) => {
    const { source, destination, type } = results

    // If there's no destination, return early
    if (!destination) return

    // If the item is dropped in the same place, return early
    if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
    ) {
        return
    }

    // Handle reordering of list
    if (type === 'group') {
        const reorderedList = [...initialList]
        const [removedQuestion] = reorderedList.splice(source.index, 1)
        reorderedList.splice(destination.index, 0, removedQuestion)

        // Call the onReorder callback with the reordered list
        onReorder(reorderedList)
        return
    }

    // Handle reordering of sublist within the same list object
    const sourceItemIndex = initialList.findIndex(
        (item) => item[droppableIdAccessor] === source.droppableId
    )
    const destinationItemIndex = initialList.findIndex(
        (item) => item[droppableIdAccessor] === destination.droppableId
    )

    // Ensure sublist can only be reordered within the same list object
    if (sourceItemIndex === destinationItemIndex) {
        const newOptions = [...initialList[sourceItemIndex][subListAccessor]]
        const [movedOption] = newOptions.splice(source.index, 1)
        newOptions.splice(destination.index, 0, movedOption)

        const updatedList = [...initialList]
        updatedList[sourceItemIndex] = {
            ...initialList[sourceItemIndex],
            [subListAccessor]: newOptions
        }

        // Call the onReorder callback with the updated List
        onReorder(updatedList)
    }
}
