import { styled, Badge, Grid } from '@mui/material'

export const StyledBadge = styled(Badge)(() => ({
    cursor: 'pointer',
    '& span': {
        height: '24px',
        width: '24px',
        borderRadius: '50%'
    }
}))

export const LoaderContainer = styled(Grid)(({ theme }) => ({
    width: 90,
    height: 90,
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}))
