import { gql } from '@apollo/client'

export const UserInfo = (on: string) => gql`
    fragment UserInfo on ${on} {
        id
        firstName
        lastName
        email
        avatar
        avatarSrc
        title
        organization
        email_verified
        races {
            id
            title
        }
        genders {
            id
            title
        }
        dateOfBirth
        interests {
            id
            title
        }
        customInterests
        organization
    }
`
