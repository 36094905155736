import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import { ReadingIcon } from '@assets'
import { LinearProgress } from '@components'
import { useCustomTheme } from '@hooks'
import { CourseTrainingsCardProps } from './types'
import { StyledGrid } from './styles'

/**
 *
 * TODO: This component will be re-useable once the Overlay is added to the Learners directory/creation on smaller screen.
 * @returns
 */

export const CourseTrainingsCard: FC<CourseTrainingsCardProps> = (props) => {
    const {
        trainingData: { title, description, progress, courses },
        selectedTraining
    } = props || {}

    const { t } = useTranslation()
    const { theme } = useCustomTheme()

    return (
        <>
            <StyledGrid
                container
                flexDirection={'column'}
                selectedTraining={selectedTraining}
                gap={0.5}
            >
                <Grid item xs>
                    <Typography
                        variant='h4'
                        color={theme.palette.backgroundBrandPrimary}
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs my={1}>
                    <LinearProgress
                        value={progress}
                        showProgress={false}
                        barBackgroundColor={theme.palette.borderSecondary}
                        border='0px'
                        progressBackgroundColor={
                            theme.palette.backgroundBrandPrimary
                        }
                    />
                </Grid>
                {description && (
                    <Grid item xs>
                        <Typography
                            variant='subtitle2'
                            fontSize={12}
                            fontWeight={400}
                            color={theme.palette.contentSecondary}
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {description}
                        </Typography>
                    </Grid>
                )}
                <Grid item display={'flex'} alignItems={'center'} xs>
                    <ReadingIcon
                        width={18}
                        height={18}
                        color={theme.palette.contentSecondary}
                    />
                    <Typography
                        variant='subtitle2'
                        fontSize={12}
                        fontWeight={500}
                        color={theme.palette.contentPrimary}
                        ml={1}
                    >
                        {[courses?.length, t('general.courses')].join(' ')}
                    </Typography>
                </Grid>
            </StyledGrid>
        </>
    )
}
