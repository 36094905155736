import React, { FC, useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import {
    AccordionDetails,
    Grid,
    IconButton,
    Tooltip,
    Typography
} from '@mui/material'
import { CollapseIcon, DotMenuIcon, PencilIcon, ReOrderIcon } from '@assets'
import {
    ControlledTextInput,
    DropdownMenu,
    StyledErrorMessage
} from '@components'
import { DropdownMenuConfig, useCustomTheme } from '@hooks'
import { isEmpty, isUndefined, startCase } from 'lodash'

import { isMediumScreen } from '@utils'
import { AccessibleAction } from '@hoc'
import { StyledAccordion, StyledAccordionSummary, StyledBox } from './styles'
import {
    DefaultAccordionSummaryProps,
    PanelBasicFields,
    PanelProps
} from './types'

export const Panel: FC<PanelProps> = ({
    panels,
    isExpanded,
    currentStep,
    // scrollToLast = false,
    // showSubtitleOnCollapsed = false,
    // newlyOpenedItem,
    onChange,
    showExpandIcon = true,
    allowMultipleOpen = false, //allow to open multiple accordions at a time if true
    backgroundColor = undefined,
    enableReorder = false,
    isHeaderEditable = false,
    showActionsOnHover = false,
    preventBorderRadius = false,
    batchActions
}: PanelProps) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const [expanded, setExpanded] = useState(isExpanded)
    const [hoveredIndex, setHoveredIndex] = useState()
    const [expandedPanels, setExpandedPanels] = useState(
        allowMultipleOpen && isExpanded
            ? [isExpanded]
            : // : panels
              // ? panels?.map((panel) => panel.id)
              []
    )

    const mediumScreen = isMediumScreen()

    const handleChange = (panel: string) => (_1, isExpanded: boolean) => {
        if (allowMultipleOpen) {
            const changedPanel = panels?.find((p) => p?.id === panel)
            if (changedPanel) {
                const index = expandedPanels.indexOf(panel)
                if (index !== -1) {
                    setExpandedPanels(expandedPanels.filter((p) => p !== panel))
                } else {
                    setExpandedPanels([...expandedPanels, panel])
                }
            }
        } else {
            setExpanded(isExpanded ? panel : false)
        }
    }
    useEffect(() => {
        expanded && onChange?.(expanded)
    }, [expanded])

    useEffect(() => {
        currentStep && setExpanded(currentStep)
    }, [currentStep])

    useEffect(() => {
        isExpanded && setExpanded(isExpanded)
    }, [isExpanded])

    const renderBatchActions = (callbackData?: object) => {
        const batchActionsInMenu: Array<DropdownMenuConfig> = []
        const batchActionsOutsideMenu: Array<DropdownMenuConfig> = []
        // Show batchActions when the accordion is hovered on a large screen or when it is a medium screen
        const displayBatchActions: boolean = showActionsOnHover
            ? hoveredIndex === callbackData?.index || mediumScreen
            : true

        batchActions?.forEach((batchAction) => {
            const hideBatchAction = batchAction?.hide
                ? batchAction?.hide(callbackData)
                : false
            if (!hideBatchAction) {
                if (batchAction?.showInMenu) {
                    batchActionsInMenu.push(batchAction)
                } else if (!batchAction?.showInMenu) {
                    batchActionsOutsideMenu.push(batchAction)
                }
            }
        })

        return batchActions && displayBatchActions ? (
            <Grid
                item
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
            >
                {!isEmpty(batchActionsOutsideMenu) ? (
                    batchActionsOutsideMenu?.map((action) => (
                        <AccessibleAction
                            permissions={action?.permissions}
                            component={
                                action?.icon && (
                                    <Tooltip
                                        title={action?.label}
                                        placement='top-end'
                                    >
                                        <Grid
                                            onClick={() =>
                                                action?.onClick?.(callbackData)
                                            }
                                        >
                                            <IconButton
                                                aria-label={action.label}
                                            >
                                                <action.icon
                                                    height={20}
                                                    width={20}
                                                    color={
                                                        theme.palette
                                                            .contentTertiary
                                                    }
                                                />
                                            </IconButton>
                                        </Grid>
                                    </Tooltip>
                                )
                            }
                        />
                    ))
                ) : (
                    <></>
                )}
                {!isEmpty(batchActionsInMenu) ? (
                    <DropdownMenu
                        callbackData={callbackData}
                        menuConfig={batchActionsInMenu}
                        MenuButton={() => (
                            <IconButton>
                                <DotMenuIcon
                                    color={theme.palette.contentTertiary}
                                    height={20}
                                    width={20}
                                />
                            </IconButton>
                        )}
                    />
                ) : (
                    <></>
                )}
            </Grid>
        ) : (
            <></>
        )
    }

    const renderDefaultSummary = ({
        id,
        Icon,
        header,
        subHeader,
        helperText,
        editConfig,
        renderElementAtTitle,
        provided,
        hasError,
        errorKey,
        errorMessage,
        panelIndex
    }: DefaultAccordionSummaryProps) => {
        const {
            onBlur,
            onHeaderClick,
            defaultHeader,
            label,
            name,
            index,
            panel
        } = editConfig || {}

        const editProps = editConfig
            ? {
                  onClick: (event) => event.stopPropagation(),
                  onFocus: (event) => event.stopPropagation()
              }
            : {}

        const titleUpdated = editConfig ? panel?.titleUpdated : true
        const isEditing = editConfig ? panel?.isEditing : false

        return (
            <Grid container alignItems={'center'}>
                {provided ? (
                    <Grid item p={1}>
                        <AccessibleAction
                            permissions={editConfig?.permissions}
                            component={
                                <IconButton
                                    {...provided?.dragHandleProps}
                                    sx={{
                                        minWidth: 'auto'
                                    }}
                                >
                                    <ReOrderIcon
                                        height={20}
                                        width={20}
                                        color={theme.palette.contentTertiary}
                                    />
                                </IconButton>
                            }
                        />
                    </Grid>
                ) : (
                    <></>
                )}

                <Grid item xs>
                    {renderElementAtTitle ? (
                        renderElementAtTitle()
                    ) : (
                        <Grid container alignItems={'center'} {...editProps}>
                            {Icon && (
                                <Grid item mr={1}>
                                    <StyledBox>{Icon}</StyledBox>
                                </Grid>
                            )}

                            <Grid
                                item
                                container
                                width={'100%'}
                                justifyContent={'space-between'}
                            >
                                {/* <Grid item>
                        <StyledTypography
                            variant='h4'
                            position={'relative'}
                            fontSize={'16px'}
                            // sx={
                            //     !subtitle &&
                            //     (expanded ?? currentStep) !== id && {
                            //         position: 'relative'
                            //     }
                            // }
                        >
                            {header}
                        </StyledTypography>
                    </Grid> */}

                                <Grid
                                    item
                                    container
                                    flexDirection={'column'}
                                    xs
                                >
                                    {subHeader && (
                                        <Grid item>
                                            <Typography variant='caption'>
                                                {panel?.questionTypeTitle}
                                            </Typography>
                                        </Grid>
                                    )}

                                    <AccessibleAction
                                        permissions={editConfig?.permissions}
                                        component={
                                            <Grid item xs>
                                                {isEditing ? (
                                                    <ControlledTextInput
                                                        name={name}
                                                        id={id}
                                                        label={label}
                                                        height={'40px'}
                                                        sx={{
                                                            marginTop: '0px'
                                                        }}
                                                        onBlur={(e) => {
                                                            onBlur?.(e)
                                                        }}
                                                        autoFocus
                                                        errorKey={errorKey}
                                                    />
                                                ) : (
                                                    <Grid
                                                        container
                                                        gap={1}
                                                        alignItems={'center'}
                                                        onClick={(e) => {
                                                            if (editConfig) {
                                                                e.stopPropagation()
                                                                onHeaderClick?.(
                                                                    e
                                                                )
                                                            }
                                                        }}
                                                    >
                                                        <Grid item>
                                                            <Grid
                                                                container
                                                                flexDirection={
                                                                    'column'
                                                                }
                                                            >
                                                                <Typography
                                                                    variant='body1'
                                                                    // To-do: Re-consider this condition
                                                                    fontWeight={
                                                                        titleUpdated &&
                                                                        !isUndefined(
                                                                            header
                                                                        ) &&
                                                                        !isEmpty(
                                                                            header
                                                                        )
                                                                            ? 600
                                                                            : 400
                                                                    }
                                                                    style={{
                                                                        color:
                                                                            titleUpdated &&
                                                                            !isUndefined(
                                                                                header
                                                                            ) &&
                                                                            !isEmpty(
                                                                                header
                                                                            )
                                                                                ? theme
                                                                                      .palette
                                                                                      .contentPrimary
                                                                                : theme
                                                                                      .palette
                                                                                      .contentTertiary
                                                                    }}
                                                                >
                                                                    {header ||
                                                                        defaultHeader}
                                                                </Typography>
                                                                {errorMessage && (
                                                                    <StyledErrorMessage
                                                                        color={
                                                                            theme
                                                                                ?.palette
                                                                                ?.contentNegative
                                                                        }
                                                                    >
                                                                        {t(
                                                                            errorMessage,
                                                                            {
                                                                                value: startCase(
                                                                                    errorKey
                                                                                )
                                                                            }
                                                                        )}
                                                                    </StyledErrorMessage>
                                                                )}
                                                            </Grid>
                                                        </Grid>

                                                        {isHeaderEditable && (
                                                            <IconButton
                                                                aria-label={t(
                                                                    'general.edit'
                                                                )}
                                                            >
                                                                <PencilIcon
                                                                    color={
                                                                        theme
                                                                            .palette
                                                                            .contentTertiary
                                                                    }
                                                                    height={18}
                                                                    width={18}
                                                                />
                                                            </IconButton>
                                                        )}
                                                    </Grid>
                                                )}
                                            </Grid>
                                        }
                                        elseComponent={
                                            <Grid item alignItems={'center'}>
                                                <Typography
                                                    variant='body1'
                                                    // To-do: Re-consider this condition
                                                    fontWeight={
                                                        titleUpdated &&
                                                        !isUndefined(header) &&
                                                        !isEmpty(header)
                                                            ? 600
                                                            : 400
                                                    }
                                                    style={{
                                                        color:
                                                            titleUpdated &&
                                                            !isUndefined(
                                                                header
                                                            ) &&
                                                            !isEmpty(header)
                                                                ? theme.palette
                                                                      .contentPrimary
                                                                : theme.palette
                                                                      .contentTertiary
                                                    }}
                                                >
                                                    {header || defaultHeader}
                                                </Typography>
                                            </Grid>
                                        }
                                    />
                                </Grid>
                                {renderBatchActions({ index, panel })}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        )
    }

    const getStyledAccordion = (props) => {
        const {
            id,
            subtitle,
            accordionSummaryProps,
            body,
            bodySx,
            renderElementAtTitle,
            index,
            provided,
            hasError
        } = props || {}

        return (
            <StyledAccordion
                id={`accordian-${id}`}
                // To-do: Discuss with relevant developer
                {...(panels[panels.length - 1]?.id === id &&
                    !allowMultipleOpen &&
                    {
                        // borderRadius: '0px 0px 25px 25px'
                    })}
                expanded={
                    !allowMultipleOpen
                        ? (expanded ?? currentStep) === id
                        : expandedPanels?.includes(id)
                }
                onChange={handleChange(id)}
                hasError={hasError}
                preventBorderRadius={preventBorderRadius}
                backgroundColor={backgroundColor}
                key={index + id}
                onMouseEnter={() => setHoveredIndex(index)} // Show Grid on hover
                onMouseLeave={() => setHoveredIndex(null)} // Hide Grid when not hovering
                // sx={{
                //     '&:before': {
                //         height: '0px' // Or you can use 'unset' or 'none' depending on your need
                //     },
                //     '& .css-fnfdl0-MuiGrid-root': {
                //         color: `${theme.palette.contentPrimary} !important` // change text color for select metric checkBox
                //     },
                //     marginBottom: '4px'
                // }}
                {...provided?.draggableProps}
                ref={provided?.innerRef}
            >
                <StyledAccordionSummary
                    allowMultipleOpen={allowMultipleOpen}
                    defaultSelected={currentStep === id}
                    backgroundColor={backgroundColor}
                    {...(index === 0 &&
                        !allowMultipleOpen && {
                            borderRadius: '10px 10px 0px 0px'
                        })}
                    enableReorder={enableReorder}
                    // To-do: Not being used
                    {...(showExpandIcon && {
                        expandIcon: <CollapseIcon />
                    })}
                    hasError={hasError}
                    aria-controls={`panel-${id}`}
                    expanded={expanded ?? currentStep}
                    expandedPanels={expandedPanels}
                    panelId={id}
                    subtitle={subtitle}
                    id={`panel-${id}`}
                    {...(accordionSummaryProps?.sx
                        ? {
                              sx: {
                                  ...accordionSummaryProps.sx
                              }
                          }
                        : {
                              sx: {
                                  flexDirection: 'row'
                              }
                          })}
                >
                    <Grid
                        container
                        alignItems={
                            !renderElementAtTitle ? 'flex-start' : 'center'
                        }
                        flexDirection={renderElementAtTitle ? 'row' : 'column'}
                    >
                        {provided
                            ? renderDefaultSummary({
                                  ...props,
                                  provided
                              })
                            : renderDefaultSummary({
                                  ...props
                              })}

                        {/* {subtitle &&
                            (allowMultipleOpen ? (
                                !expandedPanels?.includes(id)
                            ) : showSubtitleOnCollapsed ? (
                                <Grid item>
                                    <Typography
                                        variant='body2'
                                        color={theme.palette.primaryGrey}
                                    >
                                        {truncateString(subtitle, 130)}
                                    </Typography>
                                </Grid>
                            ) : (
                                (expanded ?? currentStep) !== id
                            )) && (
                                <Grid item>
                                    {dangerouslySetInnerHTML ? (
                                        <Typography
                                            variant='body2'
                                            color={theme.palette.primaryGrey}
                                            dangerouslySetInnerHTML={{
                                                __html: truncateString(
                                                    subtitle,
                                                    130
                                                ).replace(/<\/?p>/g, '')
                                            }}
                                        />
                                    ) : (
                                        <Typography
                                            variant='body2'
                                            color={theme.palette.primaryGrey}
                                        >
                                            {truncateString(subtitle, 130)}
                                        </Typography>
                                    )}
                                </Grid>
                            )} */}
                    </Grid>
                </StyledAccordionSummary>
                {(!allowMultipleOpen
                    ? expanded === id
                    : expandedPanels?.includes(id)) && (
                    <AccordionDetails
                        id={`detail-${id}`}
                        sx={{
                            paddingX: 0,
                            paddingY: 1,
                            ...bodySx,
                            borderBottomLeftRadius: preventBorderRadius
                                ? 0
                                : '12px !important',
                            borderBottomRightRadius: preventBorderRadius
                                ? 0
                                : '12px !important',
                            // Commented code in this file is to be re-considered
                            // border: `1px solid ${theme.palette.borderSecondary}`,
                            borderTop: `1px solid ${
                                hasError
                                    ? theme?.palette?.borderNegative
                                    : theme.palette.borderSecondary
                            }`,
                            background: theme.palette.backgroundPrimary
                        }}
                    >
                        {body}
                    </AccordionDetails>
                )}
                {provided?.placeholder}
            </StyledAccordion>
        )
    }

    return (
        <>
            {panels?.map((props: PanelBasicFields, index) => {
                const { id } = props || {}
                return enableReorder ? (
                    <Draggable draggableId={id} key={id} index={index}>
                        {(provided) =>
                            getStyledAccordion?.({
                                ...props,
                                index,
                                provided
                            })
                        }
                    </Draggable>
                ) : (
                    getStyledAccordion?.({
                        ...props,
                        index
                    })
                )
            })}
        </>
    )
}
