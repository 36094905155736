import React from 'react'
import { SvgProps } from '@types'

const ReviewVideoIcon: React.FC<SvgProps> = ({
    color = '#35383E',
    width = '24',
    height = '24'
}) => (
    <svg
        width={width}
        height={height}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M7.8 21C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8M10.7326 15.7076L15.4532 12.6729C15.8016 12.449 15.9758 12.337 16.0359 12.1946C16.0885 12.0702 16.0885 11.9298 16.0359 11.8054C15.9758 11.663 15.8016 11.551 15.4532 11.3271L10.7326 8.29239C10.3311 8.03429 10.1304 7.90525 9.96438 7.9171C9.81971 7.92744 9.68666 8.00007 9.59974 8.11618C9.5 8.24941 9.5 8.48805 9.5 8.96533V15.0347C9.5 15.512 9.5 15.7506 9.59974 15.8838C9.68666 15.9999 9.81971 16.0726 9.96438 16.0829C10.1304 16.0948 10.3311 15.9657 10.7326 15.7076Z'
            stroke={color}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M12.4051 19.4749C12.4404 19.2984 12.4581 19.2101 12.4904 19.1278C12.519 19.0547 12.5562 18.9853 12.6011 18.9209C12.6517 18.8484 12.7153 18.7847 12.8426 18.6574L19.5001 11.9999C20.0524 11.4476 20.9478 11.4476 21.5002 11.9999C22.0525 12.5522 22.0525 13.4477 21.5002 14L14.8427 20.6574C14.7154 20.7847 14.6517 20.8484 14.5792 20.8989C14.5148 20.9438 14.4453 20.981 14.3723 21.0097C14.2899 21.042 14.2017 21.0596 14.0251 21.0949L12 21.4999L12.4051 19.4749Z'
            stroke={color}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
)

export default ReviewVideoIcon
