import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const CHANGE_PASSWORD = gql`
  ${MetaFragment('GeneralResponseDto')}
  ${MessagesFragment('GeneralResponseDto')}
  mutation changePassword {
    changePassword {
      ...MetaFragment
      ...MessagesFragment
    }
  }
`
