import { gql } from '@apollo/client'
import { disableFragmentWarnings } from 'graphql-tag'

disableFragmentWarnings()

export const MessagesFragment = (on: string) => gql`
    fragment MessagesFragment on ${on} {
        message {
            type
            messages
            toast
          }
}
`
