import React from 'react'
import { Grid, Typography } from '@mui/material'
import { MuiCard } from '@components'
import { useCustomTheme } from '@hooks'

export type LearnCardProps = {
    title: string
    description: string
    icon: JSX.Element
    onClick?: VoidFunction
}

export const LearnCard: React.FC<LearnCardProps> = ({
    title,
    description,
    icon,
    onClick
}) => {
    const { theme } = useCustomTheme()
    const content = (
        <Grid container spacing={2} alignItems={'center'}>
            <Grid item>{icon}</Grid>
            <Grid item xs={8} sm={10}>
                <Grid
                    item
                    sx={{ display: 'flex', alignItems: 'center' }}
                    pb={1}
                >
                    <Typography variant='h2'>{title}</Typography>
                </Grid>
                <Grid item>
                    <Typography sx={{ whiteSpace: 'pre-line' }} variant='body3'>
                        {description}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )

    return (
        <MuiCard
            onClick={onClick}
            content={content}
            title={title ?? ''}
            sx={{ background: theme.palette.backgroundSecondary }}
        />
    )
}
