import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const MobileIcon: React.FC<SvgProps> = ({
    color,
    width = '16',
    height = '17'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 16 17'
            fill='none'
        >
            <path
                d='M11.3333 1.83398H4.66659C3.93021 1.83398 3.33325 2.43094 3.33325 3.16732V13.834C3.33325 14.5704 3.93021 15.1673 4.66659 15.1673H11.3333C12.0696 15.1673 12.6666 14.5704 12.6666 13.834V3.16732C12.6666 2.43094 12.0696 1.83398 11.3333 1.83398Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M8 12.5H8.00666'
                stroke={_color}
                strokeWidth='1.8'
                strokeLinecap='round'
            />
        </svg>
    )
}
export default MobileIcon
