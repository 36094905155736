import React from 'react'
import { ListItem } from '@components'
import { Grid, List, Typography } from '@mui/material'
import { DetailTableProps } from './types'

export const DetailTable: React.FC<DetailTableProps> = ({
    title,
    details,
    labelSx,
    valueSx
}) => (
    <Grid item xs={12}>
        {title && <Typography variant='h3'>{title}</Typography>}
        <List>
            {details.map(({ label, value }, index) => (
                <ListItem
                    key={index}
                    label={label}
                    value={value}
                    id={label}
                    borderBottom={index === details.length - 1 ? false : true}
                    labelSx={labelSx}
                    valueSx={valueSx}
                />
            ))}
        </List>
    </Grid>
)
