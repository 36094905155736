import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isSmallScreen } from '@utils'
import { mapDropdownOptions } from '@dts/client-utils'
import { Grid, Typography } from '@mui/material'
import {
    useGraphQLWrapper,
    useLoader,
    useModalWithGraphQL,
    usePageHeader
} from '@hooks'
import { GET_USER_BY_ID, DELETE_USER, GET_USER } from '@dts/graphql'
import {
    ActivityFeed,
    ConfirmationDialog,
    LearnerInfo,
    Loader,
    Select,
    TrainingsCard
} from '@components'
import { InitialValuesLearnerProfile, LearnerProfileProps } from './types'
import {
    CoursesGrid,
    StyledTrainingGrid,
    TrainingAndCoursesGrid
} from './styles'
import { activityFeedData } from './data'
import { useEditLearnerProfile } from './edit-profile-modal'
import { CoursesContainer } from './courses-container'

export const LearnerProfileContainer = (props) => {
    const { learnerId, learnerListId } = useParams()
    const { isLearner } = props || {}
    const { t } = useTranslation()
    const { loading } = useLoader()

    const navigate = useNavigate()
    const smallScreen = isSmallScreen()

    const { pageHeader } = usePageHeader({
        headerText: t('general.learner'),
        showBreadcrumbs: true,
        fetchEntityVariables: learnerListId
            ? { id: learnerListId, type: 'learnerList' }
            : undefined
    })

    const {
        data: learnerData,
        queryLoading,
        retrieve
    } = useGraphQLWrapper({
        query: learnerId ? GET_USER_BY_ID : GET_USER,
        queryName: learnerId ? 'getUserById' : 'getUser',
        queryVariables: learnerId
            ? {
                  id: learnerId
              }
            : {}
    })
    const onMutationSuccess = () => {
        retrieve?.({
            variables: {
                id: learnerId
            }
        })
    }

    const { editUserProfile: onEditProfile } =
        useEditLearnerProfile(onMutationSuccess)

    const [selectedTraining, setSelectedTraining] = useState()
    const [leanerProfileData, setLeanerProfileData] =
        useState<LearnerProfileProps>(InitialValuesLearnerProfile)

    const handleTrainingSelection = (trainingId: string | number) => {
        const selectedTraining = learnerData?.data?.trainings.find(
            (training) => training.id === trainingId
        )
        if (selectedTraining) {
            setSelectedTraining(selectedTraining)
        }
    }

    const { openConfirmationDialog: onDeleteProfile } = useModalWithGraphQL({
        mutation: DELETE_USER,
        mutationName: 'deleteUser',
        isCustomMutationInput: true,
        mapFormToMutationInput: () => ({ id: learnerId }),
        onMutationSuccess: () => navigate('/learner-list'),
        modalConfig: {
            confirmationConfig: {
                primaryButtonText: t('general.delete'),
                header: t('learners.delete')
            },
            Component: () => (
                <ConfirmationDialog
                    message={t('learners.delete.confirmation')}
                />
            )
        }
    })

    useEffect(() => {
        if (learnerData) {
            const data = learnerData?.data
            const newLeanerProfileData = {
                avatarSrc: data?.avatarSrc,
                email: data?.email,
                firstName: data?.firstName,
                lastName: data?.lastName,
                createdAt: data?.createdAt,
                tags: data?.tags,
                trainings: data?.trainings?.map(
                    (item: { title: string }) => item?.title
                ),
                learnerId: data?.userId,
                status: data?.status,
                active: data?.active
            }

            setLeanerProfileData(newLeanerProfileData)
            setSelectedTraining(learnerData?.data?.trainings?.[0])
        }
    }, [learnerData, queryLoading])

    return (
        <>
            {queryLoading && <Loader />}
            {pageHeader}
            <Grid minHeight={'85vh'} m={2}>
                <LearnerInfo
                    onEditProfile={() => onEditProfile({ id: learnerId })}
                    onDeleteProfile={() => onDeleteProfile()}
                    leanerProfileData={leanerProfileData}
                    isLearner={isLearner}
                />
                <Grid container spacing={2} mt={2} minHeight={'52vh'}>
                    <Grid item xs={12} md={8}>
                        {learnerData?.data?.trainings?.length ? (
                            <TrainingAndCoursesGrid
                                container
                                minHeight={'100%'}
                            >
                                <StyledTrainingGrid
                                    item
                                    xs={12}
                                    md={3}
                                    mb={smallScreen ? 2 : 0}
                                >
                                    {smallScreen ? (
                                        <Select
                                            onChange={(val: string) =>
                                                handleTrainingSelection(val)
                                            }
                                            id='trainingCourses'
                                            label={t('general.training')}
                                            options={mapDropdownOptions(
                                                learnerData?.data?.trainings
                                            )}
                                            value={`${selectedTraining?.id}`}
                                            height={30}
                                        />
                                    ) : (
                                        <TrainingsCard
                                            trainingData={
                                                learnerData?.data?.trainings
                                            }
                                            selectedTraining={selectedTraining}
                                            setSelectedTraining={
                                                setSelectedTraining
                                            }
                                        />
                                    )}
                                </StyledTrainingGrid>
                                <CoursesGrid item xs={12} md={9}>
                                    <CoursesContainer
                                        selectedTraining={selectedTraining}
                                    />
                                </CoursesGrid>
                            </TrainingAndCoursesGrid>
                        ) : (
                            <Typography variant='h4' align='center'>
                                {t('general.notFound', {
                                    label: t('general.trainings')
                                })}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <ActivityFeed activityFeedData={activityFeedData} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
