import React from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { FieldErrors } from '@components'
import { Grid } from '@mui/material'

export type Controlled<T, P extends string = 'value'> = Omit<
    T,
    'onChange' | P
> & {
    name: string
    borderColor?: string
    disabled?: string
    errorPosition?: boolean
    errorKey?: string
}

export const controlled =
    <T extends object, P extends string = 'value'>(
        Component: React.FC<T>,
        valueProp?: P
    ): React.FC<Controlled<T, P>> =>
    ({ name, borderColor, disabled, errorKey = undefined, ...props }) => {
        const formContext = useFormContext()

        const {
            field: { value, onChange, onBlur },
            fieldState: { error }
        } = useController({
            name
        })

        return (
            <Grid container flexDirection={'column'}>
                <Component
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    errors={error}
                    disabled={
                        formContext?.formEditingState?.isReadOnly || disabled
                    }
                    // style={{
                    //     ...(error?.message ? { border: 'red solid 1px' } : {})
                    // }}
                    {...(props as T)}
                    {...(valueProp ? { [valueProp]: value } : { value })}
                />

                <FieldErrors
                    error={error}
                    errorPosition={props?.errorPosition}
                    name={name}
                    errorKey={errorKey}
                />
            </Grid>
        )
    }
