import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import {
    BaseContainer,
    ComponentContainer,
    ControlledCheckBox,
    ControlledDatePicker,
    ControlledMultipleCheckboxes,
    ControlledRadioGroup,
    ControlledSelect,
    ControlledTextInput,
    ControlledTimePicker,
    ParentContainer,
    SiblingComponentContainer,
    TypographyContainer
} from '@components'
import { Grid, Typography } from '@mui/material'
import {} from './styles'
import { mapDropdownOptions } from '@dts/client-utils'
import {
    MonthlyReminderOptionEnum,
    NotificationFrequencyEnum
} from '@dts/constants/lib'
import { isMediumScreen, isSmallScreen } from '@utils'
import { useCustomTheme } from '@hooks'
import {
    dailyReminderOptionsData,
    daysData,
    monthWeeksData,
    monthlyReminderOptionsData,
    notificationFrequenciesData
} from './training-reminders-data'

export const TrainingDates: React.FC = () => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const { control, getValues, setValue, trigger } = useFormContext()

    const mediumScreen = isMediumScreen?.()
    const smallScreen = isSmallScreen?.()

    const notificationFrequency = useWatch({
        name: 'notificationFrequency',
        control
    })
    const monthlyReminderOption = useWatch({
        name: 'monthlyReminderOption',
        control
    })

    useEffect(() => {
        if (notificationFrequency === NotificationFrequencyEnum.None) {
            setValue('remindersStartDate', null)
            setValue('remindersEndDate', null)
            trigger('remindersStartDate')
            trigger('remindersEndDate')
        }
    }, [notificationFrequency])

    return (
        <ParentContainer container>
            <Typography variant='h1'>{getValues('name')}</Typography>
            <BaseContainer item>
                <TypographyContainer item md={2}>
                    <Typography variant='h3'>
                        {[t('general.training'), t('general.dates')].join(' ')}
                    </Typography>
                    <Typography variant='body2Medium'>
                        {t('trainings.chooseDates')}
                    </Typography>
                </TypographyContainer>
                <ComponentContainer md={12} xs={12}>
                    <Grid container flexDirection={'column'}>
                        <SiblingComponentContainer item md={6} xs={10}>
                            <ControlledDatePicker
                                id='startDate'
                                label={[
                                    t('general.start'),
                                    t('general.date')
                                ].join(' ')}
                                name='startDate'
                                width='100%'
                                isDisableFuture={false}
                            />
                            <ControlledTimePicker
                                id='startTime'
                                name='startTime'
                                label={t('general.time')}
                                width='100%'
                            />
                        </SiblingComponentContainer>

                        <SiblingComponentContainer item md={6} xs={10}>
                            <ControlledDatePicker
                                id='dueDate'
                                label={[
                                    t('general.due'),
                                    t('general.date')
                                ].join(' ')}
                                name='dueDate'
                                width='100%'
                                isDisableFuture={false}
                            />
                            <ControlledTimePicker
                                id='dueTime'
                                name='dueTime'
                                label={t('general.time')}
                                width='100%'
                            />
                        </SiblingComponentContainer>
                    </Grid>
                </ComponentContainer>
            </BaseContainer>
            <BaseContainer item>
                <TypographyContainer item md={2}>
                    <Typography variant='h3'>
                        {t('general.reminders')}
                    </Typography>
                    <Typography variant='body2Medium'>
                        {t('trainings.setReminder')}
                    </Typography>
                </TypographyContainer>

                <ComponentContainer item md={12} xs={12}>
                    <Grid container flexDirection={'column'} gap={3}>
                        <Typography
                            variant='h5'
                            sx={{ fontWeight: 600, fontSize: '14px' }}
                            pt={2}
                        >
                            {t('trainings.configureFrequency')}
                        </Typography>
                        <Grid
                            container
                            flexDirection={mediumScreen ? 'column' : 'row'}
                            sx={{
                                minHeight: '250px',
                                overflow: 'auto',
                                width: '100%'
                            }}
                            md={12}
                            xs={12}
                        >
                            <Grid
                                item
                                md={smallScreen ? 3 : 2.3}
                                xs={10}
                                p={2}
                                sx={{
                                    border: `1px solid ${theme.palette.backgroundTertiary}`,
                                    borderTopLeftRadius: '12px',
                                    borderBottomLeftRadius: '12px'
                                    /* Will be removed later on
                                     borderTopLeftRadius: '8px',
                                     borderBottomLeftRadius: '8px'*/
                                }}
                            >
                                <ControlledRadioGroup
                                    name='notificationFrequency'
                                    options={mapDropdownOptions(
                                        notificationFrequenciesData
                                    )}
                                    hasColumnDirection={!mediumScreen}
                                />
                            </Grid>
                            <Grid
                                item
                                md={7}
                                xs={10}
                                p={2}
                                sx={{
                                    border: `1px solid ${theme.palette.backgroundTertiary}`,
                                    borderLeft: !isMediumScreen?.() && 'none',
                                    borderTopRightRadius: '12px',
                                    borderBottomRightRadius: '12px',
                                    /* Will be removed later on
                                     borderTopRightRadius: '8px',
                                     borderBottomRightRadius: '8px',
                                    */
                                    minHeight: '250px',
                                    overflow: 'auto',
                                    width: '100%'
                                }}
                            >
                                {notificationFrequency ===
                                NotificationFrequencyEnum.Daily ? (
                                    <ControlledRadioGroup
                                        name='dailyReminderOption'
                                        options={mapDropdownOptions(
                                            dailyReminderOptionsData
                                        )}
                                        hasColumnDirection
                                    />
                                ) : notificationFrequency ===
                                  NotificationFrequencyEnum.Weekly ? (
                                    <ControlledMultipleCheckboxes
                                        name='weekdays'
                                        styles={{ md: smallScreen ? 12 : 4 }}
                                        options={mapDropdownOptions(daysData)}
                                    />
                                ) : notificationFrequency ===
                                  NotificationFrequencyEnum.Monthly ? (
                                    <Grid container flexDirection={'column'}>
                                        <Grid item>
                                            <ControlledRadioGroup
                                                name='monthlyReminderOption'
                                                options={mapDropdownOptions(
                                                    monthlyReminderOptionsData
                                                )}
                                            />
                                        </Grid>
                                        {monthlyReminderOption ===
                                        MonthlyReminderOptionEnum.DateSpecific ? (
                                            <Grid item>
                                                <ControlledTextInput
                                                    name='monthDate'
                                                    id='monthDate'
                                                    label={t('general.date')}
                                                    ariaLabel={t(
                                                        'general.date'
                                                    )}
                                                    width='100px'
                                                    placeholder={t(
                                                        'general.date'
                                                    )}
                                                />
                                            </Grid>
                                        ) : (
                                            <>
                                                <ControlledSelect
                                                    name='monthWeek'
                                                    options={mapDropdownOptions(
                                                        monthWeeksData
                                                    )}
                                                    label={t('general.every')}
                                                    ariaLabel={t(
                                                        'general.every'
                                                    )}
                                                    placeholder=''
                                                    width={
                                                        smallScreen
                                                            ? '100%'
                                                            : '80%'
                                                    }
                                                />
                                                <ControlledSelect
                                                    name='monthDay'
                                                    options={mapDropdownOptions(
                                                        daysData
                                                    )}
                                                    label={t('general.weekday')}
                                                    ariaLabel={t(
                                                        'general.weekday'
                                                    )}
                                                    width={
                                                        smallScreen
                                                            ? '100%'
                                                            : '80%'
                                                    }
                                                />
                                            </>
                                        )}
                                    </Grid>
                                ) : (
                                    <Typography variant='section2'>
                                        {t('trainings.noReminders')}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        <SiblingComponentContainer item md={6} xs={10}>
                            <ControlledDatePicker
                                id='remindersEndDate'
                                label={[
                                    t('general.date'),
                                    t('general.from')
                                ].join(' ')}
                                name='remindersEndDate'
                                width='100%'
                                disabled={
                                    notificationFrequency ===
                                    NotificationFrequencyEnum.None
                                }
                                isDisableFuture={false}
                            />
                            <ControlledDatePicker
                                id='remindersStartDate'
                                label={[
                                    t('general.date'),
                                    t('general.to')
                                ].join(' ')}
                                name='remindersStartDate'
                                width='100%'
                                disabled={
                                    notificationFrequency ===
                                    NotificationFrequencyEnum.None
                                }
                                isDisableFuture={false}
                            />
                        </SiblingComponentContainer>

                        <ControlledCheckBox
                            name='enableReminderNotifications'
                            label={t('trainings.enableNotifications')}
                            disabled={
                                notificationFrequency ===
                                NotificationFrequencyEnum.None
                            }
                        />
                    </Grid>
                </ComponentContainer>
            </BaseContainer>
        </ParentContainer>
    )
}
