import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const PreAndPostSurveyIcon: React.FC<SvgProps> = ({
    color,
    width = 22,
    height = 22
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentPrimary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 22 22'
            fill='none'
        >
            <line
                x1='3.5'
                y1='9.33301'
                x2='19.4167'
                y2='9.33301'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <line
                x1='3.5'
                y1='5.66699'
                x2='10.25'
                y2='5.66699'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <line
                x1='11.75'
                y1='16.667'
                x2='19.4167'
                y2='16.667'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <line
                x1='3.5'
                y1='13'
                x2='19.4167'
                y2='13'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default PreAndPostSurveyIcon
