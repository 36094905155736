import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_QUESTION_TYPES = gql`
  ${MessagesFragment('BaseRefResponseDto')}
  ${MetaFragment('BaseRefResponseDto')}
  query getQuestionTypes {
    getQuestionTypes {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
        title
        available
        displayOrder
      }
    }
  }
`
