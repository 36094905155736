import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, AdminTopics } from '../../fragments'

export const GET_ADMIN_TOPICS = gql`
  ${MessagesFragment('TopicsResponseDto')}
  ${MetaFragment('TopicsResponseDto')}
  ${AdminTopics('TopicDto')}
  query getAdminTopics(
    $args: AdminTopicsArgumentsDto!
    $options: PaginationDto!
  ) {
    getAdminTopics(args: $args, options: $options) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...AdminTopics
      }
    }
  }
`
