import React from 'react'
import { Navigate } from 'react-router-dom'
import { Signup } from '@components'
import { useAuth } from '@hooks'

export const SignupPage: React.FC = () => {
    const { firstLogin } = useAuth()

    return firstLogin ? <Signup /> : <Navigate to={'/'} />
}
