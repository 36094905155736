import React from 'react'
import { DescriptionIcon, PlayIcon, SettingsIcon, UserIcon } from '@assets'
import { ManageReports } from '@containers'
import { ReportingPage, ReportsLayout, RouteLayout } from '@pages'
import { ObjectBaseFields } from '@types'
import { getAccessRoles } from '@utils'
import { useCustomTheme } from '@hooks'
import { REPORT_LIBRARY } from '@constants'
import { UserPermissionEnum } from '@dts/constants'

export const getReportRoutes = (roles: Array<ObjectBaseFields>) => {
    const accessedRoles = getAccessRoles(roles)
    const { isClientAdmin, isClientUser } = accessedRoles
    const { theme } = useCustomTheme()

    return [
        {
            path: '',
            component: ManageReports,
            componentProps: { status: REPORT_LIBRARY.SYSTEM },
            canUserAccess: isClientAdmin || isClientUser,
            permissions: [UserPermissionEnum.ReportView]
        },
        {
            path: 'system',
            component: ManageReports,
            componentProps: { status: REPORT_LIBRARY.SYSTEM },
            isLanding: 'reports',
            label: 'System Reports',
            isHeaderLink: true,
            canUserAccess: isClientAdmin || isClientUser,
            icon: SettingsIcon,
            permissions: [UserPermissionEnum.ReportView]
        },
        {
            path: 'custom',
            component: ManageReports,
            componentProps: { status: REPORT_LIBRARY.CUSTOM },
            label: 'Custom Reports',
            isHeaderLink: true,
            canUserAccess: isClientAdmin || isClientUser,
            icon: UserIcon,
            permissions: [UserPermissionEnum.ReportView]
        },

        {
            path: 'new',
            component: ReportingPage,
            label: 'sideNavItems.videos',
            isHeaderLink: false,
            isSideNav: false,
            canUserAccess: isClientAdmin || isClientUser,
            hidePageHeader: true
        },
        {
            path: ':chartId',
            component: ReportingPage,
            label: 'sideNavItems.videos',
            hidePageHeader: true,
            canUserAccess: isClientAdmin || isClientUser,
            isHeaderLink: false,
            isSideNav: false
        }
    ]
}
