import Highcharts, {
  Options,
  ExportingOptions,
  CSSObject,
  XAxisOptions,
  YAxisOptions,
  SeriesOptionsType,
} from 'highcharts'

interface ChartData {
  xAxis?: (string | XAxisOptions)[]
  seriesData: SeriesOptionsType[]
}

interface ChartConfig {
  markerEnabled?: boolean
  legendEnabled?: boolean
  chartTitle?: string
  xAxisLabelColor?: string
  yAxisLabelColor?: string
  showTooltipDataInPercentage?: boolean
}

export const getLineChartOptions = (
  chartData: ChartData,
  chartStyles: CSSObject,
  chartExportingOptions: ExportingOptions,
  chartConfig: ChartConfig,
): Options => {
  const { xAxis, seriesData } = chartData || {}

  const {
    markerEnabled = true,
    legendEnabled = true,
    chartTitle = 'Line Chart',
    xAxisLabelColor = '',
    yAxisLabelColor = '',
    showTooltipDataInPercentage = true,
  } = chartConfig || {}

  const options: Options = {
    exporting: {
      ...chartExportingOptions,
    },
    chart: {
      ...chartStyles,
      events: {
        load() {
          this.series.forEach((series) => {
            // Cast to any to handle data updates
            const specificSeries = series as any
            if (specificSeries.options.data) {
              specificSeries.update(
                {
                  type: series.type, // Ensure type is included
                  data: specificSeries.options.data,
                } as SeriesOptionsType,
                false,
              )
            }
          })
          this.redraw()
        },
      },
    },

    title: { text: chartTitle },

    yAxis: {
      title: {
        text: '',
      },
      labels: {
        style: {
          ...(yAxisLabelColor && { color: yAxisLabelColor }),
        },
      },
    },

    xAxis: {
      categories: (xAxis || [])
        .map((axis) =>
          typeof axis === 'string'
            ? axis
            : (axis).categories?.[0] || '',
        )
        .filter((category): category is string => typeof category === 'string'),
      labels: {
        style: {
          ...(xAxisLabelColor && { color: xAxisLabelColor }),
        },
      },
      tickInterval: Math.ceil(xAxis?.length / 10),
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      enabled: legendEnabled,
    },

    plotOptions: {
      line: {
        marker: {
          enabled: markerEnabled,
        },
        connectNulls: true,
      },

      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },

    tooltip: {
      useHTML: true,
      stickOnContact: true,
      formatter() {
        const isPercentage = (this.series.userOptions as any).isPercentage

        return `<div style='overflow-y: auto; max-height: 300px;'>
                  <table>
                    <thead>
                      <th colspan="2" style="text-align:center;padding-bottom:7px">
                      ${this.series.name}</th>
                    </thead>
                  <tbody>
                    <tr style="font-size:12px;">
                      <td>${this.x}</td>
                    </tr>
                    <tr style="font-size:12px;">
                      <td style="color: #698928; background-color: #69892820; border-radius: 6px; padding: 4px;display: inline-block; text-align: center;">
                            ${this.y === 0 ? this.y : this.y}${
          isPercentage ? '%' : ''
        }
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>`
      },
    },

    series: seriesData,

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'top',
            },
          },
        },
      ],
    },
  }

  return options
}
