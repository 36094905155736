import {
    ManageClientAdmins,
    ModifyAdminContainer,
    ModifyClientAdminContainer,
    ViewAdminContainer
} from '@containers'
import { UserPermissionEnum } from '@dts/constants'
import { AdminsPage } from '@pages'
import { getAccessRoles } from '@utils'

export const getClientAdminRoutes = (roles) => {
    const accessedRoles = getAccessRoles(roles)
    const { isDefaultTenantUser, isManager } = accessedRoles
    return [
        {
            path: '',
            component: ManageClientAdmins,
            label: 'general.users',
            isHeaderLink: true,
            canUserAccess: true,
            isSideNav: isManager || isDefaultTenantUser
        },
        {
            path: 'new',
            component: ModifyClientAdminContainer,
            label: 'Add User',
            isHeaderLink: false,
            canUserAccess: isManager || isDefaultTenantUser
        },
        {
            path: ':adminId',
            component: ModifyClientAdminContainer,
            label: 'Add User',
            isHeaderLink: false,
            canUserAccess: isManager || isDefaultTenantUser
        }
    ]
}

export const getClientAdminsUsersRoutes = (roles) => {
    const accessedRoles = getAccessRoles(roles)
    return [
        {
            path: '',
            component: AdminsPage,
            componentProps: { isClientAdmin: true },
            label: 'general.users',
            isHeaderLink: true,
            canUserAccess: true,
            isSideNav: accessedRoles.isClientAdmin,
            permissions: [UserPermissionEnum.UserView]
        },
        {
            path: ':id',
            component: ModifyAdminContainer,
            componentProps: { isClientAdmin: true },
            isHeaderLink: false,
            canUserAccess: accessedRoles.isClientAdmin
        },
        {
            path: 'new',
            component: ModifyAdminContainer,
            componentProps: { isClientAdmin: true },
            isHeaderLink: false,
            canUserAccess: accessedRoles.isClientAdmin
        },
        {
            path: 'view-admin/:id?',
            component: ViewAdminContainer,
            isHeaderLink: false,
            canUserAccess: accessedRoles.isClientAdmin
        }
    ]
}
