import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_LEARNER_LIST_FIELD = gql`
  ${MessagesFragment('LearnerFieldResponseDto')}
  ${MetaFragment('LearnerFieldResponseDto')}
  query getLearnerListField {
    getLearnerListField {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
        title
      }
    }
  }
`
