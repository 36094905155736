import { gql } from '@apollo/client'
import { ArticleFields } from '../../fragments'

export const GET_ARTICLES_DIRECTORY = gql`
  ${ArticleFields('ArticleDataDto')}
  query getAdminArticles(
    $args: AdminArticleArgumentsDto!
    $options: PaginationDto!
  ) {
    getAdminArticles(args: $args, options: $options) {
      data {
        ...ArticleFields
      }
    }
  }
`
