import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useParams } from 'react-router-dom'
import { usePageHeader, useRoutes } from '@hooks'
import { Grid } from '@mui/material'
import { checkCurrentRoute } from '@utils'

export const LearnersLayout = () => {
    const { t } = useTranslation()
    const { learnerRoutes } = useRoutes()
    const paramValues = Object.keys(useParams())

    const currentRoute = checkCurrentRoute(learnerRoutes, paramValues)

    const { pageHeader } = usePageHeader({
        headerText: t('general.learnerLists'),
        menuConfig: () => learnerRoutes
    })

    return (
        <>
            {!currentRoute?.hidePageHeader ? (
                <>
                    {pageHeader}
                    <Grid py={1} px={2}>
                        <Outlet />
                    </Grid>
                </>
            ) : (
                <Outlet />
            )}
        </>
    )
}
