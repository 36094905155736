import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { startCase } from 'lodash'
import { ErrorFields } from './types'
import { StyledErrorDiv, StyledErrorMessage } from './styles'

export const FieldErrors: FC<ErrorFields> = ({
    error,
    errorPosition,
    name,
    errorKey
}) => {
    const { t } = useTranslation()
    return (
        error && (
            <StyledErrorDiv errorPosition={errorPosition}>
                <StyledErrorMessage errorPosition={errorPosition}>
                    {error?.ref?.name
                        ? t(error?.message ?? '', {
                              value: errorKey
                                  ? startCase(errorKey)
                                  : startCase(name.replace('Id', ''))
                          })
                        : ''}
                </StyledErrorMessage>
            </StyledErrorDiv>
        )
    )
}
