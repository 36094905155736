import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import {
    BaseContainer,
    ComponentContainer,
    ControlledSelect,
    ParentContainer,
    TypographyContainer
} from '@components'
import { Typography } from '@mui/material'
import { isMediumScreen } from '@utils'
import { useGraphQLWrapper } from '@hooks'
import { GET_LEARNER_LISTS, GET_TAGS } from '@dts/graphql'
import { mapDropdownOptions } from '@dts/client-utils'
import { TagCreationPageEnum } from '@dts/constants/lib'

export const TrainingLearners: React.FC = () => {
    const { t } = useTranslation()
    const { getValues } = useFormContext()

    const { currentData: learnerListData } = useGraphQLWrapper({
        query: GET_LEARNER_LISTS,
        queryName: 'getLearnerLists',
        retrieveOnMount: true
    })

    const { currentData: tagsData } = useGraphQLWrapper({
        query: GET_TAGS,
        queryName: 'getTags',
        queryVariables: {
            options: { creationPage: TagCreationPageEnum.Learner }
        },
        retrieveOnMount: true
    })

    /* This will be used in the future
    const multiFieldRowConfig = {
        name: 'includedTags',
        maxScrollableHeight: '400px',
        config: [
            {
                name: 'tags',
                component: SearchableTags,
                componentProps: {
                    maxWidth: '100%',
                    customDataName: 'tags'
                },
                componentId: 'tags',
                md: 4.8
            }
        ],
        addNewRowOptions: { tags: [] }
    }
    */

    const tagsMapper = () =>
        tagsData?.map((tag) => ({
            label: tag?.title,
            value: tag?.title
        })) ?? []

    return (
        <ParentContainer container>
            <Typography variant='h1'>{getValues('name')}</Typography>
            <BaseContainer item>
                <TypographyContainer item md={2}>
                    <Typography variant='h3'>
                        {t('general.learnerLists')}
                    </Typography>
                    <Typography variant='body2Medium'>
                        {t('trainings.learnerListDescription')}
                    </Typography>
                </TypographyContainer>
                <ComponentContainer item md={10} mb={1}>
                    <ControlledSelect
                        id='learnerLists'
                        name='learnerLists'
                        label={t('general.learnerLists')}
                        ariaLabel={t('general.learnerLists')}
                        isMulti
                        options={mapDropdownOptions(learnerListData)}
                        width={!isMediumScreen?.() ? '40%' : '100%'}
                    />
                </ComponentContainer>
            </BaseContainer>
            <Typography variant='h2'>
                {t('trainings.assignTraining.addFilters')}
            </Typography>
            <BaseContainer item>
                <TypographyContainer item md={2}>
                    <Typography variant='h3'>
                        {t('trainings.assignTraining.includeTag')}
                    </Typography>
                    <Typography variant='body2Medium'>
                        {t('trainings.assignTraining.includeTag.description')}
                    </Typography>
                </TypographyContainer>
                <ComponentContainer item md={10}>
                    <ControlledSelect
                        id='includedTag'
                        name='includedTag'
                        label={t('trainings.assignTraining.includeTag')}
                        ariaLabel={t('trainings.assignTraining.includeTag')}
                        options={tagsMapper()}
                        width={!isMediumScreen?.() ? '40%' : '100%'}
                        clearable
                    />
                    {/* <MultiFieldRow {...multiFieldRowConfig} /> */}
                </ComponentContainer>
            </BaseContainer>
            <BaseContainer item>
                <TypographyContainer item md={2}>
                    <Typography variant='h3'>
                        {t('trainings.assignTraining.excludeTag')}
                    </Typography>
                    <Typography variant='body2Medium'>
                        {t('trainings.assignTraining.excludeTag.description')}
                    </Typography>
                </TypographyContainer>
                <ComponentContainer item md={10}>
                    <ControlledSelect
                        id='excludedTag'
                        name='excludedTag'
                        label={t('trainings.assignTraining.excludeTag')}
                        ariaLabel={t('trainings.assignTraining.excludeTag')}
                        options={tagsMapper()}
                        width={!isMediumScreen?.() ? '40%' : '100%'}
                        clearable
                    />
                </ComponentContainer>
            </BaseContainer>
        </ParentContainer>
    )
}
