export enum LearnerListStatus {
    Active = 'available',
    Archived = 'archived'
}

export enum LearnerAdditionType {
    Upload = 'UPL',
    CopyPaste = 'CAP',
    EmptyList = 'EML',
    Import = 'IMP'
}

export enum DelimiterType {
    Comma = 'CSV (comma separated)',
    Tab = 'TSV (tab seperated)'
}

export enum FileType {
    CSV = 'text/csv',
    TSV = 'text/tab-separated-values'
}
