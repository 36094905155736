import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
    ControlledSwitch,
    ControlledTextInput,
    MaskInput,
    UploadAvatar
} from '@components'
import { Grid, Typography } from '@mui/material'
import { isMediumScreen } from '@utils'
import { useCustomTheme, useGraphQLWrapper } from '@hooks'
import { ArchiveIcon } from '@assets'
import { ARCHIVE_CONTENT_CREATOR } from '@dts/graphql'
import { phoneNumberMask } from '@constants'
import { AccessibleAction } from '@hoc'
import {
    StyledAvatarContainer,
    StyledButton,
    StyledFlexContainer
} from './styles'
import { ContentCreatorProfileFormProps } from './types'
import { UserPermissionEnum } from '@dts/constants'

export const ContentCreatorProfileForm: React.FC<
    ContentCreatorProfileFormProps
> = ({ contentCreatorId }) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const navigate = useNavigate()

    const archiveContentCreatorConfig = {
        mutation: ARCHIVE_CONTENT_CREATOR,
        mutationName: 'updateContentCreator',
        onMutationSuccess: () => {
            navigate('/content-creators/archived')
        },
        isCustomMutationInput: true
    }

    const { save: archiveContentCreator } = useGraphQLWrapper({
        ...archiveContentCreatorConfig
    })

    return (
        <Grid container>
            <StyledAvatarContainer container xs={12} md={9}>
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        ...(isMediumScreen?.()
                            ? {
                                  flexDirection: 'column',
                                  justifyContent: 'center'
                              }
                            : {})
                    }}
                >
                    <Grid item xs={10} md={10}>
                        <UploadAvatar name='avatarUpload' />
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <ControlledSwitch
                            name='active'
                            label={t('general.active')}
                        />
                    </Grid>
                </Grid>
            </StyledAvatarContainer>
            <StyledFlexContainer container>
                <Grid item xs={12} md={5}>
                    <ControlledTextInput
                        id='firstName'
                        name='firstName'
                        label={t('general.firstName')}
                    />
                </Grid>
                <Grid item xs={12} md={5}>
                    <ControlledTextInput
                        id='lastName'
                        name='lastName'
                        label={t('general.lastName')}
                    />
                </Grid>
            </StyledFlexContainer>
            <StyledFlexContainer container sx={{ alignItems: 'center' }}>
                <Grid item xs={12} md={10}>
                    <ControlledTextInput
                        id='email'
                        type='email'
                        name='email'
                        label={t('general.email')}
                    />
                </Grid>
            </StyledFlexContainer>
            <Grid item xs={12}>
                <Grid item xs={12} md={10}>
                    <MaskInput
                        name='officeNumber'
                        id='office-phone'
                        label={t('editAdmin.officePhone')}
                        mask={phoneNumberMask}
                        placeholder={'(000) 000-0000'}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <AccessibleAction
                permissions={[UserPermissionEnum.CreatorCreateModify]}
                component={
                    <Grid item xs={5} mt={3}>
                        <StyledButton
                            sx={{
                                color: `${theme.palette.errorDangerMedium}`,
                                border: `1px solid ${theme.palette.errorDangerMedium}`
                            }}
                            onClick={() => {
                                archiveContentCreator({
                                    input: {
                                        id: contentCreatorId,
                                        isArchived: true
                                    }
                                })
                            }}
                        >
                            <ArchiveIcon
                                color={theme.palette.errorDangerMedium}
                            />
                            {t('contentCreators.archive')}
                        </StyledButton>
                        <Typography
                            variant='body2'
                            sx={{
                                color: theme.palette.contentSecondary
                            }}
                        >
                            {t('contentCreators.archive.label')}
                        </Typography>
                    </Grid>
                }
            />
        </Grid>
    )
}
