import { gql } from '@apollo/client'

export const ClientFields = (on: string) => gql`
    fragment ClientFields on ${on} {
        id
        title
        email
        phone
        zipCode
        active
        sector {
        id
        title
        available
        displayOrder
        }
        logo
        contractExpiryDate
        contractStartDate
        address
        clientId
        clientType {
        id
        title
        subType
        }
        clientContact {
        title
        email
        phone
        mobile
        designation
        }
        city {
        id
        title
        }
        creator {
        id
        firstName
        lastName
        email
        }
        modifier {
        id
        firstName
        lastName
        email
        }
        state {
        id
        title
        available
        displayOrder
        }
        verticals {
            id
            title
        }
        coursesCount
    }
`
