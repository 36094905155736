import React from 'react'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid, useMediaQuery } from '@mui/material'
import { usePageHeader, useRoutes } from '@hooks'
import { BackButtonType } from '@constants'

export const ManageClientsContainer: React.FC = () => {
    const { manageClientsRoutes } = useRoutes()
    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )

    const { t } = useTranslation()

    const { pageHeader } = usePageHeader({
        headerText: t('pageHeader.manageClients'),
        menuConfig: () => manageClientsRoutes,
        hasColumnDirection: true,
        backButtonConfig: {
            showBackButton: isMediumScreen,
            backButtonType: BackButtonType.Custom,
            customUrl: '/clients'
        }
    })

    return (
        <>
            {pageHeader}
            <Grid>
                <Outlet />
            </Grid>
        </>
    )
}
