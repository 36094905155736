import React, {
    useEffect,
    useMemo,
    useState,
    isValidElement,
    useCallback
} from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable, Toolbar } from '@components'
import { useGraphQL } from '@dts/client-utils'
import { usePageHeader } from '@hooks'
import { Grid, IconButton, Paper, Stack, Tooltip } from '@mui/material'
import {
    GridRowId,
    GridSortModel,
    GridRowParams,
    useGridSelector,
    useGridApiContext,
    GridRenderCellParams,
    GridRowSelectionModel,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    gridDetailPanelExpandedRowsContentCacheSelector
} from '@mui/x-data-grid-pro'
import { ChevronUpIcon, ChevronDownIcon } from '@assets'
import { ColumnDef, DataTableProps, Response } from './types'
import { useCustomActions } from './use-custom-actions'

// These props will be filtered later on once the data grid is in stable state
export const useDataTable = ({
    config: {
        columns,
        pagination,
        hideFooter,
        queryOptions,
        setQueryOptions,
        paginationModel,
        setPaginationModel,
        detailCompConfig,
        genericActions
    },
    data,
    rowCount = 10,
    refetch,
    isLoading,
    name,
    id,
    config,
    headerHeight = 48,
    headerColor,
    maxHeight,
    rowHeight,
    directoryLoading,
    shouldShowLoader
}: DataTableProps) => {
    const { t } = useTranslation()
    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>()
    const [detailsComponentData, setDetailsComponentData] = useState<
        Array<{ id: string; data: Response<unknown> }>
    >([])
    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] =
        React.useState<Array<GridRowId>>([])
    const { getCustomActions, showActionsColumn } =
        config?.customActions || genericActions
            ? useCustomActions({
                  customActions: config?.customActions,
                  genericActions
              })
            : { getCustomActions: undefined }

    const { rows } = useMemo(
        () => ({
            rows: data,
            pinnedRows: []
        }),
        [data]
    )

    const {
        retrieve: detailCompRetrieve,
        data: detailCompData,
        queryLoading: detailPanelLoading
    } = useGraphQL({
        query: detailCompConfig?.query,
        queryName: detailCompConfig?.queryName,
        queryVariables: detailCompConfig?.queryVariables,
        retrieveOnMount: false
    })

    useEffect(() => {
        if (detailPanelExpandedRowIds?.length) {
            detailCompRetrieve({
                variables: { clientId: detailPanelExpandedRowIds[0] }
            })
        }
    }, [detailPanelExpandedRowIds])

    useEffect(() => {
        if (detailCompData) setDetailsComponentData(detailCompData)
    }, [detailCompData])

    const { gridColumns } = useMemo(() => {
        const tempColumns: Array<ColumnDef> = []

        columns?.forEach(
            (
                { flex = 1, renderCell, field, minWidth = 120, ...props },
                index
            ) => {
                index === 1 &&
                    detailCompConfig &&
                    tempColumns.push({
                        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                        maxWidth: 32,
                        renderCell: (params) => (
                            <CustomDetailPanelToggle
                                id={params.id}
                                value={params.value}
                            />
                        )
                    })

                tempColumns.push({
                    ...props,
                    field,
                    flex,
                    minWidth,
                    sortable: false,
                    renderCell: !renderCell
                        ? (params) => (
                              <Grid sx={{ wordBreak: 'break-all' }}>
                                  <Tooltip title={params.value}>
                                      {params.value}
                                  </Tooltip>
                              </Grid>
                          )
                        : (params) => (
                              <Grid sx={{ wordBreak: 'break-all' }}>
                                  {renderCell(params)}
                              </Grid>
                          )
                })
            }
        )

        if (config?.customActions && getCustomActions && showActionsColumn) {
            tempColumns.push({
                field: 'actions',
                type: 'actions',
                minWidth: 80,
                getActions: (params) => getCustomActions(params)
            })
        }
        return { gridColumns: tempColumns }
    }, [columns])

    const handleSortModelChange = React.useCallback(
        (sortModel: GridSortModel) => {
            setQueryOptions((prevOptions) => ({
                ...prevOptions,
                sortDirection: sortModel?.[0]?.sort
            }))
        },
        []
    )

    const CustomDetailPanelToggle = (
        props: Pick<GridRenderCellParams, 'id' | 'value'>
    ) => {
        const { id, value: expanded } = props
        const apiRef = useGridApiContext()
        // To avoid calling ´getDetailPanelContent` all the time, the following selector
        // gives an object with the detail panel content for each row id.
        const contentCache = useGridSelector(
            apiRef,
            gridDetailPanelExpandedRowsContentCacheSelector
        )
        // If the value is not a valid React element, it means that the row has no detail panel.
        const hasDetail = isValidElement(contentCache[id])

        return (
            <IconButton size='small' tabIndex={-1} disabled={!hasDetail}>
                <Grid
                    onClick={() => {
                        setDetailsComponentData(null)
                    }}
                    sx={{
                        transition: (theme) =>
                            theme.transitions.create('transform', {
                                duration: theme.transitions.duration.shortest
                            })
                    }}
                >
                    {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </Grid>
            </IconButton>
        )
    }

    const DetailPanelContent = ({ row, data }) => {
        const {
            buttonAction,
            buttonIcon,
            buttonTitle,
            headerIcon,
            permissions
        } = detailCompConfig?.toolbarConfig || {}

        const directoryToolbarConfig = {
            header: headerIcon ? (
                <Grid container alignItems={'center'} gap={1}>
                    {headerIcon}
                    {row?.title}
                </Grid>
            ) : (
                row?.title
            ),
            addNewButtonConfig: {
                label: buttonTitle,
                icon: buttonIcon,
                variant: 'outlined',
                action: () => {
                    buttonAction(row)
                },
                permissions: permissions
            }
        }

        const detailPanelRows = data?.data?.map((detailPanelRow) => ({
            ...detailPanelRow,
            clientPrimaryId: row?.id
        }))

        return (
            <Grid>
                <Paper
                    sx={{
                        backgroundColor: 'transparent',
                        flex: 1,
                        boxShadow: 'none',
                        marginLeft: '30px'
                    }}
                >
                    <Stack direction='column'>
                        <Toolbar toolbarConfig={directoryToolbarConfig} />

                        <DataTable
                            density='compact'
                            columns={detailCompConfig?.columns ?? []}
                            id={row.clientType?.subType}
                            headerHeight={70}
                            value={detailPanelRows}
                            autoPageSize
                            rowHeight={60}
                            hideFooter
                            directoryLoading={detailPanelLoading}
                            shouldShowLoader={shouldShowLoader}
                        />
                    </Stack>
                </Paper>
            </Grid>
        )
    }
    const getDetailPanelContent = useCallback(
        ({ row }: GridRowParams) => (
            <DetailPanelContent row={row} data={detailsComponentData} />
        ),
        [detailsComponentData]
    )

    const handleDetailPanelExpandedRowIdsChange = useCallback(
        (newIds: Array<GridRowId>) => {
            setDetailPanelExpandedRowIds(
                newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds
            )
        },
        []
    )

    return {
        dataTable: (
            <>
                <DataTable
                    id={id}
                    name={name}
                    columns={columns}
                    value={rows}
                    customActions={config?.customActions}
                    genericActions={config?.genericActions}
                    setQueryOptions={setQueryOptions}
                    queryOptions={queryOptions}
                    headerColor={headerColor}
                    headerHeight={headerHeight}
                    rowCount={rowCount}
                    loading={isLoading}
                    pagination={pagination}
                    hideFooter={hideFooter}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                    maxHeight={maxHeight}
                    handleSortModelChange={handleSortModelChange}
                    rowHeight={rowHeight}
                    directoryLoading={directoryLoading}
                    shouldShowLoader={shouldShowLoader}
                    detailCompConfig={detailCompConfig}
                    CustomDetailPanelToggle={CustomDetailPanelToggle}
                    getDetailPanelContent={
                        detailCompConfig && getDetailPanelContent
                    }
                    detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                    onDetailPanelExpandedRowIdsChange={
                        handleDetailPanelExpandedRowIdsChange
                    }
                    getDetailPanelHeight={
                        detailCompConfig ? () => 'auto' : undefined
                    }
                />
            </>
        ),
        rows,
        refetch,
        selectedRows,
        setSelectedRows
    }
}
