declare global {
    interface Window {
        API_SERVER: string
        ENABLE_COOKIE_AUTH: string
        BASE_URL: string
        MODE: string
        REACT_APP_AUTH0_DOMAIN: string
        REACT_APP_AUTH0_CLIENTID: string
        REACT_APP_AUTH0_TOKEN_IDENTIFIER: string
        LOGOUT_REDIRECT_URL: string
        MUI_LICENSE_KEY: string
        WS_SERVER: string
        FIREBASE_API_KEY: string
        FIREBASE_AUTH_DOMAIN: string
        FIREBASE_PROJECT_ID: string
        FIREBASE_STORAGE_BUCKET: string
        FIREBASE_MESSAGING_SENDER_ID: string
        FIREBASE_APP_ID: string
        FIREBASE_MEASUREMENT_ID: string
        FIREBASE_VAPID_KEY: string
    }
}

export default global
