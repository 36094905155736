import { BaseFields } from '@types'

export type LearnerProfileProps = {
    firstName: string
    lastName: string
    createdAt: string
    trainings: Array<string>
    tags: Array<string>
}

export const InitialValuesLearnerProfile = {
    firstName: '',
    lastName: '',
    createdAt: '',
    trainings: [],
    tags: []
}
export type CoursesContainerProps = {
    selectedTraining: {
        courses: Array<BaseFields>
    }
}

export type LearnerEditProfileProps = {
    isLearner: boolean
}
