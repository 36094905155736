import Styled from '@emotion/styled'
import { List } from '@mui/material'

export const StyledList = Styled(List)`
        font-size: 14px;
        padding-bottom:0px;
        padding-top:0px;
        > li {
            &::before {
                font-size: 1.5rem;
                top: -8px;
            }
        }
    `
