import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_USER_NOTIFICATIONS = gql`
  ${MessagesFragment('GetUserNotificationsResponseDto')}
  ${MetaFragment('GetUserNotificationsResponseDto')}
  query getUserNotifications($options: PaginationDto) {
    getUserNotifications(options: $options) {
      ...MessagesFragment
      ...MetaFragment
      meta {
        count
        verified
      }
      data {
        unSeen
        notifications {
          id
          body {
            title
            message
            referenceId
          }
          notificationCategoryId
          isSeen
          isRead
          sentAt
        }
      }
    }
  }
`
