import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const DocumentFoldIcon: React.FC<SvgProps> = ({
    color,
    width = 22,
    height = 22,
    fill = 'none',
    fillOpacity
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.primary.main

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 22 22'
            fill={fill}
            fillOpacity={fillOpacity}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M12.8332 2.08105V5.86738C12.8332 6.38076 12.8332 6.63745 12.9331 6.83354C13.021 7.00602 13.1612 7.14625 13.3337 7.23414C13.5298 7.33405 13.7865 7.33405 14.2998 7.33405H18.0862M14.6665 11.9173H7.33317M14.6665 15.584H7.33317M9.1665 8.25065H7.33317M12.8332 1.83398H8.0665C6.52636 1.83398 5.75629 1.83398 5.16803 2.13372C4.65058 2.39737 4.22989 2.81806 3.96624 3.33551C3.6665 3.92377 3.6665 4.69384 3.6665 6.23398V15.7673C3.6665 17.3075 3.6665 18.0775 3.96624 18.6658C4.22989 19.1832 4.65058 19.6039 5.16803 19.8676C5.75629 20.1673 6.52636 20.1673 8.0665 20.1673H13.9332C15.4733 20.1673 16.2434 20.1673 16.8316 19.8676C17.3491 19.6039 17.7698 19.1832 18.0334 18.6658C18.3332 18.0775 18.3332 17.3075 18.3332 15.7673V7.33398L12.8332 1.83398Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default DocumentFoldIcon
