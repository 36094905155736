import { gql } from '@apollo/client'
import {
  MessagesFragment,
  MetaFragment,
  ResourcesFields,
} from '../../fragments'

export const SAVE_RESOURCES = gql`
  ${MetaFragment('ResourcesResponseDto')}
  ${MessagesFragment('ResourcesResponseDto')}
  ${ResourcesFields('ResourceCategoryResponse')}
  mutation saveResources($input: [ResourceCategoryInput!]!) {
    saveResources(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...ResourcesFields
      }
    }
  }
`
