import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_ACCOUNT_INTERACTIONS = gql`
  ${MetaFragment('AccountInteractionResponseDto')}
  ${MessagesFragment('AccountInteractionResponseDto')}
  query getAccountInteractions($accountId: String!) {
    getAccountInteractions(accountId: $accountId) {
      ...MetaFragment
      ...MessagesFragment
      data {
        accountId
        following
        muted
      }
    }
  }
`
