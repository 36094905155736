import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const TwoTogglesIcon: React.FC<SvgProps> = ({
    color,
    height = '22',
    width = '22'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.primary.main

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 22 22'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M2.75 7.33398L13.75 7.33398M13.75 7.33398C13.75 8.85277 14.9812 10.084 16.5 10.084C18.0188 10.084 19.25 8.85277 19.25 7.33398C19.25 5.8152 18.0188 4.58398 16.5 4.58398C14.9812 4.58398 13.75 5.8152 13.75 7.33398ZM8.25 14.6673L19.25 14.6673M8.25 14.6673C8.25 16.1861 7.01878 17.4173 5.5 17.4173C3.98122 17.4173 2.75 16.1861 2.75 14.6673C2.75 13.1485 3.98122 11.9173 5.5 11.9173C7.01878 11.9173 8.25 13.1485 8.25 14.6673Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default TwoTogglesIcon
