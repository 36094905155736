import { gql } from '@apollo/client'

export const AGREE_PRIVACY_POLICY = gql`
  mutation AgreePrivacyPolicy {
    agreePrivacyPolicy {
      message {
        messages
      }
    }
  }
`
