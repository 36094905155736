import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Controller,
    useFieldArray,
    useFormContext,
    useWatch
} from 'react-hook-form'
import {
    ControlledCheckBox,
    ListInput,
    SearchableList,
    BaseContainer,
    ComponentContainer,
    ParentContainer,
    TypographyContainer,
    ControlledMultiSelectAutoComplete,
    SearchableTags
} from '@components'
import { Button, Typography } from '@mui/material'
import { ListType } from '@constants'
import { useGraphQLWrapper, useModalWithGraphQL } from '@hooks'

import { mapDropdownOptions } from '@dts/client-utils'
import { GET_TAGS } from '@dts/graphql/lib'
import { SearchableTopicsList } from './add-topics'
import { UserPermissionEnum } from '@dts/constants'

export const TopicDetails: React.FC = ({ allTopics }) => {
    const { t } = useTranslation()
    const { getValues } = useFormContext()

    return (
        <ParentContainer container>
            <Typography variant='h1'>{getValues('name')}</Typography>
            <BaseContainer item>
                <TypographyContainer item md={2.5}>
                    <Typography variant='h3'>{t('general.topics')}</Typography>
                    <Typography variant='body2Medium'>
                        {t('courses.chooseTopics')}
                    </Typography>
                </TypographyContainer>
                <ComponentContainer item md={9.5}>
                    <SearchableTopicsList
                        allTopics={allTopics}
                        permissions={[UserPermissionEnum.CourseCreateModify]}
                    />
                </ComponentContainer>
            </BaseContainer>
            <BaseContainer item>
                <TypographyContainer item md={2.5}>
                    <Typography variant='h3'>{t('general.tags')}</Typography>
                    <Typography variant='body2Medium'>
                        {t('courses.chooseTags')}
                    </Typography>
                </TypographyContainer>

                <ComponentContainer item md={9.5}>
                    <SearchableTags
                        name='tags'
                        customDataName='tags'
                        width={'500px'}
                    />
                </ComponentContainer>
            </BaseContainer>
        </ParentContainer>
    )
}
