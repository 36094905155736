import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Steps, useGraphQLWrapper, useStepper } from '@hooks'
import { TopicListSchema, TrainingInfoSchema } from '@dts/client-utils'
import { GET_COURSE, SAVE_COURSE } from '@dts/graphql/lib'
import { formatDuration, mapLabelsWithAutoIncrements } from '@utils'
import { isEmpty } from 'lodash'
import { GET_TOPICS } from '@dts/graphql'
import { BasicFieldsSchema, InitialDetails, TitleSchema } from '@components'
import { TopicDetails, CourseReview } from './course-creation'
import { UserPermissionEnum } from '@dts/constants'

export const CreateCourse: React.FC = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const [disableDraft, setDisableDraft] = useState(false)
    const navigate = useNavigate()

    const { data: topicsData } = useGraphQLWrapper({
        query: GET_TOPICS,
        queryName: 'getTopics',
        retrieveOnMount: true
    })

    const steps: Array<Steps> = [
        {
            component: InitialDetails,
            componentProps: () => ({
                header: t('general.about')
            }),
            label: t('general.name'),
            schema: TitleSchema
        },

        {
            component: TopicDetails,
            componentProps: () => ({ allTopics: topicsData?.data }),
            label: t('general.topics'),
            schema: TopicListSchema,
            preventDisable: true
        },

        {
            component: CourseReview,
            label: t('general.review'),
            preventDisable: true
        }
    ]

    const mapInputToForm = (result) => {
        const {
            id,
            title,
            description,
            tags,
            courseTopics,
            isArchived,
            isDraft
        } = result?.data || {}
        if (id && !isDraft) {
            setDisableDraft(true)
        } else {
            setDisableDraft(false)
        }
        let totalDuration = 0
        courseTopics?.map((topic) => {
            if (topic?.isAssigned) {
                totalDuration += topic.duration
            }
        })

        return {
            id,
            name: title,
            description,
            tags: tags,
            addedTopics: courseTopics.map((courseTopic) => ({
                ...courseTopic,
                duration: courseTopic.duration.toString()
            })),
            isArchived,
            totalDuration: totalDuration ? formatDuration(totalDuration) : null
        }
    }

    const getMutationInput = (formValues) => {
        const { id, name, description, tags, topics, addedTopics } = formValues

        const topicsAdded = topics?.length
            ? topics.filter((topic) => topic.isAdded)
            : addedTopics?.map((topic) => ({
                  id: topic.topicId,
                  duration: parseInt(topic.duration),
                  isAssigned: topic?.isAssigned
              }))

        return {
            id: id ? id : undefined,
            title: name,
            description,
            tags: tags,
            courseTopics: topicsAdded?.map((topic) => ({
                topicId: topic?.id,
                duration: topic?.duration ?? 0,
                isAssigned: topic?.isAssigned
            }))
        }
    }

    const mapFormToInput = (formValues) => ({
        ...getMutationInput(formValues),
        isDraft: false
    })

    const updateCourseConfig = id && {
        query: GET_COURSE,
        queryName: 'getCourse',
        queryVariables: { id },
        mapInputToForm
    }

    const { stepper, jumpToLast } = useStepper({
        steps,
        headerText: id
            ? `${t('general.edit')} ${t('general.course')}`
            : `${t('general.new')} ${t('general.course')}`,
        editMode: !!id,
        showBreadcrumbs: true,
        ...updateCourseConfig,
        permissions: [UserPermissionEnum.CourseCreateModify],
        initialValues: {
            isActive: true,
            name: '',
            description: '',
            tags: [],
            topics: [],
            addedTopics: [],

            totalDuration: null
        },

        mutation: SAVE_COURSE,
        mutationName: 'saveCourse',

        mapFormToInput,

        onMutationSuccess: () => {
            navigate('/courses')
        },
        tertiaryButtonConfig: {
            title: t('general.saveDraft'),
            disabled: disableDraft,
            tertiaryButtonMapper: (formValues) => ({
                ...getMutationInput(formValues),
                isDraft: true
            }),
            schema: TitleSchema
        }
    })

    useEffect(() => {
        if (id) jumpToLast()
    }, [id])

    return <>{stepper}</>
}
