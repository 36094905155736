export const getDateDifference = (startDate: string, endDate: string) => {
    // Parse the date strings into Date objects
    const start = new Date(startDate)
    const end = new Date(endDate)

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = end - start

    // Convert the difference to days
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24)

    // Return true if the difference is greater than 7 days, otherwise false
    return differenceInDays
}
