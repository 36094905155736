import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { setAccessToken, setUserData } from '@cacheql'
import {
    useCustomTheme,
    useGraphQLWrapper,
    useLocalStorage,
    usePushNotifications
} from '@hooks'

import { AuthContext } from '@context'
import { GET_ACCOUNT_STATUS } from '@dts/graphql'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { LOGO_URL } from '@constants'
import config from '@config'
import { createClient } from 'graphql-ws'
import { DisplayModeEnum } from '@dts/constants'
import { isEqual } from 'lodash'
import { getExpiryFromToken } from '@utils'
import { getCurrentTimeDifference } from '@dts/client-utils'

const _AuthProvider: React.FC = ({ children }) => {
    const [storedLoggedInUser, storeLoggedInUser] = useLocalStorage('user-data')
    const { requestNotificationPermission, deleteFcmToken } =
        usePushNotifications()
    const {
        logout,
        getAccessTokenSilently,
        isAuthenticated,
        loginWithRedirect,
        user
    } = useAuth0()
    const { theme, toggleTheme } = useCustomTheme()
    const [appTheme, setAppTheme] = useLocalStorage('app-theme')
    const [storedAccessToken, storeAccessToken] = useLocalStorage('accessToken')
    const onQuerySuccess = useRef<() => void>()
    const [tokenExpired, setTokenExpired] = useState(false)
    const [tokenExpiry, setTokenExpiry] = useState(null)

    const navigate = useNavigate()

    // Will be removed after testing
    // useEffect(() => {
    //     if (!isAuthenticated && !isLoading && !error) {
    //         loginWithRedirect({
    //             authorizationParams: {
    //                 logo: LOGO_URL,
    //                 primaryColor: theme.palette.backgroundBrandPrimary
    //             }
    //         })
    //     }
    // })

    const postLogin = (data: unknown) => {
        const user = data?.user

        setUserData({
            id: user?.id,
            firstName: user?.firstName,
            lastName: user?.lastName,
            avatarSrc: user?.avatarSrc,
            email: user?.email,
            clientId: user?.clientId,
            role: user?.roles?.[0]?.title,
            organization: user?.organization
        })
        storeLoggedInUser({
            roles: user?.roles,
            clientId: user?.clientId,
            firstLogin: user?.firstLogin,
            adminPortalOnlyAccess: user?.adminPortalOnlyAccess,
            isPortalUser: user?.isPortalUser,
            displayMode: user?.userSetting?.displayMode?.id
        })

        requestNotificationPermission()
    }

    const { retrieve, data } = useGraphQLWrapper({
        query: GET_ACCOUNT_STATUS,
        onQuerySuccess: (response) => {
            const user = {
                ...response?.data?.user,
                firstLogin: response?.meta?.firstLogin,
                isPortalUser: response?.meta?.isPortalUser
            }

            postLogin({
                user
            })

            if (response?.meta?.firstLogin === false) {
                onQuerySuccess?.current && onQuerySuccess?.current()
            }
        },
        queryName: 'getAccountStatus',
        retrieveOnMount: false
    })

    const _logout = () => {
        deleteFcmToken()
        logout({
            logoutParams: {
                returnTo: window.location.origin
            }
        })
        sessionStorage.clear()
        localStorage.clear()
    }

    // In future, refresh tokens will be implemented in order to improve the flows
    const getToken = async () => {
        try {
            const token = await getAccessTokenSilently()
            setAccessToken(token)

            storeAccessToken(token)
            const exp = getCurrentTimeDifference(
                getExpiryFromToken(setAccessToken())
            ).ms
            setTokenExpiry(exp)
        } catch (e) {
            setTokenExpiry(null)
            if (
                e?.error === 'missing_refresh_token' ||
                e?.error === 'invalid_grant'
            ) {
                setTokenExpired(true)
            }
        }
    }

    const _loginWithRedirect = async (
        connectionData: { connectionName: string },
        connectionQueryInput: { email: string }
    ) => {
        await loginWithRedirect({
            authorizationParams: {
                connection: connectionData?.connectionName,
                logo: LOGO_URL,
                primaryColor: theme?.palette?.backgroundBrandPrimary,
                login_hint: connectionQueryInput?.email
            }
        })
        // calls after login redirected
        getToken()
        fetchUserData()
    }

    useEffect(() => {
        const checkAuthentication = async () => {
            if (isAuthenticated) {
                await getToken()
                fetchUserData()
            }
        }

        checkAuthentication()
    }, [isAuthenticated])

    // Scenario when device gets online after standby with active tab session
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                // calls at every tab focus
                getToken()
            }
        }

        document.addEventListener('visibilitychange', handleVisibilityChange)
        return () => {
            document.removeEventListener(
                'visibilitychange',
                handleVisibilityChange
            )
        }
    }, [])

    useEffect(() => {
        if (!tokenExpiry) return

        const refreshTokenIntervalId =
            !tokenExpired &&
            setInterval(() => {
                // calls when refreshToken is needed and accesstoken is expired
                getToken()
            }, tokenExpiry)

        return () => {
            // Clean up the interval on component unmount
            clearInterval(refreshTokenIntervalId)
        }
    }, [tokenExpiry])

    useEffect(() => {
        storedAccessToken && retrieve?.()
    }, [storedAccessToken])

    const fetchUserData = (
        onSuccess?: VoidFunction,
        queryVariables?: object
    ) => {
        if (onQuerySuccess && onSuccess) {
            onQuerySuccess.current = onSuccess
        }
        if (storedAccessToken) {
            queryVariables
                ? retrieve?.({ variables: { ...queryVariables } })
                : retrieve?.({
                      variables: { clientId: storedLoggedInUser?.clientId }
                  })
        }
    }
    /**
     * This useEffect is used to create socket connection when the token is updated
     */

    useEffect(() => {
        if (storedAccessToken && data) {
            const wsLink = new GraphQLWsLink(
                createClient({
                    url: config.WS_SERVER,
                    shouldRetry: () => true, // retry on connection problem
                    lazy: false, // connect as soon as the client is created
                    retryAttempts: 6, // if 0 then fail immediately else keep trying to connect

                    connectionParams: () => ({
                        authorization: `Bearer ${storedAccessToken}`,
                        'client-id': data?.data?.user?.clientId
                    })
                })
            )
        }
    }, [storedAccessToken, data])

    useEffect(() => {
        if (
            !isEqual(
                appTheme,
                data?.data?.user?.userSetting?.displayMode?.id
            ) &&
            data?.data?.user?.userSetting?.displayMode?.id
        ) {
            setAppTheme(
                data?.data?.user?.userSetting?.displayMode?.id ??
                    DisplayModeEnum.LightMode
            )
        }
    }, [data])

    useEffect(() => {
        if (user && tokenExpired) {
            navigate('/session-timeout')
        }
        return
    }, [tokenExpired])

    return (
        <AuthContext.Provider
            value={{
                fetchUserData,
                userData: data?.data?.user,
                firstLogin: data?.meta?.firstLogin,
                userExists: data?.meta?.userExists,
                userInfo: storedLoggedInUser ?? {},
                login: postLogin,
                loginWithRedirect: _loginWithRedirect,
                tokenExpired,
                onSessionTimeoutClose: () => {
                    _logout()
                    setTokenExpired(false)
                },
                getToken,
                logout: () => {
                    _logout()
                },
                appTheme: appTheme ?? DisplayModeEnum.LightMode
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const AuthProvider = ({ children }) => (
    <Auth0Provider
        domain={config.REACT_APP_AUTH0_DOMAIN}
        clientId={config.REACT_APP_AUTH0_CLIENTID}
        authorizationParams={{
            redirect_uri: window.location.origin,
            audience: config.REACT_APP_AUTH0_TOKEN_IDENTIFIER
        }}
        useRefreshTokens={true} // Will be used in the future
        cacheLocation='localstorage'
    >
        <_AuthProvider>{children}</_AuthProvider>
    </Auth0Provider>
)
