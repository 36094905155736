import { styled, Grid } from '@mui/material'

export const StyledDirectoryLink = styled(Grid)(({ theme }) => ({
    fontWeight: 500,
    color: theme.palette.contentLink,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: ' 120%',
    letterSpacing: '0.14px',
    '&:hover': {
        cursor: 'pointer'
    }
}))
