enum ShowLessVideoOptionEnum {
  NoCreatorVideos = 'DSC',
  Uncomfortable = 'MFU',
  Irrelevant = 'ITM',
  NotUnderstandableLanguage = 'NLU',
  Other = 'OTH',
}

enum ShowLessVideoOptionGraphqlEnum {
  'DSC' = 'DSC',
  'MFU' = 'MFU',
  'ITM' = 'ITM',
  'NLU' = 'NLU',
  'OTH' = 'OTH',
}

export { ShowLessVideoOptionEnum, ShowLessVideoOptionGraphqlEnum }
