import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { REPORT_TIME_FRAME, ReportGranularity, TIME_UNIT } from '@constants'

dayjs.extend(duration)
dayjs.extend(advancedFormat)

export const generateIntervals = (durationValue: string, interval: string) => {
    const now = dayjs()
    let endDate

    function formatDate(dateString, isHourly) {
        const date = new Date(dateString)

        const options = {
            month: 'short', // Use 'long' for full month names (e.g., March)
            day: 'numeric',
            ...(isHourly && {
                hour: 'numeric',
                hour12: true // Use 24-hour format)
            })
        }

        return date.toLocaleDateString('en-US', options)
    }

    const getEpochForDates = (dateStr: string, isHourly: boolean) => {
        const date = new Date(dateStr)

        if (isHourly) {
            const epochTime = date.getTime()
            return epochTime // Divide by 1000 to get seconds instead of milliseconds
        } else {
            // For other intervals, set the epoch for the whole day
            return date.getTime() / 1000 // Divide by 1000 to get seconds instead of milliseconds
        }
    }

    switch (durationValue) {
        case REPORT_TIME_FRAME.SEVEN_DAYS:
            endDate = now.subtract(7, TIME_UNIT.DAY)
            break
        case REPORT_TIME_FRAME.THIRTY_DAYS:
            endDate = now.subtract(30, TIME_UNIT.DAY)
            break
        case REPORT_TIME_FRAME.THREE_MONTHS:
            endDate = now.subtract(3, TIME_UNIT.MONTH)
            break
        case REPORT_TIME_FRAME.SIX_MONTHS:
            endDate = now.subtract(6, TIME_UNIT.MONTH)
            break
        case REPORT_TIME_FRAME.TWELVE_MONTHS:
            endDate = now.subtract(12, TIME_UNIT.MONTH)
            break
        case REPORT_TIME_FRAME.TODAY:
            endDate =
                interval === ReportGranularity.DAILY
                    ? now
                    : now.startOf(TIME_UNIT.DAY)
            break
        case REPORT_TIME_FRAME.YESTERDAY:
            endDate = now.subtract(1, TIME_UNIT.DAY)
            break
        default:
            endDate = now
            break
    }

    const intervals = []
    let current = endDate

    // Generate interval dates based on the interval type
    while (current.isBefore(now) || current.isSame(now)) {
        intervals.push({
            id: current,
            isoFormat: new Date(current).toISOString(),
            date: dayjs(current).format('YYYY-MM-DD'),
            numeric: true,
            disablePadding: false,
            label: formatDate(current, interval === ReportGranularity.HOURLY),
            showColumn: true,
            epoch: getEpochForDates(
                current,
                interval === ReportGranularity.HOURLY
            )
        })
        switch (interval) {
            case ReportGranularity.DAILY:
                current = current.add(1, TIME_UNIT.DAY)
                break
            case ReportGranularity.HOURLY:
                current = current.add(1, 'hour')
                break
            case ReportGranularity.WEEKLY:
                current = current.add(1, 'week')
                break
            case ReportGranularity.MONTHLY:
                current = current.add(1, TIME_UNIT.MONTH)
                break
            default:
            // Throw our own error
        }
    }

    return intervals
}
