import React, { useRef } from 'react'

import { EmailIcon, GlobeIcon, LinkIcon } from '@assets'
import { StyledChip } from '@components/Input/styles'

export const ChippedLinks = ({ links }) =>
    links?.map((link) => (
        <StyledChip
            label={link?.value}
            key={link?.id}
            sx={{ mb: 1 }}
            clickable
            icon={
                <div style={{ alignItems: 'center' }}>
                    {link?.value?.toLowerCase()?.includes('https://') ? (
                        <GlobeIcon />
                    ) : link?.value?.includes('@') ? (
                        <EmailIcon />
                    ) : (
                        <LinkIcon />
                    )}
                </div>
            }
            onClick={() => {
                // Handle click action
                window.open(link?.value, '_blank')
            }}
        />
    ))
