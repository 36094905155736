import { MessagesFragment, MetaFragment } from '../../fragments'

import { gql } from '@apollo/client'

export const DELETE_SURVEY = gql`
  ${MessagesFragment('SurveyResponseDto')}
  ${MetaFragment('SurveyResponseDto')}
  mutation deleteSurvey($id: String!) {
    deleteSurvey(id: $id) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
      }
    }
  }
`
