import React from 'react'
import { Grid } from '@mui/material'
import { onKeyDown } from '@utils'

type IconContainerProps = {
    color: string
    size: string
    sx?: object
    displayFlex?: boolean
    onClick?: VoidFunction
    tabIndex?: number
    ariaLabel?: string
    zIndex?: number
}

export const IconContainer = (props: IconContainerProps) => (
    <Grid
        onClick={props?.onClick}
        onKeyDown={(e) => {
            onKeyDown(e, props?.onClick, true)
        }}
        role='button'
        sx={{
            zIndex: props?.zIndex ?? 1000,
            backgroundColor: `${props?.color}`,
            height: `${props?.size ?? '38px'}`,
            width: `${props?.size ?? '38px'}`,
            padding: '8px',
            borderRadius: '50%',
            textAlign: 'center',
            verticalAlign: 'middle',
            ...(props.displayFlex && {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }),
            ...props?.sx
        }}
    >
        {props.children}
    </Grid>
)
