import { getPastCenturyDate } from '../../utils'
import * as yup from 'yup'

export const SignUpSchema = yup.object().shape({
  firstName: yup.string().required('errors.required'),
  lastName: yup.string().required('errors.required'),
  birthday: yup
    .date()
    .max(new Date(), 'errors.birthday')
    .min(getPastCenturyDate(), 'errors.minBirthyear')
    .test('', 'errors.minBirthday', (value) => {
      const dob = new Date(value)
      const currentDate = new Date()

      let age = currentDate.getFullYear() - dob.getFullYear()

      if (
        currentDate.getMonth() < dob.getMonth() ||
        (currentDate.getMonth() === dob.getMonth() &&
          currentDate.getDate() < dob.getDate())
      ) {
        age--
      }

      return age >= 18
    })
    .required('errors.required')
    .nullable()
    .transform((value, currentValue) =>
      currentValue && (!isNaN(currentValue) || typeof currentValue === 'string')
        ? value
        : undefined,
    ),
})
