import { useEffect, useState } from 'react'

export const useImageLoader = (src) => {
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (!src) return // Return if src is not provided

        const img = new Image()
        img.src = src

        const handleLoad = () => setIsLoading(false) // Set loading state to false on successful load
        const handleError = () => setIsLoading(false) // Set loading state to false on error

        img.onload = handleLoad
        img.onerror = handleError

        return () => {
            img.onload = null // Cleanup onload event handler
            img.onerror = null // Cleanup onerror event handler
        }
    }, [src])

    return isLoading
}
