export const daysMapping = {
    MON: 'Monday',
    TUE: 'Tuesday',
    WED: 'Wednesday',
    THR: 'Thursday',
    FRI: 'Friday',
    SAT: 'Saturday',
    SUN: 'Sunday'
}

export const monthWeeksMapping = {
    FIR: 'First',
    SEC: 'Second',
    THI: 'Third',
    FOR: 'Fourth',
    LAS: 'Last'
}

export const dailyReminderOptionsMapping = {
    EVD: 'Everyday',
    EWD: 'Every Weekday'
}

export const monthlyReminderOptionsMappings = {
    DTS: 'Date Specific',
    DYS: 'Day Specific'
}

export const notificationFrequenciesMappings = {
    NON: 'None',
    DAI: 'Daily',
    WEE: 'Weekly',
    MON: 'Monthly'
}
