import { gql } from '@apollo/client'
import {
  MessagesFragment,
  CampusPreferenceFragment,
  MetaFragment,
} from '../../fragments'

export const SAVE_CAMPUS_PREFERENCES = gql`
  ${MessagesFragment('CampusPreferencesResponseDto')}
  ${MetaFragment('CampusPreferencesResponseDto')}
  ${CampusPreferenceFragment('CampusPreferencesDto')}
  mutation saveCampusPreferences($input: CampusPreferencesInputDto!) {
    saveCampusPreferences(input: $input) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...CampusPreferenceFragment
      }
    }
  }
`
