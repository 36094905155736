import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const PlusIcon: React.FC<SvgProps> = ({
    color,
    width = '20',
    height = '21',
    fill = 'none'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.darkestBlue

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 20 21'
            fill={fill}
        >
            <path
                d='M9.99996 4.66675V16.3334M4.16663 10.5001H15.8333'
                stroke={_color}
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default PlusIcon
