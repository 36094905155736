import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
    InputAdornment,
    Autocomplete,
    TextField,
    TextFieldVariants,
    IconButton
} from '@mui/material'

import { CloseIcon, SearchIcon } from '@assets'
import { GET_SEARCH_SUGGESTIONS } from '@dts/graphql'
import { useLazyQuery } from '@apollo/client'
import { debounce, isEmpty } from 'lodash'
import { KeyboardShortcut } from '@constants'
import { useCustomTheme } from '@hooks'

export type SearchFieldProps = {
    value: string
    id: string
    ariaLabel: string
    variant: TextFieldVariants
    placeholder: string
}

export const SearchField: React.FC<SearchFieldProps> = ({
    ariaLabel,
    variant,
    placeholder,
    ...props
}) => {
    const { theme } = useCustomTheme()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [searchString, setSearchString] = useState('')
    const [options, setOptions] = useState([])

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const paramsObj = {}
        for (const [key, value] of searchParams.entries()) {
            paramsObj[key] = value
        }

        setSearchString(paramsObj?.key)
    }, [location.search])

    const [
        getSearchSuggestions,
        {
            data: dropDownsData,
            loading: dropdownsLoading,
            error: dropdownsError
        }
    ] = useLazyQuery(GET_SEARCH_SUGGESTIONS, { fetchPolicy: 'network-only' })

    const onSearchTextChange = useCallback(
        debounce((value, isValueEntered) => {
            if (!isEmpty(value)) {
                getSearchSuggestions({
                    variables: { args: { search: value } }
                })
            }
        }, 500),
        []
    )
    useEffect(() => {
        if (dropDownsData?.getSearchSuggestions?.data?.suggestions?.length) {
            setOptions(dropDownsData?.getSearchSuggestions?.data?.suggestions)
        } else setOptions([])
    }, [dropDownsData])
    const handleKeyDown = (e) => {
        if (
            e.key === KeyboardShortcut.Enter &&
            !isEmpty(searchString?.trim())
        ) {
            navigate(`/global?key=${searchString}`)
        }
    }
    return (
        <Autocomplete
            {...props}
            freeSolo
            aria-label={ariaLabel}
            disableClearable
            value={searchString}
            options={options}
            onInputChange={(event, newValue) => {
                if (
                    newValue &&
                    (!event?.target?.value ||
                        event.key === KeyboardShortcut.Enter)
                ) {
                    setSearchString(newValue)
                    navigate(`/global?key=${newValue}`)
                }
                if (!newValue) {
                    setOptions([])
                }
            }}
            renderInput={(params) => (
                <label>
                    <TextField
                        {...params}
                        aria-label={ariaLabel}
                        hiddenLabel={true}
                        placeholder={placeholder}
                        name='Search'
                        variant={variant}
                        onKeyDown={handleKeyDown}
                        InputProps={{
                            ...params.InputProps,
                            ...(variant === 'standard' && {
                                sx: {
                                    height: '2.25rem'
                                }
                            }),
                            disableUnderline: variant === 'standard',
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon
                                        height='24'
                                        width='24'
                                        color={theme.palette.neutralsTextLight}
                                    />
                                </InputAdornment>
                            ),
                            endAdornment: searchString && (
                                <InputAdornment position='end'>
                                    <IconButton
                                        onClick={() => {
                                            setSearchString('')
                                        }}
                                        aria-label={t('general.clear')}
                                    >
                                        <CloseIcon width={'22'} height={'22'} />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        onChange={(e) => {
                            setSearchString(e?.target?.value)
                            onSearchTextChange?.(e?.target?.value, true)
                        }}
                    />
                </label>
            )}
        />
    )
}
