import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { Typography, Grid } from '@mui/material'
import { formatDate } from '@dts/client-utils'
import {
    BaseContainer,
    ComponentContainer,
    ParentContainer,
    ReviewButton,
    StyledChip,
    TextWithTooltip,
    TypographyContainer
} from '@components'
import { isEmpty } from 'lodash'
import { useCustomTheme, useGraphQLWrapper } from '@hooks'
import { GET_LEARNER_LISTS } from '@dts/graphql'
import { PencilIcon, ReadingIcon, TagIcon, UsersIcon } from '@assets'
import {
    MonthlyReminderOptionEnum,
    NotificationFrequencyEnum
} from '@dts/constants/lib'
import {
    dailyReminderOptionsMapping,
    daysMapping,
    monthWeeksMapping,
    notificationFrequenciesMappings
} from './training-reminders-mapping'

interface TrainingReviewProps {
    handleStepJump?: (step: number) => () => void
}

export const TrainingReview: React.FC<TrainingReviewProps> = ({
    handleStepJump
}) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const { control } = useFormContext()
    const formValues = useWatch({ control })
    const [selectedLearnerLists, setSelectedLearnerLists] = useState({
        learnerLists: [],
        count: 0
    })

    const [selectedCourses, setSelectedCourses] = useState({
        courseList: [],
        count: 0
    })

    const addedCourses = useWatch({
        control,
        name: 'addedCourses'
    })

    const courses = useWatch({
        control,
        name: 'courses'
    })

    const notificationFrequency = useWatch({
        control,
        name: 'notificationFrequency'
    })

    const monthlyReminderOption = useWatch({
        control,
        name: 'monthlyReminderOption'
    })

    const { currentData: learnerListData } = useGraphQLWrapper({
        query: GET_LEARNER_LISTS,
        queryName: 'getLearnerLists',
        retrieveOnMount: true
    })

    useEffect(() => {
        const { learnerLists } = formValues

        if (learnerListData && learnerLists) {
            const filteredList = learnerListData?.filter((learnerlist) =>
                learnerLists?.includes(learnerlist?.id)
            )

            let learnersCount = 0
            const learnerListNames: Array<string> = []

            filteredList?.forEach((list) => {
                learnersCount = learnersCount + list?.learnersCount
                learnerListNames.push(list?.title)
            })
            setSelectedLearnerLists({
                learnerLists: learnerListNames,
                count: learnersCount
            })
        }
    }, [formValues?.learnerlists, learnerListData])

    useEffect(() => {
        const convertedCourses = courses?.length
            ? courses
                  ?.filter((course) => course.isAdded)
                  ?.map((course) => course?.title)
            : addedCourses?.map((course) => course.title)
        setSelectedCourses({
            courseList: convertedCourses,
            count: convertedCourses?.length
        })
    }, [courses, addedCourses])

    const getReminderMessage = () => {
        if (notificationFrequency === NotificationFrequencyEnum.Daily) {
            return [
                notificationFrequenciesMappings?.[notificationFrequency],
                dailyReminderOptionsMapping[formValues?.dailyReminderOption]
            ]
        } else if (notificationFrequency === NotificationFrequencyEnum.Weekly) {
            const selectedWeekdays = formValues?.weekdays
                ? Object.entries(formValues?.weekdays)?.filter(
                      (weekday) => weekday[1] === true
                  )
                : []

            return [
                notificationFrequenciesMappings?.[notificationFrequency],
                `${t('general.every')}: 
               ${selectedWeekdays?.map((val) => daysMapping[val[0]])}`
            ]
        } else if (
            notificationFrequency === NotificationFrequencyEnum.Monthly
        ) {
            if (
                monthlyReminderOption === MonthlyReminderOptionEnum.DateSpecific
            ) {
                return [
                    notificationFrequenciesMappings?.[notificationFrequency],
                    `${t('general.every')}: 
                   ${formValues?.monthDate} ${t(
                        'trainings.review.dateOfMonth'
                    )}`
                ]
            }
            if (
                monthlyReminderOption === MonthlyReminderOptionEnum.DaySpecific
            ) {
                return [
                    notificationFrequenciesMappings?.[notificationFrequency],
                    `${t('general.every')}: 
                   ${monthWeeksMapping[formValues?.monthWeek]} ${
                        daysMapping[formValues?.monthDay]
                    }`
                ]
            }
        }
        return []
    }

    const reviewSteps = [
        {
            header: t('general.description'),
            details: () => (
                <Typography variant='body2Medium'>
                    {formValues?.description ||
                    !isEmpty(formValues?.description) ? (
                        <TextWithTooltip renderer={formValues?.description} />
                    ) : (
                        '--'
                    )}
                </Typography>
            ),
            editButtonText: [t('general.description')].join(' '),
            stepNumber: 0
        },
        {
            header: t('general.learnerLists'),
            details: () => (
                <>
                    <Grid
                        item
                        display={'flex'}
                        flexDirection={'row'}
                        flexWrap={'wrap'}
                    >
                        {selectedLearnerLists?.learnerLists?.map(
                            (list, index) => (
                                <StyledChip
                                    key={[list, index].join('-')}
                                    label={list}
                                />
                            )
                        )}
                    </Grid>
                    <Grid
                        item
                        display={'flex'}
                        flexDirection={'row'}
                        gap={1}
                        alignItems={'flex-end'}
                    >
                        <UsersIcon
                            color={theme.palette.neutralsTextLight}
                            width={20}
                            height={20}
                        />
                        <Typography
                            variant='body2Medium'
                            fontWeight={500}
                            color={theme.palette.contentSecondary}
                        >
                            {[
                                selectedLearnerLists?.count,
                                t('general.learners')
                            ].join(' ')}
                        </Typography>
                    </Grid>
                </>
            ),
            editButtonText: [t('general.learners')].join(' '),
            stepNumber: 1
        },
        {
            header: [
                t('general.courses'),
                t('general.and'),
                t('general.topics')
            ].join(' '),
            details: () => (
                <>
                    <Typography variant='body2Medium'>
                        {selectedCourses?.courseList?.map((course, index) => (
                            <StyledChip
                                key={[course, index].join('-')}
                                label={course}
                            />
                        ))}
                    </Typography>
                    <Grid item display={'flex'} flexDirection={'row'} gap={2}>
                        <Grid
                            item
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'flex-end'}
                            gap={1}
                        >
                            <ReadingIcon
                                color={theme.palette.neutralsTextLight}
                                width={20}
                                height={20}
                            />
                            <Typography
                                variant='body2Medium'
                                fontWeight={500}
                                color={theme.palette.contentSecondary}
                            >
                                {[
                                    selectedCourses?.count,
                                    selectedCourses?.count === 1
                                        ? t('general.course')
                                        : t('general.courses')
                                ].join(' ')}
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'flex-end'}
                            gap={1}
                        >
                            <TagIcon
                                color={theme.palette.neutralsTextLight}
                                width={20}
                                height={20}
                            />
                            <Typography
                                variant='body2Medium'
                                fontWeight={500}
                                color={theme.palette.contentSecondary}
                            >
                                {[
                                    formValues?.videoTags?.length,
                                    formValues?.videoTags?.length === 1
                                        ? t('general.tag')
                                        : t('general.tags')
                                ].join(' ')}
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            ),
            editButtonText: [t('general.course')].join(' '),
            stepNumber: 2
        },
        {
            header: [
                t('general.start'),
                t('general.and'),
                t('general.end'),
                t('general.dates')
            ].join(' '),
            details: () => (
                <Typography
                    variant='body2Medium'
                    fontWeight={500}
                    color={theme.palette.contentSecondary}
                >
                    {`${t('general.start')}: ${formatDate(
                        formValues?.startDate
                    )}, ${t('general.end')}: ${formatDate(
                        formValues?.dueDate
                    )}`}
                </Typography>
            ),
            editButtonText: [t('general.dates')].join(' '),
            stepNumber: 3
        },
        {
            header: t('general.reminders'),
            details: () => (
                <Typography
                    variant='body2Medium'
                    fontWeight={500}
                    color={theme.palette.contentSecondary}
                >
                    {notificationFrequency !== NotificationFrequencyEnum.None
                        ? `${t(
                              'trainings.review.sendReminder'
                          )} ${getReminderMessage().join(', ')}, ${t(
                              'trainings.review.startingOn'
                          )} ${formatDate(formValues?.remindersStartDate)}`
                        : t('trainings.noReminders')}
                </Typography>
            ),
            editButtonText: [t('general.reminders')].join(' '),
            stepNumber: 3
        }
    ]
    return (
        <ParentContainer container>
            <Typography variant='h1'>{formValues?.name}</Typography>

            {reviewSteps?.map((step) => (
                <>
                    <BaseContainer item>
                        <TypographyContainer item md={8}>
                            <Typography variant='h3'>{step?.header}</Typography>
                            {<step.details />}
                        </TypographyContainer>
                        <ComponentContainer item>
                            <ReviewButton
                                onClick={handleStepJump?.(step?.stepNumber)}
                                startIcon={
                                    <PencilIcon
                                        color={theme.palette.primary.main}
                                    />
                                }
                            >
                                {step?.editButtonText}
                            </ReviewButton>
                        </ComponentContainer>
                    </BaseContainer>
                </>
            ))}
        </ParentContainer>
    )
}
