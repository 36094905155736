import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import { useCustomTheme } from '@hooks'
import { isMediumScreen, onKeyDown } from '@utils'
import { DetailedDirectoryLinkProps } from './types'
import { StyledDirectoryDescription, StyledDirectoryText } from './styles'

export const DetailedDirectoryLink: FC<DetailedDirectoryLinkProps> = ({
    title,
    tabIndex,
    description,
    isDraft,
    additionalInfo,
    onClick,
    ellipsisLength = 50
}) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()

    return (
        <Grid container flexDirection={'column'} px={1} py={2} gap={1}>
            <Grid item display={'flex'} flexDirection={'row'} gap={1}>
                {isDraft && (
                    <Typography
                        variant='tCell'
                        color={theme.palette.inactiveBrown}
                        fontWeight={500}
                    >
                        {`[${t('general.draft')}]`}
                    </Typography>
                )}

                <StyledDirectoryText
                    tabIndex={tabIndex}
                    onClick={onClick}
                    onKeyDown={(e) => {
                        onKeyDown(e, onClick, false)
                    }}
                    color={theme.palette.contentLink}
                >
                    {title?.length > ellipsisLength
                        ? `${title?.slice(0, ellipsisLength)}...`
                        : title}
                </StyledDirectoryText>
            </Grid>

            <Grid item>
                <StyledDirectoryDescription
                    tabIndex={tabIndex}
                    color={theme.palette.contentSecondary}
                >
                    {description?.length > ellipsisLength
                        ? `${description?.slice(0, ellipsisLength)}...`
                        : description}
                </StyledDirectoryDescription>
            </Grid>

            <Grid
                item
                display={'flex'}
                flexDirection={isMediumScreen?.() ? 'column' : 'row'}
                gap={2}
            >
                {additionalInfo?.map((item, index) => (
                    <Grid
                        key={index}
                        gap={1}
                        display={'flex'}
                        alignItems={'flex-end'}
                    >
                        {item?.icon && (
                            <item.icon
                                height={20}
                                width={20}
                                color={theme.palette.contentSecondary}
                            />
                        )}
                        <Typography
                            variant='label'
                            fontWeight={500}
                            color={theme.palette.contentPrimary}
                            tabIndex={tabIndex}
                        >
                            {item?.text}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}
