import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, VideoFields } from '../../fragments'

export const GET_VIDEOS_DIRECTORY = gql`
  ${MetaFragment('VideoDirectoryResponseDto')}
  ${MessagesFragment('VideoDirectoryResponseDto')}
  ${VideoFields('VideoDataDto')}
  query getVideoDirectory(
    $args: VideoDirectoryArgumentsDto!
    $options: PaginationDto!
  ) {
    getVideoDirectory(args: $args, options: $options) {
      ...MetaFragment
      ...MessagesFragment
      data {
        videos {
          ...VideoFields
        }
        topics {
          id
          count
        }
        allTopicsCount
      }
    }
  }
`
