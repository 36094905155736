import { gql } from '@apollo/client'

export const ReportFragment = (on: string) => gql`
    fragment ReportFragment on ${on} {
  
      breakdowns {
        id
        title
      }
      chartData {
        age
        interest {
          available
          displayOrder
          id
          title
        }
        reportMetricId
        reportMetricTitle
        time
        metricValueTypeId
        reportMetricTypeId
        itemCount
        itemTitle
        itemId
        value
        aggregationTypeId
      }
      chartType {
        id
        title
      }
      filters {
        from
        id
        title
        to
        value
      }
      id
      metrics {
        id
        title
      }
      reportGranularity {
        id
        title
      }
      reportTimeFrame {
        fromDate
        id
        title
        toDate
      }
      title
      reportLibrary {
        id
        title
      }
      thumbnailSrc
}
    
`
