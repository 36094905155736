import { Workbox } from 'workbox-window'

export default function registerServiceWorker() {
    if (window.MODE !== 'production') {
        return
    }

    if ('serviceWorker' in navigator) {
        const wb = new Workbox('/firebase-messaging-sw.js')

        wb.addEventListener('installed', (event) => {
            // This is a temporary code that might be used later on
            // Additional code to handle the installed event
            // if (event.isUpdate) {
            //     if (
            //         confirm(`New app update is available!. Click OK to refresh`)
            //     ) {
            //         window.location.reload()
            //     }
            // }
        })

        wb.addEventListener('activated', (event) => {
            // Additional code to handle the activated event
        })

        wb.register()
            .then((registration) => {
                // Registration success
            })
            .catch((error) => {
                // Registration failure
            })
    }
}
