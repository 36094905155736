import React, { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Grid, FormControl } from '@mui/material'
import {
    ControlledTextInput,
    ControlledSelect,
    MaskInput,
    ControlledAutoCompleteSelect,
    ControlledSwitch,
    UploadAvatar
} from '@components'
import { controlled } from '@hoc'
import {
    GET_CITIES,
    GET_CLIENT_TYPES,
    GET_STATES,
    GET_ZIP_CODES
} from '@dts/graphql'
import { useGraphQLWrapper } from '@hooks'
import { mapDropdownOptions } from '@dts/client-utils'
import { CampusImg } from '@assets'
import { phoneNumberMask } from '@constants'

export const EditCampusForm: React.FC = () => {
    const { control, setValue } = useFormContext()
    const [firstLoad, setFirstLoad] = useState(false)

    const { pathname } = useLocation()

    const [editMode] = useState(pathname.includes('/edit'))

    const { t } = useTranslation()

    const stateId = useWatch({
        control,
        name: 'state'
    })
    const active = useWatch({
        control,
        name: 'active'
    })
    const cityId = useWatch({
        control,
        name: 'city'
    })
    useEffect(() => {
        if (stateId) {
            firstLoad && setValue('city', null)
            firstLoad && setValue('zipCode', null)
            setFirstLoad(true)
        }
    }, [stateId])

    useEffect(() => {
        if (cityId) {
            firstLoad && setValue('zipCode', '')
            setFirstLoad(true)
        }
    }, [cityId])
    const ControlledFormControl = controlled(FormControl)

    const { currentData: typesData } = useGraphQLWrapper({
        query: GET_CLIENT_TYPES,
        queryName: 'getClientTypes'
    })

    const { currentData: stateData } = useGraphQLWrapper({
        query: GET_STATES,
        queryName: 'getStatesDropDown'
    })

    const { currentData: cityData, retrieve: retrieveCities } =
        useGraphQLWrapper({
            query: GET_CITIES,
            queryName: 'getCitiesDropDown',
            retrieveOnMount: false
        })

    const { currentData: zipCodeData, retrieve: retrieveZipCodes } =
        useGraphQLWrapper({
            query: GET_ZIP_CODES,
            queryName: 'getZipCodes',
            retrieveOnMount: false
        })

    useEffect(() => {
        stateId && retrieveCities({ variables: { stateId } })
    }, [stateId])

    useEffect(() => {
        cityId && retrieveZipCodes({ variables: { cityId } })
    }, [cityId])

    return (
        <Grid container xs={12} md={6}>
            <Grid item md={12}>
                <UploadAvatar
                    name='logo'
                    defaultImg={CampusImg}
                    isRectangular
                />
            </Grid>

            <Grid container columnSpacing={2} alignItems={'center'}>
                <Grid item xs={12} md={6}>
                    <ControlledTextInput
                        name='campusId'
                        id='campus-id'
                        maxLength={5}
                        label={t('general.id')}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <ControlledSwitch
                        name='active'
                        label={
                            active ? t('general.active') : t('general.inactive')
                        }
                    />
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <ControlledTextInput
                    name='title'
                    id='campus-title'
                    label={t('general.name')}
                    fullWidth
                />
            </Grid>

            <Grid container columnSpacing={2}>
                <Grid item xs={12} md={6}>
                    <ControlledTextInput
                        name='email'
                        id='email'
                        label={t('general.email')}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MaskInput
                        name='phone'
                        title={t('general.phone')}
                        placeholder={t('general.phone.placeholder')}
                        mask={phoneNumberMask}
                        maskChar=''
                        fullWidth
                    />
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <ControlledTextInput
                    name='address'
                    id='address'
                    label={t('general.address')}
                    fullWidth
                />
            </Grid>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} md={4}>
                    <ControlledSelect
                        id='state'
                        name='state'
                        label={t('general.state')}
                        placeholder={t('general.state')}
                        options={mapDropdownOptions(stateData)}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ControlledSelect
                        id='city'
                        name='city'
                        label={t('general.city')}
                        placeholder={t('general.city')}
                        options={mapDropdownOptions(cityData)}
                    />
                </Grid>
                <Grid item xs={12} md={4} mt={1}>
                    <ControlledAutoCompleteSelect
                        name='zipCode'
                        id='zipCode'
                        label={t('general.zipCode')}
                        placeholder={t('general.zipCode')}
                        options={zipCodeData?.map((zipCode) => ({
                            value: zipCode?.id,
                            label: zipCode?.zipCode
                        }))}
                        maxLength={5}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
