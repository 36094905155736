import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormFeedback, useLoader, useModal } from '@hooks'
import { FormProvider } from '@providers'
import { ModalWithGraphQLConfig } from '@types'
import { ResponseStatusEnum } from '@dts/constants'
import { can_access } from '@hoc'

export const useModalWithGraphQL = <F, Q, M, V = object>(
    action: ModalWithGraphQLConfig<F, M, Q, V>
) => {
    const { showDialog, hideDialog, setShowLoader } = useModal()
    const { showDialog: showFeedbackDialog, hideDialog: hideFeedbackDialog } =
        useModal()
    const { setLoading } = useLoader()
    const { t } = useTranslation()

    if (action?.modalConfig) {
        const {
            modalConfig,
            mutation,
            mutationName,
            mapFormToMutationInput,
            mapFormToQueryInput,
            query,
            queryName,
            queryVariables,
            hasPrimaryExtraInput = false,
            mapQueryResultToForm,
            initialValues,
            permissions = [],
            formErrorMap,
            onMutationSuccess,
            onQuerySuccess,
            yupResolverSchema,
            isCustomMutationInput
        } = action

        const { methods, save, retrieve, mutationLoading, queryLoading } =
            useFormFeedback<F, Q, M, V>({
                permissions,
                mutation,
                mutationName,
                mapFormToMutationInput,
                mapFormToQueryInput,
                query,
                queryName,
                queryVariables,
                mapQueryResultToForm,
                initialValues,
                formErrorMap,
                retrieveOnMount: false,
                onQuerySuccess,
                yupResolverSchema,
                isCustomMutationInput,
                onMutationSuccess: (data) => {
                    methods.reset()
                    hideDialog()
                    onMutationSuccess?.(data)
                }
            })
        const readOnly = permissions?.length > 0 && !can_access(permissions)
        const openFeedbackDialog = () => {
            const body = (
                <>
                    {modalConfig.FeedbackComponent && (
                        <modalConfig.FeedbackComponent />
                    )}
                </>
            )

            showFeedbackDialog({
                maxWidth: 'sm',
                body,
                hasCancelButton: false,
                onClose: hideFeedbackDialog,
                primaryButtonConfig: {
                    text: t('general.close'),
                    onConfirm: () => hideFeedbackDialog()
                },

                hasFeedbackDialog: true
            })
        }

        const _showDialog = (
            body: JSX.Element,
            primaryButtonText?: string,
            secondaryButtonText?: string,
            header?: string,
            extraInput?: object
        ) => {
            const onPrimaryConfirm = async () => {
                const response = hasPrimaryExtraInput
                    ? await save({
                          ...extraInput
                      })
                    : await save()

                if (response?.message?.type === ResponseStatusEnum?.Success) {
                    hideDialog()
                    if (modalConfig?.FeedbackComponent) {
                        openFeedbackDialog()
                    }
                }
            }

            const onSecondaryConfirm = async () => {
                // Secondary mutation call
                await save({
                    ...extraInput
                })
            }

            showDialog({
                title: header,
                maxWidth: modalConfig?.maxWidth,
                fullScreen: modalConfig?.fullScreen,
                body,
                onClose: () => {
                    methods.reset(initialValues)
                    hideDialog()
                },
                hasCancelButton: modalConfig?.hasCancelButton ?? true,
                primaryButtonConfig: primaryButtonText
                    ? {
                          text: primaryButtonText,
                          onConfirm: () => {
                              methods.setValue('buttonType', 'primary')
                              methods.handleSubmit(() => {
                                  onPrimaryConfirm()
                                  // hideDialog()
                              })()
                          }
                      }
                    : undefined,
                secondaryButtonConfig: secondaryButtonText
                    ? {
                          text: secondaryButtonText,
                          onConfirm: () => {
                              methods.setValue('buttonType', 'secondary')
                              methods.handleSubmit(() => {
                                  onSecondaryConfirm()
                                  hideDialog()
                              })()
                          }
                      }
                    : undefined,
                hideDialogActions:
                    methods?.formEditingState?.isReadOnly || readOnly
            })
        }

        const openDialog = (extraInput?: object) => {
            const componentProps = modalConfig?.componentProps?.(extraInput)
            const body = (
                <FormProvider {...methods}>
                    <fieldset
                        style={{ border: 'none' }}
                        disabled={
                            methods?.formEditingState?.isReadOnly || readOnly
                        }
                    >
                        {modalConfig.Component && (
                            <modalConfig.Component
                                {...componentProps}
                                permissions={permissions}
                            />
                        )}
                    </fieldset>
                </FormProvider>
            )
            methods.reset({ ...initialValues })

            _showDialog(
                body,
                modalConfig?.creationConfig?.primaryButtonText,
                modalConfig?.creationConfig?.secondaryButtonText,
                modalConfig?.creationConfig?.header,
                extraInput
            )
        }

        const openPrepopulatedDialog = (
            variables?: object,
            extraInput?: object,
            formValues?: object
        ) => {
            methods.reset({ ...initialValues })

            if (query) {
                const queryVariables = {
                    ...mapFormToQueryInput?.(methods.getValues()),
                    ...variables
                }
                retrieve({ variables: { ...queryVariables } })
            }
            if (formValues) {
                methods.reset({ ...methods.getValues(), ...formValues })
            }
            const body = (
                <FormProvider {...methods}>
                    <fieldset
                        style={{ border: 'none' }}
                        disabled={
                            methods?.formEditingState?.isReadOnly || readOnly
                        }
                    >
                        {modalConfig.Component && (
                            <modalConfig.Component
                                isEditMode
                                permissions={permissions}
                            />
                        )}
                    </fieldset>
                </FormProvider>
            )

            _showDialog(
                body,
                modalConfig?.updationConfig?.primaryButtonText,
                modalConfig?.updationConfig?.secondaryButtonText,
                modalConfig?.updationConfig?.header,
                extraInput
            )
        }

        const openConfirmationDialog = (input) => {
            const body = (
                <FormProvider {...methods}>
                    <fieldset
                        style={{ border: 'none' }}
                        disabled={
                            methods?.formEditingState?.isReadOnly || readOnly
                        }
                    >
                        {modalConfig.Component && (
                            <modalConfig.Component permissions={permissions} />
                        )}
                    </fieldset>
                </FormProvider>
            )
            const onPrimaryConfirm = async () => {
                await save({ ...input })
            }
            showDialog({
                title: modalConfig?.confirmationConfig?.header,
                maxWidth: modalConfig?.maxWidth,
                body,
                hasCancelButton: modalConfig?.hasCancelButton ?? true,
                onClose: hideDialog,
                primaryButtonConfig: modalConfig?.confirmationConfig
                    ?.primaryButtonText
                    ? {
                          text: modalConfig?.confirmationConfig
                              ?.primaryButtonText,
                          onConfirm: () =>
                              methods.handleSubmit(() => {
                                  onPrimaryConfirm()
                                  hideDialog()
                              })()
                      }
                    : undefined,
                hideDialogActions:
                    methods?.formEditingState?.isReadOnly || readOnly
            })
        }

        const openModalWithForm = (formValues, onConfirm, onClose) => {
            if (query && queryName) {
                // to map query data to modal
                retrieve?.({ queryVariables })
            } else if (formValues) {
                // to map parent form values to modal
                methods.reset({ ...formValues })
            }
            const componentProps = modalConfig?.componentProps
            const body = (
                <FormProvider {...methods}>
                    <fieldset
                        style={{ border: 'none' }}
                        disabled={
                            methods?.formEditingState?.isReadOnly || readOnly
                        }
                    >
                        {modalConfig.Component && (
                            <modalConfig.Component
                                {...componentProps}
                                permissions={permissions}
                            />
                        )}
                    </fieldset>
                </FormProvider>
            )

            const primaryButtonText =
                modalConfig?.updationConfig?.primaryButtonText
            const secondaryButtonText =
                modalConfig?.updationConfig?.secondaryButtonText

            showDialog({
                title: modalConfig?.updationConfig?.header,
                maxWidth: modalConfig?.maxWidth,
                fullScreen: modalConfig?.fullScreen,
                body,
                onClose: () => {
                    modalConfig?.onClose?.() || onClose?.()
                    hideDialog()
                },
                hasCancelButton: modalConfig?.hasCancelButton ?? true,
                hideCloseIcon: modalConfig?.hideCloseIcon,
                hasFeedbackDialog: modalConfig?.hasFeedbackDialog,
                primaryButtonConfig: primaryButtonText
                    ? {
                          text: primaryButtonText,
                          onConfirm: () => {
                              methods.handleSubmit(() => {
                                  // callback function with form values for modal as arguments
                                  onConfirm(methods.getValues())
                                  hideDialog()
                              })()
                          }
                      }
                    : undefined,
                secondaryButtonConfig: secondaryButtonText
                    ? {
                          text: secondaryButtonText,
                          onConfirm: () => {
                              methods.handleSubmit(() => {
                                  hideDialog()
                              })()
                          }
                      }
                    : undefined,
                hideDialogActions:
                    methods?.formEditingState?.isReadOnly || readOnly
            })
        }

        return {
            openDialog,
            openPrepopulatedDialog,
            openConfirmationDialog,
            methods,
            openModalWithForm,
            hideDialog,
            openFeedbackDialog
        }
    }
}
