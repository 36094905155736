import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '@config'
import { Link } from '@components'
import { Grid, Divider as MuiDivider, Typography } from '@mui/material'
import { useCustomTheme, useHelmet } from '@hooks'
import { EmailIcon, PhoneIcon, ReloadIcon } from '@assets'
import { LearnerResourceType, TypographyVariants } from '@constants'
import { isMediumScreen } from '@utils'
import {
    ContainerGrid,
    IconBox,
    StyledBox,
    StyledDivider,
    StyledFormFrame,
    StyledTypography,
    StyledTypographyHeading
} from './style'

export const SupportForm = () => {
    const [reload, setReload] = useState(true)
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const { formiFrame } = useHelmet({
        embedForm: true,
        src: config.HUBSPOT_SRC,
        scriptBodyCallback: (config) => window.hbspt.forms.create(config),
        scriptBodyCallbackConfig: {
            region: 'na1',
            portalId: config.HUBSPOT_PORTAL_ID,
            formId: config.HUBSPOT_SUPPORT_FORM_ID
        },
        formContainerId: 'hubspot-form-container',
        reload
    })

    const RenderIcon = ({ data, icon, ...props }) => (
        <Grid container spacing={2} alignItems='center' sx={{ mb: 2 }}>
            <Grid item>
                <IconBox>{icon}</IconBox>
            </Grid>
            <Grid item xs>
                {props?.type === LearnerResourceType.EMAIL ? (
                    <Link
                        route={{
                            path: `mailto:${data}`,
                            label: data,
                            isExternalLink: true
                        }}
                        sx={{ cursor: 'pointer' }}
                    >
                        {' '}
                        <Typography
                            variant={TypographyVariants.body1}
                            color={theme.palette.backgroundBrandPrimary}
                        >
                            {data}
                        </Typography>
                    </Link>
                ) : (
                    (Array.isArray(data) ? data : [data]).map((item, index) => (
                        <Typography
                            key={index}
                            variant={TypographyVariants.body1}
                        >
                            {item}
                        </Typography>
                    ))
                )}
            </Grid>
        </Grid>
    )

    return (
        <ContainerGrid container spacing={4}>
            <Grid item xs={12}>
                <StyledTypographyHeading
                    variant={TypographyVariants.h1}
                    gutterBottom
                >
                    {t('support.helpHeading')}
                </StyledTypographyHeading>
                <StyledTypography variant={TypographyVariants.body1}>
                    {t('support.helpDescription')}
                </StyledTypography>
            </Grid>

            <Grid item xs={12}>
                <StyledDivider />
            </Grid>

            <Grid item container xs={12}>
                <Grid item xs={12} md={8}>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        <StyledTypographyHeading
                            variant={TypographyVariants.h2}
                        >
                            {t('support.supportTicket')}
                        </StyledTypographyHeading>
                        <div
                            onClick={() => setReload(!reload)}
                            style={{ cursor: 'pointer' }}
                        >
                            <ReloadIcon color={theme.palette.contentPrimary} />
                        </div>
                    </Grid>

                    <StyledFormFrame>{formiFrame}</StyledFormFrame>
                </Grid>

                {isMediumScreen() ? null : (
                    <Grid
                        item
                        xs={12}
                        md={0.5}
                        sx={{ display: { xs: 'none', md: 'block' } }}
                    >
                        <StyledDivider
                            orientation='vertical'
                            sx={{ height: '100%' }}
                        />
                    </Grid>
                )}

                <Grid item xs={12} md={3.5} sm={4}>
                    <StyledBox>
                        <StyledTypographyHeading
                            variant={TypographyVariants.h4}
                        >
                            {t('support.emailOrPhone')}
                        </StyledTypographyHeading>
                        <RenderIcon
                            icon={<EmailIcon width={16} height={16} />}
                            data={t('support.emailAddress')}
                            type={LearnerResourceType.EMAIL}
                        />
                        <RenderIcon
                            icon={<PhoneIcon />}
                            data={[
                                t('support.phoneNumber1'),
                                t('support.phoneNumber2')
                            ]}
                        />
                    </StyledBox>
                </Grid>
            </Grid>
        </ContainerGrid>
    )
}
