import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
    CheckCircleIcon,
    EditRecordIcon,
    PostTestIcon,
    PreTestIcon,
    PulseIcon,
    QuestionMarkIcon,
    TextMessageIcon
} from '@assets'
import { DELETE_SURVEY, GET_SURVEYS, UPDATE_SURVEY } from '@dts/graphql'
import { DetailedDirectoryLink, TextWithTooltip } from '@components'
import { useCustomTheme, useDirectory } from '@hooks'

import { SurveyTabEnum, UserPermissionEnum } from '@dts/constants'
import { SurveyTypeEnum } from '@dts/constants/lib'
import { addEntryToCache } from '@cacheql'
import { capitalize } from 'lodash'
import { formatDate } from '@dts/client-utils'
import { t } from 'i18next'

export const ManageSurveys = ({
    insightPanelId
}: {
    insightPanelId: SurveyTabEnum
}) => {
    const navigate = useNavigate()
    const { theme } = useCustomTheme()

    const refetchDirectory = () => {
        retrieve?.({
            variables: {
                options: queryOptions
            }
        })
    }

    const naviagateToAddSurvey = (
        surveyType: SurveyTypeEnum,
        surveyTitle: string
    ) => {
        addEntryToCache(surveyType, surveyTitle)
        navigate(`/surveys/new?surveyType=${surveyType}`)
    }

    const { directory, retrieve, queryOptions } = useDirectory({
        directoryConfig: {
            id: 'manageSurveys',
            config: {
                customActions: [
                    {
                        icon: (
                            <EditRecordIcon
                                color={theme.palette.contentSecondary}
                            />
                        ),
                        label: t('general.edit'),
                        showInMenu: true,
                        action: (params) =>
                            navigate(
                                `/surveys/${params?.id}?surveyType=${params?.row?.surveyType?.id}`
                            ),
                        permissions: [UserPermissionEnum.SurveyCreateModify]
                    },
                    {
                        icon: (
                            <CheckCircleIcon
                                color={theme.palette.contentSecondary}
                            />
                        ),
                        label: t('surveyResults.result'),
                        showInMenu: true,
                        action: (params) =>
                            navigate(
                                `/surveys/${params?.id}/results?surveyType=${params?.row?.surveyType?.id}`
                            )
                    }
                ],
                genericActions: {
                    archiveConfig: {
                        name: t('general.survey'),
                        mutation: UPDATE_SURVEY,
                        mutationName: 'updateSurvey',
                        onMutationSuccess: () => {
                            refetchDirectory?.()
                        },
                        hide: (params) =>
                            params?.row?.isArchived || params?.row?.isDraft,
                        permissions: [UserPermissionEnum.SurveyCreateModify]
                    },
                    restoreConfig: {
                        name: t('general.survey'),
                        mutation: UPDATE_SURVEY,
                        mutationName: 'updateSurvey',
                        onMutationSuccess: () => {
                            refetchDirectory?.()
                        },
                        hide: (params) => !params?.row?.isArchived,
                        permissions: [UserPermissionEnum.SurveyCreateModify]
                    },
                    deletionConfig: {
                        name: t('general.survey'),
                        mutation: DELETE_SURVEY,
                        mutationName: 'deleteSurvey',
                        onMutationSuccess: () => {
                            refetchDirectory?.()
                        },
                        hide: (params) =>
                            !params?.row?.isArchived && !params?.row?.isDraft,
                        permissions: [UserPermissionEnum.SurveyDelete]
                    }
                },
                columns: [
                    {
                        field: 'title',
                        headerName: t('sideNavItems.surveys'),
                        minWidth: 300,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={
                                    <DetailedDirectoryLink
                                        title={params?.value}
                                        tabIndex={params?.tabIndex}
                                        isDraft={params?.row?.isDraft}
                                        onClick={() => {
                                            navigate(
                                                `/surveys/${params?.id}?surveyType=${params?.row?.surveyType?.id}`
                                            )
                                        }}
                                        additionalInfo={[
                                            // This will be added later on
                                            // {
                                            //     icon: UsersIcon,
                                            //     text: `${
                                            //         params?.row
                                            //             ?.surveyCourseCount
                                            //     } ${t('general.responses')}`
                                            // },
                                            {
                                                icon: TextMessageIcon,
                                                text: [
                                                    params?.row?.responsesCount,
                                                    params?.row
                                                        ?.responsesCount === 1
                                                        ? t('general.response')
                                                        : t('general.responses')
                                                ].join(' ')
                                            },
                                            {
                                                icon: QuestionMarkIcon,
                                                text: [
                                                    params?.row?.questionsCount,
                                                    params?.row
                                                        ?.questionsCount === 1
                                                        ? t('general.question')
                                                        : t('general.questions')
                                                ].join(' ')
                                            }
                                        ]}
                                    />
                                }
                                toolTip={params?.value}
                                tabIndex={params?.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'surveyType',
                        headerName: t('general.type'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={params?.value?.title ?? '--'}
                                tabIndex={params?.tabIndex}
                            />
                        )
                    },
                    {
                        field: 'updatedAt',
                        headerName: t('general.dateModified'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={formatDate(params?.value)}
                                tabIndex={params?.tabIndex}
                            />
                        )
                    }
                ],
                hideFooter: false,
                pagination: true
            },
            enableSearch: true,
            toolbarConfig: {
                header: capitalize(status),
                ...(insightPanelId === SurveyTabEnum.PULSE && {
                    addNewButtonConfig: {
                        label: [
                            t('general.new'),
                            t('general.questionnaire')
                        ].join(' '),
                        action: () =>
                            naviagateToAddSurvey(
                                SurveyTypeEnum.Pulse,
                                [t('survey.pulse'), t('general.survey')].join(
                                    ' '
                                )
                            ),
                        permissions: [UserPermissionEnum.SurveyCreateModify]
                    }
                }),
                ...(insightPanelId === SurveyTabEnum.SURVEY && {
                    dropdownMenuConfig: {
                        label: [t('general.new'), t('general.survey')].join(
                            ' '
                        ),
                        menuConfig: [
                            {
                                icon: () => <PreTestIcon />,
                                label: [
                                    t('survey.preTest'),
                                    t('general.survey')
                                ].join(' '),
                                onClick: () =>
                                    naviagateToAddSurvey(
                                        SurveyTypeEnum.PreTest,
                                        [
                                            t('survey.preTest'),
                                            t('general.survey')
                                        ].join(' ')
                                    ),
                                permissions: [
                                    UserPermissionEnum.SurveyCreateModify
                                ]
                            },
                            {
                                icon: () => <PostTestIcon />,
                                label: [
                                    t('survey.postTest'),
                                    t('general.survey')
                                ].join(' '),
                                onClick: () =>
                                    naviagateToAddSurvey(
                                        SurveyTypeEnum.PostTest,
                                        [
                                            t('survey.postTest'),
                                            t('general.survey')
                                        ].join(' ')
                                    ),
                                permissions: [
                                    UserPermissionEnum.SurveyCreateModify
                                ]
                            }
                        ]
                    }
                })
            },
            query: GET_SURVEYS,
            queryName: 'getSurveys',
            queryVariables: {
                args: {
                    insightPanelId
                }
            }
        }
    })

    return directory
}
