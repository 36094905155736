import { gql } from '@apollo/client'

export const ArticleFragment = (on: string) => gql`
    fragment ArticleFragment on ${on} {
        id
      title
      description
      tags
      coverImage
      coverImageSrc
      topicIds
      topicsData {
        id
        title
      }
      creatorId
      creatorData {
        id
        firstName
        lastName
        avatar
        avatarSrc
      }
      isDraft
      isArchived
      isFeatured
      articleInteraction {
        article
        userId
        saved
        isHelpful
      }
      views
      createdAt
      updatedAt
        
    }
`
