export enum SurveyTabEnum {
  SURVEY = 'SURVEY',
  PULSE = 'PULSE',
  ARCHIVED = 'ARCHIVED',
}

export enum SurveySortColumnEnum {
  title = 'title',
  type = 'surveyType',
  updatedAt = 'updatedAt',
}
