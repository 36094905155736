import React, { createContext, FC, useState } from 'react'
import { LoaderContext } from '@context'
import { Loader } from '@components'

export const LoaderProvider: FC = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(false)

    return (
        <LoaderContext.Provider
            value={{
                loading,
                setLoading
            }}
        >
            {children}
        </LoaderContext.Provider>
    )
}
