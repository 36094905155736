import React from 'react'
import { startCase } from 'lodash'
import { FormLevelMessages } from '@dts/client-utils'

export const getErrorHelperText = (errors, name, t) => (
    <>
        {errors
            ? t(errors?.[name]?.message as string, {
                  value: startCase(name)
              })
            : null}
    </>
)

export const transformErrorsForForm = (errors: Array<object>) => {
    if (errors) {
        return errors.map((error) => ({
            field: [],
            key: error?.message,
            type: FormLevelMessages.ERRORS,
            isButtonHidden: true
        }))
    }
}
