import React from 'react'
import { useTranslation } from 'react-i18next'
import { GET_USER_BY_ID, SAVE_USER } from '@dts/graphql'
import { useModalWithGraphQL } from '@hooks'
import { formatDate, SignUpSchema } from '@dts/client-utils'
import { OperationVariables, QueryLazyOptions } from '@apollo/client'
import { LearnerProfileForm } from './edit-profile-form'

export const useEditLearnerProfile = (
    onMutationSuccess?: ((response?: unknown) => void) | undefined
) => {
    const { t } = useTranslation()

    const { openPrepopulatedDialog: editUserProfile } = useModalWithGraphQL({
        query: GET_USER_BY_ID,
        queryName: 'getUserById',
        retrieveOnMount: true,
        mapQueryResultToForm: ({
            firstName,
            lastName,
            email,
            dateOfBirth,
            avatarSrc,
            interests,
            customInterests,
            id
        }) => ({
            id,
            firstName,
            lastName,
            email,
            birthday: dateOfBirth,
            avatarUpload: avatarSrc,
            interests: interests ? interests.map((i) => i?.title) : [],
            customInterests: customInterests ?? []
        }),
        mutation: SAVE_USER,
        mutationName: 'saveUser',
        onMutationSuccess,
        initialValues: {
            id: null,
            firstName: '',
            lastName: '',
            email: '',
            birthday: null,
            avatarUpload: null,
            interests: [],
            customInterests: [],
            fileUploadKey: null
        },
        mapFormToMutationInput: ({
            firstName,
            lastName,
            email,
            fileUploadKey,
            birthday,
            id
        }) => ({
            id,
            avatar: fileUploadKey,
            firstName,
            lastName,
            email,
            dateOfBirth: formatDate(birthday)
        }),
        yupResolverSchema: SignUpSchema,
        modalConfig: {
            maxWidth: 'md',
            updationConfig: {
                primaryButtonText: t('general.save'),
                header: t('userProfile.editProfile')
            },
            Component: () => <LearnerProfileForm isLearner={false} />
        }
    })
    return { editUserProfile }
}
