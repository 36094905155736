import { gql } from '@apollo/client'

export const PreferenceSettingFragment = (on: string) => gql`
    fragment PreferenceSettingFragment on ${on} {
        addedToNewVertical
        appLanguage {
          id
          title
        }
        captionEnabled
        captionLanguage {
          id
          title
        }
        displayMode {
          id
          title
        }
        newTopicAssigned
        newlyUploadedVideos
        newsAndEvents
        pauseAll
        reminderEmails
        topicCompleted
    }
`
