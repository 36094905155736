import React from 'react'
import { useTranslation } from 'react-i18next'
import { Resolver } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    useGraphQL,
    SaveMode,
    Message,
    Response,
    UseFormWithGraphQLParams,
    useForm,
    addErrorsToForm
} from '@dts/client-utils'
import { can_access } from '@hoc'
import { useGraphQLWrapper } from './use-graphql-wrapper'

export const useFormFeedback = <
    FormType,
    QueryResult,
    MutationInput,
    QueryVariables = object
>({
    id,
    mode,
    query,
    mutation,
    mapQueryResultToForm,
    mapFormToMutationInput,
    mapPreservedFormInputs,
    queryName,
    mutationName,
    queryVariables,
    initialValues,
    onError,
    onMutationSuccess,
    onQuerySuccess,
    yupResolverSchema,
    isCustomMutationInput = false,
    retrieveOnMount = true,
    suppressToast = false,
    permissions = []
}: UseFormWithGraphQLParams<
    FormType,
    QueryResult,
    MutationInput,
    QueryVariables
>) => {
    const { t } = useTranslation()

    const resolver: Resolver | undefined =
        yupResolverSchema && yupResolver(yupResolverSchema)

    const methods = useForm({
        defaultValues: initialValues,
        resolver,
        mode: mode ?? 'all',
        disabled: false
    })

    const readOnly = permissions?.length > 0 && !can_access(permissions)

    const handleErrors = (message?: Message, meta?: object) => {
        // addErrorsToForm(methods, t, message, formErrorMap);
        // Future Implementations
    }

    const handleResponse =
        (
            callback?: (
                result?: Response<QueryResult>,
                mode?: SaveMode,
                formValue?: FormType
            ) => void
        ) =>
        (
            result?: Response<QueryResult>,
            message?: Message,
            mode?: SaveMode
        ) => {
            const formInput = mapQueryResultToForm?.(result?.data)
            const preservedFormInputs = mapPreservedFormInputs?.(
                methods.getValues() as FormType
            )
            methods.reset({
                ...formInput,
                ...preservedFormInputs
            })
            callback?.(result, mode)
        }

    const {
        data: graphQLData,
        currentData,
        retrieve,
        save: graphQLSave,
        mutationLoading,
        queryLoading
    } = useGraphQLWrapper<QueryResult, MutationInput, QueryVariables>({
        id,
        query,
        mutation,
        queryName,
        mutationName,
        queryVariables,
        isCustomMutationInput,
        onError,
        onMutationSuccess: handleResponse((result) =>
            onMutationSuccess?.(result)
        ),
        onQuerySuccess: handleResponse((result) => {
            methods?.formEditingState?.toggleReadOnly?.(readOnly)
            onQuerySuccess?.(result)
        }),
        onResponseMessages: handleErrors,
        retrieveOnMount,
        suppressToast
    })

    const save = async (extraData?: QueryVariables & { name?: string }) => {
        const formValues = methods.getValues() as FormType
        const mutationVariables = mapFormToMutationInput?.(
            formValues,
            extraData
        )
        return graphQLSave(mutationVariables, extraData)
    }

    return {
        data: graphQLData,
        currentData,
        save,
        methods,
        retrieve,
        mutationLoading,
        queryLoading
    }
}
