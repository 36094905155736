import { ResponseStatusEnum } from '../enums'

export interface Message {
  type: ResponseStatusEnum
  messages: string[]
  toast?: string
}

export interface Meta {
  count?: number
  verified?: boolean
  firstLogin?: boolean
  isPortalUser?: boolean
  userExists?: boolean
}

export interface Response<t> {
  data: t
  message: Message
  meta: Meta
}

export const RESPONSE: Response<any> = {
  data: null,
  message: {
    type: ResponseStatusEnum.Success,
    messages: [],
    toast: null,
  },
  meta: {
    verified: false,
    count: 0,
    isPortalUser: false,
    userExists: true
  },
}
