import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useFormState } from 'react-hook-form'
import { Grid, Typography, Button } from '@mui/material'
import { useS3 } from '@dts/client-utils'
import { RecordIcon, UploadIcon } from '@assets'
import { Loader, VideoPlayer } from '@components'
import { controlled } from '@hoc'
import { useCustomTheme } from '@hooks'
import { onKeyDown } from '@utils'
import { UploadBox } from './styles'

export const UploadVideo = ({ name, value }: { name: string }) => {
    const { theme } = useCustomTheme()
    const { setValue, getValues } = useFormContext()
    const { errors } = useFormState()
    const fileInputRef = React.useRef(null)
    const [dragOver, setDragOver] = useState(false)
    const [source, setSource] = useState(value)
    const [progress, setProgress] = useState(0)
    const { t } = useTranslation()

    const { initializeVideoUpload, key, videoId, isUploadingStart } = useS3({
        setProgress
    })
    const onDragOver = (e: React.SyntheticEvent) => {
        e.preventDefault()
        setDragOver(true)
    }
    const onDragLeave = () => {
        setDragOver(false)
    }
    const fileSelect = (e, dropped: boolean) => {
        e.preventDefault()

        let file: File
        if (dropped) {
            file = e.dataTransfer.files[0]
            setDragOver(false)
        } else {
            file = e.target.files[0]
        }
        const fileUrl = URL.createObjectURL(file)
        if (fileUrl !== source) {
            setSource('')
            setSource(fileUrl)
            initializeVideoUpload(file, getValues('id'))
        }
    }
    useEffect(() => {
        if (key) setValue(name, key)
    }, [key])

    useEffect(() => {
        if (videoId) setValue('videoId', videoId)
    }, [videoId])

    useEffect(() => {
        if (value && !key) setSource(value)
    }, [value])

    const labelClickHandler = () => {
        fileInputRef.current.click()
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <label htmlFor='drag'>
                    <UploadBox
                        onDragOver={onDragOver}
                        onDragLeave={onDragLeave}
                        onDrop={(e) => fileSelect(e, true)}
                        dragOver={dragOver}
                        preview={false}
                        sx={{ p: '32px 16px', position: 'relative' }}
                        hasError={!!errors?.[name]}
                    >
                        <Grid
                            item
                            sx={{ m: '24px' }}
                            display='flex'
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                        >
                            {isUploadingStart && <Loader />}
                            <Grid
                                item
                                borderRadius='50%'
                                p={2}
                                m={2}
                                lineHeight={0}
                                sx={{
                                    background: theme.palette.secondaryRust
                                }}
                            >
                                <UploadIcon />
                            </Grid>
                            <Typography variant='h3' mb={1}>
                                {t('video.upload.dragAndDrop.label')}
                            </Typography>
                            <Typography variant='caption' mb={1}>
                                {t('video.upload.dragAndDrop.description')}
                            </Typography>
                            <Button
                                variant='contained'
                                sx={{
                                    p: 0,
                                    color: theme.palette.contentPrimaryInverse
                                }}
                                onKeyDown={(e) => {
                                    onKeyDown(e, labelClickHandler, false)
                                }}
                            >
                                <label
                                    htmlFor='file'
                                    style={{
                                        cursor: 'pointer',
                                        padding: '8px 24px'
                                    }}
                                >
                                    {`${t('general.select')} ${t(
                                        'general.video'
                                    )} `}
                                </label>
                            </Button>
                        </Grid>
                    </UploadBox>
                </label>
                <input
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    type='file'
                    name={name}
                    id='file'
                    accept='video/*'
                    onChange={(e) => fileSelect(e, false)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <UploadBox
                    preview={!!source}
                    sx={{ ...(!source && { p: '20px 16px 44px 16px' }) }}
                    hasError={!!errors?.[name]}
                >
                    {!source ? (
                        <Grid
                            item
                            sx={{ m: '24px' }}
                            display='flex'
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                        >
                            <Grid item m={1} p={2} lineHeight={0}>
                                <RecordIcon />
                            </Grid>
                            <Typography variant='h3' mb={1}>
                                {t('video.upload.preview.label')}
                            </Typography>
                            <Typography variant='caption' mb={1}>
                                {t('video.upload.preview.description')}
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid>
                            <VideoPlayer
                                width='100%'
                                height='100%'
                                controls={true}
                                value={source}
                            />
                        </Grid>
                    )}
                </UploadBox>
            </Grid>
        </Grid>
    )
}

export const ControlledUploadVideo = controlled(UploadVideo)
