import { gql } from '@apollo/client'

export const SurveyFields = (on: string) => gql`
    fragment SurveyFields on ${on} {
      id
      title
      surveyType{
        id
        title
      }
      surveyTypeId
      summaryEnabled
      summaryTitle
      summaryDescription
      course{
        id
        title
      }
      topic{
        id
        title
      }
      summaryResponseIcon{
        id
        title
      }
      isDraft
      isArchived
       surveyQuestions {
    id
    title
    questionNumber
    questionType {
      id
      title
    }
    questionFeedbackType {
      id
      title
    }
    skill {
      id
      title
    }
    topic {
      id
      title
    }
    isHidden
    surveyOptions {
      id
      title
      isAnswer
      summaryDescription
      surveyRecommendations{
        recommendations{
            id
      recommendationType{
        id
        title
      }
      tags
      skills{
        id
        title
      }
        }
       
      }
      displayOrder
    }
    surveyQuestionResponses {
      responseType{
        id
        title
      }
      summaryTitle
      summaryDescription
      summaryResponseIcon{
        id
        title
      }
    }
    displayOrder
    isAnswered
    answeredOptions
    isCorrect
    summaryEnabled
    summaryTitle
    summaryDescription
    summaryResponseIcon {
      id
      title
    }
    hideFeedback
  }
      questionsCount
      responsesCount
      updatedAt
    }
`
