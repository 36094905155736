import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const PreTestIcon: React.FC<SvgProps> = ({
    color,
    width = 24,
    height = 24
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentPrimary
    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <line
                x1='3.75'
                y1='13.25'
                x2='21.25'
                y2='13.25'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12 2V10M8 6H16'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <line
                x1='3.75'
                y1='17.25'
                x2='21.25'
                y2='17.25'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <line
                x1='3.75'
                y1='21.25'
                x2='21.25'
                y2='21.25'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default PreTestIcon
