export enum SORT_DIRECTION {
    asc = 'ASC',
    desc = 'DESC'
}

export enum CustomActionConfigTypes {
    openDialog = 'openDialog',
    callMutation = 'callMutation',
    callWithForm = 'callWithForm'
}
