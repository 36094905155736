import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { UserProfileView } from '@components'
import { formatDate } from '@dts/client-utils'
import { GET_USER } from '@dts/graphql'
import { useFormFeedback, usePage } from '@hooks'
import { displayUserMultipleData } from '@utils'

export const mapUserDetailsToForm = (t, response) => ({
    avatarSrc: response?.avatarSrc,
    userDetails: [
        {
            label: t('general.firstName'),
            value: response?.firstName ?? ''
        },
        {
            label: t('general.lastName'),
            value: response?.lastName ?? ''
        },
        { label: t('general.email'), value: response?.email ?? '-' },
        { label: t('general.title'), value: response?.title ?? '-' },
        { label: t('general.school'), value: response?.organization ?? '-' },
        {
            label: t('general.birthday'),
            value: response?.dateOfBirth
                ? formatDate(response?.dateOfBirth)
                : null
        }
    ],
    additionalUserDetails: [
        {
            label: t('general.ethnicity'),
            value: displayUserMultipleData(response?.races)
        },
        {
            label: t('general.gender'),
            value: displayUserMultipleData(response?.genders)
        },
        {
            label: t('general.interests'),
            value: displayUserMultipleData([
                ...(response?.interests?.length ? [...response.interests] : []),
                ...(response?.customInterests?.length
                    ? [
                          ...response.customInterests.map(
                              (customInterest: string) => ({
                                  title: customInterest
                              })
                          )
                      ]
                    : [])
            ])
        }
    ]
})

export const ViewCreatorContainer = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { methods, mutationLoading, queryLoading } = useFormFeedback({
        query: GET_USER,
        queryName: 'getUser',
        initialValues: { userDetails: [], additionalUserDetails: [] },
        mapQueryResultToForm: (response) => mapUserDetailsToForm(t, response),
        retrieveOnMount: true
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: UserProfileView,
            hasHeader: true,
            preventPadding: true,
            preventMargin: true,
            pageHeaderConfig: {
                headerText: t('pageHeader.titleView'),
                primaryButtonTitle: t('general.edit'),
                primaryButtonAction: () => {
                    navigate('/edit-profile')
                }
            }
        }
    })

    return page
}
