import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const PlusBoxIcon: React.FC<SvgProps> = ({
    color,
    width = '36',
    height = '36',
    fill
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.backgroundPrimary
    const _fill = fill || theme.palette.backgroundBrandPrimary

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 36 36`}
            fill={_color}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4 0C1.79102 0 0 1.79102 0 4V32C0 34.209 1.79102 36 4 36H32C34.209 36 36 34.209 36 32V4C36 1.79102 34.209 0 32 0H4ZM18.8335 12C18.8335 11.4478 18.3857 11 17.8335 11C17.2812 11 16.8335 11.4478 16.8335 12V16.8335H12C11.4478 16.8335 11 17.2812 11 17.8335C11 18.3857 11.4478 18.8335 12 18.8335H16.8335V23.6665C16.8335 23.9248 16.9316 24.1606 17.0923 24.3379C17.2754 24.5396 17.5396 24.6665 17.8335 24.6665C18.3857 24.6665 18.8335 24.2188 18.8335 23.6665V18.8335H23.6665C24.2188 18.8335 24.6665 18.3857 24.6665 17.8335C24.6665 17.5405 24.5405 17.2773 24.3398 17.0942C24.1621 16.9321 23.9258 16.8335 23.6665 16.8335H18.8335V12Z'
                fill={_fill}
            />
        </svg>
    )
}
export default PlusBoxIcon
