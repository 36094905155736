import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_VIDEO_DROPDOWN_DATA = gql`
  ${MetaFragment('VideoDropdownsDataResponseDto')}
  ${MessagesFragment('VideoDropdownsDataResponseDto')}
  query {
    getVideoDropDownsData {
      ...MetaFragment
      ...MessagesFragment
      data {
        skills {
          id
          title
        }
        states {
          id
          title
        }
        topics {
          id
          title
        }
      }
    }
  }
`
