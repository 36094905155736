import { gql } from "@apollo/client";

export const SAVE_INITIAL_USER = gql`
  mutation saveInitialUser {
    saveInitialUser {
      data {
        id
        email
        roles {
          id
          title
        }
      }
      message {
        messages
        type
      }
    }
  }
`;
