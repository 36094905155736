import { Grid, styled } from '@mui/material'

export const Container = styled(Grid)`
    display: flex;
`

export const InfoContainer = styled(Grid)`
    display: flex;
    flex-direction: column;
`
