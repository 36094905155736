import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const StyledAlertIcon = styled(Box)`
    margin: 0.5rem 1rem 0 0;
    & svg {
        & path {
            stroke: ${({ color }) => (color ? color : ' !unset')};
        }
    }
`
