import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, SurveyFields } from '../../fragments'

export const GET_SURVEYS = gql`
  ${MessagesFragment('GetSurveyResponseDto')}
  ${MetaFragment('GetSurveyResponseDto')}
  ${SurveyFields('SurveyDto')}
  query getSurveys(
    $options: SurveyPaginationDto!
    $args: GetSurveyResponseArgumentsDto!
  ) {
    getSurveys(options: $options, args: $args) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...SurveyFields
      }
    }
  }
`
