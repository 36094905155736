import { gql } from '@apollo/client'
import { ArticleFields, MessagesFragment, MetaFragment } from '../../fragments'

export const GET_ARTICLES = gql`
  ${MetaFragment('ArticlesResponseDto')}
  ${MessagesFragment('ArticlesResponseDto')}
  ${ArticleFields('ArticleDataDto')}
  query ($args: ArticleArgumentsDto, $options: PaginationDto!) {
    getArticles(args: $args, options: $options) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...ArticleFields
      }
    }
  }
`
