import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const ReviewIcon: React.FC<SvgProps> = ({
    color,
    height = '20',
    width = '20',
    fill
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.backgroundBrandPrimary
    const _fill = fill || theme.palette.contentSecondary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox={`0 0 20 20`}
            fill='none'
        >
            <path
                d='M14.3333 17.8335C16.2662 17.8335 17.8333 16.2665 17.8333 14.3335C17.8333 12.4005 16.2662 10.8335 14.3333 10.8335C12.4003 10.8335 10.8333 12.4005 10.8333 14.3335C10.8333 16.2665 12.4003 17.8335 14.3333 17.8335Z'
                fill={_fill}
                fillOpacity='0.1'
            />
            <path
                d='M18.3333 18.3335L16.8333 16.8335M17.8333 14.3335C17.8333 16.2665 16.2662 17.8335 14.3333 17.8335C12.4003 17.8335 10.8333 16.2665 10.8333 14.3335C10.8333 12.4005 12.4003 10.8335 14.3333 10.8335C16.2662 10.8335 17.8333 12.4005 17.8333 14.3335Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M9 17.5C7.59987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5H13.5C14.9001 2.5 15.6002 2.5 16.135 2.77248C16.6054 3.01217 16.9878 3.39462 17.2275 3.86502C17.5 4.3998 17.5 6.76654 17.5 8.16667'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default ReviewIcon
