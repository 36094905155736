import React, { FC } from 'react'
import { useCustomTheme } from '@hooks'
import { Typography } from '@mui/material'
import { formatNumberToKMB } from '@dts/client-utils'
import { VideoAnalyticCardProps } from './types'
import { StyledVideoAnalyticCard } from './styles'

export const VideoAnalyticCard: FC<VideoAnalyticCardProps> = (props) => {
    const { count, title } = props || {}
    const { theme } = useCustomTheme()
    return (
        <StyledVideoAnalyticCard sx={{ maxHeight: '105px' }}>
            <Typography
                variant='body2'
                fontSize={14}
                color={theme.palette.contentSecondary}
                fontWeight={400}
            >
                {title}
            </Typography>
            <Typography
                variant='h2'
                fontSize={24}
                color={theme.palette.contentPrimary}
                fontWeight={700}
                ml={1}
                mt={1}
            >
                {formatNumberToKMB(count) || '0'}
            </Typography>
        </StyledVideoAnalyticCard>
    )
}
