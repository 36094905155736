import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_FILTER_DATA = gql`
  ${MessagesFragment('GenericQueryResponseDto')}
  ${MetaFragment('GenericQueryResponseDto')}
  query getFilterData($args: GetFilterDataArgumentsDto!) {
    getFilterData(args: $args) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
        title
      }
    }
  }
`
