import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const DELETE_COURSE = gql`
  ${MessagesFragment('CourseResponseDto')}
  ${MetaFragment('CourseResponseDto')}
  mutation DeleteCourse($deleteCourseId: String!) {
    deleteCourse(id: $deleteCourseId) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
      }
    }
  }
`
