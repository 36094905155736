import { gql } from '@apollo/client'

export const ArticleFields = (on: string) => gql`
    fragment ArticleFields on ${on} {
      id
      title
      description
      tags
      coverImage
      coverImageSrc
      isFeatured
      duration
      topicId
      duration
      topicData {
        id
        title
      }
      creatorId
      creatorData {
        id
        firstName
        lastName
      }
      isDraft
      isArchived
      isFeatured
      articleInteraction {
        article
        userId
        saved
        isHelpful
      }
      views
      createdAt
      updatedAt
    }
`
