import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const REMOVE_FILE = gql`
  ${MetaFragment('DeleteFileResponseDto')}
  ${MessagesFragment('DeleteFileResponseDto')}
  query removeFile($key: String!) {
    removeFile(key: $key) {
      ...MessagesFragment
      ...MetaFragment
      data {
        key
      }
    }
  }
`
