import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const PulseAnswerIcon: React.FC<SvgProps> = ({
    color,
    height = '20',
    width = '20'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 20 20'
            fill='none'
        >
            <rect
                x='2.5'
                y='3.33301'
                width='14.1667'
                height='14.1667'
                rx='3'
                stroke={_color}
                strokeWidth='1.5'
            />
            <path
                d='M11.6666 14.1667H5.83325M9.58325 10.8333H5.83325M13.3333 7.5H5.83325'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default PulseAnswerIcon
