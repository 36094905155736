import { useEffect, useState } from 'react'
import { OptionType } from '@types'
import { DocumentNode, getOperationAST } from 'graphql'
import { mapDropdownOptions } from '@dts/client-utils'
import { isEmpty } from 'lodash'
import { useCustomQuery } from './use-custom-query'

export interface DefaultType {
    label: string
    value: string
}

export const useRefValues = <V>(
    query: DocumentNode,
    queryVariables?: V,
    accessor?: string,
    optionAccessor?: string
): {
    dropdownOptions: Array<OptionType>
    queryLoading: boolean
} => {
    const operation = getOperationAST(query)
    const queryName = operation?.name?.value
    const [dropdownOptions, setDropdownOptions] = useState([])

    if (!queryName) {
        throw Error('query name wasnt determined')
    }

    const { data, queryLoading, retrieve } = useCustomQuery({
        query,
        queryName,
        retrieveOnMount: queryVariables ? false : true
    })

    useEffect(() => {
        if (queryVariables && !isEmpty(queryVariables)) {
            retrieve({ variables: { ...queryVariables } })
        }
    }, [queryVariables])

    useEffect(() => {
        const mappedData = accessor
            ? data?.[queryName]?.[accessor]
            : data?.[queryName]

        const mappedOptions = mapDropdownOptions(mappedData, optionAccessor)
        setDropdownOptions(mappedOptions)
    }, [data])

    return { queryLoading, dropdownOptions }
}
