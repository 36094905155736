import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const CheckCircleIcon: React.FC<SvgProps> = ({
    color,
    width = 24,
    height = 24
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox={`0 0 24 24`}
            fill='none'
        >
            <path
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M6.87504 11.0002L9.62504 13.7502L15.125 8.25016M20.1667 11.0002C20.1667 16.0628 16.0627 20.1668 11 20.1668C5.93743 20.1668 1.83337 16.0628 1.83337 11.0002C1.83337 5.93755 5.93743 1.8335 11 1.8335C16.0627 1.8335 20.1667 5.93755 20.1667 11.0002Z'
            />
        </svg>
    )
}
export default CheckCircleIcon
