import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Grid, IconButton, Typography } from '@mui/material'
import { DeleteIcon, DotMenuIcon, EditUserIcon } from '@assets'
import { isMediumScreen } from '@utils'
import { DropdownMenu } from '@components'
import { useCustomTheme } from '@hooks'
import { LearnerProfileProps } from './types'
import { leanerProfileData } from './data'
import { StyledTypography } from './styles'
import { UserPermissionEnum } from '@dts/constants'

export const LearnerProfile: FC<LearnerProfileProps> = (props) => {
    const { profileData, onEditProfile, onDeleteProfile } = props || {}
    const { t } = useTranslation()
    const { theme } = useCustomTheme()

    const menuConfig = [
        {
            icon: () => <EditUserIcon color={theme.palette.contentSecondary} />,
            label: t('userProfile.editProfile'),
            onClick: onEditProfile,
            permissions: [UserPermissionEnum.LearnersCreateModify]
        },
        {
            icon: () => <DeleteIcon color={theme.palette.errorDangerMedium} />,
            label: t('general.delete'),
            onClick: onDeleteProfile,
            permissions: [UserPermissionEnum.LearnersDelete]
        }
    ]

    return (
        <>
            <Grid
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                marginBottom={2}
            >
                <Typography
                    pt={1}
                    variant='h2'
                    fontSize={20}
                    color={theme.palette.contentPrimary}
                >
                    {t('sideNavItems.userProfile')}
                </Typography>
                <DropdownMenu
                    menuConfig={menuConfig}
                    MenuButton={() => (
                        <IconButton aria-label={t('general.actionDropdown')}>
                            <DotMenuIcon
                                color={theme.palette.contentSecondary}
                            />
                        </IconButton>
                    )}
                />
            </Grid>

            {leanerProfileData?.map((item, index) => (
                <>
                    <Grid container my={1} gap={isMediumScreen() ? 1 : 0}>
                        <Grid item md={3}>
                            <Typography
                                variant='body2'
                                fontSize={13}
                                fontWeight={500}
                                color={theme.palette.contentPrimary}
                            >
                                {t(item?.label)}
                            </Typography>
                        </Grid>
                        <Grid item md={9}>
                            <StyledTypography
                                variant='body2'
                                fontSize={14}
                                fontWeight={400}
                                color={theme.palette.contentPrimary}
                            >
                                {profileData[item?.value] ?? '--'}
                            </StyledTypography>
                        </Grid>
                    </Grid>
                    {leanerProfileData.length - 1 !== index && <Divider />}
                </>
            ))}
        </>
    )
}
