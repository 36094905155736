import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Article, Loader, ViewArticle } from '@components'
import { GET_ARTICLE, SAVE_ARTICLE_INTERACTION } from '@dts/graphql'
import { useGraphQLWrapper, useLoader } from '@hooks'
import { cloneDeep } from 'lodash'
import { isMediumScreen } from '@utils'
import { BackButtonType } from '@constants'

export const CreatorViewArticle = ({ showBreadcrumbs }) => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { loading } = useLoader()
    const [article, setArticle] = useState<Article | undefined>(undefined)
    const { queryLoading, data: articleData } = useGraphQLWrapper({
        query: GET_ARTICLE,
        queryName: 'getArticle',
        retrieveOnMount: true,
        queryVariables: { id }
    })

    const { save: saveArticleInteraction } = useGraphQLWrapper({
        mutation: SAVE_ARTICLE_INTERACTION,
        mutationName: 'saveArticleInteraction',
        isCustomMutationInput: true,
        onMutationSuccess: (res) => {
            const newArticle = cloneDeep(article) || {}
            newArticle.articleInteraction = res?.data

            setArticle(newArticle)
        }
    })

    useEffect(() => {
        if (!queryLoading && articleData) {
            setArticle(articleData?.data)
        }
    }, [queryLoading, articleData])

    return (
        <>
            {loading && <Loader />}
            <ViewArticle
                article={article}
                backButtonConfig={{
                    showBackButton: true,
                    backButtonType: BackButtonType.Hierarchical
                }}
                showBreadcrumbs={showBreadcrumbs}
                onSaveClick={() => {
                    saveArticleInteraction({
                        input: {
                            article: article?.id,
                            saved: !article?.articleInteraction?.saved
                        }
                    })
                }}
                saveIsHelpfulInteraction={(isHelpful) => {
                    saveArticleInteraction({
                        input: {
                            article: article?.id,
                            isHelpful
                        }
                    })
                }}
            />
        </>
    )
}
