enum FeedbackTypeEnum {
  REPORT_VIDEO = 'report_video',
  SHOW_LESS = 'show_less',
}

enum FeedbackTypeGraphqlEnum {
  'report_video' = 'report_video',
  'show_less' = 'show_less',
}

export { FeedbackTypeEnum, FeedbackTypeGraphqlEnum }
