import React, { useEffect, useState } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import { StyledContainer } from './styles'
import { TabPanelProps, ITab, CustomTabsProps } from './types'

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index } = props || {}

    return <>{value === index && <Box sx={{ p: 0 }}>{children}</Box>}</>
}

export const CustomTabs = ({ tabs, direction, tabValue }: CustomTabsProps) => {
    const [value, setValue] = useState(tabValue ?? 0)

    useEffect(() => {
        if (tabValue) {
            setValue(tabValue)
        }
    }, [tabValue])

    const handleChange = (_1, newValue: number) => {
        setValue(newValue)
    }
    return (
        <>
            <StyledContainer direction={direction}>
                <Tabs value={value} onChange={handleChange} aria-label='tabs'>
                    {tabs.map(
                        ({ id, headerLabel, onClick }: ITab, index: number) => (
                            <Tab
                                label={headerLabel}
                                id={`${id}-${index}`}
                                onClick={onClick}
                                key={index}
                                sx={{
                                    textTransform: 'capitalize',
                                    marginLeft: index === 0 && '14px',
                                    height: '14px'
                                }}
                            />
                        )
                    )}
                </Tabs>
            </StyledContainer>
            {tabs.map(({ body }: ITab, index: number) => (
                <TabPanel value={value} index={index} key={index}>
                    {body}
                </TabPanel>
            ))}
        </>
    )
}
