import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const UserCheckIcon: React.FC<SvgProps> = ({
    color,
    width = 24,
    height = 25
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.dullGreen

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox={`0 0 24 25`}
            color={_color}
            fill='none'
        >
            <path
                d='M16 18.5L18 20.5L22 16.5M11.9999 15.5C8.82977 15.5 6.01065 17.0306 4.21585 19.406C3.82956 19.9172 3.63641 20.1728 3.64273 20.5183C3.64761 20.7852 3.81521 21.1219 4.02522 21.2867C4.29704 21.5 4.67372 21.5 5.42708 21.5H13.5M16.4999 8C16.4999 10.4853 14.4851 12.5 11.9999 12.5C9.51457 12.5 7.49985 10.4853 7.49985 8C7.49985 5.51472 9.51457 3.5 11.9999 3.5C14.4851 3.5 16.4999 5.51472 16.4999 8Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default UserCheckIcon
