import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { Panel } from '@components'
import { Button, Grid, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { useCustomTheme, useGraphQLWrapper, useReport } from '@hooks'
import { BreakdownIcon, FilterIcon, HelpTextIcon, MetricIcon } from '@assets'
import { GET_REPORT_META_DATA } from '@dts/graphql/lib'
import { cloneDeep, omit } from 'lodash'
import { REPORT_TIME_FRAME } from '@constants'
import { StyledBox } from '@components/Panel/styles'
import { can_access } from '@hoc'
import { UserPermissionEnum } from '@dts/constants'
import { SearchableMetricsList } from './searchable-metrics-list'
import { StyledMetricsContainer, StyledPanelButton } from './styles'

const renderMetricAccordionSummary = (title, icon, helpText) => (
    <Grid container gap={1} alignItems={'center'}>
        <Grid item display={'flex'}>
            {icon}
        </Grid>
        <Grid item>
            <Typography variant='h4' position={'relative'} fontSize={'16px'}>
                {title}
            </Typography>
        </Grid>

        <Grid item ml={1} display={'flex'}>
            <Tooltip
                title={helpText}
                placement='top'
                tabIndex={0}
                sx={{
                    fontWeight: 'normal',
                    fontSize: '1.5rem'
                }}
            >
                <Grid position={'relative'}>
                    <HelpTextIcon />
                </Grid>
            </Tooltip>
        </Grid>
    </Grid>
)

export const handlePlotChart = (plotchartConfig) => {
    const {
        handleSubmit,
        plotChart,
        metrics,
        filters,
        reportGranularity,
        reportTimeFrame,
        breakdown,
        fromDate,
        toDate
    } = plotchartConfig
    handleSubmit(() => {
        plotChart?.({
            variables: {
                input: {
                    metricIds: metrics
                        ?.map((metric) => {
                            if (metric.isAdded) {
                                return metric.metricId
                            }
                        })
                        ?.filter(Boolean),
                    filters: filters
                        ?.map((filter) => {
                            if (filter.isAdded) {
                                return {
                                    filterId: filter.metricId,
                                    value: filter.selectedData
                                }
                            }
                        })
                        ?.filter(Boolean),
                    reportTimeFrame: {
                        reportTimeFrameId: reportTimeFrame,
                        fromDate,
                        toDate
                    },
                    reportGranularityId: reportGranularity,
                    breakdownIds: breakdown
                        ?.map((breakdownItem) => {
                            if (breakdownItem.isAdded) {
                                return breakdownItem.metricId
                            }
                        })
                        ?.filter(Boolean)
                }
            }
        })
    })?.()
}

export const MetricsPanel = ({ plotChart, showDrawer, reportMetaData }) => {
    const { theme } = useCustomTheme()
    const { t } = useTranslation()

    const {
        setValue,
        control,
        getValues,
        handleSubmit,
        formState: { errors, dirtyFields }
    } = useFormContext()

    const editReportMetaData = useWatch({
        control,
        name: 'editReportMetaData'
    })

    useEffect(() => {
        if (reportMetaData) {
            const updatedReportMetaData = omit(reportMetaData, '__typename')

            // Initialize lookup maps
            const editMetricsMap = new Map()
            const editFiltersMap = new Map()
            const editBreakdownsMap = new Map()

            // Populate lookup maps if editReportMetaData is available
            if (editReportMetaData) {
                editReportMetaData?.metric.forEach((item) =>
                    editMetricsMap?.set(item.id, item)
                )
                editReportMetaData?.filter.forEach((item) =>
                    editFiltersMap?.set(item.id, item)
                )
                editReportMetaData?.breakdown.forEach((item) =>
                    editBreakdownsMap?.set(item.id, item)
                )
            }

            // Update each category in updatedReportMetaData
            Object.keys(updatedReportMetaData).forEach((categoryKey) => {
                updatedReportMetaData[categoryKey] = updatedReportMetaData[
                    categoryKey
                ]?.map((metricItem) => {
                    let isAdded = false
                    let selectedData = []
                    let preopenMenu = false

                    if (categoryKey === 'metric') {
                        const editMetric = editMetricsMap.get(metricItem.id)
                        isAdded = !!editMetric
                    } else if (categoryKey === 'filter') {
                        const editFilter = editFiltersMap.get(metricItem.id)
                        isAdded = !!editFilter
                        if (isAdded && editFilter.value) {
                            selectedData = editFilter.value
                        }
                    } else if (categoryKey === 'breakdown') {
                        const editBreakdown = editBreakdownsMap.get(
                            metricItem.id
                        )
                        isAdded = !!editBreakdown
                    }

                    preopenMenu = isAdded

                    return {
                        ...metricItem,
                        isAdded,
                        metricId: metricItem.id,
                        dropdownData: [], // Populate dropdownData as per your application logic
                        selectedData,
                        preopenMenu,
                        filterTypeId: metricItem?.reportFilterType?.id
                    }
                })
            })

            // Update state

            if (
                updatedReportMetaData &&
                !(
                    Object.keys(dirtyFields)?.includes?.('metrics') ||
                    Object.keys(dirtyFields)?.includes?.('filters') ||
                    Object.keys(dirtyFields)?.includes?.('breakdown')
                )
            ) {
                setValue('metrics', updatedReportMetaData?.metric)
                setValue('filters', updatedReportMetaData?.filter)
                setValue('breakdown', updatedReportMetaData?.breakdown)
            }
        }
    }, [reportMetaData, editReportMetaData])

    const metrics = useWatch({
        control,
        name: 'metrics'
    })

    const reportGranularity = useWatch({
        control,
        name: 'reportGranularity'
    })

    const filters = useWatch({
        control,
        name: 'filters'
    })

    const breakdown = useWatch({
        control,
        name: 'breakdown'
    })
    const reportTimeFrame = useWatch({
        control,
        name: 'reportTimeFrame'
    })
    const customDateRange = useWatch({
        control,
        name: 'customDateRange'
    })

    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )

    return (
        <StyledMetricsContainer container xs={12} sx={{ display: 'contents' }}>
            <Grid item sx={{ overflow: 'hidden', overflowY: 'auto' }} flex={1}>
                <Panel
                    panels={[
                        {
                            id: '1',
                            bodySx: { padding: '10px' },
                            renderElementAtTitle: () =>
                                renderMetricAccordionSummary(
                                    t('general.metrics'),
                                    <MetricIcon width={'20'} height={'20'} />,
                                    t('report.metric.helpText')
                                ),
                            body: (
                                <SearchableMetricsList
                                    key={'1'}
                                    name={'metrics'}
                                    listType={t('general.metrics')}
                                    error={errors?.metrics?.message}
                                />
                            )
                        },
                        {
                            id: '2',
                            bodySx: { padding: '10px' },
                            renderElementAtTitle: () =>
                                renderMetricAccordionSummary(
                                    t('general.filters'),
                                    <FilterIcon width={20} height={20} />,
                                    t('report.filter.helpText')
                                ),
                            body: (
                                <SearchableMetricsList
                                    key={'2'}
                                    name={'filters'}
                                    listType={t('general.filters')}
                                    maxHeight='200px'
                                />
                            )
                        },
                        {
                            id: '3',
                            bodySx: { padding: '10px' },
                            renderElementAtTitle: () =>
                                renderMetricAccordionSummary(
                                    t('general.breakdown'),
                                    <BreakdownIcon />,
                                    t('report.breakdown.helpText')
                                ),
                            body: (
                                <SearchableMetricsList
                                    key={'3'}
                                    name={'breakdown'}
                                    listType={`${t('general.breakdown')} ${t(
                                        'general.filters'
                                    )}`}
                                />
                            )
                        }
                    ]}
                    // To-do: This will be removed later on
                    // showSubtitleOnCollapsed={true}
                    // showBatchActions={false}
                    backgroundColor={theme.palette.BrandPrimaryContainer}
                    allowMultipleOpen={true}
                    isExpanded='1'
                />
            </Grid>
            <Grid item>
                <StyledPanelButton
                    onClick={() => {
                        handlePlotChart({
                            handleSubmit,
                            plotChart,
                            metrics,
                            filters,
                            breakdown,
                            reportGranularity,
                            reportTimeFrame,
                            fromDate:
                                reportTimeFrame === REPORT_TIME_FRAME.CUSTOM
                                    ? customDateRange[0]
                                    : null,
                            toDate:
                                reportTimeFrame === REPORT_TIME_FRAME.CUSTOM
                                    ? customDateRange[1]
                                    : null
                        })
                        setValue('isApplied', true)
                        showDrawer?.()
                    }}
                    fullWidth
                    disabled={
                        !can_access([UserPermissionEnum.ReportCreateModify])
                    }
                >
                    {t('general.apply')}
                </StyledPanelButton>
            </Grid>
        </StyledMetricsContainer>
    )
}
