import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_TAGS = gql`
  ${MessagesFragment('TagsSearchResponseDto')}
  ${MetaFragment('TagsSearchResponseDto')}
  query getTags($options: TagsSearchDto) {
    getTags(options: $options) {
      ...MessagesFragment
      ...MetaFragment
      data {
        title
      }
    }
  }
`
