import { gql } from '@apollo/client'
import {
  MessagesFragment,
  MetaFragment,
  AddLearnerFields,
} from '../../fragments'

export const ADD_LEARNER = gql`
  ${MetaFragment('AddLearnerResponseDto')}
  ${MessagesFragment('AddLearnerResponseDto')}
  ${AddLearnerFields('AddLearnerDto')}
  mutation addLearner($input: AddLearnerInputDto!) {
    addLearner(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...AddLearnerFields
      }
    }
  }
`
