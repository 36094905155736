import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFormFeedback, usePage } from '@hooks'
import { ADD_INFO_TO_USER, GET_USER, GET_USER_BY_ID } from '@dts/graphql'
import { setUserData } from '@cacheql'
import { SignUpSchema } from '@dts/client-utils'
import { LearnerProfileForm } from './edit-profile-form'

export const LearnerEditProfileContainer = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { methods, save, mutationLoading, queryLoading } = useFormFeedback({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            birthday: null,
            avatarUpload: null,
            interests: [],
            customInterests: [],
            fileUploadKey: null
        },
        mapFormToMutationInput: ({
            firstName,
            lastName,
            interests,
            customInterests,
            fileUploadKey,
            birthday
        }) => ({
            input: {
                avatar: fileUploadKey,
                firstName,
                lastName,
                interests: interests?.filter(
                    (interest) => !customInterests?.includes(interest)
                ),
                customInterests,
                dateOfBirth: birthday
            }
        }),
        mutation: ADD_INFO_TO_USER,
        mutationName: 'addInformationToUser',
        isCustomMutationInput: true,
        onMutationSuccess: (response) => {
            const user = response?.data
            setUserData({
                ...setUserData(),
                id: user?.id,
                firstName: user?.firstName,
                lastName: user?.lastName,
                avatarSrc: user?.avatarSrc,
                email: user?.email,
                organization: user?.organization
            })

            navigate('../')
        },
        query: GET_USER,
        queryName: 'getUser',
        retrieveOnMount: true,
        mapQueryResultToForm: ({
            firstName,
            lastName,
            email,
            dateOfBirth,
            avatarSrc,
            interests,
            customInterests,
            id
        }) => ({
            id,
            firstName,
            lastName,
            email,
            birthday: dateOfBirth,
            avatarUpload: avatarSrc,
            interests: [
                ...(interests ? [...interests.map((i) => i?.id)] : []),
                ...(customInterests ?? [])
            ],
            customInterests
        }),
        yupResolverSchema: SignUpSchema
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: LearnerProfileForm,
            componentProps: { isLearner: true },
            hasHeader: true,
            preventMargin: true,
            showBreadcrumbs: true,
            pageHeaderConfig: {
                headerText: t('userProfile.editProfile'),
                primaryButtonTitle: t('general.save'),
                primaryButtonAction: () => methods.handleSubmit(() => save())(),
                secondaryButtonTitle: t('general.cancel'),
                secondaryButtonAction: () => navigate('../')
            }
        }
    })

    return <>{page}</>
}
