import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const PencilIcon: React.FC<SvgProps> = ({ color, height = 18, width = 18 }) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            width={width}
            height={height}
            viewBox={'0 0 24 24'}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M3.92096 16.7009C3.96691 16.2874 3.98988 16.0807 4.05244 15.8874C4.10794 15.716 4.18635 15.5528 4.28556 15.4024C4.39738 15.2328 4.54447 15.0857 4.83865 14.7916L15.6247 4.74322C16.7293 3.63865 18.5202 3.63865 19.6247 4.74322C20.7293 5.84779 20.7293 7.63865 19.6247 8.74322L8.83865 18.7916C8.54447 19.0857 8.39738 19.2328 8.22781 19.3446C8.07737 19.4438 7.91421 19.5223 7.74277 19.5778C7.54953 19.6403 7.34278 19.6633 6.92929 19.7092L3.54492 20.0853L3.92096 16.7009Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default PencilIcon
