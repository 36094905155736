import {
  FILE_UPLOAD,
  FINALIZE_MULTIPART_UPLOAD,
  GET_MULTIPART_PRE_SIGNED_URLS,
  INITIALIZE_VIDEO_UPLOAD,
} from '../../../graphql/lib'
import React, { useEffect, useState } from 'react'
import { CHUNK_SIZE } from '../constants'
import { useGraphQL } from './use-graphql'
import { ResponseStatusEnum } from '../../../constants/lib'

type InitializeVideoResponse = {
  fileId: string
  fileKey: string
}
type GetSignedUrlsResponse = {
  signedUrl: string
  PartNumber: number
}
type finalizeUpload = {
  PartNumber: number
  ETag: string
}

type FinalResponse = {
  location: string
  key: string
  videoId: string
}

type FileUploadResponse = {
  signedUrl: string
}

export const useS3 = ({
  setProgress,
  getChunks,
  isMobile,
  isAttachment = false,
  forPublicBucket = false,
}: {
  setProgress: React.Dispatch<React.SetStateAction<number>>
  getChunks: (props: any) => Array<object>
  isMobile: boolean
  isAttachment?: boolean
  forPublicBucket?: boolean
}) => {
  const [file, setFile] = useState<File>()
  const [key, setKey] = useState<string>('')
  const [location, setLocation] = useState<string>('')
  const [isUploadingStart, setIsUploadingStart] = useState(false)
  const [s3Object, setS3Object] = useState<{
    key: string
    signedUrl: string
    file: string
    fileName: string
  }>()
  const [videoId, setVideoId] = useState<string>(null)

  const [initializeVideoResponse, setInitializeVideoResponse] =
    useState<InitializeVideoResponse>()
  const [multiPartResponse, setMultiPartResponse] =
    useState<Array<GetSignedUrlsResponse>>()
  const {
    retrieve: initialize,
    currentData,
    queryLoading: initializeLoading,
  } = useGraphQL<InitializeVideoResponse, unknown, unknown>({
    query: INITIALIZE_VIDEO_UPLOAD,
    queryName: 'initializeMultipartUpload',
    retrieveOnMount: false,
  })
  const {
    retrieve: getSignedUrls,
    currentData: urls,
    queryLoading: multipartPreSignedUrlsLoading,
  } = useGraphQL({
    query: GET_MULTIPART_PRE_SIGNED_URLS,
    queryName: 'getMultipartPreSignedUrls',
    retrieveOnMount: false,
  })
  const {
    retrieve: finalize,
    currentData: finalResponse,
    queryLoading: finalResponseLoading,
  } = useGraphQL<FinalResponse, unknown, unknown>({
    query: FINALIZE_MULTIPART_UPLOAD,
    queryName: 'finalizeMultipartUpload',
    retrieveOnMount: false,
  })
  const { retrieve: fileUpload, queryLoading: fileUploadLoading } = useGraphQL<
    FileUploadResponse,
    unknown,
    unknown
  >({
    query: FILE_UPLOAD,
    queryName: 'fileUpload',
    retrieveOnMount: false,
  })

  // function to empty S3 object
  const emptyS3Object = () => {
    setS3Object(
      {} as {
        key: string
        signedUrl: string
        file: string
        fileName: string
      },
    )
  }

  // Below is the code for image upload

  /**
   * Below is the summary for video upload process:
   * The 'initializeVideoUpload' function is called from component level where it takes the file and videoId as arguments
   * and sends a call to 'initializeMultipartUpload' with just the fileName.
   * Once 'initializeMultipartUpload' gives a successful response, we set that response to a state variable which is to be used in
   * the final call and then a call is sent to 'getMultipartPreSignedUrls',
   * Once the call to 'getMultipartPreSignedUrls' is successful, the 'uploadVideoToS3' function is called where we convert this array
   * of signedUrls and part numbers to an array of etags and respective part numbers (by the fetch call using signedUrl for each part)
   * Once this array of eTags is created, a final call is sent to 'finalizeMultipartUpload' with the fileKey and fileId returned from
   * 'initializeMultipartUpload', mapped eTags array and the videoId in case of edit video.
   */

  /**
   * Step 1 for video upload. The function is responsible for initializing video upload process on backend.
   * @param fileUrl The video file being uploaded
   * @param id The id of the video (passed in case of edit video in order to prevent duplicate drafts)
   */
  const initializeVideoUpload = (fileUrl: File, id: string) => {
    setIsUploadingStart(true) // setIsUploadingStart true for loader
    setFile(fileUrl) // Setting the file being uploaded in a state variables in order to be used further
    setVideoId(id) /// /Setting the id of the video being edited in a state variables in order to be used further
    const fileName = fileUrl?.name // Extracting the name of the file from the File object

    // Initializing the video upload by passing a fileName and getting
    initialize?.({
      variables: {
        input: {
          name: fileName,
          forPublicBucket,
        },
      },
    })
  }

  const uploadVideoToS3 = async () => {
    // This code block runs after we successfully have an array of the signed urls and their respective part numbers
    let index = 0
    let mobileChunks = []
    const uploadedEtags: Array<finalizeUpload> = []
    if (isMobile) {
      mobileChunks = await getChunks({ file, part: multiPartResponse })
    }

    // This loop runs for each signed url and its respective part in order to fetch the e-tag from s3 using the signed url
    // and then push it into an e-tag array with its part number.
    for (const part of multiPartResponse as Array<GetSignedUrlsResponse>) {
      let chunk: any = []
      const sentSize = (part?.PartNumber - 1) * CHUNK_SIZE
      if (isMobile) {
        chunk = mobileChunks[index]
      } else {
        chunk = file?.slice(sentSize, sentSize + CHUNK_SIZE)
      }

      index += 1
      setProgress((index / (multiPartResponse?.length as number)) * 100) // Used for the progress bar being displayed on the UI while uploading video
      try {
        const response = await fetch(part.signedUrl, {
          method: 'PUT',
          body: chunk,
          headers: {
            'Content-Type': '', // Specify the content type
          },
        })

        // If response from s3 is successful, push the eTag and part number to the array to be passed to 'finalizeVideoUpload' call
        if (response.ok) {
          const etagValue = response.headers.get('ETag')
          uploadedEtags.push({
            ETag: (etagValue as string)?.replaceAll('"', ''),
            PartNumber: part.PartNumber,
          })
        } else {
          // eslint-disable-next-line no-console
          console.error('Error uploading part:', response.statusText)
          // You might want to handle the error appropriately, e.g., retry or abort the upload.
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error uploading part:', error)
        // Handle errors, e.g., retry or abort the upload.
      }
    }

    finalizeVideoUpload(uploadedEtags) // Once all the etags are successfully fetched, send a call to 'finalizeMultipartUpload'
    if (multiPartResponse && index >= multiPartResponse?.length) {
      setProgress(0) // Set the progress bar on UI to 0 when video upload call is finalized
    }
  }

  const finalizeVideoUpload = (uploadedEtags: Array<finalizeUpload>) => {
    // Input for 'finalizeMultipartUpload' consisting of the fileId and fileKey from 'initializeMultipartUpload' response and the generated etags
    const videoFinalizationMultiPartInput = {
      fileId: initializeVideoResponse?.fileId,
      fileKey: initializeVideoResponse?.fileKey,
      parts: uploadedEtags,
      videoId,
      isAttachment,
      forPublicBucket,
    }

    // Sending the final call to 'finalizeMultipartUpload' in order to complete the process for video upload
    finalize({
      variables: {
        input: videoFinalizationMultiPartInput,
      },
    })
  }

  useEffect(() => {
    // This block of code runs after 'initializeMultipartUpload' successfully executes and returns data
    if (currentData) {
      setInitializeVideoResponse({
        ...currentData,
      }) // Setting the response that returns a fileId and a fileKey being used in s3 in a state variable to be used later on

      // Call for 'getMultipartPreSignedUrls' where we pass the key and Id of the file and the number of chunks required
      getSignedUrls({
        variables: {
          input: {
            fileId: currentData?.fileId,
            fileKey: currentData?.fileKey,
            numberOfParts: Math.ceil(file?.size / CHUNK_SIZE),
            forPublicBucket,
          },
        },
      })
    }
  }, [currentData])

  useEffect(() => {
    // This block of code runs after 'getMultipartPreSignedUrls' successfully executes and returns data
    setMultiPartResponse(urls as Array<GetSignedUrlsResponse>) // Setting the signed urls (signed url and part number object) to a state variable
  }, [urls])

  useEffect(() => {
    // This code runs after successful execution of 'getMultipartPreSignedUrls' depending on the response
    if (multiPartResponse) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      uploadVideoToS3()
    }
  }, [multiPartResponse])

  useEffect(() => {
    if (finalResponse) {
      setKey(finalResponse?.key)
      setLocation(finalResponse?.location)
      setVideoId(finalResponse?.videoId)
      setIsUploadingStart(false)
    }
  }, [finalResponse])

  // Below is the code for image upload
  /**
   * Step 2 for image upload
   * @param url The signedUrl for the image being uploaded
   * @param file The actual file being uploaded
   * @returns The response of the fetch signedUrl call if successful, otherwise null
   */
  const uploadFileToS3 = async (url, file: File & { buffer?: Uint8Array }) => {
    try {
      const response = await fetch(url, {
        method: 'PUT',
        body: !isMobile
          ? file?.slice(0, file?.size)
          : (file?.buffer as Uint8Array),
      })
      setKey(file.name)
      return response
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error uploading file:', e)
    }
    return null
  }

  /**
   * Step 1 for image upload
   * This function is used to upload an image file.
   * @param file The file that is uploaded in jpg, png format.
   * @param extraInput The extra veriables to be passed to fileUpload mutation call
   * @returns the final response after uploading file on backend as well as s3. Uses await statements to ensure
   * that response is only returned when all the upload calls are successful. In case of failure, returns null
   */
  const uploadFile = async (file: File, extraInput: object) => {
    setIsUploadingStart(true)
    const fileName = file.name
    setFile(file)

    // Mutation to upload the filename (e.g, image.jpg) alongwith the extra input variables such as userId.
    const response: any = await fileUpload({
      variables: {
        input: {
          fileName,
          forPublicBucket,
          ...extraInput,
        },
      },
    })

    const res = response?.data?.fileUpload
    if (res?.message?.type === ResponseStatusEnum.Success) {
      // Checks if the response from backend was successful, uploads the file on s3 and returns fetched data with the signedUrl.
      const uploadResponse: any = await uploadFileToS3(
        res?.data?.signedUrl,
        file,
      )
      /**
       * If the response from s3 is also successful, creates an s3Object having information about the image
       * file uploaded so that the hook can return it.
       */
      if (uploadResponse?.status === 200) {
        setS3Object({
          key: res?.data?.key,
          signedUrl: res?.data?.signedUrl,
          file: URL.createObjectURL(file),
          fileName: file?.name,
        })
        setIsUploadingStart(false)
      }
      uploadResponse.key = res?.data?.key
      return uploadResponse
    }

    return null
  }

  return {
    initializeVideoUpload,
    uploadFile,
    emptyS3Object,
    key,
    videoId,
    s3Object,
    location,
    fileUploadLoading,
    finalResponseLoading,
    multipartPreSignedUrlsLoading,
    initializeLoading,
    isUploadingStart,
  }
}
