import { Card, Grid, Typography, styled } from '@mui/material'
import styledComponent from '@emotion/styled'

export const CardBodyCount = styled(Typography)(({ theme }) => ({
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '130%',
    letterSpacing: '-0.48px',
    color: theme.palette.contentLink
}))

export const StyledHeader = styled(Grid)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.backgroundPrimary,
    borderBottom: `1px solid ${theme.palette.borderSecondary}`
}))

export const Container = styled(Grid)`
    padding: 16px;
`

export const VideoGrid = styled(Grid)`
    align-items: center;
    display: flex;
    flex-direction: column;
`

export const StyledCard = styled(Card)`
    display: flex;
    width: 186px;
    height: 300px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
`

export const CoverImage = styledComponent.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const CountLabel = styledComponent(Grid)`
  position: absolute;
  bottom: 8px;
  left: 8px;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.875rem;
`

export const InfoGrid = styled(Grid)``

export const AnalyticsGrid = styled(Grid)``

export const EngagementDetailsGrid = styled(Grid)(({ theme }) => ({
    marginTop: '16px',
    borderRadius: '12px',
    border: `1px solid ${theme.palette.backgroundTertiary}`,
    padding: '16px'
}))

export const RowListGRid = styled(Grid)``

export const StyledVideoAnalyticCard = styled(Card)(({ theme }) => ({
    cursor: 'pointer',
    padding: '24px',
    border: `1px solid ${theme.palette.backgroundTertiary}`,
    backgroundColor: theme.palette.backgroundPrimary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '12px'
}))
