import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'

import { Typography } from '@mui/material'
import { VerticalsStatus } from '@constants'
import { useGraphQLWrapper, useModalWithGraphQL } from '@hooks'
import {
    BaseContainer,
    ComponentContainer,
    ParentContainer,
    InitialDetails,
    TypographyContainer,
    ControlledSelect,
    ConfirmationDialog
} from '@components'
import { GET_VERTICAL, GET_VERTICALS } from '@dts/graphql/lib'
import { mapDropdownOptions } from '@dts/client-utils'

export const VerticalDetails: React.FC = () => {
    const { t } = useTranslation()
    const { setValue, getValues, control } = useFormContext()

    const { currentData: verticalsData } = useGraphQLWrapper({
        query: GET_VERTICALS,
        queryName: 'getVerticals',
        queryVariables: {
            args: {
                tab: VerticalsStatus.Templates,
                verticalId: getValues('id')
            }
        }
    })

    const previousTemplate = useWatch({
        control,
        name: 'previousTemplate'
    })

    const template = useWatch({
        control,
        name: 'template'
    })
    const { currentData: verticalData, retrieve } = useGraphQLWrapper({
        query: GET_VERTICAL,
        queryName: 'getVertical',
        retrieveOnMount: false
    })
    const { openModalWithForm } = useModalWithGraphQL({
        modalConfig: {
            maxWidth: 'sm',
            onClose: () => {
                setValue('template', null)
            },
            Component: () => (
                <ConfirmationDialog
                    message={`${t(
                        'vertical.templateChangeConfirmation'
                    )}\n\n${t('general.proceedMessage')}`}
                />
            ),
            updationConfig: {
                primaryButtonText: t('general.confirm'),
                header: `${t('general.confirmation')}`
            },
            hasCancelButton: true
        }
    })
    useEffect(() => {
        if (template && template !== previousTemplate) {
            setValue('previousTemplate', template)
            openModalWithForm(undefined, () => {
                retrieve({ variables: { id: template } })
            })
        }
    }, [template])

    useEffect(() => {
        if (verticalData) {
            setValue(
                'skills',
                verticalData?.skills.reduce((acc, curr) => {
                    acc[curr.id] = true
                    return acc
                }, {})
            )
            setValue('addedCourses', verticalData?.courses)
            setValue('addedVideos', verticalData?.videos)
            setValue('tags', [...(verticalData?.tags ?? [])])
        }
    }, [verticalData])

    return (
        <ParentContainer container>
            <Typography variant='h1'>{t('general.about')}</Typography>

            <BaseContainer item>
                <TypographyContainer item md={2.5}>
                    <Typography variant='h3'>
                        {t('general.templates')}
                    </Typography>
                    <Typography variant='body2Medium'>
                        {t('vertical.templateCaption')}
                    </Typography>
                </TypographyContainer>
                <ComponentContainer item md={9.5}>
                    <ControlledSelect
                        name='template'
                        id='template'
                        label={'Templates'}
                        width={'70%'}
                        options={mapDropdownOptions(verticalsData)}
                    />
                </ComponentContainer>
            </BaseContainer>
            <BaseContainer item>
                <TypographyContainer item md={2.5} mt={2} pt={1}>
                    <Typography variant='h3'>{t('general.about')}</Typography>
                    <Typography variant='body2Medium'>
                        {t('vertical.aboutCaption')}
                    </Typography>
                </TypographyContainer>
                <ComponentContainer item md={9.5}>
                    <InitialDetails />
                </ComponentContainer>
            </BaseContainer>
        </ParentContainer>
    )
}
