export enum HasOption {
    Yes = 'yes',
    No = 'no'
}

export enum ButtonType {
    Primary = 'primary',
    Secondary = 'secondary',
    Tertiary = 'tertiary',
    None = 'none'
}

export enum FlexDirection {
    RowReverse = 'row-reverse',
    Row = 'row'
}

export const permissionGranted = 'granted'
export const phoneNumberMask = '(999) 999-9999'
