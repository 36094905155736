import { gql } from '@apollo/client'

export const CampusPreferenceFragment = (on: string) => gql`
    fragment CampusPreferenceFragment on ${on} {
           
            id
            campusId
            videos {
              autoPlay
            }
            captionLanguage {
              id
              title
              available
              displayOrder
            }
            displayMode {
              id
              title
              available
              displayOrder
            }
            businessDays {
              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
              sunday
            }
            calendarYearFrom
            calendarYearTo
            officeFrom
            officeTo
            universityClosure {
              title
              date
            }
          

    }
`
