import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const HelpTextIcon: React.FC<SvgProps> = ({
    height = '16',
    width = '16',
    color,
    fill
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.backgroundBrandPrimary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 16 16'
            fill='none'
        >
            <g clipPath='url(#clip0_17195_43059)'>
                <path
                    d='M8.00065 10.6666V7.99992M8.00065 5.33325H8.00732M14.6673 7.99992C14.6673 11.6818 11.6826 14.6666 8.00065 14.6666C4.31875 14.6666 1.33398 11.6818 1.33398 7.99992C1.33398 4.31802 4.31875 1.33325 8.00065 1.33325C11.6826 1.33325 14.6673 4.31802 14.6673 7.99992Z'
                    stroke={_color}
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_17195_43059'>
                    <rect width='16' height='16' fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
}

export default HelpTextIcon
