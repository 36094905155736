import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const RESPOND_PORTAL_CLIENT_REQUEST = gql`
  ${MessagesFragment('PortalUserResponseDto')}
  ${MetaFragment('PortalUserResponseDto')}
  mutation respondToPortalRequest($input: RespondToPortalRequestInputDto!) {
    respondToPortalRequest(input: $input) {
      ...MessagesFragment
      ...MetaFragment
      data
    }
  }
`
