import React from 'react'
import { usePageHeader } from '@hooks'

export const VideosPage = () => {
    const { pageHeader } = usePageHeader({
        headerText: 'Videos'
    })

    return pageHeader
}
