enum ResponseTypeEnum {
  Common = 'CMN',
  Correct = 'CRC',
  Wrong = 'WRN',
}

enum ResponseTypeGraphqlEnum {
  'CMN' = 'CMN',
  'CRC' = 'CRC',
  'WRN' = 'WRN',
}

enum QUESTION_FEEDBACK_TYPES {
  CommonFeedback = 'CMN',
  CorrectIncorrectFeedback = 'CIF',
  FeedbackAgainstAll = 'FAO',
  FeedbackAgainstSelected = 'FSO',
  NoFeedback = 'NFD',
}

export { ResponseTypeEnum, ResponseTypeGraphqlEnum, QUESTION_FEEDBACK_TYPES }
