import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useSearchParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import { useGraphQLWrapper, useLoader } from '@hooks'
import {
    CourseTrainingsCard,
    CustomLoader,
    EmptyState,
    FilterLayout
} from '@components'

import { GET_MY_TRAININGS } from '@dts/graphql/lib'
import { MenuIcon, NotificationImg } from '@assets'
import { SurveyProvider } from '@providers'
import { CoursesContainer } from './course-container'

export const LearnerTrainings = () => {
    const { t } = useTranslation()
    const { loading } = useLoader()
    const { data: myTrainingsData, queryLoading } = useGraphQLWrapper({
        query: GET_MY_TRAININGS,
        queryName: 'getMyTrainings'
    })

    const [searchParams] = useSearchParams()
    const routeId = searchParams.get('routeId')

    const [selectedTraining, setSelectedTraining] = useState()

    const handleTrainingSelection = (trainingId: string | number) => {
        const selectedTraining = myTrainingsData?.data?.find(
            (training) => training.id === trainingId
        )
        if (selectedTraining) {
            setSelectedTraining(selectedTraining)
        }
    }

    useEffect(() => {
        setSelectedTraining(
            routeId
                ? myTrainingsData?.data?.find(
                      (training) => training.id === routeId
                  )
                : myTrainingsData?.data?.[0]
        )
    }, [myTrainingsData, queryLoading, routeId])

    return (
        <>
            {loading ? (
                <Grid container xs={12} justifyContent={'center'}>
                    <CustomLoader />
                </Grid>
            ) : myTrainingsData?.data?.length === 0 ? (
                <Grid container xs={12} justifyContent={'center'} mt={4}>
                    <EmptyState
                        header={t('trainings.learnerEmptyStateHeading')}
                        subHeader={t('trainings.learnerEmptyStateSubHeading')}
                        image={NotificationImg}
                    />
                </Grid>
            ) : (
                <FilterLayout
                    pageHeaderConfig={{
                        headerText: t('general.myTrainings'),
                        showMenu: false
                    }}
                    filterConfig={{
                        categoriesArray: myTrainingsData?.data?.map((item) => ({
                            ...item,
                            label: item?.title,
                            onClick: () => handleTrainingSelection(item?.id)
                        })),
                        selectedCategory: selectedTraining,
                        categoriesHeader: t('general.assignedTrainings')
                    }}
                    renderComponent={(trainingData, index: number) => (
                        <CourseTrainingsCard
                            trainingData={trainingData}
                            selectedTraining={
                                selectedTraining?.id === trainingData?.id
                            }
                        />
                    )}
                    directoryComponent={
                        <CoursesContainer selectedTraining={selectedTraining} />
                    }
                    fallbackMessage='general.trainings'
                    OverlayIcon={MenuIcon}
                />
            )}
        </>
    )
}
