import { gql } from '@apollo/client'

export const GET_ACCOUNT_STATUS = gql`
  query getAccountStatus($clientId: String) {
    getAccountStatus(clientId: $clientId) {
      data {
        user {
          id
          firstName
          lastName
          avatarSrc
          dateOfBirth
          email
          clientId
          roles {
            id
            title
            roleId
            roleActions {
              id
              title
            }
          }
          organization
          userSetting {
            displayMode {
              id
              title
            }
          }
          adminPortalOnlyAccess
        }
      }
      message {
        type
        messages
      }
      meta {
        verified
        count
        firstLogin
        privacyAcknowledged
        userExists
        isPortalUser
      }
    }
  }
`
