export enum UserRoles {
    GRS_SUPER_ADMIN = 'GSA',
    GRS_ADMIN = 'GRA',
    GRS_MODERATOR = 'GMD',
    GRS_COORDINATOR = 'GCD',
    CLIENT_ADMIN = 'CLA',
    CLIENT_REPORTER = 'CLC',
    TRAINEE = 'TRN',
    CONTENT_CREATOR = 'COC',
    SUPPORT_USER = 'SUP'
}

export enum UserInviteStatus {
    PENDING = 'PEN',
    ACTIVE = 'ACT',
    INACTIVE = 'INA',
    REJECTED = 'REJ',
    DELETED = 'DEL'
}

export enum UserInviteStatusResponse {
    ACCEPT = 'ACCEPT',
    REJECT = 'REJECT'
}
export const PUBLIC_CLIENT_ID = 'b0000001-0000-0000-0001-b00000000001'
