import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const FilledCheckedIcon: React.FC<SvgProps> = ({
    color,
    width = 24,
    height = 24
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.successMain

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 24 24'
            fill='none'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM17.657 9.40708C18.0475 9.01656 18.0475 8.38339 17.657 7.99287C17.2665 7.60234 16.6333 7.60234 16.2428 7.99287L12.9428 11.2929L10.3499 13.8858L7.757 11.2929C7.36648 10.9023 6.73332 10.9023 6.34279 11.2929C5.95227 11.6834 5.95227 12.3166 6.34279 12.7071L9.64279 16.0071C9.83033 16.1946 10.0847 16.3 10.3499 16.3C10.6151 16.3 10.8695 16.1946 11.057 16.0071L14.357 12.7071L17.657 9.40708Z'
                fill={_color}
            />
        </svg>
    )
}
export default FilledCheckedIcon
