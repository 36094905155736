import dayjs from 'dayjs'
import { FilterTimeIntervals } from './data-view/types'

export const getPanelData = () => {}

export const filterMetricsByTimeRange = (timeArray: any, filterData: any) => {
    const endTime = timeArray[0]?.id
    const startTime = timeArray[timeArray.length - 1]?.id
    const final: Array<FilterTimeIntervals> = filterData?.map((data) => ({
        id: data?.id,
        title: data?.title,
        data: []
    }))

    /**
     * REPORT-TODO WILL BE REPLACE WITH forEach loop
     */
    filterData?.forEach((data) => {
        data.trainings.forEach((training) => {
            training.calculatedMetrics.forEach((metric) => {
                const metricTime = new Date(metric.time)

                if (metricTime >= startTime && metricTime <= endTime) {
                    const metricIndex = final.findIndex(
                        (item) => item?.id === data?.id
                    )
                    const obj = {
                        [metric?.time]: metric?.value,
                        [dayjs(metric?.time).format('YYYY-MM-DD')]:
                            metric?.value,
                        date: dayjs(metric?.tim).format('YYYY-MM-DD'),

                        metric: data?.title,
                        metricId: data?.id,
                        training: training?.name,
                        age: metric?.user?.age,
                        interests: metric?.user?.interests
                    }
                    if (metricIndex > -1) {
                        final[metricIndex].data.push(obj)
                    } else {
                        final.push({
                            id: data?.id,
                            title: data?.title,
                            data: [obj]
                        })
                    }
                }
            })
        })
    })

    return final
}

export const getCustomRangeInitialValues = () => {
    // Get the current date
    const currentDate = new Date()

    // Calculate the start of the current month
    const startOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
    )

    // Format the dates in the desired format
    const startOfMonthString = startOfMonth.toISOString()
    const currentDateString = currentDate.toISOString()

    // Create the array with the formatted dates
    const dateArray = [startOfMonthString, currentDateString]

    return dateArray
}
