import { gql } from '@apollo/client'

export const RoleFields = (on: string) => gql`
    fragment RoleFields on ${on} {
        id
        title
        roleId
        description
        createdAt
        updatedAt
        actions {
        id
        title
        available
        displayOrder
        feature {
            id
            title
            available
            displayOrder
        }
        }
        usersCount
    }
`
