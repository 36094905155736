import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormFeedback, useGraphQLWrapper, usePage } from '@hooks'
import { SAVE_ROLE, GET_ROLE, GET_FEATURES } from '@dts/graphql'
import { CreateRoleSchema } from '@dts/client-utils'
import { ClientRolesForm } from './roles-form'
import { UserPermissionEnum } from '@dts/constants'

export const ClientRolesContainer = () => {
    const { t } = useTranslation()
    const { id: clientId, roleId } = useParams()
    const navigate = useNavigate()

    // to-do: will be removed after testing
    // const featureMap = (permissions) => {
    //     const featureMap = {}
    //     permissions?.forEach((item) => {
    //         const featureId = item.feature.id
    //         const id = item.id

    //         if (!featureMap[featureId]) {
    //             featureMap[featureId] = []
    //         }

    //         featureMap[featureId].push(id)
    //     })
    //     return featureMap
    // }

    function groupActionsByFeatures(actions) {
        // Use an object to store features and their associated actions
        const groupedFeatures = {}

        actions?.forEach((action) => {
            const featureId = action.feature.id
            const featureTitle = action.feature.title

            // If the feature is not already in the object, add it
            if (!groupedFeatures[featureId]) {
                groupedFeatures[featureId] = {
                    id: featureId,
                    title: featureTitle,
                    displayOrder: action.feature.displayOrder,
                    actions: []
                }
            }

            // Add the current action to the feature's actions array
            groupedFeatures[featureId].actions.push({
                id: action.id,
                title: action.title,
                available: action.available,
                displayOrder: action.displayOrder,
                isSelected: true
            })
        })

        return Object.values(groupedFeatures) // Convert object back to array format
    }

    const { methods, save, mutationLoading, queryLoading } = useFormFeedback({
        permissions: [
            UserPermissionEnum.RolesCreateModify,
            UserPermissionEnum.ClientCreateModify
        ],
        initialValues: { permissions: [], title: '', description: '' },
        mapFormToMutationInput: ({
            title,
            description,
            mergedPermissions
        }) => ({
            input: {
                id: roleId ? roleId : undefined,
                clientId,
                title,
                description,
                actionIds: mergedPermissions
                    // .filter((value) => value?.isSelected) // Filter out items where isSelected is true
                    .flatMap(
                        (
                            permission // Flatten the array
                        ) =>
                            permission?.actions
                                ?.map((action) =>
                                    action.isSelected ? action.id : null
                                )
                                ?.filter(Boolean) || [] // Map actions to action ids and handle possible undefined actions
                    )
            }
        }),
        mutation: SAVE_ROLE,
        mutationName: 'saveRole',
        isCustomMutationInput: true,
        onMutationSuccess: () => {
            navigate('../')
        },
        ...(roleId
            ? {
                  query: GET_ROLE,
                  queryName: 'getRole',
                  retrieveOnMount: true,
                  queryVariables: { id: roleId, clientId: clientId },
                  mapQueryResultToForm: (response) => {
                      const { title, description, actions } = response || {}

                      return {
                          title,
                          description,
                          permissions: groupActionsByFeatures(actions)
                      }
                  }
              }
            : undefined),
        yupResolverSchema: CreateRoleSchema
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: ClientRolesForm,

            hasHeader: true,
            preventMargin: true,
            showBreadcrumbs: true,
            fetchEntityVariables: clientId
                ? { id: clientId, type: 'client' }
                : undefined,
            pageHeaderConfig: {
                headerText: t('pageHeader.manageRoles'),
                primaryButtonTitle: roleId
                    ? t('general.update')
                    : t('general.save'),
                primaryButtonAction: () => {
                    methods.handleSubmit(() => save())()
                },
                secondaryButtonTitle: t('general.cancel'),
                secondaryButtonAction: () => navigate('../'),
                permissions: [
                    UserPermissionEnum.RolesCreateModify,
                    UserPermissionEnum.ClientCreateModify
                ]
            }
        }
    })

    return <>{page}</>
}
