export enum InsightTimeline {
  LAST_SEVEN_DAYS = 'LSD',
  LAST_TWENTY_EIGHT_DAYS = 'LTD',
  LAST_SIXTY_DAYS = 'LSX',
  LAST_YEAR = 'LYR',
}

export enum InsightKPIsKeys {
  AVERAGE_WATCH_TIME = 'averageWatchTime',
  WATCH_FULL_VIDEO = 'watchedFullVideo',
  LEARNER_REACHED = 'learnersReached',
  REPLAYS = 'replays',
  SAVED = 'saved',
}

export enum InsightTimelineDays {
  LAST_SEVEN_DAYS = '7 days',
  LAST_TWENTY_EIGHT_DAYS = '28 days',
  LAST_SIXTY_DAYS = '60 days',
  LAST_YEAR = 'year',
}

export enum TimePeriod {
  Yesterday = 'Yesterday',
  Today = 'Today',
  SevenDays = '7D',
  ThirtyDays = '30D',
  ThreeMonths = '3M',
  SixMonths = '6M',
  TwelveMonths = '12M',
}
