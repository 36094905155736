import { FormLevelMessages } from '../../types'
import * as yup from 'yup'

export const ListMatchSchema = yup.object().shape({
  fieldMappingArray: yup
    .array()
    .of(
      yup.object().shape({
        mapFieldValue: yup.boolean(),
        hubbubField: yup.string().test('checkHubbubField', function (value) {
          const mapFieldValue = this.parent.mapFieldValue
          if (mapFieldValue && !value) {
            return this.createError({
              message: 'learnerList.matchField.required',
            })
          }
          return true
        }),
      }),
    )
    .test(
      FormLevelMessages.ERRORS,
      'At least one Hubbub Field must be Email',
      function (values) {
        const filteredArray = values
          // To-do
          // .filter((item) => item.mapFieldValue)
          .map((item) => item.hubbubField)

        const hasEML = filteredArray?.includes('EML')

        if (!hasEML) {
          return this.createError({
            message: 'At least one Hubbub Field must be Email',
            path: 'generic_error',
          })
        }
        return true
      },
    ),
})
