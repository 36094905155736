import React from 'react'
import { useTranslation } from 'react-i18next'
import { ControlledTextInput } from '@components'
import { Grid, Typography } from '@mui/material'
import { HelperTextPosition } from '@constants'

export const RejectVideo = ({ showHelpText }) => {
    const { t } = useTranslation()
    return (
        <Grid container md={12} xs={12}>
            {showHelpText && (
                <Grid item mt={1} md={11} xs={11}>
                    <Typography>
                        {t('reviewVideo.attachNotes.caption')}
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12} md={12} mt={2}>
                <ControlledTextInput
                    name='title'
                    id='title'
                    label={t('general.title')}
                    multiline
                    rows={2}
                    helperTextConfig={{
                        wordLength: 200,
                        helperTextPosition: HelperTextPosition.Right
                    }}
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} md={12} mt={2}>
                <ControlledTextInput
                    name='details'
                    id='details'
                    label={t('general.details')}
                    multiline
                    rows={3}
                    helperTextConfig={{
                        wordLength: 1000,
                        helperTextPosition: HelperTextPosition.Right
                    }}
                    fullWidth
                />
            </Grid>
        </Grid>
    )
}
