import React, { FC } from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const LaterIcon: FC<SvgProps> = ({ color, width = '24', height = '25' }) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 24 25'
            fill='none'
        >
            <path
                d='M2.30005 11.292L4.29946 13.292L6.30005 11.292M4 12.792C4 7.82143 8.02944 3.79199 13 3.79199C17.9706 3.79199 22 7.82143 22 12.792C22 17.7626 17.9706 21.792 13 21.792C9.69808 21.792 6.81148 20.0139 5.24549 17.3629M13.3 8.79199L13.3 13.792L10.3001 15.792'
                stroke={_color}
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default LaterIcon
