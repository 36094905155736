import React, { createContext, useReducer, useState } from 'react'
import { Modal } from '@components'
import { MODAL_ACTIONS } from '@constants'
import { ButtonVariant } from '@types'

type ModalInput = {
    title?: string
    body?: React.ReactElement | string
    message?: string
    onClose?: VoidFunction
    type?: string
    primaryButtonConfig?: {
        text: string
        variant?: ButtonVariant
        onConfirm: VoidFunction
    }
    secondaryButtonConfig?: {
        text: string
        variant?: ButtonVariant
        onConfirm: VoidFunction
    }
    hasCancelButton: boolean
    hasFeedbackDialog?: boolean
    hideDialogActions?: boolean
    fullScreen?: boolean
    dialogContentPadding?: string
}
const initialState = {
    open: false,
    modalContent: {
        title: '',
        body: null,
        message: undefined,
        onClose: () => undefined,
        type: 'default',
        primaryButtonConfig: null,
        secondaryButtonConfig: null,
        hasCancelButton: true,
        hasFeedbackDialog: false,
        hideDialogActions: false,
        fullScreen: false,
        dialogContentPadding: undefined
    }
}
export const dialogReducer = (state, action: ModalInput) => {
    switch (action.type) {
        case MODAL_ACTIONS.SHOW_DIALOG:
            return {
                ...{ ...initialState.modalContent, ...action },
                open: true
            }
        case MODAL_ACTIONS.HIDE_DIALOG:
            return { ...state, open: false }
        default:
            return initialState
    }
}
export interface ModalContextData {
    showDialog: (modalInput: ModalInput) => void
    hideDialog: VoidFunction
    setSubmitting?: React.Dispatch<React.SetStateAction<boolean>>
}
const defaultValue: ModalContextData = {
    hideDialog: () => undefined,
    showDialog: () => undefined
}
export const ModalContext = createContext(defaultValue)
export const ModalProvider = ({ children }) => {
    const [submitting, setSubmitting] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [
        {
            open,
            title,
            body,
            maxWidth,
            message,
            primaryButtonConfig,
            secondaryButtonConfig,
            hasCancelButton,
            onClose,
            fullScreen,
            hasFeedbackDialog,
            hideCloseIcon,
            hideDialogActions,
            dialogContentPadding
        },
        dispatch
    ] = useReducer(dialogReducer, initialState)

    const showDialog = (dialogInput: ModalInput) => {
        dispatch({
            ...dialogInput,
            type: MODAL_ACTIONS.SHOW_DIALOG
        })
    }

    const hideDialog = () => {
        dispatch({ type: MODAL_ACTIONS.HIDE_DIALOG })
    }

    return (
        <ModalContext.Provider
            value={{
                hideDialog,
                showDialog,
                setSubmitting,
                setShowLoader
            }}
        >
            {children}
            <Modal
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                title={title}
                submitting={submitting}
                Component={body}
                message={message}
                open={open}
                hasCancelButton={hasCancelButton}
                onClose={onClose ?? hideDialog}
                primaryButtonConfig={primaryButtonConfig}
                secondaryButtonConfig={secondaryButtonConfig}
                hasFeedbackDialog={hasFeedbackDialog}
                showLoader={showLoader}
                hideCloseIcon={hideCloseIcon}
                hideDialogActions={hideDialogActions}
                dialogContentPadding={dialogContentPadding}
            />
        </ModalContext.Provider>
    )
}
