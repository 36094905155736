import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, SurveyFields } from '../../fragments'

export const GET_SURVEY_QUESTIONS = gql`
  ${SurveyFields('SurveyDto')}
  ${MessagesFragment('SurveyResponseDto')}
  ${MetaFragment('SurveyResponseDto')}
  query getSurveyQuestions($args: SurveyQuestionArgumentsDto!) {
    getSurveyQuestions(args: $args) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...SurveyFields
      }
    }
  }
`
