import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import {
    Duplicate,
    ConfirmationDialog,
    StyledChip,
    TextWithTooltip
} from '@components'
import { formatDate } from '@dts/client-utils'
import { VerticalsStatus } from '@constants'
import {
    useCustomTheme,
    useDirectory,
    useGraphQLWrapper,
    useModalWithGraphQL
} from '@hooks'
import {
    AddIcon,
    ArchiveIcon,
    DeleteIcon,
    DuplicateIcon,
    EditRecordIcon,
    PlusIcon,
    RestoreIcon
} from '@assets'
import {
    DELETE_VERTICAL,
    DUPLICATE_VERTICAL,
    GET_VERTICAL,
    GET_VERTICALS
} from '@dts/graphql'
import { UPDATE_VERTICAL } from '@dts/graphql/lib'
import { startCase } from 'lodash'
import { VerticalProps } from './types'
import { UserPermissionEnum } from '@dts/constants'

export const BrowseVerticalsContainer: FC<VerticalProps> = ({ status }) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const navigate = useNavigate()

    const updateVerticalConfig = {
        mutation: UPDATE_VERTICAL,
        mutationName: 'updateVertical',
        onMutationSuccess: () => {
            retrieve?.({
                variables: {
                    options: queryOptions
                }
            })
        },
        isCustomMutationInput: true
    }

    const deleteVerticalConfig = {
        mutation: DELETE_VERTICAL,
        mutationName: 'deleteVertical',
        onMutationSuccess: () => {
            retrieve?.({
                variables: {
                    options: queryOptions
                }
            })
        },
        isCustomMutationInput: true
    }

    const duplicateVerticalConfig = {
        mutation: DUPLICATE_VERTICAL,
        mutationName: 'duplicateVertical',
        onMutationSuccess: () => {
            retrieve?.({
                variables: {
                    options: queryOptions
                }
            })
        },
        isCustomMutationInput: true
    }

    const { save: updateVertical } = useGraphQLWrapper({
        ...updateVerticalConfig
    })

    const { openPrepopulatedDialog } = useModalWithGraphQL({
        ...duplicateVerticalConfig,
        mapFormToMutationInput: ({ id, title }) => ({
            input: { id, title }
        }),
        query: GET_VERTICAL,
        queryName: 'getVertical',
        mapQueryResultToForm: ({ id, title }) => ({
            id,
            title
        }),
        initialValues: {
            title: null
        },
        modalConfig: {
            maxWidth: 'sm',
            Component: Duplicate,
            updationConfig: {
                primaryButtonText: t('general.confirm'),
                header: `${t('createArticle.duplicate')} ${t('general.course')}`
            },
            hasCancelButton: true
        }
    })

    const { save: deleteVertical } = useGraphQLWrapper({
        ...deleteVerticalConfig
    })

    const updateArchive = (params, archive) => ({
        input: {
            id: params?.row?.id,
            isArchived: archive
        }
    })
    const { directory, retrieve, queryOptions } = useDirectory({
        directoryConfig: {
            id: 'vertical',
            query: GET_VERTICALS,
            queryName: 'getVerticals',
            queryVariables: {
                args: { tab: status }
            },
            config: {
                customActions: [
                    {
                        icon: <RestoreIcon />,
                        label: t('general.restore'),
                        ariaLabel: `${t('general.restore')}`,
                        showInMenu: true,
                        action: (params) => {
                            openConfirmationDialog(updateArchive(params, false))
                        },
                        hide: (params) => params?.row?.isArchived !== true,
                        permissions: [UserPermissionEnum.VerticalCreateModify]
                    },
                    {
                        icon: (
                            <EditRecordIcon
                                color={theme.palette.contentSecondary}
                            />
                        ),
                        label: t('general.edit'),
                        ariaLabel: t('general.edit'),
                        showInMenu: true,
                        action: (params) =>
                            navigate(`/verticals/${params?.row?.id}`),
                        permissions: [UserPermissionEnum.VerticalCreateModify]
                    },
                    {
                        icon: (
                            <DeleteIcon
                                color={theme.palette.contentSecondary}
                            />
                        ),
                        label: t('general.delete'),
                        ariaLabel: t('general.delete'),
                        showInMenu: true,
                        action: (params) => {
                            deleteVertical({
                                id: params?.row?.id
                            })
                        },
                        permissions: [UserPermissionEnum.VerticalDelete]
                    },
                    {
                        icon: (
                            <DuplicateIcon
                                color={theme.palette.contentSecondary}
                            />
                        ),
                        label: t('createArticle.duplicate'),
                        ariaLabel: t('createArticle.duplicate'),
                        showInMenu: true,
                        action: (params) => {
                            openPrepopulatedDialog({
                                id: params?.row?.id
                            })
                        },
                        permissions: [UserPermissionEnum.VerticalCreateModify]
                    },

                    {
                        icon: (
                            <ArchiveIcon
                                color={theme.palette.contentSecondary}
                            />
                        ),
                        label: t('createArticle.archive'),
                        ariaLabel: t('createArticle.archive'),
                        showInMenu: true,
                        action: (params) => {
                            archiveConfirmationDialog(
                                updateArchive(params, true)
                            )
                        },
                        hide: (params) =>
                            params?.row?.isArchived || params?.row?.isDraft,
                        permissions: [UserPermissionEnum.VerticalCreateModify]
                    },

                    {
                        icon: <PlusIcon />,
                        ariaLabel: `${t('general.edit')} ${t(
                            'general.course'
                        )}`,
                        showInMenu: false,
                        action: (params) => {
                            navigate(`/verticals/${params?.id}/edit-courses`)
                        },
                        permissions: [UserPermissionEnum.VerticalCreateModify]
                    }
                ],
                columns: [
                    {
                        field: 'title',
                        headerName: t('general.title'),
                        minWidth: 150,
                        renderCell: (params) => (
                            <Grid p={2}>
                                <Grid item spacing={2}>
                                    <Grid
                                        container
                                        direction={'column'}
                                        display={'flex'}
                                    >
                                        <Grid item spacing={2} display={'flex'}>
                                            {params?.row?.isDraft && (
                                                <Typography
                                                    variant='section'
                                                    color={
                                                        theme.palette
                                                            .inactiveBrown
                                                    }
                                                    mr={0.5}
                                                >
                                                    {t('browseArticle.draft')}
                                                </Typography>
                                            )}
                                            <TextWithTooltip
                                                renderer={params?.row?.title}
                                                color={
                                                    theme.palette.contentLink
                                                }
                                                tabIndex={params.tabIndex}
                                                isDirectoryLink
                                                onClick={() =>
                                                    navigate(
                                                        `/verticals/${params?.row?.id}`
                                                    )
                                                }
                                            />
                                        </Grid>

                                        <TextWithTooltip
                                            renderer={params?.row?.description}
                                            mt={0.5}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    },
                    ...(status !== VerticalsStatus.Templates
                        ? [
                              {
                                  field: 'clients',
                                  headerName: t('general.clients'),
                                  minWidth: 50,
                                  renderCell: (params) => (
                                      <TextWithTooltip
                                          renderer={'0'}
                                          tabIndex={params.tabIndex}
                                      />
                                  )
                              },
                              {
                                  field: 'courses',
                                  headerName: t('general.courses'),
                                  minWidth: 250,
                                  renderCell: (params) => (
                                      <TextWithTooltip
                                          renderer={
                                              <Grid item py={1}>
                                                  {params?.row?.courses?.map(
                                                      (topic: {
                                                          id: number
                                                          title: string
                                                      }) => (
                                                          <StyledChip
                                                              label={
                                                                  topic?.title
                                                              }
                                                              key={topic?.id}
                                                              sx={{ mb: 1 }}
                                                          />
                                                      )
                                                  )}
                                              </Grid>
                                          }
                                          toolTip={params?.row?.courses
                                              ?.map((topic) => topic?.title)
                                              ?.join(', ')}
                                          tabIndex={params.tabIndex}
                                      />
                                  )
                              }
                          ]
                        : [
                              {
                                  field: 'verticalsCount',
                                  headerName: t('verticals.usedInVerticals'),
                                  minWidth: 80,
                                  renderCell: (params) => (
                                      <TextWithTooltip
                                          renderer={params?.value}
                                          tabIndex={params.tabIndex}
                                      />
                                  )
                              }
                          ]),

                    {
                        field: 'dateCreated',
                        headerName: t('general.dateCreated'),
                        minWidth: 100,
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={formatDate(params?.row?.createdAt)}
                                tabIndex={params.tabIndex}
                            />
                        )
                    }
                ]
            },
            enableSearch: true,
            toolbarConfig: {
                header: `${startCase(status)} ${t('sideNavItems.verticals')}`,
                addNewButtonConfig: {
                    label: t('general.createNew'),
                    ariaLabel: t('general.vertical'),
                    action: () => navigate(`/verticals/new`),
                    icon: AddIcon,
                    permissions: [UserPermissionEnum.VerticalCreateModify]
                }
            }
        }
    })
    /**
     * openConfirmationDialog is used to restore archive vertical
     */
    const { openConfirmationDialog } = useModalWithGraphQL({
        ...updateVerticalConfig,
        mapFormToMutationInput: ({ id, isArchived }) => ({
            input: { id, isArchived }
        }),

        modalConfig: {
            maxWidth: 'sm',
            Component: () => (
                <ConfirmationDialog
                    message={t('course.restore.confirmation.message')}
                />
            ),
            confirmationConfig: {
                primaryButtonText: t('general.confirm'),
                header: t('general.confirmation')
            },
            hasCancelButton: true
        }
    })

    /**
     * archiveConfirmationDialog is used to  archive vertical
     */
    const { openConfirmationDialog: archiveConfirmationDialog } =
        useModalWithGraphQL({
            ...updateVerticalConfig,
            mapFormToMutationInput: ({ id, isArchived }) => ({
                input: { id, isArchived }
            }),

            modalConfig: {
                maxWidth: 'sm',
                Component: () => (
                    <ConfirmationDialog
                        message={t('archive.confirmation.message')}
                    />
                ),
                confirmationConfig: {
                    primaryButtonText: t('general.confirm'),
                    header: t('general.confirmation')
                },
                hasCancelButton: true
            }
        })

    return directory
}
