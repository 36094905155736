import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { mapDropdownOptions } from '@dts/client-utils'
import { Divider, Grid, useMediaQuery } from '@mui/material'
import {
    ControlledTextInput,
    ControlledMultiSelectAutoComplete,
    ControlledDatePicker,
    ControlledSelect,
    ControlledCheckBox,
    UploadAvatar,
    Loader
} from '@components'
import { GET_COMMON_DATA } from '@dts/graphql'
import { useAuth, useCustomTheme, useGraphQLWrapper, useLoader } from '@hooks'
import { StyledTypography, StyledTypographyHelper } from './styles'
type UserProfileProps = {
    isSignup?: boolean
}
export const UserProfileForm: React.FC<UserProfileProps> = ({
    isSignup = false
}) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const [dropdownOptions, setDropdownOptions] = useState([])
    const { control } = useFormContext()
    const { userData } = useAuth()
    const { loading } = useLoader()

    const {
        retrieve,
        data: commonData,
        queryLoading
    } = useGraphQLWrapper({
        query: GET_COMMON_DATA,
        queryName: 'getCommonData',
        retrieveOnMount: !isSignup
    })
    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )
    const minAge = useWatch({
        control,
        name: 'minAge'
    })
    useEffect(() => {
        if (commonData) {
            setDropdownOptions(commonData?.data)
        }
    }, [commonData])

    useEffect(() => {
        if (userData) {
            retrieve?.()
        }
    }, [userData])

    return (
        <Grid
            my={2}
            mx={1}
            md={isSignup && 6}
            xs={12}
            sx={{ backgroundColor: `${theme.palette.backgroundPrimary}` }}
        >
            {loading && <Loader />}
            <Grid container px={4} justifyContent={'space-between'}>
                <Grid
                    item
                    xs={12}
                    md={12}
                    mt={3}
                    sx={
                        isMediumScreen
                            ? { display: 'flex', justifyContent: 'center' }
                            : {}
                    }
                >
                    <UploadAvatar name='avatarUpload' />
                </Grid>
                <Grid item xs={12} md={isSignup ? 12 : 5.9}>
                    <ControlledTextInput
                        id='firstName'
                        type='text'
                        name='firstName'
                        required
                        label={t('general.firstName')}
                    />
                </Grid>
                <Grid item xs={12} md={isSignup ? 12 : 5.9}>
                    <ControlledTextInput
                        id='lastName'
                        type='text'
                        name='lastName'
                        required
                        label={t('general.lastName')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ControlledTextInput
                        id='email'
                        type='email'
                        name='email'
                        label={t('general.email')}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12} md={isSignup ? 12 : 5.9}>
                    <ControlledTextInput
                        id='title'
                        type='text'
                        name='title'
                        label={t(
                            `${t('general.title')} (${t('general.optional')})`
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={isSignup ? 12 : 5.9}>
                    <ControlledTextInput
                        id='school'
                        type='text'
                        name='school'
                        label={t(
                            `${t('general.school')} (${t('general.optional')})`
                        )}
                    />
                </Grid>
                {isSignup && (
                    <Grid item xs={12}>
                        <ControlledCheckBox
                            id='minAge'
                            name='minAge'
                            label={t('completeProfile.verifyAge')}
                        />
                    </Grid>
                )}
                <Grid item xs={12} md={isSignup ? 12 : 3}>
                    <ControlledDatePicker
                        id='birthday'
                        label={t('completeProfile.birthday')}
                        name='birthday'
                        isSignup={isSignup}
                        width={'100%'}
                        disabled={isSignup && !minAge}
                    />
                </Grid>
                <Grid item xs={12} mt={3} mb={3}>
                    <Divider />
                </Grid>
                <Grid item xs={12} md={isSignup ? 12 : 7}>
                    <StyledTypography>
                        {t('userProfile.otherInformation')}
                    </StyledTypography>
                </Grid>
                <Grid item xs={12} md={isSignup ? 12 : 7}>
                    <StyledTypographyHelper py={1}>
                        {t('userProfile.otherSettingsHelper')}
                    </StyledTypographyHelper>
                </Grid>
                <Grid item xs={12} md={isSignup ? 12 : 5.9}>
                    <ControlledSelect
                        id='races'
                        name='races'
                        label={t('general.ethnicity')}
                        isMulti
                        options={mapDropdownOptions(dropdownOptions?.race)}
                    />
                </Grid>
                <Grid item xs={12} md={isSignup ? 12 : 5.9}>
                    <ControlledSelect
                        id='genders'
                        name='genders'
                        label={t('general.gender')}
                        isMulti
                        options={mapDropdownOptions(dropdownOptions?.gender)}
                    />
                </Grid>
                <Grid item xs={12} mb={6}>
                    <ControlledMultiSelectAutoComplete
                        id='interests'
                        name='interests'
                        customDataName='customInterests'
                        label={t('general.interests')}
                        options={mapDropdownOptions(dropdownOptions?.interest)}
                        allowCustomAddition
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
