enum LanguageEnum {
  English = 'ENG',
  Dansk = 'DAN',
  Deutsch = 'DEU',
  Espanol = 'ESP',
  Filipini = 'FIL',
  Francias = 'FRA',
  Italiano = 'ITA',
  Polski = 'POL',
  Portugues = 'POR',
  Svenska = 'SVE',
  Russian = 'RUS',
}

enum LanguageGraphqlEnum {
  'ENG' = 'ENG',
  'DAN' = 'DAN',
  'DEU' = 'DEU',
  'ESP' = 'ESP',
  'FIL' = 'FIL',
  'FRA' = 'FRA',
  'ITA' = 'ITA',
  'POL' = 'POL',
  'POR' = 'POR',
  'SVE' = 'SVE',
  'RUS' = 'RUS',
}

export { LanguageEnum, LanguageGraphqlEnum }
