import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_FOLLOWING_ACCOUNTS = gql`
  ${MetaFragment('GetMultipleUserResponseDto')}
  ${MessagesFragment('GetMultipleUserResponseDto')}
  query getFollowingAccounts {
    getFollowingAccounts {
      ...MetaFragment
      ...MessagesFragment
      data {
        id
        firstName
        lastName
        avatarSrc
      }
    }
  }
`
