import React from 'react'
import {
    Accordion,
    AccordionSummary,
    Box,
    Typography,
    styled
} from '@mui/material'
import { isString } from 'lodash'

// Commented code in this file is to be re-considered
export const StyledAccordion = styled(Accordion)<{
    borderRadius?: string
    hasError?: boolean
    preventBorderRadius?: boolean
    backgroundColor?: string
}>(
    ({
        theme,
        borderRadius,
        hasError,
        preventBorderRadius,
        backgroundColor
    }) => ({
        boxShadow: 'none',
        borderRadius: preventBorderRadius
            ? 0
            : borderRadius ?? '12px !important',
        backgroundColor,
        border: `1px solid ${
            hasError
                ? theme?.palette?.borderNegative
                : theme.palette.borderSecondary
        }`,
        // backgroundColor: `${theme.palette.backgroundSecondary} !important`, // Example to show theme usage, adjust as needed
        '&:before': {
            height: '0px' // Or you can use 'unset' or 'none' depending on your need
        },
        '& .css-fnfdl0-MuiGrid-root': {
            color: `${theme.palette.contentPrimary} !important` // change text color for select metric checkBox
        },
        marginBottom: '4px'
    })
)

export const StyledBox: React.FC = styled(Box)`
    position: relative;
    bottom: -3px;
`

export const StyledAccordionSummary = styled(AccordionSummary)(
    ({
        theme,
        panelId,
        expanded,
        expandedPanels,
        backgroundColor,
        hasError,
        enableReorder
    }) => ({
        // TODO this style remove after verify
        // ...(expanded &&
        //     isString(expanded) && {
        //         borderBottom: expanded?.includes(panelId)
        //             ? 'none'
        //             : `1px solid ${theme.palette.borderSecondary}`
        //     }),
        paddingLeft: enableReorder && '0px',
        borderRadius:
            expanded === panelId || expandedPanels?.includes(panelId)
                ? '0px'
                : '12px',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        // border: `1px solid ${theme.palette.borderSecondary}`,
        // backgroundColor: hasError
        //     ? theme?.palette?.backgroundNegativeSubtle
        //     : backgroundColor ?? theme.palette.backgroundPrimary,
        backgroundColor: backgroundColor ?? theme.palette.backgroundPrimary,
        // TODO Remove this code after verification on learner
        // '&:hover': {
        //     backgroundColor: allowMultipleOpen
        //         ? theme.palette.primaryGrey
        //         : theme.palette.secondaryGrey
        // },
        '.MuiAccordionSummary-content.Mui-expanded': {
            margin: '8px 0'
        },
        '.Mui-expanded': {
            margin: '8px 0'
        },
        '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)'
        },

        minHeight: '40px !important'
    })
)

export const StyledTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'contrast'
})(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        maxWidth: '22rem'
    }
}))
