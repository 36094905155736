export const userTypeData = [
    {
        id: 'new',
        title: 'New'
    },
    {
        id: 'existing',
        title: 'Existing'
    }
]
