import { gql } from '@apollo/client'
import {
  LearnerListFields,
  MessagesFragment,
  MetaFragment,
} from '../../fragments'

export const GET_LEARNER_LIST_DIRECTORY = gql`
  ${MetaFragment('LearnerListsResponse')}
  ${MessagesFragment('LearnerListsResponse')}
  ${LearnerListFields('LearnerList')}
  query getLearnerListDirectory(
    $args: GetLearnerListsArgs!
    $options: LearnerListPaginationDto!
  ) {
    getLearnerListDirectory(args: $args, options: $options) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...LearnerListFields
      }
    }
  }
`
