import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const CaptionsIcon: React.FC<SvgProps> = ({
    color,
    height = '24',
    width = '24'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 24 24`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M6.64 18.1601C5.48 18.1601 4.44667 17.9267 3.54 17.4601C2.63333 16.9801 1.92 16.3201 1.4 15.4801C0.893333 14.6401 0.639999 13.6867 0.639999 12.6201C0.639999 11.5401 0.893333 10.5867 1.4 9.76008C1.92 8.92008 2.63333 8.26674 3.54 7.80008C4.44667 7.32008 5.48 7.08008 6.64 7.08008C7.77333 7.08008 8.76 7.32008 9.6 7.80008C10.44 8.26674 11.06 8.94008 11.46 9.82008L9.04 11.1201C8.76 10.6134 8.40667 10.2401 7.98 10.0001C7.56667 9.76008 7.11333 9.64008 6.62 9.64008C6.08667 9.64008 5.60667 9.76008 5.18 10.0001C4.75333 10.2401 4.41333 10.5801 4.16 11.0201C3.92 11.4601 3.8 11.9934 3.8 12.6201C3.8 13.2467 3.92 13.7801 4.16 14.2201C4.41333 14.6601 4.75333 15.0001 5.18 15.2401C5.60667 15.4801 6.08667 15.6001 6.62 15.6001C7.11333 15.6001 7.56667 15.4867 7.98 15.2601C8.40667 15.0201 8.76 14.6401 9.04 14.1201L11.46 15.4401C11.06 16.3067 10.44 16.9801 9.6 17.4601C8.76 17.9267 7.77333 18.1601 6.64 18.1601Z'
                fill={_color}
            />
            <path
                d='M18.3197 18.1601C17.1597 18.1601 16.1264 17.9267 15.2197 17.4601C14.313 16.9801 13.5997 16.3201 13.0797 15.4801C12.573 14.6401 12.3197 13.6867 12.3197 12.6201C12.3197 11.5401 12.573 10.5867 13.0797 9.76008C13.5997 8.92008 14.313 8.26674 15.2197 7.80008C16.1264 7.32008 17.1597 7.08008 18.3197 7.08008C19.453 7.08008 20.4397 7.32008 21.2797 7.80008C22.1197 8.26674 22.7397 8.94008 23.1397 9.82008L20.7197 11.1201C20.4397 10.6134 20.0864 10.2401 19.6597 10.0001C19.2464 9.76008 18.793 9.64008 18.2997 9.64008C17.7664 9.64008 17.2864 9.76008 16.8597 10.0001C16.433 10.2401 16.093 10.5801 15.8397 11.0201C15.5997 11.4601 15.4797 11.9934 15.4797 12.6201C15.4797 13.2467 15.5997 13.7801 15.8397 14.2201C16.093 14.6601 16.433 15.0001 16.8597 15.2401C17.2864 15.4801 17.7664 15.6001 18.2997 15.6001C18.793 15.6001 19.2464 15.4867 19.6597 15.2601C20.0864 15.0201 20.4397 14.6401 20.7197 14.1201L23.1397 15.4401C22.7397 16.3067 22.1197 16.9801 21.2797 17.4601C20.4397 17.9267 19.453 18.1601 18.3197 18.1601Z'
                fill={_color}
            />
        </svg>
    )
}

export default CaptionsIcon
