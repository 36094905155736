import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import {
    BaseContainer,
    ComponentContainer,
    ParentContainer,
    TypographyContainer,
    UploadFile,
    FieldErrors,
    ControlledTextInput,
    ControlledRadioGroup,
    ErrorNotification
} from '@components'
import { Button, Grid, Typography } from '@mui/material'
import { UploadFileIcon } from '@assets'
import { FileTypeEnum, useS3 } from '@dts/client-utils'
import Papa from 'papaparse'

import { DelimiterType, FileType } from '@constants'

export const ListCopyAndPaste: React.FC = ({
    prevPastedValueRef
}: ListCopyAndPasteProps) => {
    const { t } = useTranslation()
    const { getValues, setValue, control, trigger } = useFormContext()

    const delimiterType = [
        { value: FileType.CSV, label: DelimiterType.Comma },
        { value: FileType.TSV, label: DelimiterType.Tab }
    ]

    const delimiterSelected = useWatch({ control, name: 'delimiterType' })

    const pastedText = useWatch({
        control,
        name: 'learners'
    })

    const previousPastedText = useWatch({
        control,
        name: 'previousPastedText'
    })

    useEffect(() => {
        if (pastedText) trigger()
    }, [delimiterSelected, pastedText])
    useEffect(() => {
        if (
            prevPastedValueRef.current !== null &&
            prevPastedValueRef.current !== pastedText
        ) {
            setValue('pastedTextUpdated', true)

            if (previousPastedText === pastedText) {
                setValue('undoPastedChanges', true)
            } else {
                setValue('undoPastedChanges', false)
            }
        }

        prevPastedValueRef.current = pastedText
    }, [pastedText])

    return (
        <ParentContainer container>
            <Typography variant='h1'>{getValues('name')}</Typography>
            <Grid container direction={'column'}>
                <TypographyContainer item md={12} xs={12} flexGrow={1}>
                    <Typography variant='h3'>
                        {t('learnerList.copyAndPaste')}
                    </Typography>
                    <Typography variant='body2Medium'>
                        {t('learnerList.copyPasteCaption')}
                    </Typography>
                </TypographyContainer>
                <Grid container md={12} xs={12} marginTop={1}>
                    <ControlledRadioGroup
                        name='delimiterType'
                        options={delimiterType}
                    />
                    <ControlledTextInput
                        name='learners'
                        id='Learners'
                        label={t('general.learners')}
                        multiline
                        rows={13}
                        placeholder={t('learnerList.pasteHere')}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </ParentContainer>
    )
}
