import React, { useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid, List, TextFieldProps } from '@mui/material'
import { isEmpty, isUndefined } from 'lodash'
import { EmailIcon, GlobeIcon, LinkIcon } from '@assets'
import { KeyboardShortcut, ListType } from '@constants'
import { isValidURL } from '@utils'
import { StyledChip, StyledTextField } from './styles'

type ListInputProps = TextFieldProps & {
    name: string
    label: string
    id?: string
    listType?: ListType
    maxElements?: number
    isLink?: boolean
    width?: string
    hasHelperText?: boolean
}
type ListElement = { id: number; value: string }
export const ListInput: React.FC<ListInputProps> = ({
    id = 'tag-input',
    name,
    listType,
    label,
    maxElements,
    value,
    isLink,
    width,
    hasHelperText = true,
    ...props
}) => {
    const { t } = useTranslation()
    const { control, setValue } = useFormContext()
    const elementsArray = useWatch({ name, control })
    const [linkError, setLinkError] = useState<string | null>(null)
    const addNewItem = (value: string) => {
        const comparisonLength = listType === ListType.List ? 3 : 1
        const isAlreadyPresent = elementsArray?.some(
            (obj) =>
                obj?.value?.trim()?.toLowerCase() ===
                value?.trim()?.toLowerCase()
        )

        if (value?.trim()?.length >= comparisonLength && !isAlreadyPresent) {
            if (elementsArray) {
                setValue(name, [
                    ...elementsArray,
                    { value, id: elementsArray?.length + 1 }
                ])
            } else {
                setValue(name, [{ value, id: elementsArray?.length + 1 }])
            }
            inputRef.current.value = ''
            setLinkError(null)
        }
    }
    const deleteItem = (value: ListElement) => {
        setValue(
            name,
            elementsArray.filter(
                (element: ListElement) => element?.id !== value?.id
            )
        )
    }
    const inputRef = useRef({ value: '' })
    return (
        <Grid container sx={{ width }}>
            <Grid item xs={12} md={12}>
                <StyledTextField
                    id={id}
                    label={t(label)}
                    inputRef={inputRef}
                    name={name}
                    disabled={
                        !isUndefined(maxElements) &&
                        maxElements === elementsArray?.length
                    }
                    helperText={
                        hasHelperText
                            ? linkError || t('listInput.helperText.label')
                            : undefined
                    }
                    error={!!linkError}
                    fullWidth
                    onKeyDown={(e) => {
                        if (e.key === KeyboardShortcut.Enter) {
                            const inputValue = inputRef.current.value.trim()

                            if (isValidURL(inputValue)) {
                                addNewItem(inputValue)
                            } else {
                                setLinkError(t('errors.linkValidity'))
                            }
                        }
                    }}
                    {...props}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                {listType === ListType.Tag
                    ? elementsArray?.map(
                          (element: ListElement, index: number) => (
                              <StyledChip
                                  label={element.value}
                                  key={element.value}
                                  sx={{ mb: 1 }}
                                  onDelete={(e) => {
                                      e.preventDefault()
                                      deleteItem(element)
                                  }}
                              />
                          )
                      )
                    : !isEmpty(elementsArray) && (
                          <List>
                              {elementsArray?.map(
                                  (item: ListElement, index: number) => (
                                      <StyledChip
                                          label={item?.value}
                                          key={item?.id}
                                          sx={{ mb: 1 }}
                                          clickable
                                          icon={
                                              <div style={{ padding: '1px' }}>
                                                  {item?.value
                                                      ?.toLowerCase()
                                                      ?.includes('https://') ? (
                                                      <GlobeIcon />
                                                  ) : item?.value?.includes(
                                                        '@'
                                                    ) ? (
                                                      <EmailIcon />
                                                  ) : (
                                                      <LinkIcon />
                                                  )}
                                              </div>
                                          }
                                          onClick={() => {
                                              // Handle click action
                                              window.open(item?.value, '_blank')
                                          }}
                                          onDelete={(e) => {
                                              e.preventDefault()
                                              deleteItem(item)
                                          }}
                                      />
                                  )
                              )}
                          </List>
                      )}
            </Grid>
        </Grid>
    )
}
