import styled from '@emotion/styled'
import { Grid } from '@mui/material'

export type ThumbnailProps = {
    selected?: boolean
}

export const Thumbnail = styled(Grid)<ThumbnailProps>(
    ({ width = '110px', selected, theme }) => ({
        height: '160px',
        width,
        background: theme.palette.backgroundInfoSubtle,
        border: selected
            ? `3px solid ${theme.palette.backgroundBrandPrimary}`
            : 'none',
        borderRadius: 10,
        cursor: 'pointer',
        alignContent: 'center',
        justifyContent: 'center',
        textAlign: 'center'
    })
)
