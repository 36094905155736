import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import { useCustomTheme } from '@hooks'
import { CheckmarkIcon, CrossIcon, InformationIcon, WarningIcon } from '@assets'
import { surveyStepsInfo } from '@cacheql'
import { SurveyTypeEnum } from '@dts/constants'

const defaultIconStyles = { height: 64, width: 64 }

export const getIconBySummaryResponseIconId = (id, customIconStyles) => {
    const iconStyles = customIconStyles ?? defaultIconStyles
    const iconMapper = [
        { id: 'CHM', iconToRender: <CheckmarkIcon {...iconStyles} /> },
        { id: 'CRM', iconToRender: <CrossIcon {...iconStyles} /> },
        { id: 'IFS', iconToRender: <InformationIcon {...iconStyles} /> },
        { id: 'WRS', iconToRender: <WarningIcon {...iconStyles} /> }
    ]
    return iconMapper.find((iconElement) => iconElement?.id === id)
        ?.iconToRender
}

export const QuestionFeedback = (props) => {
    const { BackClickComponent } = props || {}

    const iconToBeRendered = getIconBySummaryResponseIconId(
        props?.summaryResponseIcon?.id
    )

    return (
        <>
            <Grid container flexDirection='column'>
                {props?.showBackButton ? (
                    <Grid item>
                        <BackClickComponent />
                    </Grid>
                ) : (
                    <></>
                )}

                <Grid item alignSelf={'center'} py={1}>
                    {iconToBeRendered}
                </Grid>
                <Grid item alignSelf={'center'} py={1}>
                    <Typography variant='h1' sx={{ fontSize: '30px' }}>
                        {props?.summaryTitle}
                    </Typography>
                </Grid>
                <Grid item alignSelf={'center'} py={1}>
                    <Typography variant='subtitle'>
                        {props?.summaryDescription}
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}
