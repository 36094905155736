import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCustomTheme, useDirectory, useGraphQLWrapper } from '@hooks'
import { GET_USERS, SEND_INVITE } from '@dts/graphql'
import { Grid, Avatar, Typography } from '@mui/material'
import { StyledLink, TextWithTooltip } from '@components'
import { ActiveIcon, AddIcon, PaperPlaneIcon, UserIcon } from '@assets'
import { formatDate } from '@dts/client-utils'
import { UserInviteStatus } from '@constants'
import { StyledDirectoryLink } from '@containers/styles'
import { UserPermissionEnum } from '@dts/constants'

export const ManageClientAdmins = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const { id } = useParams()

    const sendInviteConfig = {
        mutation: SEND_INVITE,
        mutationName: 'sendInvite',
        onMutationSuccess: () => {
            retrieve?.({
                variables: {
                    options: queryOptions
                }
            })
        },
        isCustomMutationInput: true
    }

    const { save: sendInvite } = useGraphQLWrapper({ ...sendInviteConfig })

    const navigateToAdmin = (params) => `${params.id}`

    const sendInviteAction = {
        showInMenu: true,
        icon: <PaperPlaneIcon fill={theme.palette.darkestBlue} />,
        action: (params) => {
            sendInvite({
                input: {
                    email: params?.row?.email,
                    isCreator: false,
                    clientId: id
                }
            })
        }
    }

    const { directory, retrieve, queryOptions } = useDirectory({
        directoryConfig: {
            id: 'users',
            query: GET_USERS,
            queryName: 'getUsers',
            queryVariables: { clientId: id },
            config: {
                customActions: [
                    {
                        icon: (
                            <UserIcon
                                color={theme.palette.backgroundBrandPrimary}
                                fill={theme.palette.darkestBlue}
                            />
                        ),
                        label: `${t('user.edit')}`,
                        ariaLabel: `${t('user.edit')}`,
                        showInMenu: true,
                        action: (params) => navigate(navigateToAdmin(params)),
                        permissions: [UserPermissionEnum.ClientCreateModify]
                    },
                    {
                        label: `${t('user.resendInvite')}`,
                        ariaLabel: `${t('user.resendInvite')}`,
                        hide: (params) =>
                            !params?.row?.active ||
                            params?.row?.status?.id !==
                                UserInviteStatus.PENDING ||
                            !params?.row?.inviteSent,
                        ...sendInviteAction,
                        permissions: [UserPermissionEnum.ClientCreateModify]
                    },

                    {
                        label: `${t('user.sendInvite')}`,
                        ariaLabel: `${t('user.sendInvite')}`,
                        hide: (params) =>
                            !params?.row?.active || params?.row?.inviteSent,
                        ...sendInviteAction,
                        permissions: [UserPermissionEnum.ClientCreateModify]
                    }
                ],
                columns: [
                    {
                        field: 'status',
                        headerName: '',
                        maxWidth: 30,
                        flex: 1,
                        renderCell: (params) =>
                            !params?.row?.active ? (
                                <ActiveIcon
                                    color={theme.palette.errorDangerMedium}
                                />
                            ) : params?.row?.status?.id ===
                              UserInviteStatus.PENDING ? (
                                <ActiveIcon color={theme.palette.yellow} />
                            ) : (
                                <ActiveIcon />
                            )
                    },
                    {
                        field: 'avatarSrc',
                        headerName: '',
                        flex: 0,
                        renderCell: (params) => (
                            <StyledLink to={navigateToAdmin(params)}>
                                <Grid sx={{ p: 1 }}>
                                    <Avatar
                                        sx={{
                                            border: `1px solid ${theme.palette.borderGrey}`
                                        }}
                                        src={params?.value}
                                    />
                                </Grid>
                            </StyledLink>
                        )
                    },

                    {
                        field: 'name',
                        headerName: t('general.name'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={`${params?.row?.firstName} ${params?.row?.lastName}`}
                                toolTip={params.row.title}
                                tabIndex={params.tabIndex}
                                onClick={() =>
                                    navigate(navigateToAdmin(params))
                                }
                                isDirectoryLink
                            />
                        )
                    },
                    {
                        field: 'title',
                        headerName: t('general.title'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={params?.value}
                                tabIndex={params.tabIndex}
                            />
                        ),
                        flex: 1
                    },
                    {
                        field: 'roles',
                        headerName: t('general.role'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={params?.value?.[0]?.title}
                                tabIndex={params.tabIndex}
                            />
                        ),
                        flex: 1
                    },
                    {
                        field: 'email',
                        headerName: t('general.email'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={params?.value}
                                tabIndex={params.tabIndex}
                            />
                        ),
                        flex: 2
                    },
                    {
                        field: 'staffType',
                        headerName: t('general.staff'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={params?.value?.title}
                                tabIndex={params.tabIndex}
                            />
                        ),
                        flex: 1
                    },
                    {
                        field: 'createdAt',
                        headerName: t('general.date'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={
                                    params?.row?.status?.id ===
                                    UserInviteStatus.PENDING
                                        ? t('user.pendingInvitation')
                                        : params?.value
                                        ? formatDate(params?.value)
                                        : ''
                                }
                                tabIndex={params.tabIndex}
                            />
                        )
                    }
                ],
                hideFooter: false,
                pagination: true
            },
            enableSearch: true,
            toolbarConfig: {
                header: t('general.users'),
                addNewButtonConfig: {
                    label: t('general.createNew'),
                    ariaLabel: t('general.user'),
                    action: () => navigate(`new`),
                    icon: AddIcon,
                    permissions: [UserPermissionEnum.ClientCreateModify]
                }
            }
        }
    })

    return directory
}
