import { ListItemIcon, styled } from '@mui/material'

export const StyledListItemIcon = styled(ListItemIcon)`
    svg {
        path {
            stroke: ${({ stroke }) =>
                stroke ? `${stroke} !important` : 'auto'};
            stroke-width: ${({ stroke }) => (stroke ? '1px' : 'auto')};
        }
    }
`
