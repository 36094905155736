import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { ReportGranularity, TIME_UNIT } from '@constants'

dayjs.extend(duration)
dayjs.extend(advancedFormat)

export const generateCustomIntervals = (
    minDate: string,
    maxDate: string,
    interval: string
) => {
    const startDate = dayjs(minDate)
    const endDate = dayjs(maxDate)
    const now = dayjs()

    function formatDate(date, isHourly) {
        const options = {
            month: 'short',
            day: 'numeric',
            ...(isHourly && {
                hour: 'numeric',
                hour12: true
            })
        }
        return new Date(date)?.toLocaleDateString('en-US', options)
    }

    const getEpochForDates = (date, isHourly) => {
        if (isHourly) {
            const epochTime = new Date(date)?.getTime()
            return epochTime
        } else {
            return date.startOf('day').unix()
        }
    }

    const intervals = []
    let current = startDate

    while (current <= endDate) {
        intervals.push({
            id: current.valueOf(),
            isoFormat: current.toISOString(),
            date: current.format('YYYY-MM-DD'),
            numeric: true,
            disablePadding: false,
            label: formatDate(current, interval === ReportGranularity.HOURLY),
            showColumn: true,
            epoch: getEpochForDates(
                current,
                interval === ReportGranularity.HOURLY
            )
        })
        switch (interval) {
            case ReportGranularity.DAILY:
                current = current.add(1, TIME_UNIT.DAY)
                break
            case ReportGranularity.HOURLY:
                current = current.add(1, 'hour')
                break
            case ReportGranularity.WEEKLY:
                current = current.add(1, 'week')
                break
            case ReportGranularity.MONTHLY:
                current = current.add(1, TIME_UNIT.MONTH)
                break
            default:
            // Throw our own error
        }
    }

    return intervals
}
