import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const REPORT_VIDEO = gql`
  ${MetaFragment('VideoResponseDto')}
  ${MessagesFragment('VideoResponseDto')}
  mutation reportVideo($input: RejectVideoInputDto!) {
    reportVideo(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        id
      }
    }
  }
`
