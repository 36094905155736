import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const DuplicateIcon: React.FC<SvgProps> = ({
    color,
    width = 24,
    height = 24,
    fill
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary
    const _fill = theme.palette.darkestBlue
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox={`0 0 24 24`}
            fill='none'
        >
            <path
                d='M9 12.2C9 11.0799 9 10.5198 9.21799 10.092C9.40973 9.71569 9.71569 9.40973 10.092 9.21799C10.5198 9 11.0799 9 12.2 9H18.8C19.9201 9 20.4802 9 20.908 9.21799C21.2843 9.40973 21.5903 9.71569 21.782 10.092C22 10.5198 22 11.0799 22 12.2V18.8C22 19.9201 22 20.4802 21.782 20.908C21.5903 21.2843 21.2843 21.5903 20.908 21.782C20.4802 22 19.9201 22 18.8 22H12.2C11.0799 22 10.5198 22 10.092 21.782C9.71569 21.5903 9.40973 21.2843 9.21799 20.908C9 20.4802 9 19.9201 9 18.8V12.2Z'
                fill={_fill}
                fillOpacity='0.1'
            />
            <path
                d='M5 15C4.06812 15 3.60218 15 3.23463 14.8478C2.74458 14.6448 2.35523 14.2554 2.15224 13.7654C2 13.3978 2 12.9319 2 12V5.2C2 4.0799 2 3.51984 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.51984 2 4.0799 2 5.2 2H12C12.9319 2 13.3978 2 13.7654 2.15224C14.2554 2.35523 14.6448 2.74458 14.8478 3.23463C15 3.60218 15 4.06812 15 5M12.2 22H18.8C19.9201 22 20.4802 22 20.908 21.782C21.2843 21.5903 21.5903 21.2843 21.782 20.908C22 20.4802 22 19.9201 22 18.8V12.2C22 11.0799 22 10.5198 21.782 10.092C21.5903 9.71569 21.2843 9.40973 20.908 9.21799C20.4802 9 19.9201 9 18.8 9H12.2C11.0799 9 10.5198 9 10.092 9.21799C9.71569 9.40973 9.40973 9.71569 9.21799 10.092C9 10.5198 9 11.0799 9 12.2V18.8C9 19.9201 9 20.4802 9.21799 20.908C9.40973 21.2843 9.71569 21.5903 10.092 21.782C10.5198 22 11.0799 22 12.2 22Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default DuplicateIcon
