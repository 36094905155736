import { DialogActions, Typography, styled } from '@mui/material'

export const StyledDialogTitle = styled(Typography)`
    font-family: 'Roboto';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.48px;
`
export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    padding: '16px 24px',
    position: 'sticky',
    backgroundColor: theme.palette.backgroundPrimary,
    zIndex: 10,
    bottom: 0
}))
