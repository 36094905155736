import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const AddIcon: React.FC<SvgProps> = ({ color, width = 20, height = 20 }) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentPrimaryInverse
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 20 20`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M9.99996 4.16663V15.8333M4.16663 9.99996H15.8333'
                stroke={_color}
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default AddIcon
