import { gql } from '@apollo/client'

import { MessagesFragment, MetaFragment } from '../../../fragments'

export const GET_REPORTS_BY_LIBRARY_ID = gql`
  ${MetaFragment('ReportLibraryByIdResponseDto')}
  ${MessagesFragment('ReportLibraryByIdResponseDto')}
  query getReportsByLibraryId($args: GetReportByLibraryIdArgumentsDto!) {
    getReportsByLibraryId(args: $args) {
      ...MetaFragment
      ...MessagesFragment
      data {
        id
        title
        thumbnailSrc
      }
    }
  }
`
