import { gql } from '@apollo/client'

export const VerticalFields = (on: string) => gql`
    fragment VerticalFields on ${on} {
        id
        title
        description
        tags
        isDraft
        isArchived
        template{
            id
            title
        }
        isTemplate
        createdAt
        updatedAt
        creator{
            id
            firstName
            lastName
            role
            avatar
            avatarSrc
        }
        modifier{
            id
            firstName
            lastName
            role
            avatar
            avatarSrc
        }
        creatorId

        courses{
            id
            title
            description
            courseId
            courseTopics{
                topic{
                    id
                    title
                    description
                    isArchived
                    createdAt
                    updatedAt
                    videosCount
                    coursesCount
                }
                topicId
                duration
                isAssigned
            }
            topicsCount
        }
        skills{
            id
            title
        }
        videos {
            id
            coverImage
            coverImageSrc
            title
            status
            description
           
            creatorData{
                id
                firstName
                lastName
            }
          }
          videosCount
          verticalsCount
    }
`
