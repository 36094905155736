import React from 'react'
import { FormProvider } from 'react-hook-form'
import { mapDropdownOptions } from '@dts/client-utils'
import { GET_PORTAL_CLIENTS } from '@dts/graphql'
import { useFormFeedback, usePage } from '@hooks'
import { Grid } from '@mui/material'
import { ClientPortalForm } from './portal-into-client-form'

export const ClientPortalContainer = () => {
    const { methods } = useFormFeedback({
        initialValues: {
            portalClients: []
        },
        query: GET_PORTAL_CLIENTS,
        queryName: 'getPortalClients',
        retrieveOnMount: true,
        mapQueryResultToForm: (portalClients) => ({
            portalClients: mapDropdownOptions(portalClients)
        })
    })

    return (
        <FormProvider {...methods}>
            <ClientPortalForm />
        </FormProvider>
    )
}
