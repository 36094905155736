import { gql } from '@apollo/client'

export const KEY_FRAMES_READY_SUBSCRIPTION = gql`
  subscription keyFramesReady {
    keyFramesReady {
      userId
      videoId
      fileName
    }
  }
`
export const GET_VIDEO_KEY_FRAMES = gql`
  query GetVideoKeyFrames($url: String!, $videoId: String!) {
    getVideoKeyFrames(url: $url, videoId: $videoId) {
      data {
        keyFrames
        keyFramesSrc
      }
    }
  }
`
