import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { ControlledSelect, ControlledTextInput } from '@components'
import { Grid } from '@mui/material'
import { HelperTextPosition, REPORT_LIBRARY } from '@constants'
import { useGraphQLWrapper } from '@hooks'
import { GET_REPORT_LIBRARIES } from '@dts/graphql'
import { mapDropdownOptions } from '@dts/client-utils'

export const SaveReportDialog = () => {
    const { t } = useTranslation()
    const { setValue, control } = useFormContext()
    const { libraryId } = useParams()
    const reportLibraryId = useWatch({
        control,
        name: 'reportLibraryId'
    })
    const { currentData: reportLibraries } = useGraphQLWrapper({
        query: GET_REPORT_LIBRARIES,
        queryName: 'getAllReportLibraries',
        retrieveOnMount: true,
        queryVariables: {
            args: {
                tab: REPORT_LIBRARY.CUSTOM
            }
        }
    })
    useEffect(() => {
        if (reportLibraries && !reportLibraryId)
            setValue('reportLibraryId', libraryId ?? reportLibraries?.[0]?.id)
    }, [reportLibraries])

    return (
        <Grid container>
            <Grid item xs={12} md={12} gap={4}>
                <ControlledTextInput
                    id='title'
                    label={t('general.title')}
                    name='title'
                    multiline
                    rows={1}
                    helperTextConfig={{
                        wordLength: 200,
                        helperTextPosition: HelperTextPosition.Right
                    }}
                    fullWidth
                />
                <ControlledSelect
                    name='reportLibraryId'
                    ariaLabel={t('report.reportLibrary')}
                    id='reportLibraryId'
                    label={t('report.reportLibrary')}
                    options={mapDropdownOptions(reportLibraries)}
                />
            </Grid>
        </Grid>
    )
}
