import * as React from 'react'
import { useEffect, useState } from 'react'
import { controlled } from '@hoc'
import {
    Avatar,
    Card,
    CardContent,
    Divider,
    Grid,
    Radio,
    Typography
} from '@mui/material'
import { CampusLogo } from '@assets'
import { useCustomTheme } from '@hooks'
import {
    RadioCardGrid,
    StyledFormControlLabel,
    StyledRadioGroup
} from './styles'
import { RadioGroupProps } from './types'

export const RadioButtonsGroup = ({
    name,
    value,
    onChange,
    options,
    sx,
    disabled = false,
    hasCardView = false,
    isButton,
    hasColumnDirection = false,
    gap,
    hasDivider = false,
    hasStartLabelPlacement = false
}: RadioGroupProps) => {
    const { theme } = useCustomTheme()
    const [radioValue, setRadioValue] = useState(value)

    useEffect(() => {
        setRadioValue(value)
    }, [value])

    const isSelected = (val?: string) => radioValue === val

    const getRadioCard = (option) => {
        const ButtonIcon = option?.icon
        return (
            <RadioCardGrid
                item
                md={4.5}
                onClick={() => {
                    setRadioValue(option?.value)
                    onChange(option?.value)
                }}
                sx={
                    isSelected(option?.value)
                        ? {
                              backgroundColor:
                                  theme.palette.BrandPrimaryContainer,
                              border: `1px solid ${theme.palette.navyBlue}`
                          }
                        : {
                              border: `1px solid ${theme.palette.backgroundTertiary}`,
                              backgroundColor: theme.palette.backgroundSecondary
                          }
                }
                xs={5}
            >
                <Grid direction={'column'} width={'100%'}>
                    <Grid
                        item
                        container
                        display={'flex'}
                        justifyContent={'space-between'}
                    >
                        <Grid item margin={'10px 20px'}>
                            {ButtonIcon ? <ButtonIcon /> : <></>}
                        </Grid>
                        <Grid item justifyContent={'flex-end'}>
                            <StyledFormControlLabel
                                key={option?.value}
                                value={option?.value}
                                aria-label={option?.label}
                                control={
                                    <Radio
                                        checked={isSelected(option?.value)}
                                        size='small'
                                    />
                                }
                                labelPlacement={
                                    hasStartLabelPlacement ? 'start' : 'end'
                                }
                                isButton={isButton}
                                isSelected={isSelected(option?.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        flexDirection={'column'}
                        sx={{ padding: '20px' }}
                    >
                        <Grid item>
                            <Typography variant='h2'>
                                {option?.label}
                            </Typography>
                        </Grid>
                        <Grid item mt={1}>
                            <Typography variant='caption'>
                                {option?.description}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </RadioCardGrid>
        )
    }

    return (
        <StyledRadioGroup
            name={name}
            value={radioValue}
            onChange={onChange}
            isButton={isButton}
            hasColumnDirection={hasColumnDirection}
            aria-setsize={'sm'}
            gap={gap}
        >
            {options?.map((option) =>
                hasCardView ? (
                    getRadioCard(option)
                ) : (
                    <>
                        <StyledFormControlLabel
                            key={option?.value}
                            value={option?.value}
                            disabled={disabled}
                            control={
                                <Radio
                                    checked={isSelected(option?.value)}
                                    size='small'
                                />
                            }
                            label={
                                <Grid container gap={2} alignItems={'center'}>
                                    {option?.radioIcon && (
                                        <option.radioIcon
                                            color={theme.palette.contentPrimary}
                                            height={22}
                                            width={22}
                                        />
                                    )}
                                    <Typography
                                        variant='body2'
                                        color={
                                            isSelected(option?.value) &&
                                            isButton
                                                ? `${theme.palette.contentLink} !important`
                                                : 'none'
                                        }
                                    >
                                        {option?.label}
                                    </Typography>
                                </Grid>
                            }
                            labelPlacement={
                                hasStartLabelPlacement ? 'start' : 'end'
                            }
                            isButton={isButton}
                            hasStartLabelPlacement={hasStartLabelPlacement}
                            isSelected={isSelected(option?.value)}
                        />
                        {hasDivider && <Divider />}
                    </>
                )
            )}
        </StyledRadioGroup>
    )
}

export const ControlledRadioGroup = controlled(RadioButtonsGroup)
