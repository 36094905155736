import React, { useEffect, useState } from 'react'
import { useFormContext, useFormState, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ChatIcon, GlobeIcon, MapPinIcon } from '@assets'
import {
    ControlledAutoCompleteSelect,
    ControlledCheckBox,
    ControlledSelect,
    ControlledTextInput,
    MaskInput
} from '@components'
import { HelperTextPosition, phoneNumberMask } from '@constants'
import {
    GET_CAMPUS,
    GET_CAMPUSES,
    GET_CITIES,
    GET_STATES,
    GET_ZIP_CODES
} from '@dts/graphql'
import {
    controlled,
    controlledWithSmartSelect,
    SelectTypeEnum,
    withSmartSelect
} from '@hoc'
import { useAuth, useCustomQuery, useCustomTheme } from '@hooks'
import { Divider, Grid, Typography } from '@mui/material'
import { setUserData } from '@cacheql'
import { mapDropdownOptions } from '@dts/client-utils'
import { cloneDeep } from 'lodash'
import { CreateResourceProps } from './types'

const ControlledStateSelect = controlledWithSmartSelect(GET_STATES)
const ControlledCampusSelect = controlledWithSmartSelect(GET_CAMPUSES)

export const CreateResources = ({
    categoryIndex,
    resourceIndex,
    isClientAdmin
}: CreateResourceProps) => {
    const { t } = useTranslation()
    const { control, setValue } = useFormContext()
    const formState = useFormState()

    const categories = useWatch({ name: 'categories', control })
    const { theme } = useCustomTheme()
    const currentUser = setUserData()

    const [cityOptions, setCityOptions] = useState([])
    const [zipCodeOptions, setZipCodeOptions] = useState([])

    const { retrieve: retrieveCampus, currentData: campusData } =
        useCustomQuery({
            query: GET_CAMPUS,
            queryName: 'getCampus',
            retrieveOnMount: false
        })

    const { retrieve: retrieveZipCodes, currentData: zipCodesData } =
        useCustomQuery({
            query: GET_ZIP_CODES,
            queryName: 'getZipCodes',
            retrieveOnMount: false
        })

    const { retrieve: retrieveCities, currentData: citiesData } =
        useCustomQuery({
            query: GET_CITIES,
            queryName: 'getCitiesDropDown',
            retrieveOnMount: false
        })

    const currentResourceName = `categories[${categoryIndex}].resources[${resourceIndex}]`

    const stateId = useWatch({
        control,
        name: `${currentResourceName}.stateId`
    })
    const cityId = useWatch({
        control,
        name: `${currentResourceName}.cityId`
    })

    const campusId = useWatch({
        control,
        name: `${currentResourceName}.campusId`
    })

    const onStateChanged = () => {
        const currentResource =
            categories?.[categoryIndex]?.resources?.[resourceIndex]
        setValue(`categories[${categoryIndex}].resources[${resourceIndex}]`, {
            ...currentResource,
            cityId: null,
            zipCode: ''
        })

        setCityOptions([])
        setZipCodeOptions([])
    }

    const onCityChanged = () => {
        const currentResource =
            categories?.[categoryIndex]?.resources?.[resourceIndex]
        setValue(`categories[${categoryIndex}].resources[${resourceIndex}]`, {
            ...currentResource,
            zipCode: ''
        })

        setZipCodeOptions([])
    }

    useEffect(() => {
        if (
            formState?.dirtyFields?.categories?.[categoryIndex]?.resources?.[
                resourceIndex
            ]?.stateId === true
        ) {
            onStateChanged()
        }
    }, [stateId])

    useEffect(() => {
        if (
            formState?.dirtyFields?.categories?.[categoryIndex]?.resources?.[
                resourceIndex
            ]?.cityId === true
        ) {
            onCityChanged()
        }
    }, [cityId])

    useEffect(() => {
        cityId && retrieveZipCodes({ variables: { cityId } })
    }, [cityId])

    useEffect(() => {
        stateId && retrieveCities({ variables: { stateId } })
    }, [stateId])

    useEffect(() => {
        if (citiesData) {
            const options = mapDropdownOptions(citiesData)
            setCityOptions(options)
        }
    }, [citiesData])

    useEffect(() => {
        if (zipCodesData) {
            const options = mapDropdownOptions(zipCodesData, 'zipCode')
            setZipCodeOptions(options)
        }
    }, [zipCodesData])

    useEffect(() => {
        if (isClientAdmin) {
            if (campusId && currentUser?.clientId) {
                retrieveCampus({
                    variables: { campusId, clientId: currentUser?.clientId }
                })
            } else if (
                !campusId &&
                formState?.dirtyFields?.categories?.[categoryIndex]
                    ?.resources?.[resourceIndex]?.campusId === true
            ) {
                const clonedCategories = cloneDeep(categories)
                const currentResource =
                    clonedCategories?.[categoryIndex]?.resources?.[
                        resourceIndex
                    ]
                setValue(
                    `categories[${categoryIndex}].resources[${resourceIndex}]`,
                    {
                        ...currentResource,
                        stateId: null,
                        address: '',
                        cityId: null,
                        zipCode: ''
                    }
                )
            }
        }
    }, [campusId])

    useEffect(() => {
        if (isClientAdmin && campusData) {
            const { city, state, zipCode, address } = campusData || {}

            const currentResource =
                categories?.[categoryIndex]?.resources?.[resourceIndex]

            setValue(
                `categories[${categoryIndex}].resources[${resourceIndex}]`,
                {
                    ...currentResource,
                    stateId: state?.id,
                    cityId: city?.id,
                    zipCode,
                    address
                }
            )
        }
    }, [campusData])

    return (
        <Grid container flexDirection={'column'}>
            <Grid item xs={12} md={12}>
                <ControlledTextInput
                    name={`categories[${categoryIndex}].resources[${resourceIndex}].description`}
                    id={`categories-${categoryIndex}-resources-${resourceIndex}-description`}
                    label={t('general.description')}
                    multiline
                    rows={3}
                    helperTextConfig={{
                        wordLength: 1000,
                        helperTextPosition: HelperTextPosition.Right
                    }}
                    fullWidth
                    errorKey='description'
                />
            </Grid>
            <Grid item container flexDirection={'row'} xs={12} md={8} gap={2}>
                <Grid item>
                    <ControlledCheckBox
                        name={`categories[${categoryIndex}].resources[${resourceIndex}].isConfidential`}
                        label={t('general.confidential')}
                        id={`categories-${categoryIndex}-resources-${resourceIndex}-confidential-resource`}
                    />
                </Grid>
                {!isClientAdmin && (
                    <Grid item>
                        <ControlledCheckBox
                            name={`categories[${categoryIndex}].resources[${resourceIndex}].isNationalResource`}
                            label={t('resources.nationalResource')}
                            id={`categories-${categoryIndex}-resources-${resourceIndex}-national-resource`}
                        />
                    </Grid>
                )}
                <Grid item>
                    <ControlledCheckBox
                        name={`categories[${categoryIndex}].resources[${resourceIndex}].isAlwaysAvailable`}
                        label={t('resource.247badge')}
                        id={`categories-${categoryIndex}-resources-${resourceIndex}-always-available`}
                    />
                </Grid>
            </Grid>

            <Grid item mt={2}>
                <Typography variant='h3'>
                    {t('general.otherInformation')}
                </Typography>
            </Grid>
            <Grid item mt={2}>
                <Typography
                    variant='h4'
                    color={theme?.palette?.neutralsTextGrey}
                >
                    {t('resources.resourceAddress')}
                </Typography>
                <Divider sx={{ marginTop: '8px' }} />
            </Grid>

            {isClientAdmin && (
                <Grid item>
                    <ControlledCampusSelect
                        name={`categories[${categoryIndex}].resources[${resourceIndex}].campusId`}
                        label={t('general.campus')}
                        id={`categories-${categoryIndex}-resources-${resourceIndex}-campusId`}
                        queryVariables={
                            currentUser?.clientId
                                ? { clientId: currentUser?.clientId }
                                : {}
                        }
                        selectType={SelectTypeEnum.Single}
                        accessor='data'
                        width={'100%'}
                        clearable
                        errorKey='campus'
                    />
                </Grid>
            )}

            <Grid
                item
                container
                flexDirection={'row'}
                xs={12}
                md={12}
                alignItems={'flex-end'}
                justifyContent={'space-between'}
            >
                <Grid item xs={12} md={3.9} sm={3.9}>
                    <ControlledStateSelect
                        name={`categories[${categoryIndex}].resources[${resourceIndex}].stateId`}
                        label={t('general.state')}
                        ariaLabel={t('general.state')}
                        id={`categories-${categoryIndex}-resources-${resourceIndex}-stateId`}
                        selectType={SelectTypeEnum.Single}
                        accessor='data'
                        width={'100%'}
                        clearable
                        onClear={() => onStateChanged()}
                    />
                </Grid>
                <Grid item xs={12} md={3.9} sm={3.9}>
                    <ControlledSelect
                        name={`categories[${categoryIndex}].resources[${resourceIndex}].cityId`}
                        label={t('general.city')}
                        ariaLabel={t('general.city')}
                        id={`categories-${categoryIndex}-resources-${resourceIndex}-cityId`}
                        clearable
                        onClear={() => onCityChanged()}
                        options={cityOptions}
                    />
                </Grid>
                <Grid item xs={12} md={3.9} sm={3.9}>
                    <ControlledAutoCompleteSelect
                        name={`categories[${categoryIndex}].resources[${resourceIndex}].zipCode`}
                        label={t('general.zipCode')}
                        placeholder={t('general.zipCode')}
                        id={`categories-${categoryIndex}-resources-${resourceIndex}-zipCode`}
                        options={mapDropdownOptions(zipCodesData, 'zipCode')}
                        textColor={theme.palette.contentPrimary}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
                <ControlledTextInput
                    name={`categories[${categoryIndex}].resources[${resourceIndex}].address`}
                    id={`categories-${categoryIndex}-resources-${resourceIndex}-address`}
                    label={t('general.address')}
                    StartIcon={MapPinIcon}
                    fullWidth
                />
            </Grid>

            <Grid item mt={3}>
                <Typography
                    variant='h4'
                    color={theme?.palette?.neutralsTextGrey}
                >
                    {t('general.emailAndPhone')}
                </Typography>
                <Divider sx={{ marginTop: '8px' }} />
            </Grid>

            <Grid item xs={12} md={12}>
                <ControlledTextInput
                    name={`categories[${categoryIndex}].resources[${resourceIndex}].email`}
                    id={`categories-${categoryIndex}-resources-${resourceIndex}-email`}
                    label={t('general.email')}
                    fullWidth
                />
            </Grid>

            {[
                t('resources.one'),
                t('resources.two'),
                t('resources.three')
            ]?.map((phoneNumber, phoneNumberIndex) => (
                <Grid item container flexDirection={'row'} gap={1}>
                    <Grid item xs={12} sm={3} md={3}>
                        <MaskInput
                            id={`categories-${categoryIndex}-resources-${resourceIndex}-phone-number-${
                                phoneNumberIndex + 1
                            }`}
                            name={`categories[${categoryIndex}].resources[${resourceIndex}].phoneNumber${phoneNumber}`}
                            label={t('general.phone')}
                            placeholder={[
                                t('general.phone'),
                                phoneNumberIndex + 1
                            ].join(' ')}
                            mask={phoneNumberMask}
                            width='100%'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs>
                        <ControlledTextInput
                            name={`categories[${categoryIndex}].resources[${resourceIndex}].phoneNumber${phoneNumber}Description`}
                            id={`categories-${categoryIndex}-resources-${resourceIndex}-phone-number-description-${
                                phoneNumberIndex + 1
                            }`}
                            placeholder={t(`resources.phoneDescription`, {
                                phoneNumberIndex: phoneNumberIndex + 1
                            })}
                            aria-label={`${t(`general.phone`)} ${t(
                                'general.description'
                            )}`}
                            label={`${t(`general.phone`)} ${t(
                                'general.description'
                            )}`}
                            errorKey={`phoneNumberDescription`}
                        />
                    </Grid>
                </Grid>
            ))}

            <Grid item mt={3}>
                <Typography
                    variant='h4'
                    color={theme?.palette?.neutralsTextGrey}
                >
                    {t('general.website')}
                </Typography>
                <Divider sx={{ marginTop: '8px' }} />
            </Grid>

            <Grid item container flexDirection={'row'} gap={1}>
                <Grid item xs={12} sm={3} md={3}>
                    <ControlledTextInput
                        name={`categories[${categoryIndex}].resources[${resourceIndex}].websiteDisplayText`}
                        id={`categories-${categoryIndex}-resources-${resourceIndex}-display-text`}
                        label={t('general.displayText')}
                        errorKey={`websiteDisplayText`}
                        fullWidth
                    />
                </Grid>
                <Grid item xs>
                    <ControlledTextInput
                        name={`categories[${categoryIndex}].resources[${resourceIndex}].websiteUrl`}
                        id={`categories-${categoryIndex}-resources-${resourceIndex}-website-url`}
                        label={t('general.website')}
                        fullWidth
                        StartIcon={GlobeIcon}
                    />
                </Grid>
            </Grid>

            <Grid item container flexDirection={'row'} gap={1}>
                <Grid item xs={12} sm={3} md={3}>
                    <ControlledTextInput
                        name={`categories[${categoryIndex}].resources[${resourceIndex}].onlineChatDisplayText`}
                        id={`categories-${categoryIndex}-resources-${resourceIndex}-online-chat-display-text`}
                        label={t('general.displayText')}
                        errorKey={`displayText`}
                        fullWidth
                    />
                </Grid>
                <Grid item xs>
                    <ControlledTextInput
                        name={`categories[${categoryIndex}].resources[${resourceIndex}].onlineChatUrl`}
                        id={`categories-${categoryIndex}-resources-${resourceIndex}-online-chat-url`}
                        label={t('resources.chatLink')}
                        fullWidth
                        StartIcon={ChatIcon}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
