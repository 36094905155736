import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { ModalProvider, ToastProvider } from '@context'
import { AuthProvider } from '@providers/auth'
import { LoaderProvider, ThemeProvider } from '@providers'
import { client } from '../config/apollo-client'
import { NetworkProvider } from './network-provider'
import { VideoProvider } from './video-provider'
import { GlobalProvider } from './global-provider'

type Props = {
    children: React.ReactNode
}

export const Providers: React.FC<Props> = ({ children }) => (
    <ApolloProvider client={client}>
        <BrowserRouter>
            <AuthProvider>
                <ThemeProvider>
                    <LoaderProvider>
                        <ToastProvider>
                            <GlobalProvider>
                                <NetworkProvider>
                                    <VideoProvider>
                                        <ModalProvider>
                                            {children}
                                        </ModalProvider>
                                    </VideoProvider>
                                </NetworkProvider>
                            </GlobalProvider>
                        </ToastProvider>
                    </LoaderProvider>
                </ThemeProvider>
            </AuthProvider>
        </BrowserRouter>
    </ApolloProvider>
)
