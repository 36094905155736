import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { CustomLoader, Toolbar } from '@components'
import { DocumentNode, QueryResult, UpdateQueryOptions } from '@apollo/client'
import { useGraphQLWrapper, useLoader } from '@hooks'

import { ApolloResponse } from '@dts/client-utils'
import { Grid } from '@mui/material'

export type UseInfiniteScrollConfig = {
    query: DocumentNode
    queryName: string
    queryVariables?: object
    loadComponent: (data: Array<object>) => JSX.Element
    toolbarConfig?: { toolbarHeader: string; enableSearch?: boolean }
    subscription?: DocumentNode
    subscriptionVariables?: object
    updateQuery?: (
        previousQueryResult: ApolloResponse<QueryResult>,
        options: UpdateQueryOptions | object
    ) => ApolloResponse<QueryResult>
}

export const useInfiniteScroll = (config: UseInfiniteScrollConfig) => {
    const {
        query,
        queryName,
        subscription,
        updateQuery,
        subscriptionVariables,
        queryVariables: queryVars,
        loadComponent,
        toolbarConfig,
        accessor
    } = config
    const { loading } = useLoader()
    const [queryVariables, setQueryVariables] = useState({
        options: {
            page: 1,
            pageSize: 10
        },
        ...queryVars
    })

    const [data, setData] = useState<Array<unknown>>([])

    const {
        currentData,
        retrieve,
        data: queryData,
        queryLoading
    } = useGraphQLWrapper({
        query,
        queryName,
        retrieveOnMount: false,
        queryVariables,
        subscription,
        updateQuery,
        subscriptionVariables
    })

    useEffect(() => {
        retrieve?.({
            variables: queryVariables
        })
    }, [queryVariables])

    useEffect(() => {
        if (currentData) {
            const incomingData = accessor
                ? currentData?.[accessor]
                : currentData

            if (queryVariables?.options?.page === 1) {
                setData(incomingData)
            } else {
                setData((existingData) => [...existingData, ...incomingData])
            }
        }
    }, [currentData])

    const fetchMore = () => {
        setQueryVariables?.((prevQueryVariables: object) => ({
            ...prevQueryVariables,
            options: {
                ...prevQueryVariables?.options,
                page: prevQueryVariables?.options?.page + 1
            }
        }))
    }

    const toolbarConfiguration = {
        header: toolbarConfig?.toolbarHeader,
        ...(toolbarConfig?.enableSearch
            ? {
                  searchConfig: {
                      onSearch: (value: string, isValueEntered?: boolean) => {
                          setQueryVariables((prevOptions) => ({
                              ...prevOptions,
                              options: {
                                  ...prevOptions.options,
                                  search: isValueEntered ? value : undefined
                              }
                          }))
                      }
                  }
              }
            : {})
    }

    const renderWrappedComponent = () => (
        <>
            {toolbarConfig?.enableSearch && (
                <Toolbar toolbarConfig={toolbarConfiguration} />
            )}

            <InfiniteScroll
                dataLength={currentData?.length ?? 10}
                hasMore={currentData?.length !== queryData?.meta?.count}
                next={fetchMore}
                scrollThreshold={0.5}
                style={{ minHeight: '100%' }}
                scrollableTarget='content-container'
            >
                {loadComponent(data)}

                {loading && (
                    <Grid container justifyContent='center' alignItems='center'>
                        <Grid item mt={1}>
                            <CustomLoader />
                        </Grid>
                    </Grid>
                )}
            </InfiniteScroll>
        </>
    )

    return {
        data,
        setData,
        setQueryVariables,
        retrieve,
        wrappedComponent: renderWrappedComponent(),
        queryLoading,
        queryData: queryData?.data
    }
}
