import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, LinearProgress, Tooltip, Typography } from '@mui/material'
import { ReadingIcon } from '@assets'
import { useCustomTheme } from '@hooks'
import { getWordsCount } from '@dts/client-utils/lib'
import { onKeyDown } from '@utils'
import { TrainingsCardProps } from './types'
import { StyledPaper, StyledTypography } from './styles'

export const TrainingsCard: FC<TrainingsCardProps> = (props) => {
    const {
        trainingData = [],
        selectedTraining,
        setSelectedTraining
    } = props || {}
    const { theme } = useCustomTheme()
    const { t } = useTranslation()

    const handleTrainingSelection = (trainingId: string | number) => {
        const selectedTraining = trainingData.find(
            (training) => training.id === trainingId
        )
        if (selectedTraining) {
            setSelectedTraining(selectedTraining)
        }
    }

    return (
        <>
            <StyledTypography
                variant='body2'
                fontSize={14}
                fontWeight={600}
                color={theme.palette.contentPrimary}
            >
                {t('general.trainings')}
            </StyledTypography>
            {trainingData?.map(
                ({ id, description, progress, title, courses }, index) => (
                    <StyledPaper
                        key={title + index}
                        background={
                            selectedTraining?.id !== id
                                ? theme.palette.backgroundPrimary
                                : theme.palette.BrandPrimaryContainer
                        }
                        borderBottom={
                            trainingData?.length - 1 !== index &&
                            `1px solid ${theme.palette.borderSecondary}`
                        }
                        tabIndex={0}
                        onKeyDown={(e) => {
                            onKeyDown(
                                e,
                                () => handleTrainingSelection(id),
                                false
                            )
                        }}
                        onClick={() => handleTrainingSelection(id)}
                    >
                        <Tooltip title={title}>
                            <Typography
                                variant='body2'
                                fontSize={14}
                                fontWeight={600}
                                color={theme.palette.contentLink}
                                mb={1}
                                sx={
                                    title && getWordsCount(title) > 7
                                        ? {
                                              display: '-webkit-box',
                                              overflow: 'hidden',
                                              WebkitBoxOrient: 'vertical',
                                              WebkitLineClamp: 3, // Change the number to the desired number of lines
                                              height: '55px' // Adjust based on the number of lines and line-height
                                          }
                                        : {}
                                }
                            >
                                {title}
                            </Typography>
                        </Tooltip>
                        <LinearProgress
                            value={progress}
                            variant='determinate'
                        />
                        <Tooltip title={description}>
                            {description ? (
                                <Typography
                                    variant='subtitle2'
                                    fontSize={12}
                                    fontWeight={500}
                                    color={theme.palette.contentSecondary}
                                    my={1}
                                    sx={
                                        description &&
                                        getWordsCount(description) > 10
                                            ? {
                                                  display: '-webkit-box',
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  WebkitBoxOrient: 'vertical',
                                                  WebkitLineClamp: 3, // Change the number to the desired number of lines
                                                  height: '60px' // Adjust based on the number of lines and line-height
                                              }
                                            : {}
                                    }
                                >
                                    {description}
                                </Typography>
                            ) : (
                                <></>
                            )}
                        </Tooltip>

                        <Grid
                            my={!description && 1}
                            display={'flex'}
                            alignItems={'center'}
                        >
                            <ReadingIcon
                                width={18}
                                height={18}
                                color={theme.palette.contentSecondary}
                            />
                            <Typography
                                variant='subtitle2'
                                fontSize={12}
                                fontWeight={500}
                                color={theme.palette.contentPrimary}
                                ml={1}
                            >
                                {[courses?.length, t('general.courses')].join(
                                    ' '
                                )}
                            </Typography>
                        </Grid>
                    </StyledPaper>
                )
            )}
        </>
    )
}
