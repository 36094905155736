import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { VideoPlayer } from '@components'
import {
    Button,
    Typography,
    Avatar,
    Grid,
    Link,
    useMediaQuery
} from '@mui/material'
import { setUserData } from '@cacheql'
import { StyledDirectoryLink } from '@containers/styles'
import { useCustomTheme } from '@hooks'
import {
    StyledDescriptionTypography,
    StyledDetailedOverlay,
    StyledHyperLinkTypography,
    StyledMetaDataTypography,
    VideoGrid
} from './styles'
import { VideoMobileViewProps } from './types'

export const VideoMobileView: React.FC<VideoMobileViewProps> = ({
    video,
    isFirstEntry,
    config,
    index,
    accessRoles,
    isLearnerBrowse,
    surveyContextData
}) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const navigate = useNavigate()
    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )
    const isNotCurrentUser = video?.creatorData?.id !== setUserData()?.id
    const [metaDataExpanded, setMetaDataExpanded] = useState(false)

    const { accountInteractions, menuConfig, onFollow, actionIcons } = config

    const goToCreatorProfile = () =>
        navigate(`/creator-profile/${video?.creatorData?.id}/all-video`)

    const renderCreatorInfo = (
        <>
            <Grid
                item
                display={'flex'}
                flexDirection={'row'}
                sx={{ alignItems: 'center', gap: '10px' }}
            >
                <Avatar
                    sx={{
                        border: `1px solid ${theme.palette.backgroundPrimary}`,
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }}
                    src={video?.creatorData?.avatarSrc}
                    alt=''
                    onClick={() => goToCreatorProfile()}
                />
                <StyledDirectoryLink
                    variant='h3'
                    sx={{ color: theme.palette.contentPrimaryInverse }}
                    onClick={() => goToCreatorProfile()}
                >
                    {`${video?.creatorData?.firstName}`}
                </StyledDirectoryLink>
                {isNotCurrentUser && (
                    <Button
                        variant='outlined'
                        sx={{
                            color: theme.palette.contentLink,
                            border: `1px solid ${theme.palette.contentLink}`,
                            height: '16px'
                        }}
                        onClick={() => {
                            onFollow()
                        }}
                    >
                        {accountInteractions?.following
                            ? t('setting.following')
                            : t('general.follow')}
                    </Button>
                )}
            </Grid>
        </>
    )

    const renderButtonLink = (text?: string) => (
        <div onClick={() => setMetaDataExpanded(!metaDataExpanded)}>
            <Typography
                variant='subtitle2'
                color={theme.palette.contentPrimaryInverse}
                sx={{
                    cursor: 'pointer',
                    '&:hover': {
                        color: theme.palette.chipsBlue
                    }
                }}
            >
                {text}
            </Typography>
        </div>
    )

    return (
        <VideoGrid>
            <VideoPlayer
                value={video?.urlSrc}
                videoId={video?.id}
                isSwipeable
                handleWatchTime={accessRoles?.isLearner}
                isFirstEntry={isFirstEntry}
                hasMuteAction
                actionIcons={isNotCurrentUser && actionIcons}
                captionFileSrc={video?.captionFileSrc}
                disableBar={accessRoles?.isLearner}
                index={index}
                isLearnerBrowse={isLearnerBrowse}
                surveyContextData={surveyContextData}
                MetaDataComponent={
                    !metaDataExpanded ? (
                        <Grid
                            container
                            style={{
                                flexDirection: 'column',
                                gap: '10px',
                                paddingBottom: '20px',
                                paddingLeft: '20px'
                            }}
                        >
                            {renderCreatorInfo}
                            <Grid item>
                                <StyledMetaDataTypography variant='subtitle2'>
                                    {video?.title}
                                </StyledMetaDataTypography>
                            </Grid>
                            <Grid
                                item
                                width={'80%'}
                                display={'flex'}
                                flexDirection='row'
                                gap={1}
                            >
                                <StyledDescriptionTypography variant='subtitle2'>
                                    {video?.description}
                                </StyledDescriptionTypography>
                                {renderButtonLink(`...${t('general.more')}`)}
                            </Grid>
                        </Grid>
                    ) : (
                        <StyledDetailedOverlay>
                            <Grid
                                container
                                style={{
                                    flexDirection: 'column',
                                    width: '100%'
                                }}
                                gap={2}
                            >
                                {renderCreatorInfo}

                                <Typography
                                    variant='heading3'
                                    color={theme.palette.contentPrimaryInverse}
                                    fontWeight={700}
                                >
                                    {video?.title}
                                </Typography>

                                <Grid item xs={12} md={12}>
                                    <Typography
                                        variant='body2'
                                        sx={{
                                            color: theme.palette
                                                .contentPrimaryInverse
                                        }}
                                    >
                                        {video?.description}
                                    </Typography>
                                </Grid>

                                {video?.hyperlinks?.length ? (
                                    <Grid item xs={12} md={12}>
                                        <Typography
                                            variant='heading5'
                                            color={
                                                theme.palette
                                                    .contentPrimaryInverse
                                            }
                                            fontWeight={700}
                                        >
                                            {t('general.description')}
                                        </Typography>

                                        <Grid container gap={1}>
                                            {video?.hyperlinks?.map(
                                                (hyperlink, index) => (
                                                    <Link
                                                        href={hyperlink}
                                                        underline='hover'
                                                        target='_blank'
                                                        key={index}
                                                        sx={{
                                                            display: 'flex',
                                                            alignSelf: 'center',
                                                            color: theme.palette
                                                                .contentLink,
                                                            width: '80%'
                                                        }}
                                                    >
                                                        <StyledHyperLinkTypography
                                                            variant='body2'
                                                            color={
                                                                theme.palette
                                                                    .contentLink
                                                            }
                                                            isMediumScreen={
                                                                isMediumScreen
                                                            }
                                                        >
                                                            {`${hyperlink},`}
                                                        </StyledHyperLinkTypography>
                                                    </Link>
                                                )
                                            )}
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <></>
                                )}

                                {renderButtonLink(`...${t('general.less')}`)}
                            </Grid>
                        </StyledDetailedOverlay>
                    )
                }
                menuConfig={menuConfig}
            />
        </VideoGrid>
    )
}
