import * as yup from 'yup'

const businessDaysSchema = yup
  .object()
  .shape({
    monday: yup.boolean(),
    tuesday: yup.boolean(),
    wednesday: yup.boolean(),
    thursday: yup.boolean(),
    friday: yup.boolean(),
    saturday: yup.boolean(),
    sunday: yup.boolean(),
  })
  .test(
    'atLeastOneDay',
    'At least one business day should be selected',
    (value) => Object.values(value).some((day) => day),
  )

export const CampusPreferencesSchema = yup.object().shape({
  calendarYearFrom: yup.string().nullable(),
  calendarYearTo: yup
    .string()
    .nullable()
    .when('calendarYearFrom', ([calendarYearFrom], schema) => calendarYearFrom ? schema.required('errors.required') : schema),
  officeFrom: yup
    .string()
    .matches(
      /^([0-1]?[0-9]|2[0-3]):[0-9]|[0-5][0-9]$/,
      'errors.invalidTimeFormat',
    )
    .nullable(),
  officeTo: yup
    .string()
    .when('officeFrom', ([officeFrom], schema) => officeFrom?.length > 0
        ? schema.required('errors.required')
        : schema.nullable())
    .matches(
      /^([0-1]?[0-9]|2[0-3]):[0-9]|[0-5][0-9]$/,
      'errors.invalidTimeFormat',
    ),
  businessDays: businessDaysSchema,
  universityClosure: yup.array().of(
    yup.object().shape({
      title: yup.string(),
      date: yup
        .string()
        .nullable()
        .when('title', ([title], schema) => title?.trim().length > 0
            ? schema.required('errors.thisIsRequired')
            : schema),
    }),
  ),
})
