import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'
export const FILE_UPLOAD = gql`
  ${MetaFragment('FileUploadResponseDto')}
  ${MessagesFragment('FileUploadResponseDto')}
  query fileUpload($input: FileUploadInputDto!) {
    fileUpload(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        signedUrl
        key
      }
    }
  }
`
