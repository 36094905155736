import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { Grid, Typography } from '@mui/material'
import {
    ControlledRadioGroup,
    ControlledTextInput,
    SearchableTags
} from '@components'
import { mapDropdownOptions } from '@dts/client-utils'
import { useCustomTheme } from '@hooks'
import { AddLearnerProps, UserType } from './types'
import { userTypeData } from './data'
import { SearchableLearnersList } from './add-existing-learners'

export const AddLearner: FC<AddLearnerProps> = (props) => {
    const { learnerListId } = props || {}
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const { control } = useFormContext()
    const userType = useWatch({ control, name: 'userType' })

    return (
        <Grid container direction={'column'}>
            <Grid container direction={'row'} mb={2} spacing={2}>
                <Grid item xs={12} md={6} direction={'column'}>
                    <Typography
                        variant='h4'
                        mb={1}
                        fontSize={'15px'}
                        color={theme.palette.contentPrimary}
                    >
                        {t('general.type')}
                    </Typography>
                    <Typography
                        variant='h4'
                        fontWeight={500}
                        color={theme.palette.contentSecondary}
                    >
                        {t('learner.add.userType.message')}
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <ControlledRadioGroup
                        name='userType'
                        options={mapDropdownOptions(userTypeData)}
                        hasColumnDirection
                        gap='0px'
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} md={6} direction={'column'} mb={1}>
                <Typography
                    fontSize={'15px'}
                    variant='h4'
                    color={theme.palette.contentPrimary}
                    mb={1}
                >
                    {userType === UserType.NEW
                        ? t('learner.add.newUserDetails.label')
                        : t('learner.addLearner.label')}
                </Typography>
                <Typography
                    variant='h4'
                    fontWeight={500}
                    color={theme.palette.contentSecondary}
                >
                    {userType === UserType.NEW
                        ? t('learner.add.newUserDetails.message')
                        : t('learner.add.existingLearner.message')}
                </Typography>
            </Grid>
            {userType !== UserType.NEW ? (
                <>
                    <SearchableLearnersList
                        addedIn={t('general.list')}
                        learnerListId={learnerListId}
                    />
                </>
            ) : (
                <>
                    <Grid container direction={'row'} spacing={2} mb={1}>
                        <Grid item xs={12} md={6}>
                            <ControlledTextInput
                                name='firstName'
                                id='firstName'
                                label={t('general.firstName')}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <ControlledTextInput
                                name='lastName'
                                id='lastName'
                                label={t('general.lastName')}
                            />
                        </Grid>
                    </Grid>

                    <Grid container direction={'row'} spacing={2} mb={1}>
                        <Grid item xs={12} md={8}>
                            <ControlledTextInput
                                name='email'
                                id='email'
                                label={t('general.email')}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <ControlledTextInput
                                name='userId'
                                id='userId'
                                label={t('general.learnerId')}
                            />
                        </Grid>
                    </Grid>

                    <SearchableTags name='tags' customDataName='tags' />
                </>
            )}
        </Grid>
    )
}
