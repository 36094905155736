import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import {
    BaseContainer,
    ComponentContainer,
    ParentContainer,
    TypographyContainer,
    SearchableTags,
    ControlledCheckBox
} from '@components'
import { Grid, Typography } from '@mui/material'
import { useGraphQLWrapper, useModalWithGraphQL } from '@hooks'

import { ControlledMultipleCheckboxes } from '@components/CheckboxGroup'
import { GET_SKILLS } from '@dts/graphql/lib'
import { mapDropdownOptions } from '@dts/client-utils'

export const ListConfigurations: React.FC = () => {
    const { t } = useTranslation()
    const { getValues } = useFormContext()

    return (
        <ParentContainer container>
            <Typography variant='h1'>{getValues('name')}</Typography>

            <BaseContainer item>
                <TypographyContainer item md={2.5}>
                    <Typography variant='h3'>
                        {t('learnerList.makeConfigurations')}
                    </Typography>
                    <Typography variant='body2Medium'>
                        {t('learnerList.configurationsCaption')}
                    </Typography>
                </TypographyContainer>

                <ComponentContainer item md={9.5}>
                    <Grid container direction={'column'}>
                        <Typography variant='h3'>
                            {t('learnerList.tagContacts')}
                        </Typography>
                        <Typography variant='body2Medium' mt={1}>
                            {t('learnerList.tagContactsCaption')}
                        </Typography>
                        <Grid item width={'70%'}>
                            <SearchableTags name='tags' customDataName='tags' />
                        </Grid>

                        <Grid
                            container
                            display={'flex'}
                            mt={4}
                            alignItems={'top'}
                        >
                            <Grid item md={0.5} xs={1.5}>
                                <ControlledCheckBox
                                    id='updateExisting'
                                    name='updateExisting'
                                    ariaLabel={t(
                                        'learnerList.updateExistingContact'
                                    )}
                                />
                            </Grid>
                            <Grid item md={11} xs={10} mt={1}>
                                <Grid direction={'column'}>
                                    <Typography variant='h3'>
                                        {t('learnerList.updateExistingContact')}
                                    </Typography>
                                    <Typography variant='body2Medium' mt={1}>
                                        {t(
                                            'learnerList.existingContactCaption'
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ComponentContainer>
            </BaseContainer>
        </ParentContainer>
    )
}
