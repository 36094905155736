import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, CourseFields } from '../../fragments'

export const SAVE_CAMPUS_COURSES = gql`
  ${MessagesFragment('ClientCoursesResponseDto')}
  ${MetaFragment('ClientCoursesResponseDto')}
  ${CourseFields('CourseDto')}
  mutation saveCampusCourses($input: SaveCampusCoursesDto!) {
    saveCampusCourses(input: $input) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...CourseFields
      }
    }
  }
`
