import React from 'react'
import { CircularProgress, Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { useCustomTheme } from '@hooks'

export const CircularProgressWithLabel = ({ value }) => {
    const { theme } = useCustomTheme()
    return (
        <Box position='relative' display='inline-flex'>
            <CircularProgress
                variant='determinate'
                value={100}
                size={32}
                thickness={4}
                sx={{
                    position: 'relative',
                    color: theme?.palette?.borderSecondary // The default background of circular progress bar
                }}
            />

            <CircularProgress
                variant='determinate'
                value={value}
                size={32}
                thickness={4}
                sx={{
                    color: theme?.palette?.backgroundPositive, // The filled background of circular progress bar
                    position: 'absolute',
                    left: 0
                }}
            />

            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position='absolute'
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <Typography
                    variant='caption'
                    component='div'
                    color='textSecondary'
                    fontSize='0.625rem'
                >
                    {`${Math.round(value)}%`}
                </Typography>
            </Box>
        </Box>
    )
}
