import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const REJECT_VIDEO = gql`
  ${MetaFragment('VideoResponseDto')}
  ${MessagesFragment('VideoResponseDto')}
  mutation rejectVideo($input: RejectVideoInputDto!) {
    rejectVideo(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        id
      }
    }
  }
`
