import { gql } from '@apollo/client'
import { MessagesFragment, VerticalFields } from '../../fragments'

export const UPDATE_VERTICAL = gql`
  ${MessagesFragment('VerticalResponseDto')}
  ${VerticalFields('VerticalDto')}
  mutation updateVertical($input: UpdateVerticalInputDto!) {
    updateVertical(input: $input) {
      ...MessagesFragment
      data {
        ...VerticalFields
      }
    }
  }
`
