import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_DASHBOARD_ANALYTICS = gql`
  ${MetaFragment('DashboardAnalyticsResponseDto')}
  ${MessagesFragment('DashboardAnalyticsResponseDto')}
  query getDashboardAnalytics {
    getDashboardAnalytics {
      ...MetaFragment
      ...MessagesFragment
      data {
        videosCount
        contentCreatorsCount
      }
    }
  }
`
