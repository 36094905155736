/* eslint-disable no-redeclare */
import {
  DocumentNode,
  OperationVariables,
  TypedDocumentNode,
  WatchQueryFetchPolicy,
} from '@apollo/client'
import { Path } from 'react-hook-form'
import { SaveMode } from '../constants'
import { UpdateQueryOptions } from '@apollo/client/core'
import { GraphQLErrors } from '@apollo/client/errors'

export type Message = { type: string; messages: Array<string> }

export type FormErrors = {
  field?: Array<string>
  key: string
  type?: string
  isButtonHidden?: boolean
}

export enum FormLevelMessages {
  ERRORS = 'Error',
  WARNINGS = 'Warning',
  INFO = 'Information',
  SUCCESS = 'Success',
}

export type Response<QueryResult, Meta = object> = { data: QueryResult } & {
  message: Message
} & {
  meta: Meta
}

export type ApolloResponse<QueryResult> = {
  [x: string]: Response<QueryResult>
}

export type MutationVariableInput<MutationInput> = {
  input?: MutationInput
  saveAs?: boolean
}

export type MutationSave =
  | ((
      extraData?:
        | {
            name?: string
          }
        | undefined,
      extraFormData?: object | undefined,
      mode?: SaveMode | undefined,
    ) => Promise<
      | {
          data: unknown
          message?: Message
        }
      | undefined
    >)
  | undefined

export type UseGraphQLParams<
  QueryResult,
  MutationInput,
  QueryVariables,
  SubscriptionVariables,
> = {
  id?: string
  query?: DocumentNode
  mutation?: DocumentNode | TypedDocumentNode<MutationInput, OperationVariables>
  queryName?: string
  mutationName?: string
  queryVariables?: QueryVariables
  onError?: (graphQLErrors?: GraphQLErrors) => void
  onMutationSuccess?: (response?: Response<QueryResult>) => void
  onQuerySuccess?: (response: Response<QueryResult>) => void
  onResponseMessages?: (message?: Message, meta?: any) => void
  retrieveOnMount?: boolean
  isCustomMutationInput?: boolean
  shouldHideLoader?: boolean
  shouldHideToast?: boolean
  preventReload?: boolean
  token?: string
  clearCacheOnUnmount?: boolean
  fetchPolicy?: WatchQueryFetchPolicy
  clientId?: string
  suppressToast?: boolean
  subscription?: DocumentNode
  subscriptionVariables?: SubscriptionVariables
  updateQuery?: (
    previousQueryResult: ApolloResponse<QueryResult>,
    options: UpdateQueryOptions<QueryVariables> | object,
  ) => ApolloResponse<QueryResult>
}

export type UseFormWithGraphQLParams<
  FormType,
  QueryResult,
  MutationInput,
  QueryVariables,
> = {
  id?: string
  query?: DocumentNode
  mutation?: DocumentNode | TypedDocumentNode<unknown, OperationVariables>
  mapQueryResultToForm?: (queryResult?: QueryResult) => FormType
  mapFormToMutationInput?: (
    formValues: FormType,
    extraData?: QueryVariables & { name?: string },
  ) => MutationInput
  mapPreservedFormInputs?: (values?: FormType) => object
  queryName?: string
  mutationName?: string
  queryVariables?: QueryVariables
  initialValues?: FormType
  onError?: (graphQLErrors?: GraphQLErrors) => void
  onMutationSuccess?: (response?: Response<QueryResult>) => void
  onQuerySuccess?: (response?: Response<QueryResult>) => void
  formErrorMap?: { [x: string]: Path<FormType> }
  retrieveOnMount?: boolean
  yupResolverSchema?: any
  isCustomMutationInput?: boolean
  suppressToast?: boolean
  mode?: string
  permissions?: Array<string>
}
