import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const TimeoutIcon: React.FC<SvgProps> = ({
    color,
    height = '60',
    width = '66'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentNotice

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 66 60'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M2.3558 25.3333L8.57617 31.5556L14.8002 25.3333M7.64453 30C7.64453 14.536 20.1806 2 35.6445 2C51.1085 2 63.6445 14.536 63.6445 30C63.6445 45.464 51.1085 58 35.6445 58C25.3719 58 16.3913 52.468 11.5194 44.2206M36.578 17.5556L36.578 33.1111L27.2447 39.3333'
                stroke={_color}
                strokeWidth='4'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default TimeoutIcon
