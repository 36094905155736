import React from 'react'
import { setDirectoryQueryOptions } from '@cacheql'

export const useCachedDirectoryOptions = () => {
    const setCachedDirectoryOptions = (id: string, value: object) => {
        const previousData = setDirectoryQueryOptions().find(
            (val) => val?.id === id
        )
        if (previousData) {
            setDirectoryQueryOptions(
                setDirectoryQueryOptions().map((option) =>
                    option?.id === id
                        ? { ...option, options: { ...value } }
                        : option
                )
            )
        } else {
            setDirectoryQueryOptions([
                ...setDirectoryQueryOptions(),
                { id, options: { ...value } }
            ])
        }
    }

    const getCachedDirectoryOptions = (id: string) =>
        setDirectoryQueryOptions()?.find((value) => value?.id === id)

    return [getCachedDirectoryOptions, setCachedDirectoryOptions] as const
}
