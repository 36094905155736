import { Button, Typography, styled, ButtonProps, Grid } from '@mui/material'

export const StyledPageTitle = styled(Typography)`
    font-family: 'Roboto';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    display: flex;
    align-items: center;
`
export const StyledPrimaryButton = styled(Button)<ButtonProps>(({ theme }) => ({
    fontSize: 14,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: theme.palette.backgroundBrandPrimary,
    borderColor: theme.palette.backgroundBrandPrimary,
    color: theme.palette.contentPrimaryInverse,
    fontWeight: 500,
    letterSpacing: '-0.2px',
    margin: '0px 5px',
    '&:hover': {
        backgroundColor: theme.palette.backgroundBrandPrimary,
        borderColor: theme.palette.backgroundBrandPrimary
    }
}))

export const StyledSecondaryButton = styled(Button)<ButtonProps>(
    ({ theme }) => ({
        fontSize: 14,
        padding: '6px 12px',
        border: `1px solid ${theme.palette.backgroundSecondary}`,
        lineHeight: 1.5,
        backgroundColor: theme.palette.backgroundSecondary,
        color: theme.palette.contentPrimary,
        fontWeight: 500,
        letterSpacing: '-0.2px',
        margin: '0px 5px',

        '&:hover': {
            backgroundColor: theme.palette.borderSecondary
        }
    })
)
export const StyledTertiaryButton = styled(Button)<ButtonProps>(
    ({ theme }) => ({
        fontSize: 14,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        borderColor: theme.palette.contentLink,
        backgroundColor: theme.palette.backgroundInfoSubtle,
        color: theme.palette.contentLink,
        fontWeight: 500,
        letterSpacing: '-0.2px',
        margin: '0px 5px',
        variant: 'outlined',
        '&:hover': {
            borderColor: theme.palette.contentLink,
            backgroundColor: theme.palette.BrandPrimaryContainer,
            color: theme.palette.contentLink
        }
    })
)

export const StyledPageHeader = styled(Grid, {
    shouldForwardProp: (prop) => prop !== 'hasMenuConfig'
})(({ theme, hasMenuConfig, isInsideLayout, hasColumnDirection }) => ({
    display: 'flex',
    width: '100%',
    position: 'sticky',
    top: isInsideLayout ? '50px' : '0',
    zIndex: '5',
    backgroundColor: theme.palette.backgroundPrimary,
    borderBottom: `1px solid ${theme?.palette?.borderSecondary}`,
    padding: hasMenuConfig ? theme.spacing(2, 3, 0, 3) : theme.spacing(2, 3),
    justifyContent: 'space-between',
    justifyItems: 'center',
    alignItems: hasColumnDirection ? 'space-between' : 'center',
    [theme.breakpoints.down('md')]: {
        justifyContent: hasColumnDirection ? 'flex-start' : 'space-between',
        justifyItems: 'center',
        flexDirection: hasColumnDirection ? 'column' : 'row',
        gap: hasColumnDirection && '10px'
    }
}))
