import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const RocketDown: React.FC<SvgProps> = ({
    color,
    width = 24,
    height = 24,
    fill = 'none'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 24 24'
            color={color}
            fill={fill}
            fillOpacity='0.1'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M11.4582 7.79136L14.2082 10.5414M11.4582 7.79136C10.1777 8.27834 8.94936 8.89253 7.7915 9.62469M11.4582 7.79136V3.20802C11.4582 3.20802 8.68067 3.71219 7.7915 5.04136C6.8015 6.52636 7.7915 9.62469 7.7915 9.62469M14.2082 10.5414C13.7204 11.8069 13.1062 13.02 12.3748 14.1622C11.3068 15.87 9.81952 17.2761 8.05459 18.2468C6.28966 19.2175 4.30573 19.7205 2.2915 19.708C2.2915 17.2147 3.0065 12.833 7.7915 9.62469M14.2082 10.5414H18.7915C18.7915 10.5414 18.2873 13.3189 16.9582 14.208C15.4732 15.198 12.3748 14.208 12.3748 14.208M18.3332 6.41636C19.7082 5.26136 20.1665 1.83302 20.1665 1.83302C20.1665 1.83302 16.7382 2.29136 15.5832 3.66636C14.9323 4.43636 14.9415 5.61886 15.6657 6.33386C16.022 6.67393 16.4913 6.87044 16.9836 6.88567C17.4759 6.90089 17.9565 6.73376 18.3332 6.41636Z'
                stroke={_color}
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
        </svg>
    )
}
export default RocketDown
