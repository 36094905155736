import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
    useCustomTheme,
    useGraphQLWrapper,
    useInfiniteScroll,
    usePageHeader
} from '@hooks'
import { isMediumScreen } from '@utils'
import { GET_MUTED_ACCOUNTS, SAVE_MUTE_STATUS } from '@dts/graphql'
import { Avatar, Button, Divider, Grid } from '@mui/material'
import { getFullName } from '@dts/client-utils'
import { StyledDirectoryLink } from '@containers'
import { BackButtonType } from '@constants'

export const MutedAccounts = () => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const navigate = useNavigate()
    const { pageHeader } = usePageHeader({
        headerText: t('setting.mutedAccounts'),
        backButtonConfig: {
            showBackButton: isMediumScreen?.(),
            backButtonType: BackButtonType.Hierarchical
        }
    })

    const [mutedAccounts, setMutedAccounts] = useState<{
        [k: number]: boolean
    }>({})

    const { save: saveMutedStatus } = useGraphQLWrapper({
        mutation: SAVE_MUTE_STATUS,
        mutationName: 'saveMuteStatus',
        retrieveOnMount: false,
        isCustomMutationInput: true,
        onMutationSuccess: (response) => {
            setMutedAccounts((prev) => ({
                ...prev,
                [response?.data?.mutedId]: response?.data?.muted
            }))
        }
    })

    const goToCreatorProfile = (id: string) =>
        navigate(`/creator-profile/${id}/all-video`)

    const loadComponent = (mergedData: Array<object>) =>
        mergedData?.map((data) => (
            <Grid
                container
                flexDirection={'column'}
                gap={2}
                p={'15px 20px 5px 20px'}
                key={data?.id}
            >
                <Grid
                    item
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                >
                    <Grid item display={'flex'} gap={3} alignItems={'center'}>
                        <Avatar
                            sx={{
                                border: `1px solid ${theme.palette.borderGrey}`,
                                '&:hover': {
                                    cursor: 'pointer'
                                }
                            }}
                            src={data?.urlSrc}
                            onClick={() => goToCreatorProfile(data?.id)}
                        />

                        <StyledDirectoryLink
                            variant='section'
                            onClick={() => goToCreatorProfile(data?.id)}
                        >
                            {getFullName(data?.firstName, data?.lastName)}
                        </StyledDirectoryLink>
                    </Grid>
                    <Grid item>
                        <Button
                            variant={
                                mutedAccounts?.[data?.id]
                                    ? 'contained'
                                    : 'outlined'
                            }
                            onClick={() => {
                                saveMutedStatus({
                                    input: {
                                        mutedId: data?.id,
                                        muted: !mutedAccounts?.[data?.id]
                                    }
                                })
                            }}
                            sx={{
                                borderRadius: '12px',
                                height: '32px'
                            }}
                        >
                            {mutedAccounts?.[data?.id]
                                ? t('setting.muted')
                                : t('setting.mute')}
                        </Button>
                    </Grid>
                </Grid>
                <Divider />
            </Grid>
        ))

    const { wrappedComponent, data } = useInfiniteScroll({
        query: GET_MUTED_ACCOUNTS,
        queryName: 'getMutedAccounts',
        loadComponent
    })

    useEffect(() => {
        if (data) {
            const mutedStatuses = {}

            data?.forEach((data) => {
                mutedStatuses[data?.id] = true
            })

            setMutedAccounts(mutedStatuses)
        }
    }, [data])

    return (
        <>
            {pageHeader}
            {wrappedComponent}
        </>
    )
}
