import * as yup from 'yup'
import { VideoDetailValidations } from './upload-video'

export const ReviewVideoSchema = yup.object().shape({
  ...VideoDetailValidations,
})

export const RejectVideoSchema = yup.object().shape({
  details: yup
    .string()
    .required('errors.required')
    .max(1000, 'errors.max')
    .min(3, 'errors.min'),
  title: yup.string().required('errors.required').max(200, 'errors.max').trim(),
})
