import React, { FC } from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const CopyIcon: FC<SvgProps> = ({ color, width = '24', height = '25' }) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 24 25'
            fill='none'
        >
            <path
                d='M5 15.792C4.06812 15.792 3.60218 15.792 3.23463 15.6398C2.74458 15.4368 2.35523 15.0474 2.15224 14.5574C2 14.1898 2 13.7239 2 12.792V5.99199C2 4.87189 2 4.31183 2.21799 3.88401C2.40973 3.50769 2.71569 3.20173 3.09202 3.00998C3.51984 2.79199 4.0799 2.79199 5.2 2.79199H12C12.9319 2.79199 13.3978 2.79199 13.7654 2.94423C14.2554 3.14722 14.6448 3.53657 14.8478 4.02663C15 4.39417 15 4.86011 15 5.79199M12.2 22.792H18.8C19.9201 22.792 20.4802 22.792 20.908 22.574C21.2843 22.3823 21.5903 22.0763 21.782 21.7C22 21.2722 22 20.7121 22 19.592V12.992C22 11.8719 22 11.3118 21.782 10.884C21.5903 10.5077 21.2843 10.2017 20.908 10.01C20.4802 9.79199 19.9201 9.79199 18.8 9.79199H12.2C11.0799 9.79199 10.5198 9.79199 10.092 10.01C9.71569 10.2017 9.40973 10.5077 9.21799 10.884C9 11.3118 9 11.8719 9 12.992V19.592C9 20.7121 9 21.2722 9.21799 21.7C9.40973 22.0763 9.71569 22.3823 10.092 22.574C10.5198 22.792 11.0799 22.792 12.2 22.792Z'
                stroke={_color}
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default CopyIcon
