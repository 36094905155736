import { gql } from '@apollo/client'
import {
  MessagesFragment,
  MetaFragment,
  LearnerFields
} from '../../fragments'

export const UPDATE_USER = gql`
  ${MetaFragment('GetUserResponseDto')}
  ${MessagesFragment('GetUserResponseDto')}
  ${LearnerFields('UserDataDto')}
  mutation updateUser($input: UpdateUserInputDto!) {
    updateUser(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...LearnerFields
      }
    }
  }
`
