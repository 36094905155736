import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useFormContext, useWatch } from 'react-hook-form'
import { Grid } from '@mui/material'
import {
    ControlledTextInput,
    ControlledMultiSelectAutoComplete
} from '@components'
import { AdminDetailsForm } from '@containers/grs-admin'
import { useCustomTheme, useGraphQLWrapper } from '@hooks'
import { mapDropdownOptions } from '@dts/client-utils'
import { HelperTextPosition } from '@constants'
import { GET_CAMPUSES } from '@dts/graphql'
import { AdminProfileProps } from './types'

export const ClientAdminProfileForm: React.FC<AdminProfileProps> = ({
    isCurrentUser,
    clientId,
    setIsUserActive
}) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const { id } = useParams()
    const { control } = useFormContext()
    const activeStatus = useWatch({ control, name: 'active' })

    const { currentData: campusData, retrieve: retrieveCampuses } =
        useGraphQLWrapper({
            query: GET_CAMPUSES,
            queryName: 'getCampuses',
            retrieveOnMount: false,
            queryVariables: { clientId }
        })

    useEffect(() => {
        if (!isCurrentUser) {
            retrieveCampuses?.()
        }
    }, [clientId])

    useEffect(() => {
        setIsUserActive?.(activeStatus)
    }, [activeStatus])

    return (
        <Grid
            container
            direction={'column'}
            xs={12}
            md={9}
            px={3}
            pb={3}
            gap={1}
            justifyContent={'space-between'}
            sx={{ backgroundColor: `${theme.palette.backgroundPrimary}` }}
        >
            <Grid item>
                <ControlledTextInput
                    id='title'
                    name='title'
                    label={t('general.title')}
                    disabled={isCurrentUser}
                />
            </Grid>
            <AdminDetailsForm
                isCurrentUser={isCurrentUser}
                clientId={clientId}
            />
            {!isCurrentUser && (
                <>
                    <Grid item>
                        <ControlledMultiSelectAutoComplete
                            id='campuses'
                            name='campuses'
                            label={t('general.campuses')}
                            options={mapDropdownOptions(campusData)}
                        />
                    </Grid>
                    <Grid item>
                        <ControlledTextInput
                            name='notes'
                            id='notes'
                            label={t('general.notes')}
                            multiline
                            rows={3}
                            helperTextConfig={{
                                wordLength: 5000,
                                helperTextPosition: HelperTextPosition.Right
                            }}
                            fullWidth
                        />
                    </Grid>
                </>
            )}
        </Grid>
    )
}
