import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const InsightArrowDownIcon: React.FC<SvgProps> = ({
    color,
    width = 21,
    height = 21
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.errorDangerMedium
    const _fill = color || theme.palette.backgroundPrimary
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 21 21`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g clipPath='url(#clip0_20962_6325)'>
                <path
                    d='M18.9987 14.1663L12.4415 7.60915C12.1115 7.27914 11.9465 7.11413 11.7562 7.05231C11.5888 6.99792 11.4086 6.99792 11.2412 7.05231C11.0509 7.11413 10.8859 7.27914 10.5559 7.60915L8.27484 9.8902C7.94483 10.2202 7.77982 10.3852 7.58955 10.447C7.42218 10.5014 7.24189 10.5014 7.07452 10.447C6.88424 10.3852 6.71924 10.2202 6.38922 9.8902L2.33203 5.83301M18.9987 14.1663H13.1654M18.9987 14.1663V8.33301'
                    stroke={_color}
                    strokeWidth='2.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_20962_6325'>
                    <rect
                        width='20'
                        height='20'
                        fill='white'
                        transform='translate(0.666016)'
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
export default InsightArrowDownIcon
