import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const PlayFilledIcon: React.FC<SvgProps> = ({
    width = 18,
    height = 18,
    color,
    fill
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.backgroundPrimary

    return (
        <svg
            width={width}
            height={height}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 12 14'
        >
            <path
                d='M11 6.13397C11.6667 6.51888 11.6667 7.48112 11 7.86603L2 13.0622C1.33333 13.4471 0.500001 12.966 0.500001 12.1962L0.500002 1.80385C0.500002 1.03405 1.33333 0.552922 2 0.937822L11 6.13397Z'
                fill={_color}
            />
        </svg>
    )
}
export default PlayFilledIcon
