import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const HourGlassIcon: React.FC<SvgProps> = ({
    color,
    width = 24,
    height = 24,
    fill
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary
    const _fill = fill || 'none'

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox={`0 0 24 24`}
            color={color}
            fill={_fill}
            fillOpacity='0.1'
        >
            <path
                d='M9.42857 17.85H14.5714M7.37143 3H16.6286C17.1086 3 17.3486 3 17.532 3.09809C17.6933 3.18438 17.8244 3.32206 17.9066 3.49141C18 3.68393 18 3.93595 18 4.44V6.30706C18 6.74733 18 6.96746 17.9526 7.17462C17.9106 7.35828 17.8414 7.53386 17.7474 7.69491C17.6414 7.87656 17.4931 8.03222 17.1966 8.34353L14.684 10.9818C14.3446 11.3382 14.1749 11.5164 14.1113 11.7219C14.0553 11.9026 14.0553 12.0974 14.1113 12.2781C14.1749 12.4836 14.3446 12.6618 14.684 13.0182L17.1966 15.6565C17.4931 15.9678 17.6414 16.1234 17.7474 16.3051C17.8414 16.4661 17.9106 16.6417 17.9526 16.8254C18 17.0325 18 17.2527 18 17.6929V19.56C18 20.064 18 20.3161 17.9066 20.5086C17.8244 20.6779 17.6933 20.8156 17.532 20.9019C17.3486 21 17.1086 21 16.6286 21H7.37143C6.89138 21 6.65136 21 6.46801 20.9019C6.30673 20.8156 6.1756 20.6779 6.09342 20.5086C6 20.3161 6 20.064 6 19.56V17.6929C6 17.2527 6 17.0325 6.04737 16.8254C6.08936 16.6417 6.15862 16.4661 6.25262 16.3051C6.35863 16.1234 6.50688 15.9678 6.80336 15.6565L9.31597 13.0182C9.65541 12.6618 9.82513 12.4836 9.88872 12.2781C9.94466 12.0974 9.94466 11.9026 9.88872 11.7219C9.82513 11.5164 9.65541 11.3382 9.31597 10.9818L6.80336 8.34353C6.50688 8.03222 6.35863 7.87656 6.25262 7.69491C6.15862 7.53386 6.08936 7.35828 6.04737 7.17462C6 6.96746 6 6.74733 6 6.30706V4.44C6 3.93595 6 3.68393 6.09342 3.49141C6.1756 3.32206 6.30673 3.18438 6.46801 3.09809C6.65136 3 6.89138 3 7.37143 3Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default HourGlassIcon
