import { styled, Grid } from '@mui/material'
import { isMediumScreen } from '@utils'

export const ParentContainer = styled(Grid)(({ theme }) => ({
    width: '100%',
    justifyContent: 'space-between',
    gap: '30px',
    flexDirection: 'column'
}))

export const BaseContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: isMediumScreen() ? 'column' : 'row',
    justifyContent: 'space-between'
}))

export const TypographyContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: isMediumScreen() ? '20px' : '0px'
}))

export const ComponentContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: !isMediumScreen() ? 'center' : 'flex-start',
    gap: '10px'
}))
