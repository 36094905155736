import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { usePageHeader, useRoutes } from '@hooks'
import { Grid } from '@mui/material'

export const CreatorVideosLayout = () => {
    const { t } = useTranslation()
    const { creatorProfileRoutes } = useRoutes()

    const { pageHeader } = usePageHeader({
        headerText: '',
        menuConfig: () => creatorProfileRoutes
    })

    return (
        <>
            {pageHeader}
            <Outlet />
        </>
    )
}
