import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCustomTheme, useGraphQLWrapper } from '@hooks'
import { Grid, Typography } from '@mui/material'
import { ArrowDownCircleIcon, ArrowUpCircleIcon } from '@assets'
import { ControlledMultiSelectAutoComplete, SearchableTags } from '@components'
import { GET_SKILLS, GET_TOPICS } from '@dts/graphql'
import { mapDropdownOptions } from '@dts/client-utils'
import { surveyRecommendationTypes } from '@constants'
import { startCase } from 'lodash'
import { can_access } from '@hoc'

export const SurveyContentConfiguration = ({ permissions }) => {
    const { theme } = useCustomTheme()
    const { t } = useTranslation()

    const { data: skillsData } = useGraphQLWrapper({
        query: GET_SKILLS,
        queryName: 'getSkills',
        retrieveOnMount: true
    })

    const { currentData: topicsData } = useGraphQLWrapper({
        query: GET_TOPICS,
        queryName: 'getTopics',
        retrieveOnMount: true
    })

    const panelOptions = [
        {
            header: surveyRecommendationTypes[0].name,
            skillComponentName:
                'surveyRecommendations.recommendations.[0].skills',

            tagsComponentName: 'surveyRecommendations.recommendations.[0].tags',
            iconBackgroundColor: theme.palette.dullGreen,
            icon: ArrowUpCircleIcon
        },
        {
            header: surveyRecommendationTypes[1].name,
            skillComponentName:
                'surveyRecommendations.recommendations.[1].skills',

            tagsComponentName: 'surveyRecommendations.recommendations.[1].tags',
            iconBackgroundColor: theme.palette.brandRedLight,
            icon: ArrowDownCircleIcon
        }
    ]

    return (
        <Grid container gap={1}>
            {panelOptions?.map((panelOption) => (
                <Grid
                    item
                    container
                    key={panelOption?.header}
                    flexDirection={'column'}
                    sm={12}
                    md={5.9}
                    p={2}
                    sx={{
                        backgroundColor: theme.palette.backgroundSecondary,
                        borderRadius: '12px'
                    }}
                >
                    <Grid item display={'flex'} gap={1}>
                        <Grid
                            item
                            p={1}
                            borderRadius={'8px'}
                            sx={{
                                backgroundColor:
                                    panelOption?.iconBackgroundColor,
                                height: '85%'
                            }}
                        >
                            {panelOption?.icon && <panelOption.icon />}
                        </Grid>
                        <Grid item container flexDirection={'column'}>
                            <Typography variant='h3'>
                                {startCase(panelOption?.header)}
                            </Typography>
                            <Typography variant='smallText'>
                                {t('survey.contentConfiguration.description')}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <ControlledMultiSelectAutoComplete
                            id={panelOption?.skillComponentName}
                            name={panelOption?.skillComponentName}
                            label={t('general.skills')}
                            placeholder={t('general.skills')}
                            options={mapDropdownOptions(skillsData)}
                            sx={{ borderRadius: '12px' }}
                            disabled={
                                permissions?.length > 0 &&
                                !can_access(permissions)
                            }
                        />
                    </Grid>

                    <Grid item>
                        <SearchableTags
                            name={panelOption?.tagsComponentName}
                            customDataName={panelOption?.tagsComponentName}
                            sx={{ borderRadius: '12px' }}
                            disabled={
                                permissions?.length > 0 &&
                                !can_access(permissions)
                            }
                        />
                    </Grid>
                </Grid>
            ))}
        </Grid>
    )
}
