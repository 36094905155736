import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, TrainingFields } from '../../fragments'

export const GET_TRAININGS = gql`
  ${MessagesFragment('TrainingsResponseDto')}
  ${MetaFragment('TrainingsResponseDto')}
  ${TrainingFields('TrainingDto')}
  query getTrainings(
    $options: TrainingPaginationDto!
    $args: GetTrainingsArgumentsDto!
  ) {
    getTrainings(options: $options, args: $args) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...TrainingFields
      }
    }
  }
`
