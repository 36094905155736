import { gql } from '@apollo/client'
import { MessagesFragment, ArticleFields } from '../../fragments'

export const SAVE_ARTICLE = gql`
  ${MessagesFragment('ArticleResponseDto')}
  ${ArticleFields('ArticleDataDto')}
  mutation saveArticle($input: SaveArticleInputDto!) {
    saveArticle(input: $input) {
      ...MessagesFragment
      data {
        ...ArticleFields
      }
    }
  }
`
