import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useEffect,
    useState
} from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useGraphQLWrapper } from '@hooks'
import {
    GET_PULSE_SURVEY_QUESTIONS,
    GET_SURVEY_QUESTIONS,
    ON_SURVEY_AVAILABLE
} from '@dts/graphql'
import {
    QuestionFeedback,
    SurveyForm,
    SurveyQuestionDetails,
    SurveyResult
} from '@containers'
import { SurveyTypeEnum } from '@dts/constants'
import { useSubscription } from '@apollo/client'
import { SurveyFormSchema } from '@dts/client-utils'
import { valueExists } from '@utils'
import { cachedSurveyInfo, surveyStepsInfo } from '@cacheql'
import { cloneDeep, isEmpty, pullAt } from 'lodash'
import { ObjectBaseFields } from '@types'
import { LearnerSurveyMode } from '@constants'

export type CurrentSurveyStep = {
    componentProps: () => {
        surveyQuestion: object
        questionId: string
        surveyType: ObjectBaseFields
        currentTotalQuestions: number
        topicId?: string
    }
    component: (compProps: {
        surveyQuestion: object
        questionId: string
        surveyType: ObjectBaseFields
        currentTotalQuestions: number
        topicId?: string
    }) => JSX.Element
    isMainStep?: boolean
    isDetailsStep?: boolean
    // isFeedbackStep?: boolean  (To-do: Will be removed after testing)
    isSummaryStep?: boolean
    questionId: string
    schema: unknown
    initialValues?: object
}
export type SurveyContextData = {
    currentSurveySteps: Array<CurrentSurveyStep>
    setCurrentSurveySteps: Dispatch<SetStateAction<Array<CurrentSurveyStep>>>
    openSurvey: (
        trainingId: string,
        courseId: string,
        surveyTypeId: string,
        cacheMode?: LearnerSurveyMode
    ) => void
    surveyForLearnerLoading?: boolean
    isSurveyOpened: boolean
    setIsSurveyOpened: Dispatch<SetStateAction<boolean>>
    currentSurveyType: string | null
    setCurrentSurveyType: Dispatch<
        SetStateAction<
            | SurveyTypeEnum.PostTest
            | SurveyTypeEnum.PreTest
            | SurveyTypeEnum.Pulse
            | null
        >
    >
    resetToInitialValues: VoidFunction
    checkForSurveys: VoidFunction
    onVideoEnded: VoidFunction
}

export type CachedSurveyStepInfo = {
    hideStep: boolean
    questionId: string
    isMainStep?: boolean
    isDetailsStep?: boolean
    // isFeedbackStep?: boolean (To-do: Will be removed after testing)
    isSummaryStep?: boolean
}

export const SurveyContext = createContext<SurveyContextData>({
    currentSurveySteps: [],
    setCurrentSurveySteps: () => undefined,
    openSurvey: () => undefined,
    surveyForLearnerLoading: false,
    isSurveyOpened: false,
    setIsSurveyOpened: () => undefined,
    currentSurveyType: null,
    setCurrentSurveyType: () => undefined,
    resetToInitialValues: () => undefined,
    checkForSurveys: () => undefined,
    onVideoEnded: () => undefined
})

export const SurveyProvider = ({ children }) => {
    const [searchParams] = useSearchParams()
    const { topicId } = useParams()
    const trainingId = searchParams.get('trainingId') || null
    const courseId = searchParams.get('courseId') || null

    const { data: onSurveyAvailableData, loading: onSurveyAvailableLoading } =
        useSubscription(ON_SURVEY_AVAILABLE)

    const { currentData: pulseSurveyData, retrieve: getPulseSurveyQuestions } =
        useGraphQLWrapper({
            query: GET_PULSE_SURVEY_QUESTIONS,
            queryName: 'getPulseSurveyQuestions',
            retrieveOnMount: false
        })

    const {
        currentData: surveyForLearnerData,
        retrieve: getSurveyForLearner,
        queryLoading: surveyForLearnerLoading
    } = useGraphQLWrapper({
        query: GET_SURVEY_QUESTIONS,
        queryName: 'getSurveyQuestions',
        retrieveOnMount: false
    })

    const [currentSurveySteps, setCurrentSurveySteps] = useState([])
    const [currentSurveyType, setCurrentSurveyType] = useState(null)
    const [isSurveyOpened, setIsSurveyOpened] = useState(false)
    const [surveyMode, setSurveyMode] = useState<{
        surveyTypeId: SurveyTypeEnum
        cacheMode: LearnerSurveyMode
    } | null>(null)

    const resetToInitialValues = () => {
        setIsSurveyOpened?.(false)
        setCurrentSurveySteps([])
        surveyStepsInfo([])
        setCurrentSurveyType(null)
    }

    const checkIdMatch = (survey) =>
        survey?.courseId === courseId && survey?.trainingId === trainingId

    const checkForSurveys = () => {
        const prevCachedSurveyInfo = cachedSurveyInfo()
        const prevCachedSurveys = prevCachedSurveyInfo?.cachedSurveys
        const alreadyAnsweredQuestionIds =
            prevCachedSurveyInfo?.answeredQuestionIds

        if (!isEmpty(prevCachedSurveys)) {
            let elementIndex = -1
            let postTestFound = false

            prevCachedSurveys?.forEach((survey, index) => {
                if (
                    survey?.surveyTypeId === SurveyTypeEnum.PostTest &&
                    checkIdMatch(survey)
                ) {
                    elementIndex = index
                    postTestFound = true
                }
            })

            if (!postTestFound) {
                const pulseSurveyIndex = prevCachedSurveys?.findIndex(
                    (survey) =>
                        survey?.surveyTypeId === SurveyTypeEnum.Pulse &&
                        checkIdMatch(survey)
                )
                elementIndex = pulseSurveyIndex ?? elementIndex
            }

            if (elementIndex >= 0 && elementIndex < prevCachedSurveys?.length) {
                const pulledElements = pullAt(prevCachedSurveys, [elementIndex])
                const pulledData = pulledElements?.[0]

                const pulledAllSteps = pulledData?.allSteps
                    ?.map((step) => {
                        if (
                            !alreadyAnsweredQuestionIds?.includes(
                                step?.questionId
                            )
                        ) {
                            return step
                        }
                    })
                    ?.filter(Boolean)

                const pulledCacheData = pulledData?.surveyCacheData
                    ?.map((step) => {
                        if (
                            !alreadyAnsweredQuestionIds?.includes(
                                step?.questionId
                            )
                        ) {
                            return step
                        }
                    })
                    ?.filter(Boolean)

                setCurrentSurveySteps(pulledAllSteps)
                setCurrentSurveyType(pulledData?.surveyTypeId)
                surveyStepsInfo(pulledCacheData)

                postTestFound
                    ? cachedSurveyInfo({
                          ...prevCachedSurveyInfo,
                          cachedSurveys: []
                      })
                    : cachedSurveyInfo({
                          ...prevCachedSurveyInfo,
                          cachedSurveys: prevCachedSurveys
                      })
            }
        }
    }

    const onVideoEnded = () => {
        checkForSurveys()
    }

    const getSurveyBySubscriptionResult = () => {
        if (
            onSurveyAvailableData?.onSurveyAvailable?.surveyTypeId ===
            SurveyTypeEnum.PostTest
        ) {
            getSurveyForLearner({
                variables: {
                    args: {
                        trainingId,
                        courseId,
                        surveyTypeId: SurveyTypeEnum.PostTest
                    }
                }
            })
        } else if (
            onSurveyAvailableData?.onSurveyAvailable?.surveyTypeId ===
            SurveyTypeEnum.Pulse
        ) {
            getPulseSurveyQuestions({
                variables: {
                    args: {
                        courseId,
                        pulseQuestionIds:
                            onSurveyAvailableData?.onSurveyAvailable
                                ?.surveyQuestionIds
                    }
                }
            })
        }
    }

    useEffect(() => {
        if (!onSurveyAvailableLoading && onSurveyAvailableData)
            getSurveyBySubscriptionResult()
    }, [onSurveyAvailableData])

    const getCommonProps = (
        surveyQuestion: object,
        surveyType: ObjectBaseFields,
        surveyQuestions: Array<object>
    ) => ({
        surveyQuestion,
        questionId: surveyQuestion?.id,
        surveyType: surveyType,
        currentTotalQuestions: surveyQuestions?.length,
        topicId,
        courseId
    })

    const openSurvey = (
        trainingId: string,
        courseId: string,
        surveyTypeId: string,
        cacheMode?: LearnerSurveyMode
    ) => {
        getSurveyForLearner({
            variables: { args: { trainingId, courseId, surveyTypeId } }
        })

        setSurveyMode({ surveyTypeId, cacheMode })
    }

    const updateStepsAndCache = (survey, surveyQuestions) => {
        const allSteps = []
        const surveyCacheData = []
        surveyQuestions?.map((surveyQuestion, index) => {
            const cacheData = !surveyQuestion?.isAnswered
                ? [
                      {
                          isMainStep: true,
                          questionId: surveyQuestion?.id
                      },
                      {
                          isDetailsStep: true,
                          questionId: surveyQuestion?.id
                      }
                      // To-do: Will be removed after testing
                      //   {
                      //       isFeedbackStep: true,
                      //       questionId: surveyQuestion?.id,
                      //       hideStep: !valueExists(surveyQuestion?.summaryTitle)
                      //   }
                  ]
                : []

            const questionSteps = !surveyQuestion?.isAnswered
                ? [
                      {
                          componentProps: () =>
                              getCommonProps(
                                  surveyQuestion,
                                  survey?.surveyType,
                                  surveyQuestions
                              ),
                          component: (compProps) => (
                              <SurveyForm {...compProps} />
                          ),
                          isMainStep: true,
                          questionId: surveyQuestion?.id,
                          schema: SurveyFormSchema,
                          initialValues: {
                              singleChoiceAnswer: null,
                              multiChoiceAnswers: {},
                              isMainStep: true
                          }
                      },

                      {
                          componentProps: () =>
                              getCommonProps(
                                  surveyQuestion,
                                  survey?.surveyType,
                                  surveyQuestions
                              ),
                          component: (compProps) => (
                              <SurveyQuestionDetails {...compProps} />
                          ),
                          questionId: surveyQuestion?.id,
                          isDetailsStep: true
                      }
                      // To-do: Will be removed after testing
                      //   {
                      //       componentProps: () =>
                      //           getCommonProps(
                      //               surveyQuestion,
                      //               survey?.surveyType,
                      //               surveyQuestions
                      //           ),
                      //       component: (compProps) => (
                      //           <QuestionFeedback {...compProps} />
                      //       ),
                      //       questionId: surveyQuestion?.id,
                      //       isFeedbackStep: true
                      //   }
                  ]?.filter(Boolean)
                : []
            cacheData?.map((data) => surveyCacheData.push(data))
            questionSteps?.map((step) => allSteps.push(step))
        })

        if (
            survey?.surveyType?.id !== SurveyTypeEnum.Pulse &&
            !isEmpty(surveyQuestions)
        ) {
            allSteps.push({
                componentProps: () => ({
                    ...survey,
                    courseId
                }),
                isSummaryStep: true,
                component: (compProps) => <SurveyResult {...compProps} />
            })
            surveyCacheData.push({
                isSummaryStep: true,
                surveyId: survey?.id,
                hideStep:
                    !valueExists(survey?.summaryTitle) &&
                    !valueExists(survey?.summaryDescription) &&
                    !valueExists(survey?.summaryResponseIcon) &&
                    survey?.summaryEnabled === false
            })
        }

        if (survey && !isEmpty(allSteps) && !isEmpty(surveyCacheData)) {
            const surveyType = survey?.surveyType?.id
            if (
                surveyType === SurveyTypeEnum.PreTest ||
                (surveyMode?.surveyTypeId === SurveyTypeEnum.PostTest &&
                    surveyMode?.cacheMode === LearnerSurveyMode.NoCache)
            ) {
                surveyStepsInfo(surveyCacheData)
                setCurrentSurveySteps(allSteps)
                setCurrentSurveyType(survey?.surveyType?.id)
            } else {
                const prevCachedSurveyInfo = cachedSurveyInfo()
                const prevCachedSurveys = prevCachedSurveyInfo?.cachedSurveys

                prevCachedSurveys?.push({
                    surveyId: survey?.id,
                    surveyTypeId: survey?.surveyType?.id,
                    topicId,
                    courseId,
                    trainingId,
                    surveyCacheData,
                    allSteps
                })

                cachedSurveyInfo({
                    ...prevCachedSurveyInfo,
                    cachedSurveys: prevCachedSurveys
                })
            }
        }
    }

    const mapPreAndPostTestDataToSteps = (surveyForLearnerData) => {
        if (surveyForLearnerData) {
            const surveysCached = cloneDeep(cachedSurveyInfo()?.cachedSurveys)

            const surveysCachedIds = surveysCached
                ?.map((survey) => survey?.surveyId)
                ?.filter(Boolean)

            if (!surveysCachedIds?.includes(surveyForLearnerData?.id)) {
                updateStepsAndCache(
                    surveyForLearnerData,
                    surveyForLearnerData?.surveyQuestions
                )
            }
        }
    }

    const mapPulseTestDataToSteps = (pulseSurveyData) => {
        if (pulseSurveyData) {
            const survey = {
                surveyType: {
                    id: SurveyTypeEnum.Pulse
                },
                surveyQuestions: pulseSurveyData
            }

            const filteredData = []
            const surveysCached = cloneDeep(cachedSurveyInfo()?.cachedSurveys)

            pulseSurveyData?.forEach((pulseQuestion) => {
                surveysCached?.forEach((survey) => {
                    if (survey?.surveyTypeId === SurveyTypeEnum.Pulse) {
                        survey?.allSteps?.forEach((step) => {
                            if (
                                step?.questionId !== pulseQuestion?.id &&
                                !pulseQuestion?.isAnswered
                            ) {
                                filteredData.push(pulseQuestion)
                            }
                        })
                    }
                })
            })

            if (!isEmpty(filteredData) || !isEmpty(pulseSurveyData)) {
                updateStepsAndCache(
                    survey,
                    isEmpty(surveysCached) ? pulseSurveyData : filteredData
                )
            }
        }
    }

    useEffect(() => {
        mapPreAndPostTestDataToSteps(surveyForLearnerData)
    }, [surveyForLearnerData])

    useEffect(() => {
        mapPulseTestDataToSteps(pulseSurveyData)
    }, [pulseSurveyData])

    return (
        <SurveyContext.Provider
            value={{
                currentSurveySteps,
                setCurrentSurveySteps,
                openSurvey,
                surveyForLearnerLoading,
                isSurveyOpened,
                setIsSurveyOpened,
                currentSurveyType,
                setCurrentSurveyType,
                resetToInitialValues,
                onVideoEnded
            }}
        >
            {children}
        </SurveyContext.Provider>
    )
}
