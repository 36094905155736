export enum FileTypeEnum {
  USER_AVATAR = 'USER_AVATAR',
  THUMBNAIL = 'THUMBNAIL',
  REPORTED_ISSUE = 'REPORTED_ISSUE',
  ARTICLE = 'ARTICLE',
  CLIENT = 'CLIENT',
}

export enum UploadedFileType {
  CSV = 'text/csv',
  TSV = 'text/tab-separated-values',
}
