import { Grid, styled } from '@mui/material'

export const BrowseGridContainer = styled(Grid)(({ theme }) => ({
    minWidth: '300px',
    border: `1px solid ${theme.palette.backgroundTertiary}`,
    backgroundColor: theme.palette.backgroundSecondary,
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center'
}))

export const DragAndDropContainer = styled(Grid)(({ theme }) => ({
    minWidth: '300px',
    backgroundColor: theme.palette.BrandPrimaryContainer,
    border: `1px dotted ${theme.palette.navyBlue}`,
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center'
}))

export const DragAndDropInnerGrid = styled(Grid)({
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center'
})
