import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const UploadFileIcon: React.FC<SvgProps> = ({
    color,
    width = '24',
    height = '25'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.backgroundBrandPrimary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 20 21'
            fill='none'
        >
            <path
                d='M17.5 13.292V14.292C17.5 15.6921 17.5 16.3922 17.2275 16.927C16.9878 17.3974 16.6054 17.7798 16.135 18.0195C15.6002 18.292 14.9001 18.292 13.5 18.292H6.5C5.09987 18.292 4.3998 18.292 3.86502 18.0195C3.39462 17.7798 3.01217 17.3974 2.77248 16.927C2.5 16.3922 2.5 15.6921 2.5 14.292V13.292M14.1667 7.45866L10 3.29199M10 3.29199L5.83333 7.45866M10 3.29199V13.292'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default UploadFileIcon
