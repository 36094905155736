import React, { useState } from 'react'
import {
    CardMedia,
    Grid,
    Typography,
    useMediaQuery,
    Tooltip
} from '@mui/material'
import { DotMenuIcon, PictureFrameImg, ReportThumbnail } from '@assets'

import { t } from 'i18next'
import { useCustomTheme } from '@hooks'
import { KebabMenu } from '@components'
import { onKeyDown } from '@utils'

export type ArticleInteraction = {
    saved?: boolean
    isHelpful?: boolean
}

export type Article = {
    id: string
    title: string
    description: string
    isFeatured?: boolean
    tags?: Array<string>
    coverImageSrc?: string
    articleInteraction?: ArticleInteraction
    duration?: number
}

export type LearningCenterCardProps = {
    article?: Article
    onClick?: VoidFunction
}

export const ReportCard: React.FC = ({
    title,
    thumbnail,
    cardActions,
    onClickAction
}) => {
    const { theme } = useCustomTheme()
    const isSmallestScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('sm')
    )

    return (
        <Grid
            item
            display={'flex'}
            flexDirection={'column'}
            md={3}
            tabIndex={0}
            onKeyDown={(e) => onKeyDown(e, onClickAction, false)}
            onClick={onClickAction}
            xs={5}
            sx={{
                border: `1px solid ${theme?.palette?.borderSecondary}`,
                borderRadius: '12px',
                cursor: onClickAction && 'pointer'
            }}
            m={1}
        >
            <Grid
                item
                display={'flex'}
                sx={{
                    position: 'relative',
                    height: '100%'
                }}
            >
                <CardMedia
                    component='img'
                    height={'170px'}
                    image={thumbnail || ReportThumbnail} // Use default image if none is provided
                    alt={t('report.brokenImage')}
                    aria-label={title}
                    onError={(e) => {
                        e.target.onerror = null // Prevent infinite loop if the default image is also broken
                        e.target.src = ReportThumbnail // Set the default image
                    }}
                    sx={{
                        minWidth: 'auto',
                        borderTopLeftRadius: '12px',
                        borderTopRightRadius: '12px',
                        objectFit: 'fill'
                    }}
                />
            </Grid>
            <Grid item display={'flex'} p={2} alignItems={'center'}>
                <Grid item md={10} xs={10} onClick={onClickAction}>
                    <Tooltip
                        title={title}
                        sx={{
                            cursor: 'pointer'
                        }}
                    >
                        <Typography
                            variant='body2'
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {title}
                        </Typography>
                    </Tooltip>
                </Grid>
                {cardActions && (
                    <Grid
                        item
                        md={2}
                        xs={2}
                        display={'flex'}
                        justifyContent={'center'}
                        onClick={(e) => {
                            onClickAction && e.stopPropagation()
                        }}
                    >
                        <KebabMenu dropDownConfig={cardActions} />
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}
