import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAuth, useCustomTheme, useDirectory, useLocalStorage } from '@hooks'
import { Avatar, Grid } from '@mui/material'
import { formatDate } from '@dts/client-utils'
import { GET_PORTAL_CLIENTS } from '@dts/graphql/lib'
import { StyledLink, TextWithTooltip } from '@components'
import { BuildingsIcon } from '@assets'
import { onPortalClient } from './portal-into-client-form'

export const PortalClient = () => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const navigate = useNavigate()
    const { fetchUserData } = useAuth()
    const [storedLoggedInUser] = useLocalStorage('user-data')

    const { directory } = useDirectory({
        directoryConfig: {
            query: GET_PORTAL_CLIENTS,
            queryName: 'getPortalClients',
            queryVariables: {},
            config: {
                columns: [
                    {
                        field: 'avatarSrc',
                        headerName: '',
                        flex: 0,
                        renderCell: () => (
                            <StyledLink to={''}>
                                <Grid sx={{ p: 1 }}>
                                    <Avatar
                                        sx={{
                                            backgroundColor:
                                                theme.palette
                                                    .backgroundSecondary
                                        }}
                                    >
                                        <BuildingsIcon />
                                    </Avatar>
                                </Grid>
                            </StyledLink>
                        )
                    },
                    {
                        field: 'title',
                        headerName: t('general.name'),
                        minWidth: 500,
                        renderCell: (params) => (
                            <Grid item spacing={2}>
                                <TextWithTooltip
                                    renderer={params?.row?.title}
                                    mt={0.5}
                                    color={theme.palette.contentLink}
                                    onClick={() =>
                                        onPortalClient(
                                            params?.row?.id,
                                            fetchUserData,
                                            storedLoggedInUser,
                                            () => navigate('/dashboard')
                                        )
                                    }
                                    isDirectoryLink={true}
                                />
                            </Grid>
                        )
                    },
                    {
                        minWidth: 100,
                        field: 'createdAt',
                        headerName: t('general.dateLogin'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={
                                    params?.row?.loggenInAt
                                        ? formatDate(params?.row?.loggenInAt)
                                        : '--'
                                }
                                tabIndex={params.tabIndex}
                            />
                        )
                    }
                ],
                hideFooter: false,
                pagination: false
            },
            enableSearch: false,
            toolbarConfig: {
                header: t('general.clients'),
                caption: t('portal.client.header.caption')
            }
        }
    })

    return directory
}
