import { gql } from '@apollo/client'

export const CAPTIONS_READY_SUBSCRIPTION = gql`
  subscription captionsReady {
    captionsReady {
      userId
      videoId
      fileName
    }
  }
`
export const GET_VIDEO_CAPTION = gql`
  query GetVideoCaption($url: String!, $videoId: String!) {
    getVideoCaption(url: $url, videoId: $videoId) {
      data {
        captionFileSrc
      }
    }
  }
`
