import * as yup from 'yup'

export const ReportSchema = yup.object().shape({
  filters: yup.array().of(
    yup.object().shape({
      selectedData: yup.array().when(['isAdded'], ([isAdded], schema) =>
        isAdded
          ? yup
              .array()
              .min(1, 'errors.selectFilterValue') // Ensure at least one item is present
              .required('errors.selectFilterValue')
              .test(
                'is-not-empty',
                'errors.selectFilterValue',
                function (value) {
                  return value && value.length > 0
                },
              )
          : yup.array().nullable(),
      ),
    }),
  ),
  metrics: yup
    .array()
    .of(
      yup.object().shape({
        isAdded: yup.boolean().required(),
      }),
    )
    .test('at-least-one-added', 'Select a Metric', function (metrics) {
      return metrics && metrics.some((metric) => metric.isAdded)
    }),
})

export const ReportTitleSchema = yup.object().shape({
  title: yup.string().trim().required('errors.required').max(200, 'errors.max'),
})

export const ReportSaveAsNewSchema = yup.object().shape({
  title: yup.string().trim().required('errors.required').max(200, 'errors.max'),
  reportLibraryId: yup.string().required('errors.required'),
})
