import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const BoldTickIcon: React.FC<SvgProps> = ({
    color,
    height = '18',
    width = '18'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 22 22'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M18.3332 5.5L8.24984 15.5833L3.6665 11'
                stroke={_color}
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default BoldTickIcon
