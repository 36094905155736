import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGraphQLWrapper, useAuth, useCustomTheme, useLoader } from '@hooks'
import { FilterLayout, Loader } from '@components'
import { AllIcon, CategoryIcon } from '@assets'
import { Button, Grid, Typography } from '@mui/material'
import { cloneDeep, isEmpty, startCase } from 'lodash'
import { getAccessRoles } from '@utils'
import { GET_KEYWORD_SEARCH_DATA } from '@dts/graphql/lib'
import { AllCategoryId } from '@constants'
import { StyledTypographyBio } from '@components/UserProfile/styles'
import { getRoutesByRole, getUserRouteByRole } from './configurations'
import { SearchResultItem } from './search-result-item'

export const GlobalSearchPage = () => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const { loading } = useLoader()
    const navigate = useNavigate()
    const {
        userInfo: { roles }
    } = useAuth()
    const accessedRoles = getAccessRoles(roles)

    const location = useLocation()

    const [searchCategories, setSearchCategories] = useState([])
    const [searchCategory, setSearchCategory] = useState('')
    const [searchData, setSearchData] = useState({})

    const [tabComponent, setTabComponent] = useState(<></>)
    const [searchValue, setSearchValue] = useState('')
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const paramsObj = {}
        for (const [key, value] of searchParams.entries()) {
            paramsObj[key] = value
        }

        if (paramsObj?.key) setSearchValue(paramsObj?.key)
    }, [location.search])

    const { queryLoading, currentData: globalSearchData } = useGraphQLWrapper({
        query: GET_KEYWORD_SEARCH_DATA,
        queryName: 'getKeywordSearchData',
        queryVariables: {
            args: {
                search: searchValue,
                category: searchCategory === AllCategoryId ? '' : searchCategory
            }
        }
    })

    useEffect(() => {
        if (!isEmpty(searchCategory) && searchCategory !== AllCategoryId) {
            setTabComponent(
                loading ? (
                    <Loader />
                ) : (
                    <>
                        {searchData?.suggestion &&
                            !isEmpty(searchData?.suggestion) &&
                            getSuggestion(searchData?.suggestion)}
                        {SearchCategoryResult(
                            {
                                ...searchCategories.find(
                                    (category) =>
                                        category.category === searchCategory
                                ),
                                result: searchData?.data?.[0]?.result
                            },
                            searchCategory
                        )}
                    </>
                )
            )
        } else {
            const allCategories = []

            searchData?.data?.map((category) =>
                allCategories.push(SearchCategoryResult(category, ''))
            )
            setTabComponent(
                loading ? (
                    <Loader />
                ) : (
                    <>
                        {searchData?.suggestion &&
                            !isEmpty(searchData?.suggestion) &&
                            getSuggestion(searchData?.suggestion)}
                        {allCategories.length ? (
                            allCategories
                        ) : (
                            <Typography variant='body3' p={3}>
                                {t('globalSearch.noRecords')}
                            </Typography>
                        )}
                    </>
                )
            )
        }
    }, [searchCategory, searchData, queryLoading])

    const getSearchCategories = (searchData) => {
        const allTabsData: Array<object> = [
            {
                id: AllCategoryId,
                category: t('general.all'),
                Icon: AllIcon
            },
            ...(searchData?.data || [])
        ]
        return allTabsData?.map?.((category) => ({
            ...category,
            id: category?.id ? category?.id : category?.category,
            label: startCase(category?.category),
            Icon: category?.Icon ?? CategoryIcon,
            onClick: () => {
                if (category?.id === AllCategoryId) {
                    setSearchCategory('1')
                } else {
                    setSearchCategory(category?.category)
                }
            }
        }))
    }

    const mapRoutesWithCategory = () => {
        const data = cloneDeep(globalSearchData)
        const categoryRoutesByRole = getRoutesByRole(accessedRoles)
        data?.data?.forEach((categoryResult) => {
            const categoryRoute = categoryRoutesByRole?.find(
                (routeItem) => routeItem?.category === categoryResult.category
            )
            categoryResult.Icon = categoryRoute?.categoryIcon
            categoryResult.hasImage = categoryRoute?.hasImage
            categoryResult?.result.forEach((item) => {
                if (categoryRoute?.category !== 'user') {
                    if (categoryRoute && item && !categoryRoute?.isModalRoute) {
                        item.route = categoryRoute.route.replace(
                            '{id}',
                            item.id
                        )
                        item.routeState = item.id
                    } else {
                        item.route = categoryRoute?.route
                        item.routeState = item.id
                    }
                } else {
                    item.route = getUserRouteByRole(
                        item?.roles?.[0]?.roleId,
                        item?.id
                    )
                }
            })
        })

        setSearchData(data)
    }
    const SearchCategoryResult = (category, selectedCategory) => (
        <Grid
            container
            xs={11}
            md={9}
            flexDirection={'column'}
            m={2}
            p={2}
            pb={1}
        >
            <Grid item xs={12}>
                <Typography variant='h3'>
                    {startCase(category?.category)}
                </Typography>
            </Grid>

            {isEmpty(selectedCategory) || selectedCategory === AllCategoryId
                ? category?.result
                      ?.slice(0, 3)
                      ?.map?.((dataItem, index) => (
                          <SearchResultItem
                              key={index}
                              dataItem={dataItem}
                              hasImage={category?.hasImage}
                          />
                      ))
                : category?.result?.map?.((dataItem, index) => (
                      <SearchResultItem
                          key={index}
                          dataItem={dataItem}
                          hasImage={category?.hasImage}
                      />
                  ))}
            {selectedCategory === AllCategoryId || isEmpty(selectedCategory) ? (
                <Grid item mt={1} justifyContent={'center'} display={'flex'}>
                    <Grid item xs={12} md={12}>
                        <Button
                            fullWidth
                            variant='outlined'
                            size='large'
                            color='primary'
                            onClick={() => {
                                setSearchCategory(category?.category)
                            }}
                        >
                            <StyledTypographyBio>
                                {t('general.viewAll')}
                            </StyledTypographyBio>
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <></>
            )}
        </Grid>
    )
    const getSuggestion = (suggestion) => (
        <Grid
            xs={11}
            md={9}
            m={2}
            p={2}
            pb={1}
            display={'flex'}
            alignItems={'center'}
            onClick={() => {
                navigate(`/global?key=${suggestion}`)
            }}
        >
            <Typography
                variant='h4'
                color={theme.palette.errorDangerMedium}
                mr={1}
            >
                {t('general.didYouMean')}
            </Typography>
            <Typography
                color={theme.palette.royalBlue}
                variant='h4'
                tabIndex={0}
                fontWeight={'600px'}
                fontStyle={'italic'}
                sx={{
                    '&:hover': {
                        cursor: 'pointer',
                        textDecoration: 'underline'
                    }
                }}
            >
                {startCase(suggestion)}
            </Typography>
        </Grid>
    )

    useEffect(() => {
        if (globalSearchData) {
            mapRoutesWithCategory()
        }
    }, [globalSearchData])

    useEffect(() => {
        if (searchData) {
            isEmpty(searchCategory) &&
                !queryLoading &&
                setSearchCategories(getSearchCategories(searchData))
        }
    }, [searchData, queryLoading])

    useEffect(() => {
        setSearchCategory('')
    }, [searchValue])

    return (
        <>
            <FilterLayout
                pageHeaderConfig={{
                    headerText: t('general.searchResults'),
                    showMenu: false
                }}
                filterConfig={{
                    categoriesArray: searchCategories,
                    selectedCategory: isEmpty(searchCategory)
                        ? AllCategoryId
                        : searchCategory
                }}
                directoryComponent={tabComponent}
                isTabMode={true}
            />
        </>
    )
}
