export enum UserPermissionEnum {
  // DASHBOARD
  DashboardView = 'VDH',

  // RESOURCES
  ResourcesView = 'VRS',
  ResourcesCreateModify = 'CRS',

  // VIDEO
  VideoAdminApprove = 'AVD',
  VideoReject = 'RVD',
  VideoCoordinatorApprove = 'CAV',
  VideoModeratorApprove = 'MVD',
  VideoReport = 'RCD',
  VideoBrowse = 'BVD',
  VideoUpload = 'UVD',
  VideoDelete = 'DAV',

  // VERTICAL
  VerticalView = 'VVT',
  VerticalCreateModify = 'CVL',
  VerticalDelete = 'DVL',

  // CAMPUSES
  CampusView = 'VCM',
  CampusCreateModify = 'CCD',
  CampusDelete = 'DCM',

  // COURSE
  CourseView = 'VCU',
  CourseCreateModify = 'CCU',
  CourseDelete = 'DCU',

  // ARTICLE
  ArticleView = 'VAT',
  ArticleCreateModify = 'CAT',
  ArticleDelete = 'DAT',

  // LEARN
  LearnView = 'VLA',

  // LEARNERS
  LearnersView = 'VLN',
  LearnersCreateModify = 'CLN',
  LearnersDelete = 'DLN',

  // INSIGHT
  InsightView = 'INV',

  // REPORT
  ReportView = 'VRT',
  ReportCreateModify = 'CRP',
  ReportDelete = 'DRT',

  // SURVEY
  SurveyView = 'VSR',
  SurveyCreateModify = 'CSR',
  SurveyDelete = 'DSR',

  // TOPIC
  TopicView = 'VTP',
  TopicCreateModify = 'CTP',
  TopicDelete = 'DTP',

  // TRAININGS
  TrainingsView = 'VTR',
  TrainingsCreateModify = 'CTR',
  TrainingsDelete = 'DTR',
  TrainingsAssignedView = 'VAS',

  // ROLES
  RolesView = 'VRL',
  RolesCreateModify = 'CRL',
  RolesDelete = 'DRL',

  // USER
  UserView = 'VUS',
  UserCreateModify = 'CUS',
  UserDelete = 'DUS',

  // ADMIN
  AdminView = 'VDD',
  AdminCreateModify = 'CDF',
  AdminDelete = 'DDF',

  // CREATOR
  CreatorView = 'VRF',
  CreatorCreateModify = 'CRF',
  CreatorDelete = 'DRF',

  // CLIENT
  ClientView = 'VLF',
  ClientCreateModify = 'CLF',
  ClientDelete = 'DLF',
}
