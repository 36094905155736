import {
  MessagesFragment,
  MetaFragment,
  ReportLibraryFields,
} from '../../fragments'

import { gql } from '@apollo/client'

export const DELETE_REPORT_LIBRARY = gql`
  ${MessagesFragment('ReportLibraryResponseDto')}
  ${MetaFragment('ReportLibraryResponseDto')}
  ${ReportLibraryFields('ReportLibraryDto')}
  mutation deleteReportLibrary($id: String!) {
    deleteReportLibrary(id: $id) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...ReportLibraryFields
      }
    }
  }
`
