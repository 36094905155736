import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useSearchParams } from 'react-router-dom'
import {
    useCustomTheme,
    useDirectory,
    useGraphQLWrapper,
    useModalWithGraphQL
} from '@hooks'
import { Grid, Typography } from '@mui/material'
import {
    AddIcon,
    ArchiveIcon,
    DeleteIcon,
    EditRecordIcon,
    ReadingIcon,
    RestoreIcon,
    VideoPlayerIcon
} from '@assets'
import { TopicSchema, formatDate } from '@dts/client-utils'
import {
    DELETE_TOPIC,
    GET_ADMIN_TOPICS,
    GET_TOPIC_BY_ID,
    SAVE_TOPICS
} from '@dts/graphql/lib'
import { ConfirmationDialog, TextWithTooltip } from '@components'
import { startCase } from 'lodash'
import { can_access } from '@hoc'
import { CreateTopics } from './create-topics'
import { UserPermissionEnum } from '@dts/constants'

export const Topics = ({ status }: { status: string }) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const [searchParams] = useSearchParams()
    const routeId = searchParams.get('routeId')

    const saveTopicConfig = {
        mutation: SAVE_TOPICS,
        mutationName: 'saveTopic',
        onMutationSuccess: () => {
            retrieve?.({
                variables: {
                    options: queryOptions
                }
            })
        },
        isCustomMutationInput: true
    }

    const deleteTopicConfig = {
        mutation: DELETE_TOPIC,
        mutationName: 'deleteTopic',

        onMutationSuccess: () => {
            retrieve?.({
                variables: {
                    options: queryOptions
                }
            })
        },
        isCustomMutationInput: true
    }

    const { save: deleteTopic } = useGraphQLWrapper({
        ...deleteTopicConfig
    })
    useEffect(() => {
        if (routeId) {
            openPrepopulatedDialog({
                getTopicId: routeId
            })
        }
    }, [routeId])

    const { save: saveTopic } = useGraphQLWrapper({ ...saveTopicConfig })

    const { directory, retrieve, queryOptions } = useDirectory({
        directoryConfig: {
            query: GET_ADMIN_TOPICS,
            queryName: 'getAdminTopics',
            queryVariables: {
                args: {
                    tab: status
                }
            },
            config: {
                customActions: [
                    {
                        icon: <RestoreIcon />,
                        label: `${t('general.restore')}`,
                        ariaLabel: `${t('general.restore')}`,
                        showInMenu: true,
                        action: (params) => {
                            openConfirmationDialog({
                                input: {
                                    id: params?.row?.id,
                                    title: params?.row?.title ?? '',
                                    description: params?.row?.description ?? '',
                                    isArchived: false
                                }
                            })
                        },
                        hide: (params) => params?.row?.isArchived !== true,
                        permissions: [UserPermissionEnum.TopicCreateModify]
                    },
                    {
                        icon: <EditRecordIcon />,
                        label: `${t('general.edit')}`,
                        ariaLabel: `${t('general.edit')}`,
                        showInMenu: true,
                        action: (params) => {
                            openPrepopulatedDialog({
                                getTopicId: params?.id
                            })
                        },
                        permissions: [UserPermissionEnum.TopicCreateModify]
                    },
                    {
                        icon: (
                            <DeleteIcon
                                color={theme.palette.contentSecondary}
                                fill={theme.palette.contentSecondary}
                            />
                        ),
                        label: `${t('general.delete')}`,
                        ariaLabel: `${t('general.delete')}`,
                        showInMenu: true,
                        action: (params) => {
                            deleteTopic({
                                deleteTopicId: params?.row?.id
                            })
                        },
                        permissions: [UserPermissionEnum.TopicDelete]
                    },
                    {
                        icon: <ArchiveIcon />,
                        label: `${t('createArticle.archive')}`,
                        ariaLabel: `${t('createArticle.archive')}`,
                        showInMenu: true,
                        action: (params) => {
                            archiveConfirmationDialog({
                                input: {
                                    id: params?.row?.id,
                                    title: params?.row?.title ?? '',
                                    description: params?.row?.description ?? '',
                                    isArchived: true
                                }
                            })
                        },
                        hide: (params) => params?.row?.isArchived === true,
                        permissions: [UserPermissionEnum.TopicCreateModify]
                    }
                ],
                columns: [
                    {
                        field: 'title',
                        headerName: t('general.title'),
                        minWidth: 800,
                        renderCell: (params) => (
                            <Grid p={2}>
                                <Grid item spacing={2}>
                                    <Grid
                                        container
                                        direction={'column'}
                                        display={'flex'}
                                    >
                                        <TextWithTooltip
                                            renderer={params?.row?.title}
                                            toolTip={params?.row?.title}
                                            tabIndex={0}
                                            onClick={() =>
                                                openPrepopulatedDialog({
                                                    getTopicId: params?.id
                                                })
                                            }
                                            isDirectoryLink
                                        />
                                        {params?.row?.description && (
                                            <TextWithTooltip
                                                renderer={
                                                    params?.row?.description
                                                }
                                                mt={0.5}
                                            />
                                        )}
                                    </Grid>

                                    <Grid mt={0.5} mb={0.5}>
                                        <Grid
                                            container
                                            direction={'row'}
                                            display={'flex'}
                                        >
                                            <ReadingIcon />
                                            <Typography
                                                ml={2}
                                                mr={2}
                                                fontSize={'13px'}
                                                fontWeight={'500'}
                                                tabIndex={0}
                                                color={
                                                    theme.palette.contentPrimary
                                                }
                                            >
                                                {`${
                                                    params?.row?.coursesCount ??
                                                    0
                                                } ${t('general.courses')}`}
                                            </Typography>
                                            <VideoPlayerIcon
                                                color={
                                                    theme.palette
                                                        .contentSecondary
                                                }
                                                fill={
                                                    theme.palette
                                                        .contentSecondary
                                                }
                                            />
                                            <Typography
                                                ml={2}
                                                fontSize={'13px'}
                                                fontWeight={'500'}
                                                tabIndex={0}
                                                color={
                                                    theme.palette.contentPrimary
                                                }
                                            >
                                                {`${
                                                    params?.row?.videosCount ??
                                                    0
                                                } ${t('general.videos')}`}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    },
                    {
                        minWidth: 100,
                        field: 'createdAt',
                        headerName: t('general.dateCreated'),
                        renderCell: (params) => (
                            <TextWithTooltip
                                renderer={
                                    params?.value
                                        ? formatDate(params?.value)
                                        : ''
                                }
                                tabIndex={params.tabIndex}
                            />
                        )
                    }
                ],
                hideFooter: false,
                pagination: true
            },
            enableSearch: true,
            toolbarConfig: {
                header: `${startCase(status)} ${t('general.topics')}`,
                addNewButtonConfig: {
                    label: t('general.createNew'),
                    ariaLabel: t('general.topic'),
                    action: () => openDialog(),
                    icon: AddIcon,
                    permissions: [UserPermissionEnum.TopicCreateModify]
                }
            },
            id: 'topics'
        }
    })

    const { openDialog, openPrepopulatedDialog } = useModalWithGraphQL({
        ...saveTopicConfig,
        mapFormToMutationInput: ({ id, title, description, isArchived }) => ({
            input: { id, title, description, isArchived }
        }),
        query: GET_TOPIC_BY_ID,
        queryName: 'getTopic',
        mapQueryResultToForm: ({ id, title, description, isArchived }) => ({
            id,
            title,
            description,
            isArchived
        }),
        permissions: [UserPermissionEnum.TopicCreateModify],
        initialValues: {
            title: null,
            description: null,
            isArchived: false
        },
        modalConfig: {
            maxWidth: 'sm',
            Component: CreateTopics,
            creationConfig: {
                primaryButtonText: t('general.confirm'),
                header: `${t('general.create')} ${t('general.topic')}`
            },
            updationConfig: {
                primaryButtonText: t('general.confirm'),
                header: `${
                    can_access([UserPermissionEnum.TopicCreateModify])
                        ? t('general.update')
                        : t('general.view')
                } ${t('general.topic')}`
            },
            hasCancelButton: true
        },
        yupResolverSchema: TopicSchema
    })

    const { openConfirmationDialog } = useModalWithGraphQL({
        ...saveTopicConfig,

        modalConfig: {
            maxWidth: 'sm',
            Component: () => (
                <ConfirmationDialog
                    message={t('topic.restore.confirmation.message')}
                />
            ),
            confirmationConfig: {
                primaryButtonText: t('general.confirm'),
                header: `${t('general.confirmation')}`
            },
            hasCancelButton: true
        }
    })

    const { openConfirmationDialog: archiveConfirmationDialog } =
        useModalWithGraphQL({
            ...saveTopicConfig,

            modalConfig: {
                maxWidth: 'sm',
                Component: () => (
                    <ConfirmationDialog
                        message={t('archive.confirmation.message')}
                    />
                ),
                confirmationConfig: {
                    primaryButtonText: t('general.confirm'),
                    header: `${t('general.confirmation')}`
                },
                hasCancelButton: true
            }
        })

    return directory
}
