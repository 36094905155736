enum NotificationFrequencyEnum {
  None = 'NON',
  Daily = 'DAI',
  Weekly = 'WEE',
  Monthly = 'MON',
}

enum NotificationFrequencyGraphqlEnum {
  NON = 'NON',
  DAI = 'DAI',
  WEE = 'WEE',
  MON = 'MON',
}

export { NotificationFrequencyEnum, NotificationFrequencyGraphqlEnum }
