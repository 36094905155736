import React from 'react'
import { Button } from '@mui/material'
import { DropdownIcon } from '@assets'
import { DropdownMenu } from '@components'
import { isMediumScreen } from '@utils'
import { useCustomTheme } from '@hooks'
import { ToolbarDropdownConfig } from './types'

export const ToolbarDropdownMenu: React.FC<ToolbarDropdownConfig> = ({
    config
}) => {
    const { theme } = useCustomTheme()
    return (
        <DropdownMenu
            menuConfig={config?.menuConfig}
            MenuButton={() => (
                <Button
                    variant={config?.variant ?? 'contained'}
                    size={isMediumScreen?.() ? 'small' : 'medium'}
                    endIcon={
                        <DropdownIcon
                            color={theme.palette.contentPrimaryInverse}
                        />
                    }
                    sx={{
                        color: config?.variant
                            ? theme.palette.contentLink
                            : theme.palette.contentPrimaryInverse,
                        borderRadius: '12px'
                    }}
                >
                    {config?.label}
                </Button>
            )}
        />
    )
}
