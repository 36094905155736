import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SurveyContext } from '@providers'
import { isEmpty } from 'lodash'
import { SurveyDialog } from '@containers'
import { cachedSurveyInfo } from '@cacheql'
import { useModal } from './use-modal'
import { useGlobal } from './use-global'

export type UseSurveysProps = {
    onDialogClose?: VoidFunction
}

export const useSurveys = (props: UseSurveysProps) => {
    const surveyContextData = useContext(SurveyContext)
    const { onDialogClose } = props || {}

    const {
        currentSurveySteps,
        setCurrentSurveySteps,
        currentSurveyType,
        setIsSurveyOpened,
        resetToInitialValues
    } = surveyContextData || {}

    const { t } = useTranslation()
    const { isMediumScreen } = useGlobal()

    const { showDialog, hideDialog: hideSurveyDialog } = useModal()

    const hideDialog = () => {
        resetToInitialValues()
        hideSurveyDialog()
        onDialogClose?.()
    }

    const surveyDialogUnmount = () => {
        hideDialog()
        const prevCachedSurveyInfo = cachedSurveyInfo()
        cachedSurveyInfo({ ...prevCachedSurveyInfo, cachedSurveys: [] })
    }

    const showStepperModal = () => {
        const compProps = {
            steps: currentSurveySteps,
            hideDialog,
            setSteps: setCurrentSurveySteps,
            currentSurveyType
        }

        const body = <SurveyDialog {...compProps} />

        setIsSurveyOpened(true)

        showDialog({
            body,
            fullScreen: isMediumScreen,
            hideDialogActions: true,
            hasCancelButton: false,
            hasFeedbackDialog: true,
            dialogContentPadding: '0px',
            onClose: () => hideDialog(),
            primaryButtonConfig: {
                text: t('general.close'),
                onConfirm: () => hideDialog()
            }
        })
    }

    useEffect(() => {
        if (!isEmpty(currentSurveySteps)) {
            showStepperModal()
        }
    }, [currentSurveySteps])

    return {
        hideDialog,
        surveyDialogUnmount,
        ...surveyContextData
    }
}
