import React, { FC, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Avatar, Grid, IconButton } from '@mui/material'
import { CameraIcon, EditProfilePictureIcon } from '@assets'
import { setUserData } from '@cacheql'
import { FileTypeEnum, useS3 } from '@dts/client-utils'
import { useCustomTheme, useImageLoader } from '@hooks'
import { Loader } from '@components/Loader'
import { CustomLoader } from '@components/CustomLoader'
import {
    StyledAvatarContainer,
    StyledLoaderContainer
} from '@components/UserProfile/styles'
import { onKeyDown } from '@utils'
import { StyledBadge } from './styles'
import { UploadAvatarProps } from './types'

// This component assumes that it is wrapped into a form provider already
export const UploadAvatar: FC<UploadAvatarProps> = ({
    name,
    defaultImg = '',
    isRectangular = false
}) => {
    const fileInputRef = React.useRef(null)
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const { setValue, watch } = useFormContext()
    const url = watch(name) // Watching the field in the form holding the file that is uploaded
    const { uploadFile, s3Object, isUploadingStart } = useS3({})
    // Determine the source of the avatar image
    const avatarSrc = (s3Object?.file ?? url) || defaultImg
    const isLoadingImage = useImageLoader(avatarSrc)

    // Sets the filename in the form field if a file is uploaded
    useEffect(() => {
        s3Object?.fileName && setValue(name, s3Object?.fileName)
        setValue('fileUploadKey', s3Object?.key)
    }, [s3Object])

    const labelClickHandler = () => {
        fileInputRef.current.click()
    }

    return (
        <>
            <IconButton
                onKeyDown={(e) => {
                    onKeyDown(e, labelClickHandler, false)
                }}
                aria-label={t('createArticle.uploadImage')}
            >
                <label
                    htmlFor='avatarUpload'
                    aria-label={t('createArticle.uploadImage')}
                    onKeyDown={(e) => {
                        onKeyDown(e, labelClickHandler, false)
                    }}
                >
                    <StyledBadge
                        overlap='circular'
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        color='primary'
                        badgeContent={
                            <CameraIcon
                                color={theme.palette.contentPrimaryInverse}
                            />
                        }
                    >
                        {isUploadingStart ? (
                            <Grid
                                container
                                justifyContent='center'
                                alignItems='center'
                                sx={{
                                    width: 90,
                                    height: 90,
                                    borderRadius: '50%',
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    position: 'relative'
                                }}
                            >
                                <CustomLoader />
                            </Grid>
                        ) : (
                            <StyledAvatarContainer item>
                                <Avatar
                                    sx={{
                                        width: 100,
                                        height: 100,
                                        border: `1px solid ${theme.palette.borderGrey}`
                                    }}
                                    src={avatarSrc} // Avatar source
                                    alt={t('createArticle.uploadImage')}
                                />
                                {isLoadingImage && avatarSrc && (
                                    <StyledLoaderContainer>
                                        <CustomLoader />
                                    </StyledLoaderContainer>
                                )}
                            </StyledAvatarContainer>
                        )}
                    </StyledBadge>
                </label>
            </IconButton>
            <input
                style={{ display: 'none' }}
                ref={fileInputRef}
                type='file'
                id='avatarUpload'
                onChange={(e) => {
                    const file = e.target.files[0]
                    uploadFile(file, {
                        userId: setUserData()?.id,
                        type: FileTypeEnum.USER_AVATAR
                    })
                }}
                accept='.png,.jpg,.jpeg'
            />
        </>
    )
}
