import { gql } from '@apollo/client'
import { MetaFragment } from '../../fragments'

export const SEND_INVITE = gql`
  ${MetaFragment('GeneralResponseDto')}
  mutation sendInvite($input: InviteUserInputDto!) {
    sendInvite(input: $input) {
      ...MetaFragment
      message {
        type
        messages
      }
    }
  }
`
