import { styled } from '@mui/material/styles'
import { Paper, Typography } from '@mui/material'

export const StyledTypography = styled(Typography)(({ theme }) => ({
    padding: '12px 8px 13px 12px',
    borderBottom: `1px solid ${theme.palette.borderSecondary}`
}))

export const StyledPaper = styled(Paper, {
    shouldForwardProp: (prop) =>
        prop !== 'background' && prop !== 'borderBottom'
})<{ background?: string; borderBottom?: string; theme: any }>(
    ({ background, borderBottom, theme }) => ({
        background: background,
        padding: '8px 8px 8px 12px',
        borderBottom: borderBottom,
        borderRadius: '0px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        '&:hover': {
            background: theme.palette.BrandPrimaryContainer
        }
    })
)
