import { gql } from '@apollo/client'
import { MessagesFragment, TrainingFields } from '../../fragments'

export const UPDATE_TRAINING = gql`
  ${MessagesFragment('TrainingResponseDto')}
  ${TrainingFields('TrainingDto')}
  mutation updateTraining($input: UpdateTrainingInputDto!) {
    updateTraining(input: $input) {
      ...MessagesFragment
      data {
        ...TrainingFields
      }
    }
  }
`
