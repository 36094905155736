import { RecommendationTypeEnum, ResponseTypeEnum } from '@dts/constants'

import { camelCase } from 'lodash'

export enum QUESTION_TYPES {
    YNQ = 'YNQ',
    SCQ = 'SCQ',
    MCQ = 'MCQ'
}

export enum LearnerSurveyMode {
    NoCache = 'noCache',
    Cache = 'cache'
}

// export enum QUESTION_FEEDBACK_TYPES {
//     CommonFeedback = 'CMN',
//     CorrectIncorrectFeedback = 'CIF',
//     FeebackAgainstAll = 'FAO',
//     FeedbackAgainstSelected = 'FSO',
//     NoFeedback = 'NFD'
// }

export enum QUESTION_TYPES_TITLE {
    YNQ = 'YES/NO',
    SCQ = 'SINGLE-SELECT',
    MCQ = 'MULTI-CHOICE'
}

enum AnswerTypeEnum {
    CorrectAnswer = 'CRC',
    WrongAnswer = 'WRN',
    CommonAnswer = 'CMN'
}

export const surveyRecommendationTypes = Object.entries(
    RecommendationTypeEnum
).map(([key, value]) => ({
    id: value,
    name: camelCase(key)
}))

export const surveyMultiAnswerTypes = Object.entries(ResponseTypeEnum).map(
    ([key, value]) => ({
        id: value,
        name: camelCase(key)
    })
)
