import styled from '@emotion/styled'
import { Button, Grid, TableCell, Typography } from '@mui/material'
import { isMediumScreen } from '@utils'

export const StyledMetricsContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.backgroundPrimary,
    borderBottom: `2px solid ${theme.palette.borderGrey}`,
    flexDirection: 'column',
    padding: '16px 24px'
}))

export const StyledMetricsHeaderContainer = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignContent: 'space-between',
    marginBottom: '15px',
    flexWrap: 'nowrap',
    gap: isMediumScreen() ? '5px' : '0px'
}))

export const StyledMetricsCardsContainer = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px'
}))

export const StyledButton = styled(Button)(() => ({
    height: '40px',
    borderRadius: '12px'
}))

export const StyledButtonTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.contentPrimaryInverse
}))

export const StyledIconButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.backgroundBrandPrimary,
    borderRadius: '12px',
    padding: '5px'
}))

export const StyledQuestionTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.backgroundSecondary,
    borderBottom: `1px solid ${theme.palette.borderSecondary}`
}))
