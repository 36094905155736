export enum UserRolesEnum {
  SuperAdmin = 'GSA',
  SupportUser = 'SUP',
  ContentCreator = 'COC',
  Learner = 'TRN',
  ClientUser = 'CLU',
  DefaultTenantUser = 'DFU',
  Moderator = 'GMD',
  Coordinator = 'GCD',
  Manager = 'GRA',
}
