import { ApolloError } from '@apollo/client'

export const parseGraphqlErrors = (
  errors: ApolloError | Array<ApolloError>,
) => {
  const errorMessages: Array<string> = []
  let _errors: Array<ApolloError>

  if (Array.isArray(errors)) {
    _errors = [...errors]
  } else {
    _errors = [errors]
  }

  _errors.forEach((error) => {
    if (error) {
      if (error.networkError) {
        errorMessages.push(error.networkError.message)
      }
    }
    if (error.graphQLErrors) {
      error.graphQLErrors.forEach((err) => errorMessages.push(err.message))
    }
  })

  return errorMessages
}

export const addErrorsToForm = () => {
  const formErrors: Array<string> = []

  return formErrors
}

export const removeTypename = (value: object | Array<object>) => {
  if (value === null || value === undefined) {
    return value
  } else if (Array.isArray(value)) {
    return value.map((v) => removeTypename(v))
  } else if (typeof value === 'object') {
    const newObj = {}
    Object.entries(value).forEach(([key, v]) => {
      if (key !== '__typename') {
        newObj[key] = removeTypename(v)
      }
    })
    return newObj
  }
  return value
}

export const mapDropdownOptions = (responseData, accessor?: string) =>
  responseData?.map((item) => ({
    value: item?.id,
    label: item?.title ?? item?.name ?? item?.[accessor],
  })) ?? []

export const mergeDropdownFields = (responseData, iconArray = []) =>
  responseData?.map((item, index) => ({
    value: item?.id,
    label: item?.title ?? item?.name,
    ...iconArray?.[index],
  })) ?? []
