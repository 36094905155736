import './global.d'

export const baseURI = (): string => document.baseURI

export const routerBase = (): string => {
    const path = new URL(baseURI()).pathname
    return path ?? '/'
}

export default {
    API_SERVER: process.env.API_SERVER || '/api',
    BASE_PATH: routerBase(),
    ENABLE_COOKIE_AUTH: process.env.ENABLE_COOKIE_AUTH === 'true',
    MODE: process.env.MODE,
    BASE_URL: process.env.BASE_URL,
    REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_CLIENTID: process.env.REACT_APP_AUTH0_CLIENTID,
    REACT_APP_AUTH0_TOKEN_IDENTIFIER:
        process.env.REACT_APP_AUTH0_TOKEN_IDENTIFIER,
    LOGOUT_REDIRECT_URL: process.env.LOGOUT_REDIRECT_URL,
    MUI_LICENSE_KEY: process.env.MUI_LICENSE_KEY,
    WS_SERVER: process.env.WS_SERVER,
    FIREBASE_API_KEY: process.env.FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN: process.env.FIREBASE_AUTH_DOMAIN,
    FIREBASE_PROJECT_ID: process.env.FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET: process.env.FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGING_SENDER_ID: process.env.FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_APP_ID: process.env.FIREBASE_APP_ID,
    FIREBASE_MEASUREMENT_ID: process.env.FIREBASE_MEASUREMENT_ID,
    FIREBASE_VAPID_KEY: process.env.FIREBASE_VAPID_KEY,
    HUBSPOT_SRC: process.env.HUBSPOT_SRC,
    HUBSPOT_PORTAL_ID: process.env.HUBSPOT_PORTAL_ID,
    HUBSPOT_SUPPORT_FORM_ID: process.env.HUBSPOT_SUPPORT_FORM_ID
}
