import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Avatar, Divider, Grid, IconButton, Typography } from '@mui/material'
import {
    LearnerProfile,
    Loader,
    StyledChip,
    TextWithTooltip
} from '@components'
import { ActiveIcon, EditUserIcon } from '@assets'
import { UserInviteStatus } from '@constants'
import { isSmallScreen } from '@utils'
import { formatLongDate, getFullName } from '@dts/client-utils'
import { useCustomTheme, useLoader } from '@hooks'
import { LearnerInfoProps } from './types'
import { InfoContainer } from './styles'

export const LearnerInfo: FC<LearnerInfoProps> = (props) => {
    const { leanerProfileData, onEditProfile, onDeleteProfile, isLearner } =
        props || {}
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const { loading } = useLoader()
    const navigate = useNavigate()

    return (
        <Grid
            container
            flexDirection={isSmallScreen() ? 'column' : 'row'}
            justifyContent={'flex-start'}
            mt={isLearner ? 3 : 1}
            ml={isLearner ? 3 : 0}
            gap={2}
        >
            {!leanerProfileData && <Loader />}
            <Grid item>
                <Avatar
                    sx={{
                        width: 90,
                        height: 90
                    }}
                    src={leanerProfileData?.avatarSrc}
                    alt={[t('general.learner'), t('general.image')].join(' ')}
                />
            </Grid>

            <Grid item lg={7} xs={12}>
                <InfoContainer>
                    <Grid container alignItems={'center'}>
                        <Typography
                            variant='h2'
                            fontSize={20}
                            color={theme.palette.contentPrimary}
                            mr={1}
                        >
                            {getFullName(
                                leanerProfileData?.firstName,
                                leanerProfileData?.lastName
                            )}
                        </Typography>
                        {isLearner ? (
                            <TextWithTooltip
                                tabIndex={-1}
                                renderer={
                                    <IconButton
                                        sx={{ padding: 0, marginLeft: 2 }}
                                    >
                                        <EditUserIcon />
                                    </IconButton>
                                }
                                onClick={() => navigate('edit')}
                                toolTip={t('pageHeader.titleEdit')}
                            />
                        ) : !leanerProfileData?.active ? (
                            <ActiveIcon
                                color={theme.palette.errorDangerMedium}
                            />
                        ) : (!leanerProfileData?.status ||
                              leanerProfileData?.status?.id ===
                                  UserInviteStatus.PENDING) &&
                          leanerProfileData?.active ? (
                            <ActiveIcon color={theme.palette.yellow} />
                        ) : (
                            <ActiveIcon />
                        )}
                    </Grid>

                    <Grid container my={1} mt={2}>
                        <Grid item md={2} xs={12}>
                            <Typography
                                variant='body2'
                                fontSize={13}
                                fontWeight={500}
                                color={theme.palette.contentPrimary}
                            >
                                {t('general.addedVia')}
                            </Typography>
                        </Grid>
                        <Grid item md={10} xs={12}>
                            <Typography
                                variant='body2'
                                fontSize={14}
                                fontWeight={400}
                                color={theme.palette.contentPrimary}
                            >
                                {/**
                                 * TODO: this will be replace in the future
                                 */}
                                List import from filename.csv
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider />

                    <Grid container my={1}>
                        <Grid item md={2} xs={12}>
                            <Typography
                                variant='body2'
                                fontSize={13}
                                fontWeight={500}
                                color={theme.palette.contentPrimary}
                            >
                                {t('general.dateAdded')}
                            </Typography>
                        </Grid>
                        <Grid item md={10} xs={12}>
                            <Typography
                                variant='body2'
                                fontSize={14}
                                fontWeight={400}
                                color={theme.palette.contentPrimary}
                            >
                                {leanerProfileData?.createdAt
                                    ? formatLongDate(
                                          leanerProfileData?.createdAt
                                      )
                                    : '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container my={1}>
                        <Grid item md={2} xs={12}>
                            <Typography
                                variant='body2'
                                fontSize={13}
                                fontWeight={500}
                                color={theme.palette.contentPrimary}
                            >
                                {t('general.trainings')}
                            </Typography>
                        </Grid>
                        <Grid item md={10} xs={12}>
                            {leanerProfileData?.trainings?.length ? (
                                leanerProfileData?.trainings?.map(
                                    (tag: string, index: number) => (
                                        <StyledChip
                                            label={tag}
                                            key={tag + index}
                                            sx={{ mb: 1 }}
                                        />
                                    )
                                )
                            ) : (
                                <Typography
                                    fontSize={13}
                                    fontWeight={500}
                                    color={theme.palette.contentPrimary}
                                >
                                    {'--'}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container my={1}>
                        <Grid item md={2} xs={12}>
                            <Typography
                                fontSize={13}
                                fontWeight={500}
                                color={theme.palette.contentPrimary}
                            >
                                {t('general.tags')}
                            </Typography>
                        </Grid>
                        <Grid item md={10} xs={12}>
                            {leanerProfileData?.tags?.length ? (
                                leanerProfileData?.tags?.map(
                                    (tag: string, index: number) => (
                                        <StyledChip
                                            label={tag}
                                            key={tag + index}
                                            sx={{ mb: 1 }}
                                        />
                                    )
                                )
                            ) : (
                                <Typography
                                    fontSize={13}
                                    fontWeight={500}
                                    color={theme.palette.contentPrimary}
                                >
                                    {'--'}
                                </Typography>
                            )}
                        </Grid>
                        <Divider />
                    </Grid>
                </InfoContainer>
            </Grid>

            {!isLearner && (
                <Grid item xs={12} lg={4}>
                    <LearnerProfile
                        profileData={leanerProfileData}
                        onEditProfile={onEditProfile}
                        onDeleteProfile={onDeleteProfile}
                    />
                </Grid>
            )}
        </Grid>
    )
}
