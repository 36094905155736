import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const ChevronUpIcon: React.FC<SvgProps> = ({
    color,
    width = '20',
    height = '20'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 24 25'
            fill='none'
        >
            <path
                d='M6 15.5L12 9.5L18 15.5'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default ChevronUpIcon
