import React, { useEffect, useState } from 'react'
import {
    useFieldArray,
    useFormContext,
    useFormState,
    useWatch
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useLocation, useSearchParams } from 'react-router-dom'
import { EmptyState, Panel, StartIconButton } from '@components'
import { Grid } from '@mui/material'
import { ButtonVariants } from '@constants'
import { handleDragAndDrop } from '@utils'
import { generateUniqueId } from '@dts/client-utils'
import { DeleteIcon } from '@assets'
import { cloneDeep, isEmpty } from 'lodash'
import { useCustomTheme } from '@hooks'
import { AccessibleAction } from '@hoc'
import { UserPermissionEnum } from '@dts/constants'
import { CreateResources } from './create-resources'
import { ManageResourceCategoriesProps } from './types'

export const ManageResourceCategories = ({
    isClientAdmin
}: ManageResourceCategoriesProps) => {
    const { t } = useTranslation()
    const { control, setValue, trigger } = useFormContext()
    const [searchParams] = useSearchParams()
    const routeId = searchParams.get('routeId')
    const categories = useWatch({ name: 'categories', control })
    const { theme } = useCustomTheme()

    const { errors } = useFormState()
    const [globalSearchIds, setGlobalSearchIds] = useState({})
    const {
        append: appendCategory,
        remove: removeCategory,
        update: updateCategories
    } = useFieldArray({
        control,
        name: 'categories'
    })

    const resourceInitialValues = {
        id: generateUniqueId(),
        title: t('resources.newResource'),
        description: null,
        isConfidential: false,
        isNationalResource: false,
        isAlwaysAvailable: false,
        campusId: null,
        stateId: null,
        cityId: null,
        zipCode: null,
        address: null,
        email: null,
        phoneNumberOne: null,
        phoneNumberOneDescription: null,
        phoneNumberTwo: null,
        phoneNumberTwoDescription: null,
        phoneNumberThree: null,
        phoneNumberThreeDescription: null,
        websiteDisplayText: null,
        websiteUrl: null,
        onlineChatDisplayText: null,
        onlineChatUrl: null,
        titleUpdated: false,
        isEditing: false
    }

    const categoryInitialValues = {
        id: generateUniqueId(),
        title: t('resources.newCategory'),
        isDefault: false,
        titleUpdated: false,
        isEditing: false,
        resources: [{ ...resourceInitialValues }]
    }

    const updateEditResource = (
        updatedTitle,
        resources,
        resourceIndex,
        isEditing,
        titleUpdated
    ) =>
        resources?.map((resource, idx) => {
            if (idx === resourceIndex) {
                return {
                    ...resource,
                    title: updatedTitle,
                    isEditing: isEditing,
                    titleUpdated: titleUpdated
                }
            } else {
                return {
                    ...resource,
                    isEditing: false
                }
            }
        })

    function findIdInCategories(data, targetId) {
        let categoryId = null
        let resourceId = null

        for (const category of data) {
            if (category?.id === targetId) {
                categoryId = category?.id
                break
            }

            if (category?.resources) {
                for (const resource of category.resources) {
                    if (resource?.id === targetId) {
                        categoryId = category?.id // Safe to use optional chaining here
                        resourceId = resource?.id // Safe to use optional chaining here
                        break
                    }
                }
            }

            // If the resourceId is found, no need to continue searching.
            if (resourceId) {
                break
            }
        }

        return { categoryId, resourceId }
    }

    useEffect(() => {
        if (!routeId || !categories) return

        const result = findIdInCategories(categories, routeId)
        setGlobalSearchIds(result)

        const scrollToElement = (elementId) => {
            const element = document?.getElementById(elementId)
            if (element) {
                element?.scrollIntoView()
            }
        }

        // Scroll to category element if present
        if (result?.categoryId) {
            scrollToElement(`panel-${result?.categoryId}`)
        }

        // Scroll to resource element if present
        if (result?.resourceId) {
            scrollToElement(`panel-${result?.resourceId}`)
        }
    }, [categories, routeId])

    // To-do: The mappings for this code will be improved while doing backend integrations
    const getResourcePanels = (panels: Array<object>, categoryIndex: number) =>
        panels?.map((panel, index: number) => ({
            id: panel?.id,
            headerIcon: true,
            header: categories?.[categoryIndex]?.resources?.[index]?.title,
            hasError: !isEmpty(
                errors?.categories?.[categoryIndex]?.resources?.[index]
            ),
            errorKey: 'title',
            errorMessage:
                errors?.categories?.[categoryIndex]?.resources?.[index]?.title
                    ?.message,
            editConfig: {
                panel,
                index: index,
                name: `categories.[${categoryIndex}].resources.[${index}].title`,
                label: t('general.resource'),
                defaultHeader: t('resources.enterResourceTitle'),
                permissions: [UserPermissionEnum.ResourcesCreateModify],
                onBlur: (e) => {
                    const category = categories?.[categoryIndex]

                    const updatedCategory = {
                        ...category,
                        resources: updateEditResource(
                            e?.target?.value,
                            category?.resources,
                            index,
                            false,
                            true
                        )
                    }

                    updateCategories(categoryIndex, updatedCategory)

                    categories?.[categoryIndex]?.resources?.forEach((r, idx) =>
                        trigger(
                            `categories.[${categoryIndex}].resources.[${idx}].title`
                        )
                    )
                },
                onHeaderClick: (e) => {
                    const category = categories?.[categoryIndex]
                    const updatedCategory = {
                        ...category,
                        resources: updateEditResource(
                            categories?.[categoryIndex]?.resources?.[index]
                                ?.title,
                            category?.resources,
                            index,
                            true
                        )
                    }
                    updateCategories(categoryIndex, updatedCategory)
                }
            },
            bodySx: { paddingX: 1 },
            body: (
                <CreateResources
                    categoryIndex={categoryIndex}
                    resourceIndex={index}
                    isClientAdmin={isClientAdmin}
                />
            )
        }))

    const getPanels = (panels: Array<object>) =>
        panels?.map((panel, index: number) => ({
            id: panel?.id,
            headerIcon: true,
            header: categories?.[index]?.title,
            hasError: !isEmpty(errors?.categories?.[index]),
            errorKey: 'title',
            errorMessage: errors?.categories?.[index]?.title?.message,
            editConfig: {
                panel,
                index: index,
                name: `categories.[${index}].title`,
                label: t('general.category'),
                defaultHeader: t('resources.enterCategoryTitle'),
                permissions: [UserPermissionEnum.ResourcesCreateModify],
                onBlur: (e) => {
                    const updatedPanel = {
                        ...panel,
                        title: e.target.value,
                        isEditing: false,
                        titleUpdated: true
                    }
                    updateCategories(index, updatedPanel)
                    categories?.forEach((c, idx) =>
                        trigger(`categories.[${idx}].title`)
                    )
                },
                onHeaderClick: (e) => {
                    const updatedPanel = {
                        ...panel,
                        isEditing: true
                    }

                    updateCategories(index, updatedPanel)
                }
            },
            bodySx: { paddingX: 1 },
            body: (
                <Grid container flexDirection={'column'} gap={1}>
                    <Droppable droppableId={panel?.id}>
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <Panel
                                    panels={getResourcePanels(
                                        categories?.[index]?.resources,
                                        index
                                    )}
                                    // Might be used later on
                                    // backgroundColor={
                                    //     theme.palette.backgroundSecondary
                                    // }
                                    batchActions={[
                                        {
                                            icon: DeleteIcon,
                                            label: t('general.delete'),
                                            onClick: (data) => {
                                                const panelResources =
                                                    cloneDeep(panel?.resources)

                                                const filteredResources =
                                                    panelResources?.filter(
                                                        (resource) =>
                                                            resource?.id !==
                                                            data?.panel?.id
                                                    )

                                                const updatedPanel = {
                                                    ...panel,
                                                    resources: filteredResources
                                                }

                                                updateCategories(
                                                    index,
                                                    updatedPanel
                                                )
                                            },
                                            permissions: ['CRD']
                                        }
                                    ]}
                                    isHeaderEditable={true}
                                    enableReorder={true}
                                    isExpanded={
                                        globalSearchIds?.resourceId ?? ''
                                    }
                                />
                                {provided?.placeholder}
                            </div>
                        )}
                    </Droppable>
                    <AccessibleAction
                        permissions={[UserPermissionEnum.ResourcesCreateModify]}
                        component={
                            <Grid item md={3}>
                                <StartIconButton
                                    label={t('resources.newResource')}
                                    variant={ButtonVariants.Outlined}
                                    onClick={() => {
                                        const panelResources = cloneDeep(
                                            panel?.resources
                                        )

                                        panelResources.push({
                                            ...resourceInitialValues
                                        })
                                        const updatedPanel = {
                                            ...panel,
                                            resources: panelResources
                                        }

                                        updateCategories(index, updatedPanel)
                                    }}
                                />
                            </Grid>
                        }
                    />
                </Grid>
            )
        }))

    return (
        <>
            <DragDropContext
                onDragEnd={(result) =>
                    handleDragAndDrop(
                        result,
                        categories,
                        'resources',
                        (updatedObject) => setValue('categories', updatedObject)
                    )
                }
            >
                {categories?.length ? (
                    <Grid container flexDirection={'column'} gap={1}>
                        <Droppable droppableId='Root' type='group'>
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    <Panel
                                        panels={getPanels(categories)}
                                        // Might be used later on
                                        // backgroundColor={
                                        //     theme.palette.backgroundSecondary
                                        // }
                                        enableReorder
                                        isHeaderEditable={true}
                                        batchActions={[
                                            {
                                                icon: DeleteIcon,
                                                label: t('general.delete'),
                                                hide: (data) =>
                                                    data?.panel?.isDefault,
                                                onClick: (data) =>
                                                    removeCategory(data?.index),
                                                permissions: ['CRD']
                                            }
                                        ]}
                                        isExpanded={
                                            globalSearchIds?.categoryId ?? ''
                                        }
                                    />
                                    {provided?.placeholder}
                                </div>
                            )}
                        </Droppable>
                        <AccessibleAction
                            permissions={[
                                UserPermissionEnum.ResourcesCreateModify
                            ]}
                            component={
                                <Grid item md={3}>
                                    <StartIconButton
                                        label={t('resources.newCategory')}
                                        variant={ButtonVariants.Outlined}
                                        onClick={() =>
                                            appendCategory({
                                                ...categoryInitialValues
                                            })
                                        }
                                    />
                                </Grid>
                            }
                        />
                    </Grid>
                ) : (
                    <>
                        <EmptyState
                            preventPadding={true}
                            header={t(`emptyState.title`, {
                                title: t('sideNavItems.resources').toLowerCase()
                            })}
                            subHeader={t('resources.emptyState.subHeader')}
                            buttonConfig={{
                                label: t(`general.newAccessor`, {
                                    accessor: t('general.category')
                                }),
                                onClick: () => {
                                    appendCategory({
                                        ...categoryInitialValues
                                    })
                                },
                                permissions: [
                                    UserPermissionEnum.ResourcesCreateModify
                                ]
                            }}
                        />
                    </>
                )}
            </DragDropContext>
        </>
    )
}
