import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const SettingsCheckedIcon: React.FC<SvgProps> = ({
    color,
    width = '20',
    height = '20'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 20 20'
            fill='none'
        >
            <path
                d='M16.4071 8.80959L17.1127 7.84014C17.3226 7.55179 17.4443 7.20872 17.463 6.85255C17.4818 6.49639 17.3967 6.14244 17.2183 5.83366C17.04 5.52469 16.7759 5.27407 16.4581 5.11218C16.1402 4.9503 15.7822 4.8841 15.4275 4.92162L14.2358 5.04847C13.8103 5.09317 13.3815 5.00209 13.0108 4.78829C12.6418 4.57387 12.3501 4.24812 12.1775 3.85773L11.6868 2.76236C11.542 2.4363 11.3057 2.15925 11.0066 1.96482C10.7075 1.77039 10.3583 1.66693 10.0016 1.66699C9.64483 1.66693 9.29571 1.77039 8.99659 1.96482C8.69747 2.15925 8.46118 2.4363 8.3164 2.76236L7.82936 3.85773C7.6568 4.24812 7.36509 4.57387 6.99603 4.78829C6.62538 5.00209 6.19659 5.09317 5.77103 5.04847L4.57566 4.92162C4.22094 4.8841 3.86294 4.9503 3.54509 5.11218C3.22724 5.27407 2.96317 5.52469 2.78492 5.83366C2.60644 6.14244 2.52141 6.49639 2.54014 6.85255C2.55888 7.20872 2.68058 7.55179 2.89048 7.84014L3.59603 8.80959C3.84765 9.15562 3.98315 9.57248 3.98307 10.0003C3.98315 10.4282 3.84765 10.845 3.59603 11.1911L2.89048 12.1605C2.68058 12.4489 2.55888 12.7919 2.54014 13.1481C2.52141 13.5043 2.60644 13.8582 2.78492 14.167C2.96335 14.4758 3.22744 14.7263 3.54525 14.8882C3.86306 15.05 4.22096 15.1163 4.57566 15.079L5.76733 14.9522M12.5001 10.0003C12.5001 11.381 11.3808 12.5003 10.0001 12.5003C8.61939 12.5003 7.5001 11.381 7.5001 10.0003C7.5001 8.61961 8.61939 7.50033 10.0001 7.50033C11.3808 7.50033 12.5001 8.61961 12.5001 10.0003Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M17.4998 12.5L11.7707 18.3333L9.1665 15.6818'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default SettingsCheckedIcon
