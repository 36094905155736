import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, AdminTopics } from '../../fragments'

export const SAVE_TOPICS = gql`
  ${MessagesFragment('TopicResponseDto')}
  ${MetaFragment('TopicResponseDto')}
  ${AdminTopics('TopicDto')}
  mutation SaveTopic($input: SaveTopicInputDto!) {
    saveTopic(input: $input) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...AdminTopics
      }
    }
  }
`
