import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const SAVE_SURVEY_RESPONSE = gql`
  ${MessagesFragment('SurveyQuestionResponseDto')}
  ${MetaFragment('SurveyQuestionResponseDto')}
  mutation saveSurveyResponse($input: SurveyResponseInputDto!) {
    saveSurveyResponse(input: $input) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
        title
        questionNumber
        questionType {
          id
          title
        }
        questionFeedbackType {
          id
          title
        }
        skill {
          id
          title
        }
        topic {
          id
          title
        }
        isHidden
        surveyOptions {
          id
          title
          isAnswer
          summaryDescription
          displayOrder
          surveyRecommendations {
            recommendations {
              id
              recommendationType {
                id
                title
              }
              tags
              skills {
                id
                title
              }
            }
          }
        }
        surveyQuestionResponses {
          summaryTitle
          summaryDescription
          summaryResponseIcon {
            id
            title
          }
          responseType {
            id
            title
          }
        }
        displayOrder
        isAnswered
        answeredOptions
        isCorrect
        summaryEnabled
        summaryTitle
        summaryDescription
        summaryResponseIcon {
          id
          title
        }
        hideFeedback
      }
    }
  }
`
