import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SignUpSchema } from '@dts/client-utils'
import { Grid } from '@mui/material'
import { UserProfileForm } from '@components'
import { ADD_INFO_TO_USER } from '@dts/graphql'
import { useAuth, useFormFeedback, usePageHeader } from '@hooks'
import { DEFAULT_MIN_DATE } from '@constants'

export const Signup: React.FC = () => {
    const navigate = useNavigate()
    const { fetchUserData, userData } = useAuth()
    const { t } = useTranslation()

    const onQuerySuccess = () => {
        navigate('/')
    }

    const { methods, save, queryLoading, mutationLoading } = useFormFeedback({
        initialValues: {
            firstName: '',
            lastName: '',
            email: null,
            races: [],
            genders: [],
            interests: [],
            birthday: null,
            avatarUpload: null,
            customInterests: []
        },
        mapFormToMutationInput: ({
            firstName,
            lastName,
            races,
            genders,
            birthday,
            avatarUpload,
            interests,
            customInterests,
            title,
            school
        }) => {
            const selectedInterests = interests.filter(
                (interest) => !customInterests?.includes(interest)
            )
            return {
                input: {
                    avatar: avatarUpload,
                    firstName,
                    lastName,
                    races,
                    genders,
                    dateOfBirth: birthday ?? DEFAULT_MIN_DATE,
                    interests: selectedInterests,
                    customInterests,
                    title,
                    organization: school
                }
            }
        },
        mutation: ADD_INFO_TO_USER,
        mutationName: 'addInformationToUser',
        isCustomMutationInput: true,
        onMutationSuccess: () => fetchUserData?.(onQuerySuccess),
        yupResolverSchema: SignUpSchema
    })

    const getHeaderConfig = {
        headerText: t('pageHeader.titleSignup'),
        primaryButtonTitle: t('general.continue'),
        primaryButtonAction: methods.handleSubmit(save),
        isInsideLayout: false,
        saveForm: true
    }

    const { pageHeader } = usePageHeader(getHeaderConfig)

    useEffect(() => {
        if (userData) {
            const formValues = {
                firstName: userData?.firstName,
                lastName: userData?.lastName,
                avatarUpload: userData?.avatarSrc,
                email: userData?.email,
                birthday: userData?.dateOfBirth,
                ...(userData?.dateOfBirth && { minAge: true })
            }

            methods.reset({ ...formValues })
        }
    }, [userData])

    return (
        <>
            {pageHeader}
            <Grid container justifyContent='space-evenly'>
                <FormProvider {...methods}>
                    <Grid
                        container
                        justifyContent='space-evenly'
                        xs={12}
                        md={12}
                    >
                        <UserProfileForm isSignup={true} />
                    </Grid>
                </FormProvider>
            </Grid>
        </>
    )
}
