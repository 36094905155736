import styled from '@emotion/styled'
import { Chip, TextField } from '@mui/material'

export const StyledTextField = styled(TextField)(({ theme, width }) => ({
    marginTop: '1rem',
    width: width ?? '100%'
}))

export const StyledChip = styled(Chip)(({ theme }) => ({
    margin: 4,
    borderRadius: 8,
    backgroundColor: theme.palette.backgroundBrandPrimarySubtle,
    color: theme.palette.contentPrimary,
    maxWidth: 200,
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '11px',
    '.MuiChip-deleteIcon': {
        color: 'transparent',
        cursor: 'pointer',
        content: '""',
        display: 'inline-block',
        width: 18,
        height: 18,
        backgroundColor: theme.palette.neutralsTextLight,
        mask: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        maskSize: 'contain'
    }
}))
