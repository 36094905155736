import React from 'react'
import { useTranslation } from 'react-i18next'
import { AddIcon } from '@assets'
import { ButtonVariants } from '@constants'
import { useCustomTheme } from '@hooks'
import { Button } from '@mui/material'
import { AccessibleAction } from '@hoc'
import { StartIconButtonProps } from './types'
import { StyledButton } from './styles'

export const StartIconButton = ({
    label,
    onClick,
    variant = ButtonVariants.Contained,
    Icon,
    permissions
}: StartIconButtonProps) => {
    const { theme } = useCustomTheme()
    const { t } = useTranslation()
    return (
        <AccessibleAction
            permissions={permissions}
            component={
                <StyledButton
                    aria-label={t('general.add.ariaLabel', {
                        ariaLabel: label
                    })}
                    variant={variant}
                    startIcon={
                        Icon ? (
                            <Icon
                                color={
                                    variant === ButtonVariants.Contained
                                        ? theme?.palette?.contentPrimaryInverse
                                        : theme.palette.backgroundBrandPrimary
                                }
                            />
                        ) : undefined
                    }
                    size='medium'
                    onClick={() => onClick?.()}
                >
                    {label}
                </StyledButton>
            }
        />
    )
}
