export enum METRIC_TYPES {
    COMPLETION_RATE = 'CTR',
    PROGRESSION_RATE = 'PGR'
}

export enum REPORT_FILTER_TYPE {
    CATEGORICAL = 'CAT',
    NUMERICAL = 'NUM'
}

export enum AVAILABLE_FILTERS {
    Creator = 'CRE',
    Course = 'COU',
    Topic = 'TOP',
    Training = 'TRG',
    Tag = 'TAG'
}

export enum METRICS_BREAKDOWN {
    AGE = 'AGE',
    INTERESTS = 'INT',
    TRAINING = 'TRG',
    COURSE = 'COR',
    SURVEY = 'SUR'
}

export enum REPORT_TIME_FRAME {
    CUSTOM = 'CUS',
    TODAY = 'TDY',
    YESTERDAY = 'YDY',
    SEVEN_DAYS = 'SDY',
    THIRTY_DAYS = 'TYY',
    THREE_MONTHS = 'TMN',
    SIX_MONTHS = 'SMN',
    TWELVE_MONTHS = 'TVN'
}

export enum REPORT_GRANULARITY {
    HOURLY = 'HLY',
    DAILY = 'DLY',
    WEEKLY = 'WLY',
    MONTHLY = 'MLY'
}

export enum REPORT_LIBRARY {
    SYSTEM = 'system',
    CUSTOM = 'custom'
}

export enum METRIC_VALUE_TYPE_ID {
    PERCENTAGE = 'PER',
    ABSOLUTE = 'ABS'
}

export enum REPORT_DATA_TABLE_COLUMNS {
    METRICS = 'Metrics',
    TRAINING = 'Training',
    COURSE = 'Course',
    SURVEY = 'Survey',
    AGE = 'Age',
    INTEREST = 'Interest'
}

export enum REPORT_METRIC_TYPE_ID {
    TRAINING = 'TRA',
    COURSE = 'COR',
    SURVEY = 'SUR'
}

export enum REPORT_BREAK_DOWN_FIELDS {
    METRIC = 'reportMetricTitle',
    TRAINING = 'training',
    AGE = 'age',
    INTEREST = 'interest',
    SURVEY = 'survey',
    COURSE = 'course'
}

export enum AGGREGATION_TYPE_ID {
    CUMULATIVE = 'CMT',
    DISCRIMINATIVE = 'DSC'
}

export enum CHART_PANEL_TYPE {
    REPORT = 'report',
    INSIGHT = 'insight'
}
