import React, { FC } from 'react'
import { ListItem } from '@components'
import { Box, List } from '@mui/material'
import { EmailIcon, PhoneIcon, MapPinIcon, MobileIcon } from '@assets'
import { formatPhoneNumber, getAddress } from '@dts/client-utils'
import { useCustomTheme } from '@hooks'
import { ClientDetails } from './types'
import { StyledContactDetailsBox, StyledIconContainer } from './styles'

export const ContactGrid: FC<ClientDetails> = ({
    email,
    phone,
    address,
    city,
    state,
    zipCode,
    clientContact,
    valueSx,
    iconBackground,
    iconColor
}) => {
    const { theme } = useCustomTheme()
    const _iconBackground = iconBackground || theme.palette.backgroundPrimary
    const _iconColor = iconColor || theme.palette.contentSecondary
    const isAddressComplete = address && city && state && zipCode
    return (
        <Box mt={1}>
            <Box>
                <StyledContactDetailsBox>
                    <List>
                        {email && (
                            <ListItem
                                icon={{
                                    iconComponent: () => (
                                        <StyledIconContainer
                                            backgroundColor={_iconBackground}
                                        >
                                            <EmailIcon color={_iconColor} />
                                        </StyledIconContainer>
                                    )
                                }}
                                value={email}
                                id='email'
                                valueSx={valueSx}
                            />
                        )}
                        {phone && (
                            <ListItem
                                icon={{
                                    iconComponent: () => (
                                        <StyledIconContainer
                                            backgroundColor={_iconBackground}
                                        >
                                            <PhoneIcon color={_iconColor} />
                                        </StyledIconContainer>
                                    )
                                }}
                                id='mobilePhone'
                                value={formatPhoneNumber(phone, true)}
                                valueSx={valueSx}
                            />
                        )}

                        {isAddressComplete && (
                            <ListItem
                                icon={{
                                    iconComponent: () => (
                                        <StyledIconContainer
                                            backgroundColor={_iconBackground}
                                        >
                                            <MapPinIcon color={_iconColor} />
                                        </StyledIconContainer>
                                    )
                                }}
                                value={getAddress(
                                    address,
                                    city,
                                    state,
                                    zipCode
                                )}
                                id='address'
                                valueSx={valueSx}
                            />
                        )}

                        {clientContact?.email && (
                            <ListItem
                                icon={{
                                    iconComponent: () => (
                                        <StyledIconContainer
                                            backgroundColor={_iconBackground}
                                        >
                                            <EmailIcon color={_iconColor} />
                                        </StyledIconContainer>
                                    )
                                }}
                                value={clientContact?.email}
                                id='clientEmail'
                                valueSx={valueSx}
                            />
                        )}

                        {clientContact?.mobile && (
                            <ListItem
                                icon={{
                                    iconComponent: () => (
                                        <StyledIconContainer
                                            backgroundColor={_iconBackground}
                                        >
                                            <PhoneIcon color={_iconColor} />
                                        </StyledIconContainer>
                                    )
                                }}
                                value={clientContact?.mobile}
                                id='clientMobile'
                                valueSx={valueSx}
                            />
                        )}

                        {clientContact?.phone && (
                            <ListItem
                                icon={{
                                    iconComponent: () => (
                                        <StyledIconContainer
                                            backgroundColor={_iconBackground}
                                        >
                                            <MobileIcon color={_iconColor} />
                                        </StyledIconContainer>
                                    )
                                }}
                                value={clientContact?.phone}
                                id='clientPhone'
                                valueSx={valueSx}
                            />
                        )}
                    </List>
                </StyledContactDetailsBox>
            </Box>
        </Box>
    )
}
