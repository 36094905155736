import React from 'react'
import { useAuth } from '@hooks'
import { getAccessRoles } from '@utils'
import { ViewAdminContainer } from '@containers/grs-admin'
import { ViewCreatorContainer } from './view-creator-container'

export const UserProfileViewLayout = () => {
    const {
        userInfo: { roles }
    } = useAuth()

    const accessRoles = getAccessRoles(roles)

    return accessRoles?.isAdmin ? (
        <ViewAdminContainer />
    ) : (
        <ViewCreatorContainer />
    )
}
