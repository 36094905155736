import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const READ_ALL_NOTIFICATIONS = gql`
  ${MessagesFragment('NotificationsResponseDto')}
  ${MetaFragment('NotificationsResponseDto')}
  mutation readAllNotifications {
    readAllNotifications {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
      }
    }
  }
`
