import React, { FC } from 'react'
import { Divider, Grid, Typography } from '@mui/material'
import { useCustomTheme } from '@hooks'
import { RowListProps } from './types'

export const RowList: FC<RowListProps> = (props) => {
    const {
        kpiTitle = '',
        kpiSubtitle = '',
        kpiValue = '',
        hideDivider = false
    } = props || {}
    const { theme } = useCustomTheme()
    return (
        <>
            <Grid
                container
                justifyContent={'space-between'}
                alignItems={'center'}
                my={2}
            >
                <Grid item display={'flex'} alignItems={'center'}>
                    <Typography
                        variant='body1'
                        fontSize={16}
                        color={theme.palette.contentSecondary}
                        fontWeight={400}
                    >
                        {kpiTitle}
                    </Typography>
                    <Typography
                        variant='body2'
                        fontSize={14}
                        color={theme.palette.contentTertiary}
                        fontWeight={400}
                        ml={1}
                    >
                        {kpiSubtitle}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant='body1'
                        fontSize={16}
                        color={theme.palette.contentPrimary}
                        fontWeight={600}
                    >
                        {kpiValue}
                    </Typography>
                </Grid>
            </Grid>
            {!hideDivider && (
                <Divider
                    sx={{
                        border: `1px solid ${theme.palette.backgroundTertiary}`
                    }}
                />
            )}
        </>
    )
}
