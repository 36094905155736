import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { ViewArticle } from '@components'

export const ViewArticleContainer = () => {
    const { t } = useTranslation()
    const { getValues } = useFormContext()
    const {
        articleHeader,
        description,
        tags,
        time,
        fileUrl: image
    } = getValues()

    return (
        <ViewArticle
            article={{
                id: '1',
                title: articleHeader,
                tags: tags,
                description,
                duration: time,
                coverImageSrc: image
            }}
        />
    )
}
