import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, VideoFields } from '../../fragments'
export const GET_VIDEOS = gql`
  ${MetaFragment('DashboardVideosResponseDto')}
  ${MessagesFragment('DashboardVideosResponseDto')}
  ${VideoFields('VideoDataDto')}
  query getMyVideos($args: MyVideosArgumentsDto!, $options: PaginationDto!) {
    getMyVideos(args: $args, options: $options) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...VideoFields
      }
      meta {
        count
        verified
      }
    }
  }
`

export const GET_VIDEO = gql`
  ${MetaFragment('VideoDetailsResponseDto')}
  ${MessagesFragment('VideoDetailsResponseDto')}
  ${VideoFields('VideoDetailsDto')}
  query getVideo($id: String!) {
    getVideo(id: $id) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...VideoFields
      }
    }
  }
`
