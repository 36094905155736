import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { Divider, Grid, Typography } from '@mui/material'
import { isMediumScreen } from '@utils'
import { GET_COMMON_DATA } from '@dts/graphql'
import { StyledTypography } from '@components/UserProfile/styles'
import { useGraphQLWrapper } from '@hooks'
import { mapDropdownOptions } from '@dts/client-utils'
import { StyledTypographyHelper } from '@components/Forms/ContentCreator/styles'
import { LearnerEditProfileProps } from '@containers/client-admin/learners/learner-profile/types'
import {
    ControlledTextInput,
    ControlledMultiSelectAutoComplete,
    ControlledDatePicker,
    UploadAvatar,
    StyledChip
} from '@components'

export const LearnerProfileForm: React.FC<LearnerEditProfileProps> = ({
    isLearner = false
}) => {
    const { t } = useTranslation()
    const { control } = useFormContext()
    const interests = useWatch({
        name: 'interests',
        control
    })
    const customInterests = useWatch({
        name: 'customInterests',
        control
    })

    const { data: commonData } = useGraphQLWrapper({
        query: GET_COMMON_DATA,
        queryName: 'getCommonData'
    })

    return (
        <Grid container gap={1}>
            <Grid
                item
                xs={12}
                mt={1.5}
                sx={
                    isMediumScreen()
                        ? { display: 'flex', justifyContent: 'center' }
                        : {}
                }
            >
                <UploadAvatar name='avatarUpload' />
            </Grid>
            <Grid item xs={12} md={isLearner ? 4.5 : 5.9}>
                <ControlledTextInput
                    id='firstName'
                    type='text'
                    name='firstName'
                    required
                    label={t('general.firstName')}
                />
            </Grid>
            <Grid item xs={12} md={isLearner ? 4.5 : 5.9}>
                <ControlledTextInput
                    id='lastName'
                    type='text'
                    name='lastName'
                    required
                    label={t('general.lastName')}
                />
            </Grid>
            <Grid item xs={12} md={isLearner ? 9.1 : 12}>
                <ControlledTextInput
                    id='email'
                    type='email'
                    name='email'
                    label={t('general.email')}
                    disabled={true}
                />
            </Grid>
            <Grid item xs={12} md={isLearner ? 4.5 : 5.9}>
                <ControlledDatePicker
                    id='birthday'
                    label={t('completeProfile.birthday')}
                    name='birthday'
                    width={'100%'}
                />
            </Grid>

            <Grid item xs={12} mt={3} mb={3}>
                <Divider />
            </Grid>

            <Grid item xs={12} md={7}>
                <StyledTypography>
                    {t(
                        isLearner
                            ? 'userProfile.otherInformation'
                            : 'learners.editProfile.interests.heading'
                    )}
                </StyledTypography>
            </Grid>
            <Grid item xs={12} md={7}>
                <StyledTypographyHelper py={1}>
                    {t(
                        isLearner
                            ? 'userProfile.otherSettingsHelper'
                            : 'learners.editProfile.interests.subheading'
                    )}
                </StyledTypographyHelper>
            </Grid>

            {isLearner ? (
                <>
                    <Grid item xs={12} md={9.1} mb={6}>
                        <ControlledMultiSelectAutoComplete
                            id='interests'
                            name='interests'
                            customDataName='customInterests'
                            label={t('general.interests')}
                            options={mapDropdownOptions(
                                commonData?.data?.interest
                            )}
                            allowCustomAddition
                        />
                    </Grid>
                </>
            ) : (
                <Grid xs={12}>
                    {!interests.length && !customInterests.length && (
                        <Typography variant='h5'>
                            {t('learners.editProfile.interests.empty')}
                        </Typography>
                    )}
                    {interests?.map((tag: string, index: number) => (
                        <StyledChip key={index} label={tag} />
                    ))}
                    {customInterests?.map((tag: string, index: number) => (
                        <StyledChip key={index} label={tag} />
                    ))}
                </Grid>
            )}
        </Grid>
    )
}
