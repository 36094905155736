import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { ControlledSelect } from '@components'
import { mapDropdownOptions } from '@dts/client-utils'

export const ClientsListForm: FC = ({ existingClient }) => {
    const { t } = useTranslation()
    const { control } = useFormContext()
    const clientsData = useWatch({
        control,
        name: 'clientsData'
    })

    return (
        <ControlledSelect
            name='client'
            id='client'
            label={t('general.clients')}
            options={mapDropdownOptions(
                clientsData?.filter(
                    (client) =>
                        !existingClient?.some(
                            (existing) => existing?.id === client?.id
                        )
                )
            )}
        />
    )
}
