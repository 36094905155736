import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const SAVE_VIDEO_INTERACTION = gql`
  ${MetaFragment('VideoInteractionResponseDto')}
  ${MessagesFragment('VideoInteractionResponseDto')}
  mutation saveVideoInteraction($input: VideoInteractionInputDto!) {
    saveVideoInteraction(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        id
        videoId
        liked
        disliked
        saved
        watchTime
      }
    }
  }
`
