import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCustomTheme } from '@hooks'
import { Grid, Typography } from '@mui/material'
import { ArrowDownCircleIcon, ArrowUpCircleIcon } from '@assets'
import { surveyMultiAnswerTypes, TypographyVariants } from '@constants'
import { startCase } from 'lodash'
import { SummaryDetail } from './summary-detail'
import { IconContainerStyled, PanelContainer } from './style'

export const MultiChoiceAnswerDetails = ({
    showIcon = true,
    formName = 'surveyQuestionResponses',
    permissions = []
}) => {
    const { theme } = useCustomTheme()
    const { t } = useTranslation()

    const panelOptions = [
        {
            header: surveyMultiAnswerTypes[1].name,
            caption: t('survey.correctAnswer.caption'),
            selectName: `${formName}[0].summaryResponseIconId`,
            titleName: `${formName}[0].summaryTitle`,
            descriptionName: `${formName}[0].summaryDescription`,
            iconBackgroundColor: theme.palette.dullGreen,
            icon: ArrowUpCircleIcon
        },
        {
            header: surveyMultiAnswerTypes[2].name,
            caption: t('survey.wrongAnswer.caption'),
            selectName: `${formName}[1].summaryResponseIconId`,
            titleName: `${formName}[1].summaryTitle`,
            descriptionName: `${formName}[1].summaryDescription`,
            iconBackgroundColor: theme.palette.brandRedLight,
            icon: ArrowDownCircleIcon
        }
    ]

    return (
        <Grid container gap={1}>
            {panelOptions?.map((panelOption) => (
                <PanelContainer
                    item
                    container
                    key={panelOption?.header}
                    flexDirection={'column'}
                    sm={12}
                    md={5.9}
                >
                    <Grid item display={'flex'} gap={1}>
                        {showIcon && (
                            <IconContainerStyled
                                item
                                iconBackgroundColor={
                                    panelOption?.iconBackgroundColor
                                }
                            >
                                {panelOption?.icon && <panelOption.icon />}
                            </IconContainerStyled>
                        )}
                        <Grid item container flexDirection={'column'}>
                            <Typography variant={TypographyVariants.h3}>
                                {startCase(panelOption?.header)}
                            </Typography>
                            <Typography variant='smallText'>
                                {panelOption?.caption}
                            </Typography>
                        </Grid>
                    </Grid>
                    <SummaryDetail
                        selectName={panelOption?.selectName}
                        titleName={panelOption?.titleName}
                        descriptionName={panelOption?.descriptionName}
                        permissions={permissions}
                    />
                </PanelContainer>
            ))}
        </Grid>
    )
}
