import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, VideoFields } from '../../fragments'
export const GET_ALL_VIDEOS = gql`
  ${MetaFragment('DashboardVideosResponseDto')}
  ${MessagesFragment('DashboardVideosResponseDto')}
  ${VideoFields('VideoDataDto')}
  query getVideos {
    getVideos {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...VideoFields
      }
    }
  }
`
