import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Controller,
    useFieldArray,
    useFormContext,
    useWatch
} from 'react-hook-form'
import {
    ControlledCheckBox,
    ListInput,
    SearchableList,
    BaseContainer,
    ComponentContainer,
    ParentContainer,
    TypographyContainer
} from '@components'
import { Button, Typography } from '@mui/material'
import { ListType } from '@constants'
import { useModalWithGraphQL } from '@hooks'

import { SearchableCoursesList } from './add-courses'
import { UserPermissionEnum } from '@dts/constants'

export const VerticalCourses: React.FC = ({
    addedIn,
    nestedAssignmentsOnly,
    subHeader
}) => {
    const { t } = useTranslation()
    const { getValues } = useFormContext()

    return (
        <ParentContainer container>
            <Typography variant='h1'>{getValues('name')}</Typography>
            <BaseContainer item>
                <TypographyContainer item md={2.5}>
                    <Typography variant='h3'>{t('general.courses')}</Typography>
                    <Typography variant='body2Medium'>
                        {subHeader || t('vertical.coursesCaption')}
                    </Typography>
                </TypographyContainer>
                <ComponentContainer item md={9.5}>
                    <SearchableCoursesList
                        addedIn={addedIn}
                        nestedAssignmentsOnly={nestedAssignmentsOnly}
                        permissions={[UserPermissionEnum.VerticalCreateModify]}
                    />
                </ComponentContainer>
            </BaseContainer>
        </ParentContainer>
    )
}
