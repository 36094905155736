import { InsightTimeline, InsightTimelineDays } from '../constants'

import moment from 'moment'
import { round } from 'lodash'

export const getInsightTimelineDate = (selectedItem) => {
  const currentDate = moment()

  let startDate
  switch (selectedItem) {
    case InsightTimeline.LAST_SEVEN_DAYS:
      startDate = moment().subtract(7, 'days')
      break
    case InsightTimeline.LAST_TWENTY_EIGHT_DAYS:
      startDate = moment().subtract(28, 'days')
      break
    case InsightTimeline.LAST_SIXTY_DAYS:
      startDate = moment().subtract(60, 'days')
      break
    case InsightTimeline.LAST_YEAR:
      startDate = moment().subtract(1, 'year')
      break
    default:
      startDate = currentDate
  }

  const formattedStartDate = startDate.format('MMM D,')
  const formattedEndDate = currentDate.format('MMM D, YYYY')
  const dateRange = `${formattedStartDate} - ${formattedEndDate}`
  return dateRange
}

// Example usage
// const value1 = 9000;
// const value2 = 10000890;
// Output: "9.00K"
// Output: "10.00M"
export const formatNumberToKMB = (value) => {
  const format = (num, divisor, unit) => {
    const result = num / divisor
    return result % 1 === 0 ? `${result}${unit}` : `${result.toFixed(2)}${unit}`
  }

  if (value >= 1000000) {
    return format(value, 1000000, 'M')
  } else if (value >= 1000) {
    return format(value, 1000, 'K')
  } else {
    return round(value) // Assuming round is a function you've defined elsewhere
  }
}

// Example usage:
// formatToTwoDecimals(35.64259258906046) // Output: "35.64"
// formatToTwoDecimals(35.00)            // Output: "35"

export const formatToTwoDecimals = (value) =>
  parseFloat(value.toFixed(2)).toString()

// Example usage
// const seconds1 = 125.75;
// Output: 3
export const secondsToCeiledMinutes = (seconds) => {
  const minutes = seconds / 60
  return Math.round(minutes)
}

// Example usage
// const minutes = 5.034;
// Output: "5m : 2s"
export const formatMinutesToMinutesAndSeconds = (minutes) => {
  const totalSeconds = Math.floor(minutes * 60)
  const mins = Math.floor(totalSeconds / 60)
  const secs = totalSeconds % 60
  return `${mins}m : ${secs}s`
}

// Example usage
// const percentage1 = 300;
// const percentage2 = -150;
// Output: "300% more than previous 28 days"
// Output: "150% less than previous 28 days"
export const formatPercentageChange = (percentage) => {
  const absolutePercentage = Math.abs(percentage)
  if (percentage < 0) {
    return `${round(absolutePercentage)}% less than previous`
  } else {
    return `${round(absolutePercentage)}% more than previous`
  }
}

export const getInsightTimeLineDays = (menuSelectedId) =>
  InsightTimeline.LAST_SEVEN_DAYS === menuSelectedId
    ? InsightTimelineDays?.LAST_SEVEN_DAYS
    : InsightTimeline.LAST_TWENTY_EIGHT_DAYS === menuSelectedId
    ? InsightTimelineDays?.LAST_TWENTY_EIGHT_DAYS
    : InsightTimeline.LAST_SIXTY_DAYS === menuSelectedId
    ? InsightTimelineDays?.LAST_SIXTY_DAYS
    : InsightTimelineDays.LAST_YEAR
