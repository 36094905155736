import React, { createContext, FC, useState } from 'react'
interface ContextData {
    loading?: boolean
    setLoading?: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

const defaultValue: ContextData = {
    loading: false
}

export const LoaderContext = createContext<ContextData>(defaultValue)
