import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormFeedback, usePage } from '@hooks'
import { CreateEditArticleForm } from '@components'
import { SAVE_ARTICLE, GET_ARTICLE } from '@dts/graphql'
import { CreateArticleSchema } from '@dts/client-utils'
import { UserPermissionEnum } from '@dts/constants'

export const CreateArticleContainer = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { id } = useParams()
    const [isDraft, setIsDraft] = useState(false)
    const [isImageUploading, setIsImageUploading] = useState(false)
    const onDraftHandler = (isDraftFlag?: boolean) => {
        setIsDraft(isDraftFlag)
    }
    useEffect(() => {
        isDraft && methods.handleSubmit(save())
    }, [isDraft])

    const { methods, save, queryLoading, mutationLoading } = useFormFeedback({
        permissions: [UserPermissionEnum.ArticleCreateModify],
        initialValues: {
            topic: '',
            tags: [],
            articleHeader: '',
            articleBody: '',
            time: undefined,
            isFeatured: false,
            description: ''
        },
        mapFormToMutationInput: ({
            articleHeader,
            articleBody,
            topic,
            tags,
            time,
            isFeatured,
            fileUploadKey,
            description
        }) => ({
            input: {
                id: id ? id : undefined,
                coverImage: fileUploadKey ?? undefined,
                title: articleHeader,
                description,
                tags: tags,
                isDraft: isDraft,
                topicId: topic,
                duration: time ? parseInt(time) : null,
                isFeatured
            }
        }),
        mutation: SAVE_ARTICLE,
        mutationName: 'saveArticle',
        isCustomMutationInput: true,
        onMutationSuccess: () => {
            navigate('/knowledge-center')
        },
        ...(id
            ? {
                  query: GET_ARTICLE,
                  queryName: 'getArticle',
                  retrieveOnMount: true,
                  queryVariables: { id },
                  mapQueryResultToForm: ({
                      title,
                      description,
                      tags,
                      topicId,
                      duration,
                      coverImage,
                      coverImageSrc,
                      isFeatured,
                      isDraft
                  }) => ({
                      id: id,
                      topic: topicId,
                      tags: [...(tags ? tags : [])],
                      articleHeader: title,
                      articleBody: description,
                      time: duration ? String(duration) : '',
                      thumbnail: coverImageSrc,
                      fileUploadKey: coverImage,
                      isFeatured,
                      isDraft,
                      description
                  })
              }
            : undefined),
        yupResolverSchema: CreateArticleSchema
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: CreateEditArticleForm,
            componentProps: { onDraftHandler, setIsImageUploading },
            hasHeader: true,
            preventMargin: true,
            showBreadcrumbs: true,
            pageHeaderConfig: {
                headerText: t('general.createArticle'),
                primaryButtonTitle: id
                    ? [
                          t('general.update'),
                          t('general.and'),
                          t('createArticle.publish')
                      ].join(' ')
                    : t('createArticle.publish'),
                primaryButtonAction: () => {
                    onDraftHandler(false)
                    methods.handleSubmit(() => save())()
                },
                disablePrimaryButton: () => isImageUploading,
                secondaryButtonTitle: t('general.cancel'),
                secondaryButtonAction: () => navigate('/knowledge-center'),
                permissions: [UserPermissionEnum.ArticleCreateModify]
            }
        }
    })

    return <>{page}</>
}
