import { MessagesFragment, MetaFragment } from '../../fragments'

import { gql } from '@apollo/client'

export const GET_USER_NOTIFICATION_PREFERENCES = gql`
  ${MessagesFragment('UserNotificationPreferencesResponseDto')}
  ${MetaFragment('UserNotificationPreferencesResponseDto')}
  query getUserNotificationPreferences {
    getUserNotificationPreferences {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
        title
      }
    }
  }
`
