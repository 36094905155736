import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AccessDeniedContainer } from '@containers'
import { useAuth } from '@hooks'
import { useAuth0 } from '@auth0/auth0-react'

/**
 *
 * @param canUserAccess
 * @returns This HOC checks if the user has access to Route, otherwise it renders the 'Denied' page.
 */

export const withAcessControl =
    (canUserAccess: boolean, permissions: Array<string>) =>
    (Route: React.FC<RouteComponentProps>) => {
        if (canUserAccess) {
            return can_access(permissions) ? Route : <AccessDeniedContainer />
        } else {
            return <AccessDeniedContainer />
        }
    }

export const AccessibleAction = ({ permissions, ...props }) => {
    const accessible = can_access(permissions)
    return accessible ? props.component : props.elseComponent ?? null
}

export const can_access = (permissions: Array<string>) => {
    const accessible = true

    const { userData } = useAuth()

    const { isAuthenticated } = useAuth0()
    if (!userData) {
        return accessible
    }
    if (!isAuthenticated || userData?.roles === null) {
        return !accessible
    }

    // concatinate all roles permissions
    const rolesPermissions = userData?.roles?.flatMap((role) =>
        role.roleActions?.map((action) => action?.id)
    )

    return permissions?.length
        ? permissions?.some((permission) => can(permission, rolesPermissions))
        : true
}

export const can = (permission, rolesPermissions) =>
    rolesPermissions?.includes(permission)
