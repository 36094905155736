import * as yup from 'yup'

export const ReportVideoSchema = yup.object().shape({
  selectedOption: yup.string().required('errors.required'),
  description: yup
    .string()
    .when('selectedOption', ([selectedOption], schema) =>
      selectedOption === 'OTH'
        ? schema
            .required('errors.required')
            .min(2, 'errors.min')
            .max(1000, 'errors.max')
        : yup.string(),
    ),
})
