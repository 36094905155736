import { BasicFields, MessagesFragment, MetaFragment } from '../../fragments'

import { gql } from '@apollo/client'

export const GET_COMMON_DATA = gql`
  ${MetaFragment('CommonDataResponseDto')}
  ${MessagesFragment('CommonDataResponseDto')}
  ${BasicFields('BaseRefDto')}
  query {
    getCommonData {
      ...MetaFragment
      ...MessagesFragment
      data {
        race {
          ...BasicFields
        }
        gender {
          ...BasicFields
        }
        interest {
          ...BasicFields
        }
      }
    }
  }
`

export * from './get-connection'
