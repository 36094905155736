import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseIcon, TickIcon } from '@assets'
import {
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material'
import { LinearProgress, MultiLinearProgress, Panel } from '@components'
import { useCustomTheme, useGlobal } from '@hooks'

import { SurveyTypeEnum } from '@dts/constants'
import { TypographyVariants } from '@constants'
import { StyledQuestionTableCell } from './styles'
import { SurveyDetailsProps } from './types'

const RenderSurveyDetailsTitle = (title: string) => (
    <Typography variant={TypographyVariants.h4}>{title}</Typography>
)

const RenderSurveyDetailsBody = ({
    title,
    progressBarValue,
    barBackgroundColor,
    progressBackgroundColor,
    responses,
    percentage,
    lastIndex
}) => {
    const { t } = useTranslation()

    return (
        <>
            <Grid container display={'flex'} flexDirection={'column'} px={2}>
                <Grid item xs={12} my={1}>
                    <Typography variant={TypographyVariants.body2}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12} mb={1}>
                    <LinearProgress
                        height='7px'
                        value={progressBarValue}
                        barBackgroundColor={barBackgroundColor}
                        progressBackgroundColor={progressBackgroundColor}
                        showProgress={false}
                    />
                </Grid>
                <Grid item mb={1}>
                    <Typography
                        variant={TypographyVariants.h4}
                    >{`${responses} ${t(
                        'general.responses'
                    )} (${percentage}%)`}</Typography>
                </Grid>
            </Grid>
            {!lastIndex && <Divider />}
        </>
    )
}

export const ResultDetails: FC<SurveyDetailsProps> = ({
    surveyQuestions,
    columnHeaders,
    surveyType
}) => {
    const { theme } = useCustomTheme()
    const { isMediumScreen } = useGlobal()

    const roundValues = (value: number) => Math.round(value)

    const setQuestionPercentage = (
        responsePercentage: number,
        totalResponsePercentage: number
    ) => roundValues((responsePercentage / 100) * totalResponsePercentage)

    return (
        <TableContainer style={{ overflowX: 'auto' }}>
            <Table>
                <TableHead>
                    <TableRow
                        sx={{
                            backgroundColor: theme.palette.backgroundPrimary
                        }}
                    >
                        {columnHeaders?.map((header) => (
                            <TableCell key={header}>
                                <Typography variant={TypographyVariants.h3}>
                                    {header}
                                </Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {surveyQuestions?.map((question, index) =>
                        !isMediumScreen ? (
                            <>
                                <TableRow key={`${index}-${question?.title}`}>
                                    <StyledQuestionTableCell
                                        sx={{
                                            width: '50%',
                                            minWidth: isMediumScreen
                                                ? '150px'
                                                : 'auto'
                                        }}
                                    >
                                        <Typography
                                            variant={TypographyVariants.h3}
                                        >
                                            {question?.title}
                                        </Typography>
                                    </StyledQuestionTableCell>

                                    <StyledQuestionTableCell
                                        sx={{
                                            width: '25%',

                                            minWidth: isMediumScreen
                                                ? '250px'
                                                : 'auto'
                                        }}
                                    >
                                        {!question?.isKnowledgeRelatedQuestion ? (
                                            <LinearProgress
                                                height='7px'
                                                value={roundValues(
                                                    question?.responsesPercentage
                                                )}
                                                barBackgroundColor={
                                                    theme.palette
                                                        .backgroundPrimary
                                                }
                                                progressBackgroundColor={
                                                    theme.palette
                                                        .backgroundBrandPrimary
                                                }
                                                hasTooltip
                                                showProgress={false}
                                            />
                                        ) : (
                                            <MultiLinearProgress
                                                multiProgressBar={[
                                                    {
                                                        value: setQuestionPercentage(
                                                            question?.correctResponsesPercentage,
                                                            question?.responsesPercentage
                                                        ),
                                                        barBackgroundColor:
                                                            theme.palette
                                                                .successMain,
                                                        progressBackgroundColor:
                                                            theme.palette
                                                                .successMain,
                                                        hasTooltip: true
                                                    },
                                                    {
                                                        value: setQuestionPercentage(
                                                            question?.incorrectResponsesPercentage,
                                                            question?.responsesPercentage
                                                        ),
                                                        barBackgroundColor:
                                                            theme.palette
                                                                .brandRedLight,
                                                        progressBackgroundColor:
                                                            theme.palette
                                                                .brandRedLight,
                                                        hasTooltip: true
                                                    },
                                                    {
                                                        value: roundValues(
                                                            100 -
                                                                question?.responsesPercentage
                                                        ),
                                                        barBackgroundColor:
                                                            theme.palette
                                                                .backgroundPrimary,
                                                        progressBackgroundColor:
                                                            theme.palette
                                                                .backgroundPrimary,
                                                        hasTooltip: true
                                                    }
                                                ]}
                                            />
                                        )}
                                    </StyledQuestionTableCell>
                                    {surveyType !== SurveyTypeEnum.Pulse && (
                                        <>
                                            <StyledQuestionTableCell>
                                                <Typography
                                                    variant={
                                                        TypographyVariants.h3
                                                    }
                                                >
                                                    {`${roundValues(
                                                        question?.responsesPercentage
                                                    )}%`}
                                                </Typography>
                                            </StyledQuestionTableCell>

                                            <StyledQuestionTableCell>
                                                <Typography
                                                    variant={
                                                        TypographyVariants.h3
                                                    }
                                                >
                                                    {question?.responsesCount}
                                                </Typography>
                                            </StyledQuestionTableCell>
                                        </>
                                    )}
                                </TableRow>

                                {question?.surveyOptions?.map(
                                    (option, indexAnswers) => (
                                        <TableRow
                                            key={`${indexAnswers}-${option.title}`}
                                            sx={{
                                                backgroundColor:
                                                    theme.palette
                                                        .backgroundPrimary
                                            }}
                                        >
                                            <TableCell>
                                                <Grid
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                    gap={1}
                                                >
                                                    {question?.isKnowledgeRelatedQuestion ? (
                                                        option?.isAnswer ? (
                                                            <TickIcon
                                                                height={22}
                                                                width={22}
                                                            />
                                                        ) : (
                                                            <CloseIcon
                                                                height={22}
                                                                width={22}
                                                            />
                                                        )
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <Typography
                                                        variant={
                                                            TypographyVariants.body2
                                                        }
                                                    >
                                                        {option.title}
                                                    </Typography>
                                                </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <LinearProgress
                                                    height='7px'
                                                    value={roundValues(
                                                        option?.responsesPercentage
                                                    )}
                                                    barBackgroundColor={
                                                        theme.palette
                                                            .backgroundPrimary
                                                    }
                                                    progressBackgroundColor={
                                                        question?.isKnowledgeRelatedQuestion
                                                            ? option?.isAnswer
                                                                ? theme.palette
                                                                      .successMain
                                                                : theme.palette
                                                                      .brandRedLight
                                                            : theme.palette
                                                                  .naturalGrey
                                                    }
                                                    showProgress={false}
                                                />
                                            </TableCell>
                                            {surveyType !==
                                                SurveyTypeEnum.Pulse && (
                                                <>
                                                    {' '}
                                                    <TableCell>
                                                        <Typography
                                                            variant={
                                                                TypographyVariants.body2
                                                            }
                                                        >
                                                            {`${roundValues(
                                                                option?.responsesPercentage
                                                            )}%`}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant={
                                                                TypographyVariants.body2
                                                            }
                                                        >
                                                            {
                                                                option?.responsesCount
                                                            }
                                                        </Typography>
                                                    </TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                    )
                                )}
                            </>
                        ) : (
                            <Panel
                                key={index}
                                panels={[
                                    {
                                        id: index,
                                        bodySx: { padding: '10px' },
                                        renderElementAtTitle: () =>
                                            RenderSurveyDetailsTitle(
                                                question?.title
                                            ),
                                        body: question?.surveyOptions?.map(
                                            (option, indexAnswers) => (
                                                <RenderSurveyDetailsBody
                                                    key={`${indexAnswers}-${option}`}
                                                    title={option?.title}
                                                    progressBarValue={roundValues(
                                                        option?.responsesPercentage
                                                    )}
                                                    barBackgroundColor={
                                                        theme.palette
                                                            .backgroundPrimary
                                                    }
                                                    progressBackgroundColor={
                                                        question?.isKnowledgeRelatedQuestion
                                                            ? option?.isAnswer
                                                                ? theme.palette
                                                                      .successMain
                                                                : theme.palette
                                                                      .brandRedLight
                                                            : theme.palette
                                                                  .backgroundBrandPrimary
                                                    }
                                                    responses={roundValues(
                                                        option?.responsesCount
                                                    )}
                                                    percentage={roundValues(
                                                        option?.responsesPercentage
                                                    )}
                                                    lastIndex={
                                                        indexAnswers ===
                                                        question?.surveyOptions
                                                            ?.length -
                                                            1
                                                    }
                                                />
                                            )
                                        )
                                    }
                                ]}
                            />
                        )
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
