import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCustomTheme, useFormFeedback, usePage } from '@hooks'
import {
    GET_RESOURCES,
    GET_TENANT_RESOURCES,
    SAVE_RESOURCES,
    SAVE_TENANT_RESOURCES
} from '@dts/graphql'
import { SaveAsIcon } from '@assets'
import { ResourcesSchema, trimString } from '@dts/client-utils'
import { isEmpty } from 'lodash'
import { UserPermissionEnum } from '@dts/constants'
import { ManageResourceCategories } from './manage-resource-categories'

export const ManageResourcesContainer = ({ isClientAdmin }) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()

    const { methods, save } = useFormFeedback({
        initialValues: { categories: [] },
        query: isClientAdmin ? GET_TENANT_RESOURCES : GET_RESOURCES,
        queryName: isClientAdmin ? 'getTenantResources' : 'getResources',
        retrieveOnMount: true,
        mode: 'onBlur',
        permissions: [UserPermissionEnum.ResourcesCreateModify],
        mapQueryResultToForm: (response) => ({
            categories: response?.map((category) => ({
                id: category?.id,
                title: category?.title,
                isDefault: category?.isDefault,
                isEditing: false,
                titleUpdated: true,
                resources: category?.resources?.map((resource) => {
                    const {
                        id,
                        title,
                        description,
                        isConfidential,
                        isNationalResource,
                        isAlwaysAvailable,
                        address,
                        stateId,
                        cityId,
                        zipCode,
                        email,
                        phoneNumberOne,
                        phoneNumberTwo,
                        phoneNumberThree,
                        phoneNumberOneDescription,
                        phoneNumberTwoDescription,
                        phoneNumberThreeDescription,
                        websiteDisplayText,
                        websiteUrl,
                        onlineChatDisplayText,
                        onlineChatUrl,
                        campus
                    } = resource || {}

                    return {
                        id,
                        title,
                        description,
                        isConfidential,
                        isNationalResource: isClientAdmin
                            ? undefined
                            : isNationalResource,
                        isAlwaysAvailable,
                        address,
                        stateId,
                        cityId,
                        zipCode,
                        email,
                        phoneNumberOne,
                        phoneNumberTwo,
                        phoneNumberThree,
                        phoneNumberOneDescription,
                        phoneNumberTwoDescription,
                        phoneNumberThreeDescription,
                        websiteDisplayText,
                        websiteUrl,
                        onlineChatDisplayText,
                        onlineChatUrl,
                        campusId: isClientAdmin ? campus?.id : undefined,
                        isEditing: false,
                        titleUpdated: true,
                        isClientAdmin
                    }
                })
            }))
        }),
        mutation: isClientAdmin ? SAVE_TENANT_RESOURCES : SAVE_RESOURCES,
        mutationName: isClientAdmin ? 'saveTenantResources' : 'saveResources',
        mapFormToMutationInput: (form) => {
            const mappedValues = form?.categories?.map((category) => ({
                id: category?.id,
                title: category?.title,
                resources: category?.resources?.map((resource) => {
                    const {
                        id,
                        title,
                        description,
                        isConfidential,
                        isNationalResource,
                        isAlwaysAvailable,
                        address,
                        stateId,
                        cityId,
                        zipCode,
                        email,
                        phoneNumberOne,
                        phoneNumberTwo,
                        phoneNumberThree,
                        phoneNumberOneDescription,
                        phoneNumberTwoDescription,
                        phoneNumberThreeDescription,
                        websiteDisplayText,
                        websiteUrl,
                        onlineChatDisplayText,
                        onlineChatUrl,
                        campusId
                    } = resource || {}

                    return {
                        id,
                        title: trimString(title),
                        description: trimString(description),
                        isConfidential,
                        isNationalResource: isClientAdmin
                            ? undefined
                            : isNationalResource,
                        isAlwaysAvailable,
                        address: trimString(address),
                        stateId,
                        cityId,
                        zipCode: trimString(zipCode),
                        email: trimString(email),
                        phoneNumberOne,
                        phoneNumberTwo,
                        phoneNumberThree,
                        phoneNumberOneDescription: trimString(
                            phoneNumberOneDescription
                        ),
                        phoneNumberTwoDescription: trimString(
                            phoneNumberTwoDescription
                        ),
                        phoneNumberThreeDescription: trimString(
                            phoneNumberThreeDescription
                        ),
                        websiteDisplayText: trimString(websiteDisplayText),
                        websiteUrl: trimString(websiteUrl),
                        onlineChatDisplayText: trimString(
                            onlineChatDisplayText
                        ),
                        onlineChatUrl: trimString(onlineChatUrl),
                        campusId: isClientAdmin ? campusId : undefined
                    }
                })
            }))
            return mappedValues
        },
        yupResolverSchema: ResourcesSchema
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: ManageResourceCategories,
            componentProps: { isClientAdmin },
            hasHeader: true,
            preventPadding: true,
            pageHeaderConfig: {
                headerText: t('general.resourcesAndServices'),
                primaryButtonTitle: t('general.save'),
                primaryButtonIcon: (
                    <SaveAsIcon
                        color={theme.palette.contentPrimaryInverse}
                        width={20}
                        height={20}
                    />
                ),
                permissions: [UserPermissionEnum.ResourcesCreateModify],
                disablePrimaryButton: () =>
                    !methods?.formState?.isDirty ||
                    (isEmpty(methods?.getValues('categories')) &&
                        !methods?.formState?.isDirty),
                primaryButtonAction: methods.handleSubmit(() => save())
            }
        }
    })

    return <>{page}</>
}
