import React, { useEffect, useRef, useState } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { useCustomTheme } from '@hooks'
import {
    StyledChippedTab,
    StyledChipsContainer,
    StyledContainer
} from './styles'
import { TabPanelProps, Tab, CustomTabsProps } from './types'

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index } = props || {}

    return <>{value === index && <Box sx={{ p: 0 }}>{children}</Box>}</>
}

export const ChippedTabs = ({ tabs }: CustomTabsProps) => {
    const { theme } = useCustomTheme()
    const [activeIndex, setActiveIndex] = useState<number>(0)
    const containerRef = useRef(null)
    const [isScrolling, setIsScrolling] = useState(true)

    useEffect(() => {
        // The following sets isScrolling variable - on the basis of which scrollbar is added to chippedTabs only when scrolling
        let scrollTimer

        const handleScroll = () => {
            setIsScrolling(true)
            clearTimeout(scrollTimer)
            scrollTimer = setTimeout(() => setIsScrolling(false), 300)
        }
        //  timeout-based approach to show the scrollbar momentarily
        const container = containerRef.current
        container?.addEventListener('scroll', handleScroll)

        setTimeout(() => {
            setIsScrolling(false)
        }, 1000) // Show scrollbar for 1 second initially

        return () => {
            clearTimeout(scrollTimer)
            container?.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <StyledChipsContainer ref={containerRef} isScrolling={isScrolling}>
            <StyledContainer>
                {tabs.map(
                    (
                        { id, headerLabel, Icon, onClick }: Tab,
                        index: number
                    ) => (
                        <StyledChippedTab
                            container
                            key={`${id}-${index}`}
                            index={index}
                            activeIndex={activeIndex}
                            onClick={() => {
                                setActiveIndex(index)
                                onClick?.()
                            }}
                        >
                            {Icon && (
                                <Grid item mr={1} mt={1}>
                                    <Icon
                                        width={'20'}
                                        height={'19'}
                                        color={
                                            theme.palette.backgroundBrandPrimary
                                        }
                                    />
                                </Grid>
                            )}
                            <Grid item>
                                <Typography variant='body2'>
                                    {headerLabel}
                                </Typography>
                            </Grid>
                        </StyledChippedTab>
                    )
                )}
            </StyledContainer>
        </StyledChipsContainer>
    )
}
