import React from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MenuItem as MUIMenuItem, ListItemIcon, Tooltip } from '@mui/material'
import { SvgProps } from '@types'
import { isMediumScreen } from '@utils'
import { useCustomTheme } from '@hooks'
import { TooltipPlacement } from '@constants'
import { StyledLink, StyledMenuItem } from '../../components/Link/styles'
import { StyledListItemIcon } from './styles'

type MenuItemProps = {
    path?: string
    id: string
    key: number
    rowTabIndex: number
    label: string
    icon?: React.FC<SvgProps>
    onClick?: () => void
    menu?: boolean
    isLanding?: boolean
    permissions?: Array<string>
    canUserAccess?: boolean
    sx?: object
}

export const MenuItem: React.FC<MenuItemProps> = ({
    isLanding,
    path,
    state,
    rowTabIndex,
    canUserAccess,
    id,
    sx,
    onClick,
    menu,
    label,
    ...props
}) => {
    const { t } = useTranslation()
    const { pathname } = useLocation()
    const { theme } = useCustomTheme()
    const isSelected = () => {
        if (isLanding) {
            return (
                pathname === `/${isLanding}` ||
                (pathname.includes(`${isLanding}`) && pathname.includes(path))
            )
        }
        const extractedPaths = pathname.split('/')
        const currentPath = path?.includes('/')
            ? path.split('/')
            : ([path] as [string])
        return extractedPaths.includes(currentPath[currentPath?.length - 1])
    }

    const renderMenuItem = () => {
        if (path !== undefined) {
            const selected = isSelected()
            return (
                <StyledLink
                    menu={menu && selected}
                    to={path}
                    state={state}
                    tabIndex={rowTabIndex || 0}
                    aria-label={label}
                >
                    <MUIMenuItem
                        id={`menu-item-${id}`}
                        key={id}
                        onClick={onClick}
                        sx={{
                            borderBottom: selected
                                ? `2px solid ${theme.palette.contentLink}`
                                : 'none',
                            '&:hover': {
                                backgroundColor: 'transparent', // For example, making the background transparent
                                borderRadius: '0px'
                            }
                        }}
                    >
                        {props?.icon && (
                            <ListItemIcon
                                id={label}
                                // To:do : test selected icon colors in tabs
                                // sx={{
                                //     '& svg path': {
                                //         stroke: selected
                                //             ? `${theme.palette.contentLink}`
                                //             : `${theme.palette.contentSecondary}`
                                //     }
                                // }}
                            >
                                <props.icon
                                    color={
                                        selected
                                            ? `${theme.palette.contentLink}`
                                            : `${theme.palette.contentSecondary}`
                                    }
                                />
                            </ListItemIcon>
                        )}
                        {!isMediumScreen() && (
                            <Tooltip
                                title={label}
                                placement={TooltipPlacement.topEnd}
                            >
                                <StyledMenuItem
                                    selected={selected}
                                    primary={label}
                                />
                            </Tooltip>
                        )}
                    </MUIMenuItem>
                </StyledLink>
            )
        } else {
            return (
                <MUIMenuItem
                    id={`menu-item-${id}`}
                    key={id}
                    sx={sx || {}}
                    onClick={() => onClick?.()}
                    tabIndex={0}
                    selected={isSelected()}
                >
                    {props?.icon && (
                        <Tooltip
                            title={label}
                            placement={TooltipPlacement.topEnd}
                        >
                            <StyledListItemIcon>
                                {props.icon}
                            </StyledListItemIcon>
                        </Tooltip>
                    )}
                    <StyledMenuItem primary={label} />
                </MUIMenuItem>
            )
        }
    }

    return canUserAccess ? renderMenuItem() : null
}
