import { Grid, styled } from '@mui/material'

export const StyledCardGrid = styled(Grid)({
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '1'
})

export const StyledVideoCard = styled(Grid)(({ width }) => ({
    position: 'relative',
    width: `${width}px`,
    height: '275px',
    minWidth: '200px',
    // aspectRatio: '3/4', //will be removed later on
    marginBottom: '20px',
    borderRadius: '8px',
    padding: '5px',
    '&:hover': {
        cursor: 'pointer',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)'
    }
}))

export const StyledCardImage = styled('img')({
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    objectFit: 'cover',
    objectPosition: 'center center'
})

export const StyledCardVideo = styled('video')({
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    objectFit: 'cover',
    objectPosition: 'center center',
    transition: 'transform 0.3s'
})

export const StyledCardAction = styled('div')(({ theme }) => ({
    position: 'absolute',
    bottom: '5%',
    left: '5%',
    color: theme.palette.backgroundPrimary,
    display: 'flex',
    padding: '8px'
}))
