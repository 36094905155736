import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useNavigation } from 'react-router-dom'
import { getAccessRoles, isMediumScreen } from '@utils'
import { useAuth, useCustomTheme, useGraphQLWrapper } from '@hooks'
import {
    SAVE_FOLLOW_STATUS,
    SAVE_MUTE_STATUS,
    SAVE_VIDEO_INTERACTION
} from '@dts/graphql'
import { setUserData } from '@cacheql'
import {
    DashboardIcon,
    HideIcon,
    InsightsIcon,
    LikeIcon,
    SaveIcon
} from '@assets'
import { InsightTimeline } from '@dts/client-utils'
import { VideoMobileView } from './video-mobile-view'
import { VideoWebView } from './video-web-view'
import { BrowseVideoProps } from './types'

export const BrowseVideos = ({
    video,
    index,
    accountInteractionsCallback,
    isLearnerBrowse,
    surveyContextData
}: BrowseVideoProps) => {
    const { theme } = useCustomTheme()
    const isNotCurrentUser = video?.creatorData?.id !== setUserData()?.id
    const {
        userInfo: { roles }
    } = useAuth()
    const accessRoles = getAccessRoles(roles)
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [videoInteractions, setVideoInteractions] = useState({
        liked: false,
        saved: false
    })

    const [accountInteractions, setAccountInteractions] = useState({
        accountId: video?.creatorData?.id,
        following: false,
        muted: false
    })

    const { save: saveMuteStatus } = useGraphQLWrapper({
        mutation: SAVE_MUTE_STATUS,
        mutationName: 'saveMuteStatus',
        retrieveOnMount: false,
        isCustomMutationInput: true,
        onMutationSuccess: (response) => {
            setAccountInteractions((prev) => ({
                ...prev,
                muted: response?.data?.muted,
                accountId: response?.data?.mutedId
            }))

            accountInteractionsCallback({
                accessor: 'muted',
                status: response?.data?.muted,
                accountId: response?.data?.mutedId
            })
        }
    })

    const { save: saveFollowStatus } = useGraphQLWrapper({
        mutation: SAVE_FOLLOW_STATUS,
        mutationName: 'saveFollowStatus',
        retrieveOnMount: false,
        isCustomMutationInput: true,
        onMutationSuccess: (response) => {
            setAccountInteractions((prev) => ({
                ...prev,
                following: response?.data?.following,
                accountId: response?.data?.followingId
            }))

            accountInteractionsCallback({
                accessor: 'following',
                status: response?.data?.following,
                accountId: response?.data?.followingId
            })
        }
    })

    const { save: saveVideoInteraction } = useGraphQLWrapper({
        mutation: SAVE_VIDEO_INTERACTION,
        mutationName: 'saveVideoInteraction',
        onMutationSuccess: (response) =>
            setVideoInteractions((prev) => ({
                ...prev,
                liked: response?.data?.liked,
                saved: response?.data?.saved
            }))
    })

    useEffect(() => {
        if (video?.creatorData?.id) {
            const { id, following, muted } = video?.creatorData || {}
            setAccountInteractions({
                accountId: id,
                following: following ?? false,
                muted: muted ?? false
            })
        }
    }, [video?.creatorData])

    useEffect(() => {
        setVideoInteractions(video?.videoInteraction)
    }, [video])

    const config = {
        accountInteractions,
        onFollow: () => {
            saveFollowStatus({
                input: {
                    followingId: accountInteractions?.accountId,
                    following: !accountInteractions?.following
                }
            })
        },
        actionIcons: [
            {
                icon: () => (
                    <LikeIcon
                        color={theme.palette.contentSecondary}
                        fill={
                            videoInteractions?.liked
                                ? theme.palette.contentNegative
                                : theme.palette.backgroundPrimary
                        }
                    />
                ),
                label: videoInteractions?.liked
                    ? [t('general.unLike'), t('general.video')].join(' ')
                    : [t('general.like'), t('general.video')].join(' '),
                onClick: () => {
                    if (video?.id) {
                        saveVideoInteraction({
                            videoId: video?.id,
                            liked: !videoInteractions?.liked
                        })
                    }
                }
            },
            {
                icon: () => (
                    <SaveIcon
                        color={theme.palette.contentSecondary}
                        fill={
                            videoInteractions?.saved
                                ? theme.palette.electricBlue
                                : theme.palette.backgroundPrimary
                        }
                    />
                ),
                label: videoInteractions?.saved
                    ? [t('general.unSave'), t('general.video')].join(' ')
                    : [t('general.save'), t('general.video')].join(' '),
                onClick: () => {
                    if (video?.id) {
                        saveVideoInteraction({
                            videoId: video?.id,
                            saved: !videoInteractions?.saved
                        })
                    }
                }
            }
        ],
        menuConfig: [
            isNotCurrentUser && {
                label: accountInteractions?.muted
                    ? `${t('general.mute')} ${video?.creatorData?.firstName}`
                    : t('setting.muted'),
                icon: HideIcon,
                onClick: () => {
                    saveMuteStatus({
                        input: {
                            mutedId: accountInteractions?.accountId,
                            muted: !accountInteractions?.muted
                        }
                    })
                }
            },
            !isNotCurrentUser && {
                label: t('insight.viewInsights'),
                icon: InsightsIcon,
                onClick: () =>
                    navigate(
                        `/video-insights/${video?.id}?insightTimeFrameId=${InsightTimeline.LAST_SEVEN_DAYS}`
                    )
            }
        ]?.filter(Boolean)
    }

    return !isMediumScreen() ? (
        <VideoWebView
            video={video}
            key={video?.id}
            config={config}
            index={index}
            accessRoles={accessRoles}
            isLearnerBrowse={isLearnerBrowse}
            surveyContextData={surveyContextData}
        />
    ) : (
        <VideoMobileView
            video={video}
            key={video?.id}
            isFirstEntry={index === 0}
            index={index}
            config={config}
            accessRoles={accessRoles}
            isLearnerBrowse={isLearnerBrowse}
            surveyContextData={surveyContextData}
        />
    )
}
