import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, VideoFields } from '../../fragments'

export const GET_VIDEOS_GROUPED_BY_TOPICS = gql`
  ${MetaFragment('VideosGroupByTopicsResponseDto')}
  ${MessagesFragment('VideosGroupByTopicsResponseDto')}
  ${VideoFields('VideoDataDto')}
  query GetVideosGroupedByTopics($args: VideosGroupByTopicsArgumentsDto) {
    getVideosGroupedByTopics(args: $args) {
      ...MetaFragment
      ...MessagesFragment
      data {
        count
        id
        title
        videos {
          ...VideoFields
        }
      }
    }
  }
`
