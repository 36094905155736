import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonVariants } from '@constants'
import { Button, CircularProgress, Grid } from '@mui/material'
import { useCustomTheme } from '@hooks'
import { StyledDialogActions } from './styles'

export const CustomDialogActions = (props) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()

    const {
        hasFeedbackDialog,
        hasCancelButton,
        onClose,
        secondaryButtonConfig,
        primaryButtonConfig,
        submitting,
        disabled,
        setDisabled
    } = props

    return (
        <StyledDialogActions>
            <Grid
                container
                gap={1}
                justifyContent={hasFeedbackDialog ? 'center' : 'flex-end'}
            >
                <Grid item>
                    {hasCancelButton ? (
                        <Button
                            onClick={onClose}
                            aria-label={t('general.cancel')}
                            sx={{
                                backgroundColor: theme.palette.secondary.main,
                                color: theme.palette.contentPrimary
                            }}
                        >
                            {t('general.cancel')}
                        </Button>
                    ) : null}
                </Grid>
                <Grid item>
                    {secondaryButtonConfig && (
                        <Button
                            onClick={() => {
                                secondaryButtonConfig?.onConfirm()
                            }}
                            variant={ButtonVariants.Outlined}
                            color='secondary'
                            autoFocus
                            sx={{ border: 'none' }}
                        >
                            {secondaryButtonConfig?.text ??
                                t('general.confirm')}
                        </Button>
                    )}
                </Grid>

                <Grid item>
                    {primaryButtonConfig && (
                        <Button
                            onClick={() => {
                                primaryButtonConfig?.onConfirm()
                                setDisabled(true)
                            }}
                            variant={
                                primaryButtonConfig?.variant ??
                                ButtonVariants.Contained
                            }
                            startIcon={
                                submitting ? (
                                    <CircularProgress
                                        size={20}
                                        color='secondary'
                                    />
                                ) : null
                            }
                            disabled={submitting || disabled}
                            autoFocus
                            sx={{
                                paddingLeft: hasFeedbackDialog ? '100px' : '',
                                paddingRight: hasFeedbackDialog ? '100px' : '',
                                color: theme.palette.contentPrimaryInverse
                            }}
                        >
                            {primaryButtonConfig?.text ?? t('general.confirm')}
                        </Button>
                    )}
                </Grid>
            </Grid>
        </StyledDialogActions>
    )
}
