import { gql } from '@apollo/client'

export const LearnerListDetailsFields = (on: string) => gql`
    fragment LearnerListDetailsFields on ${on} {
        id
        title
        description
        learnersCount
        updatedAt
        isArchived
        learners {
          email
          firstName
          lastName
          id
          tags
          status{
            id
            title
          }
          active
          updatedAt
        }
        file
    }
`
