import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
    InsightArrowUpIcon,
    InsightsIcon,
    LikesIcon,
    PlayIcon,
    PlusIcon
} from '@assets'
import { useCustomTheme } from '@hooks'
import { Button, Grid, Typography } from '@mui/material'
import { EmptyStateForInsightsProps } from './types'

export const EmptyStateForInsights: FC<EmptyStateForInsightsProps> = (
    props
) => {
    const { onButtonClick } = props || {}
    const { theme } = useCustomTheme()
    const { t } = useTranslation()
    return (
        <Grid
            container
            alignItems={'center'}
            justifyContent={'center'}
            height={'90vh'}
            direction={'column'}
        >
            <Grid item>
                <PlayIcon
                    color={theme?.palette?.contentSecondary}
                    height={56}
                    width={56}
                />
                <LikesIcon
                    color={theme?.palette?.contentSecondary}
                    height={56}
                    width={56}
                />
                <InsightsIcon
                    color={theme?.palette?.contentSecondary}
                    height={56}
                    width={56}
                />
                <InsightArrowUpIcon
                    color={theme?.palette?.contentSecondary}
                    height={56}
                    width={56}
                />
            </Grid>
            <Typography
                variant='body1'
                color={theme.palette.contentSecondary}
                fontWeight={400}
                fontSize={16}
                mt={1}
                mb={1}
            >
                {t('general.ChannelInsights.help')}
            </Typography>
            <Button
                variant={'contained'}
                sx={{ height: '40px' }}
                startIcon={<PlusIcon />}
                color={theme.palette.brandPrimary}
                onClick={onButtonClick}
            >
                {t('creatorVideos.modal.createVideo.header')}
            </Button>
        </Grid>
    )
}
