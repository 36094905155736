import React, { FC } from 'react'
import Box from '@mui/material/Box'
import { Tooltip, Typography } from '@mui/material'
import { useCustomTheme } from '@hooks'
import {
    ProgressBarVariants,
    TooltipPlacement,
    TypographyVariants
} from '@constants'
import { StyledLabelGrid, StyledLinearProgress } from './styles'
import { LinearProgressProps } from './types'

export const LinearProgress: FC<LinearProgressProps> = (props) => {
    const {
        barBackgroundColor,
        hasTooltip,
        showProgress = true,
        height,
        progressBackgroundColor,
        value = 0,
        border,
        hasBorder = true
    } = props || {}

    const { theme } = useCustomTheme()
    const getLinearProgress = () => (
        <StyledLinearProgress
            variant={ProgressBarVariants.determinate}
            value={value}
            barBackgroundColor={barBackgroundColor}
            progressBackgroundColor={progressBackgroundColor}
            height={height}
            style={{ width: '100%' }}
            border={border}
            hasBorder={hasBorder}
        />
    )

    return (
        <>
            <Box sx={{ display: 'flex', position: 'relative' }}>
                {hasTooltip ? (
                    <Tooltip
                        title={`${value}%`}
                        placement={TooltipPlacement.top}
                    >
                        {getLinearProgress()}
                    </Tooltip>
                ) : (
                    getLinearProgress()
                )}
                {showProgress && (
                    <StyledLabelGrid
                        sx={{
                            position: 'absolute',
                            bottom: '-24px', // Adjust for desired vertical offset
                            left: `${value}%`,
                            transform: 'translateX(-50%)'
                        }}
                    >
                        <Typography
                            variant={TypographyVariants.body2}
                            fontSize={10}
                            fontWeight={600}
                            color={theme.palette.backgroundPrimary}
                        >
                            {`${value}%`}
                        </Typography>
                    </StyledLabelGrid>
                )}
            </Box>
        </>
    )
}
