import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { DialogBody } from '@types'

export const ConfirmationDialog: React.FC<DialogBody> = ({ message }) => {
    const { t } = useTranslation()
    return (
        <Typography variant='body2' py={2} sx={{ whiteSpace: 'pre-line' }}>
            {message ?? t('errors.dialog.confirmation')}
        </Typography>
    )
}
