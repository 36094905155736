import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ControlledMultiSelectAutoComplete } from '@components/Select'
import { mapDropdownOptions } from '@dts/client-utils'
import { GET_LEARNERS } from '@dts/graphql'
import { useGraphQLWrapper } from '@hooks'
import { BaseFields } from '@types'
import { SearchableLearnersProps } from './types'

export const SearchableLearners = ({
    width,
    allowCustomAddition = true,
    name,
    customDataName,
    learnerListId
}: SearchableLearnersProps) => {
    const { t } = useTranslation()
    const [learners, setLearners] = useState([])
    const { data: learnersData, queryLoading } = useGraphQLWrapper({
        query: GET_LEARNERS,
        queryName: 'getLearners',
        retrieveOnMount: true,
        queryVariables: {
            learnerListId
        }
    })

    useEffect(() => {
        if (!queryLoading && learnersData) {
            const newLearners =
                learnersData.data?.map((learner: BaseFields) => ({
                    id: learner.title,
                    title: learner.title
                })) || []
            setLearners(newLearners)
        }
    }, [learnersData, queryLoading])

    return (
        <ControlledMultiSelectAutoComplete
            id={name}
            name={name}
            customDataName={customDataName}
            label={t('general.learners')}
            options={mapDropdownOptions(learners)}
            allowCustomAddition={allowCustomAddition}
            width={width}
        />
    )
}
