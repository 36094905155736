import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import ButtonGroup from '@mui/material/ButtonGroup'
import { GET_CHART_META_DATA } from '@dts/graphql/lib'
import { useGraphQLWrapper } from '@hooks'
import { Box, Grid, Grow } from '@mui/material'
import { mapDropdownOptions } from '@dts/client-utils'
import {
    ControlledSelect,
    ControlledSingleInputDateRangePicker,
    CustomLoader
} from '@components'
import { REPORT_GRANULARITY, REPORT_TIME_FRAME } from '@constants'
import { CalendarIcon } from '@mui/x-date-pickers'
import { getDateDifference, GetTimelineAriaLabel } from '@utils'
import { can_access } from '@hoc'
import { UserPermissionEnum } from '@dts/constants'
import { handlePlotChart } from '../../report-metrics'
import { StyledButton } from './styles'

export const TimelinePanel = ({ plotChart }) => {
    const { t } = useTranslation()
    const {
        setValue,
        control,
        getValues,
        handleSubmit,
        formState: { errors, isDirty }
    } = useFormContext()
    const selectedReportTimeFrame = useWatch({
        control,
        name: 'reportTimeFrame'
    })

    const [reportTimeFrame, setReportTimeFrame] = useState([])
    const [reportGranularityData, setReportGranularityData] = useState([])
    const [firstLoad, setFirstLoad] = useState(true)
    const [firstClick, setFirstClick] = useState(true)
    const [showDateRangePicker, setShowDateRangePicker] = useState(false)
    const [selectedTimeFrame, setSelectedTimeFrame] = useState<string>(
        selectedReportTimeFrame ?? REPORT_TIME_FRAME.SEVEN_DAYS
    )
    const { queryLoading, currentData: chartMetaData } = useGraphQLWrapper({
        query: GET_CHART_META_DATA,
        queryName: 'getChartMetaData'
    })

    useEffect(() => {
        setFirstLoad(false)
    }, [])

    useEffect(() => {
        if (!queryLoading && chartMetaData) {
            setReportTimeFrame(chartMetaData?.reportTimeFrame)
            setReportGranularityData(chartMetaData?.reportGranularity)
        }
    }, [queryLoading, chartMetaData])

    const metrics = useWatch({
        control,
        name: 'metrics'
    })

    const reportGranularity = useWatch({
        control,
        name: 'reportGranularity'
    })

    const filters = useWatch({
        control,
        name: 'filters'
    })

    const customDateRange = useWatch({
        control,
        name: 'customDateRange'
    })
    const breakdown = useWatch({
        control,
        name: 'breakdown'
    })

    const handleGranularityOptions = (isRemoveHourly: boolean) => {
        if (isRemoveHourly) {
            const filteredData = reportGranularityData.filter(
                (item) => item?.id !== REPORT_GRANULARITY.HOURLY
            )
            setReportGranularityData(filteredData)
            if (reportGranularity === REPORT_GRANULARITY.HOURLY) {
                setValue('reportGranularity', REPORT_GRANULARITY.DAILY)
            }
        } else {
            setReportGranularityData(chartMetaData?.reportGranularity)
        }
    }

    useEffect(() => {
        if (selectedTimeFrame) {
            handleGranularityOptions(
                selectedTimeFrame === REPORT_TIME_FRAME?.THIRTY_DAYS ||
                    selectedTimeFrame === REPORT_TIME_FRAME?.THREE_MONTHS ||
                    selectedTimeFrame === REPORT_TIME_FRAME?.TWELVE_MONTHS
            )
        }
    }, [selectedTimeFrame])

    useEffect(() => {
        if (customDateRange) {
            const differenceInDays = getDateDifference(
                customDateRange?.[0],
                customDateRange?.[1]
            )
            handleGranularityOptions(differenceInDays > 7)
            setValue('fromDate', customDateRange?.[0])
            setValue('toDate', customDateRange?.[1])
        }
    }, [customDateRange])

    const handleTimeFrame = (id: string) => {
        if (id !== REPORT_TIME_FRAME.CUSTOM) {
            //TODO: logic here
        }

        handlePlotChart({
            handleSubmit,
            plotChart,
            breakdown,
            metrics,
            filters,
            reportGranularity,
            reportTimeFrame: id
        })

        if (showDateRangePicker) {
            const _reportTimeFrame = [...chartMetaData?.reportTimeFrame]
            setReportTimeFrame(_reportTimeFrame)
            setShowDateRangePicker(false)
        }

        if (id === REPORT_TIME_FRAME.CUSTOM) {
            const _reportTimeFrame = [...reportTimeFrame]
            _reportTimeFrame.shift()
            setShowDateRangePicker(!showDateRangePicker)
            setReportTimeFrame(_reportTimeFrame)
        }
    }

    useEffect(() => {
        !firstLoad &&
            !firstClick &&
            customDateRange?.length &&
            isDirty &&
            handlePlotChart({
                handleSubmit,
                plotChart,
                metrics,
                breakdown,
                filters,
                reportGranularity,
                reportTimeFrame: REPORT_TIME_FRAME.CUSTOM,
                fromDate: customDateRange[0],
                toDate: customDateRange[1]
            })
        !firstLoad && setFirstClick(false)
    }, [customDateRange])

    useEffect(() => {
        const reportTimeFrame = getValues('reportTimeFrame')
        !firstLoad &&
            isDirty &&
            handlePlotChart({
                handleSubmit,
                plotChart,
                metrics,
                breakdown,
                filters,
                reportGranularity,
                reportTimeFrame,
                fromDate:
                    reportTimeFrame === REPORT_TIME_FRAME.CUSTOM
                        ? customDateRange[0]
                        : null,
                toDate:
                    reportTimeFrame === REPORT_TIME_FRAME.CUSTOM
                        ? customDateRange[1]
                        : null
            })
    }, [reportGranularity])

    return (
        <>
            {!queryLoading ? (
                <Grid
                    container
                    alignItems={'flex-end'}
                    justifyContent={'space-between'}
                    ml={1}
                    mb={2}
                >
                    <Grid xl={8}>
                        {/* FIX DISABLED STYLE */}
                        <ButtonGroup
                            variant='outlined'
                            disabled={
                                !can_access([
                                    UserPermissionEnum.ReportCreateModify
                                ])
                            }
                            aria-label='reportTimeFrame'
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row'
                            }}
                        >
                            <Grow
                                in={showDateRangePicker}
                                style={{ transformOrigin: '0 0 0' }}
                                timeout={300}
                            >
                                <Box>
                                    {showDateRangePicker && (
                                        <ControlledSingleInputDateRangePicker
                                            name='customDateRange'
                                            ariaLabel={t(
                                                'report.customDateRange'
                                            )}
                                        />
                                    )}
                                </Box>
                            </Grow>

                            {reportTimeFrame?.map(
                                (
                                    item: { id: string; title: string },
                                    index
                                ) => (
                                    <Grid key={item?.id + index}>
                                        <StyledButton
                                            key={item?.id + index}
                                            size='small'
                                            onClick={() => {
                                                setSelectedTimeFrame(item?.id)
                                                setValue(
                                                    'reportTimeFrame',
                                                    item?.id
                                                )
                                                handleTimeFrame(item?.id)
                                            }}
                                            aria-label={GetTimelineAriaLabel(
                                                item?.title,
                                                t
                                            )}
                                            isSelected={
                                                item?.id ===
                                                    selectedReportTimeFrame ??
                                                REPORT_TIME_FRAME.SEVEN_DAYS
                                            }
                                            {...(item?.id ===
                                                REPORT_TIME_FRAME.CUSTOM && {
                                                startIcon: <CalendarIcon />
                                            })}
                                        >
                                            {item?.title}
                                        </StyledButton>
                                    </Grid>
                                )
                            )}
                        </ButtonGroup>
                    </Grid>
                    <Grid container xl={4} spacing={2}>
                        <Grid item>
                            <ControlledSelect
                                name='chartType'
                                id='chartType'
                                ariaLabel={t('general.chartType')}
                                options={mapDropdownOptions(
                                    chartMetaData?.chartType
                                )}
                                placeholder={t('general.select')}
                                sx={{ width: '150px', height: '34px' }}
                            />
                        </Grid>
                        <Grid item>
                            <ControlledSelect
                                placeholder={t('general.select')}
                                name='reportGranularity'
                                id='reportGranularity'
                                ariaLabel={t('general.reportGranularity')}
                                options={mapDropdownOptions(
                                    reportGranularityData
                                )}
                                sx={{ width: '120px', height: '34px' }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <CustomLoader size={'2rem'} sx={{ alignSelf: 'center' }} />
            )}
        </>
    )
}
