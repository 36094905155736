import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_SURVEY_QUESTION_DETAILS = gql`
  ${MessagesFragment('SurveyQuestionResponseDto')}
  ${MetaFragment('SurveyQuestionResponseDto')}
  query getSurveyQuestionDetails($surveyQuestionId: String!) {
    getSurveyQuestionDetails(surveyQuestionId: $surveyQuestionId) {
      ...MetaFragment
      ...MessagesFragment
      data {
        id
        title
        questionType {
          id
          title
        }
        isHidden
        surveyOptions {
          id
          title
          isAnswer
          summaryEnabled
          summaryTitle
          summaryDescription
          summaryResponseIcon {
            id
            title
          }
          surveyRecommendations {
            id
            recommendationType {
              id
              title
            }
            tags
            
            skills {
              id
              title
            }
          }
        }
        displayOrder
        isAnswered
        answeredOptions
      }
    }
  }
`
