import { gql } from '@apollo/client'
import { MessagesFragment, TrainingFields } from '../../fragments'

export const SAVE_TRAINING = gql`
  ${MessagesFragment('TrainingResponseDto')}
  ${TrainingFields('TrainingDto')}
  mutation saveTraining($input: TrainingInputDto!) {
    saveTraining(input: $input) {
      ...MessagesFragment
      data {
        ...TrainingFields
      }
    }
  }
`
