import React from 'react'
import { useNavigate } from 'react-router-dom'
import { GET_CLIENT_COURSES, SAVE_CLIENT_COURSES } from '@dts/graphql'
import { useCourses } from './hook'

export const ClientCoursesContainer: React.FC = () => {
    const page = useCourses({
        addedIn: 'general.client',
        mutation: SAVE_CLIENT_COURSES,
        mutationName: 'saveClientCourses',
        query: GET_CLIENT_COURSES,
        queryName: 'getClientCourses'
    })

    return <>{page}</>
}
