import * as React from 'react'
import { Grid, Typography } from '@mui/material'
import { useCustomTheme } from '@hooks'
import { onKeyDown } from '@utils'
import { StyledCard, StyledCardHeader } from './styles'
import { AnalyticsCardProps } from './types'

export const AnalyticsCard: React.FC<AnalyticsCardProps> = ({
    header,
    isSelected,
    onClick,
    count = '0',
    description = '',
    renderHeaderIcon,
    renderInsightIcon
}) => {
    const { theme } = useCustomTheme()
    return (
        <StyledCard
            tabIndex={0}
            onClick={() => onClick?.()}
            onKeyDown={(e) => {
                onKeyDown(e, onClick, false)
            }}
            isSelected={isSelected}
        >
            <StyledCardHeader>
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '120%' /* 19.2px */,
                        letterSpacing: '-0.32px',
                        color: theme.palette.contentPrimary
                    }}
                >
                    {header}
                </Typography>
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '120%' /* 19.2px */,
                        letterSpacing: '-0.32px',
                        color: theme.palette.contentPrimary
                    }}
                >
                    {renderHeaderIcon?.()}
                </Typography>
            </StyledCardHeader>
            <Grid container alignItems={'center'} mt={1}>
                <Typography
                    sx={{
                        fontSize: '32px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '130%' /* 19.2px */,
                        letterSpacing: '-0.64px',
                        color: theme.palette.contentPrimary
                    }}
                    mr={1}
                >
                    {count}
                </Typography>
                {renderInsightIcon?.()}
            </Grid>
            <Typography
                sx={{
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '140%' /* 19.2px */,
                    color: theme.palette.contentPrimary
                }}
                mt={1.5}
            >
                {description}
            </Typography>
        </StyledCard>
    )
}
