import React from 'react'
import {
    CaptionsIcon,
    EditUserIcon,
    FollowIcon,
    GlobeIcon,
    HalfMoonIcon,
    HideIcon,
    LikeIcon,
    NotificationsIcon,
    QuestionMarkIcon,
    ReportIssueIcon,
    SaveIcon,
    UserIcon,
    HourGlassIcon,
    LaunchIcon,
    DraftIcon,
    XCircleIcon,
    VideoPlayerIcon,
    DashboardIcon,
    TopicsIcon,
    ReadingIcon,
    ResourcesIcon,
    SupportIcon,
    BuildingsIcon,
    CategoryIcon,
    UsersIcon,
    PlayIcon,
    BarChartIcon,
    SettingsIcon,
    CheckCircleIcon,
    ReportIcon,
    CourseIcon,
    ArchiveIcon,
    DocumentFoldIcon,
    VideoApprovalIcon,
    MetricIcon,
    DocumentQuestionMarkIcon,
    FolderIcon,
    UserCheckIcon,
    RocketDown
} from '@assets'
import {
    CreatorVideosPage,
    DashboardPage,
    NotificationsPage,
    SettingsPage,
    VerticalsPage,
    SignupPage,
    UserProfileViewPage,
    UserProfileEditPage,
    SystemDownPage,
    ReportIssuePage,
    UserProfilePage,
    TraineesListPage,
    ReviewVideosPage,
    ResourcesPage,
    SupportPage,
    BrowseVideosLayout,
    TopicsPage,
    ContentCreatorsPage,
    SettingsMenu,
    CoursesPage,
    RouteLayout,
    CreatorProfilePage,
    LoginAsPage,
    AccessDeniedPage,
    ReportingPage,
    ReportsLayout,
    EditContentCreatorsPage,
    BrowseVideosPage
} from '@pages'
import {
    VideosLayout,
    ReviewVideoContainer,
    ViewVideoContainer,
    VideosByTopic,
    ViewArticleContainer,
    ViewAdminContainer,
    CreateCourse,
    SavedVideos,
    LikedVideos,
    FollowingAccounts,
    LanguageSetting,
    CaptionsSetting,
    DisplaySetting,
    MutedAccounts,
    HelpCenter,
    TrainingsLayout,
    LearnersLayout,
    LearnerTrainings,
    PortalClient,
    GlobalSearchPage,
    CustomConfirmation,
    NotificationsSetting,
    CreateSurvey,
    SurveyLayout,
    VideoInsight,
    ControlledVideoInsight,
    SessionTimeout,
    ManageReports,
    ContentCreatorsLayout,
    TrainingSurvey,
    TrainingSurveyPage
} from '@containers'
import {
    ContentCreatorStatus,
    CourseStatus,
    TopicsStatus,
    TraineeListStatus,
    VideoStatus
} from '@constants'
import { ObjectBaseFields, SvgProps } from '@types'
import { useMediaQuery } from '@mui/material'
import { getAccessRoles } from '@utils'
import { AdminTopicsLayout } from '@containers/topics/layout/topics'
import { AdminCoursesLayout } from '@containers/courses/layout'
import { useCustomTheme } from '@hooks'
import { UserPermissionEnum } from '@dts/constants'
import { getClientRolesRoutes, getClientsRoutes } from './clients'
import { getVerticalRoutes } from './verticals'
import { getGrsAdminsRoutes } from './grs-admins'
import { getLearnerProfileRoutes, getLearnerRoutes } from './learners'
import { getTrainingsRoutes } from './trainings'
import { getClientAdminsUsersRoutes } from './client-admins'
import { getCreatorProfileRoutes } from './creator-profile'
import { getBrowseVideoRoutes } from './browse-videos'
import { getKnowledgeCenterRoutes } from './knowledge-center'
import { getSurveyRoutes } from './surveys'
import { getLearnRoutes } from './learning-center'
import { getReportRoutes } from './reports'
import { getContentCreatorRoutes } from './content-creator'

export type CustomRoute = {
    path: string
    component: () => JSX.Element
    label: string
    isHeaderLink: boolean
    isSideNav: boolean
    icon: React.FC<SvgProps>
    canUserAccess: boolean
    subRoutes?: Array<CustomRoute>
    group?: string
    hidePageHeader?: boolean
}

const isMediumScreen = () =>
    useMediaQuery((theme) => theme.breakpoints.down('md'))

export const getPublicRoutes = () => [
    {
        path: '/',
        Component: LoginAsPage
    },
    {
        path: '/system-down',
        Component: SystemDownPage
    },
    {
        path: '/unauthorized',
        Component: AccessDeniedPage
    },
    {
        path: '/complete-profile',
        Component: SignupPage
    },
    {
        path: '/verify',
        Component: CustomConfirmation
    },
    {
        path: '/reset',
        Component: CustomConfirmation
    },
    {
        path: 'system-down',
        component: SystemDownPage,
        canUserAccess: true,
        isHeaderLink: false,
        isSideNav: false
    }
]

// eslint-disable-next-line complexity
export const getUserRoutes = (
    roles: Array<ObjectBaseFields>,
    adminPortalOnlyAccess?: boolean
) => {
    const accessedRoles = getAccessRoles(roles, adminPortalOnlyAccess)
    const {
        isManager,
        isAdmin,
        isClientAdmin,
        isLearner,
        isOnlyContentCreator,
        isContainContentCreator,
        isClientUser,
        isModerator,
        isCoordinator,
        isDefaultTenantUser
    } = accessedRoles
    const isContentCreator = accessedRoles?.isContentCreator

    return [
        {
            path: 'dashboard',
            component: DashboardPage,
            label: isManager
                ? 'sideNavItems.dashboard'
                : 'sideNavItems.insights',
            isHeaderLink: false,
            isSideNav: true,
            icon: isManager ? DashboardIcon : BarChartIcon,
            canUserAccess: !adminPortalOnlyAccess,
            isBottomNav: true,
            permissions: [
                UserPermissionEnum.DashboardView,
                UserPermissionEnum.InsightView
            ]
        },
        {
            path: 'video-insights/:videoId',
            component: ControlledVideoInsight,
            label: '',
            isHeaderLink: false,
            isSideNav: false,
            canUserAccess: !adminPortalOnlyAccess,
            isBottomNav: false,
            permissions: [
                UserPermissionEnum.DashboardView,
                UserPermissionEnum.InsightView
            ]
        },
        {
            path: 'videos',
            component: VideosLayout,
            label: 'sideNavItems.videos',
            isHeaderLink: false,
            isSideNav: true,
            icon: VideoApprovalIcon,
            canUserAccess: isAdmin,
            subRoutes: [...getAdminVideoRoutes(roles)],
            isBottomNav: isAdmin,
            // to-do: add permissions
            permissions: [
                UserPermissionEnum.VideoAdminApprove,
                UserPermissionEnum.VideoModeratorApprove,
                UserPermissionEnum.VideoCoordinatorApprove
            ]
        },
        {
            path: 'trainings',
            component: TrainingsLayout,
            label: 'sideNavItems.trainings',
            isHeaderLink: false,
            isSideNav: true,
            canUserAccess: isClientAdmin,
            icon: DocumentFoldIcon,
            subRoutes: [...getTrainingsRoutes(roles)],
            permissions: [UserPermissionEnum.TrainingsView]
        },
        {
            path: 'learner-list',
            component: LearnersLayout,
            label: 'general.learners',
            isHeaderLink: false,
            isSideNav: true,
            icon: CourseIcon,
            canUserAccess: isClientAdmin,
            subRoutes: [...getLearnerRoutes(isClientAdmin)],
            permissions: [UserPermissionEnum.LearnersView]
        },
        {
            path: 'create-report',
            component: ReportingPage,
            label: 'general.reports',
            isHeaderLink: false,
            isSideNav: true,
            icon: MetricIcon,
            canUserAccess: isClientAdmin,
            isBottomNav: isClientAdmin,
            permissions: [UserPermissionEnum.ReportCreateModify]
        },
        {
            path: 'reports',
            component: ReportsLayout,
            label: 'general.reportLibraries',
            isHeaderLink: false,
            isSideNav: true,
            icon: FolderIcon,
            canUserAccess: isClientAdmin,
            subRoutes: [...getReportRoutes(roles)],
            permissions: [UserPermissionEnum.ReportView]
        },
        {
            path: 'browse-videos',
            component: BrowseVideosLayout,
            label: 'sideNavItems.browse',
            isHeaderLink: false,
            isSideNav: true,
            icon: VideoPlayerIcon,
            canUserAccess: isContentCreator,
            isBottomNav:
                isContainContentCreator ||
                isContentCreator ||
                isAdmin ||
                isClientAdmin ||
                isClientUser,
            subRoutes: [...getBrowseVideoRoutes(roles)],
            permissions: [UserPermissionEnum.VideoBrowse]
        },
        {
            path: 'my-trainings',
            component: RouteLayout,
            label: 'general.myTrainings',
            isHeaderLink: false,
            isSideNav: true,
            icon: VideoPlayerIcon,
            canUserAccess: isLearner,
            isBottomNav: isLearner,
            subRoutes: [...getMyTrainingsRoutes(roles)],
            permissions: [UserPermissionEnum.TrainingsAssignedView]
        },
        {
            path: 'courses',
            component: AdminCoursesLayout,
            label: 'sideNavItems.courses',
            isHeaderLink: false,
            isSideNav: true,
            icon: CourseIcon,
            canUserAccess: isAdmin || isDefaultTenantUser,
            subRoutes: [...getCoursesSubRoutes(roles)],
            permissions: [UserPermissionEnum.CourseView]
        },
        {
            path: 'global',
            component: GlobalSearchPage,
            isHeaderLink: false,
            isSideNav: false,
            canUserAccess: true
        },
        {
            path: 'verticals',
            component: VerticalsPage,
            label: 'sideNavItems.verticals',
            isHeaderLink: false,
            isSideNav: true,
            icon: CategoryIcon,
            canUserAccess: isAdmin || isDefaultTenantUser,
            subRoutes: [...getVerticalRoutes(roles)],
            permissions: [UserPermissionEnum.VerticalView]
        },
        {
            path: 'surveys',
            component: SurveyLayout,
            label: 'sideNavItems.surveys',
            isHeaderLink: false,
            isSideNav: true,
            icon: DocumentQuestionMarkIcon,
            canUserAccess: isAdmin || isDefaultTenantUser,
            subRoutes: [...getSurveyRoutes(roles)],
            permissions: [UserPermissionEnum.SurveyView]
        },
        {
            path: 'topics',
            component: AdminTopicsLayout,
            label: 'sideNavItems.topics',
            isHeaderLink: false,
            isSideNav: true,
            icon: TopicsIcon,
            canUserAccess: isAdmin || isDefaultTenantUser,
            subRoutes: [...getTopicsSubRoutes(roles)],
            permissions: [UserPermissionEnum.TopicView]
        },
        {
            path: 'learn',
            component: RouteLayout,
            label: 'sideNavItems.learn',
            isHeaderLink: false,
            isSideNav: true,
            icon: ReadingIcon,
            canUserAccess:
                isOnlyContentCreator ||
                isContainContentCreator ||
                isLearner ||
                isClientAdmin,
            isBottomNav: isOnlyContentCreator,
            subRoutes: [
                ...getLearnRoutes(
                    isOnlyContentCreator ||
                        isContainContentCreator ||
                        isLearner ||
                        isClientAdmin
                )
            ],
            permissions: [UserPermissionEnum.LearnView]
        },
        {
            path: 'knowledge-center',
            component: RouteLayout,
            label: 'sideNavItems.knowledge.center',
            isHeaderLink: false,
            isSideNav: true,
            icon: ReadingIcon,
            canUserAccess: isAdmin || isDefaultTenantUser,
            isBottomNav: false,
            subRoutes: [
                ...getKnowledgeCenterRoutes(isAdmin || isDefaultTenantUser)
            ],
            permissions: [UserPermissionEnum.ArticleView]
        },
        {
            path: 'resources',
            component: ResourcesPage,
            label: 'sideNavItems.resources',
            isHeaderLink: false,
            isSideNav: true,
            icon: ResourcesIcon,
            canUserAccess:
                isLearner || isClientAdmin || isDefaultTenantUser || isAdmin,
            hasDivider: true,
            // Ignore this change for now
            isBottomNav: !isContentCreator && !adminPortalOnlyAccess,
            permissions: [UserPermissionEnum.ResourcesView]
        },
        {
            path: 'roles',
            component: RouteLayout,
            canUserAccess: isClientAdmin,
            isHeaderLink: false,
            isSideNav: true,
            label: 'general.roles',
            subRoutes: [...getClientRolesRoutes(roles)],
            icon: UserCheckIcon,
            permissions: [UserPermissionEnum.RolesView]
        },

        {
            path: 'users',
            component: RouteLayout,
            label: 'general.users',
            isHeaderLink: false,
            isSideNav: true,
            icon: UsersIcon,
            canUserAccess: isClientAdmin,
            subRoutes: [...getClientAdminsUsersRoutes(roles)],
            permissions: [UserPermissionEnum.UserView]
        },
        {
            path: 'clients',
            component: RouteLayout,
            label: 'sideNavItems.clients',
            isHeaderLink: false,
            isSideNav: true,
            canUserAccess: isAdmin || isDefaultTenantUser,
            icon: BuildingsIcon,
            subRoutes: [...getClientsRoutes(roles)],
            permissions: [UserPermissionEnum.ClientView]
        },
        {
            path: 'content-creators',
            component: ContentCreatorsLayout,
            label: 'sideNavItems.creators',
            isHeaderLink: false,
            isSideNav: true,
            icon: UsersIcon,
            canUserAccess: isAdmin || isDefaultTenantUser,
            subRoutes: [...getContentCreatorRoutes(roles)],
            permissions: [UserPermissionEnum.CreatorView]
        },
        {
            path: 'admins',
            component: RouteLayout,
            label: 'sideNavItems.admins',
            isHeaderLink: false,
            isSideNav: true,
            canUserAccess: isAdmin || isDefaultTenantUser,
            icon: EditUserIcon,
            hasDivider: true,
            subRoutes: [...getGrsAdminsRoutes(roles)],
            permissions: [UserPermissionEnum.AdminView]
        },
        {
            path: 'support',
            component: SupportPage,
            label: 'sideNavItems.support',
            isHeaderLink: false,
            isSideNav: true,
            icon: SupportIcon,
            canUserAccess: isAdmin
        },
        {
            path: 'notifications',
            component: NotificationsPage,
            label: 'sideNavItems.notifications',
            isHeaderLink: false,
            isSideNav: false,
            canUserAccess: true,
            icon: NotificationsIcon
        },
        {
            path: 'settings',
            component: SettingsPage,
            label: 'sideNavItems.settings',
            isHeaderLink: false,
            isSideNav: true,
            canUserAccess: !adminPortalOnlyAccess,
            icon: SettingsIcon,
            subRoutes: [...getSettingsRoutes()],
            isBottomNav: !isContentCreator && isLearner
        },
        {
            path: 'my-account',
            component: RouteLayout,
            label: 'sideNavItems.myAccount',
            isHeaderLink: false,
            isSideNav: true,
            icon: UserIcon,
            canUserAccess: !adminPortalOnlyAccess,
            subRoutes: isLearner
                ? [...getLearnerProfileRoutes(roles)]
                : [...getCreatorVideoSubRoutes(roles)],
            isBottomNav: true
        },
        // {
        //     path: 'portal-client',
        //     component: PortalClient,
        //     label: 'sideNavItems.portalClient',
        //     isHeaderLink: false,
        //     isSideNav: true,
        //     icon: TopicsIcon,
        //     canUserAccess: isManager
        // }
        {
            path: 'view-profile',
            component: UserProfileViewPage,
            canUserAccess: true,
            isHeaderLink: false,
            isSideNav: false
        },
        {
            path: 'edit-profile',
            component: UserProfileEditPage,
            isHeaderLink: false,
            canUserAccess: true
        },
        {
            path: 'session-timeout',
            component: SessionTimeout,
            canUserAccess: true,
            isHeaderLink: false,
            isSideNav: false
        },
        // Will be removed after testing
        // {
        //     path: 'complete-profile',
        //     component: SignupPage,
        //     canUserAccess: false,
        //     isHeaderLink: false,
        //     isSideNav: false
        // },
        // {
        //     path: '/videos/:id/review',
        //     component: ReviewVideoContainer,
        //     label: 'sideNavItems.videos',
        //     isHeaderLink: false,
        //     isSideNav: false,
        //     canUserAccess: isAdmin
        // },
        // {
        //     path: '/videos/:id/view',
        //     component: ViewVideoContainer,
        //     label: 'sideNavItems.videos',
        //     isHeaderLink: false,
        //     isSideNav: false,
        //     canUserAccess: isAdmin
        // },
        // {
        //     path: 'browse-videos/:id?',
        //     component: BrowseVideosPage,
        //     label: 'general.browse',
        //     isHeaderLink: false,
        //     isSideNav: false,
        //     canUserAccess: true
        // },
        // to-do: remove view-admin/:id after testing
        // {
        //     path: '/view-admin/:id',
        //     component: ViewAdminContainer,
        //     label: 'sideNavItems.videos',
        //     isHeaderLink: false,
        //     isSideNav: false,
        //     canUserAccess: isAdmin
        // },
        // {
        //     path: '/browse-videos-by-topic/:id?',
        //     component: VideosByTopic,
        //     label: 'sideNavItems.videos',
        //     isHeaderLink: false,
        //     isSideNav: false,
        //     canUserAccess: true
        // },
        // {
        //     path: 'learning-center',
        //     component: RouteLayout,
        //     isHeaderLink: false,
        //     isSideNav: false,
        //     canUserAccess: isOnlyContentCreator || isContainContentCreator,
        //     subRoutes: [
        //         ...getLearningCenterRoutes(
        //             isOnlyContentCreator || isContainContentCreator
        //         )
        //     ]
        // },
        // {
        //     path: '/learning-center/:id?',
        //     component: ViewArticlePage,
        //     isHeaderLink: false,
        //     isSideNav: false,
        //     canUserAccess: isOnlyContentCreator || isContainContentCreator
        // },
        // {
        //     path: '/create-knowledge-article/:id?',
        //     component: CreateArticleContainer,
        //     isHeaderLink: false,
        //     isSideNav: false,
        //     canUserAccess: isAdmin
        // },
        // {
        //     path: '/create-course/:id?',
        //     component: CreateCourse,
        //     isHeaderLink: false,
        //     isSideNav: false,
        //     canUserAccess: isAdmin
        // },
        {
            path: '/view-article',
            component: ViewArticleContainer,
            isHeaderLink: false,
            isSideNav: false,
            canUserAccess: isAdmin
        },
        // {
        //     path: 'create-vertical/:id?',
        //     component: CreateVertical,
        //     isHeaderLink: false,
        //     isSideNav: false,
        //     canUserAccess: isAdmin
        // },
        // {
        //     path: 'verticals/:id?/edit-courses',
        //     component: EditCoursesContainer,
        //     isHeaderLink: false,
        //     isSideNav: false,
        //     canUserAccess: isAdmin
        // },
        {
            path: 'creator-profile/:creatorId',
            component: CreatorProfilePage,
            label: 'Creator Profile',
            isHeaderLink: false,
            isSideNav: false,
            icon: UserIcon,
            canUserAccess: true,
            subRoutes: [...getCreatorProfileRoutes(roles)],
            isBottomNav: false
        },
        {
            path: 'creator-profile/:creatorId/browse-videos-by-topic/:id?',
            component: VideosByTopic,
            label: 'sideNavItems.videos',
            isHeaderLink: false,
            isSideNav: false,
            canUserAccess: true
        },
        {
            path: 'portal-client',
            label: 'Clients',
            component: PortalClient,
            isHeaderLink: true,
            isSideNav: true,
            hasViewAll: true,
            canUserAccess: adminPortalOnlyAccess,
            icon: DashboardIcon
        }
    ]
}

export const getTraineeListRoutes = (roles: Array<ObjectBaseFields>) => {
    const accessedRoles = getAccessRoles(roles)
    const isManager = accessedRoles?.isManager
    return [
        {
            path: '',
            component: TraineesListPage,
            componentProps: { status: TraineeListStatus.All },
            canUserAccess: isManager
        },
        {
            path: 'all',
            component: TraineesListPage,
            componentProps: { status: TraineeListStatus.All },
            label: 'general.all',
            isLanding: 'trainee-list',
            isHeaderLink: true,
            canUserAccess: isManager,
            icon: HourGlassIcon
        },
        {
            path: 'active',
            component: TraineesListPage,
            componentProps: { status: TraineeListStatus.Active },
            label: 'general.active',
            isHeaderLink: true,
            canUserAccess: isManager,
            icon: LaunchIcon
        },
        {
            path: 'archived',
            component: TraineesListPage,
            componentProps: { status: TraineeListStatus.Archived },
            label: 'general.archived',
            isHeaderLink: true,
            canUserAccess: isManager,
            icon: DraftIcon
        }
    ]
}

export const getCommonNavRoutes = (roles: Array<ObjectBaseFields>) => {
    const commonNavRoutes = getUserRoutes(roles)
    return commonNavRoutes.filter(
        (item) => item.isBottomNav && item.canUserAccess
    )
}

export const getMyTrainingsRoutes = (roles: Array<ObjectBaseFields>) => {
    const accessedRoles = getAccessRoles(roles)
    const { theme } = useCustomTheme()

    return [
        {
            path: '',
            component: LearnerTrainings,
            canUserAccess: true,
            permissions: [UserPermissionEnum.TrainingsAssignedView]
        },
        {
            path: ':topicId',
            component: BrowseVideosPage,
            label: 'general.watch',
            isHeaderLink: true,
            canUserAccess: true,
            icon: () => (
                <PlayIcon
                    color={theme.palette.errorDangerMedium}
                    fill={theme.palette.errorDangerMedium}
                />
            )
        },
        {
            path: 'surveys',
            component: TrainingSurveyPage,
            canUserAccess: accessedRoles?.isLearner
        }
        // {
        //     path: 'watch',
        //     component: BrowseVideosPage,
        //     label: 'general.watch',
        //     isHeaderLink: true,
        //     canUserAccess: true,
        //     icon: () => (
        //         <PlayIcon color={colors.errorRed} fill={colors.errorRed} />
        //     )
        // }
    ]
}

// Will be used later on while refactoring
// const getPortableAdminRoutes = (roles) => [
//     {
//         path: 'portal-client',
//         label: 'Clients',
//         component: PortalClient,
//         isHeaderLink: true,
//         isSideNav: true,
//         hasViewAll: true,
//         canUserAccess: true,
//         icon: DashboardIcon
//     },
//     // To be fixed
//     {
//         path: 'edit-profile',
//         component: UserProfileEditPage,
//         isHeaderLink: false,
//         canUserAccess: true
//     },
//     {
//         path: 'my-account/edit',
//         component: UserProfileEditPage,
//         label: 'userProfile.editProfile',
//         isHeaderLink: true,
//         canUserAccess: true,
//         icon: EditUserIcon
//     }
// ]

export const getAdminVideoRoutes = (roles: Array<ObjectBaseFields>) => {
    const accessedRoles = getAccessRoles(roles)
    const isAdmin = accessedRoles?.isAdmin
    return [
        {
            path: '',
            component: ReviewVideosPage,
            componentProps: { status: VideoStatus.Pending },
            canUserAccess: isAdmin
        },
        {
            path: '/videos/pending',
            component: ReviewVideosPage,
            componentProps: { status: VideoStatus.Pending },
            label: 'general.pending',
            isLanding: 'videos',
            isHeaderLink: true,
            canUserAccess: isAdmin,
            icon: HourGlassIcon
        },
        {
            path: '/videos/approved',
            component: ReviewVideosPage,
            componentProps: { status: VideoStatus.Approved },
            label: 'general.approved',
            isHeaderLink: true,
            canUserAccess: isAdmin,
            icon: CheckCircleIcon
        },
        {
            path: '/videos/rejected',
            component: ReviewVideosPage,
            componentProps: { status: VideoStatus.Rejected },
            label: 'general.rejected',
            isHeaderLink: true,
            canUserAccess: isAdmin,
            icon: XCircleIcon
        },
        {
            path: '/videos/reported',
            component: ReviewVideosPage,
            componentProps: { status: VideoStatus.Reported },
            label: 'general.reported',
            isHeaderLink: true,
            canUserAccess: isAdmin,
            icon: ReportIcon
        },

        {
            path: '/videos/unpublished',
            component: ReviewVideosPage,
            componentProps: { status: VideoStatus.UnPublished },
            label: 'general.unpublished',
            isHeaderLink: true,
            canUserAccess: isAdmin,
            icon: RocketDown
        },
        {
            path: ':id',
            component: RouteLayout,
            label: 'sideNavItems.videos',
            isHeaderLink: false,
            isSideNav: false,
            icon: VideoApprovalIcon,
            canUserAccess: isAdmin,
            subRoutes: [...getAdminReviewRoutes(roles)]
        }
    ]
}

export const getAdminReviewRoutes = (roles) => {
    const accessedRoles = getAccessRoles(roles)
    const isAdmin = accessedRoles?.isAdmin
    return [
        {
            path: '',
            component: ViewVideoContainer,
            componentProps: { status: VideoStatus.Pending },
            canUserAccess: isAdmin
        },
        {
            path: 'review',
            component: ReviewVideoContainer,
            label: 'sideNavItems.videos',
            isHeaderLink: false,
            isSideNav: false,
            canUserAccess: isAdmin
        }
        // {
        //     path: 'view',
        //     component: ViewVideoContainer,
        //     label: 'sideNavItems.videos',
        //     isHeaderLink: false,
        //     isSideNav: false,
        //     canUserAccess: isAdmin
        // }
    ]
}

export const getUserAccountRoutes = (roles: Array<ObjectBaseFields>) => {
    const accessedRoles = getAccessRoles(roles)
    const isLearner = accessedRoles?.isLearner
    const { theme } = useCustomTheme()
    return [
        {
            path: '',
            component: CreatorVideosPage,
            componentProps: { status: VideoStatus.Pending },
            canUserAccess: !isLearner
        },
        {
            path: 'pending',
            component: CreatorVideosPage,
            componentProps: { status: VideoStatus.Pending },
            label: 'general.pending',
            isLanding: 'my-account',
            isHeaderLink: true,
            canUserAccess: !isLearner,
            icon: HourGlassIcon
        },
        {
            path: 'rejected',
            component: CreatorVideosPage,
            componentProps: { status: VideoStatus.Rejected },
            label: 'general.rejected',
            isHeaderLink: true,
            canUserAccess: !isLearner,
            icon: XCircleIcon
        },
        {
            path: 'drafts',
            component: CreatorVideosPage,
            componentProps: { status: VideoStatus.Draft },
            label: 'general.drafts',
            isHeaderLink: true,
            canUserAccess: !isLearner,
            icon: DraftIcon
        },
        {
            path: 'published',
            component: CreatorVideosPage,
            componentProps: { status: VideoStatus.Approved },
            label: 'general.published',
            isHeaderLink: true,
            canUserAccess: !isLearner,
            icon: LaunchIcon
        },
        {
            path: 'unpublished',
            component: CreatorVideosPage,
            componentProps: { status: VideoStatus.UnPublished },
            label: 'general.unpublished',
            isHeaderLink: true,
            canUserAccess: !isLearner,
            icon: RocketDown
        }
    ]
}

export const getCreatorVideoSubRoutes = (roles: Array<ObjectBaseFields>) => {
    const accessedRoles = getAccessRoles(roles)
    const isLearner = accessedRoles?.isLearner
    return [
        {
            path: '',
            component: UserProfilePage,
            canUserAccess: !isLearner,
            subRoutes: [...getUserAccountRoutes(roles)]
        },
        {
            path: 'edit',
            component: UserProfileEditPage,
            label: 'userProfile.editProfile',
            isHeaderLink: true,
            canUserAccess: !isLearner,
            icon: EditUserIcon
        },
        {
            path: ':id',
            component: ViewVideoContainer,
            canUserAccess: !isLearner
        }
    ]
}

export const getTopicsSubRoutes = (roles: Array<ObjectBaseFields>) => {
    const accessedRoles = getAccessRoles(roles)
    const isLearner = accessedRoles?.isLearner
    return [
        {
            path: '',
            component: TopicsPage,
            componentProps: { status: TopicsStatus.all.toLocaleLowerCase() },
            icon: CheckCircleIcon,
            canUserAccess: !isLearner,
            permissions: [UserPermissionEnum.TopicView]
        },
        {
            path: 'all',
            component: TopicsPage,
            componentProps: { status: TopicsStatus.all.toLocaleLowerCase() },
            label: 'general.all',
            icon: CheckCircleIcon,
            isLanding: 'topics',
            isHeaderLink: true,
            canUserAccess: !isLearner,
            permissions: [UserPermissionEnum.TopicView]
        },
        {
            path: 'archived',
            component: TopicsPage,
            componentProps: {
                status: TopicsStatus.archive.toLocaleLowerCase()
            },
            icon: ArchiveIcon,
            label: 'general.archived',
            isHeaderLink: true,
            canUserAccess: !isLearner,
            permissions: [UserPermissionEnum.TopicView]
        }
    ]
}

export const getCoursesSubRoutes = (roles: Array<ObjectBaseFields>) => {
    const accessedRoles = getAccessRoles(roles)
    const isLearner = accessedRoles?.isLearner
    const { isAdmin, isClientAdmin } = accessedRoles
    return [
        {
            path: '',
            component: CoursesPage,
            componentProps: { status: CourseStatus.all },
            icon: CheckCircleIcon,
            canUserAccess: !isLearner,
            permissions: [UserPermissionEnum.CourseView]
        },
        {
            path: 'all',
            component: CoursesPage,
            componentProps: { status: CourseStatus.all },
            label: 'general.all',
            isLanding: 'courses',
            isHeaderLink: true,
            icon: CheckCircleIcon,
            canUserAccess: !isLearner,
            permissions: [UserPermissionEnum.CourseView]
        },
        {
            path: 'archived',
            component: CoursesPage,
            componentProps: { status: CourseStatus.archive },
            label: 'general.archived',
            isHeaderLink: true,
            canUserAccess: !isLearner,
            icon: ArchiveIcon,
            permissions: [UserPermissionEnum.CourseView]
        },
        {
            path: 'new',
            component: CreateCourse,
            isHeaderLink: false,
            isSideNav: false,
            canUserAccess: isAdmin || isClientAdmin,
            hidePageHeader: true
        },
        {
            path: ':id',
            component: CreateCourse,
            isHeaderLink: false,
            isSideNav: false,
            canUserAccess: isAdmin || isClientAdmin,
            hidePageHeader: true
        }
    ]
}

export const getSettingsRoutes = () => [
    {
        path: '',
        component: isMediumScreen?.() ? SettingsMenu : SavedVideos,
        canUserAccess: true
    },
    {
        path: 'saved',
        component: SavedVideos,
        label: 'setting.saved',
        isLanding: !isMediumScreen() ? '/settings' : undefined,
        isHeaderLink: true,
        canUserAccess: true,
        icon: SaveIcon,
        group: 'setting.activity'
    },
    {
        path: 'liked',
        component: LikedVideos,
        label: 'setting.liked',
        isHeaderLink: true,
        canUserAccess: true,
        icon: LikeIcon,
        group: 'setting.activity'
    },
    {
        path: 'following-accounts',
        component: FollowingAccounts,
        label: 'setting.following',
        isHeaderLink: true,
        canUserAccess: true,
        icon: FollowIcon,
        group: 'setting.activity'
    },
    {
        path: 'muted-accounts',
        component: MutedAccounts,
        label: 'setting.mutedAccounts',
        isHeaderLink: true,
        canUserAccess: true,
        icon: HideIcon,
        group: 'setting.activity'
    },
    {
        path: 'notification-preferences',
        component: NotificationsSetting,
        label: 'sideNavItems.notifications',
        isHeaderLink: true,
        canUserAccess: true,
        icon: NotificationsIcon,
        group: 'setting.preferences'
    },
    // Post production feature
    // {
    //     path: 'language',
    //     component: LanguageSetting,
    //     label: 'setting.languages',
    //     isHeaderLink: true,
    //     canUserAccess: true,
    //     icon: GlobeIcon,
    //     group: 'setting.preferences'
    // },
    {
        path: 'captions',
        component: CaptionsSetting,
        label: 'general.captions',
        isHeaderLink: true,
        canUserAccess: true,
        icon: CaptionsIcon,
        group: 'setting.preferences'
    },
    {
        path: 'display',
        component: DisplaySetting,
        label: 'setting.display',
        isHeaderLink: true,
        canUserAccess: true,
        icon: HalfMoonIcon,
        group: 'setting.preferences'
    },
    {
        path: 'help-center',
        component: HelpCenter,
        label: 'setting.helpCenter',
        isHeaderLink: true,
        canUserAccess: true,
        icon: QuestionMarkIcon,
        group: 'setting.help'
    },
    {
        path: 'report-issue',
        component: ReportIssuePage,
        label: 'setting.reportProblem',
        isHeaderLink: true,
        canUserAccess: true,
        icon: ReportIssueIcon,
        group: 'setting.help'
    }
]
