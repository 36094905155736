import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const CategoryIcon: React.FC<SvgProps> = ({
    color,
    height = '24',
    width = '24',
    fill = 'none'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 24 24`}
            fill={fill}
            fillOpacity='0.1'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect
                x='2'
                y='2'
                width='8.37211'
                height='8.37211'
                rx='2'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <rect
                x='2'
                y='13.6279'
                width='8.37211'
                height='8.37211'
                rx='2'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <rect
                x='13.6276'
                y='2'
                width='8.37211'
                height='8.37211'
                rx='2'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <rect
                x='13.6276'
                y='13.6279'
                width='8.37211'
                height='8.37211'
                rx='2'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default CategoryIcon
