import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { PUBLIC_CLIENT_ID } from '@constants'
import { ContentCreatorProfileForm } from '@containers'
import { GET_USER_BY_ID, SAVE_USER } from '@dts/graphql'
import { useFormFeedback, usePage } from '@hooks'
import { UserPermissionEnum } from '@dts/constants'

export const EditContentCreatorContainer: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { id: contentCreatorId } = useParams()

    const { methods, save } = useFormFeedback({
        permissions: [UserPermissionEnum.CreatorCreateModify],
        initialValues: {
            email: null,
            firstName: null,
            lastName: null,
            officeNumber: null,
            avatarSrc: null,
            fileUploadKey: null,
            roleId: null,
            active: false
        },
        mapFormToMutationInput: ({
            firstName,
            lastName,
            email,
            officeNumber,
            fileUploadKey,
            active,
            roleId
        }) => ({
            input: {
                id: contentCreatorId || undefined,
                firstName,
                lastName,
                email,
                phoneNumber: officeNumber,
                avatar: fileUploadKey,
                adminPortalOnlyAccess: false,
                clientId: PUBLIC_CLIENT_ID,
                roleId,
                active
            }
        }),
        mutation: SAVE_USER,
        mutationName: 'saveUser',
        isCustomMutationInput: true,
        onMutationSuccess: () => {
            navigate('/content-creators')
        },
        query: GET_USER_BY_ID,
        queryName: 'getUserById',
        retrieveOnMount: true,
        queryVariables: { id: contentCreatorId },
        mapQueryResultToForm: ({
            email,
            firstName,
            lastName,
            phoneNumber,
            avatarSrc,
            roles,
            active
        }) => ({
            firstName,
            lastName,
            email,
            officeNumber: phoneNumber,
            avatarUpload: avatarSrc,
            roleId: roles?.[0]?.id,
            active
        })
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: ContentCreatorProfileForm,
            componentProps: { contentCreatorId },
            hasHeader: true,
            preventMargin: true,
            showBreadcrumbs: true,
            pageHeaderConfig: {
                headerText: t('userProfile.editProfile'),
                primaryButtonTitle: t('general.save'),
                primaryButtonAction: () => methods.handleSubmit(() => save())(),
                tertiaryButtonTitle: t('general.cancel'),
                tertiaryButtonAction: () => {
                    navigate('/content-creators')
                },
                permissions: [UserPermissionEnum.CreatorCreateModify]
            }
        }
    })

    return <>{page}</>
}
