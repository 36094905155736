import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import {
    ControlledMultiSelectAutoComplete as ControlledAutoComplete,
    ControlledSelect,
    ControlledTextInput,
    ControlledUploadVideo,
    ListInput,
    SearchableTags,
    KeyFrames
} from '@components'
import { Grid, LinearProgress, Typography } from '@mui/material'
import { HelperTextPosition, ListType } from '@constants'
import { useCustomTheme, useVideoCaptions } from '@hooks'
import { mapDropdownOptions } from '@dts/client-utils'
import { isEmpty } from 'lodash'

export const CreateVideo = () => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()

    const { getValues, control } = useFormContext()
    const { reviews } = getValues()
    const captionText = useWatch({ control, name: 'captionText' })
    const url = useWatch({ control, name: 'url' })
    const { getVideoDropDownsData } = useVideoCaptions()

    return (
        <Grid container sx={{ padding: '2%' }}>
            {reviews?.length ? (
                <Grid
                    item
                    xs={12}
                    md={12}
                    mb={2}
                    p={2}
                    sx={{
                        background: theme.palette.backgroundNegativeSubtle,
                        borderRadius: '5px',
                        borderTop: `2px solid ${theme.palette.contentNegative}`
                    }}
                >
                    <Grid item>
                        <Typography variant='body2'>
                            {reviews?.[0]?.createdAt}
                        </Typography>
                    </Grid>
                    <Grid item py={0.5}>
                        <Typography variant='h3'>
                            {reviews?.[0]?.title}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='body2'>
                            {reviews?.[0]?.details}
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <></>
            )}

            <Grid item xs={12} md={12} mb={2}>
                <ControlledUploadVideo name='url' />
            </Grid>
            <Grid container>
                <Grid
                    item
                    display={'flex'}
                    xs={12}
                    md={12}
                    direction='column'
                    gap={4}
                >
                    <Grid item>
                        <Typography variant='h3'>
                            {t('general.about')}
                        </Typography>
                        <ControlledTextInput
                            id='title'
                            label={t('general.title')}
                            name='title'
                            multiline
                            rows={3}
                            helperTextConfig={{
                                wordLength: 200,
                                helperTextPosition: HelperTextPosition.Right
                            }}
                            fullWidth
                        />
                        <ControlledTextInput
                            id='description'
                            label={t('general.description')}
                            name='description'
                            multiline
                            rows={3}
                            helperTextConfig={{
                                wordLength: 1000,
                                helperTextPosition: HelperTextPosition.Right
                            }}
                            fullWidth
                        />
                        <ControlledTextInput
                            id='captionText'
                            label={t('general.captions')}
                            name='captionText'
                            placeholder={t('video.captions.placeholder')}
                            multiline
                            rows={6}
                            fullWidth
                            disabled={!captionText}
                        />
                        {isEmpty(captionText) && !isEmpty(url) && (
                            <LinearProgress />
                        )}
                    </Grid>
                    <Grid item>
                        <Typography variant='h3' sx={{ marginBottom: '10px' }}>
                            {t('createVideos.modal.component.coverPhoto.label')}
                        </Typography>
                        <KeyFrames name='thumbnail' />
                    </Grid>
                    <Grid item>
                        <Typography variant='h3'>
                            {t('createVideos.modal.component.moreInfo.label')}
                        </Typography>
                        <ControlledAutoComplete
                            name='topicIds'
                            label={t(
                                'createVideos.modal.component.topic.label'
                            )}
                            placeholder={t(
                                'createVideos.modal.component.topic.placeholder'
                            )}
                            id='category'
                            options={mapDropdownOptions(
                                getVideoDropDownsData?.data?.topics
                            )}
                        />
                        <ControlledSelect
                            name='skillId'
                            label={t(
                                'createVideos.modal.component.skillLevel.label'
                            )}
                            placeholder={t(
                                'createVideos.modal.component.skillLevel.placeholder'
                            )}
                            id='skills'
                            options={mapDropdownOptions(
                                getVideoDropDownsData?.data?.skills
                            )}
                        />
                    </Grid>
                    {/* To be used in future */}
                    {/* <Grid item mb={2}>
                        <ControlledAutoComplete
                            name='stateIds'
                            label={t(
                                'createVideos.modal.component.states.label'
                            )}
                            placeholder={t(
                                'createVideos.modal.component.states.placeholder'
                            )}
                            id='states'
                            options={mapDropdownOptions(
                                getVideoDropDownsData?.data?.['states']
                            )}
                        />
                    </Grid>
                    <Grid item mb={2}>
                        <ControlledAutoComplete
                            name='schoolIds'
                            label={t(
                                'createVideos.modal.component.schools.label'
                            )}
                            placeholder={t(
                                'createVideos.modal.component.schools.placeholder'
                            )}
                            id='schools'
                            options={[]}
                        />
                    </Grid> */}
                    <Grid item>
                        <Typography variant='h3'>
                            {t('createVideos.modal.component.links.label')}
                        </Typography>
                        <ListInput
                            name='hyperlinks'
                            label={t(
                                'createVideos.modal.component.links.label'
                            )}
                            placeholder={t(
                                'createVideos.modal.component.links.placeholder'
                            )}
                            id='list'
                            listType={ListType.List}
                            maxElements={3}
                            isLink
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant='h3'>
                            {t('createVideos.modal.component.tags.label')}
                        </Typography>
                        <SearchableTags name='tags' customDataName='tags' />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
