import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const SquareIcon: React.FC<SvgProps> = ({ color, width = 24, height = 24 }) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V5Z'
                stroke={_color}
                strokeWidth='2'
            />
        </svg>
    )
}
export default SquareIcon
