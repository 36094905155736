import * as _ from 'lodash'

import {
  AveragedDataResponse,
  GranularityMap,
  Metric,
  MetricBreakdownTypeData,
  ReportMetricTypeData,
} from '@types'
import {
  DATE_TIME_SETTING,
  MetricValueTypeEnum,
  ReportBreakdownEnum,
  ReportGranularityGraphqlEnum,
  ReportMetricEnum,
  ReportMetricTypeEnum,
} from '@dts/constants'
import moment, { unitOfTime } from 'moment'

/**
 * The function `getPermissibleBreakdowns` filters `metricBreakdowns` based on `reportMetricId` and
 * returns an array of `reportBreakdownId`s.
 * @param metricBreakdowns - Array of metric breakdowns
 * @param reportMetricId - string
 * @returns Array of permissible breakdown ids
 */
export const getPermissibleBreakdowns = (metricBreakdowns, reportMetricId) => {
  const breakdownIds = metricBreakdowns
    .filter(
      (metricBreakdown) => metricBreakdown.reportMetricId === reportMetricId,
    )
    ?.map((item) => item.reportBreakdownId)
  return breakdownIds
}

/**
 * Calculates and formats chart table data based on metrics and trainings.
 *
 * @param {Metric[]} metricData - Array of metrics.
 * @param {Training[]} trainings - Array of trainings.
 * @param {string[]} breakdownIds - Array of IDs for data breakdown (e.g., by interests, training, age).
 * @returns {AveragedDataResponse[]} - Array of objects with averaged data values for chart table.
 */
export const calculateChartData = (
  metricData: Metric[],
  trainings: ReportMetricTypeData[],
  surveys: ReportMetricTypeData[],
  tenantCourses: ReportMetricTypeData[],
  metricBreakdowns: MetricBreakdownTypeData[],
  metricGranularity: ReportGranularityGraphqlEnum,
  generateDataPointTill: string,
) => {
  // Convert user date of birth to age in each metricData item
  metricData.forEach(
    (item) =>
      (item.user.age = item.user?.dateOfBirth
        ? `${calculateAge(item.user.dateOfBirth)}`
        : null),
  )

  // Group metricData items based on the breakdownIds
  const groupedItemData = metricData.reduce((groupedData, metricDataObj) => {
    let items = []
    let itemId = null

    // check for valid breakdowns
    const permissibleBreakdowns = getPermissibleBreakdowns(
      metricBreakdowns,
      metricDataObj.reportMetricId,
    )

    switch (metricDataObj.reportMetricTypeId) {
      case ReportMetricTypeEnum.Training:
        items = trainings
        itemId = metricDataObj.trainingId
        break
      case ReportMetricTypeEnum.Course:
        items = tenantCourses
        itemId = metricDataObj.courseId
        break
      case ReportMetricTypeEnum.Survey:
        items = surveys
        itemId = metricDataObj.surveyId
        break
      default:
        break
    }

    // fetch the training | course | survey
    const matchedItem = items.find((itemData) => itemData.id === itemId)

    if (!matchedItem) {
      return groupedData
    }

    const key = generateKey(
      metricDataObj,
      permissibleBreakdowns,
      metricGranularity,
    )

    const processItem = (key: string, interest?: Record<string, string>) => {
      if (!groupedData[key]) {
        groupedData[key] = {
          ...metricDataObj,
          itemTitle: matchedItem.title,
          age: metricDataObj.user.age,
          values: [
            {
              value: metricDataObj.value,
              learnerCount: matchedItem.learnerCount,
              userId: metricDataObj.userId,
              itemId,
            },
          ],
          valueCount: 1,
          permissibleBreakdowns,
          itemId,
          participatingItems: {
            [itemId]: {
              [metricDataObj.userId]: 1,
            },
          },
          ...(interest && { interest }),
        }
      } else {
        groupedData[key].values.push({
          value: metricDataObj.value,
          learnerCount: matchedItem.learnerCount,
          userId: metricDataObj.userId,
          itemId,
        })

        groupedData[key].valueCount++

        if (groupedData[key].participatingItems?.[itemId]) {
          if (
            groupedData[key].participatingItems?.[itemId]?.[
              metricDataObj.userId
            ]
          ) {
            groupedData[key].participatingItems[itemId][metricDataObj.userId]++
          } else {
            groupedData[key].participatingItems[itemId][
              metricDataObj.userId
            ] = 1
          }
        } else {
          groupedData[key].participatingItems[itemId] = {
            [metricDataObj.userId]: 1,
          }
        }
      }
    }

    if (permissibleBreakdowns.includes(ReportBreakdownEnum.Interests)) {
      // Process each interest of the user
      metricDataObj.user.interests.forEach((interest) => {
        processItem(`${key}-${interest.id}`, interest)
      })
    } else {
      processItem(key)
    }

    return groupedData
  }, {})

  // Calculate the average values for each grouped key
  const averagedData: AveragedDataResponse = Object.keys(groupedItemData)
    .map((key) => {
      const {
        reportMetricId,
        time,
        values,
        user,
        itemId,
        itemTitle,
        itemCount,
        interest,
        reportMetricTitle,
        metricValueTypeId,
        reportMetricTypeId,
        participatingItems,
        permissibleBreakdowns,
        aggregationTypeId,
      } = groupedItemData[key]

      const totalValue = values.reduce((accumulator, item) => {
        const value = item.value
        const learnerCount = item.learnerCount
        const itemId = item.itemId
        const userId = item.userId
        const isPercentage =
          metricValueTypeId === MetricValueTypeEnum.Percentage

        // divide by number of row in db for each item (rows of one user in db i.e topic)
        let calculatedValue = isPercentage
          ? Number(value) / participatingItems[itemId][userId]
          : Number(value)

        // numberOfParticipatingKeys is the number of trainings/Surveys etc
        const numberOfParticipatingKeys = Object.keys(participatingItems).length

        // if age or interest breakdown
        // divide the value by number of users participating
        // divide by number of items
        if (isPercentage) {
          if (
            permissibleBreakdowns.includes(ReportBreakdownEnum.Age) ||
            permissibleBreakdowns.includes(ReportBreakdownEnum.Interests)
          ) {
            calculatedValue =
              Number(calculatedValue) /
              Object.values(participatingItems[itemId]).length

            calculatedValue /= numberOfParticipatingKeys
          } else {
            // divide by total learner count
            // number of participating item
            calculatedValue /= learnerCount
            calculatedValue /= numberOfParticipatingKeys
          }
        }

        // Add the calculated value to the accumulator
        return accumulator + calculatedValue
      }, 0) // Initial value of accumulator is 0

      let formattedTime = null

      if (
        ![
          ReportMetricEnum.CompletionRate,
          ReportMetricEnum.ProgressionRate,
        ].includes(reportMetricId)
      ) {
        switch (metricGranularity) {
          case ReportGranularityGraphqlEnum.DLY:
          case ReportGranularityGraphqlEnum.WLY:
            formattedTime = moment(time).format(
              DATE_TIME_SETTING.DATE_FORMATS.DAY,
            ) // e.g., "Aug 14"
            break

          case ReportGranularityGraphqlEnum.HLY:
            formattedTime = moment(time).format(
              DATE_TIME_SETTING.DATE_FORMATS.HOUR,
            ) // e.g., "Aug 14, 12AM"
            break

          case ReportGranularityGraphqlEnum.MLY:
            formattedTime = moment(time).format(
              DATE_TIME_SETTING.DATE_FORMATS.MONTH,
            ) // e.g., "Aug 2024"
            break

          default:
            formattedTime = time
        }
      }

      let primaryKey = `${reportMetricId}`

      if (permissibleBreakdowns.includes(ReportBreakdownEnum.Training)) {
        primaryKey += `${itemId}`
      }

      if (permissibleBreakdowns.includes(ReportBreakdownEnum.Age)) {
        primaryKey += `-${user.age}`
      }

      if (permissibleBreakdowns.includes(ReportBreakdownEnum.Interests)) {
        primaryKey += `-${interest?.id}`
      }

      return user.age
        ? {
            reportMetricId,
            primaryKey: `${primaryKey}-${
              metricGranularity === ReportGranularityGraphqlEnum.WLY
                ? moment(time).isoWeek()
                : metricGranularity !== ReportGranularityGraphqlEnum.HLY
                ? moment(time).startOf(DATE_TIME_SETTING.UNITS.DAY as any)
                : moment(time)
            }`,
            ...(formattedTime ? { time: formattedTime } : { time }),
            value: totalValue || 0,
            age: user.age,
            interest,
            itemId,
            itemTitle,
            itemCount,
            reportMetricTitle,
            reportMetricTypeId,
            metricValueTypeId,
            aggregationTypeId,
            permissibleBreakdowns,
          }
        : null
    })
    .filter(Boolean)

  const dataPoints = []

  const granularityMap: GranularityMap = {
    [ReportGranularityGraphqlEnum.DLY]: {
      unit: DATE_TIME_SETTING.UNITS.DAY as unitOfTime.DurationConstructor,
      format: DATE_TIME_SETTING.DATE_FORMATS.DAY,
    }, // e.g., "Aug 14"
    [ReportGranularityGraphqlEnum.WLY]: {
      unit: DATE_TIME_SETTING.UNITS.WEEK as unitOfTime.DurationConstructor,
      format: DATE_TIME_SETTING.DATE_FORMATS.WEEK,
    }, // e.g., "Aug 14"
    [ReportGranularityGraphqlEnum.HLY]: {
      unit: DATE_TIME_SETTING.UNITS.HOUR as unitOfTime.DurationConstructor,
      format: DATE_TIME_SETTING.DATE_FORMATS.HOUR,
    }, // e.g., "Aug 14, 2pm"
    [ReportGranularityGraphqlEnum.MLY]: {
      unit: DATE_TIME_SETTING.UNITS.MONTH as unitOfTime.DurationConstructor,
      format: DATE_TIME_SETTING.DATE_FORMATS.MONTH,
    }, // e.g., "Aug 2024"
  }

  const granularity = granularityMap[metricGranularity]

  if (!granularity) {
    throw new Error('Invalid granularity')
  }

  averagedData.forEach((item) => {
    if (
      (
        [
          ReportMetricEnum.CompletionRate,
          ReportMetricEnum.ProgressionRate,
        ] as string[]
      ).includes(item.reportMetricId)
    ) {
      let nextSlot = moment(item.time).add(1, granularity.unit)

      const endDate = moment(generateDataPointTill).startOf(granularity.unit)

      while (nextSlot.isBefore(endDate, granularity.unit)) {
        let primaryKey = `${item.reportMetricId}`

        if (item.permissibleBreakdowns.includes(ReportBreakdownEnum.Training)) {
          primaryKey += `${item.itemId}`
        }

        if (item.permissibleBreakdowns.includes(ReportBreakdownEnum.Age)) {
          primaryKey += `-${item.age}`
        }

        if (
          item.permissibleBreakdowns.includes(ReportBreakdownEnum.Interests)
        ) {
          primaryKey += `-${item.interest?.id}`
        }

        primaryKey = `${primaryKey}-${
          granularity.unit === DATE_TIME_SETTING.UNITS.WEEK
            ? nextSlot.isoWeek()
            : granularity.unit !== DATE_TIME_SETTING.UNITS.HOUR
            ? nextSlot.startOf(DATE_TIME_SETTING.UNITS.DAY as any)
            : nextSlot
        }`

        const nextHourData = averagedData.find(
          (i) => i.primaryKey === primaryKey,
        )

        if (nextHourData) {
          break
        }

        dataPoints.push({
          ...item,
          time: nextSlot,
        })

        nextSlot = moment(nextSlot).add(1, granularity.unit)
      }

      dataPoints.push({
        ...item,
        time: moment(item.time),
      })
    } else {
      dataPoints.push({
        ...item,
      })
    }
  })

  const sortedDatePoints = _.sortBy(
    dataPoints.filter(Boolean),
    DATE_TIME_SETTING.UNITS.TIME,
  )

  const timeFormattedDataPoints = sortedDatePoints.map((item) =>
    [
      ReportMetricEnum.CompletionRate,
      ReportMetricEnum.ProgressionRate,
    ].includes(item.reportMetricId)
      ? {
          ...item,
          time: moment(item.time).format(granularity.format),
        }
      : item,
  )

  return timeFormattedDataPoints
}

/**
 * Generates a unique key for grouping data based on breakdown IDs.
 *
 * @param {object} item - The data item to generate a key for.
 * @param {string[]} breakdownIds - Array of IDs for data breakdown (e.g., by interests, training, age).
 * @returns {string} - A unique key string based on the specified breakdown criteria.
 */
const generateKey = (item, breakdownIds, metricGranularity) => {
  let time = ''
  switch (metricGranularity) {
    case ReportGranularityGraphqlEnum.HLY:
      time = item.time
      break

    case ReportGranularityGraphqlEnum.DLY:
      time = `${item.time.getFullYear()}-${item.time.getMonth()}-${item.time.getDate()}`
      break

    case ReportGranularityGraphqlEnum.WLY:
      time = `${item.time.getFullYear()}-${moment(item.time).week()}`
      break

    case ReportGranularityGraphqlEnum.MLY:
      time = `${item.time.getFullYear()}-${item.time.getMonth()}`
      break

    default:
      throw new Error('Invalid granularity')
  }

  let key = `${item.reportMetricId}-${time}`

  if (breakdownIds.includes(ReportBreakdownEnum.Training)) {
    key += `-${item.trainingId}`
  }

  if (breakdownIds.includes(ReportBreakdownEnum.Age)) {
    key += `-${item.user.age}`
  }

  if (breakdownIds.includes(ReportBreakdownEnum.Course)) {
    key += `-${item.courseId}`
  }

  if (breakdownIds.includes(ReportBreakdownEnum.Survey)) {
    key += `-${item.surveyId}`
  }

  return key
}

// /**
//  * Calculate the age based on the given date of birth.
//  *
//  * @param {string} dateOfBirth - The date of birth in 'YYYY-MM-DD' format.
//  * @returns {number} - The calculated age in years.
//  */
const calculateAge = (dateOfBirth: string) => {
  if (!dateOfBirth) {
    return null
  }
  // Parse the date of birth using moment
  const birthDate = moment(
    dateOfBirth,
    DATE_TIME_SETTING.DATE_FORMATS.DATE_OF_BIRTH,
  )

  // Get the current date using moment
  const refDate = moment()

  // Calculate the difference in years between the current date and the birth date
  return refDate.diff(birthDate, DATE_TIME_SETTING.UNITS.YEAR as any)
}
