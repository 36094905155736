import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, UserDetails } from '../../fragments'

export const GET_CONTENT_CREATOR_BY_ID = gql`
  ${MessagesFragment('GetUserResponseDto')}
  ${MetaFragment('GetUserResponseDto')}
  ${UserDetails('UserDataDto')}
  query GetContentCreator($getContentCreatorId: String!) {
    getContentCreator(id: $getContentCreatorId) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...UserDetails
      }
    }
  }
`
