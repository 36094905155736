import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { useCustomTheme } from '@hooks'
import { DotMenuIcon } from '@assets'
import { getAllPermissions, onKeyDown } from '@utils'
import { AccessibleAction } from '@hoc'

export const KebabMenu: React.FC = ({ dropDownConfig }) => {
    const { theme } = useCustomTheme()
    const { t } = useTranslation()
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget)
    }
    const handleMenuClose = () => {
        setMenuAnchor(null)
    }

    return (
        <AccessibleAction
            permissions={getAllPermissions(dropDownConfig)}
            component={
                <div
                    style={{
                        paddingLeft: '10px',
                        paddingBottom: '0px'
                    }}
                >
                    <IconButton
                        aria-label={t('general.actionDropdown')}
                        onKeyDown={(e) =>
                            onKeyDown(e, () => handleMenuOpen(e), true)
                        }
                        onClick={handleMenuOpen}
                    >
                        <DotMenuIcon />
                    </IconButton>
                    <Menu
                        anchorEl={menuAnchor}
                        open={Boolean(menuAnchor)}
                        onClose={handleMenuClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                        sx={{
                            marginTop: '40px',
                            marginRight: '50px',
                            borderRadius: '8px'
                        }}
                    >
                        {dropDownConfig?.map((menu, index) => (
                            <AccessibleAction
                                permissions={menu?.permissions}
                                component={
                                    <MenuItem
                                        key={index}
                                        onClick={() => {
                                            menu.onClick?.()
                                            handleMenuClose?.()
                                        }}
                                        onKeyDown={(e) =>
                                            onKeyDown(
                                                e,
                                                () => {
                                                    menu.onClick?.()
                                                    handleMenuClose?.()
                                                },
                                                true
                                            )
                                        }
                                        sx={{
                                            padding: '10px 30px 10px 10px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {menu?.icon && (
                                            <menu.icon
                                                width={24}
                                                height={24}
                                                color={
                                                    theme.palette
                                                        .contentSecondary
                                                }
                                            />
                                        )}
                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                paddingLeft: '7px'
                                            }}
                                            variant='body2'
                                        >
                                            {menu?.label}
                                        </Typography>
                                    </MenuItem>
                                }
                            />
                        ))}
                    </Menu>
                </div>
            }
        />
    )
}
