import React from 'react'
import { SvgProps } from '@types'

const RecordIcon: React.FC<SvgProps> = ({ color }) => (
    <svg
        width='41'
        height='40'
        viewBox='0 0 41 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g id='icons/report-video'>
            <g id='Icon'>
                <path
                    d='M16.3333 14.9422C16.3333 14.1467 16.3333 13.749 16.4996 13.527C16.6444 13.3335 16.8662 13.2124 17.1073 13.1952C17.384 13.1754 17.7185 13.3905 18.3877 13.8206L26.2553 18.8784C26.836 19.2517 27.1263 19.4383 27.2266 19.6757C27.3142 19.883 27.3142 20.117 27.2266 20.3243C27.1263 20.5617 26.836 20.7483 26.2553 21.1216L18.3877 26.1793C17.7185 26.6095 17.384 26.8246 17.1073 26.8048C16.8662 26.7876 16.6444 26.6665 16.4996 26.473C16.3333 26.251 16.3333 25.8533 16.3333 25.0578V14.9422Z'
                    stroke='#B05722'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M5.5 13C5.5 10.1997 5.5 8.79961 6.04497 7.73005C6.52433 6.78924 7.28924 6.02433 8.23005 5.54497C9.29961 5 10.6997 5 13.5 5H27.5C30.3003 5 31.7004 5 32.77 5.54497C33.7108 6.02433 34.4757 6.78924 34.955 7.73005C35.5 8.79961 35.5 10.1997 35.5 13V27C35.5 29.8003 35.5 31.2004 34.955 32.27C34.4757 33.2108 33.7108 33.9757 32.77 34.455C31.7004 35 30.3003 35 27.5 35H13.5C10.6997 35 9.29961 35 8.23005 34.455C7.28924 33.9757 6.52433 33.2108 6.04497 32.27C5.5 31.2004 5.5 29.8003 5.5 27V13Z'
                    stroke='#B05722'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
        </g>
    </svg>
)

export default RecordIcon
