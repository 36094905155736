import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, VideoFields } from '../../fragments'

export const GET_SAVED_VIDEOS = gql`
  ${MetaFragment('DashboardVideosResponseDto')}
  ${MessagesFragment('DashboardVideosResponseDto')}
  ${VideoFields('VideoDataDto')}
  query getSavedVideos($options: PaginationDto!) {
    getSavedVideos(options: $options) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...VideoFields
      }
    }
  }
`
