import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import {
    ControlledSelect,
    ControlledSwitch,
    ControlledTextInput
} from '@components'
import { Grid, Typography, Divider } from '@mui/material'
import { GET_RESPONSE_ICONS } from '@dts/graphql/lib'
import { useGraphQLWrapper } from '@hooks'
import { mapDropdownOptions, mergeDropdownFields } from '@dts/client-utils'
import {
    CheckmarkIcon,
    CrossIcon,
    InfoIcon,
    InformationIcon,
    TickIcon,
    WarningIcon
} from '@assets'
import { can_access } from '@hoc'
import { SummaryDetailType } from './type'

export const SummaryDetail: React.FC<SummaryDetailType> = ({
    selectName = 'summaryResponseIconId',
    titleName = 'summaryTitle',
    descriptionName = 'summaryDescription',
    permissions = []
}) => {
    const { t } = useTranslation()
    const {
        formState: { errors }
    } = useFormContext()

    const { currentData: responseIcons, queryLoading } = useGraphQLWrapper({
        query: GET_RESPONSE_ICONS,
        queryName: 'getResponseIcons'
    })
    return (
        <>
            <Grid item mt={3}>
                <ControlledSelect
                    name={selectName}
                    id='summaryResponseIcon'
                    label={t('general.icons')}
                    marginTop={0}
                    clearable
                    disabled={
                        permissions?.length > 0 && !can_access(permissions)
                    }
                    options={mergeDropdownFields(
                        responseIcons?.filter((item) => item?.available),
                        [
                            { icon: CheckmarkIcon },
                            { icon: CrossIcon },
                            { icon: InformationIcon },
                            { icon: WarningIcon }
                        ]
                    )}
                />
            </Grid>
            <Grid item mt={1}>
                <ControlledTextInput
                    name={titleName}
                    id='summaryTitle'
                    label={t('general.title')}
                    multiline
                    rows={1}
                    fullWidth
                    disabled={
                        permissions?.length > 0 && !can_access(permissions)
                    }
                />
            </Grid>
            <Grid item mt={1}>
                <ControlledTextInput
                    name={descriptionName}
                    id='summaryDescription'
                    label={t('general.description')}
                    multiline
                    rows={5}
                    fullWidth
                    disabled={
                        permissions?.length > 0 && !can_access(permissions)
                    }
                />
            </Grid>
        </>
    )
}
