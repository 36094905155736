import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useModalWithGraphQL, useAuth } from '@hooks'
import { Grid, Typography } from '@mui/material'
import { TimeoutIcon } from '@assets'

export const SessionTimeout = () => {
    const { t } = useTranslation()
    const { onSessionTimeoutClose } = useAuth()

    const { openModalWithForm: openSessionTimeoutDialog } = useModalWithGraphQL(
        {
            modalConfig: {
                maxWidth: 'sm',
                Component: () => (
                    <Grid
                        container
                        p={4}
                        flexDirection={'column'}
                        alignItems={'center'}
                        gap={2}
                    >
                        <TimeoutIcon />
                        <Typography variant='h1'>
                            {t('general.sessionExpired')}
                        </Typography>
                        <Typography variant='body2'>
                            {t('sessionExpired.description')}
                        </Typography>
                    </Grid>
                ),
                updationConfig: {
                    primaryButtonText: t('general.login')
                },
                hasCancelButton: false,
                hideCloseIcon: true,
                hasFeedbackDialog: true
            }
        }
    )

    useEffect(() => {
        openSessionTimeoutDialog(undefined, () => onSessionTimeoutClose())
    }, [])

    return <></>
}
