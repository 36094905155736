import {
  MessagesFragment,
  MetaFragment,
  ReportLibraryFields,
} from '../../fragments'

import { gql } from '@apollo/client'

export const DUPLICATE_REPORT = gql`
  ${MessagesFragment('ReportResponseDto')}
  ${MetaFragment('ReportResponseDto')}
  mutation duplicateReport($input: DuplicateReportInputDto!) {
    duplicateReport(input: $input) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
        title
      }
    }
  }
`
