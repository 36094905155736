import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_ENTITY = gql`
  ${MessagesFragment('EntityResponseDto')}
  ${MetaFragment('EntityResponseDto')}
  query getEntity($input: GetEntityInput!) {
    getEntity(input: $input) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
        title
      }
    }
  }
`
