import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActiveIcon, DocumentIcon, VideoPlayerIcon } from '@assets'
import {
    GET_USER_NOTIFICATIONS,
    ON_NEW_NOTIFICATION,
    READ_NOTIFICATION,
    VIEW_NOTIFICATIONS
} from '@dts/graphql'
import { Grid, Typography } from '@mui/material'
import { useCustomTheme, useGraphQLWrapper, useInfiniteScroll } from '@hooks'
import { IconContainer } from '@components'
import { NotificationCategoryEnum } from '@dts/constants/lib'
import { NotificationsList } from '@containers'
import { onKeyDown, redirectNotificationToItem } from '@utils'
import { StyledNotificationRow } from '@containers/notifications/styles'
import { formatDistanceToNow } from 'date-fns'

export const useNotifications = () => {
    const [hasUnseenNotifications, setHasUnseenNotifications] =
        useState<boolean>(false)

    const { theme } = useCustomTheme()

    const navigate = useNavigate()
    const closePopoverRef = useRef(null)

    const { save: readNotification } = useGraphQLWrapper({
        mutation: READ_NOTIFICATION,
        mutationName: 'readNotification',
        suppressToast: true
    })

    const { save: viewNotifications, currentData: notificationsViewedData } =
        useGraphQLWrapper({
            mutation: VIEW_NOTIFICATIONS,
            mutationName: 'viewNotifications',
            suppressToast: true
        })

    const getNotificationIcon = (item) => {
        let IconToRender
        if (
            item?.notificationCategoryId === NotificationCategoryEnum.Training
        ) {
            IconToRender = DocumentIcon
        } else {
            IconToRender = VideoPlayerIcon
        }
        return IconToRender ? (
            <IconToRender color={theme.palette.brandPrimary} />
        ) : (
            <></>
        )
    }

    const handleNotificationClicked = (item) => {
        readNotification({
            notificationId: item?.id
        })
        closePopoverRef?.current?.()

        setData((previousData) =>
            previousData?.map((dataItem) => {
                if (dataItem?.id === item?.id) {
                    return {
                        ...dataItem,
                        isRead: true,
                        isSeen: true
                    }
                }
                return dataItem
            })
        )

        const redirectionPath =
            redirectNotificationToItem(item, item?.body?.referenceId) ?? '/'
        navigate(redirectionPath)
    }

    const loadComponent = (mergedData: Array<object>) => (
        <Grid container flexDirection={'column'} py={1} px={2} gap={1}>
            {mergedData?.map((item, index) => (
                <StyledNotificationRow
                    item
                    container
                    key={index}
                    tabIndex={0}
                    p={1}
                    isRead={item?.isRead}
                    onKeyDown={(e) => {
                        onKeyDown(
                            e,
                            () => handleNotificationClicked(item),
                            false
                        )
                    }}
                    onClick={() => handleNotificationClicked(item)}
                >
                    <Grid
                        item
                        display={'flex'}
                        flexDirection={'row'}
                        xs={10}
                        md={10}
                        gap={1}
                    >
                        <IconContainer
                            tabIndex={-1}
                            color={theme.palette.secondaryGrey}
                            zIndex={10}
                        >
                            {getNotificationIcon(item)}
                        </IconContainer>
                        <Grid container flexDirection={'column'} ml={1}>
                            <Typography
                                variant='body2'
                                color={theme.palette.contentPrimary}
                            >
                                {item?.body?.message}
                            </Typography>
                            <Typography
                                variant='body2'
                                color={theme.palette.neutralsTextGrey}
                            >
                                {formatDistanceToNow(new Date(item?.sentAt), {
                                    addSuffix: true
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                    {!item?.isRead && (
                        <Grid item>
                            <ActiveIcon />
                        </Grid>
                    )}
                </StyledNotificationRow>
            ))}
        </Grid>
    )

    const {
        wrappedComponent,
        data,
        setData,
        retrieve,
        queryLoading,
        queryData
    } = useInfiniteScroll({
        query: GET_USER_NOTIFICATIONS,
        queryName: 'getUserNotifications',
        subscriptionVariables: {},
        subscription: ON_NEW_NOTIFICATION,
        accessor: 'notifications',
        updateQuery: (prev, subscriptionData) => {
            if (!subscriptionData.data) return prev
            return {
                getUserNotifications: {
                    data: {
                        unSeen: true
                    }
                }
            }
        },
        loadComponent
    })

    useEffect(() => {
        setHasUnseenNotifications(queryData?.unSeen)
    }, [queryData])

    useEffect(() => {
        notificationsViewedData &&
            setHasUnseenNotifications(notificationsViewedData?.unSeen)
    }, [notificationsViewedData])

    return {
        hasUnseenNotifications,
        setHasUnseenNotifications,
        viewNotifications,
        wrappedComponent,
        data,
        closePopoverRef,
        refetchNotifications: retrieve,
        queryLoading,
        NotificationsList
    }
}
