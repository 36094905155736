import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, SurveyFields } from '../../fragments'

export const GET_PULSE_SURVEY_QUESTIONS = gql`
  ${MessagesFragment('SurveyQuestionsResponseDto')}
  ${MetaFragment('SurveyQuestionsResponseDto')}
  query getPulseSurveyQuestions($args: PulseQuestionsArgumentsDto!) {
    getPulseSurveyQuestions(args: $args) {
      ...MetaFragment
      ...MessagesFragment
      data {
        id
        title
        questionType {
          id
          title
        }
        questionFeedbackType {
          id
          title
        }
        skill {
          id
          title
        }
        topic {
          id
          title
        }
        isHidden
        surveyOptions {
          id
          title
          isAnswer
          summaryDescription
          displayOrder
          surveyRecommendations {
            recommendations {
              id
              recommendationType {
                id
                title
              }
              tags
              skills {
                id
                title
              }
            }
          }
        }
        surveyQuestionResponses {
          responseType {
            id
            title
          }
          summaryTitle
          summaryDescription
          summaryResponseIcon {
            id
            title
          }
        }
        displayOrder
        isAnswered
        answeredOptions
        isCorrect
        summaryEnabled
        summaryTitle
        summaryDescription
        summaryResponseIcon {
          id
          title
        }
        hideFeedback
      }
    }
  }
`
