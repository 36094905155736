import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const AllIcon: React.FC<SvgProps> = ({ color, width = 24, height = 24 }) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.backgroundBrandPrimary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 24 24'
            fill='none'
        >
            <path
                d='M3 12H10.5278M3 6H10.5278M3 18H10.5278'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M13.5537 12H21.0815M13.5537 6H21.0815M13.5537 18H21.0815'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default AllIcon
