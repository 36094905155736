import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SupportForm } from '@containers'
import { usePageHeader } from '@hooks'
import { WrapperComponent } from './reporting'

export const SupportPage = () => {
    const { pageHeader } = usePageHeader({
        headerText: 'Support'
    })

    return (
        <>
            <WrapperComponent>
                {pageHeader}
                <SupportForm />
            </WrapperComponent>
        </>
    )
}
