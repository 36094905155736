import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const DELETE_VIDEOS = gql`
  ${MetaFragment('DashboardVideosResponseDto')}
  ${MessagesFragment('DashboardVideosResponseDto')}
  mutation DeleteVideos($ids: [String!]!) {
    deleteVideos(ids: $ids) {
      ...MetaFragment
      ...MessagesFragment
      data {
        id
      }
    }
  }
`
