import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { usePageHeader, useRoutes } from '@hooks'
import { Grid } from '@mui/material'

export const BrowseVideosLayout = () => {
    const { t } = useTranslation()
    const { browseVideoRoutes } = useRoutes()

    const { pageHeader } = usePageHeader({
        headerText: t('general.browse'),
        menuConfig: () => browseVideoRoutes
    })

    return (
        <>
            {pageHeader}
            <Outlet />
        </>
    )
}
