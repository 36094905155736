import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { Divider, Typography, Grid } from '@mui/material'
import {
    BaseContainer,
    ParentContainer,
    TypographyContainer,
    StyledChip,
    ReviewButton
} from '@components'
import { isMediumScreen } from '@utils'

import { ClockIcon, PencilIcon } from '@assets'
import { useCustomTheme } from '@hooks'
import { StyledBox } from './styles'

interface CourseReviewProps {
    handleStepJump?: (step: number) => void
}

export const CourseReview: React.FC<CourseReviewProps> = ({
    handleStepJump
}) => {
    const { theme } = useCustomTheme()
    const { t } = useTranslation()
    const { getValues, control } = useFormContext()

    const topics = useWatch({
        control,
        name: 'addedTopics'
    })
    const totalDuration = useWatch({
        control,
        name: 'totalDuration'
    })

    const reviewSteps = [
        {
            header: t('general.description'),
            details: () => (
                <Typography variant='body2Medium'>
                    {getValues('description')}
                </Typography>
            ),
            editButtonText: `${t('general.description')}`,
            stepNumber: 0
        },

        {
            header: t('general.topics'),
            details: () => (
                <>
                    <Typography variant='body2Medium'>
                        {getValues('topics')?.length
                            ? getValues('topics')
                                  ?.filter((topic) => topic.isAdded)
                                  ?.map(
                                      (topic, index, array) =>
                                          topic?.title +
                                          (index !== array.length - 1
                                              ? ', '
                                              : '')
                                  )
                            : topics?.map(
                                  (topic, index, array) =>
                                      topic?.topic?.title +
                                      (index !== array.length - 1 ? ', ' : '')
                              )}
                    </Typography>

                    {totalDuration && (
                        <Grid display={'flex'} alignItems={'center'}>
                            <ClockIcon />
                            <Typography variant='body2Medium' ml={1}>
                                {totalDuration}
                            </Typography>
                        </Grid>
                    )}
                </>
            ),
            editButtonText: `${t('general.course')}`,
            stepNumber: 1
        },

        {
            header: t('general.tags'),
            details: () => (
                <>
                    {getValues('tags')?.length ? (
                        <Typography variant='body2Medium'>
                            {t('courses.tags.caption')}
                        </Typography>
                    ) : (
                        <></>
                    )}
                    <StyledBox>
                        {getValues('tags')?.map((tag) => (
                            <StyledChip label={tag} key={tag} sx={{ mb: 1 }} />
                        ))}
                    </StyledBox>
                </>
            ),
            editButtonText: `${t('general.tags')}`,
            stepNumber: 1
        }
    ]
    return (
        <ParentContainer container>
            <Typography variant='h1'>{`${t('general.review')} ${t(
                'general.course'
            )}`}</Typography>

            {reviewSteps?.map((step) => (
                <>
                    <BaseContainer item>
                        <TypographyContainer item md={4}>
                            <Typography variant='h3'>{step?.header}</Typography>
                            {<step.details />}
                        </TypographyContainer>
                        <Grid
                            item
                            md={4}
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                marginLeft: !isMediumScreen() ? '40px' : '0px',
                                gap: '10px'
                            }}
                        >
                            <ReviewButton
                                onClick={handleStepJump(step?.stepNumber)}
                                startIcon={
                                    <PencilIcon
                                        color={theme.palette.primary.main}
                                    />
                                }
                            >
                                {step?.editButtonText}
                            </ReviewButton>
                        </Grid>
                    </BaseContainer>
                    <Divider />
                </>
            ))}
        </ParentContainer>
    )
}
