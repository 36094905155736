import { Box, Grid, styled } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
    borderRadius: '8px',
    border: `1px solid ${theme.palette.borderSecondary}`,
    padding: '16px',
    height: '100%',
    backgroundColor: theme.palette.backgroundSecondary
}))
export const Container = styled(Grid)`
    display: flex;
`
export const RightContainer = styled(Grid)`
    margin-left: 8px;
`
export const InfoContainer = styled(Grid)`
    display: flex;
    flex-direction: column;
`
