import * as React from 'react'
import {
    getAdminVideoRoutes,
    getCreatorVideoSubRoutes,
    getSettingsRoutes,
    getCommonNavRoutes,
    getTraineeListRoutes,
    getTrainingsRoutes,
    getBrowseVideoRoutes,
    getTopicsSubRoutes,
    getCoursesSubRoutes,
    getManageClientsRoutes,
    getVerticalRoutes,
    getLearnerRoutes,
    getUserAccountRoutes,
    getCreatorProfileRoutes,
    getPublicRoutes,
    getUserRoutes,
    getSurveyRoutes,
    getReportRoutes,
    getContentCreatorRoutes
} from '@routes'
import { useAuth } from './use-auth'

export const useRoutes = () => {
    const {
        userInfo: { roles, adminPortalOnlyAccess }
    } = useAuth()

    return {
        centralRoutes: () => getUserRoutes(roles, adminPortalOnlyAccess),
        publicRoutes: getPublicRoutes(),
        adminVideoRoutes: getAdminVideoRoutes(roles),
        creatorVideoSubRoutes: getCreatorVideoSubRoutes(roles),
        userAccountRoutes: getUserAccountRoutes(roles),
        settingsRoutes: getSettingsRoutes(roles),
        commonNavRoutes: getCommonNavRoutes(roles),
        traineeListRoutes: getTraineeListRoutes(roles),
        trainingsRoutes: getTrainingsRoutes(roles),
        browseVideoRoutes: getBrowseVideoRoutes(roles),
        adminTopicsRoutes: getTopicsSubRoutes(roles),
        adminCoursesRoutes: getCoursesSubRoutes(roles),
        manageClientsRoutes: getManageClientsRoutes(roles),
        verticalRoutes: getVerticalRoutes(roles),
        learnerRoutes: getLearnerRoutes(roles),
        creatorProfileRoutes: getCreatorProfileRoutes(roles),
        surveyRoutes: getSurveyRoutes(roles),
        reportRoutes: getReportRoutes(roles),
        contentCreatorRoutes: getContentCreatorRoutes(roles)
    }
}
