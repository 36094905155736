import React, { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GET_CAMPUS_PREFERENCE, SAVE_CAMPUS_PREFERENCES } from '@dts/graphql'
import { useFormFeedback, usePage } from '@hooks'
import { isNull } from 'lodash'
import {
    CampusPreferencesSchema,
    formatDate,
    formatFullDate
} from '@dts/client-utils'
import { DisplayModeGraphqlEnum, UserPermissionEnum } from '@dts/constants'
import { CampusPreferenceForm } from './campus-preferences'
import {
    CampusPreferencesType,
    TCampusPreferences,
    CampusPreferencesProps
} from './types'

export const CampusPreferencesContainer: FC<CampusPreferencesProps> = (
    props
) => {
    const { campusId = '' } = props || {}
    const { t } = useTranslation()
    const { id: clientId } = useParams()

    const mapFormToMutationInput = (form: CampusPreferencesType) => {
        const {
            autoPlay = false,
            displayModeId,
            captionLanguage,
            businessDays,
            calendarYearFrom,
            calendarYearTo,
            officeFrom,
            officeTo,
            universityClosure: _universityClosure
        } = form || {}

        const universityClosure = _universityClosure
            ?.map(
                ({ title, date }) =>
                    title?.length &&
                    date && {
                        title,
                        date
                    }
            )
            .filter(Boolean)

        return {
            id: form?.id,
            displayModeId,
            ...(captionLanguage && { captionLanguageId: captionLanguage }),
            universityClosure: universityClosure?.length
                ? universityClosure
                : undefined,
            businessDays,
            calendarYearFrom: calendarYearFrom
                ? formatFullDate(calendarYearFrom)
                : undefined,
            calendarYearTo: calendarYearTo
                ? formatFullDate(calendarYearTo)
                : undefined,
            officeFrom: !isNull(officeFrom)
                ? officeFrom?.length
                    ? officeFrom
                    : undefined
                : undefined,
            officeTo: !isNull(officeTo)
                ? officeTo?.length
                    ? officeTo
                    : undefined
                : undefined,
            clientId,
            campusId,
            videos: {
                autoPlay
            }
        }
    }

    const mapQueryResultToForm = (data: TCampusPreferences) => {
        const {
            id,
            businessDays = {
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true,
                saturday: false,
                sunday: false
            },
            videos,
            calendarYearFrom,
            calendarYearTo,
            captionLanguage,
            displayMode = DisplayModeGraphqlEnum.SYD,
            officeFrom,
            officeTo,
            universityClosure
        } = data || {}

        return {
            id,
            universityClosure: universityClosure?.map(({ title, date }) => ({
                title,
                date: formatFullDate(date)
            })),
            businessDays: { ...businessDays, __typename: undefined },
            calendarYearFrom: !isNull(calendarYearFrom)
                ? formatFullDate(calendarYearFrom)
                : null,
            calendarYearTo: !isNull(calendarYearTo)
                ? formatFullDate(calendarYearTo)
                : null,
            officeFrom,
            officeTo,
            displayModeId: displayMode?.id,
            captionLanguage: captionLanguage?.id,
            autoPlay: videos?.autoPlay
        }
    }

    const { methods, save, mutationLoading, queryLoading } = useFormFeedback({
        permissions: [UserPermissionEnum.ClientCreateModify],
        initialValues: {
            id: undefined,
            universityClosure: [],
            calendarYearFrom: undefined,
            calendarYearTo: undefined,
            officeFrom: undefined,
            officeTo: undefined,
            enablePartyEmailConfig: false,
            captionLanguage: null,
            displayModeId: DisplayModeGraphqlEnum.SYD,
            videos: {
                autoPlay: false
            },
            businessDays: {
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true,
                saturday: false,
                sunday: false
            }
        },
        mutation: SAVE_CAMPUS_PREFERENCES,
        mutationName: 'saveCampusPreferences',
        query: GET_CAMPUS_PREFERENCE,
        queryName: 'getCampusPreferences',
        retrieveOnMount: true,
        queryVariables: {
            campusId,
            clientId
        },
        // TOD: WILL BE USED
        yupResolverSchema: CampusPreferencesSchema,
        mapQueryResultToForm,
        mapFormToMutationInput
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: CampusPreferenceForm,
            hasHeader: true,
            preventMargin: true,
            pageHeaderConfig: {
                headerText: t('setting.preferences'),
                primaryButtonTitle: t('general.update'),
                primaryButtonAction: () => {
                    methods.handleSubmit(() => save())()
                },
                permissions: [UserPermissionEnum.ClientCreateModify]
            }
        }
    })

    return <>{page}</>
}
