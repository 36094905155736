import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const DELETE_TOPIC = gql`
  ${MessagesFragment('TopicResponseDto')}
  ${MetaFragment('TopicResponseDto')}
  mutation DeleteTopic($deleteTopicId: String!) {
    deleteTopic(id: $deleteTopicId) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
      }
    }
  }
`
