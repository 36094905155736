import React from 'react'
import { useTranslation } from 'react-i18next'
import { useGraphQLWrapper } from '@hooks'
import { GET_VIDEOS_GROUPED_BY_CREATORS } from '@dts/graphql'
import { Grid, Typography } from '@mui/material'
import { ArrowRightIcon, PlayIcon } from '@assets'
import { VideoCardWithAction } from '@components'

export const BrowseByCreators: React.FC = () => {
    const { t } = useTranslation()
    const { currentData: videosGroupedByCreators } = useGraphQLWrapper({
        query: GET_VIDEOS_GROUPED_BY_CREATORS,
        queryName: 'getVideosGroupedByCreators',
        retrieveOnMount: true,
        queryVariables: {
            args: {
                limit: 10,
                creatorId: null
            }
        }
    })

    const cardAction = (params) => ({
        icon: PlayIcon,
        label: '122k'
    })

    return (
        <>
            <Grid container flexDirection={'column'}>
                {videosGroupedByCreators?.map((creator) => (
                    <>
                        <Grid
                            container
                            flexDirection={'column'}
                            key={creator?.id}
                        >
                            <Grid
                                item
                                display={'flex'}
                                justifyContent={'space-between'}
                                p={2}
                            >
                                <Grid
                                    item
                                    display={'flex'}
                                    flexDirection={'column'}
                                >
                                    <Typography variant='h2'>
                                        {`${creator?.firstName} ${creator?.lastName}`}
                                    </Typography>
                                    <Typography variant='body2'>{`${
                                        creator?.count
                                    } ${
                                        creator?.count === 1
                                            ? t('general.video')
                                            : t('general.videos')
                                    }`}</Typography>
                                </Grid>
                                <ArrowRightIcon />
                            </Grid>
                        </Grid>
                        <div
                            style={{
                                overflowX: 'auto',
                                overflowY: 'hidden',
                                maxWidth: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            {creator?.videos?.map((video, index) => (
                                <>
                                    <VideoCardWithAction
                                        key={video?.id}
                                        item={video}
                                        cardAction={cardAction}
                                    />
                                </>
                            ))}
                        </div>
                    </>
                ))}
            </Grid>
        </>
    )
}
