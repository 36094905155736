import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { Divider, Typography, Grid } from '@mui/material'
import {
    BaseContainer,
    ComponentContainer,
    ParentContainer,
    TypographyContainer,
    ControlledCheckBox,
    ReviewButton
} from '@components'
import { PencilIcon, ReadingIcon, VideoPlayerIcon } from '@assets'
import { useCustomTheme } from '@hooks'

interface CourseReviewProps {
    handleStepJump?: (step: number) => void
}

export const VerticalReview: React.FC<CourseReviewProps> = ({
    handleStepJump
}) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const { getValues, control } = useFormContext()
    const courseCount = useWatch({
        control,
        name: 'courseCount'
    })
    const videoCount = useWatch({
        control,
        name: 'videoCount'
    })
    const addedCourses = useWatch({
        control,
        name: 'addedCourses'
    })
    const addedVideos = useWatch({
        control,
        name: 'addedVideos'
    })

    const courses = useWatch({
        control,
        name: 'courses'
    })
    const videos = useWatch({
        control,
        name: 'videos'
    })

    const reviewSteps = [
        {
            header: t('general.description'),
            details: () => (
                <Typography variant='body2Medium'>
                    {getValues('description')}
                </Typography>
            ),
            editButtonText: `${t('general.description')}`,
            stepNumber: 0
        },

        {
            header: t('general.courses'),
            details: () => (
                <>
                    <Typography variant='body2Medium'>
                        {courses?.length
                            ? courses
                                  ?.filter((course) => course.isAdded)
                                  ?.map(
                                      (course, index, array) =>
                                          course?.title +
                                          (index !== array.length - 1
                                              ? ', '
                                              : '')
                                  )
                            : addedCourses?.map(
                                  (course, index, array) =>
                                      course.title +
                                      (index !== array.length - 1 ? ', ' : '')
                              )}
                    </Typography>
                    <Grid display={'flex'} alignItems={'center'}>
                        <ReadingIcon />
                        <Typography variant='body2Medium' ml={1}>
                            {`${
                                courses?.length
                                    ? courses?.filter(
                                          (course) => course.isAdded
                                      ).length
                                    : courseCount
                            } ${
                                courseCount === 1
                                    ? t('general.course')
                                    : t('general.courses')
                            }`}
                        </Typography>
                    </Grid>
                </>
            ),
            editButtonText: `${t('general.course')}`,
            stepNumber: 1
        },

        {
            header: t('general.filters'),
            details: () => (
                <>
                    <Typography variant='body2Medium'>
                        {t('vertical.filterReviewCaption')}
                    </Typography>
                </>
            ),
            editButtonText: `${t('general.filters')}`,
            stepNumber: 2
        },
        {
            header: t('general.videos'),
            details: () => (
                <>
                    <Typography variant='body2Medium'>
                        {videos?.length
                            ? videos
                                  ?.filter((video) => video.isAdded)
                                  ?.map(
                                      (video, index, array) =>
                                          video?.title +
                                          (index !== array.length - 1
                                              ? ', '
                                              : '')
                                  )
                            : addedVideos?.map(
                                  (video, index, array) =>
                                      video.title +
                                      (index !== array.length - 1 ? ', ' : '')
                              )}
                    </Typography>
                    <Grid display={'flex'} alignItems={'center'}>
                        <VideoPlayerIcon
                            color={theme.palette.contentSecondary}
                        />
                        <Typography variant='body2Medium' ml={1}>
                            {`${
                                videos?.length
                                    ? videos?.filter((video) => video.isAdded)
                                          .length
                                    : videoCount
                            }  ${
                                videoCount === 1
                                    ? t('general.video')
                                    : t('general.videos')
                            }`}
                        </Typography>
                    </Grid>
                </>
            ),
            editButtonText: `${t('general.videos')}`,
            stepNumber: 3
        }
    ]
    return (
        <ParentContainer container>
            <Typography variant='h1'>{`${t('general.review')} ${t(
                'general.vertical'
            )}`}</Typography>
            <Grid
                display={'flex'}
                sx={{
                    background: theme.palette.backgroundPrimary,
                    borderRadius: '5px',
                    alignItems: 'center'
                }}
            >
                <Grid item sx={{ paddingLeft: '25px' }}>
                    <ControlledCheckBox
                        ariaLabel={t('vertical.saveAsTemplate')}
                        id='isTemplate'
                        name='isTemplate'
                        disabled={getValues('disableTemplate')}
                    />
                </Grid>
                <Grid item sx={{ paddingRight: '25px' }}>
                    <Typography variant='subtitle2' sx={{ fontWeight: '500' }}>
                        {t('vertical.saveAsTemplate')}
                    </Typography>
                </Grid>
            </Grid>
            {reviewSteps?.map((step) => (
                <>
                    <BaseContainer item>
                        <TypographyContainer item md={4}>
                            <Typography variant='h3'>{step?.header}</Typography>
                            {<step.details />}
                        </TypographyContainer>
                        <ComponentContainer>
                            <ReviewButton
                                onClick={handleStepJump(step?.stepNumber)}
                                startIcon={
                                    <PencilIcon
                                        color={theme.palette.primary.main}
                                    />
                                }
                            >
                                {step?.editButtonText}
                            </ReviewButton>
                        </ComponentContainer>
                    </BaseContainer>
                    <Divider />
                </>
            ))}
        </ParentContainer>
    )
}
