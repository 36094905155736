import { isEmpty } from 'lodash'
import {
  MonthlyReminderOptionEnum,
  NotificationFrequencyEnum,
} from '../../../../constants/lib'
import { validateDateComparison } from '../../utils'
import * as yup from 'yup'

export const TrainingInfoSchema = yup.object().shape({
  name: yup.string().required('errors.required'),
  description: yup.string().required('errors.required'),
})

export const TrainingLearnerSchema = yup.object().shape({
  learnerLists: yup.array().min(1, 'errors.required'),
})

export const TrainingCoursesSchema = yup.object().shape({
  courses: yup
    .array()
    .test('at-least-one-added', 'errors.required', (courses) => {
      const addedCourses = courses?.some((course) => course?.isAdded)
      return addedCourses
    }),
})

export const TrainingDatesSchema = yup.object().shape({
  startTime: yup.string().required('errors.required'),
  dueTime: yup.string().required('errors.required'),
  startDate: yup.date().required('errors.required'),
  dueDate: yup
    .date()
    .required('errors.required')
    .test('dateValidation', 'errors.startAndDueDate', function (value) {
      const startDate: string | Date = this.resolve(yup.ref('startDate'))

      return validateDateComparison(startDate, value)
    }),
  remindersStartDate: yup
    .date()
    .nullable()
    .when(
      ['notificationFrequency', 'enableReminderNotifications'],
      ([notificationFrequency, enableReminderNotifications], schema) =>
        notificationFrequency !== NotificationFrequencyEnum.None &&
        enableReminderNotifications
          ? schema.required('errors.required')
          : schema.nullable(),
    ),
  remindersEndDate: yup
    .date()
    .when(
      ['notificationFrequency', 'enableReminderNotifications'],
      ([notificationFrequency, enableReminderNotifications], schema) =>
        notificationFrequency !== NotificationFrequencyEnum.None &&
        enableReminderNotifications
          ? schema.required('errors.required')
          : schema.nullable(),
    )
    .nullable()
    .test('dateValidation', 'errors.startAndDueDate', function (value) {
      const startDate: string | Date = this.resolve(
        yup.ref('remindersStartDate'),
      )
      return validateDateComparison(startDate, value)
    }),
  weekdays: yup
    .object()
    .test('weekdayValidation', 'errors.oneWeekdayRequired', function (value) {
      const notificationFrequency: string = this.resolve(
        yup.ref('notificationFrequency'),
      )
      const noSelectedWeekdays =
        notificationFrequency === NotificationFrequencyEnum.Weekly &&
        (!value ||
          isEmpty(value) ||
          !Object.values(value)?.some((val) => val === true))
      return !noSelectedWeekdays
    }),
  monthDate: yup
    .string()
    .nullable()
    .test('dateValidation', function (value) {
      const notificationFrequency: string = this.resolve(
        yup.ref('notificationFrequency'),
      )

      const monthlyReminderOption: string = this.resolve(
        yup.ref('monthlyReminderOption'),
      )

      if (
        notificationFrequency === NotificationFrequencyEnum.Monthly &&
        monthlyReminderOption === MonthlyReminderOptionEnum.DateSpecific
      ) {
        if (!value) {
          return this.createError({ message: 'errors.required' })
        } else if (Number(value) < 0 || Number(value) > 31) {
          return this.createError({ message: 'errors.validMonthDate' })
        } else {
          return true
        }
      } else {
        return true
      }
    }),
  monthWeek: yup
    .string()
    .when(
      ['notificationFrequency', 'monthlyReminderOption'],
      ([notificationFrequency, monthlyReminderOption], schema) =>
        notificationFrequency === NotificationFrequencyEnum.Monthly &&
        monthlyReminderOption === MonthlyReminderOptionEnum.DaySpecific
          ? schema.required('errors.required')
          : schema.nullable(),
    ),
  monthDay: yup
    .string()
    .when(
      ['notificationFrequency', 'monthlyReminderOption'],
      ([notificationFrequency, monthlyReminderOption], schema) =>
        notificationFrequency === NotificationFrequencyEnum.Monthly &&
        monthlyReminderOption === MonthlyReminderOptionEnum.DaySpecific
          ? schema.required('errors.required')
          : schema.nullable(),
    ),
})
