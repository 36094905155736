import React, { useEffect, createContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { setNetworkError, setUnauthorizedError } from '@cacheql'
import { useReactiveVar } from '@apollo/client'

export const NetworkContext = createContext(null)

export const NetworkProvider = ({ children }) => {
    const reactiveNetworkError = useReactiveVar(setNetworkError)
    const reactiveUnauthorizedError = useReactiveVar(setUnauthorizedError)

    const navigate = useNavigate()

    useEffect(() => {
        setNetworkError() && navigate('/system-down')
    }, [reactiveNetworkError])

    useEffect(() => {
        setUnauthorizedError() && navigate('/unauthorized')
    }, [reactiveUnauthorizedError])

    return (
        <NetworkContext.Provider value={null}>
            {children}
        </NetworkContext.Provider>
    )
}
