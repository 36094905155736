import React, { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import {
    Checkbox as MUICheckbox,
    CheckboxProps,
    FormControlLabel,
    Grid
} from '@mui/material'
import { controlled } from '@hoc'
import { useCustomTheme } from '@hooks'
import { StyledCheckboxGrid } from './styles'

interface CheckBoxProps {
    id: string
    color?: string
    name: string
    label?: string
    width?: string
    fullWidth?: boolean
    ariaLabel?: string
    onChange?: (checked: boolean, name?: string) => void
    hasBackground?: boolean
    isGrouped?: boolean
    bgWidth?: string
    onClick?: VoidFunction
    indeterminate?: boolean
}
export const Checkbox: React.FC<CheckboxProps & CheckBoxProps> = ({
    id,
    color,
    name,
    onChange,
    value = false,
    label,
    disabled = false,
    hasBackground = false,
    isGrouped = false,
    bgWidth,
    onClick,
    ariaLabel,
    indeterminate = false,
    ...props
}) => {
    const { theme } = useCustomTheme()
    const [checked, setChecked] = useState(value)
    const { ...formContext } = useFormContext()
    const handleValues = (val: boolean) => {
        if (!disabled) {
            if (isGrouped) {
                onChange?.(val, name)
            } else {
                onChange?.(val)
            }
        }
    }

    useEffect(() => {
        setChecked(value)
    }, [value])

    const renderCheckBox = () => (
        <FormControlLabel
            aria-label={ariaLabel}
            disabled={formContext?.formEditingState?.isReadOnly || disabled}
            control={
                <MUICheckbox
                    name={name}
                    id={id ?? 'checkbox'}
                    onChange={(e) => {
                        handleValues(e.target.checked)
                    }}
                    checked={checked}
                    indeterminate={indeterminate}
                    onClick={(e) => onClick?.(e?.target?.checked)}
                    value={value}
                    disabled={
                        formContext?.formEditingState?.isReadOnly || disabled
                    }
                    {...{
                        inputProps: {
                            'aria-label': ariaLabel
                        }
                    }}
                    label={props.label}
                    {...props}
                />
            }
            label={label}
            // TODO Remove style
            // sx={{
            //     '& .MuiFormControlLabel-label': {
            //         color: `${theme.palette.contentPrimary} !important`, // Replace with your desired color
            //         margin: 0
            //     }
            // }}
        />
    )

    return hasBackground ? (
        <StyledCheckboxGrid
            onClick={() => {
                handleValues(!checked)
                onClick?.(checked)
            }}
            checked={checked}
            width={bgWidth}
        >
            {renderCheckBox()}
        </StyledCheckboxGrid>
    ) : (
        renderCheckBox()
    )
}

export const ControlledCheckBox = controlled(Checkbox)
