import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import {
    useGraphQLWrapper,
    usePageHeader,
    useCustomTheme,
    useLoader
} from '@hooks'
import { GET_VIDEOS_GROUPED_BY_TOPICS } from '@dts/graphql'
import { Grid, Typography } from '@mui/material'
import { ArrowRightIcon, PlayFilledIcon, PlayIcon } from '@assets'
import { CustomLoader, EmptyState, VideoCardWithAction } from '@components'
import { addEntryToCache } from '@cacheql'
import { formatNumberToKMB } from '@dts/client-utils'
import { BrowseVideosByTopicsProps } from './types'

export const viewCountCardAction = (theme, viewCount: string) => (
    <Grid display={'flex'} alignItems={'center'}>
        <PlayFilledIcon color={theme.palette.constantsWhite} />
        <Typography
            variant='body1'
            fontSize={'12px'}
            color={theme.palette.constantsWhite}
            fontWeight={600}
            ml={0.5}
        >
            {formatNumberToKMB(viewCount)}
        </Typography>
    </Grid>
)

export const BrowseByTopics: FC<BrowseVideosByTopicsProps> = ({
    query = GET_VIDEOS_GROUPED_BY_TOPICS,
    queryName = 'getVideosGroupedByTopics',
    isCreatorVideos = false
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { creatorId } = useParams()
    const { theme } = useCustomTheme()
    const { loading } = useLoader()
    const { currentData: videosGroupedByTopics } = useGraphQLWrapper({
        query,
        queryName,
        retrieveOnMount: true,
        queryVariables: {
            args: {
                limit: 10,
                topicId: null,
                creatorId
            }
        }
    })

    const cardAction = (params) => ({
        icon: () => viewCountCardAction(theme, params?.viewCount)
    })
    const { pageHeader } = usePageHeader({
        headerText: t('browseVideos.byTopic')
    })

    return (
        <>
            {loading ? (
                <Grid container xs={12} justifyContent={'center'}>
                    <CustomLoader />
                </Grid>
            ) : (
                <>
                    {isCreatorVideos && pageHeader}
                    {videosGroupedByTopics?.length ? (
                        <Grid container flexDirection={'column'} p={2}>
                            {videosGroupedByTopics?.map((topic) => (
                                <>
                                    <Grid
                                        container
                                        flexDirection={'column'}
                                        key={topic?.id}
                                    >
                                        <Grid
                                            item
                                            display={'flex'}
                                            justifyContent={'space-between'}
                                            p={2}
                                        >
                                            <Grid
                                                item
                                                display={'flex'}
                                                flexDirection={'column'}
                                            >
                                                <Typography variant='h2'>
                                                    {topic?.title}
                                                </Typography>
                                                <Typography variant='body2'>{`${
                                                    topic?.count
                                                } ${
                                                    topic?.count === 1
                                                        ? t('general.video')
                                                        : t('general.videos')
                                                }`}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        style={{
                                            overflowX: 'auto',
                                            overflowY: 'hidden',
                                            maxWidth: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        {topic?.videos?.map(
                                            (video, index: number) => (
                                                <>
                                                    {index < 5 && (
                                                        <VideoCardWithAction
                                                            key={video?.id}
                                                            item={{
                                                                ...video,
                                                                onClick: () =>
                                                                    navigate(
                                                                        `/browse-videos/watch/${video?.id}`
                                                                    )
                                                            }}
                                                            cardAction={
                                                                cardAction
                                                            }
                                                            showViewMore={
                                                                topic?.count >
                                                                    5 &&
                                                                index === 4
                                                            }
                                                            onClickViewMore={() => {
                                                                addEntryToCache(
                                                                    topic?.id,
                                                                    topic?.title
                                                                )
                                                                navigate(
                                                                    creatorId
                                                                        ? `/creator-profile/${creatorId}/browse-videos-by-topic/${topic?.id}`
                                                                        : `/browse-videos/${topic?.id}`,
                                                                    {
                                                                        state: {
                                                                            topic: topic?.title
                                                                        }
                                                                    }
                                                                )
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            )
                                        )}
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                    ) : (
                        <EmptyState
                            title={t('browseVideos.noVideos')}
                            description={t('browseVideos.noVideosDescription')} //TODO: Waiting for figma design for empty state
                        />
                    )}
                </>
            )}
        </>
    )
}
