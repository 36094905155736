import { REGEX } from '../../../utils'
import * as yup from 'yup'

export const CreateClientSchema = yup.object().shape({
  clientName: yup
    .string()
    .trim()
    .required('errors.required')
    .max(60, 'errors.max')
    .matches(
      REGEX.noSpecialChars,
      'Client name can only contain letters, numbers and spaces',
    ),

  email: yup
    .string()
    .required('errors.required')
    .email('Please provide a valid email')
    .max(255, 'Email should not exceed more than 255 characters'),
  phone: yup
    .string()
    .notRequired()
    .nullable()
    .test('phone', 'Enter at least 10 digits', (value) => {
      const val = value?.replace(/[^0-9]/g, '')
      return value?.length ? val?.length === 10 : true
    }),
  zipCode: yup.string().required('errors.required'),
  city: yup.string().required('errors.required'),
  state: yup.string().required('errors.required'),
  sector: yup.string().required('errors.required'),
  clientId: yup.string().required('errors.required').min(5, 'errors.min'),
  clientType: yup.string().required('errors.required'),
  status: yup.boolean().required('errors.required'),
  address: yup.string().max(255, 'errors.max').nullable(),
  contactRole: yup.string().required('errors.required'),
  contactName: yup.string().required('errors.required').max(60, 'errors.max'),
  contactEmail: yup
    .string()
    .required('errors.required')
    .email('Please provide a valid email')
    .max(255, 'Email should not exceed more than 255 characters'),
  contactPhone: yup
    .string()
    .notRequired()
    .nullable()
    .test('phone', 'Enter at least 10 digits', (value) => {
      const val = value?.replace(/[^0-9]/g, '')
      return value?.length ? val?.length === 10 : true
    }),
  startDate: yup.date().required('errors.required'),
  expiryDate: yup
    .date()
    .min(yup.ref('startDate'), 'errors.client.contarctExpiryDate'),
})
