import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const StyledGrid = styled(Grid)(({ theme }) => ({
    borderRadius: '8px', // Access theme spacing or fallback to default value
    boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.12)',
    background: theme.palette.backgroundPrimary,
    padding: '16px',
    paddingBottom: '32px',
    marginBottom: '4px'
}))
