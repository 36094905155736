import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    useMediaQuery,
    Box
} from '@mui/material'
import SearchIcon from '@assets/icons/search'
import { debounce } from 'lodash'
import { CloseIcon, FilterActiveIcon, FilterIcon } from '@assets'
import { IconSwitch } from '@components/Switch'
import {
    useCustomTheme,
    useNavigations,
    useCachedDirectoryOptions
} from '@hooks'
import { AccessibleAction } from '@hoc'
import { ToolbarDropdownMenu } from './toolbar-dropdown-menu'
import { ToolbarButton } from './toolbar-button'
import { ToolbarProps } from './types'

export const ToolbarButtonUI = ({ toolbarConfig }) => (
    <>
        {toolbarConfig?.toolbarIconConfig?.map(
            (config: ToolbarIconConfig, index) => (
                <Grid item key={index} mr={1}>
                    <IconButton
                        aria-label='close-modal'
                        onClick={config.action}
                    >
                        <config.icon />
                    </IconButton>
                </Grid>
            )
        )}
        {toolbarConfig?.addNewButtonConfig ? (
            <AccessibleAction
                permissions={toolbarConfig?.addNewButtonConfig?.permissions}
                component={
                    <ToolbarButton
                        config={{
                            ...toolbarConfig?.addNewButtonConfig
                        }}
                    />
                }
            />
        ) : null}
        {toolbarConfig?.dropdownMenuConfig && (
            <ToolbarDropdownMenu
                config={{
                    ...toolbarConfig?.dropdownMenuConfig
                }}
            />
        )}
    </>
)

export const Toolbar: React.FC<ToolbarProps> = ({ toolbarConfig }) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const [getCachedDirectoryOptions] = useCachedDirectoryOptions()

    const { breadcrumbComponent } = toolbarConfig?.showBreadcrumbs
        ? useNavigations({
              showBreadcrumbs: toolbarConfig?.showBreadcrumbs,
              fetchEntityVariables: toolbarConfig?.fetchEntityVariables
          })
        : { breadcrumbComponent: <></> }

    const [searchText, setSearchText] = useState(
        getCachedDirectoryOptions(toolbarConfig?.id)?.options?.search ?? ''
    )
    const onSearchTextChange = useCallback(
        debounce((value, isValueEntered) => {
            toolbarConfig?.searchConfig?.onSearch?.(value, isValueEntered)
        }, 1000),
        [toolbarConfig?.cardDirectoryConfig?.gridView]
    )

    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )
    const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('380'))
    const [displaySearch, setDisplaySearch] = useState(
        getCachedDirectoryOptions(toolbarConfig?.id)?.options?.search
            ? true
            : false
    )

    return (
        <Grid
            container
            borderBottom={0}
            borderTop={0}
            justifyContent='space-between'
            p={2}
        >
            <Grid item xs={6} md={5} display='flex' alignItems='center'>
                {displaySearch && toolbarConfig?.searchConfig ? (
                    <Grid item display='flex'>
                        <Grid item xs={8} md={8}>
                            <TextField
                                size='small'
                                autoFocus
                                placeholder={t('general.search')}
                                aria-label={t('general.searchDirectory')}
                                label={t('general.search')}
                                variant='standard'
                                value={searchText}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <SearchIcon
                                                height='24'
                                                width='24'
                                                color={
                                                    theme.palette
                                                        .neutralsTextLight
                                                }
                                            />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={(e) => {
                                    setSearchText(e?.target?.value)
                                    onSearchTextChange?.(e?.target?.value, true)
                                }}
                            />
                        </Grid>
                        <Grid item alignSelf='flex-start' xs={4} md={4}>
                            <IconButton
                                aria-label={t('general.cancel')}
                                onClick={() => {
                                    setDisplaySearch(false)
                                    onSearchTextChange?.('')
                                    setSearchText('')
                                }}
                            >
                                <CloseIcon height='24' width='24' />
                            </IconButton>
                        </Grid>
                    </Grid>
                ) : (
                    <Box display='flex' flexDirection='column'>
                        {toolbarConfig?.header && (
                            <Typography variant='h2'>
                                {toolbarConfig.header}
                            </Typography>
                        )}
                        <Typography variant='caption' component={'p'}>
                            {toolbarConfig?.caption}
                        </Typography>
                        {toolbarConfig?.showBreadcrumbs && breadcrumbComponent}
                    </Box>
                )}
            </Grid>
            <Grid
                item
                xs={6}
                md={7}
                display='flex'
                columnGap={2}
                justifyContent='flex-end'
            >
                {toolbarConfig?.searchConfig || toolbarConfig?.filterConfig ? (
                    <Grid item alignSelf='center'>
                        {toolbarConfig?.filterConfig && (
                            <>
                                {toolbarConfig?.filterConfig?.filterEnabled ? (
                                    <IconButton
                                        aria-label='enabled filter'
                                        onClick={() => {
                                            toolbarConfig?.filterConfig?.setFilterEnabled?.(
                                                false
                                            )

                                            toolbarConfig?.filterConfig?.onUnfilter?.()
                                        }}
                                    >
                                        <FilterActiveIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        aria-label='filter'
                                        onClick={() => {
                                            toolbarConfig?.filterConfig?.setFilterEnabled?.(
                                                true
                                            )

                                            toolbarConfig?.filterConfig?.onFilter?.()
                                        }}
                                    >
                                        <FilterIcon />
                                    </IconButton>
                                )}
                            </>
                        )}
                        {toolbarConfig?.searchConfig && (
                            <IconButton
                                aria-label='search icon'
                                onClick={() => setDisplaySearch(!displaySearch)}
                            >
                                <SearchIcon height='24' width='24' />
                            </IconButton>
                        )}
                    </Grid>
                ) : null}
                {toolbarConfig?.cardDirectoryConfig && !isMediumScreen ? (
                    <Grid item>
                        <IconSwitch
                            onChange={(e) =>
                                toolbarConfig?.cardDirectoryConfig?.setGridView?.(
                                    e?.target?.checked
                                )
                            }
                            hasIcon
                            ariaLabel={
                                !toolbarConfig?.cardDirectoryConfig?.gridView
                                    ? `${t('general.toggle.video')}`
                                    : `${t('general.toggle.directory')}`
                            }
                        />
                    </Grid>
                ) : null}
                {!smallScreen || !toolbarConfig?.header ? (
                    <ToolbarButtonUI toolbarConfig={toolbarConfig} />
                ) : null}
            </Grid>
            {smallScreen && toolbarConfig?.header && (
                <Grid
                    item
                    xs={6}
                    md={7}
                    display='flex'
                    columnGap={2}
                    mt={1}
                    justifyContent='flex-start'
                >
                    <ToolbarButtonUI toolbarConfig={toolbarConfig} />
                </Grid>
            )}
        </Grid>
    )
}
