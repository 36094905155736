import React, { forwardRef } from 'react'
import Highcharts from 'highcharts/highcharts'
import highchartsAccessibility from 'highcharts/modules/accessibility'
import HighchartsReact from 'highcharts-react-official'

const chartOptions = {
    chart: {
        type: 'line'
    },
    title: {
        text: 'Line Chart Example'
    },
    xAxis: {
        title: {
            text: 'X-axis'
        }
    },
    yAxis: {
        title: {
            text: 'Y-axis'
        }
    }
}
highchartsAccessibility(Highcharts) // initializing accessibility module for highcharts

// TODO later on might be we pass dynamic id
export const Chart = forwardRef(({ id = 'a', options = {} }, ref) => (
    <div>
        <HighchartsReact
            id={id}
            highcharts={Highcharts}
            options={options}
            ref={ref}
        />
    </div>
))
