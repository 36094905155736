import React from 'react'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GET_VIDEO_BY_ID, PUBLISH_VIDEO } from '@dts/graphql'
import { useAuth, useFormFeedback, useModalWithGraphQL, usePage } from '@hooks'
import { isEmpty } from 'lodash'
import { getAccessRoles, renderButtonForRole } from '@utils'
import { TextMessageIcon } from '@assets'
import { CommunicationTab } from './communication-tab'
import { ViewVideo } from './view-video'
import { VideoStatus } from '@dts/constants'
import { RejectVideoForm, SaveVideoForm } from '@types'
import { mapGenericFormToMutationInput } from './review-video-container'
import { unpublishVideoConfig } from './manage-admin-videos'

export const ViewVideoContainer = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const navigate = useNavigate()

    const {
        userInfo: { roles }
    } = useAuth()
    const accessedRoles = getAccessRoles(roles)
    const [searchParams] = useSearchParams()
    const videoTypeTab = searchParams.get('tab')
    const isAccountVideos = searchParams.get('isAccountVideos')

    const {
        methods,
        currentData,
        mutationLoading,
        queryLoading,
        save: publishVideo
    } = useFormFeedback({
        initialValues: {
            title: null,
            description: null,
            tags: [],
            hyperlinks: [],
            topicIds: [],
            reviews: [],
            skillId: null,
            states: [],
            topicsData: [],
            skillData: '',
            captionText: null,
            url: null
        },
        query: GET_VIDEO_BY_ID,
        queryName: 'getVideo',
        retrieveOnMount: true,
        queryVariables: { id },
        mapQueryResultToForm: (response) => {
            const {
                title,
                urlSrc,
                description,
                tags,
                skillId,
                topicIds,
                reviews,
                statesData,
                skillData,
                hyperlinks,
                topicsData,
                url,
                captionText,
                captionFileSrc,
                previousVersions,
                pendingStatus
            } = response
            return {
                title,
                description,
                tags: !isEmpty(tags) ? tags : [],
                skillId,
                topicIds,
                states: statesData?.map((s) => s?.id),
                url: urlSrc,
                urlSrc: url,
                hyperlinks: !isEmpty(hyperlinks) ? hyperlinks : [],
                captionText,
                captionFileSrc,
                previousVersions,
                topicsData,
                skillData,
                reviews,
                pendingStatus
            }
        },
        mutation: PUBLISH_VIDEO,
        mutationName: 'publishVideo',
        mapFormToMutationInput: (form: SaveVideoForm) =>
            mapGenericFormToMutationInput(form, id),
        onMutationSuccess: () => {
            navigate('/videos/approved')
        }
    })
    const { openDialog: openUnpublishDialog } = useModalWithGraphQL({
        ...unpublishVideoConfig(t, navigate),
        mapFormToMutationInput: (form: RejectVideoForm) => {
            const { title, details } = form
            return {
                id,
                message: {
                    title,
                    details
                }
            }
        },
        hasPrimaryExtraInput: true
    })
    const getPrimaryButtonConfig = () =>
        videoTypeTab &&
        renderButtonForRole(videoTypeTab, currentData, accessedRoles)
            ? {
                  tertiaryButtonTitle: t('general.edit'),
                  tertiaryButtonAction: () =>
                      navigate(`review?tab=${videoTypeTab}`)
              }
            : isAccountVideos
            ? {
                  primaryButtonTitle: t('sideNavItems.insights'),
                  primaryButtonAction: () =>
                      navigate(`/video-insights/${id}?insightTimeFrameId=LSD`)
              }
            : videoTypeTab?.toLowerCase() === VideoStatus.Approved
            ? {
                  primaryButtonTitle: [
                      t('video.unpublish'),
                      t('general.video')
                  ].join(' '),
                  primaryButtonAction: () => openUnpublishDialog()
              }
            : videoTypeTab?.toLowerCase() === VideoStatus.Unpublished
            ? {
                  primaryButtonTitle: [
                      t('video.publish'),
                      t('general.video')
                  ].join(' '),
                  primaryButtonAction: () => publishVideo()
              }
            : {}

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: ViewVideo,
            componentProps: { isAccountVideos },
            hasHeader: true,
            preventMargin: true,
            preventPadding: true,
            showBreadcrumbs: true,
            pageHeaderConfig: {
                headerText: `${t('general.view')} ${t('general.video')}`,
                ...getPrimaryButtonConfig()
            },
            hasColumnDirection: true,
            ...(!isAccountVideos && {
                overlayConfig: {
                    OverlayComponent: CommunicationTab,
                    hasFilterOverlay: true,
                    overlayIcon: <TextMessageIcon />
                }
            })
        }
    })

    return <>{page}</>
}
