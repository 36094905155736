import styled from '@emotion/styled'
import { Select } from '@mui/material'

export const StyledSelect = styled(Select)<{ isSubheading?: boolean }>(
    ({ theme, isSubheading }) => ({
        fontWeight: 500,

        '&.MuiInput-underline:before': {
            borderBottom: `2px solid ${theme.palette.BorderBrandPrimary}`
        },
        '&.MuiInput-underline:after': {
            borderBottom: `2px solid ${theme.palette.BorderBrandPrimary}`
        },
        '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: `2px solid ${theme.palette.BorderBrandPrimary}`
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent'
        },
        '& .MuiSelect-select': {
            fontSize: isSubheading ? '20px' : '16px'
        }
    })
)
export const IconWrapper = styled('div')({
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    cursor: 'pointer'
})
