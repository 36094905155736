import { Grid, styled, Typography } from '@mui/material'

export const StyledSearchTypography = styled(Typography)(
    ({ displayedLines }) => ({
        display: '-webkit-box',
        WebkitLineClamp: displayedLines,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%'
    })
)

export const SearchItemContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '6px',
    border: `0.25px solid ${theme.palette.borderSecondary}`,
    '&:hover': {
        backgroundColor: theme.palette.backgroundInfoSubtle,
        borderLeft: `1px solid ${theme.palette.contentLink}`,
        cursor: 'pointer'
    }
}))

export const StyledTypographyContainer = styled(Grid)(({ hasImage }) => ({
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    height: !hasImage && '80px'
}))
