import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const CircleIcon: React.FC<SvgProps> = ({ color, width = 22, height = 22 }) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 23 23'
            fill='none'
        >
            <circle cx='11' cy='11' r='10' stroke={_color} strokeWidth='2' />
        </svg>
    )
}
export default CircleIcon
