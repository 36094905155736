import { gql } from '@apollo/client'
import {
  MessagesFragment,
  MetaFragment,
} from '../../fragments'

export const DELETE_LEARNER_LIST_USER = gql`
  ${MetaFragment('LearnerListUserResponseDto')}
  ${MessagesFragment('LearnerListUserResponseDto')}
  mutation deleteLearnerListUser($input: DeleteLearnerListUserInputDto!) {
    deleteLearnerListUser(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        learnerListId
        userId
      }
    }
  }
`
