import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const UserSingleIcon: React.FC<SvgProps> = ({
    color,
    width = 24,
    height = 25
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.darkestBlue

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox={`0 0 24 25`}
            color={_color}
            fill='none'
        >
            <path
                d='M12.6427 15.5C9.22882 15.5 6.19289 17.0306 4.26006 19.406C3.84406 19.9172 3.63606 20.1728 3.64286 20.5183C3.64812 20.7852 3.82861 21.1219 4.05477 21.2867C4.34749 21.5 4.75314 21.5 5.56444 21.5H19.721C20.5323 21.5 20.9379 21.5 21.2306 21.2867C21.4568 21.1219 21.6373 20.7852 21.6425 20.5183C21.6493 20.1728 21.4413 19.9172 21.0253 19.406C19.0925 17.0306 16.0566 15.5 12.6427 15.5Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12.6427 12.5C15.3191 12.5 17.4888 10.4853 17.4888 8C17.4888 5.51472 15.3191 3.5 12.6427 3.5C9.96629 3.5 7.79662 5.51472 7.79662 8C7.79662 10.4853 9.96629 12.5 12.6427 12.5Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default UserSingleIcon
