import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '@mui/material'
import { URLSegmentInfo } from '@cacheql'
import { isEmpty } from 'lodash'

export const displayUserMultipleData = (data) =>
    data?.map(
        (obj, index) => `${obj.title}${index === data.length - 1 ? ' ' : ', '}`
    )

export const mapLabelsWithAutoIncrements = (data: Array<string>) =>
    data?.map((d: string, index) => ({ value: d, id: index + 1 }))

export const displayTextWithEllipsis = (
    text: string,
    ellipsisLength: number = 25
) =>
    text?.length > ellipsisLength
        ? `${text?.slice(0, ellipsisLength)}...`
        : text

export const formatDuration = (time: int) => {
    const [hours, mins] = time?.includes?.(':') ? time.split(':') : ['', time]

    const hoursInt = parseInt(hours)
    const minsInt = parseInt(mins)

    const totalHours =
        (hoursInt ? hoursInt : 0) + (minsInt ? Math.floor(minsInt / 60) : 0)
    const remainingMins = minsInt ? minsInt % 60 : 0

    return `${
        totalHours.toString() && totalHours.toString() !== '0'
            ? totalHours.toString() + ' hrs'
            : ''
    } ${
        remainingMins.toString() && remainingMins.toString() !== '0'
            ? remainingMins.toString() + ' mins'
            : ''
    }`
}

export const isMediumScreen = () =>
    useMediaQuery((theme) => theme.breakpoints.down('md'))

export const isSmallScreen = () =>
    useMediaQuery((theme) => theme.breakpoints.down('sm'))

export const extractAllValues = (obj) => {
    let values = []

    for (const value of Object.values(obj)) {
        if (typeof value === 'object' && value !== null) {
            values = values.concat(extractAllValues(value))
        } else {
            values.push(value)
        }
    }

    function customSort(a, b) {
        if (typeof a === 'number' && typeof b === 'number') {
            return a - b // Sort numbers numerically
        }

        if (typeof a === 'string' && typeof b === 'string') {
            return a.localeCompare(b) // Sort strings lexicographically
        }

        // Numbers come before strings
        if (typeof a === 'number') {
            return -1
        }

        return 1
    }

    return values.sort(customSort)
}

export const uniqueBy = (arr, key) => {
    const seen = new Set()
    return arr?.filter((obj) => {
        const objValue = obj[key]
        return seen.has(objValue) ? false : seen.add(objValue)
    })
}

export function setCookie(cName, cValue, expDays) {
    const date = new Date()
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000)
    const expires = 'expires=' + date.toUTCString()
    document.cookie = cName + '=' + cValue + '; ' + expires + '; path=/'
}

export function getCookie(cname) {
    const name = cname + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ''
}

export const deleteCookie = (name: string) => {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
        if (cookies[i].includes(name)) {
            const cookie = cookies[i]
            const eqPos = cookie.indexOf('=')
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
        }
    }
}

export const getUrlSegmentTitleById = (id: string) =>
    URLSegmentInfo()?.find((segment) => segment?.id === id)?.title

export const valueExists = (value?: string | null) =>
    value !== undefined && value !== null && !isEmpty(value)

export const getHeaderAriaLabel = (headerName: string) => {
    const { t } = useTranslation()

    if (headerName?.includes(t('general.numberOf'))) {
        return t('general.numberOfName', {
            name: headerName.split(t('general.numberOf'))[1]
        })
    } else {
        return headerName
    }
}

export const injectAriaLabelToInnerInput = (
    className: string,
    ariaLabel: string
) => {
    const hiddenTextareas = document.querySelectorAll(className)
    if (hiddenTextareas) {
        hiddenTextareas.forEach((textarea) => {
            textarea.setAttribute('aria-label', ariaLabel)
        })
    }
}

export const getAllPermissions = (menuConfig) =>
    menuConfig?.flatMap((item) => item?.permissions).filter(Boolean)
