import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const SurveyQuestionsTextIcon: React.FC<SvgProps> = ({
    color,
    width = '24',
    height = '24',
    fill = 'none'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 24 24'
            fill={fill}
        >
            <rect
                x='3'
                y='4'
                width='17'
                height='17'
                rx='3'
                stroke={_color}
                strokeWidth='1.5'
            />
            <path
                d='M14 17H7M11.5 13H7M16 9H7'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default SurveyQuestionsTextIcon
