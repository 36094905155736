import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, VideoFields } from '../../fragments'

export const GET_LIKED_VIDEOS = gql`
  ${MetaFragment('DashboardVideosResponseDto')}
  ${MessagesFragment('DashboardVideosResponseDto')}
  ${VideoFields('VideoDataDto')}
  query getLikedVideos($options: PaginationDto!) {
    getLikedVideos(options: $options) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...VideoFields
      }
    }
  }
`
