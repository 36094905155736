import { gql } from '@apollo/client'
import { MessagesFragment, SurveyFields } from '../../fragments'

export const UPDATE_SURVEY = gql`
  ${MessagesFragment('SurveyResponseDto')}
  ${SurveyFields('SurveyDto')}
  mutation updateSurvey($input: UpdateSurveyDto!) {
    updateSurvey(input: $input) {
      ...MessagesFragment
      data {
        ...SurveyFields
      }
    }
  }
`
