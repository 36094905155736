import { gql } from '@apollo/client'

export const ON_SURVEY_AVAILABLE = gql`
  subscription onSurveyAvailable {
    onSurveyAvailable {
      userId
      surveyTypeId
      courseId
      trainingId
      surveyQuestionIds
    }
  }
`
