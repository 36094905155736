export const getChartThumbnail = async (chartRef, reportId) => {
    if (chartRef.current && chartRef.current.chart) {
        const chart = chartRef.current.chart

        const chartExportingOptions = {
            enabled: false,
            chartOptions: {
                xAxis: {
                    labels: {
                        enabled: false // Hide xAxis labels during export
                    },
                    title: {
                        text: null // Hide xAxis title during export
                    }
                },
                yAxis: {
                    labels: {
                        enabled: false // Hide yAxis labels during export
                    },
                    title: {
                        text: null // Hide yAxis title during export
                    }
                },
                legend: {
                    enabled: true // Hide legend during export
                },
                title: {
                    text: null // Optionally, hide the title during export
                },
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false // Optionally, enable data labels during export
                        }
                    }
                }
            }
        }

        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                try {
                    // Convert chart to base64 image
                    const svg = chart.getSVG(chartExportingOptions.chartOptions)
                    const svgBlob = new Blob([svg], {
                        type: 'image/svg+xml;charset=utf-8'
                    })
                    const url = URL.createObjectURL(svgBlob)

                    const img = new Image()
                    img.onload = async () => {
                        const canvas = document.createElement('canvas')
                        canvas.width = img.width
                        canvas.height = img.height
                        const ctx = canvas.getContext('2d')
                        ctx.drawImage(img, 0, 0)
                        canvas.toBlob((blob) => {
                            if (blob) {
                                const file = new File(
                                    [blob],
                                    `${reportId}-thumbnail.png`,
                                    {
                                        type: 'image/png'
                                    }
                                )
                                resolve(file)
                            } else {
                                reject(new Error('Failed to create blob'))
                            }
                        }, 'image/png')
                        URL.revokeObjectURL(url)
                    }
                    img.src = url
                } catch (error) {
                    reject(error)
                }
            }, 500) // Shorter delay to ensure the chart updates are applied
        })
    } else {
        return Promise.reject(new Error('Chart reference is not available'))
    }
}
