export enum ReportTimeFrameEnum {
  Custom = 'CUS',
  Today = 'TDY',
  Yesterday = 'YDY',
  SevenDays = 'SDY',
  ThirtyDays = 'TYY',
  ThreeMonths = 'TMN',
  SixMonths = 'SMN',
  TwelveMonths = 'TVN',
}
export enum ReportTimeFrameGraphqlEnum {
  CUS = 'CUS',
  TDY = 'TDY',
  YDY = 'YDY',
  SDY = 'SDY',
  TYY = 'TYY',
  TMN = 'TMN',
  SMN = 'SMN',
  TVN = 'TVN',
}

export enum ReportGranularityEnum {
  Hourly = 'HLY',
  Daily = 'DLY',
  Weekly = 'WLY',
  Monthly = 'MLY',
}

export enum ReportGranularityGraphqlEnum {
  HLY = 'HLY',
  DLY = 'DLY',
  WLY = 'WLY',
  MLY = 'MLY',
}

export enum ReportMetricEnum {
  CompletionRate = 'CTR',
  ProgressionRate = 'PGR',
  LearningEffectiveness = 'LEF',
  OverallKnowledgeImprovement = 'OKI',
  SurveyAssignment = 'SAS',
  SurveyParticipation = 'SPA',
  SurveyAccessed = 'SAC',
  CorrectRate = 'COR',
  IncorrectRate = 'ICR'
}

export enum ReportMetricGraphqlEnum {
  CTR = 'CTR',
  PGR = 'PGR',
  LEF = 'LEF',
  OKI = 'OKI',
  SAS = 'SAS',
  SPA = 'SPA',
  SAC = 'SAC',
  COR = 'COR',
  ICR = 'ICR',
}

export enum ReportFilterTypeEnum {
  Numerical = 'NUM',
  Categorical = 'CAT',
}

export enum ReportFilterTypeGraphqlEnum {
  NUM = 'NUM',
  CAT = 'CAT',
}

export enum ReportFilterEnum {
  Course = 'COU',
  Topic = 'TOP',
  Training = 'TRG',
  Survey = 'SUR',
  Tag = 'TAG',
}

export enum ReportFilterGraphqlEnum {
  COU = 'COU',
  TOP = 'TOP',
  TRG = 'TRG',
  SUR = 'SUR',
  TAG = 'TAG',
}

export enum ReportBreakdownEnum {
  Age = 'AGE',
  Interests = 'INT',
  Training = 'TRG',
  Course = 'COR',
  Survey = 'SUR'
}

export enum ReportBreakdownGraphqlEnum {
  AGE = 'AGE',
  INT = 'INT',
  TRG = 'TRG',
  COR = 'COR',
  SUR = 'SUR'
}

export enum ChartTypeEnum {
  LineChart = 'LCH',
}

export enum ChartTypeGraphqlEnum {
  LCH = 'LCH',
}
