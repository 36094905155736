import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const GET_VIDEO_CONVERSATION = gql`
  ${MetaFragment('VideoConversationResponseDto')}
  ${MessagesFragment('VideoConversationResponseDto')}
  query getVideoConversation($videoId: String!) {
    getVideoConversation(videoId: $videoId) {
      ...MetaFragment
      ...MessagesFragment
      data {
        message
        title
        type
        userId
        userData {
          id
          firstName
          lastName
          role
        }
        createdAt
      }
    }
  }
`
