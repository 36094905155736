/* eslint-disable complexity */
import React, { useMemo } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box
} from '@mui/material'
import { useCustomTheme } from '@hooks'

import { CustomTableProps } from './types'
import { getDateColumns, getRowSpans } from './report-utils'

/**
 * CustomTable Component
 *
 * Renders a dynamic table based on the input columns and chart data.
 * Displays data in a table format.
 *
 * If additional columns are added, ensure that they are included in the `columns` prop
 * and are handled in the `breakdownData`, `transformData`, and `getDateColumns` functions.
 */
export const CustomTable = ({ columns, tableData }: CustomTableProps) => {
    // Get unique date columns from the table data
    const dateColumns = useMemo(() => getDateColumns(tableData), [tableData])

    // Get the custom theme for styling
    const { theme } = useCustomTheme()

    // Define border styles for the table cells
    const borderStyles = {
        border: `1px solid ${theme.palette.borderSecondary}`,
        color: theme.palette.contentSecondary
    }

    // Memoize row spans calculation to optimize performance
    const rowSpans = useMemo(() => {
        const spans: Record<string, Array<number>> = {}
        columns.forEach((col) => {
            spans[col] = getRowSpans(tableData, col)
        })
        return spans
    }, [columns, tableData])

    return (
        <Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {/* Render table headers */}
                            {columns.map((col, index) => (
                                <TableCell
                                    key={`header-${index}`}
                                    style={borderStyles}
                                >
                                    {col}
                                </TableCell>
                            ))}
                            {dateColumns.map((date, index) => (
                                <TableCell
                                    key={`date-${index}`}
                                    style={borderStyles}
                                >
                                    {date}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* Render table rows */}
                        {tableData.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {columns.map(
                                    (col) =>
                                        rowSpans[col][rowIndex] > 0 && (
                                            <TableCell
                                                key={`${col}-${rowIndex}`}
                                                rowSpan={
                                                    rowSpans[col][rowIndex]
                                                }
                                                style={borderStyles}
                                            >
                                                {row[col] || '- -'}
                                            </TableCell>
                                        )
                                )}
                                {dateColumns.map((date) => (
                                    <TableCell
                                        key={`date-${rowIndex}-${date}`}
                                        style={borderStyles}
                                    >
                                        {row[date] !== undefined
                                            ? row[date]
                                            : '- -'}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
