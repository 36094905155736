import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import {
    ErrorContainer,
    StyledGrid,
    StyledTypographyHeader,
    StyledAnchorTag
} from '../styles'

type ErrorMessages = {
    errorMessage?: string | undefined
}

export const SystemDown: React.FC<ErrorMessages> = ({ errorMessage }) => {
    const { t } = useTranslation()
    const { loginWithRedirect } = useAuth0()
    return (
        <ErrorContainer>
            <StyledGrid xs={10} md={6} p={4} container>
                <Grid item>
                    <StyledTypographyHeader>
                        {errorMessage?.includes('Invalid state')
                            ? t('authenticationError.message')
                            : t('systemDown.message')}
                    </StyledTypographyHeader>
                </Grid>

                <Grid item sx={{ marginTop: '20px' }}>
                    <StyledAnchorTag onClick={loginWithRedirect}>
                        {t('systemDown.redirect')}
                    </StyledAnchorTag>
                </Grid>
            </StyledGrid>
        </ErrorContainer>
    )
}
