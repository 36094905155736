import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import { controlled } from '@hoc'
import { DropdownIcon } from '@assets'
import { useCustomTheme } from '@hooks'
import { KeyboardShortcut, TypographyVariants } from '@constants'
import { Typography, Grid } from '@mui/material'
import { injectAriaLabelToInnerInput, onKeyDown } from '@utils'
import { NativeSelectProps } from './types'
import { IconWrapper, StyledSelect } from './style'

const CustomDropdownIcon = (props) => (
    <IconWrapper>
        <DropdownIcon {...props} />
    </IconWrapper>
)

export const NativeSelect: React.FC<NativeSelectProps> = (props) => {
    const { theme } = useCustomTheme()
    const { t } = useTranslation()
    const {
        title = '',
        options = [],
        showTitleBelow = false,
        isSubheading,
        name,
        value,
        ariaLabel = '',
        onChange
    } = props || {}

    const [open, setOpen] = useState(false)

    useEffect(() => {
        injectAriaLabelToInnerInput(
            '.MuiSelect-nativeInput[aria-hidden="true"]',
            ariaLabel
        )
    }, [])

    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <Grid
            container
            onClick={handleClick}
            tabIndex={0}
            onKeyDown={(e) => {
                onKeyDown(e, () => handleClick(), false)
            }}
        >
            <FormControl
                fullWidth
                variant='standard'
                sx={{ position: 'relative' }}
            >
                {!showTitleBelow && !isSubheading && (
                    <Typography
                        variant={TypographyVariants.h5}
                        color={theme.palette.contentTertiary}
                    >
                        {title}
                    </Typography>
                )}
                <StyledSelect
                    name={name}
                    value={value}
                    onChange={onChange}
                    IconComponent={CustomDropdownIcon}
                    label={!showTitleBelow ? title : ''}
                    disableUnderline
                    isSubheading={isSubheading}
                    open={open}
                >
                    {options?.map(({ value, label }) => (
                        <MenuItem
                            key={value}
                            value={value}
                            onKeyDown={(e) =>
                                onKeyDown(
                                    e,
                                    () => handleClick(),
                                    false,
                                    KeyboardShortcut.ESC
                                )
                            }
                        >
                            {label}
                        </MenuItem>
                    ))}
                </StyledSelect>
                {isSubheading && title && (
                    <Typography
                        variant={TypographyVariants.h5}
                        color={theme.palette.contentTertiary}
                    >
                        {title}
                    </Typography>
                )}
            </FormControl>
        </Grid>
    )
}

export const ControlledNativeSelect = controlled(NativeSelect)
