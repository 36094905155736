import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ADD_INFO_TO_USER, GET_USER, GET_USER_BY_ID } from '@dts/graphql'
import { useFormFeedback, usePage } from '@hooks'
import { SAVE_USER } from '@dts/graphql/lib'
import { AdminProfileSchema } from '@dts/client-utils'
import { setUserData } from '@cacheql'
import { AdminProfileForm } from './admin-profile'
import { UserPermissionEnum } from '@dts/constants'

export const ModifyAdminContainer: React.FC = ({
    userId,
    isClientAdmin = false
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { id: paramsId } = useParams()
    const location = useLocation()
    const [isUserActive, setIsUserActive] = useState(true)

    const id = userId ?? paramsId
    const isCurrentUser = setUserData()?.id === id

    const { methods, save, retrieve } = useFormFeedback({
        permissions: [
            UserPermissionEnum.UserCreateModify,
            UserPermissionEnum.AdminCreateModify
        ],
        initialValues: {
            email: null,
            firstName: null,
            lastName: null,
            officeNumber: null,
            mobileNumber: null,
            address: null,
            cityId: null,
            stateId: null,
            zipCode: null,
            roleId: null,
            avatarSrc: null,
            fileUploadKey: null,
            active: true,
            dateOfBirth: null,
            portalClients: [],
            adminPortalOnlyAccess: false
        },
        mapFormToMutationInput: (
            {
                firstName,
                lastName,
                email,
                phoneNumber,
                mobileNumber,
                address,
                cityId,
                stateId,
                zipCode,
                roleId,
                fileUploadKey,
                dateOfBirth,
                portalClients,
                adminPortalOnlyAccess,
                active
            },
            extraData
        ) => ({
            input: {
                id: isCurrentUser ? undefined : id ? id : undefined,
                avatar: fileUploadKey,
                firstName,
                lastName,
                address,
                mobileNumber,
                phoneNumber,
                cityId,
                stateId,
                zipCode,
                sendInvite: extraData?.sendInvite,
                dateOfBirth,
                adminPortalOnlyAccess: adminPortalOnlyAccess,
                portalInfo: portalClients?.map((client) => client?.id),
                ...(!isCurrentUser && {
                    email,
                    roleId,
                    active
                })
            }
        }),
        mutation: isCurrentUser ? ADD_INFO_TO_USER : SAVE_USER,
        mutationName: isCurrentUser ? 'addInformationToUser' : 'saveUser',
        isCustomMutationInput: true,
        onMutationSuccess: (response) => {
            const user = response?.data
            isCurrentUser &&
                setUserData({
                    ...setUserData(),
                    avatarSrc: user?.avatarSrc
                })

            const pathname = location?.pathname

            if (
                isCurrentUser &&
                !paramsId &&
                pathname?.includes('my-account')
            ) {
                navigate('/my-account')
            } else if (!pathname?.includes('edit-profile')) {
                // Directories when not current user
                isClientAdmin ? navigate('/users') : navigate('/admins')
            }
        },
        ...(id
            ? {
                  query: isCurrentUser ? GET_USER : GET_USER_BY_ID,
                  queryName: isCurrentUser ? 'getUser' : 'getUserById',
                  retrieveOnMount: true,
                  queryVariables: { id },
                  mapQueryResultToForm: ({
                      id,
                      email,
                      firstName,
                      lastName,
                      phoneNumber,
                      mobileNumber,
                      address,
                      cityId,
                      stateId,
                      zipCode,
                      roles,
                      avatarSrc,
                      dateOfBirth,
                      portalClients,
                      adminPortalOnlyAccess,
                      active
                  }) => ({
                      id: id ? id : undefined,
                      firstName,
                      lastName,
                      email,
                      phoneNumber,
                      mobileNumber,
                      address,
                      cityId,
                      stateId,
                      zipCode,
                      roleId: roles?.[0]?.id,
                      avatarUpload: avatarSrc,
                      dateOfBirth,
                      portalClients,
                      adminPortalOnlyAccess,
                      active
                  })
              }
            : undefined),
        yupResolverSchema: AdminProfileSchema
    })

    const tertiaryButtonConfig = !isCurrentUser && {
        tertiaryButtonTitle: t('general.save'),
        tertiaryButtonAction: methods.handleSubmit(() =>
            save({ sendInvite: false })
        )
    }

    const secondaryButtonConfig = (!isCurrentUser || paramsId) && {
        secondaryButtonTitle: t('general.cancel'),
        secondaryButtonAction: () =>
            isClientAdmin ? navigate('/users') : navigate('/admins')
    }

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: AdminProfileForm,
            componentProps: {
                isCurrentUser,
                isClientAdmin,
                isManageAdmin: paramsId,
                retrieve,
                setIsUserActive
            },
            hasHeader: true,
            preventMargin: true,
            showBreadcrumbs: true,
            pageHeaderConfig: {
                disablePrimaryButton: () => !isUserActive,
                headerText: id
                    ? t('pageHeader.admin.titleEdit')
                    : t('pageHeader.admin.titleCreate'),
                permissions: [
                    UserPermissionEnum.UserCreateModify,
                    UserPermissionEnum.AdminCreateModify
                ],
                primaryButtonTitle: isCurrentUser
                    ? t('general.save')
                    : t('editAdmin.saveAndSendInvite'),
                primaryButtonAction: () =>
                    methods.handleSubmit(() =>
                        save({ sendInvite: isCurrentUser ? undefined : true })
                    )(),
                ...secondaryButtonConfig,
                ...tertiaryButtonConfig
            }
        }
    })

    return <>{page}</>
}
