enum QuestionTypeEnum {
    YesNo = 'YNQ',
    SingleChoice = 'SCQ',
    MultiChoice = 'MCQ',
}

enum QuestionTypeGraphqlEnum {
    'YNQ' = 'YNQ',
    'SCQ' = 'SCQ',
    'MCQ' = 'MCQ',
}

export { QuestionTypeEnum, QuestionTypeGraphqlEnum }
