import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment, CourseFields } from '../../fragments'

export const GET_CLIENT_COURSES = gql`
  ${MessagesFragment('ClientCoursesResponseDto')}
  ${MetaFragment('ClientCoursesResponseDto')}
  ${CourseFields('CourseDto')}
  query getClientCourses($clientId: String!) {
    getClientCourses(clientId: $clientId) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...CourseFields
      }
    }
  }
`
