import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ControlledMultiSelectAutoComplete } from '@components/Select'
import { mapDropdownOptions } from '@dts/client-utils'
import { GET_TAGS } from '@dts/graphql'
import { useGraphQLWrapper } from '@hooks'
import { SearchableTagsProps } from './types'

export const SearchableTags = ({
    width,
    allowCustomAddition = true,
    name,
    customDataName,
    disabled,
    sx
}: SearchableTagsProps) => {
    const { t } = useTranslation()
    const [tags, setTags] = useState([])
    const { data: tagsData, queryLoading } = useGraphQLWrapper({
        query: GET_TAGS,
        queryName: 'getTags',
        retrieveOnMount: true
    })

    useEffect(() => {
        if (!queryLoading && tagsData) {
            const newTags =
                tagsData.data?.map((tag) => ({
                    id: tag.title,
                    title: tag.title
                })) || []
            setTags(newTags)
        }
    }, [tagsData, queryLoading])
    return (
        <ControlledMultiSelectAutoComplete
            id={name}
            name={name}
            customDataName={customDataName}
            label={t('general.tags')}
            options={mapDropdownOptions(tags)}
            allowCustomAddition={allowCustomAddition}
            width={width}
            disabled={disabled}
            sx={sx}
        />
    )
}
