import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useCardDirectory, useCustomTheme, useGraphQLWrapper } from '@hooks'
import {
    Article,
    CategoriesConfig,
    FilterLayout,
    LearningCenterCardContent
} from '@components'
import { SaveIcon } from '@assets'
import {
    GET_ARTICLES,
    GET_TOPICS,
    SAVE_ARTICLE_INTERACTION
} from '@dts/graphql'
import { cloneDeep } from 'lodash'

export type ReloadArticlesArgs = {
    topicId?: string
    saved?: boolean
    header?: string
}

export const CreatorLearningCenter = () => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const [topics, setTopics] = useState<Array<object>>([])
    const [activeCategory, setActiveCategory] = useState<string | undefined>(
        t('general.all')
    )
    const navigate = useNavigate()
    const allArticlesId = '1'

    const { queryLoading, data: topicsData } = useGraphQLWrapper({
        query: GET_TOPICS,
        queryName: 'getTopics',
        retrieveOnMount: true
    })

    const { save: saveArticleInteraction } = useGraphQLWrapper({
        mutation: SAVE_ARTICLE_INTERACTION,
        mutationName: 'saveArticleInteraction',
        isCustomMutationInput: true,
        onMutationSuccess: (res) => {
            // Update local state of data
            const updatedArticles = articles
                .map((article) => {
                    const newArticle = cloneDeep(article)
                    if (newArticle.id === res?.data?.article) {
                        if (
                            activeCategory === t('setting.saved') &&
                            res?.data?.saved === false
                        ) {
                            return null
                        } else {
                            newArticle.articleInteraction = res?.data
                        }
                    }

                    return newArticle
                })
                .filter(Boolean)

            setArticles(updatedArticles)
        }
    })

    const loadCardContent = (article: Article) => (
        <LearningCenterCardContent
            article={article}
            onClick={() => {
                saveArticleInteraction({
                    input: {
                        article: article?.id,
                        saved: !article?.articleInteraction?.saved
                    }
                })
            }}
        />
    )

    const {
        data: articles,
        setData: setArticles,
        setHeader,
        setQueryVariables,
        component: directoryComponent
    } = useCardDirectory({
        query: GET_ARTICLES,
        queryName: 'getArticles',
        excludedFeaturedHeaders: [t('learn.savedArticles.header')],
        queryVariables: {
            args: {
                topicId: null
            }
        },
        cardConfig: {
            loadCardContent,
            onCardClick: (data: object) => {
                navigate(`${data?.id}`)
            }
        }
    })

    const reloadArticles = ({ topicId, saved, header }: ReloadArticlesArgs) => {
        setHeader(header)
        setQueryVariables({
            args: {
                topicId,
                saved
            },
            options: {
                page: 1,
                pageSize: 10
            }
        })
    }

    useEffect(() => {
        if (!queryLoading && topicsData) {
            const allTopics: Array<object> = [
                { id: allArticlesId, title: t('general.all') },
                ...(topicsData?.data || [])
            ]

            setTopics(
                allTopics?.map((topic) => ({
                    ...topic,
                    label: topic.title,
                    onClick: () => {
                        setActiveCategory(topic.title)
                        reloadArticles(
                            topic?.id !== allArticlesId
                                ? {
                                      topicId: topic?.id,
                                      header: topic.title
                                  }
                                : {}
                        )
                    }
                }))
            )
        }
    }, [queryLoading, topicsData])

    // return directory
    return (
        <>
            <FilterLayout
                pageHeaderConfig={{
                    headerText: t('learn.learningCenter.header'),
                    showMenu: false,
                    showBreadcrumbs: true
                }}
                filterConfig={{
                    selectedCategory: allArticlesId,
                    categoriesHeader: t('general.categories').toUpperCase(),
                    categoriesHeaderColor: theme.palette.neutralsTextLight,
                    categoriesArray: topics as Array<CategoriesConfig>,
                    hasStaticCategories: true,
                    staticCategoriesArray: [
                        {
                            id: '2',
                            name: t('setting.saved'),
                            icon: SaveIcon,
                            onClick: () => {
                                setActiveCategory(t('setting.saved'))
                                reloadArticles({
                                    saved: true,
                                    header: t('learn.savedArticles.header')
                                })
                            }
                        }
                    ]
                }}
                directoryComponent={directoryComponent}
            />
        </>
    )
}
