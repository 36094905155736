import { Button, Grid, List, ListItem, styled } from '@mui/material'

export const StyledList = styled(List)`
    font-size: 12px;
    list-style-type: disc;
    list-style-position: inside;
    padding: 1px;
`
export const StyledListItem = styled(ListItem)`
    display: list-item;
    padding-left: 0px;
`

export const StyledOutlinedButton = styled(Button)`
    border: none;
    border-radius: 12px;
    &:hover {
        border: none;
    }
`
export const PanelContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.backgroundSecondary,
    borderRadius: '12px',
    border: `1px solid ${theme.palette.borderSecondary}`,
    padding: theme.spacing(2)
}))
export const IconContainerStyled = styled(Grid)(({ iconBackgroundColor }) => ({
    backgroundColor: iconBackgroundColor,
    height: '85%',
    borderRadius: '8px',
    padding: '8px'
}))
