import React from 'react'
import { Grid, Typography } from '@mui/material'

export type TruncatedTextProps = {
    text: string
    lines?: number
    fontSize?: number
    fontWeight?: number
}

export const TruncatedText: React.FC<TruncatedTextProps> = ({
    text,
    lines = 3,
    fontSize = 12,
    fontWeight = 400
}) => (
    <Grid
        item
        xs={12}
        md={12}
        sx={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: lines,
            overflow: 'hidden'
        }}
    >
        <Typography
            variant='body2'
            component='div'
            fontSize={fontSize}
            fontWeight={fontWeight}
        >
            {text}
        </Typography>
    </Grid>
)
