import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { useGraphQL } from '@dts/client-utils'
import { GET_SURVEY_RESULT_DETAIL } from '@dts/graphql'
import { useCustomTheme, useGlobal, usePageHeader } from '@hooks'
import {
    CloseIcon,
    MultipleUsersIcon,
    SurveyQuestionsTextIcon,
    TextMessageIcon,
    TickIcon
} from '@assets'
import { isNull } from 'lodash'
import { EmptyState } from '@components'
import { SurveyTypeEnum } from '@dts/constants'
import { ResultDetails } from './result-details'
import { ResultMetrics } from './result-metrics'

export const SurveyResults: React.FC = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const { theme } = useCustomTheme()
    const { isMediumScreen } = useGlobal()

    const [searchParams] = useSearchParams()
    const surveyType = searchParams.get('surveyType')

    const { pageHeader } = usePageHeader({
        headerText: t('surveyResults.header'),
        showBreadcrumbs: true,
        breadcrumbSearchParamAccessor: 'surveyType'
    })

    const { data: resultDetails, queryLoading } = useGraphQL({
        query: GET_SURVEY_RESULT_DETAIL,
        queryName: 'getSurveyResults',
        retrieveOnMount: true,
        queryVariables: { id }
    })

    const columnHeaders = [
        t('general.questions'),
        ...(!isMediumScreen
            ? surveyType === SurveyTypeEnum.Pulse
                ? [t('general.correct/incorrect')] // Add the specific header if survey type is 'PLS'
                : [
                      t('general.responses'), // Otherwise, use the regular headers
                      t('general.percentage'),
                      t('general.responses')
                  ]
            : [])
    ]

    const setResultMetrics = (metricsData) => [
        {
            title: t('general.questions'),
            value: metricsData?.questionsCount,
            Icon: (
                <SurveyQuestionsTextIcon
                    color={theme.palette.backgroundBrandPrimary}
                />
            )
        },
        {
            title: t('surveyResults.assignee'),
            value:
                surveyType === SurveyTypeEnum.Pulse
                    ? null
                    : metricsData?.assigneesCount,
            Icon: <MultipleUsersIcon color={theme.palette.contentNotice} />
        },
        {
            title: t('surveyResults.totalResponses'),
            value:
                surveyType === SurveyTypeEnum.Pulse
                    ? null
                    : metricsData?.responsesCount,
            Icon: <TextMessageIcon color={theme.palette.navyBlue} />
        },

        {
            title: t('surveyResults.correctAnswers'),
            value: metricsData?.correctAnswersCount,
            Icon: (
                <TickIcon
                    color={theme.palette.brightGreen}
                    height={26}
                    width={26}
                />
            )
        },
        {
            title: t('surveyResults.incorrectAnswers'),
            value: metricsData?.incorrectAnswersCount,
            Icon: (
                <CloseIcon
                    color={theme.palette.brandRedLight}
                    height={26}
                    width={26}
                />
            )
        }
    ]

    return (
        !queryLoading && (
            <>
                {pageHeader}
                {isNull(resultDetails?.data) ? (
                    <EmptyState
                        header={t('surveyResults.empty.header')}
                        subHeader={t('surveyResults.empty.subHeader')}
                    />
                ) : (
                    <>
                        <ResultMetrics
                            title={resultDetails?.data?.title}
                            metrics={setResultMetrics(resultDetails?.data)}
                        />

                        <ResultDetails
                            columnHeaders={columnHeaders}
                            surveyQuestions={
                                resultDetails?.data?.surveyQuestions
                            }
                            surveyType={surveyType}
                        />
                    </>
                )}
            </>
        )
    )
}
