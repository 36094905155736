import { gql } from '@apollo/client'

export const AddLearnerFields = (on: string) => gql`
    fragment AddLearnerFields on ${on} {
        type
        learnerListId
        learners
        email
        firstName
        lastName
        userId
        tags
    }
`
