enum SkillEnum {
  Beginner = 'BEG',
  Advanced = 'ADV',
  All = 'ALV',
}

enum SkillGraphqlEnum {
  'BEG' = 'BEG',
  'ADV' = 'ADV',
  'ALV' = 'ALV',
}

export { SkillEnum, SkillGraphqlEnum }
