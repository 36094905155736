import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { ControlledRadioGroup, ControlledTextInput } from '@components'
import { Grid, Typography } from '@mui/material'
import { HelperTextPosition, ReportVideoEnum } from '@constants'
import { mapDropdownOptions } from '@dts/client-utils'
import { useGraphQLWrapper } from '@hooks'
import {
    GET_REPORT_VIDEO_OPTIONS,
    GET_SHOW_LESS_VIDEO_OPTIONS
} from '@dts/graphql'

export const Report: React.FC<{ isReportVideo?: boolean }> = ({
    isReportVideo
}) => {
    const { t } = useTranslation()
    const { control, setValue } = useFormContext()
    const selectedOption = useWatch({ control, name: 'selectedOption' })
    const inputRef = useRef(null)

    const { data: options } = useGraphQLWrapper({
        query: isReportVideo
            ? GET_REPORT_VIDEO_OPTIONS
            : GET_SHOW_LESS_VIDEO_OPTIONS,
        queryName: isReportVideo
            ? 'getReportVideoOptions'
            : 'getShowLessVideoOptions',
        retrieveOnMount: true
    })

    useEffect(() => {
        options && setValue('selectedOption', options?.[0]?.id)
    }, [options])

    useEffect(() => {
        if (selectedOption === ReportVideoEnum.other && inputRef.current) {
            inputRef.current.focus()
        }
    }, [selectedOption])

    return (
        <Grid container>
            <Grid
                item
                display={'flex'}
                xs={12}
                md={12}
                direction='column'
                gap={4}
            >
                {!isReportVideo && (
                    <Typography
                        variant='h1'
                        sx={{
                            fontWeight: 700
                        }}
                    >
                        {t('reportDialog.header')}
                    </Typography>
                )}
                <ControlledRadioGroup
                    name='selectedOption'
                    isButton
                    options={mapDropdownOptions(options)}
                    hasDivider={true}
                />
                {selectedOption === ReportVideoEnum.other && (
                    <ControlledTextInput
                        id='description'
                        label={''}
                        placeholder={t('reportDialog.description.placeholder')}
                        name='description'
                        multiline
                        rows={3}
                        helperTextConfig={{
                            wordLength: 1000,
                            helperTextPosition: HelperTextPosition.Right
                        }}
                        fullWidth
                        inputRef={inputRef}
                    />
                )}
            </Grid>
        </Grid>
    )
}
