import { MessagesFragment, MetaFragment } from '../../fragments'

import { gql } from '@apollo/client'

export const GET_FEATURES = gql`
  ${MessagesFragment('FeaturesResponseDto')}
  ${MetaFragment('FeaturesResponseDto')}
  query getFeatures($clientId: String) {
    getFeatures(clientId: $clientId) {
      ...MessagesFragment
      ...MetaFragment
      data {
        id
        title
        displayOrder
        actions {
          id
          title
          available
          displayOrder
          dependsOn {
            id
            title
            available
            displayOrder
            relationshipTypeId
            joinTypeId
          }
        }
      }
    }
  }
`
