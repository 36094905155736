import { styled, Grid, Button } from '@mui/material'

export const SectionContainer = styled(Grid)(({ theme, isAdded }) => ({
    maxHeight: '220px',
    overflow: 'auto',

    borderLeft: `2px solid ${
        isAdded ? theme.palette.successMain : theme.palette.contentNegative
    } `
}))

export const SearchableListButton = styled(Button)(({ theme }) => ({
    width: '50px',
    height: '30px',
    backgroundColor: 'none',
    border: 'none',
    outline: 'none',
    color: theme.palette.navyBlue,
    padding: 0,
    boxShadow: 'none',
    background: 'none',
    '&:disabled': {
        opacity: 1,
        cursor: 'not-allowed'
    }
}))
