import React, { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GET_CAMPUS } from '@dts/graphql'
import { useFormFeedback, usePage } from '@hooks'
import { ClientDetailsForm } from './client-details-form'
import { CampusDetailsContainerProps } from './types'
import { UserPermissionEnum } from '@dts/constants'

export const CampusDetailsContainer: FC<CampusDetailsContainerProps> = (
    props
) => {
    const { tertiaryButtonAction, campusId } = props || {}
    const { t } = useTranslation()
    const { id: clientId } = useParams()

    const { methods, mutationLoading, queryLoading } = useFormFeedback({
        initialValues: {
            id: '',
            title: '',
            email: '',
            active: true,
            phone: '',
            address: '',
            state: '',
            city: '',
            zipCode: '',
            logoSrc: ''
        },
        query: GET_CAMPUS,
        queryName: 'getCampus',
        retrieveOnMount: true,
        queryVariables: { campusId, clientId },
        mapQueryResultToForm: ({
            id = '',
            title,
            email,
            active,
            phone,
            address,
            state,
            city,
            zipCode,
            logoSrc
        }) => ({
            id,
            title,
            email,
            active,
            phone,
            address,
            state: state.title,
            city: city.title,
            zipCode,
            logoSrc
        })
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: ClientDetailsForm,
            hasHeader: true,
            preventMargin: true,
            pageHeaderConfig: {
                headerText: t('pageHeader.clientDetails'),
                tertiaryButtonTitle: t('general.edit'),
                tertiaryButtonAction,
                permissions: [UserPermissionEnum.ClientCreateModify]
            }
        }
    })

    return <>{page}</>
}
