import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DraftIcon, SaveAsIcon, SaveIcon } from '@assets'
import {
    QuestionTypeEnum,
    ResponseTypeEnum,
    SurveyTypeEnum,
    UserPermissionEnum
} from '@dts/constants'
import { useCustomTheme, useFormFeedback, usePage } from '@hooks'

import { GET_SURVEY } from '@dts/graphql'
import { QUESTION_TYPES_TITLE } from '@constants'
import { SAVE_SURVEY } from '@dts/graphql/lib'
import { SurveyQuestionsSchema } from '@dts/client-utils'
import { isEmpty } from 'lodash'
import { QUESTION_FEEDBACK_TYPES } from '@dts/constants/lib'
import { SurveyCreationDetails } from './survey-creation'

export const getAddedSurveyCourses = (coursesAdded, addedCourses) =>
    coursesAdded?.map((course) => {
        const courseId = addedCourses?.some(
            (alreadyAddedCourse) => alreadyAddedCourse.id === course.id
        )
            ? { id: course?.id }
            : { courseId: course?.id }
        return {
            ...courseId,
            topics: course?.courseTopics
                ?.filter((topic) => topic?.isAssigned)
                ?.map((topic) => topic?.topicId)
        }
    })

export const mapSurveyCourses = (surveyCourses) =>
    surveyCourses?.map((course) => ({
        id: course?.id,
        courseId: course?.course?.id,
        title: course?.course?.title,
        topicsCount: course?.topics?.filter((topic) => topic?.isAssigned)
            ?.length,
        courseTopics: course?.topics?.map((courseTopic) => ({
            topicId: courseTopic?.id,
            isAssigned: courseTopic?.isAssigned,
            topic: {
                id: courseTopic?.id,
                title: courseTopic?.title
            }
        }))
    }))
export const CreateSurvey: React.FC = () => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()

    const { id } = useParams()
    const [searchParams] = useSearchParams()
    const surveyTypeId = searchParams.get('surveyType') // Type of survey being created
    const [disableDraft, setDisableDraft] = useState(false)

    useEffect(() => {
        if (!surveyTypeId) {
            navigate(`/surveys/new?surveyType=${SurveyTypeEnum.PreTest}`)
        }
    })

    const navigate = useNavigate()

    const mapQueryResultToForm = (result) => {
        const {
            id,
            title,
            surveyType,
            summaryEnabled,
            summaryTitle,
            summaryDescription,
            summaryResponseIcon,
            isDraft,
            isArchived,
            surveyQuestions,
            course,
            topic
        } = result || {}

        if (id && !isDraft) {
            setDisableDraft(true)
        } else {
            setDisableDraft(false)
        }
        return {
            id,
            title,
            surveyType: id ? surveyType?.id : surveyTypeId,
            summaryEnabled,
            summaryTitle,
            summaryDescription,
            summaryResponseIconId: summaryResponseIcon?.id,

            questions: surveyQuestions?.map((question) => ({
                questionId: question?.id,
                alreadyAdded: true,
                question: question?.title,
                questionType: question?.questionType?.id,
                titleUpdated: true,
                topicId: question?.topic?.id,
                skillId: question?.skill?.id,
                feedbackId: question?.questionFeedbackType?.id,

                ...(question?.questionFeedbackType?.id ===
                    QUESTION_FEEDBACK_TYPES.CommonFeedback && {
                    commonFeedback: (() => {
                        const commonResponse =
                            question?.surveyQuestionResponses?.find(
                                (response) =>
                                    response?.responseType?.id ===
                                    ResponseTypeEnum.Common
                            )

                        return commonResponse
                            ? {
                                  multiAnswerId:
                                      commonResponse?.responseType?.id,
                                  summaryResponseIconId:
                                      commonResponse?.summaryResponseIcon?.id,
                                  summaryDescription:
                                      commonResponse?.summaryDescription,
                                  summaryTitle: commonResponse?.summaryTitle
                              }
                            : null
                    })()
                }),
                ...(question?.questionFeedbackType?.id ===
                    QUESTION_FEEDBACK_TYPES.CorrectIncorrectFeedback && {
                    correctFeedback: (() => {
                        const correctResponse =
                            question?.surveyQuestionResponses.find(
                                (response) =>
                                    response?.responseType?.id ===
                                    ResponseTypeEnum.Correct
                            )
                        const wrongResponse =
                            question?.surveyQuestionResponses.find(
                                (response) =>
                                    response?.responseType?.id ===
                                    ResponseTypeEnum.Wrong
                            )

                        return [correctResponse, wrongResponse]
                            ?.filter(Boolean)
                            ?.map((response) => ({
                                summaryResponseIconId:
                                    response?.summaryResponseIcon?.id,
                                summaryTitle: response?.summaryTitle,
                                summaryDescription: response?.summaryDescription
                            }))
                    })()
                }),

                questionTypeTitle:
                    QUESTION_TYPES_TITLE[question?.questionType?.id],
                selectedOption:
                    question?.questionType?.id === QuestionTypeEnum.MultiChoice
                        ? question?.surveyOptions
                              ?.filter?.((option) => option?.isAnswer)
                              ?.map((option) => option?.id)
                        : question?.surveyOptions?.filter?.(
                              (option) => option?.isAnswer
                          )?.[0]?.id,
                hideQuestion: question?.isHidden,
                surveyQuestionResponses: question?.surveyQuestionResponses?.map(
                    (surveyQuestionResponse) => ({
                        ...surveyQuestionResponse,
                        multiAnswerId: surveyQuestionResponse?.responseType?.id,
                        summaryResponseIconId:
                            surveyQuestionResponse?.summaryResponseIcon?.id
                    })
                ),
                options: question?.surveyOptions?.map((option) => ({
                    id: option?.id,
                    optionId: option?.title,
                    alreadyAdded: true,
                    title: option?.title,
                    isEditing: false,
                    titleUpdated: true,
                    summaryEnabled: option?.summaryEnabled,
                    summaryTitle: option?.summaryTitle,
                    summaryDescription: option?.summaryDescription,
                    summaryResponseIconId: option?.summaryResponseIcon?.id,
                    surveyRecommendations: !isEmpty(
                        option?.surveyRecommendations
                    )
                        ? {
                              recommendations:
                                  option?.surveyRecommendations?.recommendations?.map(
                                      (recommendation) => ({
                                          id: recommendation?.id,
                                          recommendationTypeId:
                                              recommendation?.recommendationType
                                                  ?.id,
                                          skills: recommendation?.skills?.map(
                                              (skill) => skill?.id
                                          ),
                                          tags: recommendation?.tags
                                      })
                                  )
                          }
                        : surveyRecommendationsInitialValues
                })),
                isEditing: false
            })),

            courseId: course?.id,
            topicId: topic?.id,
            course: {
                value: course?.id,
                key: course?.title
            }
        }
    }

    const updateSurveyConfig = id && {
        query: GET_SURVEY,
        queryName: 'getSurvey',
        queryVariables: { id },
        mapQueryResultToForm
    }

    const getMutationInput = (formValues) => {
        const {
            title,
            summaryEnabled,
            questions,
            surveyType,
            summaryResponseIconId,
            summaryTitle,
            summaryDescription,
            courseId,
            topicId,
            course
        } = formValues || {}

        return {
            id,
            title,
            surveyTypeId: id ? surveyType : surveyTypeId,
            summaryEnabled,
            summaryTitle,
            summaryDescription,
            summaryResponseIconId,
            surveyQuestions: questions?.map((question) => {
                const selectedOptions = Array.isArray(question?.selectedOption)
                    ? question?.selectedOption
                    : [question?.selectedOption]

                return {
                    id: question?.alreadyAdded && question?.questionId,
                    title: question?.question,
                    topicId: question?.topicId,
                    skillId: question?.skillId,
                    questionTypeId: question?.questionType,
                    isHidden: question?.hideQuestion,
                    questionFeedbackTypeId:
                        question?.feedbackId ||
                        QUESTION_FEEDBACK_TYPES.NoFeedback,
                    surveyQuestionResponses:
                        question?.feedbackId ===
                        QUESTION_FEEDBACK_TYPES.CorrectIncorrectFeedback
                            ? question?.correctFeedback?.map(
                                  (surveyQuestionResponse, index) => ({
                                      responseTypeId:
                                          index === 0
                                              ? ResponseTypeEnum.Correct
                                              : ResponseTypeEnum.Wrong,
                                      summaryTitle:
                                          question?.correctFeedback?.[index]
                                              ?.summaryTitle ||
                                          surveyQuestionResponse?.summaryTitle,
                                      summaryDescription:
                                          question?.correctFeedback?.[index]
                                              ?.summaryDescription ||
                                          surveyQuestionResponse?.summaryDescription,
                                      summaryResponseIconId:
                                          question?.correctFeedback?.[index]
                                              ?.summaryResponseIconId ||
                                          surveyQuestionResponse?.summaryResponseIconId
                                  })
                              )
                            : question?.feedbackId ===
                              QUESTION_FEEDBACK_TYPES.CommonFeedback
                            ? [
                                  {
                                      responseTypeId:
                                          QUESTION_FEEDBACK_TYPES.CommonFeedback,
                                      summaryTitle:
                                          question?.commonFeedback
                                              ?.summaryTitle,
                                      summaryDescription:
                                          question?.commonFeedback
                                              ?.summaryDescription,
                                      summaryResponseIconId:
                                          question?.commonFeedback
                                              ?.summaryResponseIconId
                                  }
                              ]
                            : null,

                    surveyOptions: question?.options?.map((option) => ({
                        id: option?.alreadyAdded && option?.id,
                        title: option?.title,
                        isAnswer: selectedOptions?.includes(option?.id),
                        surveyRecommendations: option?.surveyRecommendations,
                        summaryDescription: option?.summaryDescription
                    }))
                }
            }),
            topicId,
            courseId: course?.value
        }
    }

    const mapFormToMutationInput = (formValues) => ({
        ...getMutationInput(formValues),
        isDraft: false
    })

    const {
        methods,
        retrieve: retrieveGetReport,
        save,
        queryLoading: getReportByIdLoading
    } = useFormFeedback({
        permissions: [UserPermissionEnum.SurveyCreateModify],
        ...updateSurveyConfig,
        mutation: SAVE_SURVEY,
        mutationName: 'saveSurvey',
        mapFormToMutationInput,
        onMutationSuccess: (response) => {
            response?.data?.isArchived
                ? navigate('/surveys/archived')
                : response?.data?.surveyTypeId === SurveyTypeEnum.Pulse
                ? navigate('/surveys/pulse')
                : navigate('/surveys/pre-and-post')
        },
        initialValues: {
            title: null,
            summaryTitle: null,
            summaryDescription: null,
            questions: [],
            summaryEnabled: false,
            summaryResponseIconId: null,
            course: null,
            surveyType: surveyTypeId
        },
        yupResolverSchema: SurveyQuestionsSchema
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            showBreadcrumbs: true,
            ComponentToRender: SurveyCreationDetails,
            hasHeader: true,
            preventPadding: true,
            pageHeaderConfig: {
                headerText: 'New Survey',
                primaryButtonTitle: t('general.save'),
                primaryButtonIcon: (
                    <SaveAsIcon
                        color={theme.palette.contentPrimaryInverse}
                        width={20}
                        height={20}
                    />
                ),
                permissions: [UserPermissionEnum.SurveyCreateModify],
                primaryButtonAction: () => methods.handleSubmit(() => save())(),
                secondaryButtonTitle: t('general.cancel'),
                secondaryButtonAction: () =>
                    surveyTypeId === SurveyTypeEnum.Pulse
                        ? navigate('/surveys/pulse')
                        : navigate('/surveys/pre-and-post'),
                kebabMenuConfig: !disableDraft
                    ? [
                          {
                              label: t('general.saveDraft'),
                              icon: DraftIcon,
                              onClick: () => {
                                  methods?.clearErrors()
                                  methods?.trigger('title')?.then((isValid) => {
                                      isValid &&
                                          save({
                                              isDraft: true
                                          })
                                  })
                              }
                          }
                      ]
                    : undefined
            }
        }
    })

    return <>{page}</>
}
