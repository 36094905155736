import React from 'react'
import { useCustomTheme } from '@hooks'

const ReloadIcon = ({ color, height = '24', width = '24' }) => {
    const { theme } = useCustomTheme() // Access the theme from the hook
    const iconColor = color || theme.palette.contentPrimary

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M3 10C3 10 5.00498 7.26822 6.63383 5.63824C8.26269 4.00827 10.5136 3 13 3C17.9706 3 22 7.02944 22 12C22 16.9706 17.9706 21 13 21C8.89691 21 5.43511 18.2543 4.35177 14.5M3 10V4M3 10H9'
                stroke={iconColor} // Use the theme or custom color
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default ReloadIcon
