import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
    useCustomTheme,
    useGraphQLWrapper,
    useInfiniteScroll,
    usePageHeader
} from '@hooks'
import { isMediumScreen } from '@utils'
import { GET_LIKED_VIDEOS, SAVE_VIDEO_INTERACTION } from '@dts/graphql'
import { VideoCardGrid } from '@components'
import { LikeIcon } from '@assets'
import { Grid } from '@mui/material'
import { BackButtonType } from '@constants'

export const LikedVideos = () => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    const navigate = useNavigate()
    const [likedStatus, setLikedStatus] = useState<{
        [k: number]: boolean
    }>({})

    const { pageHeader } = usePageHeader({
        headerText: t('video.likedVideos'),
        backButtonConfig: {
            showBackButton: isMediumScreen?.(),
            backButtonType: BackButtonType.Hierarchical
        }
    })

    const { save: saveVideoInteraction } = useGraphQLWrapper({
        mutation: SAVE_VIDEO_INTERACTION,
        mutationName: 'saveVideoInteraction',
        onMutationSuccess: (response) =>
            setLikedStatus((prev) => ({
                ...prev,
                [response?.data?.videoId]: response?.data?.liked
            }))
    })

    const loadComponent = (mergedData: Array<object>) => (
        <Grid p={2}>
            <VideoCardGrid
                cardData={mergedData?.map((data) => ({
                    ...data,
                    onClick: () => navigate(`/browse-videos/watch/${data?.id}`)
                }))}
                cardAction={(params) => {
                    const isLiked = likedStatus?.[params?.id]
                    return {
                        icon: () => (
                            <LikeIcon
                                color={theme.palette.contentSecondary}
                                fill={
                                    isLiked
                                        ? theme.palette.contentNegative
                                        : 'none'
                                }
                            />
                        ),

                        ariaLabel: isLiked
                            ? [t('general.unLike'), t('general.video')].join(
                                  ' '
                              )
                            : [t('general.like'), t('general.video')].join(' '),
                        onActionClick: () => {
                            if (params?.id) {
                                saveVideoInteraction({
                                    videoId: params?.id,
                                    liked: !isLiked
                                })
                            }
                        }
                    }
                }}
            />
        </Grid>
    )

    const { wrappedComponent, data } = useInfiniteScroll({
        query: GET_LIKED_VIDEOS,
        queryName: 'getLikedVideos',
        loadComponent
    })

    useEffect(() => {
        if (data) {
            const likedVideos = {}

            data?.forEach((data) => {
                likedVideos[data.id] = true
            })

            setLikedStatus(likedVideos)
        }
    }, [data])

    return (
        <>
            {pageHeader}
            {wrappedComponent}
        </>
    )
}
