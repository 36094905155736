import * as yup from 'yup'

export const CreateArticleSchema = yup.object().shape({
  articleHeader: yup.string().trim().required('errors.required'),
  topic: yup.string().required('errors.required').trim().min(1),
  time: yup
    .number()
    .typeError('errors.number')
    .positive('errors.number.positive')
    .required('errors.required')
    .nullable(),
  tags: yup.array().of(yup.string()).max(7, 'tags.limit'),
})
