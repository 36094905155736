import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { LicenseInfo } from '@mui/x-license-pro'
import config from '@config'
import App from './App'
import registerServiceWorker from './serviceWorkerRegistration'
// TODO might be used in future.
// import 'typeface-montserrat' // Import the Montserrat font
import './i18n'
import 'typeface-roboto' // Import the Roboto font
import 'video.js/dist/video-js.css'
import 'react-quill/dist/quill.snow.css'

LicenseInfo.setLicenseKey(config.MUI_LICENSE_KEY)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    // <React.StrictMode>
    <App />
    // </React.StrictMode>
)

registerServiceWorker()
