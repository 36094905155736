import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { GET_COURSES } from '@dts/graphql'
import { useFormFeedback, useGraphQLWrapper, usePage } from '@hooks'
import { getAddedCourses, SearchableCoursesList } from '@containers'
import { BaseFields } from '@types'
import { CoursesProps } from './types'
import { UserPermissionEnum } from '@dts/constants'

export const useCourses: React.FC<CoursesProps> = ({
    campusId = '',
    addedIn = '',
    mutation,
    mutationName,
    query,
    queryName
}) => {
    const { t } = useTranslation()

    const { id: clientId } = useParams()
    const navigate = useNavigate()

    const updateVerticalConfig = {
        mutation,
        mutationName,
        onMutationSuccess: () => {
            // will be used later
        }
    }

    const { methods, save, mutationLoading, queryLoading } = useFormFeedback({
        permissions: [UserPermissionEnum.ClientCreateModify],
        initialValues: {
            title: null,
            topics: [],
            addedTopics: []
        },
        query,
        queryName,
        queryVariables: { clientId, ...(campusId && { campusId }) },
        retrieveOnMount: true,
        mapQueryResultToForm: (courses) => ({
            addedCourses: courses
        }),
        ...updateVerticalConfig,
        mapFormToMutationInput: (form) => {
            const { id, courses, addedCourses } = form
            const coursesAdded = courses
                ? courses?.filter((course) => course.isAdded)
                : addedCourses?.map((course: BaseFields) => ({ id: course.id }))

            return {
                clientId,
                ...(campusId && { campusId }),
                clientCourses: getAddedCourses(coursesAdded, addedCourses)
            }
        }
    })

    const { page } = usePage({
        pageConfig: {
            methods,
            ComponentToRender: SearchableCoursesList,
            componentProps: {
                addedIn: t(addedIn),
                permissions: [UserPermissionEnum.ClientCreateModify]
            },
            hasHeader: true,
            preventMargin: true,
            pageHeaderConfig: {
                headerText: t(`${t('general.edit')} ${t('general.courses')}`),
                primaryButtonTitle: t('general.save'),
                primaryButtonAction: () => save(),
                secondaryButtonTitle: t('general.cancel'),
                secondaryButtonAction: () =>
                    navigate(`/clients/${clientId}/details`),
                permissions: [UserPermissionEnum.ClientCreateModify]
            }
        }
    })

    return <>{page}</>
}
