import { gql } from '@apollo/client'
import {
  MessagesFragment,
  MetaFragment,
  ReportLibraryFields,
} from '../../fragments'

export const SAVE_REPORT_LIBRARY = gql`
  ${MetaFragment('ReportLibraryResponseDto')}
  ${MessagesFragment('ReportLibraryResponseDto')}
  ${ReportLibraryFields('ReportLibraryDto')}
  mutation saveReportLibrary($input: ReportLibraryInputDto!) {
    saveReportLibrary(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        ...ReportLibraryFields
      }
    }
  }
`
