import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const SAVE_VIDEO = gql`
  ${MetaFragment('VideoResponseDto')}
  ${MessagesFragment('VideoResponseDto')}
  mutation saveVideo($input: SaveVideoInputDto!) {
    saveVideo(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        id
        title
        url
        isDraft
      }
    }
  }
`
