import { CourseFields, MessagesFragment, MetaFragment } from '../../fragments'

import { gql } from '@apollo/client'

export const GET_COURSE_DIRECTORY = gql`
  ${MessagesFragment('CoursesResponseDto')}
  ${MetaFragment('CoursesResponseDto')}
  ${CourseFields('CourseDto')}
  query getCourseDirectory(
    $args: GetCoursesArgumentsDto!
    $options: CoursePaginationDto!
  ) {
    getCourseDirectory(args: $args, options: $options) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...CourseFields
      }
    }
  }
`

export const GET_COURSES = gql`
  ${MessagesFragment('CoursesResponseDto')}
  ${MetaFragment('CoursesResponseDto')}
  ${CourseFields('CourseDto')}
  query getCourses($clientId: String, $surveyTypeId: SurveyTypeGraphqlEnum) {
    getCourses(clientId: $clientId, surveyTypeId: $surveyTypeId) {
      ...MessagesFragment
      ...MetaFragment
      data {
        ...CourseFields
      }
    }
  }
`
