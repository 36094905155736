import { InsightTimeline } from '../constants'
import { format, subHours, subDays, subMonths, startOfMonth } from 'date-fns'

export const getTimeFrameData = (insightTimeFrame: string): string[] => {
  const today = new Date()
  const data: string[] = []
  let date
  let formattedDate: string

  switch (insightTimeFrame) {
    case InsightTimeline.LAST_SEVEN_DAYS:
      // Last Seven Days - Hourly
      for (let i = 7 * 24 - 1; i >= 0; i--) {
        date = subHours(today, i)
        formattedDate = format(date, "yyyy-MM-dd'T'HH:00:00")
        data.push(formattedDate)
      }
      break
    case InsightTimeline.LAST_TWENTY_EIGHT_DAYS:
      // Last 28 Days - Daily
      for (let i = 27; i >= 0; i--) {
        date = subDays(today, i)
        formattedDate = format(date, 'yyyy-MM-dd')
        data.push(formattedDate)
      }
      break
    case InsightTimeline.LAST_SIXTY_DAYS:
      // Last 60 Days - Daily
      for (let i = 59; i >= 0; i--) {
        date = subDays(today, i)
        formattedDate = format(date, 'yyyy-MM-dd')
        data.push(formattedDate)
      }
      break
    case InsightTimeline.LAST_YEAR:
      // Last Year - Monthly
      for (let i = 11; i >= 0; i--) {
        date = subMonths(startOfMonth(today), i)
        formattedDate = format(date, 'yyyy-MM-dd')
        data.push(formattedDate)
      }
      break
    default:
      // Error handling here
      data.push('')
      break
  }
  return data
}
