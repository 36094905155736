import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const SAVE_ARTICLE_INTERACTION = gql`
  ${MetaFragment('ArticleInteractionResponseDto')}
  ${MessagesFragment('ArticleInteractionResponseDto')}
  mutation ($input: ArticleInteractionInputDto!) {
    saveArticleInteraction(input: $input) {
      ...MetaFragment
      ...MessagesFragment
      data {
        article
        userId
        saved
        isHelpful
      }
    }
  }
`
