import React, { useEffect, useState } from 'react'
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
    Link,
    Breadcrumbs as MUIBreadcrumbs,
    Typography,
    IconButton,
    useMediaQuery,
    Tooltip
} from '@mui/material'
import { isEmpty, startCase } from 'lodash'
import { URLSegmentInfo } from '@cacheql'
import { ArrowLeftCircleIcon } from '@assets'
import { BackButtonType } from '@constants'
import { GET_ENTITY } from '@dts/graphql'
import { displayTextWithEllipsis, onKeyDown } from '@utils'
import { useGlobal } from './use-global'
import { useCustomTheme } from './use-custom-theme'
import { useGraphQLWrapper } from './use-graphql-wrapper'

export const useNavigations = (config) => {
    const {
        showBreadcrumbs,
        backButtonConfig,
        fetchEntityVariables,
        breadcrumbSearchParamAccessor
    } = config || {}
    const [searchParams] = useSearchParams()
    const breadcrumbSearchParam = searchParams.get(
        breadcrumbSearchParamAccessor
    )
    const { theme } = useCustomTheme()
    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const { state } = useLocation()
    const { navigationHistory } = useGlobal()
    const params = useParams()
    const paramIds = Object.values(params)

    const urlSegmentInfo = URLSegmentInfo()

    const { currentData: entityData } =
        fetchEntityVariables &&
        !urlSegmentInfo?.includes(fetchEntityVariables?.id)
            ? useGraphQLWrapper({
                  query: GET_ENTITY,
                  queryName: 'getEntity',
                  queryVariables: { input: { ...fetchEntityVariables } },
                  retrieveOnMount: true
              })
            : { currentData: undefined }

    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )

    const pathnames = location.pathname.split('/').filter((x) => x)

    const navigateBack = `/${pathnames
        .slice(0, pathnames?.length - 1)
        .join('/')}`

    const [allNamesFound, setAllNamesFound] = useState(false)

    useEffect(() => {
        let namesFound = false
        pathnames?.forEach((pathname) => {
            if (paramIds?.includes(pathname)) {
                const entry = urlSegmentInfo?.find(
                    (segment) => segment?.id === pathname
                )
                namesFound = entry ? true : false
            }
        })
        setAllNamesFound(isEmpty(paramIds) ? true : namesFound)
    }, [urlSegmentInfo, pathnames])

    const routeToSelectedBreadcrumb = (selectedPath: string) => {
        if (breadcrumbSearchParamAccessor) {
            navigate(
                `${selectedPath}?${breadcrumbSearchParamAccessor}=${breadcrumbSearchParam}`,
                {
                    state
                }
            )
        } else {
            navigate(selectedPath, { state })
        }
    }

    return {
        breadcrumbComponent:
            showBreadcrumbs &&
            !isMediumScreen &&
            !state?.isSearchItem &&
            allNamesFound ? (
                <MUIBreadcrumbs aria-label='breadcrumb'>
                    {pathnames.map((currentItem, index) => {
                        let nameToRender = ''
                        const mappedValue = URLSegmentInfo()?.find(
                            (segmentInfo) => segmentInfo?.id === currentItem
                        )?.title

                        if (mappedValue) {
                            nameToRender = mappedValue
                        } else {
                            nameToRender = paramIds?.includes(currentItem)
                                ? ''
                                : currentItem
                        }
                        const routeTo = `/${pathnames
                            .slice(0, index + 1)
                            .join('/')}`

                        const isLast = index === pathnames.length - 1
                        return isLast ? (
                            <Tooltip
                                title={startCase(nameToRender)}
                                placement='top-end'
                            >
                                <Typography
                                    sx={{
                                        fontSize: '13px',
                                        fontWeight: 400,
                                        color: theme.palette.contentPrimary,
                                        width: '10rem',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}
                                    key={currentItem}
                                >
                                    {startCase(nameToRender)}
                                </Typography>
                            </Tooltip>
                        ) : (
                            <Tooltip
                                title={startCase(nameToRender)}
                                placement='top-end'
                            >
                                <Link
                                    sx={{
                                        fontSize: '13px',
                                        fontWeight: 400,
                                        '&:hover': { cursor: 'pointer' },
                                        width: '10rem',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}
                                    key={currentItem}
                                    tabIndex={0}
                                    onClick={() => {
                                        routeToSelectedBreadcrumb(routeTo)
                                    }}
                                    onKeyDown={(e) =>
                                        onKeyDown(
                                            e,
                                            () =>
                                                routeToSelectedBreadcrumb(
                                                    routeTo
                                                ),
                                            false
                                        )
                                    }
                                >
                                    {startCase(nameToRender)}
                                </Link>
                            </Tooltip>
                        )
                    })}
                </MUIBreadcrumbs>
            ) : (
                <></>
            ),
        backRedirectionComponent:
            backButtonConfig?.showBackButton ||
            (showBreadcrumbs && isMediumScreen) ||
            state?.isSearchItem ? (
                <IconButton
                    aria-label={t('general.goBack')}
                    color='primary'
                    sx={{ padding: 0, marginRight: '8px' }}
                    onClick={() => {
                        if (
                            backButtonConfig?.backButtonType ===
                            BackButtonType.Custom
                        ) {
                            navigate(backButtonConfig?.customUrl, { state })
                        } else if (navigationHistory?.length <= 1) {
                            navigate('/dashboard')
                        } else if (
                            !showBreadcrumbs ||
                            state?.isSearchItem ||
                            backButtonConfig?.backButtonType ===
                                BackButtonType.History
                        ) {
                            navigate(-1)
                        } else if (
                            showBreadcrumbs ||
                            backButtonConfig?.backButtonType ===
                                BackButtonType.Hierarchical
                        ) {
                            routeToSelectedBreadcrumb(navigateBack)
                        }
                    }}
                >
                    <ArrowLeftCircleIcon color={theme.palette.contentPrimary} />
                </IconButton>
            ) : (
                <></>
            )
    }
}
