import React from 'react'
import { useTranslation } from 'react-i18next'
import { ControlledTextInput } from '@components'
import { Grid } from '@mui/material'
import { HelperTextPosition } from '@constants'

export const CreateTopics = () => {
    const { t } = useTranslation()
    return (
        <Grid container>
            <Grid
                item
                display={'flex'}
                xs={12}
                md={12}
                direction='column'
                gap={4}
            >
                <Grid item>
                    <ControlledTextInput
                        id='title'
                        label={t('general.title')}
                        name='title'
                        multiline
                        rows={1}
                        helperTextConfig={{
                            wordLength: 200,
                            helperTextPosition: HelperTextPosition.Right
                        }}
                        fullWidth
                    />
                    <ControlledTextInput
                        id='description'
                        label={t('general.description')}
                        name='description'
                        multiline
                        rows={3}
                        helperTextConfig={{
                            wordLength: 1000,
                            helperTextPosition: HelperTextPosition.Right
                        }}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
