enum MonthlyReminderOptionEnum {
  DateSpecific = 'DTS',
  DaySpecific = 'DYS',
}

enum MonthlyReminderOptionGraphqlEnum {
  DTS = 'DTS',
  DYS = 'DYS',
}

export { MonthlyReminderOptionEnum, MonthlyReminderOptionGraphqlEnum }
