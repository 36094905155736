import { gql } from '@apollo/client'
import { MessagesFragment, MetaFragment } from '../../fragments'

export const DELETE_PUSH_NOTIFICATION_TOKEN = gql`
  ${MessagesFragment('PushNotificationResponseDto')}
  ${MetaFragment('PushNotificationResponseDto')}
  mutation deletePushNotificationToken($input: PushNotificationInputDto!) {
    deletePushNotificationToken(input: $input) {
      ...MessagesFragment
      ...MetaFragment
      data {
        token
      }
    }
  }
`
