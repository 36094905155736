import {
    AGGREGATION_TYPE_ID,
    METRIC_VALUE_TYPE_ID,
    REPORT_BREAK_DOWN_FIELDS,
    REPORT_DATA_TABLE_COLUMNS,
    REPORT_METRIC_TYPE_ID
} from '@constants'
import { formatToTwoDecimals } from '@dts/client-utils'
import { BreakdownResult, ChartDataRow } from './types'

// Function to get unique date columns from the transformed data
export const getDateColumns = (data: Array<ChartDataRow>): Array<string> => {
    const dateSet = new Set<string>()
    data.forEach((row) => {
        Object.keys(row).forEach((key) => {
            // Add keys that are not standard columns to the dateSet
            if (
                ![
                    REPORT_DATA_TABLE_COLUMNS.METRICS,
                    REPORT_DATA_TABLE_COLUMNS.TRAINING,
                    REPORT_DATA_TABLE_COLUMNS.COURSE,
                    REPORT_DATA_TABLE_COLUMNS.SURVEY,
                    REPORT_DATA_TABLE_COLUMNS.AGE,
                    REPORT_DATA_TABLE_COLUMNS.INTEREST
                ].includes(key)
            ) {
                dateSet.add(key)
            }
        })
    })
    return Array.from(dateSet).sort()
}

// Function to calculate row spans for each column in the table
export const getRowSpans = (
    data: Array<ChartDataRow>,
    key: string
): Array<number> => {
    const rowSpans = new Array<number>(data.length).fill(0)
    let currentSpan = 0
    let lastValue: string | number | undefined = null

    for (let i = 0; i < data.length; i++) {
        if (data[i][key] !== lastValue) {
            if (currentSpan > 0) {
                rowSpans[i - currentSpan] = currentSpan
            }
            currentSpan = 1
            lastValue = data[i][key]
        } else {
            currentSpan++
        }
    }
    rowSpans[data.length - currentSpan] = currentSpan
    return rowSpans
}

// Function to process and group data for breakdown
export const breakdownData = (
    data: Array<ChartDataRow>,
    fields: Array<string>
): BreakdownResult => {
    const result: BreakdownResult = {}

    data.forEach((item) => {
        const metricTitle = item[fields[0]] // reportMetricTitle
        if (!result[metricTitle]) result[metricTitle] = []

        // Find if an entry with the same attributes already exists
        const existingEntry = result[metricTitle].find((entry) => {
            const trainingMatch = fields.includes(
                REPORT_BREAK_DOWN_FIELDS.TRAINING
            )
                ? entry.Training === item[REPORT_BREAK_DOWN_FIELDS.TRAINING]
                : true
            const courseMatch = fields.includes(REPORT_BREAK_DOWN_FIELDS.COURSE)
                ? entry.Course === item[REPORT_BREAK_DOWN_FIELDS.COURSE]
                : true
            const surveyMatch = fields.includes(REPORT_BREAK_DOWN_FIELDS.SURVEY)
                ? entry.Survey === item[REPORT_BREAK_DOWN_FIELDS.SURVEY]
                : true
            const ageMatch = fields.includes(REPORT_BREAK_DOWN_FIELDS.AGE)
                ? entry.Age === item[REPORT_BREAK_DOWN_FIELDS.AGE]
                : true
            const interestMatch = fields.includes(
                REPORT_BREAK_DOWN_FIELDS.INTEREST
            )
                ? entry.Interest === item[REPORT_BREAK_DOWN_FIELDS.INTEREST]
                : true
            return (
                trainingMatch &&
                ageMatch &&
                interestMatch &&
                courseMatch &&
                surveyMatch
            )
        })

        const formattedValue =
            item.metricValueTypeId === METRIC_VALUE_TYPE_ID.PERCENTAGE
                ? `${item.value}%`
                : item.value

        if (existingEntry) {
            // Update existing entry with new date value
            existingEntry.Dates[item.time] = formattedValue
        } else {
            // Create a new entry if none exists
            const newEntry: any = {}
            if (fields.includes(REPORT_BREAK_DOWN_FIELDS.TRAINING)) {
                newEntry.Training = item[REPORT_BREAK_DOWN_FIELDS.TRAINING]
            }
            if (fields.includes(REPORT_BREAK_DOWN_FIELDS.COURSE)) {
                newEntry.Course = item[REPORT_BREAK_DOWN_FIELDS.COURSE]
            }
            if (fields.includes(REPORT_BREAK_DOWN_FIELDS.SURVEY)) {
                newEntry.Survey = item[REPORT_BREAK_DOWN_FIELDS.SURVEY]
            }

            if (fields.includes(REPORT_BREAK_DOWN_FIELDS.AGE)) {
                newEntry.Age = item[REPORT_BREAK_DOWN_FIELDS.AGE]
            }
            if (fields.includes(REPORT_BREAK_DOWN_FIELDS.INTEREST)) {
                newEntry.Interest = item[REPORT_BREAK_DOWN_FIELDS.INTEREST]
            }
            newEntry.Dates = {
                [item.time]: formattedValue
            }
            newEntry.isPercentage =
                item.metricValueTypeId === METRIC_VALUE_TYPE_ID.PERCENTAGE
            newEntry.aggregationTypeId = item?.aggregationTypeId
            result[metricTitle].push(newEntry)
        }
    })

    return result
    // Below type of data is return this function
    // const sampleData = [
    //     {
    //         'Progression Rate': [
    //             {
    //                 Training: 'First Training',
    //                 Age: '26',
    //                 Interest: 'Fitness',
    //                 Dates: {
    //                     '1723028400000': '0.00%'
    //                 }
    //             }
    //         ],
    //         'Completion Rate': [
    //             {
    //                 Training: 'First Training',
    //                 Age: '43',
    //                 Interest: 'Travelling',
    //                 Dates: {
    //                     '1723186800000': '0.00%'
    //                 }
    //             }
    //         ]
    //     }
    // ]
}

// Function to transform the breakdown data into table format
export const transformData = (
    breakdown: BreakdownResult
): Array<ChartDataRow> => {
    const result: Array<ChartDataRow> = []

    Object.entries(breakdown).forEach(([metric, entries]) => {
        entries.forEach((entry) => {
            const row: ChartDataRow = {
                Metrics: metric,
                ...(entry.Training && { Training: entry.Training }),
                ...(entry.Age && { Age: entry.Age }),
                ...(entry.Interest && { Interest: entry.Interest }),
                ...(entry.Course && { Course: entry.Course }),
                ...(entry.Survey && { Survey: entry.Survey }),
                ...entry.Dates
            }
            result.push(row)
        })
    })

    return result
}
export const transformDataForChart = (
    breakdown: BreakdownResult
): Array<ChartDataRow> => {
    const result: Array<ChartDataRow> = []

    Object.entries(breakdown).forEach(([metric, entries]) => {
        entries.forEach((entry) => {
            const row: ChartDataRow = {
                Metrics: metric,
                ...(entry.Training && { Training: entry.Training }),
                ...(entry.Age && { Age: entry.Age }),
                ...(entry.Interest && { Interest: entry.Interest }),
                ...(entry.Course && { Course: entry.Course }),
                ...(entry.Survey && { Survey: entry.Survey }),
                dates: [entry.Dates],
                isPercentage: entry?.isPercentage,
                aggregationTypeId: entry?.aggregationTypeId
            }
            result.push(row)
        })
    })

    return result
}

// Function to create the dynamic string
const getSeriesName = (data) => {
    const { Metrics, Training, Survey, Course, Age, Interest } = data

    // Collect only the defined values
    const components = [Training, Survey, Course, Age, Interest].filter(
        (value) => value !== undefined
    )

    // Join the components with slashes
    const componentString = components.join('/')

    // Return the final string, handling cases where there might be no defined components
    return `${Metrics}${componentString ? ` (${componentString})` : ''}`
}

// Function to extract numeric values
const getYAxisDataPoints = (dates, xAxis) => {
    // Create an array with null values based on xAxis length
    const dataPoints = new Array(xAxis.length).fill(null)

    // Loop through each date object in the dates array
    dates.forEach((dateObj) => {
        // Extract all date keys and values
        Object.keys(dateObj).forEach((dateKey) => {
            const value = parseFloat(dateObj[dateKey].replace('%', ''))

            // Find the index of this date in the xAxis array
            const dateIndex = xAxis.indexOf(dateKey)

            // Place the value at the correct index in the dataPoints array
            if (dateIndex !== -1) {
                dataPoints[dateIndex] = value
            }
        })
    })

    return dataPoints
}
export const getSeriesData = (data, XAxis): Array<ChartDataRow> =>
    data?.map((item) => ({
        name: getSeriesName(item),
        data: getYAxisDataPoints(item?.dates, XAxis),
        isPercentage: item?.isPercentage
    }))

export const getXAxis = (data) => {
    const times = new Set()
    data.forEach((item) => times.add(item.time))
    return Array.from(times)
}

export const createDataSet = (chartData, breakdowns) =>
    chartData?.map((item) => ({
        reportMetricTitle: item?.reportMetricTitle,
        age: item?.age,
        interest: item?.interest?.title,
        time: item?.time,
        value: formatToTwoDecimals(item?.value),
        metricValueTypeId: item?.metricValueTypeId,
        aggregationTypeId: item?.aggregationTypeId,
        ...(item?.reportMetricTypeId === REPORT_METRIC_TYPE_ID.TRAINING &&
            breakdowns?.trainings && {
                training: item?.itemTitle
            }),
        ...(item?.reportMetricTypeId === REPORT_METRIC_TYPE_ID.COURSE &&
            breakdowns?.course && {
                course: item?.itemTitle
            }),
        ...(item?.reportMetricTypeId === REPORT_METRIC_TYPE_ID.SURVEY &&
            breakdowns?.survey && {
                survey: item?.itemTitle
            })
    })) || []
