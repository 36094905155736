import { Card, Grid, Typography, styled } from '@mui/material'

export const StyledCardHeader = styled(Grid)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const StyledCard = styled(Card, {
    shouldForwardProp: (prop) => prop !== 'isSelected'
})(({ isSelected, theme }) => ({
    cursor: 'pointer',
    padding: '14px',
    border: `1px solid ${theme.palette.backgroundTertiary}`,
    borderRadius: '8px 8px 0 0',
    borderBottom: isSelected
        ? 'none'
        : `1px solid ${theme.palette.backgroundTertiary}`,
    borderTop: isSelected
        ? `3px solid ${theme.palette.backgroundBrandPrimary}`
        : `1px solid ${theme.palette.backgroundTertiary}`,
    backgroundColor: isSelected
        ? theme.palette.backgroundPrimary
        : theme.palette.backgroundSecondary
}))
