import {
    BuildingsIcon,
    CategoryIcon,
    CourseIcon,
    DocumentFoldIcon,
    ReadingIcon,
    SearchIcon,
    TopicsIcon,
    UsersIcon,
    VideoApprovalIcon,
    VideoPlayerIcon,
    ResourcesIcon
} from '@assets'
import { UserRolesEnum } from '@dts/constants'
import { useAuth } from '@hooks'
import { getAccessRoles } from '@utils'
import { uniqBy } from 'lodash'

const resourceAndCategoryConfig = [
    {
        category: 'resource_category',
        hasImage: false,
        categoryIcon: ResourcesIcon,
        route: `/resources`,
        isModalRoute: false
    },
    {
        category: 'resource',
        hasImage: false,
        categoryIcon: ResourcesIcon,
        route: `/resources`,
        isModalRoute: false
    }
]

export const routesByCategory = {
    admin: [
        {
            category: 'course',
            categoryIcon: CourseIcon,
            hasImage: false,
            route: '/courses/{id}',
            isModalRoute: false
        },
        {
            category: 'vertical',
            hasImage: false,
            categoryIcon: CategoryIcon,
            route: '/verticals/{id}',
            isModalRoute: false
        },
        {
            category: 'article',
            hasImage: true,
            categoryIcon: ReadingIcon,
            route: '/knowledge-center/{id}',
            isModalRoute: false
        },
        {
            category: 'video',
            hasImage: true,
            categoryIcon: VideoApprovalIcon,
            route: `/videos/{id}`,
            isModalRoute: false
        },
        {
            category: 'client',
            hasImage: false,
            categoryIcon: BuildingsIcon,
            route: `/clients/{id}`,
            isModalRoute: false
        },
        {
            category: 'user',
            hasImage: true,
            categoryIcon: UsersIcon,
            route: `/admins/modify/{id}`,
            isModalRoute: false
        },
        {
            category: 'topic',
            hasImage: false,
            categoryIcon: TopicsIcon,
            route: `/topics`,
            isModalRoute: true
        },
        ...resourceAndCategoryConfig
    ],
    clientAdmin: [
        {
            category: 'user',
            hasImage: true,
            categoryIcon: UsersIcon,
            route: `/users/{id}`,
            isModalRoute: false
        },
        {
            category: 'article',
            hasImage: true,
            categoryIcon: ReadingIcon,
            route: '/learn/learning-center/{id}',
            isModalRoute: false
        },
        {
            category: 'video',
            hasImage: true,
            categoryIcon: VideoPlayerIcon,
            route: `/browse-videos/watch/{id}`,
            isModalRoute: false
        },
        {
            category: 'training',
            hasImage: false,
            categoryIcon: DocumentFoldIcon,
            route: `/trainings/{id}`,
            isModalRoute: false
        },
        {
            category: 'learner_list',
            hasImage: false,
            categoryIcon: CourseIcon,
            route: `/learner-list/{id}`,
            isModalRoute: false
        },
        ...resourceAndCategoryConfig
    ],
    contentCreator: [
        {
            category: 'video',
            hasImage: true,
            categoryIcon: VideoPlayerIcon,
            route: `/browse-videos/watch/{id}`,
            isModalRoute: false
        },
        {
            category: 'article',
            hasImage: true,
            categoryIcon: ReadingIcon,
            route: '/learn/learning-center/{id}',
            isModalRoute: false
        },
        {
            category: 'user',
            hasImage: true,
            categoryIcon: UsersIcon,
            route: ``,
            isModalRoute: false
        }
    ],
    learner: [
        {
            category: 'video',
            hasImage: true,
            categoryIcon: VideoPlayerIcon,
            route: `/browse-videos/watch/{id}`,
            isModalRoute: false
        },
        {
            category: 'article',
            hasImage: true,
            categoryIcon: ReadingIcon,
            route: '/learn/learning-center/{id}',
            isModalRoute: false
        },
        {
            category: 'user',
            hasImage: true,
            categoryIcon: UsersIcon,
            route: ``,
            isModalRoute: false
        },
        {
            category: 'training',
            hasImage: false,
            categoryIcon: DocumentFoldIcon,
            route: `/my-trainings`,
            isModalRoute: true
        },
        ...resourceAndCategoryConfig
    ]
}

export const getRoutesByRole = (accessedRoles) => {
    const {
        isManager,
        isAdmin,
        isContentCreator,
        isClientAdmin,
        isCoordinator,
        isModerator,
        isLearner,
        isContentCreatorAndLearner
    } = accessedRoles
    const routesArray = isAdmin
        ? routesByCategory && routesByCategory.admin
        : isClientAdmin
        ? routesByCategory && routesByCategory.clientAdmin
        : isContentCreatorAndLearner
        ? routesByCategory &&
          uniqBy(
              [...routesByCategory.contentCreator, ...routesByCategory.learner],
              'category'
          )
        : isLearner
        ? routesByCategory && routesByCategory.learner
        : isContentCreator
        ? routesByCategory && routesByCategory.contentCreator
        : []

    return routesArray || []
}

export const getUserRouteByRole = (role: string, id: string) => {
    const route =
        role === UserRolesEnum?.CONTENT_CREATOR
            ? `/creator-profile/${id}/all-video`
            : role === UserRolesEnum?.TRAINEE
            ? `/learner-list/learner-profile/${id}`
            : role === UserRolesEnum?.CLIENT_ADMIN
            ? `/users/${id}`
            : role === UserRolesEnum?.GRS_SUPER_ADMIN ||
              role === UserRolesEnum?.GRS_ADMIN ||
              role === UserRolesEnum?.GRS_COORDINATOR ||
              role === UserRolesEnum?.GRS_MODERATOR
            ? `/admins/${id}`
            : role === UserRolesEnum?.SUPPORT_USER
            ? `/users/view-admin/${id}`
            : ''
    return route
}
