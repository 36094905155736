import React, { useEffect, useState } from 'react'
import { DocumentNode, QueryResult, useLazyQuery } from '@apollo/client'
import { ApolloResponse, Response } from '@dts/client-utils'
import { useLoader } from './use-loader'

export const useCustomQuery = ({
    query,
    queryName,
    retrieveOnMount,
    queryVariables,
    onQuerySuccess
}: {
    query: DocumentNode
    queryName: string
    retrieveOnMount?: boolean
    queryVariables?: object
    onQuerySuccess?: (response: Response<QueryResult>) => void
}) => {
    const [currentData, setCurrentData] = useState()
    const [data, setData] = useState()
    const { setLoading } = useLoader()

    const [retrieve, { data: queryData, loading: queryLoading }] = useLazyQuery<
        ApolloResponse<QueryResult>
    >(query, {
        variables: { ...queryVariables }
    })

    useEffect(() => {
        if (!queryLoading && queryData) {
            setData(queryData)
            const result = queryData?.[queryName]
            setCurrentData(result?.data)
            onQuerySuccess?.(result)
        }
    }, [queryData])

    useEffect(() => {
        if (retrieveOnMount) {
            retrieve?.()
        }
    }, [])

    useEffect(() => {
        setLoading?.(queryLoading)
    }, [queryLoading])

    return {
        currentData,
        data,
        queryLoading,
        retrieve
    }
}
