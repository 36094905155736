import React from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { CreateVideo, ViewVideoDetails } from '@components'
import {
    DELETE_VIDEO,
    GET_VIDEO,
    SAVE_VIDEO_BY_CONTENT_CREATOR
} from '@dts/graphql'
import { UploadVideoSchema } from '@dts/client-utils'
import { GetVideo } from '@types'
import { VideoConfigType, VideoStatus } from '@constants'
import { t } from 'i18next'
import { Typography } from '@mui/material'
import { isEqual } from 'lodash'
import { mapLabelsWithAutoIncrements } from '@utils'

type VideoUploadForm = {
    id: string
    videoId: string
    url: string
    urlSrc: string
    title: string
    description: string
    tags: Array<string>
    hyperlinks: Array<string>
    thumbnail: string
    topicIds: Array<string>
    skillId: string
    stateIds: Array<string>
    coverImage: string
    schoolIds: Array<string>
    captionText: string
    keyFramesSrc: Array<string>
    keyFrames: Array<string>
    actualCaptionText: string
}

export const useVideoConfig = (params) => {
    const navigate = useNavigate()
    const match = useMatch('*') // Use '*' to match any route
    const { onSuccess, videoConfigType, modalConfig, initialValues } = params

    const getDefaultQueryResultToForm = ({
        id,
        url,
        urlSrc,
        title,
        status,
        description,
        tags,
        coverImage,
        coverImageSrc,
        hyperlinks,
        topicIds,
        statesData,
        skillData,
        topicsData,
        reviews,
        skillId,
        captionText,
        keyFramesSrc,
        keyFrames,
        captionFileSrc
    }: GetVideo) => ({
        id,
        url: urlSrc,
        urlSrc: url,
        title,
        status,
        description,
        tags,
        thumbnail: coverImageSrc,
        coverImage,
        hyperlinks,
        stateIds: statesData?.map((data) => data?.id),
        topicsData,
        topicIds,
        skillData,
        statesData,
        skillId,
        captionText,
        reviews,
        keyFramesSrc,
        keyFrames,
        captionFileSrc
    })

    const getDefaultFormToMutationInput = (formValues: VideoUploadForm) => {
        const {
            id,
            videoId,
            url,
            urlSrc,
            title,
            description,
            tags,
            hyperlinks,
            thumbnail,
            topicIds,
            skillId,
            stateIds,
            coverImage,
            schoolIds,
            captionText,
            keyFramesSrc,
            keyFrames,
            actualCaptionText
        } = formValues

        return {
            id: id || videoId,
            url: url?.includes('https://') ? urlSrc : url,
            title,
            // status: VideoStatus.Pending,
            description,
            tags,
            topicIds,
            hyperlinks: mapLabelsWithAutoIncrements(hyperlinks)?.map(
                (hyperlink) => hyperlink?.value
            ),
            coverImage: coverImage
                ? coverImage
                : thumbnail
                ? thumbnail
                : keyFrames?.[0],
            skillId,
            stateIds,
            isDraft: false,
            schoolIds: schoolIds ?? undefined,
            captionText: !isEqual(captionText, actualCaptionText)
                ? captionText
                : undefined
        }
    }

    const getDefaultModalConfig = () =>
        videoConfigType === VideoConfigType.Retrieve
            ? {
                  Component: ViewVideoDetails,
                  updationConfig: {
                      header: t('creatorVideos.modal.videoDetails.header')
                  },
                  hasCancelButton: true
              }
            : {
                  Component: CreateVideo,
                  creationConfig: {
                      primaryButtonText: t('creatorVideos.modal.video.submit'),
                      secondaryButtonText: t(
                          'creatorVideos.modal.video.saveAsDraft'
                      ),
                      header: t('creatorVideos.modal.createVideo.header')
                  },
                  updationConfig: {
                      primaryButtonText: t('creatorVideos.modal.video.submit'),
                      secondaryButtonText: t(
                          'creatorVideos.modal.video.saveAsDraft'
                      ),
                      header: t('creatorVideos.modal.updateVideo.header')
                  }
              }

    const getDefaultInitialValues = () =>
        videoConfigType === VideoConfigType.Retrieve
            ? {
                  id: '',
                  url: '',
                  title: '',
                  thumbnail: '',
                  stateIds: [],
                  topicIds: [],
                  skillId: '',
                  description: '',
                  topicsData: [],
                  skillData: {},
                  statesData: [],
                  status: '',
                  tags: [],
                  schoolIds: [],
                  hyperlinks: []
              }
            : {
                  id: '',
                  status: '',
                  urlSrc: '',
                  tags: [],
                  hyperlinks: [],
                  url: '',
                  title: '',
                  thumbnail: '',
                  skillId: '',
                  coverImageSrc: '',
                  stateIds: [],
                  topicIds: [],
                  description: '',
                  captionText: null
              }

    const getConfigByType = () => {
        const commonConfig = {
            yupResolverSchema: UploadVideoSchema,
            modalConfig: modalConfig ?? getDefaultModalConfig(),
            initialValues: initialValues ?? getDefaultInitialValues(),
            hasCancelButton: true
        }

        const uploadVideoConfig = {
            mutation: SAVE_VIDEO_BY_CONTENT_CREATOR,
            mutationName: 'saveVideo',
            mapFormToMutationInput: getDefaultFormToMutationInput,
            onMutationSuccess: (response) => {
                const draftsRoute = '/my-account/drafts'
                const pendingRoute = '/my-account/pending'
                const currentRoute = match?.pathname

                const targetRoute = response?.data?.isDraft
                    ? draftsRoute
                    : pendingRoute

                navigate(currentRoute === targetRoute ? 0 : targetRoute)
                onSuccess?.()
            }
        }

        const retrieveVideoConfig = {
            query: GET_VIDEO,
            queryName: 'getVideo',
            mapQueryResultToForm: getDefaultQueryResultToForm
        }

        const config =
            videoConfigType === VideoConfigType.Upload
                ? { ...commonConfig, ...uploadVideoConfig }
                : videoConfigType === VideoConfigType.Retrieve
                ? { ...commonConfig, ...retrieveVideoConfig }
                : {
                      ...commonConfig,
                      ...retrieveVideoConfig,
                      ...uploadVideoConfig
                  }

        return config
    }

    const getDeleteConfig = (onSuccess?: VoidFunction) => ({
        mutation: DELETE_VIDEO,
        mutationName: 'deleteVideo',
        isCustomMutationInput: true,
        onMutationSuccess: () => {
            onSuccess?.()
        },
        modalConfig: {
            confirmationConfig: {
                header: t('videos.directory.deleteVideo.header'),
                primaryButtonText: t('general.confirm')
            },
            Component: () => (
                <Typography variant='subtitle2'>
                    {t('videos.directory.deleteVideo.confirmationMessage')}
                </Typography>
            )
        }
    })

    return { videoConfig: getConfigByType(), getDeleteConfig }
}
