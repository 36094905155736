import styled from '@emotion/styled'
import { Grid } from '@mui/material'

export const StyledBox = styled(Grid)(({ theme }) => ({
    backgroundColor: '#FDFCFC', // Add directly code because because before login theme is undefined and we want white background on dark and light mode
    width: '400px',
    top: '50%',
    left: '50%',
    boxShadow: '0 0 40px 4px #111118',
    borderRadius: '24px',
    display: 'grid',
    textAlign: 'center',
    justifyContent: 'center',
    boxSizing: `border-box`,
    '& > div': {
        width: 'inherit'
    },
    [theme.breakpoints.down(445)]: {
        width: '300px' // Adjust width for extra small screens
    }
}))
