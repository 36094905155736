import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    Button,
    CardMedia,
    Chip,
    Divider,
    Grid,
    IconButton,
    Typography
} from '@mui/material'
import parse from 'html-react-parser'
import { CloseIcon, PictureFrameImg, SaveIcon, TickIcon } from '@assets'
import { isNil } from 'lodash'
import { Article, TextPageHeader } from '@components'
import { useCustomTheme } from '@hooks'

import { isMediumScreen } from '@utils'
import { BackButtonType } from '@constants'
import { ViewArticleProps } from './types'

export const ViewArticle: React.FC<ViewArticleProps> = ({
    article,
    onSaveClick,
    saveIsHelpfulInteraction,
    showBreadcrumbs = false
}) => {
    const { t } = useTranslation()
    const { theme } = useCustomTheme()
    return (
        <Grid container direction={'column'} gap={3}>
            <Grid
                container
                direction={'column'}
                sx={{
                    width: '60%',
                    marginLeft: '20%',
                    marginRight: '20%'
                }}
                gap={3}
            >
                <Grid item>
                    <TextPageHeader
                        backButtonConfig={{
                            showBackButton: isMediumScreen?.(),
                            backButtonType: BackButtonType.Hierarchical
                        }}
                        title={article?.title || ''}
                        showBreadcrumbs={showBreadcrumbs}
                    />

                    <Grid container item xs={12} md={12} gap={1}>
                        {article?.tags?.map((tag, index) => (
                            <Grid key={index}>
                                <Chip
                                    size='small'
                                    variant='filled'
                                    label={tag?.toUpperCase()}
                                    sx={{
                                        borderRadius: '4px',
                                        backgroundColor:
                                            theme.palette.backgroundSecondary,
                                        color: theme.palette.contentPrimary
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        py={'8px'}
                    >
                        <Grid item>
                            {!isNil(article?.duration) && (
                                <Typography
                                    fontSize={12}
                                    fontWeight={500}
                                    sx={{
                                        color: theme.palette.contentSecondary
                                    }}
                                >
                                    {`–  ${article?.duration} ${t(
                                        'general.minRead'
                                    )}`}
                                </Typography>
                            )}
                        </Grid>
                        <Grid>
                            <IconButton
                                color='primary'
                                aria-label={t('general.save')}
                                sx={{
                                    borderRadius: '9px',
                                    background: article?.articleInteraction
                                        ?.saved
                                        ? theme.palette.backgroundBrandPrimary
                                        : theme.palette.contentSecondary,
                                    width: '32px',
                                    height: '32px'
                                }}
                                onClick={onSaveClick}
                            >
                                <SaveIcon
                                    color={theme.palette.backgroundPrimary}
                                    fill={
                                        article?.articleInteraction?.saved
                                            ? theme.palette.backgroundPrimary
                                            : 'none'
                                    }
                                />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} mt={1} mb={1}>
                        <Divider />
                    </Grid>
                </Grid>
                <Grid item xs={12} display={'contents'}>
                    <CardMedia
                        component='img'
                        width={'100%'}
                        image={article?.coverImageSrc || PictureFrameImg}
                        alt={article?.title}
                        sx={{
                            borderRadius: '4px',
                            height: !article?.coverImageSrc && '300px',
                            maxHeight: '400px',
                            objectFit: 'contain',
                            background: `${theme.palette.backgroundSecondary}`
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography
                        fontSize={'17px'}
                        fontWeight={'400'}
                        sx={{
                            color: theme.palette.contentPrimary
                        }}
                    >
                        {parse(article?.description || '')}
                    </Typography>
                </Grid>
            </Grid>
            {!isNil(article) &&
                saveIsHelpfulInteraction &&
                isNil(article?.articleInteraction?.isHelpful) && (
                    <Grid
                        container
                        justifyContent={'center'}
                        gap={2}
                        sx={{
                            borderTop: `1px solid ${theme.palette.borderSecondary}`,
                            alignItems: 'center'
                        }}
                        py={'16px'}
                    >
                        <Grid>
                            <Typography variant='body3'>
                                {t('helpCenter.helpFull')}
                            </Typography>
                        </Grid>
                        <Grid>
                            <Button
                                fullWidth
                                variant={'contained'}
                                color={'secondary'}
                                startIcon={<TickIcon />}
                                aria-label={[
                                    t('helpCenter.helpFull'),
                                    t('general.yes.question')
                                ].join(' ')}
                                onClick={() => saveIsHelpfulInteraction(true)}
                            >
                                {t('general.yes')}
                            </Button>
                        </Grid>
                        <Grid>
                            <Button
                                fullWidth
                                variant={'contained'}
                                color={'secondary'}
                                startIcon={
                                    <CloseIcon
                                        width='21'
                                        height='20'
                                        color={theme.palette.contentSecondary}
                                    />
                                }
                                aria-label={[
                                    t('helpCenter.helpFull'),
                                    t('general.no.question')
                                ].join(' ')}
                                onClick={() => saveIsHelpfulInteraction(false)}
                            >
                                {t('general.no')}
                            </Button>
                        </Grid>
                    </Grid>
                )}
        </Grid>
    )
}
