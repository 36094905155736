import React from 'react'
import { SvgProps } from '@types'
import { useCustomTheme } from '@hooks'

const MultipleUsersIcon: React.FC<SvgProps> = ({
    color,
    width = '24',
    height = '24',
    fill = 'none'
}) => {
    const { theme } = useCustomTheme()
    const _color = color || theme.palette.contentSecondary

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 24 24'
            fill={fill}
        >
            <path
                d='M11.5 12C13.6695 12 15.4282 10.2091 15.4282 8C15.4282 5.79086 13.6695 4 11.5 4C9.33051 4 7.57178 5.79086 7.57178 8C7.57178 10.2091 9.33051 12 11.5 12Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M11.5 14.6667C9.02904 14.6667 6.83287 16.0396 5.44114 18.1674C5.13626 18.6336 4.98381 18.8666 5.00136 19.1644C5.01503 19.3962 5.16426 19.68 5.34618 19.8201C5.57981 20 5.90113 20 6.54377 20H16.4562C17.0989 20 17.4202 20 17.6538 19.8201C17.8357 19.68 17.985 19.3962 17.9986 19.1644C18.0162 18.8666 17.8637 18.6336 17.5589 18.1674C16.1671 16.0396 13.971 14.6667 11.5 14.6667Z'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M19.5 15C21.5 16.5 21.9729 18.9185 21.9939 19.0792C22.0367 19.4057 21.848 19.8072 21.5909 19.9364C21.4644 20 21.3221 20 21.0374 20M18 11.4397C19.0001 10.8517 19.6875 9.63075 19.6875 8.21986C19.6875 6.80897 19.0001 5.588 18 5'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M3.5 15C1.5 16.5 1.02712 18.9185 1.00606 19.0792C0.96328 19.4057 1.15204 19.8072 1.40912 19.9364C1.53563 20 1.67794 20 1.96257 20M5 11.4397C3.99986 10.8517 3.31254 9.63075 3.31254 8.21986C3.31254 6.80897 3.99986 5.588 5 5'
                stroke={_color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default MultipleUsersIcon
