export enum TraineeListStatus {
    All = 'ALL',
    Active = 'ACTIVE',
    Archived = 'ARCHIVED'
}

export enum TrainingsStatus {
    AVAILABLE = 'available',
    Archived = 'archived'
}

export const ShuffleForMe = 'SFM'
