export const ARTICLE = 'article'
export const VIDEO = 'video'
export const USER = 'user'
export const CAMPUS = 'campus'
export const CLIENT = 'client'
export const TOPIC = 'topic'
export const VERTICAL = 'vertical'
export const LEARNER_LIST = 'learner_list'
export const COURSE = 'course'
export const TRAINING = 'training'

export const REPORT_LIBRARY = 'report_library'
export const REPORT = 'report'

export const RESOURCES_CATEGORY = 'resource_category'
export const RESOURCES = 'resource'
